import { useState, useEffect } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";
import { useNavigate } from "react-router-dom";

export default function HomeLoanEMICalculator({ scrollToFilter }) {
  const [loanAmount, setLoanAmount] = useState(2500000);
  const [interestRate, setInterestRate] = useState(8.5);
  const [loanTenure, setLoanTenure] = useState(20);
  const [emi, setEmi] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    calculateEMI();
  }, [loanAmount, interestRate, loanTenure]);

  const calculateEMI = () => {
    const principal = loanAmount;
    const rate = interestRate / 12 / 100;
    const time = loanTenure * 12;

    const emi =
      (principal * rate * Math.pow(1 + rate, time)) /
      (Math.pow(1 + rate, time) - 1);
    const totalAmount = emi * time;
    const totalInterestPayable = totalAmount - principal;

    setEmi(Math.round(emi));
    setTotalInterest(Math.round(totalInterestPayable));
    setTotalPayment(Math.round(totalAmount));
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    });
  };

  const chartData = [
    { name: "Principal", value: loanAmount },
    { name: "Interest", value: totalInterest },
  ];

  const COLORS = ["#311664", "#3a868b"];

  return (
    <>
      <section className="bg-white py-4 sm:py-8 md:py-12 px-3 sm:px-6 md:px-8">
        <div
          className="max-w-3xl mx-auto"
          style={{ backgroundColor: "transparent" }}
        >
          <h2
            className="text-xl sm:text-2xl md:text-3xl font-bold text-[#311664] mb-4 sm:mb-6 md:mb-8 text-center"
            style={{ backgroundColor: "transparent" }}
          >
            Car Loan EMI Calculator
          </h2>
          <div className="bg-[#ebe8fc] shadow-lg rounded-lg">
            <div
              className="p-3 sm:p-4 md:p-6 border-b"
              style={{ backgroundColor: "transparent" }}
            >
              <h3
                className="text-lg md:text-xl text-[#311664] font-semibold"
                style={{ backgroundColor: "transparent" }}
              >
                Calculate Your EMI
              </h3>
            </div>
            <div
              className="p-3 sm:p-4 md:p-6"
              style={{ backgroundColor: "transparent" }}
            >
              <div
                className="grid gap-6 sm:grid-cols-2"
                style={{ backgroundColor: "transparent" }}
              >
                <div
                  className="space-y-6"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div style={{ backgroundColor: "transparent" }}>
                    <label
                      className="block text-xs sm:text-sm md:text-base font-medium text-[#311664] mb-1"
                      style={{ backgroundColor: "transparent" }}
                    >
                      Loan Amount (₹)
                    </label>
                    <input
                      type="number"
                      value={loanAmount}
                      onChange={(e) => setLoanAmount(Number(e.target.value))}
                      className="w-full p-2 border rounded-md mb-1 text-sm md:text-base"
                      style={{ backgroundColor: "transparent" }}
                    />
                    <input
                      type="range"
                      min={100000}
                      max={10000000}
                      step={100000}
                      value={loanAmount}
                      onChange={(e) => setLoanAmount(Number(e.target.value))}
                      className="w-full mb-1"
                      style={{ backgroundColor: "transparent" }}
                    />
                  </div>
                  <div style={{ backgroundColor: "transparent" }}>
                    <label
                      className="block text-xs sm:text-sm md:text-base font-medium text-[#311664] mb-1"
                      style={{ backgroundColor: "transparent" }}
                    >
                      Interest Rate (%)
                    </label>
                    <input
                      type="number"
                      value={interestRate}
                      onChange={(e) => setInterestRate(Number(e.target.value))}
                      className="w-full p-2 border rounded-md mb-1 text-sm md:text-base"
                      step="0.1"
                      style={{ backgroundColor: "transparent" }}
                    />
                    <input
                      type="range"
                      min={5}
                      max={15}
                      step={0.1}
                      value={interestRate}
                      onChange={(e) => setInterestRate(Number(e.target.value))}
                      className="w-full mb-1"
                      style={{ backgroundColor: "transparent" }}
                    />
                  </div>
                  <div style={{ backgroundColor: "transparent" }}>
                    <label
                      className="block text-xs sm:text-sm md:text-base font-medium text-[#311664] mb-1"
                      style={{ backgroundColor: "transparent" }}
                    >
                      Loan Tenure (Years)
                    </label>
                    <input
                      type="number"
                      value={loanTenure}
                      onChange={(e) => setLoanTenure(Number(e.target.value))}
                      className="w-full p-2 border rounded-md mb-1 text-sm md:text-base"
                      style={{ backgroundColor: "transparent" }}
                    />
                    <input
                      type="range"
                      min={1}
                      max={30}
                      step={1}
                      value={loanTenure}
                      onChange={(e) => setLoanTenure(Number(e.target.value))}
                      className="w-full mb-1"
                      style={{ backgroundColor: "transparent" }}
                    />
                  </div>
                </div>
                <div
                  className="space-y-6"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div className="bg-white p-2 rounded-lg shadow">
                    <h3
                      className="text-sm md:text-base font-semibold text-[#311664] mb-1 style={{backgroundColor:'transparent'}}"
                      style={{ backgroundColor: "transparent" }}
                    >
                      EMI Breakdown
                    </h3>
                    <p
                      className="text-xs sm:text-sm md:text-base text-gray-600 mb-1"
                      style={{ backgroundColor: "transparent" }}
                    >
                      Monthly EMI
                    </p>
                    <p
                      className="text-lg md:text-xl font-bold text-[#3a868b]"
                      style={{ backgroundColor: "transparent" }}
                    >
                      {formatCurrency(emi)}
                    </p>
                  </div>
                  <div
                    className="grid grid-cols-2 gap-4"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="bg-white p-2 rounded-lg shadow">
                      <p
                        className="text-xs sm:text-sm md:text-base text-gray-600 mb-1"
                        style={{ backgroundColor: "transparent" }}
                      >
                        Total Interest
                      </p>
                      <p
                        className="text-lg md:text-xl font-semibold text-[#311664] "
                        style={{ backgroundColor: "transparent" }}
                      >
                        {formatCurrency(totalInterest)}
                      </p>
                    </div>
                    <div className="bg-white p-2 rounded-lg shadow">
                      <p
                        className="text-xs sm:text-sm md:text-base text-gray-600 mb-1"
                        style={{ backgroundColor: "transparent" }}
                      >
                        Total Payment
                      </p>
                      <p
                        className="text-lg md:text-xl font-semibold text-[#311664]"
                        style={{ backgroundColor: "transparent" }}
                      >
                        {formatCurrency(totalPayment)}
                      </p>
                    </div>
                  </div>
                  <div className="bg-white p-2 rounded-lg shadow">
                    <h3
                      className="text-sm md:text-base font-semibold text-[#311664] mb-1"
                      style={{ backgroundColor: "transparent" }}
                    >
                      Loan Breakdown
                    </h3>
                    <ResponsiveContainer
                      width="100%"
                      height={150}
                      className="md:h-[200px]"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <PieChart style={{ backgroundColor: "transparent" }}>
                        <Pie
                          data={chartData}
                          cx="50%"
                          cy="50%"
                          innerRadius={60}
                          outerRadius={80}
                          fill="#8884d8"
                          paddingAngle={5}
                          dataKey="value"
                          style={{ backgroundColor: "transparent" }}
                        >
                          {chartData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                              style={{ backgroundColor: "transparent" }}
                            />
                          ))}
                          <Label
                            value={`${(
                              (totalInterest / totalPayment) *
                              100
                            ).toFixed(1)}%`}
                            position="center"
                            fill="#311664"
                            style={{
                              fontSize: "24px",
                              fontWeight: "bold",
                              backgroundColor: "transparent",
                            }}
                          />
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                    <div
                      className="flex justify-between mt-2 "
                      style={{ backgroundColor: "transparent" }}
                    >
                      <div
                        className="flex items-center"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <div className="w-3 h-3 bg-[#311664] rounded-full mr-2"></div>
                        <span
                          className="text-sm md:text-base"
                          style={{ backgroundColor: "transparent" }}
                        >
                          Principal
                        </span>
                      </div>
                      <div
                        className="flex items-center"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <div className="w-3 h-3 bg-[#3a868b] rounded-full mr-2"></div>
                        <span
                          className="text-sm md:text-base"
                          style={{ backgroundColor: "transparent" }}
                        >
                          Interest
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="mt-8 text-center"
            style={{ backgroundColor: "transparent" }}
          >
            <p
              className="text-lg md:text-xl text-[#311664] mb-4"
              style={{ backgroundColor: "transparent", textAlign: "center" }}
            >
              Ready to find the best car loan rates?
            </p>
            <button
              className="bg-[#3a868b] hover:bg-[#59266d] text-white text-base sm:text-lg md:text-xl py-2 sm:py-3 md:py-4 px-6 sm:px-8 md:px-10 rounded-lg transition-colors duration-300"
              onClick={() => navigate("/loan-recommendation")}
            >
              Compare Car Loan Offers
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "./HomeLoanEligiblityCriteria";
import "./HomeLoanBalanceTransfer.css";
import { Helmet } from "react-helmet";
import MetadataContext from "../../MetadataContext";

const HomeLoanBalanceTransfer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Balance Transfer",
      pageDescription:
        "Transfer your existing loan balance to a lower interest rate and save on monthly payments. Take advantage of better terms and reduce your financial burden with ease.",
      pageKeywords:
        "home loan balance transfer, loan balance transfer, lower interest rate loan, home loan refinancing, reduce loan payments, financial burden relief, better loan terms, home loan transfer offers, refinance home loan, affordable home loans",
      canonicalUrl:
        "https://minemi.ai/loanpage/homeloan/home-loan-balance-transfer",
    });
  }, [setMetadata]);

  return (
    <>
      <Helmet>
        <title>Balance Transfer</title>
        <meta
          name="description"
          content="Transfer your existing loan balance to a lower interest rate and save on monthly payments. Take advantage of better terms and reduce your financial burden with ease."
        />
        <meta
          name="keywords"
          content="home loan balance transfer, loan balance transfer, lower interest rate loan, home loan refinancing, reduce loan payments, financial burden relief, better loan terms, home loan transfer offers, refinance home loan, affordable home loans"
        />
        {/* <link rel="icon" type="image/png" href="./mLogo.png" /> */}
      </Helmet>
      <div
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className="custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loans
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage/homeloan"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Home Loan
            </Link>{" "}
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              Balance Transfer
            </Link>{" "}
          </p>
        </div>
        <h1
          className="homeloan-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            lineHeight: "46px",

            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          Balance Transfer: A Smart Financial Move to Save on Interest Payments{" "}
        </h1>
        <p
          className="homeloan-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 18,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: "20px",
          }}
        >
          Transfer your existing loan balance to a lower interest rate and save
          on monthly payments. Take advantage of better terms and reduce your
          financial burden with ease.
        </p>
      </div>

      <div style={{ marginLeft: "4%", marginRight: "4%" }}>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Introduction:
        </div>

        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          A <strong>Balance Transfer</strong> is a process where you transfer
          the outstanding balance of a high-interest loan or credit card to
          another lender offering a lower interest rate. This can help you save
          significantly on interest payments, reduce your overall debt burden,
          and pay off your loans faster. Whether it's a{" "}
          <strong>credit card balance transfer</strong> or a{" "}
          <strong>personal loan balance transfer</strong>, this strategy can be
          a game-changer for your finances.
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          At Minemi, we provide comprehensive guidance to help you understand
          how a balance transfer works, the eligibility criteria, and the
          benefits it offers.
        </div>
        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          What is a Balance Transfer?
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          A Balance Transfer allows you to move the outstanding balance of an
          existing loan or credit card to another lender offering a lower
          interest rate. This is usually done to reduce the overall interest
          cost and shorten the loan tenure, making repayments more manageable.
        </div>
        <div>
          <div
            style={{
              marginTop: 20,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Types of Balance Transfers:
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Credit Card Balance Transfer:&nbsp;&nbsp;
                </span>
                Transfer outstanding credit card balances to another credit card
                with a lower interest rate, usually offered as an introductory
                offer for a limited period.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Personal Loan Balance Transfer:&nbsp;&nbsp;
                </span>
                Transfer the outstanding balance of an existing personal loan to
                another lender offering better terms, such as a lower interest
                rate or a longer tenure.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Home Loan Balance Transfer:&nbsp;&nbsp;
                </span>
                Transfer the outstanding balance of an existing home loan to
                another lender offering better terms, including a lower interest
                rate.
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Benefits of a Balance Transfer:
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1f1f1f", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Lower Interest Rates:&nbsp;&nbsp;
              </span>
              This is the most significant benefit, as it can drastically reduce
              the interest you pay on your debt.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1f1f1f", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Better Loan Terms:&nbsp;&nbsp;
              </span>
              Some lenders offer extended loan tenures or flexible repayment
              schedules.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1f1f1f", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                {" "}
                Debt Consolidation:&nbsp;&nbsp;
              </span>
              A balance transfer allows you to consolidate multiple debts into
              one loan, simplifying repayments.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1f1f1f", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Improved Credit Score:&nbsp;&nbsp;
              </span>
              Paying off debt faster with a lower interest rate can improve your
              credit score.
            </div>
          </div>
        </div>

        <div id="eligibility-criteria">
          <div
            className=" pb-2"
            style={{
              marginTop: 30,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Eligibility Criteria for Balance Transfer:
          </div>
          <Typography
            sx={{
              fontFamily: "'Archivo',sans-serif",
              fontSize: "16px",
              color: "#1F1F1F",
            }}
          >
            The eligibility for balance transfer may vary based on the type of
            loan or credit card and the lender's policies. Here's an overview of
            the common eligibility factors:
          </Typography>
        </div>

        <div
          className="increase-margin"
          style={{
            marginTop: "2%",
            border: "1px solid #846CA6",
            borderRadius: 18,
            fontSize: 16,
            width: "100%",
          }}
        >
          <table
            id="customers"
            style={{
              width: "100%",
              fontFamily: "Archivo",
              borderCollapse: "collapse",
              borderRadius: 18,
            }}
          >
            <thead style={{ borderRadius: 18 }}>
              <tr style={{ borderRadius: 18 }}>
                <th
                  className="table-padding"
                  style={{
                    backgroundColor: "#E7E4F9",
                    backgroundColor: "#E7E4F9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "6px",
                    textAlign: "left",
                    borderTopLeftRadius: 18,
                    color: "#59266D",
                  }}
                >
                  Eligibility Factor
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "6px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Minimum Requirement
                </th>
                <th
                  className="table-padding"
                  style={{
                    borderLeft: "1px solid #D9D9D9",
                    borderTopRightRadius: 18,
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    paddingLeft: "6px",
                    textAlign: "left",
                    backgroundColor: "#E7E4F9",
                    color: "#59266D",
                  }}
                >
                  Impact on Balance Transfer Eligibility{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Income
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Stable monthly income or salaried employment
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  A higher income increases loan eligibility and the amount that
                  can be transferred.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Credit Score
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  750 and above (for credit cards and loans)
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Higher credit scores improve chances of approval and may offer
                  better terms.
                </td>
              </tr>
              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Existing Debt
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  No major defaults, arrears, or late payments
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  A clean repayment history ensures better transfer terms and
                  approval.
                </td>
              </tr>

              <tr style={{ backgroundColor: "#f2f2f2" }}>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                  }}
                >
                  Age
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  21–60 years (may vary by lender)
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  The applicant’s age impacts the loan amount and repayment
                  tenure.
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: "#f2f2f2",
                  borderBottomLeftRadius: 24,
                  borderBottomRightRadius: 24,
                }}
              >
                <td
                  className="table-padding"
                  style={{
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                    border: "1px solid #ddd",
                    // borderBottomLeftRadius: 18,
                  }}
                >
                  Existing Loan Type
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Credit card, personal loan, or home loan
                </td>
                <td
                  className="table-padding"
                  style={{
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                    border: "1px solid #ddd",
                    // borderBottomRightRadius: 18,
                  }}
                >
                  Must be an existing loan or credit card with an outstanding
                  balance eligible for transfer.
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: "#f2f2f2",
                  borderBottomLeftRadius: 24,
                }}
              >
                <td
                  className="table-padding"
                  style={{
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 700,
                    // borderBottomLeftRadius: 24,
                    border: "1px solid #ddd",
                  }}
                >
                  Outstanding Loan Amount
                </td>
                <td
                  className="table-padding"
                  style={{
                    border: "1px solid #ddd",
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                    border: "1px solid #ddd",
                  }}
                >
                  Varies by lender, typically 75% of the loan value
                </td>
                <td
                  className="table-padding"
                  style={{
                    padding: "16px",
                    backgroundColor: "white",
                    fontFamily: "Archivo",
                    fontSize: 14,
                    fontWeight: 400,
                    // borderBottomRightRadius: 24,
                    border: "1px solid #ddd",
                  }}
                >
                  Higher outstanding amounts may lead to better balance transfer
                  options.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Process of Balance Transfer:{" "}
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Research and Compare:
              </span>
              Start by comparing interest rates, tenure options, and processing
              fees across lenders.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Check Eligibility:
              </span>
              Ensure you meet the eligibility requirements as per the lender's
              terms.
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Apply for the Transfer:
              </span>
              Complete the application form either online or in-person and
              provide necessary documentation such as proof of income, identity,
              and existing loan details.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Approval and Transfer:
              </span>
              Once approved, the new lender will pay off your existing loan, and
              you will start making payments to the new lender under the new
              terms.
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Important Considerations Before Opting for a Balance Transfer:{" "}
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Processing Fees:
              </span>
              Some lenders may charge a processing fee or balance transfer fee.
              Ensure that the savings from the lower interest rate outweigh
              these costs.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#8A8A8A", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Introductory Period:
              </span>
              Many lenders offer low interest rates for an initial period (e.g.,
              6–12 months). After the introductory period, the rate may
              increase, so be mindful of this.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Loan Tenure:
              </span>
              While a longer tenure may reduce monthly EMIs, it may also lead to
              higher total interest payments. Assess the impact of the tenure on
              your repayment plan.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Prepayment and Foreclosure Charges:
              </span>
              Check for any additional charges in case you want to close the
              loan early.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Impact on Credit Score:
              </span>
              A balance transfer may impact your credit score, especially if you
              miss payments or take too long to repay the transferred loan.
            </div>
          </div>
        </div>

        <div id="types-of-bt">
          <div id="eligibility-criteria">
            <div
              className=" pb-2 "
              style={{
                marginTop: 30,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Types of Balance Transfers Offered by Banks:{" "}
            </div>
          </div>

          <div
            id="bt-types-table"
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
              fontSize: 16,
              width: "100%",
            }}
          >
            <table
              id="customers"
              style={{
                width: "100%",
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                    }}
                  >
                    Lender
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Balance Transfer Type
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Interest Rate
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Processing Fees
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      borderTopRightRadius: 18,
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Tenure
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    HDFC Bank
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Credit Card, Personal Loan
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    11% – 14% p.a.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    1% – 3% of loan amount{" "}
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Up to 5 years
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    ICICI Bank
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Credit Card, Personal Loan
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    10.99% – 15% p.a.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    1% – 2% of loan amount
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Up to 3 years
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    SBI Bank
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Credit Card, Personal Loan
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    12% – 16% p.a.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    0.5% – 2% of loan amount
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Up to 5 years
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Axis Bank
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Credit Card, Personal Loan
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    13% – 16% p.a.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    1% – 2.5% of loan amount
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Up to 4 years
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Frequently Asked Questions (FAQs){" "}
        </div>
        <div
          className="accordion "
          style={{
            marginTop: 9,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
            defaultExpanded={true}
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                backgroundColor: "#F6F6F6",
                fontWeight: 700,
              }}
            >
              What is the minimum credit score required for a balance transfer?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Typically, a credit score of 750 and above is considered ideal for
              securing a balance transfer with better terms. However, some
              lenders may accept lower scores with higher interest rates.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Can I transfer a home loan balance to another lender?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, you can transfer the outstanding balance of your home loan to
              another lender offering better terms, such as a lower interest
              rate or extended tenure.
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              How long does it take for a balance transfer to be processed?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              The processing time for a balance transfer generally takes 7-14
              days, depending on the lender and the documentation provided.
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              What is a Home Loan Balance Transfer?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              <ul className="my-0 py-0">
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                  }}
                  className="pb-2 pt-0"
                >
                  <span style={{ fontWeight: 600 }}>
                    A Home Loan Balance Transfer
                  </span>{" "}
                  involves shifting your existing home loan from one lender
                  (like a bank or financial institution) to another.
                </li>
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                    fontWeight: 400,
                  }}
                  className="pb-2"
                >
                  <span style={{ fontWeight: 600 }}>The primary goal</span> is
                  typically to secure a lower interest rate, resulting in
                  reduced EMIs and overall lower interest payments.
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              When should I consider a Home Loan Balance Transfer?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              <ul className="my-0 py-0">
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                  }}
                  className="pb-2 pt-0"
                >
                  <span style={{ fontWeight: 600 }}>Lower Interest Rates:</span>{" "}
                  If you find a new lender offering significantly lower interest
                  rates than your current one.
                </li>
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                    fontWeight: 400,
                  }}
                  className="pb-2"
                >
                  <span style={{ fontWeight: 600 }}>Reduced EMIs:</span> A lower
                  interest rate can lead to lower EMIs, making your monthly
                  repayments more manageable. reduced EMIs and overall lower
                  interest payments.
                </li>
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                    fontWeight: 400,
                  }}
                  className="pb-2"
                >
                  <span style={{ fontWeight: 600 }}>Longer Tenure:</span> Some
                  lenders may offer longer loan tenures, which can reduce your
                  monthly EMIs but may increase the overall interest payable.
                </li>
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                    fontWeight: 400,
                  }}
                  className="pb-2"
                >
                  <span style={{ fontWeight: 600 }}>Top-up Loans:</span> Some
                  lenders may offer top-up loans along with the balance
                  transfer, allowing you to borrow additional funds for home
                  renovations or other purposes.
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              What are the costs associated with a Home Loan Balance Transfer?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              <ul className="my-0 py-0">
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                  }}
                  className="pb-2 pt-0"
                >
                  <span style={{ fontWeight: 600 }}>Processing Fees:</span> Your
                  existing lender may charge a foreclosure charge for
                  pre-closing your existing loan. transfer.
                </li>
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                    fontWeight: 400,
                  }}
                  className="pb-2"
                >
                  <span style={{ fontWeight: 600 }}>Stamp Duty:</span> is You
                  may need to pay stamp duty charges for the new loan agreement.
                </li>
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                    fontWeight: 400,
                  }}
                  className="pb-2"
                >
                  <span style={{ fontWeight: 600 }}>
                    Legal and Documentation Costs:
                  </span>{" "}
                  There may be costs associated with legal documentation and
                  property-related paperwork.
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              How does the Home Loan Balance Transfer process work?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              <ul className="my-0 py-0">
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                  }}
                  className="pb-2 pt-0"
                >
                  <span style={{ fontWeight: 600 }}>Research and Compare:</span>{" "}
                  Research lenders offering competitive interest rates and
                  compare their offers.
                </li>
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                    fontWeight: 400,
                  }}
                  className="pb-2"
                >
                  <span style={{ fontWeight: 600 }}>
                    Apply for a Balance Transfer:
                  </span>{" "}
                  Submit an application to the new lender with all the required
                  documents.
                </li>
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                    fontWeight: 400,
                  }}
                  className="pb-2"
                >
                  <span style={{ fontWeight: 600 }}>
                    Approval and Disbursement:
                  </span>{" "}
                  If your application is approved, the new lender will disburse
                  the loan amount to your existing lender to settle your
                  outstanding loan.
                </li>
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                    fontWeight: 400,
                  }}
                  className="pb-2"
                >
                  <span style={{ fontWeight: 600 }}>
                    Start Repaying the New Loan:
                  </span>{" "}
                  You will then start repaying the loan to the new lender
                  according to the agreed-upon terms.
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              Are there any eligibility criteria for a Home Loan Balance
              Transfer?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              <ul className="my-0 py-0">
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                  }}
                  className="pb-2 pt-0"
                >
                  <span style={{ fontWeight: 600 }}>Existing Home Loan:</span>{" "}
                  You must have an existing home loan with another lender.
                </li>
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                    fontWeight: 400,
                  }}
                  className="pb-2"
                >
                  <span style={{ fontWeight: 600 }}>Credit History:</span> A
                  good credit history is essential for loan approval.
                </li>
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                    fontWeight: 400,
                  }}
                  className="pb-2"
                >
                  <span style={{ fontWeight: 600 }}>Income Stability:</span> You
                  must have a stable income to ensure timely repayments.
                </li>
                <li
                  style={{
                    // fontSize: 16,
                    lineHeight: "20px",
                    fontFamily: "Archivo",
                    fontWeight: 400,
                  }}
                  className="pb-2"
                >
                  <span style={{ fontWeight: 600 }}>Loan Age:</span> There may
                  be minimum requirements for the age of your existing loan
                  (e.g., at least 12-18 months).
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </div>
        <div
          className="increase-margines"
          style={{
            backgroundColor: "#E7E4F9",
            borderRadius: 24,
            padding: 40,
            marginTop: 40,
            marginBottom: 40,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              backgroundColor: "transparent",
            }}
          >
            <p
              className="minutes"
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 32,
                fontWeight: 600,
              }}
            >
              Check Your Eligibility in Minutes!
            </p>
            <p
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 18,
                fontWeight: 400,
                lineHeight: 1.2,
              }}
            >
              Get personalized loan options based on your income, current EMI,
              and credit score. Quick, easy,
              <br className="display-none" /> and tailored just for you—apply
              now to see what you qualify for!
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                marginTop: 30,
                display: "flex",
                flexDirection: "row",
                gap: 24,
              }}
            >
              <a
                href="https://minemi.ai/home-loan/check-eligibility"
                style={{
                  backgroundColor: "#59266D",
                  color: "white",
                  borderRadius: 8,
                  padding: 10,
                  paddingLeft: 50,
                  paddingRight: 50,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 16,
                }}
              >
                Check Eligiblity Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeLoanBalanceTransfer;

"use client";

import { useState } from "react";
import { ChevronDown, Info, Star } from "lucide-react";
import bajaj from "../../../Assets/Images/bajaj-housing-finance-square.webp";
import aditya from "../../../Assets/Images/Aditya-housing-square.webp";
import piramal from "../../../Assets/Images/PiramalFinanceSquare.webp";
import hdfc from "../../../Assets/Images/HDFCSquare.webp";
import sbi from "../../../Assets/Images/SBI-Home-Loan.webp";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

function TopPick({ category, lender }) {
  const [openSection, setOpenSection] = useState(null);
  const isMediumScreen = useMediaQuery("(max-width:768px)");

  const navigate = useNavigate();

  const toggleSection = (section) => {
    if (openSection === section) {
      setOpenSection(null);
    } else {
      setOpenSection(section);
    }
  };

  return (
    <div
      className={`mb-8 overflow-hidden rounded-lg bg-white shadow-md`}
      style={{
        border:
          lender.featured === true ? "2px solid #59266d" : "2px solid gray",
      }}
    >
      {/* Header */}
      <div
        className={`border-b ${
          lender.featured ? "bg-[#ebe8fc]" : "bg-[#ebe8fc]/30"
        } p-3 flex justify-between items-center`}
      >
        <h3 className="text-lg font-bold text-[#311664] m-0">
          Best for {category}
        </h3>
        {lender.featured && (
          <span className="bg-[#59266d] text-white text-xs px-2 py-1 rounded-full">
            Featured
          </span>
        )}
      </div>

      {/* Main content */}
      <div className="p-3 ">
        <div className="flex flex-col items-start gap-6 md:flex-row md:items-center">
          {/* Logo and name */}
          <div className="home_loan_topPicks_imges flex items-center">
            <div className="mr-4 h-16 w-16 overflow-hidden rounded-lg border">
              <img
                src={lender.img}
                alt={lender.name}
                width={64}
                height={64}
                className="object-cover"
              />
            </div>
            <div>
              <div className="text-xl font-bold text-[#311664]">
                {lender.name}
              </div>
              <div className="flex items-center">
                <span className="mr-1 font-medium">{lender.rating}</span>
                <div className="flex">
                  {Array(5)
                    .fill(0)
                    .map((_, i) => (
                      <Star
                        key={i}
                        className={`h-4 w-4 ${
                          i < Math.floor(lender.rating)
                            ? "fill-[#59266d] text-[#59266d]"
                            : "text-gray-300"
                        }`}
                      />
                    ))}
                </div>
                <span className="ml-1 text-xs text-gray-500">/5</span>
              </div>
            </div>
          </div>

          {/* Loan details */}
          <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3">
            <div>
              <div className="flex items-center text-sm text-gray-500">
                Interest Rate
                <Info className="ml-1 h-3 w-3" />
              </div>
              <div className="font-medium">{lender.rate}</div>
            </div>
            <div>
              <div className="text-sm text-gray-500">Max LTV</div>
              <div className="font-medium">{lender.ltv}</div>
            </div>
            <div>
              <div className="flex items-center text-sm text-gray-500">
                Max Tenure
                <Info className="ml-1 h-3 w-3" />
              </div>
              <div className="font-medium">{lender.tenure}</div>
            </div>
          </div>

          {/* CTA button */}
          <div className="mt-2 w-full md:mt-0 md:w-auto">
            <button
              className={`w-full rounded-md ${
                lender.featured ? "bg-[#3a868b]" : "bg-[#59266d]"
              } px-6 py-2 text-md font-medium text-white hover:opacity-90 md:w-auto`}
              style={{
                width: isMediumScreen ? "100%" : "7rem",
                height: isMediumScreen ? "3.3rem" : "auto",
              }}
              onClick={() => {
                window.location.href = lender.link;
              }}
            >
              Check Eligibility
            </button>
          </div>
        </div>

        {/* Expandable sections */}
        <div className="mt-4 border-t pt-3">
          <div className="grid gap-2 d-flex flex-column">
            {/* Details and qualifications */}
            <div className="rounded-md border">
              <button
                className="flex w-full items-center justify-between p-3 text-left font-medium text-[#311664] hover:bg-[#ebe8fc]/30"
                onClick={() => toggleSection("details")}
                aria-expanded={openSection === "details"}
              >
                Details and qualifications
                <ChevronDown
                  className={`h-5 w-5 transition-transform ${
                    openSection === "details" ? "rotate-180" : ""
                  }`}
                />
              </button>
              {openSection === "details" && (
                <div className="border-t p-3">
                  <ul className="list-inside list-disc space-y-2 text-sm">
                    {lender.details.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* Pros and cons */}
            <div className="rounded-md border">
              <button
                className="flex w-full items-center justify-between p-3 text-left font-medium text-[#311664] hover:bg-[#ebe8fc]/30"
                onClick={() => toggleSection("pros-cons")}
                aria-expanded={openSection === "pros-cons"}
              >
                Pros & cons
                <ChevronDown
                  className={`h-5 w-5 transition-transform ${
                    openSection === "pros-cons" ? "rotate-180" : ""
                  }`}
                />
              </button>
              {openSection === "pros-cons" && (
                <div className="border-t p-3">
                  <div className="grid gap-4 md:grid-cols-2">
                    <div>
                      <h6 className="mb-2 font-medium text-[#311664]">Pros</h6>
                      <ul className="list-inside list-disc space-y-1 text-sm">
                        {lender.pros.map((pro, index) => (
                          <li key={index}>{pro}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h6 className="mb-2 font-medium text-[#311664]">Cons</h6>
                      <ul className="list-inside list-disc space-y-1 text-sm">
                        {lender.cons.map((con, index) => (
                          <li key={index}>{con}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Disclaimer */}
            <div className="rounded-md border">
              <button
                className="flex w-full items-center justify-between p-3 text-left font-medium text-[#311664] hover:bg-[#ebe8fc]/30"
                onClick={() => toggleSection("disclaimer")}
                aria-expanded={openSection === "disclaimer"}
              >
                Disclaimer
                <ChevronDown
                  className={`h-5 w-5 transition-transform ${
                    openSection === "disclaimer" ? "rotate-180" : ""
                  }`}
                />
              </button>
              {openSection === "disclaimer" && (
                <div className="border-t p-3">
                  <p className="text-sm text-gray-600">{lender.disclaimer}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function TopPicksSection() {
  const topPicks = [
    {
      category: "Salaried Professionals",
      lender: {
        name: "Bajaj Housing Finance",
        img: bajaj,
        logo: "bajaj",
        rating: 5.0,
        rate: "8.50-9.50%",
        ltv: "Up to 90%",
        tenure: "Up to 30 years",
        processingFee: "0.50-1.00%",
        featured: true,
        link: "https://minemi.ai/home-loan/check-eligibility/",
        details: [
          "Minimum income: ₹30,000 per month",
          "Property should be in approved projects",
          "Age: 23-58 years (at the time of loan maturity)",
          "Processing fee: Up to 1.00% of loan amount",
          "Prepayment charges: Nil for floating rate loans",
        ],
        pros: [
          "Competitive interest rates",
          "Quick processing and disbursement",
          "Minimal documentation for salaried individuals",
          "No prepayment penalties on floating rate loans",
          "Special offers for women borrowers",
        ],
        cons: [
          "Higher processing fees compared to some banks",
          "Limited flexibility in choosing property",
          "Stricter eligibility criteria for self-employed",
        ],
        disclaimer:
          "Rates, fees, and terms are subject to change without notice. Bajaj Housing Finance home loans are subject to credit approval. Your actual rate, payment, and costs could be higher. Minimum loan amounts may vary by location.",
      },
    },
    {
      category: "Self-Employed Individuals",
      lender: {
        name: "Aditya Birla Housing Finance",
        img: aditya,
        logo: "aditya-birla",
        rating: 4.5,
        rate: "8.75-10.25%",
        ltv: "Up to 80%",
        tenure: "Up to 30 years",
        processingFee: "0.50-1.00%",
        featured: true,
        link: "https://minemi.ai/home-loan/check-eligibility/",
        details: [
          "Minimum business vintage: 3 years",
          "ITR for last 3 years required",
          "Age: 25-65 years (at the time of loan maturity)",
          "Processing fee: Up to 1.00% of loan amount",
          "Prepayment charges: Nil for floating rate loans",
        ],
        pros: [
          "Specialized loans for self-employed professionals",
          "Accepts business income for eligibility",
          "Flexible documentation requirements",
          "Balance transfer facility available",
          "Doorstep service available",
        ],
        cons: [
          "Higher interest rates compared to salaried individuals",
          "Requires minimum 3 years of business vintage",
          "Higher processing fees",
        ],
        disclaimer:
          "Rates, fees, and terms are subject to change without notice. Aditya Birla Housing Finance home loans are subject to credit approval. Your actual rate, payment, and costs could be higher. Minimum loan amounts may vary by location.",
      },
    },
    {
      category: "Affordable Housing",
      lender: {
        name: "Piramal Capital Housing Finance",
        img: piramal,
        logo: "piramal",
        rating: 4.5,
        rate: "8.65-10.50%",
        ltv: "Up to 90%",
        tenure: "Up to 25 years",
        processingFee: "0.50-1.00%",
        featured: true,
        link: "https://minemi.ai/home-loan/check-eligibility/",
        details: [
          "Specialized in affordable housing segment",
          "Minimum income: ₹20,000 per month",
          "Age: 21-65 years (at the time of loan maturity)",
          "Processing fee: Up to 1.00% of loan amount",
          "Prepayment charges: Nil for floating rate loans",
        ],
        pros: [
          "Focus on affordable housing segment",
          "Lower income eligibility criteria",
          "Pradhan Mantri Awas Yojana (PMAY) subsidy facilitation",
          "Doorstep service in select locations",
          "Flexible repayment options",
        ],
        cons: [
          "Limited presence in premium housing segment",
          "Higher interest rates for certain property types",
          "Processing time can be longer in some cases",
        ],
        disclaimer:
          "Rates, fees, and terms are subject to change without notice. Piramal Capital Housing Finance home loans are subject to credit approval. Your actual rate, payment, and costs could be higher. Minimum loan amounts may vary by location.",
      },
    },
    {
      category: "Balance Transfer",
      lender: {
        name: "HDFC Ltd",
        img: hdfc,
        logo: "hdfc",
        rating: 5.0,
        rate: "8.50-9.25%",
        ltv: "Up to 90%",
        tenure: "Up to 30 years",
        processingFee: "0.50%",
        link: "https://minemi.ai/home-loan/check-eligibility/",
        details: [
          "Existing home loan with minimum 12 EMIs paid",
          "Minimum income: ₹25,000 per month",
          "Age: 21-65 years (at the time of loan maturity)",
          "Processing fee: 0.50% of loan amount",
          "Top-up loan facility available",
        ],
        pros: [
          "Competitive interest rates for balance transfer",
          "Additional top-up loan facility",
          "Minimal documentation for existing borrowers",
          "No prepayment penalties",
          "Doorstep service available",
        ],
        cons: [
          "Processing time can be longer",
          "Requires good repayment history on existing loan",
          "May require property revaluation",
        ],
        disclaimer:
          "Rates, fees, and terms are subject to change without notice. HDFC Ltd home loans are subject to credit approval. Your actual rate, payment, and costs could be higher. Minimum loan amounts may vary by location.",
      },
    },
    {
      category: "Government Employees",
      lender: {
        name: "SBI Home Loans",
        img: sbi,
        logo: "sbi",
        rating: 4.5,
        rate: "8.40-9.15%",
        ltv: "Up to 90%",
        tenure: "Up to 30 years",
        processingFee: "0.35%",
        link: "https://minemi.ai/home-loan/check-eligibility/",
        details: [
          "Special schemes for government employees",
          "Minimum income: As per grade pay",
          "Age: 21-60 years (at the time of loan maturity)",
          "Processing fee: 0.35% of loan amount",
          "Prepayment charges: Nil",
        ],
        pros: [
          "Lower interest rates for government employees",
          "Lower processing fees",
          "Higher loan eligibility based on job security",
          "Special concessions for defense personnel",
          "Extensive branch network",
        ],
        cons: [
          "Processing time can be longer",
          "Documentation process can be cumbersome",
          "Limited flexibility in choosing property in some cases",
        ],
        disclaimer:
          "Rates, fees, and terms are subject to change without notice. SBI home loans are subject to credit approval. Your actual rate, payment, and costs could be higher. Minimum loan amounts may vary by location.",
      },
    },
  ];

  return (
    <section className="py-16">
      <div className=" mx-auto ">
        <h2 className="mb-8 text-center text-3xl font-bold text-[#311664]">
          Top Picks for Home Loans
        </h2>
        <p className="mb-12 px-2 text-center text-[#26292b]">
          We've analyzed dozens of home loan options to bring you the best
          lenders for different needs.
        </p>

        <div className="space-y-6 px-3">
          {topPicks.map((pick, index) => (
            <TopPick
              key={index}
              category={pick.category}
              lender={pick.lender}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

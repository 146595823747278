import React from "react";
import Serve from "./Serve/Serve.jsx";
import Types from "./Types/Types.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CibilScoreEligibility from "./CibilScoreEligibility/CibilScoreEligibility.jsx";

const CibilScoreForm = () => {
  return (
    <>

      <CibilScoreEligibility />
      <Serve />
      <Types />
      <ToastContainer />
    </>
  );
};

export default CibilScoreForm;

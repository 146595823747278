import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import { FaAngleDown, FaChevronUp } from "react-icons/fa6";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import tongueOut from "../../../Assets/Images/tongue-out.png";
import MetadataContext from "../../MetadataContext";

const CarLoanSBIeligibility = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const criteriaData = [
    {
      factor: "Age",
      requirement: "Minimum: 21 years; Maximum: 65 years (at loan maturity)",
      impact: "Younger applicants may need stable income proof.",
    },
    {
      factor: "Income",
      requirement: "Salaried: Minimum ₹2.5 lakh p.a.",
      impact: "Higher income ensures a higher loan sanction.",
    },
    {
      factor: "Employment Type",
      requirement: "Salaried, Self-Employed, or Professionals",
      impact: "Stable employment boosts approval chances.",
    },
    {
      factor: "Loan Tenure",
      requirement: "Up to 7 years",
      impact: "Longer tenure reduces EMI but increases interest cost.",
    },
    {
      factor: "Credit Score",
      requirement: "Minimum 700 (preferred)",
      impact: "Higher scores fetch better interest rates.",
    },
    {
      factor: "Down Payment",
      requirement: "Typically 10-15% of the car’s on-road price",
      impact: "A larger down payment reduces loan-to-value ratio.",
    },
    {
      factor: "Car Type",
      requirement: "New or Used cars (up to 5 years old)",
      impact: "Older cars may affect tenure and loan amount.",
    },
  ];

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "SBI Car Loan Eligibility",
      pageDescription:
        "SBI provides competitive car financing rates and flexible tenures. Check your eligibility to start owning your dream car.",
      pageKeywords:
        "SBI car loan eligibility, SBI car loan eligibility menemi, SBI car loan, car financing SBI, competitive car loan rates, SBI car loan offers, car loan eligibility, SBI vehicle loan, car loan tenures, dream car financing, SBI loan qualification",
      canonicalUrl:
        "https://minemi.ai/loanpage/carloan/car-loan-eligibility-criteria/sbi-car-loan-eligibility",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className="custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/carloan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Car Loan
              </Link>{" "}
              &nbsp;
              <span
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/carloan/car-loan-eligibility-criteria"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Car Loan Check Eligibility
              </Link>{" "}
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                SBI Car Loan Eligibility
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            SBI Car Loan Eligibility: Your Path to Affordable Car Financing
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: 1,
            }}
          >
            State Bank of India (SBI) is a trusted name in car financing,
            offering competitive rates, flexible tenures, and borrower-friendly
            eligibility criteria. Discover if you qualify and take the first
            step toward owning your dream car.
          </p>
        </div>
        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
          <h2
            style={{
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 28,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Eligibility Criteria for SBI Car Loans
          </h2>
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 16,
              color: "#1F1F1F",
              marginTop: 8,
            }}
          >
            SBI caters to salaried, self-employed, and professional individuals
            with straightforward eligibility requirements.
          </p>
          <div
            style={{
              border: "1px solid #D9D9D9",
              borderRadius: 24,
              marginTop: 24,
              backgroundColor: "white",
              marginBottom: "2%",
              maxWidth: "100%",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
              }}
            >
              <div
                style={{
                  backgroundColor: "#E7E4F9",
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#59266D",
                  padding: "16px",
                  borderBottom: "1px solid #D9D9D9",
                  borderTopLeftRadius: 24,
                  borderTopRightRadius: 24,
                  textAlign: "center",
                }}
              >
                SBI Loan Eligibility Criteria
              </div>
            </div>

            <div
              style={{
                maxWidth: "100%",
                overflowX: "auto",
                backgroundColor: "white",
              }}
            >
              <table
                className="table-class"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 12,
                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  textAlign: "left",
                }}
              >
                <thead className="thead-class">
                  <tr>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        color: "#59266D",
                        width: "30%",
                      }}
                    >
                      Eligibility Parameter
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Requirement
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Impact on Loan Eligibility
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {criteriaData.map((row, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      }}
                    >
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        {row.factor}
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {row.requirement}
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {row.impact}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Exclusive Features of SBI Car Loans
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  High Loan-to-Value Ratio:{" "}
                </span>
                Financing of up to 90% of the car’s on-road price for new cars.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Attractive Interest Rates:{" "}
                </span>
                Starting as low as 8.85% per annum for eligible borrowers.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Special Rates for Women:{" "}
                </span>
                Preferential interest rates for female applicants.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Loans for Used Cars:{" "}
                </span>
                Flexible terms for used cars up to 5 years old.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Pre-approved Offers:{" "}
                </span>
                Instant loans for eligible SBI account holders.
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            How to Check Your SBI Car Loan Eligibility Online
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            With SBI, checking your car loan eligibility is fast and
            straightforward:
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                Visit the Car Loans Section on SBI’s official website.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                Click on the Eligibility Calculator tool.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                Enter details such as income, loan tenure, car type, and desired
                loan amount.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                Instantly receive your eligibility status and a loan offer
                tailored to your needs.
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            SBI Car Loan Eligibility by Applicant Type
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Salaried Applicants{" "}
                </span>
                <ul style={{ listStyle: "disc" }} className="mt-2">
                  <li className="pb-2">
                    <strong>Employment Continuity: </strong>Minimum of 1 year
                    with the current employer.
                  </li>
                  <li className="">
                    <strong>Income Proof:</strong>Last 3 months’ salary slips
                    and bank statements.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Self-Employed Applicants{" "}
                </span>
                <ul style={{ listStyle: "disc" }} className="mt-2">
                  <li className="pb-2">
                    <strong>Business Vintage: </strong>Minimum of 3 years in the
                    current business.
                  </li>
                  <li className="">
                    <strong>Income Proof: </strong>Last 2 years’ IT returns and
                    bank statements.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Professionals{" "}
                </span>
                <ul style={{ listStyle: "disc" }} className="mt-2">
                  <li className="pb-2">
                    <strong>Eligibility:</strong>Practicing doctors, CAs,
                    architects, and other professionals.
                  </li>
                  <li className="">
                    <strong> Proof: </strong>Professional qualification
                    certificates and stable income proof.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* table data */}
          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Documents Required for SBI Car Loan Eligibility
          </div>
          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                    }}
                  >
                    Document Type
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Examples
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Identity Proof
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Aadhar Card, PAN Card, Passport
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Address Proof
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    {" "}
                    Utility Bills, Passport, Rent Agreement
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Income Proof
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Salary Slips, IT Returns, Bank Statements
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Vehicle-Related Docs
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Proforma Invoice, Registration Certificate (for used cars)
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Photographs
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Passport-sized photos
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            How to Improve Your SBI Car Loan Eligibility
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Increase Your Credit Score:{" "}
                </span>
                A score above 750 significantly improves approval chances.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Provide a Higher Down Payment:{" "}
                </span>
                Reduces your loan requirement and increases your eligibility.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Add a Co-Applicant:{" "}
                </span>
                Joint applications with a strong financial profile boost loan
                approval rates.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Choose a Shorter Tenure:{" "}
                </span>
                Reduces the overall interest burden and enhances approval
                chances.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Ensure Stable Income Proof:{" "}
                </span>
                Consistent income documentation reassures the bank.
              </div>
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 25,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              SBI Car Loan FOIR-Based Eligibility
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  SBI uses the{" "}
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Fixed Obligation to Income Ratio (FOIR)
                  </span>{" "}
                  method to assess your repayment capacity.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Example Calculation:{" "}
                  </span>
                  <ul style={{ listStyle: "disc" }} className="mt-2">
                    <li className="pb-2">
                      <strong>Net Monthly Income: </strong>
                      ₹60,000
                    </li>
                    <li className="pb-2">
                      <strong>Existing EMIs: </strong>
                      ₹15,000
                    </li>
                    <li className="pb-2">
                      <strong>FOIR Limit: </strong>
                      50% of income
                    </li>
                    <li className="pb-2">
                      <strong>Maximum EMI Eligibility: </strong>
                      ₹60,000 × 50% = ₹30,000
                    </li>
                    <li className="">
                      <strong>
                        Car Loan Amount (at 8.75% interest for 5 years):{" "}
                      </strong>
                      ₹13.9 lakhs
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip section */}

          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div
                className="px-1 px-sm-3 py-2"
                style={{ backgroundColor: "#e7e4f9" }}
              >
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    "Check your eligibility and pre-qualify for an SBI car loan
                    without impacting your credit score. Use MinEmi’s expert
                    tools to compare and optimize your car loan options."
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          {/* table data */}
          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Why Choose SBI for Your Car Loan?
          </div>
          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                    }}
                  >
                    Feature
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Loan Amount
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Up to ₹1 crore for new cars.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Interest Rates
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    {" "}
                    Starting from 8.85% per annum.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Processing Fees
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    0.25% of the loan amount (minimum ₹500).
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Prepayment/Foreclosure
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Permitted after 6 months (nominal charges apply).
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                    borderBottomRightRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      borderBottomLeftRadius: 18,
                    }}
                  >
                    Loan Tenure
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Flexible tenure from 1 to 7 years.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Frequently Asked Questions (FAQs) on HDFC Bank Personal Loan
            Eligibility{" "}
          </div>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              backgroundColor: "#F6F6F6",
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              style={{
                border: "1px solid #C8C8C8",
                background: "#FFFFFF",
                overflow: "hidden",
                borderRadius: 12,
              }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What is the minimum credit score required for an SBI car loan?
              </AccordionSummary>
              <AccordionDetails>
                A score of 700 or higher is recommended for favorable terms.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                background: "#FFFFFF",
                overflow: "hidden",
                borderRadius: 12,
              }}
            >
              <AccordionSummary
                style={{ borderRadius: 12 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Can I get an SBI car loan for a used car?
              </AccordionSummary>
              <AccordionDetails>
                Yes, loans are available for used cars up to 5 years old with
                attractive terms.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                background: "#FFFFFF",
                overflow: "hidden",
                borderRadius: 12,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{ borderRadius: 12 }}
              >
                Does SBI offer special car loan schemes for women?
              </AccordionSummary>
              <AccordionDetails>
                Yes, female applicants can benefit from lower interest rates.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                background: "#FFFFFF",
                overflow: "hidden",
                borderRadius: 12,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{ borderRadius: 12 }}
              >
                How can I improve my chances of approval for an SBI car loan?
              </AccordionSummary>
              <AccordionDetails>
                Maintain a high credit score, stable income, and a healthy FOIR
                ratio.
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br /> and tailored just for you—apply now to see what you
                qualify for!
              </p>
              <a
                href="https://minemi.ai/car-loan/check-eligibility"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                  backgroundColor: "transparent",
                  marginTop: "2%",
                  cursor: "pointer",
                }}
              >
                <div
                  className="buttom-loan-eligiblity-one"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 40,
                    paddingRight: 40,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                   Check Your Eligibility Now
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarLoanSBIeligibility;

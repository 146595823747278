import { useState, useEffect } from "react";
import HowMinemiWorks from "./HowMinemiWorks";
import { useNavigate } from "react-router-dom";

const styles = {
  section: {
    background: "linear-gradient(to bottom, #ebe8fc, #ffffff)",
    padding: "16px",
  },
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    backgroundColor: "transparent",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: "24px",
    backgroundColor: "transparent",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#311664",
    marginBottom: "16px",
    textAlign: "center",

    backgroundColor: "transparent",
  },
  subheading: {
    backgroundColor: "rgba(255,255,255,0.5)",
    border: "1px solid #311664",
    padding: "16px",
    marginBottom: "24px",
    borderRadius: "8px",
  },
  featureBox: {
    backgroundColor: "white",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    marginBottom: "24px",
  },
  featureItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    backgroundColor: "transparent",
  },
  checkIcon: {
    color: "green",
    marginRight: "8px",
    backgroundColor: "transparent",
  },
  button: {
    backgroundColor: "#3a868b",
    color: "white",
    border: "none",
    borderRadius: "9999px",
    padding: "12px 24px",
    fontSize: "18px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  loanComparisonBox: {
    backgroundColor: "white",
    border: "4px solid #311664",
    borderRadius: "8px",
    padding: "24px",
    position: "relative",
    overflow: "hidden",
  },
  loanComparisonHeader: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "8px",
    backgroundColor: "#3a868b",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "16px",
    border: "1px solid #311664",
    borderRadius: "4px",
    backgroundColor: "transparent",
  },
};

export default function HeroSection({ scrollToFilter }) {
  const [loanAmount, setLoanAmount] = useState("500000");
  const [isDesktop, setIsDesktop] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 1024);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatCurrency = (value) => {
    const number = Number.parseInt(value.replace(/[^0-9]/g, ""));
    return isNaN(number)
      ? ""
      : number.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        });
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setLoanAmount(value);
  };

  return (
    <>
      <section style={styles.section}>
        <div style={styles.container}>
          <div
            style={{
              ...styles.grid,
              gridTemplateColumns: isDesktop ? "2fr 1fr" : "1fr",
            }}
          >
            {/* Left Column */}
            <div style={{ backgroundColor: "transparent" }}>
              <h1
                style={{
                  ...styles.heading,
                  fontSize: isDesktop ? "48px" : "24px",
                  textAlign: isDesktop ? "left" : "center",
                  lineHeight: 1.3,
                  backgroundColor: "transparent",
                }}
              >
                Compare the Best Personal Loan Offers of 2025 – Get the Lowest
                Rates in Minutes!
              </h1>
              <div style={styles.subheading}>
                <p style={{ backgroundColor: "transparent" }}>
                  Find the perfect personal loan for your needs. Compare
                  real-time offers from India's top banks & NBFCs, get AI-driven
                  recommendations, and secure the best deal—100% free & with no
                  impact on your credit score!
                </p>
              </div>

              <div
                style={{ position: "relative", backgroundColor: "transparent" }}
              >
                <div
                  style={{
                    ...styles.featureBox,
                    position: isDesktop ? "absolute" : "static",
                    zIndex: 1,
                    maxWidth: "300px",
                  }}
                >
                  <div style={styles.featureItem}>
                    <span style={styles.checkIcon}>✓</span>
                    <span style={{ backgroundColor: "transparent" }}>
                      Verified Offers
                    </span>
                  </div>
                  <div style={styles.featureItem}>
                    <span style={styles.checkIcon}>✓</span>
                    <span style={{ backgroundColor: "transparent" }}>
                      Secure & Transparent
                    </span>
                  </div>
                  <div style={styles.featureItem}>
                    <span style={styles.checkIcon}>✓</span>
                    <span style={{ backgroundColor: "transparent" }}>
                      4.8/5 Customer Satisfaction
                    </span>
                  </div>
                </div>
                {isDesktop && (
                  <div
                    style={{
                      position: "relative",
                      width: "500px",
                      height: "400px",
                      margin: "0 auto",
                      marginRight: "80px",
                      backgroundColor: "transparent",
                    }}
                  >
                    <img
                      src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/10252702-removebg-preview-Yl0E2Q5t60YgcSedM0bl92R9tO0G2j.png"
                      alt="Illustration of two people collaboratively adding hearts to a glass jar, representing collective financial growth and community support"
                      style={{
                        objectFit: "contain",
                        backgroundColor: "transparent",
                      }}
                      priority
                    />
                  </div>
                )}
              </div>

              <div
                style={{
                  textAlign: "center",
                  marginTop: "24px",
                  backgroundColor: "transparent",
                }}
              >
                <button
                  style={styles.button}
                  onClick={scrollToFilter}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "#59266d")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "#3a868b")
                  }
                >
                  Compare Loans Now
                </button>
              </div>
            </div>

            {/* Right Column - Loan Rate Comparison Section */}
            <div style={styles.loanComparisonBox}>
              <div style={styles.loanComparisonHeader}></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "16px",
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/IMG_1304-VulJed4sxlVHeoxGkWSlqfZI6iqxpa.png"
                  alt="Rupee Card"
                  width={100}
                  height={50}
                  style={{
                    transform: "rotate(-12deg)",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
              <h2
                style={{
                  ...styles.heading,
                  fontSize: "24px",
                  backgroundColor: "transparent",
                  lineHeight: 1.5,
                }}
              >
                Compare Loan Rates in India – Get the Best Offer in 2 Minutes!
              </h2>
              <div
                style={{ marginBottom: "24px", backgroundColor: "transparent" }}
              >
                <div style={styles.featureItem}>
                  <span style={styles.checkIcon}>✓</span>
                  <span style={{ backgroundColor: "transparent" }}>
                    Check rates from top Indian banks & NBFCs
                  </span>
                </div>
                <div style={styles.featureItem}>
                  <span style={styles.checkIcon}>✓</span>
                  <span style={{ backgroundColor: "transparent" }}>
                    Explore loans up to ₹50 Lakhs
                  </span>
                </div>
                <div style={styles.featureItem}>
                  <span style={styles.checkIcon}>✓</span>
                  <span style={{ backgroundColor: "transparent" }}>
                    No impact on your CIBIL score
                  </span>
                </div>
              </div>
              <div style={{ backgroundColor: "transparent" }}>
                <input
                  type="text"
                  value={formatCurrency(loanAmount)}
                  onChange={handleInputChange}
                  style={styles.input}
                  placeholder="Enter Loan Amount"
                />
                <button
                  onClick={() => navigate("/loan-recommendation")}
                  style={{ ...styles.button, width: "100%" }}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "#59266d")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "#3a868b")
                  }
                >
                  Check Rates
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <HowMinemiWorks />
    </>
  );
}

import { CheckCircle } from "lucide-react";
import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

export function MinemiTools({ name }) {
  const [data, setData] = useState({});

  const kotakData = useMemo(
    () => ({
      title: "MinEMI's Kotak Bank Loan Success Tools",
      description:
        "Specialized tools to maximize your Kotak Bank loan approval chances",
      tools: [
        {
          name: "Kotak Bank Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your profile against Kotak Bank's specific approval criteria to predict your chances and suggest improvements.",
          features: [
            "Analyzes 20+ Kotak Bank-specific approval factors",
            "Predicts approval probability with 90% accuracy for Kotak Bank",
            "Provides personalized action plan to boost approval chances",
          ],
          link: {
            text: "Analyze Your Kotak Bank Approval Odds",
            url: "#loan-predictor",
          },
        },
        {
          name: "Kotak Bank Rate Optimizer",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Get the best possible interest rate on your Kotak Bank personal loan by optimizing your application based on the bank's rate assignment algorithm.",
          features: [
            "Identifies factors that influence Kotak Bank's rate decisions",
            "Suggests optimal loan amount and tenure for your profile",
            "Shows how to potentially save thousands in interest costs",
          ],
          link: {
            text: "Optimize Your Kotak Bank Interest Rate",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Expert Kotak Bank Guidance?",
        description:
          "Our loan specialists have deep expertise with Kotak Bank's personal loan process and can help you navigate every step for maximum success.",
        link: {
          text: "Book a Free Kotak Bank Loan Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Loan Tools",
      description:
        "Maximize your loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "MinEMI Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-transparent"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and predicts your loan approval chances before you apply.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "MinEMI Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-transparent"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "SEstimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our loan experts can help you navigate the Bank of Baroda personal loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const idfcData = useMemo(
    () => ({
      title: "MinEMI's Aditya Birla Finance Loan Success Tools",
      description:
        "Specialized tools to maximize your Aditya Birla Finance loan approval chances",
      tools: [
        {
          name: "Aditya Birla Finance Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your profile against Aditya Birla Finance's specific approval criteria to predict your chances and suggest improvements.",
          features: [
            "Analyzes 20+ Aditya Birla Finance-specific approval factors",
            "Predicts approval probability with 90% accuracy for Aditya Birla Finance",
            "Provides personalized action plan to boost approval chances",
          ],
          link: {
            text: "Analyze Your Aditya Birla Finance Approval Odds",
            url: "#loan-predictor",
          },
        },
        {
          name: "Aditya Birla Finance Rate Optimizer",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Get the best possible interest rate on your Aditya Birla Finance personal loan by optimizing your application based on the company's rate assignment algorithm.",
          features: [
            "Identifies factors that influence Aditya Birla Finance's rate decisions",
            "Suggests optimal loan amount and tenure for your profile",
            "Shows how to potentially save thousands in interest costs",
          ],
          link: {
            text: "Optimize Your Aditya Birla Finance Interest Rate",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Expert Aditya Birla Finance Guidance?",
        description:
          "Our loan specialists have deep expertise with Aditya Birla Finance's personal loan process and can help you navigate every step for maximum success.",
        link: {
          text: "Book a Free Aditya Birla Finance Loan Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Loan Tools",
      description:
        "Maximize your loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "MinEMI Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and predicts your loan approval chances before you apply.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: " Your Loan Approval predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "MinEMI Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Cheak Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our loan experts can help you navigate the HDFC Bank personal loan process and maximize your approval chances.",
        link: {
          text: "Sehedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Loan Tools",
      description:
        "Maximize your loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "MinEMI Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and predicts your loan approval chances before you apply.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "MinEMI Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our loan experts can help you navigate the SBI personal loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Loan Tools",
      description:
        "Maximize your loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "MinEMI Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and predicts your loan approval chances before you apply.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "MinEMI Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our loan experts can help you navigate the Axis Bank personal loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Loan Tools",
      description:
        "Maximize your loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "MinEMI Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and predicts your loan approval chances before you apply.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "MinEMI Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our loan experts can help you navigate the ICICI Bank personal loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Loan Tools",
      description:
        "Maximize your loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "MinEMI Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and predicts your loan approval chances before you apply.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "MinEMI Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our loan experts can help you navigate the Punjab National Bank personal loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Loan Tools",
      description:
        "Maximize your loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "MinEMI Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and predicts your loan approval chances before you apply.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "MinEMI Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our loan experts can help you navigate the IndusInd Bank personal loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Loan Tools",
      description:
        "Maximize your loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "MinEMI Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and predicts your loan approval chances before you apply.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "MinEMI Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our loan experts can help you navigate the Tata Capital personal loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Loan Tools",
      description:
        "Maximize your loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "MinEMI Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and predicts your loan approval chances before you apply.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "MinEMI Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our loan experts can help you navigate the Yes Bank personal loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Loan Tools",
      description:
        "Maximize your loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "MinEMI Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and predicts your loan approval chances before you apply.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "MinEMI Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our loan experts can help you navigate the Union Bank personal loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Loan Tools",
      description:
        "Maximize your loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "MinEMI Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and predicts your loan approval chances before you apply.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "MinEMI Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our loan specialists can provide personalized advice to help you navigate the Bajaj Finance loan process successfully.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Loan Tools",
      description:
        "Maximize your loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "MinEMI Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and predicts your loan approval chances before you apply.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "MinEMI Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our loan specialists can provide personalized advice to help you navigate the Federal Bank loan process successfully.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Loan Tools",
      description:
        "Maximize your loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "MinEMI Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI algorithm analyzes your financial profile to predict your Canara Bank loan approval chances with high accuracy.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "MinEMI Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our loan specialists can provide personalized advice to help you navigate the Canara Bank loan process successfully.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Loan Tools",
      description:
        "Maximize your loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "MinEMI Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI algorithm analyzes your financial profile to predict your Bank of India loan approval chances with high accuracy.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Want Expert Guidance?",
        description:
          "Our loan specialists can provide personalized advice to help you navigate the Bank of India loan process successfully.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Loan Tools",
      description:
        "Maximize your loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Approval Probability Analyzer",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI algorithm analyzes your financial profile to predict your RBL Bank loan approval chances with high accuracy.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Want Expert Guidance?",
        description:
          "Our loan specialists can provide personalized advice to help you navigate the RBL Bank loan process successfully.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );

  useEffect(() => {
    if (name === "checkkotakbankstatus") {
      setData(kotakData);
    } else if (name === "checkbobbankstatus") {
      setData(bobData);
    } else if (name === "checkidfcbankstatus") {
      setData(idfcData);
    } else if (name === "checkhdfcbankstatus") {
      setData(hdfcData);
    } else if (name === "checksbibankstatus") {
      setData(sbiData);
    } else if (name === "checkaxisbankstatus") {
      setData(axisData);
    } else if (name === "checkicicibankstatus") {
      setData(iciciData);
    } else if (name === "checkpunjabbankstatus") {
      setData(punjabData);
    } else if (name === "checkinduslndbankstatus") {
      setData(induslndData);
    } else if (name === "checktatabankstatus") {
      setData(tataData);
    } else if (name === "checkyesbankstatus") {
      setData(yesData);
    } else if (name === "checkunionbankstatus") {
      setData(unionData);
    } else if (name === "checkbajajbankstatus") {
      setData(bajajData);
    } else if (name === "checkfederalbankstatus") {
      setData(federalData);
    } else if (name === "checkcanarabankstatus") {
      setData(canaraData);
    } else if (name === "checkboibankstatus") {
      setData(boiData);
    } else if (name === "checkrblbankstatus") {
      setData(rblData);
    } else {
      setData({});
    }
  }, [
    name,
    axisData,
    bobData,
    hdfcData,
    iciciData,
    idfcData,
    kotakData,
    sbiData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
  ]);

  return (
    <section
      className="py-4 border-b border-gray-200"
      style={{ backgroundColor: "#ebe8fb" }}
    >
      <div className="container px-2 md:px-6 bg-transparent">
        <div className="sd:w-full md:w-4/5 mx-auto bg-transparent">
          <div className="text-center mb-6 bg-transparent">
            <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple">
              {data?.title}
            </h2>
            <p className="text-dark-grey mt-0 text-sm bg-transparent text-center">
              {data?.description}
            </p>
          </div>

          <div className="grid gap-3 bg-transparent">
            {data.tools?.map((d, index) => (
              <div
                key={index}
                className="bg-white p-3 rounded-lg shadow-sm border border-gray-200"
              >
                <div className="bg-transparent">
                  <div
                    className="h-10 w-10 rounded-full bg-light-bg flex items-center justify-center mb-3 mx-auto"
                    style={{ backgroundColor: "#ebe8fb" }}
                  >
                    {d.icon}
                  </div>
                  <h3 className="text-base font-bold text-primary-purple mb-2 text-center">
                    {d.name}
                  </h3>
                  <p className="text-dark-grey mb-3 text-sm bg-transparent">
                    {d.description}
                  </p>
                  <div className="space-y-2 mb-4 bg-transparent">
                    {d.features.map((feature, i) => (
                      <div
                        key={i}
                        className="flex items-start gap-0.5 bg-transparent"
                      >
                        {/* <CheckCircle
                          className="h-4 w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        /> */}
                        <CheckCircle
                          className="h-6 w-6 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        />

                        <p className="text-sm text-dark-grey bg-transparent m-0">
                          {feature}
                        </p>
                      </div>
                    ))}
                  </div>
                  <Link
                    to={d.link.url}
                    className="inline-flex h-12 items-center justify-center rounded-md bg-teal px-6 text-sm font-medium text-white transition-colors w-full bg-[#3b868b] hover:bg-[#2b1b61]"
                  >
                    {d.link.text}
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-3 bg-white p-3 rounded-lg border border-gray-200 shadow-sm">
            <h3 className="text-base font-bold text-primary-purple mb-2 text-center">
              {data.consultation?.title}
            </h3>
            <p className="text-dark-grey mb-4 text-sm bg-transparent">
              {data.consultation?.description}
            </p>
            <Link
              to={data.consultation?.link?.url}
              className="inline-flex h-12 items-center justify-center rounded-md bg-primary-purple px-6 text-sm font-medium text-white hover:bg-accent-purple transition-colors w-full bg-[#2b1b61] hover:bg-[#3b868b]"
            >
              {data.consultation?.link?.text}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

import React, { useState, useEffect, useContext } from "react";
import money from "../../Assets/Images/money.webp";
import creditScore from "../../Assets/Images/credit score.webp";
import Vector from "../../Assets/Images/Vector (5).webp";
import Group from "../../Assets/Images/Group (3).webp";
import Bill from "../../Assets/Images/Bill Get.webp";
import Ratio from "../../Assets/Images/Ratio.webp";
import "./Personalloan.css";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";
import Item from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MetadataContext from "../MetadataContext";
import axios from "axios";

const CreditCard = () => {
  const navigate = useNavigate();
  const isMediumScreen = useMediaQuery(" (max-width:756px) ");
  const isVerySmallScreen = useMediaQuery("(max-width:400px)");

  const [newsData, setNewsData] = useState([]);
  const [manageData, setManageData] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const blogs = [
    {
      id: 1,

      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/ccaaThree-min-1737780405029.webp",

      title: "Best Travel Credit Cards: Your Passport to Seamless Adventures",
      writer: "Aditi Malhotra",
      description:
        "Traveling is an exciting adventure, but managing expenses abroad or during trips can sometimes become challenging. ",
      naviLink: "/creditcard/how-to-maximize-credit-card-benefits",
    },
    {
      id: 2,

      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/ccaaTwo-min-1737780358161.webp",

      title: "Credit Card Annual Fees: A Comprehensive Guide",
      writer: "Aditi Malhotra",
      description:
        "Credit cards offer a host of benefits, including rewards, cashback, and convenience. ",
      naviLink: "/creditcard/credit-card-annual-fees",
    },
    {
      id: 3,

      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/ccaaFour-1737780441156.webp",

      title: "Why Is My Credit Card Application Getting Rejected?",
      writer: "Vikalp Shukla",
      description:
        "Applying for a credit card in India has become more accessible, with banks and financial institutions offering a wide range of options to suit different needs.",
      naviLink:
        "/creditcard/why-is-my-credit-card-application-getting-rejected",
    },
    {
      id: 4,

      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/ccaaOne-min-1737780206734.webp",

      title: "A Complete Guide to Balance Transfer Credit Cards",
      writer: "Vikalp Shukla",
      description:
        "Managing credit card debt can be challenging, especially when high-interest rates pile up.",
      naviLink: "/creditcard/a-complete-guide-to-balance-transfer-credit-cards",
    },
  ];

  const fetchLatestNews = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/news`,
    };

    axios
      .request(options)
      .then(function (response) {
        setNewsData(response.data.data.latestnews);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchLatestNews();
  }, []);

  const fetchManageLoans = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/manageloan`,
    };

    axios
      .request(options)
      .then(function (response) {
        setManageData(response.data.data.manageloan);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchManageLoans();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const homeLoanItems = manageData.filter((d) => d.category === "Credit Card");
  const visibleItems = showAll
    ? homeLoanItems.slice(1)
    : homeLoanItems.slice(1, 4);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Find the Best Credit Card Options | Minemi.ai",
      pageDescription:
        "Explore and compare credit card options with Minemi.ai. Discover the right card for your needs, from rewards to low-interest rates, and apply easily online",
      pageKeywords:
        "credit card,balance transfer credit card,best credit cards",
      canonicalUrl: "https://minemi.ai/creditcard/",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 48,
            paddingBottom: 80,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                className=" custom-link bg-transparent archivo "
                style={{
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Credit Card
              </Link>
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Best Credit Cards in India | Compare, Apply, and Get Personalized
            Recommendations{" "}
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 500,
              fontSize: 24,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "30px",
            }}
          >
            Find the best credit cards tailored to your spending habits. Compare
            rewards, cashback offers, travel perks, and new launches to find the
            perfect fit.
          </p>
        </div>
        <div
          className="make-wrap "
          style={{
            marginTop: 56,
            marginLeft: "4%",
            marginRight: "4%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#f8f8f8",
            gap: 16,
          }}
        >
          <div
            onClick={() => {
              navigate("/creditcard/creditcard-recommendation");
            }}
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 10,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              width: 290,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Credit Card Recommendation
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              Get personalized credit card suggestions based on your spending
              patterns.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                }}
              >
                <img
                  src={money}
                  alt="credit card"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  marginTop: 8,
                }}
              >
                Find the best card for your lifestyle.
              </p>
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/creditcard/credit-card-eligibility");
            }}
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 12,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              width: 290,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Credit Card Check Eligibility
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              Compare top credit cards for cashback, rewards, and perks.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                }}
              >
                <img
                  src={Ratio}
                  alt="credit card"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  loading="lazy"
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  marginTop: 8,
                }}
              >
                Maximize your spending with the best offers.
              </p>
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/creditcard/credit-card-rewards-and-cashback");
            }}
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 12,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              width: 290,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Credit Card Rewards and Cashback
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              Explore cards with the best rewards and cashback programs.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                }}
              >
                <img
                  src={Group}
                  alt="rewards"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  marginTop: 8,
                }}
              >
                Earn more on every purchase.
              </p>
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/creditcard/travel-and-airline-credit-cards");
            }}
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 12,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              width: 290,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Travel and Airline Credit Cards
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              Compare credit cards with travel benefits and airline miles.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                }}
              >
                <img
                  src={creditScore}
                  alt="travel"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  marginTop: 8,
                }}
              >
                Enjoy lounge access and earn travel points.
              </p>
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/creditcard/credit-card-status-tracking");
            }}
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 12,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              width: 290,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Credit Card Status Tracking
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              {" "}
              Discover the latest credit card launches in India.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                }}
              >
                <img
                  src={Vector}
                  alt="loan status"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  marginTop: 8,
                }}
              >
                Stay updated with new card offerings.
              </p>
            </p>
          </div>
          <div
            onClick={() => {
              navigate("/creditcard/balance-transfer-credit-cards");
            }}
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 12,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              width: 290,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Balance Transfer Credit Cards
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              Transfer your balance to a lower-interest credit card.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                }}
              >
                <img
                  src={Bill}
                  alt="balance transfer"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  marginTop: 8,
                }}
              >
                {" "}
                Reduce your debt and save on interest.
              </p>
            </p>
          </div>
        </div>
        <div
          style={{
            maginTop: 64,
            marginLeft: "4%",
            marginRight: "4%",
            marginTop: 32,
          }}
        >
          <p
            style={{
              color: "#59266D",
              fontWeight: 600,
              fontFamily: "Archivo",
              fontSize: 28,
              lineHeight: 1,
            }}
          >
            Qualify for a Credit Card
          </p>
        </div>
        <div
          style={{
            marginLeft: "4%",
            marginRight: "4%",
            marginTop: 32,
          }}
          className="loan-list-container"
        >
          {blogs?.map((blog) => (
            <div
              className="loan-container"
              onClick={() => navigate(blog.naviLink)}
              key={blog.id}
            >
              <a href={blog.link}>
                <img
                  src={blog.image}
                  alt={blog.heading}
                  loading="lazy"
                  className="loan-image d-none d-md-block"
                />
                <h3
                  className="loan-heading"
                  style={{
                    fontFamily: "Archivo",
                    fontWeight: 600,
                    fontSize: 18,
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {blog.title}
                </h3>
                <p
                  style={{
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    color: "#8a8a8a",
                    fontSize: 14,
                  }}
                  className="mt-2 mb-1"
                >
                  by {blog.writer}
                </p>
                <div
                  className="loan-subheading"
                  dangerouslySetInnerHTML={{
                    __html: blog.description,
                  }}
                  style={{
                    fontFamily: "Archivo",
                    fontWeight: 400,
                    fontSize: 18,
                    color: "#B1B1B1",
                    display: "-webkit-box",
                    WebkitLineClamp: 2, // Limit to 2 lines
                    WebkitBoxOrient: "vertical", // Required for webkit-line-clamp
                    overflow: "hidden", // Hide overflowing text
                    textOverflow: "ellipsis", // Add ellipsis
                  }}
                />
              </a>
            </div>
          ))}
        </div>
        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 32 }}>
          <p
            style={{
              color: "#59266D",
              fontWeight: 600,
              fontFamily: "Archivo",
              fontSize: 28,
              lineHeight: 1,
            }}
          >
            Manage your Credit Card
          </p>
          <div
            style={{
              marginTop: 40,
              marginBottom: 32,
              backgroundColor: "#f8f8f8",
            }}
          >
            <Grid container spacing={5} sx={{ backgroundColor: "#f8f8f8" }}>
              {manageData
                .filter((d) => d.category === "Credit Card")
                .slice(0, 1) // Adjust slice range based on your needs
                .map((d) => {
                  return (
                    <Grid item xs={12} md={6}>
                      <Item style={{ border: 0, boxShadow: "0px 0px" }}>
                        <Link
                          to={`/creditcard/manageloan_detail/${d.mslug}`}
                          state={{
                            mslug: d?.mslug,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 8,
                              backgroundColor: "#f8f8f8",
                            }}
                          >
                            <div
                              style={{
                                height: "auto",
                                backgroundColor: "#f8f8f8",
                              }}
                            >
                              <img
                                src={d.image}
                                style={{
                                  width: "100%",
                                  height: isVerySmallScreen ? "100%" : "400px",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                }}
                                alt={d.title}
                                loading="lazy"
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "#f8f8f8",
                                gap: "8px",
                              }}
                            >
                              <p
                                className="manage_latest_link m-0"
                                style={{ fontSize: 18, fontWeight: 400 }}
                              >
                                {d?.title}
                              </p>
                              <p
                                style={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "grey",
                                }}
                              >
                                by {d?.author}
                              </p>
                              <p
                                style={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  lineHeight: "0",
                                  color: "grey",
                                }}
                                className="m-0"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    d?.description && d.description.length > 100
                                      ? `${d.description.slice(0, 100)}...`
                                      : d?.description || "",
                                }}
                              ></p>
                            </div>
                          </div>
                        </Link>
                      </Item>
                    </Grid>
                  );
                })}

              <Grid item xs={12} md={6}>
                <Item
                  style={{
                    border: 0,
                    boxShadow: "0px 0px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 32,
                    backgroundColor: "#F6F6F6",
                  }}
                >
                  {visibleItems.map((d) => (
                    <div key={d.id}>
                      <Link
                        to={`/creditcard/manageloan_detail/${d.mslug}`}
                        state={{
                          mslug: d.mslug,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 12,
                          }}
                        >
                          <div
                            style={{
                              minWidth: isVerySmallScreen
                                ? 80
                                : isMediumScreen
                                ? 120
                                : 150,
                              maxWidth: 190,
                            }}
                          >
                            <img
                              src={d.image}
                              alt={d.title}
                              loading="lazy"
                              style={{
                                width: "100%",
                                height: isVerySmallScreen
                                  ? "50px"
                                  : isMediumScreen
                                  ? "80px"
                                  : "110px",
                                objectFit: "cover",
                                borderRadius: "5px",
                                objectPosition: "center",
                              }}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 3,
                            }}
                          >
                            <p
                              className="manage_latest_link m-0 pt-0 pt-sm-3"
                              style={{ fontSize: 18, fontWeight: 400 }}
                            >
                              {d.title}
                            </p>
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "grey",
                              }}
                            >
                              by {d.author}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                  {homeLoanItems.length > 3 && (
                    <button
                      onClick={() => {
                        navigate("/creditcard/manageloan_detail");
                      }}
                      style={{
                        alignSelf: "center",
                        padding: "8px 16px",
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#007BFF",
                        backgroundColor: "transparent",
                        border: "1px solid #007BFF",
                        borderRadius: 5,
                        cursor: "pointer",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        transition: "box-shadow 0.3s ease",
                      }}
                    >
                      See More
                    </button>
                  )}
                </Item>
              </Grid>
            </Grid>
          </div>
        </div>
        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 40 }}>
          <h3
            style={{
              color: "#59266D",
              fontWeight: 600,
              fontFamily: "Archivo",
              fontSize: 28,
              lineHeight: 1,
            }}
          >
            The Latest
          </h3>
          <Grid container spacing={2} style={{ marginTop: "2%" }}>
            {newsData
              .filter((item) => item.category === "Credit Card")
              .map((item) => (
                <Grid item xs={12} sm={6} key={item.id}>
                  {item.category === "Credit Card" && (
                    <Item style={{ boxShadow: "0px 0px", marginBottom: 24 }}>
                      <Link
                        to={`/creditcard/news_detail/${item.nslug}`}
                        state={{
                          nslug: item.nslug,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 12,
                            alignItems: "center",
                            backgroundColor: "#f8f8f8",
                          }}
                        >
                          <div
                            style={{
                              // width: "100%",
                              minWidth: isVerySmallScreen ? 100 : 150,
                              maxWidth: 170,
                            }}
                          >
                            <img
                              src={item.image}
                              alt={item.title}
                              style={{
                                width: "100%",
                                height: isVerySmallScreen ? "50px" : "110px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                              loading="lazy"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 5,
                              alignSelf: "center",
                            }}
                          >
                            <p
                              style={{ fontSize: 18, fontWeight: 400 }}
                              className="manage_latest_link m-0"
                            >
                              {item.title}
                            </p>
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "grey",
                              }}
                              className="m-0"
                            >
                              {" "}
                              by {item.author}
                            </p>
                            {/* <p
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: 1.3,
                              color: "grey",
                              display: "-webkit-box",
                              WebkitLineClamp: 2, // Limit to 2 lines
                              WebkitBoxOrient: "vertical", // Required for webkit-line-clamp
                              overflow: "hidden", // Hide overflowing text
                              textOverflow: "ellipsis",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                          ></p> */}
                          </div>
                        </div>
                      </Link>
                    </Item>
                  )}
                </Grid>
              ))}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default CreditCard;

import React from "react";
// import Image from "../../../../Assets/Images/Rectangle.png";
import Image from "../../../../../../Assets/Images/Rectangle-img.png";

import "./ListOfDocuments.css";

const ListOfDocuments = () => {
  return (
    <>
      <div id="list-of-documents" className="bg-transparent">
        <div id="bt-documents-container">
          <div id="documents-content" className="bg-transparent">
            <div className="documents-content-container bg-transparent">
              <div className="content-box w-100 d-flex flex-column flex-md-row bg-transparent ">
                {" "}
                <div className="documents-text-box bg-transparent">
                  <div className="bg-transparent">
                    <div className="bg-transparent">
                      <h4 className="bg-transparent" id="documents-heading">
                        Documents Required for Balance Transfer
                      </h4>
                    </div>
                    <div className="text-box bg-transparent">
                      <div className="d-flex flex-row gap-3 gap-md-4 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent m-0">
                          Latest Loan Statement
                        </p>
                      </div>
                      <div className="d-flex flex-row gap-3 gap-md-4 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent m-0">ID Proof</p>
                      </div>
                      <div className="d-flex flex-row gap-3 gap-md-4 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent m-0">
                          Address Proof
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="documents-image-box  d-md-block bg-transparent">
                  <div className="bg-transparent">
                    <img
                      className="documents-image bg-transparent"
                      src={Image}
                      alt="docuents-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListOfDocuments;

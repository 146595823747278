import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import MetadataContext from "../../MetadataContext";

const HomeLoanTopUp = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Home Loan Top-Up",
      pageDescription:
        "Explore how you can top-up your existing home loan with minimal documentation. Get more funds for your needs without the hassle of a new application.",
      pageKeywords:
        "home loan top-up, compare home loan top-up, top-up home loan, home loan refinancing, additional home loan funds, minimal documentation loan, home loan enhancement, easy home loan top-up, home loan offers, top-up loan application",
      canonicalUrl:
        "https://minemi.ai/loanpage/homeloan/home-loan-eligibility-criteria/sbi-home-loan-eligibility",
    });
  }, [setMetadata]);

  return (
    <>
      <Helmet>
        <title>Home Loan Top-Up</title>
        <meta
          name="description"
          content="Explore how you can top-up your existing home loan with minimal
          documentation. Get more funds for your needs without the hassle of a
          new application."
        />
        <meta
          name="keywords"
          content="home loan top-up, compare home loan top-up, top-up home loan, home loan refinancing, additional home loan funds, minimal documentation loan, home loan enhancement, easy home loan top-up, home loan offers, top-up loan application"
        />

        {/* <link rel="icon" type="image/png" href="./mLogo.png" /> */}
      </Helmet>
      <div
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className="custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loans
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage/homeloan"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Home Loan
            </Link>{" "}
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              Home Loan Top-Up
            </Link>{" "}
          </p>
        </div>
        <h1
          className="homeloan-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          {" "}
          Home Loan Top-Up: Increase Your Loan Amount Easily
        </h1>
        <p
          className="homeloan-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 18,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: 1,
          }}
        >
          Explore how you can top-up your existing home loan with minimal
          documentation. Get more funds for your needs without the hassle of a
          new application.
        </p>
      </div>

      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Introduction:
        </div>
        <div style={{ marginTop: "10px" }}>
          <p style={{ color: "#1F1F1F", fontSize: "18px", fontWeight: 600 }}>
            Home Loan Top-Up: Unlocking Additional Funds with Your Existing Home
            Loan
          </p>
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          A Home Loan Top-Up is a convenient option for homeowners who need
          additional funds without going through the hassle of applying for a
          new loan. It allows you to borrow more money on top of your existing
          home loan, typically at a lower interest rate compared to personal
          loans or other types of loans. This option can be ideal for financing
          home renovations, medical expenses, education, or even consolidating
          debt.
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          At Minemi, we aim to provide you with essential information to make
          informed decisions about Home Loan Top-Ups. Learn about the benefits,
          eligibility criteria, how to apply, and how it can serve as a
          financial solution to your needs while managing your current loan.
          Explore more and make your finances work smarter for you.
        </div>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          What is a Home Loan Top-Up?
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          A Home Loan Top-Up is an additional loan offered by financial
          institutions to existing home loan borrowers. This loan is provided on
          top of your original home loan and can be used for various purposes
          such as home renovations, funding education, medical expenses, or debt
          consolidation. The benefit of a top-up loan is that it typically comes
          with a lower interest rate than unsecured loans like personal loans,
          as it is secured against your property.
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          The amount you can borrow depends on the outstanding balance of your
          original home loan, the value of your property, and your eligibility.
          The top-up loan is often processed with minimal documentation and
          quicker turnaround times, making it a convenient financial option for
          existing homeowners.
        </div>
        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Benefits of a Home Loan Top-Up:
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Lower Interest Rates:
              </span>
              Top-up loans generally come with lower interest rates compared to
              unsecured loans like personal loans, making them a cost-effective
              borrowing option.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Flexible Loan Amount:
              </span>
              You can borrow an additional amount up to a certain percentage of
              your existing home loan balance, depending on the lender's
              policies.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                {" "}
                Minimal Documentation:
              </span>
              Since the loan is secured against your property, the documentation
              requirements are typically less stringent compared to new loans.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Faster Processing:
              </span>
              Home loan top-ups often have quicker approval and disbursal times,
              ensuring fast access to funds when you need them.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                No Need for Additional Collateral:
              </span>
              The top-up loan is secured by your existing property, so there’s
              no need to pledge new assets.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Longer Repayment Tenure:
              </span>
              You can enjoy longer repayment tenures, making monthly EMIs more
              manageable.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Use of Funds for Various Purposes:
              </span>
              The top-up amount can be used for a variety of purposes, including
              home renovations, medical expenses, education, or consolidating
              existing debt.
            </div>
          </div>
        </div>

        <div
          className=" pb-2 "
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Eligibility Criteria:
        </div>
        <Typography
          sx={{
            fontFamily: "'Archivo',sans-serif",
            fontSize: "16px",
            color: "#1F1F1F",
          }}
        >
          The eligibility criteria for a Home Loan Top-Up vary across lenders,
          but generally, they are based on the following factors:
        </Typography>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <Typography
                className=" d-inline"
                sx={{
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 500,
                }}
              >
                Existing Home Loan:
              </Typography>
              The borrower must already have an active home loan with the
              lender. The top-up amount is usually determined by the remaining
              balance of the original home loan.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <Typography
                className=" d-inline"
                sx={{
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 500,
                }}
              >
                Credit Score:
              </Typography>
              A good credit score (typically 650 and above) is required to
              qualify for a top-up loan. A higher credit score increases the
              chances of approval and better interest rates.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <Typography
                className=" d-inline"
                sx={{
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 500,
                }}
              >
                Repayment Track Record:
              </Typography>
              A borrower’s timely repayment history of the existing home loan
              plays a significant role in the eligibility for a top-up loan.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <Typography
                className=" d-inline"
                sx={{
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 500,
                }}
              >
                Income Stability:
              </Typography>
              Lenders assess the applicant's income to ensure they can
              comfortably repay the additional loan amount. A steady income or
              employment history is often a requirement.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <Typography
                className=" d-inline"
                sx={{
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 500,
                }}
              >
                Age:
              </Typography>
              Most lenders require borrowers to be between 18-60 years of age.
              The age limit may vary slightly based on the lender's policies.
            </div>
          </div>{" "}
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <Typography
                className=" d-inline"
                sx={{
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 500,
                }}
              >
                Property Valuation:
              </Typography>
              The property against which the original home loan was taken may be
              revalued to ensure it provides adequate security for the top-up
              loan.
            </div>
          </div>{" "}
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <Typography
                className=" d-inline"
                sx={{
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 500,
                }}
              >
                Loan-to-Value Ratio:
              </Typography>
              The combined loan-to-value (LTV) ratio, which includes the
              original home loan and the top-up amount, should generally be
              within the lender's prescribed limits.
            </div>
          </div>{" "}
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <Typography
                className=" d-inline"
                sx={{
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 500,
                }}
              >
                Outstanding Loan Amount:
              </Typography>
              Some lenders require that a certain portion of the original loan
              be repaid before granting a top-up loan.
            </div>
          </div>{" "}
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <Typography
                className=" d-inline"
                sx={{
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 500,
                }}
              >
                Documentation:
              </Typography>
              Lenders usually require minimal documentation for top-up loans,
              but applicants may need to provide proof of income, property
              ownership, and other financial documents.
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          How to Apply for a Home Loan Top-Up:
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Check Eligibility:
              </span>
              Ensure that you meet the eligibility criteria for a top-up loan,
              such as having an existing home loan with the lender, maintaining
              a good credit score, and a stable income.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Contact Your Lender:
              </span>
              Reach out to your lender, either online, via customer support, or
              by visiting a branch. Some lenders may offer the option to apply
              for a top-up loan through their mobile app or online portal.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Submit Documentation:
              </span>
              <p
                className="pt-2 pb-2"
                style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
              >
                Prepare the required documentation, which may include:
              </p>
              {/* list data */}
              <ul>
                <li
                  className="px-2"
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  {" "}
                  Proof of income (salary slips, bank statements)
                </li>
                <li
                  className="px-2"
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  {" "}
                  Credit score details
                </li>
                <li
                  className="px-2"
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  {" "}
                  Property documents (if required)
                </li>
                <li
                  className="px-2"
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  {" "}
                  Outstanding loan balance
                </li>
                <li
                  className="px-2"
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  {" "}
                  Application form (available on the lender's website or at the
                  branch)
                </li>
              </ul>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Loan Assessment:
              </span>
              The lender will assess your eligibility based on your current loan
              repayment record, credit score, income, and the property’s value.
              They may request additional documents if necessary.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Approval and Disbursement:
              </span>
              Once your application is approved, you will receive a sanction
              letter outlining the loan amount, interest rate, and tenure. After
              signing the agreement, the top-up amount will be disbursed to your
              account, either as a lump sum or in stages, depending on the
              lender's policy.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Repayment Terms:
              </span>
              Ensure you understand the repayment terms, including any changes
              in the EMI amount or tenure due to the top-up loan. Adjust your
              finances to accommodate the new payment structure.
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Common Uses for a Home Loan Top-Up:
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Home Renovation and Repairs:
              </span>
              Many homeowners use a top-up loan to fund renovations, such as
              remodelling kitchens, adding extra rooms, or fixing structural
              issues. It provides an affordable way to enhance the value of the
              property.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Medical Expenses:
              </span>
              An emergency medical situation can drain savings quickly. Using a
              home loan top-up allows individuals to cover large medical bills
              with a lower interest rate compared to personal loans or credit
              cards.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Education Costs:
              </span>
              For higher education or professional courses, a home loan top-up
              can be a cost-effective option. It helps manage tuition fees,
              accommodation costs, or other educational expenses without adding
              too much financial burden.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Debt Consolidation
              </span>
              A home loan top-up can help consolidate high-interest debts, such
              as credit card balances or personal loans, into a single loan with
              a lower interest rate. This streamlines payments and reduces
              overall interest expenses.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Wedding Expenses:
              </span>
              For those looking to finance wedding costs, a top-up loan is often
              preferred due to its lower interest rate and longer repayment
              period compared to other financing options.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Investment in Other Property:
              </span>
              Some homeowners use the funds from a top-up loan for investing in
              additional property, either for rental income or future capital
              appreciation.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Starting or Expanding a Business:
              </span>
              If you're an entrepreneur or a small business owner, you can use a
              home loan top-up for business expansion, buying equipment, or
              covering operating costs.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Travel and Leisure:
              </span>
              While not as common, some individuals use home loan top-ups for
              large personal expenditures such as vacations or luxury purchases,
              provided they are financially comfortable with the additional EMI
              burden.
            </div>
          </div>
          <div>
            <p
              style={{
                fontSize: "16px",
                fontFamily: "'Archivo',sans-serif",
                color: "#1F1F1F",
              }}
            >
              In general, home loan top-ups are considered a versatile and
              cost-effective borrowing option, especially since they come with
              lower interest rates compared to other types of loans. However,
              it’s important to evaluate your ability to repay the additional
              EMI before taking one on.
            </p>
          </div>
        </div>

        <div
          style={{
            marginTop: 40,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Frequently Asked Questions (FAQs){" "}
        </div>
        <div
          className="accordion "
          style={{
            marginTop: 9,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
            defaultExpanded={true}
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                backgroundColor: "#F6F6F6",
                fontWeight: 700,
              }}
            >
              What is the difference between a Home Loan Top-Up and a Personal
              Loan?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              A Home Loan Top-Up is an additional loan on top of your existing
              home loan, with lower interest rates compared to personal loans.
              It is typically repaid along with your home loan. In contrast, a
              personal loan is an unsecured loan with higher interest rates,
              usually offering a shorter repayment term. A top-up loan is ideal
              if you already have an existing home loan and need additional
              funds at a competitive rate.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Can I get a Home Loan Top-Up if I have a poor credit score?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              While a poor credit score may limit your eligibility for a home
              loan top-up, it doesn't automatically disqualify you. Lenders
              generally look at factors such as your existing repayment track
              record on the current home loan and overall financial stability.
              If you have a strong repayment history with your home loan, you
              may still qualify, even with a lower credit score.
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              How much can I borrow with a Home Loan Top-Up?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              The amount you can borrow through a Home Loan Top-Up depends on
              several factors, including the outstanding balance of your home
              loan, your repayment history, and your property’s current market
              value. Typically, lenders offer up to 100% of the existing home
              loan amount as a top-up, but this may vary depending on the bank’s
              policies and your financial profile.
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
        </div>
        <div
          className="increase-margines"
          style={{
            backgroundColor: "#E7E4F9",
            borderRadius: 24,
            padding: 40,
            marginTop: 64,
            marginBottom: 40,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              backgroundColor: "transparent",
            }}
          >
            <p
              className="minutes"
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 32,
                fontWeight: 600,
              }}
            >
              Check Your Eligibility in Minutes!
            </p>
            <p
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 18,
                fontWeight: 400,
                lineHeight: 1.2,
              }}
            >
              Get personalized loan options based on your income, current EMI,
              and credit score. Quick, easy,
              <br className="display-none" /> and tailored just for you—apply
              now to see what you qualify for!
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                marginTop: 30,
                display: "flex",
                flexDirection: "row",
                gap: 24,
              }}
            >
              <a
                href="https://minemi.ai/home-loan/check-eligibility"
                style={{
                  backgroundColor: "#59266D",
                  color: "white",
                  borderRadius: 8,
                  padding: 10,
                  paddingLeft: 50,
                  paddingRight: 50,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 16,
                }}
              >
                Check Eligiblity Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeLoanTopUp;

"use client";

import { useState } from "react";
import { ChevronDown, Info, Star } from "lucide-react";
import hdfc from "../../../Assets/Images/HDFCSquare.webp";
import bajaj from "../../../Assets/Images/BajajFinservSquare.webp";
import piramal from "../../../Assets/Images/PiramalFinanceSquare.webp";
import idfc from "../../../Assets/Images/IDFCSquare.webp";
import tata from "../../../Assets/Images/tataCapitalSqure.webp";
import { useMediaQuery } from "@mui/material";
function TopPick({ category, lender }) {
  const [openSection, setOpenSection] = useState(null);
  const isMediumScreen = useMediaQuery("(max-width:768px)");

  const toggleSection = (section) => {
    if (openSection === section) {
      setOpenSection(null);
    } else {
      setOpenSection(section);
    }
  };

  return (
    <div className="mb-8 overflow-hidden rounded-lg border bg-white shadow-md">
      {/* Header */}
      <div className="border-b bg-[#ebe8fc]/30 p-3">
        <h3 className="text-lg font-bold text-[#311664] m-0">
          Best for {category}
        </h3>
      </div>

      {/* Main content */}
      <div className="p-4">
        <div className="flex flex-col items-start gap-6 md:flex-row md:items-center">
          {/* Logo and name */}
          <div className="flex items-center">
            <div className="mr-4 h-16 w-16 overflow-hidden rounded-lg border">
              <img
                src={lender.img}
                alt={lender.name}
                className="object-cover w-16 h-16"
              />
            </div>
            <div>
              <div className="text-xl font-bold text-[#311664]">
                {lender.name}
              </div>
              <div className="flex items-center">
                <span className="mr-1 font-medium">{lender.rating}</span>
                <div className="flex">
                  {Array(5)
                    .fill(0)
                    .map((_, i) => (
                      <Star
                        key={i}
                        className={`h-4 w-4 ${
                          i < Math.floor(lender.rating)
                            ? "fill-[#59266d] text-[#59266d]"
                            : "text-gray-300"
                        }`}
                      />
                    ))}
                </div>
                <span className="ml-1 text-xs text-gray-500">/5</span>
              </div>
            </div>
          </div>

          {/* Loan details */}
          <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3">
            <div>
              <div className="flex items-center text-sm text-gray-500">
                Est. APR
                <Info className="ml-1 h-3 w-3" />
              </div>
              <div className="font-medium">{lender.apr}</div>
            </div>
            <div>
              <div className="text-sm text-gray-500">Loan amount</div>
              <div className="font-medium">{lender.amount}</div>
            </div>
            <div>
              <div className="flex items-center text-sm text-gray-500">
                Min. credit score
                <Info className="ml-1 h-3 w-3" />
              </div>
              <div className="font-medium">{lender.score}</div>
            </div>
          </div>

          {/* CTA button */}
          <div className="mt-4 w-full md:mt-0 md:w-auto">
            <button
              className="w-full rounded-md bg-[#3a868b] px-4 py-2 font-medium text-white hover:bg-[#3a868b]/90 text-sm"
              style={{
                width: isMediumScreen ? "100%" : "7rem",
                height: isMediumScreen ? "3.3rem" : "auto",
              }}
              onClick={() => {
                window.location.href = lender.link;
              }}
            >
              See My Rates
            </button>
          </div>
        </div>

        {/* Expandable sections */}
        <div className="mt-6 border-t pt-4">
          <div className="grid gap-2 d-flex flex-column">
            {/* Details and qualifications */}
            <div className="rounded-md border">
              <button
                className="flex w-full items-center justify-between p-3 text-left font-medium text-[#311664] hover:bg-[#ebe8fc]/30"
                onClick={() => toggleSection("details")}
                aria-expanded={openSection === "details"}
              >
                Details and qualifications
                <ChevronDown
                  className={`h-5 w-5 transition-transform ${
                    openSection === "details" ? "rotate-180" : ""
                  }`}
                />
              </button>
              {openSection === "details" && (
                <div className="border-t p-3">
                  <ul className="list-inside list-disc space-y-2 text-sm">
                    {lender.details.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* Pros and cons */}
            <div className="rounded-md border">
              <button
                className="flex w-full items-center justify-between p-3 text-left font-medium text-[#311664] hover:bg-[#ebe8fc]/30"
                onClick={() => toggleSection("pros-cons")}
                aria-expanded={openSection === "pros-cons"}
              >
                Pros & cons
                <ChevronDown
                  className={`h-5 w-5 transition-transform ${
                    openSection === "pros-cons" ? "rotate-180" : ""
                  }`}
                />
              </button>
              {openSection === "pros-cons" && (
                <div className="border-t p-3">
                  <div className="grid gap-4 md:grid-cols-2">
                    <div>
                      <h6 className="mb-2 font-medium text-[#311664]">Pros</h6>
                      <ul className="list-inside list-disc space-y-1 text-sm">
                        {lender.pros.map((pro, index) => (
                          <li key={index}>{pro}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h6 className="mb-2 font-medium text-[#311664]">Cons</h6>
                      <ul className="list-inside list-disc space-y-1 text-sm">
                        {lender.cons.map((con, index) => (
                          <li key={index}>{con}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Disclaimer */}
            <div className="rounded-md border">
              <button
                className="flex w-full items-center justify-between p-3 text-left font-medium text-[#311664] hover:bg-[#ebe8fc]/30"
                onClick={() => toggleSection("disclaimer")}
                aria-expanded={openSection === "disclaimer"}
              >
                Disclaimer
                <ChevronDown
                  className={`h-5 w-5 transition-transform ${
                    openSection === "disclaimer" ? "rotate-180" : ""
                  }`}
                />
              </button>
              {openSection === "disclaimer" && (
                <div className="border-t p-3">
                  <p className="text-sm text-gray-600">{lender.disclaimer}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function TopPicksSection() {
  const topPicks = [
    {
      category: "Overall",
      lender: {
        name: "HDFC Bank",
        img: hdfc,
        logo: "hdfc",
        link: "https://minemi.ai/personal-loan/check-eligibility/",
        rating: 5.0,
        apr: "10.50-21.00%",
        amount: "₹50,000-₹40,00,000",
        score: "750",
        details: [
          "Loan term: 12-60 months",
          "Minimum income: ₹25,000 per month",
          "Maximum loan amount based on income and credit profile",
          "Processing fee: Up to 2.50% of loan amount",
          "Prepayment charges: 2-5% of outstanding amount",
        ],
        pros: [
          "Competitive interest rates for existing customers",
          "Quick disbursals within 24 hours for pre-approved customers",
          "Flexible repayment options",
          "No collateral required",
        ],
        cons: [
          "Higher interest rates for non-HDFC customers",
          "Prepayment penalties apply",
          "Requires good credit history",
        ],
        disclaimer:
          "Rates, fees, and terms are subject to change without notice. HDFC Bank personal loans are subject to credit approval. Your actual rate, payment, and costs could be higher. Minimum loan amounts may vary by state.",
      },
    },
    {
      category: "Fast Disbursals",
      lender: {
        name: "Bajaj Finserv",
        img: bajaj,
        logo: "bajaj",
        link: "https://minemi.ai/personal-loan/check-eligibility/",
        rating: 4.5,
        apr: "11.99-16.00%",
        amount: "₹1,00,000-₹20,00,000",
        score: "750",
        details: [
          "Loan term: 12-60 months",
          "Minimum income: ₹30,000 per month (salaried)",
          "Disbursement within 4 hours of approval",
          "Processing fee: Up to 3.00% of loan amount",
          "Prepayment charges: 2% of outstanding amount",
        ],
        pros: [
          "Instant approval and quick disbursals",
          "Minimal documentation required",
          "Flexible loan amount",
          "Part-prepayment facility available",
        ],
        cons: [
          "Higher processing fees compared to banks",
          "Prepayment charges apply",
          "Limited flexibility in repayment tenure",
        ],
        disclaimer:
          "Rates, fees, and terms are subject to change without notice. Bajaj Finserv personal loans are subject to credit approval. Your actual rate, payment, and costs could be higher. Minimum loan amounts may vary by state.",
      },
    },
    {
      category: "Self-Employed Individuals",
      lender: {
        name: "Piramal Finance",
        img: piramal,
        logo: "piramal",
        link: "https://minemi.ai/personal-loan/check-eligibility/",
        rating: 4.5,
        apr: "12.99-20.99%",
        amount: "₹1,00,000-₹15,00,000",
        score: "680",
        details: [
          "Loan term: 12-60 months",
          "Minimum income: ₹25,000 per month (self-employed)",
          "Business vintage: Minimum 3 years",
          "Processing fee: Up to 2.50% of loan amount",
          "Prepayment charges: 3% of outstanding amount",
        ],
        pros: [
          "Specialized loans for self-employed professionals",
          "Accepts business income for eligibility",
          "Flexible documentation requirements",
          "Dedicated relationship manager",
        ],
        cons: [
          "Higher interest rates compared to salaried individuals",
          "Requires minimum 3 years of business vintage",
          "Higher processing fees",
        ],
        disclaimer:
          "Rates, fees, and terms are subject to change without notice. Piramal Finance personal loans are subject to credit approval. Your actual rate, payment, and costs could be higher. Minimum loan amounts may vary by state.",
      },
    },
    {
      category: "Low Interest Rates",
      lender: {
        name: "IDFC First Bank",
        img: idfc,
        logo: "idfc",
        link: "https://minemi.ai/personal-loan/check-eligibility/",
        rating: 4.5,
        apr: "10.49-18.00%",
        amount: "₹30,000-₹30,00,000",
        score: "725",
        details: [
          "Loan term: 12-60 months",
          "Minimum income: ₹20,000 per month",
          "Zero prepayment charges",
          "Processing fee: Up to 2.00% of loan amount",
          "Interest calculated on reducing balance",
        ],
        pros: [
          "One of the lowest interest rates in the market",
          "No prepayment penalties",
          "Minimal documentation for salaried individuals",
          "Attractive offers for existing customers",
        ],
        cons: [
          "Longer processing time compared to NBFCs",
          "Stricter eligibility criteria",
          "Limited branch network in smaller cities",
        ],
        disclaimer:
          "Rates, fees, and terms are subject to change without notice. IDFC First Bank personal loans are subject to credit approval. Your actual rate, payment, and costs could be higher. Minimum loan amounts may vary by state.",
      },
    },
    {
      category: "Flexible Repayment Options",
      lender: {
        name: "Tata Capital",
        img: tata,
        logo: "tata",
        link: "https://minemi.ai/personal-loan/check-eligibility/",
        rating: 4.0,
        apr: "10.99-19.99%",
        amount: "₹75,000-₹25,00,000",
        score: "700",
        details: [
          "Loan term: 12-72 months",
          "Minimum income: ₹25,000 per month",
          "Flexible EMI options",
          "Processing fee: Up to 2.50% of loan amount",
          "Step-up and step-down EMI options available",
        ],
        pros: [
          "Customizable repayment plans",
          "Longer tenure options up to 6 years",
          "Balance transfer facility with attractive rates",
          "Special offers for existing customers",
        ],
        cons: [
          "Higher interest rates for non-prime customers",
          "Processing time can be longer",
          "Prepayment charges apply in some cases",
        ],
        disclaimer:
          "Rates, fees, and terms are subject to change without notice. Tata Capital personal loans are subject to credit approval. Your actual rate, payment, and costs could be higher. Minimum loan amounts may vary by state.",
      },
    },
  ];

  return (
    <section className="py-16">
      <div className=" mx-auto px-4">
        <h2 className="mb-8 text-center text-3xl font-bold text-[#311664]">
          Top Picks for Personal Loans
        </h2>
        <p className="mb-12 text-center text-[#26292b]">
          We've analyzed dozens of personal loan options to bring you the best
          lenders for different needs.
        </p>

        <div className="space-y-6">
          {topPicks.map((pick, index) => (
            <TopPick
              key={index}
              category={pick.category}
              lender={pick.lender}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

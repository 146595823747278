import React from "react";
import "./index.css";

const Index = () => {
  return (
    <>
      <div id="Loans-approval">
        <div className="titleCon">
          <p className="title m-0 p-0">Our recommendation regarding your loan approval</p>
        </div>

        <div className="contentCon">
            <div className="row m-0 p-0">
                <div className="col-12 col-md-6 mb-3 ps-0 pe-0 pe-md-2 ">
                    <div className="approval-con">
                        <div className="approval-header d-flex justify-content-between">
                            <p className="approval-header-title">1. Loan Consolidation</p>
                            <img src="../../../images/credit-score/dual-files.png" alt="dual-files" className="approval-header-img"/>
                        </div>
                        <div className="ps-3">
                            <div className="approval-header-text"><div className="approval-dots"></div> <span>Combine high-interest unsecured loans into one.</span></div>
                            <div className="approval-header-text"><div className="approval-dots"></div> <span>Opt for lower-interest, longer-tenure loans.</span></div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-3 m-0 ps-0 ps-md-2 pe-0 ">
                    <div className="approval-con">
                        <div className="approval-header d-flex justify-content-between">
                            <p className="approval-header-title">2. Reduce Credit Card Utilization</p>
                            <img src="../../../images/credit-score/reduce-payment.png" alt="reduce-payment" className="approval-header-img"/>
                        </div>
                        <div className="ps-3">
                            <div className="approval-header-text"><div className="approval-dots"></div> <span>Pay down credit card balances to below 30%.</span></div>
                            <div className="approval-header-text"><div className="approval-dots"></div> <span>Focus on high-balance cards first.</span></div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 mb-3 ps-0 pe-0 pe-md-2 ">
                    <div className="approval-con">
                        <div className="approval-header d-flex justify-content-between">
                            <p className="approval-header-title">3. Avoid New Credit Applications</p>
                            <img src="../../../images/credit-score/no-handshake.png" alt="no-handshake" className="approval-header-img"/>
                        </div>
                        <div className="ps-3">
                            <div className="approval-header-text"><div className="approval-dots"></div> <span>Refrain from applying for new loans or cards.</span></div>
                            <div className="approval-header-text"><div className="approval-dots"></div> <span>Each inquiry impacts approval chances.</span></div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-3 m-0 ps-0 ps-md-2 pe-0 ">
                    <div className="approval-con">
                        <div className="approval-header d-flex justify-content-between">
                            <p className="approval-header-title">4. Increase Income or Lower FOIR</p>
                            <img src="../../../images/credit-score/income-growth.png" alt="income-growth" className="approval-header-img"/>
                        </div>
                        <div className="ps-3">
                            <div className="approval-header-text"><div className="approval-dots"></div> <span>Explore ways to boost monthly income.</span></div>
                            <div className="approval-header-text"><div className="approval-dots"></div> <span>Cut unnecessary expenses to improve FOIR.</span></div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-3 ps-0 pe-0 pe-md-2 ">
                    <div className="approval-con">
                        <div className="approval-header d-flex justify-content-between">
                            <p className="approval-header-title">5. Consider Secured Loans</p>
                            <img src="../../../images/credit-score/secure-shield.png" alt="secure-shield" className="approval-header-img"/>
                        </div>
                        <div className="ps-3">
                            <div className="approval-header-text"><div className="approval-dots"></div> <span>Shift some unsecured debt to secured loans.</span></div>
                            <div className="approval-header-text"><div className="approval-dots"></div> <span>Secured loans often have lower interest rates</span></div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-3 m-0 ps-0 ps-md-2 pe-0 ">
                    <div className="approval-con">
                        <div className="approval-header d-flex justify-content-between">
                            <p className="approval-header-title">6. Maintain Timely Payments</p>
                            <img src="../../../images/credit-score/cash-payment.png" alt="cash-payment" className="approval-header-img"/>
                        </div>
                        <div className="ps-3">
                            <div className="approval-header-text"><div className="approval-dots"></div> <span>Keep all payments on time to reinforce good credit behavior.</span></div>
                            <div className="approval-header-text"><div className="approval-dots"></div> <span>Avoid late payments to support creditworthiness.</span></div>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
      </div>
    </>
  );
};

export default Index;

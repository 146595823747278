import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import MetadataContext from "../../MetadataContext";

const Payoff = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(""); // Holds the selected amount
  const [active, setActive] = useState(null); // Active selection index
  const [categories, setCategories] = useState([]);
  const [progress, setProgress] = useState(20);
  const isSmallScreen = useMediaQuery("(max-width:576px)");

  const storedValue = localStorage.getItem("selectedFundReason");

  // ✅ `storedValue` ke change hone par categories update honge
  useEffect(() => {
    const updatedCategories =
      storedValue === "Buying a new home"
        ? [
            { name: "Rs. 1,00,000 - 5,00,000", maxValue: "200000" },
            { name: "Rs. 5,00,000 - Rs. 10,00,000", maxValue: "500000" },
            { name: "Rs. 10,00,000 - Rs. 25,00,000", maxValue: "1000000" },
            { name: "More than Rs. 25,00,000", maxValue: "2500000" },
          ]
        : [
            { name: "Less than Rs. 1,00,000", maxValue: "50000" },
            { name: "Rs. 1,00,000 - 5,00,000", maxValue: "200000" },
            { name: "Rs. 5,00,000 - Rs. 10,00,000", maxValue: "500000" },
            { name: "Rs. 10,00,000 - Rs. 25,00,000", maxValue: "1000000" },
            { name: "More than Rs. 25,00,000", maxValue: "2500000" },
          ];

    setCategories(updatedCategories);
  }, [storedValue]);

  // ✅ Jab `categories` update ho to sirf tabhi default value set karni hai jab `moneyneed` localStorage me na ho
  useEffect(() => {
    if (categories.length > 0) {
      const storedMoneyNeed = localStorage.getItem("moneyneed");

      if (!storedMoneyNeed) {
        const defaultValue = categories[0].maxValue; // **0 index ka maxValue set hoga agar pehle koi value store nahi hai**
        setValue(defaultValue);
        setActive(0);
        localStorage.setItem("moneyneed", defaultValue);
      } else {
        setValue(storedMoneyNeed);
        const index = categories.findIndex(
          (category) => category.maxValue === storedMoneyNeed
        );
        setActive(index !== -1 ? index : 0);
      }
    }
  }, [categories]); // **Runs after categories update**

  const handleCategorySelect = (category, index) => {
    setActive(index);
    setValue(category.maxValue);
    localStorage.setItem("moneyneed", category.maxValue);
  };

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Select Payoff Loan - Minemi",
      pageDescription:
        "Choose the best payoff loan option with Minemi . Simplify your repayments and save money.",
      pageKeywords:
        "payoff loan options, loan payoff calculator, loan repayment options, debt payoff strategies, loan refinancing, loan consolidation, loan payoff plan, loan repayment calculator",

      canonicalUrl: "https://minemi.ai/select-payoff-loan",
    });
  }, [setMetadata]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8", padding: "0 0 20px" }}>
        <div className="progress-bar-background" />
        <div className="progress-bar" style={{ width: `${progress}%` }} />
        <div className="container pt-4">
          {/* Title */}
          <div className="question-title pt-4">How much money do you need?</div>

          {/* Buttons */}
          <div
            className="pt-4"
            style={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              gap: 16,
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              flexWrap: "wrap",
            }}
          >
            {categories.map((category, index) => (
              <div
                key={index}
                className={`category-button  ${
                  active === index ? "active" : ""
                }`}
                onClick={() => handleCategorySelect(category, index)}
                style={{
                  marginBottom: "4%",
                  fontFamily: "Archivo",
                  fontSize: 16,
                  fontWeight: 400,
                  width: isSmallScreen ? "100%" : "300px",
                }}
              >
                {category.name}
              </div>
            ))}
          </div>

          {/* Next Button */}
          <div
            className="next-button"
            style={{ fontFamily: "Archivo" }}
            onClick={() =>
              navigate("/select-prefer", { state: { moneyneed: value } })
            }
          >
            NEXT
          </div>
        </div>
      </div>
    </>
  );
};

export default Payoff;

import { ChevronLeft, ChevronRight, Star } from "lucide-react";
import React, { useState, useEffect } from "react";
import {
  FaPiggyBank,
  FaShieldAlt,
  FaClock,
  FaChartBar,
  FaHeadphonesAlt,
  FaStar,
} from "react-icons/fa";
import { motion } from "framer-motion";
import { useSwipeable } from "react-swipeable";
const benefits = [
  {
    title: "Unmatched Savings",
    description: "Save big on your EMIs with competitive interest rates.",
    icon: (
      <FaPiggyBank
        className="w-8 h-8 md:w-12 md:h-12 text-[#59266d]"
        style={{ backgroundColor: "#ebe8fb" }}
      />
    ),
  },
  {
    title: "Top-Up Flexibility",
    description:
      "Access additional funds for any personal or professional need.",
    icon: (
      <FaShieldAlt
        className="w-8 h-8 md:w-12 md:h-12 text-[#59266d]"
        style={{ backgroundColor: "#ebe8fb" }}
      />
    ),
  },
  {
    title: "Transparent Process",
    description: "No hidden fees, no surprises—just clear and honest terms.",
    icon: (
      <FaClock
        className="w-8 h-8 md:w-12 md:h-12 text-[#59266d]"
        style={{ backgroundColor: "#ebe8fb" }}
      />
    ),
  },
  {
    title: "Quick Approvals",
    description:
      "Minimal paperwork and fast processing to get your loan top-up approved in no time.",
    icon: (
      <FaChartBar
        className="w-8 h-8 md:w-12 md:h-12 text-[#59266d]"
        style={{ backgroundColor: "#ebe8fb" }}
      />
    ),
  },
  {
    title: "Bank Comparisons",
    description:
      "Easily compare interest rates and loan options from top financial institutions.",
    icon: (
      <FaHeadphonesAlt
        className="w-8 h-8 md:w-12 md:h-12 text-[#59266d]"
        style={{ backgroundColor: "#ebe8fb" }}
      />
    ),
  },
  {
    title: "Expert Assistance",
    description: "Dedicated loan advisors to guide you every step of the way.",
    icon: (
      <FaStar
        className="w-8 h-8 md:w-12 md:h-12 text-[#59266d]"
        style={{ backgroundColor: "#ebe8fb" }}
      />
    ),
  },
];

const testimonials = [
  {
    name: "Rahul S.",
    text: "MINEMI helped me save ₹8,000 per month on my home loan EMI. Their process was quick and transparent!",
  },
  {
    name: "Priya M.",
    text: "I was hesitant at first, but MINEMI made my loan top-up process so easy. Highly recommended!",
  },
  {
    name: "Amit K.",
    text: "The expert assistance from MINEMI was invaluable. They found me the best rates for my loan transfer.",
  },
];

const WhyChooseMinemi = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [direction, setDirection] = useState(0);
  const nextTestimonial = () => {
    setDirection(1);
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  };
  const prevTestimonial = () => {
    setDirection(-1);
    setCurrentTestimonial(
      (prev) => (prev - 1 + testimonials.length) % testimonials.length
    );
  };
  const handlers = useSwipeable({
    onSwipedLeft: nextTestimonial,
    onSwipedRight: prevTestimonial,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // Move to next testimonial

  // Move to previous testimonial

  // Swipe event handling for touch or mouse events
  const handleSwipe = (event) => {
    if (event.type === "touchend") {
      const touchEndX = event.changedTouches[0].clientX;
      const touchStartX = event.changedTouches[0].clientX;

      if (touchEndX < touchStartX) {
        nextTestimonial();
      } else if (touchEndX > touchStartX) {
        prevTestimonial();
      }
    }
  };

  useEffect(() => {
    // Add event listeners for swipe
    window.addEventListener("touchstart", handleSwipe);
    window.addEventListener("touchend", handleSwipe);

    return () => {
      // Cleanup the event listeners
      window.removeEventListener("touchstart", handleSwipe);
      window.removeEventListener("touchend", handleSwipe);
    };
  }, []);

  return (
    <section className="py-6 md:py-16" style={{ backgroundColor: "#f8f8f8" }}>
      <h2
        className="text-xl md:text-3xl font-bold bg-transparent text-center mb-3 md:mb-4 text-[#59266d]"
        style={{
          color: "#59266D",
          fontSize: "30px",
          lineHeight: "36px",
          fontWeight: "700",
        }}
      >
        Why MINEMI is your trusted partner?
      </h2>
      <p
        className="text-xs md:text-lg bg-transparent text-center mb-6 md:mb-12 max-w-2xl mx-auto"
        style={{
          color: "#59266D",
          fontSize: "18px",
          lineHeight: "28px",
          fontWeight: "400",
        }}
      >
        At MINEMI, we make loan transfers and top-ups simple, transparent, and
        tailored to your needs.
      </p>
      <div className="container mx-auto  px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-3 gap-3 md:gap-8 mb-6 md:mb-12">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="bg-[#EBE8FC] p-3 md:p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
            >
              <div className="flex items-center bg-transparent mb-2 md:mb-4">
                <div className="mr-3 md:mr-4">
                  <p
                    style={{
                      backgroundColor: "#ebe8fb",
                    }}
                  >
                    {benefit.icon}
                  </p>
                </div>
                <h3
                  className="text-base md:text-xl font-semibold "
                  style={{
                    color: "#59266D",
                    fontSize: "20px",
                    lineHeight: "28px",
                    fontWeight: "600",
                  }}
                >
                  {benefit.title}
                </h3>
              </div>
              <p
                className="text-sm md:text-base text-gray-600 "
                style={{
                  color: "#4B5563",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "400",
                }}
              >
                {benefit.description}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          margin: "auto",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginLeft: "4%",
          marginRight: "4%",
        }}
      >
        <div
          className="container bg-[#59266d] mx-4 text-white p-4 md:p-8 rounded-lg  mb-6 "
          style={{
            backgroundColor: "#59266d",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h3
            className="text-xl md:text-2xl font-bold mb-2 text-center"
            style={{
              color: "#FFFFFF",
              fontSize: "24px",
              lineHeight: "32px",
              fontWeight: "700",
            }}
          >
            Save up to 20% on your EMI!
          </h3>
          <p
            className="text-sm md:text-base text-center"
            style={{
              color: "#FFFFFF",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "400",
            }}
          >
            Join thousands of satisfied customers who have reduced their loan
            burden.
          </p>
        </div>
      </div>
      <div
        className="mb-4 md:mb-12 "
        style={{
          marginTop: "3%",
          marginLeft: "5%",
          marginRight: "5%",
        }}
      >
        <h3 className="text-xl md:text-2xl font-bold mb-3 md:mb-4 text-center">
          What Our Customers Say
        </h3>
        <div
          className=" container bg-[#EBE8FC] p-4 md:p-6 rounded-lg shadow-md relative overflow-hidden"
          {...handlers}
          style={{
            marginTop: "3%",
            backgroundColor: "#EBE8FC",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="mb-2 md:mb-4"
            style={{
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Star
              className="text-yellow-400 w-4 h-4 md:w-6 md:h-6 mr-1"
              style={{ backgroundColor: "transparent" }}
            />
            <Star
              className="text-yellow-400 w-4 h-4 md:w-6 md:h-6 mr-1"
              style={{ backgroundColor: "transparent" }}
            />
            <Star
              className="text-yellow-400 w-4 h-4 md:w-6 md:h-6 mr-1"
              style={{ backgroundColor: "transparent" }}
            />
            <Star
              className="text-yellow-400 w-4 h-4 md:w-6 md:h-6 mr-1"
              style={{ backgroundColor: "transparent" }}
            />
            <Star
              className="text-yellow-400 w-4 h-4 md:w-6 md:h-6"
              style={{ backgroundColor: "transparent" }}
            />
          </div>
          <div
            className="px-8 md:px-12"
            style={{ backgroundColor: "transparent" }}
          >
            <motion.div
              key={currentTestimonial}
              initial={{ opacity: 0, x: direction > 0 ? 100 : -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: direction > 0 ? -100 : 100 }}
              transition={{ duration: 0.3 }}
              style={{ backgroundColor: "transparent" }}
            >
              <p
                className="text-sm md:text-lg mb-2 md:mb-4"
                style={{ backgroundColor: "transparent" }}
              >
                "{testimonials[currentTestimonial].text}"
              </p>
              <p
                className="font-semibold text-sm md:text-base"
                style={{ backgroundColor: "transparent" }}
              >
                - {testimonials[currentTestimonial].name}
              </p>
            </motion.div>
          </div>
          <button
            className="absolute top-1/2 left-1 md:left-2 transform -translate-y-1/2  rounded-full p-1 shadow-md make-none"
            onClick={prevTestimonial}
            style={{ backgroundColor: "transparent" }}
          >
            <ChevronLeft
              className="w-6 h-6 text-[#59266d]"
              style={{ backgroundColor: "transparent" }}
            />
          </button>
          <button
            className="absolute top-1/2 right-1 md:right-2 transform -translate-y-1/2 rounded-full p-1 shadow-md  make-none"
            onClick={nextTestimonial}
            style={{ backgroundColor: "transparent" }}
          >
            <ChevronRight
              className="w-6 h-6 text-[#59266d]"
              style={{ backgroundColor: "transparent" }}
            />
          </button>
        </div>
        <div
          className="flex justify-center mt-3 md:mt-4"
          style={{ backgroundColor: "transparent" }}
        >
          {testimonials.map((_, index) => (
            <button
              key={index}
              className={`w-2 h-2 md:w-3 md:h-3 rounded-full mx-1 ${
                index === currentTestimonial ? "bg-[#59266d]" : "bg-gray-300"
              }`}
              onClick={() => {
                setDirection(index > currentTestimonial ? 1 : -1);
                setCurrentTestimonial(index);
              }}
            />
          ))}
        </div>
      </div>
      <div
        className="flex bg-transparent mt-5 flex-wrap justify-center items-center gap-4 md:gap-8 mb-6 md:mb-12"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="text-center bg-transparent">
          <h4
            className="text-lg bg-transparent md:text-2xl font-bold text-[#59266d]"
            style={{
              color: "#59266D",
              fontSize: "24px",
              lineHeight: "32px",
              fontWeight: "700",
            }}
          >
            10,000+
          </h4>
          <p
            className="text-sm md:text-base bg-transparent"
            style={{
              color: "#59266D",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "400",
            }}
          >
            Customers Served
          </p>
        </div>
        <div className="text-center bg-transparent">
          <h4
            className="text-lg md:text-2xl font-bold text-[#59266d] bg-transparent"
            style={{
              color: "#59266D",
              fontSize: "24px",
              lineHeight: "32px",
              fontWeight: "700",
            }}
          >
            ₹50 Cr+
          </h4>
          <p
            className="text-sm md:text-base bg-transparent"
            style={{
              color: "#59266D",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "400",
            }}
          >
            EMIs Reduced
          </p>
        </div>
      </div>
      <div className="text-center bg-transparent">
        <a
          href="/login?goal=loan-top-up"
          className=""
          onClick={() => {
            localStorage.setItem("id", 3);
            window.scrollTo(0, 0);
          }}
        >
          <button className="bg-[#59266d] text-white px-4 py-2 md:px-8 md:py-3 rounded-full text-sm md:text-lg font-semibold hover:bg-purple-800 transition-colors change-width">
            Start Saving Now
          </button>
        </a>
      </div>
    </section>
  );
};

export default WhyChooseMinemi;

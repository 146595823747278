import React, { useEffect, useMemo, useState } from "react";
import { ChevronDown } from "lucide-react";

const Faq = ({ name }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [faqs, setFaqs] = useState([]);

  const idbiData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about IDBI Bank home loans",
        question:
          "How long does it take to get an IDBI Bank home loan approved?",
        answer:
          "IDBI Bank typically processes home loan applications within 5-10 working days. For salaried customers with excellent credit profiles, the 'Fast Track Home Loan' service can reduce this to just 3-5 working days. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through the IDBI GO Mobile app are processed more efficiently. Using MinEMI's tools can help streamline the process and potentially reduce the approval time by ensuring all documentation is complete and accurate from the start",
      },
      {
        question:
          "WWhat are the common reasons for IDBI Bank home loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. IDBI Bank places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an IDBI Bank home loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your debt-to-income ratio below 45%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Government employees and women applicants should highlight their status to avail special interest rate concessions. Having an existing relationship with IDBI Bank also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const lichousingData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about LIC Housing Finance home loans",
        question:
          "How long does it take to get an LIC Housing Finance home loan approved?",
        answer:
          "LIC Housing Finance typically processes home loan applications within 7-14 working days. For salaried customers with excellent credit profiles, the process can be expedited to 5-7 working days. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through the LIC HFL HOME app are processed more efficiently. Using MinEMI's tools can help streamline the process and potentially reduce the approval time by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question:
          "What are the common reasons for LIC Housing Finance home loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. LIC Housing Finance places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an LIC Housing Finance home loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your debt-to-income ratio below 50%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Women applicants should highlight their status to avail special interest rate concessions. Having an existing relationship with LIC or LIC Housing Finance also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const icicihousingData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about ICICI Home Finance home loans",
        question:
          "How long does it take to get an ICICI Home Finance home loan approved?",
        answer:
          "ICICI Home Finance typically processes home loan applications within 6-10 working days. For salaried customers with excellent credit profiles, the process can be expedited to 4-6 working days. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through the ICICI iMobile app are processed more efficiently. Using MinEMI's tools can help streamline the process and potentially reduce the approval time by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question:
          "What are the common reasons for ICICI Home Finance home loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. ICICI Home Finance places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an ICICI Home Finance home loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your debt-to-income ratio below 55%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Women applicants and existing ICICI Bank customers should highlight their status to avail special interest rate concessions. Having an existing relationship with ICICI Bank also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const pnbhousingData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about PNB Housing Finance home loans",
        question:
          "How long does it take to get a PNB Housing Finance home loan approved?",
        answer:
          "PNB Housing Finance typically processes home loan applications within 7-12 working days. For salaried customers with excellent credit profiles, the process can be expedited to 5-7 working days. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through the PNB HFL Customer Portal are processed more efficiently. Using MinEMI's tools can help streamline the process and potentially reduce the approval time by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question:
          "What are the common reasons for PNB Housing Finance home loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. PNB Housing Finance places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting a PNB Housing Finance home loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your debt-to-income ratio below 50%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Women applicants and government employees should highlight their status to avail special interest rate concessions. Having an existing relationship with PNB Bank also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const sbihousingData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about SBI Home Loans",
        question: " How long does it take to get an SBI Home Loan approved?",
        answer:
          "SBI typically processes home loan applications within 5-8 working days. For salaried customers with excellent credit profiles, the process can be expedited to 3-5 working days. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through the SBI YONO app are processed more efficiently. Using MinEMI's tools can help streamline the process and potentially reduce the approval time by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question: " What are the common reasons for SBI Home Loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. SBI places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an SBI Home Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your debt-to-income ratio below 45%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Women applicants, government employees, and defense personnel should highlight their status to avail special interest rate concessions. Having an existing relationship with SBI also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const adityahousingData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about Aditya Birla Finance Home Loans",
        question:
          " How long does it take to get an Aditya Birla Finance Home Loan approved?",
        answer:
          "Aditya Birla Finance typically processes home loan applications within 7-10 working days. For salaried customers with excellent credit profiles, the process can be expedited to 5-7 working days. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through the ABFL MyLoan app are processed more efficiently. Using MinEMI's tools can help streamline the process and potentially reduce the approval time by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question:
          "What are the common reasons for Aditya Birla Finance Home Loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. Aditya Birla Finance places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an Aditya Birla Finance Home Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 740, keep your debt-to-income ratio below 50%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Women applicants and salaried professionals should highlight their status to avail special interest rate concessions. Having an existing relationship with Aditya Birla Finance also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const lthousingData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about L&T Finance Home Loans",
        question:
          " How long does it take to get an L&T Finance Home Loan approved?",
        answer:
          "L&T Finance typically processes home loan applications within 7-10 working days. For salaried customers with excellent credit profiles, the process can be expedited to 5-7 working days. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through the L&T Finance Customer Portal are processed more efficiently. Using MinEMI's tools can help streamline the process and potentially reduce the approval time by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question:
          "What are the common reasons for L&T Finance Home Loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. L&T Finance places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an L&T Finance Home Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 730, keep your debt-to-income ratio below 55%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Women applicants and salaried professionals should highlight their status to avail special interest rate concessions. Having an existing relationship with L&T Finance also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );

  useEffect(() => {
    if (name === "checkidbihousingbankstatus") {
      setFaqs(idbiData);
    } else if (name === "checklichousingbankstatus") {
      setFaqs(lichousingData);
    } else if (name === "checkicicihousingbankstatus") {
      setFaqs(icicihousingData);
    } else if (name === "checkpnbhousingbankstatus") {
      setFaqs(pnbhousingData);
    } else if (name === "checksbihousingbankstatus") {
      setFaqs(sbihousingData);
    } else if (name === "checkadityahousingbankstatus") {
      setFaqs(adityahousingData);
    } else if (name === "checklthousingbankstatus") {
      setFaqs(lthousingData);
    } else {
      setFaqs([]);
    }
  }, [
    name,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    sbihousingData,
    adityahousingData,
    lthousingData,
  ]);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="py-2 bg-white border-t border-light-bg">
      <div className="container px-4 bg-transparent">
        <div className="w-4/5l mx-auto bg-transparent">
          <div className="text-center mb-4 bg-transparent">
            <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple">
              Frequently Asked Questions
            </h2>
            {faqs.length > 0 && (
              <p className="text-dark-grey mt-0 text-sm bg-transparent text-center">
                {faqs[0].h2} {/* Displaying dynamic h2 */}
              </p>
            )}
            {/* <p className="text-dark-grey mt-1 text-sm bg-transparent text-center">
              Get answers to common questions about personal loans
            </p> */}
          </div>
          <div className="space-y-2 bg-transparent">
            {faqs.length > 0 &&
              faqs.slice(0, 3).map((faq, index) => (
                <div
                  key={index}
                  className="border border-light-bg rounded-lg overflow-hidden"
                >
                  <button
                    className="flex items-center justify-between w-full p-3 text-left bg-white hover:bg-light-bg transition-colors"
                    onClick={() => toggleFaq(index)}
                    aria-expanded={openIndex === index}
                  >
                    <h3 className="font-medium text-primary-purple text-xs ">
                      {faq.question}
                    </h3>
                    <ChevronDown
                      className={`h-4 w-4 text-primary-purple transition-transform bg-transparent ${
                        openIndex === index ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                  {openIndex === index && (
                    <div className="p-3 border-t border-light-bg bg-[#ebe8fb]">
                      <p className="text-dark-grey text-xs bg-[#ebe8fb]">
                        {faq.answer}
                      </p>
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className="mt-3 text-center bg-transparent">
            <a
              href="/faq"
              className="inline-flex h-9 items-center justify-center rounded-md bg-teal px-4 text-xs font-medium text-white transition-colors bg-[#3c868b] hover:bg-[purple]"
            >
              View All FAQs
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;

import React from 'react'
import WhyMinemiSection from './WhyMinemiSection'
import LoanCheckerForm from './LoanCheckerForm'
import ComparisonSection from './ComparisonSection'
import HowItWorksSection from './HowItWorksSection'
import TestimonialSection from './TestimonialSection'
import SecuritySection from './SecuritySection'
import FaqSection from './FaqSection'
import FinalCta from './FinalCta'
import ScrollToTopbutton from './ScrollToTopButton'
import HeroSectionCredit from './HeroSectionCredit'

const CreditLandingPage = () => {
  return (
    <div className="flex min-h-screen flex-col items-center">
    <HeroSectionCredit />
    <div id="form-section" className="w-full">
      <LoanCheckerForm />
    </div>
    <WhyMinemiSection />
    <HowItWorksSection />
    <ComparisonSection />
    <TestimonialSection/>
    <SecuritySection />
    <FaqSection />
    <FinalCta />
    <ScrollToTopbutton />
  </div>
  )
}

export default CreditLandingPage
import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import MetadataContext from "../MetadataContext";

const Typesofpersonalloan = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Types of Personal Loans",
      pageDescription:
        "Personal loans are flexible tools. Minemi offers information to help users make informed decisions and explore loan types.",
      pageKeywords:
        "types of personal loans, personal loan guide, loan options, best personal loans, loan comparison, unsecured loans, secured loans, loan types explained, financial decisions, choosing a personal loan",
      canonicalUrl:
        "https://minemi.ai/loanpage/personalloan/types-of-personalloan",
    });
  }, [setMetadata]);

  return (
    <>
      <Helmet>
        <title>Types of Personal Loans</title>
        <meta
          name="description"
          content="Personal loans are flexible tools. Minemi offers information to help users make informed decisions and explore loan types."
        />
        <meta
          name="keywords"
          content="types of personal loans, personal loan guide, loan options, best personal loans, loan comparison, unsecured loans, secured loans, loan types explained, financial decisions, choosing a personal loan"
        />
        {/* <link rel="icon" type="image/png" href="./mLogo.png" /> */}
      </Helmet>
      <div
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className=" custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loans
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage/personalloan"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Personal Loan
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              Types of Personal Loans
            </Link>{" "}
          </p>
        </div>
        <h1
          className="homeloan-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            lineHeight: "55px",

            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          Types of Personal Loans: Comprehensive Guidance for Better Decisions
        </h1>
        <p
          className="homeloan-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 18,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: "24px",
          }}
        >
          Personal loans are versatile financial tools that cater to diverse
          financial needs. At Minemi, we focus on empowering users with
          comprehensive information to help them make informed and responsible
          choices. Explore the different types of personal loans, understand
          when to consider them, and navigate to dedicated pages for deeper
          insights and guidance.
        </p>
      </div>

      <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
        <div
          style={{
            marginTop: 35,
            color: "#59266D",
            fontWeight: 600,
            fontSize: 24,
            lineHeight: 1.2,
          }}
        >
          1) Home Renovation Loans:
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 15,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          Transform your living space with a loan tailored for home
          improvements.
        </div>
        <h4
          style={{
            marginTop: 10,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          Key Points to Consider:
        </h4>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 15,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          Ideal for enhancing property value or quality of living.
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 15,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          Borrow only for planned renovations; avoid overborrowing.
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 15,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          Compare interest rates and repayment terms across lenders.
        </div>
        <h4
          style={{
            marginTop: 18,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          When to Take:
        </h4>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 15,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          When you have a clear renovation plan and budget.
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 15,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          If savings alone cannot cover the renovation costs.
        </div>
        <h4
          style={{
            marginTop: 18,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          How Much to Take:
        </h4>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 15,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          Assess the total renovation costs and borrow accordingly.
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 15,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20px",
          }}
        >
          Learn more about Home Renovation Loans →
        </div>

        <div>
          <div
            style={{
              marginTop: 30,
              color: "#59266D",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            2) Wedding Loans
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Celebrate your special day without compromising financial stability.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            Key Points to Consider:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Choose short-term loans to ensure manageable repayments.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Avoid borrowing based on speculative future income.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Plan a budget for necessary expenses only.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            When to Take:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            When wedding expenses exceed your savings, but you have a repayment
            plan.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            If liquidating investments isn’t an option.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            How Much to Take:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Borrow only what you can comfortably repay.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Discover our Wedding Loan Guide →
          </div>
        </div>
        <div>
          <h3
            style={{
              marginTop: 30,
              color: "#59266D",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            3) Education Loans
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Invest in education and secure your future with loans for higher
            education or skill development.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            Key Points to Consider:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Understand repayment terms, including moratorium periods.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Borrow only for essential education-related costs.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Explore scholarships and grants to minimize loan amounts.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            When to Take:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            When pursuing higher education without sufficient savings.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            If scholarships only partially cover the costs.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            How Much to Take:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Borrow based on tuition fees and other essential expenses.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Explore Education Loan Options →
          </div>
        </div>
        <div>
          <div
            style={{
              marginTop: 30,
              color: "#59266D",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            4) Medical Loans
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Get financial support during health emergencies with quick,
            no-collateral loans.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            Key Points to Consider:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Borrow only what is necessary to keep repayments manageable.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Check existing insurance coverage before opting for a loan.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Focus on lenders offering quick disbursal with minimal
            documentation.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            When to Take:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            During emergencies requiring immediate funding.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            When insurance coverage is insufficient for medical expenses.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            How Much to Take:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Borrow the exact amount needed for medical treatments.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Find Out More About Medical Loans →
          </div>
        </div>
        <div>
          <div
            style={{
              marginTop: 30,
              color: "#59266D",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            5) Travel Loans
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Turn your travel dreams into reality with loans designed for
            vacations.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            Key Points to Consider:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Opt for short-term loans to avoid long-term financial strain.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Avoid borrowing based on uncertain future income.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Plan your travel budget to minimize borrowing.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            When to Take:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            When you lack sufficient savings for a major trip.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            If you prefer not to use high-interest credit cards.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            How Much to Take:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Borrow only the amount required for essential travel expenses.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Learn More About Travel Loans →
          </div>
        </div>

        <div>
          <h3
            style={{
              marginTop: 30,
              color: "#59266D",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            6) Debt Consolidation Loans
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Simplify multiple debts into one manageable loan with potentially
            lower interest rates.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            Key Points to Consider:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Consolidation should reduce your overall interest burden.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Avoid accumulating new debts after consolidation.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Review fees and charges associated with the new loan.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            When to Take:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            When managing multiple high-interest debts.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            If you seek a simpler, more manageable payment structure.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            How Much to Take:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            The total amount of outstanding debts you plan to consolidate.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Explore Debt Consolidation Loan Benefits →
          </div>
        </div>
        <div>
          <h3
            style={{
              marginTop: 30,
              color: "#59266D",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            7) Top-Up Loans
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Get additional funds on an existing loan without a new application.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            Key Points to Consider:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Compare the interest rate of the top-up loan with your existing
            loan.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Avoid if the new EMI has a higher interest component than the old
            loan.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Ideal for urgent needs when a fresh loan isn’t practical.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            When to Take:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            When you have an existing loan with a good repayment track record.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            If you need additional funds without complex documentation.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            How Much to Take:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Borrow only the additional amount required for your immediate needs.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Discover the Advantages of Top-Up Loans →
          </div>
        </div>
        <div>
          <h3
            style={{
              marginTop: 30,
              color: "#59266D",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            8) Overdraft Facility
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Flexible borrowing for uncertain or fluctuating cash requirements.
          </div>
          <h3
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            Key Points to Consider:
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Pay interest only on the amount utilized.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Avoid long-term dependency on overdrafts due to higher rates.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Suitable for short-term financial gaps.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            When to Take:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            When the required amount is uncertain or fluctuates.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            If funds can be rearranged within a short time frame.
          </div>
          <h4
            style={{
              marginTop: 18,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            How Much to Take:
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Utilize only the amount needed, keeping repayments feasible.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Learn More About Overdrafts →
          </div>
        </div>
      </div>
    </>
  );
};

export default Typesofpersonalloan;

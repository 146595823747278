import { useEffect, useState, useMemo } from "react";
// import { Helmet } from "react-helmet";
export function LoanStatusChecker({ name }) {
  const [data, setData] = useState({}); // Initialize with an empty object

  const idbiData = useMemo(
    () => [
      {
        h2: "Check Your IDBI Bank Home Loan Status",
        para: "Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          "Our mortgage experts are available to help you check your IDBI Bank home loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const lichousingData = useMemo(
    () => [
      {
        h2: "Check Your LIC Housing Finance Home Loan Status",
        para: "Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          "Our mortgage experts are available to help you check your LIC Housing Finance home loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const icicihousingData = useMemo(
    () => [
      {
        h2: "Check Your ICICI Home Finance Home Loan Status",
        para: "Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          "Our mortgage experts are available to help you check your ICICI Home Finance home loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const pnbhousingData = useMemo(
    () => [
      {
        h2: "Check Your PNB Housing Finance Home Loan Status",
        para: "Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          "Our mortgage experts are available to help you check your PNB Housing Finance home loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const sbihousingData = useMemo(
    () => [
      {
        h2: "Check Your SBI Home Loan Status",
        para: "Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          "Our mortgage experts are available to help you check your SBI Home Loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const adityahousingData = useMemo(
    () => [
      {
        h2: "Check Your Aditya Birla Finance Home Loan Status",
        para: "Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          "Our mortgage experts are available to help you check your Aditya Birla Finance Home Loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const lthousingData = useMemo(
    () => [
      {
        h2: "Check Your L&T Finance Home Loan Status",
        para: "Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          "Our mortgage experts are available to help you check your L&T Finance Home Loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );

  useEffect(() => {
    if (name === "checkidbihousingbankstatus") {
      setData(idbiData[0]);
    } else if (name === "checklichousingbankstatus") {
      setData(lichousingData[0]);
    } else if (name === "checkicicihousingbankstatus") {
      setData(icicihousingData[0]);
    } else if (name === "checkpnbhousingbankstatus") {
      setData(pnbhousingData[0]);
    } else if (name === "checksbihousingbankstatus") {
      setData(sbihousingData[0]);
    } else if (name === "checkadityahousingbankstatus") {
      setData(adityahousingData[0]);
    } else if (name === "checklthousingbankstatus") {
      setData(lthousingData[0]);
    } else {
      setData({}); // Reset data to an empty object if no match
    }
  }, [
    name,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    sbihousingData,
    adityahousingData,
    lthousingData,
  ]);

  // Render only when data is available
  if (!data.h2) {
    return null; // Return null if data is not available
  }

  return (
    <section
      id="loan-status-checker"
      className="py-3 bg-white border-t border-b border-gray-200"
    >
      <div className="container md:px-6 bg-transparent ">
        <div className="mx-auto sd:w-full md:w-4/5 bg-transparent">
          <div className="text-center mb-6 bg-transparent">
            <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple bg-transparent">
              {data.h2}
            </h2>
            <p className="text-dark-grey mt-0 text-sm bg-transparent text-center">
              {data.para}
            </p>
          </div>
          <div
            className=" px-3 py-3 rounded-lg border border-gray-200 shadow-sm"
            style={{ backgroundColor: "#ebe8fb" }}
          >
            <div className="text-center bg-transparent">
              {data.icon}

              <h3 className="text-lg font-bold text-primary-purple">
                {data.h3}
              </h3>
              <p className="text-dark-grey text-sm bg-transparent text-center">
                {data.para2}
              </p>
              <div className="flex flex-col gap-3 bg-transparent">
                <a
                  href="/trackloanstatus"
                  className="inline-flex mt-2 h-12 items-center justify-center rounded-md border border-primary-purple  px-6 text-sm font-medium text-white text-primary-purple bg-[#3b868b] hover:bg-[#311569] transition-colors w-full"
                >
                  Check Your Application Status
                </a>
                <a
                  href="#loan-predictor"
                  className="inline-flex h-12 items-center justify-center rounded-md border border-primary-purple  text-purple-900 px-6 text-sm font-medium text-primary-purple  transition-colors w-full  bg-[white] hover:bg-[#ebe8fb]"
                >
                  Predict Your Approval Chances
                </a>
              </div>
            </div>
          </div>
          <div className="mt-2 flex justify-center text-xs text-dark-grey bg-transparent">
            <p className="bg-transparent">
              Need immediate assistance?{" "}
              <a href="/contactus" className="text-teal hover:underline">
                {data.text}
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

import LoanApplicationForm from "./LoanApplicationForm";

export default function Runningloan() {
  return (
    <div
      className="min-h-screen text-gray-800"
      style={{ backgroundColor: "#f8f8f8" }}
    >
      {/* Header */}

      {/* Hero Section - No Image */}
      <section className="bg-[#3A1E6D] text-white py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight mb-6">
              Track all your running loans and credit cards in one place
            </h1>
            <p className="text-lg md:text-xl opacity-90 mb-6">
              Get a <span className="font-bold">complete view</span> of all your
              loans, credit cards, overdrafts and pay later accounts in one
              dashboard.
            </p>
            <p className="text-lg opacity-80 mb-8">
              Ensure every paid off loan is completely marked as closed, avoid
              hidden dues and stay in control of your finances.
            </p>
            <button
              className="bg-[#3BBAA0] hover:bg-[#2A9D87] text-white px-6 py-2 rounded-md flex items-center mx-auto"
              onClick={() =>
                document
                  .getElementById("application-form")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
            >
             Track My loans
            </button>
          </div>
        </div>
      </section>

      {/* Application Form Section */}
      <section className="py-16 bg-[#f8f8f8]" id="application-form">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto">
            <div className="bg-[#3A1E6D] text-white p-6 rounded-t-lg text-center">
              <h2 className="text-2xl font-bold">
                Check Your Running Loan 
              </h2>
              <p className="mt-2">
                It's free, instant, and won't affect your credit score
              </p>
            </div>

            <div
              className=" rounded-b-lg shadow-md"
              style={{ backgroundColor: "#FFFFFF" }}
            >
              <LoanApplicationForm />
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-16 " style={{ backgroundColor: "#f8f8f8" }}>
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4 text-[#3A1E6D]">
              Why Track Your Loans with MinEMI?
            </h2>
            <p className="text-gray-700 max-w-2xl mx-auto">
              Minemi helps you take control of your financial life by providing
              a clear view of all your loans.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            <div className="bg-white p-6 rounded-lg border border-gray-200 hover:shadow-lg transition-all">
              <div className="w-12 h-12 rounded-full bg-[#3A1E6D]/10 flex items-center justify-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-[#3A1E6D]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div className="text-xl font-semibold mb-3 text-[#3A1E6D]">
                Complete Visibility
              </div>
              <p className="text-gray-700">
                Get a consolidated view of all your running and closed loans
                across banks in one dashboard.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg border border-gray-200 hover:shadow-lg transition-all">
              <div className="w-12 h-12 rounded-full bg-[#3A1E6D]/10 flex items-center justify-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-[#3A1E6D]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div className="text-xl font-semibold mb-3 text-[#3A1E6D]">
                Avoid Hidden Dues
              </div>
              <p className="text-gray-700">
                Identify and prevent unexpected charges by ensuring all paid
                loans are properly marked as closed.
              </p>
            </div>

            <div className="bg-white p-6 rounded-lg border border-gray-200 hover:shadow-lg transition-all">
              <div className="w-12 h-12 rounded-full bg-[#3A1E6D]/10 flex items-center justify-center mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-[#3A1E6D]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                  />
                </svg>
              </div>
              <div className="text-xl font-semibold mb-3 text-[#3A1E6D]">
                Improve Credit Score
              </div>
              <p className="text-gray-700">
                Ensure all your closed loans are properly reported as 'closed'
                on your credit report to maintain a healthy score.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="py-16 bg-[#efefff]">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4 text-[#3A1E6D]">
              How It Works
            </h2>
            <p className="text-gray-700 max-w-2xl mx-auto">
              Three simple steps to take control of your loans
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            <div className="text-center">
              <div className="w-16 h-16 rounded-full bg-[#3A1E6D] text-white flex items-center justify-center mx-auto mb-4 text-xl font-bold">
                1
              </div>
              <div className="text-xl font-semibold mb-3 text-[#3A1E6D]">
                Enter Your Details
              </div>
              <p className="text-gray-700">
                Provide your basic information and give consent to check your
                loan status.
              </p>
            </div>

            <div className="text-center">
              <div className="w-16 h-16 rounded-full bg-[#3A1E6D] text-white flex items-center justify-center mx-auto mb-4 text-xl font-bold">
                2
              </div>
              <div className="text-xl font-semibold mb-3 text-[#3A1E6D]">
                Secure Verification
              </div>
              <p className="text-gray-700">
                MinEMI securely fetches your loan details across banks using
                bank-level encryption.
              </p>
            </div>

            <div className="text-center">
              <div className="w-16 h-16 rounded-full bg-[#3A1E6D] text-white flex items-center justify-center mx-auto mb-4 text-xl font-bold">
                3
              </div>
              <div className="text-xl font-semibold mb-3 text-[#3A1E6D]">
                Get Complete Overview
              </div>
              <p className="text-gray-700">
                View a comprehensive dashboard of all your loans and their
                current status.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 " style={{ backgroundColor: "#f8f8f8" }}>
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto bg-[#3A1E6D] text-white p-10 rounded-lg text-center">
            <h2 className="text-3xl font-bold mb-4">
              Ready to Take Control of Your Loans?
            </h2>
            <p className="mb-8 max-w-2xl mx-auto">
              Join thousands of users who have already improved their financial
              health with MinEMI.
            </p>
            <button
              className="bg-[#3BBAA0] hover:bg-[#2A9D87] text-white px-8 py-2 rounded-md text-lg font-medium"
              onClick={() =>
                document
                  .getElementById("application-form")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
            >
              Check Loan  Now
            </button>
            <p className="mt-4 text-sm opacity-80">
              No credit card required. Free forever.
            </p>
          </div>
        </div>
      </section>

      {/* Footer */}
    </div>
  );
}

import { useState } from "react";
import { Button, Card, CardContent } from "@mui/material";
import { motion } from "framer-motion";

export default function WhyMinemi() {
  const [hoveredCard, setHoveredCard] = useState(null);

  const features = [
    {
      title: "100% Transparency",
      description:
        "We disclose all costs upfront, with no hidden fees or surprises.",
    },
    {
      title: "Fee-Free Model",
      description:
        "Minemi doesn't charge processing fees, late fees, or prepayment penalties.",
    },
    {
      title: "User Empowerment",
      description:
        "We provide tools and education to help you make informed financial decisions.",
    },
  ];

  return (
    <section className="space-y-4 py-6">
      <h2 className="text-xl sm:text-2xl font-bold text-[#26292b]">
        Why Choose Minemi?
      </h2>
      <div className="grid sm:grid-cols-3 gap-4">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            whileHover={{ scale: 1.05 }}
            onHoverStart={() => setHoveredCard(index)}
            onHoverEnd={() => setHoveredCard(null)}
          >
            <Card
              className="bg-[#ebe8fc] rounded-xl h-full"
              style={{ backgroundColor: "#ebe8fc" }}
            >
              <CardContent style={{ backgroundColor: "transparent" }}>
                <p className="card_pare text-lg sm:text-xl font-bold text-[#59266d]">
                  {feature.title}
                </p>
              </CardContent>
              <CardContent style={{ backgroundColor: "transparent" }}>
                <p className="card_pare text-sm sm:text-base">
                  {feature.description}
                </p>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </div>
      <div className="text-center mt-6">
        <Button className="bg-[#59266d] hover:bg-[#3a868b] text-white rounded-full px-6 py-2 text-sm sm:text-base">
          Check my Rate
        </Button>
      </div>
    </section>
  );
}

import { CheckCircle } from "lucide-react";
import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

export function MinemiTools({ name }) {
  const [data, setData] = useState({});

  const kotakData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Home Loan Approval Predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Need Expert Kotak Bank Guidance?",
        description:
          "ur mortgage experts can help you navigate the Kotak Bank Home Loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-transparent"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Home Loan Approval Predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-transparent"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage experts can help you navigate the Bank of Baroda  Bank Home Loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const idfcData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Home Loan Approval Predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check You Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Need Expert Aditya Birla Finance Guidance?",
        description:
          "Our mortgage experts can help you navigate the IDFC FIRST Bank Home Loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: " Use Home Loan Approval predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Cheak Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage experts can help you navigate the HDFC Bank Home Loan process and maximize your approval chances.",
        link: {
          text: "Sehedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: " Use Home Loan Approval predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Cheak Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description: "Cheak Your Eligibility Now",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Home Loan Approval Predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage experts can help you navigate the Axis Bank Home Loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Home Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage experts can help you navigate the ICICI Bank Home Loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "MinEMI Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Home Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "MinEMI Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage experts can help you navigate the Punjab National Bank Home Loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "MMaximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predicto",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Home Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage experts can help you navigate the IndusInd Bank Home Loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Home Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage experts can help you navigate the Tata Housing Finance Home Loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      title: "MinEMI's YES Bank Home Loan Success Tools",
      description:
        "Specialized tools to maximize your YES Bank home loan approval chances",
      tools: [
        {
          name: "YES Bank Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your profile against YES Bank's specific home loan approval criteria to predict your chances and suggest improvements.",
          features: [
            "Analyzes 20+ YES Bank-specific home loan approval factors",
            "Predicts approval probability with 90% accuracy for YES Bank",
            "Provides personalized action plan to boost approval chances",
          ],
          link: {
            text: "Use Home Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Property Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Verify if your chosen property meets YES Bank's home loan eligibility criteria and get insights on property valuation.",
          features: [
            "Checks property against YES Bank's approved project list",
            "Provides estimated property valuation range",
            "Identifies potential legal issues before application",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Need Expert YES Bank Home Loan Guidance?",
        description:
          "Our mortgage specialists have deep expertise with YES Bank's home loan process and can help you navigate every step for maximum success.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      title: "MinEMI's Union Bank Home Loan Success Tools",
      description:
        "pecialized tools to maximize your Union Bank home loan approval chances",
      tools: [
        {
          name: "Union Bank Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your profile against Union Bank's specific home loan approval criteria to predict your chances and suggest improvements.",
          features: [
            "Analyzes 20+ Union Bank-specific home loan approval factors",
            "Predicts approval probability with 90% accuracy for Union Bank",
            "Provides personalized action plan to boost approval chances",
          ],
          link: {
            text: "Use Home Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "PMAY Subsidy Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your eligibility for Pradhan Mantri Awas Yojana (PMAY) subsidy with Union Bank home loans and maximize your benefits.",
          features: [
            "Determines your exact PMAY subsidy amount based on income category",
            "Provides step-by-step guidance for subsidy application",
            "Shows how subsidy reduces your EMI and interest burden",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Need Expert Union Bank Home Loan Guidance?",
        description:
          "Our mortgage specialists have deep expertise with Union Bank's home loan process and can help you navigate every step for maximum success.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      title: "MinEMI's Bajaj Finance Home Loan Success Tools",
      description:
        "Specialized tools to maximize your Bajaj Finance home loan approval chances",
      tools: [
        {
          name: "Bajaj Finance Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your profile against Bajaj Finance's specific home loan approval criteria to predict your chances and suggest improvements.",
          features: [
            "Analyzes 20+ Bajaj Finance-specific home loan approval factors",
            "Predicts approval probability with 90% accuracy for Bajaj Finance",
            "Provides personalized action plan to boost approval chances",
          ],
          link: {
            text: "Use Home Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Home Loan Takeover Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your savings by transferring your existing home loan to Bajaj Finance and explore top-up loan options.",
          features: [
            "Compares your current loan with Bajaj Finance's offering",
            "Shows potential interest savings and reduced EMI",
            "Calculates additional top-up loan eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage specialists have deep expertise with Bajaj Finance's home loan process and can help you navigate every step for maximum success.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      title: "MinEMI's Federal Bank Home Loan Success Tools",
      description:
        "Specialized tools to maximize your Federal Bank home loan approval chances",
      tools: [
        {
          name: "Federal Bank Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and predicts your loan approval chances before you apply.",
          features: [
            "Predicts approval probability with 92% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "MinEMI Eligibility Checker",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact loan eligibility amount based on your income, existing EMIs, and credit score.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our loan specialists can provide personalized advice to help you navigate the Federal Bank loan process successfully.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      title: "MinEMI's Canara Bank Home Loan Success Tools",
      description:
        "Specialized tools to maximize your Canara Bank home loan approval chances",
      tools: [
        {
          name: "Canara Bank Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your profile against Canara Bank's specific home loan approval criteria to predict your chances and suggest improvements.",
          features: [
            "Analyzes 20+ Canara Bank-specific home loan approval factors",
            "Predicts approval probability with 90% accuracy for Canara Bank",
            "Provides personalized action plan to boost approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Budget Home Loan Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your eligibility for Canara Bank's Budget Home Loan scheme designed for lower and middle-income groups with special benefits.",
          features: [
            "Determines your eligibility for Canara Bank's Budget Home Loan",
            "Shows potential interest savings and reduced EMI",
            "Calculates additional benefits for eligible applicants",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage specialists have deep expertise with Canara Bank's home loan process and can help you navigate every step for maximum success.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      title: "MinEMI's Bank of India Home Loan Success Tools",
      description:
        "Specialized tools to maximize your Bank of India home loan approval chances",
      tools: [
        {
          name: "Bank of India Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your profile against Bank of India's specific home loan approval criteria to predict your chances and suggest improvements.",
          features: [
            "Analyzes 20+ Bank of India-specific home loan approval factors",
            "Predicts approval probability with 90% accuracy for Bank of India",
            "Provides personalized action plan to boost approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Star Home Loan Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your eligibility for Bank of India's Star Home Loan scheme with premium benefits and special interest rate concessions.",
          features: [
            "CDetermines your eligibility for Bank of India's Star Home Loan",
            "Shows potential interest savings and reduced EMI",
            "Calculates additional benefits for eligible applicants",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Want Expert Guidance?",
        description:
          "Our mortgage specialists have deep expertise with Bank of India's home loan process and can help you navigate every step for maximum success.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      title: "MinEMI's RBL Bank Home Loan Success Tools",
      description:
        "Specialized tools to maximize your RBL Bank home loan approval chances",
      tools: [
        {
          name: "RBL Bank Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your profile against RBL Bank's specific home loan approval criteria to predict your chances and suggest improvements.",
          features: [
            "Analyzes 20+ RBL Bank-specific home loan approval factors",
            "Predicts approval probability with 90% accuracy for RBL Bank",
            "Provides personalized action plan to boost approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Express Home Loan Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your eligibility for RBL Bank's Express Home Loan program with in-principle approval within 24 hours",
          features: [
            "Determines your eligibility for RBL Bank's Express Home Loan",
            "Shows potential interest savings and reduced EMI",
            "Calculates additional benefits for eligible applicants",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Want Expert Guidance?",
        description:
          "Our mortgage specialists have deep expertise with RBL Bank's home loan process and can help you navigate every step for maximum success.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const ltData = useMemo(
    () => ({
      title: "MinEMI's L&T Bank Home Loan Success Tools",
      description:
        "Specialized tools to maximize your L&T Bank home loan approval chances",
      tools: [
        {
          name: "L&T Bank Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your profile against L&T Bank's specific home loan approval criteria to predict your chances and suggest improvements.",
          features: [
            "Analyzes 20+ L&T Bank-specific home loan approval factors",
            "Predicts approval probability with 90% accuracy for L&T Bank",
            "Provides personalized action plan to boost approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Express Home Loan Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your eligibility for L&T Bank's Express Home Loan program with in-principle approval within 24 hours",
          features: [
            "Determines your eligibility for L&T Bank's Express Home Loan",
            "Shows potential interest savings and reduced EMI",
            "Calculates additional benefits for eligible applicants",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Want Expert Guidance?",
        description:
          "Our mortgage specialists have deep expertise with L&T Bank's home loan process and can help you navigate every step for maximum success.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const idbiData = useMemo(
    () => ({
      title: "MinEMI's IDBI Bank Home Loan Success Tools",
      description:
        "Specialized tools to maximize your IDBI Bank home loan approval chances",
      tools: [
        {
          name: "IDBI Bank Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your profile against IDBI Bank's specific home loan approval criteria to predict your chances and suggest improvements.",
          features: [
            "Analyzes 20+ IDBI Bank-specific home loan approval factors",
            "Predicts approval probability with 90% accuracy for IDBI Bank",
            "Provides personalized action plan to boost approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Express Home Loan Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your eligibility for IDBI Bank's Express Home Loan program with in-principle approval within 24 hours",
          features: [
            "Determines your eligibility for IDBI Bank's Express Home Loan",
            "Shows potential interest savings and reduced EMI",
            "Calculates additional benefits for eligible applicants",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Want Expert Guidance?",
        description:
          "Our mortgage specialists have deep expertise with IDBI Bank's home loan process and can help you navigate every step for maximum success.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const lichousingData = useMemo(
    () => ({
      title: "MinEMI's LIC Housing Finance Bank Home Loan Success Tools",
      description:
        "Specialized tools to maximize your LIC Housing Finance Bank home loan approval chances",
      tools: [
        {
          name: "LIC Housing Finance Bank Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your profile against LIC Housing Finance Bank's specific home loan approval criteria to predict your chances and suggest improvements.",
          features: [
            "Analyzes 20+ LIC Housing Finance Bank-specific home loan approval factors",
            "Predicts approval probability with 90% accuracy for LIC Housing Finance Bank",
            "Provides personalized action plan to boost approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Express Home Loan Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your eligibility for LIC Housing Finance Bank's Express Home Loan program with in-principle approval within 24 hours",
          features: [
            "Determines your eligibility for LIC Housing Finance Bank's Express Home Loan",
            "Shows potential interest savings and reduced EMI",
            "Calculates additional benefits for eligible applicants",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Want Expert Guidance?",
        description:
          "Our mortgage specialists have deep expertise with LIC Housing Finance Bank's home loan process and can help you navigate every step for maximum success.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const icicihousingData = useMemo(
    () => ({
      title: "MinEMI's ICICI Home Finance Bank Home Loan Success Tools",
      description:
        "Specialized tools to maximize your ICICI Home Finance Bank home loan approval chances",
      tools: [
        {
          name: "ICICI Home Finance Bank Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your profile against ICICI Home Finance Bank's specific home loan approval criteria to predict your chances and suggest improvements.",
          features: [
            "Analyzes 20+ ICICI Home Finance Bank-specific home loan approval factors",
            "Predicts approval probability with 90% accuracy for ICICI Home Finance Bank",
            "Provides personalized action plan to boost approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Express Home Loan Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your eligibility for ICICI Home Finance Bank's Express Home Loan program with in-principle approval within 24 hours",
          features: [
            "Determines your eligibility for ICICI Home Finance Bank's Express Home Loan",
            "Shows potential interest savings and reduced EMI",
            "Calculates additional benefits for eligible applicants",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Want Expert Guidance?",
        description:
          "Our mortgage specialists have deep expertise with ICICI Home Finance Bank's home loan process and can help you navigate every step for maximum success.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const pnbhousingData = useMemo(
    () => ({
      title: "MinEMI's PNB Home Finance Bank Home Loan Success Tools",
      description:
        "Specialized tools to maximize your PNB Home Finance Bank home loan approval chances",
      tools: [
        {
          name: "PNB Home Finance Bank Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your profile against PNB Home Finance Bank's specific home loan approval criteria to predict your chances and suggest improvements.",
          features: [
            "Analyzes 20+ PNB Home Finance Bank-specific home loan approval factors",
            "Predicts approval probability with 90% accuracy for PNB Home Finance Bank",
            "Provides personalized action plan to boost approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Express Home Loan Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your eligibility for PNB Home Finance Bank's Express Home Loan program with in-principle approval within 24 hours",
          features: [
            "Determines your eligibility for PNB Home Finance Bank's Express Home Loan",
            "Shows potential interest savings and reduced EMI",
            "Calculates additional benefits for eligible applicants",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Want Expert Guidance?",
        description:
          "Our mortgage specialists have deep expertise with PNB Home Finance Bank's home loan process and can help you navigate every step for maximum success.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const hdfclnthousingData = useMemo(
    () => ({
      title: "MinEMI's Smart Home Loan Tools",
      description:
        "Boost your loan approval chances with our AI-powered solutions",
      tools: [
        {
          name: "Approval Probability Analyzer",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI algorithm analyzes your financial profile to predict your HDFC Ltd. home loan approval chances with high accuracy.",
          features: [
            "Predicts approval likelihood with 94% accuracy",
            "Highlights specific factors affecting your application",
            "Provides personalized improvement recommendations",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Determine your maximum eligible loan amount based on your income, property value, and credit profile.",
          features: [
            "Calculates your optimal loan amount",
            "Estimates monthly EMI and interest costs",
            "Provides strategies to increase eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Want Expert Guidance?",
        description:
          "Our home loan specialists can provide personalized advice to help you navigate the HDFC Ltd. home loan process successfully.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const sbihomehousingData = useMemo(
    () => ({
      title: "MinEMI's Smart Home Loan Tools",
      description:
        "Boost your loan approval chances with our AI-powered solutions",
      tools: [
        {
          name: "Approval Probability Analyzer",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI algorithm analyzes your financial profile to predict your SBI home loan approval chances with high accuracy.",
          features: [
            "Predicts approval likelihood with 93% accuracy",
            "Highlights specific factors affecting your application",
            "Provides personalized improvement recommendations",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Determine your maximum eligible loan amount based on your income, property value, and credit profile.",
          features: [
            "Calculates your optimal loan amount",
            "Estimates monthly EMI and interest costs",
            "Provides strategies to increase eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Want Expert Guidance?",
        description:
          "Our home loan specialists can provide personalized advice to help you navigate the SBI home loan process successfully.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const adityahomehousingData = useMemo(
    () => ({
      title: "MinEMI's Smart Home Loan Tools",
      description:
        "Boost your loan approval chances with our AI-powered solutions",
      tools: [
        {
          name: "Approval Probability Analyzer",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI algorithm analyzes your financial profile to predict your Aditya Birla Finance home loan approval chances with high accuracy.",
          features: [
            "Predicts approval likelihood with 93% accuracy",
            "Highlights specific factors affecting your application",
            "Provides personalized improvement recommendations",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "/loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Determine your maximum eligible loan amount based on your income, property value, and credit profile.",
          features: [
            "Calculates your optimal loan amount",
            "Estimates monthly EMI and interest costs",
            "Provides strategies to increase eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "https://minemi.ai/home-loan/check-eligibility/",
          },
        },
      ],
      consultation: {
        title: "Want Expert Guidance?",
        description:
          "Our home loan specialists can provide personalized advice to help you navigate the Aditya Birla Finance home loan process successfully.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );

  useEffect(() => {
    if (name === "checkkotakhomeloanbankstatus") {
      setData(kotakData);
    } else if (name === "checkbobhomeloanbankstatus") {
      setData(bobData);
    } else if (name === "checkidfchomeloanbankstatus") {
      setData(idfcData);
    } else if (name === "checkhdfchomeloanbankstatus") {
      setData(hdfcData);
    } else if (name === "checksbihomeloanbankstatus") {
      setData(sbiData);
    } else if (name === "checkaxishomeloanbankstatus") {
      setData(axisData);
    } else if (name === "checkicicihomeloanbankstatus") {
      setData(iciciData);
    } else if (name === "checkpunjabhomeloanbankstatus") {
      setData(punjabData);
    } else if (name === "checkinduslndhomeloanbankstatus") {
      setData(induslndData);
    } else if (name === "checktatahomeloanbankstatus") {
      setData(tataData);
    } else if (name === "checkyeshomeloanbankstatus") {
      setData(yesData);
    } else if (name === "checkunionhomeloanbankstatus") {
      setData(unionData);
    } else if (name === "checkbajajhomeloanbankstatus") {
      setData(bajajData);
    } else if (name === "checkfederalhomeloanbankstatus") {
      setData(federalData);
    } else if (name === "checkcanarahomeloanbankstatus") {
      setData(canaraData);
    } else if (name === "checkboihomeloanbankstatus") {
      setData(boiData);
    } else if (name === "checkrblhomeloanbankstatus") {
      setData(rblData);
    } else if (name === "checklthomeloanbankstatus") {
      setData(ltData);
    } else if (name === "checkidbihomeloanbankstatus") {
      setData(idbiData);
    } else if (name === "checklichousinghomeloanbankstatus") {
      setData(lichousingData);
    } else if (name === "checkicicihousinghomeloanbankstatus") {
      setData(icicihousingData);
    } else if (name === "checkpnbhousinghomeloanbankstatus") {
      setData(pnbhousingData);
    } else if (name === "checkhdfcltdhomeloanbankstatus") {
      setData(hdfclnthousingData);
    } else if (name === "checksbihousinghomeloanbankstatus") {
      setData(sbihomehousingData);
    } else if (name === "checkadityahousinghomeloanbankstatus") {
      setData(adityahomehousingData);
    } else {
      setData({});
    }
  }, [
    name,
    axisData,
    bobData,
    hdfcData,
    iciciData,
    idfcData,
    kotakData,
    sbiData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
    ltData,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    hdfclnthousingData,
    sbihomehousingData,
    adityahomehousingData,
  ]);

  return (
    <section
      className="py-4 border-b border-gray-200"
      style={{ backgroundColor: "#ebe8fb" }}
    >
      <div className="container px-2 md:px-6 bg-transparent">
        <div className="sd:w-full md:w-4/5 mx-auto bg-transparent">
          <div className="text-center mb-6 bg-transparent">
            <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple">
              {data?.title}
            </h2>
            <p className="text-dark-grey mt-0 text-sm bg-transparent text-center">
              {data?.description}
            </p>
          </div>

          <div className="grid gap-3 bg-transparent">
            {data.tools?.map((d, index) => (
              <div
                key={index}
                className="bg-white p-3 rounded-lg shadow-sm border border-gray-200"
              >
                <div className="bg-transparent">
                  <div
                    className="h-10 w-10 rounded-full bg-light-bg flex items-center justify-center mb-3 mx-auto"
                    style={{ backgroundColor: "#ebe8fb" }}
                  >
                    {d.icon}
                  </div>
                  <h3 className="text-base font-bold text-primary-purple mb-2 text-center">
                    {d.name}
                  </h3>
                  <p className="text-dark-grey mb-3 text-sm bg-transparent">
                    {d.description}
                  </p>
                  <div className="space-y-2 mb-4 bg-transparent">
                    {d.features.map((feature, i) => (
                      <div
                        key={i}
                        className="flex items-start gap-0.5 bg-transparent"
                      >
                        {/* <CheckCircle
                          className="h-4 w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        /> */}
                        <CheckCircle
                          className="h-6 w-6 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        />

                        <p className="text-sm text-dark-grey bg-transparent m-0">
                          {feature}
                        </p>
                      </div>
                    ))}
                  </div>
                  <Link
                    to={d.link.url}
                    className="inline-flex h-12 items-center justify-center rounded-md bg-teal px-6 text-sm font-medium text-white transition-colors w-full bg-[#3b868b] hover:bg-[#2b1b61]"
                  >
                    {d.link.text}
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-3 bg-white p-3 rounded-lg border border-gray-200 shadow-sm">
            <h3 className="text-base font-bold text-primary-purple mb-2 text-center">
              {data.consultation?.title}
            </h3>
            <p className="text-dark-grey mb-4 text-sm bg-transparent">
              {data.consultation?.description}
            </p>
            <Link
              to={data.consultation?.link?.url}
              className="inline-flex h-12 items-center justify-center rounded-md bg-primary-purple px-6 text-sm font-medium text-white hover:bg-accent-purple transition-colors w-full bg-[#2b1b61] hover:bg-[#3b868b]"
            >
              {data.consultation?.link?.text}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}


import { ArrowRight } from "lucide-react"

export default function FinalCta() {
  const scrollToForm = () => {
    const formElement = document.getElementById("form-section")
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <section className="w-full py-5 md:py-16 bg-gradient-to-r from-[#311664] to-[#59266d] text-white">
      <div className="container mx-auto px-3 md:px-4 text-center">
        <h2 className="text-xl md:text-4xl font-bold mb-2 md:mb-6">
          Know Your Credit Card Approval Chances Before You Apply—It's Free & Instant!
        </h2>
        <p className="text-xs md:text-xl text-[#ebe8fc] max-w-3xl mx-auto mb-3 md:mb-8">
          Don't risk damaging your credit score with rejected applications. Check your approval odds with Minemi first
          and apply with confidence.
        </p>
        <button
          onClick={scrollToForm}
          size="sm"
          style={{gap:3}}
          className="bg-[#3a868b] hover:bg-[#3a868b]/90 text-white px-4 md:px-8 py-1.5 md:py-2 text-sm md:text-lg rounded-lg shadow-lg flex items-center mx-auto   transform hover:scale-105 transition-all"
        >
          Check Now <ArrowRight className="ml-1 h-3 w-3 md:h-5 md:w-5" />
        </button>
        <p className="mt-2 md:mt-6 text-[10px] md:text-sm text-[#ebe8fc]">
          No credit card required. No impact on your credit score.
        </p>
      </div>
    </section>
  )
}


import { useEffect, useMemo, useState } from "react";
import { CheckCircle } from "lucide-react";
import { Link } from "react-router-dom";
export function LoanPredictorWidget({ name }) {
  const [loanAmount, setLoanAmount] = useState(300000);
  const [creditScore, setCreditScore] = useState(750);
  const [employmentType, setEmploymentType] = useState("salaried");
  const [data, setData] = useState([]);

  const getApprovalChance = () => {
    let baseScore = 0;
    if (creditScore >= 750) baseScore = 85;
    else if (creditScore >= 700) baseScore = 65;
    else if (creditScore >= 650) baseScore = 40;
    else baseScore = 20;
    const amountFactor =
      loanAmount <= 300000 ? 5 : loanAmount >= 1000000 ? -5 : 0;

    const employmentFactor = employmentType === "salaried" ? 5 : -5;

    const finalScore = Math.min(
      95,
      Math.max(15, baseScore + amountFactor + employmentFactor)
    );

    if (finalScore >= 80) return "High (80-95%)";
    if (finalScore >= 60) return "Medium (60-80%)";
    if (finalScore >= 40) return "Low (40-60%)";
    return "Very Low (Below 40%)";
  };

  const getApprovalColor = () => {
    if (getApprovalChance().startsWith("High")) return "text-green-600";
    if (getApprovalChance().startsWith("Medium")) return "text-yellow-600";
    if (getApprovalChance().startsWith("Low")) return "text-orange-500";
    return "text-red-600";
  };

  const idbiData = useMemo(
    () => ({
      h2: "Predict Your Home Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your IDBI Bank home loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const lichousingData = useMemo(
    () => ({
      h2: "Predict Your Home Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your LIC Housing Finance home loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for home loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const icicihousingData = useMemo(
    () => ({
      h2: "Predict Your Home Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your ICICI Home Finance home loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for home loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const pnbhousingData = useMemo(
    () => ({
      h2: "Predict Your Home Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your PNB Housing Finance home loan approval probabilitys",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for home loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const sbihousingData = useMemo(
    () => ({
      h2: "Predict Your Home Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your SBI Home Loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for home loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const adityahousingData = useMemo(
    () => ({
      h2: "Predict Your Home Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your Aditya Birla Finance Home Loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for home loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const lthousingData = useMemo(
    () => ({
      h2: "Predict Your Home Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your L&T Finance Home Loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for home loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  useEffect(() => {
    if (name === "checkidbihousingbankstatus") {
      setData(idbiData);
    } else if (name === "checklichousingbankstatus") {
      setData(lichousingData);
    } else if (name === "checkicicihousingbankstatus") {
      setData(icicihousingData);
    } else if (name === "checkpnbhousingbankstatus") {
      setData(pnbhousingData);
    } else if (name === "checksbihousingbankstatus") {
      setData(sbihousingData);
    } else if (name === "checkadityahousingbankstatus") {
      setData(adityahousingData);
    } else if (name === "checklthousingbankstatus") {
      setData(lthousingData);
    } else {
      setData([]);
    }
  }, [
    name,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    sbihousingData,
    adityahousingData,
    lthousingData,
  ]);

  return (
    <div className="md:w-4/5 sd:w-full mx-auto bg-transparent">
      <div className="text-center mb-4 bg-transparent">
        <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple bg-transparent">
          {data.h2}
        </h2>
        <p className="text-dark-grey mt-1 text-sm text-center bg-transparent">
          {data.para}
        </p>
      </div>

      <div
        className=" mx-2  p-6 rounded-lg border border-gray-200 shadow-sm"
        style={{ backgroundColor: "#ebe8fb" }}
      >
        <div className="space-y-5 bg-transparent">
          <div className="bg-transparent">
            <label
              htmlFor="loan-amount"
              className="block text-sm font-medium text-dark-grey mb-2 bg-transparent"
            >
              Loan Amount (₹)
            </label>
            <input
              type="range"
              id="loan-amount"
              min="50000"
              max="1500000"
              step="50000"
              value={loanAmount}
              onChange={(e) => setLoanAmount(Number.parseInt(e.target.value))}
              className="w-full h-2 bg-white rounded-lg appearance-none cursor-pointer"
            />
            <div className="flex justify-between mt-2 bg-transparent">
              <span className="text-xs text-dark-grey bg-transparent">
                ₹50K
              </span>
              <span className="text-sm font-medium text-primary-purple bg-transparent">
                ₹{(loanAmount / 100000).toFixed(1)}L
              </span>
              <span className="text-xs text-dark-grey bg-transparent">
                ₹15L
              </span>
            </div>
          </div>

          <div className="bg-transparent">
            <label
              htmlFor="credit-score"
              className="block text-sm font-medium text-dark-grey mb-2 bg-transparent"
            >
              Credit Score
            </label>
            <input
              type="range"
              id="credit-score"
              min="550"
              max="850"
              step="10"
              value={creditScore}
              onChange={(e) => setCreditScore(Number.parseInt(e.target.value))}
              className="w-full h-2 bg-white rounded-lg appearance-none cursor-pointer"
            />
            <div className="flex justify-between mt-2 bg-transparent">
              <span className="text-xs text-dark-grey bg-transparent">550</span>
              <span className="text-sm font-medium text-primary-purple bg-transparent">
                {creditScore}
              </span>
              <span className="text-xs text-dark-grey bg-transparent">850</span>
            </div>
          </div>

          <div className="bg-transparent">
            <label className="block text-sm font-medium text-dark-grey mb-2 bg-transparent">
              Employment Type
            </label>
            <div className="flex gap-4 bg-transparent">
              <label className="flex items-center bg-transparent">
                <input
                  type="radio"
                  name="employment-type"
                  value="salaried"
                  checked={employmentType === "salaried"}
                  onChange={() => setEmploymentType("salaried")}
                  className="mr-2"
                />
                <span className="text-sm text-dark-grey bg-transparent ">
                  Salaried
                </span>
              </label>
              <label className="flex items-center bg-transparent">
                <input
                  type="radio"
                  name="employment-type"
                  value="self-employed"
                  checked={employmentType === "self-employed"}
                  onChange={() => setEmploymentType("self-employed")}
                  className="mr-2"
                />
                <span className="text-sm text-dark-grey bg-transparent">
                  Self-Employed
                </span>
              </label>
            </div>
          </div>

          <div className="bg-white w-full p-4  rounded-lg border border-gray-200">
            <div className="flex justify-between items-center mb-3 bg-transparent">
              <span className="text-sm font-medium text-dark-grey bg-transparent">
                {data.changes}
              </span>
              <span
                className={`text-sm font-bold bg-transparent ${getApprovalColor()}`}
              >
                {getApprovalChance()}
              </span>
            </div>
            <div className=" bg-transparent ">
              <div className="flex items-start gap-0.5 md:gap-2 bg-transparent ">
                <CheckCircle
                  className="h-5 w-5 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                  style={{ color: "green" }}
                />
                <p className="text-xs sm:text-sm  mb-2 text-dark-grey bg-transparent">
                  {creditScore >= 750
                    ? data.score
                    : "Improving your credit score will significantly boost approval odds"}
                </p>
              </div>
              <div className="flex items-start gap-0.5 md:gap-2 bg-transparent">
                <CheckCircle
                  className="h-5 w-5 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                  style={{ color: "green" }}
                />
                <p className="text-xs sm:text-sm text-dark-grey bg-transparent">
                  {employmentType === "salaried"
                    ? data.type
                    : "Self-employed applicants may need additional income documentation"}
                </p>
              </div>
            </div>
            {/* <Link
              href="/loan-predictor"
              className="inline-flex h-12  mt-2 items-center justify-center rounded-md bg-[#311663] px-6 text-sm font-medium text-white transition-colors w-full hover:bg-purple-900"
            >
              {data.report}
            </Link> */}
            <a
              href="/trackloanstatus"
              className="inline-flex h-12 mt-2 items-center justify-center rounded-md bg-[#311663] px-6 text-sm font-medium text-white transition-colors w-full hover:bg-purple-900"
              target="_blank" // This opens the link in a new tab
              rel="noopener noreferrer" // Security best practice for links opening in new tabs
            >
              {data.report}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

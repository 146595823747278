import { useEffect, useState, useMemo } from "react";

export function Testimonials({ name }) {
  const [data, setData] = useState({});

  const kotakData = useMemo(
    () => ({
      title: "Success Stories: Kotak Bank Loan Approvals",
      description:
        "Real experiences from customers who successfully secured Kotak Bank personal loans with MinEMI's help",
      testimonials: [
        {
          name: "Rajesh Sharma",
          amount: "₹6,00,000 at 10.25%",
          review:
            "I was initially rejected for a Kotak Bank personal loan due to my high FOIR ratio. MinEMI's Approval Predictor identified exactly what was causing the issue and suggested I close a small ongoing loan first. After following their advice and reapplying, I was approved for the full amount with an interest rate even lower than I expected!",
        },
        {
          name: "Priya Mehta",
          amount: "₹8,00,000 at 10.75%",
          review:
            "As a business owner with fluctuating income, I was struggling to get my Kotak Bank loan application approved. MinEMI's team helped me organize my income documentation in a way that clearly showed my repayment capacity. Their Rate Optimizer tool also suggested I apply for a 3-year tenure instead of 5 years, which not only got me approved but also saved me over ₹50,000 in interest!",
        },
      ],
      link: {
        text: "Read More Kotak Bank Success Stories ",
        url: "/testimonials",
      },
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their loan approval chances",
      testimonials: [
        {
          name: "Rajesh Kumar",
          amount: "₹5,00,000",
          review:
            "My Bank of Baroda personal loan was initially rejected. Using MinEMI's Loan Approval Predictor, I identified that my high credit utilization was the issue. After reducing it, my second application was approved within a week!",
        },
        {
          name: "Priya Sharma",
          amount: "₹3,50,000",
          review:
            "MinEMI's Eligibility Checker helped me understand exactly how much loan I could get from Bank of Baroda. I applied for the right amount and got instant approval.",
        },
      ],
      link: {
        text: "View More Success Stories ",
        url: "/testimonials",
      },
    }),
    []
  );
  const idfcData = useMemo(
    () => ({
      title: "Success Stories: IDFC FIRST Bank Loan Approvals",
      description:
        "Real experiences from customers who successfully secured IDFC FIRST Bank personal loans with MinEMI's help",
      testimonials: [
        {
          name: "Aditya Sharma",
          amount: "₹6,00,000 at 10.25%",
          review:
            "I was initially rejected for an IDFC FIRST Bank personal loan due to my high FOIR ratio. MinEMI's Approval Predictor identified exactly what was causing the issue and suggested I close a small ongoing loan first. After following their advice and reapplying, I was approved for the full amount with an interest rate even lower than I expected!",
        },
        {
          name: "Neha Gupta",
          amount: "₹8,00,000 at 10.75%",
          review:
            "As a freelancer, I was struggling to get my IDFC FIRST Bank loan application approved. MinEMI's team helped me organize my income documentation in a way that clearly showed my repayment capacity. Their Rate Optimizer tool also suggested I apply for a 3-year tenure instead of 5 years, which not only got me approved but also saved me over ₹45,000 in interest!",
        },
      ],
      link: {
        text: "Read More IDFC FIRST Bank Success Stories ",
        url: "/testimonials",
      },
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their loan approval chances",
      testimonials: [
        {
          name: "Vikram Mehta",
          amount: "₹6,00,000 ",
          review:
            "My HDFC Bank personal loan was initially rejected. Using MinEMI's Loan Approval Predictor, I identified that my high credit utilization was the issue. After reducing it, my second application was approved within just 3 days!",
        },
        {
          name: "Ananya Desai",
          amount: "₹4,00,000 ",
          review:
            "MinEMI's Eligibility Checker helped me understand exactly how much loan I could get from HDFC Bank. I applied for the right amount and got instant approval with a competitive interest rate.",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their loan approval chances",
      testimonials: [
        {
          name: "Suresh Patel",
          amount: "₹4,50,000",
          review:
            "My SBI personal loan was initially rejected. Using MinEMI's Loan Approval Predictor, I identified that my high credit utilization was the issue. After reducing it, my second application was approved within 10 days!",
        },
        {
          name: "Meera Sharma",
          amount: "₹3,75,000",
          review:
            "MinEMI's Eligibility Checker helped me understand exactly how much loan I could get from SBI. I applied for the right amount and got approval with a competitive interest rate.",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their loan approval chances",
      testimonials: [
        {
          name: "Rahul Mehta",
          amount: "₹5,00,000",
          review:
            "After my Axis Bank personal loan was initially put on hold, I used MinEMI's Loan Approval Predictor to identify the issues. I improved my debt-to-income ratio and my second application was approved in just 4 days!",
        },
        {
          name: "Priya Desai",
          amount: "₹3,25,000",
          review:
            "MinEMI's Eligibility Checker helped me understand exactly how much I could borrow from Axis Bank. I applied for the right amount and got instant approval with a competitive interest rate of 10.5%.",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their loan approval chances",
      testimonials: [
        {
          name: "Vikram Singh",
          amount: "₹4,50,000",
          review:
            "After my ICICI Bank personal loan was initially put on hold, I used MinEMI's Loan Approval Predictor to identify the issues. I improved my debt-to-income ratio and my second application was approved in just 3 days!",
        },
        {
          name: "Ananya Sharma",
          amount: "₹3,75,000",
          review:
            "MinEMI's Eligibility Checker helped me understand exactly how much I could borrow from ICICI Bank. I applied for the right amount and got instant approval with a competitive interest rate of 10.25%.",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their loan approval chances",
      testimonials: [
        {
          name: "Rajesh Kumar",
          amount: "₹4,00,000",
          review:
            "After my Punjab National Bank personal loan was initially put on hold, I used MinEMI's Loan Approval Predictor to identify the issues. I improved my debt-to-income ratio and my second application was approved in just 5 days!",
        },
        {
          name: "Meera Patel",
          amount: "₹3,50,000",
          review:
            "MinEMI's Eligibility Checker helped me understand exactly how much I could borrow from PNB. I applied for the right amount and got instant approval with a competitive interest rate of 10.40%.",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their loan approval chances",
      testimonials: [
        {
          name: "Sanjay Mehta",
          amount: "₹5,00,000",
          review:
            "After my IndusInd Bank personal loan was initially put on hold, I used MinEMI's Loan Approval Predictor to identify the issues. I improved my debt-to-income ratio and my second application was approved in just 2 days with a competitive interest rate!",
        },
        {
          name: "Priya Desai",
          amount: "₹3,80,000",
          review:
            "MinEMI's Eligibility Checker helped me understand exactly how much I could borrow from IndusInd Bank. I applied for the right amount and got instant approval with a competitive interest rate of 10.25% through their IndusMobile app.",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their loan approval chances",
      testimonials: [
        {
          name: "Vikram Singh",
          amount: "₹4,50,000",
          review:
            "I was initially hesitant about applying for a Tata Capital personal loan due to my average credit score. MinEMI's Loan Approval Predictor helped me understand exactly what I needed to improve. After following their recommendations for 2 months, my application was approved with a great interest rate!",
        },
        {
          name: "Ananya Sharma",
          amount: "₹6,00,000",
          review:
            "MinEMI's Eligibility Checker helped me understand exactly how much I could borrow from Tata Capital. I applied for their Express Loan through the Tata Capital Mobile App and got approval in just 24 hours! The entire process was seamless.",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their loan approval chances",
      testimonials: [
        {
          name: "Vikram Singh",
          amount: "₹4,50,000",
          review:
            "I was worried about my Yes Bank personal loan application after it was marked 'Pending'. MinEMI's Loan Approval Predictor identified that my debt-to-income ratio was too high. I paid off a small loan and reapplied - approved within 48 hours!",
        },
        {
          name: "Ananya Sharma",
          amount: "₹3,75,000",
          review:
            "MinEMI's Eligibility Checker helped me understand exactly how much I could borrow from Yes Bank. I applied for the right amount and got instant approval with a competitive interest rate of 10.25%.",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their loan approval chances",
      testimonials: [
        {
          name: "Rajesh Kumar",
          amount: "₹3,80,000",
          review:
            "My Union Bank personal loan was stuck in 'Under Processing' status for over a week. MinEMI's support team helped me identify missing documents and guided me through the resubmission process. My loan was approved within 3 days after that!",
        },
        {
          name: "Ananya Sharma",
          amount: "₹4,25,000",
          review:
            "MinEMI's Eligibility Checker helped me understand exactly how much I could borrow from Union Bank. I applied for the right amount and got approved with a competitive interest rate of 10.75%. The entire process was smooth and hassle-free.",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their loan approval chances",
      testimonials: [
        {
          name: "Vikram Desai",
          amount: "₹4,50,000",
          review:
            "After my first Bajaj Finance loan application was stuck in 'Documents Required' status for a week, MinEMI's support team identified that my income proof needed updating. I submitted the correct documents and got approved within 24 hours for a ₹4.5 lakh loan!",
        },
        {
          name: "Neha Sharma",
          amount: "₹6,00,000",
          review:
            "MinEMI's Approval Analyzer suggested I should reduce my credit card utilization before applying to Bajaj Finance. I paid down my cards from 80% to 30% utilization, waited 15 days, and then applied. Got instant approval for ₹6 lakhs with an interest rate 2% lower than initially quoted!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their loan approval chances",
      testimonials: [
        {
          name: "Rahul Menon",
          amount: "₹5,00,000",
          review:
            "My Federal Bank personal loan was stuck in 'Documents Pending' status for over a week. MinEMI's support team helped me identify that my income proof needed additional verification. After submitting the correct format, my loan for ₹5 lakhs was approved within 3 days!",
        },
        {
          name: "Anjali Thomas",
          amount: "₹7,00,000",
          review:
            "MinEMI's Approval Analyzer suggested I should open a salary account with Federal Bank before applying for a personal loan. I followed their advice, and not only was my application processed faster, but I also received a preferential interest rate that was 1.5% lower than initially quoted!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their Canara Bank loan outcomes",
      testimonials: [
        {
          name: "Suresh Kumar",
          amount: "₹6,00,000",
          review:
            "My Canara Bank personal loan was stuck in 'Additional Documents Required' status for over two weeks. MinEMI's support team helped me identify that my income proof needed additional verification. After submitting the correct format and visiting my branch, my loan for ₹6 lakhs was approved within a week!",
        },
        {
          name: "Priya Nair",
          amount: "₹8,00,000",
          review:
            "MinEMI's Approval Analyzer suggested I should open a Canara Super Salary account before applying for a personal loan. I followed their advice, and not only was my application processed faster, but I also received a preferential interest rate that was 1.75% lower than initially quoted for my ₹8 lakh loan!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their Canara Bank loan outcomes",
      testimonials: [
        {
          name: "Rajesh Sharma",
          amount: "₹4,45,000",
          review:
            "My Bank of India personal loan was stuck in 'Documents Pending' status for over two weeks. MinEMI's support team helped me identify that my income proof needed additional verification. After submitting the correct format and visiting my branch, my loan for ₹4.5 lakhs was approved within 10 days!",
        },
        {
          name: "Meena Patel",
          amount: "₹7,00,000",
          review:
            "MinEMI's Approval Analyzer suggested I should open a Star Salary account before applying for a personal loan. I followed their advice, and not only was my application processed faster, but I also received a preferential interest rate that was 1.5% lower than initially quoted for my ₹7 lakh loan!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their Canara Bank loan outcomes",
      testimonials: [
        {
          name: "Vikram Mehta",
          amount: "₹5,00,000",
          review:
            "My RBL Bank personal loan was stuck in 'Additional Information Required' status for a week. MinEMI's support team helped me identify that my income proof needed additional verification. After submitting the correct format and completing a video KYC session, my loan for ₹5 lakhs was approved within just 48 hours!",
        },
        {
          name: "Ananya Singh",
          amount: "₹3,50,000",
          review:
            "MinEMI's Approval Analyzer suggested I should apply for RBL Bank's Express Personal Loan since I already had a credit card with them. I followed their advice, and not only was my application processed faster, but I also received a preferential interest rate that was 2% lower than initially quoted for my ₹3.5 lakh loan!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );

  useEffect(() => {
    if (name === "checkkotakbankstatus") {
      setData(kotakData);
    } else if (name === "checkbobbankstatus") {
      setData(bobData);
    } else if (name === "checkidfcbankstatus") {
      setData(idfcData);
    } else if (name === "checkhdfcbankstatus") {
      setData(hdfcData);
    } else if (name === "checksbibankstatus") {
      setData(sbiData);
    } else if (name === "checkaxisbankstatus") {
      setData(axisData);
    } else if (name === "checkicicibankstatus") {
      setData(iciciData);
    } else if (name === "checkpunjabbankstatus") {
      setData(punjabData);
    } else if (name === "checkinduslndbankstatus") {
      setData(induslndData);
    } else if (name === "checktatabankstatus") {
      setData(tataData);
    } else if (name === "checkyesbankstatus") {
      setData(yesData);
    } else if (name === "checkunionbankstatus") {
      setData(unionData);
    } else if (name === "checkbajajbankstatus") {
      setData(bajajData);
    } else if (name === "checkfederalbankstatus") {
      setData(federalData);
    } else if (name === "checkcanarabankstatus") {
      setData(canaraData);
    } else if (name === "checkboibankstatus") {
      setData(boiData);
    } else if (name === "checkrblbankstatus") {
      setData(rblData);
    } else {
      setData({});
    }
  }, [
    name,
    axisData,
    bobData,
    hdfcData,
    iciciData,
    idfcData,
    kotakData,
    sbiData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
  ]);

  return (
    <section className="py-4 border-b border-gray-200">
      <div className="container px-3 md:px-6 bg-transparent">
        <div className="w-4/5l mx-auto bg-transparent">
          <div className="text-center mb-6 bg-transparent">
            <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple">
              {data.title}
            </h2>
            <p className="text-dark-grey mt-2 text-sm bg-transparent text-center">
              {data.description}
            </p>
          </div>

          <div className="grid gap-3 bg-transparent">
            {data.testimonials?.map((d, index) => (
              <div
                key={index}
                className=" p-3 rounded-lg border border-gray-200 shadow-sm bg-[#ebe8fb]"
              >
                <div className="mb-3 bg-transparent">
                  <div className="flex bg-transparent">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <svg
                        key={star}
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="#FFD700"
                        stroke="#FFD700"
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="mr-1 bg-transparent"
                      >
                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                      </svg>
                    ))}
                  </div>
                </div>
                <p className="text-dark-grey mb-3 text-sm bg-transparent">
                  {d.review}
                </p>
                <div className="flex justify-between items-center bg-transparent">
                  <p className="font-bold text-primary-purple text-sm bg-transparent">
                    {d.name}
                  </p>
                  <p className="text-sm text-teal font-medium bg-transparent">
                    {d.amount}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* <div className="mt-4 text-center bg-transparent">
            <a
              href="/testimonials"
              className="text-primary-purple text-sm font-medium hover:underline"
            >
              {data?.link?.text} →
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
}

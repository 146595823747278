import { useEffect, useState, useMemo } from "react";
// import { Helmet } from "react-helmet";
export function LoanStatusChecker({ name }) {
  const [data, setData] = useState({}); // Initialize with an empty object

  const kotakData = useMemo(
    () => [
      {
        h2: " Check Your Kotak Mahindra Bank Home Loan Status",
        para: "Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            <path d="m9 12 2 2 4-4" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          "  Our mortgage experts are available to help you check your Kotak Mahindra Bank Home Loan status and guide you through the next steps.",
        text: "  Contact our support team",
      },
    ],
    []
  );
  const bobData = useMemo(
    () => [
      {
        h2: "Check Your Bank of Baroda Home Loan Status",
        para: " Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: " Personal Assistance for Home Loan Status",
        para2:
          "Our mortgage experts are available to help you check your Bank of Baroda Home Loan status and guide you through the next steps.",
        text: "  Contact our support team",
      },
    ],
    []
  );
  const idfcData = useMemo(
    () => [
      {
        h2: " Check Your IDFC FIRST Bank Home Loan Status",
        para: "Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          "  Our mortgage experts are available to help you check your IDFC FIRST Bank Home Loan status and guide you through the next steps.",
        text: "  Contact our support team",
      },
    ],
    []
  );
  const hdfcData = useMemo(
    () => [
      {
        h2: " Check Your HDFC Bank Home Loan Status",
        para: " Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          "  Our mortgage experts are available to help you check your HDFC Bank Home Loan status and guide you through the next steps.",
        text: " Contact our support team",
      },
    ],
    []
  );
  const sbiData = useMemo(
    () => [
      {
        h2: " Check Your SBI Home Loan Status",
        para: "Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          " Our mortgage experts are available to help you check your SBI Home Loan status and guide you through the next steps.s",
        text: "Contact our support team",
      },
    ],
    []
  );
  const axisData = useMemo(
    () => [
      {
        h2: "Check Your Axis Bank Home Loan Status",
        para: "Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          "Our mortgage experts are available to help you check your Axis Bank Home Loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const iciciData = useMemo(
    () => [
      {
        h2: "Check Your ICICI Bank Home Loan Status",
        para: "Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          "Our mortgage experts are available to help you check your ICICI Bank Home Loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const punjabData = useMemo(
    () => [
      {
        h2: "Check Your Punjab National Bank Home Loan Status",
        para: "Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          "Our mortgage experts are available to help you check your Punjab National Bank Home Loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const induslndData = useMemo(
    () => [
      {
        h2: "Check Your IndusInd Bank Home Loan Status",
        para: "Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          "Our mortgage experts are available to help you check your IndusInd Bank Home Loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const tataData = useMemo(
    () => [
      {
        h2: "Check Your Tata Housing Finance Home Loan Status",
        para: "Get instant updates on your home loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Home Loan Status",
        para2:
          "Our mortgage experts are available to help you check your Tata Housing Finance Home Loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const yesData = useMemo(
    () => [
      {
        h2: "Track Your YES Bank Home Loan Status",
        para: "Get instant updates on your home loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Real-Time Home Loan Status Tracker",
        para2:
          "Our mortgage experts are ready to help you check your YES Bank home loan status and provide guidance on next steps for faster approval.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const unionData = useMemo(
    () => [
      {
        h2: "Track Your Union Bank Home Loan Status",
        para: "Get instant updates on your home loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Real-Time Home Loan Status Tracker",
        para2:
          "Our mortgage experts are ready to help you check your Union Bank home loan status and provide guidance on next steps for faster approval.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const bajajData = useMemo(
    () => [
      {
        h2: "Track Your Bajaj Finance Home Loan Status",
        para: "Get instant updates on your home loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Real-Time Home Loan Status Tracker",
        para2:
          "Our mortgage experts are ready to help you check your Bajaj Finance home loan status and provide guidance on next steps for faster approval.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const federalData = useMemo(
    () => [
      {
        h2: "Track Your Federal Bank Home Loan Status",
        para: "Get instant updates on your home loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Real-Time Home Loan Status Tracker",
        para2:
          "Our mortgage experts are ready to help you check your Federal Bank home loan status and provide guidance on next steps for faster approval.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const canaraData = useMemo(
    () => [
      {
        h2: "Track Your Canara Bank Home Loan Status",
        para: "Get instant updates on your home loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Real-Time Home Loan Status Tracker",
        para2:
          "Our mortgage experts are ready to help you check your Canara Bank home loan status and provide guidance on next steps for faster approval.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const boiData = useMemo(
    () => [
      {
        h2: "Track Your Bank of India Home Loan Status",
        para: "Get instant updates on your home loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Real-Time Home Loan Status Tracker",
        para2:
          "Our mortgage experts are ready to help you check your Bank of India home loan status and provide guidance on next steps for faster approval.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const rblData = useMemo(
    () => [
      {
        h2: "Track Your RBL Bank Home Loan Status",
        para: "Get instant updates on your home loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Real-Time Home Loan Status Tracker",
        para2:
          "Our mortgage experts are ready to help you check your RBL Bank home loan status and provide guidance on next steps for faster approval.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const ltData = useMemo(
    () => [
      {
        h2: "Track Your L&T Finance Home Loan Status",
        para: "Get instant updates on your home loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Real-Time Home Loan Status Tracker",
        para2:
          "Our mortgage experts are ready to help you check your L&T Finance home loan status and provide guidance on next steps for faster approval.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const idbiData = useMemo(
    () => [
      {
        h2: "Track Your IDBI Finance Home Loan Status",
        para: "Get instant updates on your home loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Real-Time Home Loan Status Tracker",
        para2:
          "  Our home loan experts are available to help you check your IDBI home loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const lichousingData = useMemo(
    () => [
      {
        h2: "Track Your LIC Housing Finance Home Loan Status",
        para: "Get instant updates on your home loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Real-Time Home Loan Status Tracker",
        para2:
          "Our home loan experts are available to help you check your LIC Housing Finance home loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const icicihousingData = useMemo(
    () => [
      {
        h2: "Track Your ICICI Home Finance Home Loan Status",
        para: "Get instant updates on your home loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Real-Time Home Loan Status Tracker",
        para2:
          "Our home loan experts are available to help you check your ICICI Home Finance home loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const pnbhousingData = useMemo(
    () => [
      {
        h2: "Track Your PNB Home Finance Home Loan Status",
        para: "Get instant updates on your home loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Real-Time Home Loan Status Tracker",
        para2:
          "Our home loan experts are available to help you check your PNB Home Finance home loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const hdfclnthousingData = useMemo(
    () => [
      {
        h2: "Track Your HDFC Ltd. Home Loan Status",
        para: "Get real-time updates on your loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Expert Home Loan Status Assistance",
        para2:
          "Our home loan experts are available to help you check your PNB Home Finance home loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const sbihomehousingData = useMemo(
    () => [
      {
        h2: "Track Your SBI Home Loan Status",
        para: "Get real-time updates on your loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Expert Home Loan Status Assistance",
        para2:
          "Our loan specialists are ready to help you check your SBI home loan status and provide guidance on next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const adityahomehousingData = useMemo(
    () => [
      {
        h2: "Track Your Aditya Birla Finance Home Loan Status",
        para: "Get real-time updates on your loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Expert Home Loan Status Assistance",
        para2:
          "Our loan specialists are ready to help you check your Aditya Birla Finance home loan status and provide guidance on next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );

  useEffect(() => {
    if (name === "checkkotakhomeloanbankstatus") {
      setData(kotakData[0]);
    } else if (name === "checkbobhomeloanbankstatus") {
      setData(bobData[0]);
    } else if (name === "checkidfchomeloanbankstatus") {
      setData(idfcData[0]);
    } else if (name === "checkhdfchomeloanbankstatus") {
      setData(hdfcData[0]);
    } else if (name === "checksbihomeloanbankstatus") {
      setData(sbiData[0]);
    } else if (name === "checkaxishomeloanbankstatus") {
      setData(axisData[0]);
    } else if (name === "checkicicihomeloanbankstatus") {
      setData(iciciData[0]);
    } else if (name === "checkpunjabhomeloanbankstatus") {
      setData(punjabData[0]);
    } else if (name === "checkinduslndhomeloanbankstatus") {
      setData(induslndData[0]);
    } else if (name === "checktatahomeloanbankstatus") {
      setData(tataData[0]);
    } else if (name === "checkyeshomeloanbankstatus") {
      setData(yesData[0]);
    } else if (name === "checkunionhomeloanbankstatus") {
      setData(unionData[0]);
    } else if (name === "checkbajajhomeloanbankstatus") {
      setData(bajajData[0]);
    } else if (name === "checkfederalhomeloanbankstatus") {
      setData(federalData[0]);
    } else if (name === "checkcanarahomeloanbankstatus") {
      setData(canaraData[0]);
    } else if (name === "checkboihomeloanbankstatus") {
      setData(boiData[0]);
    } else if (name === "checkrblhomeloanbankstatus") {
      setData(rblData[0]);
    } else if (name === "checklthomeloanbankstatus") {
      setData(ltData[0]);
    } else if (name === "checkidbihomeloanbankstatus") {
      setData(idbiData[0]);
    } else if (name === "checklichousinghomeloanbankstatus") {
      setData(lichousingData[0]);
    } else if (name === "checkicicihousinghomeloanbankstatus") {
      setData(icicihousingData[0]);
    } else if (name === "checkpnbhousinghomeloanbankstatus") {
      setData(pnbhousingData[0]);
    } else if (name === "checkhdfcltdhomeloanbankstatus") {
      setData(hdfclnthousingData[0]);
    } else if (name === "checksbihousinghomeloanbankstatus") {
      setData(sbihomehousingData[0]);
    } else if (name === "checkadityahousinghomeloanbankstatus") {
      setData(adityahomehousingData[0]);
    } else {
      setData({}); // Reset data to an empty object if no match
    }
  }, [
    name,
    axisData,
    bobData,
    hdfcData,
    iciciData,
    idfcData,
    kotakData,
    sbiData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
    ltData,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    hdfclnthousingData,
    sbihomehousingData,
    adityahomehousingData,
  ]);

  // Render only when data is available
  if (!data.h2) {
    return null; // Return null if data is not available
  }

  return (
    <section
      id="loan-status-checker"
      className="py-3 bg-white border-t border-b border-gray-200"
    >
      <div className="container md:px-6 bg-transparent ">
        <div className="mx-auto sd:w-full md:w-4/5 bg-transparent">
          <div className="text-center mb-6 bg-transparent">
            <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple bg-transparent">
              {data.h2}
            </h2>
            <p className="text-dark-grey mt-0 text-sm bg-transparent text-center">
              {data.para}
            </p>
          </div>
          <div
            className=" px-3 py-3 rounded-lg border border-gray-200 shadow-sm"
            style={{ backgroundColor: "#ebe8fb" }}
          >
            <div className="text-center bg-transparent">
              {data.icon}

              <h3 className="text-lg font-bold text-primary-purple">
                {data.h3}
              </h3>
              <p className="text-dark-grey text-sm bg-transparent text-center">
                {data.para2}
              </p>
              <div className="flex flex-col gap-3 bg-transparent">
                <a
                  href="/trackloanstatus"
                  className="inline-flex mt-2 h-12 items-center justify-center rounded-md border border-primary-purple  px-6 text-sm font-medium text-white text-primary-purple bg-[#3b868b] hover:bg-[#311569] transition-colors w-full"
                >
                  Check Home Loan Status Instantly
                </a>
                <a
                  href="#loan-predictor"
                  className="inline-flex h-12 items-center justify-center rounded-md border border-primary-purple  text-purple-900 px-6 text-sm font-medium text-primary-purple  transition-colors w-full  bg-[white] hover:bg-[#ebe8fb]"
                >
                  Get Approval Insights Now
                </a>
              </div>
            </div>
          </div>
          <div className="mt-2 flex justify-center text-xs text-dark-grey bg-transparent">
            <p className="bg-transparent">
              Need immediate assistance?{" "}
              <a href="/contactus" className="text-teal hover:underline">
                {data.text}
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}


import { motion } from "framer-motion"

export default function FinalCTA() {
  return (
    <section className="py-12 md:py-24 px-4 md:px-8 lg:px-16 relative overflow-hidden">
      {/* Decorative elements */}
      <div className="absolute top-0 left-0 h-64 w-64 bg-[#ebe8fc] rounded-full opacity-30 -translate-x-1/2 -translate-y-1/2"></div>
      <div className="absolute bottom-0 right-0 h-80 w-80 bg-[#3a868b] rounded-full opacity-10 translate-x-1/3 translate-y-1/3"></div>

      <div className="container max-w-7xl mx-auto relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="bg-gradient-to-br from-[#311664] to-[#59266d] p-6 md:p-16 rounded-2xl text-center text-white shadow-xl"
        >
          <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold mb-3 md:mb-6">
            Find Your Perfect Card – Hassle-Free!
          </h2>
          <p className="text-base md:text-xl opacity-90 mb-6 md:mb-10 max-w-3xl mx-auto">
            Join thousands of users who have found their ideal credit card match.
          </p>

          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button
              size="default"
              className="bg-white   text-[#311664] hover:bg-[#ebe8fc] text-base md:text-lg px-4  md:px-4 py-2"
              style={{borderRadius:8}}
              onClick={() => {
                const eligibilitySection = document.getElementById("eligibility-section")
                eligibilitySection?.scrollIntoView({ behavior: "smooth" })
              }}
            >
              Check My Eligibility
            </button>

            <button
              variant="outline"
              size="default"
              className="border-white sm:py-2  text-white bg-white/10 hover:bg-white hover:text-purple text-base md:text-lg px-6 md:px-8 py-2"
              style={{borderRadius:8}}
            >
              Learn More
            </button>
          </div>

          <div className="mt-6 justify-start md:mt-10 flex flex-wrap md:justify-center gap-4 md:gap-6 sm:w-[150] ">
            <div className="flex items-center">
              <div className="bg-white/20 rounded-full h-10 w-10 flex items-center justify-center mr-3">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 12L11 14L15 10"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span className="text-sm md:text-base">100% Secure</span>
            </div>

            <div className="flex items-center">
              <div className="bg-white/20 rounded-full h-10 w-10 flex items-center justify-center mr-3">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 6V12L16 14"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span className="text-sm md:text-base">Instant Results</span>
            </div>

            <div className="flex items-center">
              <div className="bg-white/20 rounded-full h-10 w-10 flex items-center justify-center mr-3">
           
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20 6L9 17L4 12"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
              
                </svg>
              </div>
              <span className="text-sm md:text-base">No Credit Score Impact</span>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  )
}


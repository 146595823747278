import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import tongueOut from "../../../Assets/Images/tongue-out.webp";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import MetadataContext from "../../MetadataContext";

const CCArticleOne = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Best Travel Credit Cards",
      pageDescription:
        "Compare and apply for the best credit cards tailored to your needs. Enjoy rewards, cashback, low-interest rates, and exclusive offers. Find your perfect card today!",
      pageKeywords:
        "credit cards, best credit cards, apply for credit card, rewards credit cards, cashback credit cards, low-interest credit cards, credit card offers",
      canonicalUrl:
        "https://minemi.ai/creditcard/how-to-maximize-credit-card-benefits",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_as bg-transparent as ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-a bg-transparent archivo "
                style={{
                  color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/creditcard"}
                className="d-none d-md-inline custom-a bg-transparent "
                style={{
                  color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Credit Card
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent "
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Best Travel Credit Cards
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Best Travel Credit Cards: Your Passport to Seamless Adventures
          </h1>

          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Traveling is an exciting adventure, but managing expenses abroad or
            during trips can sometimes become challenging. That’s where travel
            credit cards step in as a perfect companion. Designed to enhance
            your travel experiences, these cards offer a variety of perks such
            as reward points, cashback, complimentary lounge access, and lower
            foreign transaction fees. In this article, we’ll dive into
            everything you need to know about the best travel credit cards in
            India, including features, benefits, and tips to choose the right
            card for your next trip.
          </p>
        </div>

        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 56 }}>
          <div
            style={{
              marginTop: 40,
              color: "#1F1F1F",
              fontFamily: "'Archivo',sans-serif",
            }}
          >
            <h4 style={{ fontSize: 24 }}>What Are Travel Credit Cards?</h4>
          </div>

          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Travel credit cards are specialized financial products designed to
            cater to the needs of frequent travellers. These cards offer
            travel-centric rewards and privileges like airline miles, hotel
            discounts, access to airport lounges, and lower transaction fees on
            international spending.
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            For instance, as per data by RBI, credit card usage for
            international travel has grown significantly post-pandemic,
            emphasizing the need for travel-friendly financial products.
          </div>

          {/* minemi tip section */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                    loading="lazy"
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        backgroundColor: "transparent",
                      }}
                    >
                      Choose wisely
                    </span>
                    —Pick the card that aligns with your travel goals, whether
                    it’s maximizing reward points, enjoying lounge access, or
                    getting discounts with service providers. Explore the world
                    with confidence and comfort—armed with the perfect travel
                    credit card for your needs!
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 20,
              }}
            >
              <h4
                style={{
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: 24,
                }}
              >
                Key Features of Travel Credit Cards
              </h4>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Travel Rewards{" "}
                  </span>{" "}
                  <br />
                  This refers to repaying part of your personal loan before the
                  scheduled EMI dates. By reducing the principal balance early,
                  you effectively cut down on the future interest.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Airport Lounge Access{" "}
                  </span>{" "}
                  <br />
                  Most travel credit cards come with complimentary access to
                  domestic and international airport lounges, making your travel
                  experience more comfortable.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Foreign Exchange Markup Fee Waiver{" "}
                  </span>{" "}
                  <br />
                  These cards often offer lower foreign transaction fees,
                  typically ranging between 1.5% to 3.5%, as opposed to the
                  standard 3.5% charged by regular cards.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Complimentary Insurance Coverage
                  </span>{" "}
                  <br />
                  Some travel credit cards include travel insurance covering
                  trip delays, loss of baggage, or personal accidents, providing
                  added peace of mind during your journey.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Hotel & Airline Partnerships
                  </span>{" "}
                  <br />
                  Top-tier travel credit cards collaborate with airlines and
                  hotel chains to provide exclusive discounts, free upgrades,
                  and other perks.
                </div>
              </div>
            </div>
          </div>

          {/*  */}

          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 28,
              color: "#1F1F1F",
              marginTop: 30,
              lineHeight: 1.2,
            }}
          >
            Top Travel Credit Cards in India
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 16,
              color: "#1f1f1f",
              marginTop: 8,
            }}
          >
            Here’s a detailed overview of some of the best travel credit cards
            in India:
          </p>

          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
              fontSize: 16,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                      verticalAlign: "top",
                    }}
                  >
                    Card Name
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                      verticalAlign: "top",
                    }}
                  >
                    Key Benefits
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                      verticalAlign: "top",
                    }}
                  >
                    Partnerships
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                      verticalAlign: "top",
                    }}
                  >
                    Ideal For
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      borderTopRightRadius: 18,
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                      verticalAlign: "top",
                    }}
                  >
                    Links
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    HDFC Bank Diners Club Privilege
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Complimentary lounge access to 700+ airports worldwide, earn
                    4 reward points for every ₹150 spent
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Airline & hotel partners for discounts
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Frequent international travellers prioritizing luxury
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    <a
                      href={"https://www.hdfcbank.com/"}
                      style={{
                        color: "#561A6A",
                      }}
                    >
                      HDFC Bank
                    </a>
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    SBI Card Elite
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    ₹5,000 welcome voucher, free Club Vistara & Trident
                    Privilege memberships, 6 free lounge visits
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Vistara, Trident Privilege
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Domestic travellers looking for extensive perks
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    <a
                      href={"https://www.sbicard.com/"}
                      style={{
                        color: "#561A6A",
                      }}
                    >
                      SBI Card
                    </a>
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Axis Bank Magnus Credit Card
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    12 Axis Edge Reward Points per ₹200 spent, unlimited
                    international lounge access, complimentary golf rounds
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Premium golf clubs
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    High-net-worth individuals seeking premium services
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    <a
                      href={"https://www.axisbank.com/"}
                      style={{
                        color: "#561A6A",
                      }}
                    >
                      Axis Bank
                    </a>
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    American Express Platinum Travelers
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Accelerated rewards on AmEx Travel bookings, free stays at
                    luxury hotels, ₹10,000 Taj Experiences Gift Card
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Travel Taj Hotels, AmEx Travel
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Travelers preferring premium hospitality services
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    <a
                      href={"https://www.americanexpress.com/"}
                      style={{
                        color: "#561A6A",
                      }}
                    >
                      American Express
                    </a>
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    ICICI Bank Sapphiro Credit Card
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Exclusive travel concierge services, 4 lounge visits,
                    MakeMyTrip partnerships for travel deals
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    MakeMyTrip
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Budget-conscious travelers with premium benefits
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    <a
                      href={"https://www.icicibank.com/"}
                      style={{
                        color: "#561A6A",
                      }}
                    >
                      ICICI Bank
                    </a>
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    MakeMyTrip ICICI Credit Card
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    10% off on hotel bookings, ₹500 MakeMyTrip vouchers, travel
                    cashback on flight bookings
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    MakeMyTrip
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Frequent travelers booking through MakeMyTrip
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    <a
                      href={"https://www.icicibank.com/"}
                      style={{
                        color: "#561A6A",
                      }}
                    >
                      ICICI Bank
                    </a>
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Yatra SBI Credit Card
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Earn Yatra e-gift voucher on spending, free lounge access,
                    1% foreign transaction fee waiver
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Yatra
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Travelers booking flights via Yatra
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    <a
                      href={"https://www.sbicard.com/"}
                      style={{
                        color: "#561A6A",
                      }}
                    >
                      SBI Card
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/*  */}

          {/* minemi tip section */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                    loading="lazy"
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 600,
                        backgroundColor: "transparent",
                      }}
                    >
                      Maximize your rewards
                    </span>
                    &nbsp;by booking flights, hotels, or dining using the right
                    travel card. Always look for the ones that offer high
                    rewards for categories you spend the most on, like flights
                    or dining, to enhance the value of your purchases.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 20,
              }}
            >
              <h3
                style={{
                  fontSize: 24,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                }}
              >
                When Travel Credit Cards Are Useful and When They Don't Add
                Value
              </h3>
              <p
                style={{
                  fontSize: 18,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  paddingTop: 5,
                  fontWeight: 600,
                }}
              >
                When They Are Useful:
              </p>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Frequent Flyers:{" "}
                  </span>
                  Travel credit cards are most beneficial for individuals who
                  frequently travel by air, as they offer rewards, miles, and
                  perks for every flight purchase.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Luxury Travelers:{" "}
                  </span>
                  If you enjoy premium experiences like airport lounge access,
                  concierge services, or exclusive hotel benefits, these cards
                  are ideal.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    International Travelers:{" "}
                  </span>
                  If you travel abroad often, look for cards that waive foreign
                  transaction fees and offer higher rewards for international
                  spending.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Booking through Partners:{" "}
                  </span>
                  For example, MakeMyTrip ICICI or Yatra SBI cards are great if
                  you consistently book through these platforms and can maximize
                  the rewards.
                </div>
              </div>
            </div>

            <p
              style={{
                fontSize: 18,
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
                paddingTop: 14,
                fontWeight: 600,
              }}
            >
              When They Don't Add Value:
            </p>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Infrequent Travelers:{" "}
                  </span>
                  If you travel only occasionally, the benefits of a travel
                  credit card (such as annual fees or high interest rates) may
                  not justify the limited rewards.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Low-Spending:{" "}
                  </span>
                  If your travel expenses are minimal or inconsistent, cards
                  with high annual fees or specific usage criteria (e.g.,
                  MakeMyTrip ICICI card) may not add value.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Non-Partner Bookings:{" "}
                  </span>
                  Cards like MakeMyTrip ICICI and Yatra SBI provide rewards
                  primarily through their respective travel portals, so if you
                  book through other channels, these benefits may not be fully
                  utilized.
                </div>
              </div>
            </div>

            {/* minemi tip section */}
            <div
              className="tips mt-4 mb-4"
              style={{
                backgroundColor: "#e7e4f9",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
              }}
            >
              <div
                id="border_line"
                style={{
                  width: "100%",
                  height: "5px",
                  backgroundColor: "#59266d",
                  border: "1px solid #8a8a8a",
                  borderTop: "0px",
                  clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
                }}
              ></div>
              <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
                <div
                  className="px-3 py-2"
                  style={{ backgroundColor: "#e7e4f9" }}
                >
                  <div
                    style={{ backgroundColor: "#e7e4f9" }}
                    className="d-flex flex-row gap-2 align-items-center "
                  >
                    <img
                      src={tongueOut}
                      alt="minemi tip image"
                      style={{
                        width: 30,
                        height: 30,
                        backgroundColor: "#e7e4f9",
                      }}
                      loading="lazy"
                    />
                    <Typography
                      sx={{
                        fontSize: 16,
                        lineHeight: "24px",
                        letterSpacing: "0.08px",
                        color: "#59266D",
                        // color: "yellow",
                        fontFamily: "'Archivo', sans-serif",
                        backgroundColor: "transparent",
                      }}
                    >
                      MinEmi Tip
                    </Typography>
                  </div>
                  <div className="mt-2 ">
                    <Typography
                      sx={{
                        fontSize: 16,
                        lineHeight: "24px",
                        letterSpacing: "0.08px",
                        color: "#1F1F1F",
                        // color: "#F6F6F6",
                        backgroundColor: "#e7e4f9",

                        fontFamily: "'Archivo', sans-serif",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 600,
                          backgroundColor: "transparent",
                        }}
                      >
                        Avoid annual fees
                      </span>
                      &nbsp;by choosing cards that waive the fees when you meet
                      a certain spending threshold. Look for cards that give
                      value on everyday expenses, too, such as dining or grocery
                      purchases.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>

            <p
              style={{
                fontFamily: "Archivo",
                fontWeight: 600,
                fontSize: 28,
                color: "#1F1F1F",
                marginTop: 30,
                lineHeight: 1.2,
              }}
            >
              Comparison Table: Top Travel Credit Cards
            </p>

            <div
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                        verticalAlign: "top",
                      }}
                    >
                      Card Name
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                        verticalAlign: "top",
                      }}
                    >
                      Welcome Bonus
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                        verticalAlign: "top",
                      }}
                    >
                      Rewards per ₹100 Spent
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                        verticalAlign: "top",
                      }}
                    >
                      Airport Lounge Access
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                        verticalAlign: "top",
                      }}
                    >
                      Best For
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                        verticalAlign: "top",
                      }}
                    >
                      Annual Fee
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        borderTopRightRadius: 18,
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                        verticalAlign: "top",
                      }}
                    >
                      Links
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      HDFC Bank Diners Club Privilege
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹5,000 vouchers
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      4 reward points
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      700+ lounges worldwide
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Luxury & frequent international travelers
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹2,500
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href={"https://www.hdfcbank.com/"}
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        HDFC Bank
                      </a>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      SBI Card Elite
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹5,000 welcome voucher
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      2 reward points
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      6 free lounge visits per quarter
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Domestic travelers looking for extensive perks
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹4,999
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href={"https://www.sbicard.com/"}
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        SBI Card
                      </a>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Axis Bank Magnus Credit Card
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹6,000 welcome voucher
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      12 reward points
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Unlimited international lounges
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      High-net-worth individuals seeking premium services
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹10,000
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href={"https://www.axisbank.com/"}
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        Axis Bank
                      </a>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      American Express Platinum Travelers
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹10,000 Taj gift card
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      2 points per ₹100 spent
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Complimentary stays at luxury hotels
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Premium hospitality services
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹5,000
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href={"https://www.americanexpress.com/"}
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        American Express
                      </a>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      ICICI Bank Sapphiro Credit Card
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹5,000 vouchers
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      4 reward points
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      4 lounge visits per quarter
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Budget-conscious travelers with premium benefits
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹2,500
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href={"https://www.icicibank.com/"}
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        ICICI Bank
                      </a>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      MakeMyTrip ICICI Credit Card
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹500 MakeMyTrip voucher
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      10% discount on hotels, cashback
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1% foreign transaction fee waiver
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Frequent MakeMyTrip users
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹1,000
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href={"https://www.icicibank.com/"}
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        ICICI Bank
                      </a>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Yatra SBI Credit Card
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹2,500 Yatra voucher
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      2 reward points
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      6 lounge visits per quarter
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Frequent Yatra users for flights & hotels
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹999
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href={"https://www.sbicard.com/"}
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        SBI Card
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <div className="mt-4">
              <h4
                style={{
                  fontSize: 24,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                }}
              >
                Categories of Travel Credit Cards
              </h4>
              <div
                style={{
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      1. Airport Credit Cards:{" "}
                    </span>{" "}
                    Cards like Adani Credit Card offer airport lounge access and
                    other airport-related benefits. These are perfect for
                    travelers who spend a lot of time in airports.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      2. Flight Credit Cards:{" "}
                    </span>
                    Air India’s credit card and others like JetPrivilege HDFC
                    Card offer airline-specific rewards, miles, and bonuses for
                    bookings.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      3. Service Provider Credit Cards:{" "}
                    </span>
                    Cards like MakeMyTrip ICICI Credit Card and Yatra SBI Credit
                    Card are ideal for booking through specific travel
                    platforms, offering discounts, vouchers, and cashback.
                  </div>
                </div>
              </div>
            </div>

            {/* minemi tip section */}
            <div
              className="tips mt-4 mb-3"
              style={{
                backgroundColor: "#e7e4f9",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
              }}
            >
              <div
                id="border_line"
                style={{
                  width: "100%",
                  height: "5px",
                  backgroundColor: "#59266d",
                  border: "1px solid #8a8a8a",
                  borderTop: "0px",
                  clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
                }}
              ></div>
              <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
                <div
                  className="px-3 py-2"
                  style={{ backgroundColor: "#e7e4f9" }}
                >
                  <div
                    style={{ backgroundColor: "#e7e4f9" }}
                    className="d-flex flex-row gap-2 align-items-center justify-content-start "
                  >
                    <img
                      src={tongueOut}
                      alt="minemi tip image"
                      style={{
                        width: 30,
                        height: 30,
                        backgroundColor: "#e7e4f9",
                      }}
                      loading="lazy"
                    />
                    <Typography
                      sx={{
                        fontSize: 16,
                        lineHeight: "24px",
                        letterSpacing: "0.08px",
                        color: "#59266D",
                        // color: "yellow",
                        fontFamily: "'Archivo', sans-serif",
                        backgroundColor: "transparent",
                      }}
                    >
                      MinEmi Tip
                    </Typography>
                  </div>
                  <div className="mt-2 ">
                    <Typography
                      sx={{
                        fontSize: 16,
                        lineHeight: "24px",
                        letterSpacing: "0.08px",
                        color: "#1F1F1F",
                        // color: "#F6F6F6",
                        backgroundColor: "#e7e4f9",

                        fontFamily: "'Archivo', sans-serif",
                      }}
                    >
                      Compare and contrast the benefits before applying for a
                      card. Check the reward points for categories you spend the
                      most on, such as airlines, hotels, or food. This ensures
                      you get the maximum benefit from your card.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-4">
            <div
              style={{
                marginTop: 20,
              }}
            >
              <h3
                style={{
                  fontSize: 24,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                }}
              >
                Conclusion
              </h3>
              <p
                style={{
                  fontSize: 16,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  fontWeight: 400,
                }}
              >
                A travel credit card is more than just a payment tool—it’s a
                gateway to luxurious and cost-effective travel experiences. By
                choosing the right card, you can enjoy unparalleled benefits
                such as reward points, lower foreign transaction fees, and
                access to premium airport lounges.
              </p>
              <p
                style={{
                  fontSize: 16,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  fontWeight: 400,
                  marginTop: 14,
                }}
              >
                Before applying for a travel credit card, assess your travel
                habits, compare the features of different cards, and read the
                fine print to avoid hidden costs. With proper planning and
                responsible usage, the best travel credit cards can make your
                journeys not only memorable but also financially rewarding.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CCArticleOne;

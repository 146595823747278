import { useEffect, useState } from "react"
import { FaChevronDown, FaChevronUp } from "react-icons/fa"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import CircularProgress from "@mui/material/CircularProgress"
import axios from "axios"
import { useLocation, useNavigate } from "react-router-dom"
import HomeLogo from "../../Assets/Images/HomeLogo.webp"
import userLogo from "../../Assets/Images/userLogo.webp"
import check from "../../Assets/Images/check.webp"
import uncheck from "../../Assets/Images/uncheck.webp"

function LoanEdit() {
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location
  const [first, setFirst] = useState(state.LoanDetail)
  const [openItems, setOpenItems] = useState({})
  const id = localStorage.getItem("id")
  const [open2, setOpen2] = useState(false)
  const [loading, setLoading] = useState(false)
  const [inputEmpty, setInputEmpty] = useState(() => {
    const initialInputEmpty = {}
    first.forEach((item, index) => {
      if (item.Current_Balance === "" || item.Repayment_Tenure === "" || item.EMI_Amount === "") {
        initialInputEmpty[`${index}_Current_Balance`] = item.Current_Balance === ""
        initialInputEmpty[`${index}_Repayment_Tenure`] = item.Repayment_Tenure === ""
        initialInputEmpty[`${index}_EMI_Amount`] = item.EMI_Amount === ""
      }
    })
    return initialInputEmpty
  })

  const handleInputChange = (index, field, value) => {
    const newFirst = [...first]
    newFirst[index][field] = value
    setFirst(newFirst)

    const newInputEmpty = { ...inputEmpty }
    newInputEmpty[`${index}_${field}`] = value === ""
    setInputEmpty(newInputEmpty)
  }

  const handleClick = (index) => {
    setOpenItems((prev) => ({ ...prev, [index]: !prev[index] }))
  }

  const HandleResponse = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");

    const filledLoanDetails = first.filter(
      (data) =>
        data.Current_Balance !== "" &&
        data.Repayment_Tenure !== "" &&
        data.EMI_Amount !== ""
    );

    const formattedData = filledLoanDetails.map((data) => ({
      amt: data.Current_Balance,
      tenure: data.Repayment_Tenure,
      emi: data.EMI_Amount,
      Account_Number: data.Account_Number,
      loan_name: data.loan_Category,
      account_type: data.Account_Type,
      Subscriber_Name: data.Subscriber_Name,
    }));

    const loanDetail = {};
    formattedData.forEach((data, index) => {
      loanDetail[
        index + 1
      ] = `['amt'=>${data.amt}, 'tenure'=>${data.tenure}, 'emi'=>${data.emi}, 'Account_Number'=>'${data.Account_Number}', 'loan_name'=>'${data.loan_name}', 'account_type'=>'${data.account_type}','Subscriber_Name'=>'${data.Subscriber_Name}']`;
    });

    const dataToSend = { loanDetail };

    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/submit_loan_detail/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: dataToSend,
    };

    axios
      .request(options)
      .then(function (response) {
        if (response.data.status == 1) {
          setLoading(false);
          navigate("/savingopportunity", {
            state: { Loan: response.data.data },
          });
        } else {
          toast(response.data.msg);
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
        toast("Please Enter Valid Detail");
        if (error.response.status == 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const formatPrice = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
}, [])
  return (
    <div className="min-h-screen bg-gray-50  py-8">
      <ToastContainer />

      {first.length !== 0 ? (
        <div className="max-w-7xl mx-auto px-4 sm:px-4 lg:px-8" style={{backgroundColor:'transparent'}}>
          <div className="text-center mb-8" style={{backgroundColor:'transparent'}}>
            <h1 className="text-lg md:text-l text-gray-900 mb-4" style={{backgroundColor:'transparent',textAlign:'left',fontFamily:'Archivo',fontWeight:400}}>
              {id == 2
                ? "Please confirm the below details to check for the loans that can be clubbed into one"
                : id == 3
                  ? "Please confirm the below details to check for the top-up opportunities"
                  : "Please confirm the below details to check for the loans for which the EMIs can be lowered"}
            </h1>
            <h2 className="text-2xl md:text-3xl font-bold text-[#59266D]" style={{backgroundColor:'transparent',textAlign:'left',marginTop:'-2%',fontFamily:'Archivo',fontWeight:600}}> saving opportunities</h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6" style={{backgroundColor:'transparent'}}>
            {first?.map((d, index) => {
              const isCategoryOpen = openItems[index]
              return (
                <div
                  key={index}
                  className={`bg-white rounded-lg shadow-sm overflow-hidden transition-all duration-200 ${
                    isCategoryOpen ? "h-auto" : "h-20"
                  }`}
                  style={{backgroundColor:'transparent'}}
                >
                  <div
                    className="flex items-center justify-between p-4 cursor-pointer hover:bg-gray-50"
                    onClick={() => handleClick(index)}
                    style={{backgroundColor:'transparent'}}
                  >
                    <div className="flex items-center space-x-3" style={{backgroundColor:'transparent'}}>
                      <div className="w-6 h-6" style={{backgroundColor:'transparent'}}>
                        <img
                          src={
                            inputEmpty[`${index || "/placeholder.svg"}_Current_Balance`] ||
                            inputEmpty[`${index}_Repayment_Tenure`] ||
                            inputEmpty[`${index}_EMI_Amount`]
                              ? uncheck
                              : check
                          }
                          alt=""
                          className="w-full h-full"
                          style={{backgroundColor:'transparent'}}
                        />
                      </div>

                      <div className="w-12 h-12 rounded-full bg-[rgba(85,50,133,0.1)] flex items-center justify-center">
                        <img src={d.Account_Type === "05" ? userLogo : HomeLogo} alt="" className="w-8 h-8"  style={{backgroundColor:'transparent'}}/>
                      </div>

                      <div style={{backgroundColor:'transparent'}}>
                        <div className="font-bold text-sm md:text-base text-gray-900" style={{backgroundColor:'transparent',fontFamily:'Archivo',fontWeight:600,textTransform:'uppercase'}}>{d.loan_Category}</div>
                        <div className="text-xs text-gray-500" style={{backgroundColor:'transparent',fontFamily:'Archivo',fontWeight:400}}>{d.Subscriber_Name.toUpperCase().slice(0, 18)}</div>
                      </div>
                    </div>

                    <div className="text-gray-400" style={{backgroundColor:'transparent'}}>
                      {isCategoryOpen ? <FaChevronUp size={20} style={{backgroundColor:'transparent',color:'black'}} /> : <FaChevronDown size={20}  style={{backgroundColor:'transparent',color:'black'}}/>}
                    </div>
                  </div>

                  {isCategoryOpen && (
                    <div className="p-6 bg-[#f5f9f7]">
                      <div className="space-y-6" style={{backgroundColor:'transparent'}}>
                        <div style={{backgroundColor:'transparent'}}>
                          <label className="block text-sm font-medium text-gray-700 mb-2" style={{backgroundColor:'transparent'}}>Outstanding</label>
                          <div className="relative" style={{backgroundColor:'transparent'}}>
                            <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500" style={{backgroundColor:'transparent'}}>Rs.</span>
                            <input
                              type="text"
                              placeholder="0,00,000"
                              value={formatPrice(d.Current_Balance)}
                              onChange={(e) => handleInputChange(index, "Current_Balance", e.target.value)}
                              className={`w-full pl-10 pr-3 py-3 rounded-lg text-base focus:outline-none
                                ${
                                  inputEmpty[`${index}_Current_Balance`]
                                    ? "bg-red-50 border-red-300"
                                    : "bg-[#e8f5e9] border-[#4caf50]"
                                }
                              `}
                            />
                          </div>
                        </div>

                        <div style={{backgroundColor:'transparent'}}>
                          <label className="block text-sm font-medium text-gray-700 mb-2" style={{backgroundColor:'transparent'}}>Tenure (Month)</label>
                          <input
                            type="text"
                            placeholder="0,00,000"
                            value={d.Repayment_Tenure}
                            onChange={(e) => handleInputChange(index, "Repayment_Tenure", e.target.value)}
                            className={`w-full px-3 py-3 rounded-lg text-base focus:outline-none
                              ${
                                inputEmpty[`${index}_Repayment_Tenure`]
                                  ? "bg-red-50 border-red-300"
                                  : "bg-[#e8f5e9] border-[#4caf50]"
                              }
                            `}
                          />
                        </div>

                        <div style={{backgroundColor:'transparent'}}>
                          <label className="block text-sm font-medium text-gray-700 mb-2" style={{backgroundColor:'transparent'}}>EMI</label>
                          <div className="relative" style={{backgroundColor:'transparent'}}>
                            <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500" style={{backgroundColor:'transparent'}}>Rs.</span>
                            <input
                              type="text"
                              placeholder="0,00,000"
                              value={formatPrice(d.EMI_Amount)}
                              onChange={(e) => handleInputChange(index, "EMI_Amount", e.target.value)}
                              className={`w-full pl-10 pr-3 py-3 rounded-lg text-base focus:outline-none
                                ${
                                  inputEmpty[`${index}_EMI_Amount`]
                                    ? "bg-red-50 border-red-300"
                                    : "bg-[#e8f5e9] border-[#4caf50]"
                                }
                              `}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
            })}
          </div>

          <div className="mt-8 md:mt-12" style={{backgroundColor:'transparent',marginTop:'8%'}}>
            <button
              onClick={HandleResponse}
              className="w-full md:w-[32%] mx-auto h-[50px] bg-[#59266D] text-white font-bold rounded-lg flex items-center justify-center hover:bg-[#4a1d5d] transition-colors"
            >
              {loading ? <CircularProgress size={24} className="text-white" style={{backgroundColor:'#59266D'}} /> : "Confirm & proceed"}
            </button>
          </div>
        </div>
      ) : (
        <div className="text-center text-gray-900 mt-8" style={{backgroundColor:'transparent'}}>You don't have any Personal or Home Loan running at the moment</div>
      )}
    </div>
  )
}

export default LoanEdit


export default function ThirdFold() {
    return (
      <section className="py-16 md:py-24 px-4 bg-[#311664]/10">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-bold text-[#311664] mb-12">How Minemi AI Helps You</h2>
  
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg border border-[#3a868b]/30 hover:border-[#3a868b] transition-all duration-300 shadow-md">
              <div className="w-16 h-16 bg-[#59266d] rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-white text-2xl font-bold">1</span>
              </div>
              <h3 className="text-xl font-semibold text-[#311664] mb-3">Enter your details & consent</h3>
              <p className="text-[#26292b]">
                Provide basic information and authorize Minemi AI to analyze your credit profile.
              </p>
            </div>
  
            <div className="bg-white p-6 rounded-lg border border-[#3a868b]/30 hover:border-[#3a868b] transition-all duration-300 shadow-md">
              <div className="w-16 h-16 bg-[#59266d] rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-white text-2xl font-bold">2</span>
              </div>
              <h3 className="text-xl font-semibold text-[#311664] mb-3">AI analyzes your credit profile & questions</h3>
              <p className="text-[#26292b]">
                Our AI examines your credit data and understands your specific financial situation.
              </p>
            </div>
  
            <div className="bg-white p-6 rounded-lg border border-[#3a868b]/30 hover:border-[#3a868b] transition-all duration-300 shadow-md">
              <div className="w-16 h-16 bg-[#59266d] rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-white text-2xl font-bold">3</span>
              </div>
              <h3 className="text-xl font-semibold text-[#311664] mb-3">Get personalized answers in real-time</h3>
              <p className="text-[#26292b]">
                Receive tailored advice and solutions specific to your credit profile and financial goals.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
  
  
import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import tongueOut from "../../../Assets/Images/tongue-out.webp";
import MetadataContext from "../../MetadataContext";

const LoanArticleTwo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        " Loan Interest Rates Comparison | Best Personal, Home, Car Loan Rates in 2024",
      pageDescription:
        "Compare loan interest rates for home loans, personal loans, and car loans in 2024. Learn how tenure, collateral, and loan types impact EMIs. Get the best rates from HDFC, ICICI, and SBI.",
      pageKeywords:
        "Loan interest rates, Home loan interest rate, Personal loan interest rate, Car loan interest rate, Loan interest rate comparison, Home loan EMI, Personal loan EMI, HDFC home loan interest rate, SBI personal loan interest rate, Loan interest rates, Home loan interest rate, Personal loan interest rate, Two-wheeler loan interest rate, EMI comparison for loans, Loan interest rates in 2024, Gold loan interest rate, Loan against property interest rate, ICICI home loan rates, HDFC personal loan rates, HDFC home loan interest rate 2024, SBI personal loan interest rate today, IDFC car loan interest rate, Compare loan interest rates by banks, Cheapest home loans in India, How to choose the best loan,Loan against property vs personal loan",
      canonicalUrl: "https://minemi.ai/loanpage/loan-interest-rate-comparison/",
    });
  }, [setMetadata]);
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Loan Interest Rate Comparison
              </Link>
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "46px",
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Loan Interest Rate Comparison: How to Choose the Right Loan for Your
            Needs
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "20px",
            }}
          >
            Compare loan interest rates from different lenders to find the best
            option for your needs. Learn how to evaluate loan terms, EMI, and
            total repayment to make an informed financial decision.
          </p>
        </div>

        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
          <h2
            style={{
              marginTop: 40,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Introduction: Why Loan Interest Rates Matter – A Real-Life Example
          </h2>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Rahul, a 32-year-old professional, recently took a ₹5 lakh personal
            loan to consolidate debt. His EMI came to around ₹11,000 per month.
            A few months later, he took a ₹1.5 lakh two-wheeler loan. To his
            surprise, despite the lower loan amount, his EMI was almost ₹5,000.
            The reason? Interest rates on two-wheeler loans were significant
            higher than personal loans.
          </p>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            This discrepancy highlights the importance of understanding loan
            interest rates and how different types of loans are priced based on
            tenure, risk, and the nature of t asset.
          </div>

          <div>
            <div
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 18,
              }}
            >
              In this guide, we’ll break down:
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  How loan types and tenures impact interest rates.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  Why secured loans like home loans are cheaper than consumer
                  loans.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  Tips on choosing the right loan product for your needs.
                </div>
              </div>
            </div>
          </div>

          {/* table data */}
          <div id="types-of-bt">
            <div id="eligibility-criteria">
              <div
                style={{
                  marginTop: 25,
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 24,
                }}
              >
                1. Understanding Loan Types and Interest Rates
                <p
                  className="pt-2"
                  style={{
                    fontFamily: "'Archivo',sans-serif",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Loan interest rates vary depending on the purpose of the loan,
                  risk factors, and loan tenure. Below is a quick breakdown:
                </p>
              </div>
              <div
                style={{
                  marginTop: 20,
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 22,
                }}
              >
                Loan Interest Rate Comparison Table
              </div>
            </div>

            <div
              id="bt-types-table"
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                // borderRadius: 10,
                fontSize: 16,
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  // borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                      }}
                    >
                      Loan Type
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Interest Rate Range
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Typical Tenure
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                        borderTopRightRadius: 18,
                      }}
                    >
                      EMI for ₹5 Lakh (5 yrs)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#FFF" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Home Loan
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8% – 10%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Up to 30 years
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹10,130
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Personal Loan
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      11% – 18%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1 – 5 years
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹11,162
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Two-Wheeler Loan
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      12% – 20%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1 – 5 years
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹11,500
                    </td>
                  </tr>

                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Gold Loan
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      10% – 14%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1 – 3 years
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹10,870
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Loan Against Property (LAP)
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      9% – 12%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Up to 15 years
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹9,500
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Overdraft (OD) Linked to Home Loan
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      7.5% – 10%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Flexible (OD)
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Varies (Interest only)
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                paddingTop: "20px",
              }}
            >
              This table gives a quick overview of the interest rates, tenures,
              and estimated EMIs for various loan types, helping you make
              informed decisions based on your nancial needs.
            </p>
          </div>

          {/* minemi tip section */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    Key Takeaway
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    "Loans secured by property (home loans, LAP) typically have
                    lower rates. In contrast, consumer loans (two-wheeler,
                    durable loans) attract higher rates due to perceived risk
                    and shorter tenure."
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div id="list-data">
            <div
              style={{
                marginTop: 25,
                color: "#1F1F1F",
                // fontWeight: 600,
                fontSize: 24,
                fontFamily: "'Archivo',sans-serif",
              }}
            >
              <h4>2. How to Choose the Right Loan for Your Needs</h4>
              <p
                style={{
                  fontSize: "16px",
                  paddingTop: 10,
                  fontFamily: "'Archivo',sans-serif",
                  fontWeight: 400,
                }}
              >
                <strong> Choosing the right loan </strong>depends on your
                nancial goal, the urgency of funds, and the repayment duration.
              </p>
            </div>
            <div>
              <h5
                style={{
                  marginTop: 20,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                }}
              >
                1. Need Low Interest + Long Duration? Opt for Home Loan or LAP
              </h5>
              <div
                style={{
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Best for:{" "}
                    </span>
                    Buying property, consolidating high debts.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Why:{" "}
                    </span>
                    Home loans and Loan Against Property (LAP) are secured loans
                    with lower interest rates and longer repayment options. LAP
                    can also be used for personal needs with lower EMIs.
                  </div>
                </div>

                <div>
                  <p style={{ fontSize: "16px", lineHeight: "24px" }}>
                    <span style={{ fontWeight: 700, paddingRight: 5 }}>
                      Example:{" "}
                    </span>
                    A ₹20 lakh LAP for 10 years may have an EMI as low as
                    ₹21,000, while a personal loan of the same value could cross
                    ₹30,000 in EMI.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h5
                style={{
                  marginTop: 20,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                }}
              >
                2. Uncertain Tenure or Cash Flow Issues? Go for Overdraft (OD)
                Linked to Home Loan
              </h5>
              <div
                style={{
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Best for:{" "}
                    </span>
                    Businesses or self-employed professionals with uctuating
                    cash flow.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Why:{" "}
                    </span>
                    OD loans allow you to{" "}
                    <strong>withdraw funds as needed</strong>. Interest is
                    charged only on the amount utilized, making it cost-eective.
                  </div>
                </div>

                <div>
                  <p style={{ fontSize: "16px", lineHeight: "24px" }}>
                    <span style={{ fontWeight: 700, paddingRight: 5 }}>
                      Example:{" "}
                    </span>
                    ICICI Bank’s Money Saver home loan allows the borrower to
                    park extra funds in the OD account, reducing the principal
                    and saving interest.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <h5
                style={{
                  marginTop: 20,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                }}
              >
                3. Short-Term Needs? Avoid Two-Wheeler or Consumer Durable Loans
              </h5>
              <div
                style={{
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Why Avoid:{" "}
                    </span>
                    These loans often have interest rates above 15%, making them
                    expensive for short-term funding.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Alternative:{" "}
                    </span>
                    Consider using a gold loan or LAP.
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* tip section */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    " If you can pledge an asset, gold loans oer quicker
                    disbursal and lower rates compared to personal or consumer
                    durable loans."
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div id="types-of-bt">
            <div id="eligibility-criteria">
              <div
                style={{
                  marginTop: 25,
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 24,
                }}
              >
                3. Comparing Personal Loans and Gold Loans
              </div>
              <div
                className=" pb-2 "
                style={{
                  marginTop: 20,
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 22,
                }}
              >
                Loan Interest Rate Comparison Table
              </div>
              <p
                style={{
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                This table provides a comparison of dierent loan types, focusing
                on processing time, interest rates, collateral requirements, and
                the best use cases for each loan type. Use this as a guide to
                select the most suitable loan for your nancial needs.
              </p>
            </div>

            <div
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                      }}
                    >
                      Loan Type
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Processing Time
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Interest Rate
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Collateral Needed
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Best For
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Personal Loan
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      24 – 48 hours
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      11% – 18%
                    </td>{" "}
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      No
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Medical emergencies, holidays
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Gold Loan
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Same Day
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      10% – 14%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Yes (Gold)
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Short-term needs
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Home Loan
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1 – 2 weeks
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8% – 10%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Yes (Property)
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Purchasing property, long-term investment
                    </td>
                  </tr>

                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Car Loan
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1 – 3 days
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      9% – 13%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Yes (Vehicle)
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Buying a new or used car
                    </td>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderBottomLeftRadius: 24,
                      borderBottomRightRadius: 24,
                    }}
                  >
                    <td
                      className="table-padding"
                      style={{
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                        border: "1px solid #ddd",
                        borderBottomLeftRadius: 20,
                      }}
                    >
                      Education Loan
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                        borderBottomRightRadius: 20,
                      }}
                    >
                      1 – 2 weeks
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                        borderBottomRightRadius: 20,
                      }}
                    >
                      8% – 12%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                        borderBottomRightRadius: 20,
                      }}
                    >
                      Yes (Co-signer often)
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                        borderBottomRightRadius: 20,
                      }}
                    >
                      Funding higher education
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    "Gold loans are cheaper than personal loans and ideal if you
                    have idle assets. Personal loans, however, are unsecured and
                    quicker to apply for. "
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div id="types-of-bt">
            <div id="eligibility-criteria">
              <div
                style={{
                  marginTop: 25,
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 24,
                }}
              >
                4. Bank-Wise Interest Rates (Updated 2024)
              </div>
              <div
                className=" pb-2 "
                style={{
                  marginTop: 20,
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 22,
                }}
              >
                Home Loan Interest Rates (Top Banks)
              </div>
            </div>

            <div
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",

                        color: "#59266D",
                      }}
                    >
                      Bank
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Home Loan Interest Rate
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      HDFC
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.50% – 9.75%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      ICICI
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.60% – 10%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      SBI
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.30% – 9.65%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Axis Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.70% – 10.25%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      IDFC Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.90% – 10.50%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                fontWeight: 400,
                paddingTop: "20px",
              }}
            >
              This table provides an overview of home loan interest rates oered
              by some of the top banks. Use this information to compare and
              choose the bank that best aligns with your nancial needs.
            </p>
          </div>

          <div id="types-of-bt">
            <div id="eligibility-criteria">
              <div
                style={{
                  marginTop: 20,
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 22,
                }}
              >
                Personal Loan Interest Rates (Top Banks)
              </div>
            </div>

            <div
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                      }}
                    >
                      Bank
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Interest Rate Range (%)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      HDFC
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      10.50% – 16%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      ICICI
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      10.99% – 17%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      SBI
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      10.45% – 15.30%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 20,
                color: "#1F1F1F",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  paddingTop: 10,
                  fontFamily: "'Archivo',sans-serif",
                  lineHeight: "24px",
                  fontWeight: 400,
                }}
              >
                This table oers a comparison of the personal loan interest rates
                from some of the leading banks. Use this information to help
                determine which bank might oer the best rate for your personal
                loan needs.
              </p>
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 25,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              5. RBI Guidelines: Why Due Diligence is Critical
              <p
                style={{
                  fontSize: "16px",
                  marginTop: "5px",
                  fontFamily: "'Archivo',sans-serif",
                  fontWeight: 400,
                  lineHeight: "24px",
                }}
              >
                The Reserve Bank of India (RBI) frequently cautions banks and
                customers to conduct due diligence when availing loans.
                Misleading oers, pre-approved loans, and consent for data
                sharing can lead to fraudulent practices.
              </p>
            </div>
            <div className="pt-2">
              <h5 style={{ fontFamily: "'Archivo',sans-serif" }}>
                Tips for Customers:
              </h5>
            </div>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  Never give blanket consent for data access without reviewing
                  the terms.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  Always compare rates from multiple banks (use platforms like
                  Minemi).
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  Watch for hidden charges in the loan agreement.
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Conclusion: Make Informed Decisions with Minemi
            <p
              style={{
                color: "#1F1F1F",
                fontSize: "16px",
                marginTop: "5px",
                fontFamily: "'Archivo',sans-serif",
                lineHeight: "24px",
                fontWeight: 400,
              }}
            >
              Choosing the right loan product depends on your nancial goals and
              repayment capacity. Whether it’s a personal loan, home loan, or
              LAP, understanding <strong>loan interest rates</strong> can save
              you lakhs in the long run.
            </p>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    "Use our loan comparison tool to evaluate the best rates
                    across major banks and secure the lowest possible EMI for
                    your needs."
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br className="display-none" /> and tailored just for you—apply
                now to see what you qualify for!
              </p>
              <div
                style={{
                  backgroundColor: "transparent",
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                }}
              >
                <a
                  href="https://minemi.ai/home-loan/check-eligibility"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 50,
                    paddingRight: 50,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                >
                  Check Eligiblity Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanArticleTwo;

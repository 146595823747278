import React from "react";
import { useState } from "react";
import {
  HelpCircle,
  Calculator,
  CreditCard,
  BarChart,
  TrendingUp,
  Shield,
  Home,
  Clock,
  Search,
  CheckCircle,
} from "lucide-react";
import { Accordion } from "@mui/material";
import { AccordionItem } from "react-bootstrap";

export default function FaqSection() {
  const [activeCategory, setActiveCategory] = useState("all");

  const faqs = [
    {
      id: "faq-1",
      question: "Why should I check my loan approval chances before applying?",
      answer:
        "Checking your chances in advance helps you avoid unnecessary rejections that can negatively impact your credit score. Minemi provides a personalized assessment so you can apply with confidence.",
      icon: <HelpCircle className="h-5 w-5 text-[#311664]" />,
      category: "general",
    },
    {
      id: "faq-2",
      question: "How does Minemi calculate my loan approval chances?",
      answer:
        "We analyze multiple factors such as credit score, income, existing loans, and lender criteria to estimate your chances. Our AI-driven approach ensures accurate and data-backed insights.",
      icon: <Calculator className="h-5 w-5 text-[#311664]" />,
      category: "process",
    },
    {
      id: "faq-3",
      question:
        "Will checking my loan approval chances affect my credit score?",
      answer:
        "No! Minemi's check is a soft inquiry, meaning it does not impact your credit score in any way. It's a risk-free way to assess eligibility before applying.",
      icon: <CreditCard className="h-5 w-5 text-[#311664]" />,
      category: "credit",
    },
    {
      id: "faq-4",
      question: "How accurate are Minemi's loan approval predictions?",
      answer:
        "Minemi uses real-time data analysis and insights from leading Indian banks to provide highly accurate projections. While approval depends on individual banks, our tool ensures you get the best possible assessment.",
      icon: <BarChart className="h-5 w-5 text-[#311664]" />,
      category: "accuracy",
    },
    {
      id: "faq-5",
      question: "What can I do if my approval chances are low?",
      answer:
        "If your approval chances are low, Minemi provides personalized recommendations to help you improve your eligibility—such as clearing existing debts, increasing your income proof, or improving your credit score.",
      icon: <TrendingUp className="h-5 w-5 text-[#311664]" />,
      category: "improvement",
    },
    {
      id: "faq-6",
      question: "Is my personal data safe with Minemi?",
      answer:
        "We use bank-level encryption to keep your data 100% safe and secure. Your information is never shared with third parties without your consent.",
      icon: <Shield className="h-5 w-5 text-[#311664]" />,
      category: "security",
    },
    {
      id: "faq-7",
      question: "What types of loans does Minemi support?",
      answer: (
        <div>
          <p className="mb-2">Minemi covers all major loan types, including:</p>
          <ul className="list-disc pl-5 space-y-1">
            <li>Home Loans</li>
            <li>Personal Loans</li>
            <li>Car Loans</li>
            <li>Business Loans</li>
            <li>Gold Loans</li>
            <li>Loan Against Property (LAP)</li>
            <li>Overdraft & Credit Lines</li>
          </ul>
        </div>
      ),
      icon: <Home className="h-5 w-5 text-[#311664]" />,
      category: "loans",
    },
    {
      id: "faq-8",
      question: "How long does it take to check my loan approval chances?",
      answer:
        "It takes less than 2 minutes! Just answer a few simple questions, and Minemi will instantly provide you with an accurate approval estimate.",
      icon: <Clock className="h-5 w-5 text-[#311664]" />,
      category: "process",
    },
    {
      id: "faq-9",
      question: "Does Minemi also help me find the best loan offers?",
      answer:
        "Yes! Once you check your eligibility, Minemi recommends the best loan options from leading Indian banks, tailored to your financial profile.",
      icon: <Search className="h-5 w-5 text-[#311664]" />,
      category: "offers",
    },
    {
      id: "faq-10",
      question: "Can I apply for a loan through Minemi?",
      answer:
        "Yes! After checking your eligibility, you can directly apply for a loan with our partner banks through Minemi's platform, ensuring a smooth and hassle-free process.",
      icon: <CheckCircle className="h-5 w-5 text-[#311664]" />,
      category: "application",
    },
  ];

  const categories = [
    {
      id: "all",
      name: "All Questions",
      icon: <HelpCircle className="h-4 w-4" />,
    },
    {
      id: "general",
      name: "General",
      icon: <HelpCircle className="h-4 w-4" />,
    },
    {
      id: "process",
      name: "Process",
      icon: <Calculator className="h-4 w-4" />,
    },
    {
      id: "credit",
      name: "Credit Score",
      icon: <CreditCard className="h-4 w-4" />,
    },
    { id: "security", name: "Security", icon: <Shield className="h-4 w-4" /> },
    { id: "loans", name: "Loan Types", icon: <Home className="h-4 w-4" /> },
  ];

  const filteredFaqs =
    activeCategory === "all"
      ? faqs
      : faqs.filter((faq) => faq.category === activeCategory);

  return (
    <section
      className="w-full py-8 md:py-16 "
      style={{ backgroundColor: "#f8f8f8" }}
    >
      <div className="container mx-auto px-4">
        <div className="text-center mb-8 md:mb-12">
          <h2 className="text-2xl md:text-4xl font-bold text-[#311664] mb-2 md:mb-4">
            Frequently Asked Questions (FAQs)
          </h2>
          <p className="text-base md:text-lg text-gray-600 max-w-2xl mx-auto">
            Everything you need to know before checking your loan approval
            chances.
          </p>
        </div>

        {/* Category filters - scrollable on mobile */}
        <div className="mb-8 overflow-x-auto pb-2">
          <div
            className="flex space-x-2 min-w-max"
            style={{ justifyContent: "center" }}
          >
            {categories.map((category) => (
              <button
                key={category.id}
                onClick={() => setActiveCategory(category.id)}
                className={`flex items-center px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  activeCategory === category.id
                    ? "bg-[#311664] text-white"
                    : "bg-white text-[#311664] hover:bg-[#ebe8fc]"
                }`}
              >
                <span className="mr-2">{category.icon}</span>
                {category.name}
              </button>
            ))}
          </div>
        </div>

        <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
          <Accordion type="single" collapsible className="w-full">
            {filteredFaqs.map((faq) => (
              <AccordionItem
                key={faq.id}
                value={faq.id}
                className="border-b border-[#ebe8fc] last:border-0"
              >
                <div className="py-4 px-6 hover:no-underline hover:bg-[#ebe8fc]/20 group">
                  <div className="flex items-center text-left">
                    <div className="mr-3 bg-[#ebe8fc] p-2 rounded-full">
                      {faq.icon}
                    </div>
                    <span className="text-[#311664] font-medium text-sm md:text-base group-hover:text-[#311664]">
                      {faq.question}
                    </span>
                  </div>
                </div>
                <div className="px-6 pb-4 pt-2 text-gray-600 text-sm md:text-base">
                  <div className="pl-11">{faq.answer}</div>
                </div>
              </AccordionItem>
            ))}
          </Accordion>
        </div>

        <div className="mt-8 text-center">
          <p className="text-sm text-gray-500">
            Don't see your question here?{" "}
            <a href="#" className="text-[#3a868b] font-medium hover:underline">
              Contact our support team
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}

import { FaAngleRight, FaSearch, FaRegThumbsUp } from "react-icons/fa";

import { LuCircleCheckBig } from "react-icons/lu";
import React from "react";

function Newrecommendation() {
  return (
    <div className=" bg-[#ebe8fc] text-[#311664]">
      {/* Hero Section */}
      <section className="container mx-auto px-4 py-16 md:py-32 flex items-center bg-transparent" style={{minHeight:'80vh'}}>
        <div className="max-w-3xl mx-auto text-center bg-transparent mt-4 " style={{marginBottom:'9%'}}>
          <h1 className="text-4xl md:text-5xl font-bold mb-4 bg-transparent">
            Find Your Perfect Loan Match Today
          </h1>
          <p className="text-xl mb-8 bg-transparent text-center">
            Let us know how much funds you need, and we'll find AI-based suited
            credit options for you.
          </p>
          <div className="space-y-4 bg-transparent">
            <select className="w-full bg-white border-2 border-[#59266d] text-[#311664] p-3 rounded ">
              <option value="">Select loan amount...</option>
              <option value="1000-100000">₹1,000 - ₹1,00,000</option>
              <option value="100001-500000">₹1,00,001 - ₹5,00,000</option>
              <option value="500001-1000000">₹5,00,001 - ₹10,00,000</option>
              <option value="1000001-5000000">₹10,00,001 - ₹50,00,000</option>
              <option value="5000001+">₹50,00,001+</option>
            </select>
            <button className="w-full bg-[#59266d] hover:bg-[#59266d]/90 text-white text-lg py-3 rounded">
              Get Started
            </button>
          </div>
          <p className="text-sm mt-4 text-[#59266d] bg-transparent text-center">
            This service is completely free and has no impact on your credit
            score.
          </p>
        </div>
      </section>

      {/* Loan Recommendation Process */}
      <section className="bg-[#59266d] text-white py-16">
        <div className="container-fluid mx-auto px-4 bg-transparent text-center" >
          <h2 className="text-3xl font-bold text-center mb-12" style={{color:'white',justifyContent:'center'}}>How It Works</h2>
          <div className="grid md:grid-cols-4 gap-8 bg-transparent">
            {[
              {
                icons: (
                  <FaAngleRight style={{ backgroundColor: "transparent" }} />
                ),
                title: "Basic Questions",
                description:
                  "Answer a few simple questions about your loan requirements and financial situation.",
              },
              {
                icons: <FaSearch style={{ backgroundColor: "transparent" }} />,
                title: "Credit Score Check",
                description:
                  "We'll perform a soft credit check that won't affect your credit score.",
              },
              {
                icons: (
                  <FaRegThumbsUp style={{ backgroundColor: "transparent" }} />
                ),
                title: "AI Analysis",
                description:
                  "Our AI system analyzes your information to find the best loan options.",
              },
              {
                icons: (
                  <LuCircleCheckBig
                    style={{ backgroundColor: "transparent" }}
                  />
                ),
                title: "Personalized Results",
                description:
                  "Review your tailored loan recommendations and choose the best option.",
              },
            ].map((item, index) => (
              <div key={index} className="text-center bg-transparent">
                <div className="bg-white/10 rounded-full p-4 inline-block mb-4">
                  <span className="text-3xl bg-transparent">{item.icons}</span>
                </div>
                <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                <p className="text-sm bg-transparent text-center">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="container-fluid mx-auto px-4 py-16 bg-transparent">
        <h2 className="text-3xl font-bold text-center mb-12" style={{justifyContent:'center'}}>
          Why Choose Our Loan Recommendation Service?
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 bg-transparent">
          {[
            "Personalized AI-Driven Recommendations",
            "Save Time and Effort",
            "No Impact on Credit Score",
            "Compare Multiple Lenders Instantly",
            "Quick and Easy Process",
            "Expert Financial Guidance",
          ].map((benefit, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md p-6 border border-[#ebe8fc] flex items-start"
            >
              <span className="text-2xl text-[#3a868b] mr-4 bg-transparent">
                <LuCircleCheckBig style={{ backgroundColor: "transparent" }} />
              </span>
              <p className="text-lg font-semibold text-[#311664] bg-transparent">
                {benefit}
              </p>
            </div>
          ))}
        </div>
      </section>

      {/* Final Expectations Section */}
      <section className="bg-[#ebe8fc] py-16">
        <div className="container-fluid mx-auto px-4 bg-transparent">
          <h2 className="text-3xl font-bold text-center mb-12" style={{justifyContent:'center'}}>
            What to Expect in Your Recommendations
          </h2>
          <div className="grid md:grid-cols-3 gap-8 bg-transparent">
            {[
              {
                title: "Lowest Interest Rates",
                description:
                  "We prioritize loans with the most competitive interest rates available based on your profile.",
              },
              {
                title: "Minimum EMI Options",
                description:
                  "Find loans with flexible repayment terms and the lowest possible EMIs to fit your budget.",
              },
              {
                title: "Easy Approval Process",
                description:
                  "We'll show you loans with higher approval chances and simpler application processes.",
              },
            ].map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md p-6 border-1 border-[#59266d]"
              >
                <h3 className="text-xl font-semibold mb-4 text-[#59266d]">
                  {item.title}
                </h3>
                <p className="text-[#311664] bg-transparent">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-[#59266d] text-white py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4" style={{justifyContent:'center',color:'white'}}>
            Ready to Find Your Perfect Loan?
          </h2>
          <p className="text-xl mb-8 bg-transparent text-center">
            Get started now and discover the best loan options tailored just for
            you.
          </p>
          <button className="bg-[#3a868b] hover:bg-[#3a868b]/90 text-white text-lg px-8 py-3 rounded">
            Get Your Personalized Recommendations
          </button>
        </div>
      </section>
    </div>
  );
}

export default Newrecommendation;

import { CheckCircle } from "lucide-react";
import { useEffect, useState, useMemo } from "react";

export function LoanStatusMethods({ name }) {
  const [data, setData] = useState([]);

  const kotakData = useMemo(
    () => ({
      h2: "How to Check Your Kotak Mahindra Bank Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "Kotak Net Banking",
          para2:
            " Log in to Kotak Net Banking to check your home loan application status.",
          step1: "Log in to Kotak Net Banking",
          step2: "Navigate to the 'Loans' or 'Applications' section",
          step3: "Select your application to view its current status",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),
          h3: " Home Loan Helpline",
          para2:
            " Contact Kotak Mahindra Bank's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call Kotak Mahindra Bank at 1800-209-8800 (toll-free) or +91 22 6600 6022",
          step2: "Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),

          h3: " Visit a Kotak Mahindra Bank Branch",
          para2:
            " Visit your nearest Kotak Mahindra Bank branch or Home Loan Center with your application details.",
          step1: "Carry your application number and ID proof",
          step2: "Meet with a home loan officer for detailed information",
          step3: "Get personalized guidance on next steps",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            " Use our secure online tracker above to instantly check your Kotak Mahindra Bank Home Loan status.",
          step1: "Enter your application details",
          step2: " Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      h2: "How to Check Your Bank of Baroda Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),

          h3: " MinEMI Loan Tracker",
          para2:
            "  Use our secure online tracker above to instantly check your Bank of Baroda Home Loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: " View your loan application progress",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "BOB World Internet Banking",
          para2:
            "  Log in to BOB World Internet Banking to check your home loan application status.",
          step1: "Log in to BOB World Internet Banking",
          step2: "Navigate to the 'Loans' or 'Applications' section",
          step3: "Select your application to view its current status",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),
          h3: " Home Loan Helpline",
          para2:
            "Contact Bank of Baroda's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call Bank of Baroda at 1800-258-4455 (toll-free) or +91 22 6985 4141",
          step2: "Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),

          h3: " Visit a Bank of Baroda Branch",
          para2:
            "  Visit your nearest Bank of Baroda branch or Home Loan Center with your application details.",
          step1: "Carry your application number and ID proof",
          step2: " Meet with a home loan officer for detailed information",
          step3: "Get personalized guidance on next steps",
        },
      ],
    }),
    []
  );
  const idfcData = useMemo(
    () => ({
      h2: " How to Check Your IDFC FIRST Bank Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: " IDFC FIRST Mobile App",
          para2:
            " Log in to the IDFC FIRST Mobile App to check your home loan application status.",
          step1: "Log in to the IDFC FIRST Mobile App",
          step2: "Navigate to the 'Loans' or 'Applications' section",
          step3: "Select your application to view its current status",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: "Home Loan Helpline",
          para2:
            " Contact IDFC FIRST Bank's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call IDFC FIRST Bank at 1800-266-9970 (toll-free) or +91 22 7132 5500",
          step2: " Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "Visit an IDFC FIRST Bank Branch",
          para2:
            " Visit your nearest IDFC FIRST Bank branch or Home Loan Center with your application details.",
          step1: "Carry your application number and ID proof",
          step2: "Meet with a home loan officer for detailed information",
          step3: "Get personalized guidance on next steps",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Loan Tracker",
          para2:
            " Use our secure online tracker above to instantly check your IDFC FIRST Bank Home Loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: "View your loan application progress",
        },
      ],
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      h2: " How to Check Your HDFC Bank Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: " HDFC Bank NetBanking",
          para2:
            " Log in to HDFC Bank NetBanking to check your home loan application status.",
          step1: "Log in to HDFC Bank NetBanking",
          step2: " Navigate to the 'Loans' or 'Applications' section",
          step3: "Select your application to view its current status",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Home Loan Helpline",
          para2:
            "  Contact HDFC Bank's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call HDFC Bank at 1800-202-6161 (toll-free) or +91 22 6171 6161",
          step2:
            "     Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "Visit an HDFC Bank Branch",
          para2:
            " Visit your nearest HDFC Bank branch or Home Loan Center with your application details.",
          step1: "Carry your application number and ID proof",
          step2: " Meet with a home loan officer for detailed information",
          step3: " Get personalized guidance on next steps",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            " We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: " Provide verification information",
          step3: "View your loan application Status",
        },
      ],
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      h2: "How to Check Your SBI Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: " YONO SBI App",
          para2:
            " Log in to the YONO SBI App to check your home loan application status.status.",
          step1: "Log in to the YONO SBI App",
          step2: " Navigate to the 'Loans' or 'Applications' section",
          step3: "Select your application to view its current status",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Home Loan Helpline",
          para2:
            "Contact SBI's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call SBI at 1800-11-2211 (toll-free) or 1800-425-3800 (toll-free)",
          step2: "Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "Visit an SBI Branch",
          para2:
            " Visit your nearest SBI branch or Home Loan Center with your application details.",
          step1: "Carry your application number and ID proof",
          step2: "Meet with a home loan officer for detailed information",
          step3: "Get personalized guidance on next steps",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            " Use our secure online tracker above to instantly check your SBI Home Loan status.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application progress",
        },
      ],
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      h2: "How to Check Your Axis Bank Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "Axis Mobile App",
          para2:
            " Log in to the Axis Mobile App to check your home loan application status.",
          step1: "Log in to the Axis Mobile App",
          step2: "Navigate to the 'Loans' or 'Applications' section",
          step3: "Select your application to view its current status",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Home Loan Helpline",
          para2:
            "Contact Axis Bank's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call Axis Bank at 1800-419-5477 (toll-free) or 1800-209-5577 (toll-free)",
          step2: "Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "Visit an Axis Bank Branch",
          para2:
            "Visit your nearest Axis Bank branch or Home Loan Center with your application details.",
          step1: "Carry your application number and ID proof",
          step2: "Meet with a home loan officer for detailed information",
          step3: "Get personalized guidance on next steps",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your Axis Bank Home Loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: "View your loan application progress",
        },
      ],
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      h2: "How to Check Your ICICI Bank Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: " iMobile Pay App",
          para2:
            "  Log in to the ICICI Bank iMobile Pay App to check your home loan application status.",
          step1: "Log in to the iMobile Pay App",
          step2: " Navigate to the 'Loans' or 'Applications' section",
          step3: " Select your application to view its current status",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Home Loan Helpline",
          para2:
            "Contact ICICI Bank's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call ICICI Bank at 1800-103-8181 (toll-free) or 1800-267-6347 (toll-free)",
          step2: "Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "Visit an ICICI Bank Branch",
          para2:
            "Visit your nearest ICICI Bank branch or Home Loan Service Center with your application details.",
          step1: "Carry your application number and ID proof",
          step2: "Meet with a home loan officer for detailed information",
          step3: "Get personalized guidance on next steps",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your ICICI Bank Home Loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: " View your loan application progress",
        },
      ],
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      h2: "How to Check Your Punjab National Bank Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "PNB One Mobile App",
          para2:
            " Log in to the PNB One Mobile App to check your home loan application status.",
          step1: "Log in to the PNB One Mobile App",
          step2: " Navigate to the 'Loans' or 'Applications' section",
          step3: " Select your application to view its current status",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: "Home Loan Helpline",
          para2:
            "Contact Punjab National Bank's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call PNB at 1800-180-2222 (toll-free) or 1800-103-2222 (toll-free)",
          step2: "Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "Visit a PNB Branch",
          para2:
            "Visit your nearest Punjab National Bank branch or Home Loan Service Center with your application details.",
          step1: "Carry your application number and ID proof",
          step2: "Meet with a home loan officer for detailed information",
          step3: "Get personalized guidance on next steps",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your Punjab National Bank Home Loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: " View your loan application progress",
        },
      ],
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      h2: "How to Check Your IndusInd Bank Personal Loan Status",
      para: " Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "IndusInd Mobile App",
          para2:
            "  Log in to the IndusInd Mobile App to check your home loan application status.",
          step1: "Log in to the IndusInd Mobile App",
          step2: " Navigate to the 'Loans' or 'Applications' section",
          step3: " Select your application to view its current status",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: "Home Loan Helpline",
          para2:
            "Contact IndusInd Bank's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call IndusInd Bank at 1860-500-5004 (toll-free) or 022-4406 6666",
          step2: "Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "Visit an IndusInd Bank Branch",
          para2:
            "Visit your nearest IndusInd Bank branch or Home Loan Service Center with your application details.",
          step1: "Carry your application number and ID proof",
          step2: "Meet with a home loan officer for detailed information",
          step3: "Get personalized guidance on next steps",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Loan Tracker",
          para2:
            " Use our secure online tracker above to instantly check your IndusInd Bank Home Loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: " View your loan application progress",
        },
      ],
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      h2: "How to Check Your Tata Housing Finance Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "Tata Capital Mobile App",
          para2:
            "  Log in to the Tata Capital Mobile App to check your home loan application status.",
          step1: "Log in to the Tata Capital Mobile App",
          step2: " Navigate to the 'Loans' or 'Applications' section",
          step3: "Select your application to view its current status",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Home Loan Helpline",
          para2:
            " Contact Tata Housing Finance's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call Tata Housing Finance at 1800-267-8282 (toll-free) or 022-6182 8282",
          step2: "Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "Visit a Tata Capital Branch",
          para2:
            "Visit your nearest Tata Capital branch or Home Loan Service Center with your application details.",
          step1: "Carry your application number and ID proof",
          step2: "Meet with a home loan officer for detailed information",
          step3: "Get personalized guidance on next steps",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Loan Tracker",
          para2:
            " Use our secure online tracker above to instantly check your Tata Housing Finance Home Loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: " View your loan application progress",
        },
      ],
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      h2: "How to Check Your Yes Bank Home Loan Status",
      para: "Multiple convenient options to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "YES Bank Net Banking",
          para2:
            "  Track your home loan application directly through YES Bank's Net Banking portal or mobile app.",
          step1: "Log in to YES Bank Net Banking or mobile app",
          step2: " Navigate to 'Loans' → 'Home Loan' → 'Application Status'",
          step3: "View comprehensive status with stage-wise tracking",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " YES Bank Home Loan Helpline",
          para2:
            "Speak directly with YES Bank's dedicated home loan specialists for status updates and guidance.",
          step1: "Call YES Bank's home loan helpline at 1800 103 1212",
          step2:
            "Provide your application reference number and verification details",
          step3: "Request specific information about pending requirements",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "YES Bank Email Service",
          para2:
            "Get detailed status updates via email for comprehensive tracking of your YES Bank home loan application.",
          step1: "Email your application number to homeloan@yesbank.in",
          step2: "Receive detailed status update within 24-48 hours",
          step3: "Get personalized guidance on any pending requirements",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Home Loan Tracker",
          para2:
            " Use our secure online tracker above to instantly check your YES Bank home loan status with real-time updates.",
          step1: "Enter your YES Bank application reference number",
          step2: "Provide your registered mobile number for verification",
          step3: "Get detailed status with estimated timeline to completion",
        },
      ],
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      h2: "4 Ways to Check Your Union Bank Home Loan Status",
      para: " Multiple convenient options to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "Union Bank Net Banking",
          para2:
            " Track your home loan application directly through Union Bank's Net Banking portal or mobile app.",
          step1: "Log in to Union Bank Net Banking or mobile app",
          step2: " Navigate to 'Loans' → 'Home Loan' → 'Application Status'",
          step3: " View comprehensive status with stage-wise tracking",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: "Union Bank Home Loan Helpline",
          para2:
            "Speak directly with Union Bank's dedicated home loan specialists for status updates and guidance.",
          step1: "Call Union Bank's home loan helpline at 1800 208 2244",
          step2: "Request specific information about pending requirements",
          step3: "Get your loan status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "Union Bank Branch Visit",
          para2:
            "Visit your nearest Union Bank branch where you applied for the home loan to get detailed status updates and personalized assistance.",
          step1: "Carry your application acknowledgment slip and ID proof",
          step2: "Meet with the home loan officer for personalized updates",
          step3: "Get immediate resolution for any pending documentation",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Home Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your Union Bank home loan status with real-time updates.",
          step1: "Enter your Union Bank application reference number",
          step2: "Provide your registered mobile number for verification",
          step3: "Get detailed status with estimated timeline to completion",
        },
      ],
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      h2: "4 Ways to Check Your Bajaj Finance Home Loan Status",
      para: " Multiple convenient options to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "Bajaj Finserv App",
          para2:
            " Track your home loan application directly through the Bajaj Finserv mobile app for real-time updates.",
          step1: "Download and log in to the Bajaj Finserv app",
          step2: "Navigate to 'Loans' → 'Home Loan' → 'Application Status'",
          step3: "View comprehensive status with stage-wise tracking",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: "Bajaj Finance Customer Care",
          para2:
            "Speak directly with Bajaj Finance's dedicated home loan specialists for status updates and guidance.",
          step1: "Call Bajaj Finance's customer care at 1800 209 5363",
          step2:
            "Provide your application reference number and verification details",
          step3: "Request specific information about pending requirements",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "Bajaj Finance Branch Visit",
          para2:
            "Visit your nearest Bajaj Finance branch where you applied for the home loan to get detailed status updates and personalized assistance.",
          step1: "Carry your application acknowledgment slip and ID proof",
          step2: "Meet with the home loan officer for personalized updates",
          step3: "Get immediate resolution for any pending documentation",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Home Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your Bajaj Finance home loan status with real-time updates.",
          step1: "Enter your Bajaj Finance application reference number",
          step2: "Provide your registered mobile number for verification",
          step3: "Get detailed status with estimated timeline to completion",
        },
      ],
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      h2: "4 Ways to Check Your Federal Bank Home Loan Status",
      para: " Multiple convenient options to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "FedMobile App",
          para2:
            "  Track your home loan application directly through the Federal Bank mobile app for real-time updates.",
          step1: "Download and log in to the FedMobile app",
          step2: " Navigate to 'Loans' → 'Home Loan' → 'Application Status'",
          step3: " View comprehensive status with stage-wise tracking",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Federal Bank Customer Care",
          para2:
            "Speak directly with Federal Bank's dedicated home loan specialists for status updates and guidance.",
          step1: "Call Federal Bank's customer care at 1800 420 1199",
          step2:
            "Provide your application reference number and verification details",
          step3: "Request specific information about pending requirements",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "Federal Bank Branch Visit",
          para2:
            "Visit your nearest Federal Bank branch where you applied for the home loan to get detailed status updates and personalized assistance.",
          step1: "Carry your application acknowledgment slip and ID proof",
          step2: "Meet with the home loan officer for personalized updates",
          step3: "Get immediate resolution for any pending documentation",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Home Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your Federal Bank home loan status with real-time updates.",
          step1: "Enter your Federal Bank application reference number",
          step2: "Provide your registered mobile number for verification",
          step3: " Get detailed status with estimated timeline to completion",
        },
      ],
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      h2: "4 Ways to Check Your Canara Bank Home Loan Status",
      para: " Multiple convenient options to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "Canara Bank Net Banking",
          para2:
            " Track your home loan application directly through Canara Bank's internet banking portal for real-time updates.",
          step1: "Log in to Canara Bank's net banking portal",
          step2: " Navigate to 'Loans' → 'Home Loan' → 'Application Status'",
          step3: "View comprehensive status with stage-wise tracking",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Canara Bank Customer Care",
          para2:
            "Speak directly with Canara Bank's dedicated home loan specialists for status updates and guidance.",
          step1: "Call Canara Bank's customer care at 1800 103 0003",
          step2:
            "Provide your application reference number and verification details",
          step3: "Request specific information about pending requirements",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "Canara Bank Branch Visit",
          para2:
            "Visit your nearest Canara Bank branch where you applied for the home loan to get detailed status updates and personalized assistance.",
          step1: "Carry your application acknowledgment slip and ID proof",
          step2: "Meet with the home loan officer for personalized updates",
          step3: "Get immediate resolution for any pending documentation",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Home Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your Canara Bank home loan status with real-time updates.",
          step1: "Enter your Canara Bank application reference number",
          step2: "Provide your registered mobile number for verification",
          step3: " Get detailed status with estimated timeline to completion",
        },
      ],
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      h2: "4 Ways to Check Your Bank of India Home Loan Status",
      para: "Multiple convenient options to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "BOI StarConnect Net Banking",
          para2:
            " Track your home loan application directly through Bank of India's internet banking portal for real-time updates.",
          step1: "Log in to Bank of India's StarConnect net banking portal",
          step2: " Navigate to 'Loans' → 'Home Loan' → 'Application Status'",
          step3: " View comprehensive status with stage-wise tracking",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Bank of India Customer Care",
          para2:
            "Speak directly with Bank of India's dedicated home loan specialists for status updates and guidance.",
          step1: "Call Bank of India's customer care at 1800 103 1906",
          step2:
            "Provide your application reference number and verification details",
          step3: "GRequest specific information about pending requirements",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "Bank of India Branch Visit",
          para2:
            "Visit your nearest Bank of India branch where you applied for the home loan to get detailed status updates and personalized assistance.",
          step1: "Carry your application acknowledgment slip and ID proof",
          step2: "Meet with the home loan officer for personalized updates",
          step3: "Get immediate resolution for any pending documentation",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Home Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your Bank of India home loan status with real-time updates.",
          step1: "Enter your Bank of India application reference number",
          step2: "Provide your registered mobile number for verification",
          step3: "Get detailed status with estimated timeline to completion",
        },
      ],
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      h2: "4 Ways to Check Your RBL Bank Home Loan Status",
      para: "Multiple convenient options to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "RBL Bank Net Banking",
          para2:
            "Track your home loan application directly through RBL Bank's internet banking portal for real-time updates.",
          step1: "Log in to RBL Bank's net banking portal",
          step2: " Navigate to 'Loans' → 'Home Loan' → 'Application Status'",
          step3: " View comprehensive status with stage-wise tracking",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: "RBL Bank Customer Care",
          para2:
            "Speak directly with RBL Bank's dedicated home loan specialists for status updates and guidance.",
          step1: "Call RBL Bank's customer care at 1800 121 9050",
          step2:
            "PProvide your application reference number and verification details",
          step3: "Request specific information about pending requirements",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "RBL Bank Branch Visit",
          para2:
            "Visit your nearest RBL Bank branch where you applied for the home loan to get detailed status updates and personalized assistance.",
          step1: "Carry your application acknowledgment slip and ID proof",
          step2: "Meet with the home loan officer for personalized updates",
          step3: "Get immediate resolution for any pending documentation",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Home Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your RBL Bank home loan status with real-time updates.",
          step1: "Enter your RBL Bank application reference number",
          step2: "Provide your registered mobile number for verification",
          step3: "Get detailed status with estimated timeline to completion",
        },
      ],
    }),
    []
  );
  const ltData = useMemo(
    () => ({
      h2: "4 Ways to Check Your L&T Finance Home Loan Status",
      para: "Multiple convenient options to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "L&T Bank Net Banking",
          para2:
            "Track your home loan application directly through L&T Bank's internet banking portal for real-time updates.",
          step1: "Log in to L&T Bank's net banking portal",
          step2: " Navigate to 'Loans' → 'Home Loan' → 'Application Status'",
          step3: " View comprehensive status with stage-wise tracking",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: "L&T Bank Customer Care",
          para2:
            "Speak directly with L&T Bank's dedicated home loan specialists for status updates and guidance.",
          step1: "Call L&T Bank's customer care at 1800 121 9050",
          step2:
            "PProvide your application reference number and verification details",
          step3: "Request specific information about pending requirements",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "L&T Bank Branch Visit",
          para2:
            "Visit your nearest L&T Bank branch where you applied for the home loan to get detailed status updates and personalized assistance.",
          step1: "Carry your application acknowledgment slip and ID proof",
          step2: "Meet with the home loan officer for personalized updates",
          step3: "Get immediate resolution for any pending documentation",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Home Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your L&T Finance home loan status with real-time updates.",
          step1: "Enter your L&T Finance application reference number",
          step2: "Provide your registered mobile number for verification",
          step3: "Get detailed status with estimated timeline to completion",
        },
      ],
    }),
    []
  );
  const idbiData = useMemo(
    () => ({
      h2: "4 Ways to Check Your IDBI Finance Home Loan Status",
      para: "Multiple convenient options to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "IDBI Bank Net Banking",
          para2:
            "Track your home loan application directly through IDBI Bank's internet banking portal for real-time updates.",
          step1: "Log in to IDBI Bank's net banking portal",
          step2: " Navigate to 'Loans' → 'Home Loan' → 'Application Status'",
          step3: " View comprehensive status with stage-wise tracking",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: "IDBI Bank Customer Care",
          para2:
            "Speak directly with IDBI Bank's dedicated home loan specialists for status updates and guidance.",
          step1: "Call IDBI Bank's customer care at 1800-209-4324",
          step2:
            "PProvide your application reference number and verification details",
          step3: "Request specific information about pending requirements",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "IDBI Bank Branch Visit",
          para2:
            "Visit your nearest IDBI Bank branch where you applied for the home loan to get detailed status updates and personalized assistance.",
          step1: "Carry your application acknowledgment slip and ID proof",
          step2: "Meet with the home loan officer for personalized updates",
          step3: "Get immediate resolution for any pending documentation",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Home Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your IDBI home loan status.",
          step1: "Enter your IDBI Finance application reference number",
          step2: "Provide your registered mobile number for verification",
          step3: "Get detailed status with estimated timeline to completion",
        },
      ],
    }),
    []
  );
  const lichousingData = useMemo(
    () => ({
      h2: "4 Ways to Check Your LIC Housing Finance Home Loan Status",
      para: "Multiple convenient options to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "LIC HFL Customer Portal",
          para2:
            "Check your LIC Housing Finance home loan application status through their official customer portal.",
          step1: "Visit the LIC HFL website and log in to your account",
          step2: " Navigate to 'My Applications' section'",
          step3: "View your application status and details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: "LIC HFL Customer Care",
          para2:
            " Contact LIC Housing Finance's dedicated customer support to inquire about your application status.",
          step1: "Call LIC HFL customer care at 1800-258-5678",
          step2: "Provide your application number",
          step3: "Get detailed status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "LIC HFL Bank Branch Visit",
          para2:
            "Visit your nearest LIC Housing Finance Bank branch where you applied for the home loan to get detailed status updates and personalized assistance.",
          step1: "Carry your application acknowledgment slip and ID proof",
          step2: "Meet with the home loan officer for personalized updates",
          step3: "Get immediate resolution for any pending documentation",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Home Loan Tracker",
          para2:
            " Use our secure online tracker above to instantly check your LIC Housing Finance home loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: "View your loan application progress",
        },
      ],
    }),
    []
  );
  const pnbhousingData = useMemo(
    () => ({
      h2: "4 Ways to Check Your PNB Housing Finance Home Loan Status",
      para: "Multiple convenient options to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "PNB Home Finance Customer Portal",
          para2:
            "Check your PNB Home Finance home loan application status through their official customer portal.",
          step1:
            "Visit the PNB Home Finance website and log in to your account",
          step2: " Navigate to 'My Applications' section'",
          step3: "View your application status and details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: "PNB Home FinanceL Customer Care",
          para2:
            " Contact PNB Home Finance dedicated customer support to inquire about your application status.",
          step1: "Call PNB Home Finance customer care at 1800-120-8800",
          step2: "Provide your application number",
          step3: "Get detailed status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "PNB Home Finance Bank Branch Visit",
          para2:
            "Visit your nearest PNB Home Finance Bank branch where you applied for the home loan to get detailed status updates and personalized assistance.",
          step1: "Carry your application acknowledgment slip and ID proof",
          step2: "Meet with the home loan officer for personalized updates",
          step3: "Get immediate resolution for any pending documentation",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Home Loan Tracker",
          para2:
            " Use our secure online tracker above to instantly check your PNB Home Finance home loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: "View your loan application progress",
        },
      ],
    }),
    []
  );
  const hdfclnthousingData = useMemo(
    () => ({
      h2: "How to Check Your HDFC Ltd. Home Loan Status",
      para: "Multiple convenient options to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "HDFC HomeOnline Portal & App",
          para2:
            "Access your loan status directly through the official HDFC HomeOnline portal or mobile app.",
          step1: "Log in to the HDFC HomeOnline portal or mobile app",
          step2: " Navigate to 'My Applications' or 'Track Application'",
          step3: "View real-time status and pending requirements",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: "Customer Care Support",
          para2:
            " Speak directly with HDFC Ltd. customer support representatives about your home loan status.",
          step1: "Call HDFC Ltd. at 1800-120-8363 (toll-free)",
          step2: "Provide your application number and verification details",
          step3: "Request detailed information about your application",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "HDFC Ltd. Home Finance Bank Branch Visit",
          para2:
            "Visit your nearest HDFC Ltd. Home Finance Bank branch where you applied for the home loan to get detailed status updates and personalized assistance.",
          step1: "Carry your application acknowledgment slip and ID proof",
          step2: "Meet with the home loan officer for personalized updates",
          step3: "Get immediate resolution for any pending documentation",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Home Loan Tracker",
          para2:
            " Use our secure online tracker above to instantly check your HDFC Ltd. home loan status.",
          step1: "Enter your application reference number",
          step2: "Provide your mobile number for verification",
          step3: "Get detailed status with next steps",
        },
      ],
    }),
    []
  );
  const sbihomehousingData = useMemo(
    () => ({
      h2: "How to Check Your HDFC Ltd. Home Loan Status",
      para: "Multiple convenient options to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "YONO SBI App & Online Banking",
          para2:
            "Access your loan status directly through the official YONO SBI app or SBI online banking portal.",
          step1: "Log in to the YONO SBI app or SBI online banking",
          step2: "Navigate to 'Loans' or 'Home Loan' section",
          step3: "View real-time status and pending requirements",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: "Customer Care Support",
          para2:
            " Speak directly with SBI customer support representatives about your home loan status.",
          step1: "Call SBI Contact Center at 1800-1234 (toll-free)",
          step2: "Provide your application number and verification details",
          step3: "Request detailed information about your application",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "SBI Home Finance Bank Branch Visit",
          para2:
            "Visit your nearest SBI Home Finance Bank branch where you applied for the home loan to get detailed status updates and personalized assistance.",
          step1: "Carry your application acknowledgment slip and ID proof",
          step2: "Meet with the home loan officer for personalized updates",
          step3: "Get immediate resolution for any pending documentation",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Home Loan Tracker",
          para2:
            " Use our secure online tracker above to instantly check your SBI home loan status.",
          step1: "Enter your application reference number",
          step2: "Provide your mobile number for verification",
          step3: "Get detailed status with next steps",
        },
      ],
    }),
    []
  );
  const adityahomehousingData = useMemo(
    () => ({
      h2: "How to Check Your Aditya Birla Finance Home Loan Status",
      para: "Multiple convenient options to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "ABFL Online Portal & Mobile App",
          para2:
            "Access your loan status directly through the official Aditya Birla Finance portal or mobile app.",
          step1: "Log in to the ABFL customer portal or mobile app",
          step2: "Navigate to 'My Applications' or 'Home Loan' section",
          step3: "View real-time status and pending requirements",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: "Customer Care Support",
          para2:
            " Speak directly with Aditya Birla Finance customer support representatives about your home loan status.",
          step1: "Call ABFL Customer Care at 1800-270-7000 (toll-free)",
          step2: "Provide your application number and verification details",
          step3: "Request detailed information about your application",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "Aditya Birla Finance Bank Branch Visit",
          para2:
            "Visit your nearest Aditya Birla Finance Bank branch where you applied for the home loan to get detailed status updates and personalized assistance.",
          step1: "Carry your application acknowledgment slip and ID proof",
          step2: "Meet with the home loan officer for personalized updates",
          step3: "Get immediate resolution for any pending documentation",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Home Loan Tracker",
          para2:
            " Use our secure online tracker above to instantly check your Aditya Birla Finance home loan status.",
          step1: "Enter your application reference number",
          step2: "Provide your mobile number for verification",
          step3: "Get detailed status with next steps",
        },
      ],
    }),
    []
  );

  useEffect(() => {
    if (name === "checkkotakhomeloanbankstatus") {
      setData(kotakData);
    } else if (name === "checkbobhomeloanbankstatus") {
      setData(bobData);
    } else if (name === "checkidfchomeloanbankstatus") {
      setData(idfcData);
    } else if (name === "checkhdfchomeloanbankstatus") {
      setData(hdfcData);
    } else if (name === "checksbihomeloanbankstatus") {
      setData(sbiData);
    } else if (name === "checkaxishomeloanbankstatus") {
      setData(axisData);
    } else if (name === "checkicicihomeloanbankstatus") {
      setData(iciciData);
    } else if (name === "checkpunjabhomeloanbankstatus") {
      setData(punjabData);
    } else if (name === "checkinduslndhomeloanbankstatus") {
      setData(induslndData);
    } else if (name === "checktatahomeloanbankstatus") {
      setData(tataData);
    } else if (name === "checkyeshomeloanbankstatus") {
      setData(yesData);
    } else if (name === "checkunionhomeloanbankstatus") {
      setData(unionData);
    } else if (name === "checkbajajhomeloanbankstatus") {
      setData(bajajData);
    } else if (name === "checkfederalhomeloanbankstatus") {
      setData(federalData);
    } else if (name === "checkcanarahomeloanbankstatus") {
      setData(canaraData);
    } else if (name === "checkboihomeloanbankstatus") {
      setData(boiData);
    } else if (name === "checkrblhomeloanbankstatus") {
      setData(rblData);
    } else if (name === "checklthomeloanbankstatus") {
      setData(ltData);
    } else if (name === "checkidbihomeloanbankstatus") {
      setData(idbiData);
    } else if (name === "checklichousinghomeloanbankstatus") {
      setData(lichousingData);
    } else if (name === "checkpnbhousinghomeloanbankstatus") {
      setData(pnbhousingData);
    } else if (name === "checkhdfcltdhomeloanbankstatus") {
      setData(hdfclnthousingData);
    } else if (name === "checksbihousinghomeloanbankstatus") {
      setData(sbihomehousingData);
    } else if (name === "checkadityahousinghomeloanbankstatus") {
      setData(adityahomehousingData);
    } else {
      setData([]);
    }
  }, [
    name,
    axisData,
    bobData,
    hdfcData,
    iciciData,
    idfcData,
    kotakData,
    sbiData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
    ltData,
    idbiData,
    lichousingData,
    pnbhousingData,
    hdfclnthousingData,
    sbihomehousingData,
    adityahomehousingData,
  ]);
  return (
    <section className="py-6  border-b border-gray-200 bg-[#ebe8fb]">
      <div className="container sd:px-2  md:px-6 bg-transparent ">
        <div className="sd:w-full md:w-4/5 mx-auto bg-transparent">
          <div className="text-center mb-6 bg-transparent">
            <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple bg-transparent">
              {data.h2}
            </h2>
            <p className="text-dark-grey mt-2 text-sm bg-transparent text-center">
              {data.para}
            </p>
          </div>

          <div className="grid gap-3 bg-transparent">
            {data?.step1?.map((d, index) => (
              <div
                key={index}
                className="bg-white p-3 rounded-lg shadow-sm border border-gray-200"
              >
                <div className="flex items-start gap-3 bg-transparent">
                  <div className="h-10 w-10 rounded-full  flex items-center justify-center flex-shrink-0 bg-[#ebe8fb]">
                    {d.icon}
                  </div>
                  <div className="bg-transparent">
                    <h3 className="text-base font-bold text-primary-purple mb-2">
                      {d.h3}
                    </h3>
                    <p className="text-dark-grey mb-2 text-sm bg-transparent">
                      {d.para2}
                    </p>
                    <div className="space-y-2 bg-transparent p-0">
                      <div className="flex items-start  bg-transparent">
                        <CheckCircle
                          className="h-5 w-5 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        />

                        <p className="text-sm text-dark-grey bg-transparent p-0 m-0">
                          {d.step1}
                        </p>
                      </div>
                      <div className="flex items-start gap-0.5 bg-transparent">
                        <CheckCircle
                          className="h-5 w-5 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        />

                        <p className="text-sm text-dark-grey bg-transparent p-0 m-0">
                          {d.step2}
                        </p>
                      </div>
                      <div className="flex items-start gap-0.5 bg-transparent">
                        <CheckCircle
                          className="h-5 w-5 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        />
                        <p className="text-sm text-dark-grey bg-transparent p-0 m-0">
                          {d.step3}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import Eligibility from "../CommonComponents/Eligibility/Index";
import CreditScore from "../CommonComponents/CreditScore/Index";
import ApprovalChance from "../CommonComponents/ApprovalChance";
import Approval from "../CommonComponents/Approval";

const Index = () => {
  const location = useLocation();
  const [data, setData] = useState(null);
  const [loanEstimator, setLoanEstimator] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const {
    cibil,
    name,
    amount,
    runningLoan,
    loanamount,
    employmenttype,
    loantype,
    monthlyincome,
    monthlyemi,
    secured,
    unsecured,
    eligibleLoan
  } = location.state || {};

  const DataHandler = async () => {
    try {
      setLoading(true);

      // const token = localStorage.getItem("token");
      // if (!token) throw new Error("No token found!");

      const response = await axios.get(
        `${process.env.REACT_APP_SECRET_KEY}/loanConfirmationProb`,
        {
          params: {
            loanAmount: loanamount,
            loanType: loantype,
            monthlyIncome: monthlyincome,
            monthlyEmi: monthlyemi,
            employmentType: employmenttype,
            cibil,
          },
          // headers: { Authorization: `Bearer ${token}` },
        }
      );

      await  setData(response.data);

      await LoanEstimatorHandler(response.data);
      setLoading(false);
    } catch (error) {
      console.error(
        "DataHandler error:",
        error.response?.data || error.message
      );
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const LoanEstimatorHandler = async () => {
    setLoading2(true);
    try {
      const formData = new FormData();
      formData.append("loanAmount", eligibleLoan);
      formData.append("tenure", loantype === "hl" ? "360" : "84");
      formData.append("loanType", loantype);
      formData.append("cibilScore", cibil);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/loanEstimator`,
        formData,
        {
          // headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" },
        }
      );

      setLoanEstimator(response.data);
      setLoading2(false);
    } catch (error) {
      console.error(
        "Failed to fetch loan estimate:",
        error.response?.data || error.message
      );
      setLoading2(false);
    } finally {
      setLoading2(false);
    }
  };

  useEffect(() => {
    DataHandler();
  }, []);

  const secondFoldRef = useRef(null);
  const handleScrollToSecondFold = () => {
    secondFoldRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      {loading ? (
        <Skeleton count={8} style={{ height: 40, marginTop: 20 }} />
      ) : (
        <>
          <Eligibility
            data={{ cibil, name, amount, runningLoan, secured, unsecured }}
          />

          {/* Render ApprovalChance only when data is successfully fetched */}
          {data ? (
            <ApprovalChance data={{ data, handleScrollToSecondFold }} />
          ) : (
            <Skeleton height={100} />
          )}

          {loading2 ? (
            "Loading..."
          ) : (
            <CreditScore
              data={{
                loanEstimator,
                monthlyincome,
                monthlyemi,
                cibil,
                secondFoldRef,
                loanamount
              }}
            />
          )}
          <Approval />
        </>
      )}
    </>
  );
};

export default Index;

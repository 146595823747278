import React, { useState, useEffect, useCallback } from "react";
import logo from "../../Assets/Images/Logo.webp";
import mlogo from "../../Assets/Images/mLogo.webp";
import rightArrow from "../../Assets/Images/rightArrow.webp";
import leftArrow from "../../Assets/Images/leftArrow.webp";
import { BsList } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import "./NavBar.css";
import axios from "axios";
import LoadingBar from "react-top-loading-bar";
import { HashLink as RouterHashLink } from "react-router-hash-link";
import Credits from "../../Assets/Images/creditScore.webp";
import { FaAngleUp } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import {
  ArrowLeftRight,
  BookOpen,
  CheckCircle2,
  ClipboardList,
  CreditCard,
  Home,
  LogOut,
  PiggyBank,
  SquareMousePointer,
  TrendingDown,
  UserX,
  Wallet,
} from "lucide-react";
const NavBar = () => {
  const [progress, setprogress] = useState(0);
  const [status, setStatus] = useState(false);
  const [home, setHome] = useState(true);
  const [creditCard, setCreditCard] = useState(false);
  const [loan, setLoan] = useState(false);
  const [statusD, setStatusD] = useState(false);
  const [blog, setBlog] = useState(false);
  const [homeD, setHomeD] = useState(true);
  const [loanD, setLoanD] = useState(false);
  const [Lower, setLower] = useState(false);
  const [Consolidates, setConsolidate] = useState(false);
  const [topUp, setTopup] = useState(false);
  const [credit, setCredit] = useState(false);
  const token = localStorage.getItem("token");
  const [LoanDetail, setLoanDetail] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [FirstName, setFirstName] = useState("");
  const navigate = useNavigate();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isDropdownCredit, setDropdownCredit] = useState(false);
  const [item, setItem] = useState("");
  const [Eligiblity, setEligiblity] = useState(false);
  const viewProfileHandler = () => {
    setprogress(30);
    const token = localStorage.getItem("token");
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/user/detail`,
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        navigate("/updateprofile", {
          state: {
            name1: response.data.data.user.name,
            pan1: response.data.data.user.pan,
            dob1: response.data.data.user.dob,
            gender1: response.data.data.user.gender,
            avatar1: response.data.data.user.avatar,
          },
        });
        localStorage.setItem("name", response.data.data.user.name);
        localStorage.setItem("profile", response.data.data.user.avatar);
        setprogress(100);
      })
      .catch(function (error) {
        console.error(error);
        setprogress(100);
      });
  };
  const CreditSummary = () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/credit_summary/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        setLoanDetail(response.data.data.loanDetail);
        navigate("/confirmloan", {
          state: { LoanDetail: response.data.data.loanDetail },
        });
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  const LogoutHandler = () => {
    const token = localStorage.getItem("token");
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/logout`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        localStorage.clear();
        localStorage.removeItem("token");
        localStorage.removeItem("FirstName");
        setIsOpen(false);
        navigate("/login");
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    const name = async () => {
      const name = await localStorage.getItem("name");
      setFirstName(name);
    };
    name();
  }, []);

  return (
    <div>
      <LoadingBar color=" #59266d" progress={progress} />
      <nav className="navBar">
        <div className="navLeft">
          <div className="logoContainer">
            <Link to="/" style={{ backgroundColor: "transparent" }}>
              <img src={logo} alt="" />
            </Link>
          </div>
        </div>
        <div className="navRight">
          <div className="navLinks" style={{ backgroundColor: "white" }}>
            <ul style={{ border: "1px soild red", marginBottom: 0 }}>
              {token == null ? (
                <>
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <li
                      style={{
                        fontWeight: homeD ? "600" : "300",
                        color: homeD ? "#59266D" : "black",
                        fontSize: 16,
                      }}
                      onMouseEnter={() => {
                        
                        setDropdownOpen(false);
                        setDropdownCredit(false);
                      }}
                      onClick={() => {
                        setHomeD(true);
                        setLoanD(false);
                        setStatusD(false);
                        setBlog(false);
                        setCreditCard(false);
                        setDropdownOpen(false);
                        
                        setDropdownCredit(false);
                        setCredit(false);
                      }}
                    >
                      Home
                    </li>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/dashboard" style={{ textDecoration: "none" }}>
                    <li
                      style={{
                        fontWeight: homeD ? "600" : "300",
                        color: homeD ? "#59266D" : "black",
                        fontSize: 16,
                      }}
                      onClick={() => {
                        localStorage.setItem("id", 1);
                        setHomeD(true);
                        setLoanD(false);
                        setStatusD(false);
                        setBlog(false);
                        setCreditCard(false);
                        setCredit(false);
                      }}
                      onMouseEnter={() => {
                        
                        setDropdownOpen(false);
                        setDropdownCredit(false);
                      }}
                    >
                      Home
                    </li>
                  </Link>
                </>
              )}

            
{token==null &&  <div style={{ position: "relative" }}>
                <li
                  style={{
                    fontWeight: credit ? "600" : "300",
                    fontSize: 16,
                    color: credit ? "#59266D" : "#1F1F1F",
                   cursor:'pointer'
                  }}
                  onMouseEnter={() => {
                    setDropdownCredit(false);
                    
                    setDropdownOpen(false);
                  }}
                  onClick={() => {
                    setHomeD(false);
                    setLoanD(false);
                    setBlog(false);
                    setCreditCard(false);
                    setCredit(true);
                    setStatusD(false);
                    navigate("/credit-score");
                    setDropdownCredit(false);
                    
                    setDropdownOpen(false);
                  }}
                >
                  Credit Score
                </li>
              </div>}
   

              {/* Credit Card */}

              {/* <RouterHashLink to="/creditcard"> */}

{token==null &&
  <div style={{ position: "relative" }}>
  <li
    style={{
      fontWeight: creditCard ? "600" : "300",
      fontSize: 16,
      color: creditCard ? "#59266D" : "#1F1F1F",
      cursor:'pointer'
    }}
    onMouseEnter={() => {
      setDropdownCredit(true);
      
      setDropdownOpen(false);
    }}
    onClick={() => {
      navigate("/creditcard");
      setHomeD(false);
      setLoanD(false);
      setBlog(false);
      setCreditCard(true);
      setCredit(false);
      setStatusD(false);
      setDropdownCredit(false);
      
      setDropdownOpen(false);
    }}
  >
    Credit Card
  </li>

  {isDropdownCredit && (
    <ul
      style={{
        width: 289,
        position: "absolute",
        top: 50,
        left: 0,
        backgroundColor: "#F6F6F6",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        padding: 16,
        paddingLeft: 0,
        paddingLeft: 9,
        textAlign: "left",
        listStyle: "none",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 2,
      }}
      onMouseEnter={() => setDropdownCredit(true)}
      onMouseLeave={() => setDropdownCredit(false)}
    >
      <Link
        className="drop-link"
        to="/creditcard/creditcard-recommendation"
        style={{ color: "black" }}
      >
        <li
          style={{
            cursor: "pointer",
            padding: "8px 0",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#F6F6F6",
            gap: 4,
            alignContent: "center",
          }}
        >
  <div style={{ alignSelf: "center",fontSize:14,fontWeight:500 }}>          Credit Card Recommendations
          </div>
          <IoIosArrowForward
            style={{ alignSelf: "center", fontSize: 16 }}
          />
        </li>
      </Link>
      <li
        style={{
          cursor: "pointer",
          padding: "8px 0",
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#F6F6F6",
          gap: 4,
          alignContent: "center",
        }}
        onClick={() => {
          navigate("/creditcard/credit-card-eligibility");
          setDropdownOpen(false);
        }}
      >
          <div style={{ alignSelf: "center",fontSize:14,fontWeight:500 }}>          Credit Card Check Eligibility
        </div>
        <IoIosArrowForward
          style={{ alignSelf: "center", fontSize: 16 }}
        />{" "}
      </li>
      <li
        style={{
          cursor: "pointer",
          padding: "8px 0",
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#F6F6F6",
          gap: 4,
          alignContent: "center",
        }}
        onClick={() => {
          navigate(
            "/creditcard/credit-card-rewards-and-cashback"
          );
          setDropdownOpen(false);
        }}
      >
          <div style={{ alignSelf: "center",fontSize:14,fontWeight:500 }}>          Credit Card Rewards and Cashback
        </div>
        <IoIosArrowForward
          style={{ alignSelf: "center", fontSize: 16 }}
        />{" "}
      </li>
      <li
        style={{
          cursor: "pointer",
          padding: "8px 0",
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#F6F6F6",
          gap: 4,
          alignContent: "center",
        }}
        onClick={() => {
          navigate("/creditcard/travel-and-airline-credit-cards");
          setDropdownOpen(false);
        }}
      >
          <div style={{ alignSelf: "center",fontSize:14,fontWeight:500 }}>          Travel and Airline Credit Cards
        </div>
        <IoIosArrowForward
          style={{ alignSelf: "center", fontSize: 16 }}
        />{" "}
      </li>
      <li
        style={{
          cursor: "pointer",
          padding: "8px 0",
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#F6F6F6",
          gap: 4,
          alignContent: "center",
        }}
        onClick={() => {
          navigate("/creditcard/credit-card-status-tracking");
          setDropdownOpen(false);
        }}
      >
          <div style={{ alignSelf: "center",fontSize:14,fontWeight:500 }}>          Credit Card Status Tracking
        </div>
        <IoIosArrowForward
          style={{ alignSelf: "center", fontSize: 16 }}
        />{" "}
      </li>

      <li
        style={{
          cursor: "pointer",
          padding: "8px 0",
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#F6F6F6",
          gap: 4,
          alignContent: "center",
        }}
        onClick={() => {
          navigate("/creditcard/balance-transfer-credit-cards");
          setDropdownOpen(false);
        }}
      >
          <div style={{ alignSelf: "center",fontSize:14,fontWeight:500 }}>          Balance Transfer Credit Cards
        </div>
        <IoIosArrowForward
          style={{ alignSelf: "center", fontSize: 16 }}
        />{" "}
      </li>
    </ul>
  )}
</div>}
            

              <div style={{ position: "relative" }}>
                {/* <RouterHashLink to="/#DownloadApp"> */}
                {token==null ?
                <>
                   <li
                  style={{
                    fontWeight: loanD ? "600" : "300",
                    fontSize: 16,
                    color: loanD ? "#59266D" : "#1F1F1F",
                    cursor:'pointer'
                  }}
                  onClick={() => {
                    setHomeD(false);
                    setLoanD(true);
                    setStatusD(false);
                    setBlog(false);
                    setCredit(false);
                    setCreditCard(false);
                    navigate("/loanpage");
                  }}
                  onMouseEnter={() => {
                    setDropdownOpen(true);
                    
                    setDropdownCredit(false);
                  }}
                >
                  Loans
                </li>

                {/* </RouterHashLink> */}
                {isDropdownOpen && (
                  <ul
                    style={{
                      position: "absolute",
                      top: 50,
                      left: 0,
                      right: 0,
                      backgroundColor: "#fff",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      padding: 16,
                      listStyle: "none",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: 2,
                      width: 200,
                      zIndex: 1000,
                      backgroundColor: "#F6F6F6",
                    }}
                    onMouseEnter={() => setDropdownOpen(true)}
                    onMouseLeave={() => setDropdownOpen(false)}
                  >
                    <li
                      style={{
                        cursor: "pointer",
                        padding: "8px 0",
                        display: "flex",
                        backgroundColor: "transparent",
                        flexDirection: "row",
                        gap: 4,
                        alignContent: "center",
                      }}
                      onClick={() => {
                        navigate("/loanpage/personalloan");
                        setDropdownOpen(false);
                      }}
                    >
                       <div style={{ alignSelf: "center",fontSize:14,fontWeight:500 }}>Personal Loan</div>
                      <IoIosArrowForward
                        style={{ alignSelf: "center", fontSize: 16 }}
                      />{" "}
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        padding: "8px 0",
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#F6F6F6",
                        gap: 4,
                        alignContent: "center",
                      }}
                      onClick={() => {
                        navigate("/loanpage/homeloan");
                        setDropdownOpen(false);
                      }}
                    >
                 <div style={{ alignSelf: "center",fontSize:14,fontWeight:500 }}>Home Loan</div>
                      <IoIosArrowForward
                        style={{ alignSelf: "center", fontSize: 16 }}
                      />{" "}
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        padding: "8px 0",
                        display: "flex",
                        backgroundColor: "#F6F6F6",
                        flexDirection: "row",
                        gap: 4,
                        alignContent: "center",
                      }}
                      onClick={() => {
                        navigate("/loanpage/carloan");
                        setDropdownOpen(false);
                      }}
                    >
                       <div style={{ alignSelf: "center",fontSize:14,fontWeight:500 }}>Car Loan</div>
                      <IoIosArrowForward
                        style={{ alignSelf: "center", fontSize: 16 }}
                      />{" "}
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        padding: "8px 0",
                        display: "flex",
                        backgroundColor: "#F6F6F6",
                        flexDirection: "row",
                        gap: 4,
                        alignContent: "center",
                      }}
                      onClick={() => {
                        navigate("/loanpage/digital-loan");
                        setDropdownOpen(false);
                      }}
                    >
                 <div style={{ alignSelf: "center",fontSize:14,fontWeight:500 }}>Digital Loan</div>
                      <IoIosArrowForward
                        style={{ alignSelf: "center", fontSize: 16 }}
                      />{" "}
                    </li>
                    <li
                      style={{
                        cursor: "pointer",
                        padding: "8px 0",
                        display: "flex",
                        backgroundColor: "#F6F6F6",
                        flexDirection: "row",
                        gap: 4,
                        alignContent: "center",
                      }}
                      onClick={() => {
                        navigate("/loanpage/gold-loan");
                        setDropdownOpen(false);
                      }}
                    >
                 <div style={{ alignSelf: "center",fontSize:14,fontWeight:500 }}>Gold Loan</div>
                      <IoIosArrowForward
                        style={{ alignSelf: "center", fontSize: 16 }}
                      />{" "}
                    </li>
                  </ul>
                )}
                </>
                :
                <li
                style={{
                  fontWeight: loanD ? "600" : "300",
                  fontSize: 16,
                  color: loanD ? "#59266D" : "#1F1F1F",
                  cursor:'pointer'
                }}
                onClick={() => {
                  setHomeD(false);
                  setLoanD(true);
                  setStatusD(false);
                  setBlog(false);
                  setCredit(false);
                  setCreditCard(false);
                  navigate("/loanoverview");
                }}
              >
                Loans
              </li>

                }
             
              </div>
              {
                token && 
                <li
                style={{
                  fontWeight: statusD ? "600" : "300",
                  fontSize: 16,
                  color: statusD  ? "#59266D" : "#1F1F1F",
                  cursor:'pointer'
                }}
                onClick={() => {
                  setHomeD(false);
                  setLoanD(false);
                  setStatusD(true);
                  setBlog(false);
                  setCredit(false);
                  setCreditCard(false);
                  navigate("/applicationstatus");
                }}
              >
                Status
              </li>
              }
              {/* <div style={{ position: "relative" }}>
                <RouterHashLink to="/#DownloadApp">
                <li
                  style={{
                    fontWeight: statusD ? "600" : "300",
                    fontSize: 16,
                    color: statusD ? "#59266D" : "#1F1F1F",
                  }}
                  onClick={() => {
                    setHomeD(false);
                    setLoanD(false);
                    setStatusD(true);
                    setBlog(false);
                    setCredit(false)
                    setCreditCard(false);
                    navigate("/loanpage");
                  }}
                  onMouseEnter={() => {
                    setDropdownOpen(true);
                    
                    setDropdownCredit(false);

                  }}
                >
                  Services
                </li>

                </RouterHashLink>
              
              </div> */}
              <Link
                to="/blog"
                style={{ textDecoration: "none" }}
                onClick={() => {
                  setDropdownOpen(false);
                  
                  setDropdownCredit(false);
                }}
                onMouseEnter={() => {
                  
                  setDropdownOpen(false);
                  setDropdownCredit(false);
                }}
              >
                <li
                  style={{
                    fontWeight: blog ? "600" : "300",
                    fontSize: 16,
                    color: blog ? "#59266D" : "#1F1F1F",
                  }}
                  onClick={() => {
                    setHomeD(false);
                    setLoanD(false);
                    setBlog(true);
                    setCreditCard(false);
                    setStatusD(false);
                    setDropdownCredit(false);
                    
                    setDropdownOpen(false);
                    setCredit(false);
                  }}
                >
                  Blogs
                </li>
              </Link>
            </ul>
          </div>
          <div
            className="navStatus"
            onClick={() => {
              setDropdownOpen(false);
              
              setDropdownCredit(false);
            }}
          >
            {token == null ? (
              <RouterHashLink to="/login">
                <button
                  className="loginDesktop"
                  style={{ cursor: "pointer" }}
                  onMouseEnter={() => {
                    setDropdownOpen(false);
                    
                    setDropdownOpen(false);
                    setDropdownCredit(false);
                  }}
                >
                  Log in
                  <img src={rightArrow} alt="" />
                </button>
              </RouterHashLink>
            ) : (
              <button
                className="loginDesktop"
                style={{ cursor: "pointer" }}
                onClick={() => LogoutHandler()}
                onMouseEnter={() => {
                  setDropdownOpen(false);
                  
                  setDropdownOpen(false);
                  setDropdownCredit(false);
                }}
              >
                Log out
                <img src={rightArrow} alt="" />
              </button>
            )}
          </div>
        </div>
      </nav>
      <nav className="mobileNavBar">
        <div className="mLogo">
          <Link to="/dashboard" style={{ backgroundColor: "transparent" }} onClick={()=>localStorage.setItem("id",1)}>
            <img src={mlogo} alt="" />
          </Link>
        </div>
        {/* {["/otp", "/goal"].includes(location.pathname) ? null : ( */}
        <BsList
          onClick={toggleSidebar}
          size={30}
          color="#1F1F1F"
          style={{ backgroundColor: "#ffff", cursor: "pointer" }}
        />
        {/* )} */}
      </nav>
      {isOpen && (
        <div
          className={`sidebar ${isOpen ? "open" : ""}`}
          style={{ overflowY: "scroll" }}
        >
          <div style={{ backgroundColor: "white" }}>
            <div style={{ backgroundColor: "white" }}>
              <div style={{ height: "72px", backgroundColor: "white" }}></div>
              <div className="menu">
                <RxCross2
                  onClick={toggleSidebar}
                  size={16}
                  color="#1F1F1F"
                  style={{ backgroundColor: "#ffff" }}
                />
                <div className="frame">
                  <div className="menuLogin">
                    <div
                      className="menuArrow"
                      style={{ backgroundColor: "transparent" }}
                    >
                      {" "}
                      {token ? (
                        <img src={leftArrow} alt="" />
                      ) : (
                        <img src={leftArrow} alt="" />
                      )}
                    </div>
                    {token != null ? (
                      <Link
                        to="/Dashboard"
                        style={{ backgroundColor: "transparent" }}
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        <div
                          style={{
                            fontWeight: 700,
                            color: "#59266D",
                            fontSize: 21,
                            backgroundColor: "transparent",
                          }}
                        >
                          Dashboard
                        </div>{" "}
                      </Link>
                    ) : (
                      <a
                        href="/login"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <div
                          onClick={() => setIsOpen(!isOpen)}
                          style={{
                            fontWeight: 700,
                            color: "#59266D",
                            fontSize: 21,
                            backgroundColor: "transparent",
                          }}
                        >
                          Log in
                        </div>
                      </a>
                    )}
                  </div>
                </div>
              </div>
              {token && (
                <div
                  onClick={() => {
                    viewProfileHandler();
                    setIsOpen(!isOpen);
                  }}
                  style={{
                    backgroundColor: "transparent",
                    display: "flex",
                    marginTop: "30px",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "20px",
                      backgroundColor: "transparent",
                      // backgroundColor: "#846CA6",
                      width: "70px",
                      height: "70px",
                      borderRadius: "50%",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/profile-1737532702169.webp"
                      alt=""
                      width="64"
                      height="64"
                      style={{ borderRadius: "50%" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto 0",
                      marginLeft: "10px",
                      backgroundColor: "transparent",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "13.06px",
                        textAlign: "left",
                        paddingBottom: "9px",
                        backgroundColor: "transparent",
                      }}
                    >
                      {" "}
                      👋 Hi!
                    </div>

                    {FirstName == "null" ? null : (
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                          lineHeight: "17.41px",
                          textAlign: "left",
                          backgroundColor: "transparent",
                        }}
                      >
                        {FirstName}
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div
                className="menuLinks"
                style={{ backgroundColor: "white", paddingRight: "4%" }}
              >
                <ul style={{ backgroundColor: "white", paddingLeft: "1.5rem" }}>
                  {token === null ? (
                    <Link
                      to="/"
                      style={{
                        backgroundColor: "white",
                        textDecoration: "none",
                      }}
                    >
                      <li
                        style={{
                          fontWeight: 700,
                          fontSize: 22,
                          backgroundColor: "white",
                          cursor: "pointer",
                          color: "#6b6565",
                          padding: 8,
                        }}
                        onClick={() => {
                          setHome(true);
                          setLoan(false);
                          setStatus(false);
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                      >
                        <Home
                          className="h-6 w-6"
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            backgroundColor: "white",
                            color: "#6b6565",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            backgroundColor: "transparent",
                            color: "#6b6565",
                          }}
                        >
                          Home
                        </span>
                      </li>
                    </Link>
                  ) : (
                    <Link to="/dashboard" style={{ backgroundColor: "white" }}>
                      <li
                        style={{
                          fontWeight: 700,
                          fontSize: 22,
                          backgroundColor: "white",
                          cursor: "pointer",
                          color: "#6b6565",
                          backgroundColor: home ? "#F6F6F6" : "white",
                          padding: 8,
                          borderRadius: 4,
                        }}
                        onClick={() => {
                          setHome(true);
                          setLoan(false);
                          setStatus(false);
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                      >
                        <Home
                          className="h-6 w-6"
                          style={{
                            color: "#6b6565",
                            backgroundColor: "transparent",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            backgroundColor: "transparent",
                            color: "#6b6565",
                          }}
                        >
                          Home
                        </span>
                      </li>
                    </Link>
                  )}
                  {token == null ? (
                    <Link
                      className="slider-drop-link"
                      to="/loanpage"
                      style={{
                        backgroundColor: loan ? "#F6F6F6" : "white",
                        color: "#6b6565",
                        padding: 8,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          backgroundColor: "transparent",
                        }}
                      >
                        <li
                          onClick={() => {
                            setLoan(true);
                            setHome(false);
                            setStatus(false);
                            setIsOpen(!isOpen);
                            setLower(false);
                            setConsolidate(false);
                            setTopup(false);
                            setCredit(false);
                            setBlog(false);
                            setCreditCard(false);
                            setEligiblity(false);
                          }}
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            backgroundColor: "transparent",
                          }}
                        >
                          <Wallet
                            className="h-6 w-6"
                            style={{
                              color: "#6b6565",
                              backgroundColor: "transparent",
                            }}
                          />
                          Loans
                        </li>
                        <FaAngleUp
                          className="slider-drop-link"
                          onClick={() => setDropdownOpen(!isDropdownOpen)}
                          style={{
                            backgroundColor: "transparent",
                            color:
                              isDropdownOpen || loan ? "#59266D" : "#A5A5A5",
                            fontSize: 12,
                            transform: isDropdownOpen
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.2s",
                            alignSelf: "center",
                          }}
                        />
                      </div>
                      {isDropdownOpen && (
                        <ul
                          style={{
                            padding: "0",
                            marginTop: "5px",
                            listStyleType: "none",
                            marginLeft: "12%",
                            gap: 2,
                            backgroundColor: "white",
                          }}
                        >
                          <li
                            className="slider-hover"
                            style={{
                              padding: "8px 12px",
                              backgroundColor: "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                              backgroundColor:
                                item === "personal" ? "e5e3f2" : "white",
                            }}
                            onClick={() => {
                              setIsOpen(false);
                              setItem("personal");
                            }}
                          >
                            <Link
                              to="/loanpage/personalloan"
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color:
                                  item === "personal" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: 14,
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              {" "}
                              Personal Loan
                            </Link>
                          </li>
                          <li
                            className="slider-hover"
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "home" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setIsOpen(false);
                              setItem("home");
                            }}
                          >
                            <Link
                              to="/loanpage/homeloan"
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color: item === "home" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: 14,
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              {" "}
                              Home Loan
                            </Link>
                          </li>
                          <li
                            className="slider-hover"
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "car" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setIsOpen(false);
                              setItem("car");
                            }}
                          >
                            <Link
                              to="/loanpage/carloan"
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color: item === "car" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: 14,
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              Car Loan
                            </Link>
                          </li>
                          <li
                            className="slider-hover"
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "digital" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setIsOpen(false);
                              setItem("digital");
                            }}
                          >
                            <Link
                              to="/loanpage/digital-loan"
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color:
                                  item === "digital" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: 14,
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              Digital Loan
                            </Link>
                          </li>
                          <li
                            className="slider-hover"
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "gold" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setIsOpen(false);
                              setItem("gold");
                            }}
                          >
                            <Link
                              to="/loanpage/gold-loan"
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color: item === "gold" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: 14,
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              {" "}
                              Gold Loan
                            </Link>
                          </li>
                        </ul>
                      )}
                    </Link>
                  ) : (
                    <Link className="slider-drop-link" to="/loanoverview">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          backgroundColor: loan ? "#F6F6F6" : "white",
                          padding: 8,
                          borderRadius: 4,
                        }}
                      >
                        <li
                          onClick={() => {
                            setLoan(true);
                            setHome(false);
                            setStatus(false);
                            setIsOpen(!isOpen);
                            setLower(false);
                            setConsolidate(false);
                            setTopup(false);
                            setCredit(false);
                            setBlog(false);
                            setCreditCard(false);
                            setEligiblity(false);
                          }}
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            fontWeight: "500",
                            color: "#6b6565",
                            backgroundColor: "transparent",
                          }}
                        >
                          <Wallet
                            className="h-6 w-6"
                            style={{
                              color: "#6b6565",
                              backgroundColor: "transparent",
                            }}
                          />
                          <span
                            style={{
                              fontWeight: 500,
                              fontSize: 16,
                              backgroundColor: "transparent",
                              color: "#6b6565",
                            }}
                          >
                            Loans
                          </span>
                        </li>
                      </div>
                    </Link>
                  )}
                  {token == null ? (
                    <Link
                      className="slider-drop-link"
                      to="/creditcard"
                      style={{
                        backgroundColor: creditCard ? "#F6F6F6" : "white",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          backgroundColor: "transparent",
                          backgroundColor: creditCard ? "#F6F6F6" : "white",
                          padding: 8,
                          borderRadius: 4,
                        }}
                      >
                        <li
                          onClick={() => {
                            setLoan(false);
                            setHome(false);
                            setStatus(false);
                            setIsOpen(!isOpen);
                            setLower(false);
                            setConsolidate(false);
                            setTopup(false);
                            setCredit(false);
                            setBlog(false);
                            setCreditCard(true);
                            setEligiblity(false);
                          }}
                          style={{
                            backgroundColor: "transparent",
                          }}
                        >
                          <CreditCard
                            variant={"ghost"}
                            className="h-6 w-6"
                            style={{
                              color: "#6b6565",
                              backgroundColor: "transparent",
                            }}
                          />
                          <span
                            style={{
                              fontWeight: 500,
                              fontSize: 16,
                              backgroundColor: "transparent",
                              color: "#6b6565",
                            }}
                          >
                            Credit Card
                          </span>
                        </li>
                        <FaAngleUp
                          className="slider-drop-link"
                          onClick={() => setDropdownCredit(!isDropdownCredit)}
                          style={{
                            backgroundColor: "transparent",
                            color:
                              isDropdownCredit || creditCard
                                ? "#59266D"
                                : "#6b6565",
                            fontSize: 12,
                            transform: isDropdownCredit
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.2s",
                            alignSelf: "center",
                          }}
                        />
                      </div>

                      {isDropdownCredit && (
                        <ul
                          style={{
                            padding: "0",
                            marginTop: "5px",
                            listStyleType: "none",
                            marginLeft: "5%",
                            gap: 2,
                            backgroundColor: "white",
                          }}
                        >
                          <li
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "recommend" ? "#e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setItem("recommend");
                              setIsOpen(false);
                            }}
                          >
                            <Link
                              to="/creditcard/creditcard-recommendation"
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color:
                                  item === "recommend" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: 14,
                                fontWeight: 500,
                                textDecoration: "none",
                              }}
                            >
                               Credit Card Recommendations
                            </Link>
                          </li>
                          <li
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "benefit" ? "#e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setItem("benefit");
                              setIsOpen(false);
                              navigate("/creditcard/credit-card-eligibility");
                            }}
                          >
                            <Link
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color:
                                  item === "benefit" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: 14,
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >

                              Credit Card Check Eligibility

                            </Link>
                          </li>
                          <li
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "cashback" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setItem("cashback");
                              setIsOpen(false);
                              navigate(
                                "/creditcard/credit-card-rewards-and-cashback"
                              );
                            }}
                          >
                            <Link
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color:
                                  item === "cashback" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: 14,
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              Credit Card Rewards and Cashback
                            </Link>
                          </li>
                          <li
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "travel" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setItem("travel");
                              setIsOpen(false);
                              navigate(
                                "/creditcard/travel-and-airline-credit-cards"
                              );
                            }}
                          >
                            <Link
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color:
                                  item === "travel" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: 14,
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              {" "}
                               Travel and Airline Credit Cards
                            </Link>
                          </li>
                          <li
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "creditCard" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setItem("creditCard");
                              setIsOpen(false);
                              navigate(
                                "/creditcard/credit-card-status-tracking"
                              );
                            }}
                          >
                            <Link
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color:
                                  item === "creditCard" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: 14,
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >
                              Credit Cards Status Tracking
                            </Link>
                          </li>
                          <li
                            style={{
                              padding: "8px 12px",
                              backgroundColor:
                                item === "transfer" ? "e5e3f2" : "white",
                              borderBottom: "0.1px solid #d3d3d3",
                              width: "100%",
                            }}
                            onClick={() => {
                              setItem("transfer");
                              setIsOpen(false);

                              navigate(
                                "/creditcard/balance-transfer-credit-cards"
                              );

                            }}
                          >
                            <Link
                              className="slider-drop-link"
                              style={{
                                textDecoration: "none",
                                color:
                                  item === "transfer" ? "#663778" : "#6b6565",
                                display: "block",
                                fontSize: 14,
                                fontWeight: "500",
                                textDecoration: "none",
                              }}
                            >

                              Balance Transfer Credit Cards

                            </Link>
                          </li>
                        </ul>
                      )}
                    </Link>
                  ) : null}

                  {token == null ? (
                    <a
                      href="/login?goal=lower-your-emi"
                      onClick={() => {
                        localStorage.setItem("id", 1);
                        window.scrollTo(0, 0);
                      }}
                      style={{
                        backgroundColor: Lower ? "#F6F6F6" : "white",
                      }}
                    >
                      <li
                        onClick={() => {
                          setIsOpen(!isOpen);
                          setLower(true);
                          setConsolidate(false);
                          setTopup(false);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          backgroundColor: "transparent",
                          padding: 8,
                          borderRadius: 4,
                        }}
                      >
                        <TrendingDown
                          className="h-6 w-6"
                          style={{
                            color: "#6b6565",
                            backgroundColor: "transparent",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            backgroundColor: "transparent",
                            color: "#6b6565",
                          }}
                        >
                          Lower my EMI
                        </span>
                      </li>
                    </a>
                  ) : (
                    <Link
                      to="/dashboard"
                      state={{ LoanDetail: LoanDetail }}
                      style={{
                        backgroundColor: Lower ? "#F6F6F6" : "white",
                        borderRadius: 4,
                      }}
                    >
                      <li
                        onClick={() => {
                          localStorage.setItem("id", 1);
                          CreditSummary();
                          setIsOpen(!isOpen);
                          setLower(true);
                          setConsolidate(false);
                          setTopup(false);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          backgroundColor: Lower ? "#F6F6F6" : "white",
                          padding: 8,
                          borderRadius: 4,
                        }}
                      >
                        <TrendingDown
                          className="h-6 w-6"
                          style={{
                            color: "#6b6565",
                            backgroundColor: "transparent",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            backgroundColor: "transparent",
                            color: "#6b6565",
                          }}
                        >
                          {" "}
                          Lower my EMI
                        </span>
                      </li>
                    </Link>
                  )}

                  {token == null ? (
                    <a
                      href="/login?goal=loan-consolidate"
                      style={{
                        backgroundColor: Consolidates ? "#F6F6F6" : "white",
                      }}
                      onClick={() => {
                        localStorage.setItem("id", 2);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <li
                        onClick={() => {
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(true);
                          setTopup(false);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          backgroundColor: Consolidates ? "#F6F6F6" : "white",
                          padding: 8,
                          borderRadius: 4,
                        }}
                      >
                        <PiggyBank
                          className="h-6 w-6"
                          style={{
                            color: "#6b6565",
                            backgroundColor: "transparent",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            backgroundColor: "transparent",
                            color: "#6b6565",
                          }}
                        >
                          Consolidate High Interest Debt
                        </span>
                      </li>
                    </a>
                  ) : (
                    <Link
                      to="/dashboard"
                      state={{ LoanDetail: LoanDetail }}
                      style={{
                        backgroundColor: Consolidates ? "#F6F6F6" : "white",
                        padding: 8,
                        borderRadius: 4,
                      }}
                    >
                      <li
                        onClick={() => {
                          localStorage.setItem("id", 2);
                          CreditSummary();
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(true);
                          setTopup(false);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: "rgba(31, 31, 31, 1)",
                          backgroundColor: "transparent",
                        }}
                      >
                        <PiggyBank
                          className="h-6 w-6"
                          style={{
                            color: "#6b6565",
                            backgroundColor: "transparent",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            backgroundColor: "transparent",
                            color: "#6b6565",
                          }}
                        >
                          Consolidate High Interest Debt
                        </span>
                      </li>
                    </Link>
                  )}
                  {token == null ? (
                    <a
                      href="/login?goal=loan-top-up"
                      style={{
                        backgroundColor: topUp ? "#F6F6F6" : "white",
                        padding: 8,
                        borderRadius: 4,
                      }}
                      onClick={() => {
                        localStorage.setItem("id", 3);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <li
                        onClick={() => {
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(true);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          backgroundColor: "transparent",
                        }}
                      >
                        <ArrowLeftRight
                          className="h-6 w-6"
                          style={{
                            color: "#6b6565",
                            backgroundColor: "transparent",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            backgroundColor: "transparent",
                            color: "#6b6565",
                          }}
                        >
                          Transfer Loan With a Top-Up
                        </span>
                      </li>
                    </a>
                  ) : (
                    <Link
                      to="/dashboard"
                      state={{ LoanDetail: LoanDetail }}
                      style={{
                        backgroundColor: topUp ? "#F6F6F6" : "white",
                        padding: 8,
                        borderRadius: 4,
                      }}
                    >
                      <li
                        onClick={() => {
                          localStorage.setItem("id", 3);
                          CreditSummary();
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(true);
                          setHome(false);
                          setStatus(false);
                          setLoan(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: "rgba(31, 31, 31, 1)",
                          backgroundColor: "transparent",
                        }}
                      >
                        <ArrowLeftRight
                          className="h-6 w-6"
                          style={{
                            color: "#6b6565",
                            backgroundColor: "transparent",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            backgroundColor: "transparent",
                            color: "#6b6565",
                          }}
                        >
                          Transfer Loan With a Top-Up
                        </span>
                      </li>
                    </Link>
                  )}

                  <a
                    href="https://minemi.ai/personal-loan/check-eligibility"
                    style={{
                      backgroundColor: Eligiblity ? "#F6F6F6" : "white",
                      padding: 8,
                      borderRadius: 4,
                    }}
                  >
                    <li
                      onClick={() => {
                        setStatus(false);
                        setLoan(false);
                        setHome(false);
                        setIsOpen(!isOpen);
                        setLower(false);
                        setConsolidate(false);
                        setTopup(false);
                        setCredit(false);
                        setBlog(false);
                        setCreditCard(false);
                        setEligiblity(true);
                      }}
                      style={{
                        backgroundColor: "transparent",
                        fontSize: 14,
                      }}
                    >
                      <CheckCircle2
                        className="h-6 w-6"
                        style={{
                          color: "#6b6565",
                          backgroundColor: "transparent",
                        }}
                      />
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: 16,
                          backgroundColor: "transparent",
                          color: "#6b6565",
                        }}
                      >
                        Loan Eligibility
                      </span>
                    </li>
                  </a>

                  {token == null ? (
                    <Link
                      to="/login"
                      style={{
                        backgroundColor: status ? "#F6F6F6" : "white",
                        padding: 8,
                        borderRadius: 4,
                      }}
                    >
                      <li
                        onClick={() => {
                          setStatus(true);
                          setLoan(false);
                          setHome(false);
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          backgroundColor: "transparent",
                        }}
                      >
                        <ClipboardList
                          className="h-6 w-6"
                          style={{
                            color: "#6b6565",
                            backgroundColor: "transparent",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            backgroundColor: "transparent",
                            color: "#6b6565",
                          }}
                        >
                          Application Status
                        </span>
                      </li>
                    </Link>
                  ) : (
                    <Link
                      to="/applicationstatus"
                      style={{
                        backgroundColor: status ? "#F6F6F6" : "white",
                        padding: 8,
                        borderRadius: 4,
                      }}
                    >
                      <li
                        onClick={() => {
                          setStatus(true);
                          setLoan(false);
                          setHome(false);
                          setIsOpen(!isOpen);
                          setLower(false);
                          setConsolidate(false);
                          setTopup(false);
                          setCredit(false);
                          setBlog(false);
                          setCreditCard(false);
                          setEligiblity(false);
                        }}
                        style={{
                          backgroundColor: "transparent",
                        }}
                      >
                        <ClipboardList
                          className="h-6 w-6"
                          style={{
                            color: "#6b6565",
                            backgroundColor: "transparent",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 16,
                            backgroundColor: "transparent",
                            color: "#6b6565",
                          }}
                        >
                          Application Status
                        </span>
                      </li>
                    </Link>
                  )}
                  <Link
                    to="/blog"
                    style={{
                      backgroundColor: blog ? "#F6F6F6" : "white",
                      padding: 8,
                      borderRadius: 4,
                    }}
                  >
                    <li
                      onClick={() => {
                        setIsOpen(!isOpen);
                        setLower(false);
                        setConsolidate(false);
                        setTopup(false);
                        setHome(false);
                        setStatus(false);
                        setLoan(false);
                        setCredit(false);
                        setBlog(true);
                        setCreditCard(false);
                        setEligiblity(false);
                      }}
                      style={{
                        backgroundColor: "transparent",
                      }}
                    >
                      <BookOpen
                        className="h-6 w-6"
                        style={{
                          color: "#6b6565",
                          backgroundColor: "transparent",
                        }}
                      />

                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: 16,
                          backgroundColor: "transparent",
                          color: "#6b6565",
                        }}
                      >
                        Blogs
                      </span>
                    </li>
                  </Link>

                  <li
                    onClick={() => {
                      setIsOpen(!isOpen);
                      setLower(false);
                      setConsolidate(false);
                      setTopup(false);
                      setHome(false);
                      setStatus(false);
                      setLoan(false);
                      setCredit(true);
                      setBlog(false);
                      setCreditCard(false);
                      setEligiblity(false);
                      navigate("/credit-score");
                    }}
                    style={{
                      backgroundColor: Credits ? "#F6F6F6" : "white",
                      padding: 8,
                      borderRadius: 4,
                    }}
                  >
                    <SquareMousePointer
                      className="h-6 w-6"
                      color="black"
                      style={{
                        backgroundColor: "#F6F6F6",
                      }}
                    />
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: 16,
                        backgroundColor: "transparent",
                        color: "#6b6565",
                      }}
                    >
                      Check Credit Score
                    </span>
                  </li>

                  {token === null ? null : (
                    <li
                      onClick={() => {
                        // localStorage.removeItem("token");
                        LogoutHandler();
                      }}
                      style={{
                        backgroundColor: "white",
                        padding: 8,
                        borderRadius: 4,
                        padding: 8,
                        borderRadius: 4,
                      }}
                    >
                      <LogOut
                        className="h-6 w-6"
                        style={{
                          color: "#6b6565",
                          backgroundColor: "transparent",
                        }}
                        fontVariant="ghost"
                      />
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: 16,
                          backgroundColor: "transparent",
                          color: "#6b6565",
                        }}
                      >
                        Logout
                      </span>
                    </li>
                  )}

                  {token === null ? null : (
                    <li
                      onClick={() => {
                        toggleSidebar();
                      }}
                      style={{
                        fontSize: 14,
                        fontWeight: "700",
                        color: "white",
                        backgroundColor: "#EF4444",
                        padding: 8,
                        borderRadius: 4,
                      }}
                    >
                      <UserX
                        variant="destructive"
                        className="h-6 w-6"
                        style={{
                          color: "white",
                          backgroundColor: "transparent",
                        }}
                      />
                      <span
                        style={{
                          fontWeight: 500,
                          fontSize: 16,
                          backgroundColor: "transparent",
                          color: "white",
                        }}
                      >
                        Delete Account
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default NavBar;

import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const FinalCta = () => {
  const navigate = useNavigate();
  return (
    <section className="bg-[#311664] text-white py-8 px-4">
      <div
        className="max-w-3xl mx-auto text-center"
        style={{ backgroundColor: "transparent" }}
      >
        <h2
          className="text-2xl font-bold mb-3"
          style={{ backgroundColor: "transparent" }}
        >
          Ready to Find Your Perfect Loan?
        </h2>
        <p
          className="text-base mb-4"
          style={{ backgroundColor: "transparent" }}
        >
          Let Minemi guide you to the best financial solution for your needs.
        </p>
        <div
          className="flex flex-col sm:flex-row justify-center items-center gap-3"
          style={{ backgroundColor: "transparent" }}
        >
          <Link
            to="https://minemi.ai/home-loan/check-eligibility/"
            className="bg-[#3a868b] hover:bg-[#59266d] text-white font-bold py-2 px-4 rounded-full text-sm w-full sm:w-auto transition-colors duration-300"
          >
            <button>Check Eligiblity</button>
          </Link>
          <button
            className="bg-transparent hover:bg-white hover:text-[#311664] text-white font-bold py-2 px-4 rounded-full text-sm border-2 w-full sm:w-auto mt-2 sm:mt-0 transition-colors duration-300"
            onClick={() => navigate("/credit-score")}
          >
            Check Cibil Score
          </button>
        </div>
      </div>
    </section>
  );
};

export default FinalCta;

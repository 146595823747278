import { Button, Typography, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  CircularProgressbar,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./Calculator.css";

const Calculator = ({ onButtonClick }) => {
  // calculator calculation
  const [loanAmount, setLoanAmount] = useState(100000); // Default Loan Amount
  const [rateOfInterest, setRateOfInterest] = useState(8.5); // Default Rate of Interest
  const [loanTenure, setLoanTenure] = useState(3); // Default Loan Tenure (in years)

  const [monthlyEMI, setMonthlyEMI] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  // progress bar
  const [percentage, setPercentage] = useState(0); // To store progress bar percentage

  // Function to calculate EMI and update results
  const calculateEMI = () => {
    const P = loanAmount;
    const R = rateOfInterest / 12 / 100; // Monthly Rate of Interest
    const N = loanTenure * 12; // Loan Tenure in months

    if (P > 0 && R > 0 && N > 0) {
      const emi = (P * R * Math.pow(1 + R, N)) / (Math.pow(1 + R, N) - 1);
      const totalAmt = emi * N;
      const interest = totalAmt - P;

      setMonthlyEMI(emi.toFixed(2));
      setTotalInterest(interest.toFixed(2));
      setTotalAmount(totalAmt.toFixed(2));

      // Calculate percentage progress for the CircularProgressbar
      const progressPercentage = ((P / totalAmt) * 100).toFixed(2);
      setPercentage(progressPercentage);
    } else {
      setMonthlyEMI(0);
      setTotalInterest(0);
      setTotalAmount(0);
      setPercentage(0); // Reset progress if inputs are invalid
    }
  };

  // Recalculate EMI whenever any input changes
  useEffect(() => {
    calculateEMI();
  }, [loanAmount, rateOfInterest, loanTenure]);

  // formatter
  const formatter = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0, // Ensure no decimals
      maximumFractionDigits: 0, // Ensure no decimals
    }).format(value);
  };

  const smallScreen = useMediaQuery("(max-width:576px)");
  const mediumScreen = useMediaQuery("(max-width:768px)");
  const largeScreen = useMediaQuery("(max-width:992px)");

  return (
    <>
      <div id="loan-consolidation-calculator-page">
        <div id="calculator-page-container">
          <div id="calculator-page-content">
            <section id="emi-heading" className="bg-transparent">
              <div className="bt-heading-box bg-transparent">
                <h3>Calculate Your New EMI After Loan Consolidation </h3>
              </div>
            </section>
            <div className="main-box-container w-100 d-flex bg-transparent ">
              {/* calculator result section */}

              <section className="result-box-section bg-transparent">
                <div id="result-box" className="bg-transparent">
                  <div id="result-box-content">
                    <div className="bg-transparent">
                      <h4 className="bg-transparent">Your Monthly EMI is</h4>
                      <h3>{formatter(monthlyEMI)}</h3>
                      <p className="year-para bg-transparent">
                        for {loanTenure} years
                      </p>
                    </div>

                    <div className="border-line"></div>

                    <div
                      id="total-interest"
                      className=" bg-transparent w-100 d-flex flex-row justify-content-center gap-3 gap-md-5 pt-2 pt-md-4 pb-md-2"
                    >
                      <div className=" bg-transparent d-flex flex-column justify-content-md-between ">
                        <p className="total-interest-text bg-transparent">
                          Total Interest
                        </p>
                        <p className="total-interest-number bg-transparent">
                          {formatter(totalInterest)}
                        </p>
                      </div>
                      <div
                        style={{
                          borderRight: " 2px solid #E6E3BE ",
                          textAlign: "center",
                          height: "3rem",
                        }}
                      ></div>

                      <div className=" bg-transparent d-flex flex-column justify-content-md-between">
                        <p className="total-interest-text bg-transparent">
                          Total Amount
                        </p>
                        <p className="total-interest-number bg-transparent">
                          {formatter(totalAmount)}
                        </p>
                      </div>
                    </div>

                    <div
                      className="d-none d-md-block"
                      style={{
                        width: "100%",
                        borderBottom: " 2px solid #E6E3BE ",
                        textAlign: "center",
                      }}
                    ></div>

                    {/* progress bar */}
                    <div className="d-none d-md-block bg-transparent">
                      <div
                        id="progress-bar-container"
                        className="bg-transparent  pt-5 d-flex flex-row justify-content-start align-items-center gap-md-5"
                      >
                        <div className="bg-transparent">
                          <div
                            className="bg-transparent"
                            style={{
                              width: 100,
                              height: 100,
                              // marginLeft: largeScreen ? "none" : "-70px",
                            }}
                          >
                            <CircularProgressbar
                              className="bg-transparent"
                              value={percentage}
                              // text={`${percentage}%`}
                              styles={buildStyles({
                                strokeLinecap: "butt",
                                pathColor: "#80387C", // Filling color
                                trailColor: "#eee", // Background color (optional)
                                strokeWidth: 100, // Adjust width of the progress bar
                                pathTransition: "none", // Smooth transition
                                pathThickness: "90%", // Thickness of the progress bar
                              })}
                              strokeWidth={20} // Makes it thicker
                            />
                          </div>
                        </div>
                        <div className="bg-transparent">
                          <div className=" bg-transparent d-flex flex-row align-items-center gap-md-3 pb-3">
                            <div className="circle-box-one"></div>
                            <p className="m-0 box-one-para bg-transparent">
                              Loan Amount
                            </p>
                          </div>
                          <div className=" bg-transparent d-flex flex-row align-items-center gap-md-3">
                            <div className="circle-box-two"></div>
                            <p className="m-0 box-one-para bg-transparent">
                              Total Interest
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {/* calculator range section */}
              <section className="range-content-section bg-transparent">
                <div id="content-container" className="bg-transparent">
                  <div className="bg-transparent">
                    <div
                      id="range-container-one"
                      className="w-100 bg-transparent"
                    >
                      <div className=" bg-transparent range-section-content w-100 d-flex flex-row  align-items-center justify-content-between ">
                        <div className="range-loan-amount bg-transparent">
                          <p
                            className="m-0 bg-transparent"
                            id="loan-amount-para"
                          >
                            Loan Amount
                          </p>
                        </div>
                        <div id="button-box w-100 " className="bg-transparent ">
                          <button
                            style={{
                              width: "100%",
                              backgroundColor: "#FFFFFF",
                              color: "#432C56",
                              fontSize: smallScreen
                                ? "12px"
                                : mediumScreen
                                ? "16px"
                                : largeScreen
                                ? "18px"
                                : "24px",
                              fontWeight: 500,
                              lineHeight: smallScreen ? "12px" : "20px",
                              borderRadius: smallScreen ? "4px" : "10px",
                              padding: smallScreen
                                ? "0.4rem 1.5rem"
                                : "0.8rem 3rem",
                              fontFamily: "'Archivo',sans-serif",
                              letterSpacing: "-0.17px",
                              textTransform: "none",
                            }}
                            variant="contained"
                          >
                            {formatter(loanAmount)}
                          </button>
                        </div>
                      </div>

                      {/* range slider */}
                      <div id="range" className="w-100 mt-md-4">
                        <div className="range-slider-container">
                          <input
                            type="range"
                            min="100000"
                            max="50000000"
                            className="home-loan-range"
                            value={loanAmount}
                            onChange={(e) =>
                              setLoanAmount(Number(e.target.value))
                            }
                          />
                        </div>
                      </div>

                      <div className="w-100 d-flex flex-row justify-content-between align-items-center pt-3 pt-md-4 bg-transparent">
                        <p className="min-text bg-transparent">Min ₹1,00,000</p>
                        <p className="min-text bg-transparent">
                          Max ₹5,00,00,000
                        </p>
                      </div>
                    </div>

                    <div
                      id="range-container-two"
                      className="w-100 bg-transparent"
                    >
                      <div className=" range-section-content w-100 d-flex flex-row  align-items-center justify-content-between bg-transparent ">
                        <div className="bg-transparent">
                          <p
                            className="m-0 bg-transparent"
                            id="loan-amount-para"
                          >
                            Rate of Interest
                          </p>
                        </div>
                        <div id="button-box w-100 " className="bg-transparent">
                          <button
                            style={{
                              width: "100%",
                              backgroundColor: "#FFFFFF",
                              color: "#432C56",
                              fontSize: smallScreen
                                ? "12px"
                                : largeScreen
                                ? "18px"
                                : "24px",
                              fontWeight: 500,
                              lineHeight: smallScreen ? "12px" : "20px",
                              borderRadius: smallScreen ? "4px" : "10px",
                              padding: smallScreen
                                ? "0.4rem 2.2rem"
                                : largeScreen
                                ? " 0.8rem 4.1rem"
                                : "0.8rem 4.6rem",
                              fontFamily: "'Archivo',sans-serif",
                              letterSpacing: "-0.17px",
                              textTransform: "none",
                            }}
                            variant="contained"
                          >
                            {rateOfInterest}%
                          </button>
                        </div>
                      </div>

                      {/* range slider */}
                      <div id="range" className="w-100 mt-md-4">
                        <div className="range-slider-container">
                          <input
                            type="range"
                            min="8.5"
                            max="20"
                            step="0.1" // Ensures increments of 0.1
                            className="home-loan-range"
                            value={rateOfInterest}
                            onChange={(e) =>
                              setRateOfInterest(Number(e.target.value))
                            }
                          />
                        </div>
                      </div>

                      <div className="w-100 d-flex flex-row justify-content-between align-items-center pt-3 pt-md-4 bg-transparent">
                        <p className="min-text bg-transparent">Min 8.5%</p>
                        <p className="min-text bg-transparent">Max 20%</p>
                      </div>
                    </div>
                    <div
                      id="range-container-three"
                      className="w-100 bg-transparent"
                    >
                      <div className="bg-transparent range-section-content w-100 d-flex flex-row justify-content-between align-items-center ">
                        <div className="bg-transparent">
                          <p
                            className="m-0 bg-transparent"
                            id="loan-amount-para"
                          >
                            Loan Tenure
                          </p>
                        </div>
                        <div id="button-box w-100 " className="bg-transparent">
                          <button
                            style={{
                              width: "100%",
                              backgroundColor: "#FFFFFF",
                              color: "#432C56",
                              fontSize: smallScreen
                                ? "12px"
                                : largeScreen
                                ? "18px"
                                : "24px",
                              fontWeight: 500,
                              lineHeight: smallScreen ? "12px" : "20px",
                              borderRadius: smallScreen ? "4px" : "10px",
                              padding: smallScreen
                                ? "0.4rem 2.9rem"
                                : largeScreen
                                ? "0.8rem 5rem"
                                : "0.8rem 5.9rem",
                              fontFamily: "'Archivo',sans-serif",
                              letterSpacing: "-0.17px",
                              textTransform: "none",
                            }}
                            variant="contained"
                          >
                            {loanTenure}
                          </button>
                        </div>
                      </div>

                      {/* range slider */}
                      <div id="range" className="w-100 mt-md-4">
                        <div className="range-slider-container">
                          <input
                            type="range"
                            min="1"
                            max="30"
                            className="home-loan-range"
                            value={loanTenure}
                            onChange={(e) =>
                              setLoanTenure(Number(e.target.value))
                            }
                          />
                        </div>
                      </div>

                      <div className="w-100 d-flex flex-row justify-content-between align-items-center pt-3 pt-md-4 bg-transparent">
                        <p className="min-text bg-transparent">Min 1 year</p>
                        <p className="min-text bg-inherit">Max 30 years</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div id="apply-now-btn" className="mt-lg-4 bg-inherit">
              <div id="button-box  " className="pt-2 bg-inherit">
                <button
                  onClick={onButtonClick}
                  style={{
                    width: "100%",
                    backgroundColor: "#59266D",
                    color: "#ffff",
                    fontSize: smallScreen
                      ? "15px"
                      : mediumScreen
                      ? "20px"
                      : largeScreen
                      ? "20px"
                      : "24px",
                    fontWeight: 500,
                    lineHeight: smallScreen ? "15px" : "32px",
                    borderRadius: smallScreen ? "4px" : "10px",
                    padding: smallScreen
                      ? "0.6rem 1.8rem"
                      : mediumScreen
                      ? "0.6rem 5rem"
                      : largeScreen
                      ? "1rem 5rem"
                      : "1rem 7rem",
                    fontFamily: "'Archivo',sans-serif",
                    letterSpacing: "-0.17px",
                    textTransform: "none",
                    textAlign: "center",
                  }}
                  variant="contained"
                >
                  Apply Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calculator;

import React from "react";
import "./Serve.css";
import Accordion from "react-bootstrap/Accordion";
import { FaPlus } from "react-icons/fa";

const Serve = () => {
  const accordionData = [
    {
      title: "Home Loan Eligibility",
      content: (
        <p className="m-0 bg-transparent">
          A Quick Guide Your home loan eligibility depends on various factors
          such as your income, credit score, the value of the property, and your
          current financial obligations. Typically, a credit score of 700+ is
          preferred by most banks, along with a stable income history. At
          Minemi, we provide a quick and easy way to check your home loan
          eligibility instantly, without affecting your credit score. Know your
          eligibility upfront and get personalized loan options that fit your
          needs, avoiding unnecessary rejections!
        </p>
      ),
    },
    {
      title: "Features and Benefits of Home Loan",
      content: (
        <div className="bg-transparent">
          Home loans are essential when purchasing your dream home, and they
          offer several.
          <p className="my-2 bg-transparent">Key features include:</p>
          <p className="my-1 bg-transparent">
            • Low interest rates compared to other loans
          </p>
          <p className="m-0 bg-transparent">
            • Longer repayment tenures (up to 30 years)
          </p>
          <p className="my-1 bg-transparent">
            • Tax benefits on both principal and interest repayment
          </p>
          <p className="m-0 bg-transparent">
            • Flexibility to choose fixed or floating interest rates
          </p>
        </div>
      ),
    },
    {
      title: "Why Check Home Loan Eligibility? ",
      content: (
        <p className="m-0 bg-transparent">
          Checking your home loan eligibility before applying helps you assess
          your chances of approval and avoids multiple hard credit inquiries
          that could lower your credit score. At Minemi, you can check your
          eligibility instantly—without impacting your credit score.
        </p>
      ),
    },
    {
      title: "When to Home Loan Consolidation?",
      content: (
        <p className="m-0 bg-transparent">
          A home loan is ideal when you're ready to purchase or construct your
          dream home, or even refinance an existing property. You can also use a
          home loan for buying a plot of land or for home improvements. With
          longer tenures and lower interest rates, home loans are structured to
          offer affordability over time. However, it's essential to evaluate
          your repayment capacity before taking a loan. Use Minemi's home loan
          eligibility checker to determine the best options available to you.
        </p>
      ),
    },
    {
      title: "Documents Required for Home Loan",
      content: (
        <div className="bg-transparent">
          you will need to submit the following documents:
          <p className="my-1 bg-transparent">
            • Identity Proof: PAN Card, Aadhaar Card, Passport
          </p>
          <p className="m-0 bg-transparent">
            • Address Proof: Utility bills, Aadhaar, or rental agreements
          </p>
          <p className="my-1 bg-transparent">
            • Income Proof: Latest salary slips or bank statements (for
            salaried), IT returns or audited financials (for self-employed)
          </p>
          <p className="my-1 bg-transparent">
            • Property Documents: Agreement to Sale, Title Deed, Approved
            Building Plan
          </p>
          <p className="m-0 bg-transparent">
            •Employment Proof: Offer letter, Form 16, or employment certificate
            Having these documents ready will speed up the home loan approval
            process.
          </p>
        </div>
      ),
    },
    {
      title: " Frequently Asked Questions (FAQs) ",
      content: (
        <div className="m-0 bg-transparent">
          <p className="m-0 bg-transparent">
            1. What is the minimum credit score required for a home loan? Most
            lenders prefer a credit score of 700+ for home loan approvals, but
            the actual requirement may vary. Use Minemi's eligibility checker to
            know where you stand.
          </p>
          <p className="my-2 bg-transparent">
            2. How much home loan can I get? The loan amount depends on your
            income, credit score, and the value of the property. Use Minem's
            eligibility checker to find out how much you can borrow without
            affecting your credit score.
          </p>
          <p className="m-0 bg-transparent">
            3. Will checking home loan eligibility impact my credit score? No,
            Minemi uses a soft inquiry method to check your eligibility, so it
            doesn't impact your credit score.
          </p>
          <p className="my-2 bg-transparent">
            4. Can I get a home loan for property under construction? Yes, many
            lenders offer home loans for properties under construction. Minemi
            can help you find lenders that suit your needs.
          </p>
          <p className="m-0 bg-transparent">
            5. How long does it take to get a home loan? Home loans typically
            take a few weeks to process, depending on property verification and
            documentation. Minemi streamlines the process and provides faster
            access to offers.
          </p>
        </div>
      ),
    },
  ];
  return (
    <>
      <div id="serve">
        <div className="contentCon">
          <div id="header-con">
            <p className="bg-transparent">We swear to serve you with ...</p>
          </div>
          <div id="content" className="bg-transparent">
            <div className="d-flex flex-wrap w-100 bg-transparent">
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/loan_eligibility.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/no_empact.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Impact on credit Score</p>
              </div>

              <div className="plus-icon-con second-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/interest_rate.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Best Interest Rate Offer</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/no_calls.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Calls From Us</p>
              </div>

              <div className="plus-icon-con fourth-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Data Security Guarantee</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>
            </div>

            <div id="line-breaker"></div>

            <div className="accordion-con bg-transparent">
              <Accordion className="bg-transparent">
                {accordionData.map((item, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body
                      style={{
                        visibility: "visible",
                        backgroundColor: "transparent",
                      }}
                    >
                      {item.content}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Serve;

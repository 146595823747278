import React from "react";
import {useEffect } from "react";
import Eligibility from "../CommonComponents/Eligibility/Index";
import ApprovalChance from "./ApprovalChance/Index";
import CheckCardStatus from "./CheckCardStatus";
import Approval from "../CommonComponents/Approval";
import { useLocation } from "react-router-dom";
import axios from "axios";

const Index = () => {
  const location = useLocation();
  const data = location.state;
  const DetailHandler = async () => {
    try {
      const formData = new FormData();
      formData.append("cibil", data.cibil);
      formData.append("specified_card", data.cibil);
      formData.append("monthly_income", data.cibil);
      formData.append("monthly_emi", data.cibil);
      formData.append("profession", data.cibil);
      formData.append("company", data.cibil);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/creditCardAApproval`,
        formData,
      );

     
    } catch (error) {
      console.error(
        "Failed to fetch loan estimate:",
        error.response?.data || error.message
      );
    } finally {
    }
  };

useEffect(() => {
  DetailHandler()
}, [])


  return (
    <>
      <Eligibility data={data} />
      <ApprovalChance data={{ data }} />
      <CheckCardStatus />
      <Approval />
    </>
  );
};

export default Index;

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import axios from "axios";

function RelatedLinks({ title, description, params }) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [data, setData] = useState([]);
  const FetchLinks = async () => {
    try {
      const { REACT_APP_SECRET_KEY } = process.env;

      if (!REACT_APP_SECRET_KEY) {
        console.error("API Key is missing!");
        return;
      }

      const options = {
        method: "GET",
        url: `${REACT_APP_SECRET_KEY}/quickLinks`,
        params: { category: params }, // Ensure params is an object
      };

      const response = await axios.request(options);
      setData(response.data?.data?.links || []);
    } catch (error) {
      console.error("Failed to fetch links:", error);
      setData([]); // Optional: Reset data to empty if error occurs
    }
  };

  useEffect(() => {
    FetchLinks();
  }, []);

  return (
    <div className="py-8 " style={{ marginLeft: "4%", marginRight: "4%" }}>
      <div
        className={`mx-auto   border border-gray-200 rounded-lg shadow-sm `}
        style={{ backgroundColor: "transparent" }}
      >
        <div className="p-6" style={{ backgroundColor: "transparent" }}>
          <h2
            className="text-3xl font-bold mb-2"
            style={{ backgroundColor: "transparent", color: "#59266D" }}
          >
            {title}
          </h2>
          <p
            className="text-gray-600 mb-6"
            style={{ backgroundColor: "transparent" }}
          >
            {description}
          </p>
          <div
            className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3"
            style={{ backgroundColor: "transparent" }}
          >
            {data.map((link, index) => (
              <a
                href={link.link}
                key={index}
                target={link.isExternal ? "_blank" : undefined}
                rel={link.isExternal ? "noopener noreferrer" : undefined}
                className="block"
                style={{ backgroundColor: "white", borderRadius: 8 }}
              >
                <div
                  className="h-full border border-gray-200 rounded-lg p-4 transition-all duration-200 hover:shadow-md hover:border-purple-300"
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  style={{ backgroundColor: "transparent" }}
                >
                  <h3
                    className="text-lg font-semibold mb-2 flex  gap-2"
                    style={{ backgroundColor: "transparent", color: "black" }}
                  >
                    {link.title}
                    {/* {link.isExternal && (
                    <svg
                    style={{backgroundColor:'transparent'}}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                      />
                    </svg>
                  )} */}
                  </h3>
                  <p
                    className="text-sm text-gray-600 mb-4"
                    style={{ backgroundColor: "transparent" }}
                  >
                    {link.description}
                  </p>
                  <div
                    className="flex items-center text-sm text-purple-600 font-medium"
                    style={{ backgroundColor: "transparent", color: "#59266D" }}
                  >
                    Read more
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className={`ml-1 h-4 w-4 transition-transform duration-300 ${
                        hoveredIndex === index ? "translate-x-1" : ""
                      }`}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                        style={{ backgroundColor: "transparent" }}
                      />
                    </svg>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
        <div
          className="border-t border-gray-200 p-4 text-center"
          style={{
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="text-sm text-purple-600 font-medium inline-flex items-center gap-2 hover:underline"
            style={{
              backgroundColor: "transparent",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4"
              style={{ backgroundColor: "transparent" }}
            >
              <path
                style={{ backgroundColor: "transparent" }}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
              />
            </svg>
            View all related content
          </button>
        </div>
      </div>
    </div>
  );
}

RelatedLinks.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      isExternal: PropTypes.bool,
    })
  ).isRequired,
  className: PropTypes.string,
};

export default RelatedLinks;

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function Typesofcarloan() {
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        {/* Header Section with Background */}
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* Breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links">
              <Link
                to="/"
                className="custom-link bg-transparent archivo"
                style={{
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to="/loanpage"
                className="custom-link d-none d-md-inline bg-transparent archivo"
                style={{
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to="/loanpage/carloan"
                className="custom-link d-none d-md-inline bg-transparent archivo"
                style={{
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Car Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <span
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Types of Car Loans
              </span>
            </p>
          </div>

          {/* Main Heading */}
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "55px",
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Types of Car Loans: Comprehensive Guide for Better Financing
            Decisions
          </h1>

          {/* Subheading */}
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Car loans are versatile financial tools that cater to diverse
            vehicle financing needs. We focus on empowering users with
            comprehensive information to help them make informed and responsible
            choices. Explore the different types of car loans, understand when
            to consider them, and discover the right option for your specific
            requirements.
          </p>
        </div>

        {/* Main Content */}
        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
          {/* New Car Loan */}
          <div>
            <div
              style={{
                marginTop: 35,
                color: "#59266D",
                fontWeight: 600,
                fontSize: 24,
                lineHeight: 1.2,
                fontFamily: "Archivo",
              }}
            >
              1) New Car Loans:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Finance your brand new vehicle with loans specifically designed
              for new car purchases.
            </div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Archivo",
              }}
            >
              Key Points to Consider:
            </h4>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Generally offers lower interest rates compared to used car loans.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Longer loan terms available, typically up to 7 years.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Consider the total cost including insurance, taxes, and
              maintenance.
            </div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Archivo",
              }}
            >
              When to Take:
            </h4>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              When purchasing a brand-new vehicle from a dealership.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              If you prefer the latest safety features and technology.
            </div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Archivo",
              }}
            >
              How Much to Take:
            </h4>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Consider borrowing 80-90% of the vehicle cost, with a down payment
              of 10-20%.
            </div>
          </div>

          {/* Used Car Loan - with CTA */}
          <div>
            <div
              style={{
                marginTop: 25,
                color: "#59266D",
                fontWeight: 600,
                fontSize: 24,
                lineHeight: 1.2,
                fontFamily: "Archivo",
              }}
            >
              2) Used Car Loans
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Finance your pre-owned vehicle purchase with loans designed
              specifically for used cars.
            </div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Archivo",
              }}
            >
              Key Points to Consider:
            </h4>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Slightly higher interest rates compared to new car loans.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Loan terms typically shorter, up to 5-7 years depending on vehicle
              age.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Verify the vehicle's condition, history, and resale value before
              financing.
            </div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Archivo",
              }}
            >
              When to Take:
            </h4>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              When looking for a more affordable vehicle option.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              If you want to avoid the steep depreciation of a new car.
            </div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Archivo",
              }}
            >
              How Much to Take:
            </h4>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Consider a larger down payment (20-30%) to offset higher interest
              rates.
            </div>

            {/* CTA for Used Car Loan */}
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 25,
                marginBottom: 15,
              }}
            >
              <Link
                to="/loanpage/carloan/used-car-loan"
                style={{
                  display: "inline-block",
                  backgroundColor: "#59266D",
                  color: "white",
                  textDecoration: "none",
                  padding: "12px 24px",
                  borderRadius: "4px",
                  fontWeight: 600,
                  fontSize: 16,
                  fontFamily: "Archivo",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                Apply for Used Car Loan
              </Link>
            </div>
          </div>

          {/* Car Loan Top-up - with CTA */}
          <div>
            <h3
              style={{
                marginTop: 25,
                color: "#59266D",
                fontWeight: 600,
                fontSize: 24,
                lineHeight: 1.2,
                fontFamily: "Archivo",
              }}
            >
              3) Car Loan Top-up
            </h3>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Get additional funds on your existing car loan with minimal
              documentation.
            </div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Archivo",
              }}
            >
              Key Points to Consider:
            </h4>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Uses your existing car as collateral for additional borrowing.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Often has lower interest rates compared to personal loans.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Minimal documentation required since your credit has already been
              established.
            </div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Archivo",
              }}
            >
              When to Take:
            </h4>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              When you need additional funds for home renovations, education, or
              other expenses.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              If you have a good repayment history on your existing car loan.
            </div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Archivo",
              }}
            >
              How Much to Take:
            </h4>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Up to 80% of your car's current value, minus your outstanding loan
              amount.
            </div>

            {/* CTA for Car Loan Top-up */}
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 25,
                marginBottom: 15,
              }}
            >
              <Link
                to="/loanpage/carloan/car-loan-top-up"
                style={{
                  display: "inline-block",
                  backgroundColor: "#59266D",
                  color: "white",
                  textDecoration: "none",
                  padding: "12px 24px",
                  borderRadius: "4px",
                  fontWeight: 600,
                  fontSize: 16,
                  fontFamily: "Archivo",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                Apply for Car Loan Top-up
              </Link>
            </div>
          </div>

          {/* Refinance Car Loan */}
          <div>
            <div
              style={{
                marginTop: 25,
                color: "#59266D",
                fontWeight: 600,
                fontSize: 24,
                lineHeight: 1.2,
                fontFamily: "Archivo",
              }}
            >
              4) Refinance Car Loan
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Replace your existing car loan with a new one, often with better
              terms or lower interest rates.
            </div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Archivo",
              }}
            >
              Key Points to Consider:
            </h4>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Ideal when interest rates have dropped significantly since your
              initial loan.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Can help lower monthly payments or reduce the loan term.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Check for prepayment penalties on your existing loan.
            </div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Archivo",
              }}
            >
              When to Take:
            </h4>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              When your credit score has improved significantly.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              If current market interest rates are lower than your existing loan
              rate.
            </div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Archivo",
              }}
            >
              How Much to Take:
            </h4>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              At minimum, the outstanding balance on your current car loan.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            ></div>
          </div>

          {/* Commercial Vehicle Loan */}
          <div>
            <div
              style={{
                marginTop: 25,
                color: "#59266D",
                fontWeight: 600,
                fontSize: 24,
                lineHeight: 1.2,
                fontFamily: "Archivo",
              }}
            >
              5) Commercial Vehicle Loan
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Finance vehicles for business purposes such as delivery vans,
              trucks, or rideshare vehicles.
            </div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Archivo",
              }}
            >
              Key Points to Consider:
            </h4>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              May offer tax benefits as a business expense.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Often requires a detailed business plan or proof of business
              income.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              May have different insurance requirements than personal vehicles.
            </div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Archivo",
              }}
            >
              When to Take:
            </h4>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              When expanding your business fleet or starting a transport-related
              business.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              If the vehicle will generate income to cover its loan payments.
            </div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
                fontFamily: "Archivo",
              }}
            >
              How Much to Take:
            </h4>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Based on projected business income and the vehicle's potential
              return on investment.
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            ></div>
          </div>
        </div>

        {/* Call to Action Banner */}
        <div
          style={{
            backgroundColor: "#59266D",
            margin: "50px 0 0 0",
            padding: "30px 4%",
            textAlign: "center",
          }}
        >
          <h2
            style={{
              color: "white",
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 24,
              paddingBottom: 40,
            }}
          >
            Ready to Get Started?
          </h2>
          <p
            style={{
              color: "white",
              fontFamily: "Archivo",
              fontSize: 18,
              marginBottom: 30,
              maxWidth: 800,
              margin: "0 auto 30px auto",
              backgroundColor: "transparent",
              textAlign: "center",
            }}
          >
            Apply today and take the first step toward financing your vehicle or
            getting additional funds with our flexible car loan options.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: 20,
              flexWrap: "wrap",
              backgroundColor: "transparent",
            }}
          >
            <Link
              to="/loanpage/carloan/used-car-loan"
              style={{
                display: "inline-block",
                backgroundColor: "white",
                color: "#59266D",
                padding: "15px 30px",
                borderRadius: 4,
                textDecoration: "none",
                fontWeight: 600,
                fontSize: 16,
                fontFamily: "Archivo",
              }}
            >
              Apply for Used Car Loan
            </Link>
            <Link
              to="/loanpage/carloan/car-loan-top-up"
              style={{
                display: "inline-block",
                backgroundColor: "transparent",
                color: "white",
                padding: "14px 28px",
                borderRadius: 4,
                textDecoration: "none",
                fontWeight: 600,
                fontSize: 16,
                border: "2px solid white",
                fontFamily: "Archivo",
              }}
            >
              Apply for Car Loan Top-up
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import Eligibility from "./Eligibility/Eligibility.jsx";
import LoanEstimator from "./Estimator/LoanEstimator.jsx";
import Tailor from "./Tailor/Tailor.jsx";
import Insights from "./Insights/Insights.jsx";

const LAPDashboardPage = () => {
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <Eligibility />
        <LoanEstimator />
        <Tailor />
        <Insights />
      </div>
    </>
  );
};

export default LAPDashboardPage;

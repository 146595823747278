import { CheckCircle } from "lucide-react";
import { useEffect, useState, useMemo } from "react";

export function LoanStatusMethods({ name }) {
  const [data, setData] = useState([]);

  const kotakData = useMemo(
    () => ({
      h2: "  4 Ways to Check Your Kotak Bank Loan Status",
      para: " Multiple convenient options to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  Use our secure online tracker above to instantly check your Bank of Baroda personal loan status.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application progress",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " MinEMI Loan Tracker",
          para2:
            "  Use our secure online tracker above to instantly check your Bank of Baroda personal loan status.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application progress",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      h2: "  How to Check Your Bank of Baroda Personal Loan Status",
      para: "Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: " Bank of Baroda NetBanking",
          para2:
            "  Log in to your Bank of Baroda NetBanking account to check your personal loan application status.",
          step1: "Visit the Bank of Baroda website and log in",
          step2: " Navigate to the 'Loans' section",
          step3: "View your application status and details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),
          h3: " Customer Support",
          para2:
            "Contact Bank of Baroda's customer support to inquire about your personal loan application status.",
          step1: "Call customer care at 1800-258-4455",
          step2: " Provide your application number",
          step3: " Get your loan status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),

          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const idfcData = useMemo(
    () => ({
      h2: "  4 Ways to Check Your Aditya Birla Finance Loan Status",
      para: "Multiple convenient options to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: " Aditya Birla Finance Customer Portal",
          para2:
            " Track your personal loan application directly through the Aditya Birla Finance customer portal or mobile app.",
          step1: "Log in to the Aditya Birla Finance customer portal",
          step2: " Navigate to 'My Applications' → 'Application Status'",
          step3: "View comprehensive status with stage-wise tracking",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: "Aditya Birla Finance Customer Care",
          para2:
            "  Speak directly with Aditya Birla Finance's dedicated personal loan specialists for status updates and guidance.",
          step1: "Call Aditya Birla Finance's customer care at 1800 270 7000",
          step2:
            " Provide your application reference number and verification details",
          step3: "Request specific information about pending requirements",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: "Aditya Birla Finance Email Service",
          para2:
            " Get detailed status updates via email for comprehensive tracking of your Aditya Birla Finance personal loan application.",
          step1:
            "Email your application number to care.finance@adityabirlacapital.com",
          step2: "Receive detailed status update within 24-48 hours",
          step3: "Get personalized guidance on any pending requirements",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      h2: " How to Check Your HDFC Bank Personal Loan Status",
      para: "Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  Use our secure online tracker above to instantly check your Bank of Baroda personal loan status.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application progress",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " MinEMI Loan Tracker",
          para2:
            "  Use our secure online tracker above to instantly check your Bank of Baroda personal loan status.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application progress",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  Use our secure online tracker above to instantly check your Bank of Baroda personal loan status.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application progress",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      h2: " How to Check Your State Bank of India Personal Loan Status",
      para: " Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: " SBI YONO App & Net Banking",
          para2:
            "  Log in to your SBI YONO app or Net Banking account to check your personal loan application status.",
          step1: "Log in to YONO app or SBI Net Banking",
          step2: " Navigate to the 'Loans' or 'Services' section",
          step3: " Select 'Loan Status' to view your application details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Customer Support",
          para2:
            " Contact SBI's customer support to inquire about your personal loan application status.",
          step1: "Call SBI Contact Center at 1800-11-2211",
          step2: "Provide your application number",
          step3: "Get your loan status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      h2: "How to Check Your Axis Bank Personal Loan Status",
      para: " Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: " Axis Mobile App & Internet Banking",
          para2:
            "  Log in to your Axis Mobile app or Internet Banking account to check your personal loan application status.",
          step1: "Log in to Axis Mobile app or Internet Banking",
          step2: " Navigate to the 'Loans' or 'Services' section",
          step3: " Select 'Loan Status' to view your application details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Customer Support",
          para2:
            " Contact Axis Bank's customer support to inquire about your personal loan application status.",
          step1: "Call Axis Bank at 1800-209-7400s",
          step2: "Provide your application number",
          step3: "Get your loan status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      h2: "How to Check Your ICICI Bank Personal Loan Status",
      para: " Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: " iMobile Pay App & Internet Banking",
          para2:
            "  Log in to your iMobile Pay app or Internet Banking account to check your personal loan application status.",
          step1: "Log in to iMobile Pay app or Internet Banking",
          step2: " Navigate to the 'Loans'  section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Customer Support",
          para2:
            " Contact ICICI Bank's customer support to inquire about your personal loan application status.",
          step1: "Call ICICI Bank at 1800-1080 or 1860-120-7777",
          step2: "Provide your application number",
          step3: "Get your loan status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      h2: "How to Check Your Punjab National Bank Personal Loan Status",
      para: " Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: " PNB ONE App & Internet Banking",
          para2:
            "  Log in to your PNB ONE app or Internet Banking account to check your personal loan application status.",
          step1: "Log in to PNB ONE app or Internet Banking",
          step2: " Navigate to the 'Loans'  section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Customer Support",
          para2:
            " Contact Punjab National Bank's customer support to inquire about your personal loan application status.",
          step1: "Call PNB at 1800-180-2222 or 1800-103-2222",
          step2: "Provide your application number",
          step3: "Get your loan status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      h2: "How to Check Your IndusInd Bank Personal Loan Status",
      para: " Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "IndusMobile App & Internet Banking",
          para2:
            "  Log in to your IndusMobile app or Internet Banking account to check your personal loan application status.",
          step1: "Log in to PNB ONE app or Internet Banking",
          step2: " Navigate to the 'Loans'  section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Customer Support",
          para2:
            " Contact IndusInd Bank's customer support to inquire about your personal loan application status.",
          step1: "Call IndusInd Bank at 1860-500-5004 or 022-4406 6666",
          step2: "Provide your application number",
          step3: "Get your loan status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      h2: "How to Check Your Tata Capital Personal Loan Status",
      para: " Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "Tata Capital Mobile App & Web Portal",
          para2:
            "  Log in to the Tata Capital Mobile App or Customer Portal to check your personal loan application status.",
          step1: "Log in to the Tata Capital Mobile App or Customer Portal",
          step2: " Navigate to the 'Loans'  section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Customer Support",
          para2:
            " Contact Tata Capital's customer support to inquire about your personal loan application status.",
          step1: "Call Tata Capital at 1800-267-8282 (toll-free)",
          step2: "Provide your application number",
          step3: "Get your loan status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      h2: "How to Check Your Yes Bank Personal Loan Status",
      para: " Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "YES Mobile App & NetBanking",
          para2:
            "  Log in to your YES Mobile app or NetBanking account to check your personal loan application status.",
          step1: "Log in to YES Mobile app or NetBanking",
          step2: " Navigate to the 'Loans'  section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Customer Support",
          para2:
            " Contact Yes Bank's customer support to inquire about your personal loan application status.",
          step1: "Call Yes Bank at 1800-1200",
          step2: "Provide your application number",
          step3: "Get your loan status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      h2: "How to Check Your Union Bank Personal Loan Status",
      para: " Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "U-Mobile App & Net Banking",
          para2:
            "  Log in to your U-Mobile app or Union Bank Net Banking account to check your personal loan application status.",
          step1: "Log in to U-Mobile app or Net Banking",
          step2: " Navigate to the 'Loans'  section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Customer Support",
          para2:
            "Contact Union Bank's customer support to inquire about your personal loan application status.",
          step1: "Call Union Bank at 1800-22-2244",
          step2: "Provide your application number",
          step3: "Get your loan status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      h2: "How to Check Your Bajaj Finance Loan Status",
      para: " Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "Bajaj Finserv App & Portal",
          para2:
            "  Access your loan status directly through the official Bajaj Finserv app or customer portal.",
          step1: "Log in to the Bajaj Finserv app or website",
          step2: " Navigate to the 'Loans'  section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Customer Support",
          para2:
            "Speak directly with Bajaj Finance customer support representatives about your loan status.",
          step1: "Call Bajaj Finance at 020-3957-5152",
          step2: "Provide your application number",
          step3: "Get your loan status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      h2: "How to Check Your Federal Bank Loan Status",
      para: " Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "FedMobile App & FedNet",
          para2:
            "  Access your loan status directly through the official Federal Bank mobile app or internet banking.",
          step1: "Log in to the FedMobile app or FedNet internet banking",
          step2: " Navigate to the 'Loans'  section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Customer Support",
          para2:
            "Speak directly with Federal Bank customer support representatives about your loan status.",
          step1: "Call Federal Bank at 1800-425-1199 (toll-free)",
          step2: "Provide your application number",
          step3: "Get your loan status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      h2: "How to Check Your Canara Bank Loan Status",
      para: " Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "CANDI App & Net Banking",
          para2:
            " Access your loan status directly through the official Canara Bank mobile app (CANDI) or internet banking.",
          step1: "Log in to the CANDI app or Canara Bank net banking",
          step2: " Navigate to the 'Loans'  section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Customer Support",
          para2:
            "Speak directly with Canara Bank customer support representatives about your loan status.",
          step1: "Call Canara Bank at 1800-425-0018 (toll-free)",
          step2: "Provide your application number",
          step3: "Get your loan status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      h2: "How to Check Your Bank of India Loan Status",
      para: " Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "Starconnect+ App & Net Banking",
          para2:
            " Access your loan status directly through the official Bank of India mobile app (Starconnect+) or internet banking.",
          step1: "Log in to the Starconnect+ app or Bank of India net banking",
          step2: " Navigate to the 'Loans'  section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Customer Support",
          para2:
            "Speak directly with Bank of India customer support representatives about your loan status.",
          step1: "Call Bank of India at 1800-103-1906 (toll-free)",
          step2: "Provide your application number",
          step3: "Get your loan status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      h2: "How to Check Your RBL Bank Loan Status",
      para: " Multiple convenient ways to track your loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "RBL MoBank App & Net Banking",
          para2:
            "Access your loan status directly through the official RBL Bank mobile app (MoBank) or internet banking.",
          step1: "Log in to the RBL MoBank app or RBL Bank net banking",
          step2: " Navigate to the 'Loans'  section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Customer Support",
          para2:
            "Speak directly with RBL Bank customer support representatives about your loan status.",
          step1: "CCall RBL Bank at 1800-121-9050 (toll-free)",
          step2: "Provide your application number",
          step3: "Get your loan status information",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: " MinEMI Loan Tracker",
          para2:
            "  We do not track loan status directly with the Bank. Instead, fill out our quick form to understand your chances of approval. MinEMI will help you assess your profile and guide you on the next steps — including how to follow up with the bank for faster resolution.",
          step1: "Enter your application details",
          step2: "     Provide verification information",
          step3: " View your loan application Status",
        },
      ],
    }),
    []
  );

  useEffect(() => {
    if (name === "checkkotakbankstatus") {
      setData(kotakData);
    } else if (name === "checkbobbankstatus") {
      setData(bobData);
    } else if (name === "checkidfcbankstatus") {
      setData(idfcData);
    } else if (name === "checkhdfcbankstatus") {
      setData(hdfcData);
    } else if (name === "checksbibankstatus") {
      setData(sbiData);
    } else if (name === "checkaxisbankstatus") {
      setData(axisData);
    } else if (name === "checkicicibankstatus") {
      setData(iciciData);
    } else if (name === "checkpunjabbankstatus") {
      setData(punjabData);
    } else if (name === "checkinduslndbankstatus") {
      setData(induslndData);
    } else if (name === "checktatabankstatus") {
      setData(tataData);
    } else if (name === "checkyesbankstatus") {
      setData(yesData);
    } else if (name === "checkunionbankstatus") {
      setData(unionData);
    } else if (name === "checkbajajbankstatus") {
      setData(bajajData);
    } else if (name === "checkfederalbankstatus") {
      setData(federalData);
    } else if (name === "checkcanarabankstatus") {
      setData(canaraData);
    } else if (name === "checkboibankstatus") {
      setData(boiData);
    } else if (name === "checkrblbankstatus") {
      setData(rblData);
    } else {
      setData([]);
    }
  }, [
    name,
    axisData,
    bobData,
    hdfcData,
    iciciData,
    idfcData,
    kotakData,
    sbiData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
  ]);
  return (
    <section className="py-3  border-b border-gray-200 bg-[#ebe8fb]">
      <div className="container sd:px-2  md:px-6 bg-transparent ">
        <div className="sd:w-full md:w-4/5 mx-auto bg-transparent">
          <div className="text-center mb-6 bg-transparent">
            <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple bg-transparent">
              {data.h2}
            </h2>
            <p className="text-dark-grey mt-2 text-sm bg-transparent text-center">
              {data.para}
            </p>
          </div>

          <div className="grid gap-3 bg-transparent">
            {data?.step1?.map((d, index) => (
              <div
                key={index}
                className="bg-white p-3 rounded-lg shadow-sm border border-gray-200"
              >
                <div className="flex items-start gap-3 bg-transparent">
                  <div className="h-10 w-10 rounded-full  flex items-center justify-center flex-shrink-0 bg-[#ebe8fb]">
                    {d.icon}
                  </div>
                  <div className="bg-transparent">
                    <h3 className="text-base font-bold text-primary-purple mb-2">
                      {d.h3}
                    </h3>
                    <p className="text-dark-grey mb-2 text-sm bg-transparent">
                      {d.para2}
                    </p>
                    <div className="space-y-2 bg-transparent p-0">
                      <div className="flex items-start  bg-transparent">
                        <CheckCircle
                          className="h-5 w-5 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        />

                        <p className="text-sm text-dark-grey bg-transparent p-0 m-0">
                          {d.step1}
                        </p>
                      </div>
                      <div className="flex items-start gap-0.5 bg-transparent">
                        <CheckCircle
                          className="h-5 w-5 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        />

                        <p className="text-sm text-dark-grey bg-transparent p-0 m-0">
                          {d.step2}
                        </p>
                      </div>
                      <div className="flex items-start gap-0.5 bg-transparent">
                        <CheckCircle
                          className="h-5 w-5 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        />
                        <p className="text-sm text-dark-grey bg-transparent p-0 m-0">
                          {d.step3}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

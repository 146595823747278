import axios from "axios";
import 'react-tooltip/dist/react-tooltip.css'
import { useState, useEffect, useRef, forwardRef } from "react";
import { IoIosAlert } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { ColorRing } from "react-loader-spinner";

const LoanFilterAndSort = forwardRef((props, ref) => {
  const [filters, setFilters] = useState({
    car_type: "new",
    loanAmount: "50000",
    down_payment:[10,50],
    tenure: [1, 7],
    cibilScore: "650",
    processingTime: "Instant",
  
  });
  const [sortBy, setSortBy] = useState("lowestInterestRate");
  const [loading, setLoading] = useState(false); // Loader state
  const [loanAmountInput, setLoanAmountInput] = useState("50,000");
  const [showFilters, setShowFilters] = useState(true);
  const [data, setData] = useState([]);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [sortedData, setSortedData] = useState([]);
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const options = [
    {
      icon: (
        <svg style={{backgroundColor:'transparent'}}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-8 h-8"
        >
          <path style={{backgroundColor:'transparent'}}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
          />
        </svg>
      ),
      title: "Improve Eligibility",
      description: "Check your credit score and understand what's impacting your loan approval.",
      buttonText: "Check My Credit Score",
      link:"https://minemi.ai/personal-loan/check-eligibility/"
    },
    {
      icon: (
        <svg style={{backgroundColor:'transparent'}}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-8 h-8"
        >
          <path style={{backgroundColor:'transparent'}}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </svg>
      ),
      title: "Try Loan Consolidation",
      description: "Reducing your monthly EMI burden may improve eligibility for a new loan.",
      buttonText: "Explore Loan Consolidation",
      link:"/consolidate-emi-pages"
    },
    {
      icon: (
        <svg style={{backgroundColor:'transparent'}}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-8 h-8"
        >
          <path style={{backgroundColor:'transparent'}}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
      ),
      title: "Consider Other Loan Products",
      description: "You may qualify for an Overdraft, Loan Against FD, or Credit Card EMI.",
      buttonText: "View Other Loan Options",
      link:"/loanpage"
    },
    {
      icon: (
        <svg style={{backgroundColor:'transparent'}}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-8 h-8"
        >
          <path style={{backgroundColor:'transparent'}} strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
        </svg>
      ),
      title: "Go Back and Modify Filters",
      description: "Try adjusting the loan amount, tenure, or interest rate to find more options.",
      buttonText: "Modify Loan Criteria",
      link:"#"
    },
  ]


  const sortData = (data, sortType, filters) => {
    let sorted = [...data];

    switch (sortType) {
        case "highestLoanAmount":
            sorted.sort((a, b) => (b.MaximumLoanAmount || 0) - (a.MaximumLoanAmount || 0));
            break;
        case "lowestInterestRate":
            sorted.sort((a, b) => (a.InterestRate || Infinity) - (b.InterestRate || Infinity));
            break;
        case "BestforCreditScore":
            sorted.sort((a, b) => (a.minCibilScore || 750) - (b.minCibilScore || 750));
            break;
        case "mostPopular":
            sorted.sort((a, b) => (b.BestMatch || 0) - (a.BestMatch || 0));
            break;
        default:
            return sorted;
    }

    // **Secondary sorting logic: Prioritize loans that match user filters**
    if (filters) {
        sorted.sort((a, b) => {
            const aMatchesAmount = Math.abs((a.MaximumLoanAmount || 0) - filters.loanAmount) < 50000 ? 1 : 0;
            const bMatchesAmount = Math.abs((b.MaximumLoanAmount || 0) - filters.loanAmount) < 50000 ? 1 : 0;

            const aMatchesSecured = a.secured === filters.secured ? 1 : 0;
            const bMatchesSecured = b.secured === filters.secured ? 1 : 0;

            const aMatchesCibil = filters.cibilScore === "any" || (a.minCibilScore || 750) === filters.cibilScore ? 1 : 0;
            const bMatchesCibil = filters.cibilScore === "any" || (b.minCibilScore || 750) === filters.cibilScore ? 1 : 0;

            const aMatchesProcessing = filters.processingTime === "any" || a.processingTime === filters.processingTime ? 1 : 0;
            const bMatchesProcessing = filters.processingTime === "any" || b.processingTime === filters.processingTime ? 1 : 0;

            const aScore = aMatchesAmount + aMatchesSecured + aMatchesCibil + aMatchesProcessing;
            const bScore = bMatchesAmount + bMatchesSecured + bMatchesCibil + bMatchesProcessing;

            return bScore - aScore; // Higher matching score comes first
        });
    }

    return sorted;
};


  const LoanSort = () => {
    setLoading(true); // Start loader
    const options = {
      method: "POST",
      url: "https://cardai.minemi.ai/loanComparison",
      data: {
        loan_amount: filters.loanAmount,
        car_type:filters.car_type, //ONLY FOR CL
        down_payment:filters.down_payment[1]+"%",//ONLY FOR CL
        tenure: filters.tenure[1],
        cibil: filters.cibilScore,
        processing_time: filters.processingTime,
        loan_comparison_for:"CL",
      },
    };

    axios
      .request(options)
      .then((response) => {
        const responseData = response.data.matches[0]?.matches || [];
        setData(responseData);
        setSortedData(sortData(responseData, sortBy));
      })
      .catch((error) => {
        console.error(error);
        setData([]);
        setSortedData([]);
      })
      .finally(() => {
        setLoading(false); // Stop loader
      });
      
  };

  useEffect(() => {
    LoanSort();
  }, [filters]);

  // Re-sort when sortBy changes
  useEffect(() => {
    setSortedData(sortData(data, sortBy));
  }, [sortBy]);

  const handleLoanAmountChange = (value) => {
    // Remove non-numeric characters except digits
    let numValue = value.replace(/[^0-9]/g, "").trim(); 
  
    // Ensure it's a valid number
    if (numValue) {
      let formattedValue = new Intl.NumberFormat("en-IN").format(parseInt(numValue, 10));
  
      setFilters({ ...filters, loanAmount: parseInt(numValue, 10) });
      setLoanAmountInput(formattedValue);
    } else {
      setLoanAmountInput(""); // Reset input if empty
    }
  }; 
   const handleLoanToValueChange = (value) => {
    // Remove non-numeric characters except digits
    let numValue = value.replace(/[^0-9]/g, "").trim(); 
  
    // Ensure it's a valid number
    if (numValue) {

      setFilters({ ...filters, loanValueInput: numValue });
    } else {
    }
  };
  
  return (
    <section ref={ref} className="bg-[#ebe8fc] py-4 px-4 md:py-8 md:px-6">
      <div className="max-w-7xl mx-auto" style={{ backgroundColor: "transparent" }}>
        <h2 className="text-2xl md:text-3xl font-bold text-[#311664] mb-6 text-center" style={{ backgroundColor: "transparent" }}>
          Find Your Ideal Loan
        </h2>

        <div className="md:grid md:grid-cols-[400px,1fr] md:gap-6" style={{ backgroundColor: "transparent" }}>
          {/* Filters */}
          <div className="mb-6 md:mb-0" style={{ backgroundColor: "transparent" }}>
            <button style={{ backgroundColor: "transparent" }}
              onClick={() => setShowFilters(!showFilters)}
              className="w-full md:hidden bg-white text-[#311664] font-semibold py-2 px-4 rounded-lg shadow mb-4"
            >
              {showFilters ? "Hide Filters" : "Show Filters"}
            </button>
            <div className="bg-white p-4 lg:p-6 rounded-lg shadow-md" style={{ backgroundColor: "transparent" }}>
            <h3 className="font-semibold text-lg mb-4 text-[#311664]" style={{ backgroundColor: "transparent" }}>Filters</h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4" style={{ backgroundColor: "transparent" }}>
              {/* Loan Amount */}
              <div style={{ backgroundColor: "transparent" }}>
                <label className="block text-sm font-medium mb-2 text-[#311664]" style={{ backgroundColor: "transparent" }}>Loan Amount (₹)</label>
                <input
                  type="text"
                  style={{ backgroundColor: "transparent" }}
                  value={loanAmountInput}
                  onChange={(e) => handleLoanAmountChange(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md mb-2 focus:outline-none focus:ring-2 focus:ring-[#311664]"
                />
                <input
                  type="range"
                  min={50000}
                  max={10000000}
                  step={50000}
                  value={filters.loanAmount}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10)
                    setFilters({ ...filters, loanAmount: value })
                    setLoanAmountInput(value.toLocaleString())
                  }}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer mb-2"
                />
                <div className="flex justify-between text-xs text-gray-600" style={{ backgroundColor: "transparent" }}>
                  <span style={{ backgroundColor: "transparent" }}>₹50,000</span>
                  <span style={{ backgroundColor: "transparent" }}>₹1,00,00,000</span>
                </div>
              </div>

              {/* Down Payment */}
              <div style={{ backgroundColor: "transparent" }}>
              <div style={{ backgroundColor: "transparent" }}>
                  <label className="block text-sm font-medium mb-1" style={{ backgroundColor: "transparent" }}>
                  Down Payment (%)
                  </label>
                  <input
                    type="range"
                    min="10"
                    max="50"
                    value={filters.down_payment[1]}
                    onChange={(e) => setFilters({ ...filters, down_payment: [filters.down_payment[0], Number(e.target.value)] })}
                    className="w-full"
                  />
                 
                </div>
                <div className="flex justify-between text-xs text-gray-600" style={{ backgroundColor: "transparent" }}>
                  <span style={{ backgroundColor: "transparent" }}>10%</span>
                  <span style={{ backgroundColor: "transparent" }}>{filters.down_payment[1]}%</span>
                  <span style={{ backgroundColor: "transparent" }}>50%</span>
                </div>
              </div>

              {/* Tenure */}
              <div style={{ backgroundColor: "transparent" }}>
                  <label className="block text-sm font-medium mb-1" style={{ backgroundColor: "transparent" }}>
                    Tenure (Years)
                  </label>
                  <input
                    type="range"
                    min="1"
                    max="7"
                    value={filters.tenure[1]}
                    onChange={(e) => setFilters({ ...filters, tenure: [filters.tenure[0], Number(e.target.value)] })}
                    className="w-full "
                 
                  />
                  <div className="flex justify-between text-xs mt-1" style={{ backgroundColor: "transparent" }}>
                    <span style={{ backgroundColor: "transparent" }}>{filters.tenure[0]} year(s)</span>
                    <span style={{ backgroundColor: "transparent" }}>{filters.tenure[1]} year(s)</span>
                  </div>
                </div>

              {/* Car Type */}
              <div style={{ backgroundColor: "transparent" }}>
                <label className="block text-sm font-medium mb-2 text-[#311664]" style={{ backgroundColor: "transparent" }}>Car Type</label>
                <select 
                style={{ backgroundColor: "transparent" }}
                  onChange={(e) => setFilters({ ...filters, car_type: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#311664]"
                >
                  <option value="any" style={{ backgroundColor: "transparent" }}>Any</option>
                  <option value="new" style={{ backgroundColor: "transparent" }}>New Car</option>
                  <option value="used" style={{ backgroundColor: "transparent" }}>Used Car</option>
                </select>
              </div>

              {/* Credit Score */}
              <div style={{ backgroundColor: "transparent" }}>
                <label className="block text-sm font-medium mb-2 text-[#311664]" style={{ backgroundColor: "transparent" }}>Credit Score</label>
                <select 
                style={{ backgroundColor: "transparent" }}
                  onChange={(e) => setFilters({ ...filters, cibilScore: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#311664]"
                >
                  <option value="650" style={{ backgroundColor: "transparent" }}>650</option>
                  <option value="650-700" style={{ backgroundColor: "transparent" }}>650-700</option>
                  <option value="700-750" style={{ backgroundColor: "transparent" }}>700-750</option>
                  <option value="750+" style={{ backgroundColor: "transparent" }}>750+</option>
                </select>
              </div>

              {/* Processing Time */}
              <div style={{ backgroundColor: "transparent" }}>
                <label className="block text-sm font-medium mb-2 text-[#311664]" style={{ backgroundColor: "transparent" }}>Processing Time</label>
                <select 
                style={{ backgroundColor: "transparent" }}
                  onChange={(e) => setFilters({ ...filters, processingTime: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#311664]"
                >
                  <option value="Instant" style={{ backgroundColor: "transparent" }}>Instant</option>
                  <option value="1-3 days" style={{ backgroundColor: "transparent" }}>1-3 days</option>
                  <option value="3-7 days" style={{ backgroundColor: "transparent" }}>3-7 days</option>
                  <option value="7+ days" style={{ backgroundColor: "transparent" }}>7+ days</option>
                </select>
              </div>
            </div>
          </div>
          </div>

          {/* Loan Results */}
          <div style={{ backgroundColor: "transparent" }}>
            <div
              style={{
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "2%",
                alignContent: "center",
              }}
            >
              <p className="text-sm text-gray-600 sm:mb-0" style={{ backgroundColor: "transparent", alignSelf: "center" }}>
                {Array.isArray(data) ? data.length : 0} loans found
              </p>
              <select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
                className="w-full sm:w-auto p-2 text-sm border border-gray-300 rounded"
              >
                <option value="lowestInterestRate">Lowest Interest Rate</option>
                <option value="highestLoanAmount">Highest Loan Amount</option>
                <option value="bestForLowCreditScore">Best for Low Credit Score</option>
                <option value="mostPopular">Most Popular</option>
              </select>
            </div>
            {loading ? ( // Show loader when fetching data
          <div className="text-center my-6" style={{backgroundColor:'transparent'}}>
            <div className="loader"></div> {/* Use a CSS spinner or a text-based loader */}
            <p style={{backgroundColor:'transparent'}}>Loading loan...</p>
          </div>
        ) : (
<>{

  sortedData.length > 0 ? (
              sortedData.map((loan,index) => (
                <div key={index} className="bg-white rounded-lg shadow overflow-hidden mb-4">
                  <div className="p-4">
                    <div className="flex justify-between items-start mb-2">
                      <div>
                        <h3 className="text-lg font-semibold text-[#311664]">{loan.BankName}</h3>
                        <p className="text-sm text-gray-600">{loan.LoanTypeCategory}</p>
                      </div>
                      <div style={{display:'flex',flexDirection:'row',gap:4,alignItems:'center'}}>
                      <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-xs sm:text-sm font-semibold text-gray-700 mt-1 sm:mt-0">
                      Down Payment: {loan.downPayment}
                        </span>
                     
                      <div style={{backgroundColor:'transparent'}}>
                      <a data-tooltip-id="my-tooltip-click" style={{backgroundColor:'transparent'}}><IoIosAlert  style={{backgroundColor:'transparent'}} size={24}/>                      </a>
<Tooltip

  id="my-tooltip-click"
  content="Your interest rate depends on your credit score, income, and lender policies. Better profiles get lower rates."
  events={['click']}
/>
                      </div>
</div>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mt-4">
                      <div className="text-center">
                        <p className="text-xs text-gray-600">Interest Rate</p>
                        <p className="text-lg font-semibold text-[#311664]">{loan.InterestRate}</p>
                      </div>
                      <div className="text-center">
                        <p className="text-xs text-gray-600">Max Tenure</p>
                        <p className="text-lg font-semibold text-[#311664]">{loan.MaximumTenure} </p>
                      </div>
                      <div className="text-center">
                        <p className="text-xs text-gray-600">Max Loan Amount</p>
                        <p className="text-lg font-semibold text-[#311664]">{loan.MaximumLoanAmount}</p>
                      </div>
                      <div className="text-center">
                        <p className="text-xs text-gray-600">Processing Fee</p>
                        <p className="text-lg font-semibold text-[#311664]">{loan.ProcessingFees}</p>
                      </div>
                    </div>

                  </div>

                  {selectedLoan === index && (
                    <div className="p-4 border-t">
                      <h4 className="font-semibold mb-2 text-sm">Detailed Information</h4>
                      <div className="space-y-4">
                        <div>
                          <h5 className="font-medium mb-1 text-xs">Documents Required</h5>
                          <ul className="list-disc pl-5 text-xs">
                            {loan.DocumentRequired?.map((doc, index) => (
                              <li key={index}>{doc}</li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <h5 className="font-medium mb-1 text-xs">Advantages</h5>
                          <ul className="text-xs">
                            {loan.Advantages?.map((advantage, index) => (
                              <li key={index} className="flex items-center mb-1">
                                <span className="text-green-500 mr-2">✓</span>
                                {advantage}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <h5 className="font-medium mb-1 text-xs">Disadvantages</h5>
                          <ul className="text-xs">
                            {loan.Disadvantages?.map((disadvantage, index) => (
                              <li key={index} className="flex items-center mb-1">
                                <span className="text-red-500 mr-2">✗</span>
                                {disadvantage}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="p-4 bg-gray-50 flex flex-col sm:flex-row justify-between items-center gap-2">
                    <button
                      onClick={() => setSelectedLoan(selectedLoan === index ? null : index)}
                      className="w-full sm:w-auto px-4 py-2 border border-[#311664] text-[#311664] text-sm rounded hover:bg-[#311664] hover:text-white transition-colors"
                    >
                      {selectedLoan === index ? "Hide Details" : "Show Details"}
                    </button>
                    <button className="w-full sm:w-auto px-4 py-2 bg-[#3a868b] text-white text-sm rounded hover:bg-[#59266d] transition-colors" onClick={() => {
                          setSelectedIndex(index);
                          setShow(true);
                          setLoader(true);
                        }}>
                          {selectedIndex === index ?
                            <ColorRing
                              visible={loader}
                              height="30"
                              width="30"
                              ariaLabel="color-ring-loading"
                              wrapperStyle={{ backgroundColor: "transparent" }}
                              wrapperClass="color-ring-wrapper"
                              colors={[
                                "#ffffff",
                                "#ffffff",
                                "#ffffff",
                                "#ffffff",
                                "#ffffff",
                                "#ffffff",
                              ]}
                            /> :
                            "Apply Now"
                          }
                        </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="max-w-4xl mx-auto p-4 sm:p-6 lg:p-8" style={{backgroundColor:'transparent'}}>
              <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg shadow-lg p-6 sm:p-8">
                <div className="text-center mb-8" style={{backgroundColor:'transparent'}}>
                  <h2 className="text-2xl sm:text-3xl font-bold text-indigo-700 mb-2" style={{backgroundColor:'transparent'}}>No offers match your criteria</h2>
                  <p className="text-lg sm:text-xl text-indigo-600" style={{backgroundColor:'transparent',textAlign:'center'}}>But here's what you can do next:</p>
                </div>
                <div className="grid gap-6 sm:grid-cols-2" style={{backgroundColor:'transparent'}}>
                  {options.map((option, index) => (
                    <div
                      key={index}
                      className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-6"
                    >
                      <div className="flex flex-col items-center text-center gap-4" style={{backgroundColor:'transparent'}}>
                        <div className="bg-indigo-100 p-3 rounded-full" >{option.icon}</div>
                        <h3 className="text-lg font-semibold text-indigo-700" style={{backgroundColor:'transparent'}}>{option.title}</h3>
                        <p className="text-gray-600" style={{backgroundColor:'transparent'}}>{option.description}</p>
                        <Link to={option.link} className="mt-2 px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded-md transition-colors duration-300">
                          {option.buttonText}
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          
          )}
          </>
          )}
          </div>
        </div>
      </div>
      <Modal
        className="bg-transparent"
        show={show}
        onHide={() => {
          setShow(false);
          setLoader(false);
          setSelectedIndex(null)
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          Your responses have been recorded, we'll get back to you.
        </Modal.Body>
        <Modal.Footer>
          <Button id="modalCloseBtn" onClick={() => {
            setShow(false);
            setLoader(false);
            setSelectedIndex(null)
          }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
});

export default LoanFilterAndSort;
import React from "react";
import {
  FaPercent,
  FaCheckSquare,
  FaHeart,
  FaShieldAlt,
  FaDollarSign,
  FaHeadphones,
} from "react-icons/fa";

const benefits = [
  {
    title: "Competitive Interest Rates",
    description:
      "Minemi offers some of the lowest interest rates in the market, helping you save money in the long run.",
    icon: FaPercent,
    tooltip:
      "Our rates are regularly reviewed to ensure you get the best deal.",
  },
  {
    title: "Simplified Process",
    description:
      "Our easy-to-follow process means no complicated paperwork or long waits—just fast, efficient loan consolidation.",
    icon: FaCheckSquare,
    tooltip: "Complete your application in minutes, not hours.",
  },
  {
    title: "Personalized Loan Solutions",
    description:
      "We tailor loan solutions to your unique needs, ensuring you get the most suitable terms for your situation.",
    icon: FaHeart,
    tooltip: "Get a loan that fits your life, not the other way around.",
  },
  {
    title: "Trusted Expertise",
    description:
      "With years of experience in financial services, Minemi offers expert guidance and support every step of the way.",
    icon: FaShieldAlt,
    tooltip: "Benefit from our deep industry knowledge and experience.",
  },
  {
    title: "Transparent Fees",
    description:
      "At Minemi, there are no hidden fees—what you see is what you get, with clear and honest terms.",
    icon: FaDollarSign,
    tooltip: "We believe in full transparency in all our dealings.",
  },
  {
    title: "Excellent Customer Support",
    description:
      "Our customer support team is always available to assist you, making your loan consolidation experience stress-free.",
    icon: FaHeadphones,
    tooltip: "Get help when you need it, how you need it.",
  },
];

function WhyChooseMinemi() {
  return (
    <section className="py-6 " style={{ backgroundColor: "#f8f8f8" }}>
      <h2 className="text-2xl font-bold text-center mb-2 text-[#59266d]">
        Why Choose Minemi for Loan Consolidation
      </h2>
      <p className="text-sm text-center mb-4 max-w-2xl mx-auto">
        Get a reliable partner committed to your financial success.
      </p>
      <div className="container-fluid mx-auto px-4 ">
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 mb-6 ">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className=" p-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 flex flex-col items-center text-center"
              style={{ backgroundColor: "#EAE8FF" }}
              // onMouseEnter={() => alert(benefit.tooltip)}
            >
              <div className="flex flex-col items-center text-center w-full">
                <div className="mb-2">
                  {React.createElement(benefit.icon, { className: "w-6 h-6" })}
                </div>
                <h3 className="text-sm font-semibold mb-1">{benefit.title}</h3>
                <p className="text-xs text-gray-600">{benefit.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default WhyChooseMinemi;

import React, { useState } from "react";

export function EmiTopupSection() {
  return (
    <section className="py-8 md:py-20 " style={{ backgroundColor: "#f8f8f8" }}>
      <h2 className="text-2xl md:text-4xl font-bold text-center mb-4 text-[#59266d] ">
        What is Loan Consolidation?
      </h2>
      <p className="text-lg md:text-xl text-center mb-6 max-w-2xl mx-auto">
        Combine loans, simplify payments, and save on interest
      </p>
      <div className="bg-gradient-to-r from-purple-100 to-indigo-100 rounded-lg p-6 mb-8 shadow-md mx-4">
        <p className="text-[#59266d] text-center text-base md:text-lg leading-relaxed bg-gradient-to-r from-purple-100 to-indigo-100">
          Loan consolidation means combining all your existing loans into one
          single loan. This makes it easier to manage because you only have to
          pay one EMI instead of multiple. It can also help you lower your
          interest rate, reduce your monthly payments, and simplify your
          finances.
        </p>
      </div>
      <div className="container-fluid mx-auto px-4 bg-transparent">
        <div className="mb-8 bg-transparent">
          <h3 className="text-xl md:text-2xl font-semibold text-center mb-6 text-[#59266d] bg-transparent">
            Benefits of Loan Consolidation
          </h3>
          <div className="overflow-x-auto bg-transparent">
            <table className="w-full bg-transparent">
              <thead className="bg-transparent">
                <tr className="bg-transparent text-white">
                  <th className="text-left font-semibold p-3 bg-[#59266d] text-white">
                    Benefit
                  </th>
                  <th className="text-left font-semibold p-3 bg-[#59266d] text-white">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody className="bg-transparent">
                {benefits.map((benefit, index) => (
                  <tr
                    key={index}
                    className={`border-b border-purple-200 ${
                      index % 2 === 0 ? "bg-purple-50" : "bg-white"
                    }`}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#F3E8FF" : "White",
                    }}
                  >
                    <td
                      className={`font-medium`}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <CustomTooltip
                        content={benefit.tooltip}
                        style={{ backgroundColor: "transparent" }}
                      >
                        <div
                          className={`flex items-center gap-3 md:gap-4 cursor-help bg-transparent ${
                            index % 2 === 0 ? "bg-purple-100" : "bg-white"
                          }`}
                        >
                          {benefit.icon}
                          <span
                            className={`p-3 ${
                              index % 2 === 0 ? "bg-purple-100" : "bg-white"
                            }`}
                          >
                            {benefit.title}
                          </span>
                        </div>
                      </CustomTooltip>
                    </td>
                    <td
                      className={`p-3 ${
                        index % 2 === 0 ? "bg-purple-100" : "bg-purple-50"
                      }`}
                    >
                      {benefit.description}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="text-center mt-10 bg-transparent">
        <a
          style={{ textDecoration: "none", color: "#ffffff" }}
          href="/lower-emi-loan-consolidation"
          onClick={() => {
            localStorage.setItem("id", 2);
            window.scrollTo(0, 0);
          }}
        >
          <button className="bg-[#59266d] text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-purple-800 transition-colors change-width">
            Ready to Consolidate Your Loans?
          </button>
        </a>
      </div>
    </section>
  );
}

const CustomTooltip = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div className="absolute z-10 p-2 bg-gray-800 text-white text-sm rounded shadow-lg mt-1">
          {content}
        </div>
      )}
    </div>
  );
};

const benefits = [
  {
    title: "Single EMI",
    description:
      "Simplify your finances by paying just one EMI instead of managing multiple.",
    // icon: <Check className="w-6 h-6 md:w-8 md:h-8 text-green-500" />,
    tooltip:
      "Consolidate all your loans into a single, easy-to-manage EMI payment.",
  },
  {
    title: "Lower Interest Rates",
    description:
      "Consolidate loans to secure a better interest rate, saving you money over time.",
    // icon: <ArrowDown className="w-6 h-6 md:w-8 md:h-8 text-blue-500" />,
    tooltip:
      "Potentially reduce your overall interest payments with a lower consolidated rate.",
  },
  {
    title: "Reduced Monthly Payments",
    description: "Lower your monthly EMI by extending the loan tenure.",
    // icon: <Zap className="w-6 h-6 md:w-8 md:h-8 text-yellow-500" />,
    tooltip: "Ease your monthly financial burden with lower EMI payments.",
  },
  {
    title: "Easier Debt Management",
    description:
      "Stay organized with one payment, avoiding missed payments and late fees.",
    // icon: <PieChart className="w-6 h-6 md:w-8 md:h-8 text-purple-500" />,
    tooltip:
      "Simplify your financial life by managing just one loan instead of multiple.",
  },
  {
    title: "Improved Credit Score",
    description:
      "By consolidating, you can stay on top of your payments, which may boost your credit score.",
    // icon: <TrendingUp className="w-6 h-6 md:w-8 md:h-8 text-red-500" />,
    tooltip:
      "Regular, on-time payments of your consolidated loan can positively impact your credit score.",
  },
];

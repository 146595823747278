import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import MetadataContext from "../../MetadataContext";

const NRIHomeLoan = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        "NRI Home Loans: A Comprehensive Guide for Non-Resident Indians",
      pageDescription:
        "Explore NRI home loan options with our comprehensive guide. Learn about eligibility, documentation, and the best loan offers for Non-Resident Indians looking to invest in property.",
      pageKeywords:
        "NRI home loans, home loans for Non-Resident Indians, NRI property loans, eligibility for NRI home loans, NRI loan documentation, best NRI loan offers, property investment for NRIs, NRI mortgage loans, NRI home loan guide, home loan options for NRIs",
      canonicalUrl: "https://minemi.ai/loanpage/homeloan/nri-home-loan",
    });
  }, [setMetadata]);
  return (
    <>
      <Helmet>
        <title>
          NRI Home Loans: A Comprehensive Guide for Non-Resident Indians
        </title>
        <meta
          name="description"
          content="Explore NRI home loan options with our comprehensive guide. Learn about eligibility, documentation, and the best loan offers for Non-Resident Indians looking to invest in property."
        />
        <meta
          name="keywords"
          content="NRI home loans, home loans for Non-Resident Indians, NRI property loans, eligibility for NRI home loans, NRI loan documentation, best NRI loan offers, property investment for NRIs, NRI mortgage loans, NRI home loan guide, home loan options for NRIs"
        />
        {/* <link rel="icon" type="image/png" href="./mLogo.png" /> */}
      </Helmet>
      <div
        style={{
          backgroundColor: "#E7E4F9",
          paddingLeft: "4%",
          paddingRight: "4%",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          paddingTop: 40,
          paddingBottom: 40,
          overflow: "hidden",
          boxShadow: "2px 1px 2px 0px #00000040",
        }}
      >
        {/* breadcrumbs */}
        <div id="breadcrumbs" style={{ background: "transparent" }}>
          <p className="pages_links bg-transparent links ">
            <Link
              to={"/"}
              // underline="hover"
              className="custom-link bg-transparent archivo "
              style={{
                // color: "#59266D",
                cursor: "pointer",
                fontFamily: "'Archivo', sans-serif",
              }}
            >
              Home
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1 "
              style={{
                color: "#59266D",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Loans
            </Link>
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              to={"/loanpage/homeloan"}
              underline="hover"
              className=" custom-link d-none d-md-inline bg-transparent archivo"
              style={{ cursor: "pointer" }}
            >
              Home Loan
            </Link>{" "}
            &nbsp;
            <span
              className="d-none d-md-inline bg-transparent ps-1 px-1"
              style={{
                color: "#59266d",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: 800,
              }}
            >
              &gt;
            </span>
            &nbsp;
            <Link
              underline="hover"
              className="d-none d-md-inline fw-bold bg-transparent archivo"
              style={{ color: "#59266d", cursor: "pointer" }}
            >
              NRI Home Loans
            </Link>{" "}
          </p>
        </div>
        <h1
          className="homeloan-heading mt-3"
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 40,
            lineHeight: "46px",
            color: "#59266D",
            backgroundColor: "#E7E4F9",
          }}
        >
          NRI Home Loans: A Comprehensive Guide for Non-Resident Indians{" "}
        </h1>
        <p
          className="homeloan-paragraph"
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 18,
            color: "#74589B",
            backgroundColor: "#E7E4F9",
            lineHeight: "20px",
          }}
        >
          Explore NRI home loan options with our comprehensive guide. Learn
          about eligibility, documentation, and the best loan offers for
          Non-Resident Indians looking to invest in property.
        </p>
      </div>

      <div style={{ marginLeft: "4%", marginRight: "4%" }}>
        <div
          style={{
            marginTop: 35,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Introduction:
        </div>

        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          Non-Resident Indians (NRIs) who wish to own property in India can
          benefit from NRI Home Loans. These loans are specially designed for
          NRIs and Persons of Indian Origin (PIOs) to help them finance the
          purchase, construction, or renovation of a home in India. With several
          banks and financial institutions offering these loans, it’s important
          to understand the eligibility criteria, benefits, and application
          process to make informed decisions.
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          What are NRI Home Loans?
        </div>
        <div
          style={{
            fontFamily: "Archivo",
            marginTop: 16,
            color: "#1F1F1F",
            fontWeight: 400,
            fontSize: 16,
          }}
        >
          An NRI Home Loan is a financial product tailored to meet the housing
          needs of NRIs. It provides funds to purchase, build, or renovate
          residential properties in India. These loans come with competitive
          interest rates and flexible repayment options. The loans are typically
          granted in Indian Rupees (INR) and are repaid in the same currency.
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Key Benefits of NRI Home Loans:
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Loan Amount Flexibility:&nbsp;&nbsp;
              </span>
              Most lenders offer home loans based on the applicant’s income,
              property value, and other financial parameters.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Attractive Interest Rates:&nbsp;&nbsp;
              </span>
              NRIs often benefit from lower interest rates than other loan types
              due to the stable nature of the property market in India.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                {" "}
                Longer Repayment Tenure:&nbsp;&nbsp;
              </span>
              Loan repayment tenure can extend from 10 to 30 years, depending on
              the lender and loan amount.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Easy Repayment Options:&nbsp;&nbsp;
              </span>
              Loans can be repaid through NRE or NRO accounts, and through
              remittances from abroad.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Tax Benefits:&nbsp;&nbsp;
              </span>
              NRI Home Loan borrowers are eligible for tax deductions on both
              principal and interest under Section 80C and Section 24(b) of the
              Income Tax Act.
            </div>
          </div>
        </div>

        <div
          className=" pb-2 "
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Eligibility Criteria for NRI Home Loans:{" "}
        </div>
        <Typography
          sx={{
            fontFamily: "'Archivo',sans-serif",
            fontSize: "16px",
            color: "#1F1F1F",
          }}
        >
          Eligibility criteria for NRI Home Loans generally depend on several
          factors, including income, age, and employment status. Here are some
          of the common requirements:
        </Typography>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <Typography
                className=" d-inline"
                sx={{
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 600,
                }}
              >
                Age:&nbsp;&nbsp;
              </Typography>
              Typically, applicants must be between 21 and 60 years of age.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <Typography
                className=" d-inline"
                sx={{
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 600,
                }}
              >
                Income:&nbsp;&nbsp;
              </Typography>
              A stable income source is required, which can be in the form of
              salary, business income, or self-employment. The minimum income
              requirement varies by lender.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <Typography
                className=" d-inline"
                sx={{
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 600,
                }}
              >
                Employment:&nbsp;&nbsp;
              </Typography>
              NRIs employed in a foreign country or those running a business
              abroad can apply for these loans.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <Typography
                className=" d-inline"
                sx={{
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 600,
                }}
              >
                Credit Score:&nbsp;&nbsp;
              </Typography>
              A good credit score (above 750) increases the chances of loan
              approval and better interest rates.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <Typography
                className=" d-inline"
                sx={{
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: "16px",
                  color: "#1F1F1F",
                  fontWeight: 600,
                }}
              >
                Property Type:&nbsp;&nbsp;
              </Typography>
              The property being purchased, constructed, or renovated must be
              located in India.
            </div>
          </div>{" "}
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          How to Apply for an NRI Home Loan:
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Choose a Lender:&nbsp;&nbsp;
              </span>
              Compare different banks and financial institutions to choose one
              that offers favorable interest rates and terms.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Submit Application:&nbsp;&nbsp;
              </span>
              Fill out the application form online or in person.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Provide Required Documents:&nbsp;&nbsp;
              </span>
              <p
                className="pt-2 pb-2"
                style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
              >
                Submit your KYC documents (Passport, Visa, Employment details),
                income proof, property documents, and bank statements.
              </p>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Loan Processing:&nbsp;&nbsp;
              </span>
              The lender will evaluate your application, verify documents, and
              assess your eligibility.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Approval & Disbursement:&nbsp;&nbsp;
              </span>
              Once approved, the loan is disbursed to your account, and you can
              begin repaying as per the agreed terms.
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Common Uses of NRI Home Loans:
        </div>

        <div
          style={{
            marginTop: 16,
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Property Purchase:&nbsp;&nbsp;
              </span>
              Finance the purchase of a flat, house, or land in India.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Construction of Property:&nbsp;&nbsp;
              </span>
              If you already own land, you can use the loan to construct a
              property.
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                <LuDot />
              </span>
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                Renovation/Extension:&nbsp;&nbsp;
              </span>
              Fund home improvements, repairs, or extensions to your existing
              property in India.
            </div>
          </div>

          {/* tax-benefits */}
          <div id="tax-benefits">
            <div
              className=""
              style={{
                marginTop: 30,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Tax Benefits for NRI Home Loan Borrowers:
            </div>
            <Typography
              sx={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                color: "#1F1F1F",
              }}
            >
              NRIs who take home loans are eligible for tax benefits, such as:
            </Typography>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <Typography
                    className=" d-inline"
                    sx={{
                      fontFamily: "'Archivo',sans-serif",
                      fontSize: "16px",
                      color: "#1F1F1F",
                      fontWeight: 600,
                    }}
                  >
                    Deduction for Principal Repayment:&nbsp;&nbsp;
                  </Typography>
                  Under Section 80C, tax deduction is allowed for principal
                  repayment of the home loan.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <Typography
                    className=" d-inline"
                    sx={{
                      fontFamily: "'Archivo',sans-serif",
                      fontSize: "16px",
                      color: "#1F1F1F",
                      fontWeight: 600,
                    }}
                  >
                    Deduction for Interest Paid:&nbsp;&nbsp;
                  </Typography>
                  Under Section 24(b), interest on home loan repayment is
                  tax-deductible up to ₹2 lakh per year.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: 30,
            color: "#1F1F1F",
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Frequently Asked Questions (FAQs){" "}
        </div>
        <div
          className="accordion "
          style={{
            marginTop: 9,
            backgroundColor: "#F6F6F6",
            display: "flex",
            flexDirection: "column",
            gap: 14,
          }}
        >
          <Accordion
            defaultExpanded={true}
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              // expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                backgroundColor: "#F6F6F6",
                fontWeight: 700,
              }}
            >
              Can NRIs apply for a home loan in India?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, NRIs can apply for home loans in India. Many banks offer
              specialized NRI home loans with flexible terms and competitive
              interest rates.
            </AccordionDetails>
          </Accordion>
          <Accordion
            backgroundColor="#F6F6F6"
            style={{
              border: "1px solid #C8C8C8",
              borderRadius: 12,
              backgroundColor: "#F6F6F6",
            }}
          >
            <AccordionSummary
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              What is the maximum loan tenure for NRI Home Loans?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Most lenders provide a loan tenure of up to 20-25 years. However,
              the tenure may depend on your age and the loan amount.
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              What is the process for repaying the home loan?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              NRI home loan repayment can be made from your NRE (Non-Resident
              External) or NRO (Non-Resident Ordinary) accounts in India, or
              through remittances from abroad.
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
          <Accordion style={{ backgroundColor: "#F6F6F6", borderRadius: 12 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
              style={{
                backgroundColor: "#F6F6F6",
                borderRadius: 12,
                fontWeight: 700,
              }}
            >
              Can NRIs avail of tax deductions on home loans?
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F6F6F6" }}>
              Yes, NRIs can avail of tax deductions on both the principal
              repayment (under Section 80C) and the interest paid (under Section
              24) for home loans.
            </AccordionDetails>
            <AccordionActions></AccordionActions>
          </Accordion>
        </div>
        <div
          className="increase-margines"
          style={{
            backgroundColor: "#E7E4F9",
            borderRadius: 24,
            padding: 40,
            marginTop: 40,
            marginBottom: 40,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              backgroundColor: "transparent",
            }}
          >
            <p
              className="minutes"
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 32,
                fontWeight: 600,
              }}
            >
              Check Your Eligibility in Minutes!
            </p>
            <p
              style={{
                color: "#59266D",
                backgroundColor: "transparent",
                fontFamily: "Archivo",
                fontSize: 18,
                fontWeight: 400,
                lineHeight: 1.2,
              }}
            >
              Get personalized loan options based on your income, current EMI,
              and credit score. Quick, easy,
              <br className="display-none" /> and tailored just for you—apply
              now to see what you qualify for!
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                marginTop: 30,
                display: "flex",
                flexDirection: "row",
                gap: 24,
              }}
            >
              <a
                href="https://minemi.ai/home-loan/check-eligibility"
                style={{
                  backgroundColor: "#59266D",
                  color: "white",
                  borderRadius: 8,
                  padding: 10,
                  paddingLeft: 50,
                  paddingRight: 50,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 16,
                }}
              >
                Check Eligiblity Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NRIHomeLoan;

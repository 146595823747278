import React from "react";
import "./Serve.css";
import Accordion from "react-bootstrap/Accordion";
import { FaPlus } from "react-icons/fa";

const Serve = () => {
  const accordionData = [
    {
      title: "A Quick Guide to Loan Consolidation",
      content: (
        <p className="m-0 bg-transparent">
          Loan consolidation allows you to combine multiple debts into a single
          loan, simplifying repayment and potentially reducing your overall
          interest costs. Your eligibility for loan consolidation depends on
          factors such as your credit score, repayment history, total
          outstanding debt, and income stability. Typically, a credit score of
          700+ and a strong financial track record improve your chances of
          approval. At Minemi, we provide a fast and hassle-free way to check
          your loan consolidation eligibility instantly—without affecting your
          credit score. By consolidating your loans, you can streamline
          payments, secure better interest rates, and regain control over your
          finances. Know your eligibility upfront and take the first step
          towards a stress-free financial future!
        </p>
      ),
    },
    {
      title: "Features and Benefits of Loan Consolidation",
      content: (
        <div className="bg-transparent">
          Consolidating your existing loans into a single loan can help you
          manage your finances more efficiently and reduce your overall
          repayment burden.
          <p className="my-2 bg-transparent">Key features include:</p>
          <p className="my-1 bg-transparent">
            • Simplified Repayments – Manage a single loan instead of multiple
            EMIs.
          </p>
          <p className="m-0 bg-transparent">
            • Lower Interest Rates – Potential savings on interest costs.
          </p>
          <p className="my-1 bg-transparent">
            • Flexible Tenure Options to match your financial goals.
          </p>
          <p className="m-0 bg-transparent">
            • One Convenient EMI instead of multiple payments.
          </p>
        </div>
      ),
    },
    {
      title: "Why Check Loan Consolidation Eligibility? ",
      content: (
        <p className="m-0 bg-transparent">
          Checking your loan consolidation eligibility before applying helps you
          assess your chances of approval and avoids multiple hard credit
          inquiries that could lower your credit score. At Minemi, you can check
          your eligibility instantly—without impacting your credit score.
        </p>
      ),
    },
    {
      title: "When to Consider Loan Consolidation?",
      content: (
        <p className="m-0 bg-transparent">
          Loan consolidation is a great option when managing multiple loans
          becomes difficult or when high interest rates are increasing your
          financial burden. If you have several EMIs, consolidating them into a
          single loan can simplify repayments and reduce stress. It’s also
          beneficial if your credit score has improved, allowing you to secure
          lower interest rates and better terms. If you're struggling with high
          monthly payments, extending the loan tenure through consolidation can
          ease your financial strain. Before making a decision, evaluate the
          total savings, interest rates, and any associated fees. Use Minemi’s
          eligibility checker to find the best consolidation plan for your
          needs. transfer eligibility checker to find the best options for you.
        </p>
      ),
    },
    {
      title: "Documents Required for Loan Consolidation",
      content: (
        <div className="bg-transparent">
          When applying for loan consolidation, you will need to provide the
          following documents:
          <p className="my-1 bg-transparent">
            • Identity Proof: PAN Card, Aadhaar Card, Passport
          </p>
          <p className="m-0 bg-transparent">
            • Address Proof: Utility bills, Aadhaar, or rental agreements
          </p>
          <p className="my-1 bg-transparent">
            • Income Proof: Latest salary slips or bank statements (for
            salaried), IT returns or audited financials (for self-employed)
          </p>
          <p className="my-1 bg-transparent">
            •Existing Loan Details: Loan sanction letter, latest EMI receipts,
            and loan statements
          </p>
          <p className="m-0 bg-transparent">
            •Additional Documents: Any lender-specific requirements, such as
            property documents for secured loans
          </p>
        </div>
      ),
    },
    {
      title: " Frequently Asked Questions (FAQs) ",
      content: (
        <div className="m-0 bg-transparent">
          <p className="m-0 bg-transparent">
            1.Need help managing multiple loans? Yes, loan consolidation can
            simplify debt management by combining multiple loans into a single
            payment, often with a lower interest rate. However, it's essential
            to evaluate fees, repayment terms, and total savings to ensure it
            aligns with your financial goals.
          </p>
          <p className="my-2 bg-transparent">
            2.Will loan consolidation always save me money? Not necessarily.
            While consolidation can lower interest rates and monthly payments,
            factors such as loan tenure and fees may impact overall savings.
            It’s important to compare total costs before proceeding.
          </p>
          <p className="m-0 bg-transparent">
            3.Can I consolidate different types of loans? Yes, many unsecured
            debts, such as personal loans, credit card balances, and student
            loans, can be consolidated. However, secured loans like mortgages
            usually require different refinancing options.
          </p>
          <p className="my-2 bg-transparent">
            4.How does loan consolidation affect my credit score? Applying for a
            consolidation loan may result in a temporary dip in your credit
            score due to a hard inquiry. However, making timely payments on the
            new loan can improve your credit score over time.
          </p>
          <p className="m-0 bg-transparent">
            5.What happens if I miss a payment on my consolidated loan? Missing
            a payment can lead to late fees, increased interest rates, and a
            negative impact on your credit score. Setting up automatic payments
            can help you stay on track and avoid penalties.
          </p>
        </div>
      ),
    },
  ];
  return (
    <>
      <div id="serve">
        <div className="contentCon">
          <div id="header-con">
            <p className="bg-transparent">We swear to serve you with ...</p>
          </div>
          <div id="content" className="bg-transparent">
            <div className="d-flex flex-wrap w-100 bg-transparent">
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/loan_eligibility.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/no_empact.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Impact on credit Score</p>
              </div>

              <div className="plus-icon-con second-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/interest_rate.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Best Interest Rate Offer</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/no_calls.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Calls From Us</p>
              </div>

              <div className="plus-icon-con fourth-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Data Security Guarantee</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>
            </div>

            <div id="line-breaker"></div>

            <div className="accordion-con bg-transparent">
              <Accordion className="bg-transparent">
                {accordionData.map((item, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body
                      style={{
                        visibility: "visible",
                        backgroundColor: "transparent",
                      }}
                    >
                      {item.content}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Serve;

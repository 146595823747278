import {
  CheckCircle,
  TrendingUp,
  Clock,
  XCircle,
  Target,
  Shield,
  UserCheck,
  List,
  BarChart,
  CreditCard,
  DollarSign,
  Lock,
} from "react-feather";
import "./BadCredits.css";

const Testimonial = ({ quote, author, location }) => (
  <div className="testimonial">
    <p className="quote">"{quote}"</p>
    <p className="author">
      — {author}, {location}
    </p>
  </div>
);

function BadCredits() {
  return (
    <div className="app">
      <div className="bad_credit_container">
        {/* Hero Section */}
        <section className="hero ">
          <header className="bg-transparent">
            <h1>Check Credit Card Eligibility Before Applying Online</h1>
            <p className="bad_credits_para">
              Check eligibility, limits, and recommendation to get the card of
              your choice.
            </p>
          </header>

          <div className="features bg-transparent">
            {[
              { icon: CheckCircle, text: "Know your chances before applying" },
              { icon: TrendingUp, text: "Get personalized card options" },
              {
                icon: Clock,
                text: "Avoid rejections and delays in your application",
              },
            ].map((item, index) => (
              <div key={index} className="feature-item">
                <item.icon className="icon" />
                <p>{item.text}</p>
              </div>
            ))}
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center bg-transparent">
            <button className="cta-button">Check My Eligibility Now</button>

            <div className="info-banner">
              100% Free | No Credit Score Impact | Trusted by Thousands
            </div>
          </div>
        </section>

        {/* Why Check Eligibility Section */}
        <section className="why-check-eligibility bg-transparent">
          <h2>Why Check Your Credit Card Eligibility Before Applying?</h2>
          <div className="content">
            <div className="reasons">
              {[
                {
                  icon: XCircle,
                  title: "Avoid Rejections",
                  description:
                    "Know your chances before applying to avoid rejections that hurt your credit score.",
                },
                {
                  icon: Clock,
                  title: "Save Time",
                  description:
                    "No more wasted time applying for cards that don't match your profile.",
                },
                {
                  icon: Target,
                  title: "Get Tailored Offers",
                  description:
                    "Find credit cards suited to your income, credit score, and spending habits.",
                },
                {
                  icon: Shield,
                  title: "Peace of Mind",
                  description:
                    "Apply confidently, knowing you're eligible for the product.",
                },
              ].map((item, index) => (
                <div key={index} className="reason-item">
                  <div className="icon-wrapper">
                    <item.icon className="icon" />
                  </div>
                  <div>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="check-icon">
              <div className="circle">
                <CheckCircle className="icon" />
              </div>
            </div>
          </div>
          <button className="cta-button">Check My Eligibility Now</button>
        </section>

        {/* Why Eligibility Checks Are Crucial Section */}
        <section className="why-crucial">
          <h2>Why Eligibility Checks Are Crucial for Your Financial Success</h2>
          <p className="bad_credits_para">
            Did you know 80% of credit card applications are rejected? Be smart
            – avoid rejection with tailored recommendations based on your
            profile.
          </p>
        </section>

        {/* How MinEMI Makes Eligibility Checks Easy Section */}
        <section className="how-minemi-works">
          <h2>How MinEMI Makes Eligibility Checks Easy</h2>
          <div className="steps">
            {[
              {
                icon: UserCheck,
                title: "Step 1",
                description:
                  "Share your details and consent to securely fetch your credit score.",
              },
              {
                icon: List,
                title: "Step 2",
                description:
                  "View a personalized list of credit cards suited to your financial profile.",
              },
              {
                icon: BarChart,
                title: "Step 3",
                description:
                  "Compare benefits, interest rates, and eligibility criteria.",
              },
              {
                icon: CreditCard,
                title: "Step 4",
                description: "Choose your card and apply seamlessly.",
              },
            ].map((item, index) => (
              <div key={index} className="step-item">
                <div className="icon-wrapper">
                  <item.icon className="icon" />
                </div>
                <h3>{item.title}</h3>
                <p className="bad_credits_para">{item.description}</p>
              </div>
            ))}
          </div>
          <button className="cta-button">Check My Eligibility Now</button>
        </section>

        {/* Why Check Eligibility with MinEMI? Section */}
        <section className="why-minemi">
          <h2>Why Check Eligibility with MinEMI?</h2>
          <div className="benefits">
            {[
              {
                icon: CheckCircle,
                title: "No Impact on Credit Score",
                description:
                  "Eligibility checks won't affect your credit score.",
              },
              {
                icon: DollarSign,
                title: "Completely Free",
                description:
                  "We don't charge users—our revenue comes from banks.",
              },
              {
                icon: Target,
                title: "Personalized Recommendations",
                description:
                  "Get tailored offers based on your credit score and profile.",
              },
              {
                icon: Lock,
                title: "Secure & Private",
                description:
                  "Your data is protected with industry-grade encryption.",
              },
            ].map((item, index) => (
              <div key={index} className="benefit-item">
                <div className="icon-wrapper">
                  <item.icon className="icon" />
                </div>
                <div>
                  <h3>{item.title}</h3>
                  <p className="bad_credits_para">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="trust-banner">
            <Shield className="icon" />
            <span>Trusted by 50,000+ Happy Customers</span>
          </div>
          <button className="cta-button">Check My Eligibility Now</button>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials">
          <h2>See How MinEMI Helps Users Find the Right Credit Cards</h2>
          <div className="testimonial-list">
            <Testimonial
              quote="MinEMI showed me exactly which cards I was eligible for, saving me from multiple rejections."
              author="Priya S."
              location="Bangalore"
            />
            <Testimonial
              quote="I didn't know which card suited me. MinEMI helped me find the perfect cashback card!"
              author="Rajesh M."
              location="Mumbai"
            />
            <Testimonial
              quote="Checking my eligibility was fast and accurate—I applied confidently and got approved."
              author="Ankita T."
              location="Delhi"
            />
          </div>
          <button className="cta-button">Check My Eligibility Now</button>
        </section>

        {/* The Smarter Way to Apply for Credit Cards Section */}
        <section className="smarter-way">
          <h2>The Smarter Way to Apply for Credit Cards</h2>
          <div className="content">
            <div className="info">
              <p className="bad_credits_para">
                Avoid the risk of applying blindly and getting rejected.
              </p>
              <p className="bad_credits_para">
                MinEMI shows you cards with the best rewards, low fees, and
                better interest rates.
              </p>
              <p className="quote bad_credits_para">
                "Why take chances when you can check eligibility first and save
                yourself the hassle?"
              </p>
              <button className="cta-button">Check Your Eligibility Now</button>
            </div>
            <div className="comparison">
              <h3>Comparison: With vs Without Eligibility Check</h3>
              <div className="comparison-grid">
                <div>
                  <h4>With MinEMI</h4>
                  <ul>
                    {[
                      "Higher approval chances",
                      "Personalized card offers",
                      "Better rewards and rates",
                      "Faster approval process",
                    ].map((item, index) => (
                      <li key={index}>
                        <CheckCircle className="icon" />
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h4>Without MinEMI</h4>
                  <ul>
                    {[
                      "Risk of rejection",
                      "Generic card options",
                      "Potentially higher fees",
                      "Longer approval times",
                    ].map((item, index) => (
                      <li key={index}>
                        <XCircle className="icon" />
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <button className="cta-button">Check My Eligibility Now</button>
        </section>

        {/* Ready to Get Started? Section */}
        <section className="get-started">
          <h2>Ready to Get Started?</h2>
          <h3>Check Your Eligibility in 2 Minutes!</h3>
          <div className="benefits">
            <ul>
              <li>
                <CheckCircle className="icon" />
                <span>No impact on your credit score.</span>
              </li>
              <li>
                <CheckCircle className="icon" />
                <span>100% free and secure.</span>
              </li>
              <li>
                <CheckCircle className="icon" />
                <span>
                  Personalized recommendations tailored to your profile.
                </span>
              </li>
            </ul>
          </div>
          <button className="cta-button">
            Check My Credit Card Eligibility Now
          </button>
          <div className="info-banner">
            No Impact on Credit Score | Trusted by Thousands | Secure Process
          </div>
        </section>

        {/* FAQ Section */}
        <section className="faq">
          <h2>Got Questions About Credit Card Eligibility?</h2>
          <h3>We've Got Answers!</h3>
          <div className="faq-list">
            {[
              {
                question:
                  "What are the basic eligibility criteria for a credit card?",
                answer:
                  "You must be at least 18 years old, have a steady income, and meet the credit score requirements.",
              },
              {
                question: "Can I apply if I don't have a credit history?",
                answer:
                  "Yes, banks offer basic credit cards for first-time applicants with no credit history.",
              },
              {
                question:
                  "Does checking my eligibility affect my credit score?",
                answer:
                  "No, Minemi's eligibility check uses a soft inquiry that doesn't impact your score.",
              },
              {
                question: "What documents do I need to apply?",
                answer:
                  "You'll need proof of identity, address, and income (e.g., PAN card, Aadhaar, salary slips).",
              },
              {
                question: "Can I reapply if my application is denied?",
                answer:
                  "Yes, you can reapply after addressing the reasons for denial, like improving your credit score.",
              },
              {
                question: "How do I check my eligibility on Minemi?",
                answer:
                  "Use the online eligibility checker for instant, tailored results based on your profile.",
              },
            ].map((item, index) => (
              <details key={index} className="faq-item">
                <summary>{item.question}</summary>
                <p className="bad_credits_para">{item.answer}</p>
              </details>
            ))}
          </div>
          <button className="cta-button">Check My Eligibility Now</button>
        </section>

        {/* Final CTA Section */}
        <section className="final-cta">
          <h2>Ready to prepare for a reject proof application?</h2>
          <p className="bad_credits_para">
            Join thousands of successful users who trust Minemi to guide their
            financial journey.
          </p>
        </section>
      </div>
    </div>
  );
}

export default BadCredits;

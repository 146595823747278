import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaRupeeSign } from "react-icons/fa";
import "./LoanEstimator.css";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ColorRing } from "react-loader-spinner";

const LoanEstimator = () => {

  const [loader, setLoader] = useState(false);
  const [loanAmount, setLoanAmount] = useState("");
  const [maxTenure, setMaxTenure] = useState(0);
  const [minTenure, setMinTenure] = useState(0);
  const [maxLoan, setMaxLoan] = useState(0);
  const [tenure, setTenure] = useState(0);
  const [interestRate, setInterestRate] = useState(12);
  const [monthlyEmi, setMonthlyEmi] = useState(0);
  const [formShow, setFormShow] = useState(false);

  const handleLoanInputChange = (event) => {
    const newValue = event.target.value;
    const plainNumber = parseFloat(newValue.replace(/,/g, ""));

    if (plainNumber >= 0 && plainNumber <= maxLoan) {
      setLoanAmount(plainNumber);
      calculateEMI(plainNumber, "loanAmount");
    } else if (newValue === "") {
      setLoanAmount("");
      calculateEMI(1, "loanAmount");
    }
  };

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    if (newValue >= minTenure && newValue <= maxTenure) {
      setTenure(newValue);
    }
    calculateEMI(minTenure, "tenure");
  };

  const handleRangeChange = (value, name) => {
    if (name === "tenure") {
      setTenure(value);
    }

    if (name === "loanAmount") {
      setLoanAmount(value);
    }

    calculateEMI(value, name);
  };

  const calculateEMI = async (value, name) => {
    const loanType = process.env.REACT_APP_LOAN_TYPE_CL;
    const apiUrl = process.env.REACT_APP_URL + "/loanEstimator";
    const eligibleData = localStorage.getItem("eligibleData");
    const datas = JSON.parse(eligibleData);

    // check datas


    let nTenure = 0;
    let loanAmt = 0;
    if (name === "tenure") {
      nTenure = value;
    } else {
      nTenure = tenure;
    }

    if (name === "loanAmount") {
      loanAmt = value;
    } else {
      loanAmt = loanAmount;
    }
    const fType = datas.data.eligibleLoan.savingopprtunity.formula_type;
    const fTenure = fType === "hl" ? 360 : 84;
    const cbliScore = Number(datas.data.cbil.score);
    const lAmt = datas.data.eligibleLoan.amount;

    // check


    const formData = new FormData();
    formData.append(
      "loanAmount",
      value === null && name === null ? lAmt : loanAmt
    );
    formData.append(
      "tenure",
      value === null && name === null ? fTenure : nTenure
    );
    formData.append("loanType", loanType);
    formData.append("cibilScore", cbliScore);

    try {
      setLoader(true);
      const res = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setLoader(false);
      // setLoanAmount(res.data.data.estimatorValue);
      setInterestRate(res.data.data.estimatorValue.interestRate);
      setMonthlyEmi(res.data.data.estimatorValue.montlyEmi);
    } catch (err) {
    }
  };

  useEffect(() => {
    const isFid = sessionStorage.getItem("fId");
    if (isFid !== null) {
      const eligibleData = localStorage.getItem("eligibleData");
      const data = JSON.parse(eligibleData);
      const findType = data.data.eligibleLoan.savingopprtunity.formula_type;
      const findTenure = findType === "hl" ? 360 : 84;

      if (
        data.status === 2 ||
        data.status === 0 ||
        data.data.eligibleLoan.amount < 0
      ) {
        setFormShow(true);
      }

      setLoanAmount(data.data.eligibleLoan.amount);
      setTenure(findTenure);
      setMaxLoan(data.data.eligibleLoan.amount);
      setMaxTenure(findTenure);
      setMinTenure(data.data.eligibleLoan.minimum_tenure);
      calculateEMI(null, null);
    }
  }, []);

  return (
    <>
      {!formShow && (
        <div id="estimator" className="bg-transparent">
          <div className="headerCon">
            <p className="header m-0 p-0 bg-transparent">Car Loan Estimator</p>
          </div>

          <div className="contentCon">
            <p className="title  p-0">
              Adjust your loan amount and tenure to estimate your EMI
            </p>
            <div className="row">
              <div className="col-12 col-md-7 range-con">
                <div className="bg-transparent">
                  <div className="d-flex justify-content-between align-items-center bg-transparent">
                    <p className="label p-0 m-0 bg-transparent">Loan amount</p>
                    <div className="d-flex align-items-center bg-transparent">
                      <InputGroup className="loan-input-con bg-transparent">
                        <InputGroup.Text className="loan-input-icon">
                          <FaRupeeSign className="loan-icon" />
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="45,000"
                          className="loan-amt"
                          type="text"
                          name="loan-amount"
                          value={new Intl.NumberFormat("en-IN").format(
                            loanAmount
                          )}
                          onChange={handleLoanInputChange}
                        />
                      </InputGroup>
                      <p className="loan-suffix p-0 mb-0 bg-transparent">
                        (In Rupees)
                      </p>
                    </div>
                  </div>
                  <div id="range" className="p-0 bg-transparent">
                    <Form.Range
                      min="45000"
                      max={maxLoan}
                      step="5000"
                      value={loanAmount}
                      onChange={(event) =>
                        handleRangeChange(event.target.value, "loanAmount")
                      }
                      className="custom-range"
                    />
                  </div>
                </div>

                <div className="breaker"></div>

                <div className="bg-transparent">
                  <div className="d-flex justify-content-between align-items-center bg-transparent">
                    <p className="label p-0 m-0 bg-transparent">Loan Tenure</p>
                    <div className="d-flex align-items-center bg-transparent ">
                      <Form.Control
                        placeholder="124"
                        className="loan-month"
                        type="number"
                        name="loan-month"
                        value={tenure}
                        onChange={handleInputChange}
                      />
                      <p className="loan-suffix p-0 mb-0 bg-transparent">
                        (In Months)
                      </p>
                    </div>
                  </div>
                  <div id="range" className="p-0 bg-transparent">
                    <Form.Range
                      min={minTenure}
                      max={maxTenure}
                      step="1"
                      value={tenure}
                      onChange={(event) =>
                        handleRangeChange(event.target.value, "tenure")
                      }
                      className="custom-range"
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-5 emi-con ">
                <div className="w-100 bg-transparent">
                  <p className="title">Your Monthly EMI</p>
                  <div className="input-con m-0 ">
                    <p className="mb-0 p-0 bg-transparent">
                      <FaRupeeSign className="emi-icon bg-transparent" />
                    </p>
                    {!loader ? (
                      <p className="emi-amount m-0 bg-transparent">
                        {new Intl.NumberFormat("en-IN").format(monthlyEmi)}
                      </p>
                    ) : (
                      <ColorRing
                        visible={loader}
                        height="30"
                        width="30"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{ backgroundColor: "transparent" }}
                        wrapperClass="color-ring-wrapper"
                        colors={[
                          "#846CA6",
                          "#846CA6",
                          "#846CA6",
                          "#846CA6",
                          "#846CA6",
                          "#846CA6",
                        ]}
                      />
                    )}
                  </div>
                  <p className="emi-text bg-transparent">(In Rupees)</p>
                  <div className="d-flex justify-content-between bg-transparent">
                    <p className="mb-0 interest-rate bg-transparent">
                      Annual Interest Rate
                    </p>
                    <p className="mb-0 interest-per">{interestRate}%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      )}
    </>
  );
};

export default LoanEstimator;

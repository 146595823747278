import { useEffect, useMemo, useState } from "react";
import { CheckCircle } from "lucide-react";
export function LoanPredictorWidget({ name }) {
  const [loanAmount, setLoanAmount] = useState(5000000);
  const [creditScore, setCreditScore] = useState(750);
  const [employmentType, setEmploymentType] = useState("salaried");
  const [data, setData] = useState([]);

  const getApprovalChance = () => {
    let baseScore = 0;
    if (creditScore >= 750) baseScore = 85;
    else if (creditScore >= 700) baseScore = 65;
    else if (creditScore >= 650) baseScore = 40;
    else baseScore = 20;
    const amountFactor =
      loanAmount <= 1000000 ? 5 : loanAmount >= 1000000 ? -5 : 0;

    const employmentFactor = employmentType === "salaried" ? 5 : -5;

    const finalScore = Math.min(
      95,
      Math.max(15, baseScore + amountFactor + employmentFactor)
    );

    if (finalScore >= 80) return "High (80-95%)";
    if (finalScore >= 60) return "Medium (60-80%)";
    if (finalScore >= 40) return "Low (40-60%)";
    return "Very Low (Below 40%)";
  };

  const getApprovalColor = () => {
    if (getApprovalChance().startsWith("High")) return "text-green-600";
    if (getApprovalChance().startsWith("Medium")) return "text-yellow-600";
    if (getApprovalChance().startsWith("Low")) return "text-orange-500";
    return "text-red-600";
  };
  const kotakData = useMemo(
    () => ({
      h2: " Predict Your Kotak Bank Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your Kotak Mahindra Bank Home Loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score meets Kotak Bank's preferred criteria",
      type: "Salaried applicants have higher approval rates with Kotak Bank",
      report: "Get Detailed Approval Analysis",
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      h2: " Predict Your Loan Approval Chances",
      para: " Use our quick predictor tool to estimate your Bank of Baroda Home Loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "SThis loan amount has higher approval rates",
      report: "Get Detailed Approval Analysis",
    }),
    []
  );
  const idfcData = useMemo(
    () => ({
      h2: " Predict Your Home Loan Approval Chances",
      para: " Use our quick predictor tool to estimate your IDFC FIRST Bank Home Loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for home loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Analysis",
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      h2: "Predict Your Home Loan Approval Chances",
      para: "  Use our quick predictor tool to estimate your HDFC Bank Home Loan approval probability",
      changes: "Approval Chance:",
      score: "Your credit score is excellent for home loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Analysis",
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      h2: "Predict Your Home Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your SBI Home Loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for home loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Analysis",
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      h2: " Predict Your Home Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your Axis Bank Home Loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for home loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Analysis",
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      h2: "Predict Your Home Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your ICICI Bank Home Loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for home loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Analysis",
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      h2: " Predict Your Home Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your Punjab National Bank Home Loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for home loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Analysis",
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      h2: "Predict Your Home Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your IndusInd Bank Home Loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for home loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      h2: " Predict Your Home Loan Approval Chances",
      para: " Use our quick predictor tool to estimate your Tata Housing Finance Home Loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for home loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      h2: " Predict Your YES Bank Home Loan Approval Chances",
      para: "Our AI-powered predictor uses YES Bank's actual approval criteria to estimate your chances",
      changes: " Approval Chance:",
      score: "Your credit score meets YES Bank's preferred criteria",
      type: "Ready properties have higher approval rates with YES Bank",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      h2: " Predict Your Union Bank Home Loan Approval Chances",
      para: "Our AI-powered predictor uses Union Bank's actual approval criteria to estimate your chances",
      changes: " Approval Chance:",
      score: "Your credit score meets Union Bank's preferred criteria",
      type: "Ready properties have higher approval rates with Union Bank",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      h2: " Predict Your Bajaj Finance Home Loan Approval Chances",
      para: "Our AI-powered predictor uses Bajaj Finance's actual approval criteria to estimate your chances",
      changes: " Approval Chance:",
      score: "Your credit score meets Bajaj Finance's preferred criteria",
      type: "Ready properties have higher approval rates with Bajaj Finance",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      h2: " Predict Your Federal Bank Home Loan Approval Chances",
      para: "Chances Our AI-powered predictor uses Federal Bank's actual approval criteria to estimate your chances",
      changes: " Approval Chance:",
      score: "Your credit score meets Federal Bank's preferred criteria",
      type: "Ready properties have higher approval rates with Federal Bank",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      h2: "Predict Your Canara Bank Home Loan Approval Chances",
      para: "Our AI-powered predictor uses Canara Bank's actual approval criteria to estimate your chances",
      changes: " Approval Chance:",
      score: "Your credit score meets Canara Bank's preferred criteria",
      type: "Ready properties have higher approval rates with Canara Bank",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      h2: "Predict Your Bank of India Home Loan Approval Chances",
      para: "Our AI-powered predictor uses Bank of India's actual approval criteria to estimate your chances",
      changes: " Approval Chance:",
      score: "Your credit score meets Bank of India's preferred criteria",
      type: "Ready properties have higher approval rates with Bank of India",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      h2: " Predict Your RBL Bank Home Loan Approval Chances",
      para: "Our AI-powered predictor uses RBL Bank's actual approval criteria to estimate your chances",
      changes: " Approval Chance:",
      score: "Your credit score meets RBL Bank's preferred criteria",
      type: "Ready properties have higher approval rates with RBL Bank",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const ltData = useMemo(
    () => ({
      h2: " Predict Your L&T Finance Home Loan Approval Chances",
      para: "Our AI-powered predictor uses L&T Finance's actual approval criteria to estimate your chances",
      changes: " Approval Chance:",
      score: "Your credit score meets L&t Bank's preferred criteria",
      type: "Ready properties have higher approval rates with L&T Bank",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const idbiData = useMemo(
    () => ({
      h2: " Predict Your IDBI Home Loan Approval Chances",
      para: " Use our quick predictor tool to estimate your IDBI Home Loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score meets IDBI Bank's preferred criteria",
      type: "Ready properties have higher approval rates with IDBI Bank",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const lichousingData = useMemo(
    () => ({
      h2: " Predict Your LIC Housing Finance Home Loan Approval Chances",
      para: " Use our quick predictor tool to estimate your LIC Housing Finance Home Loan approval probability",
      changes: " Approval Chance:",
      score:
        "Your credit score meets LIC Housing Finance Bank's preferred criteria",
      type: "Ready properties have higher approval rates with LIC Housing Finance Bank",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const icicihousingData = useMemo(
    () => ({
      h2: " Predict Your ICICI Home Finance Home Loan Approval Chances",
      para: " Use our quick predictor tool to estimate your ICICI Home Finance Home Loan approval probability",
      changes: " Approval Chance:",
      score:
        "Your credit score meets ICICI Home Finance Bank's preferred criteria",
      type: "Ready properties have higher approval rates with ICICI Home Finance Bank",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const pnbhousingData = useMemo(
    () => ({
      h2: " Predict Your PNB Home Finance Home Loan Approval Chances",
      para: " Use our quick predictor tool to estimate your PNB Home Finance Home Loan approval probability",
      changes: " Approval Chance:",
      score:
        "Your credit score meets PNB Home Finance Bank's preferred criteria",
      type: "Ready properties have higher approval rates with PNB Home Finance Bank",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const hdfclnthousingData = useMemo(
    () => ({
      h2: " Predict Your Home Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your HDFC Ltd. home loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const sbihomehousingData = useMemo(
    () => ({
      h2: " Predict Your Home Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your SBI home loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const adityahomehousingData = useMemo(
    () => ({
      h2: " Predict Your Home Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your Aditya Birla Finance home loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );

  useEffect(() => {
    if (name === "checkkotakhomeloanbankstatus") {
      setData(kotakData);
    } else if (name === "checkbobhomeloanbankstatus") {
      setData(bobData);
    } else if (name === "checkidfchomeloanbankstatus") {
      setData(idfcData);
    } else if (name === "checkhdfchomeloanbankstatus") {
      setData(hdfcData);
    } else if (name === "checksbihomeloanbankstatus") {
      setData(sbiData);
    } else if (name === "checkaxishomeloanbankstatus") {
      setData(axisData);
    } else if (name === "checkicicihomeloanbankstatus") {
      setData(iciciData);
    } else if (name === "checkpunjabhomeloanbankstatus") {
      setData(punjabData);
    } else if (name === "checkinduslndhomeloanbankstatus") {
      setData(induslndData);
    } else if (name === "checktatahomeloanbankstatus") {
      setData(tataData);
    } else if (name === "checkyeshomeloanbankstatus") {
      setData(yesData);
    } else if (name === "checkunionhomeloanbankstatus") {
      setData(unionData);
    } else if (name === "checkbajajhomeloanbankstatus") {
      setData(bajajData);
    } else if (name === "checkfederalhomeloanbankstatus") {
      setData(federalData);
    } else if (name === "checkcanarahomeloanbankstatus") {
      setData(canaraData);
    } else if (name === "checkboihomeloanbankstatus") {
      setData(boiData);
    } else if (name === "checkrblhomeloanbankstatus") {
      setData(rblData);
    } else if (name === "checklthomeloanbankstatus") {
      setData(ltData);
    } else if (name === "checkidbihomeloanbankstatus") {
      setData(idbiData);
    } else if (name === "checklichousinghomeloanbankstatus") {
      setData(lichousingData);
    } else if (name === "checkicicihousinghomeloanbankstatus") {
      setData(icicihousingData);
    } else if (name === "checkpnbhousinghomeloanbankstatus") {
      setData(pnbhousingData);
    } else if (name === "checkhdfcltdhomeloanbankstatus") {
      setData(hdfclnthousingData);
    } else if (name === "checksbihousinghomeloanbankstatus") {
      setData(sbihomehousingData);
    } else if (name === "checkadityahousinghomeloanbankstatus") {
      setData(adityahomehousingData);
    } else {
      setData([]);
    }
  }, [
    name,
    axisData,
    bobData,
    hdfcData,
    iciciData,
    idfcData,
    kotakData,
    sbiData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
    ltData,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    hdfclnthousingData,
    sbihomehousingData,
    adityahomehousingData,
  ]);

  return (
    <div className="md:w-4/5 sd:w-full mx-auto bg-transparent">
      <div className="text-center mb-4 bg-transparent">
        <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple bg-transparent">
          {data.h2}
        </h2>
        <p className="text-dark-grey mt-1 text-sm text-center bg-transparent">
          {data.para}
        </p>
      </div>

      <div
        className=" mx-2  p-6 rounded-lg border border-gray-200 shadow-sm"
        style={{ backgroundColor: "#ebe8fb" }}
      >
        <div className="space-y-5 bg-transparent">
          <div className="bg-transparent">
            <label
              htmlFor="loan-amount"
              className="block text-sm font-medium text-dark-grey mb-2 bg-transparent"
            >
              Loan Amount (₹)
            </label>
            <input
              type="range"
              id="loan-amount"
              min="5000000"
              max="50000000"
              step="5000000"
              value={loanAmount}
              onChange={(e) => setLoanAmount(Number.parseInt(e.target.value))}
              className="w-full h-2 bg-white rounded-lg appearance-none cursor-pointer"
            />
            <div className="flex justify-between mt-2 bg-transparent">
              <span className="text-xs text-dark-grey bg-transparent">
                ₹50L
              </span>
              <span className="text-sm font-medium text-primary-purple bg-transparent">
                ₹
                {loanAmount < 1000000
                  ? (loanAmount / 10000).toFixed(0) + "k"
                  : loanAmount < 10000000
                  ? (loanAmount / 100000).toFixed(1) + "L"
                  : (loanAmount / 10000000).toFixed(2) + "Cr"}{" "}
              </span>

              <span className="text-xs text-dark-grey bg-transparent">
                ₹5Cr
              </span>
            </div>
          </div>

          <div className="bg-transparent">
            <label
              htmlFor="credit-score"
              className="block text-sm font-medium text-dark-grey mb-2 bg-transparent"
            >
              Credit Score
            </label>
            <input
              type="range"
              id="credit-score"
              min="550"
              max="850"
              step="10"
              value={creditScore}
              onChange={(e) => setCreditScore(Number.parseInt(e.target.value))}
              className="w-full h-2 bg-white rounded-lg appearance-none cursor-pointer"
            />
            <div className="flex justify-between mt-2 bg-transparent">
              <span className="text-xs text-dark-grey bg-transparent">550</span>
              <span className="text-sm font-medium text-primary-purple bg-transparent">
                {creditScore}
              </span>
              <span className="text-xs text-dark-grey bg-transparent">850</span>
            </div>
          </div>

          <div className="bg-transparent">
            <label className="block text-sm font-medium text-dark-grey mb-2 bg-transparent">
              Employment Type
            </label>
            <div className="flex gap-4 bg-transparent">
              <label className="flex items-center bg-transparent">
                <input
                  type="radio"
                  name="employment-type"
                  value="salaried"
                  checked={employmentType === "salaried"}
                  onChange={() => setEmploymentType("salaried")}
                  className="mr-2"
                />
                <span className="text-sm text-dark-grey bg-transparent ">
                  Salaried
                </span>
              </label>
              <label className="flex items-center bg-transparent">
                <input
                  type="radio"
                  name="employment-type"
                  value="self-employed"
                  checked={employmentType === "self-employed"}
                  onChange={() => setEmploymentType("self-employed")}
                  className="mr-2"
                />
                <span className="text-sm text-dark-grey bg-transparent">
                  Self-Employed
                </span>
              </label>
            </div>
          </div>

          <div className="bg-white w-full p-4  rounded-lg border border-gray-200">
            <div className="flex justify-between items-center mb-3 bg-transparent">
              <span className="text-sm font-medium text-dark-grey bg-transparent">
                {data.changes}
              </span>
              <span
                className={`text-sm font-bold bg-transparent ${getApprovalColor()}`}
              >
                {getApprovalChance()}
              </span>
            </div>
            <div className=" bg-transparent ">
              <div className="flex items-start gap-0.5 md:gap-2 bg-transparent ">
                <CheckCircle
                  className="h-5 w-5 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                  style={{ color: "green" }}
                />
                <p className="text-xs sm:text-sm  mb-2 text-dark-grey bg-transparent">
                  {creditScore >= 750
                    ? data.score
                    : "Improving your credit score will significantly boost approval odds"}
                </p>
              </div>
              <div className="flex items-start gap-0.5 md:gap-2 bg-transparent">
                <CheckCircle
                  className="h-5 w-5 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                  style={{ color: "green" }}
                />
                <p className="text-xs sm:text-sm text-dark-grey bg-transparent">
                  {employmentType === "salaried"
                    ? data.type
                    : "Self-employed applicants may need additional income documentation"}
                </p>
              </div>
            </div>
            {/* <Link
              href="https://minemi.ai/personal-loan/check-eligibility/"
              className="inline-flex h-12  mt-2 items-center justify-center rounded-md bg-[#311663] px-6 text-sm font-medium text-white transition-colors w-full hover:bg-purple-900"
            >
              {data.report}
            </Link> */}
            <a
              href="/trackloanstatus"
              className="inline-flex h-12 mt-2 items-center justify-center rounded-md bg-[#311663] px-6 text-sm font-medium text-white transition-colors w-full hover:bg-purple-900"
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.report}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useRef } from "react";
import HeroSection from "./HeroSection";
import WhyChooseSection from "./WhyChooseSection";
import FinalCTA from "./FinalCTA";
import FAQSection from "./FAQSection";
import ComparisonTable from "./ComparisonTable";
import TestimonialSection from "./TestimonialSection";
import HowItWorksSection from "./HowItWorksSection";
import CardEligibilitySection from "./CardEligibilitySection";

const CheckEligiblity = () => {
    const secondFoldRef = useRef(null);
    const handleScrollToSecondFold = () => {
      secondFoldRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    };
  return (
    <div
      className="min-h-screen overflow-x-hidden"
      style={{ backgroundColor: "#f8f8f8" }}
    >
      <HeroSection data={{handleScrollToSecondFold}} />
      <div  ref={secondFoldRef}>
      <CardEligibilitySection />
      </div>
      <WhyChooseSection />
      <HowItWorksSection />
      <TestimonialSection />
      <ComparisonTable />
      <FAQSection />
      <FinalCTA />
    </div>
  );
};

export default CheckEligiblity;

import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Grid,
  Paper,
  Box,
  ThemeProvider,
  useMediaQuery,
  createTheme,
} from "@mui/material";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import //   ChevronRight,
//   Search,
//   ThumbUpAlt,
//   CheckCircle,
"@mui/icons-material";
import { ChevronDown, CreditCard } from "lucide-react";
import { ChevronRight, Search, ThumbsUp, CheckCircle } from "lucide-react";
import MetadataContext from "../MetadataContext";

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#59266d",
    },
    secondary: {
      main: "#3a868b",
    },
    background: {
      default: "#ebe8fc",
    },
    text: {
      primary: "#311664",
    },
  },
});

export default function LoanRecommendationLandingPage() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("");

  const isMediumScreen = useMediaQuery("(max-width:768px)");
  const isSmallScreen = useMediaQuery("(max-width:576px)");

  const options = [
    "Buying a new home",
    "Buying a new car",
    "Buying a used car",
    "Business needs",
    "Home Renovation",
    "Education Expenses",
    "Marriage Expenses",
    "Buying a two-wheeler",
    "Buying Gold",
    "Travelling",
    "Generic Shopping",
    "Personal (can’t disclose)",
  ];

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Loan Recommendations - Minemi ",
      pageDescription:
        "Get personalized loan recommendations with Minemi . Choose the best loan for your financial needs.",
      pageKeywords:
        "loan recommendations, best loan options, personalized loan offers, loan comparison, loan suggestions, loan matching, loan finder, loan advisor, loan recommendation tool",

      canonicalUrl: "https://minemi.ai/loan-recommendation",
    });
  }, [setMetadata]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const storedValue = localStorage.getItem("selectedFundReason");
    if (storedValue) {
      setSelected(storedValue);
    } else {
      setSelected(options[0]);
      localStorage.setItem("selectedFundReason", options[0]);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <style>{buttonStyles}</style>
      <Box
        sx={{
          minHeight: "100vh",
          bgcolor: "background.default",
          color: "text.primary",
        }}
      >
        {/* Hero Section */}
        <Container
          sx={{
            // py: { xs: 4, md: 16 },
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          <Box
            sx={{
              maxWidth: "46rem",
              mx: "auto",
              textAlign: "center",
              backgroundColor: "transparent",
            }}
          >
            <h1
              style={{
                fontSize: isMediumScreen ? "2rem" : "3rem",
                lineHeight: isMediumScreen ? "2.675rem" : "3.875rem",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              Find Your Perfect Loan Match Today
            </h1>
            <p
              style={{
                fontSize: isSmallScreen ? "16px" : "1.25rem",
                marginBottom: "2rem",
                textAlign: "center",
                lineHeight: "28px",
                backgroundColor: "transparent",
              }}
            >
              Let us know how much funds you need, and we'll find AI-based
              suited credit options for you.
            </p>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                backgroundColor: "transparent",
              }}
            >
              <p style={{ fontSize: "18px", backgroundColor: "transparent" }}>
                Why do you need funds?
              </p>

              {/* Custom Select Component */}
              <div className="relative">
                <button
                  type="button"
                  className="w-full px-4 py-3 text-left  rounded-md bg-white flex items-center justify-between text-gray-600 hover:border-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500"
                  onClick={() => setIsOpen(!isOpen)}
                  style={{
                    border: "1px solid grey",
                    backgroundColor: "transparent",
                  }}
                >
                  <span style={{ backgroundColor: "transparent" }}>
                    {selected || "I need a funds for..."}
                  </span>
                  <ChevronDown
                    className={`w-5 h-5 transition-transform ${
                      isOpen ? "rotate-180" : ""
                    }`}
                    style={{ backgroundColor: "transparent" }}
                  />
                </button>
                {isOpen && (
                  <div className="absolute z-50 w-full mt-1 bg-white  rounded-md shadow-lg">
                    {options.map((option) => (
                      <button
                        key={option.value}
                        className="w-full px-4 py-2 text-left hover:bg-purple-50 text-gray-700"
                        onClick={() => {
                          setSelected(option);
                          localStorage.setItem("selectedFundReason", option);
                          setIsOpen(false);
                        }}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <button
                onClick={() => navigate("/select-payoff-loan")}
                style={{ backgroundColor: "#59266D" }}
                className="w-full  hover:bg-purple-800 text-white rounded-md  text-lg font-medium transition-colors h-10"
              >
                Find My Best Loan Options
              </button>
            </Box>
            <p
              style={{
                fontSize: "0.875rem",
                marginTop: "1rem",
                textAlign: "center",
                color: theme.palette.primary.main,
                backgroundColor: "transparent",
              }}
            >
              This service is completely free and has no impact on your credit
              score.
            </p>
          </Box>
        </Container>

        {/* Loan Recommendation Process */}
        <Box
          sx={{
            bgcolor: "primary.main",
            color: "white",
            py: 6,
          }}
        >
          <Container sx={{ backgroundColor: "transparent" }}>
            <h2
              style={{
                fontSize: isSmallScreen ? "1.775rem" : "1.975rem",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "3rem",
                backgroundColor: "transparent",
              }}
            >
              How It Works
            </h2>
            <Grid container spacing={4} sx={{ backgroundColor: "transparent" }}>
              {[
                {
                  icon: (
                    <ChevronRight
                      className="w-8 h-8"
                      style={{ backgroundColor: "transparent", color: "#fff" }}
                    />
                  ),
                  title: "Basic Questions",
                  description:
                    "Answer a few simple questions about your loan requirements and financial situation.",
                },
                {
                  icon: (
                    <Search
                      className="w-8 h-8"
                      style={{ backgroundColor: "transparent", color: "#fff" }}
                    />
                  ),
                  title: "Credit Score Check",
                  description:
                    "We'll perform a soft credit check that won't affect your credit score.",
                },
                {
                  icon: (
                    <ThumbsUp
                      className="w-8 h-8"
                      style={{ backgroundColor: "transparent", color: "#fff" }}
                    />
                  ),
                  title: "AI Analysis",
                  description:
                    "Our AI system analyzes your information to find the best loan options.",
                },
                {
                  icon: (
                    <CheckCircle
                      className="w-8 h-8"
                      style={{ backgroundColor: "transparent", color: "#fff" }}
                    />
                  ),
                  title: "Personalized Results",
                  description:
                    "Review your tailored loan recommendations and choose the best option.",
                },
              ].map((step, index) => (
                <Grid
                  item
                  xs={12}
                  md={3}
                  key={index}
                  sx={{ backgroundColor: "transparent" }}
                >
                  <Box
                    sx={{ textAlign: "center", backgroundColor: "transparent" }}
                  >
                    <Paper
                      sx={{
                        display: "inline-block",
                        p: 2,
                        mb: 2,
                        bgcolor: "rgba(255,255,255,0.1)",
                        borderRadius: "50%",
                      }}
                    >
                      {step.icon}
                    </Paper>
                    <h3
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                        marginBottom: "0.5rem",
                        backgroundColor: "transparent",
                      }}
                    >
                      {step.title}
                    </h3>
                    <p
                      style={{
                        fontSize: "0.875rem",
                        textAlign: "center",
                        backgroundColor: "transparent",
                      }}
                    >
                      {step.description}
                    </p>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/* Benefits Section */}
        <Container sx={{ py: 8, backgroundColor: "transparent" }}>
          <h2
            style={{
              fontSize: isSmallScreen ? "1.775rem" : "1.975rem",
              lineHeight: isSmallScreen ? "30px" : "50px",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "3rem",
              backgroundColor: "transparent",
            }}
          >
            Why Choose Our Loan Recommendation Service?
          </h2>
          <Grid
            container
            spacing={4}
            sx={{ backgroundColor: "transparent", height: "100%" }}
          >
            {[
              "Personalized AI-Driven Recommendations",
              "Save Time and Effort",
              "No Impact on Credit Score",
              "Compare Multiple Lenders Instantly",
              "Quick and Easy Process",
              "Expert Financial Guidance",
            ].map((benefit, index) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                key={index}
                sx={{
                  width: "auto",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
              >
                <Paper
                  sx={{
                    p: 3,
                    display: "flex",
                    gap: "10px",
                    height: "100%",
                    alignItems: "flex-start",
                  }}
                >
                  <CheckCircle
                    style={{
                      color: "#3A868B",
                      mr: 2,
                      flexShrink: 0,
                      backgroundColor: "transparent",
                    }}
                  />
                  <p
                    style={{
                      fontSize: "1.125rem",
                      fontWeight: "bold",
                      backgroundColor: "transparent",
                    }}
                  >
                    {benefit}
                  </p>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>

        {/* Final Expectations Section */}
        <Box
          sx={{
            bgcolor: "background.default",
            pt: isSmallScreen ? 3 : 8,
            pb: 8,
          }}
        >
          <Container sx={{ backgroundColor: "transparent" }}>
            <h2
              style={{
                fontSize: isSmallScreen ? "1.775rem" : "1.975rem",
                lineHeight: isSmallScreen ? "30px" : "50px",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "3rem",
                backgroundColor: "transparent",
              }}
            >
              What to Expect in Your Recommendations
            </h2>
            <Grid container spacing={4} sx={{ backgroundColor: "transparent" }}>
              {[
                {
                  title: "Lowest Interest Rates",
                  description:
                    "We prioritize loans with the most competitive interest rates available based on your profile.",
                },
                {
                  title: "Minimum EMI Options",
                  description:
                    "Find loans with flexible repayment terms and the lowest possible EMIs to fit your budget.",
                },
                {
                  title: "Easy Approval Process",
                  description:
                    "We'll show you loans with higher approval chances and simpler application processes.",
                },
              ].map((expectation, index) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  key={index}
                  sx={{ backgroundColor: "transparent" }}
                >
                  <Paper
                    sx={{
                      p: 3,
                      height: "100%",
                      border: 1,
                      borderColor: "primary.main",
                    }}
                  >
                    <h3
                      style={{
                        fontSize: "1.25rem",
                        fontWeight: "bold",
                        marginBottom: "1rem",
                        backgroundColor: "transparent",
                        color: theme.palette.primary.main,
                      }}
                    >
                      {expectation.title}
                    </h3>
                    <p
                      style={{
                        fontSize: "1rem",
                        backgroundColor: "transparent",
                      }}
                    >
                      {expectation.description}
                    </p>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/* Call to Action */}
        <Box
          sx={{
            bgcolor: "primary.main",
            color: "white",
            py: 8,
          }}
        >
          <Container
            sx={{ textAlign: "center", backgroundColor: "transparent" }}
          >
            <h2
              style={{
                fontSize: isSmallScreen ? "1.775rem" : "1.975rem",
                lineHeight: isSmallScreen ? "30px" : "50px",
                fontWeight: "bold",
                marginBottom: "1rem",
              }}
            >
              Ready to Find Your Perfect Loan?
            </h2>
            <p
              style={{
                fontSize: isSmallScreen ? "16px" : "1.25rem",
                lineHeight: "20px",
                marginBottom: "2rem",
                textAlign: "center",
                backgroundColor: "transparent",
              }}
            >
              Get started now and discover the best loan options tailored just
              for you.
            </p>
            <button
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              className="custom-button custom-button-secondary custom-button-large"
              style={{
                padding: "12px 32px",
                fontSize: "18px",
                lineHeight: "24px",
                textTransform: "none",
              }}
            >
              Get Your Personalized Recommendations
            </button>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const buttonStyles = `
.custom-button {
  font-family: "Archivo";
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
}
.custom-button-primary {
  color: #fff;
  background-color: ${theme.palette.primary.main};
}
.custom-button-primary:hover {
  background-color: ${theme.palette.primary.dark};
}
.custom-button-secondary {
  color: #fff;
  background-color: ${theme.palette.secondary.main};
}
.custom-button-secondary:hover {
  background-color: ${theme.palette.secondary.dark};
}
.custom-button-large {
  padding: 8px 22px;
  font-size: 0.9375rem;
}
.custom-button-fullwidth {
  width: 100%;
}
`;

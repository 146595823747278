import React, { useState, useEffect, useContext } from "react";
import illustration from "../../../Assets/Images/Illustration22.webp";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import "./DigitalLoanOverdraft.css";
import {
  Navigate,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet";
import MetadataContext from "../../MetadataContext";

// import Breadcrumbs from "./Breadcrumbs";

const DigitalLoanOverdraft = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [monthlyIncome, setMonthlyIncome] = useState(""); // ₹
  const [existingObligations, setExistingObligations] = useState(""); // ₹
  const [loanTenure, setLoanTenure] = useState(""); // months
  const [annualInterestRate, setAnnualInterestRate] = useState(""); // %
  const [eligibility, setEligibility] = useState(0);
  const [foir, setFOIR] = useState(0);
  const navigate = useNavigate();

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Flexible Overdraft Facility | Compare & Apply Online",
      pageDescription:
        "Manage your short-term financial needs with an overdraft facility. Compare offers, check eligibility, and apply online for flexible credit through Minemi.",
      pageKeywords:
        "overdraft facility, apply for overdraft, overdraft eligibility, overdraft vs personal loan, flexible credit.",
      canonicalUrl:
        "https://minemi.ai/loanpage/digital-loan/digital-loan-overdraft-facility",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          className="margin-less"
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {" "}
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              <Link
                to={"/loanpage/digital-loan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Digital Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Overdraft Facility
              </Link>{" "}
            </p>
          </div>
          <h1
            className="loanstatus-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Overdraft Facility: Flexible Credit for Your Short-Term Needs
          </h1>
          <p
            className="loanstatus-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: 1,
            }}
          >
            Access instant funds with overdraft facilities tailored for your
            financial flexibility. Borrow only what you need, repay at your
            pace.{" "}
          </p>
        </div>
        <div
          className="tracking-status"
          style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}
        >
          {/* eligibility content */}
          <div
            style={{
              marginTop: 40,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Introduction:
          </div>

          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Overdrafts are a convenient credit option for managing short-term
            financial needs without the hassle of applying for a new loan.
            Whether you need funds for unforeseen emergencies, business working
            capital, or everyday expenses, an overdraft facility provides a
            flexible solution. Unlike traditional loans, you pay interest only
            on the amount utilized, offering significant savings and
            convenience.
          </div>

          <div id="types-of-car-loan-eligibility">
            <div
              style={{
                marginTop: 25,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Benefits of Overdraft Facilities
            </div>

            <div className="mt-2">
              <ol style={{ listStyle: "number" }}>
                <li
                  className="mt-2 mb-0"
                  style={{
                    fontFamily: "Archivo",
                    marginTop: 16,
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                  }}
                >
                  <strong>Flexibility: </strong>Borrow as per your requirement
                  and repay when funds are available.
                </li>
                <li
                  className="mt-2 mb-0"
                  style={{
                    fontFamily: "Archivo",
                    marginTop: 16,
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                  }}
                >
                  <strong>Interest on Usage</strong>Pay interest only on the
                  amount utilized.
                </li>
                <li
                  className="mt-2 mb-0"
                  style={{
                    fontFamily: "Archivo",
                    marginTop: 16,
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                  }}
                >
                  <strong>Quick Access: </strong>Enjoy fast and hassle-free
                  approval
                </li>
                <li
                  className="mt-2 mb-0"
                  style={{
                    fontFamily: "Archivo",
                    marginTop: 16,
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                  }}
                >
                  <strong>No Fixed EMI: </strong>Repay based on cash flow,
                  without fixed monthly obligations.
                </li>
                <li
                  className="mt-2 mb-0"
                  style={{
                    fontFamily: "Archivo",
                    marginTop: 16,
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                  }}
                >
                  <strong>Multiple Use Cases: </strong>Ideal for personal or
                  business needs like working capital or emergencies.
                </li>
              </ol>
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Eligibility Criteria for Overdraft Facility
              <p
                style={{
                  fontFamily: "Archivo",
                  marginTop: 16,
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "24px",
                }}
              >
                <strong>Criteria Details: </strong>
              </p>
              <div className="mt-2">
                <ul style={{ listStyle: "disc" }}>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    Income Requirement ₹25,000/month minimum income
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    Employment Status Salaried or self-employed
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    Credit Score 700+ preferred for attractive rates
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    Collateral Required in case of secured overdraft
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    Age 21 to 65 years
                  </li>
                </ul>
              </div>
            </div>
            <div
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Documents Required
              <div className="mt-2">
                <ul style={{ listStyle: "disc" }}>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    <strong>Identity Proof: </strong>PAN, Aadhaar Card.
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    <strong>Address Proof: </strong>Utility bill, rent
                    agreement.
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    <strong>Income Proof: </strong>Salary slips, bank
                    statements.
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    <strong>Collateral Papers: </strong>(For secured overdrafts
                    only).
                  </li>
                </ul>
              </div>
            </div>
            <div
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Use Cases for Overdraft Facility
              <div className="mt-2">
                <ul style={{ listStyle: "disc" }}>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    <strong>Business Needs: </strong>Manage working capital and
                    operational expenses.
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    <strong>Emergency Expenses: </strong>Handle unexpected
                    medical or travel needs.
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    <strong>Seasonal Requirements: </strong>Ideal for irregular
                    cash flows.
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    <strong>Debt Consolidation: </strong>Use it to pay off
                    high-interest loans.
                  </li>
                </ul>
              </div>
            </div>
            <div
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Overdraft vs. Personal Loan
              <div className="mt-2">
                <ul style={{ listStyle: "disc" }}>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    Feature Overdraft Personal Loan
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    Interest Calculation On utilized amount only On full loan
                    amount
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    Repayment Flexible, based on cash flow Fixed EMIs
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    Approval Time Quick Moderate
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    Collateral Optional (secured overdrafts) Not required
                  </li>
                </ul>
              </div>
            </div>
            <div
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Why Choose Minemi for Overdrafts?
              <div className="mt-2">
                <ul style={{ listStyle: "disc" }}>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    Compare multiple lenders offering competitive overdraft
                    facilities.
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    Get personalized recommendations based on your profile.
                  </li>
                  <li
                    className="mt-2 mb-0"
                    style={{
                      fontFamily: "Archivo",
                      marginTop: 16,
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                  >
                    Access a seamless, end-to-end digital application process.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            className="make-it-center increase-margin "
            style={{
              marginTop: "2%",
              marginBottom: "2%",
              padding: 24,
              background: "linear-gradient(91.7deg, #EADCFF 0.4%, #FFFFFF 99%)",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 20,
              borderRadius: 24,
            }}
          >
            <div
              style={{
                marginLeft: "4%",
                backgroundColor: "transparent",
                display: "flex",
                flexDirection: "column",
                gap: 24,
                borderRadius: 24,
                alignSelf: "center",
              }}
            >
              <p
                className="eligiblity-criteria"
                style={{
                  fontFamily: "Archivo",
                  color: "#59266D",
                  fontSize: 40,
                  backgroundColor: "transparent",
                }}
              >
                <i style={{ backgroundColor: "transparent", lineHeight: 1.2 }}>
                  Check Your Loan Eligibility Without{" "}
                  <br className="brake-remove" /> Impacting Your Credit Score
                </i>
              </p>
              <a
                href="https://minemi.ai/personal-loan/check-eligibility/"
                className="eligiblity-buttons"
                style={{
                  backgroundColor: "#59266D",
                  color: "#FFFFFF",
                  width: "39%",
                  borderRadius: 8,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 18,
                  paddingTop: 8,
                  paddingBottom: 8,
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                Check Eligibility
              </a>
            </div>
            <div
              style={{
                width: 200,
                height: 195,
                backgroundColor: "transparent",
                marginRight: "4%",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
              }}
            >
              <img
                src={illustration}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                loading="lazy"
                alt="eligibility"
              />
            </div>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Frequently Asked Questions (FAQs){" "}
          </div>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What is the interest rate on overdraft facilities?
              </AccordionSummary>
              <AccordionDetails>
                Interest rates vary by lender but typically range from 10-18%
                per annum, calculated only on the utilized amount.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Can I get an overdraft without collateral?
              </AccordionSummary>
              <AccordionDetails>
                Yes, unsecured overdraft facilities are available, but they may
                have higher interest rates.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                How is an overdraft limit determined?
              </AccordionSummary>
              <AccordionDetails>
                The limit depends on your income, credit score, and relationship
                with the lender.
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalLoanOverdraft;

import React, { useEffect, useState } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Link, useNavigate } from "react-router-dom";
import "./statuss.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import nostatus from "../../Assets/Images/nostatuss.webp";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { CheckCircle } from "lucide-react";

function Statuss() {
  const [data, setData] = useState([]);
  const[status,setStatus]=useState("")
  const navigate = useNavigate();
  const ListHandler = () => {
    const token = localStorage.getItem("token");
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/user/applicationForm/list`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        setStatus(response.data.status)
        if (response.data.status === 1) {
          setData(response.data.data.list);
        } else {
          toast(response.data.msg);
        }
      })
      .catch(function (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };
  useEffect(() => {
    ListHandler();
  }, []);
  let percentage = 0;
  if (data.status === "Application Submitted") {
    percentage = 0;
  } else if (data.status === "Document Verified") {
    percentage = 35;
  } else if (data.status === "In Progress") {
    percentage = 70;
  } else if (data.status === "MinEMI Approved") {
    percentage = 100;
  }
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/");
    } else {
    }
  }, []);

  return (
    <>
    {status !== 0 ? (
      data.length === 0 ? (
        <Skeleton count={8} style={{ height: 40, marginTop: 20 }} />
      ) : ( 
        <div className="container mx-auto px-4 py-8 max-w-7xl">
      <div className="grid gap-4 sm:grid-cols-1 lg:grid-cols-3">
        {data?.map((d, index) => (
          <div key={index} >
            <div className=" max-w-md">
              <div className="overflow-hidden rounded-lg bg-white shadow-lg">
                {/* Header */}
                <div className="border-b border-gray-100 bg-purple-50 p-4">
                  <div className="flex items-center justify-between"  style={{backgroundColor:'transparent'}}>
                    <div className="bg-purple-50">
                      <h1 className="text-lg font-semibold text-purple-900" style={{backgroundColor:'transparent'}}>
                        Application Submitted
                      </h1>
                      <p className="text-sm text-purple-700" style={{backgroundColor:'transparent'}}>Other</p>
                    </div>
                    <div className="h-12 w-12 rounded-full bg-purple-100 p-2">
                      <svg
                        className="h-8 w-8 text-purple-600"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        style={{backgroundColor:'transparent'}}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          style={{backgroundColor:'transparent'}}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
  
                {/* Main Content */}
                <div className="p-4" style={{backgroundColor:'transparent'}}>
                  <h2 className="mb-4 text-xl font-semibold text-purple-900" style={{backgroundColor:'transparent'}}>
                    Application Status
                  </h2>
                  <div className="space-y-4" style={{backgroundColor:'transparent'}}>
                    {/* Loan Details */}
                    <div className="grid gap-4" style={{backgroundColor:'transparent'}}>
                      <div className="flex justify-between border-b border-gray-100 pb-2"  style={{backgroundColor:'transparent'}}>
                        <span className="text-sm text-gray-600"  style={{backgroundColor:'transparent'}}>Loan Amount</span>
                        <span className="font-medium text-gray-900"  style={{backgroundColor:'transparent'}}>   ₹{d.loanamount}</span>
                      </div>
                      <div className="flex justify-between border-b border-gray-100 pb-2"  style={{backgroundColor:'transparent'}}>
                        <span className="text-sm text-gray-600" style={{backgroundColor:'transparent'}}>Tenure</span>
                        <span className="font-medium text-gray-900" style={{backgroundColor:'transparent'}}>
                        {d.remaining_tenure} Months 
                        </span>
                      </div>
                      <div className="flex justify-between border-b border-gray-100 pb-2" style={{backgroundColor:'transparent'}}>
                        <span className="text-sm text-gray-600" style={{backgroundColor:'transparent'}}>
                          Interest Rate
                        </span>
                        <span className="font-medium text-gray-900" style={{backgroundColor:'transparent'}}>{d.interestRate}%</span>
                      </div>
                      <div className="flex justify-between border-b border-gray-100 pb-2" style={{backgroundColor:'transparent'}}>
                        <span className="text-sm text-gray-600" style={{backgroundColor:'transparent'}}>
                          Monthly EMI
                        </span>
                        <span className="font-medium text-gray-900" style={{backgroundColor:'transparent'}}>₹{d.currentemi}</span>
                      </div>
                    </div>
  
                    {/* Savings Message */}
                    <div className="rounded-md bg-purple-50 p-3">
                      <p className="text-center text-sm font-medium text-purple-900" style={{backgroundColor:'transparent'}}>
                        You are saving ₹{d.savingAmount} with this offer
                      </p>
                    </div>
                  </div>
                </div>
  
                {/* Footer */}
                <div className="p-4" style={{backgroundColor:'transparent'}}>

                  <Link
                    state={{
                      docId: d.id,
                    }}
                    to="/statusdetail"
                    style={{width:'92%',display:'flex',flexDirection:'column',alignSelf:'center', alignItems:'center',margin:'auto'}}
                    className="w-full rounded-md bg-purple-700 px-4 py-3 text-sm font-medium text-white transition-colors hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                  >
                    View Application
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
        </div>
        </div>
      )
    ) :<>
  {status !== 0 ? (
    data.length === 0 ? (
      <Skeleton count={8} style={{ height: 40, marginTop: 20 }} />
    ) : (
      <div className="container mx-auto px-4 py-8 max-w-7xl">
      <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
        {data?.map((d, index) => (
            <div  key={index}  className="overflow-hidden rounded-lg bg-white shadow-lg">
              {/* Header */}
              <div className="border-b border-gray-100 bg-purple-50 p-4">
                <div className="flex items-center justify-between">
                  <div>
                    <h1 className="text-lg font-semibold text-purple-900">Application Submitted</h1>
                    <p className="text-sm text-purple-700">Other</p>
                  </div>
                  <div className="h-12 w-12 rounded-full bg-purple-100 p-2 flex items-center justify-center">
                    <CheckCircle className="h-8 w-8 text-purple-600" />
                  </div>
                </div>
              </div>

              {/* Main Content */}
              <div className="p-4">
                <h2 className="mb-4 text-xl font-semibold text-purple-900">Application Status</h2>
                <div className="space-y-4">
                  {/* Loan Details */}
                  <div className="grid gap-4">
                    <div className="flex justify-between border-b border-gray-100 pb-2">
                      <span className="text-sm text-gray-600">Loan Amount</span>
                      <span className="font-medium text-gray-900">₹{d.loanAmount}</span>
                    </div>
                    <div className="flex justify-between border-b border-gray-100 pb-2">
                      <span className="text-sm text-gray-600">Tenure</span>
                      <span className="font-medium text-gray-900">{d.tenure} Months</span>
                    </div>
                    <div className="flex justify-between border-b border-gray-100 pb-2">
                      <span className="text-sm text-gray-600">Interest Rate</span>
                      <span className="font-medium text-gray-900">{d.interestRate}%</span>
                    </div>
                    <div className="flex justify-between border-b border-gray-100 pb-2">
                      <span className="text-sm text-gray-600">Monthly EMI</span>
                      <span className="font-medium text-gray-900">₹{d.monthlyEMI}</span>
                    </div>
                  </div>

                  {/* Savings Message */}
                  <div className="rounded-md bg-purple-50 p-3">
                    <p className="text-center text-sm font-medium text-purple-900">
                      You are saving ₹{d.savings} with this offer
                    </p>
                  </div>
                </div>
              </div>

              {/* Footer */}
              <div className="p-4">
                <button
                  className="w-full rounded-md bg-purple-700 px-4 py-3 text-sm font-medium text-white transition-colors hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                >
                  View Application
                </button>
              </div>
            </div>
        ))}
      </div>
    </div>
    )
  ) : 
  <div
  style={{
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    marginTop: "15%",
    marginBottom: "15%",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    gap: 8,
  }}
>
  <div style={{ height: 86, width: 90 }}>
    <img src={nostatus} style={{ width: "100%", height: "100%" }} alt="No status" />
  </div>
  <div style={{ fontSize: 14, fontWeight: "700" }}>No Application Submitted</div>
</div>}
</>
}
  </>
  
  );
}

export default Statuss;

import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography } from "@mui/material";
import toungeOut from "../../../Assets/Images/tongue-out.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import MetadataContext from "../../MetadataContext";

const HLCompareInterestRates = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Compare Home Loan Interest Rates",
      pageDescription:
        "Compare home loan interest rates across top banks. See both fixed and floating loan rates.",
      pageKeywords:
        "compare home loan interest rates, home loan rates, fixed loan rates, floating loan rates, best home loan offers, home loan comparison, top bank loan rates, affordable home loans, home loan interest rates India, housing loan rates",

      canonicalUrl:
        "https://minemi.ai/loanpage/homeloan/home-loan-compare-interest-rates",
    });
  }, [setMetadata]);
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className="custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/homeloan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Home Loan
              </Link>{" "}
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                {" "}
                Compare Home Loan Interest Rates
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "46px",

              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Compare Home Loan Interest Rates
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "20px",
            }}
          >
            Compare home loan interest rates across top banks. See both fixed
            and floating loan rates.
          </p>
        </div>

        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
          <div
            style={{
              marginTop: 40,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Why Comparing Home Loan Interest Rates Matters?
          </div>

          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Choosing the right home loan involves more than just the loan amount
            and tenure. Interest rates play a crucial role in determining the
            total repayment amount and your monthly EMI. Comparing home loan
            rates ensures you find the most cost-effective solution tailored to
            your financial needs.
          </div>

          <div>
            <h3
              style={{
                marginTop: 25,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Understanding Fixed vs. Floating Interest Rates
            </h3>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Fixed Interest Rate:&nbsp;&nbsp;
                  </span>
                  The interest rate remains constant throughout the loan tenure,
                  providing predictability in EMIs. However, fixed rates may be
                  higher than initial floating rates and could result in higher
                  costs if market rates decrease.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Floating Interest Rate:&nbsp;&nbsp;
                  </span>
                  The rate fluctuates based on market conditions and the bank's
                  benchmark rates. While this means EMIs can vary, borrowers
                  might benefit from reduced rates when the market trend is
                  downward.
                </div>
              </div>
            </div>
          </div>

          {/* table data */}
          <div id="types-of-bt" className="mt-5">
            <div
              id="bt-types-table"
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                      }}
                    >
                      Bank Name
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Interest Rate (Floating)
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Interest Rate (Fixed)
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Processing Fees
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        borderTopRightRadius: 18,
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Tenure
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      HDFC Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.40% - 9.50%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      9.25%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹3,000 - ₹5,000
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Up to 30 years
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      ICICI Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.50% - 9.60%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      9.35%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹3,000 or 0.5% of the loan amount
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Up to 30 years
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      SBI Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.35% - 9.45%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      9.20%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹10,000 or 0.35% of the loan amount
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Up to 30 years
                    </td>
                  </tr>

                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Axis Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.50% - 9.75%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      9.30%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹2,500 - ₹5,000
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Up to 30 years
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div id="factors-data">
            <h3
              style={{
                marginTop: 25,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Factors Affecting Home Loan Interest Rates
            </h3>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:&nbsp;&nbsp;
                  </span>
                  Higher loan amounts may attract better rates for eligible
                  borrowers.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Credit Score:&nbsp;&nbsp;
                  </span>
                  A score above 750 increases your chances of securing lower
                  rates.
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Income Stability:&nbsp;&nbsp;
                  </span>
                  Lenders prefer applicants with stable and verifiable income.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan-to-Value Ratio (LTV):&nbsp;&nbsp;
                  </span>
                  Higher down payments can lead to better interest rates.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Economic Conditions:&nbsp;&nbsp;
                  </span>
                  Market factors, such as the RBI’s repo rate, influence
                  floating rates.
                </div>
              </div>
            </div>
          </div>
          <div id="interest-rates">
            <h3
              style={{
                marginTop: 25,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Understanding Bank-Specific Interest Rates
            </h3>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    HDFC Bank Home Loan Rates&nbsp;&nbsp;
                  </span>
                  <ul style={{ listStyle: "disc" }} className="mt-2">
                    <li className="" style={{ marginBottom: "5px" }}>
                      Offers competitive floating and fixed rates tailored for
                      salaried and self-employed individuals.
                    </li>
                    <li>
                      Special discounts available for women applicants under
                      certain schemes.
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    ICICI Bank Home Loan Rates&nbsp;&nbsp;
                  </span>
                  <ul
                    style={{ listStyle: "disc" }}
                    className="d-flex flex-column gap-2 mt-2"
                  >
                    <li>
                      Provides flexible repayment options and interest rate
                      discounts for high credit scores.
                    </li>
                    <li>
                      Includes top-up loans at preferential rates for existing
                      customers.
                    </li>
                  </ul>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    SBI Home Loan Rates&nbsp;&nbsp;
                  </span>
                  <ul
                    style={{ listStyle: "disc" }}
                    className="d-flex flex-column gap-2 mt-2"
                  >
                    <li>
                      Offers some of the lowest rates in the market, especially
                      under its SBI Privilege Home Loan scheme for government
                      employees.
                    </li>
                    <li>
                      No hidden charges and transparency in terms of prepayment
                      penalties.
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Axis Bank Home Loan Rates&nbsp;&nbsp;
                  </span>
                  <ul
                    style={{ listStyle: "disc" }}
                    className="d-flex flex-column gap-2 mt-2"
                  >
                    <li>
                      Offers attractive rates for salaried individuals with
                      higher income brackets.
                    </li>
                    <li>
                      Includes balance transfer options for existing home loan
                      borrowers seeking better rates.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="tips-data">
            <h3
              style={{
                marginTop: 25,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Tips to Secure the Best Interest Rate
            </h3>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Improve Your Credit Score:&nbsp;&nbsp;
                  </span>
                  A score above 750 is ideal for getting favorable terms.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Compare Offers:&nbsp;&nbsp;
                  </span>
                  Use MinEMI's tools to evaluate rates, processing fees, and
                  other costs.
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Choose the Right Tenure:&nbsp;&nbsp;
                  </span>
                  A longer tenure lowers EMIs but increases total interest,
                  while a shorter tenure reduces interest but increases EMIs.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Negotiate with the Lender:&nbsp;&nbsp;
                  </span>
                  Banks often offer better rates to retain high-value customers.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip section */}
          <div
            className="tips mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
              marginTop: 30,
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              className="p-1 px-sm-4 py-4"
              style={{ backgroundColor: "#e7e4f9" }}
            >
              <div
                className="px-3 py-2"
                style={{ backgroundColor: "#e7e4f9", padding: "30px " }}
              >
                <div
                  className="d-flex flex-row gap-2 align-items-center"
                  style={{
                    backgroundColor: "transparent",
                    marginBottom: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    gap: "20px",
                  }}
                >
                  <img
                    src={toungeOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "#e7e4f9",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    💡 A balance transfer can help you switch to a lower
                    interest rate during your loan tenure, reducing your EMIs
                    and overall repayment cost. Always review the transfer
                    charges before making the switch.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3
              style={{
                marginTop: 25,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Start Comparing Home Loans Today
            </h3>
            <p
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontSize: 16,
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
              Use MinEMI’s tools to evaluate and compare home loan interest
              rates across top lenders. Find the best options for your dream
              home and take the first step toward a stress-free home-buying
              journey!
            </p>
          </div>

          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Frequently Asked Questions (FAQs){" "}
          </h3>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What are the latest home loan interest rates in 2024?
              </AccordionSummary>
              <AccordionDetails>
                The current floating rates range between 8.35% and 9.75% across
                leading banks.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Can I negotiate my home loan interest rate?
              </AccordionSummary>
              <AccordionDetails>
                Yes, banks often offer discounts based on your credit score,
                loan amount, and income profile.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Are there prepayment charges for floating rate loans?
              </AccordionSummary>
              <AccordionDetails>
                No, floating rate loans typically don’t have prepayment
                penalties, but fixed-rate loans may have charges.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                How does my credit score impact the interest rate?
              </AccordionSummary>
              <AccordionDetails>
                A high credit score improves your chances of securing a lower
                interest rate.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                How do I effectively compare interest rates when looking for a
                home loan?
              </AccordionSummary>
              <AccordionDetails>
                <ul style={{ listStyle: "disc" }} className="my-0 py-0">
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                    }}
                    className="pb-2 pt-0"
                  >
                    <p style={{ fontWeight: 600 }}>
                      Focus on Annual Percentage Rate (APR):{" "}
                    </p>
                    <ul style={{ listStyle: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        APR is the true cost of borrowing, encompassing the
                        interest rate plus other associated fees (processing
                        fees, documentation fees, etc.).
                      </li>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Comparing APRs across different lenders provides a more
                        accurate reflection of the overall cost of each loan.
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                      fontWeight: 400,
                    }}
                    className="pb-2"
                  >
                    <p style={{ fontWeight: 600 }}>
                      {" "}
                      Consider Interest Rate Types:{" "}
                    </p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        <span style={{ fontWeight: 600 }}>Fixed Rate:</span>{" "}
                        Offers stable monthly payments but may have higher
                        initial rates.
                      </li>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        <span style={{ fontWeight: 600 }}>Floating Rate:</span>{" "}
                        Linked to a benchmark rate (like MCLR or Repo Rate),
                        offering potential for lower rates in the future but
                        carries the risk of increased payments if interest rates
                        rise.
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                      fontWeight: 400,
                    }}
                    className="pb-2"
                  >
                    <p style={{ fontWeight: 600 }}>
                      {" "}
                      Look Beyond Introductory Rates:
                    </p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Some lenders offer introductory teaser rates for a
                        limited period.
                      </li>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Ensure you understand the rate after the introductory
                        period expires to get a complete picture of your
                        long-term costs.
                      </li>
                    </ul>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What are the key factors to consider beyond interest rates when
                comparing home loans?
              </AccordionSummary>
              <AccordionDetails>
                <ul style={{ listStyle: "disc" }} className="my-0 py-0">
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                    }}
                    className="pb-2 pt-0"
                  >
                    <p style={{ fontWeight: 600 }}>Loan Tenure: </p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Longer tenures result in lower EMIs but higher overall
                        interest payments.
                      </li>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Choose a tenure that aligns with your financial capacity
                        and long-term goals.
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                      fontWeight: 400,
                    }}
                    className="pb-2"
                  >
                    <p style={{ fontWeight: 600 }}>
                      Processing Fees and Other Charges:{" "}
                    </p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Compare processing fees, documentation charges,
                        valuation fees, and any other applicable charges across
                        different lenders.
                      </li>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        These fees can significantly impact the overall cost of
                        the loan.
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                      fontWeight: 400,
                    }}
                    className="pb-2"
                  >
                    <p style={{ fontWeight: 600 }}>
                      Loan-to-Value (LTV) Ratio:
                    </p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        A lower LTV ratio (higher down payment) generally
                        results in better interest rates and loan terms.
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                      fontWeight: 400,
                    }}
                    className="pb-2"
                  >
                    <p style={{ fontWeight: 600 }}>Prepayment Penalties:</p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Understand the prepayment penalties associated with each
                        loan.
                      </li>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Some lenders may charge a penalty for early repayment,
                        while others offer prepayment options without any
                        charges.
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                      fontWeight: 400,
                    }}
                    className="pb-2"
                  >
                    <p style={{ fontWeight: 600 }}>Repayment Flexibility:</p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Consider options like flexible EMI payments, part
                        payments, or the ability to make extra payments without
                        penalties.
                      </li>
                    </ul>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                How can I use online tools and resources to compare home loan
                options effectively?
              </AccordionSummary>
              <AccordionDetails>
                <ul style={{ listStyle: "disc" }} className="my-0 py-0">
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                    }}
                    className="pb-2 pt-0"
                  >
                    <p style={{ fontWeight: 600 }}>
                      Utilize online loan comparison portals:{" "}
                    </p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Many websites and apps allow you to compare home loan
                        offers from various lenders based on your eligibility
                        and loan requirements.
                      </li>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        These platforms can provide valuable insights into
                        interest rates, fees, and other key factors.
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                      fontWeight: 400,
                    }}
                    className="pb-2"
                  >
                    <p style={{ fontWeight: 600 }}>
                      Use online home loan calculators:
                    </p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Calculate your estimated EMIs, total interest payable,
                        and compare different loan scenarios to determine the
                        most affordable option.
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                      fontWeight: 400,
                    }}
                    className="pb-2"
                  >
                    <p style={{ fontWeight: 600 }}>
                      Read reviews and testimonials:
                    </p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Research customer reviews and testimonials about
                        different lenders to understand their customer service,
                        loan processing speed, and overall reputation.
                      </li>
                    </ul>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What are the benefits of consulting with a financial advisor
                when comparing home loans?
              </AccordionSummary>
              <AccordionDetails>
                <ul style={{ listStyle: "disc" }} className="my-0 py-0">
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                    }}
                    className="pb-2 pt-0"
                  >
                    <p style={{ fontWeight: 600 }}>Personalized Advice:</p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        A financial advisor can assess your individual financial
                        situation, risk tolerance, and long-term goals to
                        recommend the most suitable home loan option.
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                      fontWeight: 400,
                    }}
                    className="pb-2"
                  >
                    <p style={{ fontWeight: 600 }}>Expert Guidance:</p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        They can provide expert guidance on various aspects of
                        the home loan process, including loan eligibility,
                        documentation requirements, and negotiation strategies.
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                      fontWeight: 400,
                    }}
                    className="pb-2"
                  >
                    <p style={{ fontWeight: 600 }}>
                      Access to Exclusive Deals:{" "}
                    </p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Some financial advisors may have access to exclusive
                        deals and offers from lenders that are not available to
                        the general public.
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                      fontWeight: 400,
                    }}
                    className="pb-2"
                  >
                    <p style={{ fontWeight: 600 }}>Objective Comparison: </p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        A financial advisor can provide an unbiased comparison
                        of different loan options, helping you make an informed
                        decision.
                      </li>
                    </ul>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                How can I improve my chances of securing a competitive home
                loan?
              </AccordionSummary>
              <AccordionDetails>
                <ul style={{ listStyle: "disc" }} className="my-0 py-0">
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                    }}
                    className="pb-2 pt-0"
                  >
                    <p style={{ fontWeight: 600 }}>
                      Maintain a good credit score:{" "}
                    </p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        A high credit score demonstrates your creditworthiness
                        and can qualify you for better interest rates.
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                      fontWeight: 400,
                    }}
                    className="pb-2"
                  >
                    <p style={{ fontWeight: 600 }}>
                      Improve your debt-to-income ratio:
                    </p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Reduce existing debts to improve your debt-to-income
                        ratio, which lenders consider when assessing your loan
                        eligibility.
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                      fontWeight: 400,
                    }}
                    className="pb-2"
                  >
                    <p style={{ fontWeight: 600 }}>
                      Increase your down payment:
                    </p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        A larger down payment can lead to lower interest rates
                        and better loan terms.
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      // fontSize: 16,
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                      fontWeight: 400,
                    }}
                    className="pb-2"
                  >
                    <p style={{ fontWeight: 600 }}>
                      Shop around and compare offers from multiple lenders:
                    </p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          // fontSize: 16,
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Don't limit yourself to just one or two banks. Compare
                        offers from various lenders to find the most competitive
                        terms.
                      </li>
                    </ul>
                  </li>
                  <li
                    style={{
                      lineHeight: "20px",
                      fontFamily: "Archivo",
                      fontWeight: 400,
                    }}
                    className="pb-2"
                  >
                    <p style={{ fontWeight: 600 }}>
                      Provide accurate and complete documentation:{" "}
                    </p>
                    <ul style={{ listStyleType: "circle" }}>
                      <li
                        style={{
                          lineHeight: "20px",
                          fontFamily: "Archivo",
                        }}
                        className="pb-2 pt-0"
                      >
                        Ensure all the necessary documents are submitted
                        accurately and on time to expedite the loan approval
                        process.
                      </li>
                    </ul>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br className="display-none" /> and tailored just for you—apply
                now to see what you qualify for!
              </p>
              <div
                style={{
                  backgroundColor: "transparent",
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                }}
              >
                <a
                  href="https://minemi.ai/home-loan/check-eligibility"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 50,
                    paddingRight: 50,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                >
                  Check Eligiblity Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HLCompareInterestRates;

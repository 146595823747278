import React from "react";

export default function Hero() {
  return (
    <section className="bg-gradient-to-r from-[#311664] to-[#59266d] text-white py-6 sm:py-10 w-md" >
      <div className="bg-transparent mx-auto px-4 flex flex-col sm:flex-row items-center justify-between  gap-8" style={{alignContent:'center',marginTop:35,marginBottom:35}}>
        <div className="w-full sm:w-1/2 lg:w-5/12 space-y-4 bg-transparent">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold leading-tight bg-transparent">
            Hidden Loan Costs Can Cost You Lakhs – Check Your APR First!
          </h1>
          <p className="text-sm text-start sm:text-base md:text-lg bg-transparent">
            Most borrowers overpay due to hidden charges in loans. Minemi helps
            you compare real APRs, avoid extra costs, and secure the lowest EMI
            before applying.
          </p>
          <button className="bg-[#3a868b] hover:bg-[#ebe8fc] hover:text-[#26292b] text-white rounded-full px-6 py-2 text-sm sm:text-base font-semibold transition-all duration-300 ease-in-out transform hover:scale-105">
            Check My APR & Get the Best Loan Rate
          </button>
          <p className="text-xs  sm:text-sm italic bg-transparent text-start">
            9 out of 10 Minemi users secure better loan deals by checking APRs
            first.
          </p>
        </div>
        <div className="relative w-full  sm:w-[400px] md:w-[550px]  bg-transparent" style={{alignSelf:'center'}}>
          <img
            src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/IMG_1252-HoldJv3aHz6bTAFzFblMOFtXZ9Wu1k.png"
            alt="Financial advisor with percentage sign and pie chart"
            className="object-contain"
            priority
            style={{backgroundColor:'transparent',alignSelf:'center',margin:'auto'}}
          />
        </div>
      </div>
    </section>
  );
}

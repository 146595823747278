import React from "react";
import Serve from "./Serve/Serve.jsx";
import Types from "./Types/Types.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LAPEligibility from "./LAPEligibility/LAPEligibility.jsx";

const LoanAgainstPropertyForm = () => {
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <LAPEligibility />
        <Serve />
        <Types />
        <ToastContainer />
      </div>
    </>
  );
};

export default LoanAgainstPropertyForm;

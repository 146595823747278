import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import "./Question.css";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import logo from ".././assets/Images/mLogo.webp";
import { ColorRing } from "react-loader-spinner";

import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgColor: "#F6F6F6",
  transform: "translate(-50%, -50%)",
  width: 350,
  boxShadow: 24,
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 2,
  paddingRight: 2,
  border: 0,
};

const Repaymentpriority = () => {
  const [progress, setProgress] = useState(90);
  const [active, setActive] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loader, setLoader] = useState(false);

  const [value, setValue] = useState(
    "Lower EMI (Longer tenure, affordable payments)"
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Retrieve selected value from localStorage
    const storedValue = localStorage.getItem("repayment_priority");
    if (storedValue) {
      setValue(storedValue);
      setActive(priorityOptions.indexOf(storedValue));
    } else {
      setValue("Lower EMI (Longer tenure, affordable payments)");
      setActive(0);
      localStorage.setItem(
        "repayment_priority",
        "Lower EMI (Longer tenure, affordable payments)"
      );
    }
  }, []);

  const handleSelection = (index, newValue) => {
    setActive(index);
    setValue(newValue);
    localStorage.setItem("repayment_priority", newValue);
  };

  const selectedFundReason = localStorage.getItem("selectedFundReason");
  const selectedrepay = localStorage.getItem("selectedrepay");
  const moneyneed = localStorage.getItem("moneyneed");
  const selectedLoanIndex = localStorage.getItem("selectedLoanIndex");
  const selectedIncome = localStorage.getItem("selectedIncome");

  const valueData = localStorage.getItem("value");
  const monthlySpend = localStorage.getItem("monthly_spend");
  const assets = localStorage.getItem("assets");
  const coApplicantSelection = localStorage.getItem("coApplicantSelection");
  const repaymentPriority = localStorage.getItem("repayment_priority");

  const handleFormSubmit = () => {
    setLoading(true);

    // if (spendmonth == "") {
    //   alert("Please fill field.");
    //   return;
    // }else{

    // }
    const token = localStorage.getItem("token");
    if (token) {
      const creditScore = localStorage.getItem("creditScore");
      const form = new FormData();
      form.append("need_fund_for", selectedFundReason);
      form.append("amount_needed", moneyneed);
      form.append("loan_tenure", selectedrepay);
      form.append("monthly_income", selectedIncome);
      form.append("existing_emi", monthlySpend);
      form.append("collateral_investments", assets);
      form.append("co_applicant", coApplicantSelection);
      form.append("repayment_priority", repaymentPriority);
      form.append("credit_score", creditScore);

      const options = {
        method: "POST",
        url: "https://cardai.minemi.ai/loanRecommedation",
        headers: {
          "content-type": "multipart/form-data",
        },
        data: form,
      };

      axios
        .request(options)
        .then(function (response) {
          localStorage.setItem(
            "matchdata",
            JSON.stringify(response.data.matches[0].matches)
          );

          // You need to stringify before saving

          localStorage.setItem("attemept", true);
          setLoading(false);
          navigate("/loan-recommendation-page");
        })
        .catch(function (error) {
          setLoading(false);

          console.error(error);
        });
    } else {
      navigate("/loan-recommendation-login");
    }
  };

  const priorityOptions = [
    "Lower EMI (Longer tenure, affordable payments)",
    "Lower Interest (Shorter tenure, pay less in total)",
    "Quick Approval (Minimal paperwork, instant disbursal)",
  ];

  return (
    <>
      <div className="progress-bar-background" />
      <div className="progress-bar" style={{ width: `${progress}%` }} />
      <div className="min-h-[100vh] w-full pt-4 md:pt-8 px-4 md:px-6">
        {/* Header Navigation */}
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "4%",
            paddingRight: "4%",
          }}
        >
          <div
            style={{ display: "flex", gap: 8, cursor: "pointer" }}
            onClick={() => navigate("/apply-with-co-applicant")}
          >
            <FaArrowLeftLong style={{ fontSize: 20, color: "#8A8A8A" }} />
            <p style={{ fontSize: 16, color: "#8A8A8A", marginBottom: 0 }}>
              Back
            </p>
          </div>
          <IoClose
            style={{ fontSize: 24, color: "#8A8A8A", cursor: "pointer" }}
            onClick={() => setOpen(true)}
          />
        </div>

        {/* Main Content */}
        <div className="container mx-auto mt-8 md:mt-12">
          <h1 className="text-center font-['Archivo'] text-2xl md:text-3xl font-semibold text-black mb-8">
            What’s your repayment priority?
          </h1>

          <div
            className="flex flex-col sm:flex-row justify-center gap-4 md:gap-5 px-4"
            style={{ flexWrap: "wrap" }}
          >
            {priorityOptions.map((option, index) => (
              <button
                key={index}
                onClick={() => handleSelection(index, option)}
                className={`py-3 px-6 rounded-lg font-['Archivo'] shadow-md transition-all ${
                  active === index
                    ? "bg-[#E7E4F9] border-2 border-[#59266D]"
                    : "bg-white hover:bg-gray-50"
                }`}
              >
                {option}
              </button>
            ))}
          </div>

          <div className="mt-8 md:mt-12 flex justify-center px-4">
            <button
              style={{ fontFamily: "Archivo" }}
              onClick={handleFormSubmit}
              className="next-button"
            >
              {loading ? (
                <ColorRing
                  visible={loading}
                  height="30"
                  width="30"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{
                    backgroundColor: "transparent",
                  }}
                  wrapperClass="color-ring-wrapper"
                  className="color-ring-wrapper"
                  colors={[
                    "#ffffff",
                    "#ffffff",
                    "#ffffff",
                    "#ffffff",
                    "#ffffff",
                    "#ffffff",
                  ]}
                />
              ) : (
                "NEXT"
              )}
            </button>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <img src={logo} style={{ width: 40, height: 40 }} alt="Logo" />
            <IoClose
              style={{ fontSize: 24, cursor: "pointer" }}
              onClick={() => setOpen(false)}
            />
          </div>
          <Typography
            variant="h6"
            style={{
              color: "#59266D",
              fontSize: 24,
              fontWeight: 700,
              marginTop: 16,
            }}
          >
            Wait ! your loan recommendation is just a step away !
          </Typography>
          <Typography
            sx={{ mt: 2 }}
            style={{ color: "#8A8A8A", fontSize: 18, fontWeight: 400 }}
          >
            Complete the form to receive the best credit card tailored to your
            needs.
          </Typography>
          <div
            style={{
              backgroundColor: "#59266D",
              padding: 15,
              color: "#FFFFFF",
              borderRadius: 10,
              textAlign: "center",
              marginTop: "4%",
              fontWeight: 700,
              fontSize: 16,
              cursor: "pointer",
            }}
            onClick={() => setOpen(false)}
          >
            Keep Going
          </div>
          <div
            style={{
              backgroundColor: "#F6F6F6",
              padding: 15,
              color: "#59266D",
              borderRadius: 10,
              textAlign: "center",
              marginTop: "4%",
              fontWeight: 700,
              fontSize: 16,
              border: "1px solid #59266D",
              cursor: "pointer",
            }}
            onClick={() => navigate("/creditcard-recommendation")}
          >
            No Thanks
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Repaymentpriority;

import React, { useState } from "react";
import "./FAQ.css";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, useMediaQuery } from "@mui/material";
import { faqData } from "./faq.js";

const FAQ = () => {
  const [openAnswers, setOpenAnswers] = useState([]);

  const handleFAQ = (id) => {
    // Check if the id is already open; if it is, remove it, else add it
    setOpenAnswers((prevOpenAnswers) =>
      prevOpenAnswers.includes(id)
        ? prevOpenAnswers.filter((answerId) => answerId !== id)
        : [...prevOpenAnswers, id]
    );
  };

  const smallScreen = useMediaQuery("( max-width : 576px)");
  const mediumScreen = useMediaQuery("( max-width : 768px)");

  return (
    <>
      <div id="faq-page-container">
        <div style={{ backgroundColor: "" }} id="faq_container">
          <h1 className="faq-heading">Frequently asked questions</h1>
          <div
            style={{ border: "2px solid #E7E4F9", marginTop: "40px" }}
            className="w-100 border-start border-bottom-0 border-start-0 border-end-0 "
          >
            {faqData &&
              faqData.map((item) => (
                <div
                  key={item.id}
                  style={{ border: "2px solid #E7E4F9" }}
                  className="faq_container d-flex border-top-0 border-start-0 border-end-0"
                >
                  <div className="w-100">
                    <div
                      className="question_box py-3 d-flex justify-content-between w-100 "
                      onClick={() => handleFAQ(item.id)}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          fontSize: smallScreen
                            ? "14px"
                            : mediumScreen
                            ? "20px"
                            : "22px",
                          fontWeight: 700,
                          lineHeight: smallScreen
                            ? "14px"
                            : mediumScreen
                            ? "24px"
                            : "28px",
                          color: "#432C56",
                          fontFamily: "'Archivo',sans-serif",
                        }}
                      >
                        {item.question}
                      </Typography>
                      <Typography className="w-25 text-end">
                        <FontAwesomeIcon
                          icon={
                            openAnswers.includes(item.id)
                              ? faChevronUp
                              : faChevronDown
                          }
                          style={{
                            color: "#80387C",
                            fontSize: "20px",
                            // Extra shadow
                          }}
                        />
                      </Typography>
                    </div>
                    {openAnswers.includes(item.id) && (
                      <div className="answer_box mt-3">
                        <Typography
                          sx={{
                            fontSize: smallScreen
                              ? "12px"
                              : mediumScreen
                              ? "14px"
                              : "20px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            fontFamily: "'Archivo',sans-serif",
                          }}
                        >
                          {item.answer}
                        </Typography>
                        <ul
                          style={{
                            fontSize: 16,
                            lineHeight: "24px",
                            letterSpacing: "0.08px",
                            fontWeight: 500,
                            fontFamily: "'Archivo',sans-serif",
                          }}
                        >
                          {item.answerList &&
                            item.answerList.map((data, index) => (
                              <li key={index}>
                                <strong>{data.heading}</strong>
                                {data.info}
                              </li>
                            ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      {/* footer section */}

      {/* <div>
        <div style={{ backgroundColor: "#80387C" }} className="w-100 ">
          <div className="d-flex justify-content-center align-items-center pt-5 pb-3">
            <img style={{ width: "8rem", height: "8rem" }} src={Logo} alt="" />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default FAQ;

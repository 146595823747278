import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

export function RelatedContent({ name }) {
  const [data, setData] = useState({});

  const kotakData = useMemo(
    () => ({
      h1: "Explore our guides for tracking and improving loan approval",
      des: "Explore our guides for tracking and improving loan approval chances with other major banks",
      step: [
        {
          name: "SBI Personal Loan Status",
          url: "/sbi-personal-loan-status",
          description:
            "Track your State Bank of India personal loan application and improve approval odds",
        },
        {
          name: "HDFC Bank Personal Loan Status",
          url: "/hdfc-personal-loan-status",
          description:
            "Check your HDFC Bank personal loan application status and boost approval chances",
        },
        {
          name: "ICICI Bank Personal Loan Status",
          url: "/icici-personal-loan-status",
          description:
            "Monitor your ICICI Bank personal loan application and maximize approval odds",
        },
        {
          name: "Axis Bank Personal Loan Status",
          url: "/axis-personal-loan-status",
          description:
            "Track your Axis Bank personal loan application and improve approval chances",
        },
        {
          name: "Aditya Birla Finance Personal Loan Status",
          url: "/aditya-birla-personal-loan-status",
          description:
            "Check your Aditya Birla Finance personal loan status and boost approval odds",
        },
        {
          name: "YES Bank Personal Loan Status",
          url: "/yes-personal-loan-status",
          description:
            "Monitor your YES Bank personal loan application and maximize approval chances",
        },
        {
          name: "Bajaj Finserv Personal Loan Status",
          url: "/bajaj-personal-loan-status",
          description:
            "Track your Bajaj Finserv personal loan application and improve approval chances",
        },
      ],
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const idfcData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );

  useEffect(() => {
    if (name === "checkkotakbankstatus") {
      setData(kotakData);
    } else if (name === "checkbobbankstatus") {
      setData(bobData);
    } else if (name === "checkidfcbankstatus") {
      setData(idfcData);
    } else if (name === "checkhdfcbankstatus") {
      setData(hdfcData);
    } else if (name === "checksbibankstatus") {
      setData(sbiData);
    } else if (name === "checkaxisbankstatus") {
      setData(axisData);
    } else if (name === "checkicicibankstatus") {
      setData(iciciData);
    } else if (name === "checkpunjabbankstatus") {
      setData(punjabData);
    } else if (name === "checkinduslndbankstatus") {
      setData(induslndData);
    } else if (name === "checktatabankstatus") {
      setData(tataData);
    } else if (name === "checkyesbankstatus") {
      setData(yesData);
    } else if (name === "checkunionbankstatus") {
      setData(unionData);
    } else if (name === "checkbajajbankstatus") {
      setData(bajajData);
    } else if (name === "checkfederalbankstatus") {
      setData(federalData);
    } else if (name === "checkcanarabankstatus") {
      setData(canaraData);
    } else if (name === "checkboibankstatus") {
      setData(boiData);
    } else if (name === "checkrblbankstatus") {
      setData(rblData);
    } else {
      setData({});
    }
  }, [
    name,
    axisData,
    bobData,
    hdfcData,
    iciciData,
    idfcData,
    kotakData,
    sbiData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
  ]);

  return (
    <>
      {data.title == null ? null : (
        <section className="py-10 border-b border-gray-200 bg-[#ebe8fb]">
          <div className="container px-4 md:px-6 bg-transparent">
            <div className="w-4/5l mx-auto bg-transparent">
              <div className="text-center mb-6 bg-transparent">
                <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple bg-transparent">
                  {data.h1}
                </h2>
                <p className="text-dark-grey mt-2 text-sm bg-transparent text-center">
                  {data.des}
                </p>
              </div>

              <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 bg-transparent">
                {data.step?.map((d, index) => (
                  <Link
                    key={index}
                    to={d.url} // Use `to` instead of `href`
                    className="block"
                  >
                    <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 hover:border-teal transition-colors">
                      <div
                        className="h-10 w-10 rounded-full bg-light-bg flex items-center justify-center mb-3 mx-auto"
                        style={{ backgroundColor: "#ebe8fb" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="text-primary-purple"
                          style={{ backgroundColor: "#ebe8fb" }}
                        >
                          <rect width="20" height="14" x="2" y="5" rx="2" />
                          <line x1="2" x2="22" y1="10" y2="10" />
                        </svg>
                      </div>
                      <h3
                        className="text-base font-bold mb-2 text-center"
                        style={{ color: "#37155a" }}
                      >
                        {d.name}
                      </h3>
                      <p className="text-dark text-sm text-center bg-transparent">
                        {d.description}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

import React from "react";
import Eligibility from "./Eligibility";
import CreditScore from "./CreditScore";
import Approval from "./Approval";
import Faq from "./Faq";

const index = () => {
  return (
    <>
      <Eligibility />
      <CreditScore />
      <Approval />
      <Faq />
    </>
  );
};

export default index;

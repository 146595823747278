import React, { useEffect, useRef } from "react";
import HeroSection from "./HeroSection";
import EMICalculator from "./EMICalculator";
import LoanFilterAndSort from "./LoanFilterAndSort";
import TestimonialSection from "./TestimonialSection ";
import FAQ from "./FAQ";
import FinalCTA from "./finalCTA";
import { Helmet } from "react-helmet";

const MainOffer = () => {
  const loanFilterRef = useRef(null);

  const scrollToFilter = () => {
    if (loanFilterRef.current) {
      loanFilterRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Best Personal Loan Offers - Minemi </title>
        <meta
          name="description"
          content="Discover the best personal loan offers with Minemi . Apply now for quick and easy approvals."
        />
      </Helmet>

      <div className=" pt-5" style={{ backgroundColor: "#f8f8f8" }}>
        <HeroSection scrollToFilter={scrollToFilter} />

        {/* Wrap LoanFilterAndSort inside a div with ref */}
        <div ref={loanFilterRef}>
          <LoanFilterAndSort />
        </div>

        <EMICalculator />
        <TestimonialSection />
        <FAQ />
        <FinalCTA />
      </div>
    </>
  );
};

export default MainOffer;

import React, { useContext, useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import logo from ".././assets/Images/mLogo.webp";
import MetadataContext from "../../MetadataContext";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "#F6F6F6",
  boxShadow: 24,
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 2,
  paddingRight: 2,
  border: 0,
};
const options = [
  "Short-term ( 0 - 2 years - Higher EMIs, Less Interest paid)",
  "Medium-term  (2 - 5 years - Balanced EMIs & Interest paid)",
  "Long-term (5+ years - Lower EMIs, More Interest paid)",
];

const AssetsSelect = () => {
  const [progress, setProgress] = useState(40);
  const [active, setActive] = useState(0); // Default to first option
  const navigate = useNavigate();
  const location = useLocation();
  const { whyneed, moneyneed } = location.state || {};
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(options[0]); // Default first option

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Retrieve saved selection from localStorage
    const storedValue = localStorage.getItem("selectedrepay");
    const storedIndex = localStorage.getItem("selectedLoanIndex");

    if (storedValue && storedIndex !== null) {
      setValue(storedValue);
      setActive(parseInt(storedIndex));
    } else {
      // If no saved selection, set default (index 0)
      localStorage.setItem("selectedrepay", options[0]);
      localStorage.setItem("selectedLoanIndex", 0);
      setValue(options[0]);
      setActive(0);
    }
  }, []);

  const handleSelection = (index, selectedValue) => {
    setActive(index);
    setValue(selectedValue);

    // Store selection in localStorage
    localStorage.setItem("selectedrepay", selectedValue);
    localStorage.setItem("selectedLoanIndex", index);
  };

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Select Assets - Minemi",
      pageDescription:
        "Select your assets with Minemi  and get tailored financial solutions for your needs.",
      pageKeywords:
        "select assets, asset management, financial assets, asset selection tool, asset-based loans, asset planning, asset allocation, asset tracking, financial planning tools",

      canonicalUrl: "https://minemi.ai/select-assets",
    });
  }, [setMetadata]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div className="progress-bar-background" />
        <div className="progress-bar" style={{ width: `${progress}%` }} />
        <div className="min-h-[100vh] w-full pt-4 md:pt-8 px-4 md:px-6">
          {/* Header Navigation */}
          <div
            className="container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "4%",
              paddingRight: "4%",
            }}
          >
            <div
              style={{ display: "flex", gap: 8, cursor: "pointer" }}
              onClick={() => navigate("/select-prefer")}
            >
              <FaArrowLeftLong style={{ fontSize: 20, color: "#8A8A8A" }} />
              <p style={{ fontSize: 16, color: "#8A8A8A", marginBottom: 0 }}>
                Back
              </p>
            </div>
            <div onClick={() => setOpen(true)}>
              <IoClose
                style={{ fontSize: 24, color: "#8A8A8A", cursor: "pointer" }}
              />
            </div>
          </div>

          {/* Main Content */}
          <div className=" mx-auto mt-8 md:mt-12">
            <h1 className="text-center text-2xl md:text-3xl font-semibold text-black mb-8">
              How long do you want to repay this loan?
            </h1>

            {/* Selection Buttons */}
            <div
              className="flex flex-col sm:flex-row justify-center gap-4 md:gap-5 px-1 px-sm-4"
              style={{ flexWrap: "wrap" }}
            >
              {options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleSelection(index, option)}
                  className={`px-6 rounded-lg font-['Archivo'] shadow-md transition-all ${
                    active === index
                      ? "bg-[#E7E4F9] border-2 border-[#59266D]"
                      : "bg-white hover:bg-gray-50"
                  }`}
                  style={{ paddingTop: "13px", paddingBottom: "13px" }}
                >
                  {option}
                </button>
              ))}
            </div>

            {/* Next Button */}
            <div className="mt-8 md:mt-12 flex justify-center px-4">
              <button
                style={{ fontFamily: "Archivo" }}
                // onClick={() => navigate("/select-prefer")}
                onClick={() => navigate("/select-existing-emi")}
                className="next-button"
              >
                NEXT
              </button>
            </div>
          </div>
        </div>

        {/* Modal */}
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-title"
        >
          <Box sx={style}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src={logo} style={{ width: 40, height: 40 }} alt="Logo" />
              <IoClose
                onClick={() => setOpen(false)}
                style={{ fontSize: 24, cursor: "pointer" }}
              />
            </div>
            <h2
              id="modal-title"
              style={{
                color: "#59266D",
                fontSize: 24,
                fontWeight: 700,
                marginTop: 16,
              }}
            >
              Wait ! your loan recommendation is just a step away !
            </h2>
            <p style={{ color: "#8A8A8A", fontSize: 18, fontWeight: 400 }}>
              Complete the form to receive the best credit card tailored to your
              needs.
            </p>
            <div
              style={{
                backgroundColor: "#59266D",
                padding: 15,
                color: "#FFF",
                borderRadius: 10,
                textAlign: "center",
                marginTop: "4%",
                fontWeight: 700,
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            >
              Keep Going
            </div>
            <div
              style={{
                backgroundColor: "#F6F6F6",
                padding: 15,
                color: "#59266D",
                borderRadius: 10,
                textAlign: "center",
                marginTop: "4%",
                fontWeight: 700,
                border: "1px solid #59266D",
                cursor: "pointer",
              }}
              onClick={() => navigate("/creditcard-recommendation")}
            >
              No Thanks
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default AssetsSelect;

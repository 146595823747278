import React from "react";
import "./index.css";
import Accordion from "react-bootstrap/Accordion";

const Index = () => {
  const accordionData = [
    {
      title: "What does my credit score indicate? ",
      content: (
        <p className="m-0">
         Your credit score represents your creditworthiness and financial history. A higher score improves your chances of loan approval at lower interest rates
        </p>
      ),
    },
    {
      title: "How does Minemi assess my loan eligibility? ",
      content: (
        <div>
         Minemi evaluates your credit score, existing loans, repayment history, income, and other financial factors to determine your chances of approval. 
        </div>
      ),
    },
    {
      title: "What factors influence my credit score? ",
      content: (
        <p className="m-0">
         Your credit score is based on your payment history, credit utilization, loan inquiries, length of credit history, and credit mix. 
        </p>
      ),
    },
    {
      title: "Why is my loan approval probability low despite a good credit score? ",
      content: (
        <div>
       Lenders consider multiple factors beyond credit score, such as high existing EMIs, frequent loan applications, unstable income, or unsecured loan dependency. 
        </div>
      ),
    }, 
       {
      title: "What insights does Minemi provide in the Credit Pro section?  ",
      content: (
        <div>
Minemi offers insights on your loan eligibility, risk factors, high-interest debts, opportunities to improve your score, and recommended credit actions.         </div>
      ),
    },  
       {
      title: "Can I improve my loan approval chances?   ",
      content: (
        <div>
Yes! Paying existing dues on time, reducing unsecured loans, limiting new credit applications, and maintaining a low credit utilization ratio can help.         </div>
      ),
    },   
       {
      title: "Why are some factors beyond my control affecting my eligibility?  ",
      content: (
        <div>
Lenders may have specific policies regarding employment type, income consistency, or industry risk factors that affect loan approvals.        </div>
      ),
    }, 
         {
      title: "What should I do if my credit score is low?  ",
      content: (
        <div>Work on timely repayments, avoid maxing out credit cards, reduce unnecessary loan inquiries, and maintain a healthy mix of secured and unsecured credit. 
</div>
      ),
    },
    {
      title: "Can Minemi suggest the best loan options based on my credit score? ",
      content: (
        <div>Yes, Minemi provides tailored recommendations for loans, balance transfers, and top-up options based on your financial profile. 
</div>
      ),
    },    {
      title: "How does Minemi determine my recommended credit actions? ",
      content: (
        <div>Minemi uses AI-based algorithms to analyze your credit profile and suggests actions like reducing FOIR, consolidating loans, or negotiating better interest rates. 
</div>
      ),
    },
    {
      title: "Will checking my credit score on Minemi impact my score? ",
      content: (
        <div>No, checking your credit score on Minemi is a soft inquiry and does not affect your score. Only lender inquiries impact your credit score. </div>
      ),
    }, 
       {
      title: "How often should I check my credit score and eligibility?  ",
      content: (
        <div>It’s advisable to check your score and eligibility every few months or before applying for a loan to ensure better approval chances and terms. </div>
      ),
    },
  ];
  return (
    <>
      <div id="faq" >
        <div className="contentCon" >
          <p id="faq-title" style={{backgroundColor:'transparent',marginBottom:'2%',lineHeight:1.3}}>
            Frequently Asked Questions (FAQs) 
          </p>

          <div className="accordion-con" style={{backgroundColor:'transparent'}}>
            <Accordion defaultActiveKey="0" style={{backgroundColor:'transparent'}}>
              {accordionData.map((item, index) => (
                <Accordion.Item eventKey={index} key={index}  style={{gap:8}}>
                  <Accordion.Header style={{lineHeight:1.5}}>{item.title}</Accordion.Header>
                  <Accordion.Body style={{ visibility: "visible" }}>
                    {item.content}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;

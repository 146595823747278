import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Shield, Clock, ThumbsUp, Lock, CreditCard, BarChart, AlertCircle, FileText, Building, ArrowRight, MessageCircle, ChevronDown } from 'lucide-react';

function FAQSection() {
  // State to track which FAQ is expanded
  const [expandedId, setExpandedId] = useState(null);

  // Toggle function for accordion
  const toggleAccordion = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const faqs = [
    {
      id: 'how-check',
      question: 'How does Minemi check my card eligibility?',
      answer: 'Minemi analyzes key factors like income, credit score, and financial profile using our advanced AI-powered system. We match your details with real-time eligibility criteria from top banks to provide accurate results.',
      icon: <BarChart className="accordion-icon" />
    },
    {
      id: 'credit-score',
      question: 'Will checking my eligibility affect my credit score?',
      answer: 'No, checking your eligibility on Minemi is a soft inquiry, meaning it does not impact your credit score in any way.',
      icon: <ThumbsUp className="accordion-icon" />
    },
    {
      id: 'data-safe',
      question: 'Is my data safe with Minemi?',
      answer: 'We use bank-level encryption and never share your personal information with third parties. Your privacy and security are our top priorities.',
      icon: <Shield className="accordion-icon" />
    },
    {
      id: 'accuracy',
      question: 'How accurate are Minemi\'s card eligibility results?',
      answer: 'Our system is powered by the latest AI-driven analytics and real-time data from banks, ensuring 97%+ accuracy in eligibility predictions.',
      icon: <Lock className="accordion-icon" />
    },
    {
      id: 'factors',
      question: 'What factors determine my credit card eligibility?',
      answer: 'Key factors include credit score, income, employment type, existing loans, and repayment history. Each bank has its own criteria, and Minemi helps you find the best fit.',
      icon: <CreditCard className="accordion-icon" />
    },
    {
      id: 'not-eligible',
      question: 'What if I\'m not eligible for any cards?',
      answer: 'If you\'re not eligible right now, we\'ll provide personalized recommendations on how to improve your chances. You can check back anytime after making improvements!',
      icon: <AlertCircle className="accordion-icon" />
    },
    {
      id: 'documents',
      question: 'Do I need to submit any documents to check my eligibility?',
      answer: 'No documents are required! Just enter basic details, and we\'ll instantly show you your eligibility results.',
      icon: <FileText className="accordion-icon" />
    },
    {
      id: 'banks',
      question: 'Which banks\' credit cards does Minemi check eligibility for?',
      answer: 'We cover all major Indian banks, including HDFC, ICICI, SBI, Axis, Kotak, and more, to give you the widest range of options.',
      icon: <Building className="accordion-icon" />
    },
    {
      id: 'time',
      question: 'How long does it take to check my eligibility?',
      answer: 'Less than 60 seconds! Just enter your details, and we\'ll instantly show your best matches.',
      icon: <Clock className="accordion-icon" />
    },
    {
      id: 'after-check',
      question: 'What happens after I check my eligibility?',
      answer: 'Once you find a card you\'re eligible for, you can apply directly through Minemi with a seamless, hassle-free process.',
      icon: <ArrowRight className="accordion-icon" />
    }
  ];

  // Styles
  const styles = {
    section: {
      padding: '2rem 1rem',
      backgroundColor: 'white',
      fontFamily: 'Arial, sans-serif',
    },
    container: {
      maxWidth: '1000px',
      margin: '0 auto',
    },
    header: {
      textAlign: 'center',
      marginBottom: '2rem',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#311664',
      marginBottom: '0.5rem',
    },
    subtitle: {
      fontSize: '0.875rem',
      color: '#666',
      maxWidth: '600px',
      margin: '0 auto',
    },
    accordionContainer: {
      backgroundColor: 'rgba(235, 232, 252, 0.3)',
      borderRadius: '0.75rem',
      padding: '1rem',
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    },
    accordionItem: {
      marginBottom: '0.5rem',
      borderRadius: '0.5rem',
      overflow: 'hidden',
      backgroundColor: 'white',
      border: '1px solid rgba(235, 232, 252, 1)',
      boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
    },
    accordionButton: {
      width: '100%',
      textAlign: 'left',
      padding: '0.75rem 1rem',
      backgroundColor: 'white',
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      transition: 'background-color 0.2s',
    },
    accordionButtonHover: {
      backgroundColor: 'rgba(235, 232, 252, 0.2)',
    },
    iconContainer: {
      backgroundColor: 'rgba(235, 232, 252, 1)',
      padding: '0.375rem',
      borderRadius: '0.375rem',
      marginRight: '0.75rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
    },
    icon: {
      width: '1rem',
      height: '1rem',
      color: '#3a868b',
    },
    question: {
      fontSize: '0.875rem',
      fontWeight: '500',
      color: '#311664',
      flex: 1,
    },
    chevron: {
      width: '1rem',
      height: '1rem',
      transition: 'transform 0.3s',
    },
    chevronRotated: {
      transform: 'rotate(180deg)',
    },
    accordionContent: {
      padding: '0.75rem 1rem 1rem',
      backgroundColor: 'white',
      fontSize: '0.875rem',
      color: '#666',
    },
    answer: {
      paddingLeft: '2.5rem',
    },
    ctaContainer: {
      textAlign: 'center',
      marginTop: '2rem',
    },
    ctaText: {
      fontSize: '1rem',
      fontWeight: '500',
      color: '#311664',
      marginBottom: '0.75rem',
    },
    button: {
      backgroundColor: '#311664',
      color: 'white',
      border: 'none',
      borderRadius: '0.375rem',
      padding: '0.5rem 1rem',
      fontSize: '0.875rem',
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      gap: '0.5rem',
      transition: 'background-color 0.2s',
    },
    buttonHover: {
      backgroundColor: '#26124f',
    },
  };

  // Media queries for responsive design
  if (typeof window !== 'undefined') {
    if (window.innerWidth >= 768) {
      // Tablet and desktop styles
      styles.section.padding = '4rem 2rem';
      styles.title.fontSize = '2.25rem';
      styles.subtitle.fontSize = '1.125rem';
      styles.accordionContainer.padding = '2rem';
      styles.accordionButton.padding = '1rem 1.5rem';
      styles.question.fontSize = '1.125rem';
      styles.accordionContent.padding = '1rem 1.5rem 1.5rem';
      styles.accordionContent.fontSize = '1rem';
      styles.answer.paddingLeft = '3.5rem';
      styles.icon.width = '1.25rem';
      styles.icon.height = '1.25rem';
      styles.iconContainer.padding = '0.5rem';
    }
  }

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          style={styles.header}
        >
          <h2 style={styles.title}>Got Questions? We've Got Answers!</h2>
          <p style={styles.subtitle}>
            Find answers to commonly asked questions about Minemi's credit card eligibility checker.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          style={styles.accordionContainer}
        >
          {faqs.map((faq, index) => (
            <motion.div
              key={faq.id}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.4, delay: index * 0.05 }}
              style={styles.accordionItem}
            >
              <button
                onClick={() => toggleAccordion(faq.id)}
                style={{
                  ...styles.accordionButton,
                  ...(expandedId === faq.id ? styles.accordionButtonHover : {})
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = styles.accordionButtonHover.backgroundColor;
                }}
                onMouseLeave={(e) => {
                  if (expandedId !== faq.id) {
                    e.currentTarget.style.backgroundColor = styles.accordionButton.backgroundColor;
                  }
                }}
                aria-expanded={expandedId === faq.id}
                aria-controls={`content-${faq.id}`}
              >
                <div style={styles.iconContainer}>
                  {React.cloneElement(faq.icon, { style: styles.icon })}
                </div>
                <span style={styles.question}>{faq.question}</span>
                <ChevronDown
                  style={{
                    ...styles.chevron,
                    ...(expandedId === faq.id ? styles.chevronRotated : {})
                  }}
                />
              </button>
              
              {expandedId === faq.id && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  style={styles.accordionContent}
                  id={`content-${faq.id}`}
                >
                  <div style={styles.answer}>{faq.answer}</div>
                </motion.div>
              )}
            </motion.div>
          ))}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
          style={styles.ctaContainer}
        >
          <p style={styles.ctaText}>Still have questions?</p>
          <button
            style={styles.button}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = styles.button.backgroundColor;
            }}
          >
            <MessageCircle size={16} />
            Contact Support
          </button>
        </motion.div>
      </div>
    </section>
  );
}

export default FAQSection;
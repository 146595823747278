import React from 'react'
import LoanOverview from './LoanOverview';
import { useLocation } from 'react-router-dom';
import Eligibility from '../CommonComponents/Eligibility/Index'

const Index = () => {
  const location = useLocation();
const { cibil, name, amount, runningLoan,secured,unsecured } = location.state || {};


  return (
    <>
      <Eligibility data={{ cibil, name, amount, runningLoan ,secured,unsecured}} /> 
      <LoanOverview /> 
    </>
  )
}

export default Index;
import { useLocation, useParams } from "react-router-dom";
import { LoanPredictorWidget } from "./LoanPredictorWidget";
import { LoanStatusChecker } from "./LoanStatusChecker";
import { LoanStatusMethods } from "./LoanStatusMethods";
import { StatusExplanation } from "./StatusExplanation";

import { MinemiTools } from "./MinemiTools";
import { Testimonials } from "./Testimonials";
import { RelatedContent } from "./RelatedContent";
import { TrustIndicators } from "./TrustIndicators";
import { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import Homeloan from "../Homeloanstatus/Homeloan";

import Faq from "./Faq";
// import NotFoundPage from "../../ErrorPages/NotFoundPage";
// console.log("hie");
// import MetadataContext from "../MetadataContext";
export default function Kotakbank() {
  const { bankdetails } = useParams();
  const [data, setData] = useState(null);
  const [notFound, setNotFound] = useState(false);
  // const { setMetadata } = useContext(MetadataContext);

  const kotakdata = useMemo(
    () => ({
      heading:
        "Check Your Kotak Mahindra Bank Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your Kotak Mahindra Bank Home Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",
      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your Kotak Mahindra Bank Home Loan Journey",
      h2des:
        "Checking your Kotak Mahindra Bank Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      heading:
        "Check Your Bank of Baroda Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your Bank of Baroda Home Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",
      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your Bank of Baroda Home Loan Journey",
      h2des:
        "Checking your Bank of Baroda Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <circle cx="12" cy="12" r="10" />
              <path d="M12 6v6l4 2" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const idfcData = useMemo(
    () => ({
      heading:
        "Check Your IDFC FIRST Bank Home Loan Status Online - Track Your Application & Get Real-Time Updates! ",
      paragraph:
        "Easily track your IDFC FIRST Bank Home Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",
      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your IDFC FIRST Bank Home Loan Journey",
      h2des:
        "Checking your IDFC FIRST Bank Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      heading:
        "Check Your HDFC Bank Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your HDFC Bank Home Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "HDFC Bank Digital Home Loan Features",
      h2des:
        "Experience the convenience of HDFC Bank's digital-first loan process",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Paperless Application",
          description:
            "Apply for a home loan completely online through HDFC Bank NetBanking without any physical paperwork.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Quick Approval",
          description:
            "HDFC Bank offers approvals in as little as 48 hours for salary account holders with excellent credit profiles.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Instant Sanction",
          description:
            "Get your loan sanctioned by HDFC Bank within hours of submitting final documents and receiving approval confirmation.",
        },
      ],
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      heading:
        "Check Your SBI Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your State Bank of India Home Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your SBI Home Loan Journey",
      h2des:
        "Checking your SBI Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      heading:
        "Check Your Axis Bank Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your Axis Bank Home Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your Axis Bank Home Loan Journey",
      h2des:
        "Checking your Axis Bank Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      heading:
        "Check Your ICICI Bank Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your ICICI Bank Home Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your ICICI Bank Home Loan Journey",
      h2des:
        "Checking your ICICI Bank Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      heading:
        "Check Your Punjab National Bank Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your Punjab National Bank Home Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your Punjab National Bank Home Loan Journey",
      h2des:
        "Checking your Punjab National Bank Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            " Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      heading:
        "Check Your IndusInd Bank Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your IndusInd Bank Home Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your IndusInd Bank Home Loan Journey",
      h2des:
        "Checking your IndusInd Bank Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      heading:
        "Check Your Tata Housing Finance Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your Tata Housing Finance Home Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now   ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your Tata Housing Finance Home Loan Journey",
      h2des:
        "Checking your Tata Housing Finance Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      heading:
        "Check Your Yes Bank Home Loan Status Online - Avoid Rejection & Get Real-Time Updates!",
      paragraph:
        "Monitor your YES Bank home loan application in real-time and use MinEMI's specialized tools to enhance your approval chances and secure the most competitive interest rates.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your YES Bank Home Loan Journey with Confidence",
      h2des:
        "YES Bank's home loan process becomes seamless with MinEMI's specialized tools and expert guidance. Track your application status and receive tailored recommendations at every stage of your home buying journey.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Real-Time Status Tracking",
          description:
            "Monitor your YES Bank home loan application status securely with instant updates at every processing stage.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Property Insights",
          description:
            "Receive expert guidance on property documentation and valuation to enhance your YES Bank home loan approval chances.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Access YES Bank home loan specialists who understand the bank's internal approval criteria and decision-making process.",
        },
      ],
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      heading:
        "Check Your Union Bank Home Loan Status Online - Avoid Rejection & Get Real-Time Updates!",
      paragraph:
        "Monitor your Union Bank home loan application in real-time and use MinEMI's specialized tools to enhance your approval chances and secure the most competitive interest rates.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your Union Bank Home Loan Journey with Confidence",
      h2des:
        "Union Bank's home loan process becomes seamless with MinEMI's specialized tools and expert guidance. Track your application status and receive tailored recommendations at every stage of your home buying journey.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Real-Time Status Tracking",
          description:
            "Monitor your Union Bank home loan application status securely with instant updates at every processing stage.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Government Scheme Benefits",
          description:
            "Get expert guidance on PMAY and other government subsidies available with Union Bank home loans.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Access Union Bank home loan specialists who understand the bank's internal approval criteria and decision-making process.",
        },
      ],
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      heading:
        "Check Your Bajaj Finance Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Monitor your Bajaj Finance home loan application in real-time and use MinEMI's specialized tools to enhance your approval chances and secure the most competitive interest rates.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your Bajaj Finance Home Loan Journey with Confidence",
      h2des:
        "Bajaj Finance's home loan process becomes seamless with MinEMI's specialized tools and expert guidance. Track your application status and receive tailored recommendations at every stage of your home buying journey.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Digital-First Experience",
          description:
            "Monitor your Bajaj Finance home loan application status securely with their advanced digital platform and instant updates.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Quick Approval Process",
          description:
            "Benefit from Bajaj Finance's streamlined approval process with minimal documentation and faster disbursals.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Access Bajaj Finance home loan specialists who understand the company's internal approval criteria and decision-making process.",
        },
      ],
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      heading:
        "Check Your Federal Bank Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Monitor your Federal Bank home loan application in real-time and use MinEMI's specialized tools to enhance your approval chances and secure the most competitive interest rates.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your Federal Bank Home Loan Journey with Confidence",
      h2des:
        "Federal Bank's home loan process becomes seamless with MinEMI's specialized tools and expert guidance. Track your application status and receive tailored recommendations at every stage of your home buying journey.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Transparent Processing",
          description:
            "Monitor your Federal Bank home loan application status with complete transparency at every stage of processing.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Doorstep Service",
          description:
            "Benefit from Federal Bank's unique doorstep service for document collection and verification for a hassle-free experience.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Access Federal Bank home loan specialists who understand the bank's internal approval criteria and decision-making process.",
        },
      ],
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      heading:
        "Check Your Canara Bank Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Monitor your Canara Bank home loan application in real-time and use MinEMI's specialized tools to enhance your approval chances and secure the most competitive interest rates.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your Canara Bank Home Loan Journey with Confidence",
      h2des:
        "Canara Bank's home loan process becomes seamless with MinEMI's specialized tools and expert guidance. Track your application status and receive tailored recommendations at every stage of your home buying journey.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Competitive Interest Rates",
          description:
            "Benefit from Canara Bank's competitive home loan interest rates with special concessions for various customer segments.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Minimal Documentation",
          description:
            "Experience Canara Bank's streamlined documentation process with their Can Fin Homes subsidiary for faster approvals.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Dedicated Relationship Managers",
          description:
            "Access Canara Bank's dedicated home loan specialists who provide personalized guidance throughout your loan journey.",
        },
      ],
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      heading:
        "Check Your Bank of India Bank Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Monitor your Bank of India home loan application in real-time and use MinEMI's specialized tools to enhance your approval chances and secure the most competitive interest rates.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your Bank of India Home Loan Journey with Confidence",
      h2des:
        "Bank of India's home loan process becomes seamless with MinEMI's specialized tools and expert guidance. Track your application status and receive tailored recommendations at every stage of your home buying journey.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Attractive Interest Rates",
          description:
            "Benefit from Bank of India's competitive home loan interest rates with special concessions for various customer segments.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Flexible Repayment Options",
          description:
            "Choose from multiple repayment options with Bank of India's customizable EMI plans and tenure flexibility.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Dedicated Loan Officers",
          description:
            "Access Bank of India's specialized home loan officers who provide personalized guidance throughout your loan journey.",
        },
      ],
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      heading:
        "Check Your RBL Bank Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Monitor your RBL Bank home loan application in real-time and use MinEMI's specialized tools to enhance your approval chances and secure the most competitive interest rates",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your RBL Bank Home Loan Journey with Confidence",
      h2des:
        "RBL Bank's home loan process becomes seamless with MinEMI's specialized tools and expert guidance. Track your application status and receive tailored recommendations at every stage of your home buying journey.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Digital-First Experience",
          description:
            "Enjoy RBL Bank's seamless digital application process with minimal paperwork and quick approvals.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Competitive Interest Rates",
          description:
            "Benefit from RBL Bank's attractive home loan interest rates with special offers for premium customers.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Personalized Service",
          description:
            "Access RBL Bank's dedicated relationship managers who provide customized guidance throughout your loan journey.",
        },
      ],
    }),
    []
  );
  const ltData = useMemo(
    () => ({
      heading:
        "Check Your L&T Finance Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        " Monitor your L&T Finance home loan application in real-time and use MinEMI's specialized tools to enhance your approval chances and secure the most competitive interest rates.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your L&T Finance Home Loan Journey with Confidence",
      h2des:
        " L&T Finance's home loan process becomes seamless with MinEMI's specialized tools and expert guidance.Track your application status and receive tailored recommendations at every stage of your home buying journey.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "PMAY Benefits",
          description:
            "Access Pradhan Mantri Awas Yojana benefits through L&T Finance with interest subsidies up to ₹2.67 lakhs.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Flexible Repayment",
          description:
            "Enjoy L&T Finance's flexible EMI options with loan tenures extending up to 30 years for easier repayment.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Specialized Programs",
          description:
            " Access L&T Finance's tailored home loan programs for salaried professionals with preferential interest rates.",
        },
      ],
    }),
    []
  );
  const idbiData = useMemo(
    () => ({
      heading:
        " Check Your IDBI Home Loan Status Online - Track Application & Get Expert Guidance!",
      paragraph:
        " Easily track your IDBI Home Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your IDBI Home Loan Journey with Confidence",
      h2des:
        " Checking your IDBI Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            " Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const lichousingData = useMemo(
    () => ({
      heading:
        " Check Your LIC Housing Finance Home Loan Status Online - Track Application & Get Expert Guidance!",
      paragraph:
        "  Easily track your LIC Housing Finance Home Loan application status using MinEMI's smart tools.Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: " Simplify Your LIC Housing Finance Home Loan Journey",
      h2des:
        "Checking your LIC Housing Finance Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "  Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            " Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const icicihousingData = useMemo(
    () => ({
      heading:
        " Check Your ICICI Home Finance Home Loan Status Online - Track Application & Get Expert Guidance!",
      paragraph:
        "  Easily track your ICICI Home Finance Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your ICICI Home Finance Loan Journey",
      h2des:
        "Checking your ICICI Home Finance Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "  Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            " Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const pnbhousingData = useMemo(
    () => ({
      heading:
        " Check  PNB Housing Finance Home Loan Status Online - Track Application & Get Expert Guidance!",
      paragraph:
        "  Easily track your PNB Housing Finance Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your PNB Housing Finance Loan Journey",
      h2des:
        "Checking your PNB Housing Finance Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "  Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            " Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const hdfclnthousingData = useMemo(
    () => ({
      heading:
        " Check HDFC Ltd. Home Loan Status Online - Track Application & Get Expert Guidance!",
      paragraph:
        " Monitor your HDFC Ltd. home loan application status in real-time with MinEMI's intelligent tracking tools. Get personalized insights to improve your approval odds.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your HDFC Ltd. Home Loan Journey with Confidence",
      h2des:
        "Tracking your HDFC Ltd. home loan status is now seamless and stress-free. MinEMI's smart tools help you stay informed and improve your approval prospects.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Private & Secure",
          description:
            "Check your home loan status with complete privacy and bank-level data protection.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Instant Updates",
          description:
            "Receive immediate notifications when your home loan application status changes.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Smart Guidance",
          description:
            " Get AI-powered recommendations to enhance your home loan approval probability.",
        },
      ],
    }),
    []
  );
  const sbihomehousingData = useMemo(
    () => ({
      heading:
        " Track Your SBI Home Loan Status - Get Instant Updates & Maximize Approval Chances!",
      paragraph:
        " Monitor your State Bank of India home loan application status in real-time with MinEMI's intelligent tracking tools. Get personalized insights to improve your approval odds.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your SBI Home Loan Journey with Confidence",
      h2des:
        "Tracking your SBI home loan status is now seamless and stress-free. MinEMI's smart tools help you stay informed and improve your approval prospects.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Private & Secure",
          description:
            "Check your home loan status with complete privacy and bank-level data protection.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Instant Updates",
          description:
            "Receive immediate notifications when your home loan application status changes.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Smart Guidance",
          description:
            " Get AI-powered recommendations to enhance your home loan approval probability.",
        },
      ],
    }),
    []
  );
  const adityahomehousingData = useMemo(
    () => ({
      heading:
        " Track Your Aditya Birla Finance Home Loan Status - Get Instant Updates & Maximize Approval Chances!",
      paragraph:
        " Monitor your Aditya Birla Finance home loan application status in real-time with MinEMI's intelligent tracking tools. Get personalized insights to improve your approval odds.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "/trackloanstatus",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your Aditya Birla Finance Home Loan Journey with Confidence",
      h2des:
        "Tracking your Aditya Birla Finance home loan status is now seamless and stress-free. MinEMI's smart tools help you stay informed and improve your approval prospects.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Private & Secure",
          description:
            "Check your home loan status with complete privacy and bank-level data protection.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Instant Updates",
          description:
            "Receive immediate notifications when your home loan application status changes.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Smart Guidance",
          description:
            " Get AI-powered recommendations to enhance your home loan approval probability.",
        },
      ],
    }),
    []
  );

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    if (bankdetails === "checkkotakhomeloanbankstatus") {
      setData(kotakdata);
      setNotFound(false);
    } else if (bankdetails === "checkbobhomeloanbankstatus") {
      setData(bobData);
      setNotFound(false);
    } else if (bankdetails === "checkidfchomeloanbankstatus") {
      setData(idfcData);
      setNotFound(false);
    } else if (bankdetails === "checkhdfchomeloanbankstatus") {
      setData(hdfcData);
      setNotFound(false);
    } else if (bankdetails === "checksbihomeloanbankstatus") {
      setData(sbiData);
      setNotFound(false);
    } else if (bankdetails === "checkaxishomeloanbankstatus") {
      setData(axisData);
      setNotFound(false);
    } else if (bankdetails === "checkicicihomeloanbankstatus") {
      setData(iciciData);
      setNotFound(false);
    } else if (bankdetails === "checkpunjabhomeloanbankstatus") {
      setData(punjabData);
      setNotFound(false);
    } else if (bankdetails === "checkinduslndhomeloanbankstatus") {
      setData(induslndData);
      setNotFound(false);
    } else if (bankdetails === "checktatahomeloanbankstatus") {
      setData(tataData);
      setNotFound(false);
    } else if (bankdetails === "checkyeshomeloanbankstatus") {
      setData(yesData);
      setNotFound(false);
    } else if (bankdetails === "checkunionhomeloanbankstatus") {
      setData(unionData);
      setNotFound(false);
    } else if (bankdetails === "checkbajajhomeloanbankstatus") {
      setData(bajajData);
      setNotFound(false);
    } else if (bankdetails === "checkfederalhomeloanbankstatus") {
      setData(federalData);
      setNotFound(false);
    } else if (bankdetails === "checkcanarahomeloanbankstatus") {
      setData(canaraData);
      setNotFound(false);
    } else if (bankdetails === "checkboihomeloanbankstatus") {
      setData(boiData);
      setNotFound(false);
    } else if (bankdetails === "checkrblhomeloanbankstatus") {
      setData(rblData);
      setNotFound(false);
    } else if (bankdetails === "checklthomeloanbankstatus") {
      setData(ltData);
      setNotFound(false);
    } else if (bankdetails === "checkidbihomeloanbankstatus") {
      setData(idbiData);
      setNotFound(false);
    } else if (bankdetails === "checklichousinghomeloanbankstatus") {
      setData(lichousingData);
      setNotFound(false);
    } else if (bankdetails === "checkicicihousinghomeloanbankstatus") {
      setData(icicihousingData);
      setNotFound(false);
    } else if (bankdetails === "checkpnbhousinghomeloanbankstatus") {
      setData(pnbhousingData);
      setNotFound(false);
    } else if (bankdetails === "checkhdfcltdhomeloanbankstatus") {
      setData(hdfclnthousingData);
      setNotFound(false);
    } else if (bankdetails === "checksbihousinghomeloanbankstatus") {
      setData(sbihomehousingData);
      setNotFound(false);
    } else if (bankdetails === "checkadityahousinghomeloanbankstatus") {
      setData(adityahomehousingData);
      setNotFound(false);
    } else {
      setNotFound(true);
    }
  }, [
    bankdetails,
    axisData,
    bajajData,
    bobData,
    boiData,
    canaraData,
    federalData,
    hdfcData,
    iciciData,
    idfcData,
    induslndData,
    kotakdata,
    punjabData,
    rblData,
    sbiData,
    tataData,
    unionData,
    yesData,
    ltData,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    hdfclnthousingData,
    sbihomehousingData,
    adityahomehousingData,
  ]);
  if (notFound) {
    return <Homeloan />;
  }

  if (!data) return null;

  return (
    <>
      <Helmet>
        <title>
          {bankdetails === "checkkotakhomeloanbankstatus"
            ? "Kotak Mahindra Bank Home Loan Status - Track Your Loan Status Online"
            : bankdetails === "checkbobhomeloanbankstatus"
            ? "Bank of Baroda Home Loan Status - Track Your Loan Status Online"
            : bankdetails === "checkidfchomeloanbankstatus"
            ? "IDFC First Bank Home Loan Status - Track Your Loan Status Online"
            : bankdetails === "checkhdfchomeloanbankstatus"
            ? "HDFC Bank Home Loan Status - Track Your Loan Status Online"
            : bankdetails === "checksbihomeloanbankstatus"
            ? "State Bank of India Home Loan Status - Track Your Loan Status Online"
            : bankdetails === "checkaxishomeloanbankstatus"
            ? "Axis Bank Home Loan Status - Track Your Loan Status Online	"
            : bankdetails === "checkicicihomeloanbankstatus"
            ? "ICICI Bank Home Loan Status - Track Your Loan Status Online	"
            : bankdetails === "checkpunjabhomeloanbankstatus"
            ? "Punjab National Bank Home Loan Status - Track Your Loan Status Online		"
            : bankdetails === "checkinduslndhomeloanbankstatus"
            ? "Induslnd Bank Home Loan Status - Track Your Loan Status Online		"
            : bankdetails === "checktatahomeloanbankstatus"
            ? "Tata Capital Bank Home Loan Status - Track Your Loan Status Online		"
            : bankdetails === "checkyeshomeloanbankstatus"
            ? "Yes Bank Home Loan Status - Track Your Loan Status Online		"
            : bankdetails === "checkunionhomeloanbankstatus"
            ? "Union Bank Home Loan Status - Track Your Loan Status Online		"
            : bankdetails === "checkbajajhomeloanbankstatus"
            ? "Bajaj Finance Home Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checkfederalhomeloanbankstatus"
            ? "Federal Bank Home Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checkcanarahomeloanbankstatus"
            ? "Canara Bank Home Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checkboihomeloanbankstatus"
            ? "Bank of India Home Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checkrblhomeloanbankstatus"
            ? "RBL Bank Home Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checklthomeloanbankstatus"
            ? "L&T Finance Bank Home Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checkidbihomeloanbankstatus"
            ? "IDBI Bank Home Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checklichousinghomeloanbankstatus"
            ? "LIC Housing Finance  Bank Home Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checkicicihousinghomeloanbankstatus"
            ? "ICICI Home Finance  Bank Home Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checkpnbhousinghomeloanbankstatus"
            ? "PNB Home Finance  Bank Home Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checkhdfcltdhomeloanbankstatus"
            ? "HDFC Ltd. Home Finance  Bank Home Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checksbihousinghomeloanbankstatus"
            ? "SBI Home Finance  Bank Home Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checkadityahousinghomeloanbankstatus"
            ? "Aditya Birla Home Finance  Bank Home Loan Status - Track Your Loan Status Online			"
            : null}
        </title>
        <meta
          name="description"
          content={
            bankdetails === "checkkotakhomeloanbankstatus"
              ? "Check your Kotak Mahindra Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkbobhomeloanbankstatus"
              ? "Check your Bank of Baroda Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate. "
              : bankdetails === "checkidfchomeloanbankstatus"
              ? "Check your IDFC First Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate. "
              : bankdetails === "checkhdfchomeloanbankstatus"
              ? "Check your HDFC Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checksbihomeloanbankstatus"
              ? "Check your State Bank of India Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkaxishomeloanbankstatus"
              ? "Check your Axis Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkicicihomeloanbankstatus"
              ? "Check your ICICI Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkpunjabhomeloanbankstatus"
              ? "Check your Punjab National Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkinduslndhomeloanbankstatus"
              ? "Check your Induslnd Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checktatahomeloanbankstatus"
              ? "Check your Tata Capital Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkyeshomeloanbankstatus"
              ? "Check your Yes Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkunionhomeloanbankstatus"
              ? "Check your Union Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkbajajhomeloanbankstatus"
              ? "Check your Bajaj Finance Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkfederalhomeloanbankstatus"
              ? "Check your Federal Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkcanarahomeloanbankstatus"
              ? "Check your Canara Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkbobhomeloanbankstatus"
              ? "Check your Bank of India Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkrblhomeloanbankstatus"
              ? "Check your RBL Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checklthomeloanbankstatus"
              ? "Check your L&T Finance Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkidbihomeloanbankstatus"
              ? "Check your IDBI Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checklichousinghomeloanbankstatus"
              ? "Check your LIC Housing Finance  Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkicicihousinghomeloanbankstatus"
              ? "Check your ICICI Home Finance  Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkpnbhousinghomeloanbankstatus"
              ? "Check your PNB Home Finance  Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkhdfcltdhomeloanbankstatus"
              ? "Check your HDFC Ltd. Home Finance  Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "Checkidbihomeloanbankstatus"
              ? "Check your SBI Home Finance  Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkadityahousinghomeloanbankstatus"
              ? "Check your Aditya Birla Home Finance  Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : null
          }
        />
      </Helmet>
      <div className=" min-h-screen flex-col">
        {/* Hero Section */}
        <section className="py-4 border-b border-gray-200 bg-[#ebe8fb]">
          <div className="container md:px-6 bg-transparent p-0">
            <div className="space-y-4 md:w-4/5 sd:w-full px-3 mx-auto bg-transparent">
              <h1 className="text-2xl font-bold tracking-tighter sm:text-3xl text-primary-purple bg-transparent ">
                {data.heading}
              </h1>
              <p className="text-dark-grey bg-transparent ">{data.paragraph}</p>
              <div className="flex flex-col gap-3  pt-2 bg-transparent">
                {data.buttons.map((button, index) => (
                  <a
                    key={index}
                    href={button.link}
                    className={button.style}
                    style={button.customStyle || {}}
                  >
                    {button.text}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Loan Predictor Widget */}
        <section
          id="loan-predictor"
          className="py-10 bg-white border-b border-gray-200"
        >
          <div className="container  md:px-6 bg-transparent p-0">
            <LoanPredictorWidget name={bankdetails} />
          </div>
        </section>

        {/* Features Section */}
        <section className="py-6 border-b border-gray-200 bg-[#ebe8fb]">
          <div className="container sb:px-4  md:px-6 bg-transparent">
            <div className="mx-auto md:w-4/5 sd:w-full text-center bg-transparent">
              <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple bg-transparent">
                {data.h2}
              </h2>
              <p className="text-dark-grey mb-8 bg-transparent">{data.h2des}</p>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 bg-transparent">
                {data.features.map((feature, index) => (
                  <div
                    key={index}
                    className="bg-white p-3 rounded-lg shadow-sm border border-gray-200"
                  >
                    <div className="h-10 w-10 rounded-full bg-light-bg flex items-center justify-center mx-auto mb-3 bg-[#ebe8fb]">
                      {feature.icon}
                    </div>
                    <h3 className="text-base font-bold text-primary-purple mb-2 bg-transparent">
                      {feature.title}
                    </h3>
                    <p className="text-dark-grey text-sm bg-transparent">
                      {feature.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Loan Tools Sections */}
        <LoanStatusChecker name={bankdetails} />
        <LoanStatusMethods name={bankdetails} />
        <StatusExplanation name={bankdetails} />
        <MinemiTools name={bankdetails} />
        <Testimonials name={bankdetails} />
        {bankdetails && <RelatedContent name={bankdetails} />}
        <Faq name={bankdetails} />
        <TrustIndicators name={bankdetails} />
      </div>
    </>
  );
}

import React from "react";
import Serve from "./Serve/Serve.jsx";
import Types from "./Types/Types.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CarLoanEligibility from "./CarLoanEligibility/CarLoanEligibility.jsx";

const CarLoanEligibilityForm = () => {
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <CarLoanEligibility />
        <Serve />
        <Types />
        <ToastContainer />
      </div>
    </>
  );
};

export default CarLoanEligibilityForm;

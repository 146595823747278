import React, { useEffect, useState, useContext } from "react";
import "./BadCredit.css";
import { FaAngleDown, FaChevronUp } from "react-icons/fa6";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import illustration from "../../Assets/Images/Illustration.webp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { HashLink as RouterHashLink } from "react-router-hash-link";
import BankTable from "./BankTable";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import MetadataContext from "../MetadataContext";

const BadCredit = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Compare Personal Loan Interest Rates from Top Banks in India",
      pageDescription:
        "Compare personal loan interest rates from top banks in India. Find the best rates, low EMIs, and flexible repayment options.",
      pageKeywords:
        "personal loan interest rates, compare loan rates, top banks in India, best personal loan rates, lowest loan interest, bank loan comparison, personal loan comparison, flexible loan rates, affordable personal loans, loan offers India",
      canonicalUrl: "https://minemi.ai/loanpage/personalloan/badcredit",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          className=""
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 48,
            paddingBottom: 40,
            overflow: "hidden",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              <Link
                to={"/loanpage/personalloan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Personal Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Personal Loan Interest Rates
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "55px",
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Compare Personal Loan Interest Rates from Top Banks in India
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 500,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Compare personal loan interest rates from top banks in India.
            Discover the best rates, low EMIs, and flexible repayment options
            tailored to your financial requirements.
          </p>
        </div>
        <div
          className=""
          style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}
        >
          <h2
            style={{
              marginTop: 30,
              fontWeight: 600,
              fontSize: 24,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Interest Rate Comparison Table:
          </h2>
          <p
            style={{
              fontWeight: 400,
              fontSize: 16,

              lineHeight: "20px ",
              color: "#1f1f1f",
              marginTop: 8,
            }}
          >
            MinEMI suggests the best loans for bad Credit scores by analyzing
            thousands of companies
          </p>
          <div
            style={{
              backgroundColor: "#E7E4F9",
              border: "1px solid #D9D9D9",
              borderRadius: 12,
              marginTop: 24,
            }}
          >
            <p
              style={{
                color: "#59266D",
                backgroundColor: "#E7E4F9",
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                marginTop: 14,
                marginLeft: "1%",
                paddingBottom: 0,
              }}
            >
              Comparison Table for Interest Rate:
            </p>
            <BankTable />
          </div>
          <h3
            style={{
              marginTop: 40,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            How Interest Rates Affect EMI Payments
          </h3>
          <div
            style={{
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
              marginTop: 15,
            }}
          >
            Your loan’s interest rate directly affects your monthly EMI (Equated
            Monthly Installment). A slight difference in interest rates can
            result in significant savings over time. Use our EMI calculator to
            estimate your monthly payments and choose the loan that fits your
            budget
          </div>

          <div
            style={{
              marginTop: 30,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            How to Compare Personal Loan Interest Rates
          </div>
          <div
            style={{
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            When comparing personal loans, interest rates are essential, but
            they’re not the only factor to consider. Here are the key things you
            should evaluate when choosing the best loan for you:
          </div>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                1.Interest Rates:
              </span>{" "}
              Lower interest rates mean lower EMIs, but be cautious of
              promotional rates that may increase after a certain period.{" "}
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                2. Processing Fees:
              </span>{" "}
              Some lenders charge higher processing fees that can significantly
              affect the overall loan cost.{" "}
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                3.Loan Tenure:
              </span>{" "}
              A shorter tenure results in higher EMIs but less total interest
              paid. Longer tenures reduce EMIs but increase total interest.{" "}
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                4.Prepayment Penalties:
              </span>{" "}
              Look for lenders that allow prepayments with little or no
              penalties, allowing you to save on interest if you decide to pay
              off the loan early.
            </div>
          </div>

          <div
            style={{
              marginTop: 30,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Tips for Choosing the Best Personal Loan
          </div>
          <div
            style={{
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Selecting the right personal loan goes beyond just finding the
            lowest interest rate. Here are some tips to help you make the best
            choice for your financial situation:
          </div>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                1.Overall Costs:
              </span>{" "}
              Consider all associated fees such as processing fees, prepayment
              penalties, and hidden charges.{" "}
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                2.Loan Tenure:
              </span>{" "}
              Choose a tenure that balances your EMI affordability with the
              total interest you’ll pay over time.{" "}
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                3.Prepayment Flexibility:
              </span>{" "}
              Look for loans that offer prepayment or foreclosure with minimal
              fees, which can help you close the loan early and save on
              interest.{" "}
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                4.Credit Score:
              </span>{" "}
              Look for lenders that allow prepayments with little or no
              penalties, allowing you to save on interest if you decide to pay
              off the loan early.Your credit score significantly affects the
              loan offers and interest rates you can receive. Understand your
              credit profile before applying.{" "}
            </div>
          </div>
          <h3
            style={{
              marginTop: 30,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Bad Credit loan interest rates and fees
          </h3>

          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                    }}
                  >
                    Borrow Credit rating
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Score range
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Estimated APR
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Excellent
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    720 - 850
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    11%
                  </td>
                </tr>
                <tr style={{ border: "1px solid #D9D9D9" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Good
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    690 - 718
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    15%
                  </td>
                </tr>
                <tr style={{ border: "1px solid #D9D9D9" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Fair
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    360 - 689
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    18%
                  </td>
                </tr>
                <tr style={{ border: "1px solid #D9D9D9" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Bad
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    300 - 629
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    22%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3
            style={{
              marginTop: 30,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            How to Get a Personal Loan for Bad Credit
          </h3>
          <div
            style={{
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Securing a personal loan with bad credit can be challenging, but
            it's not impossible. Here’s a step-by-step guide to help you get
            started:
          </div>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                1. Check Your Credit Score :
              </span>{" "}
              Begin by reviewing your credit score. Knowing where you stand
              helps you understand what loan options are available to you and
              what lenders will likely approve your application.
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                2. Explore Lenders for Bad Credit:
              </span>{" "}
              Look for lenders that specialize in bad credit loans. These
              lenders are more willing to work with borrowers who have
              less-than-perfect credit and may offer more flexible terms.
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                3. Consider a Co-signer :
              </span>{" "}
              If your credit score is too low, having a co-signer with good
              credit can increase your chances of approval and might help you
              secure a better interest rate.
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                4. Prequalify Before Applying :
              </span>{" "}
              Many lenders allow you to prequalify for a loan without affecting
              your credit score. This helps you see what interest rates and
              terms you qualify for before formally applying.
            </div>
            <div
              style={{
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              <span
                style={{
                  color: "#1F1F1F",
                  fontWeight: 600,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                5. Compare Loan Options :
              </span>{" "}
              Compare Loan Options: Once you have multiple offers, compare
              interest rates, fees, loan amounts, and repayment terms. Choose
              the option that best fits your financial situation and repayment
              ability.
            </div>
          </div>
          <div
            style={{
              marginTop: 40,
              marginBottom: 24,
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Frequently Asked Questions (FAQs)
          </div>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              marginBottom: 120,

              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Can I negotiate the interest rate on a personal loan?
              </AccordionSummary>
              <AccordionDetails>
                Yes, lenders may offer lower interest rates to applicants with
                higher credit scores or strong financial profiles.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{ borderRadius: 12, fontWeight: 600 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                How do processing fees affect my loan cost?
              </AccordionSummary>
              <AccordionDetails>
                Processing fees are upfront costs that can increase the overall
                expense of your loan. Always factor these into your total loan
                cost calculations.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{ borderRadius: 12, fontWeight: 600 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                What factors influence personal loan interest rates in India?
              </AccordionSummary>
              <AccordionDetails>
                Personal loan interest rates in India are influenced by factors
                such as your credit score, income level, employment status, loan
                amount, and tenure. A higher credit score and stable income can
                help secure a lower interest rate.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{ borderRadius: 12, fontWeight: 600 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                How can I improve my chances of obtaining a personal loan at a
                lower interest rate?
              </AccordionSummary>
              <AccordionDetails>
                To improve your chances of securing a personal loan at a lower
                interest rate, maintain a good credit score (preferably above
                750), ensure a stable income, and have a low debt-to-income
                ratio. Additionally, having a good repayment history and
                applying during promotional periods can be beneficial.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{ borderRadius: 12, fontWeight: 600 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Are there any prepayment or foreclosure charges associated with
                personal loans?
              </AccordionSummary>
              <AccordionDetails>
                Prepayment or foreclosure charges vary among banks. Some banks
                may allow prepayment without charges, while others may impose a
                fee. It's essential to check the specific terms and conditions
                of the bank offering the loan. For instance, IDFC FIRST Bank
                offers zero foreclosure charges, allowing you to repay your loan
                early without any penalties.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{ borderRadius: 12, fontWeight: 600 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Can I apply for a personal loan online, and how long does the
                approval process take?
              </AccordionSummary>
              <AccordionDetails>
                Yes, most banks offer online applications for personal loans.
                The approval process duration varies but typically ranges from a
                few hours to a few days, depending on the bank's internal
                processes and the completeness of your application.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{ borderRadius: 12, fontWeight: 600 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                What is the maximum loan amount I can avail of, and what are the
                repayment tenures?
              </AccordionSummary>
              <AccordionDetails>
                The maximum loan amount and repayment tenure vary by bank. For
                example, HDFC Bank offers loans up to ₹40 lakh with flexible
                repayment options. It's advisable to check with the specific
                bank for their loan limits and tenure options.
                <br />
                <br />
                When choosing a personal loan, it's crucial to compare the
                interest rates, processing fees, loan amounts, and repayment
                tenures offered by different banks to find the option that best
                suits your financial needs.
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            className="make-it-center increase-margin "
            style={{
              marginTop: "-5%",
              paddingBottom: "4%",
              padding: 24,
              background: "linear-gradient(91.7deg, #EADCFF 0.4%, #FFFFFF 99%)",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 20,
              borderRadius: 24,
            }}
          >
            <div
              style={{
                marginLeft: "4%",
                backgroundColor: "transparent",
                display: "flex",
                flexDirection: "column",
                gap: 24,
                borderRadius: 24,
                alignSelf: "center",
              }}
            >
              <p
                className="eligiblity-criteria"
                style={{
                  fontFamily: "Archivo",
                  color: "#59266D",
                  // fontSize: 40,
                  backgroundColor: "transparent",
                }}
              >
                <i style={{ backgroundColor: "transparent", lineHeight: 1.2 }}>
                  Ready to find the best personal loan for you?
                  <br className="brake-remove" /> Compare rates, check
                  eligibility, and calculate <br className="brake-remove" />{" "}
                  your EMI with Minemi’s personalized tools.
                </i>
              </p>
              <a href="https://minemi.ai/personal-loan/check-eligibility/">
                <div
                  className="eligiblity-buttons"
                  style={{
                    backgroundColor: "#59266D",
                    color: "#FFFFFF",
                    width: "39%",
                    borderRadius: 8,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 18,
                    paddingTop: 8,
                    paddingBottom: 8,
                    textAlign: "center",
                  }}
                >
                  Compare now
                </div>
              </a>
            </div>
            <div
              style={{
                height: 195,
                backgroundColor: "transparent",
                marginRight: "4%",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
              }}
            >
              <img
                src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Illustration-1736403318651.webp"
                alt="Check Eligibility"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BadCredit;

import { useEffect } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import homelogo from '../../Assets/Images/HomeLogo.png'
import userlogo from '../../Assets/Images/userLogo.webp'

function LowerEmiDetail() {
  const location = useLocation()
  const navigate = useNavigate()
  const id = localStorage.getItem("id")
  const {
    oldIrr,
    newIrr,
    loanAmount,
    tenure,
    oldemi,
    newemi,
    saveonemi,
    accountType,
    subscriber,
    savepercentage,
    Account_Number,
  } = location.state

  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-IN").format(number)
  }

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (!token) navigate("/")
  }, [navigate])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
}, [])
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      {/* Savings Header */}
      <div className="text-center mb-8">
        <h2 className="text-[#59266D] text-2xl font-medium mb-2">Your savings</h2>
        <p className="text-[#59266D] text-5xl font-bold mb-4" style={{textAlign:'center'}}>₹{formatNumberWithCommas(saveonemi)}</p>
        <p className="text-gray-500 text-sm"  style={{textAlign:'center'}}>This is {savepercentage}% of Outstanding Amount</p>
      </div>

      {/* Loan Details Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
        {/* Loan Card */}
        <div className="bg-white rounded-lg p-3 ">
          <div className="flex items-center gap-3 pb-2 border-b border-gray-100" style={{backgroundColor:'transparent'}}>
            <div className="w-12 h-12 rounded-full bg-[#59266D]/10 flex items-center justify-center" >
              <img src={accountType === "02" ? homelogo : userlogo} alt="" className="w-8 h-8"  style={{backgroundColor:'transparent'}}/>
            </div>
            <div style={{backgroundColor:'transparent'}}>
              <h3 className="font-bold text-gray-900" style={{fontSize:17,backgroundColor:'transparent'}}>{accountType === "02" ? "Home Loan" : "Personal Loan"}</h3>
              <p className="text-sm text-gray-500" style={{marginTop:'-13%',backgroundColor:'transparent'}}>{subscriber}</p>
            </div>
          </div>

          <div className="mt-4 space-y-4" style={{backgroundColor:'transparent'}}>
            <div className="flex justify-between " style={{backgroundColor:'transparent'}}> 
              <span className="text-gray-900" style={{backgroundColor:'transparent'}}>Outstanding</span>
              <span className="text-[#59266D]" style={{backgroundColor:'transparent'}}>₹{formatNumberWithCommas(loanAmount)}</span>
            </div>
            <div className="flex justify-between" style={{backgroundColor:'transparent'}}>
              <span className="text-gray-900" style={{backgroundColor:'transparent'}}>Tenure</span>
              <span className="text-[#59266D]" style={{backgroundColor:'transparent'}}>{tenure} Months</span>
            </div>
          </div>
        </div>

        {/* Comparison Table */}
        <div className="bg-white rounded-lg overflow-hidden" style={{border:'1px solid gray'}}>
          <table className="w-full border" style={{border:'1px solid gray'}}>
            <thead  style={{border:'1px solid gray'}}>
              <tr className="border">
                <th className="w-1/3 p-3 text-left border-b border-gray-200" style={{borderBottom:'1px solid grey', borderRight:'1px solid grey',backgroundColor:'white'}}></th>
                <th className="w-1/3 p-3 text-center border-b border-gray-200"  style={{borderBottom:'1px solid grey',backgroundColor:'white'}}>Current</th>
                <th className="w-1/3 p-3 text-center bg-[#3A868B] text-white"  style={{borderBottom:'1px solid grey'}}>New</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-3 border-b border-gray-200"  style={{borderBottom:'1px solid gray',borderRight:'1px solid gray'}}>Interest</td>
                <td className="p-3 text-center border-b border-gray-200"  style={{borderBottom:'1px solid gray',borderRight:'1px solid gray'}}>{oldIrr}%</td>
                <td className="p-3 text-center text-[#3A868B] font-bold border-b border-[#3A868B]"  >{newIrr}%</td>
              </tr>
              <tr>
                <td className="p-3 border-b border-gray-200"  style={{borderBottom:'1px solid gray',borderRight:'1px solid gray',backgroundColor:'white'}}>EMI</td>
                <td className="p-3 text-center border-b border-gray-200"  style={{borderBottom:'1px solid gray',borderRight:'1px solid gray',backgroundColor:'white'}}>₹{formatNumberWithCommas(oldemi)}</td>
                <td className="p-3 text-center text-[#3A868B] font-bold border-b border-[#3A868B]" style={{backgroundColor:'white'}} >
                  ₹{formatNumberWithCommas(newemi)}
                </td>
              </tr>
              <tr>
                <td className="p-3 border-b border-gray-200"  style={{borderRight:'1px solid gray'}}>Total Savings</td>
                <td className="p-3 text-center border-b border-gray-200"  style={{borderRight:'1px solid gray'}}></td>
                <td className="p-3 text-center text-[#3A868B] font-bold border-[#3A868B]"  >
                  ₹{formatNumberWithCommas(saveonemi)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Action Button */}
      <div className="text-center">
        <Link
          to={accountType === "05" ? "/personalloanform" : "/homeloanform"}
          state={{
            loanAmount,
            oldIrr,
            tenure,
            newIrr,
            accountType,
            Account_Number,
          }}
          className="inline-block w-full max-w-md"
        >
          <button  className={`w-full max-w-md mx-auto py-3 rounded-lg text-white font-semibold bg-[#59266D]
               transition-colors block`}>
            {id == 1 ? "Initiate Process" : "Initiate Consolidate Process"}
          </button>
        </Link>
      </div>
    </div>
  )
}

export default LowerEmiDetail


import React from "react";
import "./Serve.css";
import Accordion from "react-bootstrap/Accordion";
import { FaPlus } from "react-icons/fa";

const Serve = () => {
  const accordionData = [
    {
      title: "Loan Against Property Eligibility",
      content: (
        <p className="m-0 bg-transparent">
          A Quick Guide Your loan against property eligibility depends on
          several factors, including your income, credit score, property value,
          and existing financial commitments. Most banks prefer applicants with
          a credit score of 700+ and a stable income history. At Minemi, we
          offer a hassle-free way to check your loan eligibility instantly,
          without impacting your credit score. Get clarity on your borrowing
          capacity upfront and access personalized loan options tailored to your
          needs, ensuring a smooth and stress-free approval process!
        </p>
      ),
    },
    {
      title: "Benefits of Loan Against Property",
      content: (
        <div className="bg-transparent">
          Loan against property is a secured loan that allows you to unlock the
          value of your property while continuing to own it. It comes with
          multiple advantages.
          <p className="my-2 bg-transparent">Key features include:</p>
          <p className="my-1 bg-transparent">
            • Lower interest rates compared to unsecured loans
          </p>
          <p className="m-0 bg-transparent">
            • High loan amounts based on property value
          </p>
          <p className="my-1 bg-transparent">
            • Flexible repayment tenures (up to 20 years)
          </p>
          <p className="m-0 bg-transparent">
            • Continued ownership and usage of the property
          </p>
          <p className="my-1 bg-transparent">
            • Multipurpose usage – for business expansion, education, medical
            emergencies, and more
          </p>
        </div>
      ),
    },

    {
      title: "Why Check Loan Eligibility?",
      content: (
        <p className="m-0 bg-transparent">
          Checking your loan against property eligibility helps you understand
          your borrowing capacity and avoid rejections. It also prevents hard
          credit inquiries that may impact your score. At Minemi, check
          eligibility instantly without affecting your credit score.
        </p>
      ),
    },

    {
      title: "When to Consider a Property Loan?",
      content: (
        <p className="m-0 bg-transparent">
          A loan against property is ideal when you need funds for business
          expansion, education, medical emergencies, or debt consolidation. It
          allows you to leverage your property's value while continuing to own
          it. With lower interest rates and flexible repayment options, it
          offers a cost-effective solution. Assess your repayment capacity
          before applying, and use Minemi’s eligibility checker to find the best
          loan options.
        </p>
      ),
    },

    {
      title: "Documents Required for LAP",
      content: (
        <div className="bg-transparent">
          You will need to submit the following documents:
          <p className="my-1 bg-transparent">
            • Identity Proof: PAN Card, Aadhaar Card, Passport
          </p>
          <p className="m-0 bg-transparent">
            • Address Proof: Utility bills, Aadhaar, or rental agreements
          </p>
          <p className="my-1 bg-transparent">
            • Income Proof: Latest salary slips or bank statements (for
            salaried), IT returns or audited financials (for self-employed)
          </p>
          <p className="my-1 bg-transparent">
            • Property Documents: Title Deed, Sale Deed, Approved Building Plan
          </p>
          <p className="m-0 bg-transparent">
            • Loan Purpose Documents: Business plan, medical bills, or
            educational expenses (if applicable)
          </p>
          Having these documents ready will help ensure a smooth loan approval
          process.
        </div>
      ),
    },

    {
      title: "Frequently Asked Questions (FAQs)",
      content: (
        <div className="m-0 bg-transparent">
          <p className="m-0 bg-transparent">
            1. What is the minimum credit score required for a loan against
            property? Most lenders prefer a credit score of 700+, but
            requirements may vary. Use Minemi's eligibility checker to assess
            your chances.
          </p>
          <p className="my-2 bg-transparent">
            2. How much loan can I get against my property? The loan amount
            depends on your income, credit score, and property value. Minemi
            helps you check your eligibility instantly without impacting your
            credit score.
          </p>
          <p className="m-0 bg-transparent">
            3. Will checking loan eligibility affect my credit score? No, Minemi
            uses a soft inquiry method, ensuring your credit score remains
            unaffected.
          </p>
          <p className="my-2 bg-transparent">
            4. Can I use a loan against property for personal or business needs?
            Yes, you can use the funds for various purposes, including business
            expansion, education, medical emergencies, or debt consolidation.
          </p>
          <p className="m-0 bg-transparent">
            5. How long does it take to get a loan against property? The process
            typically takes a few weeks, depending on property verification and
            documentation. Minemi simplifies and speeds up the approval process.
          </p>
        </div>
      ),
    },
  ];
  return (
    <>
      <div id="serve">
        <div className="contentCon">
          <div id="header-con">
            <p className="bg-transparent">We swear to serve you with ...</p>
          </div>
          <div id="content" className="bg-transparent">
            <div className="d-flex flex-wrap w-100 bg-transparent">
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/loan_eligibility.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/no_empact.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Impact on credit Score</p>
              </div>

              <div className="plus-icon-con second-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/interest_rate.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Best Interest Rate Offer</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/no_calls.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Calls From Us</p>
              </div>

              <div className="plus-icon-con fourth-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Data Security Guarantee</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>
            </div>

            <div id="line-breaker"></div>

            <div className="accordion-con bg-transparent">
              <Accordion className="bg-transparent">
                {accordionData.map((item, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body
                      style={{
                        visibility: "visible",
                        backgroundColor: "transparent",
                      }}
                    >
                      {item.content}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Serve;

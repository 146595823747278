import React, { useEffect, useState, useRef } from "react";
import "./BalanceTansferEligibility.css";
import { useMediaQuery } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { ColorRing } from "react-loader-spinner";
import axios from "axios";
import { useContext } from "react";
import { UserContext } from "../../../../ContextAPI/FormContextAPI";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaRupeeSign } from "react-icons/fa";

const BalanceTransferEligibility = ({ formData, setFormData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [salaried, setSalaried] = useState(false);
  const [cmpList, setCmpList] = useState([]);
  const [validated, setValidated] = useState(false);
  const [otpField, setOtpField] = useState("");
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const [stgOne, setStgOne] = useState("");
  const [stgTwo, setStgTwo] = useState("");
  const [formId, setFormId] = useState("");
  const [termsAccepted2, setTermsAccepted2] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");
  const[inputFil ,setInput]=useState(false)
   const [companyname, setCompanyName] = useState([]);
   const [searchResults, setSearchResults] = useState([]);
       const [open, setOpen] = useState(false);

      
     
  // start new form data!
  const { form2Data, setForm2Data, mergedData,setMergedData } = useContext(UserContext);

  useEffect(() => {
    if (!form2Data.profession) {
      setForm2Data({
        profession: "",
        company: "",
        monthly_income: "",
        monthly_emi: "",
        loan_type: "Bt",
      });
    }
  }, []);

  const handleChangeInput = async (e) => {
    const { name, value } = e.target;
  
    if (name === "monthly_income" || name === "monthly_emi") {
      const plainNumber = parseFloat(value.replace(/,/g, ""));
      let fValue = plainNumber >= 0 ? plainNumber : "";
  
      setForm2Data((oldData) => ({
        ...oldData,
        [name]: fValue,
      }));
    } else if (name === "profession") {
      if (value === "Salaried") {
        setSalaried(true);
        const response = await axios.get(`${process.env.REACT_APP_SECRET_KEY}/companyList`);
  
        if (response.status === 200) {
          let cmpArray = response.data.data.company;
          const formattedOptions = cmpArray.map((option) => ({
            value: option.id,
            label: option.company_name,
          }));
          setCmpList(formattedOptions);
        }
      } else {
        setSalaried(false);
        setForm2Data((oldData) => ({
          ...oldData,
          company: "", // Reset company if profession is not "Salaried"
        }));
      }
  
      setForm2Data((oldData) => ({
        ...oldData,
        [name]: value,
      }));
    } else {
      setForm2Data((oldData) => ({
        ...oldData,
        [name]: value,
      }));
    }
  };
  const handleSubmitForm = async (event) => {
    resetOtp();
    event.preventDefault();
    setValidated(true);

    // console.log("Final data:", mergedData);
    await localStorage.setItem("name", mergedData.fname);
    localStorage.setItem("lname", mergedData.lname);
    localStorage.setItem("mobile", mergedData.mobile.substring(3));
    localStorage.setItem("email", mergedData.email);

    if (
      mergedData.fname &&
      mergedData.lname &&
      mergedData.email &&
      mergedData.mobile &&
      form2Data.profession !== "Your profession" &&
      form2Data.monthly_income &&
      form2Data.monthly_emi &&
      termsAccepted2
    ) {
      try {
        setLoader(true);
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/login`,
          mergedData
        );
        // console.log("send-data:", response);

        if (response.status === 200) {
          if(response.data.status==1){
            setStgOne(response.data.data.expData.stgOneHitId);
            setStgTwo(response.data.data.expData.stgTwoHitId);
            setFormId(response.data.formId);
            setShow(true);
          }
          else{
            toast.error(response.data.msg)
            setLoader(false);
            setShow(true);

          }
        }

        // if (!response.status) {
        //   const errors = response.data.msg
        //     ? response.data.msg
        //     : response.data.errors;
        //   toast.error(errors);
        // }

        setLoader(false);
      } catch (error) {
        console.error("Error making POST request", error);
        setLoader(false);
      }
    } else {
    }
  };

  const otpSubmit = async () => {
    setOtpLoader(true);
    const formToken=localStorage.getItem("token")
    // const concatenateOtp = otpField.join("");
    if (otpField.length !== 6) {
      toast.error(
        "Please enter the 6-digit OTP sent to your registered mobile number!"
      );
      setOtpLoader(false);
    } else {
      let formData = new FormData();
      formData.append("stgOneHitId", stgOne);
      formData.append("stgTwoHitId", stgTwo);
      formData.append("otp", otpField);
      formData.append("mobile", mergedData.mobile);
      formData.append("type", "CUSTOM");
      formData.append("formId", formId);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/verifyOtpBt`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${formToken}`
            }
          }
        );
        setOtpLoader(false);


        localStorage.setItem("name", response.data.data.name);
        localStorage.setItem("id", 1);

        localStorage.setItem("credit_score", response.data.data.credit_score);

        localStorage.setItem(
          "no_of_active_loan",
          response.data.data.no_of_active_loan
        );
        localStorage.setItem(
          "outstanding_amount",
          response.data.data.outstanding_amount
        );
        // console.log(response);
        if (response.data.status) {
          resetOtp();
          setShow(false);
          localStorage.setItem(
            "username",
            mergedData.fname + " " + mergedData.lname
          );
          sessionStorage.setItem("fId", formId);
          localStorage.setItem("eligibleData", JSON.stringify(response.data));
          navigate("/dashboard", {
            replace: true,
            state: { isValid: true },
          });
        }

        if (!response.status) {
          toast.error(response.msg);
        }
      } catch (error) {
        console.error("Error making POST request", error);
        console.log(error.response);
        if (!error.response.data.status) {
          toast.error(error.response.data.msg);
        }
        setOtpLoader(false);
      }
    }
  };

  const otpHandleChange = (element) => {
    let value = element.value.replace(/[^0-9]/g, "");
    value = value.slice(0, 6);
    setOtpField(value);

    // const newOtp = [...otpField];
    // newOtp[index] = value;

    // Move focus to the next input if a digit is entered
    // if (index < otpField.length - 1 && value) {
    //   inputRefs.current[index + 1].focus();
    // }
  };
  const resetOtp = () => {
    setOtpField("");
  };

  useEffect(() => {
    localStorage.removeItem("termData");
    localStorage.removeItem("eligibleData");
    localStorage.removeItem("username");
    sessionStorage.removeItem("fId");
    const routeData = location.state;
    if (routeData !== null) {
      toast.warning(routeData.msg);
      navigate(location.pathname, { state: null });
    }
  }, []);

  // old code


  // terms of use
  const CompanyDetail = () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/webData`,
    };

    axios
      .request(options)
      .then(function (response) {
        setHtmlContent(response.data.data.term_of_use);
        // setHtmlContentTwo(response.data.data.privacy_policy);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    CompanyDetail();
  }, []);

  // useMediaQuery

  const smallScreen = useMediaQuery("(max-width:576px)");
  const mediumScreen = useMediaQuery("(max-width:768px)");
  const largeScreen = useMediaQuery("(max-width:992px)");

  const handleOptionSelect2 = (companyname) => {
    setForm2Data((prevData) => ({
      ...prevData,
      company: companyname,
    }));
    setCompanyName(companyname)
    setOpen(!open)
    setInput(!inputFil)
  };
  const handleSearch = (searchValue) => {
    const filteredResults = cmpList.filter((item) =>
      item.label?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setSearchResults(filteredResults);
  };
  
  const handleChange1 = (event) => {
    const searchValue = event.target.value;
    handleSearch(searchValue);
    setOpen(true);
  };
 
  return (
    <>
      <div id="find-home-loan">
        <div className="find-hl-eligibility-page bg-transparent">
          <div className="card-box-content ">
            <div className="find-hl-loan-get-up-card bg-transparent">
              <div className="card-content d-flex flex-column justify-content-center align-items-center gap-md-4 bg-transparent">
                <div className="few-details-text bg-transparent">
                  <div className="get-up-text bg-transparent">
                    {" "}
                    <h2 className="bg-transparent">
                      Almost There! Just Share a Few Details to Check
                      Eligibility
                    </h2>
                  </div>
                  {/* <p className="enjoy-para pt-3 pb-2 ps-1 d-md-none ">
                    Enjoy instant approval & processing
                  </p> */}
                </div>

                <div
                  id="input-filds-container"
                  className="w-100 bg-transparent"
                >
                  <form
                    action=""
                    onSubmit={handleSubmitForm}
                    className="bg-transparent"
                  >
                    {" "}
                    <div
                      id="input-fields"
                      className="  d-flex flex-column justify-content-center align-items-center gap-4 w-100 bg-transparent"
                    >
                      <div className="w-100 d-flex flex-column gap-4 bg-transparent">
                        <div className="bg-transparent">
                          <Form.Select
                            // className="profession"
                            className="home-loan-eligibility-input-field"
                            // required
                            value={form2Data.profession}
                            name="profession"
                            onChange={handleChangeInput}
                          >
                            <option value="Your profession">
                              Your profession
                            </option>
                            <option value="Salaried">Salaried</option>
                            <option value="Self-Employed">Self-Employed</option>
                            <option value="Others">Others</option>
                          </Form.Select>

                          {validated &&
                            (!form2Data.profession ||
                              form2Data.profession === "Your profession") && (
                              <p className="field-error bg-transparent">
                                Profession is required field
                              </p>
                            )}

                          {salaried && (
                              <div
                              className="home-loan-eligibility-input-field mt-4"
                              style={{display:'flex',justifyContent:'space-between'}}

                              onClick={()=>setInput(!inputFil)}
                                           
                                              >
                                                {companyname == "" ? "Your Company" : companyname}
                          
                                              
                                              </div>
                         
                          )}
                          {inputFil?
                             <input
                             className="home-loan-eligibility-input-field mt-4"                             type="text"
                             placeholder="Search here"
                             onChange={handleChange1}
                           
                           />
                         
                             
                         :null
                        
                          }
                          {
                            open && 
                            (
                              searchResults.length==0?null:
                             <div
                              className="input-boxes"
                              style={{
                                paddingLeft: "4px",
                                paddingRight: "4px",
                                marginBottom: "10px",
                                paddingTop: "10px",
                                borderRadius: "8px",
                                border: "1px solid rgba(132, 108, 166, 1)",
                                backgroundColor: "rgba(132, 108, 166, 0.1)",
                                cursor: "pointer", // Add cursor pointer for indicating clickable
                                display: "flex",
                                flexDirection: "column",
                                marginTop: "4%",
                                height:160,
                                overflowX:'auto'
                              }}
                            >
                              {searchResults.map((d,index) => (
                                <div key={index}
                                  onClick={() =>
                                    handleOptionSelect2(d.label)
                                  }
                                  style={{
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    color: "rgba(31, 31, 31, 1)",
                                    backgroundColor: "transparent",
                                    paddingBottom: 20,
                                  }}
                                >
                                  {d.label}
                                </div>
                              ))}
                            </div>
                            )
                          }
                          {validated && salaried && !form2Data.company && (
                            <p className="field-error bg-transparent">
                              Company name is required field
                            </p>
                          )}
                        </div>

                        <InputGroup className="w-100 bg-transparent">
                          <InputGroup.Text className="rupee-icon ">
                            <FaRupeeSign
                              color="#59266D"
                              className="bg-transparent"
                            />
                          </InputGroup.Text>
                          <Form.Control
                            // required
                            placeholder="Your monthly income"
                            // className="home-loan-eligibility-input-field"
                            className="input-groups"
                            value={
                              form2Data.monthly_income === 0 ||
                              form2Data.monthly_income
                                ? new Intl.NumberFormat("en-IN").format(
                                    form2Data.monthly_income
                                  )
                                : ""
                            }
                            name="monthly_income"
                            onChange={handleChangeInput}
                          />
                        </InputGroup>
                          {validated && !form2Data.monthly_income && (
                            <p className="field-error bg-transparent">
                              Monthly income is required field
                            </p>
                          )}
                     

                        <InputGroup className="w-100">
                          <InputGroup.Text className="rupee-icon">
                            <FaRupeeSign
                              color="#59266D"
                              className="bg-transparent"
                            />
                          </InputGroup.Text>
                          <Form.Control
                            // required
                            placeholder="Your monthly EMI"
                            // className="home-loan-eligibility-input-field"
                            className="input-groups"
                            value={
                              form2Data.monthly_emi === 0 ||
                              form2Data.monthly_emi
                                ? new Intl.NumberFormat("en-IN").format(
                                    form2Data.monthly_emi
                                  )
                                : ""
                            }
                            name="monthly_emi"
                            onChange={handleChangeInput}
                          />
                          {/* {validated && !form2Data.monthly_emi && (
                            <p className="field-error bg-white w-100">
                              Monthly EMI is required field
                            </p>
                          )} */}
                        </InputGroup>
                      </div>
                    </div>
                    <div id="check-box" className="bg-transparent">
                      <div
                        id="check-box"
                        className=" bg-transparent d-flex flex-row justify-content-start align-items-start pt-4 pt-md-4 w-100"
                      >
                        {/* <div className="bg-transparent">
                          <input
                            type="checkbox"
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                            className="form-check-input"
                          />
                        
                        </div>
                        <div className="bg-transparent">
                         

                          <label
                            id="terms-privecy-para"
                            className="bg-transparent"
                          >
                            I agree to MinEMI's{" "}
                            <a
                              id="anker-text"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleClickOne("term_of_use");
                              }}
                            >
                              Terms of Use
                            </a>{" "}
                            and{" "}
                            <a
                              id="anker-text"
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handleClickTwo("privacy_policy");
                              }}
                            >
                              Privacy Policy
                            </a>{" "}
                            and authorise to be communicated via SMS, Call,
                            Whatsapp and Email. I hereby also allow MinEMI to
                            access my credit report on my behalf
                          </label>
                        </div> */}
                      </div>
                      {/* {validated && !termsAccepted && (
                        <p className="field-error bg-transparent">
                          You must agree before submitting.
                        </p>
                      )} */}

                      <div
                        id="check-box"
                        className="d-flex flex-row justify-content-center align-items-start pt-3 w-100
                        bg-transparent"
                      >
                        <div className="bg-transparent">
                          <input
                            type="checkbox"
                            checked={termsAccepted2}
                            onChange={(e) =>
                              setTermsAccepted2(e.target.checked)
                            }
                            className="form-check-input"
                          />
                          {/* <FormControlLabel
                            sx={{
                              width: "22px",
                              height: "22px",
                              paddingRight: "30px",
                              "& .MuiSvgIcon-root": { fontSize: 28 },
                            }}
                            control={
                              <Checkbox
                                color="secondary"
                                checked={termsAccepted2}
                                onChange={(e) =>
                                  setTermsAccepted2(e.target.checked)
                                }
                              />
                            }
                          /> */}
                        </div>
                        <div className="bg-transparent">
                          {/* <p id="terms-privecy-para" sx={{}}>
                            I hearby consent to Nine Triangles Pvt.Ltd. being
                            appointed as my authorised representative to receive
                            my credit information from Experian for the purpose
                            of Credit Assessment to advice me on the best loan
                            offers on expiry of 6 months from the date the
                            consent is collected, whichever is earlier. I also
                            agree to Experian Terms and Conditions.
                          </p> */}
                          <label
                            id="terms-privecy-para"
                            className="bg-transparent"
                          >
                            I allow MinEmi and Nine Triangles Pvt.
                            Ltd to access my credit report from RBI approved
                            credit bureaus on an ongoing basis for not exceeding
                            6 months. I also agree to{" "}
                            <a
                              id="anker-text"
                              href="#"
                              // onClick={(e) => {
                              //   e.preventDefault();
                              //   handleClickOne("experian_consent");
                              // }}
                              onClick={() => setShowTwo(true)}
                            >
                              Experian Terms and Conditions.
                            </a>
                          </label>
                        </div>
                      </div>
                      {validated && !termsAccepted2 && (
                        <p className="field-error bg-transparent">
                          You must agree before submitting.
                        </p>
                      )}
                    </div>
                    <div id="button-box" className=" bg-transparent w-100">
                      {!loader ? (
                        <button
                        disabled={loader} 
                          // onClick={() => setShow(false)}
                          // Open modal on button click
                          style={{
                            width: smallScreen
                              ? "100%"
                              : largeScreen
                              ? "100%"
                              : "70%",
                            backgroundColor: "#59266D",
                            color: "#ffff",
                            border: "none",
                            fontSize: smallScreen
                              ? "14px"
                              : mediumScreen
                              ? "18px"
                              : " 24px",
                            fontWeight: 500,
                            lineHeight: "26px",
                            borderRadius: smallScreen ? "6px" : "10px",
                            padding: smallScreen
                              ? "0.3rem 2.5rem"
                              : "0.8rem 0rem",
                            // padding: "0.8rem 0",
                            fontFamily: "'Archivo',sans-serif",
                            letterSpacing: "-0.17px",
                            textTransform: "none",
                          }}
                          variant="contained"
                        >
                          Proceed
                        </button>
                      ) 
                      : (
                        <button className="color-ring">
                          <ColorRing
                            visible={loader}
                            height="30"
                            width="30"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{ background: "transparent" }}
                            wrapperClass="color-ring-wrapper"
                            colors={[
                              "#ffffff",
                              "#ffffff",
                              "#ffffff",
                              "#ffffff",
                              "#ffffff",
                              "#ffffff",
                            ]}
                          />
                        </button>
                      )}
                    </div>{" "}
                  </form>

                  <div
                    id="experian-img"
                    className=" bg-transparent d-flex justify-content-center align-content-center pt-md-2"
                  >
                    <img
                      style={{
                        width: "122px",
                        backgroundColor: "transparent",
                      }}
                      // src={experianImg}
                      src="./images/experian.png"
                      alt="experian-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* OTP Modal */}
        <Modal
          className="bg-transparent"
          size="lg"
          show={show}
          onHide={() => {
            setShow(false);
            resetOtp();
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <Modal.Body>
            <div className="row bg-transparent" id="otpCon">
              <div className="col-lg-6 px-0 bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/auth.webp"
                  alt="Authentication"
                />
              </div>

              <div className="col-lg-6 bg-transparent" id="otp-section">
                <div>
                  <p className="m-0 p-0 otp-text">Enter OTP</p>
                  <p className="m-0 p-0 otp-desc">
                    Please enter the one-time password (OTP) sent on the given
                    number - {mergedData.mobile}
                  </p>
                </div>

                <div className="otp-input-con bg-transparent">
                  <input
                    type="text"
                    maxLength="6"
                    className="otp-single-input bg-transparent"
                    value={otpField}
                    onChange={(e) => otpHandleChange(e.target)}
                  />
                </div>

                <div className="otpBtn bg-transparent">
                  {!otpLoader ? (

                    <button class="verify-btn"  disabled={otpLoader}  onClick={otpSubmit}>

                      Verify
                    </button>
                  ) : (
                    <button className="verify-btn">
                      <ColorRing
                        visible={otpLoader}
                        height="30"
                        width="30"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{ background: "transparent" }}
                        wrapperClass="color-ring-wrapper"
                        colors={[
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                        ]}
                      />
                    </button>
                  )}
                  <button id="resend-otp" onClick={handleSubmitForm}>
                    Resend OTP
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* terms-condition Modal */}
        <Modal
          className="bg-transparent"
          size="lg"
          show={showTwo}
          onHide={() => {
            setShowTwo(false);
            // resetOtp();
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <Modal.Body>
            <div
              style={{
                marginLeft: "5%",
                marginRight: "5%",
                marginTop: 10,
                fontSize: "21px",
                lineHeight: "3.75rem",
                color: "rgb(89, 38, 109)",
                fontWeight: "bold",
              }}
            >
              Terms and Conditions{" "}
            </div>
            <div
              className="api-text"
              style={{ marginLeft: "5%", marginRight: "5%" }}
              dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default BalanceTransferEligibility;

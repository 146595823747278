import React, { useContext, useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import "./Question.css";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import logo from ".././assets/Images/mLogo.webp";
import { ColorRing } from "react-loader-spinner";
import { Typography } from "@mui/material";
import MetadataContext from "../../MetadataContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F6F6F6",
  boxShadow: 24,
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 2,
  paddingRight: 2,
  border: 0,
};

const Paymonthly = () => {
  const location = useLocation();
  const { occupation, monthly, category } = location.state || {};
  const [progress, setProgress] = useState(60);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  // Load stored value from localStorage when component mounts
  useEffect(() => {
    const storedValue = localStorage.getItem("monthly_spend");
    if (storedValue) {
      setValue(storedValue);
    } else {
      setValue("10000");
      localStorage.setItem("monthly_spend", "10000");
    }
  }, []);

  // Update localStorage whenever value changes
  useEffect(() => {
    localStorage.setItem("monthly_spend", value);
  }, [value]);

  const PostHandler = () => {
    if (value === "") {
      alert("Please Fill The Form!");
    } else {
      navigate("/select-assets-or-investments-to-use");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      PostHandler();
    }
  };

  const formatNumberWithCommas = (number) => {
    if (number === 0 || number === null || number === undefined) {
      return "Enter spend";
    }
    return new Intl.NumberFormat("en-IN").format(number);
  };

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "How much do you pay monthly? - Minemi",
      pageDescription:
        "How much do you pay monthly? Tell us your budget, and MinEmi will recommend the best loan options tailored to your needs.",
      pageKeywords:
        "loan recommendation, monthly payment, loan options, MinEmi, best loan offers, loan eligibility, personal loan, home loan, car loan, finance planning",

      canonicalUrl: "https://minemi.ai/pay-monthly",
    });
  }, [setMetadata]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div className="progress-bar-background" />
        <div className="progress-bar" style={{ width: `${progress}%` }} />
        <div className="min-h-[100vh] pt-4 md:pt-8 px-4 md:px-6">
          <div
            className="container"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingLeft: "4%",
              paddingRight: "4%",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", gap: 8 }}
              onClick={() => navigate("/select-existing-emi")}
            >
              <FaArrowLeftLong
                style={{
                  alignSelf: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  fontSize: 20,
                  color: "#8A8A8A",
                }}
              />
              <p
                style={{
                  alignSelf: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  fontSize: 16,
                  marginBottom: 0,
                  color: "#8A8A8A",
                }}
              >
                Back
              </p>
            </div>
            <div onClick={() => setOpen(true)}>
              <IoClose
                style={{
                  alignSelf: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  fontSize: 24,
                  color: "#8A8A8A",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <div
            className="container mt-4"
            style={{
              textAlign: "center",
              fontFamily: "Archivo",
              color: "#000000",
              fontSize: 24,
              fontWeight: 600,
            }}
          >
            How much do you pay monthly?
          </div>
          <div
            className="container"
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 20,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: 350,
                border: "1px solid #846CA6",
                borderRadius: 8,
                backgroundColor: "#FFFFFF",
                height: 49,
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
              }}
            >
              <div
                style={{
                  alignSelf: "center",
                  paddingLeft: "4%",
                  backgroundColor: "white",
                }}
              >
                ₹
              </div>
              <input
                className="input-fiedl-inner"
                value={value !== "0" ? formatNumberWithCommas(value) : ""}
                type="text"
                placeholder="Enter spend"
                style={{
                  width: 338,
                  border: "0px solid white",
                  marginLeft: 5,
                  marginRight: 8,
                  outline: "none",
                  backgroundColor: "white",
                }}
                onChange={(e) => {
                  const rawValue = e.target.value.replace(/,/g, "");
                  if (!isNaN(rawValue)) setValue(rawValue);
                }}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div
            className="container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              onClick={PostHandler}
              className="next-button"
              style={{ fontFamily: "Archivo" }}
            >
              {loading ? (
                <ColorRing
                  visible={loading}
                  height="30"
                  width="30"
                  ariaLabel="color-ring-loading"
                  wrapperStyle={{ backgroundColor: "transparent" }}
                  className="color-ring-wrapper"
                  colors={[
                    "#ffffff",
                    "#ffffff",
                    "#ffffff",
                    "#ffffff",
                    "#ffffff",
                    "#ffffff",
                  ]}
                />
              ) : (
                "NEXT"
              )}
            </div>
          </div>
        </div>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: 40, height: 40 }}>
                <img
                  src={logo}
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </div>
              <IoClose
                onClick={() => setOpen(false)}
                style={{
                  alignSelf: "center",
                  fontWeight: 400,
                  fontSize: 24,
                  cursor: "pointer",
                }}
              />
            </div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#59266D",
                fontSize: 24,
                fontWeight: 700,
                marginTop: 16,
              }}
            >
              Wait ! your loan recommendation is just a step away !
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              style={{
                color: "#8A8A8A",
                fontSize: 18,
                fontWeight: 400,
                fontFamily: "Archivo",
              }}
            >
              Complete the form to receive the best credit card tailored to your
              needs.
            </Typography>
            <div
              style={{
                backgroundColor: "#59266D",
                padding: 15,
                color: "#FFFFFF",
                borderRadius: 10,
                textAlign: "center",
                marginTop: "4%",
                fontFamily: "Archivo",
                fontWeight: 700,
                fontSize: 16,
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            >
              Keep Going
            </div>
            <div
              style={{
                backgroundColor: "#F6F6F6",
                padding: 15,
                color: "#59266D",
                borderRadius: 10,
                textAlign: "center",
                marginTop: "4%",
                fontFamily: "Archivo",
                fontWeight: 700,
                fontSize: 16,
                border: "1px solid #59266D",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              No Thanks
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Paymonthly;

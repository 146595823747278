import React, { useEffect, useState } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import {useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "./Tailor.css";

const Tailor = () => {
  const navigate = useNavigate();

  const [overdraft, setOverdraft] = useState({});
  const [topUp, setTopUp] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    const isFid = sessionStorage.getItem("fId");
    if (isFid !== null) {
      const jsonData = localStorage.getItem("eligibleData");
      const eligibleData = JSON.parse(jsonData);
      if (eligibleData.data.actions.length < 1) {
        navigate("/", { replace: true });
      }
      setOverdraft(eligibleData.data.actions[0]);
      setTopUp(eligibleData.data.actions[1]);
    }
  }, []);
  return (
    <>
      <div id="tailor">
        <div className="headerCon">
          <p className="header m-0 p-0 text-capitalize bg-transparent">
            Discover financial services tailored for your needs
          </p>
        </div>
        <div id="tailor-content" className="bg-transparent">
          <div className="row">
            <div className="col-12 col-md-6  ps-0 overdraft-main">
              <div id="overdraft">
                <p className="title">{overdraft.title}</p>
                <p className="desc mt-4 bg-transparent">{overdraft.text}</p>
                <div className="bg-transparent">
                  <a
                    href="#"
                    onClick={handleShow}
                    className="d-flex align-items-center p-0 bg-transparent"
                  >
                    <p className="mb-0 tailor-text bg-transparent">
                      Tailor It Now
                    </p>
                    <FaLongArrowAltRight className="tailor-icon bg-transparent" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6  pe-0 topUp-main">
              <div id="topUp">
                <p className="title ">{topUp.title}</p>
                <p className="desc mt-4 bg-transparent">{topUp.text}</p>
                <div className="bg-transparent">
                  <a
                    href="#"
                    onClick={handleShow}
                    className="d-flex align-items-center p-0 bg-transparent"
                  >
                    <p className="mb-0 tailor-text bg-transparent">
                      Tailor It Now
                    </p>
                    <FaLongArrowAltRight className="tailor-icon bg-transparent" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          className="bg-transparent"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal title</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div>
              <p id="tailor-title">Thank you for your interest!</p>
              <p id="tailor-notice">
                {" "}
                Our team has received your request, and one of our
                representatives will get in touch with you shortly. Stay tuned
                for updates
              </p>
              {/* <Button variant="secondary" id="tailor-modal-btn" onClick={handleClose}>
                Close
              </Button> */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Tailor;

import React from "react";
import { useState, useMemo } from "react";

const faqs = [
  {
    question: "What is loan consolidation?",
    answer:
      "Loan consolidation combines multiple loans into a single loan with one EMI, making repayment simpler and potentially saving you money.",
  },
  {
    question: "How does loan consolidation save me money?",
    answer:
      "By lowering your interest rate and reducing your monthly payments, loan consolidation can help you save on overall loan costs.",
  },
  {
    question: "Will my credit score be affected by loan consolidation?",
    answer:
      "Loan consolidation can improve your credit score if you maintain timely payments, as it simplifies your debt management.",
  },
  {
    question: "How do I know if loan consolidation is right for me?",
    answer:
      "If you have multiple loans with high interest rates or managing multiple EMIs is difficult, loan consolidation can help simplify your finances.",
  },
  {
    question: "How long does the loan consolidation process take?",
    answer:
      "The process is quick, typically taking just a few days to complete, depending on your eligibility and documentation.",
  },
  {
    question: "Are there any fees associated with loan consolidation?",
    answer:
      "At Minemi, we believe in transparency—there are no hidden fees in the loan consolidation process.",
  },
  {
    question: "Can I consolidate secured and unsecured loans together?",
    answer:
      "Yes, you can consolidate both secured (like home or car loans) and unsecured loans (like credit cards) into one single loan.",
  },
];

export function AskedQuestions() {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredFaqs = useMemo(() => {
    const term = searchTerm.toLowerCase();
    return faqs.filter(
      (faq) =>
        faq.question.toLowerCase().includes(term) ||
        faq.answer.toLowerCase().includes(term)
    );
  }, [searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <section className="py-12 bg-[#f9fafb] w-100">
      <div
        className="w-[90%] max-w-[1200px] mx-auto"
        style={{ backgroundColor: "#f9fafb" }}
      >
        <h2 className="text-3xl text-center mb-4 bg-[#f9fafb] text-[#59266d]">
          Frequently Asked Questions
        </h2>
        <p className="text-center mb-8 bg-[#f9fafb]">
          Have questions about loan consolidation? We've got the answers to help
          you make an informed decision.
        </p>

        <div className="mb-8 text-center bg-[#f9fafb]">
          <input
            type="text"
            placeholder="Search FAQs..."
            value={searchTerm}
            onChange={handleSearch}
            className="w-full max-w-[500px] px-4 py-2 border rounded-md"
          />
        </div>

        {filteredFaqs.length === 0 ? (
          <p className="text-center text-gray-600">
            No matching questions found. Please try a different search term.
          </p>
        ) : (
          <div className="space-y-4">
            {filteredFaqs.map((faq, index) => (
              <div key={index} className="border rounded-md bg-white shadow-sm">
                <div className="p-4 bg-gray-100 font-semibold">
                  {faq.question}
                </div>
                <div className="p-4">{faq.answer}</div>
              </div>
            ))}
          </div>
        )}

        <div className="text-center mt-12 bg-[#f9fafb]">
          <a
            style={{ textDecoration: "none", color: "#ffffff" }}
            href="/login?goal=loan-consolidate"
            onClick={() => {
              localStorage.setItem("id", 2);
              window.scrollTo(0, 0);
            }}
          >
            <button className="px-8 py-3 bg-[#59266d] text-white rounded-md hover:bg-purple-800">
              Still have questions? Contact us today!
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}

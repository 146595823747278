import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import { Typography } from "@mui/material";
import TongueOut from "../../../Assets/Images/tongue-out.webp";
import { Link } from "react-router-dom";
import MetadataContext from "../../MetadataContext";

const GoldLoanProcedure = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        "How to Get a Gold Loan - Gold Loan Procedure & Schemes (2025)",
      pageDescription:
        "Learn how to get a gold loan with our step-by-step guide. Explore gold loan procedures,repayment options, and schemes from SBI, Canara Bank, and leading lenders.",
      pageKeywords:
        "How to get a gold loan,gold loan procedure, SBI gold loan procedure, Canara Bank gold loan procedure, loan against gold, gold loan schemes, gold loan process, gold loan benets, gold loan tips, gold loan for wedding, gold loan for education, gold loan bullet repayment, gold overdraft loan",
      canonicalUrl: "https://minemi.ai/loanpage/gold-loan/gold-loan-procedure",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/gold-loan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Gold Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Gold Loan Procedure
              </Link>{" "}
            </p>
          </div>

          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontSize: 40,
              color: "#59266D",
              backgroundColor: "#E7E4F9",
              lineHeight: 1,
            }}
          >
            Gold Loan Procedure: How to Get a Gold loan
          </h1>

          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Unlock the potential of your gold for quick nancial needs. This
            guide explains the gold loan process, available schemes, and how to
            choose the best option from banks like SBI, HDFC, and Canara Bank.
          </p>
        </div>

        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
          <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
            Introduction to Gold Loans
          </h3>

          <div>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px ",
              }}
            >
              A gold loan is one of the fastest and easiest ways to access funds
              by leveraging the gold you already own. Whether for{" "}
              <strong>
                medical emergencies, business needs, education, or weddings
              </strong>
              , a gold loan provides <strong>instant liquidity</strong> without
              selling your assets.
            </p>
          </div>
          <div>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Banks and Non-Banking Financial Companies (NBFCs) oer{" "}
              <strong>loan amounts up to 75% of the gold’s value</strong>, with
              exible repayment options and competitive interest rates. With
              minimal paperwork and quick disbursal,gold loans have become a
              popular choice for short-term nancial requirements.
            </p>
          </div>

          <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
            Why Consider a Gold Loan?
          </h3>
          <div>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Quick Processing:{" "}
                  </span>
                  Get funds within hours of applying.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Lower Interest Rates:{" "}
                  </span>
                  Compared to personal loans, gold loans oer interest rates
                  starting at 7.25% p.a.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    No Credit Score Dependency:{" "}
                  </span>
                  Approval depends on gold value, not credit score.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Flexible Repayment Options:{" "}
                  </span>
                  Choose from EMI, bullet repayment, or overdraft schemes.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Multipurpose Loan:{" "}
                  </span>
                  Use the funds for{" "}
                  <strong>
                    any purpose – education, weddings, medical expenses, or
                    business expansion
                  </strong>
                  .
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
              Step-by-Step Gold Loan Procedure
            </h3>
            <h4 style={{ color: "#1F1F1F", fontSize: 20, marginTop: 20 }}>
              1. Choose the Right Lender
            </h4>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Gold loans are oered by:
            </p>
          </div>
          <div>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  Banks – SBI, HDFC, Canara Bank, ICICI Bank
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  NBFCs – Muthoot Finance, Manappuram Finance, Rupeek
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  Cooperative Banks and Local Lenders
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip section */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={TongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Choose banks for lower interest rates and NBFCs for faster
                    disbursal with minimal documentation.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
            2. Application Process
          </h3>
          <div>
            <h5
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              There are three ways to apply for a gold loan:
            </h5>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Online:{" "}
                  </span>
                  Apply through bank websites like{" "}
                  <strong>HDFC or ICICI </strong>
                  or NBFC platforms like <strong>Muthoot</strong>. Schedule an
                  appointment for gold pickup or visit the branch.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Branch Visit:{" "}
                  </span>
                  Walk into any branch with your gold and necessary documents.
                  Get the loan processed immediately after valuation.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Doorstep Service:{" "}
                  </span>
                  NBFCs like <strong>Rupeek and Muthoot</strong> provide home
                  services where gold is collected, appraised, and the loan is
                  disbursed directly to your account
                </div>
              </div>
            </div>
          </div>

          <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
            3. Gold Evaluation and Loan Sanction
          </h3>
          <div>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Purity Check:{" "}
                  </span>
                  Lenders evaluate gold for purity (measured in karats).
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Weight and Value Assessment:{" "}
                  </span>
                  Gold is weighed, and the loan amount is determined based on
                  the current market price.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  You can get <strong>up to 75% </strong>of the gold’s value as
                  per <strong>RBI regulations</strong>.
                </div>
              </div>
            </div>
            <h5
              style={{
                fontSize: 20,
                fontFamily: "'Archivo',sans-serif",
                paddingTop: 20,
              }}
            >
              Example:
            </h5>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                color: "#1F1F1F",
                fontWeight: 400,
                paddingTop: "10px",
              }}
            >
              If the gold price is ₹5,500 per gram and you pledge 100 grams, the
              maximum loan amount could be ₹4,12,500.
            </p>
          </div>

          <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
            4. Loan Disbursal
          </h3>
          <div>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Once the evaluation is complete, the loan is disbursed through:
            </p>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  Bank Transfer
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  Cheque
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  Cash (for smaller amounts)
                </div>
              </div>
            </div>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                color: "#1F1F1F",
                fontWeight: 400,
                paddingTop: "20px",
              }}
            >
              Most banks and NBFCs provide the loan amount within a few hours.
            </p>
          </div>

          <div>
            <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
              Gold Loan Schemes and Repayment Options
            </h3>
            <div>
              <h4
                style={{
                  fontFamily: "Archivo",
                  color: "#1F1F1F",
                  fontSize: 18,
                  marginTop: 20,
                }}
              >
                1. Bullet Repayment
              </h4>
              <div
                style={{
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      How It Works:{" "}
                    </span>
                    Pay the interest monthly or quarterly and repay the
                    principal at the end of the tenure.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Best For:{" "}
                    </span>
                    Short-term nancing of 3 to 12 months.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Example:{" "}
                    </span>
                    An entrepreneur took a ₹5 lakh bullet loan for 6 months from
                    SBI to manage cash ow. He paid ₹4,000 monthly interest and
                    settled the entire ₹5 lakh at the end of the tenure.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Oered By:{" "}
                    </span>
                    <strong>
                      SBI, ICICI Bank, Canara Bank, Muthoot Finance.
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h4
                style={{
                  fontFamily: "Archivo",
                  color: "#1F1F1F",
                  fontSize: 18,
                  marginTop: 20,
                }}
              >
                2. Overdraft Gold Loan
              </h4>
              <div
                style={{
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      How It Works:{" "}
                    </span>
                    Interest is charged only on the utilized amount. You can
                    withdraw funds as needed, repay, and reuse the credit limit.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Best For:{" "}
                    </span>
                    <strong>
                      Businesses and individuals with uctuating cash ows.
                    </strong>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Example:{" "}
                    </span>
                    A trader pledged gold worth ₹8 lakh at HDFC Bank. He
                    withdrew ₹3 lakh to buy inventory and repaid within 2
                    months. Interest was charged only on the ₹3 lakh for 60
                    days.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Oered By:{" "}
                    </span>
                    <strong>FC Bank, ICICI Bank, SBI.</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h4
                style={{
                  fontFamily: "Archivo",
                  color: "#1F1F1F",
                  fontSize: 18,
                  marginTop: 20,
                }}
              >
                3. EMI Gold Loan
              </h4>
              <div
                style={{
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      How It Works:{" "}
                    </span>
                    Fixed monthly installments cover both principal and
                    interest.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Best For:{" "}
                    </span>
                    Salaried professionals preferring structured repayment.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Example:{" "}
                    </span>
                    A salaried individual took a ₹2 lakh gold loan from Canara
                    Bank for his child’s education, opting for a 12-month EMI at
                    ₹18,000 per month.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Oered By:{" "}
                    </span>
                    <strong>nara Bank, Axis Bank, Union Bank.</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={TongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    Trusted Expert Quote
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    <em className="bg-transparent">
                      “Gold loans are a lifeline during emergencies. Opt for
                      bullet repayment if you expect inow within months or
                      overdraft schemes if you need exibility,”
                    </em>
                    <strong className="bg-transparent">
                      -Rajan Sharma, Financial Advisor at ICICI Bank
                    </strong>
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <h3
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: 24,
                margin: 25,
                color: "#1f1f1f",
              }}
            >
              Gold Loan Comparison (Top 10 Banks and NBFCs in India):
            </h3>
          </div>

          {/* table data */}
          <div id="types-of-bt">
            <div id="eligibility-criteria">
              <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
                Gold Loan Comparison Table
              </h3>
            </div>

            <div
              id="bt-types-table"
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                      }}
                    >
                      Bank/NBFC
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Interest Rate
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Max Tenure
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Loan Type
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Processing Fees
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      SBI
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      7.25% p.a.
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      36 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Bullet/EMI/Overdraft
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      0.5%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      HDFC Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      7.5% p.a.
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      24 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      EMI/Bullet
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Canara Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      7.35% p.a.
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      24 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      EMI/Bullet
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      0.5%
                    </td>
                  </tr>

                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      ICICI Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8% p.a.
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      36 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Overdraft/Bullet
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Muthoot Finance
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.5% p.a.
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      12 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Bullet/EMI
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div>
            <div>
              <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
                Minemi’s Tips to Maximize Gold Loan Benefits
              </h3>
              <div
                style={{
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Opt for Overdraft Facilities{" "}
                    </span>
                    to minimize interest costs during surplus periods.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Negotiate processing fees{" "}
                    </span>
                    to lower upfront costs.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Compare rates from multiple lenders{" "}
                    </span>
                    to find the best deal.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      Repay early{" "}
                    </span>
                    to reduce overall interest.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
              Conclusion
            </h3>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                marginTop: "10px",
                lineHeight: "24px",
                fontWeight: 400,
              }}
            >
              Gold loans oer quick liquidity and exibility, but selecting the
              right scheme is crucial. By comparing{" "}
              <strong>interest rates, tenure, and repayment options</strong>{" "}
              from top banks and NBFCs, you can make the most informed decision.
            </p>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                marginTop: "10px",
                lineHeight: "24px",
                fontWeight: 400,
              }}
            >
              <strong>
                Explore and apply for the best gold loan with Minemi today.
              </strong>
            </p>
          </div>

          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br className="display-none" /> and tailored just for you—apply
                now to see what you qualify for!
              </p>
              <div
                style={{
                  backgroundColor: "transparent",
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                }}
              >
                <a
                  href="https://minemi.ai/personal-loan/check-eligibility"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 50,
                    paddingRight: 50,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                >
                  Check Eligiblity Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoldLoanProcedure;

import { ShieldCheck, Lock } from "lucide-react"

export default function SecuritySection() {
  return (
    <section className="w-full py-8 md:py-16 bg-[#311664] text-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-6 md:mb-12">
          <h2 className="text-2xl md:text-4xl font-bold mb-2 md:mb-4">Security & Accuracy Assurance</h2>
          <p className="text-base md:text-lg text-[#ebe8fc] max-w-2xl mx-auto">
            At Minemi, we take your data security and the accuracy of our predictions seriously.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-4 md:gap-8 max-w-4xl mx-auto">
          <div className="bg-white/10 backdrop-blur-sm p-4 md:p-8 rounded-xl border border-white/20">
            <div className="flex items-start">
              <div className="mr-3 md:mr-4">
                <ShieldCheck className="h-6 w-6 md:h-10 md:w-10 text-[#3a868b]" />
              </div>
              <div>
                <h3 className="text-base md:text-xl font-bold mb-1 md:mb-3">Bank-Approved Eligibility Criteria</h3>
                <p className="text-xs md:text-base text-[#ebe8fc]">
                  Our system is aligned with actual lending standards used by major financial institutions. We regularly
                  update our algorithms to reflect current lending practices, ensuring you get the most accurate
                  assessment.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-white/10 backdrop-blur-sm p-4 md:p-8 rounded-xl border border-white/20">
            <div className="flex items-start">
              <div className="mr-3 md:mr-4">
                <Lock className="h-6 w-6 md:h-10 md:w-10 text-[#3a868b]" />
              </div>
              <div>
                <h3 className="text-base md:text-xl font-bold mb-1 md:mb-3">Data Encryption & Privacy</h3>
                <p className="text-xs md:text-base text-[#ebe8fc]">
                  Your personal information is protected with bank-level encryption. We never share your data with third
                  parties without your explicit consent, and we adhere to strict data privacy regulations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


import React from "react";
import "./CheckRate.css";

const CheckRate = ({ data }) => {
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-IN").format(number)
  }


  return (
    <>
      <div id="Loan-Check-Rate">
        <div className="titleCon">
          <p className="title m-0 p-0">Compare top lender rates below</p>
        </div>

        <div className="CheckRate-contentCon">
          <p id="CheckRate-title">
            As per your request, the interest rate for your {data.loantype=="hl"?"Home":data.loantype=="pl"?"Personal":"Car"} loan
            requirement is    <b style={{fontWeight:800}}>{data?.tableData?.loanFound?.irr}%</b>.
       
          </p>  
            {/* <p id="CheckRate-titles">
           <span style={{textDecoration:'underline'}}>Compare top lender rates below.</span>
       
          </p> */}

          <div className="container table-con">
            <table className="table table-bordered text-start m-0 p-0">
              <thead>
                <tr>
                  <th>Bank Name</th>
                  <th>Requested Loan Amount (₹)</th>
                  <th>Interest Rate (%)</th>
                  <th>EMI Per Month (₹)</th>
                </tr>
              </thead>
              <tbody>
                {data?.tableData.bankList.map((row, index) => (
                  <tr key={index}>
                    <td className="bank-name">{row.bank_name} BANK</td>
                    <td>{formatNumberWithCommas(row?.eligible_loan_amt||0)}</td>
                    <td>
                      <strong>{row.irr||0}%</strong>
                    </td>
                    <td>{formatNumberWithCommas(Math.floor(row.emi)||0)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckRate;

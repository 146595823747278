import React, { useEffect, useContext } from "react";
import loanaccepted from "../../Assets/Images/creditScore-image/loan accepted.webp";
import home from "../../Assets/Images/creditScore-image/HomeScreen.webp";
import lowchart from "../../Assets/Images/creditScore-image/Low Chart.webp";
import credit from "../../Assets/Images/creditScore-image/Credit Card.webp";
import Authorized from "../../Assets/Images/creditScore-image/Authorized.webp";
import CreditBuilder from "../../Assets/Images/creditScore-image/Credit Builder.webp";
import wallet from "../../Assets/Images/creditScore-image/wallet.webp";
import CompensationAndBenefits from "../../Assets/Images/creditScore-image/Compensation And Benefits.webp";
import Vector from "../../Assets/Images/creditScore-image/Vector.webp";
import Layer2 from "../../Assets/Images/creditScore-image/Layer 2.webp";
import tablerclock from "../../Assets/Images/creditScore-image/tabler_clock.webp";
import stashchartpie from "../../Assets/Images/creditScore-image/stash_chart-pie.webp";
import SearchFile from "../../Assets/Images/creditScore-image/Search File.webp";
import check from "../../Assets/Images/creditScore-image/check.webp";
import "./CreditScore.css";
import MetadataContext from "../MetadataContext";
import { useMediaQuery } from "@mui/material";

const CreditScore = () => {
  // UseMediaQuery
  const isSmallScreen = useMediaQuery("(max-width:576px)");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Check Your Credit Score with Minemi.ai",
      pageDescription:
        "Instantly check your credit score with Minemi.ai. Get accurate results and take control of your financial health with our easy-to-use credit score checker",
      pageKeywords:
        "credit score check, free credit score, check my credit score, credit check",
      canonicalUrl: "https://minemi.ai/credit-score",
    });
  }, [setMetadata]);
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        {/* 1  */}
        <div className=" Check-credit ">
          <div
            className=" Check-credit-heading "
            style={{ backgroundColor: "#FAF6FF" }}
          >
            <h1>Check your credit score for free!</h1>
            <p style={{ fontSize: 20, lineHeight: 1.3, fontWeight: "400" }}>
              Discover smart financial solutions tailored to your needs. Whether
              it's balance transfers, loan consolidation, or flexible top-ups,
              we've got you covered.
            </p>
            <button className="button">
              <a
                style={{ color: "white" }}
                href="/login?goal=lower-your-emi"
                onClick={() => {
                  localStorage.setItem("id", 1);
                  window.scrollTo(0, 0);
                }}
              >
                Check Credit Score
              </a>
            </button>
          </div>
          <div style={{ backgroundColor: "#FAF6FF" }}>
            <img src={home} alt="Company Logo" className="img-fluid" />
          </div>
        </div>

        {/* 2  */}
        <div className="container-Score" style={{ backgroundColor: "#f8f8f8" }}>
          <div className="col-12">
            <h4 className=" Score-heading">What is a Credit Score?</h4>
            <p className=" Score-paragraph ">
              A credit score is a three-digit number that represents your
              creditworthiness. In India, credit scores range from 300 to 900,
              with higher scores
              <br /> indicating better creditworthiness. The Credit Information
              Bureau (India) Limited (CIBIL) is one of the primary credit
              bureaus in India that
              <br /> calculates these scores.
            </p>
          </div>
        </div>

        {/* 3  */}
        <div
          className="container-good-credit"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          <div className="col-12">
            <div className="">
              <h3 className="good-credit-heading">
                Why is a Good Credit Score Important?
              </h3>
              <p className="good-credit-paragraph mt-2">
                A good credit score, typically above 750, can lead to:
              </p>
            </div>
            <div className="good-credits mt-4">
              <div className="good-credit-card">
                <div>
                  <img
                    src={loanaccepted}
                    alt="Loan Approval Icon"
                    style={{
                      maxHeight: "32px",
                      width: "32px",
                    }}
                  />
                </div>
                <div style={{ alignSelf: "center" }}>
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: 18,
                      lineHeight: 1.2,
                    }}
                  >
                    <strong
                      style={{
                        fontWeight: "700",
                        fontSize: 18,
                      }}
                    >
                      Easier Loan Approvals:
                    </strong>{" "}
                    Lenders are more likely to approve loans for individuals
                    with high credit scores.
                  </p>
                </div>
              </div>

              <div className="good-credit-card">
                <div>
                  <img
                    src={lowchart}
                    alt="Lower Interest Rates Icon"
                    style={{
                      maxHeight: "32px",
                      width: "32px",
                    }}
                  />
                </div>
                <div style={{ alignSelf: "center" }}>
                  <p
                    style={{
                      fontSize: 18,
                      fontWeight: "400",
                      lineHeight: 1.2,
                    }}
                  >
                    <strong>Lower Interest Rates:</strong> Borrowers with
                    excellent credit scores often receive loans at lower
                    interest rates.
                  </p>
                </div>
              </div>

              <div className="good-credit-card">
                <div>
                  <img
                    src={CompensationAndBenefits}
                    alt="Higher Credit Limit Icon"
                    style={{
                      maxHeight: "32px",
                      width: "32px",
                    }}
                  />
                </div>
                <div style={{ alignSelf: "center" }}>
                  <p
                    style={{
                      fontSize: 18,
                      fontWeight: "400",
                      lineHeight: 1.2,
                    }}
                  >
                    <strong>Higher Credit Limits:</strong> Credit card issuers
                    may offer higher credit limits to individuals with strong
                    credit histories.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 4 */}
        <div className="Credit-History" style={{ backgroundColor: "#faf6ff" }}>
          <div className="credit-heading">
            <h2>
              Building a <span style={{ color: "#59266D" }}>Credit Score</span>{" "}
              with No Credit History
            </h2>
            <p
              style={{
                fontWeight: "400",
                fontSize: 18,
              }}
            >
              If you have no credit history, consider the following steps to
              build your credit score:
            </p>
          </div>
          <div className="credit-cards" style={{}}>
            <div style={{ display: "flex" }}>
              <img
                src={credit}
                alt="Credit Builder Loan"
                style={{ maxHeight: "48px", width: "48px", marginRight: "8px" }}
              />
              <div style={{}}>
                <h4
                  style={{
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "28px",
                  }}
                >
                  Apply for a Secured Credit Card:
                </h4>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "#8A8A8A",
                  }}
                >
                  These cards are backed by a fixed deposit and can help
                  establish credit history.
                </p>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <img
                src={Authorized}
                alt="Credit Builder Loan"
                style={{ maxHeight: "48px", width: "48px", marginRight: "8px" }}
              />
              <div>
                <h4
                  style={{
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "28px",
                  }}
                >
                  Become an Authorized User:
                </h4>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "#8A8A8A",
                  }}
                >
                  Being added to someone else's credit card account can help you
                  build credit.
                </p>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <img
                src={CreditBuilder}
                alt="Credit Builder Loan"
                style={{ maxHeight: "48px", width: "48px", marginRight: "8px" }}
              />
              <div>
                <h4
                  style={{
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "28px",
                  }}
                >
                  Take a Credit-Builder Loan:
                </h4>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "#8A8A8A",
                  }}
                >
                  Some financial institutions offer loans specifically designed
                  to help build credit.
                </p>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <img
                src={wallet}
                alt="Credit Builder Loan"
                style={{ maxHeight: "48px", width: "48px", marginRight: "8px" }}
              />
              <div>
                <h4
                  style={{
                    fontWeight: "700",
                    fontSize: "18px",
                    lineHeight: "28px",
                  }}
                >
                  Use Credit Responsibly:
                </h4>
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "#8A8A8A",
                  }}
                >
                  Make small purchases and pay off the balance in full each
                  month to establish a positive payment history.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* 5 */}
        <div className="container-good-credit">
          <div className="col-12">
            <div className="">
              <h4 className="good-credit-heading">
                Factors Affecting Your Credit Score
              </h4>
              <p
                className="good-credit-paragraph mt-2"
                style={{
                  fontWeight: "400",
                  fontSize: 18,
                }}
              >
                Several factors influence your credit score:
              </p>
            </div>
            <div className="good-credits mt-3">
              <div className="good-credit-card">
                <div>
                  <img
                    src={Vector}
                    alt="Loan Approval Icon"
                    style={{
                      maxHeight: "32px",
                      width: "32px",
                      marginRight: "8px",
                    }}
                  />
                </div>
                <div style={{ alignSelf: "center" }}>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: "400",
                      lineHeight: 1.2,
                    }}
                  >
                    <strong>Payment History:</strong> Timely payments boost your
                    score, while missed or late payments can significantly lower
                    it.
                  </p>
                </div>
              </div>

              <div className="good-credit-card">
                <div>
                  <img
                    src={Layer2}
                    alt="Lower Interest Rates Icon"
                    style={{
                      maxHeight: "32px",
                      width: "32px",
                      marginRight: "8px",
                    }}
                  />
                </div>
                <div style={{ alignSelf: "center" }}>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: "400",
                      lineHeight: 1.2,
                    }}
                  >
                    <strong>Credit Utilization Ratio:</strong> This is the ratio
                    of your current credit card balances to your credit limits.
                    Maintaining a low ratio (preferably below 30%) is
                    beneficial.
                  </p>
                </div>
              </div>

              <div className="good-credit-card">
                <div>
                  <img
                    src={tablerclock}
                    alt="Higher Credit Limit Icon"
                    style={{
                      maxHeight: "32px",
                      width: "32px",
                      marginRight: "8px",
                    }}
                  />
                </div>
                <div style={{ alignSelf: "center" }}>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: "400",
                      lineHeight: 1.2,
                    }}
                  >
                    <strong>Length of Credit History:</strong> A longer credit
                    history provides more data on your financial behavior, which
                    can positively impact your score.
                  </p>
                </div>
              </div>

              <div className="good-credit-card">
                <div>
                  <img
                    src={stashchartpie}
                    alt="Lower Interest Rates Icon"
                    style={{
                      maxHeight: "32px",
                      width: "32px",
                      marginRight: "8px",
                    }}
                  />
                </div>
                <div style={{ alignSelf: "center" }}>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: "400",
                      lineHeight: 1.2,
                    }}
                  >
                    <strong>Credit Mix:</strong> A diverse mix of credit
                    accounts, such as credit cards, mortgages, and personal
                    loans, can favorably affect your score.
                  </p>
                </div>
              </div>

              <div className="good-credit-card">
                <div>
                  <img
                    src={SearchFile}
                    alt="Lower Interest Rates Icon"
                    style={{
                      maxHeight: "32px",
                      width: "32px",
                      marginRight: "8px",
                    }}
                  />
                </div>
                <div style={{ alignSelf: "center" }}>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: "400",
                      lineHeight: 1.2,
                    }}
                  >
                    <strong>New Credit Inquiries:</strong> Frequent applications
                    for new credit can be seen as risky behavior and may lower
                    your score.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* new banner */}
        <div className="cibil_score" style={{ marginTop: "5%" }}>
          <div
            className="make-it-center increase-margin "
            style={{
              marginBottom: "2%",
              padding: 24,
              background: "linear-gradient(91.7deg, #EADCFF 0.4%, #f8f8f8 99%)",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 20,
              borderRadius: 24,
            }}
          >
            <div
              style={{
                marginLeft: "4%",
                backgroundColor: "transparent",
                display: "flex",
                flexDirection: "column",
                gap: 24,
                borderRadius: 24,
                alignSelf: "center",
              }}
            >
              <p
                className="eligiblity-criteria"
                style={{
                  fontFamily: "Archivo",
                  color: "#59266D",
                  fontSize: 40,
                  backgroundColor: "transparent",
                }}
              >
                <i style={{ backgroundColor: "transparent", lineHeight: 1.2 }}>
                  Check Your Cibil Score Without <br className="brake-remove" />{" "}
                  Impacting Your Credit Score
                </i>
              </p>
              <a
                href="/check-cibil-score"
                className="button"
                style={{
                  backgroundColor: "#59266D",
                  color: "#FFFFFF",
                  width: isSmallScreen ? "100%" : "60%",
                  borderRadius: 8,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 18,
                  paddingTop: 8,
                  paddingBottom: 8,
                  textAlign: "center",
                }}
              >
                Check Your Cibil Score Now
              </a>
            </div>
            <div
              style={{
                width: 200,
                height: 195,
                backgroundColor: "transparent",
                marginRight: "4%",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
              }}
            >
              <img
                src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Illustration-1736403318651.webp"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                loading="lazy"
                alt="check eligibility"
              />
            </div>
          </div>
        </div>

        {/* 6 */}
        <div
          className="container-fluid Improve"
          style={{ backgroundColor: "#FAF6FF" }}
        >
          <div className="Improve-Score" style={{ backgroundColor: "#FAF6FF" }}>
            <h4 style={{ backgroundColor: "#FAF6FF", fontWeight: "700" }}>
              Tips to Improve and Maintain Your Credit Score
            </h4>
          </div>
          <div>
            <ul
              className="list-unstyled"
              style={{ backgroundColor: "#FAF6FF" }}
            >
              <li
                className="d-flex  mb-4"
                style={{ backgroundColor: "#FAF6FF" }}
              >
                <img
                  src={check}
                  alt="Checkmark"
                  className="check-icon me-2"
                  style={{ backgroundColor: "#FAF6FF" }}
                />
                <p
                  className="mb-0 Improve-Score-card"
                  style={{ backgroundColor: "#FAF6FF" }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                      fontSize: 18,
                      backgroundColor: "#FAF6FF",
                    }}
                  >
                    Pay Bills on Time:
                  </span>{" "}
                  Consistently paying your bills by the due date is crucial.
                </p>
              </li>
              <li
                className="d-flex  mb-4"
                style={{ backgroundColor: "#FAF6FF" }}
              >
                <img
                  src={check}
                  alt="Checkmark"
                  className="check-icon me-2"
                  style={{ backgroundColor: "#FAF6FF" }}
                />
                <p
                  className="mb-0 Improve-Score-card"
                  style={{ backgroundColor: "#FAF6FF" }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                      fontSize: 18,
                      backgroundColor: "#FAF6FF",
                    }}
                  >
                    Reduce Outstanding Debt:
                  </span>{" "}
                  Aim to pay off existing debts to lower your credit utilization
                  ratio.
                </p>
              </li>
              <li
                className="d-flex  mb-4"
                style={{ backgroundColor: "#FAF6FF" }}
              >
                <img
                  src={check}
                  alt="Checkmark"
                  className="check-icon me-2"
                  style={{ backgroundColor: "#FAF6FF" }}
                />
                <p
                  className="mb-0 Improve-Score-card"
                  style={{ backgroundColor: "#FAF6FF" }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                      fontSize: 18,
                      backgroundColor: "#FAF6FF",
                    }}
                  >
                    Limit New Credit Applications:
                  </span>{" "}
                  Apply for new credit only when necessary to avoid multiple
                  hard inquiries.
                </p>
              </li>
              <li
                className="d-flex  mb-4"
                style={{ backgroundColor: "#FAF6FF" }}
              >
                <img
                  src={check}
                  alt="Checkmark"
                  className="check-icon me-2"
                  style={{ backgroundColor: "#FAF6FF" }}
                />
                <p
                  className="mb-0 Improve-Score-card"
                  style={{ backgroundColor: "#FAF6FF" }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                      fontSize: 18,
                      backgroundColor: "#FAF6FF",
                    }}
                  >
                    Maintain Old Credit Accounts:
                  </span>{" "}
                  Keeping older accounts open can lengthen your credit history,
                  which is beneficial.
                </p>
              </li>
              <li
                className="d-flex  mb-4"
                style={{ backgroundColor: "#FAF6FF" }}
              >
                <img
                  src={check}
                  alt="Checkmark"
                  className="check-icon me-2"
                  style={{ backgroundColor: "#FAF6FF" }}
                />
                <p
                  className="mb-0 Improve-Score-card"
                  style={{ backgroundColor: "#FAF6FF" }}
                >
                  <span
                    style={{
                      fontWeight: "700",
                      fontSize: 18,
                      backgroundColor: "#FAF6FF",
                    }}
                  >
                    Monitor Your Credit Report:
                  </span>{" "}
                  Regularly review your credit report to identify and dispute
                  any inaccuracies.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditScore;

export const faqData = [
  {
    id: 1,
    question: "What is loan consolidation?",
    answer:
      "Loan consolidation is the process of combining multiple loans into a single loan with one monthly payment. This can simplify repayment and may offer a lower interest rate or extended repayment terms.",
  },
  {
    id: 2,
    question: "How does loan consolidation work?",
    answer:
      "When you consolidate loans, a lender pays off your existing debts and issues you a new loan with a fixed repayment schedule. This allows you to manage your debts more efficiently by making a single payment instead of multiple payments.",
  },
  {
    id: 3,
    question: "Will consolidating my loans save me money?",
    answer:
      "Loan consolidation can save you money if you secure a lower interest rate or extend your repayment term to reduce monthly payments. However, a longer loan term may result in paying more interest over time.",
    // answerList: [
    //   {
    //     heading: " Loan Amount: ",
    //     info: "Higher amounts lead to higher EMIs.",
    //   },
    //   {
    //     heading: " Interest Rate:",
    //     info: "Lower rates reduce your EMI and total repayment.",
    //   },
    //   {
    //     heading: "Tenure:  ",
    //     info: " Longer tenures lower EMIs but increase total interest.",
    //   },
    // ],
  },
  {
    id: 4,
    question: "Can I consolidate different types of loans?",
    answer:
      "Yes, you can consolidate various types of loans, including personal loans, credit card debt, student loans, and other unsecured debts. However, secured loans (such as mortgages or car loans) typically cannot be consolidated with unsecured loans.",
  },
  {
    id: 5,
    question: "Will consolidating my loans affect my credit score?",
    answer:
      "Loan consolidation may initially cause a slight dip in your credit score due to a hard inquiry on your credit report. However, over time, making consistent on-time payments can improve your credit score.",
  },
  {
    id: 6,
    question: "Are there any fees for loan consolidation?",
    answer:
      "Some lenders may charge origination fees, processing fees, or prepayment penalties for consolidating loans. It’s essential to review the terms of your consolidation loan to understand any associated costs.",
  },
  {
    id: 7,
    question: "Can I consolidate loans with bad credit?",
    answer:
      "Yes, but your options may be limited. If you have bad credit, you might face higher interest rates or require a co-signer. Some lenders specialize in loan consolidation for individuals with lower credit scores.",
  },
  {
    id: 8,
    question: "What happens if I miss a payment on my consolidated loan?",
    answer:
      "Missing a payment can result in late fees, increased interest rates, and a negative impact on your credit score. It’s crucial to make timely payments or set up automatic payments to avoid penalties.",
  },
];

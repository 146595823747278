import { useEffect, useMemo, useState } from "react";
import { CheckCircle } from "lucide-react";
export function LoanPredictorWidget({ name }) {
  const [loanAmount, setLoanAmount] = useState(300000);
  const [creditScore, setCreditScore] = useState(750);
  const [employmentType, setEmploymentType] = useState("salaried");
  const [data, setData] = useState([]);

  const getApprovalChance = () => {
    let baseScore = 0;
    if (creditScore >= 750) baseScore = 85;
    else if (creditScore >= 700) baseScore = 65;
    else if (creditScore >= 650) baseScore = 40;
    else baseScore = 20;
    const amountFactor =
      loanAmount <= 300000 ? 5 : loanAmount >= 1000000 ? -5 : 0;

    const employmentFactor = employmentType === "salaried" ? 5 : -5;

    const finalScore = Math.min(
      95,
      Math.max(15, baseScore + amountFactor + employmentFactor)
    );

    if (finalScore >= 80) return "High (80-95%)";
    if (finalScore >= 60) return "Medium (60-80%)";
    if (finalScore >= 40) return "Low (40-60%)";
    return "Very Low (Below 40%)";
  };

  const getApprovalColor = () => {
    if (getApprovalChance().startsWith("High")) return "text-green-600";
    if (getApprovalChance().startsWith("Medium")) return "text-yellow-600";
    if (getApprovalChance().startsWith("Low")) return "text-orange-500";
    return "text-red-600";
  };
  const kotakData = useMemo(
    () => ({
      h2: " Predict Your Kotak Bank Loan Approval Chances",
      para: "   Our AI-powered predictor uses Kotak Bank's actual approval criteria to estimate your chances",
      changes: " Approval Chance:",
      score: "Your credit score meets Kotak Bank's preferred criteria",
      type: "Salaried applicants have higher approval rates with Kotak Bank",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      h2: " Predict Your Loan Approval Chances",
      para: "  Use our quick predictor tool to estimate your Bank of Baroda loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "SThis loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const idfcData = useMemo(
    () => ({
      h2: " Predict Your IDFC FIRST Bank Loan Approval Chances",
      para: "  Our AI-powered predictor uses IDFC FIRST Bank's actual approval criteria to estimate your chances",
      changes: " Approval Chance:",
      score: "Your credit score meets IDFC FIRST Bank's preferred criteria",
      type: "Salaried applicants have higher approval rates with IDFC FIRST Bank",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      h2: " Predict Your Loan Approval Chancess",
      para: "  Use our quick predictor tool to estimate your HDFC Bank loan approval probabilitys",
      changes: "Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Analysis",
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      h2: " Predict Your Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your SBI loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Analysis",
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      h2: " Predict Your Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your Axis Bank loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Analysis",
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      h2: " Predict Your Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your ICICI Bank loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Analysis",
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      h2: " Predict Your Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your Punjab National Bank loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Analysis",
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      h2: " Predict Your Kotak Bank Loan Approval Chances",
      para: "   Use our quick predictor tool to estimate your IndusInd Bank loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      h2: " Predict Your Loan Approval Chances",
      para: "  Use our quick predictor tool to estimate your Tata Capital loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      h2: " Predict Your Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your Yes Bank loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      h2: " Predict Your Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your Union Bank loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      h2: " Predict Your Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your Bajaj Finance loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      h2: " Predict Your Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your Federal Bank loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      h2: " Predict Your Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your Canara Bank loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      h2: " Predict Your Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your Bank of India loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      h2: " Predict Your Loan Approval Chances",
      para: "Use our quick predictor tool to estimate your RBL Bank loan approval probability",
      changes: " Approval Chance:",
      score: "Your credit score is excellent for loan approval",
      type: "This loan amount has higher approval rates",
      report: "Get Detailed Approval Report",
    }),
    []
  );

  useEffect(() => {
    if (name === "checkkotakbankstatus") {
      setData(kotakData);
    } else if (name === "checkbobbankstatus") {
      setData(bobData);
    } else if (name === "checkidfcbankstatus") {
      setData(idfcData);
    } else if (name === "checkhdfcbankstatus") {
      setData(hdfcData);
    } else if (name === "checksbibankstatus") {
      setData(sbiData);
    } else if (name === "checkaxisbankstatus") {
      setData(axisData);
    } else if (name === "checkicicibankstatus") {
      setData(iciciData);
    } else if (name === "checkpunjabbankstatus") {
      setData(punjabData);
    } else if (name === "checkinduslndbankstatus") {
      setData(induslndData);
    } else if (name === "checktatabankstatus") {
      setData(tataData);
    } else if (name === "checkyesbankstatus") {
      setData(yesData);
    } else if (name === "checkunionbankstatus") {
      setData(unionData);
    } else if (name === "checkbajajbankstatus") {
      setData(bajajData);
    } else if (name === "checkfederalbankstatus") {
      setData(federalData);
    } else if (name === "checkcanarabankstatus") {
      setData(canaraData);
    } else if (name === "checkboibankstatus") {
      setData(boiData);
    } else if (name === "checkrblbankstatus") {
      setData(rblData);
    } else {
      setData([]);
    }
  }, [
    name,
    axisData,
    bobData,
    hdfcData,
    iciciData,
    idfcData,
    kotakData,
    sbiData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
  ]);

  return (
    <div className="md:w-4/5 sd:w-full mx-auto bg-transparent">
      <div className="text-center mb-4 bg-transparent">
        <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple bg-transparent">
          {data.h2}
        </h2>
        <p className="text-dark-grey mt-1 text-sm text-center bg-transparent">
          {data.para}
        </p>
      </div>

      <div
        className=" mx-2  p-6 rounded-lg border border-gray-200 shadow-sm"
        style={{ backgroundColor: "#ebe8fb" }}
      >
        <div className="space-y-5 bg-transparent">
          <div className="bg-transparent">
            <label
              htmlFor="loan-amount"
              className="block text-sm font-medium text-dark-grey mb-2 bg-transparent"
            >
              Loan Amount (₹)
            </label>
            <input
              type="range"
              id="loan-amount"
              min="50000"
              max="1500000"
              step="50000"
              value={loanAmount}
              onChange={(e) => setLoanAmount(Number.parseInt(e.target.value))}
              className="w-full h-2 bg-white rounded-lg appearance-none cursor-pointer"
            />
            <div className="flex justify-between mt-2 bg-transparent">
              <span className="text-xs text-dark-grey bg-transparent">
                ₹50K
              </span>
              <span className="text-sm font-medium text-primary-purple bg-transparent">
                ₹{(loanAmount / 100000).toFixed(1)}L
              </span>
              <span className="text-xs text-dark-grey bg-transparent">
                ₹15L
              </span>
            </div>
          </div>

          <div className="bg-transparent">
            <label
              htmlFor="credit-score"
              className="block text-sm font-medium text-dark-grey mb-2 bg-transparent"
            >
              Credit Score
            </label>
            <input
              type="range"
              id="credit-score"
              min="550"
              max="850"
              step="10"
              value={creditScore}
              onChange={(e) => setCreditScore(Number.parseInt(e.target.value))}
              className="w-full h-2 bg-white rounded-lg appearance-none cursor-pointer"
            />
            <div className="flex justify-between mt-2 bg-transparent">
              <span className="text-xs text-dark-grey bg-transparent">550</span>
              <span className="text-sm font-medium text-primary-purple bg-transparent">
                {creditScore}
              </span>
              <span className="text-xs text-dark-grey bg-transparent">850</span>
            </div>
          </div>

          <div className="bg-transparent">
            <label className="block text-sm font-medium text-dark-grey mb-2 bg-transparent">
              Employment Type
            </label>
            <div className="flex gap-4 bg-transparent">
              <label className="flex items-center bg-transparent">
                <input
                  type="radio"
                  name="employment-type"
                  value="salaried"
                  checked={employmentType === "salaried"}
                  onChange={() => setEmploymentType("salaried")}
                  className="mr-2"
                />
                <span className="text-sm text-dark-grey bg-transparent ">
                  Salaried
                </span>
              </label>
              <label className="flex items-center bg-transparent">
                <input
                  type="radio"
                  name="employment-type"
                  value="self-employed"
                  checked={employmentType === "self-employed"}
                  onChange={() => setEmploymentType("self-employed")}
                  className="mr-2"
                />
                <span className="text-sm text-dark-grey bg-transparent">
                  Self-Employed
                </span>
              </label>
            </div>
          </div>

          <div className="bg-white w-full p-4  rounded-lg border border-gray-200">
            <div className="flex justify-between items-center mb-3 bg-transparent">
              <span className="text-sm font-medium text-dark-grey bg-transparent">
                {data.changes}
              </span>
              <span
                className={`text-sm font-bold bg-transparent ${getApprovalColor()}`}
              >
                {getApprovalChance()}
              </span>
            </div>
            <div className=" bg-transparent ">
              <div className="flex items-start gap-0.5 md:gap-2 bg-transparent ">
                <CheckCircle
                  className="h-5 w-5 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                  style={{ color: "green" }}
                />
                <p className="text-xs sm:text-sm  mb-2 text-dark-grey bg-transparent">
                  {creditScore >= 750
                    ? data.score
                    : "Improving your credit score will significantly boost approval odds"}
                </p>
              </div>
              <div className="flex items-start gap-0.5 md:gap-2 bg-transparent">
                <CheckCircle
                  className="h-5 w-5 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                  style={{ color: "green" }}
                />
                <p className="text-xs sm:text-sm text-dark-grey bg-transparent">
                  {employmentType === "salaried"
                    ? data.type
                    : "Self-employed applicants may need additional income documentation"}
                </p>
              </div>
            </div>
            {/* <Link
              href="https://minemi.ai/personal-loan/check-eligibility/"
              className="inline-flex h-12  mt-2 items-center justify-center rounded-md bg-[#311663] px-6 text-sm font-medium text-white transition-colors w-full hover:bg-purple-900"
            >
              {data.report}
            </Link> */}
            <a
              href="/trackloanstatus"
              className="inline-flex h-12 mt-2 items-center justify-center rounded-md bg-[#311663] px-6 text-sm font-medium text-white transition-colors w-full hover:bg-purple-900"
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.report}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Diversity1 } from "@mui/icons-material"
import {
    CheckCircle,
    TrendingUp,
    Clock,
    XCircle,
    Target,
    Shield,
    UserCheck,
    List,
    BarChart,
    CreditCard,
    DollarSign,
    Lock,
  } from "react-feather"
  
  const Testimonial = ({ quote, author, location }) => (
    <div className="bg-indigo-100 text-indigo-900 p-4 rounded-lg mb-4">
      <p className="italic mb-2" style={{backgroundColor:'transparent'}}>"{quote}"</p>
      <p className="font-semibold text-sm" style={{backgroundColor:'transparent'}}>
        — {author}, {location}
      </p>
    </div>
  )
  
  function CreditCardEligiblity() {
    return (
      <div className="min-h-screen bg-gradient-to-br from-purple-900 to-indigo-800 text-white">
        <div className="max-w-6xl mx-auto px-4 py-8" style={{backgroundColor:'transparent'}}>
          {/* Hero Section */}
          <section className="mb-4 md:mb-8 flex flex-col justify-center" style={{backgroundColor:'transparent'}}>
          <header className="text-center space-y-2 md:space-y-4 mb-4 md:mb-6" style={{backgroundColor:'transparent'}}>
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold leading-tight text-white" style={{backgroundColor:'transparent'}}>
              Check Credit Card Eligibility Before Applying Online
            </h1>
            <p className="text-base md:text-lg lg:text-xl text-gray-200 max-w-3xl mx-auto" style={{backgroundColor:'transparent',textAlign:'center'}}>
              Check eligibility, limits, and recommendation to get the card of your choice.
            </p>
          </header>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-4 mb-4 md:mb-6" style={{backgroundColor:'transparent'}}>
            {[
              { icon: CheckCircle, text: "Know your chances before applying" },
              { icon: TrendingUp, text: "Get personalized card options" },
              { icon: Clock, text: "Avoid rejections and delays in your application" },
            ].map((item, index) => (
              <div
                key={index}
                className="bg-gradient-to-br from-white to-[#EBE8FC] rounded-lg shadow-md p-3 md:p-4 flex items-center space-x-3 transition-transform duration-300 hover:scale-105"
              >
                <item.icon className="w-6 h-6 md:w-8 md:h-8 text-[#3a868b] flex-shrink-0" style={{backgroundColor:'transparent'}} />
                <p className="text-sm md:text-base text-[#59266d] font-medium" style={{backgroundColor:'transparent'}}>{item.text}</p>
              </div>
            ))}
          </div>

          <div className="text-center mt-6 md:mt-8" style={{backgroundColor:'transparent'}}>
            <button className="bg-[#3a868b] hover:bg-[#2d6f6d] text-white text-lg md:text-xl font-semibold py-3 px-6 md:py-4 md:px-8 rounded-full transition-colors duration-300 shadow-lg">
              Check My Eligibility Now
            </button>
          </div>

          <div className="text-center text-sm md:text-base bg-gradient-to-br from-white to-[#EBE8FC] py-2 px-4 rounded-full inline-block mx-auto text-[#59266d] shadow-sm mt-4 md:mt-6">
            100% Free | No Credit Score Impact | Trusted by Thousands
          </div>
        </section>

  
          {/* Why Check Eligibility Section */}
          <section className="mb-4 md:mb-8 flex flex-col justify-center" style={{backgroundColor:'transparent'}}> 
          <h2 className="text-2xl md:text-5xl lg:text-6xl font-bold text-center text-white mb-4 md:mb-10" style={{backgroundColor:'transparent'}}>
            Why Check Your Credit Card Eligibility Before Applying?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mb-6 md:mb-10 bg-white/10 rounded-lg p-6 md:p-10">
            <div className="space-y-3 md:space-y-6" style={{backgroundColor:'transparent'}}>
              {[
                {
                  icon: XCircle,
                  title: "Avoid Rejections",
                  description: "Know your chances before applying to avoid rejections that hurt your credit score.",
                },
                {
                  icon: Clock,
                  title: "Save Time",
                  description: "No more wasted time applying for cards that don't match your profile.",
                },
                {
                  icon: Target,
                  title: "Get Tailored Offers",
                  description: "Find credit cards suited to your income, credit score, and spending habits.",
                },
                {
                  icon: Shield,
                  title: "Peace of Mind",
                  description: "Apply confidently, knowing you're eligible for the product.",
                },
              ].map((item, index) => (
                <div key={index} className="flex items-start space-x-4 md:space-x-6" style={{backgroundColor:'transparent'}}>
                  <div className="bg-[#3a868b] p-3 md:p-4 rounded-full" >
                    <item.icon className="w-6 h-6 md:w-8 md:h-8 text-white"  style={{backgroundColor:'transparent'}}/>
                  </div>
                  <div style={{backgroundColor:'transparent'}}>
                    <h3 className="text-lg md:text-2xl font-semibold mb-2 md:mb-3 text-white" style={{backgroundColor:'transparent'}}>{item.title}</h3>
                    <p className="text-sm md:text-lg text-gray-200" style={{backgroundColor:'transparent'}}>{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex items-center justify-center" style={{backgroundColor:'transparent'}}>
              <div className="relative w-48 h-48 md:w-64 md:h-64" style={{backgroundColor:'transparent'}}>
                <div className="absolute inset-0 bg-[#3a868b] rounded-full opacity-20"></div>
                <div className="absolute inset-2 bg-[#3a868b] rounded-full opacity-40"></div>
                <div className="absolute inset-3 bg-[#3a868b] rounded-full opacity-60"></div>
                <div className="absolute inset-4 bg-[#3a868b] rounded-full opacity-80"></div>
                <div className="absolute inset-5 bg-[#3a868b] rounded-full flex items-center justify-center">
                  <CheckCircle className="w-16 h-16 md:w-20 md:h-20 text-white" style={{backgroundColor:'transparent'}} />
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-8 md:mt-12" style={{backgroundColor:'transparent'}}>
          <button className="bg-teal-600 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded-full transition-colors duration-300">
                  Check Your Eligibility Now
                </button>
          </div>
        </section>

  
          {/* Why Eligibility Checks Are Crucial Section */}
          <section className="mb-16 text-center" style={{backgroundColor:'transparent'}}>
            <h2 className="text-3xl md:text-4xl font-bold mb-4" style={{backgroundColor:'transparent'}}>
              Why Eligibility Checks Are Crucial for Your Financial Success
            </h2>
            <p className="text-xl max-w-3xl mx-auto" style={{backgroundColor:'transparent',textAlign:'center'}}>
              Did you know 80% of credit card applications are rejected? Be smart – avoid rejection with tailored
              recommendations based on your profile.
            </p>
          </section>
  
          {/* How MinEMI Makes Eligibility Checks Easy Section */}
          <section className="mb-6 md:mb-12 flex flex-col justify-center" style={{backgroundColor:'transparent'}}>
          <h2 className="text-xl md:text-4xl lg:text-5xl font-bold text-center text-white mb-4 md:mb-8" style={{backgroundColor:'transparent'}}>
            How MinEMI Makes Eligibility Checks Easy
          </h2>
          <div className="grid grid-cols-2 gap-4 md:gap-6 mb-4 md:mb-6" style={{backgroundColor:'transparent'}}>
            {[
              {
                icon: UserCheck,
                title: "Step 1",
                description: "Share your details and consent to securely fetch your credit score.",
              },
              {
                icon: List,
                title: "Step 2",
                description: "View a personalized list of credit cards suited to your financial profile.",
              },
              {
                icon: BarChart,
                title: "Step 3",
                description: "Compare benefits, interest rates, and eligibility criteria.",
              },
              { icon: CreditCard, title: "Step 4", description: "Choose your card and apply seamlessly." },
            ].map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md p-4 md:p-6 text-center transition-transform duration-300 hover:scale-105"
              >
                <div className="bg-[#3a868b] p-3 md:p-4 rounded-full inline-flex items-center justify-center mb-3 md:mb-4">
                  <item.icon className="w-6 h-6 md:w-8 md:h-8 text-white" style={{backgroundColor:'transparent'}} />
                </div>
                <h3 className="text-sm md:text-lg font-semibold mb-2 md:mb-3 text-[#59266d]" style={{backgroundColor:'transparent'}}>{item.title}</h3>
                <p className="text-xs md:text-sm text-[#59266d]" style={{backgroundColor:'transparent',textAlign:'center'}}>{item.description}</p>
              </div>
            ))}
          </div>
          <div className="text-center mt-6 md:mt-10" style={{backgroundColor:'transparent'}}>
            <button className="bg-[#3a868b] hover:bg-[#2d6f6d] text-white text-base md:text-xl font-semibold py-3 px-6 rounded-full transition-colors duration-300 shadow-lg">
              Check My Eligibility Now
            </button>
          </div>
        </section>

  
          {/* Why Check Eligibility with MinEMI? Section */}
          <section className="mb-6 md:mb-12 flex flex-col justify-center" style={{backgroundColor:'transparent'}}>
          <h2 className="text-xl md:text-4xl lg:text-5xl font-bold text-center text-white mb-4 md:mb-8" style={{backgroundColor:'transparent'}}>
            Why Check Eligibility with MinEMI?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mb-4 md:mb-8" style={{backgroundColor:'transparent'}}>
            {[
              {
                icon: CheckCircle,
                title: "No Impact on Credit Score",
                description: "Eligibility checks won't affect your credit score.",
              },
              {
                icon: DollarSign,
                title: "Completely Free",
                description: "We don't charge users—our revenue comes from banks.",
              },
              {
                icon: Target,
                title: "Personalized Recommendations",
                description: "Get tailored offers based on your credit score and profile.",
              },
              {
                icon: Lock,
                title: "Secure & Private",
                description: "Your data is protected with industry-grade encryption.",
              },
            ].map((item, index) => (
              <div
                key={index}
                className="flex items-start space-x-4 bg-white/10 p-4 md:p-6 rounded-lg transition-transform duration-300 hover:scale-105"
              >
                <div className="bg-[#3a868b] p-3 md:p-4 rounded-full">
                  <item.icon className="w-6 h-6 md:w-8 md:h-8 text-white"  style={{backgroundColor:'transparent'}}/>
                </div>
                <div style={{backgroundColor:'transparent'}}>
                  <h3 className="text-lg md:text-xl font-semibold text-white mb-2 md:mb-3" style={{backgroundColor:'transparent'}}>{item.title}</h3>
                  <p className="text-sm md:text-base text-gray-200" style={{backgroundColor:'transparent'}}>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center bg-white/20 rounded-lg p-4 md:p-6">
            <Shield className="w-8 h-8 md:w-10 md:h-10 text-[#3a868b] mr-4" style={{backgroundColor:'transparent'}}/>
            <span className="text-lg md:text-xl text-white font-semibold"style={{backgroundColor:'transparent'}}>Trusted by 50,000+ Happy Customers</span>
          </div>
          <div className="text-center mt-6 md:mt-10" style={{backgroundColor:'transparent'}}>
            <button className="bg-[#3a868b] hover:bg-[#2d6f6d] text-white text-base md:text-xl font-semibold py-3 px-6 rounded-full transition-colors duration-300 shadow-lg">
              Check My Eligibility Now
            </button>
          </div>
        </section>

  
          {/* Testimonials Section */}
          <section className="mb-16" style={{backgroundColor:'transparent'}}>
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-8" style={{backgroundColor:'transparent'}}>
              See How MinEMI Helps Users Find the Right Credit Cards
            </h2>
            <div className="max-w-3xl mx-auto" style={{backgroundColor:'transparent'}}>
              <Testimonial
                quote="MinEMI showed me exactly which cards I was eligible for, saving me from multiple rejections."
                author="Priya S."
                location="Bangalore"
              />
              <Testimonial
                quote="I didn't know which card suited me. MinEMI helped me find the perfect cashback card!"
                author="Rajesh M."
                location="Mumbai"
              />
              <Testimonial
                quote="Checking my eligibility was fast and accurate—I applied confidently and got approved."
                author="Ankita T."
                location="Delhi"
              />
            </div>
            <div className="text-center mt-8" style={{backgroundColor:'transparent'}}>
              <button className="bg-teal-600 hover:bg-teal-700 text-white font-bold py-3 px-6 rounded-full transition-colors duration-300 shadow-lg">
                Check My Eligibility Now
              </button>
            </div>
          </section>
  
          {/* The Smarter Way to Apply for Credit Cards Section */}
          <section className="mb-4 md:mb-8 flex flex-col justify-center bg-white rounded-lg p-4 md:p-6">
          <h2 className="text-lg md:text-3xl lg:text-4xl font-bold text-center text-[#59266d] mb-2 md:mb-6" style={{backgroundColor:'transparent'}}>
            The Smarter Way to Apply for Credit Cards
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 items-center" style={{backgroundColor:'transparent'}}>
            <div className="space-y-2 md:space-y-4" style={{backgroundColor:'transparent'}}>
              <p className="text-sm md:text-base text-[#59266d]" style={{backgroundColor:'transparent'}}>
                Avoid the risk of applying blindly and getting rejected.
              </p>
              <p className="text-sm md:text-base text-[#59266d]" style={{backgroundColor:'transparent'}}>
                MinEMI shows you cards with the best rewards, low fees, and better interest rates.
              </p>
              <p className="text-sm md:text-base font-semibold text-[#59266d]" style={{backgroundColor:'transparent'}}>
                "Why take chances when you can check eligibility first and save yourself the hassle?"
              </p>
              <button className="bg-[#3a868b] hover:bg-[#2d6f6d] text-white text-sm md:text-base py-2 px-4 rounded-full transition-colors duration-300">
                Check Your Eligibility Now
              </button>
            </div>
            <div className="bg-[#EBE8FC] p-2 md:p-4 rounded-lg">
              <h3 className="text-lg md:text-xl font-semibold text-[#59266d] mb-2 md:mb-4 text-center" style={{backgroundColor:'transparent'}}>
                Comparison: With vs Without Eligibility Check
              </h3>
              <div className="grid grid-cols-2 gap-2 md:gap-4" style={{backgroundColor:'transparent'}}>
                <div style={{backgroundColor:'transparent'}}>
                  <h4 className="font-semibold text-[#59266d] mb-1 md:mb-2" style={{backgroundColor:'transparent'}}>With MinEMI</h4>
                  <ul className="space-y-1 md:space-y-2" style={{backgroundColor:'transparent'}}>
                    {[
                      "Higher approval chances",
                      "Personalized card offers",
                      "Better rewards and rates",
                      "Faster approval process",
                    ].map((item, index) => (
                      <li key={index} className="flex items-center text-sm text-[#59266d]" style={{backgroundColor:'transparent'}}>
                        <CheckCircle className="w-4 h-4 text-green-500 mr-2 flex-shrink-0"  style={{backgroundColor:'transparent'}}/>
                        <span style={{backgroundColor:'transparent'}}>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div style={{backgroundColor:'transparent'}}>
                  <h4 className="font-semibold text-[#59266d] mb-1 md:mb-2" style={{backgroundColor:'transparent'}}>Without MinEMI</h4>
                  <ul className="space-y-1 md:space-y-2" style={{backgroundColor:'transparent'}}>
                    {[
                      "Risk of rejection",
                      "Generic card options",
                      "Potentially higher fees",
                      "Longer approval times",
                    ].map((item, index) => (
                      <li key={index} className="flex items-center text-sm text-[#59266d]" style={{backgroundColor:'transparent'}}>
                        <XCircle className="w-4 h-4 text-red-500 mr-2 flex-shrink-0" style={{backgroundColor:'transparent'}} />
                        <span style={{backgroundColor:'transparent'}}>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-4 md:mt-8" style={{backgroundColor:'transparent'}}>
            <button className="bg-[#3a868b] hover:bg-[#2d6f6d] text-white text-sm md:text-lg font-semibold py-2 px-4 rounded-full transition-colors duration-300 shadow-lg">
              Check My Eligibility Now
            </button>
          </div>
        </section>

  
          {/* Ready to Get Started? Section */}
          <section className="mb-8 md:mb-16 flex flex-col items-center justify-center rounded-lg p-6 md:p-10 bg-white/10" style={{backgroundColor:'transparent'}}>
          <h2 className="text-2xl md:text-5xl lg:text-6xl font-bold text-center text-white mb-4 md:mb-8" style={{backgroundColor:'transparent'}}>
            Ready to Get Started?
          </h2>
          <h3 className="text-xl md:text-3xl font-semibold text-center text-white mb-6 md:mb-10" style={{backgroundColor:'transparent'}}>
            Check Your Eligibility in 2 Minutes!
          </h3>
          <div className="bg-white rounded-lg p-6 md:p-10 mb-6 md:mb-10 w-full max-w-2xl shadow-lg">
            <ul className="space-y-4 md:space-y-6" style={{backgroundColor:'transparent'}}>
              <li className="flex items-start text-base md:text-xl text-[#59266d]" style={{backgroundColor:'transparent'}}>
                <CheckCircle className="w-6 h-6 md:w-8 md:h-8 text-green-500 mr-4 mt-1 flex-shrink-0"  style={{backgroundColor:'transparent'}}/>
                <span style={{backgroundColor:'transparent'}}>No impact on your credit score.</span>
              </li>
              <li className="flex items-start text-base md:text-xl text-[#59266d]" style={{backgroundColor:'transparent'}}>
                <CheckCircle className="w-6 h-6 md:w-8 md:h-8 text-green-500 mr-4 mt-1 flex-shrink-0" style={{backgroundColor:'transparent'}}/>
                <span style={{backgroundColor:'transparent'}}>100% free and secure.</span>
              </li>
              <li className="flex items-start text-base md:text-xl text-[#59266d]" style={{backgroundColor:'transparent'}}>
                <CheckCircle className="w-6 h-6 md:w-8 md:h-8 text-green-500 mr-4 mt-1 flex-shrink-0" style={{backgroundColor:'transparent'}}/>
                <span style={{backgroundColor:'transparent'}}>Personalized recommendations tailored to your profile.</span>
              </li>
            </ul>
          </div>
          <div className="text-center mb-6 md:mb-10" style={{backgroundColor:'transparent'}}>
            <button className="bg-teal-600 hover:bg-teal-700 text-white font-bold py-3 px-6 rounded-full transition-colors duration-300 shadow-lg">
            Check My Credit Card Eligibility Now
              </button>
          </div>
          <div className="text-center text-sm md:text-base text-white bg-white/20 py-2 px-4 rounded-full inline-block">
            No Impact on Credit Score | Trusted by Thousands | Secure Process
          </div>
        </section>
  
          {/* FAQ Section */}
          <section className="mb-16 bg-white text-indigo-900 rounded-lg p-8">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-4">
              Got Questions About Credit Card Eligibility?
            </h2>
            <h3 className="text-xl md:text-2xl font-semibold text-center mb-8">We've Got Answers!</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6" style={{backgroundColor:'transparent'}}>
              {[
                {
                  question: "What are the basic eligibility criteria for a credit card?",
                  answer:
                    "You must be at least 18 years old, have a steady income, and meet the credit score requirements.",
                },
                {
                  question: "Can I apply if I don't have a credit history?",
                  answer: "Yes, banks offer basic credit cards for first-time applicants with no credit history.",
                },
                {
                  question: "Does checking my eligibility affect my credit score?",
                  answer: "No, Minemi's eligibility check uses a soft inquiry that doesn't impact your score.",
                },
                {
                  question: "What documents do I need to apply?",
                  answer: "You'll need proof of identity, address, and income (e.g., PAN card, Aadhaar, salary slips).",
                },
                {
                  question: "Can I reapply if my application is denied?",
                  answer:
                    "Yes, you can reapply after addressing the reasons for denial, like improving your credit score.",
                },
                {
                  question: "How do I check my eligibility on Minemi?",
                  answer: "Use the online eligibility checker for instant, tailored results based on your profile.",
                },
              ].map((item, index) => (
                <details
                  key={index}
                  className="bg-indigo-100 rounded-lg p-4 transition-all duration-300 hover:bg-indigo-200"
                >
                  <summary className="font-semibold cursor-pointer" style={{backgroundColor:'transparent'}}>{item.question}</summary>
                  <p className="mt-2" style={{backgroundColor:'transparent'}}>{item.answer}</p>
                </details>
              ))}
            </div>
            <div className="text-center mt-8" style={{backgroundColor:'transparent'}}>
              <button className="bg-teal-600 hover:bg-teal-700 text-white font-bold py-3 px-6 rounded-full transition-colors duration-300 shadow-lg">
                Check My Eligibility Now
              </button>
            </div>
          </section>
  
          {/* Final CTA Section */}
          <section className="text-center mb-4 md:mb-8 flex flex-col justify-center" style={{backgroundColor:'transparent'}}>
          <h2 className="text-lg md:text-3xl lg:text-4xl font-bold text-white mb-1 md:mb-4" style={{backgroundColor:'transparent'}}>
            Ready to prepare for a reject proof application?
          </h2>
          <p className="text-xs md:text-sm text-gray-200 mb-2 md:mb-4" style={{backgroundColor:'transparent',textAlign:'center'}}>
            Join thousands of successful users who trust Minemi to guide their financial journey.
          </p>
        </section>
        </div>
      </div>
    )
  }
  
  export default CreditCardEligiblity
  
  
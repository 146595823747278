
import { useContext, useEffect, useRef, useState } from "react"
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { UserContext } from "../ContextAPI/FormContextAPI"
import axios from "axios"
import { ColorRing } from "react-loader-spinner"
import { useNavigate } from "react-router-dom";
import login from "../../Assets/Images/login.webp";
import experian from '../../Assets/Images/experian.webp'
import { Link } from "react-router-dom";



export default function SecondFold() {
    const [otpLoader, setOtpLoader] = useState(false);
    const navigate=useNavigate()

    const inputRefs = useRef([]);
  const [name, setName] = useState("")
  const [lname, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [consent, setConsent] = useState(false)
  const [consent2, setConsent2] = useState(false)
  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState("")
  const {city}=useContext(UserContext)
   const [stgOne, setStgOne] = useState("");
    const [stgTwo, setStgTwo] = useState("");
    const [mbl, setMbl] = useState("");
      const [token, setToken] = useState("");
        const [otpField, setOtpField] = useState(new Array(6).fill(""));
        const [show, setShow] = useState(false);
        const [loader, setLoader] = useState(false);
        const timerRef = useRef(null);
        const [isResendDisabled, setIsResendDisabled] = useState(true);
        const [timeLeft, setTimeLeft] = useState(() => {
          const endTime = parseInt(localStorage.getItem("otpEndTime"), 10);
          const now = new Date().getTime();
          const timeLeft = Math.floor((endTime - now) / 1000);
          return timeLeft > 0 ? timeLeft : 30;
        });
        const formatTime = (seconds) => {
          const minutes = Math.floor(seconds / 60);
          const remainingSeconds = seconds % 60;
          return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
        };
      
        const startTimer = (duration = 30) => {
          // Clear any existing timer
          if (timerRef.current) {
            clearInterval(timerRef.current);
          }
          
          setTimeLeft(duration);
          setIsResendDisabled(true);
          
          timerRef.current = setInterval(() => {
            setTimeLeft(prev => {
              if (prev <= 1) {
                clearInterval(timerRef.current);
                setIsResendDisabled(false);
                return 0;
              }
              return prev - 1;
            });
          }, 1000);
        };
      
        // Initialize timer when OTP modal is shown or when resending OTP
        useEffect(() => {
          if (show) {
            startTimer();
          }
          
          return () => {
            if (timerRef.current) {
              clearInterval(timerRef.current);
            }
          };
        }, [show]); // This effect depends on the 'show' state
      
    

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoader(true)
    setError("")

    // Basic validation
    const nameRegex = /^[a-zA-Z]{2,}$/;
    if (!nameRegex.test(name)) {
      setError("First name must contain only alphabets and be at least 2 characters.");
      setLoader(false)
      return;
    }
  
    if (!nameRegex.test(lname)) {
      setError("Last name must contain only alphabets and be at least 2 characters.");
      setLoader(false)

      return;
    }
  
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      setLoader(false)

      return;
    }
  
    // Mobile number validation: 10 digits only
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(mobile)) {
      setError("Mobile number must be 10 digits.");
      setLoader(false)

      return;
    }
  
    // Consent checkbox validation
    if (!consent) {
      setError("You must accept the consent.");
      setLoader(false)

      return;
    } 
       if (!consent2) {
      setError("You must accept the consent.");
      setLoader(false)

      return;
    }
  

    setIsSubmitting(true)
   
    try {
        const response = await axios.post(`${process.env.REACT_APP_SECRET_KEY}/login`, {
          fname: name,
          email: email,
          mobile: mobile,
          lname: lname,
          city: city,
        });

        // Check if the response is successful
        if (response.data.status) {
          // Destructure the required data from the response
          const { expData, token } = response.data.data;

          setStgOne(expData.stgOneHitId);
          setStgTwo(expData.stgTwoHitId);
          setToken(token.token);
          setTimeLeft(30);
          setIsResendDisabled(true)

          setMbl(mobile);
          setShow(true); // Show success state
          setLoader(false); // Hide loader
        } else {
          toast.error(response.data.msg);
          setLoader(false);
        }
      } catch (error) {
        // Handle error in case of API failure
        console.error("Error making POST request", error);
        setLoader(false); // Hide loader even in case of error
        toast.error("Something went wrong. Please try again later.");
      }
  finally {
      setIsSubmitting(false)
    }
  }
    const otpSubmit = async () => {
      setOtpLoader(true);
      const concatenateOtp = otpField.join("");
      if (concatenateOtp.length === 6) {
        let formData = new FormData();
        formData.append("stgOneHitId", stgOne);
        formData.append("stgTwoHitId", stgTwo);
        formData.append("otp", concatenateOtp);
        formData.append("mobileNo", mbl);
        
  
        formData.append("type", "CUSTOM");
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_SECRET_KEY}/verify_otp`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Add the token to the Authorization header
              },
            }
          );
  
          if (response.data.status == 1) {
            localStorage.setItem("token", token);
            localStorage.setItem("creditScore", response.data.data.credit_score);
            localStorage.setItem("name", response.data.data.name);
            localStorage.setItem("id", 1);
            setOtpLoader(false)
            ChatbotHandler()
            
            
          } else {
            toast.error(response.data.msg);
            setOtpLoader(false);
          }
        } catch (error) {
          toast.error(error.response.data.msg);
          console.error("Error making POST request", error);
          setOtpLoader(false);
        }
      }
    };
      const ChatbotHandler = async () => {
       
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_SECRET_KEY}/getChatToken`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Add the token to the Authorization header
              },
            }
          );
  
          if (response.data.status == 1) {
           window.location.href = `https://chat.minemi.ai/?token=${response.data.chatToken}`;
      
            
          } else {
            console.log(response.data)

           
          }
        } catch (error) {
          console.error("Error making POST request", error);
        }
      
    };
    const otpHandleChange = (element, index) => {
      const value = element.value.replace(/[^0-9]/g, "").slice(0, 1); // Ensure only one digit
      if (!value) return;
    
      const newOtp = [...otpField];
      newOtp[index] = value;
      setOtpField(newOtp);
    
      // Move focus to the next field if the current field has a value
      if (index < 5 && value) {
        inputRefs.current[index + 1].focus();
      }
    }
    
    const handleKeyDown = (e, index) => {
      if (e.key === "Backspace") {
        // Create a copy of the current OTP array
        const newOtp = [...otpField];
    
        // Clear the current field
        newOtp[index] = "";
    
        // If the current field is empty and not the first field, move focus to the previous field
        if (index > 0 && !newOtp[index]) {
          inputRefs.current[index - 1].focus();
        }
    
        // Update the state with the new OTP array
        setOtpField(newOtp);
      }
    }
    const handleResendOtp = async () => {
      if (isResendDisabled) return;
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/login`,
          {
            fname: name,
            email:email,
            mobile: mobile,
            lname: lname,
            city: city,
            purpose:"ai-landing-page"
          }
        );
    
        if (response.data.status) {
          const { expData, token } = response.data.data;
          setStgOne(expData.stgOneHitId);
          setStgTwo(expData.stgTwoHitId);
          setToken(token.token);
          setTimeLeft(30);
          setIsResendDisabled(true)
          setOtpField(new Array(6).fill("")); // Reset OTP fields
          toast.success("OTP resent successfully");
        } else {
          toast.error(response.data.msg);
        }
      } catch (error) {
        console.error("Error resending OTP", error);
        toast.error("Failed to resend OTP");
      }
    };
    
  return (
    <>
    <section className="py-16 md:py-24 px-4">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white border border-[#3a868b] rounded-lg shadow-xl">
          <div className="p-8">
            <h2 className="text-3xl md:text-4xl font-bold text-[#311664] text-center mb-8">
              Get Personalized AI Advice in 30 Seconds!
            </h2>

            <form onSubmit={handleSubmit} className="space-y-6 max-w-md mx-auto">
              <div className="space-y-2">
                <label htmlFor="name" className="block text-[#26292b] font-medium">
                  First Name
                </label>
                <input
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full px-4 py-2 bg-white border border-[#3a868b] rounded-md text-[#26292b] focus:outline-none focus:ring-2 focus:ring-[#3a868b]"
                  placeholder="Enter First name"
                  required
                />
              </div> 
              <div className="space-y-2">
                <label htmlFor="name" className="block text-[#26292b] font-medium">
                 Last Name
                </label>
                <input
                  id="lname"
                  value={lname}
                  onChange={(e) => setLastName(e.target.value)}
                  className="w-full px-4 py-2 bg-white border border-[#3a868b] rounded-md text-[#26292b] focus:outline-none focus:ring-2 focus:ring-[#3a868b]"
                  placeholder="Enter Last Name"
                  required
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="email" className="block text-[#26292b] font-medium">
                  Email Address
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 bg-white border border-[#3a868b] rounded-md text-[#26292b] focus:outline-none focus:ring-2 focus:ring-[#3a868b]"
                  placeholder="Enter email address"
                  required
                />
              </div>

              <div className="space-y-2">
                <label htmlFor="mobile" className="block text-[#26292b] font-medium">
                  Mobile Number
                </label>
                <input
                  id="mobile"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  className="w-full px-4 py-2 bg-white border border-[#3a868b] rounded-md text-[#26292b] focus:outline-none focus:ring-2 focus:ring-[#3a868b]"
                  placeholder="Enter mobile number"
                  required
                />
              </div>

              <div className="flex items-start space-x-2">
        <div className="flex items-center h-5">
          <input
            id="consent"
            type="checkbox"
            checked={consent}
            onChange={(e) => setConsent(e.target.checked)}
            className="w-4 h-4 text-[#3a868b] border-[#3a868b] rounded focus:ring-[#3a868b]"
          />
        </div>
        <label htmlFor="consentTerms" className="text-sm font-normal">
          I agree to MinEMI's{" "}
          <a
                         href="https://minemi.ai/terms-condition"
                         target="_blank"
            style={{ color: "rgba(89, 38, 109, 1)", fontWeight: 600 }}
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
                         href="https://minemi.ai/privacypolicy"
                         target="_blank"
            style={{ color: "rgba(89, 38, 109, 1)", fontWeight: 600 }}
          >
            Privacy Policy
          </a>
          {showMore1 ? (
            <>
              {" "}
              and authorise to be communicated via SMS, Call, WhatsApp, and
              Email. I hereby also allow MinEMI to access my credit report on my
              behalf.
              <span
                className="text-[#3a868b] cursor-pointer font-medium"
                onClick={() => setShowMore1(false)}
              >
                {" "}
                Read Less
              </span>
            </>
          ) : (
            <span
              className="text-[#3a868b] cursor-pointer font-medium"
              onClick={() => setShowMore1(true)}
            >
              {" "}
              ...Read More
            </span>
          )}
        </label>
      </div>

      <div className="flex items-start space-x-2 mt-2">
        <div className="flex items-center h-5">
          <input
            id="consentMarketing"
            type="checkbox"
            checked={consent2}
            onChange={(e) => setConsent2(e.target.checked)}
            className="w-4 h-4 text-[#3a868b] border-[#3a868b] rounded focus:ring-[#3a868b]"
          />
        </div>
        <label htmlFor="consentMarketing" className="text-sm font-normal">
          I hereby consent to Nine Triangles Pvt.Ltd. being appointed as my
          authorised 
          {showMore2 ? (
            <>
            representative to receive my credit information from
            Experian for the purpose of Credit Assessment{" "}
              to advise me on the best loan offers on expiry of 6 months from
              the date the consent is collected, whichever is earlier. I also
              agree to{" "}
              <a
                         href="https://minemi.ai/experianconsent"
                         target="_blank"
                style={{ color: "rgba(89, 38, 109, 1)", fontWeight: 600 }}
              >
                Experian Terms and Conditions.
              </a>
              <span
                className="text-[#3a868b] cursor-pointer font-medium"
                onClick={() => setShowMore2(false)}
              >
                {" "}
                Read Less
              </span>
            </>
          ) : (
            <span
              className="text-[#3a868b] cursor-pointer font-medium"
              onClick={() => setShowMore2(true)}
            >
              {" "}
              ...Read More
            </span>
          )}
        </label>
      </div>
              {error && <div className="text-red-600 text-sm">{error}</div>}
              <div className="d-flex flex-column align-items-center mt-3"style={{
                                              backgroundColor:'transparent' 
                                          }} >
                                                        
                                                        <div className="experianLogo">
              <img src={experian} alt="image" />
            </div>
                                                          </div>

              <button
                type="submit"
                className="w-full bg-[#59266d] hover:bg-[#3a868b] text-white py-3 px-4 rounded-lg font-medium transition-colors duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={loader}
              >
                  {loader ? 
                                      <ColorRing
                                        visible={loader}
                                        height="30"
                                        width="30"
                                        ariaLabel="color-ring-loading"
                                        wrapperClass="color-ring-wrapper"
                                        style={{ color: "white" }}
                                        colors={[
                                          "#ffffff",
                                          "#ffffff",
                                          "#ffffff",
                                          "#ffffff",
                                          "#ffffff",
                                          "#ffffff",
                                        ]}
                                      />
                                     : "Get Started"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
      <Modal
            size="lg"
            show={show}
            onHide={() => setShow(false)}
            backdrop={true}
            keyboard={false}
            style={{ backgroundColor: "transparent" }}
          >
            <Modal.Header closeButton className="border-0"></Modal.Header>
            <Modal.Body>
              <div className="row" id="otpCon">
                <div className="col-lg-6 px-0">
                  <img src={login} alt="" style={{ objectFit: "cover" }} />
                </div>
    
                <div className="col-lg-6" id="otp-section">
                  <div>
                    <p className="m-0 p-0 otp-text ">Enter OTP</p>
                    <p className="m-0 p-0 otp-desc mt-2">
                      Please enter the one-time password (OTP) sent on the given
                      number - {mobile}
                    </p>
                  </div>
    
                  <div className="d-flex justify-content-between otp-input-con">
                    {otpField.map((digit, index) => (
                      <Form.Control
                        type="number"
                        className="otp-inputs"
                        key={index}
                        maxLength="1"
                        value={digit}
                        onChange={(e) => otpHandleChange(e.target, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (inputRefs.current[index] = el)}
                      />
                    ))}
                  </div>
                  <div style={{
                fontWeight: 700,
                fontSize: 14,
                color: "rgba(89, 38, 109, 1)",
                textAlign: "center",
                marginTop:'5%'
              }}
            >
              {formatTime(timeLeft)} 
            </div>
    
                  <div className="otpBtn">
                    {!otpLoader ? (
                      <Button
                        className="button-class"
                        id="verify-btn"
                        onClick={otpSubmit}
                      >
                        Verify
                      </Button>
                    ) : (
                      <Button
                        className="button-class"
                        id="verify-btn"
                        style={{ backgroundColor: "#633376" }}
                      >
                        <ColorRing
                          visible={otpLoader}
                          height="30"
                          width="30"
                          ariaLabel="color-ring-loading"
                          wrapperStyle={{ backgroundColor: "#633376" }}
                          wrapperClass="color-ring-wrapper"
                          style={{ color: "white", backgroundColor: "#633376" }}
                          colors={[
                            "#ffffff",
                            "#ffffff",
                            "#ffffff",
                            "#ffffff",
                            "#ffffff",
                            "#ffffff",
                          ]}
                        />
                      </Button>
                    )}
                     <Button
                  className="button-class"
                  id="resend-otp"
                  disabled={isResendDisabled}
                  onClick={() => handleResendOtp()}
                >
                  Resend OTP
                </Button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          </>
  )
}


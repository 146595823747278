import { useLocation, useParams } from "react-router-dom";
import { LoanPredictorWidget } from "./LoanPredictorWidget";
import { LoanStatusChecker } from "./LoanStatusChecker";
import { LoanStatusMethods } from "./LoanStatusMethods";
import { StatusExplanation } from "./StatusExplanation";
import { KeyApprovalFactors } from "./KeyApprovalFactors";
import { MinemiTools } from "./MinemiTools";
import { Testimonials } from "./Testimonials";
import { RelatedContent } from "./RelatedContent";
import { TrustIndicators } from "./TrustIndicators";
import { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import Homeloan from "../Homeloanstatus/Homeloan";

import Faq from "./Faq";

export default function Kotakbank() {
  const { bankdetails } = useParams();
  const [data, setData] = useState(null);
  const [notFound, setNotFound] = useState(false);
  // const { setMetadata } = useContext(MetadataContext);

  const kotakdata = useMemo(
    () => ({
      heading:
        "Track Your Kotak Bank Loan Status & Maximize Approval Potential",
      paragraph:
        "Monitor your Kotak Mahindra Bank personal loan application in real-time and use MinEMI's data-driven tools to enhance your approval chances and secure competitive interest rates.",
      buttons: [
        {
          text: "Check Application Status Now",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Analyze Your Approval Chances",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your Kotak Bank Loan Journey with Confidence",
      h2des:
        "Kotak Mahindra Bank's personal loan process becomes seamless with MinEMI's specialized tools and expert guidance.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Real-Time Status Tracking",
          description:
            "Monitor your Kotak Bank loan application status securely with instant updates at every processing stage.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Smart Recommendations",
          description:
            "Receive data-driven suggestions to enhance your Kotak Bank loan approval probability and secure better rates.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Access Kotak Bank loan specialists who understand the bank's internal approval criteria and decision-making process.",
        },
      ],
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      heading:
        "Check Your Bank of Baroda Personal Loan Status Online - Avoid Rejection & Get Real-Time Updates!",
      paragraph:
        "Easily track your BoB loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",
      buttons: [
        {
          text: "Check Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your Bank of Baroda Personal Loan Journey",
      h2des:
        "Checking your Bank of Baroda personal loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information. ",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <circle cx="12" cy="12" r="10" />
              <path d="M12 6v6l4 2" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );
  const idfcData = useMemo(
    () => ({
      heading:
        "IDFC FIRST Bank Personal Loan Status | Track & Improve Approval Chances ",
      paragraph:
        "Get real-time updates on your IDFC FIRST Bank personal loan application and use MinEMI's AI-powered tools to improve your approval odds and secure the best interest rates.",
      buttons: [
        {
          text: "Check Application Status Now",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Analyze Your Approval Chances",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your IDFC FIRST Bank Personal Loan Journey with Confidence",
      h2des:
        "IDFC FIRST Bank's personal loan process is now easier to navigate with MinEMI's specialized tools and expert guidance. Track your application status and get personalized recommendations at every step.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Status Tracking",
          description:
            "Monitor your IDFC FIRST Bank loan application status securely with real-time updates at every stage.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Smart Recommendations",
          description:
            "Receive data-driven suggestions to enhance your Kotak Bank loan approval probability and secure better rates.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Access Kotak Bank loan specialists who understand the bank's internal approval criteria and decision-making process.",
        },
      ],
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      heading:
        "Check Your HDFC Bank Personal Loan Status Online - Avoid Rejection & Get Real-Time Updates!",
      paragraph:
        "Easily track your HDFC loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Application Status Now",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Analyze Your Approval Chances",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your HDFC Bank Personal Loan Journey",
      h2des:
        "Checking your HDFC Bank personal loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      heading:
        "Check Your State Bank of India Personal Loan Status Online - Avoid Rejection & Get Real-Time Updates!",
      paragraph:
        "Easily track your SBI loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Loan  Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your State Bank of India Personal Loan Journey",
      h2des:
        "Checking your SBI personal loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      heading:
        "Check Your Axis Bank Personal Loan Status Online - Avoid Rejection & Get Real-Time Updates!",
      paragraph:
        "Easily track your Axis Bank loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Loan  Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your Axis Bank Personal Loan Journey",
      h2des:
        "Checking your Axis Bank personal loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      heading:
        "Check Your ICICI Bank Personal Loan Status Online - Avoid Rejection & Get Real-Time Updates!",
      paragraph:
        "Easily track your ICICI Bank loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Loan  Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your ICICI Bank Personal Loan Journey",
      h2des:
        "Checking your ICICI Bank personal loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      heading:
        "Check Your Punjab National Bank Personal Loan Status Online - Avoid Rejection & Get Real-Time Updates!",
      paragraph:
        "Easily track your Punjab National Bank loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your Punjab National Bank Personal Loan Journey",
      h2des:
        "Checking your Punjab National Bank personal loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      heading:
        "Check Your IndusInd Bank Personal Loan Status Online - Avoid Rejection & Get Real-Time Updates!",
      paragraph:
        "Easily track your IndusInd Bank loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your IndusInd Bank Personal Loan Journey",
      h2des:
        "Checking your IndusInd Bank personal loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      heading:
        "Check Your Tata Capital Personal Loan Status Online - Avoid Rejection & Get Real-Time Updates!",
      paragraph:
        "Easily track your Tata Capital loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your Tata Capital Personal Loan Journey",
      h2des:
        "Checking your Tata Capital personal loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.s",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      heading:
        "Check Your Yes Bank Personal Loan Status Online - Avoid Rejection & Get Real-Time Updates!",
      paragraph:
        "Easily track your Yes Bank loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your Yes Bank Personal Loan Journey",
      h2des:
        "Checking your Yes Bank personal loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      heading:
        "Check Your Union Bank Personal Loan Status Online - Avoid Rejection & Get Real-Time Updates!",
      paragraph:
        "Easily track your Union Bank loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your Union Bank Personal Loan Journey",
      h2des:
        "Checking your Union Bank personal loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      heading:
        "Track Your Bajaj Finance Personal Loan Status - Get Instant Updates & Maximize Approval Chances!",
      paragraph:
        "Monitor your Bajaj Finance loan application status in real-time with MinEMI's intelligent tracking tools. Get personalized insights to improve your approval odds.",

      buttons: [
        {
          text: "Check Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your Bajaj Finance Loan Journey with Confidence",
      h2des:
        "Tracking your Bajaj Finance personal loan status is now seamless and stress-free. MinEMI's smart tools help you stay informed and improve your approval prospects.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      heading:
        "Track Your Federal Bank Personal Loan Status - Get Instant Updates & Maximize Approval Chances!",
      paragraph:
        "Monitor your Federal Bank loan application status in real-time with MinEMI's intelligent tracking tools. Get personalized insights to improve your approval odds.",

      buttons: [
        {
          text: "Check Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your Federal Bank Loan Journey with Confidence",
      h2des:
        "Tracking your Federal Bank personal loan status is now seamless and stress-free. MinEMI's smart tools help you stay informed and improve your approval prospects.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      heading:
        "Track Your Canara Bank Personal Loan Status - Get Instant Updates & Maximize Approval Chances!",
      paragraph:
        "Monitor your Canara Bank loan application status in real-time with MinEMI's intelligent tracking tools. Get personalized insights to improve your approval odds.",

      buttons: [
        {
          text: "Check Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your Canara Bank Loan Journey with Confidence",
      h2des:
        "Tracking your Canara Bank personal loan status is now seamless and stress-free. MinEMI's smart tools help you stay informed and improve your approval prospects.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      heading:
        "Track Your Bank of India Personal Loan Status - Get Instant Updates & Maximize Approval Chances!",
      paragraph:
        "Monitor your Bank of India loan application status in real-time with MinEMI's intelligent tracking tools. Get personalized insights to improve your approval odds.",

      buttons: [
        {
          text: "Check Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your Bank of India Loan Journey with Confidence",
      h2des:
        "Tracking your Bank of India personal loan status is now seamless and stress-free. MinEMI's smart tools help you stay informed and improve your approval prospects.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      heading:
        "Track Your RBL Bank Personal Loan Status - Get Instant Updates & Maximize Approval Chances!",
      paragraph:
        "Monitor your RBL Bank loan application status in real-time with MinEMI's intelligent tracking tools. Get personalized insights to improve your approval odds.",

      buttons: [
        {
          text: "Check Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Navigate Your RBL Bank Loan Journey with Confidence",
      h2des:
        "Tracking your RBL Bank personal loan status is now seamless and stress-free. MinEMI's smart tools help you stay informed and improve your approval prospects.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    if (bankdetails === "checkkotakbankstatus") {
      setData(kotakdata);
      setNotFound(false);
    } else if (bankdetails === "checkbobbankstatus") {
      setData(bobData);
      setNotFound(false);
    } else if (bankdetails === "checkidfcbankstatus") {
      setData(idfcData);
      setNotFound(false);
    } else if (bankdetails === "checkhdfcbankstatus") {
      setData(hdfcData);
      setNotFound(false);
    } else if (bankdetails === "checksbibankstatus") {
      setData(sbiData);
      setNotFound(false);
    } else if (bankdetails === "checkaxisbankstatus") {
      setData(axisData);
      setNotFound(false);
    } else if (bankdetails === "checkicicibankstatus") {
      setData(iciciData);
      setNotFound(false);
    } else if (bankdetails === "checkpunjabbankstatus") {
      setData(punjabData);
      setNotFound(false);
    } else if (bankdetails === "checkinduslndbankstatus") {
      setData(induslndData);
      setNotFound(false);
    } else if (bankdetails === "checktatabankstatus") {
      setData(tataData);
      setNotFound(false);
    } else if (bankdetails === "checkyesbankstatus") {
      setData(yesData);
      setNotFound(false);
    } else if (bankdetails === "checkunionbankstatus") {
      setData(unionData);
      setNotFound(false);
    } else if (bankdetails === "checkbajajbankstatus") {
      setData(bajajData);
      setNotFound(false);
    } else if (bankdetails === "checkfederalbankstatus") {
      setData(federalData);
      setNotFound(false);
    } else if (bankdetails === "checkcanarabankstatus") {
      setData(canaraData);
      setNotFound(false);
    } else if (bankdetails === "checkboibankstatus") {
      setData(boiData);
      setNotFound(false);
    } else if (bankdetails === "checkrblbankstatus") {
      setData(rblData);
      setNotFound(false);
    } else {
      setNotFound(true);
    }
  }, [
    bankdetails,
    axisData,
    bajajData,
    bobData,
    boiData,
    canaraData,
    federalData,
    hdfcData,
    iciciData,
    idfcData,
    induslndData,
    kotakdata,
    punjabData,
    rblData,
    sbiData,
    tataData,
    unionData,
    yesData,
  ]);
  if (notFound) {
    return <Homeloan />;
  }

  if (!data) return null;

  return (
    <>
      <Helmet>
        <title>
          {bankdetails === "checkkotakbankstatus"
            ? "Kotak Mahindra Bank Personal Loan Status - Track Your Loan Status Online"
            : bankdetails === "checkbobbankstatus"
            ? "Bank of Baroda Personal Loan Status - Track Your Loan Status Online"
            : bankdetails === "checkidfcbankstatus"
            ? "IDFC First Bank Personal Loan Status - Track Your Loan Status Online"
            : bankdetails === "checkhdfcbankstatus"
            ? "HDFC Bank Personal Loan Status - Track Your Loan Status Online"
            : bankdetails === "checksbibankstatus"
            ? "State Bank of India Personal Loan Status - Track Your Loan Status Online"
            : bankdetails === "checkaxisbankstatus"
            ? "Axis Bank Personal Loan Status - Track Your Loan Status Online	"
            : bankdetails === "checkicicibankstatus"
            ? "ICICI Bank Personal Loan Status - Track Your Loan Status Online	"
            : bankdetails === "checkpunjabbankstatus"
            ? "Punjab National Bank Personal Loan Status - Track Your Loan Status Online		"
            : bankdetails === "checkinduslndbankstatus"
            ? "Induslnd Bank Personal Loan Status - Track Your Loan Status Online		"
            : bankdetails === "checktatabankstatus"
            ? "Tata Capital Bank Personal Loan Status - Track Your Loan Status Online		"
            : bankdetails === "checkyesbankstatus"
            ? "Yes Bank Personal Loan Status - Track Your Loan Status Online		"
            : bankdetails === "checkunionbankstatus"
            ? "Union Bank Personal Loan Status - Track Your Loan Status Online		"
            : bankdetails === "checkbajajbankstatus"
            ? "Bajaj Finance Personal Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checkfederalbankstatus"
            ? "Federal Bank Personal Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checkcanarabankstatus"
            ? "Canara Bank Personal Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checkboibankstatus"
            ? "Bank of India Personal Loan Status - Track Your Loan Status Online			"
            : bankdetails === "checkrblbankstatus"
            ? "RBL Bank Personal Loan Status - Track Your Loan Status Online			"
            : null}
        </title>
        <meta
          name="description"
          content={
            bankdetails === "checkkotakbankstatus"
              ? "Check your Kotak Mahindra Bank personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkbobbankstatus"
              ? "Check your Bank of Baroda personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate. "
              : bankdetails === "checkidfcbankstatus"
              ? "Check your IDFC First Bank personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate. "
              : bankdetails === "checkhdfcbankstatus"
              ? "Check your HDFC Bank personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checksbibankstatus"
              ? "Check your State Bank of India personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkaxisbankstatus"
              ? "Check your Axis Bank personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkicicibankstatus"
              ? "Check your ICICI Bank personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkpunjabbankstatus"
              ? "Check your Punjab National Bank personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkinduslndbankstatus"
              ? "Check your Induslnd Bank personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checktatabankstatus"
              ? "Check your Tata Capital Bank personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkyesbankstatus"
              ? "Check your Yes Bank personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkunionbankstatus"
              ? "Check your Union Bank personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkbajajbankstatus"
              ? "Check your Bajaj Finance Bank personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkfederalbankstatus"
              ? "Check your Federal Bank personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkcanarabankstatus"
              ? "Check your Canara Bank personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkbobbankstatus"
              ? "Check your Bank of India personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkrblbankstatus"
              ? "Check your RBL Bank personal loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : null
          }
        />
      </Helmet>
      <div
        className=" min-h-screen flex-col "
        style={{ backgroundColor: "#f8f8f8" }}
      >
        {/* Hero Section */}
        <section className="py-4 border-b border-gray-200 bg-[#ebe8fb]">
          <div className="container md:px-6 bg-transparent p-0">
            <div className="space-y-4 md:w-4/5 sd:w-full px-3 mx-auto bg-transparent">
              <h1 className="text-2xl font-bold tracking-tighter sm:text-3xl text-primary-purple bg-transparent ">
                {data.heading}
              </h1>
              <p className="text-dark-grey bg-transparent ">{data.paragraph}</p>
              <div className="flex flex-col gap-3  pt-2 bg-transparent">
                {data.buttons.map((button, index) => (
                  <a
                    key={index}
                    href={button.link}
                    className={button.style}
                    style={button.customStyle || {}}
                  >
                    {button.text}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Loan Predictor Widget */}
        <section
          id="loan-predictor"
          className="py-10  border-b border-gray-200"
        >
          <div className="container  md:px-6 bg-transparent p-0">
            <LoanPredictorWidget name={bankdetails} />
          </div>
        </section>

        {/* Features Section */}
        <section className="py-4 border-b border-gray-200 bg-[#ebe8fb]">
          <div className="container sb:px-4  md:px-6 bg-transparent">
            <div className="mx-auto md:w-4/5 sd:w-full text-center bg-transparent">
              <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple bg-transparent">
                {data.h2}
              </h2>
              <p className="text-dark-grey mb-8 bg-transparent">{data.h2des}</p>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 bg-transparent">
                {data.features.map((feature, index) => (
                  <div
                    key={index}
                    className="bg-white p-3 rounded-lg shadow-sm border border-gray-200"
                  >
                    <div className="h-10 w-10 rounded-full bg-light-bg flex items-center justify-center mx-auto mb-3 bg-[#ebe8fb]">
                      {feature.icon}
                    </div>
                    <h3 className="text-base font-bold text-primary-purple mb-2 bg-transparent">
                      {feature.title}
                    </h3>
                    <p className="text-dark-grey text-sm bg-transparent">
                      {feature.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Loan Tools Sections */}
        <LoanStatusChecker name={bankdetails} />
        <LoanStatusMethods name={bankdetails} />
        <StatusExplanation name={bankdetails} />
        {bankdetails && <KeyApprovalFactors name={bankdetails} />}
        <MinemiTools name={bankdetails} />
        <Testimonials name={bankdetails} />
        {bankdetails && <RelatedContent name={bankdetails} />}
        <Faq name={bankdetails} />
        <TrustIndicators name={bankdetails} />
      </div>
    </>
  );
}

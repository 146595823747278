import React, { useRef, useState } from "react";
import "./OurServices.css";
import { useNavigate } from "react-router-dom";
import { ArrowRight, Check } from "lucide-react";
import { MdArrowDownward, MdOutlineSavings } from "react-icons/md";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { RiGeminiFill, RiSecurePaymentLine } from "react-icons/ri";
import { SiMattermost } from "react-icons/si";
import { BsBookmarkCheckFill } from "react-icons/bs";
const OurServices = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full py-16 px-4" style={{ backgroundColor: "#F6F6F6" }}>
        <div className="max-w-[1200px] mx-auto">
          <div className="text-center mb-16">
            <h1 className="text-[40px] font-bold text-[#1A1A1A] mb-6">
              Discover Your Best Financial Options
            </h1>
            <p className="text-[#4A4A4A] text-xl max-w-[1000px] mx-auto">
              Find the perfect financial solutions tailored to your needs and
              save money on your loans.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Lower Your EMIs the Smart Way */}
            <div className="bg-[#5D2A87] rounded-2xl p-8 flex flex-col min-h-[520px]">
              <h2 className="text-white text-[30px] font-semibold mb-4">
                Lower Your EMIs the Smart Way
              </h2>
              <p className="text-white/90 mb-8">
                Lower EMIs using our smart algorithm based on your credit score.{" "}
              </p>

              <div className="flex-grow space-y-4">
                <div className="bg-white rounded-lg p-3 flex items-center gap-3">
                  <MdArrowDownward className="h-5 w-5 text-[#5D2A87]" />
                  <span className="text-[#5D2A87] font-medium">
                    Reduce Your EMI Burden
                  </span>
                </div>
                <div className="bg-white rounded-lg p-3 flex items-center gap-3">
                  <MdOutlineSavings className="h-5 w-5 text-[#5D2A87]" />
                  <span className="text-[#5D2A87] font-medium">
                    Instant Savings on Interest
                  </span>
                </div>
                <div className="bg-white rounded-lg p-3 flex items-center gap-3">
                  <SiMattermost className="h-5 w-5 text-[#5D2A87]" />
                  <span className="text-[#5D2A87] font-medium">
                    Customizable Loan Terms
                  </span>
                </div>
              </div>

              <button
                className="mt-8 bg-[#3A868B] text-white px-6 py-3 rounded-lg font-semibold inline-flex items-center w-max transform transition-all duration-300 hover:scale-105 hover:bg-[#2E6D72]"
                style={{ gap: 2 }}
                onClick={() => {
                  navigate("/login");
                  window.scrollTo(0, 0);
                }}
              >
                Get Started
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.16666 10H15.8333"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.8333 5L15.8333 10L10.8333 15"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>

            {/* Get Personalized Loan Recommendations */}
            <div className="bg-[#5D2A87] rounded-2xl p-8 flex flex-col min-h-[520px]">
              <h2 className="text-white text-[30px] font-semibold mb-4">
                Get Personalized Loan Recommendations
              </h2>
              <p className="text-white/90 mb-8">
                Find the best loan options for your profile and needs.
              </p>
              <div className="flex-grow space-y-4">
                <div className="bg-white rounded-lg p-3 flex items-center gap-3">
                  <FaMagnifyingGlass className="h-5 w-5 text-[#5D2A87]" />
                  <span className="text-[#5D2A87] font-medium">
                    Best Loan Offers for You
                  </span>
                </div>
                <div className="bg-white rounded-lg p-3 flex items-center gap-3">
                  <BsBookmarkCheckFill className="h-5 w-5 text-[#5D2A87]" />
                  <span className="text-[#5D2A87] font-medium">
                    Improve Your Approval Chances
                  </span>
                </div>
                <div className="bg-white rounded-lg p-3 flex items-center gap-3">
                  <RiSecurePaymentLine className="h-5 w-5 text-[#5D2A87]" />
                  <span className="text-[#5D2A87] font-medium">
                    Optimize Loan Repayments
                  </span>
                </div>
              </div>

              <button
                className="mt-8 bg-[#3A868B] text-white px-6 py-3 rounded-lg font-semibold inline-flex items-center w-max transform transition-all duration-300 hover:scale-105 hover:bg-[#2E6D72]"
                style={{ gap: 2 }}
                onClick={() => {
                  navigate("/loan-recommendation");
                  window.scrollTo(0, 0);
                }}
              >
                Get Recommendations
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.16666 10H15.8333"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.8333 5L15.8333 10L10.8333 15"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>

            {/* Compare Loans and Choose the Best Deal */}
            <div className="bg-[#5D2A87] rounded-2xl p-8 flex flex-col min-h-[520px]">
              <h2 className="text-white text-[30px] font-semibold mb-4">
                Simplify, Consolidate & Save on Loans
              </h2>
              <p className="text-white/90 mb-8">
                Combine all loans into one EMI with lower interest."
              </p>
              <div className="flex-grow space-y-4">
                <div className="bg-white rounded-lg p-3 flex items-center gap-3">
                  <RiGeminiFill className="h-5 w-5 text-[#5D2A87]" />
                  <span className="text-[#5D2A87] font-medium">
                    Manage All Loans in One EMI
                  </span>
                </div>
                <div className="bg-white rounded-lg p-3 flex items-center gap-3">
                  <MdOutlineSavings className="h-5 w-5 text-[#5D2A87]" />
                  <span className="text-[#5D2A87] font-medium">
                    Maximize Your Savings
                  </span>
                </div>
                <div className="bg-white rounded-lg p-3 flex items-center gap-3">
                  <RiSecurePaymentLine className="h-5 w-5 text-[#5D2A87]" />
                  <span className="text-[#5D2A87] font-medium">
                    Get Flexible Repayment Options
                  </span>
                </div>
              </div>

              <button
                className="mt-8 bg-[#3A868B] text-white px-6 py-3 rounded-lg font-semibold inline-flex items-center w-max transform transition-all duration-300 hover:scale-105 hover:bg-[#2E6D72]"
                style={{ gap: 2 }}
                onClick={() => {
                  navigate("/login");
                  window.scrollTo(0, 0);
                }}
              >
                Consolidate Now
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.16666 10H15.8333"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.8333 5L15.8333 10L10.8333 15"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* Sub-Boxes for Comparison Section */}

          <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="text-center mb-8 col-span-full">
              <h1 className="text-[40px] font-bold text-[#1A1A1A] mb-6">
                Compare Loans and Choose the Best Deal
              </h1>
              <p className="text-[#4A4A4A] text-xl max-w-[1000px] mx-auto">
                Easily compare loan options and choose the best deal that fits
                your needs and budget!{" "}
              </p>
            </div>

            {/* Personal Loan Comparison */}
            <div className="bg-white border-2 border-[#5D2A87]/20 rounded-2xl p-6 flex flex-col items-center text-center">
              <div className="w-16 h-16 rounded-full bg-[#5D2A87]/10 flex items-center justify-center mb-4">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 8V7.2C18 6.0799 18 5.51984 17.782 5.09202C17.5903 4.71569 17.2843 4.40973 16.908 4.21799C16.4802 4 15.9201 4 14.8 4H9.2C8.07989 4 7.51984 4 7.09202 4.21799C6.71569 4.40973 6.40973 4.71569 6.21799 5.09202C6 5.51984 6 6.0799 6 7.2V8M6 8H18M6 8H4M18 8H20M9 13V17M15 13V17M3 8H21V18.8C21 19.9201 21 20.4802 20.782 20.908C20.5903 21.2843 20.2843 21.5903 19.908 21.782C19.4802 22 18.9201 22 17.8 22H6.2C5.0799 22 4.51984 22 4.09202 21.782C3.71569 21.5903 3.40973 21.2843 3.21799 20.908C3 20.4802 3 19.9201 3 18.8V8Z"
                    stroke="#5D2A87"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-3 text-[#5D2A87]">
                Personal Loan Comparison
              </h3>
              <p className="text-gray-600 mb-6">
                Compare personal loan offers and find the lowest EMIs.
              </p>
              <button
  className="mt-auto py-3 px-6 rounded-full font-medium flex items-center justify-center transition-all duration-300 w-full max-w-xs 
  bg-[#5D2A87] text-white hover:scale-105 hover:bg-[#4A206E]"
  onClick={() => navigate("/best-personal-loan-offer")}
  aria-label="Learn more about home loan comparison"
>
  Learn More
  <ArrowRight className="ml-2 transition-transform duration-300 hover:translate-x-1" />
</button>
            </div>

            {/* Home Loan Comparison */}
            <div className="bg-white border-2 border-[#5D2A87]/20 rounded-2xl p-6 flex flex-col items-center text-center">
              <div className="w-16 h-16 rounded-full bg-[#5D2A87]/10 flex items-center justify-center mb-4">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 9.5L12 4L21 9.5M19 13V19.4C19 19.9601 19 20.2401 18.891 20.454C18.7951 20.6422 18.6422 20.7951 18.454 20.891C18.2401 21 17.9601 21 17.4 21H6.6C6.03995 21 5.75992 21 5.54601 20.891C5.35785 20.7951 5.20487 20.6422 5.10899 20.454C5 20.2401 5 19.9601 5 19.4V13M9.5 21V17C9.5 16.4477 9.94772 16 10.5 16H13.5C14.0523 16 14.5 16.4477 14.5 17V21"
                    stroke="#5D2A87"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-3 text-[#5D2A87]">
                Home Loan Comparison
              </h3>
              <p className="text-gray-600 mb-6">
                Discover the best home loan rates and save on interest.
              </p>
              <button
  className="mt-auto py-3 px-6 rounded-full font-medium flex items-center justify-center transition-all duration-300 w-full max-w-xs 
  bg-[#5D2A87] text-white hover:scale-105 hover:bg-[#4A206E]"
  onClick={() => navigate("/best-home-loan-offer")}
  aria-label="Learn more about home loan comparison"
>
  Learn More
  <ArrowRight className="ml-2 transition-transform duration-300 hover:translate-x-1" />
</button>
            </div>

            {/* Car Loan Comparison */}
            <div className="bg-white border-2 border-[#5D2A87]/20 rounded-2xl p-6 flex flex-col items-center text-center">
              <div className="w-16 h-16 rounded-full bg-[#5D2A87]/10 flex items-center justify-center mb-4">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 17H5M19 17C19.5523 17 20 16.5523 20 16V13.5C20 12.9477 19.5523 12.5 19 12.5M19 17C18.4477 17 18 16.5523 18 16V13.5C18 12.9477 18.4477 12.5 19 12.5M5 17C4.44772 17 4 16.5523 4 16V13.5C4 12.9477 4.44772 12.5 5 12.5M5 17C5.55228 17 6 16.5523 6 16V13.5C6 12.9477 5.55228 12.5 5 12.5M5 12.5H19M15 8.5H9L7.5 12.5H16.5L15 8.5Z"
                    stroke="#5D2A87"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-3 text-[#5D2A87]">
                Car Loan Comparison
              </h3>
              <p className="text-gray-600 mb-6">
                Find the perfect car loan with low EMIs and flexible terms.
              </p>
              <button
  className="mt-auto py-3 px-6 rounded-full font-medium flex items-center justify-center transition-all duration-300 w-full max-w-xs 
  bg-[#5D2A87] text-white hover:scale-105 hover:bg-[#4A206E]"
  onClick={() => navigate("/best-car-loan-offer")}
  aria-label="Learn more about home loan comparison"
>
  Learn More
  <ArrowRight className="ml-2 transition-transform duration-300 hover:translate-x-1" />
</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurServices;

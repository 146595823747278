import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

const styles = {
  section: {
    backgroundColor: "white",
    padding: "24px 12px",
  },
  container: {
    maxWidth: "1024px",
    margin: "0 auto",
   backgroundColor:'transparent'
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#311664",
    textAlign: "center",
    marginBottom: "24px",
    backgroundColor:'transparent'

  },
  card: {
    backgroundColor: "#ebe8fc",
    borderRadius: "8px",
    padding: "24px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  cardTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#311664",
    marginBottom: "16px",
    backgroundColor:'transparent'

  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: "24px",
    backgroundColor:'transparent'

  },
  inputGroup: {
    marginBottom: "16px",
    backgroundColor:'transparent'

  },
  label: {
    display: "block",
    fontSize: "14px",
    fontWeight: "500",
    color: "#311664",
    marginBottom: "8px",
    backgroundColor:'transparent'

  },
  input: {
    width: "100%",
    padding: "8px",
    border: "1px solid #311664",
    borderRadius: "4px",
    marginBottom: "8px",
    backgroundColor:'transparent'

  },
  slider: {
    width: "100%",
    backgroundColor:'transparent'

  },
  resultBox: {
    backgroundColor: "white",
    padding: "16px",
    borderRadius: "8px",
    marginBottom: "16px",
    width:'95%'
    
  },
  resultLabel: {
    fontSize: "14px",
    color: "#666",
    marginBottom: "4px",
    backgroundColor:'transparent'

  },
  resultValue: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#3a868b",
    backgroundColor:'transparent'

  },
  button: {
    backgroundColor: "#3a868b",
    color: "white",
    border: "none",
    borderRadius: "9999px",
    padding: "12px 24px",
    fontSize: "18px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    display: "block",
    margin: "24px auto 0",
    width: "fit-content",
  },
  mainbox:{
    backgroundColor:'transparent',
  }
}

export default function EMICalculator() {
  const [loanAmount, setLoanAmount] = useState(1000000)
  const [interestRate, setInterestRate] = useState(10)
  const [loanTenure, setLoanTenure] = useState(60)
  const [emi, setEmi] = useState(0)
  const [totalInterest, setTotalInterest] = useState(0)
  const [totalPayment, setTotalPayment] = useState(0)
  const navigate=useNavigate()

  useEffect(() => {
    calculateEMI()
  }, [loanAmount, interestRate, loanTenure]) //This line was already correct.  No change needed.

  const calculateEMI = () => {
    const principal = loanAmount
    const rate = interestRate / 12 / 100
    const time = loanTenure

    const emi = principal * rate * (Math.pow(1 + rate, time) / (Math.pow(1 + rate, time) - 1))
    const totalAmount = emi * time
    const totalInterestPayable = totalAmount - principal

    setEmi(Math.round(emi))
    setTotalInterest(Math.round(totalInterestPayable))
    setTotalPayment(Math.round(totalAmount))
  }

  const formatCurrency = (value) => {
    return value.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    })
  }

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <h2 style={styles.heading}>Personal Loan EMI Calculator</h2>
        <div style={styles.card}>
          <h3 style={styles.cardTitle}>Calculate Your EMI</h3>
          <div style={styles.grid}>
            <div style={styles.mainbox}>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Loan Amount (₹) </label>
                <input
                  type="number"
                  value={loanAmount}
                  onChange={(e) => setLoanAmount(Number(e.target.value))}
                  style={styles.input}
                />
                <input
                  type="range"
                  min="10000"
                  max="5000000"
                  step="10000"
                  value={loanAmount}
                  onChange={(e) => setLoanAmount(Number(e.target.value))}
                  style={styles.slider}
                />
              </div>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Interest Rate (%)</label>
                <input
                  type="number"
                  value={interestRate}
                  onChange={(e) => setInterestRate(Number(e.target.value))}
                  style={styles.input}
                />
                <input
                  type="range"
                  min="5"
                  max="30"
                  step="0.1"
                  value={interestRate}
                  onChange={(e) => setInterestRate(Number(e.target.value))}
                  style={styles.slider}
                />
              </div>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Loan Tenure (Months)</label>
                <input
                  type="number"
                  value={loanTenure}
                  onChange={(e) => setLoanTenure(Number(e.target.value))}
                  style={styles.input}
                />
                <input
                  type="range"
                  min="6"
                  max="120"
                  step="1"
                  value={loanTenure}
                  onChange={(e) => setLoanTenure(Number(e.target.value))}
                  style={styles.slider}
                />
              </div>
            </div>
            <div style={{backgroundColor:'transparent'}}> 
              <h4 style={styles.cardTitle}>EMI Breakdown</h4>
              <div style={styles.resultBox}>
                <p style={styles.resultLabel}>Monthly EMI</p>
                <p style={styles.resultValue}>{formatCurrency(emi)}</p>
              </div>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "16px",backgroundColor:'transparent',width:'97%' }}>
                <div style={styles.resultBox}>
                  <p style={styles.resultLabel}>Total Interest</p>
                  <p style={styles.resultValue}>{formatCurrency(totalInterest)}</p>
                </div>
                <div style={styles.resultBox}>
                  <p style={styles.resultLabel}>Total Payment</p>
                  <p style={styles.resultValue}>{formatCurrency(totalPayment)}</p>
                </div>
              </div>
              <div style={styles.resultBox}>
                <p style={styles.resultLabel}>Loan Breakdown</p>
                <p style={{ ...styles.resultValue, fontSize: "16px" }}>Principal: {formatCurrency(loanAmount)}</p>
                <p style={{ ...styles.resultValue, fontSize: "16px" }}>
                  Total Interest: {formatCurrency(totalInterest)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "24px" ,backgroundColor:'transparent'}}>
          <p style={{ fontSize: "18px", color: "#311664", marginBottom: "16px" ,backgroundColor:'transparent',textAlign:'center'}}>
            Found Your Ideal EMI? Compare Personal Loans to Get the Best Deal!
          </p>
          <button
          onClick={()=>navigate("/personalloan/applyonline")}
            style={styles.button}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#59266d")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#3a868b")}
          >
            Compare Personal Loan Offers
          </button>
        </div>
      </div>
    </section>
  )
}


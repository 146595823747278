
import  React from "react"
import { useState } from "react"
import { Star } from "lucide-react"
import { Card, CardContent } from "@mui/material"



export default function TestimonialSection() {
  const testimonials = [
    {
      name: "Rajat Mehta",
      city: "Mumbai",
      quote:
        "I was unsure if my credit score was good enough for a home loan. Minemi's analysis helped me understand my eligibility, and I got approved on my first attempt!",
      loanType: "Home Loan",
      impact: "Approved on first attempt",
      rating: 5,
      imgSrc: "/placeholder.svg?height=80&width=80",
    },
    {
      name: "Sanya Verma",
      city: "Bangalore",
      quote:
        "Minemi showed me why my personal loan application might get rejected and how to fix it. I took their advice and got approved without affecting my credit score!",
      loanType: "Personal Loan",
      impact: "Fixed issues before applying",
      rating: 5,
      imgSrc: "/placeholder.svg?height=80&width=80",
    },
    {
      name: "Nikhil Sharma",
      city: "Delhi",
      quote:
        "I didn't know multiple loan inquiries could harm my credit score. Minemi helped me check my eligibility first, and I secured a business loan without any hassle!",
      loanType: "Business Loan",
      impact: "Avoided multiple inquiries",
      rating: 4,
      imgSrc: "/placeholder.svg?height=80&width=80",
    },
    {
      name: "Pooja Reddy",
      city: "Hyderabad",
      quote:
        "I was worried about my existing car loan affecting my chances for a personal loan. Minemi's detailed insights made it clear how much I could actually borrow.",
      loanType: "Personal Loan",
      impact: "Understood borrowing capacity",
      rating: 5,
      imgSrc: "/placeholder.svg?height=80&width=80",
    },
    {
      name: "Arjun Patel",
      city: "Ahmedabad",
      quote:
        "Banks were rejecting my loan applications, and I didn't know why. Minemi broke down my approval chances and guided me to the right lender!",
      loanType: "Home Loan",
      impact: "Found the right lender",
      rating: 5,
      imgSrc: "/placeholder.svg?height=80&width=80",
    },
  ]

  const [activeIndex, setActiveIndex] = useState(0)
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)

  const handlePrev = () => {
    setActiveIndex((prev) => (prev === 0 ? testimonials.length - 1 : prev - 1))
  }

  const handleNext = () => {
    setActiveIndex((prev) => (prev === testimonials.length - 1 ? 0 : prev + 1))
  }

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX)
  }

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 50) {
      // Swipe left
      handleNext()
    }

    if (touchStart - touchEnd < -50) {
      // Swipe right
      handlePrev()
    }
  }

  const renderStars = (rating) => {
    return Array(5)
      .fill(0)
      .map((_, i) => (
        <Star key={i} className={`h-4 w-4 ${i < rating ? "text-yellow-400 fill-yellow-400" : "text-gray-300"}`} />
      ))
  }

  return (
    <section className="w-full py-8 md:py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8 md:mb-12">
          <h2 className="text-2xl md:text-4xl font-bold text-[#311664] mb-2 md:mb-4">
            What Our Users Say About Minemi
          </h2>
          <p className="text-base md:text-lg text-gray-600 max-w-2xl mx-auto">
            Real stories from real people who checked their loan approval chances with Minemi before applying.
          </p>
        </div>

        {/* Mobile Carousel */}
        <div
          className="md:hidden"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <Card className="border-none shadow-lg">
            <CardContent className="p-6">
              <div className="flex flex-col items-center text-center">
                <div className="mb-4 relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-[#311664] to-[#59266d] rounded-full opacity-10"></div>
                  <img
                    src='https://img.freepik.com/premium-vector/default-avatar-profile-icon-social-media-user-image-gray-avatar-icon-blank-profile-silhouette-vector-illustration_561158-3383.jpg?semt=ais_hybrid'
                    alt={testimonials[activeIndex].name}
                    width={80}
                    height={80}
                    className="rounded-full border-2 border-[#311664]"
                  />
                </div>
                <div className="flex mb-3">{renderStars(testimonials[activeIndex].rating)}</div>
                <p className="text-gray-700 italic mb-4">"{testimonials[activeIndex].quote}"</p>
                <div className="font-bold text-[#311664]">
                  {testimonials[activeIndex].name}, {testimonials[activeIndex].city}
                </div>
                <div className="mt-2 flex flex-col items-center">
                  <span className="bg-[#ebe8fc] text-[#311664] text-xs font-medium px-2.5 py-0.5 rounded-full mb-1">
                    {testimonials[activeIndex].loanType}
                  </span>
                  <span className="bg-[#3a868b]/10 text-[#3a868b] text-xs font-medium px-2.5 py-0.5 rounded-full">
                    {testimonials[activeIndex].impact}
                  </span>
                </div>
              </div>
            </CardContent>
          </Card>

          <div className="flex justify-center mt-4 " style={{gap:1}}>
            {testimonials.map((_, index) => (
              <button
                key={index}
                className={`h-2 rounded-full transition-all ${
                  activeIndex === index ? "w-6 bg-[#311664]" : "w-2 bg-[#311664]/30"
                }`}
                onClick={() => setActiveIndex(index)}
                aria-label={`Go to testimonial ${index + 1}`}
              />
            ))}
          </div>
        </div>

        {/* Desktop Grid */}
        <div className="hidden md:block">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {testimonials.map((testimonial, index) => (
              <Card key={index} className="border-none shadow-lg hover:shadow-xl transition-shadow">
                <CardContent className="p-6">
                  <div className="flex items-start mb-4">
                    <div className="relative mr-4">
                      <div className="absolute inset-0 bg-gradient-to-r from-[#311664] to-[#59266d] rounded-full opacity-10"></div>
                      <img
                        src='https://img.freepik.com/premium-vector/default-avatar-profile-icon-social-media-user-image-gray-avatar-icon-blank-profile-silhouette-vector-illustration_561158-3383.jpg?semt=ais_hybrid'
                        alt={testimonial.name}
                        width={60}
                        height={60}
                        className="rounded-full border-2 border-[#311664]"
                      />
                    </div>
                    <div>
                      <div className="font-bold text-[#311664]" style={{fontSize:18}}>
                        {testimonial.name}, <span className="font-normal">{testimonial.city}</span>
                      </div>
                      <div className="flex mt-1">{renderStars(testimonial.rating)}</div>
                    </div>
                  </div>
                  <p className="text-gray-700 italic mb-4">"{testimonial.quote}"</p>
                  <div className="flex flex-wrap "   style={{gap:2}}>
                    <span className="bg-[#ebe8fc] text-[#311664] text-xs font-medium px-2.5 py-0.5 rounded-full">
                      {testimonial.loanType}
                    </span>
                    <span className="bg-[#3a868b]/10 text-[#3a868b] text-xs font-medium px-2.5 py-0.5 rounded-full">
                      {testimonial.impact}
                    </span>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}


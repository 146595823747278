import React, { useEffect, useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import lowerEmiIllustration from "../../Assets/Images/LowerEmiIllustration.webp";
import ConsolidateIllustration from "../../Assets/Images/ConsolidateIllustration.webp";
import TopUPIllustration from "../../Assets/Images/TopUPIllustration.1.webp";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import chatbox from "../../Assets/Images/chatbot (1).webp";
import experian from "../../Assets/Images/experian.webp";
import "./HomeAfterLogin.css";
import { CgChevronDoubleRightR } from "react-icons/cg";
function HomeAfterLogin() {
  const navigate = useNavigate();
  const [saveupto, setSaveUpto] = useState("");
  const [cbil, setCbil] = useState("");
  const [cbilbody, setCbilbody] = useState("");
  const [runningLoan, setRunningLoan] = useState("");
  const [LoanDetail, setLoanDetail] = useState([]);
  const [total, setTotal] = useState("");
  const [score, setScore] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chat, setChat] = useState("");
  const [username, setUsername] = useState("");
  const [formShow, setFormShow] = useState(false);
  const [reportDate, setReportDate] = useState("");
  const[id,setId]=useState("")

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const isMobileDevice =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    if (!token && !isMobileDevice) {
      navigate("/");
    }
  }, [navigate]);

  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-IN").format(number);
  };
  const CreditSummary = async () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/credit_summary/${id}`,
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const response = await axios.request(options);
      const data = response.data.data;
      setChat(data.chat_token);
      setLoanDetail(data.loanDetail);
      setSaveUpto(data.save_upto);
      setCbil(data.cbil.MsgTitle);
      setCbilbody(data.cbil.MsgBody);
      setRunningLoan(data.loanSummary.AllowedRunningLoan);
      setTotal(data.loanSummary.AllLoanCurrentBalance);
      setScore(parseInt(data.cbil.score, 10));
    } catch (error) {
      console.error(error.response?.status);
      if (error.response?.status === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };

  const moveToSave = () => {
    navigate("/confirmloan", { state: { LoanDetail } });
  };

  useEffect(() => {
    CreditSummary();
  }, []);
  const moveToNewpage = () => {
    navigate("/confirmloan", { state: { LoanDetail: LoanDetail } });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const username = localStorage.getItem("name");
    setUsername(username);
    if (token == null) {
      navigate("/");
    } else {
    }
  }, []);
  const getRating = (score) => {
    if (score >= 720) {
      return "EXCELLENT";
    } else if (score >= 640) {
      return "GOOD";
    } else {
      return "BAD";
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    const currentDate = new Date();
    // Manually format the date as MM/DD/YYYY
    const formattedDate =
      `${currentDate.getDate().toString().padStart(2, "0")}/` +
      `${(currentDate.getMonth() + 1).toString().padStart(2, "0")}/` +
      `${currentDate.getFullYear()}`;
    setReportDate(formattedDate);
  }, []);
  useEffect(() => {
    const id =localStorage.getItem("id")
setId(id)
  }, [])
  
  return (
    <>
      {cbil == "" ? (
        <Skeleton count={8} style={{ height: 40, marginTop: 20 }} /> // Five-line loading skeleton
      ) : (
        <>
        <div style={{backgroundColor:'#F6F6F6'}}>

          <div
            className=" mobile-dashboard-img "
            style={{
              background:
                "linear-gradient(180deg, rgba(132, 108, 166, 0.2) 0%, rgba(132, 108, 166, 0) 54.44%)",
            }}
          >
            <div
              className=" mobile-dashboard-img max-w-2xl mx-auto px-2 py-4"
              style={{ backgroundColor: "transparent" }}
            >
              {/* Header */}
              <div
                style={{ backgroundColor: "transparent",paddingLeft:'2%' ,display:'flex',flexDirection:'row',gap:4}}
              >
                <p
                  style={{
                    backgroundColor: "transparent",
                    alignSelf:'center',
                    fontSize:14,
                    color:'black',fontWeight:500

                  }}
                >
                  Awesome! here is your
                </p>
                <h1
                  className="text-[#59266D]"
                  style={{
                    backgroundColor: "transparent",
                  marginBottom:0,
                  lineHeight:1,
                    fontSize:21,  fontWeight:600,
                  }}
                >
                  Credit Summary
                </h1>
              </div>

              {/* Ongoing Loans Card */}
              <div className="bg-[#59266D] rounded-lg mt-2 mb-3 ">
                <h2
                  className="text-white mb-2 pl-4 pr-4 "
                  style={{
                    backgroundColor: "transparent",
                    borderBottom: "1px solid grey",
                    fontSize:16,
                    paddingTop:'2%',
                    paddingBottom:'2%',
                  }}
                >
                  Ongoing Loans
                </h2>
                <div
                  className="flex justify-between items-center pr-4"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div
                    className="pl-4  pb-1"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <p
                      className="text-gray-300  mb-1"
                      style={{ backgroundColor: "transparent",fontSize:13,fontWeight:400, }}
                    >
                      Outstanding amount :
                    </p>
                    <p
                      className="text-white text-xl font-semibold"
                      style={{ backgroundColor: "transparent" ,fontSize:16,fontWeight:700, }}
                    >
                      {" "}
                      ₹{formatNumberWithCommas(total)}
                    </p>
                  </div>
                  <div
                    className="pl-4  pb-1"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <p
                      className="text-gray-300 text-sm mb-1"
                      style={{ backgroundColor: "transparent",fontSize:13,fontWeight:400, }}
                    >
                      {" "}
                      Number of Running Loans :
                    </p>
                    <p
                      className="text-white text-xl font-semibold"
                      style={{
                        backgroundColor: "transparent",
                        textAlign: "end",
                        fontSize:16,fontWeight:700, 
                      }}
                    >
                      {" "}
                      {formatNumberWithCommas(runningLoan)}
                    </p>
                  </div>
                </div>
              </div>

              {/* Credit Score Meter */}
              <div
                className="bg-white rounded-lg p-2 mb-2 text-center mt-4"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap:16
                }}
              >
                <div
                  style={{ backgroundColor: "transparent" }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignSelf: "center",
                      backgroundColor: "white",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <ReactSpeedometer
                      style={{
                        backgroundColor: "white",
                        fontSize: 12,
                        marginRight: "2%",
                      }}
                      value={Number(score)}
                      maxValue={900}
                      minValue={0}
                      customSegmentStops={[0, 500, 700, 800, 900]}
                      segmentStopsFontSize="60px"
                      width={180}
                      height={115}
                    
                      needleColor="black"
                      backgroundColor="white"
                      color="white"
                      segmentColors={[
                        "rgba(255, 88, 88, 1)",
                        "rgba(255, 159, 159, 1)",
                        "rgba(255, 199, 2, 1)",
                        "rgba(4, 218, 0, 1)",
                        "rgba(2, 90, 0, 1)",
                      ]}
                      textColor="#8A8A8A"
                      ringWidth={30}
                      needleHeightRatio={0.7}
                      currentValueText={`${score}`}
                    />
                  </div>
                </div>

                <div
                  style={{
                    backgroundColor: "transparent",
                    alignContent: "center",
                  }}
                >
                  <p
                    className="text-gray-600 text-sm"
                    style={{
                      backgroundColor: "transparent",
                      textAlign: "center",
                      color:'#5A3D7D'
                    }}
                  >
                    Your Credit score is 
                  </p>
                  <p
                    className="text-[#5A3D7D] text-4xl font-bold"
                    style={{
                      backgroundColor: "transparent",
                      textAlign: "center",
                      fontWeight: 800,
                      fontSize: 34,
                      fontFamily: "Archivo",
                      marginTop:0
                    }}
                  >
                    {" "}
                    {score}
                  </p>
                </div>
              </div>
              <a
                href={chat}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  position: "fixed",
                  top: "75%",
                  right: 0,
                  transform: "translateY(-50%)",
                  backgroundColor: "#59266D",
                  color: "white",
                  padding: "8px 12px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textDecoration: "none",
                  borderRadius: "8px 0 0 8px",
                  boxShadow: "-2px 2px 4px rgba(0,0,0,0.2)",
                  zIndex: 1000,
                  transition: "all 0.3s ease",
                  writingMode: "vertical-rl",
                  textOrientation: "mixed",
                  letterSpacing: "1px",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#59266D";
                  e.target.style.right = "5px";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#59266D";
                  e.target.style.right = "0";
                }}
              >
                <div
                  style={{ width: 25, height: 25, backgroundColor: "#59266D" }}
                >
                  <img
                    src={chatbox}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#59266D",
                      objectFit:'cover'
                    }}
                  />
                </div>
              </a>

              <div className="mb-2" style={{ backgroundColor: "transparent" }}>
                <h3
                  className="text-[#5A3D7D] text-xl mb-2 mt-2"
                  style={{
                    backgroundColor: "transparent",
                    textAlign: "center",
                    fontWeight:600,
                    fontSize:21,


                  }}
                >
                  {" "}
                  {cbil}
                </h3>

                <p
                  className="text-gray-600 px-4"
                  style={{
                    backgroundColor: "transparent",
                    textAlign: "center",
                    fontWeight:400,
                    fontSize:12,
                  }}
                >
                  {cbilbody}
                </p>
                <div
                  className="desktop-dashboard d-flex justify-content-center cmp-logo-con "
                  style={{
                    backgroundColor: "transparent",
                    width: "10rem",
                    height: "4rem",
                    margin: "auto",
                  }}
                >
                  <img
                    src={experian}
                    className="cmp-logo"
                    style={{
                      backgroundColor: "transparent",
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <p className="mt-2"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500px",
                    lineHeight: "20px",
                    letterSpacing: "0em",
                    textAlign: "center",
                    color: "#1F1F1F",
                 
                    marginLeft: "3%",
                    marginRight: "3%",
                  }}
                >
                  Report a issue with the result{" "}
                  <Link
                    to="https://consumer.experian.in/ECV-OLN/view/angular/#/"
                    style={{ color: "#59266D", fontWeight: "700px" }}
                  >
                    Here
                  </Link>
                </p>
              </div>

              <div
                className="text-[#59266D] mb-2 mt-6"
                style={{ backgroundColor: "transparent", textAlign: "center",fontWeight:700,fontSize:22}}
              >
                You can save up to ₹{formatNumberWithCommas(saveupto)}
              </div>
              <button
                onClick={moveToNewpage}
                style={{width:'100%' }}
                className={`mx-auto py-3 rounded-lg text-white font-semibold bg-[#59266D] hover:bg-[purple]  transition-colors block mt-1`}
              >
                Check How
              </button>
            </div>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                gap: 4,
                justifyContent: "center",
              }}
            >
              <button
                onClick={openModal}
                style={{
                  border: "none",
                  color: "rgb(89, 38, 109)",
                  fontWeight: "600",
                  outline: "none",
                  marginBottom: "30px",
                  cursor: "pointer",
                  width: "fit-content",
                }}
              >
                Change Your Goal
              </button>
              <CgChevronDoubleRightR
                size={20}
                onClick={openModal}
                style={{
                  backgroundColor: "transparent",
                  color: "rgb(89, 38, 109)",
                }}
              />
            </div>
          </div>
          </div>
          <div className="desktop-dashboard add-bg-color"  style={{backgroundColor:'#E7E4F9'}}>
            <div id="eligibility"  style={{backgroundColor:'#E7E4F9'}}>
              {/* <div className="titleCon">
          <p className="title m-0 p-0">Personal Loan Eligibility Report</p>
        </div> */}

              <div className="contentCon " >
                <div
                  className="row d-flex justify-content-between m-0 p-0"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div
                    className={
                      formShow
                        ? "col-12 col-md-12 mb-5 mb-md-0 p-0"
                        : "col-12 col-md-7 col-lg-8 mb-5 mb-md-0 p-0"
                    }
                    style={{ backgroundColor: "transparent" }}
                  >
                    <p
                      className="cus-name p-0 m-0 text-capitalize"
                      style={{ backgroundColor: "transparent" }}
                    >
                      Hii👋 {username}
                    </p>
                    {/* <p className="credit-title p-0 m-0" style={{backgroundColor:'transparent',lineHeight:1.4}}>
                Thankyou for using <b style={{backgroundColor:'transparent',lineHeight:1.4}}>MinEMI CreditPro!</b>
              </p> */}
                    <p
                      className="credit-desc p-0 "
                      style={{
                        backgroundColor: "transparent",
                        lineHeight: 1.1,
                        fontSize: 20,
                        marginTop: "0.7%",
                      }}
                    >
                      <b  style={{
                        backgroundColor: "transparent",}}>{id==1 ?"let’s Lower your EMI's":id==2?"let’s Consolidate your EMI's":"let’s Top-up your EMI's"}</b>
                    </p>
                    <div
                      className="credit-score-con"
                      style={{ backgroundColor: "transparent",marginTop:'2%' ,paddingLeft:0,paddingRight:0}}
                    >
                      <p
                        className="awesome-text"
                        style={{ backgroundColor: "transparent" ,color:'black'}}
                      >
                        Here is your{" "}
                      </p>
                      <p
                        className="loan-sum-text"
                        style={{ backgroundColor: "transparent" }}
                      >
                        Credit Summary
                      </p>
                      <div
                        className="loan-sum-con"
                        style={{
                          backgroundColor: "#cec5e0",
                          border: "2px solid #553285",
                          marginTop: "2%",
                        }}
                      >
                        <div
                          className="row p-0 m-0"
                          style={{
                            backgroundColor: "transparent",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            className=" col-12 col-lg-6 mb-0 mb-lg-3 p-0 d-flex justify-content-between"
                            style={{ backgroundColor: "transparent",flexDirection:'column' }}
                          >
                            <p
                              className="loan-text"
                              style={{
                                backgroundColor: "transparent",
                                color: "#553285",
                              }}
                            >
                              Total no. of running loans :
                            </p>
                            <p
                              className="loan-value"
                              style={{
                                backgroundColor: "transparent",
                                color: "#553285",
                              }}
                            >
                              {runningLoan}
                            </p>
                          </div>
                          <div
                            className="col-12 col-lg-6 mb-0 mb-lg-3 p-0 d-flex justify-content-between"
                            style={{ backgroundColor: "transparent",flexDirection:'column' }}
                          >
                            <p
                              className="loan-text"
                              style={{
                                backgroundColor: "transparent",
                                color: "#553285",
                              }}
                            >
                              Total outstanding amount :
                            </p>
                            <p
                              className="loan-value"
                              style={{
                                backgroundColor: "transparent",
                                color: "#553285",
                              }}
                            >
                              ₹{formatNumberWithCommas(total)}
                            </p>
                          </div>
                        </div>
                      </div>
                   

                      <div
                        className="w-full"
                        style={{
                          backgroundColor: "transparent",
                          marginTop: 40,
                        }}
                      >
                        <div style={{margin: "auto", display:'flex',flexDirection:'row',justifyContent:'center',backgroundColor:'transparent'}}>
                           <p
                        className="credit-desc p-0 "
                        style={{
                          backgroundColor: "transparent",
                          lineHeight: 1.1,
                          fontSize: 24,
                          marginTop: "1.5%",
                          textAlign:'center',
                          border:'2px dashed  #553285',
                         width: "27rem",
                         borderRadius:4
                         

                        }}
                      >
                        You can save upto to{" "}
                        <b
                          style={{
                            backgroundColor: "transparent",
                            fontSize: 34,
                            marginTop: "2%",
                            lineHeight: 1.3,
                             textAlign:'center'
                          }}
                        >
                          ₹{formatNumberWithCommas(saveupto)}
                        </b>
                      </p>
                        </div>
                        <button
                          onClick={moveToNewpage}
                          className={`py-3 rounded-lg text-white font-semibold bg-[#59266D] transition-colors  hover:bg-[#573982] block mt-3 `}
                          style={{ width: "27rem", margin: "auto" }}
                        >
                          Check How
                        </button>

                        <div
                          className="w-full mt-2"
                          style={{
                            backgroundColor: "transparent",
                            textAlign: "center",
                            fontFamily: "Archivo",
                            fontWeight: 500,
                            fontSize: 14,
                            fontWeight: 400,
                          }}
                        >
                          Confirm your loan to see your exact EMI savings
                        </div>
                      </div>
                      <div
                        style={{
                          backgroundColor: "transparent",
                          display: "flex",
                          flexDirection: "row",
                          gap: 4,
                          justifyContent: "center",
                        }}
                        className={`w-full rounded-lg text-[#59266D] py-2  font-semibold  transition-colors block mt-2`}
                      >
                        <button onClick={openModal}>Change Your Goal</button>
                        <CgChevronDoubleRightR
                          size={20}
                          onClick={openModal}
                          style={{ backgroundColor: "transparent" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-12 col-md-4 col-lg-3 p-0"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div
                      id={
                        formShow ? "speedometer-con-manage" : "speedometer-con"
                      }
                      style={{ backgroundColor: "#FAF6FF" }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#FAF6FF",
                        }}
                      >
                        <ReactSpeedometer
                          fluidWidth={true}
                          value={score}
                          maxValue={900}
                          minValue={0}
                          textColor="#59266D"
                          needleColor="#431d76"
                          segmentColors={[
                            "rgb(132, 108, 166)",
                            "rgb(116, 88, 156)",
                            "rgb(89, 38, 109)",
                            "rgb(52, 22, 100)",
                            "rgba(2, 90, 0, 1)",
                          ]}
                          customSegmentStops={[0, 500, 700, 800, 900]}
                          needleHeightRatio={0.7}
                          currentValueText={`${score}`}
                          valueTextFontSize="20px"
                          style={{ backgroundColor: "transparent" }}
                        />
                      </div>
                    </div>

                    <div
                      className={
                        formShow
                          ? "score-con d-flex justify-content-between"
                          : "d-flex "
                      }
                      style={{
                        flexDirection: "column",
                        backgroundColor: "transparent",
                      }}
                    >
                      <p
                        style={{
                          backgroundColor: "transparent",
                          alignSelf: "center",
                          margin: "auto",
                          marginTop: "3%",
                          marginBottom: 0,
                        }}
                        className={
                          formShow ? "poor-score2 mb-0" : "poor-score mb-0"
                        }
                      >
                        Your Credit Score is
                      </p>
                      <b
                        style={{
                          fontSize: 29,
                          fontWeight: 800,
                          backgroundColor: "transparent",
                          textAlign: "center",
                          color: "#561A6A",
                        }}
                      >
                        {score}
                      </b>
                    </div>

                    <div
                      className="cibil-con"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <p
                        style={{
                          backgroundColor: "transparent",
                          textAlign: "center",
                        }}
                        // className={
                        //   formShow ? "cibil-cond mb-2" : "cibil-condition mb-2"
                        // }
                      >
                        You are in a{" "}
                        <b
                          style={{
                            color: "#EEA11E",
                            backgroundColor: "transparent",
                          }}
                        >
                          {getRating(score)}
                        </b>
                        {getRating(score) === "EXCELLENT"
                          ? "🙂👍"
                          : getRating(score) === "GOOD"
                          ? "🙂"
                          : "😐"}{" "}
                        Shape
                      </p>
                      <p
                        style={{ backgroundColor: "transparent" }}
                        className={
                          formShow ? "cibil-msg2 mb-0" : "cibil-msg mb-0"
                        }
                      >
                        {cbilbody}
                      </p>
                    </div>
                    <div
                      className="d-flex justify-content-center cmp-logo-con"
                      style={{
                        backgroundColor: "transparent",
                        width: "10rem",
                        height: "4rem",
                        margin: "auto",
                        marginTop: "3%",
                        marginBottom: "2%",
                      }}
                    >
                      <img
                        src={experian}
                        className="cmp-logo"
                        style={{
                          backgroundColor: "transparent",
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>

                    <div
                      className="report-con"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <p
                        className="reportText"
                        style={{ backgroundColor: "transparent" }}
                      >
                        Report issue with the result{" "}
                        <a
                          href="https://consumer.experian.in/ECV-OLN/view/angular/#/"
                          style={{ backgroundColor: "transparent" }}
                        >
                          Here
                        </a>
                      </p>

                      <p
                        className="reportUpdate"
                        style={{ backgroundColor: "transparent" }}
                      >
                        last update on {reportDate}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <a
                href={chat}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  position: "fixed",
                  top: "82%",
                  right: 0,
                  transform: "translateY(-50%)",
                  backgroundColor: "#59266D",
                  color: "white",
                  padding: "10px 15px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textDecoration: "none",
                  borderRadius: "8px 0 0 8px",
                  boxShadow: "-2px 2px 4px rgba(0,0,0,0.2)",
                  zIndex: 1000,
                  transition: "all 0.3s ease",
                  writingMode: "vertical-rl",
                  textOrientation: "mixed",
                  letterSpacing: "1px",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "#59266D";
                  e.target.style.right = "5px";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "#59266D";
                  e.target.style.right = "0";
                }}
              >
                <div
                  style={{ width: 40, height: 40, backgroundColor: "#59266D" }}
                >
                  <img
                    src={chatbox}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#59266D",
                    }}
                  />
                </div>
              </a>
            </div>
          </div>
          {isModalOpen && (
            <div className="modal">
              <div className="modal-overlay" onClick={closeModal} />
              <div className="modal-content">
                <span
                  className="modal-close"
                  onClick={closeModal}
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "30px",
                    color: "black",
                  }}
                >
                  &times;
                </span>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "20px",
                  }}
                >
                  <div
                    onClick={() => {
                      localStorage.setItem("id", 1);
                      moveToSave();
                      closeModal();
                    }}
                    style={{
                      margin: "0 auto",
                      display: "flex",
                    }}
                  >
                    <div
                      className="image2-book"
                      style={{
                        width: "117px",
                        height: "94px",
                        marginTop: "22px",
                        marginBottom: "28px",
                        marginLeft: "2px",
                        backgroundColor: "transparent",
                        marginRight: "30px",
                      }}
                    >
                      <img
                        src={lowerEmiIllustration}
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "transparent",
                        }}
                        alt=""
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "32px",
                        marginRight: "8px",
                        marginBottom: "24px",
                        gap: "5px",
                        backgroundColor: "transparent",
                        width: "203px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "transparent",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 500,
                            fontSize: "21px",
                            color: "rgba(89, 38, 109, 1)",
                            backgroundColor: "transparent",
                          }}
                        >
                          Lower Your EMIs
                        </div>
                        <FaArrowRight
                          size={16}
                          color={"rgba(89, 38, 109, 1)"}
                          style={{
                            backgroundColor: "transparent",
                            marginLeft: "20px",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "16px",
                          color: "rgba(138, 138, 138, 1)",
                          backgroundColor: "transparent",
                        }}
                      >
                        Use our algorithm to reduce your loan interest rate
                        based on your credit score.
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ margin: "0 auto", display: "flex" }}
                    onClick={() => {
                      localStorage.setItem("id", 3);
                      moveToSave();
                      closeModal();
                    }}
                  >
                    <div
                      className="image2-book"
                      style={{
                        width: "117px",
                        height: "94px",
                        marginTop: "22px",
                        marginBottom: "28px",
                        marginLeft: "2px",
                        backgroundColor: "transparent",
                        marginRight: "30px",
                      }}
                    >
                      <img
                        src={TopUPIllustration}
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "transparent",
                        }}
                        alt=""
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "32px",
                        marginRight: "8px",
                        marginBottom: "24px",
                        gap: "5px",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "transparent",
                        width: "203px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "transparent",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 500,
                            fontSize: "21px",
                            color: "rgba(89, 38, 109, 1)",
                            backgroundColor: "transparent",
                          }}
                        >
                          Get top-up on loan with same Emi
                        </div>
                        <FaArrowRight
                          size={16}
                          color={"rgba(89, 38, 109, 1)"}
                          style={{
                            backgroundColor: "transparent",
                            alignSelf: "center",
                          }}
                          onClick={() => {
                            localStorage.setItem("id", 3);
                            closeModal();
                          }}
                        />
                      </div>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "16px",
                          color: "rgba(138, 138, 138, 1)",
                          backgroundColor: "transparent",
                        }}
                      >
                        Get extra funds easily to chase new goals and
                        opportunity.
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ margin: "0 auto", display: "flex" }}
                    onClick={() => {
                      localStorage.setItem("id", 2);
                      moveToSave();
                      closeModal();
                    }}
                  >
                    <div
                      className="image2-book"
                      style={{
                        width: "117px",
                        height: "94px",
                        marginTop: "22px",
                        marginBottom: "28px",
                        marginLeft: "2px",
                        backgroundColor: "transparent",
                        marginRight: "30px",
                      }}
                    >
                      <img
                        src={ConsolidateIllustration}
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "transparent",
                        }}
                        alt=""
                      />
                    </div>
                    <div
                      style={{
                        marginTop: "32px",
                        marginRight: "8px",
                        marginBottom: "24px",
                        gap: "5px",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "transparent",
                        width: "203px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "transparent",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 500,
                            fontSize: "21px",
                            color: "rgba(89, 38, 109, 1)",
                            backgroundColor: "transparent",
                          }}
                        >
                          Club Multiple EMIs
                        </div>
                        <FaArrowRight
                          size={16}
                          color={"rgba(89, 38, 109, 1)"}
                          style={{ backgroundColor: "transparent" }}
                        />
                      </div>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "16px",
                          color: "rgba(138, 138, 138, 1)",
                          backgroundColor: "transparent",
                        }}
                      >
                        Review and consolidate high-interest loans and overdraft
                        accounts affecting your credit profile.
                      </div>
                    </div>
                  </div>

                  <div style={{ margin: "0 auto", display: "flex" }}>
                    <div
                      className="image2-book"
                      style={{
                        width: "117px",
                        height: "94px",
                        marginTop: "22px",
                        marginBottom: "28px",
                        marginLeft: "2px",
                        backgroundColor: "transparent",
                        marginRight: "30px",
                      }}
                    >
                      <img
                        src={ConsolidateIllustration}
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "transparent",
                        }}
                        alt=""
                      />
                    </div>
                    <Link
                      to="https://minemi.ai/personal-loan/check-eligibility"
                      style={{
                        marginTop: "32px",
                        marginRight: "8px",
                        marginBottom: "24px",
                        gap: "5px",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "transparent",
                        width: "203px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          // justifyContent: "space-between",
                          gap: 16,
                          alignItems: "center",
                          backgroundColor: "transparent",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 500,
                            fontSize: "21px",
                            color: "rgba(89, 38, 109, 1)",
                            backgroundColor: "transparent",
                          }}
                        >
                          Discover Your Loan Eligiblity
                        </div>
                        <FaArrowRight
                          size={16}
                          color={"rgba(89, 38, 109, 1)"}
                          style={{ backgroundColor: "transparent" }}
                        />
                      </div>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "16px",
                          color: "rgba(138, 138, 138, 1)",
                          backgroundColor: "transparent",
                        }}
                      >
                        Explore your loan potential with no impact on your
                        credit score.
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          <style>{`
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .modal-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
        }
        .modal-content {
          background-color: white;
          padding: 20px;
          border-radius: 5px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
          z-index: 1001;
          width: 90%;
          max-width: 400px; /* Adjust the maximum width as needed */
          max-height: 90%;
          overflow: auto;
        }
        .modal-close {
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }

        @media (min-width: 768px) {
          .modal-content {
            max-width: 60%;
          }
        }

        @media (min-width: 992px) {
          .modal-content {
            max-width: 50%;
          }
        }

        @media (min-width: 1200px) {
          .modal-content {
            max-width: 40%;
          }
        }
      `}</style>
        </>
      )}
    </>
  );
}

export default HomeAfterLogin;

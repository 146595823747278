import React from "react";
import "./Types.css";

const Types = () => {

  return (
    <>
      <div id="types">
        <div className="contentCon">
          <div id="header-con">
            <p className="bg-transparent">Types Of Loan</p>
          </div>
          <div id="content" className="bg-transparent">
            <div className="d-flex flex-wrap justify-content-center w-100 bg-transparent ">
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/home_loan.webp"
                  alt="home_loan"
                />
                <p className="bg-transparent">Home Loan</p>
              </div>

              <div className="line-con bg-transparent">
                <div className="line"></div>
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/personal_loan.webp"
                  alt="personal_loan"
                />
                <p className="bg-transparent">Personal Loan</p>
              </div>

              <div className="line-con bg-transparent">
                <div className="line"></div>
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/car_loan.webp"
                  alt="car_loan"
                />
                <p className="bg-transparent">Car Loan</p>
              </div>

              <div className="line-con third-line bg-transparent">
                <div className="line"></div>
              </div>
              <div className="loan-info-con fourth-loan-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/balance_transfer.webp"
                  alt="balance_transfer"
                />
                <p className="bg-transparent">Balance Transfer</p>
              </div>

              <div className="line-con bg-transparent">
                <div className="line"></div>
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/topup_loan.webp"
                  alt="topup_loan"
                />
                <p className="bg-transparent">Top-Up Loan</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Types;

import { useState } from "react";
import { motion } from "framer-motion";

export default function FaqSection() {
  const faqs = [
    {
      question: "Why should I check my rate before applying for a loan?",
      answer:
        "Checking your rate before applying helps you find the best interest rate without affecting your credit score, so you don't get rejected due to multiple applications.",
    },
    {
      question: "Does checking my rate impact my credit score?",
      answer:
        "No! Minemi performs a soft check, meaning it has zero impact on your credit score.",
    },
    {
      question: "How does Minemi calculate my loan rate?",
      answer:
        "Minemi uses AI-driven algorithms that analyze credit score, income, loan amount, and lender criteria to provide accurate rate estimates.",
    },
    {
      question: "What happens after I check my rate?",
      answer:
        "Once you check your rate, you'll see a list of personalized loan options on our dashboard. You can then apply directly with high approval chances.",
    },
    {
      question: "Is my data safe with Minemi?",
      answer:
        "We use bank-grade encryption and never share your data without your consent.",
    },
    {
      question: "What types of loans does Minemi support?",
      answer:
        "We help users check rates for: Personal Loans, Home Loans, Car Loans, Gold Loans, Loan Against Property, and Business Loans.",
    },
    {
      question: "How long does it take to check my loan rate?",
      answer:
        "Less than 2 minutes! Just fill in a few details and get instant rate results.",
    },
    {
      question: "Can I apply for a loan through Minemi after checking my rate?",
      answer:
        "Yes! Once you check your rate, you'll see the best loan options, and you can apply directly through Minemi.",
    },
  ];

  // State to track which FAQ is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function to open/close FAQs
  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="py-10 md:py-16 " style={{ backgroundColor: "#f8f8f8" }}>
      <div className="container mx-auto px-6">
        <div className="text-center mb-8">
          <h2 className="text-xl md:text-2xl font-bold text-[#311664] mb-2">
            Frequently Asked Questions
          </h2>
          <div className="w-16 h-0.5 bg-[#3a868b] mx-auto"></div>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 5 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          viewport={{ once: true, margin: "-50px" }}
          className="max-w-lg mx-auto"
        >
          <div className="space-y-3">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="border border-[#ebe8fc] rounded-lg overflow-hidden bg-white shadow-md"
              >
                <button
                  onClick={() => toggleFaq(index)}
                  className={`w-full px-5 py-3 flex justify-between items-center text-left text-sm font-medium text-[#311664] hover:bg-[#ebe8fc]/50 transition-colors duration-200 ${
                    openIndex === index ? "bg-[#ebe8fc]/30" : ""
                  }`}
                  aria-expanded={openIndex === index}
                  aria-controls={`faq-content-${index}`}
                >
                  {faq.question}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className={`transition-transform duration-200 ${
                      openIndex === index ? "rotate-180" : ""
                    }`}
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </button>
                <div
                  id={`faq-content-${index}`}
                  className={`overflow-hidden transition-all duration-200 ease-in-out ${
                    openIndex === index
                      ? "max-h-96 opacity-100"
                      : "max-h-0 opacity-0"
                  }`}
                  aria-hidden={openIndex !== index}
                >
                  <div className="px-5 py-3 text-[#26292b] text-sm">
                    {faq.answer}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
}

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CalculateIcon from "@mui/icons-material/Calculate";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const faqCategories = [
  {
    title: "General Questions",
    icon: HelpOutlineIcon,
    questions: [
      {
        q: "What is APR, and why is it important?",
        a: "APR stands for Annual Percentage Rate, representing the total annual cost of borrowing, including interest and fees. It's important for comparing loans or credit cards to choose the most cost-effective option.",
      },
      {
        q: "How is APR different from the interest rate?",
        a: "The interest rate is the cost of borrowing money, while APR includes the interest rate plus additional fees or charges.",
      },
    ],
  },
  {
    title: "Understanding APR",
    icon: CalculateIcon,
    questions: [
      {
        q: "How is APR calculated?",
        a: "APR is calculated by adding the total interest and fees, dividing by the loan amount, and annualizing the result as a percentage.",
      },
      {
        q: "What types of APR are there?",
        a: "There are two main types of APR: Fixed APR, which stays constant, and Variable APR, which changes based on market rates.",
      },
    ],
  },
  {
    title: "Impact on Loans & Credit Cards",
    icon: CreditCardIcon,
    questions: [
      {
        q: "How does APR impact my loan repayment?",
        a: "A higher APR means you'll pay more in interest and fees over time, increasing the total cost of the loan.",
      },
      {
        q: "Does a lower APR always mean a better deal?",
        a: "Not necessarily. Other factors like loan terms, repayment flexibility, and hidden fees should also be considered.",
      },
    ],
  },
  {
    title: "APR in Practice",
    icon: AttachMoneyIcon,
    questions: [
      {
        q: "What is a good APR for personal loans or credit cards in India?",
        a: "A good APR depends on the type of loan or card and current market rates, but lower APRs are generally more favorable.",
      },
      {
        q: "Can APR change over time?",
        a: "Yes, for loans or credit cards with Variable APR, it can fluctuate based on changes in the market or benchmark interest rates.",
      },
      {
        q: "How can I use APR to compare financial products?",
        a: "Look at the APR when comparing loans or credit cards to understand the true cost, including fees, not just the interest rate.",
      },
    ],
  },
];

export default function FAQSection() {
  return (
    <section style={{ padding: "48px 0", backgroundColor: "transparent" }}>
      <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 16px" }}>
        <h2
          style={{
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "32px",
            color: "#26292b",
          }}
        >
          Everything You Need to Know About APR
        </h2>

        <Grid container spacing={4}>
          {faqCategories.map((category, index) => (
            <Grid item xs={12} md={6} key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                  backgroundColor: "transparent",
                }}
              >
                <category.icon
                  style={{
                    fontSize: "24px",
                    color: "#59266d",
                    marginRight: "8px",
                    backgroundColor: "transparent",
                  }}
                />
                <h3
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#59266d",
                    backgroundColor: "transparent",
                  }}
                >
                  {category.title}
                </h3>
              </div>
              {category.questions.map((faq, faqIndex) => (
                <Accordion
                  key={faqIndex}
                  style={{ marginBottom: "20px", backgroundColor: "#fff" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${index}-${faqIndex}-content`}
                    id={`panel-${index}-${faqIndex}-header`}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#26292b",
                        textAlign: "start",
                        backgroundColor: "#fff",
                      }}
                    >
                      {faq.q}
                    </p>
                  </AccordionSummary>
                  <AccordionDetails style={{ backgroundColor: "transparent" }}>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#4a4a4a",
                        textAlign: "start",
                        backgroundColor: "#fff",
                      }}
                    >
                      {faq.a}
                    </p>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          ))}
        </Grid>

        <div style={{ textAlign: "center", marginTop: "48px" }}>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "500",
              marginBottom: "16px",
              color: "#26292b",
            }}
          >
            Still have questions?
          </p>
          <button
            variant="contained"
            style={{
              backgroundColor: "#59266d",
              color: "white",
              padding: "12px 24px",
              fontSize: "16px",
              borderRadius: "9999px",
              textTransform: "none",
            }}
          >
            Reach Out to Our Support Team
          </button>
        </div>
      </div>
    </section>
  );
}

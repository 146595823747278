import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import tongueOut from "../../../Assets/Images/tongue-out.webp";
import MetadataContext from "../../MetadataContext";

const LoanArticleOne = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Loan Pre-Approval Guide | Steps to Get Pre-Approved Fast",
      pageDescription:
        "Learn how to get pre-approved for loans, boost your approval chances, and understand the process for personal, home, and car loans with Minemi’s expert guide.",
      pageKeywords:
        "Loan pre-approval,Pre-approved loans, How to get pre-approved for a loan, Pre-approved home loan, Pre-approved personal loan, Pre-approved loan process, Loan eligibility check, Instant loan pre-approval, Benets of loan pre-approval, Online loan pre-approval,Home loan pre-approval process",
      canonicalUrl: "https://minemi.ai/loanpage/loan-get-pre-approved/",
    });
  }, [setMetadata]);
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Pre-Approved Loan
              </Link>
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            How to Get Pre-Approved for a Loan: A Step-by-Step Guide
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: 1,
            }}
          >
            Learn how to secure pre-approval for a loan with this step-by-step
            guide, helping you streamline the borrowing process and improve your
            chances of approval.
          </p>
        </div>

        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
          <h2
            style={{
              marginTop: 40,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Introduction: Why Loan Pre-Approval Matters?
          </h2>

          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Loan pre-approval can transform the borrowing experience by oering
            clarity and condence. Whether you’re purchasing a house, nancing a
            car, or seeking a personal loan, pre-approval helps you gauge your
            eligibility and borrowing capacity upfront. This simplies the
            process, improves your negotiating position, and can accelerate nal
            approval.
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            With nancial institutions oering pre-approved loans directly through
            banking apps and net banking, many borrowers receive oers without
            even applying. However, understanding how pre-approvals work and
            ensuring due diligence is essential to avoid potential pitfalls.
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            This guide will break down the pre-approval process, highlight
            benets, and oer practical tips to maximize your chances of approval
            while safeguarding your nancial data.
          </p>

          <h4
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            What is Loan Pre-Approval and How Does It Work?
          </h4>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Loan pre-approval is an assessment by a lender that estimates how
            much you can borrow based on your creditworthiness and income. This
            initial check typically involves a{" "}
            <strong>soft credit inquiry </strong>that doesn’t impact your credit
            score.
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            <strong>Unlike pre-qualication</strong>, which is a rough estimate,
            pre-approval requires a detailed review of nancial documents and a
            more comprehensive check, providing a more accurate borrowing range.
          </p>

          <div>
            <h4
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 22,
                lineHeight: 1.2,
              }}
            >
              Common Loan Types for Pre-Approval:
            </h4>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Home Loans :{" "}
                  </span>
                  Pre-approval helps streamline the home-buying process by
                  conrming the budget you can aord.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Car Loans:{" "}
                  </span>
                  With pre-approval, you can negotiate better deals at
                  dealerships, having clarity on loan limits.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Personal Loans:{" "}
                  </span>
                  Ideal for consolidating debt or funding emergencies.
                  Pre-approval gives a snapshot of your borrowing potential.
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Benets of Getting Pre-Approved for a Loan
          </div>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Strengthens Negotiating Power:{" "}
                </span>
                Pre-approval makes you a stronger candidate for property or car
                purchases. Sellers are more likely to engage with pre-approved
                buyers due to their nancial readiness.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Faster Loan Disbursement:{" "}
                </span>
                Since lenders have already assessed your prole, the nal approval
                process is quicker, reducing the overall wait time for fund
                disbursement.
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Clarity on Borrowing Capacity:{" "}
                </span>
                Pre-approval oers a clear picture of the maximum amount you can
                borrow, helping you plan nances and set realistic expectations.
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Locks Favorable Interest Rates:{" "}
                </span>
                Some lenders provide an interest rate lock during the
                pre-approval phase, protecting you from market rate uctuations
                during the loan processing period.
              </div>
            </div>
          </div>

          {/* minemi tip section */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    💡 Borrowers with long-standing relationships at their
                    primary bank are more likely to receive pre-approved oers.
                    Maintaining a clean repayment history and stable income
                    improves eligibility.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 25,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
                lineHeight: 1.2,
              }}
            >
              Steps to Get Pre-Approved for a Loan :
            </div>
            <h5
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 22,
              }}
            >
              1. Review Your Credit Score and Report
            </h5>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                fontWeight: 400,
                marginTop: "10px",
              }}
            >
              Your credit score is the cornerstone of loan approval. Check your
              credit score through trusted platforms like Experian or CIBIL to
              ensure accuracy.
            </p>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    750 or higher:{" "}
                  </span>
                  Likely to receive the best rates and terms.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    650 to 750:{" "}
                  </span>
                  Pre-approval is possible, but interest rates may be higher.
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Below 650:{" "}
                  </span>
                  Focus on improving your score before applying.
                </div>
              </div>
            </div>
          </div>
          <div>
            <h5
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 22,
                lineHeight: "26px",
              }}
            >
              2. Gather Required Documents
            </h5>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                fontWeight: 400,
                marginTop: "10px",
              }}
            >
              Lenders assess your nancial health through various documents.
              Keeping these handy speeds up the process:
            </p>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Identity Proof:{" "}
                  </span>
                  Aadhaar, PAN, or Passport
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Income Proof:{" "}
                  </span>
                  Salary slips (for salaried), ITR or GST returns (for
                  self-employed)
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Bank Statements:{" "}
                  </span>
                  Last 6 months for assessing cash flow
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Existing Liabilities :{" "}
                  </span>
                  Details of ongoing EMIs, credit card statements
                </div>
              </div>
            </div>
          </div>
          <div id="right-lender">
            <h5
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 22,
                lineHeight: "26px",
              }}
            >
              3. Choose the Right Lender
            </h5>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                fontWeight: 400,
                marginTop: "10px",
              }}
            >
              Start with lenders where you have an existing relationship.
              Primary banks often provide pre-approved oers based on
              transactional data, increasing the likelihood of favorable terms.
            </p>
            <h5
              style={{
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 18,
                lineHeight: "26px",
              }}
            >
              Example:
            </h5>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                fontWeight: 400,
                marginTop: "10px",
              }}
            >
              ICICI Bank’s Money Saver Home Loan allows overdraft-linked
              repayment, reducing interest based on surplus funds parked in the
              linked account. This is often pre-approved for long-term account
              holders.
            </p>
          </div>
          <h5
            style={{
              marginTop: 20,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 22,
              lineHeight: "26px",
            }}
          >
            4. Apply Online or via Net Banking
          </h5>
          <p
            style={{
              fontFamily: "'Archivo',sans-serif",
              fontSize: "16px",
              fontWeight: 400,
              marginTop: "10px",
            }}
          >
            Most banks offer seamless pre-approval applications through mobile
            apps and websites. Simply log in to check for pre-approved oers or
            initiate the process.
          </p>

          {/* minemi tip section */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    "Check loan offers through the lender’s ocial app to avoid
                    unauthorized third-party platforms that may misuse personal
                    data."
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <h5
            style={{
              marginTop: 20,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 22,
              lineHeight: "26px",
            }}
          >
            5. Accept the Offer and Submit Additional Documents
          </h5>
          <p
            style={{
              fontFamily: "'Archivo',sans-serif",
              fontSize: "16px",
              fontWeight: 400,
              marginTop: "10px",
            }}
          >
            Once pre-approved, you’ll receive an oer detailing the amount,
            tenure, and interest rate. Accept the oer and provide any additional
            documentation if required.
          </p>

          {/* minemi tip section */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    🔍 Always compare pre-approved oers from dierent lenders.
                    Even a small dierence in interest rates can lead to
                    signicant savings over the loan tenure.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3
              style={{
                marginTop: 25,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
                lineHeight: 1.2,
              }}
            >
              RBI Guidelines and Consumer Protection
            </h3>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 400,
                marginTop: "10px",
                fontFamily: "'Archivo',sans-serif",
                lineHeight: "24px",
              }}
            >
              The Reserve Bank of India (RBI) has issued multiple advisories
              emphasizing the importance of due diligence in digital lending.
              Borrowers must be cautious when accepting pre-approved loans,
              ensuring the lender is authorized and legitimate.
            </p>
            <h5
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 22,
                lineHeight: "26px",
              }}
            >
              Key RBI Recommendations:
            </h5>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  Avoid sharing sensitive personal information with unveried
                  platforms.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  Always review the terms and conditions before consenting to
                  pre-approval oers.
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  Check for hidden charges or additional fees linked to
                  pre-approved loans.
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: 15,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 18,
              }}
            >
              Example:
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  marginTop: "10px",
                  fontFamily: "'Archivo',sans-serif",
                  lineHeight: "24px",
                }}
              >
                Mr. Sharma received a pre-approved personal loan oer via SMS
                from his bank. While the rate seemed attractive, upon reading
                the terms, he realized there was a 3% processing fee hidden in
                the ne print. By comparing oers through his bank’s app, he
                secured a better deal without hidden costs
              </p>
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              When to Consider Pre-Approved Loans
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    During Property Purchase:{" "}
                  </span>
                  If you’re in the market for a house, pre-approval gives
                  clarity on the loan amount, allowing you to lter properties
                  within budget.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Debt Consolidation:{" "}
                  </span>
                  Pre-approved personal loans can consolidate high-interest
                  debts, reducing the overall interest burden.
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Urgent Financial Needs:{" "}
                  </span>
                  For emergencies, pre-approved loans oer immediate liquidity
                  without lengthy approval times.
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              When to Avoid Pre-Approved Loans
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Unfavorable Interest Rates :{" "}
                  </span>
                  If the rates seem high, explore renancing options
                  post-approval.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Unveried Lenders :{" "}
                  </span>
                  ick to established banks and NBFCs to avoid scams.
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Debt Trap:{" "}
                  </span>
                  Avoid unnecessary loans, even if pre-approved, to prevent
                  overleveraging.
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Tips to Maximize Pre-Approval Success
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Maintain a Good Credit Score:{" "}
                  </span>
                  Timely bill payments, low credit utilization, and a strong
                  credit history improve preapproval chances.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Limit Multiple Applications:{" "}
                  </span>
                  Applying to several lenders simultaneously can reduce your
                  credit score. Focus on 2-3 trusted banks.
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Leverage Salary Accounts:{" "}
                  </span>
                  Banks often provide pre-approved loans to salary account
                  holders due to stable income visibility.
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 20,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Conclusion
            </div>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                marginTop: "10px",
                lineHeight: "24px",
              }}
            >
              Loan pre-approval simplies the borrowing journey, providing
              clarity, faster approvals, and better negotiating power. However,
              understanding the ne print, comparing oers, and safeguarding
              personal data are essential to avoid pitfalls. With the right
              approach, pre-approvals can unlock nancial opportunities and bring
              you closer to your goals.
            </p>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                marginTop: "10px",
                lineHeight: "24px",
              }}
            >
              Here’s a comprehensive breakdown of one-page SEO elements and
              structural details for the article “How to Get Pre-Approved for a
              Loan” to t under the Loans Section of Minemi’s site.
            </p>
          </div>

          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br className="display-none" /> and tailored just for you—apply
                now to see what you qualify for!
              </p>
              <div
                style={{
                  backgroundColor: "transparent",
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                }}
              >
                <a
                  href="https://minemi.ai/home-loan/check-eligibility"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 50,
                    paddingRight: 50,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                >
                  Check Eligiblity Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanArticleOne;

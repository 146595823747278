
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"

export default function HeroSection({data}) {
    const navigate = useNavigate();
    return (
    <section className="relative min-h-[60vh] md:min-h-[80vh] flex items-center justify-center py-8 md:py-16 px-4 md:px-8 lg:px-16 overflow-hidden">
      {/* Background gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-[#311664] to-[#59266d] opacity-[0.03] rounded-bl-[100px]" />

      <div className="container max-w-7xl grid grid-cols-1 lg:grid-cols-2 gap-12 items-center" style={{zIndex:50000}}>
        {/* Text content */}
        <div
          className="md:text-center lg:text-left first-fold"
          >
          <h1 className="text-3xl md:text-5xl md:text-center lg:text-6xl font-bold text-[#311664] mb-3 md:mb-6 leading-tight  lg:text-left first-fold">
            Check If You’re Eligible for the Right Credit Card

          </h1>
          <p className="text-base  md:text-center md:text-xl text-gray-700 mb-4 md:mb-8 mx-auto lg:mx-0  lg:text-left  first-fold">
          Find cards you’re most likely to qualify for—without impacting your credit score.
          </p>
          <button

  style={{zIndex:60}}
  className="bg-[#3a868b] hover:bg-[#347379] text-white text-base md:text-lg py-2 px-4 rounded-lg transition-all duration-300"
  onClick={()=>data.handleScrollToSecondFold()}
>
  Check My Eligibility
</button>
   

        </div>

        {/* Illustration - Center aligned on mobile */}
        <div className="flex justify-center lg:block">
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="relative w-[280px] md:w-[400px] h-[250px] md:h-[400px]"
          >
            <motion.div
              className="absolute left-0 right-0 mx-auto lg:mx-0 lg:left-0 top-0 w-56 md:w-64 h-36 md:h-40 bg-[#ebe8fc] rounded-xl shadow-lg transform rotate-6 z-10"
              animate={{ y: [0, -10, 0], rotate: [6, 8, 6] }}
              transition={{ repeat: Number.POSITIVE_INFINITY, duration: 5, ease: "easeInOut" }}
            >
              <div className="absolute top-3 left-4 h-8 w-12 bg-[#3a868b] rounded-md"></div>
              <div className="absolute top-6 right-6 h-3 w-20 bg-[#59266d] rounded-sm"></div>
              <div className="absolute bottom-6 left-6 h-3 w-16 bg-gray-400 rounded-sm"></div>
              <div className="absolute bottom-10 left-6 h-3 w-24 bg-gray-300 rounded-sm"></div>
            </motion.div>

            <motion.div
              className="absolute left-0 right-0 mx-auto lg:mx-0 lg:right-0 lg:left-auto top-20 w-64 md:w-72 h-40 md:h-44 bg-white rounded-xl shadow-lg transform -rotate-3 z-20"
              animate={{ y: [0, 10, 0], rotate: [-3, -5, -3] }}
              transition={{ repeat: Number.POSITIVE_INFINITY, duration: 6, ease: "easeInOut", delay: 0.5 }}
            >
              <div className="absolute top-3 left-4 h-8 w-12 bg-[#311664] rounded-md"></div>
              <div className="absolute top-6 right-6 h-3 w-20 bg-[#3a868b] rounded-sm"></div>
              <div className="absolute bottom-6 left-6 h-3 w-16 bg-gray-400 rounded-sm"></div>
              <div className="absolute bottom-10 left-6 h-3 w-24 bg-gray-300 rounded-sm"></div>
            </motion.div>

            <motion.div
              className="absolute left-0 right-0 mx-auto lg:mx-0 lg:left-10 bottom-0 w-72 md:w-80 h-44 md:h-48 bg-gradient-to-br from-[#311664] to-[#59266d] rounded-xl shadow-lg z-30"
              animate={{ y: [0, 5, 0], rotate: [0, 2, 0] }}
              transition={{ repeat: Number.POSITIVE_INFINITY, duration: 4, ease: "easeInOut", delay: 1 }}
            >
              <div className="absolute top-3 right-4 h-8 w-12 bg-[#ebe8fc] rounded-md"></div>
              <div className="absolute top-14 left-6 h-3 w-20 bg-white opacity-50 rounded-sm"></div>
              <div className="absolute bottom-10 left-6 h-3 w-16 bg-white opacity-30 rounded-sm"></div>
              <div className="absolute bottom-6 left-6 h-3 w-24 bg-white opacity-50 rounded-sm"></div>
            </motion.div>
          </motion.div>
        </div>

      </div>
    </section>
  )
}


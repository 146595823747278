import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Check, X } from "lucide-react";

export default function ComparisonSection() {
  const comparisonData = [
    {
      feature: "Instant Results",
      minemi: true,
      bankVisit: false,
      genericCalculator: true,
    },
    {
      feature: "No Credit Score Impact",
      minemi: true,
      bankVisit: false,
      genericCalculator: true,
    },
    {
      feature: "Personalized Insights",
      minemi: true,
      bankVisit: false,
      genericCalculator: false,
    },
    {
      feature: "Data Security",
      minemi: true,
      bankVisit: true,
      genericCalculator: false,
    },
    {
      feature: "Tailored Loan Options",
      minemi: true,
      bankVisit: true,
      genericCalculator: false,
    },
  ];

  const CheckMark = () => <Check className="h-5 w-5 text-green-500 mx-auto" />;
  const XMark = () => <X className="h-5 w-5 text-red-500 mx-auto" />;

  return (
    <section
      className="w-full py-8 md:py-16 "
      style={{ backgroundColor: "#f8f8f8" }}
    >
      <div className="container mx-auto px-4">
        <div className="text-center mb-6 md:mb-12">
          <h2 className="text-2xl md:text-4xl font-bold text-[#311664] mb-2 md:mb-4">
            Minemi vs. Other Methods
          </h2>
          <p className="text-base md:text-lg text-gray-600 max-w-2xl mx-auto">
            See why Minemi's loan approval checker outperforms traditional
            methods
          </p>
        </div>

        <div className="max-w-4xl mx-auto rounded-xl overflow-hidden shadow-lg">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className="bg-[#ebe8fc]">
                  <TableCell className="font-bold text-[#311664] text-xs md:text-base">
                    Feature
                  </TableCell>
                  <TableCell
                    align="center"
                    className="font-bold text-[#311664] text-xs md:text-base"
                  >
                    Minemi
                  </TableCell>
                  <TableCell
                    align="center"
                    className="font-bold text-[#311664] text-xs md:text-base"
                  >
                    Bank Visit
                  </TableCell>
                  <TableCell
                    align="center"
                    className="font-bold text-[#311664] text-xs md:text-base"
                  >
                    Generic Calculator
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {comparisonData.map((row, index) => (
                  <TableRow
                    key={index}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                  >
                    <TableCell className="font-medium text-xs md:text-base">
                      {row.feature}
                    </TableCell>
                    <TableCell align="center">
                      {row.minemi ? <CheckMark /> : <XMark />}
                    </TableCell>
                    <TableCell align="center">
                      {row.bankVisit ? <CheckMark /> : <XMark />}
                    </TableCell>
                    <TableCell align="center">
                      {row.genericCalculator ? <CheckMark /> : <XMark />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="bg-[#311664] text-white py-2 md:py-3 px-4 md:px-6 text-center">
            <p className="font-medium text-xs md:text-base">
              Minemi combines the best of all worlds—accuracy, privacy, and
              personalization
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

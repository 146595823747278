import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import TongueOut from "../../../Assets/Images/tongue-out.webp";
import MetadataContext from "../../MetadataContext";

const CreditCardRewards = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Discover the Best Cash Back Credit Cards | Minemi.ai",
      pageDescription:
        "Unlock the best cash back credit cards with Minemi.ai. Compare top cards offering high rewards, cashback benefits, and more to maximize your savings",
      pageKeywords:
        "best cash back credit cards,cash back credit cards, credit card rewards, cashback credit cards, best rewards credit cards, top cashback cards, earn cashback, credit card perks, rewards points, best credit cards for cashback, SBI cashback credit card, HDFC rewards credit card, ICICI cashback offers, earn rewards points, credit card benefits, best credit cards for rewards, cashback cards 2025",
      canonicalUrl:
        "https://minemi.ai/creditcard/credit-card-rewards-and-cashback",
    });
  }, [setMetadata]);
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                underline="hover"
                className="custom-link bg-transparent archivo "
                style={{
                  color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/creditcard"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ color: "#59266D", cursor: "pointer" }}
              >
                Credit Cards
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Credit Card Rewards and Cashback
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontSize: 40,
              lineHeight: "55px",
              color: "#59266D",
              backgroundColor: "#E7E4F9",
              lineHeight: 1,
            }}
          >
            Unlock Top Credit Card Rewards & Cashback Offers
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Find the best credit cards to maximize your rewards and cashback on
            everyday purchases.
          </p>
        </div>

        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
          {/* table data */}
          <div id="types-of-bt">
            <div id="eligibility-criteria">
              <div
                className=""
                style={{
                  marginTop: 30,
                }}
              >
                <h3 style={{ color: "#1F1F1F", fontSize: 24 }}>
                  Rewards Categories
                </h3>
                <h3 style={{ color: "#1F1F1F", fontSize: 16, fontWeight: 400 }}>
                  Explore Rewards by Spending Category:
                </h3>
              </div>
            </div>

            <div
              id="bt-types-table"
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                        verticalAlign: "top",
                      }}
                    >
                      Category
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                        verticalAlign: "top",
                      }}
                    >
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Dining
                    </td>

                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Earn rewards on restaurant and food delivery purchases.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Groceries
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Get cashback on supermarket and grocery store spending.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Travel
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Accumulate points or miles on airfare, hotels, and other
                      travel expenses.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Fuel
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Receive cashback or surcharge waivers on fuel purchases at
                      gas stations.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Online Shopping
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Benefit from rewards on purchases made through online
                      retailers.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* table data */}
          <div id="types-of-bt">
            <div id="eligibility-criteria">
              <div
                className=""
                style={{
                  marginTop: 30,
                }}
              >
                <h3
                  style={{
                    color: "#1F1F1F",
                    fontSize: 24,
                    fontFamily: "'Archivo',sans-serif",
                  }}
                >
                  Top Rewards Credit Cards
                </h3>
                <h3
                  style={{
                    color: "#1F1F1F",
                    fontSize: 16,
                    fontFamily: "'Archivo',sans-serif",
                    fontWeight: "400",
                  }}
                  className="mt-0"
                >
                  Best Credit Cards for Rewards & Cashback:
                </h3>
              </div>
            </div>

            <div
              id="bt-types-table"
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                        verticalAlign: "top",
                      }}
                    >
                      Card Name
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                        verticalAlign: "top",
                      }}
                    >
                      Issuer
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                        verticalAlign: "top",
                      }}
                    >
                      Annual Fee
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                        verticalAlign: "top",
                      }}
                    >
                      Key Benefits
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                        verticalAlign: "top",
                      }}
                    >
                      Apply Now
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      SBI Cashback Credit Card
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      SBI
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹999
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      - 5% cashback on all online purchases (excluding utility
                      bills).
                      <br />
                      - 1% cashback on other spends.
                      <br />
                      - No cashback capping, making it ideal for online
                      shoppers.
                      <br />
                      - Welcome benefits worth ₹2,000 on payment of the annual
                      fee.
                      <br />
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href="https://www.sbicard.com/en/personal/credit-cards/rewards/cashback-sbi-card.page"
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        Apply Now
                      </a>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      HDFC Millennia Credit Card
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      HDFC Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹1,000
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      - 5% cashback on Amazon, Flipkart, and other preferred
                      partners.
                      <br />
                      - 2.5% cashback on all online spends.
                      <br />
                      - 1% cashback on other retail spends.
                      <br />- Complimentary airport lounge access.
                    </td>

                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href="https://www.hdfcbank.com/personal/pay/cards/millennia-cards/millennia-cc-new"
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        Apply Now
                      </a>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      ICICI Amazon Pay Credit Card
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ICICI Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Nil
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      - 5% cashback on Amazon.in for Prime members.
                      <br />
                      - 3% cashback on Amazon.in for non-Prime members.
                      <br />
                      - 2% cashback on 100+ Amazon Pay partner merchants.
                      <br />- 1% cashback on all other spends.
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href="https://www.icicibank.com/personal-banking/cards/credit-card/amazon-pay-credit-card"
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        Apply Now
                      </a>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Axis Bank Ace Credit Card
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Axis Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹499
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      - 5% cashback on bill payments and mobile recharges via
                      Google Pay.
                      <br />
                      - 4% cashback on Swiggy, Zomato, and Ola.
                      <br />
                      - 2% cashback on all other spends.
                      <br />- Unlimited cashback with no expiry.
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href="https://www.axisbank.com/retail/cards/credit-card/axis-bank-ace-credit-card"
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        Apply Now
                      </a>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Citibank Cashback Credit Card
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Citibank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹500
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      - 5% cashback on movie ticket purchases.
                      <br />
                      - 5% cashback on telephone bill payments.
                      <br />
                      - 5% cashback on utility bill payments.
                      <br />- 0.5% cashback on all other spends.
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href="https://www.online.citibank.co.in/portal/newgen/cards/tab/cash-back-credit-card.htm"
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        Apply Now
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="mt-4">
            <p
              style={{
                fontSize: "16px",
                fontWeight: 400,
                fontFamily: "'Archivo',sans-serif",
              }}
            >
              <strong>Note: </strong>Always review the terms and conditions on
              the official card issuer's website before applying.
            </p>
          </div>

          <div className="tracking-status" style={{ marginTop: 30 }}>
            <h3
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontSize: 24,
                lineHeight: 1.2,
              }}
            >
              Maximize Your Rewards with Minemi Tips
            </h3>
            <h5
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontSize: 18,
                marginTop: 16,
                fontWeight: 500,
              }}
            >
              Minemi Tips:
            </h5>

            {/* minemi tip */}

            <div
              className="tips mt-4 mb-3"
              style={{
                backgroundColor: "#e7e4f9",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
              }}
            >
              <div
                id="border_line"
                style={{
                  width: "100%",
                  height: "5px",
                  backgroundColor: "#59266d",
                  border: "1px solid #8a8a8a",
                  borderTop: "0px",
                  clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
                }}
              ></div>
              <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
                <div
                  className="px-3 py-2"
                  style={{ backgroundColor: "#e7e4f9" }}
                >
                  <div
                    style={{ backgroundColor: "#e7e4f9" }}
                    className="d-flex flex-row gap-2 align-items-center "
                  >
                    <img
                      src={TongueOut}
                      alt="minemi tip image"
                      style={{
                        width: 30,
                        height: 30,
                        backgroundColor: "#e7e4f9",
                      }}
                      loading="lazy"
                    />
                    <Typography
                      sx={{
                        fontSize: 16,
                        lineHeight: "24px",
                        letterSpacing: "0.08px",
                        color: "#59266D",
                        // color: "yellow",
                        fontFamily: "'Archivo', sans-serif",
                        backgroundColor: "transparent",
                      }}
                    >
                      MinEmi Tip
                    </Typography>
                  </div>
                  <div
                    style={{
                      marginTop: 15,
                      display: "flex",
                      flexDirection: "column",

                      gap: 16,
                      backgroundColor: "transparent",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 4,
                        backgroundColor: "transparent",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "transparent",
                        }}
                      >
                        <span
                          style={{
                            color: "#1F1F1F",
                            fontWeight: 600,
                            fontSize: 16,
                            backgroundColor: "transparent",
                          }}
                        >
                          <LuDot style={{ backgroundColor: "transparent" }} />
                        </span>
                      </div>
                      <div
                        style={{
                          color: "#1F1F1F",
                          fontWeight: 400,
                          fontSize: 16,
                          backgroundColor: "transparent",
                        }}
                      >
                        <span
                          style={{
                            color: "#1F1F1F",
                            fontWeight: 600,
                            fontSize: 16,
                            backgroundColor: "transparent",
                          }}
                        >
                          Leverage Category Bonuses:{" "}
                        </span>
                        Use cards that offer higher cashback in specific
                        categories. For example, the SBI Cashback Credit Card
                        provides 5% cashback on online purchases, making it
                        ideal for online shoppers.
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 4,
                        backgroundColor: "transparent",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "transparent",
                        }}
                      >
                        <span
                          style={{
                            color: "#1F1F1F",
                            fontWeight: 600,
                            fontSize: 16,
                            backgroundColor: "transparent",
                          }}
                        >
                          <LuDot style={{ backgroundColor: "transparent" }} />
                        </span>
                      </div>
                      <div
                        style={{
                          color: "#1F1F1F",
                          fontWeight: 400,
                          fontSize: 16,
                          backgroundColor: "transparent",
                        }}
                      >
                        <span
                          style={{
                            color: "#1F1F1F",
                            fontWeight: 600,
                            fontSize: 16,
                            backgroundColor: "transparent",
                          }}
                        >
                          Combine Multiple Cards:{" "}
                        </span>
                        Utilize different cards for different spending
                        categories to maximize rewards. For instance, use the
                        Axis Bank Ace Credit Card for bill payments via Google
                        Pay to earn 5% cashback.
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 4,
                        backgroundColor: "transparent",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "transparent",
                        }}
                      >
                        <span
                          style={{
                            color: "#1F1F1F",
                            fontWeight: 600,
                            fontSize: 16,
                            backgroundColor: "transparent",
                          }}
                        >
                          <LuDot style={{ backgroundColor: "transparent" }} />
                        </span>
                      </div>
                      <div
                        style={{
                          color: "#1F1F1F",
                          fontWeight: 400,
                          fontSize: 16,
                          backgroundColor: "transparent",
                        }}
                      >
                        <span
                          style={{
                            color: "#1F1F1F",
                            fontWeight: 600,
                            fontSize: 16,
                            backgroundColor: "transparent",
                          }}
                        >
                          Monitor Reward Expiry:{" "}
                        </span>
                        Keep track of reward points' validity to ensure you
                        redeem them before expiration. Some cards offer
                        unlimited cashback with no expiry, such as the Axis Bank
                        Ace Credit Card.
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 4,
                        backgroundColor: "transparent",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "transparent",
                        }}
                      >
                        <span
                          style={{
                            color: "#1F1F1F",
                            fontWeight: 600,
                            fontSize: 16,
                            backgroundColor: "transparent",
                          }}
                        >
                          <LuDot style={{ backgroundColor: "transparent" }} />
                        </span>
                      </div>
                      <div
                        style={{
                          color: "#1F1F1F",
                          fontWeight: 400,
                          fontSize: 16,
                          backgroundColor: "transparent",
                        }}
                      >
                        <span
                          style={{
                            color: "#1F1F1F",
                            fontWeight: 600,
                            fontSize: 16,
                            backgroundColor: "transparent",
                          }}
                        >
                          Stay Updated on Offers:{" "}
                        </span>
                        Regularly check for promotional offers and accelerated
                        reward categories to take full advantage of your card's
                        benefits.
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 4,
                        backgroundColor: "transparent",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor: "transparent",
                        }}
                      >
                        <span
                          style={{
                            color: "#1F1F1F",
                            fontWeight: 600,
                            fontSize: 16,
                            backgroundColor: "transparent",
                          }}
                        >
                          <LuDot style={{ backgroundColor: "transparent" }} />
                        </span>
                      </div>
                      <div
                        style={{
                          color: "#1F1F1F",
                          fontWeight: 400,
                          fontSize: 16,
                          backgroundColor: "transparent",
                        }}
                      >
                        <span
                          style={{
                            color: "#1F1F1F",
                            fontWeight: 600,
                            fontSize: 16,
                            backgroundColor: "transparent",
                          }}
                        >
                          Redeem Wisely:{" "}
                        </span>
                        Choose redemption options that provide maximum value,
                        such as using cashback to offset statement balances or
                        redeeming points for high-value merchandise.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontSize: 24,
                paddingTop: "20px",
                lineHeight: 1.2,
              }}
            >
              How Rewards and Cashback Work
            </h3>
            <h5
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontSize: 18,
                lineHeight: "24px",
                marginTop: 16,
                fontWeight: 400,
              }}
            >
              Understanding Credit Card Rewards Programs:
            </h5>
            <div
              style={{
                marginTop: 5,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Earning Rewards:{" "}
                  </span>
                  Earn cashback or reward points on eligible purchases. For
                  example, the HDFC Millennia Credit Card offers 5% cashback on
                  Amazon and Flipkart purchases.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Redeeming Rewards:{" "}
                  </span>
                  Redeem accumulated points or cashback for statement credits,
                  merchandise, or travel bookings, depending on the card's
                  reward program.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Points vs. Cashback:{" "}
                  </span>
                  <strong>Cashback:</strong> Direct monetary returns on your
                  spending, often credited to your account. <br />
                  <strong>Points:</strong> Can be accumulated and redeemed for
                  various rewards; the value per point varies by issuer.
                </div>
              </div>
            </div>
          </div>

          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Find Credit Cards Tailored Just for You
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Let us match you with the best credit cards based on your income
                and credit score.
              </p>
              <div
                style={{
                  backgroundColor: "transparent",
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                }}
              >
                <Link
                  to="/creditcard/creditcard-recommendation"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 50,
                    paddingRight: 50,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                >
                  See Recommended Cards Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditCardRewards;

import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import "./HomeLoanAxisEligibility.css";
import MetadataContext from "../../MetadataContext";

const HomeLoanAxisEligibility = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const criteriaData = [
    {
      typeOfLoan: "Regular Home Loan",
      Applicability: "Salaried individuals and self-employed individuals",
      eligibility: "Age: 21 to 65 years; stable income; credit score of 750+",
    },
    {
      typeOfLoan: "Shubh Aarambh Home Loan",
      Applicability: "First-time borrowers for loans up to ₹30 lakhs",
      eligibility:
        "Minimum loan tenure: 20 years; clean repayment history; salaried or self-employed individuals",
    },
    {
      typeOfLoan: "Asha Home Loan",
      Applicability: "Affordable housing for low-income applicants",
      eligibility:
        "Combined family income: ₹8,000 to ₹10,000/month; loan amount up to ₹35 lakhs",
    },
    {
      typeOfLoan: "Top-Up Home Loan",
      Applicability: "Existing home loan customers needing additional funds",
      eligibility: "Regular repayment history; good credit profile",
    },
  ];

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Check you Home Loan Eligibility Eligibility with AXIS Bank",
      pageDescription:
        "Axis Bank offers various home loan solutions, and knowing the eligibility criteria can help secure better terms.",
      pageKeywords:
        "home loan eligibility, home loan eligibility minemi, AXIS Bank home loan, home loan solutions, AXIS Bank loan eligibility, check home loan eligibility, home loan criteria, affordable home loans, home loan approval, loan eligibility requirements, AXIS Bank loan offers",
      canonicalUrl:
        "https://minemi.ai/loanpage/homeloan/home-loan-eligibility-criteria/axis-home-loan-eligibility",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className="custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/homeloan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Home Loan
              </Link>{" "}
              &nbsp;
              <span
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/homeloan/home-loan-eligibility-criteria"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Home Loan Check Eligibility
              </Link>{" "}
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                AXIS Bank Home Loan Eligibility
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "46px",

              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Check you Home Loan Eligibility with AXIS Bank{" "}
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Axis Bank offers a wide range of home loan solutions tailored to
            meet the needs of various borrowers. Understanding the eligibility
            criteria can help you secure a home loan at favorable terms.
          </p>
        </div>
        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 28,
              marginTop: 30,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Home Loan Eligibility Criteria{" "}
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 16,
              color: "#1f1f1f",
              marginTop: 8,
            }}
          >
            Axis Bank evaluates your eligibility based on specific factors.
            Below is an overview of the eligibility requirements:
          </p>
          <div
            style={{
              border: "1px solid #D9D9D9",
              borderRadius: 24,
              marginTop: 24,
              backgroundColor: "white",
              marginBottom: "2%",
              maxWidth: "100%",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
              }}
            >
              <div
                style={{
                  backgroundColor: "#E7E4F9",
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#59266D",
                  padding: "16px",
                  borderBottom: "1px solid #D9D9D9",
                  borderTopLeftRadius: 24,
                  borderTopRightRadius: 24,
                  textAlign: "center",
                }}
              >
                AXIS Loan Eligibility Criteria
              </div>
            </div>

            <div
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                width: "100%",
                overflow: "auto",
              }}
            >
              <table
                className="table-class"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",

                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  textAlign: "left",
                }}
              >
                <thead className="thead-class">
                  <tr>
                    <th
                      className="table-padding"
                      style={{
                        width: "20%",
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Type of Loan
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Applicability
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Eligibility Criteria
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {criteriaData.map((row, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      }}
                    >
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        {row.typeOfLoan}
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {row.Applicability}
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {row.eligibility}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Eligibility Criteria for AXIS Bank Home Loan
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Axis Bank evaluates your home loan eligibility based on various
            factors, ensuring a straightforward and transparent process:
          </div>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Employment Type:&nbsp;&nbsp;
                </span>

                {/* list */}
                <ul className="d-flex flex-column gap-2 mt-2">
                  <li>
                    Salaried applicants must have at least two years of work
                    experience, with six months in the current role.
                  </li>
                  <li>
                    Self-employed applicants must have a stable business with
                    proof of income for at least three years
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Credit Score:&nbsp;&nbsp;
                </span>
                A credit score of 750 or above is preferred for lower interest
                rates and higher loan amounts.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Age:&nbsp;&nbsp;
                </span>
                Applicants must be between 21 and 65 years of age at the time of
                loan maturity.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Loan Amount:&nbsp;&nbsp;
                </span>
                Based on the property value and repayment capacity, loans can
                range from ₹1 lakh to ₹5 crores.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Debt-to-Income Ratio:&nbsp;&nbsp;
                </span>
                A lower ratio (ideally below 40%) is required to ensure
                manageable EMIs
              </div>
            </div>
          </div>

          <div id="documents">
            <div
              style={{
                marginTop: 25,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Documents Required for AXIS Bank Home Loan
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Identity Proof:&nbsp;&nbsp;
                  </span>
                  Aadhaar, PAN, Voter ID, or Passport
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Address Proof:&nbsp;&nbsp;
                  </span>
                  Utility bill, rent agreement, or property tax receipt
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Income Proof:&nbsp;&nbsp;
                  </span>
                  <ul className="d-flex flex-column gap-2 mt-2">
                    <li>
                      {" "}
                      For salaried individuals: Salary slips for the last 3
                      months, Form 16, and bank statements
                    </li>
                    <li>
                      For self-employed individuals: IT returns, business proof,
                      and bank statements for the last 6 months
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Property Documents:&nbsp;&nbsp;
                  </span>
                  Sale agreement, property tax receipts, and NOC from the
                  builder or society
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Other Documents:&nbsp;&nbsp;
                  </span>
                  Passport-size photographs, processing fee cheque, and
                  application form
                </div>
              </div>
            </div>
          </div>
          <div id="improve-eligibility">
            <div
              style={{
                marginTop: 25,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              How to Improve Your Eligibility for AXIS Bank Home Loan
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Boost Your Credit Score:&nbsp;&nbsp;
                  </span>
                  Pay off existing debts and ensure timely repayments to
                  maintain a score above 750.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Increase Down Payment:&nbsp;&nbsp;
                  </span>
                  A larger down payment reduces the loan amount and improves
                  approval chances.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Reduce Existing Liabilities:&nbsp;&nbsp;
                  </span>
                  Lower your debt-to-income ratio by clearing other loans or
                  credit card dues.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Choose Longer Tenures:&nbsp;&nbsp;
                  </span>
                  Opting for a longer tenure lowers your EMIs and enhances
                  affordability.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Maintain Stable Employment:&nbsp;&nbsp;
                  </span>
                  A steady job or business profile builds confidence in your
                  repayment capacity.
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Frequently asked questions (FAQs) about AXIS Home Loan Eligibility:
          </div>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What is the minimum income required for an Axis Bank Home Loan?
              </AccordionSummary>
              <AccordionDetails>
                Salaried applicants should have a minimum monthly income of
                ₹15,000, while self-employed individuals must demonstrate
                sufficient business income.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{ fontWeight: 600, borderRadius: 12 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Can I apply for a joint home loan with Axis Bank?
              </AccordionSummary>
              <AccordionDetails>
                Yes, you can apply jointly with co-applicants such as your
                spouse or immediate family members to improve eligibility.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{ fontWeight: 600, borderRadius: 12 }}
              >
                Does Axis Bank offer home loans for under-construction
                properties?
              </AccordionSummary>
              <AccordionDetails>
                Yes, Axis Bank finances under-construction, ready-to-move,
                resale properties, and self-construction projects.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{ fontWeight: 600, borderRadius: 12 }}
              >
                What factors affect my home loan eligibility with Axis Bank?
              </AccordionSummary>
              <AccordionDetails>
                Key factors include credit score, age, income stability,
                debt-to-income ratio, and property valuation.
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br /> and tailored just for you—apply now to see what you
                qualify for!
              </p>
              <a
                href="https://minemi.ai/home-loan/check-eligibility"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                  backgroundColor: "transparent",
                  marginTop: "2%",
                  cursor: "pointer",
                }}
              >
                <div
                  className="buttom-loan-eligiblity-one"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 40,
                    paddingRight: 40,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                   Check Your Eligibility Now
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeLoanAxisEligibility;

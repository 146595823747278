import React, { useEffect, useMemo, useState } from "react";
import { ChevronDown } from "lucide-react";

const Faq = ({ name }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [faqs, setFaqs] = useState([]);

  const kotakData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about Kotak Bank personal loans",
        question:
          "How long does it take to get a Bank of Baroda personal loan approved?",
        answer:
          "Typically, Bank of Baroda takes 5-7 working days to process and approve a personal loan application. However, this timeline can vary based on your profile, documentation, and verification process. Using MinEMI's tools can help streamline the process and potentially reduce the approval time.",
      },
      {
        question:
          "What are the common reasons for Bank of Baroda personal loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect documentation, unstable employment history, and insufficient income. MinEMI's Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting a Bank of Baroda personal loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your credit utilization below 30%, ensure all existing loans are paid on time, provide complete and accurate documentation, and apply for a loan amount that aligns with your income. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
      {
        question:
          "Can I check my Bank of Baroda personal loan status without visiting the branch?",
        answer:
          "Yes, you can check your loan status through multiple channels without visiting a branch: Bank of Baroda's NetBanking portal, Baroda M-Connect Plus mobile app, customer care helpline (1800-258-4455), or using MinEMI's Loan Status Tracker on this page.",
      },
    ],
    []
  );
  const bobData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about Bank of Baroda Bank personal loans",
        question:
          "How long does it take to get a Bank of Baroda personal loan approved?",
        answer:
          "Typically, Bank of Baroda takes 5-7 working days to process and approve a personal loan application. However, this timeline can vary based on your profile, documentation, and verification process. Using MinEMI's tools can help streamline the process and potentially reduce the approval time.",
      },
      {
        question:
          "What are the common reasons for Bank of Baroda personal loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect documentation, unstable employment history, and insufficient income. MinEMI's Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting a Bank of Baroda personal loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your credit utilization below 30%, ensure all existing loans are paid on time, provide complete and accurate documentation, and apply for a loan amount that aligns with your income. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
      {
        question:
          "Can I check my Bank of Baroda personal loan status without visiting the branch?",
        answer:
          "Yes, you can check your loan status through multiple channels without visiting a branch: Bank of Baroda's NetBanking portal, Baroda M-Connect Plus mobile app, customer care helpline (1800-258-4455), or using MinEMI's Loan Status Tracker on this page.",
      },
    ],
    []
  );
  const idfcData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about IDFC FIRST Bank personal loans",
        question:
          "What is the typical processing time for an IDFC FIRST Bank personal loan?",
        answer:
          "IDFC FIRST Bank typically processes personal loans in 2-4 business days for salaried individuals with complete documentation. For self-employed applicants, it may take 3-5 business days. IDFC FIRST Bank's digital loan process can expedite approvals, with some existing customers receiving same-day approval and disbursement through the FIRST Preferred program. Digital verification and e-signing can significantly reduce processing time. MinEMI's tools can help ensure your application is complete and optimized for faster approval.",
      },
      {
        question:
          "What are IDFC FIRST Bank's specific eligibility criteria for personal loans?",
        answer:
          "IDFC FIRST Bank requires: (1) Age between 23-58 years, (2) Minimum monthly income of ₹20,000 for salaried and ₹25,000 for self-employed, (3) Credit score of 700+ (though 750+ is preferred for better rates), (4) Minimum 2 years of employment with at least 1 year in current job for salaried applicants, (5) 3+ years in current business for self-employed, and (6) Indian residency. IDFC FIRST Bank also considers your banking relationship, with preferential terms for existing customers with good transaction history.",
      },
      {
        question:
          "How can I improve my chances of getting an IDFC FIRST Bank personal loan approved?",
        answer:
          "To maximize your IDFC FIRST Bank approval chances: (1) Maintain a credit score above 750, (2) Keep your fixed obligation to income ratio (FOIR) below 50%, (3) Clear any existing credit card dues or overdue payments, (4) Provide complete and accurate documentation including latest salary slips and bank statements, (5) Apply for a loan amount that aligns with your income (typically 10-15 times monthly income), (6) Consider opening a savings account with IDFC FIRST Bank before applying, and (7) Use MinEMI's IDFC FIRST Bank Approval Predictor to identify and address potential issues before applying.",
      },
      {
        question:
          "Can I check my Bank of Baroda personal loan status without visiting the branch?",
        answer:
          "Yes, you can check your loan status through multiple channels without visiting a branch: Bank of Baroda's NetBanking portal, Baroda M-Connect Plus mobile app, customer care helpline (1800-258-4455), or using MinEMI's Loan Status Tracker on this page.",
      },
    ],
    []
  );
  const hdfcData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about HDFC Bank personal loans",
        question:
          "How long does it take to get a HDFC Bank personal loan approverd?",
        answer:
          "Typically, HDFC Bank takes 3-5 working days to process and approve a personal loan application. For pre-approved customers, it can be as quick as 24 hours. However, this timeline can vary based on your profile, documentation, and verification process. Using MinEMI's tools can help streamline the process and potentially reduce the approval time.",
      },
      {
        question:
          "What are the common reasons for HDFC Bank personal loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect documentation, unstable employment history, and insufficient income. MinEMI's Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an HDFC Bank personal loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your credit utilization below 30%, ensure all existing loans are paid on time, provide complete and accurate documentation, and apply for a loan amount that aligns with your income. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
      // {
      //   question:
      //     "Can I check my Bank of Baroda personal loan status without visiting the branch?",
      //   answer:
      //     "Yes, you can check your loan status through multiple channels without visiting a branch: Bank of Baroda's NetBanking portal, Baroda M-Connect Plus mobile app, customer care helpline (1800-258-4455), or using MinEMI's Loan Status Tracker on this page.",
      // },
    ],
    []
  );
  const sbiData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about SBI Bank personal loans",
        question:
          "How long does it take to get a State Bank of Indian personal loan approverd?",
        answer:
          "Typically, SBI takes 7-10 working days to process and approve a personal loan application. For pre-approved customers or existing SBI account holders, it can be faster. However, this timeline can vary based on your profile, documentation, and verification process. Using MinEMI's tools can help streamline the process and potentially reduce the approval time.",
      },
      {
        question:
          "What are the common reasons for SBI personal loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect documentation, unstable employment history, and insufficient income. MinEMI's Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an SBI personal loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your credit utilization below 30%, ensure all existing loans are paid on time, provide complete and accurate documentation, and apply for a loan amount that aligns with your income. Having an existing relationship with SBI can also improve your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
      //
    ],
    []
  );
  const axisData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about Axis Bank personal loans",
        question:
          "How long does it take to get an Axis Bank personal loan approverd?",
        answer:
          "Typically, Axis Bank takes 3-5 working days to process and approve a personal loan application. For pre-approved customers or existing Axis Bank account holders, it can be as quick as 24 hours. However, this timeline can vary based on your profile, documentation, and verification process. Using MinEMI's tools can help streamline the process and potentially reduce the approval time.",
      },
      {
        question:
          "What are the common reasons for Axis personal loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect documentation, unstable employment history, and insufficient income. MinEMI's Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an Axis personal loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your credit utilization below 30%, ensure all existing loans are paid on time, provide complete and accurate documentation, and apply for a loan amount that aligns with your income. Having an existing relationship with Axis Bank can also improve your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const iciciData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about ICICI Bank personal loans",
        question:
          "How long does it take to get an ICICI Bank personal loan approved?",
        answer:
          "Typically, ICICI Bank takes 2-4 working days to process and approve a personal loan application. For pre-approved customers or existing ICICI Bank account holders, it can be as quick as 24 hours. However, this timeline can vary based on your profile, documentation, and verification process. Using MinEMI's tools can help streamline the process and potentially reduce the approval time.",
      },
      {
        question:
          "What are the common reasons for ICICI Bank personal loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect documentation, unstable employment history, and insufficient income. MinEMI's Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an ICICI Bank personal loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your credit utilization below 30%, ensure all existing loans are paid on time, provide complete and accurate documentation, and apply for a loan amount that aligns with your income. Having an existing relationship with ICICI Bank can also improve your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const punjabData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about Punjab National Bank personal loans",
        question:
          "How long does it take to get a Punjab National Bank personal loan approved?",
        answer:
          "Typically, Punjab National Bank takes 4-7 working days to process and approve a personal loan application. For pre-approved customers or existing PNB account holders, it can be as quick as 2-3 days. However, this timeline can vary based on your profile, documentation, and verification process. Using MinEMI's tools can help streamline the process and potentially reduce the approval time.",
      },
      {
        question:
          "What are the common reasons for Punjab National Bank personal loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect documentation, unstable employment history, and insufficient income. MinEMI's Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting a Punjab National Bank personal loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your credit utilization below 30%, ensure all existing loans are paid on time, provide complete and accurate documentation, and apply for a loan amount that aligns with your income. Having an existing relationship with and apply for a loan amount that aligns with your income. Having an existing relationship with Punjab National Bank can also improve your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const induslndData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about IndusInd Bank personal loans",
        question:
          "How long does it take to get an IndusInd Bank personal loan approved?",
        answer:
          "Typically, IndusInd Bank takes 2-4 working days to process and approve a personal loan application. For pre-approved customers or existing IndusInd Bank account holders, it can be as quick as 24 hours. Their 'Quick Approval' loans for premium customers can be approved within hours. However, this timeline can vary based on your profile, documentation, and verification process. Using MinEMI's tools can help streamline the process and potentially reduce the approval time.",
      },
      {
        question:
          "What are the common reasons for IndusInd Bank personal loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect documentation, unstable employment history, and insufficient income. IndusInd Bank also places significant emphasis on your banking relationship and repayment history. MinEMI's Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an IndusInd Bank personal loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your credit utilization below 30%, ensure all existing loans are paid on time, provide complete and accurate documentation, and apply for a loan amount that aligns with your income. Having an existing relationship with IndusInd Bank can significantly improve your chances. IndusInd Bank values customers with salary accounts or fixed deposits. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const tataData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about Tata Capital personal loans",
        question:
          "How long does it take to get a Tata Capital personal loan approved?",
        answer:
          "Typically, Tata Capital takes 3-5 working days to process and approve a personal loan application. For their Express Loan service, approvals can happen in as little as 24 hours. For existing Tata Capital customers, the process is often faster. Digital applications through the Tata Capital Mobile App or website are processed more quickly than paper applications. Using MinEMI's tools can help streamline the process and potentially reduce the approval time.",
      },
      {
        question:
          "What are the common reasons for Tata Capital personal loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect documentation, unstable employment history, and insufficient income. Tata Capital also evaluates your debt-to-income ratio carefully, and applications with ratios above 50% are often rejected. MinEMI's Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting a Tata Capital personal loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your credit utilization below 30%, ensure all existing loans are paid on time, provide complete and accurate documentation, and apply for a loan amount that aligns with your income. Having an existing relationship with Tata Capital through other financial products can also improve your chances. Tata Capital particularly values customers with good repayment history. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for..",
      },
    ],
    []
  );
  const yesData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about Yes Bank personal loans",
        question:
          "How long does it take to get a Yes Bank personal loan approved?",
        answer:
          "Typically, Yes Bank takes 2-4 working days to process and approve a personal loan application. For pre-approved customers or existing Yes Bank account holders, it can be as quick as 24-48 hours. However, this timeline can vary based on your profile, documentation, and verification process. Using MinEMI's tools can help streamline the process and potentially reduce the approval time.",
      },
      {
        question:
          "What are the common reasons for Yes Bank personal loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect documentation, unstable employment history, and insufficient income. MinEMI's Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting a Yes Bank personal loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your credit utilization below 30%, ensure all existing loans are paid on time, provide complete and accurate documentation, and apply for a loan amount that aligns with your income. Having an existing relationship with Yes Bank can also improve your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const unionData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about Union Bank personal loans",
        question:
          "How long does it take to get a Union Bank personal loan approved?",
        answer:
          "Typically, Union Bank takes 4-7 working days to process and approve a personal loan application. For existing Union Bank customers with good credit history, it can be expedited to 2-3 days. However, this timeline can vary based on your profile, documentation, and verification process. Using MinEMI's tools can help streamline the process and potentially reduce the approval time.",
      },
      {
        question:
          "What are the common reasons for Union Bank personal loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect documentation, unstable employment history, and insufficient income. MinEMI's Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting a Union Bank personal loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 730, keep your credit utilization below 40%, ensure all existing loans are paid on time, provide complete and accurate documentation, and apply for a loan amount that aligns with your income. Having an existing relationship with Union Bank can also improve your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const bajajData = useMemo(
    () => [
      {
        h2: "Common questions about Bajaj Finance personal loans",
        question:
          "How quickly can I get a Bajaj Finance personal loan approved?",
        answer:
          "Bajaj Finance is known for its quick approval process. For pre-qualified customers using the Bajaj Finserv app, approvals can happen in as little as 5 minutes. For new customers, the typical approval time ranges from a few hours to 2 days. Disbursals usually happen within 24 hours of final approval. Using MinEMI's tools before applying can help streamline the process by ensuring you meet all eligibility criteria.",
      },
      {
        question:
          "What might cause Bajaj Finance to reject my personal loan application?",
        answer:
          "Common rejection reasons include: low credit score (below 700), high debt-to-income ratio (above 50%), unstable employment history (less than 1 year at current job), incomplete or inconsistent documentation, recent credit defaults or late payments, and applying for an amount beyond your repayment capacity. MinEMI's Approval Analyzer can help identify potential issues before you apply.",
      },
      {
        question:
          "How can I improve my chances of getting approved by Bajaj Finance?",
        answer:
          "To maximize approval chances: maintain a credit score above 750, keep credit card utilization below 30%, ensure all existing loans are paid on time, have at least 2 years of stable employment, provide complete and accurate documentation, and apply for a loan amount that's reasonable for your income (typically not exceeding 15-18 times your monthly income). MinEMI's Eligibility Calculator can help determine your optimal loan amount.",
      },
    ],
    []
  );
  const federalData = useMemo(
    () => [
      {
        h2: "Common questions about Federal Bank personal loans",
        question:
          "How long does it take to get a Federal Bank personal loan approved?",
        answer:
          "Federal Bank typically processes personal loan applications within 3-5 working days. For existing customers with good credit history, approvals can be faster, sometimes within 48 hours. The complete process from application to disbursement usually takes 5-7 working days. Using MinEMI's tools before applying can help streamline the process by ensuring you meet all eligibility criteria and have all required documentation ready.",
      },
      {
        question:
          "What are the common reasons for Federal Bank personal loan rejection?",
        answer:
          "Common rejection reasons include: low credit score (below 700), high debt-to-income ratio (above 50%), unstable employment history (less than 2 years), incomplete or inconsistent documentation, recent credit defaults or late payments, and applying for an amount beyond your repayment capacity. Federal Bank also considers your relationship with the bank, giving preference to existing customers. MinEMI's Approval Analyzer can help identify potential issues before you apply.",
      },
      {
        question:
          "How can I improve my chances of getting a Federal Bank personal loan approved?",
        answer:
          "To maximize approval chances: maintain a credit score above 750, keep credit card utilization below 30%, ensure all existing loans are paid on time, have at least 2 years of stable employment, provide complete and accurate documentation, and apply for a loan amount that's reasonable for your income. Having an existing relationship with Federal Bank (such as a savings account or fixed deposit) can significantly improve your chances. MinEMI's Eligibility Calculator can help determine your optimal loan amount.",
      },
    ],
    []
  );
  const canaraData = useMemo(
    () => [
      {
        h2: "Common questions about Canara Bank personal loans",
        question:
          "How long does it take to get a Canara Bank personal loan approved?",
        answer:
          "Canara Bank typically processes personal loan applications within 5-7 working days. For existing customers with good credit history and salary accounts, approvals can be faster, sometimes within 3-4 days. The complete process from application to disbursement usually takes 7-10 working days. Using MinEMI's tools before applying can help streamline the process by ensuring you meet all eligibility criteria and have all required documentation ready..",
      },
      {
        question:
          "What are the common reasons for Canara Bank personal loan rejection?",
        answer:
          "Common rejection reasons include: low credit score (below 700), high debt-to-income ratio (above 50%), unstable employment history (less than 2 years), incomplete or inconsistent documentation, recent credit defaults or late payments, and applying for an amount beyond your repayment capacity. Canara Bank also considers your relationship with the bank, giving preference to existing customers. MinEMI's Approval Analyzer can help identify potential issues before you apply.",
      },
      {
        question:
          "How can I improve my chances of getting a Canara Bank personal loan approved?",
        answer:
          "to maximize approval chances: maintain a credit score above 750, keep credit card utilization below 30%, ensure all existing loans are paid on time, have at least 2 years of stable employment, provide complete and accurate documentation, and apply for a loan amount that's reasonable for your income. Having an existing relationship with Canara Bank (such as a savings account or fixed deposit) can significantly improve your chances. Government employees and defense personnel have higher approval rates. MinEMI's Eligibility Calculator can help determine your optimal loan amount.",
      },
    ],
    []
  );
  const boiData = useMemo(
    () => [
      {
        h2: "Common questions about Bank of India personal loans",
        question:
          "How long does it take to get a Bank of India personal loan approved?",
        answer:
          "Bank of India typically processes personal loan applications within 6-8 working days. For existing customers with good credit history and Star Salary accounts, approvals can be faster, sometimes within 4-5 days. The complete process from application to disbursement usually takes 8-12 working days. Using MinEMI's tools before applying can help streamline the process by ensuring you meet all eligibility criteria and have all required documentation ready.",
      },
      {
        question:
          "What are the common reasons for Bank of India personal loan rejection?",
        answer:
          "Common rejection reasons include: low credit score (below 700), high debt-to-income ratio (above 50%), unstable employment history (less than 2 years), incomplete or inconsistent documentation, recent credit defaults or late payments, and applying for an amount beyond your repayment capacity. Bank of India also considers your relationship with the bank, giving preference to existing customers. MinEMI's Approval Analyzer can help identify potential issues before you apply.",
      },
      {
        question:
          "How can I improve my chances of getting a Bank of India personal loan approved?",
        answer:
          "To maximize approval chances: maintain a credit score above 750, keep credit card utilization below 30%, ensure all existing loans are paid on time, have at least 2 years of stable employment, provide complete and accurate documentation, and apply for a loan amount that's reasonable for your income. Having an existing relationship with Bank of India (such as a savings account or fixed deposit) can significantly improve your chances. Government employees and defense personnel have higher approval rates. MinEMI's Eligibility Calculator can help determine your optimal loan amount.",
      },
    ],
    []
  );
  const rblData = useMemo(
    () => [
      {
        h2: "Common questions about RBL Bank personal loans",
        question:
          "How long does it take to get an RBL Bank personal loan approved?",
        answer:
          "RBL Bank typically processes personal loan applications within 3-5 working days. For existing customers with good credit history and salary accounts or credit cards, approvals can be faster, sometimes within 24-48 hours through their Express Personal Loan program. The complete process from application to disbursement usually takes 4-7 working days. Using MinEMI's tools before applying can help streamline the process by ensuring you meet all eligibility criteria and have all required documentation ready.",
      },
      {
        question:
          "What are the common reasons for RBL Bank personal loan rejection?",
        answer:
          "Common rejection reasons include: low credit score (below 700), high debt-to-income ratio (above 45%), unstable employment history (less than 1 year), incomplete or inconsistent documentation, recent credit defaults or late payments, and applying for an amount beyond your repayment capacity. RBL Bank also considers your digital footprint and social media presence during verification. MinEMI's Approval Analyzer can help identify potential issues before you apply.",
      },
      {
        question:
          "How can I improve my chances of getting an RBL Bank personal loan approved?",
        answer:
          "To maximize approval chances: maintain a credit score above 750, keep credit card utilization below 30%, ensure all existing loans are paid on time, have at least 1 year of stable employment, provide complete and accurate documentation, and apply for a loan amount that's reasonable for your income. Having an existing relationship with RBL Bank (such as a savings account or credit card) can significantly improve your chances. MinEMI's Eligibility Calculator can help determine your optimal loan amount.",
      },
    ],
    []
  );

  useEffect(() => {
    if (name === "checkkotakbankstatus") {
      setFaqs(kotakData);
    } else if (name === "checkbobbankstatus") {
      setFaqs(bobData);
    } else if (name === "checkidfcbankstatus") {
      setFaqs(idfcData);
    } else if (name === "checkhdfcbankstatus") {
      setFaqs(hdfcData);
    } else if (name === "checksbibankstatus") {
      setFaqs(sbiData);
    } else if (name === "checkaxisbankstatus") {
      setFaqs(axisData);
    } else if (name === "checkicicibankstatus") {
      setFaqs(iciciData);
    } else if (name === "checkpunjabbankstatus") {
      setFaqs(punjabData);
    } else if (name === "checkinduslndbankstatus") {
      setFaqs(induslndData);
    } else if (name === "checktatabankstatus") {
      setFaqs(tataData);
    } else if (name === "checkyesbankstatus") {
      setFaqs(yesData);
    } else if (name === "checkunionbankstatus") {
      setFaqs(unionData);
    } else if (name === "checkbajajbankstatus") {
      setFaqs(bajajData);
    } else if (name === "checkfederalbankstatus") {
      setFaqs(federalData);
    } else if (name === "checkcanarabankstatus") {
      setFaqs(canaraData);
    } else if (name === "checkboibankstatus") {
      setFaqs(boiData);
    } else if (name === "checkrblbankstatus") {
      setFaqs(rblData);
    } else {
      setFaqs([]);
    }
  }, [
    name,
    kotakData,
    bobData,
    idfcData,
    hdfcData,
    sbiData,
    axisData,
    iciciData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
  ]);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="py-4  border-t border-light-bg">
      <div className="container px-4 bg-transparent">
        <div className="w-4/5l mx-auto bg-transparent">
          <div className="text-center mb-4 bg-transparent">
            <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple">
              Frequently Asked Questions
            </h2>
            {faqs.length > 0 && (
              <p className="text-dark-grey mt-0 text-sm bg-transparent text-center">
                {faqs[0].h2} {/* Displaying dynamic h2 */}
              </p>
            )}
            {/* <p className="text-dark-grey mt-1 text-sm bg-transparent text-center">
              Get answers to common questions about personal loans
            </p> */}
          </div>
          <div className="space-y-2 bg-transparent">
            {faqs.length > 0 &&
              faqs.slice(0, 3).map((faq, index) => (
                <div
                  key={index}
                  className="border border-light-bg rounded-lg overflow-hidden"
                >
                  <button
                    className="flex items-center justify-between w-full p-3 text-left bg-white hover:bg-light-bg transition-colors"
                    onClick={() => toggleFaq(index)}
                    aria-expanded={openIndex === index}
                  >
                    <h3 className="font-medium text-primary-purple text-xs ">
                      {faq.question}
                    </h3>
                    <ChevronDown
                      className={`h-4 w-4 text-primary-purple transition-transform bg-transparent ${
                        openIndex === index ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                  {openIndex === index && (
                    <div className="p-3 border-t border-light-bg bg-[#ebe8fb]">
                      <p className="text-dark-grey text-xs bg-[#ebe8fb]">
                        {faq.answer}
                      </p>
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className="mt-3 text-center bg-transparent">
            <a
              href="/faq"
              className="inline-flex h-9 items-center justify-center rounded-md bg-teal px-4 text-xs font-medium text-white transition-colors bg-[#3c868b] hover:bg-[purple]"
            >
              View All FAQs
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;

import { useEffect, useState, useMemo } from "react";

export function TrustIndicators({ name }) {
  const [data, setData] = useState({});

  const kotakData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of home buyers across India for mortgage guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of home buyers across India for mortgage guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const idfcData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of home buyers across India for mortgage guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of home buyers across India for mortgage guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of home buyers across India for mortgage guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Stor",
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of home buyers across India for mortgage guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of home buyers across India for mortgage guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls..",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of home buyers across India for mortgage guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of home buyers across India for mortgage guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of home buyers across India for mortgage guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      h1: "Why Trust MinEMI for Your YES Bank Home Loan?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with military-grade encryption and complies with all banking security standards.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "YES Bank Home Loan Specialists",
          description:
            "Our team includes former YES Bank mortgage officers who understand the bank's internal approval process.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Proven Results with YES Bank",
      rating: "4.9/5",
      content:
        "We've helped over 5,800 customers successfully secure YES Bank home loans with a 93% success rate",
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      h1: "Why Trust MinEMI for Your Union Bank Home Loan?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with military-grade encryption and complies with all banking security standards.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "Union Bank Home Loan Specialists",
          description:
            "Our team includes former Union Bank mortgage officers who understand the bank's internal approval process.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Proven Results with Union Bank",
      rating: "4.8/5",
      content:
        "We've helped over 4,500 customers successfully secure Union Bank home loans with a 92% success rate",
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with military-grade encryption and complies with all banking security standards.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "Bajaj Finance Home Loan Specialists",
          description:
            "Our team includes former Bajaj Finance mortgage officers who understand the company's internal approval process.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Proven Results with Bajaj Finance",
      rating: "4.9/5",
      content:
        "We've helped over 6,200 customers successfully secure Bajaj Finance home loans with a 94% success rate",
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with military-grade encryption and complies with all banking security standards.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "Federal Bank Home Loan Specialists",
          description:
            "Our team includes former Federal Bank mortgage officers who understand the bank's internal approval process.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Proven Results with Federal Bank",
      rating: "4.8/5",
      content:
        "We've helped over 3,800 customers successfully secure Federal Bank home loans with a 93% success rate",
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with military-grade encryption and complies with all banking security standards.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "Canara Bank Home Loan Specialists",
          description:
            "Our team includes former Canara Bank mortgage officers who understand the bank's internal approval process.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Proven Results with Canara Bank",
      rating: "4.8/5",
      content:
        "We've helped over 4,200 customers successfully secure Canara Bank home loans with a 92% success rate",
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with military-grade encryption and complies with all banking security standards.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "Bank of India Home Loan Specialists",
          description:
            "Our team includes former Bank of India mortgage officers who understand the bank's internal approval process.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Proven Results with Bank of India",
      rating: "4.8/5",
      content:
        "We've helped over 3,900 customers successfully secure Bank of India home loans with a 94% success rate",
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with military-grade encryption and complies with all banking security standards.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "RBL Bank Home Loan Specialists",
          description:
            "Our team includes former RBL Bank mortgage officers who understand the bank's internal approval process.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Proven Results with RBL Bank",
      rating: "4.9/5",
      content:
        "We've helped over 2,800 customers successfully secure RBL Bank home loans with a 95% success rate",
    }),
    []
  );
  const ltData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with military-grade encryption and complies with all banking security standards.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "L&T Bank Home Loan Specialists",
          description:
            "Our team includes former L&T Bank mortgage officers who understand the bank's internal approval process.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Proven Results with L&T Bank",
      rating: "4.9/5",
      content:
        "We've helped over 2,800 customers successfully secure L&T Bank home loans with a 95% success rate",
    }),
    []
  );
  const idbiData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with military-grade encryption and complies with all banking security standards.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "IDBI Bank Home Loan Specialists",
          description:
            "Our team includes former IDBI Bank mortgage officers who understand the bank's internal approval process.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Proven Results with IDBI Bank",
      rating: "4.9/5",
      content:
        "We've helped over 2,800 customers successfully secure IDBI Bank home loans with a 95% success rate",
    }),
    []
  );
  const lichousingData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with military-grade encryption and complies with all banking security standards.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "LIC Housing Finance Bank Home Loan Specialists",
          description:
            "Our team includes former LIC Housing FinanceBank mortgage officers who understand the bank's internal approval process.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Proven Results with LIC Housing Finance Bank",
      rating: "4.9/5",
      content:
        "We've helped over 2,800 customers successfully secure LIC Housing Finance Bank home loans with a 95% success rate",
    }),
    []
  );
  const icicihousingData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with military-grade encryption and complies with all banking security standards.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "ICICI Home Finance Bank Home Loan Specialists",
          description:
            "Our team includes former ICICI Home FinanceBank mortgage officers who understand the bank's internal approval process.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Proven Results with ICICI Home Finance Bank",
      rating: "4.9/5",
      content:
        "We've helped over 2,800 customers successfully secure ICICI Home Finance Bank home loans with a 95% success rate",
    }),
    []
  );
  const pnbhousingData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with military-grade encryption and complies with all banking security standards.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "PNB Home Finance Bank Home Loan Specialists",
          description:
            "Our team includes former PNB Home FinanceBank mortgage officers who understand the bank's internal approval process.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Proven Results with PNB Home Finance Bank",
      rating: "4.9/5",
      content:
        "We've helped over 2,800 customers successfully secure PNB Home Finance Bank home loans with a 95% success rate",
    }),
    []
  );
  const hdfclnthousingData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Enterprise-Grade Security",
          description:
            "Your information is protected with advanced encryption and strict privacy protocols.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "80,000+ Satisfied Users",
          description:
            "Trusted by thousands of home buyers nationwide for reliable loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "User Satisfaction",
      rating: "4.9/5",
      content:
        "Based on 9,500+ verified reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const sbihomehousingData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Enterprise-Grade Security",
          description:
            "Your information is protected with advanced encryption and strict privacy protocols.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "80,000+ Satisfied Users",
          description:
            "Trusted by thousands of home buyers nationwide for reliable loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "User Satisfaction",
      rating: "4.9/5",
      content:
        "Based on 10,200+ verified reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const adityahomehousingData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Enterprise-Grade Security",
          description:
            "Your information is protected with advanced encryption and strict privacy protocols.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "80,000+ Satisfied Users",
          description:
            "Trusted by thousands of home buyers nationwide for reliable loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "User Satisfaction",
      rating: "4.9/5",
      content:
        "Based on 10,200+ verified reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );

  useEffect(() => {
    if (name === "checkkotakhomeloanbankstatus") {
      setData(kotakData);
    } else if (name === "checkbobhomeloanbankstatus") {
      setData(bobData);
    } else if (name === "checkidfchomeloanbankstatus") {
      setData(idfcData);
    } else if (name === "checkhdfchomeloanbankstatus") {
      setData(hdfcData);
    } else if (name === "checksbihomeloanbankstatus") {
      setData(sbiData);
    } else if (name === "checkaxishomeloanbankstatus") {
      setData(axisData);
    } else if (name === "checkicicihomeloanbankstatus") {
      setData(iciciData);
    } else if (name === "checkpunjabhomeloanbankstatus") {
      setData(punjabData);
    } else if (name === "checkinduslndhomeloanbankstatus") {
      setData(induslndData);
    } else if (name === "checktatahomeloanbankstatus") {
      setData(tataData);
    } else if (name === "checkyeshomeloanbankstatus") {
      setData(yesData);
    } else if (name === "checkunionhomeloanbankstatus") {
      setData(unionData);
    } else if (name === "checkbajajhomeloanbankstatus") {
      setData(bajajData);
    } else if (name === "checkfederalhomeloanbankstatus") {
      setData(federalData);
    } else if (name === "checkcanarahomeloanbankstatus") {
      setData(canaraData);
    } else if (name === "checkboihomeloanbankstatus") {
      setData(boiData);
    } else if (name === "checkrblhomeloanbankstatus") {
      setData(rblData);
    } else if (name === "checklthomeloanbankstatus") {
      setData(ltData);
    } else if (name === "checkidbihomeloanbankstatus") {
      setData(idbiData);
    } else if (name === "checklichousinghomeloanbankstatus") {
      setData(lichousingData);
    } else if (name === "checkicicihousinghomeloanbankstatus") {
      setData(icicihousingData);
    } else if (name === "checkpnbhousinghomeloanbankstatus") {
      setData(pnbhousingData);
    } else if (name === "checkhdfcltdhomeloanbankstatus") {
      setData(hdfclnthousingData);
    } else if (name === "checksbihousinghomeloanbankstatus") {
      setData(sbihomehousingData);
    } else if (name === "checkadityahousinghomeloanbankstatus") {
      setData(adityahomehousingData);
    } else {
      setData({});
    }
  }, [
    name,
    kotakData,
    bobData,
    idfcData,
    hdfcData,
    sbiData,
    axisData,
    iciciData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
    ltData,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    hdfclnthousingData,
    sbihomehousingData,
    adityahomehousingData,
  ]);

  return (
    <section className="py-10 border-b border-gray-200 bg-[#ebe8fb]">
      <div className="container px-4 md:px-6 bg-transparent">
        <div className="w-4/5l mx-auto bg-transparent">
          <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple mb-6 text-center">
            {data.h1}
          </h2>
          <div className="grid gap-3 bg-transparent">
            {data.step?.map((d, index) => (
              <div
                key={index}
                className="flex items-start gap-4 bg-white p-4 rounded-lg shadow-sm border border-gray-200"
              >
                <div className="h-10 w-10 rounded-full bg-[#ebe8fb] flex items-center justify-center flex-shrink-0">
                  {d.icon}
                </div>
                <div className="bg-transparent">
                  <h3 className="font-bold text-primary-purple text-base mb-1">
                    {d.title}
                  </h3>
                  <p className="text-sm text-dark-grey bg-transparent">
                    {d.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-3">
            <div className="bg-white p-3 rounded-lg shadow-sm border border-gray-200">
              <h3 className="text-base font-bold text-primary-purple mb-3 text-center">
                {data.h3}
              </h3>
              <div className="flex items-center justify-center gap-3 mb-3 bg-transparent">
                <div className="flex bg-transparent">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <svg
                      key={star}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="#FFD700"
                      stroke="#FFD700"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="mr-1 bg-transparent"
                      aria-label={`Star ${star}`}
                    >
                      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                    </svg>
                  ))}
                </div>
                <span className="font-bold text-primary-purple bg-transparent">
                  {data.rating}
                </span>
              </div>
              <p className="text-dark-grey text-sm text-center bg-transparent">
                {data.content}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React, { useEffect, useState } from "react";
import Eligibility from "../CommonComponents/Eligibility/Index";
import CheckCardAvailability from "./CheckCardAvailability";
import Approval from "../CommonComponents/Approval";
import { useLocation } from "react-router-dom";
import axios from "axios";

const Index = () => {
  const location=useLocation()
  const data= location.state
  const[eligiblity,setEligiblity]=useState([])
  console.log(data)
  
  const EligiblityHandler = async () => {


    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found!");
        return;
      }

      // Convert data to query params instead of FormData
      const params = {
        specificCard: data.specificCard,

        monthlyemi: data.monthlyemi,
        annualIncome: data.annualIncome?.replace(/[, ]/g, "")||0,
      };
      console.log("called")
  

      const response = await axios.get(
        `${process.env.REACT_APP_URL}/creditCardEligibilty`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params, // Pass params here correctly
        }
      );

      setEligiblity(response.data);
    } catch (error) {
      console.error(
        "Failed to fetch loan estimate:",
        error.response?.data || error.message
      );
    }
  };

  useEffect(() => {
    EligiblityHandler();
  }, []);

  return (
    <>
      <Eligibility data={data} />
      <CheckCardAvailability data={{ eligiblity, monthlyemi:data.monthlyemi=="undefined"?0:data.monthlyemi, annualincome: data.annualIncome }} />
      <Approval />
    </>
  );
};

export default Index;

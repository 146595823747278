import React from "react";
import { useMediaQuery } from "@mui/material";

function RightLoan({ overview, loanSummary }) {
  const isMediumScreen = useMediaQuery("(max-width:768px)");
  const isSmallScreen = useMediaQuery("(max-width:576px)");
  return (
    <div className="mx-3 bg-[#f7f5ff] border-[#d8d2ff]  mb-8 rounded-lg border-1">
      <div className="px-2 px-sm-4 pt-4 pb-auto pb-sm-4 bg-transparent">
        <h2
          className="text-xl sm:text-2xl text-[#311664]"
          style={{
            fontSize: isSmallScreen ? "20px" : "24px",
            lineHeight: isSmallScreen ? "28px" : "32px",
            fontWeight: "600",
          }}
        >
          Your Existing Loan Summary
        </h2>
      </div>
      <div className="space-y-4 px-2 py-3 p-sm-4 sm:p-6 pb-6 pb-sm-5 bg-transparent">
        <div
          className="d-flex flex-column gap-2"
          style={{
            width: isSmallScreen ? "100%" : isMediumScreen ? "90%" : "100%",
            backgroundColor: "transparent",
          }}
        >
          <div className="bg-transparent d-flex flex-row gap-4">
            <p
              className="bg-transparent text-start"
              style={{
                fontSize: isMediumScreen ? "14px" : "16px",
                lineHeight: isMediumScreen ? "18px" : "20px",

                backgroundColor: "transparent",
                alignSelf: "start",
              }}
            >
              <strong
                style={{ backgroundColor: "transparent", alignSelf: "start" }}
              >
                Total Active Loans:
              </strong>{" "}
              {loanSummary?.loanSummary?.AllowedRunningLoan}
            </p>
            <p
              className="bg-transparent text-start m-0"
              style={{
                fontSize: isMediumScreen ? "14px" : "16px",
                lineHeight: isMediumScreen ? "18px" : "20px",
                backgroundColor: "transparent",
                alignSelf: "start",
              }}
            >
              <strong style={{ backgroundColor: "transparent" }}>
                Outstanding:
              </strong>{" "}
              {loanSummary?.loanSummary?.AllLoanCurrentBalance
                ? new Intl.NumberFormat("en-IN", {
                    style: "currency",
                    currency: "INR",
                    maximumFractionDigits: 0,
                  }).format(loanSummary.loanSummary.AllLoanCurrentBalance)
                : 0}
            </p>
          </div>
          <div className="space-y-2 bg-transparent">
            <p
              className="bg-transparent text-start text-sm-start m-0"
              style={{
                fontSize: isMediumScreen ? "14px" : "16px",
                lineHeight: isMediumScreen ? "18px" : "20px",
                alignSelf: "start",
                backgroundColor: "transparent",
              }}
            >
              <strong style={{ backgroundColor: "transparent" }}>
                Credit Score:
              </strong>{" "}
              {loanSummary?.cbil?.score}
            </p>

            {/* <p className="bg-transparent" style={{ textAlign: "start" }}>
              <strong>FOIR:</strong> 47% (Moderate)
            </p> */}
          </div>
        </div>

        <div className="overflow-x-auto bg-transparent">
          <table className="w-full text-xs sm:text-sm border-collapse bg-transparent">
            <thead className="bg-transparent text-[gray]">
              <tr className="bg-transparent">
                <th className="text-left p-2 px-0 w-1/4  bg-transparent">
                  Loan Type
                </th>
                <th className="text-left p-2 px-0 w-1/4 bg-transparent">
                  Amount
                </th>
                <th className="text-left p-2 px-0 w-1/4 bg-transparent">
                  Loan Percentage
                  {/* Debt Distribution */}
                </th>
              </tr>
            </thead>
            <tbody className="bg-transparent ">
              {overview?.loanChart?.map((loan, index) => (
                <tr
                  key={index}
                  className="border-t border-[#gray] bg-transparent "
                >
                  <td
                    className="p-2 px-0 bg-transparent"
                    // style={{ textDecoration: "none" }}
                  >
                    {loan.loan_Category
                      .toLowerCase()
                      .replace(/\b\w/g, (char) => char.toUpperCase())}
                  </td>
                  <td className="p-2 px-0 bg-transparent">
                    {loan.loanAmt
                      ? new Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                          maximumFractionDigits: 0,
                        }).format(loan.loanAmt)
                      : "₹0"}
                  </td>
                  <td className="p-2 px-0 bg-transparent">
                    {loan.LoanPercentage}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="bg-[#ebe8fc] p-3 rounded-md text-xs sm:text-sm">
          <p className="font-semibold text-[#311664] mb-1 bg-transparent text-start">
            Action Recommended:
          </p>
          <p className="text-[#26292b] bg-transparent text-start">
            Consider loan consolidation to potentially lower your overall EMI
            and simplify repayments.
          </p>
        </div>
      </div>
    </div>
  );
}

export default RightLoan;

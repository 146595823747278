import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import MetadataContext from "../MetadataContext";

const IciciEligiblity = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const criteriaData = [
    {
      factor: "Income",
      requirement: "₹25,000/month (minimum for salaried individuals)",
      impact:
        "Higher income increases loan eligibility and the amount you can borrow.",
    },
    {
      factor: "Credit Score",
      requirement: "700+",
      impact:
        "A higher score qualifies you for better loan terms and lower interest rates.",
    },
    {
      factor: "Employment Status",
      requirement: "Salaried or self-employed (minimum 2 years)",
      impact:
        "Stable employment improves chances; self-employed individuals may need more documentation.",
    },
    {
      factor: "Fixed Obligations to Income Ratio (FOIR)",
      requirement: "40%-50%",
      impact:
        "A lower FOIR indicates fewer existing EMIs and improves loan eligibility.",
    },
    {
      factor: "Age",
      requirement: "21 to 60 years",
      impact:
        "Younger applicants have a longer tenure but must show steady income.",
    },
  ];

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        " Check Your Personal Loan Eligibility Instantly with ICICI Bank",
      pageDescription:
        "Check your personal loan eligibility instantly with ICICI Bank. Enjoy fast approvals, flexible repayment options, and simple online applications. Apply today!",
      pageKeywords:
        "ICICI Bank Personal Loan, Personal Loan Eligibility, Instant Loan Eligibility Check, ICICI Loan Eligibility Calculator, Personal Loan Online Application, Quick Personal Loan Approval, ICICI Bank Loan Calculator, Check Personal Loan Eligibility, Instant Personal Loan Approval, ICICI Bank Loan Offers, Personal Loan EMI Calculator, Online Loan Eligibility Check, ICICI Instant Loan Services, Best Personal Loan Rates, Loan Eligibility Criteria",
      canonicalUrl:
        "https://minemi.ai/loanpage/personalloan/loan-eligibilty-criteria/icici-loan-eligiblity",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              <Link
                to={"/loanpage/personalloan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Personal Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/personalloan/loan-eligibilty-criteria"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Personal Loan Check Eligibility
              </Link>{" "}
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Check Eligibility with ICICI Bank
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "55px",
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            ICICI Bank Personal Loan Eligibility
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Check your eligibility for an ICICI Bank personal loan. Learn about
            the credit score, income, age, and other criteria to qualify for the
            best loan offers from ICICI.
          </p>
        </div>
        <div
          className=""
          style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}
        >
          <h2
            style={{
              marginTop: 30,
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 24,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Personal Loan Eligibility Criteria
          </h2>
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 500,
              fontSize: 16,
              lineHeight: "20px",
              color: "#1f1f1f",
              marginTop: 15,
            }}
          >
            Explore the key eligibility criteria for securing a personal loan
            from ICICI Bank. Discover how factors such as your age, income,
            credit score, and employment type can impact your ability to qualify
            for a loan with attractive terms and competitive interest rates.
          </p>
          <div
            style={{
              border: "1px solid #D9D9D9",
              borderRadius: 24,
              marginTop: 24,
              backgroundColor: "white",
              overflow: "hidden",
              maxWidth: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
              }}
            >
              <div
                style={{
                  backgroundColor: "#E7E4F9",
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 16,
                  lineHeight: "20px",
                  color: "#59266D",
                  padding: "16px",
                  borderBottom: "1px solid #D9D9D9",
                  borderTopLeftRadius: 24,
                  borderTopRightRadius: 24,
                  textAlign: "center",
                }}
              >
                ICICI Loan Eligibility Criteria
              </div>
            </div>

            <div
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                width: "100%",
                overflow: "auto",
              }}
            >
              <table
                className="table-class"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 12,
                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  textAlign: "left",
                }}
              >
                <thead className="thead-class">
                  <tr>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Eligibility Factor
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Minimum Requirement
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Impact on Loan Eligibility
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {criteriaData.map((row, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      }}
                    >
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        {row.factor}
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {row.requirement}
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {row.impact}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Eligibility Criteria for ICICI Bank Personal Loan{" "}
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            For ICICI Bank Personal Loan eligibility, here are the key criteria
            to consider:{" "}
          </div>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Credit Score:{" "}
                </span>
                A good credit score is essential for loan approval. ICICI Bank
                generally requires a minimum score of 700 for both salaried and
                self-employed applicants. A higher score indicates a strong
                credit history and repayment ability, improving your chances of
                approval{" "}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Income and Employment:{" "}
                </span>
                ICICI Bank looks for stable employment and a steady income.
                Salaried individuals must have a minimum monthly salary of Rs
                30,000, though this can vary depending on other factors.
                Self-employed applicants need to show consistent income and a
                solid business history{" "}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Age:{" "}
                </span>
                Applicants must typically be between 23 to 58 years of age at
                the time of applying, though some exceptions may apply.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Work Experience:{" "}
                </span>
                A minimum of 2 years of work experience is often required for
                salaried employees, while self-employed applicants should show
                at least 3 years of business continuity{" "}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Other Factors:{" "}
                </span>
                The applicant should have a good repayment history and must not
                have any existing defaults or significant financial obligations
                that may affect the ability to repay the loan{" "}
              </div>
            </div>
            <p style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              For more detailed information and to apply, you can visit ICICI
              Bank's official page on personal loan eligibility.{" "}
            </p>
          </div>
          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Documents Required for ICICI Bank Personal Loan{" "}
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            To apply for an ICICI Bank personal loan, the following documents
            are typically required:
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Proof of Identity:{" "}
                </span>
                Passport, Voter ID, Aadhar Card, or Driving License.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Proof of Address:{" "}
                </span>
                Aadhar Card, Passport, Voter ID, or Utility Bill (such as
                electricity or water bill) issued within the last three months.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                   Proof of Income:{" "}
                </div>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "black",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • Salaried Applicants:{" "}
                  </span>{" "}
                  Latest 3 months' salary slips.
                </div>
                <div style={{ paddingLeft: "1%" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                      marginTop: 5,
                    }}
                  >
                    • Self-employed Applicants:
                  </span>{" "}
                  Last 6 months' bank statements, Income Tax Returns (ITR) for
                  the past 2 years, and audited financial statements (Profit &
                  Loss, Balance Sheet).
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Bank Statements:{" "}
                </span>
                Bank statements for the past 3 months showing regular income
                deposits.{" "}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Other Documents (if applicable):{" "}
                </span>
                Recent passport-sized photographs, and in some cases,
                business-related documents for self-employed applicants (e.g.,
                business registration, proof of business continuity).
              </div>
            </div>
            <p style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              For existing ICICI Bank customers, there may be an option to apply
              for an Insta Personal Loan through the bank's iMobile Pay app or
              Internet Banking with minimal documentation if the customer is
              pre-approved.
            </p>
            <p style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              For detailed and the most current document list, you can visit
              ICICI Bank's official personal loan webpage.
            </p>
          </div>

          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            How to Improve Your Eligibility for ICICI Bank Personal Loan{" "}
          </h3>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Boost Your Credit Score:{" "}
                </span>
                A higher credit score improves your chances of approval. Ensure
                timely bill payments and reduce debt.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Increase Your Income:{" "}
                </span>
                For salaried applicants, ensure a monthly income of at least Rs.
                30,000. Self-employed individuals should focus on increasing
                business revenue.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Stable Employment:{" "}
                </span>
                Ensure at least two years of employment (for salaried) or 3-5
                years of business continuity (for self-employed).
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Maintain a Stable Residence:{" "}
                </span>
                Having a residence for over a year increases your loan
                eligibility.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Clear Existing Debts:{" "}
                </span>
                Pay off existing loans and avoid defaults to maintain a strong
                financial profile.
              </div>
            </div>
            <p style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              These steps can improve your loan approval chances and help you
              secure better terms. For more details, visit ICICI Bank's official
              website
            </p>
          </div>

          <p
            style={{ fontSize: 16, lineHeight: "20px", color: "#1f1f1f" }}
            className="mt-4"
          >
            For more information please check{" "}
            <a
              href={
                "https://www.icicibank.com/personal-banking/loans/personal-loan/eligibility"
              }
              target="_blank"
            >
              ICICI Bank Eligibility
            </a>
          </p>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Frequently Asked Questions (FAQs) on ICICI Bank Personal Loan
            Eligibility{" "}
          </div>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What is the minimum income required to qualify for an ICICI Bank
                Personal Loan?
              </AccordionSummary>
              <AccordionDetails>
                To be eligible for an ICICI Bank personal loan, salaried
                individuals must have a minimum monthly income of Rs. 30,000.
                For selfemployed individuals, a steady income and business
                continuity of at least 3 years is required.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{ fontWeight: 600, borderRadius: 12 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                What is the required credit score for an ICICI Bank Personal
                Loan?
              </AccordionSummary>
              <AccordionDetails>
                ICICI Bank generally requires a credit score of 750 or above to
                qualify for a personal loan. A higher credit score increases
                your chances of approval and better loan terms.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{ fontWeight: 600, borderRadius: 12 }}
              >
                Can I apply for an ICICI Bank Personal Loan if I am
                selfemployed?
              </AccordionSummary>
              <AccordionDetails>
                Yes, self-employed individuals can apply for an ICICI Bank
                personal loan. However, they need to provide proof of business
                continuity for at least 3-5 years and show adequate income
                documentation such as ITR and business bank statements.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{ fontWeight: 600, borderRadius: 12 }}
              >
                How long do I need to be employed to qualify for an ICICI Bank
                Personal Loan?
              </AccordionSummary>
              <AccordionDetails>
                For salaried applicants, ICICI Bank typically requires at least
                2 years of employment with a stable income source.
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 30,
              paddingBottom: 30,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br /> and tailored just for you—apply now to see what you
                qualify for!
              </p>
              <a
                href="https://minemi.ai/personal-loan/check-eligibility"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                  backgroundColor: "transparent",
                  marginTop: "2%",
                  cursor: "pointer",
                }}
              >
                <div
                  className="buttom-loan-eligiblity-one"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 40,
                    paddingRight: 40,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                   Check Your Eligibility Now
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IciciEligiblity;

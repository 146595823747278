import React, { useState } from "react";
import "./Section.css";
import { Button, TextField, InputLabel, FormControl } from "@mui/material"; // Updated imports
import { Card, CardContent, CardHeader, Typography } from "@mui/material"; // Updated imports

export default function APRCalculator() {
  const [loanAmount, setLoanAmount] = useState("");
  const [loanTerm, setLoanTerm] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [fees, setFees] = useState("");
  const [apr, setApr] = useState(null); // Change to useState(null)

  const calculateAPR = () => {
    const amount = parseFloat(loanAmount);
    const term = parseFloat(loanTerm);
    const rate = parseFloat(interestRate) / 100;
    const totalFees = parseFloat(fees);

    if (isNaN(amount) || isNaN(term) || isNaN(rate) || isNaN(totalFees)) {
      alert("Please enter valid numbers for all fields.");
      return;
    }

    const totalInterest = amount * rate * term;
    const totalCost = amount + totalInterest + totalFees;
    const calculatedAPR = ((totalCost - amount) / amount / term) * 100;

    setApr(parseFloat(calculatedAPR.toFixed(2)));
  };

  return (
    <section className="space-y-4 bg-[#311664] text-white p-4 sm:p-6 rounded-xl">
      <h2 className="card_pare text-xl sm:text-2xl font-bold">
        APR Calculator
      </h2>
      <div className="grid sm:grid-cols-2 gap-4 bg-transparent">
        <div className="space-y-4 bg-transparent">
          <FormControl fullWidth style={{ backgroundColor: "transparent" }}>
            <label
              htmlFor="loanAmount"
              className="card_pare text-sm sm:text-base bg-transparent"
            >
              Loan Amount (₹)
            </label>
            <input
              type="number"
              value={loanAmount}
              onChange={(e) => setLoanAmount(e.target.value)}
              className=" text-[#26292b] text-sm sm:text-base p-2 rounded-md bg-white"
              placeholder="Loan Amount (₹)"
              variant="outlined"
            />
          </FormControl>

          <FormControl fullWidth style={{ backgroundColor: "transparent" }}>
            <label
              htmlFor="loanTerm"
              className="text-sm sm:text-base bg-transparent"
            >
              Loan Term (years)
            </label>
            <input
              type="number"
              value={loanTerm}
              onChange={(e) => setLoanTerm(e.target.value)}
              className="bg-white text-[#26292b] text-sm p-2 rounded-md sm:text-base"
              //   label="Loan Term (years)"
              placeholder="Loan Term (years)"
              variant="outlined"
            />
          </FormControl>

          <FormControl fullWidth style={{ backgroundColor: "transparent" }}>
            <label
              htmlFor="interestRate"
              className="text-sm sm:text-base bg-transparent"
            >
              Interest Rate (%)
            </label>
            <input
              id="interestRate"
              type="number"
              value={interestRate}
              onChange={(e) => setInterestRate(e.target.value)}
              className="bg-white text-[#26292b] text-sm p-2 rounded-md sm:text-base"
              //   label="Interest Rate (%)"
              placeholder="Interest Rate (%)"
              variant="outlined"
            />
          </FormControl>

          <FormControl fullWidth style={{ backgroundColor: "transparent" }}>
            <label
              htmlFor="fees"
              className="text-sm sm:text-base bg-transparent"
            >
              Total Fees (₹)
            </label>
            <input
              id="fees"
              type="number"
              value={fees}
              onChange={(e) => setFees(e.target.value)}
              className="bg-white text-[#26292b] text-sm p-2 rounded-md sm:text-base"
              placeholder="Total Fees (₹)"
              variant="outlined"
            />
          </FormControl>

          <button
            className="bg-[#59266d] hover:bg-[#3a868b] text-white rounded-full px-4 py-1.5 text-sm sm:text-base w-full"
            style={{}}
            onClick={calculateAPR}
            variant="contained"
            color="primary"
            fullWidth
            // className="text-sm sm:text-base"
          >
            Calculate APR
          </button>
        </div>

        <Card
          className="bg-[#ebe8fc] text-[#26292b] rounded-xl"
          style={{
            backgroundColor: "#ebe8fc",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <CardContent style={{ backgroundColor: "transparent" }}>
            <h6
              variant="h6"
              component="div"
              className="text-lg sm:text-xl"
              style={{ backgroundColor: "transparent", fontWeight: 600 }}
            >
              Results
            </h6>
          </CardContent>
          <CardContent style={{ backgroundColor: "transparent" }}>
            {apr !== null ? (
              <div className="bg-transparent">
                <h5
                  variant="h5"
                  component="p"
                  className="text-xl sm:text-2xl font-bold card_pare bg-transparent"
                >
                  Calculated APR: {apr}%
                </h5>
                <p
                  variant="body2"
                  className="mt-2 text-sm sm:text-base card_pare bg-transparent"
                >
                  This APR takes into account the loan amount, term, interest
                  rate, and additional fees.
                </p>
              </div>
            ) : (
              <p
                variant="body2"
                className="card_pare text-sm sm:text-base "
                style={{ backgroundColor: "transparent" }}
              >
                Enter loan details and click "Calculate APR" to see results.
              </p>
            )}
          </CardContent>
        </Card>
      </div>
    </section>
  );
}

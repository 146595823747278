import React from "react";
import HeroSection from "./HeroSection";
import EmiTopup from "./EmiTopup";
import LoanCalculator from "./LoanCalculator";
import LoanTopupProcess from "./LoanTopupProcess";
import WhyChooseminemi from "./WhyChooseminemi";
import NewQueSection from "./NewQueSection";

function TopupFlowIndex() {
  return (
    <>
      <HeroSection />
      <EmiTopup />
      <LoanCalculator />
      <LoanTopupProcess />
      <WhyChooseminemi />
      <NewQueSection />
    </>
  );
}

export default TopupFlowIndex;

import React, { useEffect, useState, useContext } from "react";
import "./PersonalLoanNewsDetails.css"; // Importing the CSS for styling
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet
import MetadataContext from "../MetadataContext";

function CarLoanManageLoanDetails() {
  const { mslug } = useParams();
  const [newsDetails, setNewsDetails] = useState();
  const [news, setNews] = useState([]);
  const [title, setTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const location = useLocation();
  // const { mslug } = location.state || {};

  const newsHandler = () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    // console.log("mslug", mslug);
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/manageloan_detail?mslug=${mslug}`,
    };

    axios
      .request(options)
      .then(function (response) {
        setNews(response.data.data.detail);
        setNewsDetails(response.data.data.detail[0]);
        setRelatedBlogs(response.data.data.related);
        setTitle(response.data.data.detail[0].page_title);
        setMetaDescription(response.data.data.detail[0].meta_description);
        setMetaKeywords(response.data.data.detail[0].page_title);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    // BlogDetailHandler();
    newsHandler();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: newsDetails?.title,
      pageDescription: newsDetails?.meta_description.replace(/<\/?p>/g, ""),
      pageKeywords: newsDetails?.meta_tag,
      ogTitle: newsDetails?.title,
      ogDescription: newsDetails?.meta_description.replace(/<\/?p>/g, ""),
    });
  }, [newsDetails, setMetadata]);


  return (
    <div className="news-detail-container">
      {/* Main news content */}
      <div className="news-main-content">
        <h1 className="news-title">{newsDetails?.title}</h1>
        <p className="published-date">by {newsDetails?.author}</p>
        <p className="published-date">
          Published on: {formatDate(newsDetails?.created_at)}
        </p>
        <img
          src={
            newsDetails
              ? newsDetails?.image
              : "https://via.placeholder.com/820x340"
          }
          alt="Blog Banner"
          loading="lazy"
          className="news-banner"
        />
        <div className="news-description">
          <div dangerouslySetInnerHTML={{ __html: newsDetails?.description }} />
          {/* <p>{newsDetails?.description}</p> */}
        </div>
      </div>

      {/* Related blogs on the side */}
      <div className="related-blogs">
        <h3 className="related-title">Related Blogs</h3>
        {relatedBlogs.map((relBlog) => (
          <a
            href={`/loanpage/carloan/manageloan_detail/${relBlog.mslug}`}
            state={{
              mslug: relBlog.mslug,
            }}
          >
            <div className="related-news-item" key={relBlog.id}>
              <img
                src={relBlog.image}
                alt={relBlog.title}
                loading="lazy"
                className="related-news-image"
              />
              <p className="related-news-title">{relBlog.title}</p>
              <p className="related-auther">by {relBlog.author}</p>
              <div
                style={{ backgroundColor: "#FFFFFF" }}
                className="related-news-discription"
                dangerouslySetInnerHTML={{
                  __html: relBlog.description.replace(/<\/?strong>/g, ""),
                }}
              />

              {/* <p className="related-news-discription">{news.description}</p> */}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default CarLoanManageLoanDetails;

import React, { useEffect, useState } from "react";
import "./ManageLoan.css"; // Importing the CSS for styling
import axios from "axios";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

function LoanManageLoanBlogs() {
  //   const [blog, setBlog] = useState([]);
  const [manageData, setManageData] = useState([]);

  //   const blogHandler = () => {
  //     const token = localStorage.getItem("token");
  //     const id = localStorage.getItem("id");
  //     // console.log(token);
  //     const options = {
  //       method: "GET",
  //       url: `${process.env.REACT_APP_SECRET_KEY}/blog`,
  //     };

  //     axios
  //       .request(options)
  //       .then(function (response) {
  //         // console.log(response.data.data.view_blog);
  //         setBlog(response.data.data.view_blog);
  //       })
  //       .catch(function (error) {
  //         console.error(error);
  //       });
  //   };
  //   useEffect(() => {
  //     blogHandler();
  //   }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchManageLoans = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/manageloan`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data.data.manageloan);
        setManageData(response.data.data.manageloan);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchManageLoans();
  }, []);

  //   const blogs = [
  //     {
  //       id: 1,
  //       image: "https://via.placeholder.com/150",
  //       title: "Blog Post 1",
  //       description: "This is the subheading for blog post 1.",
  //     },
  //     {
  //       id: 2,
  //       image: "https://via.placeholder.com/150",
  //       title: "Blog Post 2",
  //       description: "This is the subheading for blog post 2.",
  //     },
  //     {
  //       id: 3,
  //       image: "https://via.placeholder.com/150",
  //       title: "Blog Post 3",
  //       description: "This is the subheading for blog post 3.",
  //     },
  //     {
  //       id: 4,
  //       image: "https://via.placeholder.com/150",
  //       title: "Blog Post 4",
  //       description: "This is the subheading for blog post 4.",
  //     },
  //     {
  //       id: 5,
  //       image: "https://via.placeholder.com/150",
  //       title: "Blog Post 5",
  //       description: "This is the subheading for blog post 5.",
  //     },
  //     {
  //       id: 6,
  //       image: "https://via.placeholder.com/150",
  //       title: "Blog Post 6",
  //       description: "This is the subheading for blog post 6.",
  //     },
  //   ];

  return (
    <div className="" style={{ marginLeft: "4%", marginRight: "4%" }}>
      <div
        style={{
          gap: 16,
          display: "flex",
          flexDirection: "column",
          marginTop: 40,
        }}
      >
        <div
          style={{
            fontSize: "40px",
            fontWeight: "600",
            color: "#1F1F1F",
            textAlign: "center",
          }}
        >
          Manage Your Loan{" "}
        </div>
        <p
          style={{
            textAlign: "center",
            color: "#8A8A8A",
            fontSize: 24,
            fontWeight: 500,
          }}
        >
          Expert Tips & Insights from Minemi
        </p>
      </div>

      <div className="mx-4">
        <Grid container spacing={{ xs: 4, md: 6, lg: 10 }} className="mt-4">
          {manageData
            .filter((d) => d.category === "Loan")
            .map((d) => (
              <Grid item xs={12} sm={6} md={4} key={d.id} className="m-0">
                <div className="manage-container">
                  <Link
                    to={`/loanpage/manageloan_detail/${d.mslug}`}
                    state={{
                      mslug: d.mslug,
                    }}
                  >
                    <img
                      src={d.image}
                      alt={d.heading}
                      className="manage-image"
                      loading="lazy"
                    />
                    <h2 className="manage-heading">{d.title}</h2>
                    <p className="manage-auther">by {d.author}</p>
                    <div
                      className="manage-subheading"
                      dangerouslySetInnerHTML={{
                        __html: d.description.replace(/<\/?strong>/g, ""),
                      }}
                    />
                  </Link>
                </div>
              </Grid>
            ))}
        </Grid>
      </div>
    </div>
  );
}

export default LoanManageLoanBlogs;


import { useState, useEffect } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { ChevronLeft, ChevronRight, Star } from "lucide-react"
import { Card, CardContent } from "@mui/material"

export default function TestimonialsSection() {
  const testimonials = [
    {
      name: "Rajesh Sharma",
      location: "Mumbai",
      image: "/placeholder.svg?height=70&width=70",
      rating: 5,
      text: "Minemi helped me find a personal loan with an interest rate 3% lower than what my bank offered. The rate check was quick and accurate!",
    },
    {
      name: "Priya Patel",
      location: "Bangalore",
      image: "/placeholder.svg?height=70&width=70",
      rating: 5,
      text: "I was hesitant about applying for a home loan, but Minemi's rate check gave me confidence. I got approved at exactly the rate they predicted!",
    },
    {
      name: "Vikram Malhotra",
      location: "Delhi",
      image: "/placeholder.svg?height=70&width=70",
      rating: 4,
      text: "The rate check feature saved me from applying to multiple lenders. I found the best car loan rate through Minemi in just minutes.",
    },
  ]

  const [current, setCurrent] = useState(0)
  const [autoplay, setAutoplay] = useState(true)

  const next = () => {
    setCurrent((current + 1) % testimonials.length)
  }

  const prev = () => {
    setCurrent((current - 1 + testimonials.length) % testimonials.length)
  }

  useEffect(() => {
    if (!autoplay) return

    const interval = setInterval(() => {
      next()
    }, 5000)

    return () => clearInterval(interval)
  }, [current, autoplay])

  return (
    <section className="py-10 md:py-16 bg-white">
      <div className="container mx-auto px-6">
        <div className="text-center mb-8">
          <h2 className="text-xl md:text-2xl font-bold text-[#311664] mb-2">What Our Users Say</h2>
          <div className="w-16 h-0.5 bg-[#3a868b] mx-auto"></div>
        </div>

        <div className="relative max-w-lg mx-auto">
          <AnimatePresence mode="wait">
            <motion.div
              key={current}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
              className="relative"
            >
              <Card className="border-none shadow-lg overflow-hidden">
                <CardContent className="p-6">
                  <div className="flex " style={{gap:8}}>
                    <div className="flex-shrink-0">
                      <img
                        src='https://kzmic7ri6hboo0trjfow.lite.vusercontent.net/placeholder.svg?height=200&width=300'
                        alt={testimonials[current].name}
                        width={70}
                        height={70}
                        className="rounded-full border-2 border-[#ebe8fc]"
                      />
                    </div>
                    <div className="flex-1">
                      <div className="flex items-center mb-2">
                        {[...Array(testimonials[current].rating)].map((_, i) => (
                          <Star key={i} className="h-4 w-4 fill-yellow-400 text-yellow-400" />
                        ))}
                      </div>
                      <p className="text-sm mb-3 italic text-[#26292b]">"{testimonials[current].text}"</p>
                      <div>
                        <h4 className="text-sm font-semibold text-[#311664]">{testimonials[current].name}</h4>
                        <p className="text-xs text-muted-foreground">{testimonials[current].location}</p>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          </AnimatePresence>

          <div className="flex justify-center mt-4 " style={{gap:5}}>
            <button
              variant="outline"
              size="icon"
              onClick={() => {
                prev()
                setAutoplay(false)
              }}
              className="h-8 w-8 rounded-full"
              aria-label="Previous testimonial"
            >
              <ChevronLeft className="h-4 w-4" />
            </button>

            <div className="flex items-center " style={{gap:4}}>
              {testimonials.map((_, index) => (
                <button
                  key={index}
                  onClick={() => {
                    setCurrent(index)
                    setAutoplay(false)
                  }}
                  className={`w-2 h-2 rounded-full ${current === index ? "bg-[#3a868b]" : "bg-[#ebe8fc]"}`}
                  aria-label={`Go to testimonial ${index + 1}`}
                />
              ))}
            </div>

            <button
              variant="outline"
              size="icon"
              onClick={() => {
                next()
                setAutoplay(false)
              }}
              className="h-8 w-8 rounded-full"
              aria-label="Next testimonial"
            >
              <ChevronRight className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}


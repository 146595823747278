import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import { Link } from "react-router-dom";
import MetadataContext from "../../../MetadataContext";

const DLArticleTwo = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Loan Against Shares and Mutual Funds",
      pageDescription:
        "Advice on refinancing or taking out a top-up loan against shares and mutual funds, focusing on improving loan terms and increasing the loan amount.",
      pageKeywords:
        "loan against shares, loan against shares minemi, loan against mutual funds, loans on securities, loan against equity, financing with shares, share-backed loans, mutual fund loans, collateralized loans, loans against investments, investment-backed loans, share margin loans, borrowing against mutual funds, secured loans, loans on financial assets, portfolio financing, stock-backed loans",
      canonicalUrl:
        "https://minemi.ai/loanpage/digital-loan/loan-against-shares-and-mutual-funds",
    });
  }, [setMetadata]);
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/digital-loan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Digital Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Loan Against Shares and Mutual Funds
              </Link>
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            {" "}
            Loan Against Shares and Mutual Funds: How to Top-Up or Refinance
            Digitally
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: 1,
            }}
          >
            Advice on refinancing or taking out a top-up loan against shares and
            mutual funds, focusing on improving loan terms and increasing the
            loan amount.
          </p>
        </div>

        <div
          style={{
            marginLeft: "4%",
            marginRight: "4%",
            marginTop: 35,
            paddingBottom: 30,
          }}
        >
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Loans Against Shares (LAS) and Mutual Funds allow you to leverage
            your investment portfolio for quick funding. Whether you want to
            increase your loan amount or improve the terms of your existing
            loan, top-up loans and refinancing are two effective options. In the
            digital lending space, these processes have become much easier to
            navigate. Here's how you can top-up or refinance your Loan Against
            Shares and Mutual Funds digitally.
          </p>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            1. Understanding Top-Up Loans Against Shares and Mutual Funds
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            A top-up loan allows existing borrowers to borrow additional funds
            on their current loan. It works similarly to a regular LAS, where
            you pledge your securities (shares or mutual funds) as collateral.
          </div>
          <div>
            <p
              style={{
                fontSize: "18px",
                fontWeight: 600,
                color: "#1f1f1f",
                marginTop: 20,
              }}
            >
              When to Consider a Top-Up Loan
            </p>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: 4 }}
                className="mt-3"
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Increased Need for Funds:{" "}
                  </span>
                  If you find yourself needing more funds for a new investment
                  or urgent expenses, a top-up loan is a good option.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Improved Security Value:{" "}
                  </span>
                  If the value of the shares or mutual funds you&#39;ve pledged
                  has increased, you may qualify for a higher loan amount.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Lower Outstanding Debt:{" "}
                  </span>
                  If you have repaid a significant portion of your original
                  loan, you may be eligible for a larger loan amount based on
                  the remaining collateral.
                </div>
              </div>{" "}
            </div>
          </div>

          <div
            style={{
              marginTop: 20,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 18,
            }}
          >
            How to Apply for a Top-Up Loan Digitally
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Most digital lenders provide an easy online process to apply for a
            top-up loan. The steps generally include:
          </div>
          <div>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: 4 }}
                className="mt-3"
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Login to Your Lender&#39;s Platform:{" "}
                  </span>
                  Access the lender’s website or app where you initially took
                  the LAS.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Check Eligibility:{" "}
                  </span>
                  Use the digital eligibility check tool by entering details
                  like the updated value of your shares or mutual funds, income,
                  and current loan balance.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Submit Documents:{" "}
                  </span>
                  Provide documents such as your updated portfolio, proof of
                  income, and existing loan details. Many lenders accept digital
                  submission through their platforms.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Receive an Instant Offer:{" "}
                  </span>
                  Based on the information, the lender will assess your
                  eligibility and provide an offer for the additional loan
                  amount and revised terms.
                </div>
              </div>{" "}
            </div>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            2. Refinancing Your Loan Against Shares and Mutual Funds
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Refinancing involves replacing your existing loan with a new one,
            typically at better terms such as a lower interest rate or a longer
            loan tenure. This is beneficial if your financial situation has
            improved or if interest rates have dropped since you first took out
            the loan.
          </div>
          <div>
            <p style={{ fontSize: "18px", fontWeight: 600 }} className="mt-4">
              When to Consider Refinancing{" "}
            </p>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: 4 }}
                className="mt-3"
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Lower Interest Rates:{" "}
                  </span>
                  If interest rates have decreased since you took out the
                  original loan, refinancing can help you secure a better rate.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Improved Credit Score:{" "}
                  </span>
                  If your credit score has improved, you might be eligible for a
                  lower interest rate on the refinanced loan.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Lower EMI Burden:{" "}
                  </span>
                  If you want to reduce your monthly EMI, refinancing with a
                  longer tenure or lower interest rate may help.
                </div>
              </div>{" "}
            </div>
          </div>
          <div>
            <p style={{ fontSize: "18px", fontWeight: 600, marginTop: 20 }}>
              How to Refinance Digitally
            </p>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: 4 }}
                className="mt-3"
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Research Digital Lenders:{" "}
                  </span>
                  Start by researching digital lenders who offer competitive
                  refinancing options for loans against shares and mutual funds.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Check Current Loan Terms:{" "}
                  </span>
                  Review the interest rate, loan tenure, and repayment schedule
                  of your existing loan.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Calculate Potential Savings:{" "}
                  </span>
                  Use online calculators provided by digital lenders to assess
                  the impact of refinancing, including potential savings in
                  interest and changes to your EMI.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Apply for Refinancing:{" "}
                  </span>
                  Many lenders offer an entirely digital refinancing process.
                  You can fill out an online application, upload the necessary
                  documents, and submit them for approval.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Get a New Loan Agreement:{" "}
                  </span>
                  Once approved, you’ll receive a new loan agreement, which you
                  must sign digitally. The lender will pay off your old loan,
                  and you’ll begin repaying the new loan under the revised
                  terms.
                </div>
              </div>{" "}
            </div>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            3. Benefits of Top-Up Loans and Refinancing
          </div>
          <div>
            <p style={{ fontSize: "18px", fontWeight: 600, marginTop: 20 }}>
              Top-Up Loans
            </p>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: 4 }}
                className="mt-3"
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Quick Access to Funds:{" "}
                  </span>
                  A top-up loan is a fast way to increase your borrowing
                  capacity without going through a lengthy loan application
                  process again.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    No Need for New Collateral:{" "}
                  </span>
                  You don’t need to pledge additional securities. The existing
                  ones act as collateral for the increased loan amount.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Lower Documentation:{" "}
                  </span>
                  Since it’s a top-up on an existing loan, the documentation
                  process is usually quicker and simpler.
                </div>
              </div>{" "}
            </div>
          </div>
          <div>
            <p style={{ fontSize: "18px", fontWeight: 600, marginTop: 20 }}>
              Refinancing
            </p>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: 4 }}
                className="mt-3"
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Lower Interest Rates:{" "}
                  </span>
                  If market conditions have improved or your credit score has
                  risen, refinancing could reduce the interest rate, lowering
                  the overall repayment burden.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    More Favorable Terms:{" "}
                  </span>
                  Refinancing might help you extend the loan tenure, reducing
                  your monthly EMI.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Better Loan Structure:{" "}
                  </span>
                  If your existing loan is on less favorable terms, refinancing
                  can provide you with a better loan structure that aligns with
                  your current financial needs.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Apply for Refinancing:{" "}
                  </span>
                  Many lenders offer an entirely digital refinancing process.
                  You can fill out an online application, upload the necessary
                  documents, and submit them for approval.
                </div>
              </div>{" "}
            </div>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            4. How to Ensure a Successful Top-Up or Refinancing Application
          </div>
          <div>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "row", gap: 4 }}
                className="mt-3"
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Update Your Portfolio Details:{" "}
                  </span>
                  Ensure that the value of the shares or mutual funds you pledge
                  is up-to-date. Lenders typically evaluate the current market
                  value of your securities before approving any top-up loan or
                  refinancing.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Check Your Credit Score:{" "}
                  </span>
                  A higher credit score can help you secure a better interest
                  rate, whether for a top-up or refinancing. If your credit
                  score has improved since you took out the loan, refinancing
                  could offer a more competitive rate.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Evaluate Your Loan Terms:{" "}
                  </span>
                  Be sure that the new loan terms fit your financial
                  capabilities. If refinancing, assess whether the revised
                  interest rates and EMIs align with your current repayment
                  capacity.
                </div>
              </div>{" "}
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Compare Lender Offers:{" "}
                  </span>
                  Digital platforms allow you to easily compare various offers
                  for top- up loans and refinancing. Look for the best terms,
                  including interest rates, loan amounts, and repayment options.
                </div>
              </div>{" "}
            </div>
          </div>
          <div>
            <h4 style={{ fontSize: "24px", fontWeight: 600, marginTop: 25 }}>
              Conclusion
            </h4>
            <p
              style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px" }}
              className=""
            >
              Whether you’re looking to increase your loan amount through a
              top-up loan or improve your terms via refinancing, digital lenders
              have made the process seamless and efficient. By understanding
              when and how to top-up or refinance your Loan Against Shares and
              Mutual Funds, you can optimize your borrowing experience and
              secure better financial terms. Always stay informed, and use the
              digital tools provided by lenders to manage your loan effectively.{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DLArticleTwo;

import React, { useEffect, useRef } from "react";
import HeroSection from "./HeroSection";
import HowMinemiWorks from "./HowMinemiWorks";
import HomeLoanEMICalculator from "./HomeLoanEMICalculator";
import TestimonialSection from "./TestimonialSection";
import Faq from "./Faq";
import LoanFilterAndSort from "./LoanFilterAndSort";
import FinalCta from "./FinalCta";
import { Helmet } from "react-helmet";

const MainCarLoan = () => {
  const loanFilterRef = useRef(null);

  const scrollToFilter = () => {
    if (loanFilterRef.current) {
      loanFilterRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Best Car Loan Offers - Minemi </title>
        <meta
          name="description"
          content="Find the best car loan offers with Minemi . Drive your dream car with competitive interest rates."
        />

        {/* <link rel="icon" type="image/png" href="./mLogo.png" /> */}
      </Helmet>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <HeroSection scrollToFilter={scrollToFilter} />
        <HowMinemiWorks scrollToFilter={scrollToFilter} />
        <div ref={loanFilterRef}>
          <LoanFilterAndSort />
        </div>
        <HomeLoanEMICalculator />
        <TestimonialSection scrollToFilter={scrollToFilter} />
        <Faq />
        <FinalCta />
      </div>
    </div>
  );
};

export default MainCarLoan;

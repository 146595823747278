import React from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";

export default function HiddenCosts() {
  return (
    <section className="space-y-4 py-6">
      <h5 className="font-bold text-2xl text-[#26292b]">
        Hidden Costs Unveiled
      </h5>
      <div className="grid sm:grid-cols-3 gap-4">
        <Card
          className="bg-[#ebe8fc] rounded-xl"
          style={{ backgroundColor: "#ebe8fc" }}
        >
          <Box className="p-4" style={{ backgroundColor: "transparent" }}>
            <h6 className="text-[#59266d] text-xl card_pare">
              Processing Fees
            </h6>
          </Box>
          <CardContent style={{ backgroundColor: "transparent" }}>
            <p className=" card_pare text-sm sm:text-base m-0">
              Many lenders charge 0.5% to 2% of the loan amount as processing
              fees.
            </p>
          </CardContent>
        </Card>
        <Card
          className="bg-[#ebe8fc] rounded-xl"
          style={{ backgroundColor: "#ebe8fc" }}
        >
          <Box className="p-4" style={{ backgroundColor: "transparent" }}>
            <h6 className="text-[#59266d] text-xl card_pare">
              Late Payment Penalties
            </h6>
          </Box>
          <CardContent style={{ backgroundColor: "transparent" }}>
            <p className=" card_pare text-sm sm:text-base m-0">
              Missing a payment can result in fees of 2-5% of the overdue
              amount.
            </p>
          </CardContent>
        </Card>
        <Card
          className="bg-[#ebe8fc] rounded-xl"
          style={{ backgroundColor: "#ebe8fc" }}
        >
          <Box className="p-4" style={{ backgroundColor: "transparent" }}>
            <h6 className="text-[#59266d] text-xl card_pare">
              Prepayment Penalties
            </h6>
          </Box>
          <CardContent style={{ backgroundColor: "transparent" }}>
            <p className=" card_pare text-sm sm:text-base m-0">
              Some lenders charge 2-5% of the outstanding principal for early
              repayment.
            </p>
          </CardContent>
        </Card>
      </div>
      <Box textAlign="center" mt={3}>
        <button
          style={{
            color: "white",
            borderRadius: "9999px",
            padding: "8px 24px",
            fontSize: "16px",
          }}
          className="bg-[#59266d] hover:bg-[#3a868b] transition-all duration-300 ease-in-out transform hover:scale-105"
        >
          Check my Rate
        </button>
      </Box>
    </section>
  );
}

import React from "react";
import { LuArrowDown, LuWallet, LuCalculator, LuZap } from "react-icons/lu";
function TopupFlowIndex() {
  return (
    <section className="py-8 md:py-20" style={{ backgroundColor: "#f8f8f8" }}>
      <h2
        className="text-2xl md:text-4xl font-bold text-center bg-transparent mb-4 text-[#59266d]"
        style={{
          color: "#59266D",
          fontSize: "36px",
          lineHeight: "40px",
          fontWeight: "700",
        }}
      >
        What is a Loan Top-Up?
      </h2>
      <p
        className="text-lg md:text-xl text-center bg-transparent mb-6 max-w-2xl mx-auto"
        style={{
          color: "#59266D",
          fontSize: "20px",
          lineHeight: "28px",
          fontWeight: "400",
        }}
      >
        Loan Top-Up: Borrow More, Pay Less
      </p>
      <div className="bg-gradient-to-r from-purple-100 to-indigo-100 rounded-lg p-6 mx-4 mb-8 shadow-md">
        <p
          className="text-[#59266d] bg-transparent text-center text-base md:text-lg leading-relaxed"
          style={{
            color: "#59266D",
            fontSize: "18px",
            lineHeight: "28px",
            fontWeight: "400",
          }}
        >
          A loan top-up allows you to add extra funds to your existing loan
          while reducing your monthly payments. By consolidating your original
          loan and the top-up amount into one, you get lower EMIs and access
          funds for personal needs—all in a single repayment.
        </p>
      </div>
      <div
        className="container mx-auto  px-4"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
          margin: "auto",
        }}
      >
        <div className="overflow-x-auto ">
          <table className="w-full max-w-4xl mx-auto  bg-white  rounded-lg overflow-hidden text-sm md:text-base">
            <thead className="bg-[#59266d] text-white">
              <tr>
                <th className="px-4 py-3 text-left bg-[#59266d]">Benefit</th>
                <th className="px-4 py-3 text-left bg-[#59266d]">
                  Description
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b bg-white border-gray-200 hover:bg-gray-50">
                <td className="px-4 bg-white  py-3 flex items-center">
                  <LuArrowDown className="w-6 h-6 mr-2 bg-white text-[#59266d]" />
                  <span
                    className="font-semibold bg-white "
                    style={{
                      color: "#59266D",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "600",
                    }}
                  >
                    Lower EMIs
                  </span>
                </td>
                <td
                  className="px-4 py-3 bg-white"
                  style={{
                    color: "#59266D",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "400",
                  }}
                >
                  Reduce your monthly loan payments by transferring to a lender
                  with better terms.
                </td>
              </tr>
              <tr className="border-b bg-white border-gray-200 hover:bg-gray-50">
                <td className="px-4 py-3  bg-white flex items-center">
                  <LuWallet className="w-6 h-6 mr-2 bg-white text-[#59266d]" />
                  <span
                    className="font-semibold bg-white"
                    style={{
                      color: "#59266D",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "600",
                    }}
                  >
                    Extra Funds
                  </span>
                </td>
                <td
                  className="px-4 py-3 bg-white"
                  style={{
                    color: "#59266D",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "400",
                  }}
                >
                  Access additional funds for personal or business needs, like
                  renovations or education.
                </td>
              </tr>
              <tr className="border-b bg-white border-gray-200 hover:bg-gray-50">
                <td className="px-4 bg-white py-3 flex items-center">
                  <LuCalculator className="w-6 h-6 mr-2 bg-white text-[#59266d]" />
                  <span
                    className="font-semibold bg-white"
                    style={{
                      color: "#59266D",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "600",
                    }}
                  >
                    Single EMI
                  </span>
                </td>
                <td
                  className="px-4 py-3 bg-white"
                  style={{
                    color: "#59266D",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "400",
                  }}
                >
                  Combine your existing loan balance with the top-up into one
                  easy repayment.
                </td>
              </tr>
              <tr className="hover:bg-gray-50 bg-white">
                <td className="px-4 py-3 flex items-center bg-white">
                  <LuZap className="w-6 h-6 mr-2 bg-white text-[#59266d]" />
                  <span
                    className="font-semibold bg-white"
                    style={{
                      color: "#59266D",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "600",
                    }}
                  >
                    Flexible Usage
                  </span>
                </td>
                <td
                  className="px-4 py-3 bg-white"
                  style={{
                    color: "#59266D",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "400",
                  }}
                >
                  Use the extra funds for any purpose—no restrictions.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="text-center bg-transparent mt-10">
        <a
          href="/lower-emi-top-up"
          className=""
          onClick={() => {
            localStorage.setItem("id", 3);
            window.scrollTo(0, 0);
          }}
        >
          <button className="bg-[#59266d] text-white px-4 py-2 md:px-6 md:py-3 rounded-full text-sm md:text-lg font-semibold hover:bg-purple-800 transition-colors change-width">
            Check Your Top-Up Eligibility
          </button>
        </a>
      </div>
    </section>
  );
}

export default TopupFlowIndex;

// import { Quote } from "lucide-react";
import { LuQuote } from "react-icons/lu";
function TestimonialCard({ name, location, quote }) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md transition-all duration-300 ease-in-out hover:shadow-lg">
      <div className="flex items-center mb-4 bg-white ">
        <div className="w-16 h-16 rounded-full mr-4 " style={{backgroundColor:'white'}}></div>
        <div style={{backgroundColor:'white'}}>
          <h3
            className="font-semibold bg-white  text-[#59266d]"
            style={{
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "600",
              color: "#59266D",
              marginBottom:0
            }}
            
          >
            {name}
          </h3>
          <p
            className="text-gray-600 bg-white "
            style={{
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: "400",
            }}
          >
            {location}
          </p>
        </div>
      </div>
      {/* <Quote className="w-8 h-8 text-[#59266d] mb-2" /> */}
      <LuQuote className="w-8 h-8 text-[#59266d] mb-2  bg-white" />
      <p
        className="text-[#59266d] bg-white "
        style={{
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "400",
        }}
      >
        {quote}
      </p>
    </div>
  );
}

export default TestimonialCard;

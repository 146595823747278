
export default function FifthFold({ onScrollToSecondFold }) {
  const benefits = [
    "Unlike normal chatbots, it tailors advice to your real credit profile",
    "Get expert-level loan & credit guidance instantly",
    "100% secure – your data is protected",
    "Completely free to use",
  ]

  return (
    <section className="py-16 md:py-24 px-4 bg-[#311664]/10">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-[#311664] text-center mb-12">Why Minemi AI?</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
          {benefits.map((benefit, index) => (
            <div key={index} className="flex items-start p-3 bg-white rounded-lg border border-[#3a868b]/30 shadow-md">
              <div className="mr-3 mt-1 text-[#3a868b]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <div className="text-[#26292b] text-lg">{benefit}</div>
            </div>
          ))}
        </div>

        <div className="text-center">
          <button
            className="bg-[#59266d] hover:bg-[#3a868b] text-white text-lg py-3 px-8 rounded-lg transition-all duration-300 shadow-lg"
            asChild
            onClick={onScrollToSecondFold}
          >
Try Minemi AI Now
          </button>
        </div>
      </div>

      <footer className="mt-20 text-center text-[#26292b]/70 text-sm">
        <div>© {new Date().getFullYear()} Minemi AI. All rights reserved.</div>
      </footer>
    </section>
  )
}


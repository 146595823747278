import React, { useEffect, useRef } from "react";
import HeroSection from "./HeroSection";
import HowMinemiWorks from "./HowMinemiWorks";
import HomeLoanEMICalculator from "./HomeLoanEMICalculator";
import TestimonialSection from "./TestimonialSection";
import Faq from "./Faq";
import LoanFilterAndSort from "./LoanFilterAndSort";
import FinalCta from "./FinalCta";
import { Helmet } from "react-helmet";

const MainHomeLoan = () => {
  const loanFilterRef = useRef(null);

  const scrollToFilter = () => {
    if (loanFilterRef.current) {
      loanFilterRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <Helmet>
          <title>Best Home Loan Offers - Minemi </title>
          <meta
            name="description"
            content="Explore the best home loan offers with Minemi . Secure your dream home today."
          />
          {/* <link rel="icon" type="image/png" href="./mLogo.png" /> */}
        </Helmet>
        <HeroSection scrollToFilter={scrollToFilter} />
        <HowMinemiWorks scrollToFilter={scrollToFilter} />
        <div ref={loanFilterRef}>
          <LoanFilterAndSort />
        </div>
        <HomeLoanEMICalculator />
        <TestimonialSection scrollToFilter={scrollToFilter} />
        <Faq />
        <FinalCta />
      </div>
    </>
  );
};

export default MainHomeLoan;

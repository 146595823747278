import React, { useContext, useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import logo from ".././assets/Images/mLogo.webp";
import { Typography } from "@mui/material";
import { ColorRing } from "react-loader-spinner";

import axios from "axios";
import MetadataContext from "../../MetadataContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "#F6F6F6",
  boxShadow: 24,
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 2,
  paddingRight: 2,
  border: 0,
};

const Coapplicant = () => {
  const [progress, setProgress] = useState(90);
  const [active, setActive] = useState(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [emiAmount, setEmiAmount] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const { category } = location.state || {};

  // Retrieve stored value from localStorage on mount
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const storedValue = localStorage.getItem("coApplicantSelection");
    if (storedValue) {
      setValue(storedValue);
      setActive(storedValue === "Yes" ? 0 : 1);
    } else {
      // Default to "Yes" if no previous value
      setValue("Yes");
      setActive(0);
      localStorage.setItem("coApplicantSelection", "Yes");
    }
  }, []);

  const handleSelection = (option) => {
    setActive(option === "Yes" ? 0 : 1);
    setValue(option);
    setEmiAmount(""); // Reset EMI selection
    localStorage.setItem("coApplicantSelection", option); // Store in localStorage
  };

  const selectedFundReason = localStorage.getItem("selectedFundReason");
  const selectedrepay = localStorage.getItem("selectedrepay");
  const moneyneed = localStorage.getItem("moneyneed");
  const selectedLoanIndex = localStorage.getItem("selectedLoanIndex");
  const selectedIncome = localStorage.getItem("selectedIncome");
  const valueData = localStorage.getItem("value");
  const monthlySpend = localStorage.getItem("monthly_spend");
  const assets = localStorage.getItem("assets");
  const coApplicantSelection = localStorage.getItem("coApplicantSelection");
  const repaymentPriority = localStorage.getItem("repayment_priority");

  const handleFormSubmit = () => {
    setLoading(true);

    // if (spendmonth == "") {
    //   alert("Please fill field.");
    //   return;
    // }else{

    // }
    const token = localStorage.getItem("token");
    if (token) {
      const creditScore = localStorage.getItem("creditScore");

      if (
        moneyneed === null &&
        selectedFundReason === null &&
        selectedrepay === null &&
        selectedIncome === null &&
        creditScore === ""
      ) {
        navigate("/loan-recommendation");
      } else {
        const form = {
          need_fund_for: selectedFundReason,
          amount_needed: moneyneed,
          assets: assets,
          loan_tenure: selectedrepay,
          monthly_income: selectedIncome,
          existing_emi: monthlySpend,
          collateral_investments: assets,
          co_applicant: coApplicantSelection,
          credit_score: creditScore,
        };

        const options = {
          method: "POST",
          url: "https://cardai.minemi.ai/loanRecommedation",
          headers: {
            "Content-Type": "application/json",
          },
          data: form,
        };

        axios
          .request(options)
          .then(function (response) {
            localStorage.setItem(
              "matchdata",
              JSON.stringify(response.data.matches[0].matches)
            );

            // You need to stringify before saving

            localStorage.setItem("attemept", true);
            setLoading(false);
            navigate("/loan-recommendation-page");
          })
          .catch(function (error) {
            setLoading(false);

            console.error(error);
          });
      }
    } else {
      navigate("/loan-recommendation-login");
    }
  };

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        "Do you have any assets or investments to use as collateral? - MinEmi",
      pageDescription:
        "Find out if applying with a co-applicant can improve your loan eligibility. Get personalized loan recommendations with MinEmi.",
      pageKeywords:
        "loan application, co-applicant, joint loan, loan eligibility, loan recommendation, MinEmi, better loan offers, loan approval, financial assistance",

      canonicalUrl: "https://minemi.ai/apply-with-co-applicant",
    });
  }, [setMetadata]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div className="progress-bar-background" />
        <div className="progress-bar" style={{ width: `${progress}%` }} />
        <div className="min-h-[100vh] w-full pt-4 md:pt-8 px-4 md:px-6">
          {/* Header Navigation */}
          <div
            className="container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 4%",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", gap: 8 }}
              onClick={() => navigate("/select-assets-or-investments-to-use")}
            >
              <FaArrowLeftLong style={{ fontSize: 20, color: "#8A8A8A" }} />
              <p style={{ marginLeft: 8, fontSize: 16, color: "#8A8A8A" }}>
                Back
              </p>
            </div>
            <IoClose
              style={{ fontSize: 24, color: "#8A8A8A", cursor: "pointer" }}
              onClick={() => setOpen(true)}
            />
          </div>

          {/* Main Content */}
          <div className="mx-auto mt-8 md:mt-12">
            <h1 className="text-center text-2xl md:text-3xl font-semibold text-black mb-8">
              Would you like to apply with a co-applicant?
            </h1>

            {/* Yes/No Buttons */}
            <div className="flex flex-col sm:flex-row justify-center gap-4 md:gap-5 px-4">
              <button
                onClick={() => handleSelection("Yes")}
                className={` px-6 w-full sm:w-48 rounded-lg shadow-md transition-all ${
                  active === 0
                    ? "bg-[#E7E4F9] border-2 border-[#59266D]"
                    : "bg-white hover:bg-gray-50"
                }`}
                style={{ padding: "13px " }}
              >
                Yes
              </button>
              <button
                onClick={() => handleSelection("No")}
                className={` px-6 w-full sm:w-48 rounded-lg shadow-md transition-all ${
                  active === 1
                    ? "bg-[#E7E4F9] border-2 border-[#59266D]"
                    : "bg-white hover:bg-gray-50"
                }`}
                style={{ padding: "13px " }}
              >
                No
              </button>
            </div>

            <div className="mt-8 md:mt-12 flex justify-center px-4">
              <button
                style={{ fontFamily: "Archivo" }}
                onClick={handleFormSubmit}
                className="next-button"
              >
                {loading ? (
                  <ColorRing
                    visible={loading}
                    height="30"
                    width="30"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{
                      backgroundColor: "transparent",
                    }}
                    wrapperClass="color-ring-wrapper"
                    className="color-ring-wrapper"
                    colors={[
                      "#ffffff",
                      "#ffffff",
                      "#ffffff",
                      "#ffffff",
                      "#ffffff",
                      "#ffffff",
                    ]}
                  />
                ) : (
                  "NEXT"
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Modal */}
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: 40, height: 40 }}>
                <img
                  src={logo}
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </div>
              <IoClose
                onClick={() => setOpen(false)}
                style={{
                  alignSelf: "center",
                  fontWeight: 400,
                  fontSize: 24,
                  cursor: "pointer",
                }}
              />
            </div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#59266D",
                fontSize: 24,
                fontWeight: 700,
                marginTop: 16,
              }}
            >
              Wait! Your Card is Just a Step Away!
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              style={{
                color: "#8A8A8A",
                fontSize: 18,
                fontWeight: 400,
                fontFamily: "Archivo",
              }}
            >
              Complete the form to receive the best credit card tailored to your
              needs.
            </Typography>
            <div
              style={{
                backgroundColor: "#59266D",
                padding: 15,
                color: "#FFFFFF",
                borderRadius: 10,
                textAlign: "center",
                marginTop: "4%",
                fontFamily: "Archivo",
                fontWeight: 700,
                fontSize: 16,
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            >
              Keep Going
            </div>
            <div
              style={{
                backgroundColor: "#F6F6F6",
                padding: 15,
                color: "#59266D",
                borderRadius: 10,
                textAlign: "center",
                marginTop: "4%",
                fontFamily: "Archivo",
                fontWeight: 700,
                fontSize: 16,
                border: "1px solid #59266D",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              No Thanks
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Coapplicant;

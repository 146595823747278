import { useLocation, useParams } from "react-router-dom";
import { LoanPredictorWidget } from "./LoanPredictorWidget";
import { LoanStatusChecker } from "./LoanStatusChecker";
import { LoanStatusMethods } from "./LoanStatusMethods";
import { StatusExplanation } from "./StatusExplanation";
// import { KeyApprovalFactors } from "./KeyApprovalFactors";
import { MinemiTools } from "./MinemiTools";
import { Testimonials } from "./Testimonials";
// import { RelatedContent } from "./RelatedContent";
import { TrustIndicators } from "./TrustIndicators";
import { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";

import Faq from "./Faq";
import NotFoundPage from "../../ErrorPages/NotFoundPage";
// import MetadataContext from "../MetadataContext";
export default function Homeloan() {
  const { bankdetails } = useParams();
  const [data, setData] = useState(null);
  const [notFound, setNotFound] = useState(false);
  // const { setMetadata } = useContext(MetadataContext);

  const idbiData = useMemo(
    () => ({
      heading:
        "Check Your IDBI Bank Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your IDBI Bank home loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your IDBI Bank Home Loan Journey",
      h2des:
        "Checking your IDBI Bank home loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your loan approval chances.",
        },
      ],
    }),
    []
  );
  const lichousingData = useMemo(
    () => ({
      heading:
        "Check Your LIC Housing Finance Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your LIC Housing Finance home loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your LIC Housing Finance Home Loan Journey",
      h2des:
        "Checking your LIC Housing Finance home loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const icicihousingData = useMemo(
    () => ({
      heading:
        "Check Your ICICI Home Finance Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your ICICI Home Finance home loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your ICICI Home Finance Home Loan Journey",
      h2des:
        "Checking your ICICI Home Finance home loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const pnbhousingData = useMemo(
    () => ({
      heading:
        "Check Your PNB Housing Finance Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your PNB Housing Finance home loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your PNB Housing Finance Home Loan Journey",
      h2des:
        "Checking your PNB Housing Finance home loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const sbihousingData = useMemo(
    () => ({
      heading:
        "Check Your SBI Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your SBI Home Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your SBI Home Loan Journey",
      h2des:
        "Checking your SBI Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const adityahousingData = useMemo(
    () => ({
      heading:
        "Check Your Aditya Birla Finance Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your Aditya Birla Finance Home Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your Aditya Birla Finance Home Loan Journey",
      h2des:
        "Checking your Aditya Birla Finance Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );
  const lthousingData = useMemo(
    () => ({
      heading:
        "Check Your L&T Finance Home Loan Status Online - Track Your Application & Get Real-Time Updates!",
      paragraph:
        "Easily track your L&T Finance Home Loan application status using MinEMI's smart tools. Understand where your application stands and get personalized tips to improve your approval chances.",

      buttons: [
        {
          text: "Check Home Loan Status Instantly",
          link: "#loan-status-checker",
          style:
            "inline-flex h-12 items-center justify-center rounded-md border border-primary-purple px-6 text-sm font-medium text-white transition-colors bg-[#418388] hover:bg-[#311663]",
        },
        {
          text: "Get Approval Insights Now  ",
          link: "#loan-predictor",
          style:
            "inline-flex h-12 items-center justify-center rounded-md bg-white hover:bg-[#ebe8fb] px-6 text-sm font-medium transition-colors",
          customStyle: {
            color: "#311663",
            border: "1px solid #311663",
            backgroundColor: "transparent",
          },
        },
      ],
      h2: "Simplify Your L&T Finance Home Loan Journey",
      h2des:
        "Checking your L&T Finance Home Loan status is now easier than ever. With MinEMI's intelligent tools, you can track your application and improve your chances of approval.",
      features: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            </svg>
          ),
          title: "Secure Tracking",
          description:
            "Check your home loan status securely without compromising your personal information.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          title: "Real-Time Updates",
          description:
            "Get instant notifications about changes in your home loan application status.",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
          title: "Expert Guidance",
          description:
            "Receive personalized recommendations to improve your home loan approval chances.",
        },
      ],
    }),
    []
  );

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    switch (bankdetails) {
      case "checkidbihousingbankstatus":
        setData(idbiData);
        setNotFound(false);
        break;
      case "checklichousingbankstatus":
        setData(lichousingData);
        setNotFound(false);
        break;
      case "checkicicihousingbankstatus":
        setData(icicihousingData);
        setNotFound(false);
        break;
      case "checkpnbhousingbankstatus":
        setData(pnbhousingData);
        setNotFound(false);
        break;
      case "checksbihousingbankstatus":
        setData(sbihousingData);
        setNotFound(false);
        break;
      case "checkadityahousingbankstatus":
        setData(adityahousingData);
        setNotFound(false);
        break;
      case "checklthousingbankstatus":
        setData(lthousingData);
        setNotFound(false);
        break;
      default:
        setNotFound(true);
    }
  }, [
    bankdetails,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    sbihousingData,
    adityahousingData,
    lthousingData,
  ]);
  if (notFound) {
    return <NotFoundPage />;
  }

  if (!data) return null;

  return (
    <>
      <Helmet>
        <title>
          {bankdetails === "checkidbibankstatus"
            ? "IDBI Bank Home Loan Status - Track Your Loan Status Online"
            : bankdetails === "checklichousingbankstatus"
            ? "LIC Housing Finance Bank Home Loan Status - Track Your Loan Status Online"
            : bankdetails === "checkicicihousingbankstatus"
            ? "ICICI Housing Finance Bank Home Loan Status - Track Your Loan Status Online"
            : bankdetails === "checkpnbhousingbankstatus"
            ? "PNB Housing Finance Bank Home Loan Status - Track Your Loan Status Online"
            : bankdetails === "checksbihousingbankstatus"
            ? "SBI Housing Finance Bank Home Loan Status - Track Your Loan Status Online"
            : bankdetails === "checkadityahousingbankstatus"
            ? "Aditya Birla Housing Finance Bank Home Loan Status - Track Your Loan Status Online"
            : null}
        </title>
        <meta
          name="description"
          content={
            bankdetails === "checkidbibankstatus"
              ? "Check your IDBI Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checklichousingbankstatus"
              ? "Check your LIC Housing Finance Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkicicihousingbankstatus"
              ? "Check your ICICI Housing Finance Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkpnbhousingbankstatus"
              ? "Check your PNB Housing Finance Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checksbihousingbankstatus"
              ? "Check your SBI Housing Finance Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : bankdetails === "checkadityahousingbankstatus"
              ? "Check your Aditya Birla Housing Finance Bank Home loan status online. Get instant updates on your loan application status with MinEMI – fast, secure, and accurate."
              : null
          }
        />
      </Helmet>
      <div className=" min-h-screen flex-col">
        {/* Hero Section */}
        <section className="py-4 border-b border-gray-200 bg-[#ebe8fb]">
          <div className="container md:px-6 bg-transparent p-0">
            <div className="space-y-4 md:w-4/5 sd:w-full px-3 mx-auto bg-transparent">
              <h1 className="text-2xl font-bold tracking-tighter sm:text-3xl text-primary-purple bg-transparent ">
                {data.heading}
              </h1>
              <p className="text-dark-grey bg-transparent ">{data.paragraph}</p>
              <div className="flex flex-col gap-3  pt-2 bg-transparent">
                {data.buttons.map((button, index) => (
                  <a
                    key={index}
                    href={button.link}
                    className={button.style}
                    style={button.customStyle || {}}
                  >
                    {button.text}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Loan Predictor Widget */}
        <section
          id="loan-predictor"
          className="py-10 bg-white border-b border-gray-200"
        >
          <div className="container  md:px-6 bg-transparent p-0">
            <LoanPredictorWidget name={bankdetails} />
          </div>
        </section>

        {/* Features Section */}
        <section className="py-2 border-b border-gray-200 bg-[#ebe8fb]">
          <div className="container sb:px-4  md:px-6 bg-transparent">
            <div className="mx-auto md:w-4/5 sd:w-full text-center bg-transparent">
              <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple bg-transparent">
                {data.h2}
              </h2>
              <p className="text-dark-grey mb-8 bg-transparent">{data.h2des}</p>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 bg-transparent">
                {data.features.map((feature, index) => (
                  <div
                    key={index}
                    className="bg-white p-3 rounded-lg shadow-sm border border-gray-200"
                  >
                    <div className="h-10 w-10 rounded-full bg-light-bg flex items-center justify-center mx-auto mb-3 bg-[#ebe8fb]">
                      {feature.icon}
                    </div>
                    <h3 className="text-base font-bold text-primary-purple mb-2 bg-transparent">
                      {feature.title}
                    </h3>
                    <p className="text-dark-grey text-sm bg-transparent">
                      {feature.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Loan Tools Sections */}
        <LoanStatusChecker name={bankdetails} />
        <LoanStatusMethods name={bankdetails} />
        <StatusExplanation name={bankdetails} />
        {/* {bankdetails && <KeyApprovalFactors name={bankdetails} />} */}

        <MinemiTools name={bankdetails} />

        <Testimonials name={bankdetails} />
        {/* {bankdetails && <RelatedContent name={bankdetails} />} */}
        <Faq name={bankdetails} />
        <TrustIndicators name={bankdetails} />
      </div>
    </>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./HomeLoanEligiblityCriteria.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MetadataContext from "../../MetadataContext";
import RelatedLinks from "../../../Components/RelatedLinks.js/Relatedlinks";

const HomeLoanEligiblityCriteria = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [monthlyIncome, setMonthlyIncome] = useState(""); // ₹
  const [existingObligations, setExistingObligations] = useState(""); // ₹
  const [loanTenure, setLoanTenure] = useState(""); // months
  const [annualInterestRate, setAnnualInterestRate] = useState(""); // %
  const [eligibility, setEligibility] = useState("");
  const navigate = useNavigate();

  const currencyFormatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
  });

  const calculateLoanEligibility = () => {
    const P = monthlyIncome;
    const r = annualInterestRate;
    const n = loanTenure;

    // Parse inputs
    const foir = 0.5;
    const affordableEMI = P * foir - existingObligations;
    if (affordableEMI <= 0) {
      setEligibility("Not Eligible for Loan");
      return;
    }

    const monthlyRate = r / 12 / 100;

    const loanEligibility =
      (affordableEMI * (Math.pow(1 + monthlyRate, n) - 1)) /
      (monthlyRate * Math.pow(1 + monthlyRate, n));

    setEligibility(
      isNaN(loanEligibility) || loanEligibility <= 0
        ? "Not Eligible for Loan"
        : Math.round(loanEligibility)
    );
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-IN");
  };

  const handleAmountChange = (e, setter) => {
    const value = e.target.value.replace(/,/g, ""); // Remove commas
    if (/^\d*\.?\d*$/.test(value)) {
      const numericValue = Number(value);
      if (numericValue <= 100000000) {
        // Restrict to maximum 10,00,00,000
        setter(numericValue);
      }
    }
  };
  // useMediaqueris
  const isSmallScreen = useMediaQuery("(max-width:576px)");
  const isMediumScreen = useMediaQuery("(max-width:768px)");
  const isLargeScreen = useMediaQuery("(max-width:1080px)");

  const mainContent = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: isMediumScreen ? "column" : "row",
    gap: isMediumScreen ? "16px" : isLargeScreen ? "30px" : "90px",
  };
  const tableContent = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: isMediumScreen ? "column" : "row",
    gap: isMediumScreen ? "16px" : isLargeScreen ? "20px" : "55px",
  };

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Home Loan Eligibility Criteria",
      pageDescription:
        "Find out if you're eligible for a home loan within minutes! Discover the income, credit score, and age requirements needed to make your dream home a reality.",
      pageKeywords:
        "home loan eligibility, home loan criteria, home loan requirements, income requirement for home loan, credit score for home loan, age limit for home loan, home loan eligibility check, mortgage eligibility, home loan qualification, dream home loan eligibility",

      canonicalUrl:
        "https://minemi.ai/loanpage/homeloan/home-loan-eligibility-criteria",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          className="margin-less"
          style={{
            width: "100%",
            backgroundColor: "#E7E4F9",
            boxSizing: "border-box",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className="custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/homeloan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Home Loan
              </Link>{" "}
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Home Loan Check Eligibility
              </Link>{" "}
            </p>
          </div>

          <h1
            className="loanstatus-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "46px",

              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Check Your Home Loan Eligibility Instantly
          </h1>
          <p
            className="loanstatus-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Find out if you're eligible for a home loan within minutes! Discover
            the income, credit score, and age requirements needed to make your
            dream home a reality.
          </p>
        </div>
        <div
          className="tracking-status"
          style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}
        >
          <h2
            style={{
              marginTop: 35,
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 24,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Home Loan Eligibility Criteria
          </h2>
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 16,
              color: "#8A8A8A",
              marginTop: 8,
            }}
          ></p>

          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
              fontSize: 16,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                    }}
                  >
                    Eligibility Factor
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Minimum Requirement
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      borderTopRightRadius: 18,
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Impact on Home Loan Eligibility
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Income
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    ₹25,000/month (varies by lender) for salaried; ₹3-5 lakh
                    annual turnover for self-employed
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Higher income ensures better loan repayment capacity,
                    increasing the loan amount eligible.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Credit Score
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    650–750 or above (minimum varies by lender)
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    A higher credit score reflects good creditworthiness,
                    leading to faster approvals and possibly lower interest
                    rates.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Employment Status
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Salaried with 2–3 years of work experience or self-employed
                    with 3+ years in business
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Stability in employment or business enhances repayment
                    assurance for lenders.
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Age
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    21–60 years for salaried; up to 65 years for self-employed
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Younger applicants have longer repayment tenure, increasing
                    eligibility, while older applicants might face tenure
                    limitations.{" "}
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                    borderBottomRightRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      // borderBottomLeftRadius: 18,
                    }}
                  >
                    Fixed Obligations to Income Ratio (FOIR)
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    40%–60% (depends on lender)
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                      border: "1px solid #ddd",
                      // borderBottomRightRadius: 18,
                    }}
                  >
                    Lower FOIR indicates less existing debt and better ability
                    to handle new loan EMIs, enhancing approval chances.
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      // borderBottomLeftRadius: 24,
                      border: "1px solid #ddd",
                    }}
                  >
                    Co-Applicant (if Eligibility Factor applicable)
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                      border: "1px solid #ddd",
                    }}
                  >
                    Spouse, parents, or other family Minimum Requirement members
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                      // borderBottomRightRadius: 24,
                      border: "1px solid #ddd",
                    }}
                  >
                    Adding a co-applicant increases the total Impact on Home
                    Loan Eligibility eligible income, improving loan
                    eligibility.
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      // borderBottomLeftRadius: 24,
                      border: "1px solid #ddd",
                    }}
                  >
                    Loan-to-Value (LTV) Ratio
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                      border: "1px solid #ddd",
                    }}
                  >
                    Up to 75%–90% of the property value, based on type and
                    location
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                      // borderBottomRightRadius: 24,
                      border: "1px solid #ddd",
                    }}
                  >
                    Higher down payments (lower LTV) demonstrate financial
                    stability and reduce lender risk, increasing approval
                    likelihood.
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      // borderBottomLeftRadius: 24,
                      border: "1px solid #ddd",
                    }}
                  >
                    Property Type and Legal Status
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                      border: "1px solid #ddd",
                    }}
                  >
                    Residential properties with clear titles
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                      // borderBottomRightRadius: 24,
                      border: "1px solid #ddd",
                    }}
                  >
                    Lenders prioritize legally compliant properties, ensuring
                    secure investment and avoiding disputes.
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      borderBottomLeftRadius: 18,
                    }}
                  >
                    Document Completeness
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    KYC, income proof, property documents, and bank statements
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                      borderBottomRightRadius: 18,
                    }}
                  >
                    Accurate and complete documentation accelerates loan
                    approval and avoids delays or rejections.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className="make-it-center increase-margin "
            style={{
              marginTop: "5%",
              marginBottom: "2%",
              padding: 24,
              background: "linear-gradient(91.7deg, #EADCFF 0.4%, #FFFFFF 99%)",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 20,
              borderRadius: 24,
            }}
          >
            <div
              style={{
                marginLeft: "4%",
                backgroundColor: "transparent",
                display: "flex",
                flexDirection: "column",
                gap: 24,
                borderRadius: 24,
                alignSelf: "center",
              }}
            >
              <p
                className="eligiblity-criteria"
                style={{
                  fontFamily: "Archivo",
                  color: "#59266D",
                  fontSize: 40,
                  backgroundColor: "transparent",
                }}
              >
                <i style={{ backgroundColor: "transparent", lineHeight: 1.2 }}>
                  Check Your Loan Eligibility Without{" "}
                  <br className="brake-remove" /> Impacting Your Credit Score
                </i>
              </p>
              <a
                href="https://minemi.ai/home-loan/check-eligibility/"
                className=""
                style={{
                  backgroundColor: "#59266D",
                  color: "#FFFFFF",
                  width: isSmallScreen ? "100%" : "40%",
                  borderRadius: 8,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 18,
                  paddingTop: 8,
                  paddingBottom: 8,
                  textAlign: "center",
                }}
              >
                Check Eligibility
              </a>
            </div>
            <div
              style={{
                width: 200,
                height: 195,
                backgroundColor: "transparent",
                marginRight: "4%",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
              }}
            >
              <img
                src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Illustration-1736403318651.webp"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                loading="lazy"
                alt="check eligibility"
              />
            </div>
          </div>

          <h3
            style={{
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 24,
              color: "#1F1F1F",
              lineHeight: 1.2,
              marginTop: 25,
            }}
          >
            Eligibility Criteria by Lender
          </h3>

          <div
            style={{
              backgroundColor: "#E7E4F9",
              border: "1px solid #D9D9D9",
              borderRadius: 12,
              marginTop: 24,
            }}
          >
            <p
              style={{
                color: "#59266D",
                backgroundColor: "#E7E4F9",
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                marginTop: 14,
                marginLeft: "1%",
                paddingBottom: 0,
              }}
            >
              Loan Criteria
            </p>
            <div
              style={{
                backgroundColor: "#E7E4F9",
                border: "1px solid #D9D9D9",
                borderRadius: 12,
                marginTop: 24,
              }}
            >
              <div
                style={{
                  backgroundColor: "#FFFFFF",
                  padding: 16,
                  borderRadius: 12,
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              >
                <div
                  className="main-box"
                  style={{ backgroundColor: "#FFFFFF" }}
                >
                  <div className="" style={mainContent}>
                    <div
                      className="bank-image"
                      style={{
                        width: 141,
                        height: 62,
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <img
                        src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/image%2048-1736403678444.webp"
                        alt="Eligibility Criteria"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          backgroundColor: "#FFFFFF",
                        }}
                        loading="lazy"
                      />
                    </div>
                    <div className="" style={tableContent}>
                      <div
                        className="headingss"
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          backgroundColor: "#FFFFFF",
                          justifyContent: "start",
                        }}
                      >
                        <p
                          className="main-h"
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#8A8A8A",
                            alignSelf: "start",
                          }}
                        >
                          Minimum Credit Score
                        </p>
                        <p
                          className="main-sub"
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          700+
                        </p>
                      </div>
                      <div
                        className="headingss"
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          backgroundColor: "#FFFFFF",
                          justifyContent: "start",
                        }}
                      >
                        <p
                          className="main-h"
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#8A8A8A",
                            alignSelf: "start",
                          }}
                        >
                          Income Requirement
                        </p>
                        <p
                          className="main-sub"
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          ₹10,000/month for <br />
                          salaried individuals; <br /> ₹2,00,000/year for <br />
                          self-employed
                        </p>
                      </div>
                      <div
                        className="headingss"
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          backgroundColor: "#FFFFFF",
                          justifyContent: "start",
                        }}
                      >
                        <p
                          className="main-h"
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#8A8A8A",
                            alignSelf: "start",
                          }}
                        >
                          Maximum Loan Tenure
                        </p>
                        <p
                          className="main-sub"
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          {" "}
                          Up to 30 years
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      navigate(
                        "/loanpage/homeloan/home-loan-eligibility-criteria/hdfc-home-loan-eligibility"
                      )
                    }
                    className="view-detail"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 256,
                      fontFamily: "Archivo",
                      color: "#FFFFFF",
                      fontWeight: 500,
                      fontSize: 18,
                    }}
                  >
                    HDFC Loan Eligibility
                  </div>
                </div>
                <div
                  className="main-box"
                  style={{ backgroundColor: "#FFFFFF" }}
                >
                  <div className="" style={mainContent}>
                    <div
                      className="bank-image"
                      style={{
                        width: 141,
                        height: 62,
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <img
                        src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/sbi-1736403898225.webp"
                        alt="sbi"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          backgroundColor: "#FFFFFF",
                        }}
                        loading="lazy"
                      />
                    </div>

                    <div className="" style={tableContent}>
                      <div
                        className="headingss"
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          backgroundColor: "#FFFFFF",
                          justifyContent: "start",
                        }}
                      >
                        <p
                          className="main-h"
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#8A8A8A",
                            alignSelf: "start",
                          }}
                        >
                          Minimum Credit Score
                        </p>
                        <p
                          className="main-sub"
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          700+
                        </p>
                      </div>

                      <div
                        className="headingss"
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          backgroundColor: "#FFFFFF",
                          justifyContent: "start",
                        }}
                      >
                        <p
                          className="main-h"
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#8A8A8A",
                            alignSelf: "start",
                          }}
                        >
                          Income Requirement
                        </p>
                        <p
                          className="main-sub"
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          Not explicitly stated; <br />
                          depends on FOIR
                        </p>
                      </div>
                      <div
                        className="headingss"
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          backgroundColor: "#FFFFFF",
                          justifyContent: "start",
                        }}
                      >
                        <p
                          className="main-h"
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#8A8A8A",
                            alignSelf: "start",
                          }}
                        >
                          Maximum Loan Tenure
                        </p>
                        <p
                          className="main-sub"
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          Up to 30 years
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      navigate(
                        "/loanpage/homeloan/home-loan-eligibility-criteria/sbi-home-loan-eligibility"
                      )
                    }
                    className="view-detail"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 256,
                      fontFamily: "Archivo",
                      color: "#FFFFFF",
                      fontWeight: 500,
                      fontSize: 18,
                    }}
                  >
                    SBI Loan Eligibility
                  </div>
                </div>
                <div
                  className="main-box"
                  style={{ backgroundColor: "#FFFFFF" }}
                >
                  <div className="" style={mainContent}>
                    <div
                      className="bank-image"
                      style={{
                        width: 141,
                        height: 62,
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <img
                        src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/icici-1736403726941.webp"
                        alt="ICICI"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          backgroundColor: "#FFFFFF",
                        }}
                        loading="lazy"
                      />
                    </div>

                    <div className="" style={tableContent}>
                      <div
                        className="headingss"
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          backgroundColor: "#FFFFFF",
                          justifyContent: "start",
                        }}
                      >
                        <p
                          className="main-h"
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#8A8A8A",
                            alignSelf: "start",
                          }}
                        >
                          Minimum Credit Score
                        </p>
                        <p
                          className="main-sub"
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          700+
                        </p>
                      </div>
                      <div
                        className="headingss"
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          backgroundColor: "#FFFFFF",
                          justifyContent: "start",
                        }}
                      >
                        <p
                          className="main-h"
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#8A8A8A",
                            alignSelf: "start",
                          }}
                        >
                          Income Requirement
                        </p>
                        <p
                          className="main-sub"
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          ₹25,000/month <br /> minimum for <br />
                          salaried individuals
                        </p>
                      </div>
                      <div
                        className="headingss"
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          backgroundColor: "#FFFFFF",
                          justifyContent: "start",
                        }}
                      >
                        <p
                          className="main-h"
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#8A8A8A",
                            alignSelf: "start",
                          }}
                        >
                          Maximum Loan Tenure
                        </p>
                        <p
                          className="main-sub"
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          Up to 30 years
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      navigate(
                        "/loanpage/homeloan/home-loan-eligibility-criteria/icici-home-loan-eligibility"
                      )
                    }
                    className="view-detail"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 256,
                      fontFamily: "Archivo",
                      color: "#FFFFFF",
                      fontWeight: 500,
                      fontSize: 18,
                    }}
                  >
                    ICICI Loan Eligibility
                  </div>
                </div>
                <div
                  className="main-box"
                  style={{
                    backgroundColor: "#FFFFFF",
                    // borderBottom: "1px solid #D9D9D9",
                    paddingBottom: 15,
                  }}
                >
                  <div className="" style={mainContent}>
                    <div
                      className="bank-image"
                      style={{
                        width: 141,
                        height: 62,
                        backgroundColor: "#FFFFFF",
                      }}
                    >
                      <img
                        src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/axis-1736403836726.webp"
                        alt="axis"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          backgroundColor: "#FFFFFF",
                        }}
                        loading="lazy"
                      />
                    </div>

                    <div className="" style={tableContent}>
                      <div
                        className="headingss"
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          backgroundColor: "#FFFFFF",
                          justifyContent: "start",
                        }}
                      >
                        <p
                          className="main-h"
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#8A8A8A",
                            alignSelf: "start",
                          }}
                        >
                          Minimum Credit Score
                        </p>
                        <p
                          className="main-sub"
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          700+
                        </p>
                      </div>
                      <div
                        className="headingss"
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          backgroundColor: "#FFFFFF",
                          justifyContent: "start",
                        }}
                      >
                        <p
                          className="main-h"
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#8A8A8A",
                            alignSelf: "start",
                          }}
                        >
                          Income Requirement
                        </p>
                        <p
                          className="main-sub"
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          ₹1,80,000/year <br />
                          minimum
                        </p>
                      </div>
                      <div
                        className="headingss"
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          backgroundColor: "#FFFFFF",
                          justifyContent: "start",
                        }}
                      >
                        <p
                          className="main-h"
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#8A8A8A",
                            alignSelf: "start",
                          }}
                        >
                          Maximum Loan Tenure
                        </p>
                        <p
                          className="main-sub"
                          style={{ backgroundColor: "#FFFFFF" }}
                        >
                          Up to 30 years
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() =>
                      navigate(
                        "/loanpage/homeloan/home-loan-eligibility-criteria/axis-home-loan-eligibility"
                      )
                    }
                    className="view-detail"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 256,
                      fontFamily: "Archivo",
                      color: "#FFFFFF",
                      fontWeight: 500,
                      fontSize: 18,
                    }}
                  >
                    AXIS Loan Eligibility
                  </div>
                </div>

                {/* <div
                style={{
                  textAlign: "center",
                  backgroundColor: "white",
                  padding: 5,
                  fontFamily: "Archivo",
                  fontWeight: 600,
                  color: "#1F1F1F",
                  fontSize: 18,
                }}
              >
                Load More..
              </div> */}
              </div>
            </div>
          </div>
          <h3
            style={{
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 24,
              color: "#1F1F1F",
              lineHeight: 1.2,
              marginTop: 25,
            }}
          >
            Understand Your Eligibility for a Home Loan
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Eligibility for a home loan depends on several key factors that
            lenders assess to determine your repayment ability and financial
            stability. By understanding these criteria, you can prepare better
            and maximize your chances of securing a favorable loan.
          </div>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Income and Employment Stability:
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • Salaried Individuals:
                  </span>{" "}
                  Consistent income with a minimum threshold as specified by the
                  lender.
                </div>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • Self-Employed Individuals:
                  </span>{" "}
                  Regular business income and proof of stability over 2–3
                  years.          
                </div>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • 
                  </span>{" "}
                  Higher income enhances loan approval chances and allows for a
                  greater loan amount.          
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Credit Score and History:
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • 
                  </span>{" "}
                  A <strong>credit score of 700 or above</strong> is preferred
                  by most lenders.          
                </div>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • 
                  </span>{" "}
                  A good credit history with timely payments on existing loans
                  or credit cards reflects financial discipline.          
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Age of the Applicant:
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • 
                  </span>{" "}
                  Most lenders consider applicants aged{" "}
                  <strong>21 to 65 years</strong>.          
                </div>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • 
                  </span>{" "}
                  Younger applicants may qualify for longer loan tenures, while
                  older applicants are evaluated based on retirement plans and
                  other income sources.         
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Employment Type and Tenure:
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • Salaried Professionals:
                  </span>{" "}
                  At least 1 year of continuous employment with the current
                  organization.          
                </div>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • Self-Employed Professionals:
                  </span>{" "}
                  A minimum of 2–3 years of stable income in the same business
                  or profession.          
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  FOIR (Fixed Obligations to Income Ratio):
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • 
                  </span>{" "}
                  FOIR should ideally be under 50%, indicating manageable debt
                  relative to income.          
                </div>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • 
                  </span>{" "}
                  A lower FOIR increases your repayment capacity and loan
                  eligibility.          
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Property Value and Loan-to-Value Ratio (LTV):
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • 
                  </span>{" "}
                  The LTV ratio, usually capped at <strong>75–90%</strong> of
                  the property’s market value, determines the maximum loan
                  amount.          
                </div>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • 
                  </span>{" "}
                  Higher down payments reduce the loan amount and risk for
                  lenders, improving approval chances.          
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Co-Applicant’s Role:
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • 
                  </span>{" "}
                  Adding a co-applicant, especially one with a steady income,
                  can enhance loan eligibility and approval chances.          
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Existing Liabilities:
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • 
                  </span>{" "}
                  Lenders assess your current debts, including EMIs, credit card
                  bills, and other obligations.          
                </div>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • 
                  </span>{" "}
                  Lower outstanding liabilities improve your financial
                  profile.          
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Documentation:
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • 
                  </span>{" "}
                  Proper submission of income proof, KYC documents, and property
                  details is crucial. Missing documents can delay or negatively
                  affect approval.          
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Employer Reputation (for Salaried Applicants):
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • 
                  </span>{" "}
                  Working for a reputed organization or being a government
                  employee can positively influence eligibility as it indicates
                  job security.          
                </div>
              </div>
            </div>{" "}
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Location of Property:
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • 
                  </span>{" "}
                  Properties in approved locations or from trusted builders may
                  improve eligibility as they lower the risk for the
                  lender.          
                </div>
              </div>
            </div>
          </div>
          <h3
            style={{
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 24,
              color: "#1F1F1F",
              lineHeight: 1.2,
              marginTop: 25,
            }}
          >
            Use Our Eligibility Calculator to Get Personalized Results
          </h3>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Using our personal loan eligibility calculator, you can instantly
            assess how much you can borrow based on your financial profile. The
            calculator considers your income, current EMIs, credit score, and
            more to give you a personalized result. This helps you understand
            your borrowing capacity before submitting an official loan
            application.{" "}
          </p>

          <p
            style={{
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            If you don’t meet the eligibility criteria or want to increase your
            chances of approval, here are some tips:
          </p>
          <div
            className="main-inner-box-eligiblity increase-margin "
            style={{
              marginTop: "2%",
              backgroundColor: "white",
              borderRadius: 24,
              border: "2px solid #846CA6",
              borderRight: 0,
            }}
          >
            <div
              className=" loan-criteria-box "
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                // flexWrap: "wrap",
                backgroundColor: "white",
                borderRadius: 24,
                gap: "30px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 24,
                  backgroundColor: "white",
                  margin: 32,
                  width: "40%",
                }}
                className="box-eligiblity-input-field"
              >
                <div
                  className="input-fiedl"
                  style={{
                    width: "85%",
                    border: "1px solid #846CA6",
                    borderRadius: 8,
                    position: "relative",
                    backgroundColor: "#FFFFFF",
                    padding: "15px",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      left: "15px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      backgroundColor: "white",
                      color: "#846CA6",
                      fontSize: "18px",
                    }}
                  >
                    ₹
                  </span>
                  <input
                    className="input-fiedl-inner"
                    placeholder="Monthly Income"
                    style={{
                      width: "100%",
                      border: "0px solid white",
                      padding: "0 0 0 15px",
                      outline: "none",
                      fontSize: "16px",
                      backgroundColor: "white",
                    }}
                    value={formatCurrency(monthlyIncome)} // Display value with commas
                    onChange={(e) => handleAmountChange(e, setMonthlyIncome)}
                  />
                </div>
                <div
                  className="input-fiedl"
                  style={{
                    width: "85%",
                    border: "1px solid #846CA6",
                    borderRadius: 8,
                    position: "relative",
                    backgroundColor: "#FFFFFF",
                    padding: "15px",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      left: "15px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      backgroundColor: "white",
                      color: "#846CA6",
                      fontSize: "18px",
                    }}
                  >
                    ₹
                  </span>
                  <input
                    className="input-fiedl-inner"
                    placeholder="Existing Monthly Obligations"
                    style={{
                      width: "100%",
                      border: "0px solid white",
                      padding: "0 0 0 15px",
                      outline: "none",
                      fontSize: "16px",
                      backgroundColor: "white",
                    }}
                    value={formatCurrency(existingObligations)} // Display value with commas
                    onChange={(e) =>
                      handleAmountChange(e, setExistingObligations)
                    }
                  />
                </div>
                <div
                  className="input-fiedl"
                  style={{
                    width: "85%",
                    border: "1px solid #846CA6",
                    borderRadius: 8,
                    backgroundColor: "#FFFFFF",
                    position: "relative",
                    padding: "15px",
                  }}
                >
                  <input
                    className="input-fiedl-inner"
                    placeholder="Desired Loan Tenure"
                    style={{
                      width: "90%",
                      border: "0px solid white",
                      padding: "0 15px 0 15px",
                      outline: "none",
                      fontSize: "16px",
                      backgroundColor: "white",
                    }}
                    value={loanTenure}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (
                        !isNaN(value) &&
                        Number.isInteger(+value) &&
                        value >= 0
                      ) {
                        setLoanTenure(value);
                      }
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "15px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      backgroundColor: "white",
                      color: "#846CA6",
                      fontSize: "18px",
                    }}
                  >
                    months
                  </span>
                </div>
                <div
                  className="input-fiedl"
                  style={{
                    width: "85%",
                    border: "1px solid #846CA6",
                    borderRadius: 8,
                    backgroundColor: "#FFFFFF",
                    position: "relative",
                    padding: "15px",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      left: "15px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      backgroundColor: "white",
                      color: "#846CA6",
                      fontSize: "18px",
                    }}
                  >
                    %
                  </span>
                  <input
                    className="input-fiedl-inner"
                    placeholder="Interest Rate"
                    style={{
                      width: "90%",
                      border: "0px solid white",
                      padding: "0 0 0 20px",
                      outline: "none",
                      fontSize: "16px",
                      backgroundColor: "white",
                    }}
                    value={annualInterestRate}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!isNaN(value) && value >= 0 && value <= 99) {
                        setAnnualInterestRate(value);
                      } else if (value > 99) {
                        setAnnualInterestRate(99); // Optionally set it to 99 if the user exceeds the limit
                      } else if (value === 0) {
                        setAnnualInterestRate(0); // Optionally set it to 99 if the user exceeds the limit
                      }
                    }}
                  />
                </div>
                <div
                  className="input-fiedl"
                  style={{
                    width: "85%",
                    border: "1px solid #846CA6",
                    borderRadius: 8,
                    backgroundColor: "#59266D",
                    color: "white",
                    height: 48,
                    fontFamily: "Archivo",
                    fontWeight: 700,
                    fontSize: 16,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => calculateLoanEligibility()}
                >
                  Submit
                </div>
              </div>
              <div
                className="box-eligiblity-loan-amount"
                style={{
                  width: "50%",
                  padding: 62,
                  backgroundColor: "#846CA6",
                  margin: 0,
                  borderTopRightRadius: 24,
                  borderBottomRightRadius: 24,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "transparent",
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "transparent",
                      color: "white",
                      fontFamily: "Archivo",
                      fontWeight: 700,
                      fontSize: 32,
                    }}
                  >
                    Eligible Loan Amount
                  </div>
                  <div
                    className="eligible-loan-amount-box-button"
                    style={{
                      width: 400,
                      height: 80,
                      borderRadius: 16,
                      color: "#59266D",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontWeight: 700,
                      fontSize: 40,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {isNaN(eligibility) || eligibility === "₹0" ? (
                      <span
                        style={{
                          fontSize: "24px",
                          backgroundColor: "transparent",
                        }}
                      >
                        Not Eligible for Loan
                      </span>
                    ) : (
                      currencyFormatter.format(eligibility)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Improve Your Home Loan Eligibility
          </h3>
          <p
            style={{
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            If you don’t meet the eligibility criteria or want to increase your
            chances of approval, here are some tips:
          </p>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                1. Maintain a High Credit Score:
              </span>{" "}
              Ensure a credit score above 700 by paying existing EMIs and credit
              card bills on time. A strong credit profile boosts lender
              confidence.
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                2. Lower Existing Debts:
              </span>{" "}
              Reduce your Fixed Obligations to Income Ratio (FOIR) by clearing
              outstanding loans or credit card balances before applying.
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                3. Increase Your Income Sources:
              </span>{" "}
              Supplement your regular income with additional earnings, such as
              freelancing or rental income, to showcase higher repayment
              capacity.
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                4. Opt for a Longer Loan Tenure:
              </span>{" "}
              Selecting an extended tenure reduces monthly EMI burdens,
              improving affordability and approval chances.
            </div>
            <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
              <span style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}>
                5. Provide a Higher Down Payment:
              </span>{" "}
              Making a substantial down payment reduces the lender's risk and
              increases your chances of securing a better deal on the loan.
            </div>
          </div>

          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Frequently Asked Questions (FAQs){" "}
          </h3>
          <div
            className="accordion "
            style={{
              marginTop: 9,

              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What is home loan eligibility?
              </AccordionSummary>
              <AccordionDetails>
                Home loan eligibility refers to the criteria that banks or
                financial institutions use to determine whether an individual
                qualifies for a home loan. It depends on factors such as income,
                age, credit score, employment stability, and existing
                liabilities.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                How can I check my home loan eligibility online?
              </AccordionSummary>
              <AccordionDetails>
                You can check your home loan eligibility online using Minemi’s
                home loan eligibility calculator. Enter your details, such as
                income, loan tenure, and existing EMIs, to get an instant
                assessment.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What factors affect my home loan eligibility?
              </AccordionSummary>
              <AccordionDetails>
                <span> Key factors include:</span>
                <ul className="mb-0 pb-0">
                  <li
                    style={{ color: "#1f1f1f", fontWeight: 400 }}
                    className="mt-1 mb-2"
                  >
                    Monthly income
                  </li>
                  <li
                    style={{ color: "#1f1f1f", fontWeight: 400 }}
                    className="mb-2"
                  >
                    Age of the applicant
                  </li>
                  <li
                    style={{ color: "#1f1f1f", fontWeight: 400 }}
                    className="mb-2"
                  >
                    Employment type (salaried or self-employed)
                  </li>
                  <li
                    style={{ color: "#1f1f1f", fontWeight: 400 }}
                    className="mb-2"
                  >
                    Credit score (ideally 750 and above)
                  </li>
                  <li
                    style={{ color: "#1f1f1f", fontWeight: 400 }}
                    className="mb-2"
                  >
                    Existing liabilities or EMIs
                  </li>
                  <li
                    style={{ color: "#1f1f1f", fontWeight: 400 }}
                    className="mb-0 pb-0"
                  >
                    Property value and loan-to-value (LTV) ratio
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What is the minimum salary required for a home loan?
              </AccordionSummary>
              <AccordionDetails>
                The minimum salary requirement varies by lender. Generally,
                banks require a minimum monthly income of ₹20,000 for salaried
                individuals and ₹25,000 for self-employed professionals.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Can I get a home loan with a low credit score?
              </AccordionSummary>
              <AccordionDetails>
                Yes, it is possible, but lenders may offer a lower loan amount
                or higher interest rates. A credit score above 750 is generally
                preferred for favorable terms.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What documents are required to check home loan eligibility?
              </AccordionSummary>
              <AccordionDetails>
                <span>Commonly required documents include:</span>
                <ul className="mb-0 pb-0">
                  <li
                    style={{ color: "#1f1f1f", fontWeight: 400 }}
                    className="mt-1 mb-2"
                  >
                    Proof of identity (Aadhaar, PAN)
                  </li>
                  <li
                    style={{ color: "#1f1f1f", fontWeight: 400 }}
                    className="mb-2"
                  >
                    Proof of income (salary slips, bank statements, ITR)
                  </li>
                  <li
                    style={{ color: "#1f1f1f", fontWeight: 400 }}
                    className="mb-2"
                  >
                    Proof of residence
                  </li>
                  <li
                    style={{ color: "#1f1f1f", fontWeight: 400 }}
                    className="mb-2"
                  >
                    Property documents (if identified)
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                How does loan tenure affect my eligibility?
              </AccordionSummary>
              <AccordionDetails>
                A longer loan tenure increases your loan eligibility by reducing
                your monthly EMI burden. However, it also increases the total
                interest paid over the tenure.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What is the maximum home loan amount I can get?
              </AccordionSummary>
              <AccordionDetails>
                The loan amount depends on your income, credit profile, and
                property value. Typically, lenders offer 75%-90% of the
                property's market value as a loan.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Can I add a co-applicant to improve my eligibility?
              </AccordionSummary>
              <AccordionDetails>
                Yes, adding a co-applicant (spouse or family member) with a
                stable income can increase your loan eligibility and reduce the
                risk for lenders.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What is the Loan-to-Value (LTV) ratio?
              </AccordionSummary>
              <AccordionDetails>
                The LTV ratio is the percentage of the property value that a
                bank will finance. For example, an 80% LTV ratio means the
                lender will finance 80% of the property value, and you must
                arrange the remaining 20%.
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br className="display-none" /> and tailored just for you—apply
                now to see what you qualify for!
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                  backgroundColor: "transparent",
                  marginTop: "3%",
                }}
              >
                <a
                  href="https://minemi.ai/home-loan/check-eligibility/"
                  className="eligiblity-buttons"
                  style={{
                    backgroundColor: "#59266D",
                    color: "#FFFFFF",
                    width: "18%",
                    borderRadius: 8,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 18,
                    paddingTop: 8,
                    paddingBottom: 8,
                    textAlign: "center",
                  }}
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <RelatedLinks
          title="Explore More Loan Eligiblity Options"
          description="Discover other financial products and services that might interest you"
          params="/loanpage/homeloan/home-loan-eligibility-criteria"
        />
      </div>
    </>
  );
};

export default HomeLoanEligiblityCriteria;

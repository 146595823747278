import React from "react";
import { useState, useEffect } from "react";
import { Star, Filter, ArrowUpDown, Check } from "lucide-react";
import { Link } from "react-router-dom";
import icici from "../../../Assets/Images/ICICIBank-circular.webp";
import hdfc from "../../../Assets/Images/hdfc.png";
import bajaj from "../../../Assets/Images/BF-photoaidcom-cropped.webp";
import Mirai from "../../../Assets/Images/MiraiAssetsCircular.webp";
import aditya from "../../../Assets/Images/ABF.webp";
import BF from "../../../Assets/Images/BF-photoaidcom-cropped.webp";
import volt from "../../../Assets/Images/VoltCircular.webp";
import tata from "../../../Assets/Images/tata-capital.png";

const sharesLenders = [
  {
    name: "Bajaj Finserv",
    img: bajaj,
    type: "NBFC",
    rating: 5.0,
    bestFor: "Best for high-value loans",
    rate: "11.50-13.50%",
    ltv: "Up to 70%",
    fee: "1.00-2.00%",
    maxLoan: "No upper limit",
    processingTime: "24-48 hours",
    minPortfolio: "₹2,00,000",
    link: "https://www.bajajfinserv.in/loan-against-shares",
  },
  {
    name: "ICICI Bank",
    img: icici,
    type: "Bank",
    rating: 4.5,
    bestFor: "Best for existing bank customers",
    rate: "10.50-12.00%",
    ltv: "Up to 50%",
    fee: "0.50-1.00%",
    maxLoan: "Up to ₹20,00,000",
    processingTime: "3-5 days",
    minPortfolio: "₹4,00,000",
    link: "https://www.icicibank.com/personal-banking/loans/loan-against-securities",
  },
  {
    name: "HDFC Bank",
    img: hdfc,
    type: "Bank",
    rating: 4.5,
    bestFor: "Best for diverse portfolio acceptance",
    rate: "10.75-12.25%",
    ltv: "Up to 50%",
    fee: "0.50-1.00%",
    maxLoan: "Up to ₹20,00,000",
    processingTime: "3-7 days",
    minPortfolio: "₹3,00,000",
    link: "https://apply.hdfcbank.com/vivid/las#LAS_Authentication",
  },
  {
    name: "Mirae Asset",
    img: Mirai,
    type: "Fintech",
    rating: 4.0,
    bestFor: "Best for quick processing",
    rate: "12.00-14.00%",
    ltv: "Up to 65%",
    fee: "1.00-1.50%",
    maxLoan: "Up to ₹5,00,00,000",
    processingTime: "24 hours",
    minPortfolio: "₹1,00,000",
    link: "https://transact.miraeassetfin.com/register?_gl=1*mr3huj*_gcl_au*NTg1OTM1NDYxLjE3NDIwNDUyMjg.*_ga*MTA4NzQ2NTU2OC4xNzQyMDQ1MjI5*_ga_21E4CJGJYQ*MTc0MjA0NTIyOC4xLjAuMTc0MjA0NTIyOC42MC4wLjA.",
  },
  {
    name: "Aditya Birla Finance",
    img: aditya,
    type: "NBFC",
    rating: 4.5,
    bestFor: "Best for flexible repayment",
    rate: "11.75-13.75%",
    ltv: "Up to 65%",
    fee: "1.00-1.50%",
    maxLoan: "Up to ₹10,00,00,000",
    processingTime: "48-72 hours",
    minPortfolio: "₹2,50,000",
    link: "https://finance.adityabirlacapital.com/loan-against-securities/apply-now",
  },
  {
    name: "Volt",
    img: volt,
    type: "Fintech",
    rating: 4.0,
    bestFor: "Best for digital experience",
    rate: "12.50-14.50%",
    ltv: "Up to 60%",
    fee: "1.00-2.00%",
    maxLoan: "Up to ₹5,00,00,000",
    processingTime: "24-48 hours",
    minPortfolio: "₹1,50,000",
    link: "https://voltmoney.in/",
  },
];

const mfLenders = [
  {
    name: "Aditya Birla Finance",
    img: aditya,
    type: "NBFC",
    rating: 4.5,
    bestFor: "Best for high-value loans",
    rate: "11.25-13.25%",
    ltv: "Up to 80%",
    fee: "1.00-1.50%",
    maxLoan: "No upper limit",
    processingTime: "48-72 hours",
    minPortfolio: "₹2,50,000",
    link: "https://finance.adityabirlacapital.com/loan-against-securities/apply-now",
  },
  {
    name: "HDFC Bank",
    img: hdfc,
    type: "Bank",
    rating: 5.0,
    bestFor: "Best overall for mutual fund loans",
    rate: "10.25-11.75%",
    ltv: "Up to 65%",
    fee: "0.50-1.00%",
    maxLoan: "Up to ₹20,00,000",
    processingTime: "3-5 days",
    minPortfolio: "₹3,00,000",
    link: "https://apply.hdfcbank.com/vivid/las#LAS_Authentication",
  },
  {
    name: "ICICI Bank",
    img: icici,
    type: "Bank",
    rating: 4.5,
    bestFor: "Best for existing customers",
    rate: "10.50-12.00%",
    ltv: "Up to 60%",
    fee: "0.50-1.00%",
    maxLoan: "Up to ₹20,00,000",
    processingTime: "3-7 days",
    minPortfolio: "₹4,00,000",
    link: "https://www.icicibank.com/personal-banking/loans/loan-against-securities",
  },
  {
    name: "Bajaj Finserv",
    img: bajaj,
    type: "NBFC",
    rating: 4.5,
    bestFor: "Best for quick processing",
    rate: "11.50-13.50%",
    ltv: "Up to 75%",
    fee: "1.00-2.00%",
    maxLoan: "No upper limit",
    processingTime: "24-48 hours",
    minPortfolio: "₹2,00,000",
    link: "https://www.bajajfinserv.in/loan-against-shares",
  },
  {
    name: "Mirae Asset",
    img: Mirai,
    type: "Fintech",
    rating: 4.0,
    bestFor: "Best for digital experience",
    rate: "11.75-13.75%",
    ltv: "Up to 70%",
    fee: "1.00-1.50%",
    maxLoan: "Up to ₹5,00,00,000",
    processingTime: "24 hours",
    minPortfolio: "₹1,00,000",
    link: "https://transact.miraeassetfin.com/register?_gl=1*mr3huj*_gcl_au*NTg1OTM1NDYxLjE3NDIwNDUyMjg.*_ga*MTA4NzQ2NTU2OC4xNzQyMDQ1MjI5*_ga_21E4CJGJYQ*MTc0MjA0NTIyOC4xLjAuMTc0MjA0NTIyOC42MC4wLjA.",
  },
  {
    name: "Tata Capital",
    img: tata,
    type: "NBFC",
    rating: 4.0,
    bestFor: "Best for flexible repayment",
    rate: "11.50-13.50%",
    ltv: "Up to 70%",
    fee: "1.00-1.50%",
    maxLoan: "Up to ₹10,00,00,000",
    processingTime: "48-72 hours",
    minPortfolio: "₹2,00,000",
    link: "https://www.las.tatacapital.com/online/loans/las/apply-now-las-loan#!",
  },
];

export default function LoanFilterResults({
  securityType: initialSecurityType,
  loanAmount: initialLoanAmount,
}) {
  const [securityType, setSecurityType] = useState(initialSecurityType);
  const [loanAmount, setLoanAmount] = useState(initialLoanAmount);
  const [sortField, setSortField] = useState("rating");
  const [sortOrder, setSortOrder] = useState("desc");
  const [showFilters, setShowFilters] = useState(false);

  // Update state when props change
  useEffect(() => {
    setSecurityType(initialSecurityType);
    setLoanAmount(initialLoanAmount);
  }, [initialSecurityType, initialLoanAmount]);

  // Get the appropriate lenders based on security type
  const allLenders = securityType === "shares" ? sharesLenders : mfLenders;

  // Filter lenders based on loan amount if selected
  const filteredLenders = loanAmount
    ? allLenders.filter((lender) => {
        if (lender.maxLoan === "No upper limit") return true;

        const maxLoanValue = Number.parseInt(
          lender.maxLoan.replace(/[^\d]/g, "")
        );
        const loanAmountValue = Number.parseInt(loanAmount);

        return maxLoanValue >= loanAmountValue;
      })
    : allLenders;

  // Sort lenders based on selected field and order
  const sortedLenders = [...filteredLenders].sort((a, b) => {
    if (sortField === "rating") {
      return sortOrder === "desc" ? b.rating - a.rating : a.rating - b.rating;
    }

    if (sortField === "rate") {
      // Extract the lower rate for comparison
      const rateA = Number.parseFloat(a.rate.split("-")[0]);
      const rateB = Number.parseFloat(b.rate.split("-")[0]);
      return sortOrder === "desc" ? rateB - rateA : rateA - rateB;
    }

    if (sortField === "ltv") {
      // Extract the percentage value
      const ltvA = Number.parseInt(a.ltv.match(/\d+/)?.[0] || "0");
      const ltvB = Number.parseInt(b.ltv.match(/\d+/)?.[0] || "0");
      return sortOrder === "desc" ? ltvB - ltvA : ltvA - ltvB;
    }

    if (sortField === "processingTime") {
      // Simple string comparison for processing time
      const timeA = a.processingTime.includes("hour")
        ? 1
        : Number.parseInt(a.processingTime.split("-")[0]);
      const timeB = b.processingTime.includes("hour")
        ? 1
        : Number.parseInt(b.processingTime.split("-")[0]);
      return sortOrder === "desc" ? timeB - timeA : timeA - timeB;
    }

    return 0;
  });

  const handleSortChange = (field) => {
    if (sortField === field) {
      // Toggle sort order if clicking the same field
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // Set new field and default to descending order
      setSortField(field);
      setSortOrder("desc");
    }
  };

  const handleSecurityTypeChange = (type) => {
    setSecurityType(type);
  };

  const handleLoanAmountChange = (e) => {
    setLoanAmount(e.target.value);
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-3 sm:p-4">
      {/* Filter Controls */}
      <div className="mb-4 sm:mb-6 flex flex-col sm:flex-row sm:items-center justify-between gap-2 sm:gap-4">
        <div className="flex items-center">
          <h3 className="text-base sm:text-lg font-bold text-[#311664]">
            {securityType === "shares"
              ? "Loan Against Shares"
              : "Loan Against Mutual Funds"}{" "}
            Results
          </h3>
          <span className="ml-2 bg-[#ebe8fc] text-[#59266d] text-xs px-2 py-1 rounded-full">
            {sortedLenders.length} options
          </span>
        </div>

        <div className="flex flex-wrap gap-2 mt-2 sm:mt-0">
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="flex items-center px-2 sm:px-3 py-1 sm:py-1.5 text-xs sm:text-sm border rounded-md hover:bg-gray-50"
          >
            <Filter className="h-3 w-3 sm:h-4 sm:w-4 mr-1" />
            Filters
          </button>

          <div className="flex items-center border rounded-md overflow-hidden">
            <button
              onClick={() => handleSortChange("rating")}
              className={`px-2 sm:px-3 py-1 sm:py-1.5 text-xs sm:text-sm flex items-center ${
                sortField === "rating"
                  ? "bg-[#ebe8fc] text-[#59266d]"
                  : "hover:bg-gray-50"
              }`}
            >
              Rating
              {sortField === "rating" && (
                <ArrowUpDown className="h-3 w-3 ml-1" />
              )}
            </button>
            <button
              onClick={() => handleSortChange("rate")}
              className={`px-2 sm:px-3 py-1 sm:py-1.5 text-xs sm:text-sm flex items-center ${
                sortField === "rate"
                  ? "bg-[#ebe8fc] text-[#59266d]"
                  : "hover:bg-gray-50"
              }`}
            >
              Rate
              {sortField === "rate" && <ArrowUpDown className="h-3 w-3 ml-1" />}
            </button>
            <button
              onClick={() => handleSortChange("ltv")}
              className={`px-2 sm:px-3 py-1 sm:py-1.5 text-xs sm:text-sm flex items-center ${
                sortField === "ltv"
                  ? "bg-[#ebe8fc] text-[#59266d]"
                  : "hover:bg-gray-50"
              }`}
            >
              LTV
              {sortField === "ltv" && <ArrowUpDown className="h-3 w-3 ml-1" />}
            </button>
          </div>
        </div>
      </div>

      {/* Expanded Filters */}
      {showFilters && (
        <div className="mb-4 sm:mb-6 p-3 sm:p-4 border rounded-md bg-gray-50">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
            <div>
              <label className="block text-xs sm:text-sm font-medium text-[#26292b] mb-1 sm:mb-2">
                Type of Security
              </label>
              <div className="grid grid-cols-2 gap-2 sm:gap-4">
                <div className="relative">
                  <input
                    type="radio"
                    name="securityTypeFilter"
                    id="sharesFilter"
                    className="peer sr-only"
                    checked={securityType === "shares"}
                    onChange={() => handleSecurityTypeChange("shares")}
                  />
                  <label
                    htmlFor="sharesFilter"
                    className="flex items-center justify-center p-1.5 sm:p-2 border-2 rounded-md cursor-pointer border-gray-200 hover:border-[#59266d] peer-checked:border-[#59266d] peer-checked:bg-[#ebe8fc] text-xs sm:text-sm"
                  >
                    <span>Shares</span>
                  </label>
                </div>
                <div className="relative">
                  <input
                    type="radio"
                    name="securityTypeFilter"
                    id="mutualFundsFilter"
                    className="peer sr-only"
                    checked={securityType === "mutualFunds"}
                    onChange={() => handleSecurityTypeChange("mutualFunds")}
                  />
                  <label
                    htmlFor="mutualFundsFilter"
                    className="flex items-center justify-center p-1.5 sm:p-2 border-2 rounded-md cursor-pointer border-gray-200 hover:border-[#3a868b] peer-checked:border-[#3a868b] peer-checked:bg-[#ebe8fc] text-xs sm:text-sm"
                  >
                    <span>Mutual Funds</span>
                  </label>
                </div>
              </div>
            </div>

            <div>
              <label
                htmlFor="loanAmountFilter"
                className="block text-xs sm:text-sm font-medium text-[#26292b] mb-1 sm:mb-2"
              >
                Loan Amount (₹)
              </label>
              <select
                id="loanAmountFilter"
                className="w-full p-1.5 sm:p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#59266d] text-xs sm:text-sm"
                value={loanAmount}
                onChange={handleLoanAmountChange}
              >
                <option value="">Any amount</option>
                <option value="500000">Up to ₹5 Lakhs</option>
                <option value="1000000">₹5 Lakhs - ₹10 Lakhs</option>
                <option value="2000000">₹10 Lakhs - ₹20 Lakhs</option>
                <option value="5000000">₹20 Lakhs - ₹50 Lakhs</option>
                <option value="10000000">₹50 Lakhs - ₹1 Crore</option>
                <option value="20000000">Above ₹1 Crore</option>
              </select>
            </div>
          </div>
        </div>
      )}

      {/* Results List */}
      <div className="space-y-3 sm:space-y-4">
        {sortedLenders.length > 0 ? (
          sortedLenders.map((lender, index) => (
            <div
              key={index}
              className="border rounded-lg hover:shadow-md transition-shadow"
            >
              <div className="p-3 sm:p-4">
                <div className="flex flex-col sm:flex-row sm:items-center gap-3 sm:gap-4">
                  {/* Lender Info */}
                  <div className="flex items-center sm:w-1/4">
                    <div className="h-10 w-10 sm:h-12 sm:w-12 flex-shrink-0 rounded-full overflow-hidden mr-3">
                      <img
                        src={lender.img}
                        alt={lender.name}
                        width={48}
                        height={48}
                        className="object-cover"
                      />
                    </div>
                    <div>
                      <div className="font-medium text-[#311664] text-sm sm:text-base">
                        {lender.name}
                      </div>
                      <div className="flex items-center">
                        <span className="mr-1 text-xs sm:text-sm font-medium">
                          {lender.rating}
                        </span>
                        <div className="flex">
                          {Array(5)
                            .fill(0)
                            .map((_, i) => (
                              <Star
                                key={i}
                                className={`h-3 w-3 ${
                                  i < Math.floor(lender.rating)
                                    ? "fill-[#59266d] text-[#59266d]"
                                    : "text-gray-300"
                                }`}
                              />
                            ))}
                        </div>
                      </div>
                      <div className="text-xs px-1.5 py-0.5 bg-[#ebe8fc] rounded-full inline-block mt-1">
                        {lender.type}
                      </div>
                    </div>
                  </div>

                  {/* Loan Details */}
                  <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-3 mt-2 sm:mt-0 sm:w-2/4">
                    <div>
                      <div className="text-xs text-gray-500">Interest Rate</div>
                      <div className="font-medium text-xs sm:text-sm">
                        {lender.rate}
                      </div>
                    </div>
                    <div>
                      <div className="text-xs text-gray-500">Max LTV</div>
                      <div className="font-medium text-xs sm:text-sm">
                        {lender.ltv}
                      </div>
                    </div>
                    <div>
                      <div className="text-xs text-gray-500">
                        Processing Fee
                      </div>
                      <div className="font-medium text-xs sm:text-sm">
                        {lender.fee}
                      </div>
                    </div>
                    <div>
                      <div className="text-xs text-gray-500">
                        Processing Time
                      </div>
                      <div className="font-medium text-xs sm:text-sm">
                        {lender.processingTime}
                      </div>
                    </div>
                  </div>

                  {/* CTA */}
                  <div className="sm:w-1/4 flex justify-start sm:justify-end mt-2 sm:mt-0">
                    <button
                      className={`rounded bg-${
                        securityType === "shares" ? "[#59266d]" : "[#3a868b]"
                      } px-3 sm:px-4 py-1.5 sm:py-2 text-xs sm:text-sm font-medium text-white hover:opacity-90`}
                      onClick={() => {
                        window.location.href = lender.link;
                      }}
                    >
                      Check My Rate
                    </button>
                  </div>
                </div>

                {/* Best For Tag */}
                <div className="mt-2 text-xs text-gray-500 flex items-center">
                  <Check className="h-3 w-3 mr-1 text-green-500" />
                  {lender.bestFor}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-6 sm:py-8">
            <p className="text-gray-500 text-sm sm:text-base">
              No lenders match your criteria. Try adjusting your filters.
            </p>
          </div>
        )}
      </div>

      {/* View All Link */}
      {filteredLenders.length > 0 && (
        <div className="mt-4 sm:mt-6 text-center">
          <Link
            to={securityType === "shares" ? "#shares-section" : "#mf-section"}
            className="text-[#59266d] hover:underline font-medium text-sm sm:text-base"
            onClick={() => {
              if (securityType === "shares") {
                document
                  .getElementById("loan-against-shares_scroll")
                  ?.scrollIntoView({ behavior: "smooth" });
              } else {
                document
                  .getElementById("loan-against-mutual-funds_shares")
                  ?.scrollIntoView({ behavior: "smooth" });
              }
            }}
          >
            View All{" "}
            {securityType === "shares"
              ? "Loan Against Shares"
              : "Loan Against Mutual Funds"}{" "}
            Options
          </Link>
        </div>
      )}

      {/* Disclaimer */}
      <div className="mt-4 sm:mt-6 text-xs text-gray-500">
        <p>
          Rates and terms shown are based on current market conditions and may
          vary. Actual loan offers will depend on your credit profile, portfolio
          value, and lender criteria.
        </p>
      </div>
    </div>
  );
}

import React from "react";
import { IoArrowDownOutline } from "react-icons/io5";
import { HiOutlineArrowRight } from "react-icons/hi2";
import { GoPencil } from "react-icons/go";
import { LuCoins } from "react-icons/lu";
const steps = [
  {
    title: "Combine Your Loans",
    description: "All your loans are merged into one single loan.",
    icon: (
      <div
        className="flex items-center justify-center gap-1"
        style={{ backgroundColor: "transparent" }}
      >
        <HiOutlineArrowRight
          className="w-4 h-4 text-blue-500 "
          style={{ backgroundColor: "transparent" }}
        />
        <HiOutlineArrowRight
          className="w-4 h-4 text-blue-500 -ml-1"
          style={{ backgroundColor: "transparent" }}
        />
      </div>
    ),
    tooltip: "Consolidating multiple loans simplifies your debt management.",
  },
  {
    title: "Choose Your Terms",
    description: "Set a new loan amount, interest rate, and repayment period.",
    icon: (
      <GoPencil
        className="w-5 h-5 text-purple-500"
        style={{ backgroundColor: "transparent" }}
      />
    ),
    tooltip: "Customize your loan terms to fit your financial situation.",
  },
  {
    title: "Enjoy Lower EMIs",
    description: "Reduce your monthly payments and simplify your finances.",
    icon: (
      <IoArrowDownOutline
        className="w-5 h-5 text-green-500 "
        style={{ backgroundColor: "transparent" }}
      />
    ),
    tooltip: "Lower EMIs mean more money in your pocket each month.",
  },
  {
    title: "One Easy Payment",
    description:
      "Make one simple monthly payment instead of managing multiple loans.",
    icon: (
      <LuCoins
        className="w-5 h-5 text-yellow-500 "
        style={{ backgroundColor: "transparent" }}
      />
    ),
    tooltip: "Simplify your finances with a single, easy-to-manage payment.",
  },
];

export default function LoanConsolidate() {
  return (
    <section className="py-6  w-100" style={{ backgroundColor: "#f8f8f8" }}>
      <h2
        className="text-2xl sm:text-3xl font-bold text-center mb-2 text-[#59266d] "
        style={{
          color: "#59266D",
          fontSize: "30px",
          lineHeight: "36px",
          fontWeight: "700",
        }}
      >
        How Loan Consolidation Works
      </h2>
      <p
        className="text-sm sm:text-base text-center mb-4 max-w-xs mx-auto "
        style={{
          color: "#59266D",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "400",
        }}
      >
        See how consolidating your loans can simplify your payments and save you
        money.
      </p>
      <div className="container-fluid mx-auto px-4 max-w-md sm:max-w-xl md:max-w-full ">
        <div className="grid grid-cols-2 gap-3 mb-4 ">
          {steps.map((step, index) => (
            <div
              key={index}
              className="bg-white p-3 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 flex flex-col items-center text-center border border-gray-100"
            >
              <div
                className="mb-2 bg-transparent"
                style={{ backgroundColor: "white" }}
              >
                {step.icon}
              </div>
              <h3 className="text-sm font-semibold mb-1 bg-white">
                {step.title}
              </h3>
              <p className="text-xs text-gray-600 bg-white">
                {step.description}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="text-center pb-4 ">
        <p className="text-xs sm:text-sm text-center pb-2 ">
          By consolidating your loans, you reduce the number of payments you
          need to make each month, making it easier to stay on top of your
          finances.
        </p>
      </div>
      <div className="text-center ">
        <a
          style={{ textDecoration: "none", color: "#ffffff" }}
          href="/lower-emi-loan-consolidation"
          onClick={() => {
            localStorage.setItem("id", 2);
            window.scrollTo(0, 0);
          }}
        >
          <button className="px-4 py-2 bg-[#59266d] text-white rounded-md hover:bg-purple-800 transition-colors text-sm change-width">
            Ready to Consolidate Your Loans?
          </button>
        </a>
      </div>
    </section>
  );
}

import React, { useEffect, useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import "./index.css";
import { useLocation } from "react-router-dom";

const Index = ({data}) => {
    const location = useLocation();
    const { pathname } = location; 
  const [reportDate, setReportDate] = useState("");
  const [username, setUsername] = useState(data?.name);
  const [maxValue, setMaxValue] = useState(900);
  const [cibilMsg, setCibilMsg] = useState("Great news! Your credit score is in good shape. Access a wide range of financial opportunities with competitive rates.");
  const [formShow, setFormShow] = useState(false);
  const[para,setPara]=useState("")
  const[des,setDes]=useState("")


  useEffect(() => {
    window.scrollTo(0, 0);
    const currentDate = new Date();
    // Manually format the date as MM/DD/YYYY
    const formattedDate = 
    `${currentDate.getDate().toString().padStart(2, '0')}/` +
    `${(currentDate.getMonth() + 1).toString().padStart(2, '0')}/` +
      `${currentDate.getFullYear()}`;
    setReportDate(formattedDate);
  }, []);

  const getRating = (score) => {
    if (score >= 720) {
      return "EXCELLENT 👍";
    } else if (score >= 640) {
      return "GOOD 😊";
    } else {
      return "BAD 😞";
    }
  };
  useEffect(() => {
    if(pathname=="/check-rate"){
      setPara(<>Thanks for using  <b>MinEMI</b> to find your applicable loan rate.</>)
      setDes("Here’s a brief overview of your profile before we show your eligible rate.")
    }  else if(pathname=="/loan-approval"){
      setPara(<>Thanks for using <b>MinEMI</b> to check your loan approval chances</>)
      setDes(<>Here’s a quick snapshot of your credit profile before we calculate your chances.</>)
    } else if(pathname=="/loan-overview"){
      setPara(<>Thanks for using <b>MinEMI</b> to track your running loans and credit cards.</>)
      setDes("Here’s the data reported by banks linked to your profile.")
    }else if(pathname=="/check-card-availability"){
      setPara(<>Thanks for checking your credit card eligibility with <b>MinEMI</b>.</>)
      setDes("Here’s your credit overview before we list cards you may qualify for.")
    }
    else{
  
      setPara(<>Thanks for checking your credit card approval odds with <b>MinEMI</b>.</>)
      setDes(<>Here’s a summary of your credit data before we assess your approval odds.</>)
    }
  }, [pathname])


  const formatIndianNumber = (number) => {
    return new Intl.NumberFormat("en-IN").format(number)
  }

  return (
    <>
      <div id="Loans-eligibility">
        <div className="contentCon">
          <div className="row d-flex justify-content-between m-0 p-0">
            <div
              className={
                formShow
                  ? "col-12 col-md-12 mb-5 mb-md-0 p-0"
                  : "col-12 col-md-7 col-lg-8 mb-2 mb-md-0 p-0"
              }
            >
              <p className="cus-name p-0 m-0 text-capitalize">Hi👋 {username}, {para} 
              </p>
           
              {/* <p className="credit-desc p-0 m-0">Here are the details of your requested loan of <b>₹5,00,000</b></p> */}
              <div className="Awesome-con">
                <p className="awesome-text mb-2">{des}</p>
                <p className="loan-sum-text">Your Ongoing Loans</p>

                <div className="loan-sum-con">
                  <div className="row p-0 m-0 d-flex justify-content-between">
                    <div className="col-12 col-lg-6 mb-0 mb-lg-3 p-0 d-flex justify-content-between">
                      <p className="loan-text">Total no. of running loans :</p>
                      <p className="loan-value">{data?.runningLoan}</p>
                    </div>
                 
                    <div className="col-12 col-lg-5 p-0 d-flex justify-content-between">
                      <p className="loan-text">Total outstanding amount :</p>
                      <p className="loan-value">₹{formatIndianNumber((Number(data?.unsecured || 0)) +(Number(data?.secured || 0)))}</p>
                    </div>
        
                    <div className="col-12 col-lg-6 p-0 d-flex justify-content-between">
                      <p className="loan-text">Unsecured Loan Balance :</p>
                      <p className="loan-value">{data?.unsecured==null?"₹0":"₹"+formatIndianNumber(data?.unsecured)}</p>
                    </div>
                    <div className="col-12 col-lg-5 mb-0 mb-lg-3 p-0 d-flex justify-content-between">
                      <p className="loan-text">Secured Loan Balance :</p>
                      <p className="loan-value">{data?.secured==null?"₹0":"₹"+formatIndianNumber(data?.secured)}</p>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4 col-lg-3 p-0">
              <div id={formShow ? "speedometer-con-manage" : "speedometer-con"}>
                <div 
                style={{
                  position: "absolute",
                  top: 0, 
                  left: 0, 
                  right: 0, 
                  bottom: 0,
                  display:"flex", 
                  justifyContent: "center", 
                }}>
                    <ReactSpeedometer
                    fluidWidth={true}
                    value={data?.cibil}
                    maxValue={maxValue}
                    height={159}
                    minValue={0}
                    textColor="#59266D"
                    needleColor="#431d76"
                    segmentColors={[
                      "rgb(132, 108, 166)",
                      "rgb(116, 88, 156)",
                      "rgb(89, 38, 109)",
                      "rgb(52, 22, 100)",
                    ]}
                    customSegmentStops={[0, 300, 500, 700, maxValue]}
                    needleHeightRatio={0.7}
                    currentValueText={data?.cibil}
                    valueTextFontSize="20px"
                    
                  />
                </div>
              </div>

              <div
                className={
                  formShow
                    ? "score-con d-flex justify-content-between"
                    : "d-flex justify-content-between add-line"
                }
              >
                <p
                  className={
                    formShow
                      ? "poor-score2 mb-0"
                      : "poor-score mb-0"
                  }
                >
                  Poor
                </p>
                <p
                  className={
                    formShow
                      ? "good-score2 mb-0"
                      : "good-score mb-0"
                  }
                >
                  Good
                </p>
              </div>

              <div className="d-flex justify-content-center">
                <img src="../../../images/experian.png" className="cmp-logo" />
              </div>

              <div className="report-con">
                <p className="reportText">Report issue with the result <a href="https://consumer.experian.in/ECV-OLN/view/angular/#/">Here</a></p>
                <p className="reportUpdate">last update on {reportDate}</p>
              </div>

              <div className="cibil-con">
                <p
                  className={
                    formShow ? "cibil-cond mb-2" : "cibil-condition"
                  }
                >
                  You are in a <span>{getRating(data?.cibil)}</span> Shape
                </p>
                <p
                  className={formShow ? "cibil-msg2 mb-0" : "cibil-msg mb-0"}
                >
                  {cibilMsg}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
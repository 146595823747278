import React from "react";

import "./ListOfDocuments.css";

const ListOfDocuments = () => {
  return (
    <>
      <div id="list-of-documents" className="bg-transparent">
        <div id="lc-documents-container">
          <div id="documents-content" className="bg-transparent">
            <div className="documents-content-container bg-transparent">
              <div className="content-box w-100 bg-transparent ">
                {" "}
                <div className="documents-text-box bg-transparent">
                  <div className="bg-transparent   ">
                    <div className="bg-transparent d-flex justify-content-center align-items-center">
                      <h4 className="bg-transparent" id="documents-heading">
                        Why Consolidate Your Loans?
                      </h4>
                    </div>
                    <div className="text-box bg-transparent">
                      <div className="d-flex flex-row gap-3 gap-md-5 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent">
                          Reduce your monthly EMI by up to 30% by consolidating
                          loans.
                        </p>
                      </div>
                      <div className="d-flex flex-row gap-3 gap-md-5 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent">
                          Lower your interest rate across loans.
                        </p>
                      </div>
                      <div className="d-flex flex-row gap-3 gap-md-5 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent">
                          Streamline your repayments with a single loan and EMI.
                        </p>
                      </div>
                      <div className="d-flex flex-row gap-3 gap-md-5 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent">
                          Improve your credit score with consistent payments.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="documents-image-box  d-md-block bg-transparent">
                  <div className="bg-transparent">
                    <img
                      className="documents-image bg-transparent"
                      src={Image}
                      alt="docuents-image"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListOfDocuments;

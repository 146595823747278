import React, { useEffect } from "react";
import Submitted from "../../Assets/Images/Submitted.webp";
import "./Confirm.css";
import { Link, useNavigate } from "react-router-dom";

function Confirm() {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/");
    } else {
    }
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
}, [])
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100vh",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "272px", height: "272px" }}>
        <img src={Submitted} width="100%" height="100%" alt="Submitted" />
      </div>
      <div
        style={{
          fontFamily: "Montserrat",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "20px",
          letterSpacing: "0em",
          textAlign: "center",
          color: "rgba(31, 31, 31, 1)",
        }}
      >
        Your Application is Submitted
      </div>
      <div
        style={{
          fontSize: "24px",
          fontWeight: "600",
          lineHeight: "26px",
          letterSpacing: "0em",
          textAlign: "center",
          color: "rgba(89, 38, 109, 1)",
          marginTop: "20px",
        }}
      >
        Successfully
      </div>
      <div style={{ marginTop: "40px" }}>
        <Link to="/dashboard">
          {" "}
          <button
            className="confirm-button-1"
            style={{
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "17px",
              letterSpacing: "0em",
              textAlign: "left",
              paddingLeft: " 146px",
              paddingRight: "146px",
              paddingBottom: "19px",
              paddingTop: "19px",
              borderRadius: "10px",
              border: "1px solid rgba(89, 38, 109, 1)",
              color: "rgba(89, 38, 109, 1)",
              cursor: "pointer",
            }}
          >
            Back to Home
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Confirm;

import React from 'react';
import { motion } from 'framer-motion';
import { Check, X } from 'lucide-react';

function ComparisonTable() {
  const comparisonData = [
    {
      feature: 'Eligibility Check',
      minemi: { value: 'Instant AI-powered analysis', positive: true },
      banks: { value: 'Manual application required', positive: false },
      others: { value: 'Basic pre-qualification', positive: false }
    },
    {
      feature: 'Credit Score Impact',
      minemi: { value: 'No impact', positive: true },
      banks: { value: 'Hard inquiry affects score', positive: false },
      others: { value: 'Varies by provider', positive: false }
    },
    {
      feature: 'Accuracy',
      minemi: { value: '97%+ accuracy rate', positive: true },
      banks: { value: 'Final decision after application', positive: false },
      others: { value: '70-80% accuracy', positive: false }
    },
    {
      feature: 'Processing Time',
      minemi: { value: 'Under 60 seconds', positive: true },
      banks: { value: '7-14 days', positive: false },
      others: { value: '24-48 hours', positive: false }
    },
    {
      feature: 'Personalized Recommendations',
      minemi: { value: 'AI-tailored to your profile', positive: true },
      banks: { value: 'Limited options', positive: false },
      others: { value: 'Generic recommendations', positive: false }
    }
  ];

  // Styles
  const styles = {
    section: {
      padding: '2rem 1rem',
      backgroundColor: 'rgba(235, 232, 252, 0.2)',
      fontFamily: 'Arial, sans-serif',
    },
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
    },
    header: {
      textAlign: 'center',
      marginBottom: '2rem',
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#311664',
      marginBottom: '0.5rem',
    },
    subtitle: {
      fontSize: '0.875rem',
      color: '#666',
      maxWidth: '600px',
      margin: '0 auto',
    },
    mobileCards: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
    },
    card: {
      backgroundColor: 'white',
      borderRadius: '0.75rem',
      overflow: 'hidden',
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    },
    cardHeader: {
      backgroundColor: '#311664',
      color: 'white',
      padding: '0.75rem',
      fontWeight: '500',
    },
    cardBody: {
      padding: '1rem',
    },
    cardRow: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '0.75rem',
    },
    cardLabel: {
      width: '6rem',
      flexShrink: 0,
      fontWeight: '600',
    },
    cardLabelMinemi: {
      color: '#311664',
    },
    cardLabelOthers: {
      color: '#666',
    },
    cardContent: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
    },
    cardText: {
      fontSize: '0.875rem',
    },
    icon: {
      marginLeft: '0.5rem',
      flexShrink: 0,
    },
    iconPositive: {
      color: '#22c55e',
    },
    iconNegative: {
      color: '#ef4444',
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      backgroundColor: 'white',
      borderRadius: '0.75rem',
      overflow: 'hidden',
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    },
    tableHead: {
      backgroundColor: '#311664',
      color: 'white',
    },
    tableHeadCell: {
      padding: '1rem 1.5rem',
      textAlign: 'left',
      fontWeight: 'normal',
    },
    tableHeadCellMinemi: {
      fontWeight: 'bold',
    },
    tableRow: {
      borderBottom: '1px solid #f1f1f1',
    },
    tableRowEven: {
      backgroundColor: 'rgba(235, 232, 252, 0.1)',
    },
    tableCell: {
      padding: '1rem 1.5rem',
      verticalAlign: 'top',
    },
    tableCellFeature: {
      fontWeight: '500',
    },
    tableCellContent: {
      display: 'flex',
      alignItems: 'center',
    },
  };

  // Media queries for responsive design
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

  // Desktop styles
  if (!isMobile) {
    styles.section.padding = '4rem 2rem';
    styles.title.fontSize = '2.25rem';
    styles.subtitle.fontSize = '1.125rem';
  }

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          style={styles.header}
        >
          <h2 style={styles.title}>Minemi vs. Other Methods</h2>
          <p style={styles.subtitle}>
            See how Minemi's credit card eligibility checker compares to traditional methods.
          </p>
        </motion.div>

        {/* Mobile view - Card-based comparison */}
        {isMobile && (
          <div style={styles.mobileCards}>
            {comparisonData.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.4, delay: index * 0.1 }}
                style={styles.card}
              >
                <div style={styles.cardHeader}>
                  {item.feature}
                </div>
                <div style={styles.cardBody}>
                  <div style={styles.cardRow}>
                    <div style={{...styles.cardLabel, ...styles.cardLabelMinemi}}>Minemi:</div>
                    <div style={styles.cardContent}>
                      <span style={styles.cardText}>{item.minemi.value}</span>
                      {item.minemi.positive ? (
                        <Check style={{...styles.icon, ...styles.iconPositive}} size={16} />
                      ) : (
                        <X style={{...styles.icon, ...styles.iconNegative}} size={16} />
                      )}
                    </div>
                  </div>
                  <div style={styles.cardRow}>
                    <div style={{...styles.cardLabel, ...styles.cardLabelOthers}}>Banks:</div>
                    <div style={styles.cardContent}>
                      <span style={styles.cardText}>{item.banks.value}</span>
                      {item.banks.positive ? (
                        <Check style={{...styles.icon, ...styles.iconPositive}} size={16} />
                      ) : (
                        <X style={{...styles.icon, ...styles.iconNegative}} size={16} />
                      )}
                    </div>
                  </div>
                  <div style={styles.cardRow}>
                    <div style={{...styles.cardLabel, ...styles.cardLabelOthers}}>Others:</div>
                    <div style={styles.cardContent}>
                      <span style={styles.cardText}>{item.others.value}</span>
                      {item.others.positive ? (
                        <Check style={{...styles.icon, ...styles.iconPositive}} size={16} />
                      ) : (
                        <X style={{...styles.icon, ...styles.iconNegative}} size={16} />
                      )}
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        )}

        {/* Desktop view - Traditional table */}
        {!isMobile && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <table style={styles.table}>
              <thead style={styles.tableHead}>
                <tr>
                  <th style={styles.tableHeadCell}>Feature</th>
                  <th style={{...styles.tableHeadCell, ...styles.tableHeadCellMinemi}}>
                    Minemi
                  </th>
                  <th style={styles.tableHeadCell}>Banks</th>
                  <th style={styles.tableHeadCell}>Other Platforms</th>
                </tr>
              </thead>
              <tbody>
                {comparisonData.map((item, index) => (
                  <tr 
                    key={index} 
                    style={{
                      ...styles.tableRow,
                      ...(index % 2 === 0 ? styles.tableRowEven : {})
                    }}
                  >
                    <td style={{...styles.tableCell, ...styles.tableCellFeature}}>{item.feature}</td>
                    <td style={styles.tableCell}>
                      <div style={styles.tableCellContent}>
                        <span>{item.minemi.value}</span>
                        {item.minemi.positive ? (
                          <Check style={{...styles.icon, ...styles.iconPositive}} size={20} />
                        ) : (
                          <X style={{...styles.icon, ...styles.iconNegative}} size={20} />
                        )}
                      </div>
                    </td>
                    <td style={styles.tableCell}>
                      <div style={styles.tableCellContent}>
                        <span>{item.banks.value}</span>
                        {item.banks.positive ? (
                          <Check style={{...styles.icon, ...styles.iconPositive}} size={20} />
                        ) : (
                          <X style={{...styles.icon, ...styles.iconNegative}} size={20} />
                        )}
                      </div>
                    </td>
                    <td style={styles.tableCell}>
                      <div style={styles.tableCellContent}>
                        <span>{item.others.value}</span>
                        {item.others.positive ? (
                          <Check style={{...styles.icon, ...styles.iconPositive}} size={20} />
                        ) : (
                          <X style={{...styles.icon, ...styles.iconNegative}} size={20} />
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </motion.div>
        )}
      </div>
    </section>
  );
}

export default ComparisonTable;
export const faqData = [
  {
    id: 1,
    question: "What is the minimum credit score required for a car loan?",
    answer:
      "Most lenders require a credit score of 650 or higher. Check your car loan eligibility with Minemi to see your options without affecting your score.",
  },
  {
    id: 2,
    question: "How much car loan can I get?",
    answer:
      "The loan amount is determined by your income, credit score, and the price of the car. Use Minemi's eligibility checker to get an estimate.",
  },
  {
    id: 3,
    question: "Will checking car loan eligibility impact my credit score?",
    answer:
      "No, checking your eligibility through Minemi uses a soft inquiry that doesn't impact your credit score.",
    // answerList: [
    //   {
    //     heading: " Loan Amount: ",
    //     info: "Higher amounts lead to higher EMIs.",
    //   },
    //   {
    //     heading: " Interest Rate:",
    //     info: "Lower rates reduce your EMI and total repayment.",
    //   },
    //   {
    //     heading: "Tenure:  ",
    //     info: " Longer tenures lower EMIs but increase total interest.",
    //   },
    // ],
  },
  {
    id: 4,
    question: "Can I get a car loan for a used vehicle?",
    answer:
      "Yes, many lenders offer car loans for used vehicles as well. Minemi can help you find the best lenders for your needs.",
  },
  {
    id: 5,
    question: "How quickly can I get a car loan?",
    answer:
      "Car loans are typically approved quickly, often within a day or two. Minemi helps speed up the process by providing personalized loan offers.",
  },
  // {
  //   id: 6,
  //   question: "How does MinEMI assist with car loans?",
  //   answer:
  //     "Yes, the amount you can transfer is typically limited by the credit limit of the new card or loan and the bank’s policies. Some banks may allow transfers up to a certain percentage of the credit limit.",
  // },
  // {
  //   id: 7,
  //   question: "What happens if I miss a car loan EMI payment?",
  //   answer:
  //     "Some credit cards allow balance transfers from personal loans or other credit cards. However, this depends on the card issuer’s policies.",
  // },
  // {
  //   id: 8,
  //   question: "Are car loan EMIs tax-deductible?",
  //   answer:
  //     "Focus on timely repayments of the new balance to take full advantage of the lower interest rate. Avoid accumulating new debt on the old account to prevent further financial strain.",
  // },
];

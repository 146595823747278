import React from "react";
import Hero from "./SectionsPages/Hero";
import EducationalContent from "./SectionsPages/EducationSection";
import HowAPRWorks from "./SectionsPages/HowAPRWorks";
import HiddenCosts from "./SectionsPages/HiddenCosts";
import WhyMinemi from "./SectionsPages/WhyMinemi";
import Testimonials from "./SectionsPages/Testimonials";
import FAQSection from "./SectionsPages/FAQSection";
import ClosingSection from "./SectionsPages/ClosingSection";
export default function APRSection() {
  return (
    <main className="d-flex flex-column bg-white w-100">
      <Hero />
      <div className="d-flex flex-column mx-auto px-4 py-4 space-y-8">
        <EducationalContent />
        <HowAPRWorks />
        <HiddenCosts />
        <WhyMinemi />
        <Testimonials />
        <FAQSection />
        <ClosingSection />
      </div>
    </main>
  );
}

import React from "react";
import BenefitCard from "./BenefitCard";
import { LuWallet, LuFileCheck, LuShieldCheck } from "react-icons/lu";

function BenefitsSection() {
  return (
    <section className="py-20 w-100" style={{ backgroundColor: "#f8f8f8" }}>
      <div className="container-fluid mx-auto px-16 ">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <BenefitCard
            icon={
              <LuWallet
                className="w-12 h-12"
                style={{ color: "#58226d", backgroundColor: "transparent" }}
              />
            }
            title="Save More Every Month"
            description="Reduce your EMIs and unlock monthly savings with competitive interest rates."
          />
          <BenefitCard
            icon={
              <LuFileCheck
                className="w-12 h-12"
                style={{ color: "#58226d", backgroundColor: "transparent" }}
              />
            }
            title="Hassle-Free Process"
            description="We take care of the comparisons, paperwork, and recommendations for you."
          />
          <BenefitCard
            icon={
              <LuShieldCheck
                className="w-12 h-12"
                style={{ color: "#58226d", backgroundColor: "transparent" }}
              />
            }
            title="100% Transparency"
            description="Clear and straightforward processes with no hidden charges."
          />
        </div>
      </div>
    </section>
  );
}

export default BenefitsSection;

import axios from "axios";
import { createContext, useEffect, useState } from "react";

// Context Banaye
export const UserContext = createContext();

// Provider Component
export const UserProvider = ({ children }) => {
  const [city, setCity] = useState("");

  const [userData, setUserData] = useState({
    fname: "",
    lname: "",
    email: "",
    mobile: "",
    city: city, // This will not update dynamically, so we'll fix it.
  });

  const [form2Data, setForm2Data] = useState({
    profession: "",
    company: "",
    monthly_income: "",
    monthly_emi: "",
    loan_type: "hl",
    purpose: "/home-loan-check-eligibility",
  });

  // Dono Forms ka Data Merge Karna
  const [mergedData, setMergedData] = useState({});

  // Jab bhi userData ya form2Data change ho, mergedData update ho
  useEffect(() => {
    setMergedData({ ...userData, ...form2Data });
  }, [userData, form2Data]);

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await axios.get("https://ipinfo.io/json", {
          headers: {
            Authorization: "Token tpif4c80536d977581", // Correct format
          },
        });
        const cityName = response.data.city;
        setCity(cityName);
      } catch (err) {
        console.error("Error fetching city information:", err);
      }
    };

    fetchCity();
  }, []);

  // ✅ Ensure `userData.city` updates when `city` changes
  useEffect(() => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      city: city,
    }));
  }, [city]);

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        form2Data,
        setForm2Data,
        mergedData,
        setMergedData,
        city

      }}
    >
      {children}
    </UserContext.Provider>
  );
};

import React, { useState, useEffect } from "react";

export function LoanCalculator() {
  const [currentLoan, setCurrentLoan] = useState(1000000);
  const [currentRate, setCurrentRate] = useState(8);
  const [currentTenure, setCurrentTenure] = useState(10);
  const [topUpAmount, setTopUpAmount] = useState(200000);
  const [topUpRate, setTopUpRate] = useState(7);
  const [newTenure, setNewTenure] = useState(12);
  const [currentEMI, setCurrentEMI] = useState(0);
  const [newEMI, setNewEMI] = useState(0);

  useEffect(() => {
    calculateEMI();
  }, [
    currentLoan,
    currentRate,
    currentTenure,
    topUpAmount,
    topUpRate,
    newTenure,
  ]);

  const calculateEMI = () => {
    const currentMonthlyRate = currentRate / 12 / 100;
    const currentEMIValue =
      (currentLoan *
        currentMonthlyRate *
        Math.pow(1 + currentMonthlyRate, currentTenure * 12)) /
      (Math.pow(1 + currentMonthlyRate, currentTenure * 12) - 1);
    setCurrentEMI(currentEMIValue);

    const totalLoan = currentLoan + topUpAmount;
    const newMonthlyRate = topUpRate / 12 / 100;
    const newEMIValue =
      (totalLoan *
        newMonthlyRate *
        Math.pow(1 + newMonthlyRate, newTenure * 12)) /
      (Math.pow(1 + newMonthlyRate, newTenure * 12) - 1);
    setNewEMI(newEMIValue);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    }).format(value);
  };

  return (
    <section
      className="py-4 md:py-8  w-100 "
      style={{ width: "100%", backgroundColor: "#f8f8f8" }}
    >
      <h2
        className="text-lg sm:text-2xl md:text-3xl font-bold text-center bg-transparent mb-2 md:mb-3  text-[#59266d]"
        style={{
          fontSize: "30px",
          lineHeight: "36px",
          fontWeight: "700",
        }}
      >
        Calculate Your New EMI After Loan Top-Up
      </h2>
      <p
        className="text-xs sm:text-sm md:text-base text-center mb-3 md:mb-4 max-w-2xl mx-auto"
        style={{
          backgroundColor: "#f9fafc",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "500",
        }}
      >
        See how much you can save with a loan top-up and how your monthly EMI
        will change.
      </p>
      <div className="container-fluid mx-auto px-2 sm:px-4">
        <div className="bg-gradient-to-br from-purple-100 to-indigo-100 rounded-lg shadow-md p-3 sm:p-4 md:p-6 max-w-4xl mx-auto border-2 border-[#59266d]">
          <div className="bg-white rounded-lg p-3 sm:p-4 md:p-5 mb-3 sm:mb-4">
            <div className="grid grid-cols-2 md:grid-cols-3  sm:gap-3 md:gap-4 bg-white">
              <div className="space-y-1 bg-white">
                <label
                  className="block text-xs font-medium text-gray-700 bg-white"
                  htmlFor="currentLoan"
                >
                  Current Loan Amount
                </label>
                <input
                  type="number"
                  id="currentLoan"
                  className="w-full text-xs sm:text-sm p-1 bg-white sm:p-2 border rounded focus:ring-2 focus:ring-[#59266d] focus:border-transparent"
                  value={currentLoan}
                  onChange={(e) => setCurrentLoan(Number(e.target.value))}
                />
              </div>
              <div className="space-y-1 bg-white">
                <label
                  className="block text-xs font-medium text-gray-700 bg-white"
                  htmlFor="currentRate"
                >
                  Current Interest Rate (%)
                </label>
                <input
                  type="number"
                  id="currentRate"
                  className="w-full text-xs bg-white sm:text-sm p-1 sm:p-2 border rounded focus:ring-2 focus:ring-[#59266d] focus:border-transparent"
                  value={currentRate}
                  onChange={(e) => setCurrentRate(Number(e.target.value))}
                  step="0.1"
                />
              </div>
              <div className="space-y-1 md:col-start-1 bg-white">
                <label
                  className="block text-xs bg-white font-medium text-gray-700"
                  htmlFor="currentTenure"
                >
                  Current Loan Tenure (Years)
                </label>
                <input
                  type="number"
                  id="currentTenure"
                  className="w-full text-xs bg-white sm:text-sm p-1 sm:p-2 border rounded focus:ring-2 focus:ring-[#59266d] focus:border-transparent"
                  value={currentTenure}
                  onChange={(e) => setCurrentTenure(Number(e.target.value))}
                />
              </div>
              <div className="space-y-1 bg-white">
                <label
                  className="block text-xs bg-white font-medium text-gray-700"
                  htmlFor="topUpAmount"
                >
                  Top-Up Amount
                </label>
                <input
                  type="number"
                  id="topUpAmount"
                  className="w-full text-xs bg-white sm:text-sm p-1 sm:p-2 border rounded focus:ring-2 focus:ring-[#59266d] focus:border-transparent"
                  value={topUpAmount}
                  onChange={(e) => setTopUpAmount(Number(e.target.value))}
                />
              </div>
              <div className="space-y-1 bg-white">
                <label
                  className="block text-xs bg-white font-medium text-gray-700"
                  htmlFor="topUpRate"
                >
                  Top-Up Interest Rate (%)
                </label>
                <input
                  type="number"
                  id="topUpRate"
                  className="w-full text-xs bg-white sm:text-sm p-1 sm:p-2 border rounded focus:ring-2 focus:ring-[#59266d] focus:border-transparent"
                  value={topUpRate}
                  onChange={(e) => setTopUpRate(Number(e.target.value))}
                  step="0.1"
                />
              </div>
              <div className="space-y-1 bg-white">
                <label
                  className="block text-xs bg-white font-medium text-gray-700"
                  htmlFor="newTenure"
                >
                  New Loan Tenure (Years)
                </label>
                <input
                  type="number"
                  id="newTenure"
                  className="w-full text-xs bg-white sm:text-sm p-1 sm:p-2 border rounded focus:ring-2 focus:ring-[#59266d] focus:border-transparent"
                  value={newTenure}
                  onChange={(e) => setNewTenure(Number(e.target.value))}
                />
              </div>
            </div>
          </div>

          <div className="mt-3 sm:mt-4 bg-white rounded-lg p-3 sm:p-4">
            <h3 className="text-sm sm:text-base font-semibold mb-2 text-[#59266d] bg-white">
              Calculation Results
            </h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 gap-4 sm:gap-6  bg-white">
              <div
                className="bg-gray-100 p-3 rounded-lg  col-span-1 sm:col-span-1"
                style={{ height: "70px" }}
              >
                <p
                  className="text-xs font-medium text-gray-600"
                  style={{ color: "#495460" }}
                >
                  Current EMI
                </p>
                <p className="text-xs sm:text-sm font-bold text-[#59266d]">
                  {formatCurrency(currentEMI)}
                </p>
              </div>
              <div
                className="bg-gray-100 p-3 rounded-lg col-span-1 sm:col-span-1"
                style={{ height: "70px" }}
              >
                <p className="text-xs font-medium text-gray-600">New EMI</p>
                <p className="text-xs sm:text-sm font-bold text-[#59266d]">
                  {formatCurrency(newEMI)}
                </p>
              </div>
              <div
                className="bg-gray-100 p-3 rounded-lg col-span-1 sm:col-span-1"
                style={{ height: "70px" }}
              >
                <p className="text-xs font-medium text-gray-600">
                  Monthly Savings
                </p>
                <p className="text-xs sm:text-sm font-bold text-green-600">
                  {formatCurrency(Math.max(currentEMI - newEMI, 0))}
                </p>
              </div>
            </div>

            <div className="mt-3 sm:mt-4 bg-white">
              <h4
                className="text-xs sm:text-sm font-semibold mb-1 sm:mb-2 bg-white"
                style={{ color: "#553782" }}
              >
                EMI Comparison
              </h4>
              <div className="flex items-end h-16 sm:h-20 md:h-24 bg-gray-100 rounded-lg p-1 sm:p-2">
                <div className="flex-1 h-full flex items-end py-2">
                  <div
                    className="bg-blue-500  w-1/3 sm:w-1/2"
                    style={{
                      height: `${
                        (currentEMI / Math.max(currentEMI, newEMI)) * 100
                      }%`,
                    }}
                  >
                    <div className="text-[10px] sm:text-xs text-white  bg-blue-500 p-1">
                      Current
                    </div>
                  </div>
                </div>
                <div className="flex-1 h-full py-2 flex items-end">
                  <div
                    className="bg-green-500 w-1/3 sm:w-1/2"
                    style={{
                      height: `${
                        (newEMI / Math.max(currentEMI, newEMI)) * 100
                      }%`,
                    }}
                  >
                    <div className="text-[10px] sm:text-xs bg-green-500 text-white p-1">
                      New
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3 sm:mt-4 text-center bg-transparent">
            <a
              href="/login?goal=loan-top-up"
              className=""
              onClick={() => {
                localStorage.setItem("id", 3);
                window.scrollTo(0, 0);
              }}
            >
              <button className="bg-[#59266d] text-white px-3 py-1 sm:px-4 sm:py-2 rounded-full text-xs sm:text-sm font-semibold hover:bg-purple-800 transition-colors h-10">
                Ready to Apply for Your Loan Top-Up?
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoanCalculator;

import React from "react";

function HeroSectionPage() {
  return (
    <section className="bg-gradient-to-b from-white to-[#EBE8FC] py-6 md:py-20 w-100">
      <div className="container-fluid bg-transparent mx-auto px-4">
        <div className="max-w-4xl mx-auto bg-transparent">
          <div className="text-center mb-4 md:mb-6 bg-transparent">
            <h1 className="text-2xl md:text-5xl font-bold mb-2 md:mb-4 bg-transparent">
              Consolidate Your Loans, Take Control of Your Finances
            </h1>
            <p
              className="text-base md:text-xl mb-4 md:mb-8"
              style={{ backgroundColor: "transparent" }}
            >
              Combine multiple loans into one, reduce your EMIs, and enjoy
              stress-free repayments with better terms.
            </p>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between gap-2 md:gap-4 bg-transparent">
            <div className="w-full md:w-1/2 flex justify-center items-center mb-2 md:mb-0 bg-transparent">
              <img
                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/IMG_1126-zS3NhC5T7Rmda5xrM9VQcOG8moUU7j.png"
                alt="Online banking interface with people managing finances illustration"
                className="object-contain w-full h-auto max-w-[280px] md:max-w-[320px] bg-transparent"
                style={{
                  maxWidth: "280px",
                  height: "auto",
                }}
              />
            </div>
            <ul className="w-full md:w-1/2 space-y-2 bg-transparent md:space-y-4 text-sm md:text-base">
              <li className="flex items-center bg-transparent">
                <span className="text-green-500 mr-2 flex-shrink-0 bg-transparent w-4 h-4 md:w-5 md:h-5">
                  ✓
                </span>
                <span className="bg-transparent">Save up to ₹10,000/month</span>
              </li>
              <li className="flex items-center bg-transparent">
                <span className="text-green-500 bg-transparent mr-2 flex-shrink-0 w-4 h-4 md:w-5 md:h-5">
                  ✓
                </span>
                <span className="bg-transparent">
                  Exclusive rates from 8.5% p.a.
                </span>
              </li>
              <li className="flex items-center bg-transparent">
                <span className="text-green-500 mr-2 bg-transparent flex-shrink-0 w-4 h-4 md:w-5 md:h-5">
                  ✓
                </span>
                <span className="bg-transparent">100% Free Service</span>
              </li>
            </ul>
          </div>
          <div className="text-center mt-6 md:mt-8 bg-transparent">
              <a
                style={{ textDecoration: "none", color: "#ffffff" }}
                href="/login?goal=loan-consolidate"
                onClick={() => {
                  localStorage.setItem("id", 2);
                  window.scrollTo(0, 0);
                }}
              >
              <button className="bg-[#59266d] text-white px-6 py-3 md:px-10 md:py-4 rounded-full text-base md:text-xl font-semibold hover:bg-purple-800 transition-colors">
                Get Started
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSectionPage;


import { useState } from "react"
import { ChevronDown, ChevronUp, Search } from "lucide-react"

const faqs = [
  {
    question: "How do I compare car loans using Minemi?",
    answer:
      "Minemi's car loan comparison tool allows you to compare interest rates, loan terms, and eligibility criteria across top Indian banks, all in one place. Simply enter your preferences, and we'll show you the best options available.",
  },
  {
    question: "Is there a fee for using Minemi's car loan comparison tool?",
    answer:
      "No, Minemi's car loan comparison service is completely free. You can compare loans, explore options, and make an informed decision without any hidden charges.",
  },
  {
    question: "Can I apply for a car loan directly through Minemi?",
    answer:
      "While Minemi helps you find the best loan options, you'll need to apply for the loan directly through the bank or financial institution of your choice. We provide the necessary information to make your application process easier.",
  },
  {
    question: "How accurate are the loan rates displayed on Minemi?",
    answer:
      "The rates and terms provided are regularly updated and sourced from leading banks in India. We ensure you get the most current and relevant information, but final rates may vary based on your credit profile and eligibility.",
  },
  {
    question: "What documents do I need to apply for a car loan?",
    answer:
      "Typically, you'll need identity proof, income proof, address proof, and vehicle-related documents. Each bank may have its own requirements, and Minemi can guide you on the specifics based on your selected loan options.",
  },
  {
    question: "Can I compare loan options for both new and used cars?",
    answer:
      "Yes, Minemi lets you compare loan options for both new and used cars. You can filter your search based on your vehicle preferences and loan requirements.",
  },
]

export default function FAQ() {
  const [openQuestion, setOpenQuestion] = useState(null)
  const [searchTerm, setSearchTerm] = useState("")

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index)
  }

  const filteredFaqs = faqs.filter(
    (faq) =>
      faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  return (
    <section className="py-8 sm:py-16 bg-gradient-to-b from-white to-[#ebe8fc]">
      <div className="max-w-4xl mx-auto px-4" style={{backgroundColor:'transparent'}}>
        <h2 className="text-2xl sm:text-3xl font-bold text-center text-[#311664] mb-4 sm:mb-8" style={{backgroundColor:'transparent'}}>
          Frequently Asked Questions
        </h2>
        <div className="relative mb-4 sm:mb-8" style={{backgroundColor:'transparent'}}>
          <input
          style={{backgroundColor:'transparent'}}
            type="text"
            placeholder="Search FAQs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-8 sm:pl-10 pr-4 py-1 sm:py-2 w-full text-sm sm:text-base border-2 border-[#3a868b] rounded-full focus:outline-none focus:ring-2 focus:ring-[#311664]"
          />
          <Search className="absolute left-2 sm:left-3 top-1/2 transform -translate-y-1/2 text-[#3a868b] w-4 h-4 sm:w-5 sm:h-5" style={{backgroundColor:'transparent'}} />
        </div>
        <div className="space-y-2 sm:space-y-4" style={{backgroundColor:'transparent'}}>
          {filteredFaqs.map((faq, index) => (
            <div key={index} className="border-b border-gray-200 pb-2 sm:pb-4" style={{backgroundColor:'transparent'}}>
              <button
                onClick={() => toggleQuestion(index)}
                style={{backgroundColor:'transparent'}}
                className="flex justify-between items-center w-full text-left font-semibold text-base sm:text-lg text-[#311664] hover:text-[#3a868b] transition-colors duration-300"
              >
                <span style={{backgroundColor:'transparent'}}>{faq.question}</span>
                {openQuestion === index ? (
                  <ChevronUp className="w-4 h-4 sm:w-6 sm:h-6 text-[#3a868b]" style={{backgroundColor:'transparent'}} />
                ) : (
                  <ChevronDown className="w-4 h-4 sm:w-6 sm:h-6 text-[#3a868b]" style={{backgroundColor:'transparent'}} />
                )}
              </button>
              {openQuestion === index && (
                <p className="mt-2 text-sm sm:text-base text-[#26292b] leading-relaxed"style={{backgroundColor:'transparent'}}> 
                  {faq.answer.split(" ").map((word, i) => (
                    <span
                      key={i}
                      style={{backgroundColor:'transparent'}}
                      className={
                        ["compare", "free", "accurate", "updated", "guide"].includes(word.toLowerCase())
                          ? "font-semibold text-[#3a868b]"
                          : ""
                      }
                    >
                      {word}{" "}
                    </span>
                  ))}
                </p>
              )}
            </div>
          ))}
        </div>
        <div className="mt-8 sm:mt-12 text-center" style={{backgroundColor:'transparent'}}>
          <p className="text-base sm:text-lg text-[#311664] mb-2 sm:mb-4" style={{textAlign:'center',backgroundColor:'transparent'}}>Still have questions?</p>
          <button className="bg-[#3a868b] hover:bg-[#59266d] text-white text-base sm:text-lg py-3 sm:py-4 px-6 sm:px-10 rounded-full transition-all duration-300 transform hover:scale-105">
            Get in touch with our support team
          </button>
        </div>
        <p className="mt-4 sm:mt-8 text-xs sm:text-sm text-center text-gray-600" style={{backgroundColor:'transparent'}}>
          Minemi partners with top banks to bring you the most accurate and transparent information. All comparisons are
          based on the latest available data.
        </p>
      </div>
    </section>
  )
}


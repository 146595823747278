import React, { useEffect, useState } from "react";
import { FaRupeeSign } from "react-icons/fa";
import { FaPercent } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ColorRing } from "react-loader-spinner";
import "./index.css";
import axios from "axios";
import img from '../../../../Assets/Images/Submitted.webp'
import { AnimatePresence } from "framer-motion";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { CheckCircle } from "lucide-react";

const Index = ({data}) => {
    const [show, setShow] = useState(false);
    const[ Foir,setFoir]=useState(0)
    const [amount, setAmount] = useState(
      Number(data?.loanEstimator?.data?.estimatorValue?.loanAmount) || 0
    );
     const [tenure, setTenure] = useState(
      Number(data?.loanEstimator?.data?.estimatorValue?.tenure )
    );
    const [interest, setInterest] = useState(
      Number(data?.loanEstimator?.data?.estimatorValue?.interestRate )
    );
    const [emi, setEmi] = useState(
      Number(data?.loanEstimator?.data?.estimatorValue?.montlyEmi )
    );
    const [loader, setLoader] = useState(false);
    const [maxTenure, setMaxTenure] = useState(0);
    const [maxLoan, setMaxLoan] = useState(0);
    const [errors, setErrors] = useState(false);

    const fields = [
        {
          label: "Loan amount",
          icon: <FaRupeeSign color="#59266D" className="icons" />,
          placeholder: 100000,
          type: "text",
          name: "amount",
          text: "( In Rupees )",
        },
        {
          label: "Loan Tenure",
          icon: <FaRegCalendarAlt color="#59266D" className="icons" />,
          placeholder: 12,
          type: "number",
          name: "tenure",
          text: "( In Months )",
        },
        {
          label: "Interest Rate",
          icon: <FaPercent color="#59266D" className="icons" />,
          placeholder: 12,
          type: "text",
          name: "interest",
          text: "( In Percent )",
        },
        {
          label: "EMI",
          icon: <FaRupeeSign color="#59266D" className="icons" />,
          placeholder: 1000,
          type: "text",
          name: "emi",
          text: "( Per Months )",
        },
    ];
    
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formId=localStorage.getItem("formId")
    if (amount || tenure || interest || emi) {
      const formData = new FormData();
      formData.append("amount", amount);
      formData.append("tenure", tenure);
      formData.append("iRate", interest);
      formData.append("emi", emi);
      formData.append("formId", formId);
      setLoader(true);

      const response = await axios.post(
       `${process.env.REACT_APP_URL}/applyLoan`,
        formData
      );

      if (response.data.status) {
        setShow(true);
      }
      setLoader(false);
    }
  };
  const calculateFOIR = (income, emi) => {
    if (income <= 0) return "Income must be greater than zero";
    const foir = (emi / income) * 100;
    setFoir(String(Math.floor(foir)))
    return ` ${foir.toFixed(2)}`;
  };
  useEffect(() => {
    calculateFOIR(data.monthlyincome, data.monthlyemi)
  }, [data])
  const getRating = (score) => {
    if (score >= 720) {
      return "EXCELLENT 👍";
    } else if (score >= 640) {
      return "GOOD 😊";
    } else {
      return "BAD 😞";
    }
  }
  console.log(data?.loanamount,amount)

  return (
    <>
      <div id="Loan-creditScore" ref={data.secondFoldRef}>
        <div className="titleCon">
          <p className="title m-0 p-0">Factors Banks Consider Apart From Credit Score</p>
        </div>

        <div className="contentCon">
            <div id="top-con">
                <div className="factor-main-con">
                    <div className="factor-con">
                        <p className="factor-text"> Factor - 1</p>
                        <div className="credit-content-con">
                            <div className="d-flex justify-content-center">
                                <img src= {data.monthlyincome>=50000?"../../../images/credit-score/good-money-bag.png":" ../../../images/credit-score/bad-money-bag.png"} alt="good-money-bag" className="credit-content-img1"/>
                            </div>
                            <p className="credit-content-title">Income Stability</p>
                            <p className="credit-content-desc1"> {data.monthlyincome>=50000?"Your income is stable and well-suited for loan approval .":"A Higher income could have increased your loan approval chances. "}</p>
                        </div>
                    </div>
                </div>

                <div className="factor-main-con">
                    <div className="factor-con">
                        <p className="factor-text"> Factor - 2</p>
                        <div className="credit-content-con">
                            <div className="d-flex justify-content-center">
                                <img src= {Foir<=50?"../../../images/credit-score/good-scale.png":" ../../../images/credit-score/bad-scale.png"} alt="good-scale" className="credit-content-img2"/>
                            </div>
                            <p className="credit-content-title">Debt-to-Income Ratio</p>
                            <p className="credit-content-desc2"> {Foir>=50?"Decrease your debts to increase your loan approval chances":"Your debt-to-income ratio is healthy, supporting a strong loan eligibility."}</p>
                        </div>
                    </div>
                </div>
               
                <div className="factor-main-con">
                    <div className="factor-con ">
                        <p className="factor-text"> Factor - 3</p>
                        <div className="credit-content-con">
                            <div className=" d-flex justify-content-center">
                                <img src={"../../../images/credit-score/good-calendar.png"} alt="good-calendar" className="credit-content-img3"/>
                            </div>
                            <p className="credit-content-title">Employment History</p>
                            <p className="credit-content-desc3"> Your stable employment history enhances your financial credibility and supports loan approval.</p>
                        </div>
                    </div>
                </div>
                <div className="factor-main-con">
                    <div className="factor-con">
                        <p className="factor-text"> Factor - 4</p>
                        <div className="credit-content-con">
                            <div className=" d-flex justify-content-center">
                                <img src={Foir<=60?"../../../images/credit-score/good-page.png":"../../../images/credit-score/bad-page.png"} alt="good-page" className="credit-content-img4"/>
                            </div>
                            <p className="credit-content-title">Existing Liabilities</p>
                            <p className="credit-content-desc4">{Foir>=60?" Decrease your liabilities to increase loan approval chances.":"Your existing liabilities are manageable, supporting your ability to handle additional financial commitments."}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="bottom-con">
                <p id="bottom-con-title">Based on your above Credit Score</p>
                <div id="credit-score-details-con">
                    <div className="row m-0 p-0 d-flex justify-content-between">
                        <div className="col-12 col-md-5 p-0">
                            <div id="cs-shape-img">
                                <img src="../../../images/credit-score/good-shape.png" alt="good-shape" id="shape-img" />
                            </div>
                            <p id="shape-text">You are in a <b>{getRating(data?.cibil)}</b>  shape!</p>
                            <p id="shape-title">Financial Health Overview</p>
                            <p id="shape-desc">
                              {data?.loanamount<=amount?"You are eligible for the loan amount you’ve requested. Your financial profile and credit score are well-aligned with lender requirements.":
    "You may qualify for a lower loan amount than requested. Consider reducing obligations or increasing income to enhance eligibility."
                              }
                            </p>
                        </div>

                        <div className="col-12 col-md-6 p-0">
                            <div className="form-con">
                                <p className="form-title m-0 p-0">YOU ARE ELIGIBLE FOR....</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="pe-0">
                                    {fields.map((item, index) => (
                                        <div key={index}>
                                            <div className="d-flex justify-content-between align-items-center input-main-con mt-3">
                                                <p className="label p-0 m-0">{item.label}</p>
                                                <div className="input-con">
                                                <div className="d-flex">
    <InputGroup.Text className="input-icon">{item.icon}</InputGroup.Text>
    {index === 0 ? (
        <input 
            type="text" 
            style={{width:'100%',outline:'none'}}
            className="creditScore-input-field" 
            value={amount ? new Intl.NumberFormat("en-IN").format(amount) : ""}
            onChange={(e) => {
              // Remove all non-digit characters
              const rawValue = e.target.value.replace(/\D/g, '');
              // Convert to number or default to 0 if empty
              const numValue = rawValue === '' ? 0 : Number(rawValue);
              setAmount(numValue);
            }}
            contentEditable={true}
             
        />
    ) : (
        <span className="creditScore-input-field">
            {index === 1 && tenure}
            {index === 2 && interest}
            {index === 3 && new Intl.NumberFormat("en-IN").format(emi)}
        </span>
    )}
</div>

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                    <div className="d-flex justify-content-center btn-con">
                                    {!loader ? (
                                        <Button className="creditScore-apply-btn" type="submit">
                                            Apply Now
                                        </Button>
                                    ) : (
                                        <Button type="button" className="creditScore-apply-btn">
                                        <ColorRing
                                            visible={loader}
                                            height="30"
                                            width="30"
                                            ariaLabel="color-ring-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="color-ring-wrapper"
                                            colors={[
                                            "#ffffff",
                                            "#ffffff",
                                            "#ffffff",
                                            "#ffffff",
                                            "#ffffff",
                                            "#ffffff",
                                            ]}
                                        />
                                        </Button>
                                    )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    
      </div>
      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-md">
          <div>
            <DialogTitle className=" font-bold text-center" style={{fontWeight:'bold',fontSize:21,fontFamily:'Archivo',color:'#59266D'}}>
             Application Submitted Sucessfully!
            </DialogTitle>
          </div>

          <AnimatePresence mode="wait">
            
              <div
                key="success"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="flex flex-col items-center justify-center py-3 space-y-3"
              >
                <div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ type: "spring", damping: 10, stiffness: 100 }}
                >
                  <CheckCircle className="w-16 h-16 text-green-500" />
                </div>
                <p className="text-center text-muted-foreground">
                  Your responses have been recorded. Our team will review your feedback and get back to you soon!
                </p>
              </div>
      
          </AnimatePresence>

          <div className="sm:justify-center" style={{display:'flex'}}>
        
              <Button
                id="loans-modalCloseBtn"
                onClick={()=>setShow(false)}
                variant="outline"
                style={{margin:'auto'}}
                
                className="mt-2 transition-all duration-300 hover:bg-primary hover:text-primary-foreground text-center"
              >
                Close
              </Button>
         
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Index;

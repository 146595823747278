import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import img from "./assets/Images/Credit card vector 1 (1).webp";
import img2 from "./assets/Images/Accept.webp";
import login from "./assets/Images/otp.webp";
import { ColorRing } from "react-loader-spinner";
import Form from "react-bootstrap/Form";
import experian from "./assets/Images/image 1.webp";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { UserContext } from "../ContextAPI/FormContextAPI";

export default function LoginScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const { occupation, category, spendmonth, month } = location.state;
  const [modal, setModal] = useState(true);
  const [isReadMore, setIsReadMore] = useState(true);
  const [isReadMore2, setIsReadMore2] = useState(true);
  const {city} =useContext(UserContext)

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const toggle2ReadMore = () => {
    setIsReadMore2(!isReadMore2);
  };

  const [salaried, setSalaried] = useState(false);
  const [cmpList, setCmpList] = useState([]);
  const [validated, setValidated] = useState(false);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const inputRefs = useRef([]);
  const [otpField, setOtpField] = useState(new Array(6).fill(""));
  const [show, setShow] = useState(false);
  const [cmpModal, setCmpModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const [stgOne, setStgOne] = useState("");
  const [stgTwo, setStgTwo] = useState("");
  const [mbl, setMbl] = useState("");
  const [formId, setFormId] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [token, setToken] = useState("");
  const [data, setData] = useState({
    fname: "",
    lname: "",
    email: "",
    mobile: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault(); // Ensure the form doesn't reload the page on submit.
    setLoader(true); // Show loader while making the API request
    // Show loader while making the API request

    // Check if all fields are filled
    if (
      !data.fname ||
      !data.lname ||
      !data.email ||
      !data.mobile ||
      !check2 ||
      !check1
    ) {
      alert("Please fill in all fields.");
      setLoader(false);
      return;
    }

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if mobile number is valid (should be at least 10 digits)
    if (data.mobile.length < 10) {
      alert("Please enter a valid mobile number with at least 10 digits.");
      setLoader(false);

      return;
    }

    // Check if the email format is valid
    if (!regex.test(data.email)) {
      alert("Please enter a valid email address.");
      setLoader(false);

      return;
    }

    const payload = {
      ...data,
      platform: "web",
      access_for: "credit_card_recommendation",
      purpose: "credit_card_recommendation",
      city:city
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SECRET_KEY}/login`,
        payload
      );

      // Check if the response is successful
      if (response.data.status) {
        // Destructure the required data from the response
        const { expData, token } = response.data.data;

        setStgOne(expData.stgOneHitId);
        setStgTwo(expData.stgTwoHitId);
        setToken(token.token);
        setMbl(data.mobile);
        setShow(true); // Show success state
        setLoader(false); // Hide loader
      } else {
        toast.error(response.data.msg);
        setLoader(false);
      }
    } catch (error) {
      // Handle error in case of API failure
      console.error("Error making POST request", error);
      setLoader(false); // Hide loader even in case of error
      toast.error("Something went wrong. Please try again later.");
    }
  };

  const otpSubmit = async () => {
    setOtpLoader(true);
    const concatenateOtp = otpField.join("");
    if (concatenateOtp.length === 6) {
      let formData = new FormData();
      formData.append("stgOneHitId", stgOne);
      formData.append("stgTwoHitId", stgTwo);
      formData.append("otp", concatenateOtp);
      formData.append("mobileNo", mbl);
      formData.append("type", "CUSTOM");
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/verify_otp`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the Authorization header
            },
          }
        );

        if (response.data.status == 1) {
          localStorage.setItem("token", token);
          localStorage.setItem("creditScore", response.data.data.credit_score);
          localStorage.setItem("name", response.data.data.name);
          localStorage.setItem("id", 1);
          PostHandler();
        } else {
          toast.error(response.data.msg);
          setOtpLoader(false);
        }
      } catch (error) {
        toast.error(error.response.data.msg);
        console.error("Error making POST request", error);
        setOtpLoader(false);
      }
    }
  };

  const handleChange = async (event, cName = null) => {
    let name = null;
    let value = null;
    if (cName === null) {
      name = event.target.name;
      value = event.target.value;
    } else {
      name = cName;
      value = event.value;
    }
    if (
      name === "mobile" ||
      name === "monthly_income" ||
      name === "monthly_emi" ||
      name === "email"
    ) {
      if (name === "mobile") {
        const numericValue = value.replace(/\D/g, "").slice(0, 10);
        (numericValue.length < 1 || numericValue.length >= 10) &&
          setNumberError(false);
        setData((oldData) => ({
          ...oldData,
          [name]: numericValue,
        }));
        numericValue.length >= 1 &&
          numericValue.length < 10 &&
          setNumberError(true);
      } else if (name === "email") {
        setData((oldData) => ({
          ...oldData,
          [name]: value,
        }));
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        regex.test(value) ? setEmailError(false) : setEmailError(true);
      } else {
        if (value === "" || /^\d*$/.test(value)) {
          setData((oldData) => ({
            ...oldData,
            [name]: value,
          }));
        }
      }
    } else if (name === "profession") {
      if (value === "Salaried") {
        setSalaried(true);
        setCmpModal(true);
        const response = await axios.get(
          `${process.env.REACT_APP_SECRET_KEY}/companyList`
        );
        if (response.status === 200) {
          let cmpArray = response.data.data.company;
          const formattedOptions = cmpArray.map((option) => ({
            value: option.id,
            label: option.company_name,
          }));
          setCmpList(formattedOptions);
        }
        setCmpModal(false);
      } else {
        setSalaried(false);
      }
      setData((oldData) => ({
        ...oldData,
        [name]: value,
      }));
    } else {
      setData((oldData) => ({
        ...oldData,
        [name]: value,
      }));
    }
  };

  const otpHandleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, "").slice(0, 1); // Ensure only one digit
    if (!value) return;
  
    const newOtp = [...otpField];
    newOtp[index] = value;
    setOtpField(newOtp);
  
    // Move focus to the next field if the current field has a value
    if (index < 5 && value) {
      inputRefs.current[index + 1].focus();
    }
  }
  
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      // Create a copy of the current OTP array
      const newOtp = [...otpField];
  
      // Clear the current field
      newOtp[index] = "";
  
      // If the current field is empty and not the first field, move focus to the previous field
      if (index > 0 && !newOtp[index]) {
        inputRefs.current[index - 1].focus();
      }
  
      // Update the state with the new OTP array
      setOtpField(newOtp);
    }
  }
  const handleClick = (name) => {
    navigate("/experian-terms-and-conditions", { state: { name } });
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const PostHandler = () => {
    if (spendmonth == "") {
      alert("Please fill field.");
      return;
    } else if (month == undefined) {
      alert("Please check you filled all the previous field correctly !");
      navigate("/select-credit-score");
      return;
    }
    const creditScore = localStorage.getItem("creditScore");
    const form = new FormData();
    form.append("credit_score", creditScore);
    form.append("Occupation", occupation);
    form.append("monthly_income", month);
    form.append("category", category);
    form.append("roughly_spend_monthly", spendmonth);
    const options = {
      method: "POST",
      url: "https://cardai.minemi.ai/match-credit-cards",
      headers: {
        "content-type": "multipart/form-data",
      },
      data: form,
    };

    axios
      .request(options)
      .then(function (response) {
        localStorage.setItem(
          "matchdata",
          JSON.stringify(response.data.matches[0].matches)
        ); // You need to stringify before saving
        localStorage.setItem("attemept", true);
        setOtpLoader(false);
        navigate("/credit-card-recommendations");
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div
      className=" mx-auto px-4 py-8"
      style={{ paddingLeft: "4%", paddingRight: "4%", maxWidth: 1350 }}
    >
      <div className="flex flex-col lg:flex-row justify-between items-start">
        {/* Left Section */}
        <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
          <div className="flex justify-center mb-6">
            <img
              src={img}
              alt="Credit Card"
              className="w-full max-w-lg h-auto"
            />
          </div>
          <h1
            className="text-3xl font-bold font-archivo mb-4"
            style={{
              fontSize: 29,
              fontWeight: 700,
              fontFamily: "Archivo",
            }}
          >
            One Click Away from Your Perfect Credit Card
          </h1>
          <p
            className="text-lg mb-6"
            style={{
              fontWeight: 400,
              fontFamily: "Archivo",
            }}
          >
            Fetch your credit score to unlock tailored recommendations that
            match your financial needs
          </p>

          {[
            "No Impact on Your Credit Score",
            "Personalized Suggestions",
            "Secure and Hassle-Free",
          ].map((feature, index) => (
            <div key={index} className="flex items-start mb-4">
              <img src={img2} alt="Checkmark" className="w-6 h-6 mr-3 mt-1" />
              <div>
                <h3
                  className="text-xl font-semibold mb-1"
                  style={{
                    textAlign: "left",
                    fontWeight: 700,
                    fontFamily: "Archivo",
                  }}
                >
                  {feature}
                </h3>
                <p
                  className="text-gray-600"
                  style={{
                    textAlign: "left",
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: 1.4,
                    fontFamily: "Archivo",
                  }}
                >
                  {index === 0 &&
                    "Checking your credit score here won't harm your profile."}
                  {index === 1 &&
                    "Get credit cards with the best rewards, interest rates, and benefits—tailored to your spending habits and profile."}
                  {index === 2 &&
                    "Your information is safe with us, and the process takes just seconds."}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Right Section - Form */}
        <div className="w-full lg:w-5/12 mt-4">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              maxHeight: 609,
              marginTop: "5%",
            }}
          >
            <div style={{ height: 609, width: 450, maxHeight: 609 }}>
              <div
                id="form-main-con "
                style={{ borderRadius: 32, borderTop: 0 }}
              >
                <div
                  id="form-header"
                  style={{
                    backgroundColor: "#59266D",
                    borderTopLeftRadius: 32,
                    borderTopRightRadius: 32,
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    height: 90,
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      marginLeft: 24,
                      marginRight: 24,
                      backgroundColor: "transparent",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: 700,
                        fontSize: 17,
                        color: "#FFFFFF",
                        marginBottom: 0,
                        backgroundColor: "transparent",
                        fontFamily: "Archivo",
                      }}
                    >
                      Credit Card Recommendation Quiz <br />
                    </p>
                    <p
                      className="form-header-sub-heading"
                      style={{
                        fontWeight: 400,
                        fontFamily: "Archivo",
                        fontSize: 15,
                        color: "#FFFFFF",
                        marginBottom: 0,
                        backgroundColor: "transparent",
                      }}
                    >
                      Enter your details and get the best card recommendation in
                      minutes.
                    </p>
                  </div>
                </div>
                <div
                  id="form-con"
                  className="add-padd"
                  style={{
                    padding: "8px 10px",
                    backgroundColor: "#E7E4F9",
                    borderBottomLeftRadius: 32,
                    borderBottomRightRadius: 32,
                  }}
                >
                  <Form
                    noValidate
                    onSubmit={handleSubmit}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div style={{ backgroundColor: "transparent" }}>
                      <Form.Control
                        style={{
                          border: "1.4px solid #846CA6",
                          borderRadius: 8,
                          fontFamily: "Archivo",
                          fontWeight: 400,
                          flex: "1 1 48%",
                          height: 36,
                          fontWeight: 400,
                          fontSize: 16,
                          marginTop: 12,
                        }}
                        required
                        type="text"
                        placeholder="First Name"
                        className="inputs"
                        name="fname"
                        value={data.fname}
                        onChange={handleChange}
                      />
                      <div
                        style={{
                          minHeight: "18px",
                          backgroundColor: "transparent",
                        }}
                      ></div>
                    </div>

                    <div style={{ backgroundColor: "transparent" }}>
                      <Form.Control
                        style={{
                          border: "1.4px solid #846CA6",
                          borderRadius: 8,
                          flex: "1 1 48%",
                          height: 36,
                          fontFamily: "Archivo",
                          fontWeight: 400,
                          fontSize: 16,
                        }}
                        required
                        type="text"
                        placeholder="Last Name"
                        className="inputs"
                        name="lname"
                        value={data.lname}
                        onChange={handleChange}
                      />
                      <div
                        style={{
                          minHeight: "18px",
                          backgroundColor: "transparent",
                        }}
                      ></div>
                    </div>

                    <div style={{ backgroundColor: "transparent" }}>
                      <Form.Control
                        style={{
                          border: "1.4px solid #846CA6",
                          borderRadius: 8,
                          flex: "1 1 48%",
                          height: 36,
                          fontFamily: "Archivo",
                          fontWeight: 400,
                          fontSize: 16,
                        }}
                        required
                        type="text"
                        placeholder="Email Address"
                        className="inputs"
                        value={data.email}
                        name="email"
                        onChange={handleChange}
                      />
                      <div
                        style={{
                          minHeight: "18px",
                          backgroundColor: "transparent",
                        }}
                      >
                        {/* {(validated && !data.email) && <p className='field-error' style={{color:'red',fontWeight:400,fontFamily:'Archivo-Regular',marginBottom:0,fontFamily: 'Archivo-Regular', fontSize: 14,}}>Email is required field</p>}
              {emailError && <p className='field-error'style={{color:'red',fontWeight:400,fontFamily:'Archivo-Regular',marginBottom:0,fontFamily: 'Archivo-Regular', fontSize: 14,}} >Invalid Email</p>} */}
                      </div>
                    </div>
                    <div style={{ backgroundColor: "transparent" }}>
                      <Form.Control
                        style={{
                          border: "1.4px solid #846CA6",
                          borderRadius: 8,
                          flex: "1 1 48%",
                          height: 36,
                          fontFamily: "Archivo",
                          fontWeight: 400,
                          fontSize: 16,
                        }}
                        required
                        type="text"
                        maxLength={10}
                        placeholder="Contact Number"
                        className="mbl-input"
                        name="mobile"
                        value={data.mobile}
                        onChange={handleChange}
                      />
                      <div
                        style={{
                          minHeight: "18px",
                          backgroundColor: "transparent",
                        }}
                      >
                        {/* {(validated && !data.mobile) && <p className='field-error' style={{color:'red',fontWeight:400,fontFamily:'Archivo-Regular',marginBottom:0,fontFamily: 'Archivo-Regular', fontSize: 14,}}>Number is required field</p>}
              {numberError && <p className='field-error' style={{color:'red',fontWeight:400,fontFamily:'Archivo-Regular',marginBottom:0,fontFamily: 'Archivo-Regular', fontSize: 14,}}>Number must be 10 digits</p>} */}
                      </div>
                    </div>
                    <div
                      id="checkbox-con"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <div
                        className="d-flex gap-6 mt-2"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <input
                          type="checkbox"
                          onChange={(e) => setCheck1(e.target.checked)}
                          className="form-check-input"
                          style={{
                            border: "1px solid #59266D",
                            marginRight: 4,
                            width: 24,
                            height: 24,
                            borderRadius: 6,
                            backgroundColor: check1 ? "#59266D" : "transparent",
                          }}
                        />
                        {isReadMore ? (
                          <p
                            style={{
                              fontSize: 12,
                              color: "#1F1F1F",
                              fontWeight: 400,
                              fontFamily: "Archivo",
                              backgroundColor: "transparent",
                            }}
                          >
                            {`I agree to MinEMI's `}
                            <Link
                              to="https://minemi.ai/terms-condition"
                              style={{
                                color: "rgba(89, 38, 109, 1)",
                                fontSize: 12,
                                fontWeight: 600,
                                fontFamily: "Archivo",
                                textDecorationLine: "none",
                                backgroundColor: "transparent",
                              }}
                            >
                              Terms of Use
                            </Link>
                            {` and `}
                            <Link
                              to="https://minemi.ai/privacypolicy"
                              style={{
                                color: "rgba(89, 38, 109, 1)",
                                fontWeight: 600,
                                textDecorationLine: "none",
                                backgroundColor: "transparent",
                              }}
                            >
                              Privacy Policy
                            </Link>
                            {`  and authorise to be communicated ... via SMS, Call, Whatsapp, and Email. I hereby also allow MinEMI to access my credit report on my behalf.`.slice(
                              0,
                              87
                            )}
                          </p>
                        ) : (
                          <p
                            style={{
                              fontSize: 12,
                              color: "#1F1F1F",
                              fontWeight: 400,
                              fontFamily: "Archivo",
                              backgroundColor: "transparent",
                            }}
                          >
                            I agree to MinEMI's{" "}
                            <Link
                              to="https://minemi.ai/terms-condition"
                              style={{
                                color: "rgba(89, 38, 109, 1)",
                                fontWeight: 600,
                                textDecorationLine: "none",
                                fontSize: 12,
                                backgroundColor: "transparent",
                              }}
                            >
                              Terms of Use{" "}
                            </Link>
                            and
                            <Link
                              to="https://minemi.ai/privacypolicy"
                              style={{
                                color: "rgba(89, 38, 109, 1)",
                                fontSize: 12,
                                fontWeight: 400,
                                fontFamily: "Archivo",
                                textDecorationLine: "none",
                                backgroundColor: "transparent",
                              }}
                            >
                              {" "}
                              Privacy Policy
                            </Link>{" "}
                            and authorise to be communicate via SMS ,Call,
                            Whatsapp and Email. I hereby also allow MInEMI to
                            access my credit report on my behalf{" "}
                          </p>
                        )}
                        <span
                          onClick={toggleReadMore}
                          className="read-or-hide"
                          style={{
                            color: "#59266D",
                            fontSize: 12,
                            fontWeight: 400,
                            fontFamily: "Archivo",
                            backgroundColor: "transparent",
                          }}
                        >
                          {isReadMore ? (
                            <div
                              style={{
                                color: "#59266D",
                                fontSize: 12,
                                fontWeight: 400,
                                fontFamily: "Archivo",
                                backgroundColor: "transparent",
                              }}
                            >
                              ...Read More
                            </div>
                          ) : (
                            " Read Less"
                          )}
                        </span>
                      </div>
                      {/* {(validated && !check1) && <p className='field-error ms-4' style={{color:'red',fontWeight:400,fontFamily:'Archivo-Regular',marginTop:4,marginBottom:0,fontFamily: 'Archivo-Regular', fontSize: 14,}}>You must agree before submitting.</p>} */}

                      <div
                        className="d-flex mt-2  gap-6"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <input
                          type="checkbox"
                          onChange={(e) => setCheck2(e.target.checked)}
                          className="form-check-input"
                          style={{
                            border: "1px solid #59266D",
                            marginRight: 4,
                            width: 24,
                            height: 24,
                            borderRadius: 6,
                            backgroundColor: check2 ? "#59266D" : "transparent",
                          }}
                        />
                        {/* <label className='form-check-label' style={{fontSize:12,color:'#1F1F1F',fontWeight:400,fontFamily:'Archivo'}}>Lorem ipsum dolor sit amet consectetur. Non eu integer quis quisque leo eu tellus. Mauris id egestas cursus .</label> */}
                        {isReadMore2 ? (
                          <p
                            style={{
                              fontSize: 12,
                              color: "#1F1F1F",
                              fontWeight: 400,
                              fontFamily: "Archivo",
                              backgroundColor: "transparent",
                            }}
                          >
                            {`I hearby consent to Nine Triangles Pvt.Ltd. being appointed as my authorised representative to receive my credit information from Experian for the purpose of ..`.slice(
                              0,
                              95
                            )}
                          </p>
                        ) : (
                          <p
                            style={{
                              color: "#1F1F1F",
                              fontSize: 12,
                              fontWeight: 400,
                              fontFamily: "Archivo",
                              backgroundColor: "transparent",
                            }}
                          >
                            {" "}
                            I hearby consent to Nine Triangles Pvt.Ltd. being
                            appointed as my authorised representative to receive
                            my credit information from Experian for the purpose
                            of Credit Assessment to advice me on the best loan
                            offers on expiry of 6 months from the date the
                            consent is collected, whichever is earlier. I also
                            agree to{" "}
                            <Link
                              to="https://minemi.ai/experianconsent"
                              style={{
                                color: "rgba(89, 38, 109, 1)",
                                fontWeight: 600,
                                fontSize: 12,
                                fontWeight: 400,
                                textDecorationLine: "none",
                              }}
                            >
                              Experian Terms and Conditions.{" "}
                            </Link>
                          </p>
                        )}
                        <span
                          onClick={toggle2ReadMore}
                          className="read-or-hide"
                          style={{
                            color: "#59266D",
                            fontSize: 12,
                            fontWeight: 400,
                            fontFamily: "Archivo",
                            backgroundColor: "transparent",
                          }}
                        >
                          {isReadMore2 ? (
                            <div
                              style={{
                                color: "#59266D",
                                backgroundColor: "transparent",
                              }}
                            >
                              ...Read More
                            </div>
                          ) : (
                            " Read Less"
                          )}
                        </span>
                      </div>

                      {/* {(validated && !check2) && <p className='field-error ms-4' style={{color:'red',fontWeight:400,fontFamily:'Archivo-Regular',marginTop:4,fontFamily: 'Archivo-Regular', fontSize: 14,}}>You must agree before submitting.</p>} */}

                      <div
                        className="d-flex justify-content-center mt-0 "
                        style={{ backgroundColor: "transparent" }}
                      >
                        {!loader ? (
                          <Button
                          className="button-class"
                          disabled={loader}
                            type="submit"
                            style={{
                              fontFamily: "Archivo-Bold",
                              color: "#FFFFFF",
                              fontSize: 16,
                              backgroundColor: "#59266D",
                              border: "none",
                              paddingLeft: 86,
                              paddingRight: 86,
                              color: "white",
                              paddingTop: 14,
                              paddingBottom: 14,
                              fontSize: 16,
                              fontWeight: 700,
                              fontFamily: "Archivo",
                              borderRadius: 8,
                              marginBottom: "4%",
                              marginTop: 26,
                            }}
                          >
                            Get Started
                          </Button>
                        ) : (
                          <Button
                          className="button-class"
                            type="button"
                            style={{ backgroundColor: "#59266D" }}
                          >
                            <ColorRing
                              visible={loader}
                              style={{}}
                              height="20"
                              width="260"
                              color="white"
                              ariaLabel="color-ring-loading"
                              wrapperStyle={{
                                fontFamily: "Archivo-Bold",
                                backgroundColor: "#59266D",
                                border: "none",
                                color: "white",
                              }}
                              wrapperClass="color-ring-wrapper"
                              colors={[
                                "#ffffff",
                                "#ffffff",
                                "#ffffff",
                                "#ffffff",
                                "#ffffff",
                                "#ffffff",
                              ]}
                            />
                          </Button>
                        )}
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* OTP Modal */}
      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        backdrop={true}
        keyboard={false}
        style={{ backgroundColor: "transparent" }}
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <div className="row" id="otpCon">
            <div className="col-lg-6 px-0">
              <img src={login} alt="" style={{ objectFit: "cover" }} />
            </div>

            <div className="col-lg-6" id="otp-section">
              <div>
                <p className="m-0 p-0 otp-text ">Enter OTP</p>
                <p className="m-0 p-0 otp-desc mt-2">
                  Please enter the one-time password (OTP) sent on the given
                  number - {data.mobile}
                </p>
              </div>

              <div className="d-flex justify-content-between otp-input-con">
                {otpField.map((digit, index) => (
                  <Form.Control
                    type="number"
                    className="otp-inputs"
                    key={index}
                    maxLength="1"
                    value={digit}
                    onChange={(e) => otpHandleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </div>

              <div className="otpBtn">
                {!otpLoader ? (
                  <Button  className="button-class" id="verify-btn" onClick={otpSubmit}>
                    Verify
                  </Button>
                ) : (
                  <Button
                  className="button-class"
                    id="verify-btn"
                    style={{ backgroundColor: "#633376" }}
                  >
                    <ColorRing
                      visible={otpLoader}
                      height="30"
                      width="30"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{ backgroundColor: "#633376" }}
                      wrapperClass="color-ring-wrapper"
                      style={{ color: "white", backgroundColor: "#633376" }}
                      colors={[
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                      ]}
                    />
                  </Button>
                )}
                <Button className="button-class" id="resend-otp" onClick={() => handleSubmit()}>
                  Resend OTP
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
import React from "react";
import "./Serve.css";
import Accordion from "react-bootstrap/Accordion";
import { FaPlus } from "react-icons/fa";

const Serve = () => {
  const accordionData = [
    {
      title: "Personal Loan Eligibility",
      content: (
        <p className="m-0 bg-transparent">
          A Quick Guide Your personal loan eligibility depends on several
          factors like your income, credit score, and existing financial
          obligations. Most banks look for a credit score of 660+ and a steady
          income stream. At Minemi, we help you check your eligibility
          instantly, so you know exactly where you stand before applying for a
          personal loan, without impacting your credit score. Know your chances
          upfront, and avoid unnecessary rejections!
        </p>
      ),
    },
    {
      title: "Features and Benefits of Personal Loan",
      content: (
        <div className="bg-transparent">
          Features and Benefits of Personal Loan & Benefits of Checking
          Eligibility Before Applying Features and Benefits of a Personal Loan
          Personal loans offer flexibility, whether you need funds for medical
          emergencies, education, or home renovations.
          <p className="my-2 bg-transparent">Key features include:</p>
          <p className="my-1 bg-transparent">•No collateral required</p>
          <p className="m-0 bg-transparent">• Flexible repayment tenures</p>
          <p className="my-1 bg-transparent">• Competitive interest rates</p>
          <p className="m-0 bg-transparent">• Quick disbursal</p>
        </div>
      ),
    },
    {
      title: "Why Check Personal Loan Eligibility? ",
      content: (
        <p className="m-0 bg-transparent">
          When to Consider a Personal Loan A personal loan can be the right
          choice when you need quick access to funds for situations like debt
          consolidation, unexpected medical expenses, weddings, or even home
          repairs. The flexibility of use, easy approvals, and manageable EMIs
          make personal loans convenient for short-term financial needs.
          However, it's important to assess your repayment ability before taking
          on a new loan. Use Minemi's eligibility checker to explore the best
          loan options for your needs.
        </p>
      ),
    },

    {
      title: "Required Documents",
      content: (
        <div className="bg-transparent">
          Required Documents Documents You Need for a Personal Loan Here's a
          checklist of documents you'll need to apply for a personal loan
          <p className="my-1 bg-transparent">
            • Identity Proof: PAN Card, Aadhaar Card, Passport
          </p>
          <p className="m-0 bg-transparent">
            • Address Proof: Utility bills, Aadhaar, or rental agreements
          </p>
          <p className="my-1 bg-transparent">
            • Income Proof: Latest salary slips or bank statements (for
            salaried), IT returns or audited financials (for self-employed)
          </p>
          <p className="my-1 bg-transparent">
            • Employment Proof: Offer letter or work ID Having these documents
            ready will speed up the personal loan application process, so be
            prepared before applying.
          </p>
        </div>
      ),
    },
    {
      title: " Frequently Asked Questions (FAQs) ",
      content: (
        <div className="m-0 bg-transparent">
          <p className="m-0 bg-transparent">
            1. What is the minimum credit score required for a personal loan?
            Most lenders prefer a credit score above 660, but eligibility can
            vary by lender. Check your personal loan eligibility with Minemi to
            find out where you stand.
          </p>
          <p className="my-2 bg-transparent">
            2. How much personal loan can I get? Your personal loan amount
            depends on your income, current financial obligations, and credit
            score. Use Minemi's eligibility checker to see how much you qualify
            for without affecting your credit score.
          </p>
          <p className="m-0 bg-transparent">
            3. Does checking loan eligibility impact my credit score? No,
            checking your eligibility through Minemi does not impact your credit
            score since it's a soft inquiry.
          </p>
          <p className="my-2 bg-transparent">
            4. Can I apply for a personal loan if I have other ongoing loans?
            Yes, but your eligibility is influenced by your FOIR (Fixed
            Obligation to Income Ratio). Minemi can help you understand how your
            existing loans affect your personal loan eligibility.
          </p>
          <p className="m-0 bg-transparent">
            5. How quickly can I get my personal loan? Personal loans are
            typically disbursed within a few days, depending on the lender’s
            verification process. Use Minemi to streamline the process and get
            personalized loan offers.
          </p>
        </div>
      ),
    },
  ];
  return (
    <>
      <div id="serve">
        <div className="contentCon">
          <div id="header-con">
            <p className="bg-transparent">We swear to serve you with ...</p>
          </div>
          <div id="content" className="bg-transparent">
            <div className="d-flex flex-wrap w-100 bg-transparent">
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/loan_eligibility.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/no_empact.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Impact on credit Score</p>
              </div>

              <div className="plus-icon-con second-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/interest_rate.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Best Interest Rate Offer</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/no_calls.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Calls From Us</p>
              </div>

              <div className="plus-icon-con fourth-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Data Security Guarantee</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>
            </div>

            <div id="line-breaker"></div>

            <div className="accordion-con bg-transparent">
              <Accordion className="bg-transparent">
                {accordionData.map((item, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body
                      style={{
                        visibility: "visible",
                        backgroundColor: "transparent",
                      }}
                    >
                      {item.content}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Serve;

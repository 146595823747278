import React, { useContext, useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import "./Question.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Modal, Box, useMediaQuery } from "@mui/material/";
import logo from ".././assets/Images/mLogo.webp";
import MetadataContext from "../../MetadataContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "#F6F6F6",
  boxShadow: 24,
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 2,
  paddingRight: 2,
  border: 0,
};

const incomeOptions = [
  "Less than Rs. 30,000",
  "Rs. 30,000 - Rs. 50,000",
  "Rs. 50,000 - Rs. 1,00,000",
  "Rs. 1,00,000 - Rs. 2,00,000",
  "More than Rs. 2,00,000",
];

const SelectPrefer = () => {
  const [progress, setProgress] = useState(30);
  const [active, setActive] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { category } = location.state || {};
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("Less than ₹30,000");

  const isSmallScreen = useMediaQuery("(max-width:576px)");

  useEffect(() => {
    // Retrieve stored value from localStorage
    const storedValue = localStorage.getItem("selectedIncome");
    if (storedValue) {
      const index = incomeOptions.indexOf(storedValue);
      if (index !== -1) {
        setActive(index);
        setValue(storedValue);
      }
    } else {
      localStorage.setItem("selectedIncome", incomeOptions[0]);
      setActive(0);
      setValue(incomeOptions[0]);
    }
  }, []);

  const handleSelection = (index, option) => {
    setActive(index);
    setValue(option);
    localStorage.setItem("selectedIncome", option); // Store the selected value
  };

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Select Preferences - Minemi ",
      pageDescription:
        "Select your financial preferences with Minemi  and get personalized recommendations.",
      pageKeywords:
        "payoff loan options, loan payoff calculator, loan repayment options, debt payoff strategies, loan refinancing, loan consolidation, loan payoff plan, loan repayment calculator",

      canonicalUrl: "https://minemi.ai/select-prefer",
    });
  }, [setMetadata]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div className="progress-bar-background" />
        <div className="progress-bar" style={{ width: `${progress}%` }} />
        <div className="min-h-[100vh] w-full pt-4 md:pt-8 px-4 md:px-6">
          {/* Header Navigation */}
          <div
            className="container"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingLeft: "4%",
              paddingRight: "4%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 8,
                cursor: "pointer",
              }}
              onClick={() => navigate("/select-payoff-loan")}
            >
              <FaArrowLeftLong
                style={{
                  alignSelf: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  fontSize: 20,
                  color: "#8A8A8A",
                }}
              />
              <p
                style={{
                  alignSelf: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  fontSize: 16,
                  marginBottom: 0,
                  color: "#8A8A8A",
                }}
              >
                Back
              </p>
            </div>
            <div onClick={() => setOpen(true)}>
              <IoClose
                style={{
                  alignSelf: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  fontSize: 24,
                  color: "#8A8A8A",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>

          {/* Main Content */}
          <div className="mx-auto">
            {/* Title */}
            <h1 className="text-center font-['Archivo'] text-2xl md:text-3xl font-semibold text-black mb-8">
              What’s your monthly income?
            </h1>

            {/* Income Selection Buttons */}
            <div
              // className="flex flex-col sm:flex-row justify-center gap-4 md:gap-5 px-4"
              // style={{ flexWrap: "wrap" }}
              className="mt-4"
              style={{
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
                gap: 16,
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                flexWrap: "wrap",
              }}
            >
              {incomeOptions.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleSelection(index, option)}
                  style={{
                    marginBottom: "4%",
                    fontFamily: "Archivo",
                    fontSize: 16,
                    fontWeight: 400,
                    width: isSmallScreen ? "100%" : "300px",
                    padding: "10px",
                  }}
                  className={`
                 
                  w-full sm:w-48
                  rounded-lg
                  font-['Archivo']
                  shadow-md
                  transition-all
                  ${
                    active === index
                      ? "bg-[#E7E4F9] border-2 border-[#59266D]"
                      : "bg-white hover:bg-gray-50"
                  }
                `}
                >
                  {option}
                </button>
              ))}
            </div>

            {/* Next Button */}
            <div className="mt-3 md:mt-12 flex justify-center px-4">
              <button
                style={{ fontFamily: "Archivo" }}
                onClick={() => navigate("/select-assets")}
                className="next-button"
              >
                NEXT
              </button>
            </div>
          </div>
        </div>

        {/* Modal */}
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: 40, height: 40 }}>
                <img
                  src={logo}
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </div>
              <IoClose
                onClick={() => setOpen(false)}
                style={{
                  alignSelf: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  fontSize: 24,
                  cursor: "pointer",
                }}
              />
            </div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#59266D",
                fontSize: 24,
                fontFamily: "Archivo",
                fontWeight: 700,
                marginTop: 16,
              }}
            >
              Wait ! your loan recommendation is just a step away !
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              style={{
                color: "#8A8A8A",
                fontSize: 18,
                fontWeight: 400,
                fontFamily: "Archivo",
              }}
            >
              Complete the form to receive the best credit card tailored to your
              needs.
            </Typography>
            <div
              style={{
                backgroundColor: "#59266D",
                padding: 15,
                color: "#FFFFFF",
                borderRadius: 10,
                textAlign: "center",
                marginTop: "4%",
                fontFamily: "Archivo",
                fontWeight: 700,
                fontSize: 16,
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            >
              Keep Going
            </div>
            <div
              style={{
                backgroundColor: "#F6F6F6",
                padding: 15,
                color: "#59266D",
                borderRadius: 10,
                textAlign: "center",
                marginTop: "4%",
                fontFamily: "Archivo",
                fontWeight: 700,
                fontSize: 16,
                border: "1px solid #59266D",
                cursor: "pointer",
              }}
              onClick={() => navigate("/creditcard-recommendation")}
            >
              No Thanks
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default SelectPrefer;

import React from "react";
import "./TableData.css";
import { Button, useMediaQuery } from "@mui/material";

const TableData = ({ onButtonClick }) => {
  const data = [
    {
      lender: "750+ (Excellent Credit)",
      rate: "6.9% - 7.5%",
      // tenure: "2 Years",
      // fees: "₹1,00,000",
    },
    {
      lender: "700 - 749 (Good Credit)",
      rate: "7.6% - 8.9%",
      // tenure: "1 Years",
      // fees: "₹5,00,000",
    },
    {
      lender: "650 - 699 (Average Credit)",
      rate: "9.0% - 12.5%",
      // tenure: "5 Years",
      // fees: "₹9,00,000",
    },
    {
      lender: "Below 650 (Low Credit)",
      rate: "12.6% - 18%",
      // tenure: "15 Years",
      // fees: "₹20,00,000",
    },
  ];

  // useMediaQuery
  const smallScreen = useMediaQuery("(max-width:576px)");
  const mediumScreen = useMediaQuery("(max-width:768px)");
  const largeScreen = useMediaQuery("(max-width:992px)");

  return (
    <>
      <div id="table-data-container">
        <div>
          <div className="table-heading-text-box ">
            <h3 className="table-heading-text">
              Discover Loans Tailored to Your Credit Score
            </h3>
          </div>
        </div>
        <div className="table-container">
          <table className="responsive-table">
            <thead>
              <tr>
                <th>Credit Score Range</th>
                <th>Eligible Loan Interest Rate</th>
                {/* <th>Loan Tenure</th>
                <th>Loan Amount</th> */}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td>{row.lender}</td>
                  <td>{row.rate}</td>
                  {/* <td>{row.tenure}</td>
                  <td>
                    {row.fees}
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          id="apply-now-btn"
          className="mt-lg-4"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            id="table-button-box"
            className="pt-3 pt-md-4 bg-transparent"
            style={{
              width: smallScreen
                ? "9rem"
                : mediumScreen
                ? "15rem"
                : largeScreen
                ? "15rem"
                : "15rem",
            }}
          >
            <button
              onClick={onButtonClick}
              style={{
                width: "100%",
                backgroundColor: "#59266D",
                color: "#ffff",
                fontSize: smallScreen
                  ? "15px"
                  : mediumScreen
                  ? "15px"
                  : largeScreen
                  ? "20px"
                  : "24px",
                fontWeight: 500,
                lineHeight: smallScreen ? "15px" : "32px",
                borderRadius: smallScreen ? "4px" : "10px",
                padding: smallScreen
                  ? "0.6rem 1.8rem"
                  : mediumScreen
                  ? "0.6rem 5rem"
                  : largeScreen
                  ? "0.6rem 2rem"
                  : "1rem 0rem",
                fontFamily: "'Archivo',sans-serif",
                letterSpacing: "-0.17px",
                textTransform: "none",
                textAlign: "center",
              }}
              variant="contained"
            >
              Apply Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableData;

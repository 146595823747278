import React from "react";
import "./TopUpMinemi.css";

const TopUpMinemi = () => {
  return (
    <>
      <div id="top-up-minemi" className="bg-transparent ">
        <div id="list-of-documents-container">
          <div id="documents-content" className="bg-transparent">
            <div className="documents-content-container bg-transparent">
              <div className="content-box w-100 bg-transparent ">
                {" "}
                <div className="documents-text-box bg-transparent">
                  <div className="bg-transparent">
                    <div id="choose-minemi-head-box" className="bg-transparent">
                      <div className="choose-minemi-heading bg-transparent">
                        <h4 id="documents-heading" className="bg-transparent">
                          Why Check Your Credit Score With MinEMI?
                        </h4>
                      </div>
                    </div>

                    <div className="text-box bg-transparent">
                      <div className="d-flex flex-row gap-3 gap-md-5 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent m-0">
                          Check your credit score for free, securely and
                          instantly.
                        </p>
                      </div>
                      <div className="d-flex flex-row gap-3 gap-md-5 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent m-0">
                          Improve your credit profile with actionable insights.
                        </p>
                      </div>
                      <div className="d-flex flex-row gap-3 gap-md-5 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent m-0">
                          Find the lowest interest rates available in the
                          market.
                        </p>
                      </div>
                      <div className="d-flex flex-row gap-3 gap-md-5 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent m-0">
                          Discover loan offers tailored to your credit score and
                          income.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="documents-image-box d-none d-md-block">
                  <div>
                    <img
                      className="documents-image"
                      src={Image}
                      alt="docuents-image"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopUpMinemi;

import React from "react";
import "./SimpleSteps.css";
// import vectorOne from "../../../../Assets/Images/pl-details-vector.png";
import vectorOne from "../../../../../../Assets/Images/pl-details-vector.webp";
import vectorTwo from "../../../../../../Assets/Images/bt-icon-four.webp";
import vectoThree from "../../../../../../Assets/Images/icon-three.webp";
import vectorFour from "../../../../../../Assets/Images/icon-four.webp";

const SimpleSteps = () => {
  return (
    <>
      <div id="lower-bt-simple-steps-container">
        <div id="steps-content-page">
          <div className="heading-text-container">
            <h4 className="heading-text">Get started in 4 simple steps</h4>
          </div>
          <div className="steps-page-content d-flex flex-row flex-md-column">
            <div id="vectors-img-container">
              <div className="image-content-section  d-flex flex-column flex-md-row gap-4 justify-content-md-between  pt-md-3 ">
                {/* image one */}

                <div className=" vector-img-text-container w-100  d-flex flex-row flex-md-column  gap-3 gap-lg-3 ">
                  <div className="vector-image ">
                    <img
                      id="img-one"
                      className="bg-transparent"
                      src={vectorOne}
                      alt="vector-image"
                    />
                  </div>
                  <div id="vector-text-one">
                    <p className="text">
                      Enter Your Loan details & <br /> submit application
                    </p>
                  </div>
                </div>

                {/* image two */}

                <div className=" vector-img-text-container  w-100  d-flex flex-row flex-md-column  gap-3 gap-lg-3 ">
                  <div className="vector-image image-two-box">
                    <img
                      id="img-two"
                      className="bg-transparent"
                      src={vectorTwo}
                      alt="vector-image"
                    />
                  </div>
                  <div id="vector-text-two">
                    <p className="text">
                      We Evaluate Your Current <br /> EMI & Offer Better Option
                    </p>
                  </div>
                </div>

                {/* image three */}

                <div className=" vector-img-text-container w-100  d-flex flex-row flex-md-column  gap-3 gap-lg-3 ">
                  <div className="vector-image">
                    <img
                      id="img-three"
                      className="bg-transparent"
                      src={vectoThree}
                      alt="vector-image"
                    />
                  </div>
                  <div id="vector-text-three">
                    <p className="text">
                      Get Confirmation from <br /> Lending Partner
                    </p>
                  </div>
                </div>

                {/* image four */}

                <div className=" vector-img-text-container w-100  d-flex flex-row flex-md-column  gap-3 gap-lg-3 ">
                  <div className="vector-image">
                    <img
                      id="img-four"
                      className="bg-transparent"
                      src={vectorFour}
                      alt="vector-image"
                    />
                  </div>
                  <div id="vector-text-four">
                    <p className="text">
                      Enjoy Reduced EMIs & <br /> Better Loan Terms
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className=" vector-text-container d-flex flex-column flex-md-row  ">
              <div id="vector-text">
                <p>Lending Partner calls you to confirm details</p>
              </div>
              <div id="vector-text">
                <p>Site visit & in-person discussion</p>
              </div>
              <div id="vector-text">
                <p>Loan Approval & Get funds disbursed</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SimpleSteps;

import React, { useEffect, useState } from "react";
import "./HeroSection.css";
import { IoMdArrowForward } from "react-icons/io";
import lowerEmiIllustration from "../../Assets/Images/LowerEmiIllustration.webp";
import ConsolidateIllustration from "../../Assets/Images/ConsolidateIllustration.webp";
import TopUPIllustration from "../../Assets/Images/TopUPIllustration.1.webp";
import creditPro from "../../Assets/Images/6245943.webp";

import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { HashLink as RouterHashLink } from "react-router-hash-link";

const HeroSection = () => {
  const isSmallScreen = useMediaQuery("(max-width:576px)");
  const texts = [
    "Paying more EMIs than you should?",
    "Paying too many different EMIs? ",
    "Looking to top-up your running loan?",
  ];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="HeroSection">
        <div id="HeroSectionHeading">
          <h1 className="HeroHeading">Minimize your EMIs with MinEMI</h1>
          <h2 className="SubHeading">
            Are you{" "}
            <span style={{ backgroundColor: "transparent", color: "#009964" }}>
              {texts[index]}
            </span>
            <p style={{fontSize:18,fontWeight:400,color:'#59266D'}}>With MinEMI you can:</p>
          </h2>
        </div>

        <div className="HeroContainer">
          <div className="Section">
            <a
              className="lowerEmi shadow-md"
              href="/lower-emi-page"
              onClick={() => {
                localStorage.setItem("id", 1);
                window.scrollTo(0, 0);
              }}
            >
              <div className="InnerSection">
                <div className="InnerSectionText">
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Lower Your EMIs the Smart Way
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Use our algorithm to reduce your loan interest rate based on
                    your credit score.
                  </div>
                </div>
                <a
                  href="/lower-emi-page"
                  onClick={() => {
                    localStorage.setItem("id", 1);
                    window.scrollTo(0, 0);
                  }}
                  style={{ backgroundColor: "transparent" }}
                >
                  <IoMdArrowForward
                    size={30}
                    color={"#59266D"}
                    style={{ backgroundColor: "rgba(85, 50, 133, 0)" }}
                  />
                </a>
              </div>
              <div className="Illustration">
                <img
                  src={
                    "https://minemiwebsite.s3.ap-south-1.amazonaws.com/OurServiceLower.webp"
                  }
                  alt="Lower Emi"
                  loading="lazy"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </a>
          </div>

          <div className="Section">
            <a
              href="/consolidate-emi-pages"
              className="lowerEmi shadow-md"
              onClick={() => {
                localStorage.setItem("id", 2);
                window.scrollTo(0, 0);
              }}
            >
              <div className="InnerSection">
                <div className="InnerSectionText">
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Consolidate Multiple Loans into One EMI
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Combine your loans into one easy-to-manage EMI with lower
                    interest rates.
                  </div>
                </div>

                <a
                  href="/consolidate-emi-pages"
                  onClick={() => {
                    localStorage.setItem("id", 2);
                    window.scrollTo(0, 0);
                  }}
                  style={{ backgroundColor: "transparent" }}
                >
                  {" "}
                  <IoMdArrowForward
                    size={30}
                    color={"#59266D"}
                    style={{ backgroundColor: "rgba(85, 50, 133, 0)" }}
                  />{" "}
                </a>
              </div>
              <div className="ConsolidateIllustration">
                <img
                  src={
                    "https://minemiwebsite.s3.ap-south-1.amazonaws.com/OurServiceConsolidate.webp"
                  }
                  alt="Club Multiple EMIs"
                  loading="lazy"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </a>
          </div>

          <div className="Section">
            <a
              href="/topup-emi-pages "
              className="lowerEmi shadow-md"
              onClick={() => {
                localStorage.setItem("id", 3);
                window.scrollTo(0, 0);
              }}
            >
              <div className="InnerSection">
                <div className="InnerSectionText">
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Get a New Loan With Lower EMI
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                    Use our algorithm to find better rates for a top-up or a new
                    loan.
                  </div>
                </div>
                <a
                  href="/topup-emi-pages"
                  onClick={() => {
                    localStorage.setItem("id", 3);
                    window.scrollTo(0, 0);
                  }}
                  style={{ backgroundColor: "transparent" }}
                >
                  <IoMdArrowForward
                    size={30}
                    color={"#59266D"}
                    style={{ backgroundColor: "rgba(85, 50, 133, 0)" }}
                  />
                </a>
              </div>
              <div className="TopUPIllustration">
                <img
                  src={
                    "https://minemiwebsite.s3.ap-south-1.amazonaws.com/OurServiceTopUp.webp"
                  }
                  loading="lazy"
                  alt="Get New Loan with lower EMI"
                />
              </div>
            </a>
          </div>
          
          <div className="Section">
            <a
              href="/trackloanstatus"
              className="lowerEmi shadow-md"
              onClick={() => {
                localStorage.setItem("id", 3);
                window.scrollTo(0, 0);
              }}
            >
              <div className="InnerSection">
                <div className="InnerSectionText">
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                  Check Loan Status & Optimize EMI
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                
                Assess your loan application status and discover EMI-saving opportunities     
                  </div>
                </div>
                <a
                  href="/trackloanstatus"
                  onClick={() => {
                    localStorage.setItem("id", 3);
                    window.scrollTo(0, 0);
                  }}
                  style={{ backgroundColor: "transparent" }}
                >
                  <IoMdArrowForward
                    size={30}
                    color={"#59266D"}
                    style={{ backgroundColor: "rgba(85, 50, 133, 0)" }}
                  />
                </a>
              </div>
              <div className="TopUPIllustration">
                <img style={{  mixBlendMode: "multiply",}}
                  src={
                   creditPro
                  }
                  loading="lazy"
                  alt="Get New Loan with lower EMI"
                />
              </div>
            </a>
          </div>
          <div className="Section">
            <a
              href="https://minemi.ai/personal-loan/check-eligibility/"
              className="lowerEmi shadow-md"
              onClick={() => {
                localStorage.setItem("id", 3);
                window.scrollTo(0, 0);
              }}
            >
              <div className="InnerSection">
                <div className="InnerSectionText">
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
             Check Your Personal Loan Eligibility
                  </div>
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "rgba(138, 138, 138, 1)",
                      backgroundColor: "rgba(85, 50, 133, 0)",
                    }}
                  >
                
                Find out your loan options without affecting your credit score.
                  </div>
                </div>
                <a
                  href="https://minemi.ai/personal-loan/check-eligibility/"
                  onClick={() => {
                    localStorage.setItem("id", 3);
                    window.scrollTo(0, 0);
                  }}
                  style={{ backgroundColor: "transparent" }}
                >
                  <IoMdArrowForward
                    size={30}
                    color={"#59266D"}
                    style={{ backgroundColor: "rgba(85, 50, 133, 0)" }}
                  />
                </a>
              </div>
              <div className="TopUPIllustration">
                <img
                    src={
                      "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/priorities-1737367033262.webp"
                    }
                    alt="Discover Your Loan Eligibility"
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "transparent",
                      mixBlendMode: "multiply",
                    }}
                  />
                </div>
            </a>
          </div>
         

    
        </div>

        {/* Hero Section for Mobile */}

        <div className="HeroContainerMobile">
          <div
            style={{ backgroundColor: "transparent" }}
            className="d-flex flex-column align-items-center gap-4"
          >
            <a
              href="/lower-emi-page"
              onClick={() => {
                localStorage.setItem("id", 1);
                window.scrollTo(0, 0);
              }}
              className="lowerEmiMobile shadow-sm"
            >
              <div className="mobileimg"
                style={{
                  width: "117px",
                  height: "94px",
                  marginTop: "22px",
                  marginBottom: "28px",
                  marginLeft: "2px",
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/LowerEmiIllustration-1738064439347.webp"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                  loading="lazy"
                  alt="Lower Emi"
                />
              </div>
              <div
                style={{
                  marginTop: "32px",
                  marginRight: "8px",
                  marginBottom: "24px",
                  gap: "5px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "transparent",
                  width: "203px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    alignItems: "center",
                    backgroundColor: "transparent",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "transparent",
                    }}
                  >
                    Lower Your EMIs the Smart Way
                  </div>
                  <a
                    href="/lower-emi-page"
                    onClick={() => {
                      localStorage.setItem("id", 1);
                      window.scrollTo(0, 0);
                    }}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <FaArrowRight
                      size={16}
                      color={"rgba(89, 38, 109, 1)"}
                      style={{ backgroundColor: "transparent" }}
                    />
                  </a>
                </div>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "rgba(138, 138, 138, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                  Use our algorithm to reduce your loan interest rate based on
                  your credit score.{" "}
                </div>
              </div>
            </a>
            <a
              href="/consolidate-emi-pages"
              className="lowerEmiMobile shadow-sm"
              onClick={() => {
                localStorage.setItem("id", 2);
                window.scrollTo(0, 0);
              }}
            >
              <div className="mobileimg"
                style={{
                  width: "117px",
                  height: "94px",
                  marginTop: "22px",
                  marginBottom: "28px",
                  marginLeft: "2px",
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/ConsolidateIllustration-1738064584598.webp"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                  loading="lazy"
                  alt="Cosolidation"
                />
              </div>
              <div
                style={{
                  marginTop: "32px",
                  marginRight: "8px",
                  marginBottom: "24px",
                  gap: "5px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "transparent",
                  width: "203px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    alignItems: "center",
                    backgroundColor: "transparent",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "transparent",
                    }}
                  >
                    Consolidate Multiple Loans into One EMI
                  </div>
                  <a
                    href="/consolidate-emi-pages"
                    onClick={() => {
                      localStorage.setItem("id", 2);
                      window.scrollTo(0, 0);
                    }}
                    style={{ backgroundColor: "transparent" }}
                  >
                    {" "}
                    <FaArrowRight
                      size={16}
                      color={"rgba(89, 38, 109, 1)"}
                      style={{ backgroundColor: "transparent" }}
                    />
                  </a>
                </div>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "rgba(138, 138, 138, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                  Combine your loans into one easy-to-manage EMI with lower
                  interest rates.
                </div>
              </div>
            </a>
            
         
          </div>

          <div
            style={{ backgroundColor: "transparent" }}
            className="d-flex flex-column align-items-center gap-4"
          >
            <a
              className="lowerEmiMobile shadow-sm"
              href="/topup-emi-pages"
              onClick={() => {
                localStorage.setItem("id", 3);
                window.scrollTo(0, 0);
              }}
            >
              <div className="mobileimg"
                style={{
                  width: "117px",
                  height: "94px",
                  marginTop: "22px",
                  marginBottom: "28px",
                  marginLeft: "2px",
                  backgroundColor: "rgba(85, 50, 133, 0)",
                }}
              >
                <img
                  src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/TopUPIllustration-1738064156174.webp"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(85, 50, 133, 0)",
                  }}
                  loading="lazy"
                  alt="Top Up"
                />
              </div>
              <div
                style={{
                  marginTop: "32px",
                  marginRight: "8px",
                  marginBottom: "24px",
                  gap: "5px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "transparent",
                  width: "203px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    alignItems: "center",
                    backgroundColor: "transparent",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "transparent",
                    }}
                  >
                    Get a New Loan with Lower EMI
                  </div>
                  <a
                    href="/topup-emi-pages"
                    onClick={() => {
                      localStorage.setItem("id", 3);
                      window.scrollTo(0, 0);
                    }}
                    style={{ backgroundColor: "transparent" }}
                  >
                    {" "}
                    <FaArrowRight
                      size={16}
                      color={"rgba(89, 38, 109, 1)"}
                      style={{ backgroundColor: "transparent" }}
                    />
                  </a>
                </div>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "rgba(138, 138, 138, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                  Use our algorithm to find better rates for a loan top-up or
                  new financing
                </div>
              </div>
            </a>
            <a
              className="lowerEmiMobile shadow-sm"
              href="/trackloanstatus"
              onClick={() => {
                localStorage.setItem("id", 3);
                window.scrollTo(0, 0);
              }}
            >
              <div className="mobileimg"
                style={{
                  width: "117px",
                  height: "94px",
                  marginTop: "22px",
                  marginBottom: "28px",
                  marginLeft: "2px",
                  backgroundColor: "rgba(85, 50, 133, 0)",
                }}
              >
                <img
                  src={creditPro}
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(85, 50, 133, 0)",
                    mixBlendMode: "multiply",

                  }}
                  loading="lazy"
                  alt="Top Up"
                />
              </div>
              <div
                style={{
                  marginTop: "32px",
                  marginRight: "8px",
                  marginBottom: "24px",
                  gap: "5px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "transparent",
                  width: "203px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    alignItems: "center",
                    backgroundColor: "transparent",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "transparent",
                    }}
                  >
Check Loan Status & Optimize EMI                </div>
                  <a
                    href="/trackloanstatus"
                    onClick={() => {
                      localStorage.setItem("id", 3);
                      window.scrollTo(0, 0);
                    }}
                    style={{ backgroundColor: "transparent" }}
                  >
                    {" "}
                    <FaArrowRight
                      size={16}
                      color={"rgba(89, 38, 109, 1)"}
                      style={{ backgroundColor: "transparent" }}
                    />
                  </a>
                </div>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "rgba(138, 138, 138, 1)",
                    backgroundColor: "transparent",
                  }}
                >
        Assess your loan application status and discover EMI-saving opportunities
                </div>
              </div>
            </a>

          </div>
       
          <div
            style={{ backgroundColor: "transparent" }}
            className="d-flex flex-column align-items-center  "
          >
                <a
              className="lowerEmiMobile shadow-sm"
              href="https://minemi.ai/personal-loan/check-eligibility/"
              // target="_blank"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <div className="mobileimg"
                style={{
                  width: "117px",
                  height: "94px",
                  marginTop: "22px",
                  marginBottom: "28px",
                  marginLeft: "2px",
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/priorities-one-1738063592331.webp"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    mixBlendMode: "multiply",
                  }}
                  loading="lazy"
                  alt="Eligibility"
                />
              </div>
              <div
                style={{
                  marginTop: "32px",
                  marginRight: "8px",
                  marginBottom: "24px",
                  gap: "5px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "transparent",
                  width: "203px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "10px",
                    alignItems: "center",
                    backgroundColor: "transparent",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "rgba(89, 38, 109, 1)",
                      backgroundColor: "transparent",
                    }}
                  >
              Check Your Personal Loan Eligibility
                  </div>
                  <Link to="https://minemi.ai/personal-loan/check-eligibility/" style={{ backgroundColor: "transparent" }}>
                    <FaArrowRight
                      size={16}
                      color={"rgba(89, 38, 109, 1)"}
                      style={{ backgroundColor: "transparent" }}
                    />
                  </Link>
                </div>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "rgba(138, 138, 138, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                Find out your loan options without affecting your credit score.
                </div>
              </div>
            </a>
            <div    className="lowerEmiMobile"></div>
            </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;

import React from "react";
import "./Serve.css";
import Accordion from "react-bootstrap/Accordion";
import { FaPlus } from "react-icons/fa";

const Serve = () => {
  const accordionData = [
    {
      title: "A Quick Guide to Top-Up Loans",
      content: (
        <p className="m-0 bg-transparent">
          Your top-up loan eligibility depends on factors such as your repayment
          history, credit score, existing loan tenure, and current financial
          obligations. Typically, a credit score of 700+ and a strong repayment
          track record increase your chances of approval. At Minemi, we offer a
          quick and hassle-free way to check your top-up loan eligibility
          instantly, without affecting your credit score. Know your eligibility
          upfront and explore better loan options with lower EMIs, helping you
          save more and manage your finances efficiently!
        </p>
      ),
    },
    {
      title: "Features and Benefits of Top-Up Loans",
      content: (
        <div className="bg-transparent">
          A top-up loan allows you to borrow additional funds on your existing
          loan at a lower interest rate than personal loans. Key benefits
          include:
          <p className="my-2 bg-transparent">Key features include:</p>
          <p className="my-1 bg-transparent">
            • Lower Interest Rates compared to unsecured loans
          </p>
          <p className="m-0 bg-transparent">
            • Increased Loan Amount without the need for a new loan application
          </p>
          <p className="my-1 bg-transparent">
            • Flexible Repayment Tenure to suit your financial needs
          </p>
          <p className="m-0 bg-transparent">
            • Quick & Hassle-Free Processing with minimal documentation
          </p>
        </div>
      ),
    },
    {
      title: "Why Check Top-Up Loan Eligibility? ",
      content: (
        <p className="m-0 bg-transparent">
          Checking your top-up loan eligibility before applying helps you
          understand your chances of approval and avoids multiple hard credit
          inquiries that could impact your credit score. With Minemi, you can
          check your eligibility instantly without affecting your score. This
          allows you to compare lower interest rates from various lenders,
          saving you time and reducing the risk of rejection. Ensure you choose
          the best financial institution for maximum savings and a stress-free
          borrowing experience!
        </p>
      ),
    },
    {
      title: "Is a Top-Up Loan Right for You?",
      content: (
        <p className="m-0 bg-transparent">
          If you need extra funds at a lower interest rate for home renovation,
          education, or medical expenses, a top-up loan is a smart choice. With
          quick approval, flexible tenure, and minimal paperwork, it’s a
          hassle-free way to manage your finances. Check your eligibility with
          Minemi to find the best options and save more!
        </p>
      ),
    },
    {
      title: "Documents Required for a Top-Up Loan",
      content: (
        <div className="bg-transparent">
          When applying for a top-up loan, you will need to submit the following
          documents:
          <p className="my-1 bg-transparent">
            • Identity Proof: PAN Card, Aadhaar Card, Passport
          </p>
          <p className="m-0 bg-transparent">
            • Address Proof: Utility bills, Aadhaar, or rental agreements
          </p>
          <p className="my-1 bg-transparent">
            • Income Proof: Latest salary slips or bank statements (for
            salaried), IT returns or audited financials (for self-employed)
          </p>
          <p className="my-1 bg-transparent">
            • Existing Loan Documents: Sanction letter, latest EMI payment
            receipts, loan statement
          </p>
          <p className="m-0 bg-transparent">
            • Property Documents: Agreement to Sale, Title Deed, Approved
            Building Plan
          </p>
        </div>
      ),
    },
    {
      title: " Frequently Asked Questions (FAQs) ",
      content: (
        <div className="m-0 bg-transparent">
          <p className="m-0 bg-transparent">
            1.Need extra funds for financial goals? Yes, a top-up loan is a
            great way to access additional funds at a lower interest rate
            without applying for a new loan. It can help with home renovation,
            education, medical expenses, or debt consolidation while keeping
            EMIs manageable.
          </p>
          <p className="my-2 bg-transparent">
            2.Will a top-up loan always lower my EMI? Not always. While
            extending the tenure can reduce your EMI, borrowing a higher amount
            may increase your monthly payments. It’s important to calculate the
            total cost before proceeding.
          </p>
          <p className="m-0 bg-transparent">
            3.Can I get a top-up loan from the same lender? Yes, most banks and
            NBFCs offer top-up loans to existing borrowers with a good repayment
            history. However, eligibility depends on factors like your credit
            score, outstanding loan amount, and financial stability.
          </p>
          <p className="my-2 bg-transparent">
            4.How does a top-up loan affect my credit score? Taking a top-up
            loan increases your total debt, which may impact your credit
            utilization ratio. However, timely repayments can improve your
            credit score over time.
          </p>
          <p className="m-0 bg-transparent">
            5.What happens if I miss a payment on my top-up loan? Missing a
            payment can result in late fees, a higher interest burden, and a
            negative impact on your credit score. To avoid penalties, ensure you
            pay your EMIs on time.
          </p>
        </div>
      ),
    },
  ];
  return (
    <>
      <div id="serve">
        <div className="contentCon">
          <div id="header-con">
            <p className="bg-transparent">We swear to serve you with ...</p>
          </div>
          <div id="content" className="bg-transparent">
            <div className="d-flex flex-wrap w-100 bg-transparent">
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/loan_eligibility.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/no_empact.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Impact on credit Score</p>
              </div>

              <div className="plus-icon-con second-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/interest_rate.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Best Interest Rate Offer</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/no_calls.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Calls From Us</p>
              </div>

              <div className="plus-icon-con fourth-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Data Security Guarantee</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>
            </div>

            <div id="line-breaker"></div>

            <div className="accordion-con bg-transparent">
              <Accordion className="bg-transparent">
                {accordionData.map((item, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body
                      style={{
                        visibility: "visible",
                        backgroundColor: "transparent",
                      }}
                    >
                      {item.content}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Serve;

import React, { useEffect, useContext } from "react";
// import image from "../../Assets/Images/sbi.webp";
import { LuDot } from "react-icons/lu";
// import { FaAngleDown, FaChevronUp } from "react-icons/fa6";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from "react-helmet";
import MetadataContext from "../MetadataContext";

const SbiEligiblity = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const infoListStyle = {
    color: "#1F1F1F",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "20px",
    paddingLeft: "13px",
    listStyleType: "circle",
    marginBottom: "0px",
  };

  const criteriaData = [
    {
      factor: "Income",
      requirement: "₹25,000/month (minimum for salaried individuals)",
      impact:
        "Higher income increases loan eligibility and the amount you can borrow.",
    },
    {
      factor: "Credit Score",
      requirement: "700+",
      impact:
        "A higher score qualifies you for better loan terms and lower interest rates.",
    },
    {
      factor: "Employment Status",
      requirement: "Salaried or self-employed (minimum 2 years)",
      impact:
        "Stable employment improves chances; self-employed individuals may need more documentation.",
    },
    {
      factor: "Fixed Obligations to Income Ratio (FOIR)",
      requirement: "40%-50%",
      impact:
        "A lower FOIR indicates fewer existing EMIs and improves loan eligibility.",
    },
    {
      factor: "Age",
      requirement: "21 to 60 years",
      impact:
        "Younger applicants have a longer tenure but must show steady income.",
    },
  ];

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Check Your SBI Bank Personal Loan Eligibility Instantly",
      pageDescription:
        "Check your SBI Bank personal loan eligibility instantly. Enjoy quick approvals, attractive interest rates, and easy online applications. Apply now!",
      pageKeywords:
        "SBI Bank Personal Loan, Personal Loan Eligibility, Instant Loan Eligibility Check, SBI Loan Eligibility Calculator, Personal Loan Online Application, Quick Personal Loan Approval, SBI Loan Offers, Personal Loan EMI Calculator, Online Loan Eligibility Check, SBI Instant Loan Services, Best Personal Loan Rates, Loan Eligibility Criteria, Apply for SBI Personal Loan, SBI Bank Quick Loan Approval",
      canonicalUrl:
        "https://minemi.ai/loanpage/personalloan/loan-eligibilty-criteria/sbi-loan-eligiblity",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              <Link
                to={"/loanpage/personalloan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Personal Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/personalloan/loan-eligibilty-criteria"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Personal Loan Check Eligibility
              </Link>{" "}
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Check Eligibility with SBI Bank
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "55px",
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            SBI Bank Personal Loan Eligibility
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Check your eligibility for an SBI Bank personal loan. Learn about
            the credit score, income, age, and other criteria to qualify for the
            best loan offers from SBI.
          </p>
        </div>
        <div
          className=""
          style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}
        >
          <h2
            style={{
              marginTop: 35,
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 24,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Personal Loan Eligibility Criteria with SBI Bank
          </h2>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Explore the essential eligibility requirements for an SBI personal
            loan. From age and income thresholds to employment type and credit
            score benchmarks, learn what it takes to qualify for the best loan
            offers tailored to your financial needs.
          </p>
          <div
            style={{
              border: "1px solid #D9D9D9",
              borderRadius: 24,
              marginTop: 24,
              backgroundColor: "white",
              overflow: "hidden",
              maxWidth: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
              }}
            >
              <div
                style={{
                  backgroundColor: "#E7E4F9",
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 16,
                  lineHeight: "20px",
                  color: "#59266D",
                  padding: "16px",
                  borderBottom: "1px solid #D9D9D9",
                  borderTopLeftRadius: 24,
                  borderTopRightRadius: 24,
                  textAlign: "center",
                }}
              >
                SBI Loan Eligibility Criteria
              </div>
            </div>

            <div
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                width: "100%",
                overflow: "auto",
              }}
            >
              <table
                className="table-class"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 12,
                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  textAlign: "left",
                }}
              >
                <thead className="thead-class">
                  <tr>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Eligibility Factor
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Minimum Requirement
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Impact on Loan Eligibility
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {criteriaData.map((row, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      }}
                    >
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        {row.factor}
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {row.requirement}
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {row.impact}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Eligibility Criteria for SBI Bank Personal Loan:{" "}
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Here are the eligibility criteria for an SBI Personal Loan:
          </div>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Employment Type:&nbsp;&nbsp;
                </span>
                Salaried individuals and pensioners are eligible. Applicants
                should work in Central/State government, reputed private sector,
                or public sector undertakings.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Income Requirements:&nbsp;&nbsp;
                </span>
                <ul style={infoListStyle}>
                  <li className="">
                    For salaried employees: Minimum monthly income of ₹15,000.
                  </li>
                  <li className="mb-0">
                    For pensioners: Regular receipt of pension from an SBI
                    account.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Age Limit:&nbsp;&nbsp;
                </span>
                <ul style={infoListStyle}>
                  <li className="">
                    Salaried employees: Minimum age 21 years; maximum age 58-60
                    years (depending on the employer's retirement age).
                  </li>
                  <li className="mb-0">Pensioners: Up to 76 years.</li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Loan Amount:&nbsp;&nbsp;
                </span>
                The eligible loan amount is typically calculated based on income
                and repayment capacity, with a cap depending on net monthly
                income and employer type.{" "}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Other Conditions:&nbsp;&nbsp;
                </span>
                <ul style={infoListStyle}>
                  <li className="">
                    A good credit score (usually above 650) is required
                  </li>
                  <li className="mb-0">
                    The applicant must maintain a specific EMI-to-income ratio
                    (e.g., 50-65% depending on other obligations).
                  </li>
                </ul>
              </div>
            </div>
            <p style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              For more detailed terms, you can refer to the official{" "}
              <Link
                href={
                  "https://sbi.co.in/web/personal-banking/loans/personal-loans"
                }
                target="_blank"
                underline="hover"
              >
                SBI Personal Loan page
              </Link>
            </p>
          </div>
          <h3
            style={{
              marginTop: 30,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Documents Required for SBI Personal Loan{" "}
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            To apply for a personal loan with SBI, applicants need to submit the
            following documents:
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Identity Proof (Any One):{" "}
                </span>
                <ul style={infoListStyle}>
                  <li className="">Aadhaar Card</li>
                  <li className="">PAN Card</li>
                  <li className="">Passport </li>
                  <li className="">Driving License </li>
                  <li className="mb-0">Voter ID</li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Address Proof (Any One):
                </span>
                <ul style={infoListStyle}>
                  <li className="">
                    Utility Bill (Electricity, Water, or Telephone){" "}
                  </li>
                  <li className="">PAN Card</li>
                  <li className="">Aadhaar Card </li>
                  <li className="">Passport</li>
                  <li className="mb-0">Rental Agreement</li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                   Income Proof:
                </div>
                <div>
                  <ul style={infoListStyle}>
                    <li className="">
                      Utility Bill (Electricity, Water, or Telephone){" "}
                    </li>
                    <li className="">PAN Card</li>
                    <li className="">Aadhaar Card </li>
                    <li className="">Passport</li>
                    <li className="mb-0">Rental Agreement</li>
                  </ul>
                </div>
                <div style={{ paddingLeft: "1%" }} className="mt-2">
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 15,
                      marginTop: 5,
                    }}
                  >
                    • For salaried individuals:
                  </span>{" "}
                  Last 3 months' salary slips and 6 months' bank statements
                  showing salary credits.
                </div>
                <div style={{ paddingLeft: "1%" }} className="mt-2">
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 15,
                      marginTop: 5,
                    }}
                  >
                    • For pensioners:
                  </span>{" "}
                  Pension payment proof or pension account statement.
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Photographs:{" "}
                </span>
                Recent passport-sized photographs.{" "}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Loan Application Form:{" "}
                </span>
                Duly filled and signed loan application form.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Additional Documents:{" "}
                </span>
                <ul style={infoListStyle}>
                  <li className="">
                    Employer’s certification or Form 16, if required.{" "}
                  </li>

                  <li className="mb-0">
                    Any existing loan sanction letters or repayment records.
                  </li>
                </ul>
              </div>
            </div>
            <p style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              These requirements may vary slightly based on the applicant's
              employment status and loan amount. For exact details, visit the
              official{" "}
              <Link
                href={
                  "https://sbi.co.in/web/personal-banking/loans/personal-loans"
                }
                target="_blank"
                underline="hover"
              >
                SBI Personal Loan page
              </Link>
            </p>
          </div>

          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            How to Improve Your Eligibility for an SBI Personal Loan{" "}
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            To increase your chances of qualifying for a personal loan from SBI,
            consider the following strategies:
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Maintain a Good Credit Score:{" "}
                </span>
                Aim for a CIBIL score of 700 or above. Regularly pay your credit
                card bills and EMIs on time to boost your score.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Enhance Your Income Profile:{" "}
                </span>
                Showcase additional sources of income, such as rental income or
                freelance earnings, to improve your repayment capacity.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Reduce Existing Debt:{" "}
                </span>
                Clear smaller loans and reduce your debt-to-income ratio to
                demonstrate better financial health.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Opt for a Reasonable Loan Amount:{" "}
                </span>
                Choose a loan amount that aligns with your income and repayment
                ability. Overborrowing can reduce your chances of approval.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Submit Complete Documentation:{" "}
                </span>
                Ensure that all required documents (e.g., salary slips, bank
                statements, and identity proof) are accurate and up to date.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Maintain a Strong Banking Relationship with SBI:{" "}
                </span>
                If you already have an account with SBI or use their financial
                products, this can improve your credibility as a borrower.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Add a Co-Applicant (If Required):{" "}
                </span>
                A co-applicant with a stable income or good credit score can
                enhance your eligibility and allow you to apply for a higher
                loan amount.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Minimize Financial Obligations:{" "}
                </span>
                Avoid applying for multiple loans simultaneously, as it may
                raise concerns about your financial stability.
              </div>
            </div>
            <p style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              For more tailored advice, consider visiting SBI’s official website
              or consulting their representatives for specific eligibility
              requirements
            </p>
          </div>

          <p
            style={{ fontSize: 16, lineHeight: "20px", color: "#1f1f1f" }}
            className="mt-4"
          >
            For more information please check{" "}
            <Link
              href={
                "https://sbi.co.in/web/personal-banking/loans/personal-loans"
              }
              target="_blank"
            >
              SBI Eligibility
            </Link>
          </p>

          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            FAQs on SBI Bank Personal Loan Eligibility{" "}
          </h3>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  fontWeight: 600,
                  borderRadius: 12,
                }}
              >
                What is the minimum salary required to apply for an SBI personal
                loan?
              </AccordionSummary>
              <AccordionDetails>
                For salaried individuals, the minimum monthly salary should be
                ₹15,000 to ₹50,000, depending on the applicant’s employer and
                job type. The amount can vary for government employees or
                employees of specific organization
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{ fontWeight: 600, borderRadius: 12 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Can self-employed individuals apply for an SBI personal loan?
              </AccordionSummary>
              <AccordionDetails>
                Yes, self-employed individuals are eligible for SBI personal
                loans, but they must meet specific criteria, including a stable
                income and a good credit score. The required documents include
                financial statements for the last two years and income tax
                returns.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{ fontWeight: 600, borderRadius: 12 }}
              >
                What is the maximum loan amount available through SBI personal
                loans?
              </AccordionSummary>
              <AccordionDetails>
                The maximum loan amount can be up to ₹25 lakh, depending on your
                income and repayment capacity. This limit may vary based on the
                applicant’s profile and loan type
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{ fontWeight: 600, borderRadius: 12 }}
              >
                What is the preferred age limit for an SBI personal loan?
              </AccordionSummary>
              <AccordionDetails>
                Applicants should be between 21 and 58 years of age at the time
                of application. The loan must be repaid before the applicant
                turns 60 years, with certain exceptions for pensioners
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br /> and tailored just for you—apply now to see what you
                qualify for!
              </p>
              <a
                href="https://minemi.ai/personal-loan/check-eligibility"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                  backgroundColor: "transparent",
                  marginTop: "2%",
                  cursor: "pointer",
                }}
              >
                <div
                  className="buttom-loan-eligiblity-one"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 40,
                    paddingRight: 40,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                   Check Your Eligibility Now
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SbiEligiblity;


import  React from "react"
import { useState } from "react"
import { Star } from "lucide-react"
import { Card, CardContent } from "@mui/material"



export default function TestimonialSection() {
  const testimonials= [
    {
      name: "Vikram Malhotra",
      city: "Delhi",
      quote:
        "I was rejected twice for premium credit cards before using Minemi. Their tool showed me exactly why I was getting rejected and what to fix. Now I have the travel card I wanted!",
      cardType: "Travel Credit Card",
      impact: "Approved after previous rejections",
      rating: 5,
      imgSrc: "/placeholder.svg?height=80&width=80",
    },
    {
      name: "Priya Sharma",
      city: "Pune",
      quote:
        "Minemi helped me understand which cards I actually qualified for instead of randomly applying. Saved me from multiple rejections and protected my credit score!",
      cardType: "Cashback Card",
      impact: "Protected credit score",
      rating: 5,
      imgSrc: "/placeholder.svg?height=80&width=80",
    },
    {
      name: "Aryan Patel",
      city: "Ahmedabad",
      quote:
        "As a recent graduate, I had no idea which credit cards I could get. Minemi showed me starter cards with high approval odds, and I got approved on my first try!",
      cardType: "Student Credit Card",
      impact: "First-time approval",
      rating: 4,
      imgSrc: "/placeholder.svg?height=80&width=80",
    },
    {
      name: "Neha Kapoor",
      city: "Bangalore",
      quote:
        "I wanted to upgrade to a premium card but wasn't sure if I'd qualify. Minemi's prediction was spot-on, and the recommendations helped me choose the perfect card for my lifestyle.",
      cardType: "Premium Credit Card",
      impact: "Successful upgrade",
      rating: 5,
      imgSrc: "/placeholder.svg?height=80&width=80",
    },
    {
      name: "Rahul Desai",
      city: "Mumbai",
      quote:
        "After a financial setback, I wasn't sure which cards would accept me. Minemi's tool was honest about my chances and suggested secured cards to rebuild my credit. Invaluable advice!",
      cardType: "Secured Credit Card",
      impact: "Credit rebuilding success",
      rating: 5,
      imgSrc: "/placeholder.svg?height=80&width=80",
    },
  ]

  const [activeIndex, setActiveIndex] = useState(0)
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)

  const handlePrev = () => {
    setActiveIndex((prev) => (prev === 0 ? testimonials.length - 1 : prev - 1))
  }

  const handleNext = () => {
    setActiveIndex((prev) => (prev === testimonials.length - 1 ? 0 : prev + 1))
  }

  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX)
  }

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 50) {
      // Swipe left
      handleNext()
    }

    if (touchStart - touchEnd < -50) {
      // Swipe right
      handlePrev()
    }
  }

  const renderStars = (rating) => {
    return Array(5)
      .fill(0)
      .map((_, i) => (
        <Star
          key={i}
          className={`h-3 w-3 md:h-4 md:w-4 ${i < rating ? "text-yellow-400 fill-yellow-400" : "text-gray-300"}`}
        />
      ))
  }

  return (
    <section className="w-full py-5 md:py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-4 md:mb-12">
          <h2 className="text-xl md:text-4xl font-bold text-[#311664] mb-1 md:mb-4">What Our Users Say About Minemi</h2>
          <p className="text-xs md:text-lg text-gray-600 max-w-2xl mx-auto">
            Real stories from real people who checked their credit card approval chances with Minemi before applying.
          </p>
        </div>

        {/* Mobile Carousel */}
        <div
          className="md:hidden"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <Card className="border-none shadow-lg">
            <CardContent className="p-3 md:p-6">
              <div className="flex flex-col items-center text-center">
                <div className="mb-2 md:mb-4 relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-[#311664] to-[#59266d] rounded-full opacity-10"></div>
                  <img
                    src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3TpYrSjIlUliSFfOxWQOBYAD9xcFg-GDCyg&s'
                    alt={testimonials[activeIndex].name}
                    width={60}
                    height={60}
                    className="rounded-full border-2 border-[#311664] w-12 h-12 md:w-20 md:h-20"
                  />
                </div>
                <div className="flex mb-1 md:mb-3">{renderStars(testimonials[activeIndex].rating)}</div>
                <p className="text-gray-700 italic mb-2 md:mb-4 text-xs md:text-base">
                  "{testimonials[activeIndex].quote}"
                </p>
                <h3 className="font-bold text-[#311664] text-sm md:text-base">
                  {testimonials[activeIndex].name}, {testimonials[activeIndex].city}
                </h3>
                <div className="mt-1 md:mt-2 flex flex-col items-center">
                  <span className="bg-[#ebe8fc] text-[#311664] text-[10px] md:text-xs font-medium px-2 py-0.5 rounded-full mb-0.5 md:mb-1">
                    {testimonials[activeIndex].cardType}
                  </span>
                  <span className="bg-[#3a868b]/10 text-[#3a868b] text-[10px] md:text-xs font-medium px-2 py-0.5 rounded-full">
                    {testimonials[activeIndex].impact}
                  </span>
                </div>
              </div>
            </CardContent>
          </Card>

          <div className="flex justify-center mt-4" style={{gap:3}}>
            {testimonials.map((_, index) => (
              <button
                key={index}
                className={`h-2 rounded-full transition-all ${
                  activeIndex === index ? "w-6 bg-[#311664]" : "w-2 bg-[#311664]/30"
                }`}
                onClick={() => setActiveIndex(index)}
                aria-label={`Go to testimonial ${index + 1}`}
              />
            ))}
          </div>
        </div>

        {/* Desktop Grid */}
        <div className="hidden md:block">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {testimonials.map((testimonial, index) => (
              <Card key={index} className="border-none shadow-lg hover:shadow-xl transition-shadow">
                <CardContent className="p-3 md:p-6">
                  <div className="flex items-start mb-2 md:mb-4">
                    <div className="relative mr-2 md:mr-4">
                      <div className="absolute inset-0 bg-gradient-to-r from-[#311664] to-[#59266d] rounded-full opacity-10"></div>
                      <img
                        src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3TpYrSjIlUliSFfOxWQOBYAD9xcFg-GDCyg&s'
                        alt={testimonial.name}
                        width={40}
                        height={40}
                        className="rounded-full border-2 border-[#311664] w-8 h-8 md:w-14 md:h-14"
                        style={{objectFit:'cover'}}
                      />
                    </div>
                    <div>
                      <h3 className="font-bold text-[#311664] text-xs md:text-base">
                        {testimonial.name}, <span className="font-normal">{testimonial.city}</span>
                      </h3>
                      <div className="flex mt-0.5 md:mt-1">{renderStars(testimonial.rating)}</div>
                    </div>
                  </div>
                  <p className="text-gray-700 italic mb-2 md:mb-4 text-xs md:text-base">"{testimonial.quote}"</p>
                  <div className="flex flex-wrap gap-1 md:gap-2">
                    <span className="bg-[#ebe8fc] text-[#311664] text-[10px] md:text-xs font-medium px-2 py-0.5 rounded-full">
                      {testimonial.cardType}
                    </span>
                    <span className="bg-[#3a868b]/10 text-[#3a868b] text-[10px] md:text-xs font-medium px-2 py-0.5 rounded-full">
                      {testimonial.impact}
                    </span>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}


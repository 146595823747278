import React, { useEffect, useState, useContext } from "react";
import toungeOut from "../../../Assets/Images/tongue-out.webp";
import { LuDot } from "react-icons/lu";
import "../EligiblityCriteria.css";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MetadataContext from "../../MetadataContext";

const HDFCInterestRate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "HDFC Bank Personal Loan Eligibility",
      pageDescription:
        "Discover your personal loan eligibility with HDFC Bank. Check instantly online, enjoy quick approvals, flexible repayment options, and attractive interest rates.",
      pageKeywords:
        "HDFC Bank Personal Loan Eligibility, Personal Loan Eligibility Check, Instant Loan Eligibility, HDFC Loan Eligibility Calculator, Personal Loan Online, Quick Loan Approval, HDFC Bank Loan Offers, Personal Loan EMI Calculator, Online Loan Application, Fast Personal Loan Approval, Flexible Loan Options, Loan Eligibility Criteria, Best Personal Loan Rates, HDFC Personal Loan Details, Online Loan Eligibility Check",
      canonicalUrl:
        "https://minemi.ai/loanpage/personalloan/badcredit/hdfc-bank-personal-loan-eligibility",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          className="margin-less"
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              <Link
                to={"/loanpage/personalloan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Personal Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/personalloan/badcredit"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Personal Loan Interest Rates
              </Link>{" "}
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                HDFC Bank Personal Loan Eligibility
              </Link>{" "}
            </p>
          </div>
          <h1
            className="loanstatus-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "55px",

              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            HDFC Bank Personal Loan Eligibility
          </h1>
          <p
            className="loanstatus-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 500,
              fontSize: 18,
              lineHeight: "24px",

              color: "#74589B",
              backgroundColor: "#E7E4F9",
            }}
          >
            HDFC Bank offers personal loans that cater to various financial
            needs like medical expenses, education, travel, and home
            renovations. To ensure you meet the requirements for a personal
            loan, here’s a detailed overview of HDFC Bank's eligibility
            criteria, loan features, and how to improve your chances of
            approval.
          </p>
        </div>
        <div
          className="tracking-status"
          style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}
        >
          <h2
            style={{
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 24,
              marginTop: 30,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Eligibility Criteria for HDFC Bank Personal Loan
          </h2>

          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                      width: "30%",
                    }}
                  >
                    Criteria
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Age
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Minimum: 21 years
                    <br />- Maximum: 60 years (at loan maturity)
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Income
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Salaried: ₹25,000 per month (depends on city) <br />-
                    Self-employed: ₹2.5 lakh annually
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Employment Status
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Salaried: Must be employed with a reputed organization{" "}
                    <br />- Self-employed: Minimum 2 years in business
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Credit
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Score Minimum CIBIL score of 750 (higher score improves
                    chances)
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                    borderBottomRightRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      borderBottomLeftRadius: 18,
                    }}
                  >
                    Work Experience
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Salaried: 2 years of work experience, with at least 1 year
                    in the current job
                    <br />- Self-employed: 2 years of business continuity
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                    borderBottomRightRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      borderBottomLeftRadius: 18,
                    }}
                  >
                    Existing Loans
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Manageable debt-to-income ratio with a clean repayment
                    history
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className="make-it-center increase-margin "
            style={{
              marginTop: "4%",
              marginBottom: "2%",
              padding: 24,
              background: "linear-gradient(91.7deg, #EADCFF 0.4%, #FFFFFF 99%)",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 20,
              borderRadius: 24,
            }}
          >
            <div
              style={{
                marginLeft: "4%",
                backgroundColor: "transparent",
                display: "flex",
                flexDirection: "column",
                gap: 24,
                borderRadius: 24,
                alignSelf: "center",
              }}
            >
              <p
                className="eligiblity-criteria"
                style={{
                  fontFamily: "Archivo",
                  color: "#59266D",
                  fontSize: 40,
                  backgroundColor: "transparent",
                }}
              >
                <i style={{ backgroundColor: "transparent", lineHeight: 1.2 }}>
                  Check Your Loan Eligibility Without{" "}
                  <br className="brake-remove" /> Impacting Your Credit Score
                </i>
              </p>
              <a
                href="https://minemi.ai/personal-loan/check-eligibility/"
                className="eligiblity-buttons"
                style={{
                  backgroundColor: "#59266D",
                  color: "#FFFFFF",
                  width: "39%",
                  borderRadius: 8,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 18,
                  paddingTop: 8,
                  paddingBottom: 8,
                  textAlign: "center",
                }}
              >
                Check Eligibility
              </a>
            </div>
            <div
              style={{
                width: 200,
                height: 195,
                backgroundColor: "transparent",
                marginRight: "4%",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
              }}
            >
              <img
                src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Illustration-1736403318651.webp"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                loading="lazy"
                alt="Check Eligibility"
              />
            </div>
          </div>

          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 24,
              color: "#1F1F1F",
              lineHeight: 1.2,
              marginTop: "4%",
            }}
          >
            Features That Enhance Eligibility
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
              color: "#1F1F1F",
              lineHeight: "20px",
            }}
          >
            HDFC Bank offers several features that enhance the chances of
            getting a personal loan:
          </p>

          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                      width: "30%",
                    }}
                  >
                    Feature
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Benefit
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Good Credit Score
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Higher credit scores (750 and above) may lead to better
                    terms and lower interest rates.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    High Income
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    A stable and higher income reassures the bank of your
                    ability to repay the loan.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Stable Employment
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Long tenure with your current employer shows job stability.
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Low Existing Debt
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    A low debt-to-income ratio suggests that you can manage the
                    new loan.
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                    borderBottomRightRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      borderBottomLeftRadius: 18,
                    }}
                  >
                    No Collateral Required
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Being an unsecured loan, you don’t need to provide any
                    assets as collateral.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h4
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            How to Check Your HDFC Bank Personal Loan Eligibility Online
          </h4>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Checking your eligibility is quick and easy:
          </div>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Visit HDFC Bank's Eligibility Checker:&nbsp;&nbsp;
                </span>
                Navigate to the HDFC Bank Personal Loan eligibility page.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Enter Basic Information:&nbsp;&nbsp;
                </span>
                Fill in details such as your name, date of birth, income, and
                employment status.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Submit:&nbsp;&nbsp;
                </span>
                Click "Check Eligibility" and get your results in minutes.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Eligibility Assessment:&nbsp;&nbsp;
                </span>
                Based on your inputs, you will see an immediate evaluation of
                your eligibility, loan amount, and possible interest rates.
              </div>
            </div>
          </div>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            <span
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Note:
            </span>{" "}
            This process is entirely online and does not require you to visit a
            branch.
          </p>
          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Eligibility Criteria by Applicant Type
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            The eligibility varies depending on the applicant’s profile. Here’s
            a breakdown:
          </div>

          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                      width: "30%",
                    }}
                  >
                    Applicant Type
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Criteria
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Salaried Individual
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Age: 21 to 60 years <br /> - Income: ₹25,000/month <br />{" "}
                    - Work Experience: Minimum 1 year in the current job
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Self-employed Professional
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Age: 21 to 65 years <br /> - Income: ₹2.5 lakh annually{" "}
                    <br /> - Experience: Minimum 2 years in the business
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Self-employed Non-professional
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Age: 21 to 65 years <br /> - Income: ₹2.5 lakh annually{" "}
                    <br /> - Experience: Minimum 2 years in business
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Documents Required for HDFC Bank Personal Loan Eligibility
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Here’s the list of essential documents you’ll need:
          </div>
          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                      width: "30%",
                    }}
                  >
                    Document Type Required Documents
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Required Documents
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Identity Proof
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Identity Proof Aadhar Card, Passport, Voter ID, PAN Card, or
                    Driver’s License
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Address Proof
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Aadhar Card, Passport, Utility Bill (within 3 months), or
                    Rent Agreement
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Income Proof
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Salaried: Latest 3 months salary slips and Form 16
                    <br />- Self-employed: ITR (2 years), Profit & Loss
                    statement
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Bank Statements
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Last 6 months bank statements to assess financial health
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Photographs
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    2-3 recent passport-sized photographs
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            How to Improve Your HDFC Bank Personal Loan Eligibility
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Improving your eligibility can increase your chances of loan
            approval:
          </div>
          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                      width: "30%",
                    }}
                  >
                    Tip
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Maintain a Good Credit Score
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Aim for a CIBIL score of 750 or higher by clearing past dues
                    and reducing credit card balances.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Increase Your Income
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Look for additional income sources or secure a salary hike
                    to improve your loan eligibility.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Reduce Existing Debts
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Pay off outstanding loans or credit card balances to improve
                    your debt-to-income ratio.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Work on Job Stability
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Stay with your current employer or business for a longer
                    period, showcasing job/business continuity.{" "}
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Consider a Co-Applicant
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Add a co-applicant with a good credit history to improve
                    your loan eligibility.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* minemi tip section */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              className="px-1 px-sm-4 py-4"
              style={{ backgroundColor: "#e7e4f9" }}
            >
              <div
                className="px-3 py-2"
                style={{ backgroundColor: "#e7e4f9", padding: "30px " }}
              >
                <div
                  className="d-flex flex-row gap-2 align-items-center"
                  style={{
                    backgroundColor: "transparent",
                    marginBottom: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    gap: "20px",
                  }}
                >
                  <img
                    src={toungeOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "20px",
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "#e7e4f9",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "20px",
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    <span
                      style={{ backgroundColor: "#e7e4f9", fontWeight: 600 }}
                    >
                      Track Your EMIs:
                    </span>{" "}
                    Use tools like MinEmi to track your existing EMIs and manage
                    them effectively. By consolidating or refinancing high-EMI
                    loans, you can lower your debt burden and boost your
                    eligibility for a new loan.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            HDFC Bank Personal Loan Benefits
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            HDFC Bank provides several advantages that make their personal loan
            offerings appealing:
          </div>
          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                      width: "30%",
                    }}
                  >
                    Benefit
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Fast Approval & Disbursal
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Get quick approval and disbursal of the loan, often within
                    hours.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Flexible Loan Tenure
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Choose a repayment tenure between 12 to 60 months to suit
                    your budget.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Competitive Interest Rates
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Interest rates starting from as low as 10.5% per annum.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    No Collateral Needed
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Unsecured loans that do not require any collateral or
                    security.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Pre-approved Loans
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Existing HDFC Bank customers may qualify for pre-approved
                    loans.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Balance Transfer Option
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Transfer your existing loan to HDFC Bank for better terms.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p
            style={{ fontSize: 16, lineHeight: "20px", color: "#1f1f1f" }}
            className="mt-4"
          >
            For more information please check{" "}
            <a
              href={
                "https://www.hdfcbank.com/personal/borrow/popular-loans/personal-loan/eligibility"
              }
              target="_blank"
            >
              HDFC Bank Eligibility
            </a>
          </p>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Frequently Asked Questions (FAQs){" "}
          </div>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                overflow: "hidden",
                backgroundColor: "#FFFFFF",
              }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What is the minimum salary required for an HDFC Bank personal
                loan?
              </AccordionSummary>
              <AccordionDetails>
                The minimum salary required is ₹25,000 per month, though it may
                vary depending on the city of residence.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                overflow: "hidden",
                backgroundColor: "#FFFFFF",
              }}
            >
              <AccordionSummary
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                How can I improve my chances of getting approved for a personal
                loan from HDFC Bank?
              </AccordionSummary>
              <AccordionDetails>
                Improve your credit score, reduce your existing debt, increase
                your income, and maintain a stable job or business.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                overflow: "hidden",
                backgroundColor: "#FFFFFF",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What is the maximum loan amount I can apply for with HDFC Bank?
              </AccordionSummary>
              <AccordionDetails>
                The loan amount ranges from ₹50,000 to ₹40 lakh depending on
                your eligibility.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                overflow: "hidden",
                backgroundColor: "#FFFFFF",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What is the interest rate for HDFC Bank personal loans?
              </AccordionSummary>
              <AccordionDetails>
                Interest rates start from 10.5% per annum, subject to the
                applicant’s credit score and profile.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                overflow: "hidden",
                backgroundColor: "#FFFFFF",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Is there a processing fee for personal loans from HDFC Bank?
              </AccordionSummary>
              <AccordionDetails>
                Yes, there is a processing fee of up to 2.50% of the loan
                amount, which is non-refundable.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                overflow: "hidden",
                backgroundColor: "#FFFFFF",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Can I apply for a top-up loan on an existing personal loan from
                HDFC Bank?
              </AccordionSummary>
              <AccordionDetails>
                Yes, HDFC Bank offers a top-up loan facility to existing
                customers based on their loan repayment history.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                overflow: "hidden",
                backgroundColor: "#FFFFFF",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                How long will it take for HDFC Bank to process my personal loan
                application?
              </AccordionSummary>
              <AccordionDetails>
                Once approved, your loan can be disbursed within 24 hours to 2
                working days, depending on the documentation and approval
                process.
              </AccordionDetails>
            </Accordion>
          </div>

          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 30,
              paddingBottom: 30,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br className="display-none" /> and tailored just for you—apply
                now to see what you qualify for!
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                  backgroundColor: "transparent",
                  marginTop: "3%",
                }}
              >
                <a
                  href="https://minemi.ai/personal-loan/check-eligibility/"
                  className="eligiblity-buttons"
                  style={{
                    backgroundColor: "#59266D",
                    color: "#FFFFFF",
                    width: "18%",
                    borderRadius: 8,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 18,
                    paddingTop: 8,
                    paddingBottom: 8,
                    textAlign: "center",
                  }}
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HDFCInterestRate;

import { useEffect, useState } from "react"
import { useLocation, Link, useNavigate } from "react-router-dom"
import axios from "axios"
import userlogo from "../../Assets/Images/userLogo.webp"
import homelogo from "../../Assets/Images/HomeLogo.webp"

function TopUpDetail() {
  const location = useLocation()
  const navigate = useNavigate()
  const { loan_tenure, maxtopupallowed, accountType, CurrentBalance, Account_Number, subscriber } = location.state

  const [button, setButton] = useState(false)
  const [table, setTable] = useState(false)
  const [TopAmount, setTopAmount] = useState(maxtopupallowed)
  const [irr, setIrr] = useState("")
  const [newIrrs, setNewirrs] = useState("")
  const [newemi, setnewEmi] = useState("")
  const [oddirr, setOddirrs] = useState("")
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-IN").format(number)
  }

  const formatPrice = (price) => {
    if (!price) return ""
    const cleanNumber = String(price).replace(/,/g, "")
    if (!/^\d+$/.test(cleanNumber)) return ""
    const lastThree = cleanNumber.substring(cleanNumber.length - 3)
    const otherNumbers = cleanNumber.substring(0, cleanNumber.length - 3)
    if (otherNumbers !== "") {
      const formatted = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",")
      return formatted + "," + lastThree
    }
    return lastThree
  }

  const Handler = () => {
    const token = localStorage.getItem("token")
    setTable(true)
    setButton(true)
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SECRET_KEY}/topupSummary`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        "topup-amount": CurrentBalance,
        tenure: loan_tenure,
        accountType: accountType,
        outstandingAmt: TopAmount,
      },
    }

    axios
      .request(options)
      .then((response) => {
        setnewEmi(response.data.data.summary.new_emi)
        setIrr(response.data.data.formulaUsed.irr)
        setNewirrs(response.data.data.summary.new_interest)
        setOddirrs(response.data.data.summary.current_interest)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token == null) {
      navigate("/")
    }
  }, [navigate])

  return (
    <div className="min-h-screen flex  justify-center bg-[#FAFAFA] px-4 py-8">
      <div className="w-full max-w-5xl" style={{backgroundColor:'transparent'}}>
        <div className="text-center mb-8" style={{backgroundColor:'transparent'}}>
          <h1 className="text-4xl font-semibold text-[#59266D] mb-6" style={{backgroundColor:'transparent'}}>Congratulations!</h1>
          <p className="text-lg text-gray-600" style={{textAlign:'center',backgroundColor:'transparent'}}>You Can avail Top-Up to Rs.{formatNumberWithCommas(maxtopupallowed)}</p>
        </div>

        <div className="flex flex-col md:flex-row md:space-x-8" style={{backgroundColor:'transparent'}}>
          <div className="w-full md:w-1/2" style={{backgroundColor:'transparent'}}>
            <div className="bg-white rounded-lg shadow-md p-6 mb-8">
              <div className="flex items-center border-b border-gray-200 pb-4 mb-6" style={{backgroundColor:'transparent'}}>
                <div className="w-12 h-12 bg-[#59266D]/10 rounded-full flex items-center justify-center mr-4" >
                  <img
                    src={accountType === "05" ? userlogo : homelogo}
                    alt={accountType === "05" ? "User Logo" : "Home Logo"}
                    className="w-8 h-8"
                    style={{backgroundColor:'transparent'}}
                  />
                </div>
                <div style={{backgroundColor:'transparent'}}>
                  <h2 className="font-bold text-gray-900" style={{backgroundColor:'transparent',fontSize:17}}>{accountType === "05" ? "Personal Loan" : "Housing Loan"}</h2>
                  <p className="text-sm text-gray-600" style={{backgroundColor:'transparent',marginTop:'-18%'}}>{subscriber}</p>
                </div>
              </div>

              <div className="mb-6" style={{backgroundColor:'transparent'}}>
                <label className="block text-sm font-medium text-gray-700 mb-1" style={{backgroundColor:'transparent'}}>Required Top-Up Amount (Rs)</label>
                <p className="text-xs text-gray-500 mb-2" style={{backgroundColor:'transparent'}}>Max: Rs {formatNumberWithCommas(maxtopupallowed)}</p>
                <input
                  type="text"
                  value={formatPrice(TopAmount)}
                  onChange={(e) => {
                    setTopAmount(e.target.value.replace(/,/g, ""))
                    setButtonDisabled(e.target.value === "")
                  }}
                  style={{backgroundColor:'transparent'}}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#59266D]"
                />
              </div>

              <div className="flex justify-between items-center mb-6" style={{backgroundColor:'transparent'}}>
                <span className="font-semibold text-[#59266D]" style={{backgroundColor:'transparent'}}>Tenure</span>
                <span className="font-semibold text-[#59266D]" style={{backgroundColor:'transparent'}}>{loan_tenure} Month</span>
              </div>

              <button
                onClick={Handler}
                disabled={TopAmount === ""}
                className={`w-full py-3 rounded-lg font-semibold text-white ${
                  TopAmount === "" ? "bg-[#59266D]/50 cursor-not-allowed" : "bg-[#59266D] hover:bg-[#4a1d5a]"
                } transition-colors`}
              >
                Proceed Top-up
              </button>
            </div>

         
          </div>

          {table && (
            <div className="w-full md:w-1/2" style={{backgroundColor:'transparent'}}>
              <div className="bg-white rounded-lg shadow-md overflow-hidden mb-8">
                <table className="w-full border">
                  <thead>
                    <tr>
                      <th className="py-3 px-4 bg-gray-100 text-left" style={{backgroundColor:'white'}}>Detail</th>
                      <th className="py-3 px-4 bg-[#3A868B] text-white text-center">New</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="py-3 px-4 border-b border-r">Tenure</td>
                      <td className="py-3 px-4 border-b text-center text-[#3A868B] font-bold">{loan_tenure}</td>
                    </tr>
                    <tr>
                      <td className="py-3 px-4 border-b border-r"  style={{backgroundColor:'white'}}>Interest</td>
                      <td className="py-3 px-4 border-b text-center text-[#3A868B] font-bold"  style={{backgroundColor:'white'}}>{newIrrs}%</td>
                    </tr>
                    <tr>
                      <td className="py-3 px-4 border-r">EMI</td>
                      <td className="py-3 px-4 text-center text-[#3A868B] font-bold">
                        ₹{formatNumberWithCommas(newemi)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          
        </div>
        {button && (
              <div className="mt-8" style={{margin:'auto',backgroundColor:'transparent'}}>
                <Link
                  to={accountType === "05" ? "/personalloanform" : "/homeloanform"}
                  state={{
                    newIrr: irr,
                    tenure: loan_tenure,
                    accountType: accountType,
                    Account_Number: Account_Number,
                    loanAmount: CurrentBalance,
                    oldIrr: oddirr,
                  }}
                  className="block w-full"
                >
                  <button  className={`w-full max-w-md mx-auto py-3 rounded-lg text-white font-semibold bg-[#59266D]
               transition-colors block`}>
                    {accountType === "05" ? "Process Top-up" : "Initiate Process"}
                  </button>
                </Link>
              </div>
            )}
      </div>
    </div>
  )
}

export default TopUpDetail


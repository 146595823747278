export const faqData = [
  {
    id: 1,
    question: " Who is eligible for a home loan?",
    answerList: [
      {
        heading:
          " Eligibility criteria vary by lender, but typically include: ",
      },
      {
        info: "Minimum age of 21 years at the time of loan application.",
      },
      {
        info: " Stable income or employment history.",
      },
      {
        info: "A good credit score (typically 650 or above).",
      },
      {
        info: "Indian citizenship or valid residency proof for NRIs.",
      },
    ],
  },
  {
    id: 2,
    question: "What is the minimum credit score required for a home loan?",
    answer:
      "Most lenders prefer a credit score of 700+ for home loan approvals, but the actual requirement may vary. Use Minemi's eligibility checker to know where you stand.",
  },
  {
    id: 3,
    question: "How much home loan can I get?",
    answer:
      "The loan amount depends on your income, credit score, and the value of the property. Use Minem's eligibility checker to find out how much you can borrow without affecting your credit score.",
  },
  {
    id: 4,
    question: "Will checking home loan eligibility impact my credit score?",
    answer:
      "No, Minemi uses a soft inquiry method to check your eligibility, so it doesn't impact your credit score.",
  },
  {
    id: 5,
    question: "Can I get a home loan for property under construction?",
    answer:
      "Yes, many lenders offer home loans for properties under construction. Minemi can help you find lenders that suit your needs.",
  },
  {
    id: 6,
    question: "How long does it take to get a home loan?",
    answer:
      "Home loans typically take a few weeks to process, depending on property verification and documentation. Minemi streamlines the process and provides faster access to offers.",
  },
  // {
  //   id: 7,
  //   question: "Can I prepay or foreclose my home loan?",
  //   answer:
  //     "Some credit cards allow balance transfers from personal loans or other credit cards. However, this depends on the card issuer’s policies.",
  // },
  // {
  //   id: 8,
  //   question: "What is the processing fee for a home loan?",
  //   answer:
  //     "Focus on timely repayments of the new balance to take full advantage of the lower interest rate. Avoid accumulating new debt on the old account to prevent further financial strain.",
  // },
];

import React, { useEffect, useState } from "react";
import { FaRupeeSign } from "react-icons/fa";
import { FaPercent } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ColorRing } from "react-loader-spinner";
import "./index.css";
import axios from "axios";
import img from '../../assets/Images/excellent-shape.webp'
import goodShape from '../../assets/Images/good-shape.webp'
import bagimage from '../../assets/Images/bad-shape.webp'
import goodpage  from  '../../assets/Images/good-page.webp'
import badpage  from  '../../assets/Images/bad-page.webp'
import badcalender  from  '../../assets/Images/bad-calender.webp'
import goodcalender  from  '../../assets/Images/good-calendar.webp'
import goodscale  from  '../../assets/Images/good-scale.webp'
import badscale  from  '../../assets/Images/bad-scale.webp'
import goodbag from '../../assets/Images/good-money-bag.webp'
import badbag from '../../assets/Images/bad-money-bag.webp'
const Index = () => {
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState("");
  const [tenure, setTenure] = useState("");
  const [interest, setInterest] = useState("");
  const [emi, setEmi] = useState("");
  const [formId, seFormId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [score,setScore]=useState(0)


  useEffect(() => {
  const score= localStorage.getItem("creditScore")
  setScore(score)
  }, [])
  

  const fields = [
    {
      label: "Loan amount",
      icon: <FaRupeeSign color="#59266D" className="icons" />,
      placeholder: 100000,
      type: "text",
      name: "amount",
      text: "( In Rupees )",
    },
    {
      label: "Loan Tenure",
      icon: <FaRegCalendarAlt color="#59266D" className="icons" />,
      placeholder: 12,
      type: "number",
      name: "tenure",
      text: "( In Months )",
    },
    {
      label: "Interest Rate",
      // icon: <FaPercent color="#59266D" className="icons" />,
      placeholder: 12,
      type: "text",
      name: "interest",
      text: "( In Percent )",
    },
    {
      label: "EMI",
      icon: <FaRupeeSign color="#59266D" className="icons" />,
      placeholder: 1000,
      type: "text",
      name: "emi",
      text: "( Per Months )",
    },
  ];

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (amount || tenure || interest || emi) {
      const formData = new FormData();
      formData.append("amount", amount);
      formData.append("tenure", tenure);
      formData.append("iRate", interest);
      formData.append("emi", emi);
      formData.append("formId", formId);
      setLoader(true);

      const response = await axios.post(
       `${process.env.REACT_APP_URL}/applyLoan`,
        formData
      );

      if (response.data.status) {
        setShow(true);
      }
      setLoader(false);
    }
  };
  const getRating = (score) => {
    if (score >= 720) {
      return "EXCELLENT";
    } else if (score >= 640) {
      return "GOOD";
    } else {
      return "BAD";
    }
  };
  return (
    <>
      <div id="creditScore">
        <div className="titleCon">
          <p className="title m-0 p-0">
            Factors Banks Consider Apart From Credit Score
          </p>
        </div>

        <div className="contentCon" >
          <div id="top-con" style={{backgroundColor:'transparent'}}>
            <div className="factor-main-con" style={{backgroundColor:'transparent'}} >
              <div className="factor-con">
                <p className="factor-text" style={{backgroundColor:'transparent'}}> Factor - 1</p>
                <div className="credit-content-con">
                  <div className="d-flex justify-content-center">
                    <img
                      src= {score<640?badbag:goodbag}
                      alt="good-money-bag"
                      className="credit-content-img1"
                      style={{backgroundColor:'transparent'}}
                    />
                  </div>
                  <p className="credit-content-title" style={{backgroundColor:'transparent'}}>Income Stability</p>
                  <p className="credit-content-desc1" style={{backgroundColor:'transparent'}}>
                    {" "}
                    Your income is stable and well-suited for loan approval.
                  </p>
                </div>
              </div>
            </div>

            <div className="factor-main-con" style={{backgroundColor:'transparent'}}>
              <div className="factor-con" >
                <p className="factor-text" style={{backgroundColor:'transparent'}}> Factor - 2</p>
                <div className="credit-content-con" >
                  <div className="d-flex justify-content-center" style={{backgroundColor:'transparent'}}>
                    <img
                      src= {score<640?badscale:goodscale}
                      alt="good-scale"
                      className="credit-content-img2"
                      style={{backgroundColor:'transparent'}}
                    />
                  </div>
                  <p className="credit-content-title" style={{backgroundColor:'transparent'}}>Debt-to-Income Ratio</p>
                  <p className="credit-content-desc2" style={{backgroundColor:'transparent'}}>
                    {" "}
                    Your debt-to-income ratio is healthy, supporting a strong
                    loan eligibility.
                  </p>
                </div>
              </div>
            </div>

            <div className="factor-main-con"style={{backgroundColor:'transparent'}}>
              <div className="factor-con ">
                <p className="factor-text" style={{backgroundColor:'transparent'}}> Factor - 3</p>
                <div className="credit-content-con">
                  <div className=" d-flex justify-content-center">
                    <img
                      src= {score<640?badcalender:goodcalender}
                      alt="good-calendar"
                      className="credit-content-img3"
                    />
                  </div>
                  <p className="credit-content-title" style={{backgroundColor:'transparent'}}>Employment History</p>
                  <p className="credit-content-desc3" style={{backgroundColor:'transparent'}}>
                    {" "}
                    Your stable employment history enhances your financial
                    credibility and supports loan approval.
                  </p>
                </div>
              </div>
            </div>
            <div className="factor-main-con" style={{backgroundColor:'transparent'}}>
              <div className="factor-con">
                <p className="factor-text" style={{backgroundColor:'transparent'}}> Factor - 4</p>
                <div className="credit-content-con">
                  <div className=" d-flex justify-content-center">
                    <img
                      src={score<640?badpage:goodpage}
                      alt="good-page"
                      className="credit-content-img4"
                    />
                  </div>
                  <p className="credit-content-title" style={{backgroundColor:'transparent'}}>Existing Liabilities</p>
                  <p className="credit-content-desc4" style={{backgroundColor:'transparent'}}>
                    {" "}
                    Your existing liabilities are manageable, supporting your
                    ability to handle additional financial commitments.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div id="bottom-con" style={{backgroundColor:'transparent'}}>
            <p id="bottom-con-title" style={{backgroundColor:'transparent',marginBottom:'4%'}}>Based on your above Credit Score</p>
            <div id="credit-score-details-con">
              <div className="row m-0 p-0 d-flex justify-content-between" style={{backgroundColor:'transparent'}}> 
                <div className="col-12 col-md-5 p-0" style={{backgroundColor:'transparent'}}>
                  <div id="cs-shape-img" style={{backgroundColor:'transparent'}}>
                    <img
                      src= {getRating(score) === "EXCELLENT" ? img : (getRating(score) === "GOOD" ? goodShape: bagimage)} 
                      alt="good-shape"
                      id="shape-img"
                      style={{backgroundColor:'transparent',width:'90%'}}
                    />
                  </div>
                  <p id="shape-text" style={{backgroundColor:'transparent',marginTop:'3%'}}>
        You are in a <b style={{backgroundColor:'transparent'}}>{getRating(score)}</b> 
        {getRating(score) === "EXCELLENT" ? "🙂👍" : (getRating(score) === "GOOD" ? "🙂" : "😐")} shape!
      </p>
                  <p id="shape-title" style={{backgroundColor:'transparent'}}>Financial Health Overview</p>
                  <p id="shape-desc" style={{backgroundColor:'transparent',lineHeight:1.3}}>
                    You have a High probability of loan acceptance. You've got
                    an excellent credit score! CreditPro is tailoring the best
                    loan options for you.
                  </p>
                </div>

                <div className="col-12 col-md-6 p-0" style={{backgroundColor:'transparent'}}>
                  <div className="form-con" >
                    <p className="form-title m-0 p-0" >
                      YOU ARE ELIGIBLE FOR....
                    </p>
                    <form onSubmit={handleSubmit} style={{backgroundColor:'transparent'}}>
                      <div className="pe-0" style={{backgroundColor:'transparent'}}>
                        { fields.map((item, index) => (
                          <div key={index}  style={{backgroundColor:'transparent'}}>
                            <div className="d-flex justify-content-between align-items-center input-main-con mt-3" >
                              <p className="label p-0 m-0">{item.label}</p>
                              <div className="input-con">
                                <div className="d-flex" >
                                  <InputGroup.Text className="input-icon" style={{border:'none',backgroundColor:'transparent'}}>
                                    {item.icon}
                                  </InputGroup.Text>
                                  <span className="input-field" style={{border:'none'}}>
                                    {(index === 0 &&
                                      new Intl.NumberFormat("en-IN").format(
                                        JSON.parse(localStorage.getItem("estimatorValue"))?.loanAmount                                      )) ||
                                      (index === 1 &&     JSON.parse(localStorage.getItem("estimatorValue"))?.tenure) ||
                                      (index === 2 &&     JSON.parse(localStorage.getItem("estimatorValue"))?.interestRate+"%") ||
                                      (index === 3 &&
                                        new Intl.NumberFormat("en-IN").format(
                                          JSON.parse(localStorage.getItem("estimatorValue"))?.montlyEmi
                                        ))}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="d-flex justify-content-center btn-con" style={{backgroundColor:'transparent'}}>
                        {!loader ? (
                          <Button className="apply-btn" type="submit">
                            Apply Now
                          </Button>
                        ) : (
                          <Button type="button" className="apply-btn" style={{textAlign:'center'}}>
                            <ColorRing
                              visible={loader}
                              height="30"
                              width="30"
                              ariaLabel="color-ring-loading"
                              wrapperStyle={{}}
                              wrapperClass="color-ring-wrapper"
                              style={{textAlign:'center'}}
                              colors={[
                                "#ffffff",
                                "#ffffff",
                                "#ffffff",
                                "#ffffff",
                                "#ffffff",
                                "#ffffff",
                              ]}
                            />
                          </Button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body style={{fontFamily:'Archivo-Regular',fontSize:14,color:'black ',textAlign:'center',padding:40,borderTopLeftRadius:12,borderTopRightRadius:12}}>
            Your responses have been recorded, we'll get back to you.
          </Modal.Body>
          <Modal.Footer>
            <Button id="modalCloseBtn" onClick={() => setShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Index;

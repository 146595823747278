
import { useState, useEffect } from "react"
import { ChevronUp } from "lucide-react"

export default function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 500) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener("scroll", toggleVisibility)
    return () => window.removeEventListener("scroll", toggleVisibility)
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <>
      {isVisible && (
        <button
          className="fixed bottom-3 right-3 md:bottom-6 md:right-6 h-8 w-8 md:h-12 md:w-12 rounded-full bg-[#311664] hover:bg-[#311664]/90 text-white shadow-lg p-0 z-50"
          onClick={scrollToTop}
          aria-label="Scroll to top"
          style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}
        >
          <ChevronUp className="h-4 w-4 md:h-6 md:w-6" />
        </button>
      )}
    </>
  )
}


import { useEffect, useState, useMemo } from "react";

export function Testimonials({ name }) {
  const [data, setData] = useState({});

  const kotakData = useMemo(
    () => ({
      title: "Success Stories: Kotak Bank Loan Approvals",
      description:
        "Real experiences from customers who successfully secured Kotak Bank Home loans with MinEMI's help",
      testimonials: [
        {
          name: "Neha Gupta",
          amount: "₹55,00,000",
          review:
            "I was looking for a home loan as a woman borrower and was worried about approval. MinEMI suggested HDFC Bank's Women's Home Loan scheme which offers special interest rates for women. The entire process was smooth, and I got a competitive interest rate of 8.50%. The HDFC Bank NetBanking portal made tracking my application status incredibly easy!",
        },
        {
          name: "Rahul Verma",
          amount: "₹75,00,000",
          review:
            "MinEMI's Document Checklist tool was a lifesaver! I was missing several important property documents that would have delayed my HDFC Bank Home Loan. Their experts guided me through the process of obtaining the right paperwork, and I was able to get my loan approved without any hiccups. As a salaried professional, I also qualified for the Home Loan Overdraft option which gives me the flexibility to withdraw funds as needed for my home renovation project!",
        },
      ],
      link: {
        text: "Read More Kotak Bank Success Stories ",
        url: "/testimonials",
      },
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their home loan approval chances",
      testimonials: [
        {
          name: "Suresh Kumar",
          amount: "₹60,00,000",
          review:
            "As a government employee, I was looking for a home loan with special benefits. MinEMI suggested Bank of Baroda's Baroda Advantage Home Loan scheme which offers special interest rates for government employees. The entire process was smooth, and I got a competitive interest rate of 8.40%. The BOB World Internet Banking portal made tracking my application status incredibly easy!",
        },
        {
          name: "Priya Sharma",
          amount: "₹45,00,000",
          review:
            "MinEMI's Document Checklist tool was a lifesaver! I was missing several important property documents that would have delayed my Bank of Baroda Home Loan. Their experts guided me through the process of obtaining the right paperwork, and I was able to get my loan approved without any hiccups. I also opted for the Baroda Home Loan Plus option which gives me the flexibility to access funds through an overdraft facility for my future home renovation needs!",
        },
      ],
      link: {
        text: "View More Success Stories ",
        url: "/testimonials",
      },
    }),
    []
  );
  const idfcData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their home loan approval chances",
      testimonials: [
        {
          name: "Anjali Desai",
          amount: "₹65,00,000",
          review:
            "As a woman borrower, I was looking for a home loan with special benefits. MinEMI suggested IDFC FIRST Bank's FIRST Preferred Home Loan scheme which offers special interest rates for women borrowers. The entire process was smooth, and I got a competitive interest rate of 8.35%. The IDFC FIRST Mobile App made tracking my application status incredibly easy!",
        },
        {
          name: "Vikram Malhotra",
          amount: "₹50,00,000",
          review:
            "MinEMI's Document Checklist tool was a lifesaver! I was missing several important property documents that would have delayed my IDFC FIRST Bank Home Loan. Their experts guided me through the process of obtaining the right paperwork, and I was able to get my loan approved without any hiccups. I also opted for the FIRST Flexi Home Loan option which gives me the flexibility to make unlimited part-payments without any charges. This has already helped me reduce my loan tenure by making additional payments whenever I have extra funds!",
        },
      ],
      link: {
        text: "Read More IDFC FIRST Bank Success Stories ",
        url: "/testimonials",
      },
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their home loan approval chances",
      testimonials: [
        {
          name: "Neha Gupta",
          amount: "₹55,00,000 ",
          review:
            "I was looking for a home loan as a woman borrower and was worried about approval. MinEMI suggested HDFC Bank's Women's Home Loan scheme which offers special interest rates for women. The entire process was smooth, and I got a competitive interest rate of 8.50%. The HDFC Bank NetBanking portal made tracking my application status incredibly easy!",
        },
        {
          name: "Rahul Verma",
          amount: "₹75,00,000 ",
          review:
            "MinEMI's Document Checklist tool was a lifesaver! I was missing several important property documents that would have delayed my HDFC Bank Home Loan. Their experts guided me through the process of obtaining the right paperwork, and I was able to get my loan approved without any hiccups. As a salaried professional, I also qualified for the Home Loan Overdraft option which gives me the flexibility to withdraw funds as needed for my home renovation project!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their home loan approval chances",
      testimonials: [
        {
          name: "Priya Gupta",
          amount: "₹70,00,000",
          review:
            "As a woman borrower, I was looking for a home loan with special benefits. MinEMI suggested SBI's home loan scheme which offers a 5 basis points concession for women borrowers. The entire process was smooth, and I got a competitive interest rate of 8.35%. The YONO SBI App made tracking my application status incredibly easy!",
        },
        {
          name: "Rahul Verma",
          amount: "₹55,00,000",
          review:
            "MinEMI's Document Checklist tool was a lifesaver! I was missing several important property documents that would have delayed my SBI Home Loan. Their experts guided me through the process of obtaining the right paperwork, and I was able to get my loan approved without any hiccups. I also opted for the SBI Maxgain Home Loan which functions as a home loan with an overdraft facility. This has already helped me save on interest costs by linking my savings to my loan account!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their home loan approval chances",
      testimonials: [
        {
          name: "Anita Desai",
          amount: "₹65,00,000",
          review:
            "As a woman borrower, I was looking for a home loan with competitive rates. MinEMI guided me toward Axis Bank's home loan with its special 5 basis points concession for women. The Express Home Loan service lived up to its promise - I got my approval in just 2 days! The Axis Mobile app made it incredibly easy to track my application status throughout the process.",
        },
        {
          name: "Vikram Mehta",
          amount: "₹48,00,000",
          review:
            "MinEMI's document checklist tool was incredibly helpful during my Axis Bank home loan application. I was missing several key property documents that would have delayed my application. Their experts guided me through the process of obtaining the right paperwork, and I opted for the Super Saver Home Loan which has already helped me save on interest while keeping my savings accessible. The digital application process through the Axis Mobile app was convenient and user-friendly.",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their home loan approval chances",
      testimonials: [
        {
          name: "Rohit Sharma",
          amount: "₹75,00,000",
          review:
            "I was looking for a flexible home loan option that would allow me to use my occasional bonuses to reduce interest. MinEMI guided me toward ICICI Bank's Home Loan Overdraft facility. The iMobile Pay app made the application process incredibly smooth, and I could track my status at every stage. I got my approval in just 3 days! The ability to deposit surplus funds and reduce interest while maintaining access to my money has been a game-changer.",
        },
        {
          name: "Priya Nair",
          amount: "₹85,00,000",
          review:
            "As a young professional with good career growth prospects, I wanted to buy a better home than my current salary would allow. MinEMI's tools helped me identify ICICI Bank's Extraa Home Loan as the perfect solution. I qualified for an additional 15% loan amount based on my future income potential. The Home Loan at Your Doorstep service was incredibly convenient - the loan specialist came to my office at a time that worked for me, and guided me through the entire documentation process. Highly recommend!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their home loan approval chances",
      testimonials: [
        {
          name: "Vikram Mehta",
          amount: "₹85,00,000",
          review:
            "I was looking for a premium home loan option with excellent service. MinEMI guided me toward IndusInd Bank's Super Home Loan. The IndusInd Mobile App made tracking my application status incredibly easy. As part of their Platinum Circle program, I was assigned a dedicated relationship manager who guided me through the entire process. I got my approval in just 4 days! The preferential interest rate and personalized service have been exceptional. I highly recommend this for anyone looking for a premium home loan experience.",
        },
        {
          name: "Ananya Gupta",
          amount: "₹70,00,000",
          review:
            "As a young professional with good career growth prospects, I was looking for a home loan that would align with my future income growth. MinEMI's tools helped me identify IndusInd Bank's Step-Up EMI feature as the perfect solution. I started with lower EMIs that will gradually increase as my income grows. The Balance Transfer Plus option also allowed me to consolidate my existing personal loan with my new home loan, simplifying my finances. The digital application process was smooth, and I received excellent support throughout. Highly recommend!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their loan approval chances",
      testimonials: [
        {
          name: "Sanjay Mehta",
          amount: "₹5,00,000",
          review:
            "After my IndusInd Bank personal loan was initially put on hold, I used MinEMI's Loan Approval Predictor to identify the issues. I improved my debt-to-income ratio and my second application was approved in just 2 days with a competitive interest rate!",
        },
        {
          name: "Priya Desai",
          amount: "₹3,80,000",
          review:
            "MinEMI's Eligibility Checker helped me understand exactly how much I could borrow from IndusInd Bank. I applied for the right amount and got instant approval with a competitive interest rate of 10.25% through their IndusMobile app.",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      title: "Success Stories: YES Bank Home Loan Approvals",
      description:
        "Real experiences from customers who successfully secured YES Bank home loans with MinEMI's help",
      testimonials: [
        {
          name: "Vikram Mehta",
          amount: "₹75,00,000",
          review:
            "I was initially concerned about the property documentation for my home loan with YES Bank. MinEMI's Property Eligibility Checker identified exactly what was missing and guided me through the process. Their team helped me organize all the necessary documents, and my loan was approved within just 7 days! The interest rate I received was also better than what I was initially offered.",
        },
        {
          name: "Ananya Desai",
          amount: "₹95,00,000",
          review:
            "As a self-employed professional, I was struggling to get my YES Bank home loan approved due to income documentation issues. MinEMI's team helped me present my financials in a way that clearly demonstrated my repayment capacity. Their Approval Predictor tool also suggested I add my spouse as a co-applicant, which not only got my loan approved but also increased the eligible loan amount by 30%!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their loan approval chances",
      testimonials: [
        {
          name: "Vikram Singh",
          amount: "₹4,50,000",
          review:
            "I was worried about my Yes Bank personal loan application after it was marked 'Pending'. MinEMI's Loan Approval Predictor identified that my debt-to-income ratio was too high. I paid off a small loan and reapplied - approved within 48 hours!",
        },
        {
          name: "Ananya Sharma",
          amount: "₹3,75,000",
          review:
            "MinEMI's Eligibility Checker helped me understand exactly how much I could borrow from Yes Bank. I applied for the right amount and got instant approval with a competitive interest rate of 10.25%.",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      title: "Success Stories: Union Bank Home Loan Approvals",
      description:
        "Real experiences from customers who successfully secured Union Bank home loans with MinEMI's help",
      testimonials: [
        {
          name: "Rajesh Kumar",
          amount: "₹45,00,000",
          review:
            "I was struggling with my PMAY subsidy application for my Union Bank home loan. MinEMI's PMAY Subsidy Calculator helped me understand my exact eligibility and their team guided me through the entire documentation process. Not only was my loan approved quickly, but I also received the full subsidy amount which reduced my EMI significantly. The interest rate I received was also better than what I was initially offered.",
        },
        {
          name: "Priya Sharma",
          amount: "₹65,00,000",
          review:
            "As a government employee, I wasn't aware of the special interest rate concessions offered by Union Bank. MinEMI's team identified this opportunity and helped me apply with the right documentation. Their Approval Predictor tool also suggested I add my spouse as a co-applicant to take advantage of the women borrower concession, which further reduced my interest rate by 0.1%. The entire process was smooth, and I received approval within just 10 days!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      title: "Success Stories: Bajaj Finance Home Loan Approvals",
      description:
        "Real experiences from customers who successfully secured Bajaj Finance home loans with MinEMI's help",
      testimonials: [
        {
          name: "Rahul Sharma",
          amount: "₹85,00,000",
          review:
            "I was looking to transfer my existing home loan to get a better interest rate. MinEMI's Home Loan Takeover Calculator showed me exactly how much I could save with Bajaj Finance. Their team helped me with all the documentation and negotiated an additional 0.1% interest rate reduction because of my excellent credit score. The entire process was completed in just 15 days, and I'm now saving ₹4,500 per month on my EMI!",
        },
        {
          name: "Neha Patel",
          amount: "₹65,00,000",
          review:
            "As a first-time home buyer, I was overwhelmed by the home loan process. MinEMI's Bajaj Finance Approval Predictor tool identified that my credit utilization was too high, which could affect my approval. Their team guided me to reduce my credit card balances and apply as a joint applicant with my spouse. Thanks to their advice, not only was my loan approved, but I also qualified for the first-time homebuyer special scheme with reduced processing fees!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      title: "Success Stories: Federal Bank Home Loan Approvals",
      description:
        "Real experiences from customers who successfully secured Federal Bank home loans with MinEMI's help",
      testimonials: [
        {
          name: "Arun Menon",
          amount: "₹75,00,000",
          review:
            "As an NRI, I was concerned about the home loan process in India. MinEMI's Federal Bank Approval Predictor tool helped me understand the specific documentation I needed as an NRI applicant. Their team guided me through the entire process remotely, and I was able to secure a home loan with a 0.05% interest rate concession due to my NRI status. The doorstep service for document collection was particularly helpful as my family in India could handle everything without me having to travel.",
        },
        {
          name: "Priya Thomas",
          amount: "₹60,00,000",
          review:
            "I was looking for an eco-friendly home and wanted to take advantage of Federal Bank's Green Home Loan program. MinEMI's Green Home Loan Calculator showed me exactly how much I could save with the special interest rates. Their team also helped me understand the specific green certifications my property needed to qualify. Thanks to their guidance, I not only got a lower interest rate but also qualified for additional benefits like reduced processing fees. The entire process was smooth, and I received approval within just 10 days!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from customers who successfully secured Canara Bank home loans with MinEMI's help",
      testimonials: [
        {
          name: "Rajesh Kumar",
          amount: "₹70,00,000",
          review:
            "As a government employee, I was looking for a home loan with special benefits. MinEMI's Canara Bank Approval Predictor tool helped me understand that I qualified for a 0.10% interest rate concession. Their team guided me through the documentation process and helped me highlight my government service appropriately. Thanks to their guidance, I not only got the interest rate concession but also qualified for a higher loan amount than I initially expected!",
        },
        {
          name: "Meera Patel",
          amount: "₹45,00,000",
          review:
            "I was a first-time home buyer with a modest income, and I wasn't sure if I would qualify for a home loan. MinEMI's Budget Home Loan Calculator showed me that I was eligible for Canara Bank's special Budget Home Loan scheme. Their team helped me prepare all the necessary documentation and guided me through the entire process. The relaxed eligibility criteria of the Budget Home Loan scheme made it possible for me to buy my first home with an affordable EMI. I'm extremely grateful for MinEMI's expert guidance!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      title: "Success Stories: Bank of India Home Loan Approvals",
      description:
        "Real experiences from customers who successfully secured Bank of India home loans with MinEMI's help",
      testimonials: [
        {
          name: "Priya Sharma",
          amount: "₹65,00,000",
          review:
            "As a woman borrower, I was looking for a home loan with special benefits. MinEMI's Bank of India Approval Predictor tool helped me understand that I qualified for a 0.05% interest rate concession. Their team guided me through the documentation process and helped me highlight my eligibility for the women borrower scheme. Thanks to their guidance, I not only got the interest rate concession but also qualified for a higher loan amount than I initially expected!",
        },
        {
          name: "Rahul Verma",
          amount: "₹55,00,000",
          review:
            "I was a first-time home buyer and wasn't sure if I would qualify for Bank of India's Star Home Loan scheme. MinEMI's Star Home Loan Calculator showed me that I was eligible for the premium offering with lower processing fees and complimentary property insurance for the first year. Their team helped me prepare all the necessary documentation and guided me through the entire process. The reduced margin requirement for first-time buyers made it possible for me to buy my dream home with an affordable EMI. I'm extremely grateful for MinEMI's expert guidance!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from customers who successfully secured RBL Bank home loans with MinEMI's help",
      testimonials: [
        {
          name: "Vikram Mehta",
          amount: "₹60,00,000",
          review:
            "As a first-time homebuyer, I was looking for a bank with a streamlined digital process. MinEMI's RBL Bank Approval Predictor tool helped me understand that I qualified for their 'Apna Ghar' scheme with lower processing fees. Their team guided me through the documentation process and helped me highlight my eligibility for the first-time homebuyer benefits. Thanks to their guidance, I not only got approved quickly but also received in-principle approval within just 24 hours through their Express Home Loan program!",
        },
        {
          name: "Neha Kapoor",
          amount: "₹75,00,000",
          review:
            "As a self-employed professional, I was worried about the documentation requirements for a home loan. MinEMI's Express Home Loan Calculator showed me that I was eligible for RBL Bank's specialized home loan program for self-employed individuals with relaxed income documentation requirements. Their team helped me prepare all the necessary documentation and guided me through the entire process. The digital-first approach of RBL Bank made the entire process smooth and hassle-free. I'm extremely grateful for MinEMI's expert guidance!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const ltData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from customers who successfully secured L&T Bank home loans with MinEMI's help",
      testimonials: [
        {
          name: "Vikram Mehta",
          amount: "₹80,00,000",
          review:
            "As a first-time homebuyer, I was looking for a bank with a streamlined digital process. MinEMI's L&t Bank Approval Predictor tool helped me understand that I qualified for their 'Apna Ghar' scheme with lower processing fees. Their team guided me through the documentation process and helped me highlight my eligibility for the first-time homebuyer benefits. Thanks to their guidance, I not only got approved quickly but also received in-principle approval within just 24 hours through their Express Home Loan program!",
        },
        {
          name: "Neha Kapoor",
          amount: "₹75,00,000",
          review:
            "As a self-employed professional, I was worried about the documentation requirements for a home loan. MinEMI's Express Home Loan Calculator showed me that I was eligible for L&T Bank's specialized home loan program for self-employed individuals with relaxed income documentation requirements. Their team helped me prepare all the necessary documentation and guided me through the entire process. The digital-first approach of L&T Bank made the entire process smooth and hassle-free. I'm extremely grateful for MinEMI's expert guidance!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const idbiData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from customers who successfully secured IDBI Bank home loans with MinEMI's help",
      testimonials: [
        {
          name: "Vikram Mehta",
          amount: "₹70,00,000",
          review:
            "As a first-time homebuyer, I was looking for a bank with a streamlined digital process. MinEMI's IDBI Bank Approval Predictor tool helped me understand that I qualified for their 'Apna Ghar' scheme with lower processing fees. Their team guided me through the documentation process and helped me highlight my eligibility for the first-time homebuyer benefits. Thanks to their guidance, I not only got approved quickly but also received in-principle approval within just 24 hours through their Express Home Loan program!",
        },
        {
          name: "Neha Kapoor",
          amount: "₹55,00,000",
          review:
            "As a self-employed professional, I was worried about the documentation requirements for a home loan. MinEMI's Express Home Loan Calculator showed me that I was eligible for IDBI Bank's specialized home loan program for self-employed individuals with relaxed income documentation requirements. Their team helped me prepare all the necessary documentation and guided me through the entire process. The digital-first approach of IDBI Bank made the entire process smooth and hassle-free. I'm extremely grateful for MinEMI's expert guidance!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const lichousingData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from customers who successfully secured LIC Housing Finance Bank home loans with MinEMI's help",
      testimonials: [
        {
          name: "Vikram Mehta",
          amount: "₹50,00,000",
          review:
            "As a first-time homebuyer, I was looking for a bank with a streamlined digital process. MinEMI's LIC Housing Finance Bank Approval Predictor tool helped me understand that I qualified for their 'Apna Ghar' scheme with lower processing fees. Their team guided me through the documentation process and helped me highlight my eligibility for the first-time homebuyer benefits. Thanks to their guidance, I not only got approved quickly but also received in-principle approval within just 24 hours through their Express Home Loan program!",
        },
        {
          name: "Neha Kapoor",
          amount: "₹75,00,000",
          review:
            "As a self-employed professional, I was worried about the documentation requirements for a home loan. MinEMI's Express Home Loan Calculator showed me that I was eligible for LIC Housing Finance Bank's specialized home loan program for self-employed individuals with relaxed income documentation requirements. Their team helped me prepare all the necessary documentation and guided me through the entire process. The digital-first approach of LIC Housing Finance Bank made the entire process smooth and hassle-free. I'm extremely grateful for MinEMI's expert guidance!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const icicihousingData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        " Real experiences from people who improved their home loan approval chances with ICICI Home Finance",
      testimonials: [
        {
          name: "Vikram Mehta",
          amount: "₹68,00,000",
          review:
            "My ICICI Home Finance loan application was stuck in the property evaluation stage for weeks. MinEMI's experts helped me identify the missing legal documents and guided me through the verification process. My loan was approved within just 10 days after addressing these issues!",
        },
        {
          name: "Kavita Singh",
          amount: "₹42,00,000",
          review:
            "As an ICICI Bank customer, I wasn't aware of the special interest rates offered by ICICI Home Finance.MinEMI's Home Loan Eligibility Calculator showed me exactly how much I could save. Their status tracker kept me updated throughout the process, and I got my loan approved with a great interest rate!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const pnbhousingData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        " Real experiences from people who improved their home loan approval chances with PNB Housing Finance",
      testimonials: [
        {
          name: "Vikram Singh",
          amount: "₹58,00,000",
          review:
            "My PNB Housing Finance loan application was stuck due to incomplete property documentation. MinEMI's experts helped me identify the missing legal documents and guided me through the verification process. Their loan status tracker kept me updated at every stage, and my loan was approved within just 12 days!",
        },
        {
          name: "Neha Gupta",
          amount: "₹45,00,000",
          review:
            "As a government employee, I wasn't aware of the special interest rates offered by PNB Housing Finance. MinEMI's Home Loan Eligibility Calculator showed me exactly how much I could save. Their guidance helped me navigate the entire process smoothly, and I got my loan approved with a great interest rate!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const hdfclnthousingData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        " Real experiences from people who improved their HDFC Ltd. home loan outcomes",
      testimonials: [
        {
          name: "Suresh Menon",
          amount: "₹85,00,000",
          review:
            "My HDFC Ltd. home loan was stuck in 'Legal Verification' status for over two weeks. MinEMI's support team helped me identify issues with my property documents. After addressing the title concerns and providing additional paperwork, my loan for ₹85 lakhs was approved within just 10 days with a competitive interest rate of 8.5%!",
        },
        {
          name: "Anjali Sharma",
          amount: "₹1,20,00,000",
          review:
            "MinEMI's Approval Analyzer suggested I should apply for HDFC Ltd.'s Green Home Loan program since I was purchasing an eco-certified property. I followed their advice, and not only was my application processed faster, but I also received a 0.25% interest rate reduction on my ₹1.2 crore loan! The Home Loan Overdraft facility they recommended has also saved me thousands in interest costs.",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const sbihomehousingData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        " Real experiences from people who improved their SBI home loan outcomes",
      testimonials: [
        {
          name: "Rajesh Kumar",
          amount: "₹65,00,000",
          review:
            "My SBI home loan was stuck in 'Technical Verification' status for over two weeks. MinEMI's support team helped me identify issues with my property valuation. After addressing the concerns and providing additional documentation about the property, my loan for ₹65 lakhs was sanctioned within just 8 days with a competitive interest rate of 8.35%!",
        },
        {
          name: "Meera Patel",
          amount: "₹90,00,000",
          review:
            "MinEMI's Approval Analyzer suggested I should apply for SBI's 'Her Ghar' scheme since I was the primary applicant. I followed their advice, and not only was my application processed faster, but I also received a 0.05% interest rate concession on my ₹90 lakh loan! The Flexipay option they recommended has also made my initial EMIs much more manageable while I'm establishing my career.",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const adityahomehousingData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        " Real experiences from people who improved their Aditya Birla Finance home loan outcomes",
      testimonials: [
        {
          name: "Anand Sharma",
          amount: "₹78,00,000",
          review:
            "As a self-employed professional, I was struggling with my home loan application at Aditya Birla Finance. MinEMI's guidance helped me understand how to properly document my income sources. Thanks to their support, I was able to provide the right business documentation and secured a ₹78 lakh home loan at 8.60% interest with ABFL's special self-employed program!",
        },
        {
          name: "Priya Desai",
          amount: "₹55,00,000",
          review:
            "MinEMI's Approval Analyzer suggested I apply for Aditya Birla Finance's 'Step-Up EMI' option since I'm early in my career. Following their advice, I not only got my ₹55 lakh loan approved within just 7 days, but also started with 30% lower EMIs in the first two years! The ABFL express approval process was incredibly smooth, and I couldn't be happier with my new home.",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );

  useEffect(() => {
    if (name === "checkkotakhomeloanbankstatus") {
      setData(kotakData);
    } else if (name === "checkbobhomeloanbankstatus") {
      setData(bobData);
    } else if (name === "checkidfchomeloanbankstatus") {
      setData(idfcData);
    } else if (name === "checkhdfchomeloanbankstatus") {
      setData(hdfcData);
    } else if (name === "checksbihomeloanbankstatus") {
      setData(sbiData);
    } else if (name === "checkaxishomeloanbankstatus") {
      setData(axisData);
    } else if (name === "checkicicihomeloanbankstatus") {
      setData(iciciData);
    } else if (name === "checkpunjabhomeloanbankstatus") {
      setData(punjabData);
    } else if (name === "checkinduslndhomeloanbankstatus") {
      setData(induslndData);
    } else if (name === "checktatahomeloanbankstatus") {
      setData(tataData);
    } else if (name === "checkyeshomeloanbankstatus") {
      setData(yesData);
    } else if (name === "checkunionhomeloanbankstatus") {
      setData(unionData);
    } else if (name === "checkbajajhomeloanbankstatus") {
      setData(bajajData);
    } else if (name === "checkfederalhomeloanbankstatus") {
      setData(federalData);
    } else if (name === "checkcanarahomeloanbankstatus") {
      setData(canaraData);
    } else if (name === "checkboihomeloanbankstatus") {
      setData(boiData);
    } else if (name === "checkrblhomeloanbankstatus") {
      setData(rblData);
    } else if (name === "checklthomeloanbankstatus") {
      setData(ltData);
    } else if (name === "checkidbihomeloanbankstatus") {
      setData(idbiData);
    } else if (name === "checklichousinghomeloanbankstatus") {
      setData(lichousingData);
    } else if (name === "checkicicihousinghomeloanbankstatus") {
      setData(icicihousingData);
    } else if (name === "checkpnbhousinghomeloanbankstatus") {
      setData(pnbhousingData);
    } else if (name === "checkhdfcltdhomeloanbankstatus") {
      setData(hdfclnthousingData);
    } else if (name === "checksbihousinghomeloanbankstatus") {
      setData(sbihomehousingData);
    } else if (name === "checkadityahousinghomeloanbankstatus") {
      setData(adityahomehousingData);
    } else {
      setData({});
    }
  }, [
    name,
    axisData,
    bobData,
    hdfcData,
    iciciData,
    idfcData,
    kotakData,
    sbiData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
    ltData,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    hdfclnthousingData,
    sbihomehousingData,
    adityahomehousingData,
  ]);

  return (
    <section className="py-4 bg-white border-b border-gray-200">
      <div className="container px-3 md:px-6 bg-transparent">
        <div className="w-4/5l mx-auto bg-transparent">
          <div className="text-center mb-6 bg-transparent">
            <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple">
              {data.title}
            </h2>
            <p className="text-dark-grey mt-2 text-sm bg-transparent text-center">
              {data.description}
            </p>
          </div>

          <div className="grid gap-3 bg-transparent">
            {data.testimonials?.map((d, index) => (
              <div
                key={index}
                className=" p-3 rounded-lg border border-gray-200 shadow-sm bg-[#ebe8fb]"
              >
                <div className="mb-3 bg-transparent">
                  <div className="flex bg-transparent">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <svg
                        key={star}
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="#FFD700"
                        stroke="#FFD700"
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="mr-1 bg-transparent"
                      >
                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                      </svg>
                    ))}
                  </div>
                </div>
                <p className="text-dark-grey mb-3 text-sm bg-transparent">
                  {d.review}
                </p>
                <div className="flex justify-between items-center bg-transparent">
                  <p className="font-bold text-primary-purple text-sm bg-transparent">
                    {d.name}
                  </p>
                  <p className="text-sm text-teal font-medium bg-transparent">
                    {d.amount}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* <div className="mt-4 text-center bg-transparent">
            <a
              href="/testimonials"
              className="text-primary-purple text-sm font-medium hover:underline"
            >
              {data?.link?.text} →
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
}

import React, { useEffect, useContext } from "react";
// import image from "../../Assets/Images/image 48.webp";
import { LuDot } from "react-icons/lu";
import { FaAngleDown, FaChevronUp } from "react-icons/fa6";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import "./HomeLoanHdfcEligibility.css";
import { Helmet } from "react-helmet";
import MetadataContext from "../../MetadataContext";

const HomeLoanHdfcEligibility = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const criteriaData = [
    {
      factor: "Income",
      requirement:
        "Minimum income varies by location and employment status (₹25,000/month recommended for salaried individuals in metro cities).",
      impact:
        "Higher income increases eligibility for larger loan amounts as it demonstrates repayment capacity.",
    },
    {
      factor: "Credit Score",
      requirement: "750 or above is recommended.",
      impact:
        "A high credit score improves the likelihood of loan approval and access to better interest rates.",
    },
    {
      factor: "Employment Status",
      requirement: "Salaried, self-employed professionals, or business owners.",
      impact:
        "Stability and continuity in employment positively impact eligibility.",
    },
    {
      factor: "Age",
      requirement: "21–65 years at loan maturity.",
      impact:
        "Younger applicants have higher tenure options, while older applicants may face restrictions on tenure.",
    },
    {
      factor: "Loan Amount",
      requirement:
        "Based on property value and repayment capacity; typically up to 90% of property value for loans under ₹30 lakhs.",
      impact:
        "A higher loan amount requires stronger financial credentials, including high income and creditworthiness.",
    },
    {
      factor: "Tenure",
      requirement: "Up to 30 years.",
      impact:
        "Longer tenures reduce EMI burden but increase total interest paid; eligibility for longer tenure depends on age and income.",
    },
  ];

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Check you Home Loan Eligibility with HDFC Bank",
      pageDescription:
        "Use HDFC Bank's eligibility calculator to check how much you can borrow without affecting your credit score.",
      pageKeywords:
        "home loan eligibility, home loan eligibility minemi, HDFC Bank home loan, home loan eligibility calculator, check loan eligibility, HDFC home loan check, loan eligibility without affecting credit score, home loan qualification, home loan borrowing limit, HDFC home loan eligibility, loan approval check",
      canonicalUrl:
        "https://minemi.ai/loanpage/homeloan/home-loan-eligibility-criteria/hdfc-home-loan-eligibility",
    });
  }, [setMetadata]);
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className="custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/homeloan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Home Loan
              </Link>{" "}
              &nbsp;
              <span
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/homeloan/home-loan-eligibility-criteria"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Home Loan Check Eligibility
              </Link>{" "}
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                HDFC Bank Home Loan Eligibility
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "46px",

              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Check you Home Loan Eligibility with HDFC Bank{" "}
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Discover how much you can borrow from HDFC Bank without impacting
            your credit score. Use our eligibility calculator and get
            personalized insights based on your income, credit score, and other
            factors.
          </p>
        </div>
        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 30 }}>
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 28,
              marginTop: 30,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Home Loan Eligibility Criteria{" "}
          </p>

          <div
            style={{
              border: "1px solid #D9D9D9",
              borderRadius: 24,
              marginTop: 24,
              marginBottom: "2%",
              maxWidth: "100%",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
              }}
            >
              <div
                style={{
                  backgroundColor: "#E7E4F9",
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#59266D",
                  padding: "16px",
                  borderBottom: "1px solid #D9D9D9",
                  borderTopLeftRadius: 24,
                  borderTopRightRadius: 24,
                  textAlign: "center",
                }}
              >
                HDFC Loan Eligibility Criteria
              </div>
            </div>

            <div
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                width: "100%",
                overflow: "auto",
              }}
            >
              <table
                className="table-class"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",

                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  textAlign: "left",
                }}
              >
                <thead className="thead-class">
                  <tr>
                    <th
                      className="table-padding"
                      style={{
                        width: "20%",
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Eligibility Criteria
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Minimum Requirement
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Impact on Loan Eligibility
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {criteriaData.map((row, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      }}
                    >
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        {row.factor}
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {row.requirement}
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {row.impact}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Understanding Your Eligibility for HDFC Bank Home Loans{" "}
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            HDFC Bank evaluates your home loan eligibility based on various
            factors, ensuring a straightforward and transparent process:
          </div>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Income:&nbsp;&nbsp;
                </span>
                A stable monthly income directly impacts your loan amount
                eligibility. Higher income allows for larger loan approvals.{" "}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Credit Score:&nbsp;&nbsp;
                </span>
                A score of 750 or above significantly improves your chances of
                loan approval and better interest rates.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Employment Status:&nbsp;&nbsp;
                </span>
                Salaried individuals, self-employed professionals, and business
                owners with stable financial records are eligible.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Age:&nbsp;&nbsp;
                </span>
                Applicants aged 21–65 years are eligible, with the loan tenure
                tailored to ensure repayment before retirement.{" "}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Property Value:&nbsp;&nbsp;
                </span>
                Loan amounts are typically up to 90% of the property value for
                amounts under ₹30 lakhs, subject to repayment capacity.
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Improve Your Eligibility for HDFC Bank Home Loans{" "}
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            If you don’t meet the eligibility criteria for an HDFC Bank Home
            Loan, try these strategies:{" "}
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Enhance Your Credit Score:&nbsp;&nbsp;
                </span>
                Pay off existing debts promptly and maintain a strong credit
                repayment history. A score of 750+ increases approval chances.{" "}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Increase Down Payment:&nbsp;&nbsp;
                </span>
                Paying a higher down payment reduces the loan amount required,
                improving approval likelihood.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Add a Co-Applicant:&nbsp;&nbsp;
                </span>
                Including a co-applicant with a stable income or strong credit
                profile can boost your eligibility.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Choose a Longer Tenure:&nbsp;&nbsp;
                </span>
                Opting for a longer repayment period reduces EMIs, enhancing
                your repayment capacity.{" "}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Ensure Document Readiness:&nbsp;&nbsp;
                </span>
                Provide complete and accurate documents to streamline the
                application process.
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Types of Loans and Their Eligibility{" "}
          </div>
          <div style={{ marginTop: "10px" }}>
            <p style={{ fontSize: "16px", textAlign: "start" }}>
              Here’s the table for types of HDFC Home Loans and their
              eligibility criteria based on accurate and fact-checked
              information:
            </p>
          </div>

          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                    }}
                  >
                    Type of Loan
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Applicability
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      borderTopRightRadius: 18,
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "6px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Eligibility Criteria
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    HDFC Regular Home Loan{" "}
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    To purchase or construct a residential property.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Indian residents and NRIs; Salaried or Self-employed
                    individuals aged 21–65 years; minimum income as applicable.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Home Improvement Loan
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    For renovations, repairs, or upgrades to an existing home.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Salaried or Self-employed individuals; property ownership
                    required; basic credit and income criteria.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Home Extension Loan
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    For extending an existing home (adding rooms or extra
                    space).
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Similar to regular home loan criteria; ownership of the
                    property being extended is mandatory.
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Plot Loan
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    For purchasing residential land or a plot.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Salaried or Self-employed; funding up to 75% of plot value;
                    repayment capacity and credit score are crucial factors.
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                    borderBottomRightRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      borderBottomLeftRadius: 18,
                    }}
                  >
                    Balance Transfer Loan
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Transfer an existing home loan to HDFC for lower interest
                    rates.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                      border: "1px solid #ddd",
                      borderBottomRightRadius: 18,
                    }}
                  >
                    Existing home loan; satisfactory repayment track record;
                    creditworthiness evaluation applies.
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      borderBottomLeftRadius: 24,
                    }}
                  >
                    Top-up Loan
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Additional funding for current borrowers over their existing
                    home loan.
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                      borderBottomRightRadius: 24,
                    }}
                  >
                    Available to existing HDFC home loan customers; repayment
                    track record and eligibility for higher funding evaluated.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Frequently asked questions (FAQs) about HDFC Home Loan Eligibility:
          </div>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  fontWeight: 600,
                  borderRadius: 12,
                }}
              >
                What is the minimum credit score required to be eligible for an
                HDFC home loan?
              </AccordionSummary>
              <AccordionDetails>
                A minimum credit score of 700 is typically recommended to
                improve your chances of home loan approval and to secure
                competitive interest rates.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{ fontWeight: 600, borderRadius: 12 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                What are the age criteria for HDFC home loan applicants?
              </AccordionSummary>
              <AccordionDetails>
                Applicants must be at least 21 years old at the time of loan
                application and should not exceed 65 years of age at the time of
                loan maturity.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{ fontWeight: 600, borderRadius: 12 }}
              >
                Is there a minimum income requirement for salaried and
                self-employed individuals?
              </AccordionSummary>
              <AccordionDetails>
                Yes, HDFC requires a minimum monthly income, which varies
                depending on the loan amount and repayment tenure. Salaried
                individuals must show consistent income, while self-employed
                individuals need proof of stable business income.
              </AccordionDetails>
              <AccordionActions></AccordionActions>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{ fontWeight: 600, borderRadius: 12 }}
              >
                Can adding a co-applicant improve my home loan eligibility?
              </AccordionSummary>
              <AccordionDetails>
                Yes, adding a co-applicant, such as a spouse or family member
                with a steady income or a good credit score, can increase your
                overall eligibility and loan sanction amount.
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            className="increase-margines "
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br /> and tailored just for you—apply now to see what you
                qualify for!
              </p>
              <a
                href="https://minemi.ai/home-loan/check-eligibility"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                  backgroundColor: "transparent",
                  marginTop: "2%",
                  cursor: "pointer",
                }}
              >
                <div
                  className="buttom-loan-eligiblity-one"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 40,
                    paddingRight: 40,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                   Check Your Eligibility Now
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeLoanHdfcEligibility;

import React from "react";
import "./Serve.css";
import Accordion from "react-bootstrap/Accordion";
import { FaPlus } from "react-icons/fa";

const Serve = () => {
  const accordionData = [
    {
      title: "A Quick Guide to Balance Transfer",
      content: (
        <p className="m-0 bg-transparent">
          Your loan balance transfer eligibility depends on factors such as your
          repayment history, credit score, existing loan amount, and current
          financial obligations. Typically, a credit score of 700+ and a good
          repayment track record increase your chances of approval. At Minemi,
          we offer a quick and hassle-free way to check your balance transfer
          eligibility instantly, without impacting your credit score. Know your
          eligibility upfront and explore better loan options with lower
          interest rates, helping you save more and manage your finances
          efficiently!
        </p>
      ),
    },
    {
      title: "Features and Benefits of Balance Transfer",
      content: (
        <div className="bg-transparent">
          Transferring your existing loan to a lower interest rate can help you
          save significantly. Key benefits include:
          <p className="my-2 bg-transparent">Key features include:</p>
          <p className="my-1 bg-transparent">
            • Lower Interest Rates compared to your current loan
          </p>
          <p className="m-0 bg-transparent">
            • Reduced EMI Burden, making repayments easier
          </p>
          <p className="my-1 bg-transparent">
            • Flexible Tenure Options to suit your financial goals
          </p>
          <p className="m-0 bg-transparent">
            • Minimal Processing Fees for a hassle-free transfer
          </p>
        </div>
      ),
    },
    {
      title: "Why Check Balance Transfer Eligibility ",
      content: (
        <p className="m-0 bg-transparent">
          Checking your balance transfer eligibility before applying helps you
          understand your chances of approval and prevents multiple hard credit
          inquiries that could impact your credit score. With Minemi, you can
          check your eligibility without affecting your score, allowing you to
          compare lower interest rates from various lenders. This saves time,
          reduces rejection risks, and ensures you choose the best financial
          institution for maximum savings on your loan.
        </p>
      ),
    },
    {
      title: "When Should You Consider ",
      content: (
        <p className="m-0 bg-transparent">
          A balance transfer is ideal when you want to reduce your loan’s
          interest rate, lower your monthly EMI, or get better repayment terms.
          It’s a great option if you have a good credit score and a strong
          repayment history, allowing you to save significantly over time. You
          can also use a balance transfer to consolidate multiple loans into a
          single, more manageable one. Before proceeding, evaluate the total
          savings and any processing fees involved. Use Minemi’s balance
          transfer eligibility checker to find the best options for you.
        </p>
      ),
    },
    {
      title: "Documents You Need for a Balance Transfer",
      content: (
        <div className="bg-transparent">
          When applying for a loan balance transfer, you will need to submit the
          following documents:
          <p className="my-1 bg-transparent">
            • Identity Proof: PAN Card, Aadhaar Card, Passport
          </p>
          <p className="m-0 bg-transparent">
            • Address Proof: Utility bills, Aadhaar, or rental agreements
          </p>
          <p className="my-1 bg-transparent">
            • Income Proof: Latest salary slips or bank statements (for
            salaried), IT returns or audited financials (for self-employed)
          </p>
          <p className="my-1 bg-transparent">
            • Existing Loan Documents: Sanction letter, latest EMI payment
            receipts, loan statement
          </p>
          <p className="m-0 bg-transparent">
            • Property Documents: Agreement to Sale, Title Deed, Approved
            Building Plan
          </p>
        </div>
      ),
    },
    {
      title: " Frequently Asked Questions (FAQs) ",
      content: (
        <div className="m-0 bg-transparent">
          <p className="m-0 bg-transparent">
            1.Need help with debt management? Yes, balance transfers can be an
            effective tool for managing debt by consolidating multiple
            high-interest debts into a single, lower-interest payment. However,
            it’s essential to consider fees, interest rates, and repayment terms
            to ensure it aligns with your financial goals.
          </p>
          <p className="my-2 bg-transparent">
            2.Will a balance transfer always save me money? Not necessarily.
            While balance transfers can reduce interest costs, fees associated
            with the transfer and the duration of the lower interest rate period
            can affect overall savings. It’s crucial to compare the total costs
            before proceeding.
          </p>
          <p className="m-0 bg-transparent">
            3.Can I transfer balances between cards from the same bank?
            Generally, banks do not allow balance transfers between their own
            credit cards. The purpose of a balance transfer is to move debt from
            one lender to another.
          </p>
          <p className="my-2 bg-transparent">
            4.How does a balance transfer affect my credit score? Applying for a
            new credit card or loan for a balance transfer can result in a hard
            inquiry, which may temporarily lower your credit score. However,
            timely repayments on the new account can improve your score over
            time.
          </p>
          <p className="m-0 bg-transparent">
            5.What happens if I miss a payment on my balance transfer? Missing a
            payment can lead to penalties, an increase in the interest rate, and
            a negative impact on your credit score. It’s crucial to make
            payments on time to maintain favorable terms.
          </p>
        </div>
      ),
    },
  ];
  return (
    <>
      <div id="serve">
        <div className="contentCon">
          <div id="header-con">
            <p className="bg-transparent">We swear to serve you with ...</p>
          </div>
          <div id="content" className="bg-transparent">
            <div className="d-flex flex-wrap w-100 bg-transparent">
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/loan_eligibility.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/no_empact.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Impact on credit Score</p>
              </div>

              <div className="plus-icon-con second-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/interest_rate.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Best Interest Rate Offer</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/no_calls.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Calls From Us</p>
              </div>

              <div className="plus-icon-con fourth-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Data Security Guarantee</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>
            </div>

            <div id="line-breaker"></div>

            <div className="accordion-con bg-transparent">
              <Accordion className="bg-transparent">
                {accordionData.map((item, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body
                      style={{
                        visibility: "visible",
                        backgroundColor: "transparent",
                      }}
                    >
                      {item.content}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Serve;

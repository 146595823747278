import { useEffect, useState, useMemo } from "react";

export function StatusExplanation({ name }) {
  const [data, setData] = useState({
    title: "",
    description: "",
    statuses: [],
    footer: { text: "", button: { text: "", link: "" } },
  });

  const idbiData = useMemo(
    () => ({
      title: "Understanding Your IDBI Bank Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by IDBI Bank. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Prepare for disbursement to your bank account",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Discuss with the bank if you need clarification",
            "Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by IDBI Bank. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            "Address any queries from the legal team promptly",
            " Follow up with the seller if additional documents are needed",
            "Track the status through IDBI GO Mobile app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            " Ensure property access for the evaluator",
            " Have construction plans and approvals ready if needed",
            "Address any structural concerns highlighted",
            " Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by IDBI Bank. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "  Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Address the concerns (credit score, income, property title)",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "#loan-status-checker",
        },
      },
    }),
    []
  );
  const lichousingData = useMemo(
    () => ({
      title: "Understanding Your LIC Housing Finance Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by LIC Housing Finance. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Prepare for disbursement to your bank account",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Discuss with the bank if you need clarification",
            "Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by LIC Housing Finance. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            "Address any queries from the legal team promptly",
            " Follow up with the seller if additional documents are needed",
            "Track the status through LIC HFL HOME app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            " Ensure property access for the evaluator",
            " Have construction plans and approvals ready if needed",
            "Address any structural concerns highlighted",
            " Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by LIC Housing Finance. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "  Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Address the concerns (credit score, income, property title)",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "#loan-status-checker",
        },
      },
    }),
    []
  );
  const icicihousingData = useMemo(
    () => ({
      title: "Understanding Your ICICI Home Finance Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by ICICI Home Finance. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Prepare for disbursement to your bank account",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Discuss with the bank if you need clarification",
            "Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by ICICI Home Finance. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            "Address any queries from the legal team promptly",
            " Follow up with the seller if additional documents are needed",
            "Track the status through ICICI iMobile app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            " Ensure property access for the evaluator",
            " Have construction plans and approvals ready if needed",
            "Address any structural concerns highlighted",
            " Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by ICICI Home Finance. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "  Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Address the concerns (credit score, income, property title)",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "#loan-status-checker",
        },
      },
    }),
    []
  );
  const pnbhousingData = useMemo(
    () => ({
      title: "Understanding Your PNB Home Finance Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by PNB Home Finance. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Prepare for disbursement to your bank account",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Discuss with the bank if you need clarification",
            "Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by PNB Home Finance. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            "Address any queries from the legal team promptly",
            " Follow up with the seller if additional documents are needed",
            "Track the status through PNB iMobile app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            " Ensure property access for the evaluator",
            " Have construction plans and approvals ready if needed",
            "Address any structural concerns highlighted",
            " Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by PNB Home Finance. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "  Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Address the concerns (credit score, income, property title)",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "#loan-status-checker",
        },
      },
    }),
    []
  );
  const sbihousingData = useMemo(
    () => ({
      title: "Understanding Your SBI Home Finance Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by SBI Home Finance. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Prepare for disbursement to your bank account",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Discuss with the bank if you need clarification",
            "Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by SBI Home Finance. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            "Address any queries from the legal team promptly",
            " Follow up with the seller if additional documents are needed",
            "Track the status through PNG iMobile app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            " Ensure property access for the evaluator",
            " Have construction plans and approvals ready if needed",
            "Address any structural concerns highlighted",
            " Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by SBI Home Finance. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "  Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Address the concerns (credit score, income, property title)",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "#loan-status-checker",
        },
      },
    }),
    []
  );
  const adityahousingData = useMemo(
    () => ({
      title: "Understanding Your Aditya Birla  Finance Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by Aditya Birla Finance. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Prepare for disbursement to your bank account",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Discuss with the bank if you need clarification",
            "Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by Aditya Birla Finance. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            "Address any queries from the legal team promptly",
            " Follow up with the seller if additional documents are needed",
            "Track the status through Aditya Birla iMobile app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            " Ensure property access for the evaluator",
            " Have construction plans and approvals ready if needed",
            "Address any structural concerns highlighted",
            " Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by Aditya Birla Finance. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "  Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Address the concerns (credit score, income, property title)",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "#loan-status-checker",
        },
      },
    }),
    []
  );
  const lthousingData = useMemo(
    () => ({
      title: "Understanding Your L&T Finance Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by L&T Finance. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Prepare for disbursement to your bank account",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Discuss with the bank if you need clarification",
            "Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by L&T Finance. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            "Address any queries from the legal team promptly",
            " Follow up with the seller if additional documents are needed",
            "Track the status through L&T Finance iMobile app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            " Ensure property access for the evaluator",
            " Have construction plans and approvals ready if needed",
            "Address any structural concerns highlighted",
            " Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by L&T Finance. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "  Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Address the concerns (credit score, income, property title)",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "#loan-status-checker",
        },
      },
    }),
    []
  );

  useEffect(() => {
    if (name === "checkidbihousingbankstatus") {
      setData(idbiData);
    } else if (name === "checklichousingbankstatus") {
      setData(lichousingData);
    } else if (name === "checkicicihousingbankstatus") {
      setData(icicihousingData);
    } else if (name === "checkpnbhousingbankstatus") {
      setData(pnbhousingData);
    } else if (name === "checksbihousingbankstatus") {
      setData(sbihousingData);
    } else if (name === "checkadityahousingbankstatus") {
      setData(adityahousingData);
    } else if (name === "checklthousingbankstatus") {
      setData(lthousingData);
    } else {
      setData({
        title: "",
        description: "",
        statuses: [],
        footer: { text: "", button: { text: "", link: "" } },
      });
    }
  }, [
    name,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    sbihousingData,
    adityahousingData,
    lthousingData,
  ]);

  return (
    <section className="py-2 md:py-16 bg-white border-b border-gray-200">
      <div className="container px-4 md:px-6">
        <div className="text-center mb-10 bg-transparent">
          <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl text-primary-purple bg-transparent ">
            {data.title}
          </h2>
          <p className="text-dark-grey mt-2 max-w-2xl mx-auto bg-transparent text-center">
            {data.description}
          </p>
        </div>

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 max-w-6xl mx-auto bg-transparent">
          {data.statuses.map((d, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-sm border border-gray-200"
            >
              <div className="h-12 w-12 rounded-full bg-green-100 flex items-center justify-center mb-4">
                {d.icon}
              </div>
              <h3 className="text-xl font-bold text-primary-purple mb-2">
                {d.name}
              </h3>
              <p className="text-dark-grey mb-4 bg-transparent">
                {d.description}
              </p>
              <div
                className="bg-light-bg px-4 pt-2 rounded-md border border-gray-100"
                style={{ backgroundColor: "#ebe8fb" }}
              >
                <h4 className="font-bold text-primary-purple text-sm mb-2 bg-transparent">
                  Next Steps:
                </h4>
                <ul className="text-sm text-dark-grey space-y-2 bg-transparent p-0">
                  {d.nextSteps.map((step, index) => (
                    <li key={index} className="bg-transparent">
                      • {step}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-10 text-center bg-transparent">
          <p className="text-dark-grey mb-3 bg-transparent text-center">
            {data.footer.text}
          </p>
          <a
            href="/trackloanstatus"
            className="inline-flex h-12 items-center justify-center rounded-md bg-teal px-8 text-sm font-medium text-white transition-colors hover:bg-primary-purple focus:outline-none focus:ring-2 focus:ring-primary-purple  bg-[#3b868b] hover:bg-[#2b1b61]"
          >
            {data.footer.button.text}
          </a>
        </div>
      </div>
    </section>
  );
}

import React, { useState, useEffect } from "react";
import HomeLogo from "../../Assets/Images/HomeLogo.webp";
import userLogo from "../../Assets/Images/userLogo.webp";
import Credit from "../../Assets/Images/Credit.webp";
import ApexChart from "./ApexChart";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function Loan() {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [emi, setEmi] = useState([]);
  const [data1, setData] = useState([]);
  const location = useLocation();
  const { state } = location;
  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [name3, setName3] = useState("");
  const [name4, setName4] = useState("");
  const [series1, setSeries1] = useState("");
  const [series2, setSeries2] = useState("");
  const [series3, setSeries3] = useState("");
  const [series4, setSeries4] = useState("");
  const HandleClick1 = () => {
    const token = localStorage.getItem("token");
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/loanOverview`,
      headers: {
        Authorization: `Bearer  ${token}`,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        setName1(response.data.data.loanChart[0].loan_Category);
        setName2(response.data.data.loanChart[1].loan_Category);
        setName3(response.data.data.loanChart[2].loan_Category);
        setName4(response.data.data.loanChart[3].loan_Category);
        setSeries1(response.data.data.loanChart[0].LoanPercentage);
        setSeries2(response.data.data.loanChart[1].LoanPercentage);
        setSeries3(response.data.data.loanChart[2].LoanPercentage);
        setSeries4(response.data.data.loanChart[3].LoanPercentage);
        setAmount(response.data.data.loanOutstanding);
        setData(response.data.data.loanDetail);
        setEmi(response.data.data.loanChart);
      })
      .catch(function (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  useEffect(() => {
    HandleClick1();
  }, []);
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-IN").format(number);
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/");
    } else {
    }
  }, []);
  return (
    <>
  {
      data1.length===0 || emi.length===0 ?
    <Skeleton count={8} style={{height:40,marginTop:20}} /> // Five-line loading skeleton
:

  <div style={{ marginTop: "20px" }}>
     
      <ApexChart outstanding={formatNumberWithCommas(amount)}  series1={series1} series2={series2} series3={series3} series4={series4}/>

      <div className=" container flex flex-wrap mt-2 mb-12 px-4 sm:px-6 lg:px-8 sm:gap-2 lg:gap-4 " style={{justifyContent:'space-between',gap:16}}>
      {data1.map((d, index) => (
        <div
          key={index}
          className=" sm:w-[calc(50%-8px)] lg:w-[calc(33.33%-11px)] xl:w-[390px] h-auto sm:h-[88px] rounded-lg bg-white shadow-sm"
        >
          <div className="flex justify-between items-center p-4 h-full" style={{backgroundColor:'transparent'}}>
            <div className="flex items-start gap-3" style={{backgroundColor:'transparent',alignItems:'center'}}>
              <div className="rounded-full bg-purple-100 p-2">
                <img
                  src={d.Account_Type === "05" ?userLogo :Credit}
                  alt=""
                  width={40}
                  height={40}
                  className="rounded-full object-cover"
                  style={{backgroundColor:'transparent'}}
                />
              </div>
              <div style={{backgroundColor:'transparent'}}>
                <div className="text-sm font-bold text-gray-900" style={{backgroundColor:'transparent'}}>
                  {d.loanType === "PL" ? "Personal Loan" : d.loanName.slice(0, 13)}
                </div>
                <div className="text-xs text-gray-500 " style={{backgroundColor:'transparent'}}>{d.Subscriber_Name.slice(0, 27).toUpperCase()}</div>
              </div>
            </div>
            {d.Current_Balance !== 0 && (
              <div className="text-center" style={{backgroundColor:'transparent'}}>
                <div className="w-[110px] h-10 rounded-full bg-purple-100 flex flex-col justify-center items-center text-xs font-semibold">
                  <span style={{backgroundColor:'transparent'}}>Outstanding</span>
                  <span style={{backgroundColor:'transparent'}}>₹ {formatNumberWithCommas(d.Current_Balance)}</span>
                </div>
                {emi.map((emiData, emiIndex) => (
                  <React.Fragment key={emiIndex}>
                    {emiData.LoanEmi !== 0 && (
                      <div className="text-[10px] font-medium text-gray-900 mt-1" style={{backgroundColor:'transparent'}}>Current EMI 10,000 p/m</div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
  }
    </>
  );
}

export default Loan;

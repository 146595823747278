import React, { useState, useEffect, useContext } from "react";
import money from "../../Assets/Images/money.webp";
import creditScore from "../../Assets/Images/credit score.webp";
import Vector from "../../Assets/Images/Vector (5).webp";
import Group from "../../Assets/Images/Group (3).webp";
import Bill from "../../Assets/Images/Bill Get.webp";
import Ratio from "../../Assets/Images/Ratio.webp";
import { useNavigate } from "react-router-dom";
import "./Personalloan.css";
import Grid from "@mui/material/Grid";
import Item from "@mui/material/Paper"; // Assuming Item is a Paper component
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "@mui/material";
import MetadataContext from "../MetadataContext";

const GoldLoan = () => {
  const navigate = useNavigate();

  const isMediumScreen = useMediaQuery("(max-width:756px) ");
  const isVerySmallScreen = useMediaQuery("(max-width:400px)");

  const [newsData, setNewsData] = useState([]);
  const [manageData, setManageData] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const blogs = [
    {
      id: 1,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/GoldLoanBalanceTransfer-1737202132806.webp",
      title:
        "Gold Loan Balance Transfer: Save on Interest Rates and EMI Payments",
      writer: "Aditi Malhotra",
      description:
        "Discusses the benefits and steps involved in transferring an existing gold loan to another lender.",
      naviLink: "/loanpage/gold-loan/gold-loan-balance-transfer",
    },
    {
      id: 2,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/TopUpYourGoldLoan-1737202361673.webp",
      title: "How to Top Up Your Gold Loan for Additional Financial Needs",
      writer: "Aditi Malhotra",
      description:
        "Explains the process of increasing your loan amount without closing the current loan.",
      naviLink:
        "/loanpage/gold-loan/how-to-top-up-your-gold-loan-for-additional-financial-needs",
    },
    {
      id: 3,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/PrepaymentandForeclosureofGoldLoans-1737202471677.webp",
      title:
        "Prepayment and Foreclosure of Gold Loans: A Guide to Save on Interest",
      writer: "Vikalp Shukla",
      description:
        "Guides borrowers on how to minimize costs when repaying their gold loan early.",
      naviLink: "/loanpage/gold-loan/prepayment-and-foreclosure-of-gold-loans",
    },
    {
      id: 4,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/GoldLoanRenewalProcess-1737202582216.webp",
      title: "Gold Loan Renewal Process: What You Need to Know",
      writer: "Vikalp Shukla",
      description:
        "Covers the steps and requirements to renew an existing gold loan after the tenure ends.",
      naviLink: "/loanpage/gold-loan/gold-loan-renewal-process",
    },
    {
      id: 5,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/ManagingGoldLoanEMIs-1737202665655.webp",
      title: "Managing Gold Loan EMIs: Tips to Avoid Defaults",
      writer: "Vikalp Shukla",
      description:
        "Practical advice for planning repayments and using calculators for EMI management.",
      naviLink: "/loanpage/gold-loan/managing-gold-loan-emis",
    },
    {
      id: 6,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/GoldLoanClosureProcess-1737202759323.webp",
      title:
        "Gold Loan Closure Process: How to Retrieve Your Collateral Safely",
      writer: "Aditi Malhotra",
      description:
        "Detailed guide on completing the loan tenure and securing the pledged gold.",
      naviLink: "/loanpage/gold-loan/gold-loan-closure-process",
    },
  ];

  const fetchLatestNews = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/news`,
    };

    axios
      .request(options)
      .then(function (response) {
        setNewsData(response.data.data.latestnews);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchLatestNews();
  }, []);

  const fetchManageLoans = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/manageloan`,
    };

    axios
      .request(options)
      .then(function (response) {
        setManageData(response.data.data.manageloan);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchManageLoans();
  }, []);

  const homeLoanItems = manageData.filter((d) => d.category === "Gold Loan");
  const visibleItems = showAll
    ? homeLoanItems.slice(1)
    : homeLoanItems.slice(1, 4);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Struggling for Funds? Get Instant Gold Loans with Minemi",
      pageDescription:
        "Solve your financial needs with Minemi’s secure gold loans. Enjoy quick approvals, flexible repayment plans, and low-interest rates. Apply now",
      pageKeywords:
        "gold loans,easy gold loans, secure gold loans, gold loan approvals, low interest gold loans, flexible repayment gold loans, gold loan offers, quick gold loans, instant gold loans, gold loan eligibility",
      canonicalUrl: "https://minemi.ai/loanpage/gold-loan",
    });
  }, [setMetadata]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 48,
            paddingBottom: 80,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Gold Loan
              </Link>
            </p>
          </div>
          <h1
            className="homeloan-heading"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Gold Loan{" "}
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 500,
              fontSize: 24,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "30px",
            }}
          >
            Unlock the value of your gold with easy and secure gold loans. Enjoy
            quick approvals, low interest rates, and flexible repayment options
            tailored to your needs.
          </p>
        </div>
        <div
          className="make-wrap"
          style={{
            marginTop: 56,
            marginLeft: "4%",
            marginRight: "4%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 16,
          }}
        >
          <div
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 10,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              width: 290,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/loanpage/gold-loan/gold-loan-eligibility-criteria");
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                backgroundColor: "transparent",
                fontSize: 16,
              }}
            >
              Gold Loan <br /> Eligibility Criteria{" "}
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              Discover Your Gold Loan Eligibility in Seconds!
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src={money}
                  alt="eligibility"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  backgroundColor: "transparent",
                  marginTop: 8,
                }}
              >
                Unlock the Full Financial Potential of Your Treasured Gold
              </p>
            </p>
          </div>
          <div
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 12,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              width: 290,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/loanpage/gold-loan/gold-loan-interest-rates");
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                backgroundColor: "transparent",
                fontSize: 16,
              }}
            >
              Gold Loan Interest Rates in India
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              2025's Top Gold Loan Rates – Compare Now
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src={Ratio}
                  alt="interest rates"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  backgroundColor: "transparent",
                  marginTop: 8,
                }}
              >
                Secure the Best Gold Loan Rate and Save Big on Interest
              </p>
            </p>
          </div>
          <div
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 12,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              width: 290,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/loanpage/gold-loan/gold-loan-procedure");
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                backgroundColor: "transparent",
                fontSize: 16,
              }}
            >
              Gold Loan Procedure
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              Your Step-by-Step Guide to Easy Gold Loans
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src={Group}
                  alt="procedure"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  backgroundColor: "transparent",
                  marginTop: 8,
                }}
              >
                Simplify Your Gold Loan Process and Get Funded Quickly
              </p>
            </p>
          </div>
          <div
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 12,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              width: 290,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/loanpage/gold-loan/gold-loan-providers-in-india");
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                backgroundColor: "transparent",
                fontSize: 16,
              }}
            >
              Top Gold Loan Providers in India
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              Top Gold Loan Providers in India – Compare Offers
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src={creditScore}
                  alt="loan providers"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  backgroundColor: "transparent",
                  marginTop: 8,
                }}
              >
                Find the Perfect Loan with Tailored Rates, Amounts, and Features
              </p>
            </p>
          </div>
          <div
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 12,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              width: 290,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/loanpage/gold-loan/types-of-gold-loan");
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                backgroundColor: "transparent",
                fontSize: 16,
              }}
            >
              Explore Different Types of Gold Loans
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              Gold Loans for Every Financial Need
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src={Vector}
                  alt="financial need"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  backgroundColor: "transparent",
                  marginTop: 8,
                }}
              >
                Explore Customized Gold Loan Options for a Stress-Free Future
              </p>
            </p>
          </div>
          <div
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 12,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              width: 290,
              cursor: "pointer",
            }}
          >
            <a href="https://minemi.ai/calculators/emi-calculators/gold-loan-emi-calculator">
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  color: "#1F1F1F",
                  backgroundColor: "transparent",
                  fontSize: 16,
                }}
              >
                Gold Loan <br /> EMI Calculator
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  backgroundColor: "transparent",
                  marginTop: 8,
                  fontSize: 13,
                }}
              >
                Calculate your monthly EMI.
              </p>
              <div
                style={{
                  backgroundColor: "transparent",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 32,
                  marginBottom: 19,
                }}
              >
                <div
                  style={{
                    width: 56,
                    height: 56,
                    backgroundColor: "transparent",
                  }}
                >
                  <img
                    src={Bill}
                    alt="emi"
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#846CA6",
                  backgroundColor: "transparent",
                  marginTop: 8,
                  fontSize: 13,
                }}
              >
                {" "}
                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Archivo",
                    fontWeight: 400,
                    color: "#8A8A8A",
                    backgroundColor: "transparent",
                    marginTop: 8,
                  }}
                >
                  {" "}
                  Use our car loan EMI calculator to estimate monthly
                  repayments.
                </p>
              </p>
            </a>
          </div>
        </div>
        <div
          style={{
            maginTop: 64,
            marginLeft: "4%",
            marginRight: "4%",
            marginTop: 32,
          }}
        >
          <h2
            style={{
              color: "#59266D",
              fontWeight: 600,
              fontFamily: "Archivo",
              fontSize: 28,
              lineHeight: 1.2,
            }}
          >
            Qualify for a Gold Loan
          </h2>
        </div>
        <div
          style={{ marginLeft: "4%", marginRight: "4%", marginTop: 32 }}
          className="loan-list-container px-0"
        >
          {blogs?.map((blog) => (
            <div
              className="loan-container"
              onClick={() => navigate(blog.naviLink)}
              key={blog.id}
            >
              <a href={blog.link}>
                <img
                  src={blog.image}
                  alt={blog.heading}
                  loading="lazy"
                  className="loan-image d-none d-md-block"
                />
                <h3
                  className="loan-heading"
                  style={{
                    fontFamily: "Archivo",
                    fontWeight: 600,
                    fontSize: 18,
                    display: "-webkit-box",
                    WebkitLineClamp: 2, // Limit to 2 lines
                    WebkitBoxOrient: "vertical", // Required for webkit-line-clamp
                    overflow: "hidden", // Hide overflowing text
                    textOverflow: "ellipsis", // Add ellipsis
                  }}
                >
                  {blog.title}
                </h3>
                <p
                  style={{
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    color: "#8a8a8a",
                    fontSize: 14,
                  }}
                  className="mt-2 mb-1"
                >
                  by {blog.writer}
                </p>
                <div
                  className="loan-subheading"
                  dangerouslySetInnerHTML={{
                    __html: blog.description,
                  }}
                  style={{
                    fontFamily: "Archivo",
                    fontWeight: 400,
                    fontSize: 18,
                    color: "#B1B1B1",
                    display: "-webkit-box",
                    WebkitLineClamp: 2, // Limit to 2 lines
                    WebkitBoxOrient: "vertical", // Required for webkit-line-clamp
                    overflow: "hidden", // Hide overflowing text
                    textOverflow: "ellipsis", // Add ellipsis
                  }}
                />
              </a>
            </div>
          ))}
        </div>
        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 32 }}>
          <h3
            style={{
              color: "#59266D",
              fontWeight: 600,
              fontFamily: "Archivo",
              fontSize: 28,
              lineHeight: 1,
            }}
          >
            Manage your loan
          </h3>
          <div style={{ marginTop: 40, marginBottom: 32 }}>
            <Grid container spacing={5}>
              {manageData
                .filter((d) => d.category === "Gold Loan")
                .slice(0, 1) // Adjust slice range based on your needs
                .map((d) => {
                  return (
                    <Grid item xs={12} md={6}>
                      <Item
                        style={{
                          border: 0,
                          boxShadow: "0px 0px",
                          backgroundColor: "#f8f8f8",
                        }}
                      >
                        <Link
                          to={`/loanpage/gold-loan/manageloan_detail/${d.mslug}`}
                          state={{
                            mslug: d?.mslug,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 8,
                            }}
                          >
                            <div style={{ height: "auto" }}>
                              <img
                                src={d.image}
                                alt={d.title}
                                loading="lazy"
                                style={{
                                  width: "100%",
                                  height: isVerySmallScreen ? "100%" : "400px",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 8,
                              }}
                            >
                              <p
                                className="manage_latest_link"
                                style={{ fontSize: 18, fontWeight: 400 }}
                              >
                                {d?.title}
                              </p>
                              <p
                                style={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "grey",
                                }}
                              >
                                by {d?.author}
                              </p>

                              <p
                                style={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  lineHeight: 1.3,
                                  color: "grey",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: d?.description,
                                }}
                              ></p>
                            </div>
                          </div>
                        </Link>
                      </Item>
                    </Grid>
                  );
                })}

              <Grid item xs={12} md={6}>
                <Item
                  style={{
                    border: 0,
                    boxShadow: "0px 0px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 32,
                    backgroundColor: "#f8f8f8",
                  }}
                >
                  {visibleItems.map((d) => (
                    <div key={d.id}>
                      <Link
                        to={`/loanpage/gold-loan/manageloan_detail/${d.mslug}`}
                        state={{
                          mslug: d.mslug,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 12,
                          }}
                        >
                          <div
                            style={{
                              minWidth: isVerySmallScreen
                                ? 80
                                : isMediumScreen
                                ? 120
                                : 150,
                              maxWidth: 190,
                            }}
                          >
                            <img
                              src={d.image}
                              alt={d.title}
                              loading="lazy"
                              style={{
                                width: "100%",
                                height: isVerySmallScreen
                                  ? "50px"
                                  : isMediumScreen
                                  ? "80px"
                                  : "110px",
                                objectFit: "cover",
                                borderRadius: "5px",
                                objectPosition: "center",
                              }}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 3,
                            }}
                          >
                            <p
                              className="manage_latest_link m-0 pt-0 pt-sm-3"
                              style={{ fontSize: 18, fontWeight: 400 }}
                            >
                              {d.title}
                            </p>
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "grey",
                              }}
                            >
                              by {d.author}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                  {homeLoanItems.length > 4 && (
                    <button
                      onClick={() => {
                        navigate("/loanpage/gold-loan/manageloan_detail");
                      }}
                      style={{
                        alignSelf: "center",
                        padding: "8px 16px",
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#007BFF",
                        backgroundColor: "transparent",
                        border: "1px solid #007BFF",
                        borderRadius: 5,
                        cursor: "pointer",
                      }}
                    >
                      See More
                    </button>
                  )}
                </Item>
              </Grid>
            </Grid>
          </div>
        </div>
        <div
          style={{
            marginLeft: "4%",
            marginRight: "4%",
            marginTop: 40,
            position: "relative",
          }}
        >
          <h3
            style={{
              color: "#59266D",
              fontWeight: 600,
              fontFamily: "Archivo",
              fontSize: 28,
              lineHeight: 1,
            }}
          >
            The Latest
          </h3>
          <Grid container spacing={4} style={{ marginTop: "2%" }}>
            {newsData
              .filter((item) => item.category === "Gold Loan")
              .map((item) => (
                <Grid item xs={12} sm={6} key={item.id}>
                  {item.category === "Gold Loan" && (
                    <Item
                      style={{
                        boxShadow: "0px 0px",
                        marginBottom: 10,
                        backgroundColor: "#f8f8f8",
                      }}
                    >
                      <Link
                        to={`/loanpage/gold-loan/news_detail/${item.nslug}`}
                        state={{
                          nslug: item.nslug,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 15,
                            alignItems: "start",
                          }}
                        >
                          <div
                            style={{
                              // width: "100%",
                              minWidth: isVerySmallScreen
                                ? 80
                                : isMediumScreen
                                ? 120
                                : 150,
                              maxWidth: 190,
                            }}
                          >
                            <img
                              src={item.image}
                              alt={item.title}
                              loading="lazy"
                              style={{
                                width: "100%",
                                // maxWidth: "250px",
                                height: isVerySmallScreen
                                  ? "50px"
                                  : isMediumScreen
                                  ? "80px"
                                  : "110px",
                                objectFit: "cover",

                                borderRadius: "5px",
                                objectPosition: "center",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 5,
                              alignSelf: "center",
                            }}
                          >
                            <p
                              style={{ fontSize: 18, fontWeight: 400 }}
                              className="manage_latest_link m-0"
                            >
                              {item.title}
                            </p>
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "grey",
                              }}
                              className="m-0"
                            >
                              by {item.author}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </Item>
                  )}
                </Grid>
              ))}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default GoldLoan;

import React, { useState, useEffect } from "react";
import { Info } from "lucide-react";
import RightLoan from "./RightLoan";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// FAQ data (unchanged)
const faqs = [
  {
    question: "Will applying for a loan affect my credit score?",
    answer:
      "Applying for a loan may result in a temporary dip in your credit score due to a hard inquiry. However, this effect is usually minimal and short-lived. Responsible loan management can actually improve your credit score over time.",
  },
  {
    question: "How were these loan offers recommended for me?",
    answer:
      "Our AI-powered system analyzes your financial profile, credit history, and stated loan needs to match you with the most suitable loan options. We consider factors like your income, credit score, existing debts, and the purpose of the loan to provide personalized recommendations.",
  },
  {
    question: "What documents are required to apply for a loan?",
    answer:
      "Common documents include proof of identity (e.g., Aadhaar, PAN card), proof of address, income proof (salary slips or tax returns), bank statements, and sometimes additional documents specific to the loan type. The exact requirements may vary by lender and loan type.",
  },
  {
    question: "What happens if my application is rejected?",
    answer:
      "If your application is rejected, we'll provide you with the reasons for the decision. You can use this information to improve your financial profile or explore alternative loan options. Remember, you can always reapply after addressing the issues or consider different loan products that might be more suitable for your current situation.",
  },
  {
    question: "How do I know which loan is best for me?",
    answer:
      "The best loan for you depends on your specific needs and financial situation. Consider factors like interest rates, loan tenure, processing fees, and your ability to repay. Our recommendations are personalized, but we encourage you to carefully review each option and choose the one that aligns best with your financial goals and repayment capacity.",
  },
];

export default function LoanRecommendationPage() {
  const [matchdata, setMatchdata] = useState([]);
  const [overviewData, setOverviewData] = useState([]);
  const [loanSummaryData, setLoanSummaryData] = useState({});
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery("(max-width:576px)");

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    const fectOverViewData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SECRET_KEY}/loanOverview`,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response?.data && response.status === 200) {
            setOverviewData(response?.data.data);
          }
        } catch (error) {
          console.log("error in overview api", error);
        }
      }
    };
    fectOverViewData();
  }, []);
  useEffect(() => {
    const fatchCreditSummary = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SECRET_KEY}/credit_summary/1`,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response?.data && response.status === 200) {
            setLoanSummaryData(response?.data.data);
          }
        } catch (error) {
        }
      }
    };
    fatchCreditSummary();
  }, []);

  useEffect(() => {
    const matchDataResponse = localStorage.getItem("matchdata");
    const parsedData = JSON.parse(matchDataResponse);
    if (parsedData) {
      setMatchdata(parsedData);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="min-h-screen text-[#311664] "
      style={{ backgroundColor: "#f8f8f8" }}
    >
      <header
        className="pt-4 pt-sm-5 pb-4 pb-sm-3 px-3 px-sm-4 text-center"
        style={{ backgroundColor: "#f8f8f8" }}
      >
        <h1
          className="text-center text-sm-center mb-2 sm:mb-4"
          style={{
            color: "#311664",
            fontFamily: "Archivo",
            fontSize: isSmallScreen ? "25px" : "36px",
            lineHeight: isSmallScreen ? "30px" : "40px",
            fontWeight: "700",
          }}
        >
          Great! Here Are Your Recommended Loans
        </h1>
        <p
          className="text-center text-sm-center mb-2 mb-sm-5  max-w-3xl mx-auto bg-transparent"
          style={{
            color: "#311664",
            fontFamily: "Archivo",
            fontSize: isSmallScreen ? "14px" : "20px",
            lineHeight: isSmallScreen ? "22px" : "28px",
            fontWeight: "400",
          }}
        >
          Based on your profile, we’ve shortlisted the best loan options for
          you. Compare, choose, and apply with confidence.
        </p>
      </header>

      <main
        className="container mx-auto px-4 pt-0 pb-8 sm:pb-12 mb-5 "
        style={{ display: "contents" }}
      >
        <RightLoan overview={overviewData} loanSummary={loanSummaryData} />

        <h2
          className="text-xl sm:text-2xl px-3 font-semibold text-center my-4 sm:my-8 text-[#59266d] "
          style={{
            fontFamily: "Archivo",
            fontSize: isSmallScreen ? "18px" : "24px",
            lineHeight: isSmallScreen ? "24px" : "32px",
            fontWeight: "600",
          }}
        >
          The Right Loan, Tailored for You – Compare, Choose, and Apply with
          Confidence.
        </h2>

        <div className="space-y-4 sm:space-y-8 max-w-3xl mx-3 mx-md-auto bg-transparent">
          {matchdata?.map((loan, index) => (
            <div
              key={index}
              className="bg-[#f7f5ff] border-1 border-[#d8d2ff] rounded-lg "
            >
              <div className="bg-white border-b border-[#d8d2ff] p-3 rounded-t-lg">
                <h3 className="text-xl sm:text-2xl text-[#59266d] m-0">
                  {loan["Loan Type"]}
                </h3>
              </div>
              <div className="px-2 px-sm-4 p-4  space-y-4 bg-transparent">
                <div className="bg-transparent">
                  <h4 className="font-semibold text-sm m-0 mb-sm-2 bg-transparent">
                    Loan Details
                  </h4>
                  <div className="d-flex flex-column gap-sm-2 mt-2 text-xs sm:text-sm bg-transparent text-start ">
                    <div
                      className="bg-transparent d-flex flex-row gap-3  align-items-start w-full sm:w-[50%] justify-content-start"
                      style={{ width: "100%" }}
                    >
                      <p
                        className="bg-transparent text-start"
                        style={{
                          fontSize: isSmallScreen ? "12.2px" : "14px",
                          backgroundColor: "transparent",
                        }}
                      >
                        <strong style={{ backgroundColor: "transparent" }}>
                          Loan Amount:
                        </strong>{" "}
                        {loan["Minimum Loan Amount"]} -{" "}
                        {loan["Maximum Loan Amount"]}
                      </p>
                      <p
                        className="bg-transparent text-start align-top m-0"
                        style={{
                          alignSelf: "start",
                          fontSize: isSmallScreen ? "12.2px" : "14px",
                          backgroundColor: "transparent",
                        }}
                      >
                        <strong style={{ backgroundColor: "transparent" }}>
                          Interest Rate:
                        </strong>{" "}
                        {loan["Interest Rate"]}%
                      </p>
                    </div>

                    <div
                      className="bg-transparent text-start d-flex flex-row gap-3  mt-1 w-full sm:w-[50%]"
                      style={{ width: "100%" }}
                    >
                      <p
                        className="bg-transparent text-start"
                        style={{ fontSize: isSmallScreen ? "12.2px" : "14px" }}
                      >
                        <strong style={{ backgroundColor: "transparent" }}>
                          Processing Fee:
                        </strong>{" "}
                        {loan["Processing Fees"]}
                      </p>
                      <p
                        className="bg-transparent text-start m-0"
                        style={{
                          alignSelf: "start",
                          fontSize: isSmallScreen ? "12.2px" : "14px",
                        }}
                      >
                        <strong style={{ backgroundColor: "transparent" }}>
                          Tenure:
                        </strong>{" "}
                        {loan["Minimum Tenure"]} - {loan["Maximum Tenure"]}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-transparent">
                  <h4 className="font-semibold text-sm m-0 mb-sm-2 bg-transparent">
                    Eligibility
                  </h4>
                  <div className="d-flex flex-column gap-sm-2 mt-2 text-xs sm:text-sm bg-transparent text-start">
                    <div
                      className="bg-transparent d-flex flex-row gap-3  align-items-start w-full sm:w-[50%] justify-content-start"
                      style={{ width: "100%" }}
                    >
                      <p
                        className="bg-transparent text-start m-0"
                        style={{
                          fontSize: isSmallScreen ? "12.2px" : "14px",
                          backgroundColor: "transparent",
                          alignSelf: "self-start",
                        }}
                      >
                        <strong style={{ backgroundColor: "transparent" }}>
                          CIBIL Score:
                        </strong>{" "}
                        {loan["Credit Score Requirement"]}
                      </p>
                      <p
                        className="bg-transparent text-start m-0"
                        style={{
                          fontSize: isSmallScreen ? "12.2px" : "14px",
                          alignSelf: "self-start",
                        }}
                      >
                        <strong style={{ backgroundColor: "transparent" }}>
                          Loan to Value Ratio:
                        </strong>{" "}
                        {loan["Loan to Value Ratio"]}
                      </p>
                    </div>
                    <div
                      className="bg-transparent d-flex flex-row gap-3 mt-1 w-full sm:w-[50%]"
                      style={{ width: "100%" }}
                    >
                      <p
                        className="bg-transparent text-start"
                        style={{
                          alignSelf: "start",
                          fontSize: isSmallScreen ? "12.2px" : "14px",
                        }}
                      >
                        <strong style={{ backgroundColor: "transparent" }}>
                          Min. Income:
                        </strong>{" "}
                        {loan["Minimum Income"]}
                      </p>
                      <p
                        className="bg-transparent text-start m-0"
                        style={{
                          alignSelf: "start",
                          fontSize: isSmallScreen ? "12.2px" : "14px",
                        }}
                      >
                        <strong style={{ backgroundColor: "transparent" }}>
                          Security Required:
                        </strong>{" "}
                        {loan["Security Required"]}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-[#311664] rounded-lg p-3 sm:p-4 shadow-md ">
                  <h4 className="font-bold text-sm sm:text-base mb-2 text-white bg-transparent">
                    Why We Recommend This
                  </h4>
                  <p className="text-xs sm:text-sm text-[#f7f5ff] bg-transparent text-start">
                    {loan.reason}
                  </p>
                </div>
              </div>
              <div className="w-100 d-flex flex-column flex-sm-row justify-between items-center bg-white border-t border-[#d8d2ff] p-4 space-y-2 sm:space-y-0 rounded-b-lg">
                <button
                  className=" bg-[#3a868b] hover:bg-[#2c6d71] text-white py-2 px-4 rounded-lg"
                  onClick={() => {
                    window.location.href =
                      "https://minemi.ai/personalloan/applyonline";
                  }}
                  style={{
                    width: isSmallScreen ? "100%" : "10rem",
                    maxWidth: isSmallScreen ? "100%" : "10rem",
                  }}
                >
                  Apply Now
                </button>
                <button
                  className=" border text-[#311664] border-[#d8d2ff] py-2 px-4 rounded-lg d-flex text-center justify-content-center align-items-center"
                  onClick={() => {
                    window.location.href =
                      "https://minemi.ai/loanpage/personalloan";
                  }}
                  style={{
                    width: isSmallScreen ? "100%" : "10rem",
                    maxWidth: isSmallScreen ? "100%" : "10rem",
                  }}
                >
                  <Info className="mr-2 h-4 w-4" />
                  More Details
                </button>
              </div>
            </div>
          ))}
        </div>

        <section className="mt-8 sm:mt-16 max-w-3xl mx-auto bg-transparent pb-5  ">
          <h2
            className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-8 text-center bg-transparent"
            style={{
              fontFamily: "Archivo",
              fontSize: isSmallScreen ? "22px" : "24px",
              lineHeight: isSmallScreen ? "28px" : "32px",
              fontWeight: "600",
            }}
          >
            Frequently Asked Questions
          </h2>
          <div
            className=" rounded-lg shadow-md  "
            style={{ backgroundColor: "#FFFFFF" }}
          >
            {faqs.map((faq, index) => (
              <details key={index} className="border-b bg-transparent">
                <summary className="text-[#311664] cursor-pointer px-4 sm:px-6 py-3 sm:py-4 text-sm sm:text-base bg-transparent">
                  {faq.question}
                </summary>
                <div className="text-[#26292b] px-4 sm:px-6 pb-3 sm:pb-4 text-xs sm:text-sm bg-transparent">
                  {faq.answer}
                </div>
              </details>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
}

import React from "react";
import { useState } from "react";
import { ChevronDown, Info, Star } from "lucide-react";
import { useMediaQuery } from "@mui/material";
import bajaj from "../../../Assets/Images/BajajFinservSquare.webp";
import aditya from "../../../Assets/Images/aditya-birla-capital-squre.webp";
import mirae from "../../../Assets/Images/MiraiAssetsCircular.webp";
import icici from "../../../Assets/Images/ICICIBank-circular.webp";
import volt from "../../../Assets/Images/VoltCircular.webp";

function TopPick({ category, lender }) {
  const [openSection, setOpenSection] = useState(null);

  const isMediumScreen = useMediaQuery("(max-width:768px)");

  const toggleSection = (section) => {
    if (openSection === section) {
      setOpenSection(null);
    } else {
      setOpenSection(section);
    }
  };

  return (
    <div
      className={`mb-8 overflow-hidden rounded-lg  bg-white shadow-md`}
      style={{
        border:
          lender.featured === true ? "2px solid #59266d" : "2px solid gray",
      }}
    >
      {/* Header */}
      <div
        className={`border-b ${
          lender.featured ? "bg-[#ebe8fc]" : "bg-[#ebe8fc]/30"
        } p-3 flex justify-between items-center`}
      >
        <h3 className="text-lg font-bold text-[#311664] m-0">
          Best for {category}
        </h3>
        {lender.featured && (
          <span className="bg-[#59266d] text-white text-xs px-2 py-1 rounded-full">
            Featured
          </span>
        )}
      </div>

      {/* Main content */}
      <div className="p-3">
        <div className="flex flex-col items-start gap-6 md:flex-row md:items-center">
          {/* Logo and name */}
          <div className="flex items-center">
            <div className="mr-4 h-16 w-16 overflow-hidden rounded-lg border">
              <img
                src={lender.img}
                alt={lender.name}
                width={64}
                height={64}
                className="object-cover"
              />
            </div>
            <div>
              <div className="text-xl font-bold text-[#311664]">
                {lender.name}
              </div>
              <div className="flex items-center">
                <span className="mr-1 font-medium">{lender.rating}</span>
                <div className="flex">
                  {Array(5)
                    .fill(0)
                    .map((_, i) => (
                      <Star
                        key={i}
                        className={`h-4 w-4 ${
                          i < Math.floor(lender.rating)
                            ? "fill-[#59266d] text-[#59266d]"
                            : "text-gray-300"
                        }`}
                      />
                    ))}
                </div>
                <span className="ml-1 text-xs text-gray-500">/5</span>
              </div>
              <div className="mt-1 text-xs px-2 py-0.5 bg-[#ebe8fc] rounded-full inline-block">
                {lender.type}
              </div>
            </div>
          </div>

          {/* Loan details */}
          <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-3">
            <div>
              <div className="flex items-center text-sm text-gray-500">
                Interest Rate
                <Info className="ml-1 h-3 w-3" />
              </div>
              <div className="font-medium">{lender.rate}</div>
            </div>
            <div>
              <div className="text-sm text-gray-500">Max LTV</div>
              <div className="font-medium">{lender.ltv}</div>
            </div>
            <div>
              <div className="flex items-center text-sm text-gray-500">
                Max Loan Amount
                <Info className="ml-1 h-3 w-3" />
              </div>
              <div className="font-medium">{lender.maxLoan}</div>
            </div>
          </div>

          {/* CTA button */}
          <div className="mt-2 w-full md:mt-0 md:w-auto">
            <button
              className={`w-full rounded-md ${
                lender.featured ? "bg-[#3a868b]" : "bg-[#59266d]"
              }  text-md font-medium px-6 py-2 text-white hover:opacity-90 md:w-auto`}
              style={{
                width: isMediumScreen ? "100%" : "7rem",
                height: isMediumScreen ? "3.3rem" : "auto",
              }}
              onClick={() => {
                window.location.href = lender.link;
              }}
            >
              Check Eligibility
            </button>
          </div>
        </div>

        {/* Expandable sections */}
        <div className="mt-6 border-t pt-4">
          <div className="grid d-flex flex-column gap-2">
            {/* Details and qualifications */}
            <div className="rounded-md border">
              <button
                className="flex w-full items-center justify-between p-3 text-left font-medium text-[#311664] hover:bg-[#ebe8fc]/30"
                onClick={() => toggleSection("details")}
                aria-expanded={openSection === "details"}
              >
                Details and qualifications
                <ChevronDown
                  className={`h-5 w-5 transition-transform ${
                    openSection === "details" ? "rotate-180" : ""
                  }`}
                />
              </button>
              {openSection === "details" && (
                <div className="border-t p-3">
                  <ul className="list-inside list-disc space-y-2 text-sm">
                    {lender.details.map((detail, index) => (
                      <li key={index}>{detail}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* Pros and cons */}
            <div className="rounded-md border">
              <button
                className="flex w-full items-center justify-between p-3 text-left font-medium text-[#311664] hover:bg-[#ebe8fc]/30"
                onClick={() => toggleSection("pros-cons")}
                aria-expanded={openSection === "pros-cons"}
              >
                Pros & cons
                <ChevronDown
                  className={`h-5 w-5 transition-transform ${
                    openSection === "pros-cons" ? "rotate-180" : ""
                  }`}
                />
              </button>
              {openSection === "pros-cons" && (
                <div className="border-t p-3">
                  <div className="grid gap-4 md:grid-cols-2">
                    <div>
                      <h4 className="mb-2 font-medium text-[#311664]">Pros</h4>
                      <ul className="list-inside list-disc space-y-1 text-sm">
                        {lender.pros.map((pro, index) => (
                          <li key={index}>{pro}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h4 className="mb-2 font-medium text-[#311664]">Cons</h4>
                      <ul className="list-inside list-disc space-y-1 text-sm">
                        {lender.cons.map((con, index) => (
                          <li key={index}>{con}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Disclaimer */}
            <div className="rounded-md border">
              <button
                className="flex w-full items-center justify-between p-3 text-left font-medium text-[#311664] hover:bg-[#ebe8fc]/30"
                onClick={() => toggleSection("disclaimer")}
                aria-expanded={openSection === "disclaimer"}
              >
                Disclaimer
                <ChevronDown
                  className={`h-5 w-5 transition-transform ${
                    openSection === "disclaimer" ? "rotate-180" : ""
                  }`}
                />
              </button>
              {openSection === "disclaimer" && (
                <div className="border-t p-3">
                  <p className="text-sm text-gray-600">{lender.disclaimer}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function LasTopPicksSection() {
  const topPicks = [
    {
      category: "Loan Against Shares",
      lender: {
        name: "Bajaj Finserv",
        img: bajaj,
        logo: "bajaj",
        link: "https://www.bajajfinserv.in/loan-against-shares",
        type: "NBFC",
        rating: 5.0,
        rate: "11.50-13.50%",
        ltv: "Up to 70%",
        processingFee: "1.00-2.00%",
        maxLoan: "No upper limit",
        featured: true,
        details: [
          "Minimum portfolio value: ₹2,00,000",
          "Loan tenure: 12-60 months",
          "Minimum income: ₹30,000 per month",
          "Processing fee: Up to 2.00% of loan amount",
          "Prepayment charges: Nil after 12 months",
        ],
        pros: [
          "No upper limit on loan amount",
          "Higher LTV ratio compared to banks",
          "Quick processing within 48 hours",
          "Flexible repayment options",
          "Wide range of acceptable shares",
        ],
        cons: [
          "Higher interest rates compared to banks",
          "Higher processing fees",
          "Prepayment charges applicable in first 12 months",
        ],
        disclaimer:
          "Rates, fees, and terms are subject to change without notice. Bajaj Finserv loans against shares are subject to credit approval. Your actual rate, payment, and costs could be higher. Minimum loan amounts may vary by location.",
      },
    },
    {
      category: "Loan Against Mutual Funds",
      lender: {
        name: "Aditya Birla Finance",
        img: aditya,
        logo: "aditya-birla",
        link: "https://finance.adityabirlacapital.com/loan-against-securities/apply-now",
        type: "NBFC",
        rating: 4.5,
        rate: "11.25-13.25%",
        ltv: "Up to 80%",
        processingFee: "1.00-1.50%",
        maxLoan: "No upper limit",
        featured: true,
        details: [
          "Minimum portfolio value: ₹2,50,000",
          "Loan tenure: 12-60 months",
          "Minimum income: ₹25,000 per month",
          "Processing fee: Up to 1.50% of loan amount",
          "Prepayment charges: Nil after 12 months",
        ],
        pros: [
          "Highest LTV ratio in the industry for mutual funds",
          "No upper limit on loan amount",
          "Accepts a wide range of mutual fund schemes",
          "Doorstep service available",
          "Flexible repayment options",
        ],
        cons: [
          "Higher interest rates compared to banks",
          "Minimum portfolio value requirement is higher",
          "Limited branch presence in smaller cities",
        ],
        disclaimer:
          "Rates, fees, and terms are subject to change without notice. Aditya Birla Finance loans against mutual funds are subject to credit approval. Your actual rate, payment, and costs could be higher. Minimum loan amounts may vary by location.",
      },
    },
    {
      category: "Digital Experience",
      lender: {
        name: "Mirae Asset",
        img: mirae,
        logo: "mirae",
        link: "https://transact.miraeassetfin.com/register?_gl=1*mr3huj*_gcl_au*NTg1OTM1NDYxLjE3NDIwNDUyMjg.*_ga*MTA4NzQ2NTU2OC4xNzQyMDQ1MjI5*_ga_21E4CJGJYQ*MTc0MjA0NTIyOC4xLjAuMTc0MjA0NTIyOC42MC4wLjA.",
        type: "Fintech",
        rating: 4.0,
        rate: "12.00-14.00%",
        ltv: "Up to 65%",
        processingFee: "1.00-1.50%",
        maxLoan: "No upper limit",
        featured: true,
        details: [
          "Minimum portfolio value: ₹1,00,000",
          "Loan tenure: 12-36 months",
          "Fully digital application process",
          "Processing fee: Up to 1.50% of loan amount",
          "Prepayment charges: Nil",
        ],
        pros: [
          "End-to-end digital process",
          "Fastest disbursement (as quick as 24 hours)",
          "Lower minimum portfolio requirement",
          "No prepayment charges",
          "Simple documentation process",
        ],
        cons: [
          "Higher interest rates",
          "Shorter maximum loan tenure",
          "Limited customer support options",
        ],
        disclaimer:
          "Rates, fees, and terms are subject to change without notice. Mirae Asset loans against securities are subject to credit approval. Your actual rate, payment, and costs could be higher. Minimum loan amounts may vary by location.",
      },
    },
    {
      category: "Bank Option",
      lender: {
        name: "ICICI Bank",
        img: icici,
        logo: "icici",
        link: "https://www.icicibank.com/personal-banking/loans/loan-against-securities",
        type: "Bank",
        rating: 4.5,
        rate: "10.50-12.00%",
        ltv: "Up to 50%",
        processingFee: "0.50-1.00%",
        maxLoan: "Up to ₹20,00,000",
        details: [
          "Minimum portfolio value: ₹4,00,000",
          "Loan tenure: 12-60 months",
          "Minimum income: ₹30,000 per month",
          "Processing fee: Up to 1.00% of loan amount",
          "Prepayment charges: Nil",
        ],
        pros: [
          "Lower interest rates compared to NBFCs",
          "Lower processing fees",
          "No prepayment charges",
          "Special rates for existing customers",
          "Extensive branch network",
        ],
        cons: [
          "Limited to ₹20,00,000 as per RBI guidelines",
          "Lower LTV ratio compared to NBFCs",
          "Longer processing time (3-7 days)",
        ],
        disclaimer:
          "Rates, fees, and terms are subject to change without notice. ICICI Bank loans against securities are subject to credit approval. Your actual rate, payment, and costs could be higher. Minimum loan amounts may vary by location.",
      },
    },
    {
      category: "Flexible Repayment",
      lender: {
        name: "Volt",
        img: volt,
        logo: "volt",
        link: "https://voltmoney.in/",
        type: "Fintech",
        rating: 4.0,
        rate: "12.50-14.50%",
        ltv: "Up to 60%",
        processingFee: "1.00-2.00%",
        maxLoan: "Up to ₹5,00,00,000",
        details: [
          "Minimum portfolio value: ₹1,50,000",
          "Loan tenure: 3-36 months",
          "Fully digital application process",
          "Processing fee: Up to 2.00% of loan amount",
          "Prepayment charges: Nil",
        ],
        pros: [
          "Unique flexible repayment options",
          "Interest-only payment periods available",
          "Quick digital approval process",
          "No prepayment charges",
          "Lower minimum portfolio requirement",
        ],
        cons: [
          "Higher interest rates",
          "Higher processing fees",
          "Relatively new player in the market",
        ],
        disclaimer:
          "Rates, fees, and terms are subject to change without notice. Volt loans against securities are subject to credit approval. Your actual rate, payment, and costs could be higher. Minimum loan amounts may vary by location.",
      },
    },
  ];

  return (
    <section className="pt-4 pb-16">
      <div className=" mx-auto px-3">
        <h2 className="mb-8 text-center text-3xl font-bold text-[#311664]">
          Top Picks for Loan Against Securities
        </h2>
        <p className="mb-12 text-center text-[#26292b]">
          We've analyzed dozens of Loan Against Securities options to bring you
          the best lenders for different needs.
        </p>

        <div className="space-y-6">
          {topPicks.map((pick, index) => (
            <TopPick
              key={index}
              category={pick.category}
              lender={pick.lender}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

import { PenLine, BarChart2, CheckSquare, Rocket } from "lucide-react"

const steps = [
  {
    icon: PenLine,
    title: "Enter Details",
    description: "Input loan amount and basic info",
  },
  {
    icon: BarChart2,
    title: "Compare Offers",
    description: "View top loan options instantly",
  },
  {
    icon: CheckSquare,
    title: "Choose Best Loan",
    description: "Select the ideal offer for you",
  },
  {
    icon: Rocket,
    title: "Quick Apply",
    description: "Complete application easily",
  },
]
export default function HowMinemiWorks({scrollToFilter}) {

  return (
    <>
    <section className="bg-white py-4 sm:py-8 md:py-16 px-3 sm:px-6 md:px-8">
      <div className="max-w-7xl mx-auto" style={{backgroundColor:'transparent'}}>
        <h2 className="text-lg sm:text-2xl md:text-3xl lg:text-4xl font-bold text-center text-[#311664] mb-4 sm:mb-6 md:mb-10" style={{backgroundColor:'transparent'}}>
        How Loan Comparison Works – Find Your Best Loan Fast! 
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 sm:gap-4 md:gap-8" style={{backgroundColor:'transparent',justifyContent:'center',gap:12}}>
          {steps.map((step, index) => (
            <div style={{border:'1px solid #311664',height:190,justifyContent:'center'}}
              key={index}
              className="bg-[#ebe8fc]  p-2 sm:p-4 md:p-6 flex flex-col items-center text-center transition-transform duration-300 hover:scale-105 rounded-lg"
            >
            <div className="bg-[#311664] p-2 sm:p-3 md:p-4 rounded-full mb-2 sm:mb-4">
  <step.icon className="w-4 h-4 sm:w-6 sm:h-6 md:w-8 md:h-8 text-[#3a868b]" style={{backgroundColor:'transparent'}} />
</div>

              <h3 className="text-sm sm:text-lg md:text-xl font-semibold text-[#311664] mb-1 sm:mb-2" style={{backgroundColor:'transparent'}}>{step.title}</h3>
              <p className="text-xs sm:text-sm md:text-base text-gray-600" style={{backgroundColor:'transparent',textAlign:'center'}}>{step.description}</p>
            </div>
          ))}
        </div>
        <div className="mt-8 sm:mt-10 md:mt-12 text-center" style={{backgroundColor:'transparent'}}>
          <button className="bg-[#3a868b] hover:bg-[#59266d] text-white text-sm sm:text-lg md:text-xl py-2 sm:py-3 md:py-4 px-4 sm:px-6 md:px-10 rounded-lg transition-colors duration-300" onClick={scrollToFilter}>
            Compare Loans Now
          </button>
        </div>
      </div>
    </section>
    </>
  )
}


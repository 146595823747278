import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import "./Question3.css";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import logo from ".././assets/Images/mLogo.webp";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: '#F6F6F6',
  boxShadow: 24,
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 2,
  paddingRight: 2,
  border: 0,
};

const CreditCardscore = () => {
  const location = useLocation();
  const { category } = location.state || {};
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [progress, setProgress] = useState(30);

  const categories = [
    { name: "Excellent (720-850)", maxValue: 850 },
    { name: "Good (690-719)", maxValue: 719 },
    { name: "Fair (630-689)", maxValue: 689 },
    { name: "Bad (350-629)", maxValue: 629 },
  ];

  // Initialize active state with saved value or default to 0
  const [active, setActive] = useState(() => {
    const savedScore = localStorage.getItem("creditScore");
    if (savedScore) {
      const index = categories.findIndex(cat => cat.maxValue.toString() === savedScore);
      return index !== -1 ? index : 0;
    }
    // Set first option as default and save it
    localStorage.setItem("creditScore", categories[0].maxValue.toString());
    return 0;
  });



  const handleCategorySelect = (category, index) => {
    setActive(index);
    localStorage.setItem("creditScore", category.maxValue.toString());
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 75,
          left: 0,
          width: "100%",
          height: 5,
          backgroundColor: "#E7E4F9",
          zIndex: 2,
          // height: "100%",
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 75,
          left: 0,
          // height: "100%",
          height: 5,
          width: `${progress}%`,
          backgroundColor: progress >= 30 ? "#59266D" : "#E7E4F9",
          transition: "width 0.3s ease",
          zIndex: 2,
        }}
      />
      <div className="content-container">
        {/* Title */}
        <div className="question-title mt-4">Let's start with your credit. What is your score these days?</div>

        {/* Buttons */}
        <div className="buttons-container mt-4 ">
          {categories.map((category, index) => (
            <div
              key={index}
              

              className={`category-button ${active === index ? "active" : ""} button-class`}
              onClick={() => handleCategorySelect(category, index)}
              style={{ marginBottom: '4%' ,fontFamily:'Archivo',fontSize:16,fontWeight:400}}
            >
              {category.name}
            </div>
          ))}
        </div>

        {/* Next Button */}
        <div className="next-button"  style={{fontFamily:'Archivo'}} onClick={() => navigate("/select-occupation",{state:{category:category}})}>
          NEXT
        </div>
      </div>

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ width: 40, height: 40 }}>
              <img src={logo} style={{ width: '100%', height: '100%' }} alt="" />
            </div>
            <IoClose
              onClick={() => setOpen(false)}
              style={{
                alignSelf: "center",
                fontWeight: 400,
                fontSize: 24,
                cursor: 'pointer'
              }}
            />
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: '#59266D', fontSize: 24,  fontWeight: 700, marginTop: 16 }}>
            Wait! Your Card is Just a Step Away!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ color: "#8A8A8A", fontSize: 18, fontWeight: 400, fontFamily: 'Archivo' }}>
            Complete the form to receive the best credit card tailored to your needs.
          </Typography>
          <div style={{ backgroundColor: '#59266D', padding: 15, color: '#FFFFFF', borderRadius: 10, textAlign: 'center', marginTop: '4%', fontFamily: 'Archivo', fontWeight: 700, fontSize: 16, cursor: "pointer" }} onClick={() => setOpen(false)}>Keep Going</div>
          <div style={{ backgroundColor: '#F6F6F6', padding: 15, color: '#59266D', borderRadius: 10, textAlign: 'center', marginTop: '4%', fontFamily: 'Archivo', fontWeight: 700, fontSize: 16, border: '1px solid #59266D', cursor: "pointer" }} onClick={() => navigate("/")}>No Thanks</div>
        </Box>
      </Modal>
    </>
  );
};

export default CreditCardscore;

import React, { useState, useEffect } from "react";
import image1 from "../assets/Images/user.webp";
import twitter from "../assets/Images/mage_x.webp";
import mage from "../assets/Images/Group (2).webp";

const TestimonialsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);

  const testimonials = [
    {
      name: "Priyesh Shah",
      date: "07/11/2000",
      text: "MinEMI made it so simple for me to manage my credit and stay on track with my payments. Their flexible plans helped me balance my finances without stress. Highly recommend!",
      image: image1,
    },
    {
      name: "Adhiyesh Roy",
      date: "12/10/1995",
      text: "The platform provided us with financial freedom. We were able to manage our credit easily. The service is unmatched!",
      image: image1,
    },
    {
      name: "Priya Kushwaha",
      date: "07/11/2000",
      text: "MinEMI made it so simple for me to manage my credit and stay on track with my payments. Their flexible plans helped me balance my finances without stress. Highly recommend!",
      image: image1,
    },
    {
      name: "Kavita Bajaj",
      date: "12/10/1995",
      text: "The platform provided us with financial freedom. We were able to manage our credit easily. The service is unmatched!",
      image: image1,
    },
    {
      name: "John Smith",
      date: "05/02/1987",
      text: "Their flexible plans gave me control over my finances. I can't recommend them enough. Simply outstanding!",
      image: image1,
    },
    {
      name: "Michael ROy",
      date: "23/03/1990",
      text: "MinEMI helped me save money and stay on track with my credit goals. I’m really happy with the experience.",
      image: image1,
    },
    {
      name: "Emily Davis",
      date: "14/08/1992",
      text: "Fantastic service, very easy to use and truly helped me gain financial freedom. The flexibility they offer is unmatched.",
      image: image1,
    },
    {
      name: "Joy Wilson",
      date: "18/06/1985",
      text: "Amazing support and service. I could manage my finances better with MinEMI and highly recommend them to everyone!",
      image: image1,
    },
    // Add more testimonials as needed
  ];

  const itemsPerPageDesktop = 3;
  const itemsPerPageMobile = 1;
  const itemsPerPageTab = 2;

  const totalSlidesDesktop = Math.ceil(
    testimonials.length / itemsPerPageDesktop
  );
  const totalSlidesMobile = Math.ceil(testimonials.length / itemsPerPageMobile);
  const totalSlidesTab = Math.ceil(testimonials.length / itemsPerPageTab);

  // Function to check screen width and set state accordingly
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      setIsTab(window.innerWidth >= 600 && window.innerWidth <= 991);
    };

    handleResize(); // Call it initially to set state on load
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Get current testimonials for the current slide index
  const getCurrentTestimonials = () => {
    const start =
      currentIndex *
      (isMobile
        ? itemsPerPageMobile
        : isTab
        ? itemsPerPageTab
        : itemsPerPageDesktop);
    return testimonials.slice(
      start,
      start +
        (isMobile
          ? itemsPerPageMobile
          : isTab
          ? itemsPerPageTab
          : itemsPerPageDesktop)
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <section
      className="relative overflow-hidden py-10 "
      style={{ paddingTop: "9%", backgroundColor: "#f8f8f8" }}
    >
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-10 bg-pattern bg-cover z-0"></div>

      <div className="container mx-auto px-6 lg:px-12 text-center relative z-10">
        {/* Section Title */}
        <h2
          className="text-[#59266D] tracking-wide leading-tight animate-fadeInUp"
          style={{ fontFamily: "Archivo", fontSize: 30, fontWeight: 700 }}
        >
          Customers Speaks
        </h2>

        {/* Testimonials Grid */}
        <div
          className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 animate-fadeInUp mt-4`}
          style={{ animationDelay: "0.6s" }}
        >
          {getCurrentTestimonials().map((testimonial, index) => (
            <div
              key={index}
              className="group relative bg-[#E7E4F9] p-8 rounded-lg shadow-lg hover:shadow-2xl transition-all duration-500 transform hover:scale-105"
              style={{ borderRadius: 24 }}
            >
              <div
                className="relative z-10"
                style={{
                  backgroundColor: "transparent",
                }}
              >
                <div
                  className="flex items-center mt-6"
                  style={{
                    backgroundColor: "transparent",
                  }}
                >
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-10 h-10 rounded-full shadow-md mr-4"
                    style={{
                      backgroundColor: "transparent",
                    }}
                  />
                  <div
                    style={{
                      backgroundColor: "transparent",
                    }}
                  >
                    <div
                      className="text-[#59266D]"
                      style={{
                        fontFamily: "Archivo",
                        fontSize: 18,
                        backgroundColor: "transparent",
                        fontWeight: 600,
                      }}
                    >
                      {testimonial.name}
                    </div>
                    <div
                      className="text-[#868686]"
                      style={{
                        fontFamily: "Archivo",
                        fontSize: 12,
                        textAlign: "left",
                        backgroundColor: "transparent",
                        fontWeight: 500,
                      }}
                    >
                      {testimonial.date}
                    </div>
                  </div>
                </div>
                <p
                  className="text-[#1F1F1F] leading-relaxed mb-4 mt-3"
                  style={{
                    fontFamily: "Archivo",
                    fontSize: 14,
                    textAlign: "left",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                  }}
                >
                  {testimonial.text}
                </p>
                <div
                  className="flex items-center mt-6 gap-26"
                  style={{ gap: 26, backgroundColor: "transparent" }}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: "transparent",
                    }}
                  >
                    <img
                      src={twitter}
                      style={{
                        width: "100%",
                        height: "100%",
                        resizeMode: "contain",
                        backgroundColor: "transparent",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      backgroundColor: "transparent",
                    }}
                  >
                    <img
                      src={mage}
                      style={{
                        width: "100%",
                        height: "100%",
                        resizeMode: "contain",
                        backgroundColor: "transparent",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination Dots */}
        <div className="mt-10 flex justify-center">
          {Array.from({
            length: isMobile
              ? totalSlidesMobile
              : isTab
              ? totalSlidesTab
              : totalSlidesDesktop,
          }).map((_, index) => {
            const isActive = index === currentIndex;
            const isAdjacent = Math.abs(currentIndex - index) === 1;

            return (
              <button
                key={index}
                onClick={() => handleDotClick(index)}
                className={`rounded-full ${
                  isActive
                    ? "bg-[#59266D]"
                    : isAdjacent
                    ? "bg-[#d1b3e6]"
                    : "bg-[#f0e6fa]"
                }`}
                style={{
                  transition:
                    "background-color 0.3s ease, width 0.3s ease, height 0.3s ease",
                  border: "1px solid #59266D",
                  width: isActive ? 24 : isAdjacent ? 18 : 14,
                  height: isActive ? 24 : isAdjacent ? 18 : 14,
                  margin: "0 4px",
                  alignSelf: "center",
                }}
              ></button>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;

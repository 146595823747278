import { useEffect, useState } from "react"

export default function MobileFriendlyDashboard({outstanding,series1,series2,series3,series4}) {
  const [isClient, setIsClient] = useState(false)

  const [loans, setLoans] = useState([
    { name: "Personal Loan", amount: 1600000, color: "#FFD700",percentage:series1 },
    { name: "Housing Loan", amount: 600000, color: "#32CD32",percentage:series2 },
    { name: "Two Wheeler Loan ", amount: 600000, color: "#FFA500",percentage:series3  },
    { name: "Other Loan ", amount: 1200000, color: "#00BFFF" ,percentage:series4 },
  ])

  const totalAmount = loans.length > 0 
    ? loans.reduce((sum, loan) => sum + loan.amount, 0)
    : 0

  const loansWithPercentage = loans.length > 0
    ? loans.map((loan) => ({
        ...loan,
      }))
    : []

  useEffect(() => {
    setIsClient(true)
  }, [])

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      maximumFractionDigits: 0,
    }).format(amount)
  }

  const createDonutChart = () => {
    if (loansWithPercentage.length === 0) {
      return null
    }

    let cumulativePercentage = 0
    return loansWithPercentage.map((loan, index) => {
      const startAngle = (cumulativePercentage * 360) / 100
      const endAngle = ((cumulativePercentage + loan.percentage) * 360) / 100
      cumulativePercentage += loan.percentage

      const startRadians = (startAngle - 90) * (Math.PI / 180)
      const endRadians = (endAngle - 90) * (Math.PI / 180)

      const radius = 50
      const centerX = 60
      const centerY = 60

      const x1 = centerX + radius * Math.cos(startRadians)
      const y1 = centerY + radius * Math.sin(startRadians)
      const x2 = centerX + radius * Math.cos(endRadians)
      const y2 = centerY + radius * Math.sin(endRadians)

      const largeArcFlag = loan.percentage > 50 ? 1 : 0

      const pathData = [
        `M ${centerX} ${centerY}`,
        `L ${x1} ${y1}`,
        `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${x2} ${y2}`,
        "Z",
      ].join(" ")

      return <path key={index} d={pathData} fill={loan.color} />
    })
  }

  return (
    <div className=" p-4 container">
      <div className="mx-auto max-w-l">
        <div className="rounded-lg bg-purple-900 p-4 text-white shadow-xl">
          <h2 className="mb-4 text-xl font-bold" style={{backgroundColor:'transparent'}}>Financial Dashboard</h2>

          <div className="mb-4 flex items-center justify-between" style={{backgroundColor:'transparent'}}>
            <div style={{backgroundColor:'transparent'}}>
              <p className="text-sm text-purple-200" style={{backgroundColor:'transparent'}}>Outstanding Amount</p>
              <p className="text-2xl font-bold" style={{backgroundColor:'transparent'}}>{outstanding}</p>
            </div>
            <div className="relative h-24 w-24" style={{backgroundColor:'transparent'}}>
              {isClient && (
                <svg viewBox="0 0 120 120" className="h-full w-full -rotate-90" style={{backgroundColor:'transparent'}}>
                  {createDonutChart()}
                </svg>
              )}
            </div>
          </div>

          <div className="space-y-3" style={{backgroundColor:'transparent'}}>
            {loansWithPercentage.map((loan, index) => (
              <div key={index} className="flex items-center justify-between rounded-md bg-purple-800/50 p-2">
                <div className="flex items-center space-x-2" style={{backgroundColor:'transparent'}}>
                  <div className="h-3 w-3 rounded-full" style={{ backgroundColor: loan.color }} />
                  <span className="text-sm" style={{backgroundColor:'transparent'}}>{loan.name}</span>
                </div>
                <div className="text-right" style={{backgroundColor:'transparent'}}>
                  <p className="text-xs text-purple-200" style={{backgroundColor:'transparent'}}>{loan.percentage}%</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
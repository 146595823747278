import React,{useEffect,useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./LoanOverview.css";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { CreditCard } from "@mui/icons-material";
import { CiBank } from "react-icons/ci";
import { MdSecurity } from "react-icons/md";

const LoanOverview = () => {
  const [loans,setLoans] = useState([]);
  const [loading,setLoading] = useState(false); 
  const [filterData,setFilterData] = useState([]);
  const [selectedType, setSelectedType] = useState("All");

  useEffect(() => {
    const request = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9..Ug-8dJgAdnERr9o3v62kchzVYqTZ9KRs-aAPfmLFu8ps8xlmUr48RYxMOpnP2uOeWGsOpPAx9LjmPLgsSoVYuIwZFoTm345adeds-9TR4AcVQ7oyHmghKluo_oMz2o-bDcSOx_C6_5z0fn0UZt4w6LA58-gY9gjEaS44D8-qKuoln_SJAVREspftnJleMoxX80pCMY_ZqZJEhAaDxDCkXuaAPeyTFVST-qqU5Fn1s5-qASC9ub8rIo3iGJBc-q02kLDhcecptyLklYoiGIWUnW2U2e0eVv8jh2b_iNHpgVP3Qna0Pmvd61thC93BUGJlxoTbUlPAJ0o9b9ZJJAjjsbfia6hP_6OtaWq0u812HzzGvQsgg9HeM7R6SAkBP50XRAzk81yVkrqDy3Dv0Kyubgn_5hnCA40jBFcPANT-5w-NZxawf7OBgp6q60wRPYqt6eIx2WLGxj3iR7riwNDy5wj8VZsObOReF8__9Kure02RWg7_nm8oVscsLyJ_kc3FIkTQJ7VQ5XKMOT1CgOXYhfvZR9x7GBOtVflV7VdZgyPazsa-F1R2d0dweXuv_CDysXNcV0JVht9Ju7qTvJhYe69Orb2lAzPYNn3XPGj3lAWBChcjAb1WFvgLwP1uR0Y9n9AoEO9A88bvcE7Lal9KQWkaHj5vB47azcO1QCQu-kQ';
      if(token) 
      {
        try 
        {
          const response = await axios.get(
            `${process.env.REACT_APP_SECRET_KEY}/loanOverview`,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if(response?.data && response.status === 200)
          {
            setFilterData([...new Set(response?.data?.data?.loanDetail.map((item) => item.loanName))]);
            setLoans(response?.data?.data?.loanDetail);
          }
        }catch (error) {
        }
      }
      setLoading(false);
    };
    request();
  }, []);
  

  const handleFilterClick = (type) => {
    setSelectedType(type);
  };


  const filteredLoans = loans.filter((loan) => {
    return (selectedType === "All" || loan.loanName.toLowerCase() === selectedType.toLowerCase());
  });

  const status_closed = ["12","13","14","15","16","17"];
  const status_active = ["11","21","22","23","24","25","71","78","80","82","83","84","DEFAULTVALUE"];


  return (
    <>
      <div id="Loan-Overview" className="bg-success">
        <div className="Loan-Overview-contentCon">
          <p id="Loan-Overview-title">Detailed information of all your running loans.</p>

          <div className="m-0 p-0">
            <div className="filter-btn-con gap-2 my-3">
              <div id="filter-subcon">
                <button 
                onClick={() => handleFilterClick("All")} 
                className={selectedType === "All" ? "active-btn" : "inactive-btns"}>
                  All
                </button>
                {
                  filterData.map((item, index) => (
                    <button 
                      key={index}
                      onClick={() => handleFilterClick(item)} 
                      className={selectedType?.toLowerCase() === item?.toLowerCase() ? "active-btn" : "inactive-btns"}>
                      {item}
                    </button>
                  ))
                }
              </div>
            </div>

            <h4 className="loan-status-title">Active Loans</h4>
            <div className="loan-card-row">
              {filteredLoans.map((loan, index) => (
                !status_closed.includes(loan.Account_Status) && 
                <div key={index} className="loan-card-col">
                  <div className="loan-card">
                    <div className="d-flex align-items-start mb-4">
                      <div>
                        <div className="overview-icons-con">
                        { loan?.loanName === "HOME LOAN" || loan?.loanName === "HOUSING LOAN" && <img src="../../../images/loan-overview/House.png" alt={`house.png-${index}`} className="overview-icons" />}
                        { loan?.loanName === "CREDIT CARD" && <CreditCard alt={`CREDIT-${index}`} className="class-overview-icons"  />}
                        { loan?.loanName === "OVERDRAFT" && <CiBank alt={`CiBank-${index}`} className="class-overview-icons"  />}
                        { loan?.loanName === "LOAN AGAINST SHARES/ SECURITIES" && <MdSecurity alt={`MdSecurity-${index}`} className="class-overview-icons"  />}
                        { loan?.loanName === "CAR LOAN" && <img src="../../../images/loan-overview/Car.png" alt={`car.png-${index}`} className="overview-icons" />}
                        { loan?.loanName === "GOLD LOAN" && <img src="../../../images/loan-overview/Gold.png" alt={`gold.png-${index}`} className="overview-icons" />}
                        { (loan?.loanName === "PERSONAL LOAN" || loan?.loanName === "CONSUMER LOAN") && <img src="../../../images/loan-overview/User.png" alt={`user.png-${index}`} className="overview-icons" />}
                        </div>
                      </div>

                      <div className="me-1">
                        <p className="loan-type">{loan?.loanName}</p>
                        <p className="loan-bank">{loan?.Subscriber_Name}</p>
                      </div>
                      
                      {
                      status_active.includes(loan.Account_Status) 
                      ? 
                      <p className="loan-status-Active">Active</p> 
                      : 
                      <p className="loan-status-Overdue">N/A</p>
                      }

                    </div>

                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="loan-amt-title">Outstanding Amount</p>
                        <p className="loan-amt-value">₹{Number(loan?.Current_Balance)?.toLocaleString()}</p>
                      </div>
                      <div>
                        <p className="loan-amt-title" >Account Open Date</p>
                        <p className="loan-amt-value" style={{textAlign:"end"}}>
                        {loan?.Open_Date
  ? `${loan.Open_Date.slice(6, 8)}-${loan.Open_Date.slice(4, 6)}-${loan.Open_Date.slice(0, 4)}`
  : ""}

</p>                      </div>
                    </div>

                    {/* <button className="loan-details-btn">
                      View Details <i className="bi bi-arrow-right"></i>
                    </button> */}
                  </div>
                </div>
              ))}
            </div>

            <h4 className="loan-status-title">Closed Loans</h4>
            <div className="loan-card-row border-0">
              {filteredLoans.map((loan, index) => (
                status_closed.includes(loan.Account_Status) && 
                <div key={index} className="loan-card-col">
                  <div className="loan-card">
                    <div className="d-flex align-items-start mb-4">
                      <div>
                        <div className="overview-icons-con">
                        { loan?.loanName === "HOME LOAN" || loan?.loanName === "HOUSING LOAN" && <img src="../../../images/loan-overview/House.png" alt={`house.png-${index}`} className="overview-icons" />}
                        { loan?.loanName === "CREDIT CARD" && <CreditCard alt={`CREDIT-${index}`} className="class-overview-icons"  />}
                        { loan?.loanName === "OVERDRAFT" && <CiBank alt={`CiBank-${index}`} className="class-overview-icons"  />}
                        { loan?.loanName === "LOAN AGAINST SHARES/ SECURITIES" && <MdSecurity alt={`MdSecurity-${index}`} className="class-overview-icons"  />}
                        { loan?.loanName === "CAR LOAN" && <img src="../../../images/loan-overview/Car.png" alt={`car.png-${index}`} className="overview-icons" />}
                        { loan?.loanName === "GOLD LOAN" && <img src="../../../images/loan-overview/Gold.png" alt={`gold.png-${index}`} className="overview-icons" />}
                        { (loan?.loanName === "PERSONAL LOAN" || loan?.loanName === "CONSUMER LOAN") && <img src="../../../images/loan-overview/User.png" alt={`user.png-${index}`} className="overview-icons" />}
                        </div>
                      </div>

                      <div className="me-1">
                        <p className="loan-type">{loan?.loanName}</p>
                        <p className="loan-bank">{loan?.Subscriber_Name}</p>
                      </div>
                      <p className="loan-status-Closed">Closed</p>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div>
                        <p className="loan-amt-title">Outstanding Amount</p>
                        <p className="loan-amt-value">₹{Number(loan?.Current_Balance)?.toLocaleString()}</p>
                      </div>
                      <div>
                        <p className="loan-amt-title">Account Open Date</p>
                        <p className="loan-amt-value" style={{textAlign:"end"}}>
  {loan?.Open_Date
    ? `${loan.Open_Date.slice(0, 4)}-${loan.Open_Date.slice(4, 6)}-${loan.Open_Date.slice(6, 8)}`
    : ""}
</p>                      </div>
                    </div>

                    {/* <button className="loan-details-btn">
                      View Details <i className="bi bi-arrow-right"></i>
                    </button> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

       {loading && 
          <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            position: "fixed",
            top: "0px",
            left: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "10000"
          }}
          >
            <ColorRing
              visible={true}
              height="60"
              width="60"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={[
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
                "#ffffff",
              ]}
            />
          </div>
        }
    </>
  );
};

export default LoanOverview;

import React from "react";
import { Button } from "@mui/material";

export default function ClosingSection() {
  return (
    <section className="text-center space-y-4 py-8">
      <p className="text-xl sm:text-2xl font-bold text-[#26292b]">
        Experience Financial Clarity with Minemi
      </p>
      <p className="text-sm sm:text-base text-[#26292b] max-w-2xl mx-auto">
        At Minemi, we're committed to empowering you with the knowledge and
        tools you need to make informed financial decisions. Say goodbye to
        hidden fees and hello to transparency.
      </p>
      <button
        variant="contained"
        style={{
          backgroundColor: "#59266d",
          color: "white",
          padding: "12px 24px",
          fontSize: "16px",
          borderRadius: "9999px",
          textTransform: "none",
        }}
      >
        Check my Rate
      </button>
    </section>
  );
}

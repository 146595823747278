import { useEffect, useState, useMemo } from "react";

export function TrustIndicators({ name }) {
  const [data, setData] = useState({});

  const kotakData = useMemo(
    () => ({
      h1: "Why Trust MinEMI for Your Kotak Bank Loan?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with military-grade encryption and complies with all banking security standards.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "Kotak Bank Loan Specialists",
          description:
            "Our team includes former Kotak Bank loan officers who understand the bank's internal approval process.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Proven Results with Kotak Bank",
      rating: "4.8/5",
      content:
        "We've helped over 7,200 customers successfully secure Kotak Bank personal loans with a 91% success rate",
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of borrowers across India for loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple bg-[#ebe8fb]"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const idfcData = useMemo(
    () => ({
      h1: "Why Trust MinEMI for Your IDFC FIRST Bank Loan?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with military-grade encryption and complies with all banking security standards.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "IDFC FIRST Bank Loan Specialists",
          description:
            "Our team includes former IDFC FIRST Bank loan officers who understand the bank's internal approval process.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Proven Results with IDFC FIRST Bank",
      rating: "4.8/5",
      content:
        "We've helped over 8,500 customers successfully secure IDFC FIRST Bank personal loans with a 90% success rate",
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of borrowers across India for loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of borrowers across India for loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of borrowers across India for loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of borrowers across India for loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of borrowers across India for loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of borrowers across India for loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of borrowers across India for loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of borrowers across India for loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of borrowers across India for loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of borrowers across India for loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of borrowers across India for loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of borrowers across India for loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of borrowers across India for loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      h1: "Why Trust MinEMI?",
      step: [
        {
          title: "Bank-Grade Security",
          description:
            "Your data is protected with 256-bit encryption and strict privacy controls.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
        },
        {
          title: "50,000+ Happy Customers",
          description:
            "Trusted by thousands of borrowers across India for loan guidance.",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
              <circle cx="9" cy="7" r="4" />
              <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
              <path d="M16 3.13a4 4 0 0 1 0 7.75" />
            </svg>
          ),
        },
      ],
      h3: "Customer Satisfaction",
      rating: "4.8/5",
      content:
        "Based on 5,000+ reviews across Google, Trustpilot, and the App Store",
    }),
    []
  );

  useEffect(() => {
    if (name === "checkkotakbankstatus") {
      setData(kotakData);
    } else if (name === "checkbobbankstatus") {
      setData(bobData);
    } else if (name === "checkidfcbankstatus") {
      setData(idfcData);
    } else if (name === "checkhdfcbankstatus") {
      setData(hdfcData);
    } else if (name === "checksbibankstatus") {
      setData(sbiData);
    } else if (name === "checkaxisbankstatus") {
      setData(axisData);
    } else if (name === "checkicicibankstatus") {
      setData(iciciData);
    } else if (name === "checkpunjabbankstatus") {
      setData(punjabData);
    } else if (name === "checkinduslndbankstatus") {
      setData(induslndData);
    } else if (name === "checktatabankstatus") {
      setData(tataData);
    } else if (name === "checkyesbankstatus") {
      setData(yesData);
    } else if (name === "checkunionbankstatus") {
      setData(unionData);
    } else if (name === "checkbajajbankstatus") {
      setData(bajajData);
    } else if (name === "checkfederalbankstatus") {
      setData(federalData);
    } else if (name === "checkcanarabankstatus") {
      setData(canaraData);
    } else if (name === "checkboibankstatus") {
      setData(boiData);
    } else if (name === "checkrblbankstatus") {
      setData(rblData);
    } else {
      setData({});
    }
  }, [
    name,
    kotakData,
    bobData,
    idfcData,
    hdfcData,
    sbiData,
    axisData,
    iciciData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
  ]);

  return (
    <section className="py-10 border-b border-gray-200 bg-[#ebe8fb]">
      <div className="container px-4 md:px-6 bg-transparent">
        <div className="w-4/5l mx-auto bg-transparent">
          <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple mb-6 text-center">
            {data.h1}
          </h2>
          <div className="grid gap-3 bg-transparent">
            {data.step?.map((d, index) => (
              <div
                key={index}
                className="flex items-start gap-4 bg-white p-4 rounded-lg shadow-sm border border-gray-200"
              >
                <div className="h-10 w-10 rounded-full bg-[#ebe8fb] flex items-center justify-center flex-shrink-0">
                  {d.icon}
                </div>
                <div className="bg-transparent">
                  <h3 className="font-bold text-primary-purple text-base mb-1">
                    {d.title}
                  </h3>
                  <p className="text-sm text-dark-grey bg-transparent">
                    {d.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-3">
            <div className="bg-white p-3 rounded-lg shadow-sm border border-gray-200">
              <h3 className="text-base font-bold text-primary-purple mb-3 text-center">
                {data.h3}
              </h3>
              <div className="flex items-center justify-center gap-3 mb-3 bg-transparent">
                <div className="flex bg-transparent">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <svg
                      key={star}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="#FFD700"
                      stroke="#FFD700"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="mr-1 bg-transparent"
                      aria-label={`Star ${star}`}
                    >
                      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                    </svg>
                  ))}
                </div>
                <span className="font-bold text-primary-purple bg-transparent">
                  {data.rating}
                </span>
              </div>
              <p className="text-dark-grey text-sm text-center bg-transparent">
                {data.content}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

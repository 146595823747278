import React from "react";
import {
  FiCheckCircle,
  FiBarChart2,
  FiFileText,
  FiClock,
} from "react-icons/fi";
import { LuPiggyBank } from "react-icons/lu";
const steps = [
  {
    title: "Check Eligibility",
    description:
      "Provide basic details like your current loan amount, EMI, and credit score to see if you qualify.",
    icon: (
      <FiCheckCircle
        className="w-8 h-8"
        style={{ backgroundColor: "#59266d" }}
      />
    ),
  },
  {
    title: "Compare Options",
    description:
      "Review interest rates and top-up loan offers from leading financial institutions.",
    icon: (
      <FiBarChart2 className="w-8 h-8" style={{ backgroundColor: "#59266d" }} />
    ),
  },
  {
    title: "Apply Online",
    description:
      "Fill out a quick online application form with minimal details and upload required documents.",
    icon: (
      <FiFileText className="w-8 h-8" style={{ backgroundColor: "#59266d" }} />
    ),
  },
  {
    title: "Get Approved",
    description:
      "Receive approval within 24–48 hours after document verification.",
    icon: (
      <FiClock className="w-8 h-8" style={{ backgroundColor: "#59266d" }} />
    ),
  },
  {
    title: "Enjoy Savings",
    description:
      "Transfer your loan, lower your EMI, and access additional funds directly into your account.",
    icon: (
      <LuPiggyBank className="w-8 h-8" style={{ backgroundColor: "#59266d" }} />
    ),
  },
];

function LoanTopupProcess() {
  return (
    <section className="py-6 md:py-16 " style={{ backgroundColor: "#f8f8f8" }}>
      <h2
        className="text-xl md:text-3xl font-bold bg-transparent text-center mb-3 md:mb-4 text-[#59266d]"
        style={{
          color: "#59266D",
          fontSize: "30px",
          lineHeight: "36px",
          fontWeight: "700",
        }}
      >
        Top-Up Made Simple: Your 5-Step Guide
      </h2>
      <p
        className="text-xs md:text-lg text-center bg-transparent mb-6 md:mb-8 max-w-2xl mx-auto"
        style={{
          color: "#59266D",
          fontSize: "18px",
          lineHeight: "28px",
          fontWeight: "400",
        }}
      >
        From application to savings, we make the loan top-up process simple and
        stress-free.
      </p>
      <div
        className="container-fluid  mx-auto px-4"
        style={{ backgroundColor: "#f8f8f8" }}
      >
        <div className="relative mb-6  md:mb-8">
          <div className="overflow-hidden  h-1 md:h-2 mb-2 md:mb-4 text-xs flex rounded bg-purple-200">
            <div
              className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-[#59266d]"
              style={{ width: "100%" }}
            />
          </div>
        </div>

        <div
          className="space-y-3  md:space-y-8"
          style={{ backgroundColor: "#f8f8f8" }}
        >
          {steps.map((step, index) => (
            <div
              key={index}
              className=" p-3 md:p-6 rounded-lg shadow-md bg-white"
            >
              <div className="flex bg-transparent items-start">
                <div className="flex-shrink-0 w-6 h-6 md:w-12 md:h-12 rounded-full  flex items-center justify-center bg-[#59266d] ">
                  <p className="bg-[#59266d] text-white "> {step.icon} </p>
                </div>
                <div className="ml-3  bg-transparent md:ml-4">
                  <h3
                    className="text-base md:text-xl font-semibold mb-1 bg-transparent md:mb-2"
                    style={{
                      color: "#59266D",
                      fontSize: "20px",
                      lineHeight: "28px",
                      fontWeight: "600",
                    }}
                  >
                    {index + 1}. {step.title}
                  </h3>
                  <p
                    className="text-sm bg-transparent md:text-base text-gray-600"
                    style={{
                      color: "#4B5563",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "400",
                    }}
                  >
                    {step.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-8 md:mt-12 bg-transparent text-center">
        <a
          href="/lower-emi-top-up"
          className=""
          onClick={() => {
            localStorage.setItem("id", 3);
            window.scrollTo(0, 0);
          }}
        >
          <button className="bg-[#59266d] text-white px-4 py-2 md:px-8 md:py-3 rounded-full text-sm md:text-lg font-semibold hover:bg-purple-800 transition-colors change-width">
            Start Your Loan Top-Up Today
          </button>
        </a>
      </div>
    </section>
  );
}

export default LoanTopupProcess;

import React, { useEffect, useState } from "react";
import Eligibility from "../CommonComponents/Eligibility/Index";
import CheckRate from "./CheckRate";
import CreditScore from "../CommonComponents/CreditScore/Index";
import Approval from "../CommonComponents/Approval";
import { useLocation } from "react-router-dom";
import axios from "axios";

const Index = () => {
  const location = useLocation();
  const [loanEstimator, setLoanEstimator] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const {
    cibil,
    name,
    amount,
    runningLoan,
    loanamount,
    employmenttype,
    loantype,
    company,
    monthlyincome,
    monthlyemi,
    secured,
    unsecured,
    eligibleLoan,
  } = location.state;

  const LoanEstimatorHandler = async () => {
    console.log("called LoanEstimator")
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      // if (!token) {
      //   console.error("No token found!");
      //   return;
      // }
      const formData = new FormData();
      formData.append("loanAmount", eligibleLoan);

      formData.append("tenure", loantype === "hl" ? "360" : "84");
      formData.append("loanType", loantype);
      formData.append("cibilScore", cibil);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/loanEstimator`,
        formData,
        {
          // headers: {
          //   Authorization: `Bearer ${token}`,
          //   "Content-Type": "multipart/form-data",
          // },
        }
      );

      setLoanEstimator(response.data);
      setLoading(false);
    } catch (error) {
      console.error(
        "Failed to fetch loan estimate:",
        error.response?.data || error.message
      );
      setLoading(false);
    }
  };
  const CheckRateHandler = async () => {
    console.log("called CheckRateHandler")

    setLoading2(true);
    try {
      const token = localStorage.getItem("token");
      // if (!token) {
      //   console.error("No token found!");
      //   return;
      // }

      const formData = new FormData();
      formData.append("loanAmount", loanamount);
      formData.append("loantype", loantype);
      formData.append("monthlyincome", monthlyincome);
      formData.append("monthlyemi", monthlyemi);
      formData.append("employmenttype", employmenttype);
      formData.append("company", company);
      formData.append("cibil", cibil);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/checkRate`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setTableData(response.data.data);
      setLoading2(false);
    } catch (error) {
      console.error(
        "Failed to fetch loan estimate:",
        error.response?.data || error.message
      );
      setLoading2(false);
    }
  };
  useEffect(() => {
    LoanEstimatorHandler();
  }, []);
  useEffect(() => {
    CheckRateHandler();
  }, []);
  return (
    <>

      <Eligibility
        data={{ cibil, name, amount, runningLoan, secured, unsecured }}
      />
      {loading ? (
        <p>Loading credit score...</p>
      ) : loanEstimator ? (
        <>
          {!loading2 && <CheckRate data={{ tableData, loantype }} />}
          {tableData?.loanFound ? (
            <CreditScore
              data={{ loanEstimator, monthlyincome, monthlyemi, cibil,loanamount }}
            />
          ) : null}

        </>
      ) : (
        <p>Failed to load credit score.</p>
      )}
      <Approval />
    </>
  );
};

export default Index;
import { Box, Typography, Grid, Card, CardContent, Button, Container, useMediaQuery } from "@mui/material"
import { styled } from "@mui/system"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { Link } from "react-router-dom"

const theme = createTheme({
  palette: {
    primary: {
      main: "#311664",
    },
    secondary: {
      main: "#3a868b",
    },
  },
})

const StyledIcon = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  borderRadius: "50%",
  width: 40,
  height: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: theme.spacing(1),
  fontSize: 20,
}))

const steps = [
  {
    icon: "✏️",
    title: "Enter Details",
    description: "Input loan amount and basic info",
  },
  {
    icon: "📊",
    title: "Compare Offers",
    description: "View top loan options instantly",
  },
  {
    icon: "✅",
    title: "Choose Best Loan",
    description: "Select the ideal offer for you",
  },
  {
    icon: "🚀",
    title: "Quick Apply",
    description: "Complete application easily",
  },
]
const styles={
    button: {
        backgroundColor: "#3a868b",
        color: "#ffffff",
        border: "none",
        borderRadius: "9999px",
        padding: "12px 24px",
        fontSize: "18px",
        fontWeight: "bold",
        cursor: "pointer",
        transition: "background-color 0.3s",
        display: "block",
        margin: "32px auto 0",
      }
}

export default function HowMinemiWorks() {
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"))

  return (
    <ThemeProvider theme={theme} sx={{backgroundColor:'#EDEAFC'}} lg={{backgroundColor:'#EDEAFC'}}>
      <Box component="section" sx={{ bgcolor: "background.paper", py: { xs: 3, sm: 6 } }} >
        <Container maxWidth="lg"  sx={{ bgcolor:"transparent"}} >
          <Typography variant={isDesktop ? "h2" : "h4"} component="h2" align="center" color="primary"sx={{ bgcolor:"transparent"}} gutterBottom  >
            How Loan Comparison Works – Find Your Best Loan in Minutes!
          </Typography>
          <Grid container spacing={2}  bgcolor="transparent" >
            {steps.map((step, index) => (
              <Grid item xs={6} lg={3} key={index}  sx={{ bgcolor:"transparent"}} >
                <Card sx={{ height: "100%", bgcolor: "#ebe8fc", border: 1, borderColor: "primary.main" }}>
                  <CardContent
                    sx={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" ,
                        bgcolor:"transparent"
                    }}
                  >
                    <StyledIcon>{step.icon}</StyledIcon>
                    <Typography variant="subtitle1" component="h3" color="primary" gutterBottom>
                      {step.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ bgcolor:"transparent",textAlign:'center'}} >
                      {step.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
     

          <button
          style={styles.button}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#59266d")}
          onMouseOut={(e) => (e.target.style.backgroundColor = "#3a868b")}
        >
          Compare Loans Now
         
        </button>
        </Container>
      </Box>
    </ThemeProvider>
  )
}


import React, { useEffect,  useState, useContext } from "react";
import img1 from "./assets/Images/Group 8415 (1).webp";
import img2 from "./assets/Images/Group 8415 (2).webp";
import img3 from "./assets/Images/Group 8415.webp";
import one from "./assets/Images/Frame 371.webp";
import two from "./assets/Images/Frame 371 (1).webp";
import three from "./assets/Images/Frame 371 (2).webp";
import Creditcard from "./assets/Images/Credit card vector 1 (1).webp";
import "react-toastify/dist/ReactToastify.css";

import "./Home.css";
import { useNavigate } from "react-router-dom";
import { ChevronDown} from "lucide-react";
import MetadataContext from "../MetadataContext";

import { Link } from "react-router-dom";

const CreditRecommendationPage = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(() => {
    // Initialize from localStorage if available
    const savedSelection = localStorage.getItem('creditCardSelection');
    return savedSelection || "";
  });
  
  const pageTitle =
    "Find Your Perfect Credit Card Match Today – Get Results in Minutes";
  const pageDescription =
    "Discover the best credit card options based on your spending goals. Get personalized recommendations instantly with no impact on your credit score. Start today for free!";
  const pageKeywords =
    "credit card match, find credit card, best credit cards, no impact on credit score, credit card recommendations, credit card comparison, free credit card service, personalized credit cards, choose spending goals, credit card for you";

  const options = [
    "Shopping",
    "Fuel",
    "Everyday Spending",
    "Travel",
    "Dining, Entertainment, and Wellness",
  ];

  // Save selection to localStorage whenever it changes
  useEffect(() => {
    if (selected) {
      localStorage.setItem('creditCardSelection', selected);
    }
  }, [selected]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Set the page title
    document.title = pageTitle;

    // Update meta description
    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.setAttribute("name", "description");
      document.head.appendChild(metaDescription);
    }
    metaDescription.setAttribute("content", pageDescription);

    // Update meta keywords
    let metaKeywords = document.querySelector('meta[name="keywords"]');
    if (!metaKeywords) {
      metaKeywords = document.createElement("meta");
      metaKeywords.setAttribute("name", "keywords");
      document.head.appendChild(metaKeywords);
    }
    metaKeywords.setAttribute("content", pageKeywords);
  }, [pageTitle, pageDescription, pageKeywords]);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Discover the Most Recommended Credit Cards | Minemi.ai",
      pageDescription:
        "Explore the most recommended credit cards with Minemi.ai. Compare features, rewards, and benefits to find the perfect card for your financial goals",
      pageKeywords: "most recommended credit card,credit card recommendation",
      canonicalUrl: "https://minemi.ai/creditcard/creditcard-recommendation",
    });
  }, [setMetadata]);

  return (
    <>
      <div>
        <div className="bg-purple-50 " style={{ paddingBottom: "3%" }}>
          <div className="bg-purple-50 p-2">
            {/* breadcrumbs */}
            <div
              id="breadcrumbs"
              style={{ background: "transparent" }}
              className="mx-5 my-1"
            >
              <p className="pages_links bg-transparent links ">
                <Link
                  to={"/"}
                  className=" custom-link bg-transparent archivo "
                  style={{
                    cursor: "pointer",
                    fontFamily: "'Archivo', sans-serif",
                  }}
                >
                  Home
                </Link>
                &nbsp;
                <span
                  className="d-none d-md-inline bg-transparent ps-1 px-1 "
                  style={{
                    color: "#59266D",
                    fontFamily: "'Archivo', sans-serif",
                    fontWeight: 800,
                  }}
                >
                  &gt;
                </span>
                &nbsp;
                <Link
                  to={"/creditcard"}
                  underline="hover"
                  className=" custom-link d-none d-md-inline bg-transparent archivo"
                  style={{ cursor: "pointer" }}
                >
                  Credit Card
                </Link>
                &nbsp;
                <span
                  className="d-none d-md-inline bg-transparent ps-1 px-1"
                  style={{
                    color: "#59266d",
                    fontFamily: "'Archivo', sans-serif",
                    fontWeight: 800,
                  }}
                >
                  &gt;
                </span>
                &nbsp;
                <Link
                  underline="hover"
                  className="d-none d-md-inline fw-bold bg-transparent archivo"
                  style={{ color: "#59266d", cursor: "pointer" }}
                >
                  Credit Card Recommendations
                </Link>{" "}
              </p>
            </div>

            <div className="mx-auto max-w-2xl mt-4 pt-1">
              <div className="bg-white rounded-lg p-8 shadow-sm">
                <h1
                  className="text-3xl font-bold text-center text-purple-700 mb-2"
                  style={{ color: "#59266D", backgroundColor: "transparent" }}
                >
                  Find Your Perfect Credit Card Match <br />
                  Today
                </h1>

                <h2
                  className="text-2xl text-gray-700 text-center mb-8"
                  style={{ backgroundColor: "transparent" }}
                >
                  Get results in minutes:
                </h2>

                <div
                  className="space-y-6"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div style={{ backgroundColor: "transparent" }}>
                    <h3
                      className="text-xl text-gray-700 mb-3"
                      style={{ backgroundColor: "transparent" }}
                    >
                      Choose Your Spending Goals:
                    </h3>

                    {/* Custom Select Component */}
                    <div className="relative">
                      <button
                        type="button"
                        className="button-class w-full px-4 py-3 text-left  rounded-md bg-white flex items-center justify-between text-gray-600 hover:border-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500"
                        onClick={() => setIsOpen(!isOpen)}
                        style={{
                          border: "1px solid grey",
                          backgroundColor: "transparent",
                        }}
                      >
                        <span style={{ backgroundColor: "transparent" }}>
                          {selected || "I need a credit card for..."}
                        </span>
                        <ChevronDown
                          className={`w-5 h-5 transition-transform ${
                            isOpen ? "rotate-180" : ""
                          }`}
                          style={{ backgroundColor: "transparent" }}
                        />
                      </button>
                      {isOpen && (
                        <div className="absolute z-50 w-full mt-1 bg-white  rounded-md shadow-lg">
                          {options.map((option) => (
                            <button
                              key={option}
                              className="button-class w-full px-4 py-2 text-left hover:bg-purple-50 text-gray-700"
                              onClick={() => {
                                setSelected(option);
                                setIsOpen(false);
                              }}
                            >
                              {option}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    onClick={() =>
                      navigate("/select-credit-score", {
                        state: { category: selected },
                      })
                    }
                    style={{ backgroundColor: "#59266D" }}
                    className="w-full  hover:bg-purple-800 text-white rounded-md  text-lg font-medium transition-colors h-10"
                  >
                    GET STARTED
                  </button>

                  <p
                    className="text-center text-gray-600 "
                    style={{ backgroundColor: "transparent" }}
                  >
                    This service is completely free and has no impact on your
                    credit score.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "white",
            paddingTop: "3%",
            paddingBottom: "3%",
          }}
        >
          <section
            className="container mx-auto px-4 py-12 md:py-24"
            style={{ backgroundColor: "white" }}
          >
            <div
              className="flex flex-col md:flex-row items-center justify-between gap-12 max-w-6xl mx-auto"
              style={{ backgroundColor: "white" }}
            >
              {/* Image Section */}
              <div
                className="w-full md:w-1/2"
                style={{ backgroundColor: "white" }}
              >
                <div className="relative max-w-[450px] mx-auto">
                  <img
                    src={Creditcard}
                    alt="Credit card illustration"
                    className="w-full h-auto"
                    style={{ backgroundColor: "white" }}
                  />
                </div>
              </div>

              {/* Content Section */}
              <div
                className="w-full md:w-1/2 space-y-8"
                style={{ backgroundColor: "white" }}
              >
                <h1
                  className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900"
                  style={{
                    backgroundColor: "white",
                    fontWeight: 700,
                    fontSize: 30,
                  }}
                >
                  You deserve a great credit card.
                </h1>

                <div className="space-y-6" style={{ backgroundColor: "white" }}>
                  {/* No Impact Feature */}
                  <div
                    className="flex gap-3"
                    style={{ backgroundColor: "white" }}
                  >
                    <div
                      className="flex-shrink-0 w-6 h-6 rounded-full bg-emerald-400 flex items-center justify-center"
                      style={{ marginTop: "1%" }}
                    >
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        style={{ backgroundColor: "#34D399" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                    <div style={{ backgroundColor: "white" }}>
                      <h3
                        className="text-xl font-semibold text-gray-900 mb-1"
                        style={{
                          backgroundColor: "white",
                          fontWeight: 600,
                          fontSize: 18,
                          textAlign: "left",
                        }}
                      >
                        No Impact on Your Credit Score
                      </h3>
                      <p
                        className="text-gray-500"
                        style={{
                          backgroundColor: "white",
                          fontWeight: 400,
                          fontSize: 16,
                        }}
                      >
                        Explore options without affecting credit score.
                      </p>
                    </div>
                  </div>

                  {/* Make Informed Choices Feature */}
                  <div
                    className="flex gap-3"
                    style={{ backgroundColor: "white" }}
                  >
                    <div
                      className="flex-shrink-0 w-6 h-6 rounded-full bg-emerald-400 flex items-center justify-center"
                      style={{ marginTop: "1%" }}
                    >
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        style={{ backgroundColor: "#34D399" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                          style={{ backgroundColor: "white" }}
                        />
                      </svg>
                    </div>
                    <div style={{ backgroundColor: "white" }}>
                      <h3
                        className="text-xl font-semibold text-gray-900 mb-1"
                        style={{
                          backgroundColor: "white",
                          fontWeight: 600,
                          fontSize: 18,
                          textAlign: "left",
                        }}
                      >
                        Make Informed Choices
                      </h3>
                      <p
                        className="text-gray-500"
                        style={{
                          backgroundColor: "white",
                          fontWeight: 400,
                          fontSize: 16,
                        }}
                      >
                        Understand each card's features to select the best one
                        for you.
                      </p>
                    </div>
                  </div>

                  {/* Clear Insights Feature */}
                  <div
                    className="flex gap-3"
                    style={{ backgroundColor: "white" }}
                  >
                    <div
                      className="flex-shrink-0 w-6 h-6 rounded-full bg-emerald-400 flex items-center justify-center"
                      style={{ marginTop: "1%" }}
                    >
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        style={{ backgroundColor: "#34D399" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                    <div style={{ backgroundColor: "white" }}>
                      <h3
                        className="text-xl font-semibold text-gray-900 mb-1"
                        style={{
                          backgroundColor: "white",
                          fontWeight: 600,
                          fontSize: 18,
                          textAlign: "left",
                        }}
                      >
                        Clear, Honest Insights
                      </h3>
                      <p
                        className="text-gray-500"
                        style={{
                          backgroundColor: "white",
                          fontWeight: 400,
                          fontSize: 16,
                        }}
                      >
                        See unbiased details to pick the right card
                        effortlessly.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div className="bg-[#846CA6] pt-14 pb-14">
        <div
          className="container mx-auto flex flex-wrap justify-between px-6 py-12 lg:py-24"
          style={{ backgroundColor: "transparent" }}
        >
          {/* First Item */}
          <div
            className="flex flex-col items-center gap-1 w-full sm:w-1/2 lg:w-1/3"
            style={{ backgroundColor: "transparent", flexDirection: "column" }}
          >
            <div
              className="w-full h-[240px] lg:w-[358px]"
              style={{ backgroundColor: "transparent" }}
            >
              <img
                src={img2}
                className="w-full h-full object-cover"
                style={{ backgroundColor: "transparent" }}
                alt=""
              />
            </div>
            <div
              className="w-[39px] h-[39px]"
              style={{ backgroundColor: "transparent" }}
            >
              <img
                src={one}
                className="w-full h-full object-contain"
                style={{ backgroundColor: "transparent" }}
                alt=""
              />
            </div>
            <div
              className="text-center"
              style={{ backgroundColor: "transparent", alignItems: "center" }}
            >
              <p
                className="text-white font-bold text-lg"
                style={{ backgroundColor: "transparent", textAlign: "center" }}
              >
                Share Your Preferences
              </p>
              <p
                className="text-white text-sm"
                style={{ backgroundColor: "transparent", textAlign: "center" }}
              >
                Tell us what you're looking for,
                <br /> and we’ll ask only the most relevant questions.
              </p>
            </div>

          </div>

          {/* Second Item */}
          <div
            className="flex flex-col items-center gap-1 w-full sm:w-1/2 lg:w-1/3"
            style={{ backgroundColor: "transparent", flexDirection: "column" }}
          >
            <div
              className="w-full h-[240px] lg:w-[358px]"
              style={{ backgroundColor: "transparent" }}
            >
              <img
                src={img3}
                className="w-full h-full object-cover"
                style={{ backgroundColor: "transparent" }}
                alt=""
              />
            </div>
            <div
              className="w-[39px] h-[39px]"
              style={{ backgroundColor: "transparent" }}
            >
              <img
                src={two}
                className="w-full h-full object-contain"
                style={{ backgroundColor: "transparent" }}
                alt=""
              />
            </div>
            <div
              className="text-center"
              style={{ backgroundColor: "transparent" }}
            >
              <p
                className="text-white font-bold text-lg"
                style={{ backgroundColor: "transparent", textAlign: "center" }}
              >
                We Analyze the Best Options
              </p>
              <p
                className="text-white text-sm"
                style={{ backgroundColor: "transparent", textAlign: "center" }}
              >
                Our advanced system reviews credit
                <br /> cards from multiple providers to match you with
                <br /> the best choices for your needs.
              </p>
            </div>
          </div>

          {/* Third Item */}
          <div
            className="flex flex-col items-center gap-1 w-full sm:w-1/2 lg:w-1/3 mx-auto sm:mt-0 mt-6"
            style={{ backgroundColor: "transparent", flexDirection: "column" }}
          >
            <div
              className="w-full h-[240px] lg:w-[358px]"
              style={{ backgroundColor: "transparent" }}
            >
              <img
                src={img1}
                className="w-full h-full object-cover"
                style={{ backgroundColor: "transparent" }}
                alt=""
              />
            </div>
            <div
              className="relative"
              style={{ backgroundColor: "transparent" }}
            >
              <div
                className="w-[39px] h-[39px]"
                style={{ backgroundColor: "transparent" }}
              >
                <img
                  src={three}
                  className="w-full h-full object-contain"
                  style={{ backgroundColor: "transparent" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="text-center"
              style={{ backgroundColor: "transparent" }}
            >
              <p
                className="text-white font-bold text-lg"
                style={{ backgroundColor: "transparent", textAlign: "center" }}
              >
                Review Your Top Picks
              </p>
              <p
                className="text-white text-sm"
                style={{ backgroundColor: "transparent", textAlign: "center" }}
              >
                Explore your personalized options and
                <br /> apply for your ideal card in just a few clicks.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid"
        style={{
          backgroundColor: "#59266D",
          flexDirection: "column",
          gap: 19,
          alignItems: "center",
          padding: 24,
        }}
      >
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 20,
            textAlign: "center",
            color: "white",
            marginBottom: 0,
            backgroundColor: "transparent",
          }}
        >
          Personalized recommendations. Objective ratings.
        </p>
        <p
          style={{
            fontFamily: "Archivo",
            fontWeight: 400,
            fontSize: 15,
            textAlign: "center",
            color: "#E7E4F9",
            marginBottom: 0,
            backgroundColor: "transparent",
          }}
        >
          We’ve combed through countless stacks of credit cards. We’ll show you
          the best cards for you and your needs — along with our objective
          rating of each card.
        </p>
      </div>
      <div></div>
    </>
  );
};

export default CreditRecommendationPage;

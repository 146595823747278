import ComparisonTable from "./ComparisonTable";
import FifthFold from "./FifthFold";
import FirstFold from "./FirstFold";
import FourthFold from "./FourthFold";
import SecondFold from "./SecondFold";
import ThirdFold from "./ThirdFold";
import { useRef } from "react";

export default function Aipage() {
  const secondFoldRef = useRef(null);
  const handleScrollToSecondFold = () => {
    secondFoldRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#f8f8f8] to-[#e8e4f3]">
      <FirstFold onScrollToSecondFold={handleScrollToSecondFold} />
      <div ref={secondFoldRef}>
        <SecondFold />
      </div>
      <ThirdFold />
      <FourthFold onScrollToSecondFold={handleScrollToSecondFold} />
      <ComparisonTable />
      <FifthFold onScrollToSecondFold={handleScrollToSecondFold} />
    </div>
  );
}

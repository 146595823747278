import React from "react";

function HeroSection() {
  return (
    <section className="bg-gradient-to-b from-white to-[#EBE8FC] py-6 md:py-20">
      <div className="container-fluid mx-auto px-4 bg-transparent">
        <div className="max-w-4xl mx-auto bg-transparent">
          <div className="text-center mb-6 md:mb-8 bg-transparent">
            <h1
              className="text-2xl md:text-5xl font-bold mb-2 md:mb-4 bg-transparent"
              style={{
                color: "#59266D",
                fontSize: "48px",
                lineHeight: "48px",
                fontWeight: "700",
              }}
            >
              Lower EMIs, Extra Funds: Top-Up Loans Made Easy
            </h1>
            <p
              className="text-base md:text-xl text-center mb-4 md:mb-8 bg-transparent"
              style={{
                color: "#59266D",
                fontSize: "20px",
                lineHeight: "28px",
                fontWeight: "400",
              }}
            >
              Reduce Your EMIs and unlock additional funds by enhancing your
              existing loan, eliminating the need to apply for a separate loan.
            </p>
          </div>
          <div className="flex flex-col md:flex-row items-center justify-between gap-4 md:gap-8 bg-transparent">
            <div className="w-full md:w-1/2 flex justify-center mb-4 md:mb-0 bg-transparent">
              <img
                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/IMG_1117-m4TNaZwy0ThBgWWMpNuUvEXo2z5FPa.png"
                alt="Financial peace of mind illustration"
                className="object-contain bg-transparent w-full h-auto max-w-[250px] md:max-w-[300px]"
              />
            </div>
            <ul className="w-full md:w-1/2 space-y-2 md:space-y-4 text-sm md:text-base bg-transparent">
              <li className="flex items-center bg-transparent">
                <span className="text-green-500 mr-2 bg-transparent flex-shrink-0 w-4 h-4 md:w-5 md:h-5">
                  ✓
                </span>
                <span className="bg-transparent">Save up to ₹10,000/month</span>
              </li>
              <li className="flex items-center bg-transparent">
                <span className="text-green-500 bg-transparent mr-2 flex-shrink-0 w-4 h-4 md:w-5 md:h-5">
                  ✓
                </span>
                <span className="bg-transparent">
                  Exclusive rates from 8.5% p.a.
                </span>
              </li>
              <li className="flex items-center bg-transparent">
                <span className="text-green-500 bg-transparent mr-2 flex-shrink-0 w-4 h-4 md:w-5 md:h-5">
                  ✓
                </span>
                <span className="bg-transparent">100% Free Service</span>
              </li>
            </ul>
          </div>
          <div className="text-center mt-6 md:mt-8 bg-transparent">
            <a
              href="/login?goal=loan-top-up"
              className=""
              onClick={() => {
                localStorage.setItem("id", 3);
                window.scrollTo(0, 0);
              }}
            >
              <button className="bg-[#59266d] hover:bg-purple-800 text-white py-2 px-6 rounded-lg text-lg">
                Check Your Savings Now
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;

import React from "react";
import { useState } from "react";
import {
  HelpCircle,
  Calculator,
  CreditCard,
  BarChart,
  TrendingUp,
  Shield,
  Home,
  Clock,
  Search,
  CheckCircle,
  ChevronDown,
} from "lucide-react";
import { Accordion } from "@mui/material";
import { AccordionItem } from "react-bootstrap";

export default function FaqSection() {
  const [activeCategory, setActiveCategory] = useState("all");
  // State to track which FAQ is expanded
  const [expandedId, setExpandedId] = useState(null);

  // Toggle function for accordion
  const toggleAccordion = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };
  const styles = {
    section: {
      padding: "2rem 1rem",
      backgroundColor: "white",
      fontFamily: "Arial, sans-serif",
    },
    container: {
      maxWidth: "1000px",
      margin: "0 auto",
    },
    header: {
      textAlign: "center",
      marginBottom: "2rem",
    },
    title: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      color: "#311664",
      marginBottom: "0.5rem",
    },
    subtitle: {
      fontSize: "0.875rem",
      color: "#666",
      maxWidth: "600px",
      margin: "0 auto",
    },
    accordionContainer: {
      backgroundColor: "rgba(235, 232, 252, 0.3)",
      borderRadius: "0.75rem",
      padding: "1rem",
      boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
    },
    accordionItem: {
      borderRadius: "0.5rem",
      overflow: "hidden",
      backgroundColor: "white",
      border: "1px solid rgba(235, 232, 252, 1)",
      boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
    },
    accordionButton: {
      width: "100%",
      textAlign: "left",
      padding: "0.75rem 1rem",
      backgroundColor: "white",
      border: "none",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      transition: "background-color 0.2s",
    },
    accordionButtonHover: {
      backgroundColor: "rgba(235, 232, 252, 0.2)",
    },
    iconContainer: {
      backgroundColor: "rgba(235, 232, 252, 1)",
      padding: "0.375rem",
      borderRadius: "0.375rem",
      marginRight: "0.75rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexShrink: 0,
    },
    icon: {
      width: "1rem",
      height: "1rem",
      color: "#3a868b",
    },
    question: {
      fontSize: "0.875rem",
      fontWeight: "500",
      color: "#311664",
      flex: 1,
    },
    chevron: {
      width: "1rem",
      height: "1rem",
      transition: "transform 0.3s",
    },
    chevronRotated: {
      transform: "rotate(180deg)",
    },
    accordionContent: {
      padding: "0.75rem 1rem 1rem",
      backgroundColor: "white",
      fontSize: "0.875rem",
      color: "#666",
    },
    answer: {
      paddingLeft: "2.5rem",
    },
    ctaContainer: {
      textAlign: "center",
      marginTop: "2rem",
    },
    ctaText: {
      fontSize: "1rem",
      fontWeight: "500",
      color: "#311664",
      marginBottom: "0.75rem",
    },
    button: {
      backgroundColor: "#311664",
      color: "white",
      border: "none",
      borderRadius: "0.375rem",
      padding: "0.5rem 1rem",
      fontSize: "0.875rem",
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
      gap: "0.5rem",
      transition: "background-color 0.2s",
    },
    buttonHover: {
      backgroundColor: "#26124f",
    },
  };

  const faqs = [
    {
      id: "faq-1",
      question:
        "Why should I check my credit card approval chances before applying?",
      answer:
        "Applying for multiple credit cards without knowing your approval chances can hurt your credit score. Minemi helps you apply only where you have high chances of approval.",
      icon: <HelpCircle className="h-3 w-3 md:h-5 md:w-5 text-[#311664]" />,
      category: "general",
    },
    {
      id: "faq-2",
      question: "How does Minemi calculate my approval chances?",
      answer:
        "We analyze credit score, income, existing credit cards, and lender criteria to provide data-backed insights.",
      icon: <Calculator className="h-3 w-3 md:h-5 md:w-5 text-[#311664]" />,
      category: "process",
    },
    {
      id: "faq-3",
      question: "Will checking my chances on Minemi affect my credit score?",
      answer:
        "No! Minemi's check is a soft inquiry and has zero impact on your credit score.",
      icon: <CreditCard className="h-3 w-3 md:h-5 md:w-5 text-[#311664]" />,
      category: "credit",
    },
    {
      id: "faq-4",
      question: "What if my approval chances are low?",
      answer:
        "Minemi will provide personalized tips to improve eligibility, such as increasing your credit score or reducing credit utilization.",
      icon: <BarChart className="h-3 w-3 md:h-5 md:w-5 text-[#311664]" />,
      category: "improvement",
    },
    {
      id: "faq-5",
      question: "Is my personal data safe with Minemi?",
      answer:
        "Yes! We use bank-grade encryption to protect your data. Your information is never shared without your consent.",
      icon: <Shield className="h-3 w-3 md:h-5 md:w-5 text-[#311664]" />,
      category: "security",
    },
    {
      id: "faq-6",
      question: "What types of credit cards does Minemi support?",
      answer: (
        <div>
          <p className="mb-2">We cover all major categories:</p>
          <ul className="list-disc pl-5 space-y-1">
            <li>Cashback Credit Cards</li>
            <li>Travel Credit Cards</li>
            <li>Rewards Credit Cards</li>
            <li>Premium & Lifestyle Credit Cards</li>
            <li>Secured Credit Cards</li>
          </ul>
        </div>
      ),
      icon: <CreditCard className="h-3 w-3 md:h-5 md:w-5 text-[#311664]" />,
      category: "cards",
    },
    {
      id: "faq-7",
      question: "How long does it take to check my approval chances?",
      answer:
        "Less than 2 minutes! Just fill in a few details and get instant results.",
      icon: <Clock className="h-3 w-3 md:h-5 md:w-5 text-[#311664]" />,
      category: "process",
    },
    {
      id: "faq-8",
      question: "Can I apply for a credit card through Minemi?",
      answer:
        "Yes! After checking your eligibility, you can directly apply for the best-matched credit card from our partner banks.",
      icon: <Search className="h-3 w-3 md:h-5 md:w-5 text-[#311664]" />,
      category: "application",
    },
    {
      id: "faq-9",
      question: "How accurate are Minemi's approval predictions?",
      answer:
        "Our AI-powered system uses real data from major credit card issuers to provide highly accurate predictions. While final approval depends on the issuer, our predictions are typically 90%+ accurate.",
      icon: <TrendingUp className="h-3 w-3 md:h-5 md:w-5 text-[#311664]" />,
      category: "accuracy",
    },
    {
      id: "faq-10",
      question: "Will I get unwanted calls after using Minemi?",
      answer:
        "Absolutely not. We respect your privacy and will never share your information with telemarketers or make unsolicited calls. You're in control of who contacts you.",
      icon: <CheckCircle className="h-3 w-3 md:h-5 md:w-5 text-[#311664]" />,
      category: "privacy",
    },
  ];

  const categories = [
    {
      id: "all",
      name: "All Questions",
      icon: <HelpCircle className="h-4 w-4" />,
    },
    {
      id: "general",
      name: "General",
      icon: <HelpCircle className="h-4 w-4" />,
    },
    {
      id: "process",
      name: "Process",
      icon: <Calculator className="h-4 w-4" />,
    },
    {
      id: "credit",
      name: "Credit Score",
      icon: <CreditCard className="h-4 w-4" />,
    },
    { id: "security", name: "Security", icon: <Shield className="h-4 w-4" /> },
    { id: "loans", name: "Loan Types", icon: <Home className="h-4 w-4" /> },
  ];

  const filteredFaqs =
    activeCategory == "all"
      ? faqs
      : faqs.filter((faq) => faq.category === activeCategory);
  return (
    <section
      className="w-full py-8 md:py-16 "
      style={{ backgroundColor: "#f8f8f8" }}
    >
      <div className="container mx-auto px-4">
        <div className="text-center mb-8 md:mb-12">
          <h2 className="text-2xl md:text-4xl font-bold text-[#311664] mb-2 md:mb-4">
            Credit Card Approval FAQ
          </h2>
          <p className="text-base md:text-lg text-gray-600 max-w-2xl mx-auto">
            Everything you need to know before checking your credit card
            approval chances.{" "}
          </p>
        </div>

        {/* Category filters - scrollable on mobile */}
        <div className="mb-8 overflow-x-auto pb-2">
          <div
            className="flex space-x-2 min-w-max"
            style={{ justifyContent: "center" }}
          >
            {categories.map((category) => (
              <button
                key={category.id}
                onClick={() => setActiveCategory(category.id)}
                className={`flex items-center px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  activeCategory === category.id
                    ? "bg-[#311664] text-white"
                    : "bg-white text-[#311664] hover:bg-[#ebe8fc]"
                }`}
              >
                <span className="mr-2">{category.icon}</span>
                {category.name}
              </button>
            ))}
          </div>
        </div>

        <div className="max-w-3xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
          <Accordion type="single" collapsible className="w-full">
            {filteredFaqs.map((faq) => (
              <div key={faq.id} style={styles.accordionItem}>
                <button
                  style={styles.accordionButton}
                  onClick={() => toggleAccordion(faq.id)}
                >
                  <div style={styles.iconContainer}>{faq.icon}</div>
                  <span style={styles.question}>{faq.question}</span>
                  <ChevronDown
                    style={{
                      ...styles.chevron,
                      ...(expandedId === faq.id && styles.chevronRotated),
                    }}
                  />
                </button>
                {expandedId === faq.id && (
                  <div style={styles.accordionContent}>{faq.answer}</div>
                )}
              </div>
            ))}
          </Accordion>
        </div>

        <div className="mt-8 text-center">
          <p className="text-sm text-gray-500">
            Don't see your question here?{" "}
            <a href="#" className="text-[#3a868b] font-medium hover:underline">
              Contact our support team
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}

import { useState, useEffect } from "react";
import { Button, Card, CardContent, IconButton } from "@mui/material";
import { FormatQuote, ChevronLeft, ChevronRight } from "@mui/icons-material";
import { styled } from "@mui/system";

const testimonials = [
  {
    name: "Rajesh Kumar",
    city: "Mumbai",
    quote:
      "Minemi helped me understand how APR works, and I finally chose the best personal loan for my daughter's wedding. I saved over ₹25,000 in hidden costs!",
    verifiedSince: "2023",
  },
  {
    name: "Aditi Shah",
    city: "Ahmedabad",
    quote:
      "As a small business owner, comparing APR on loans was always confusing. Minemi made it so easy. Their tools saved me hours and helped me grow my business.",
  },
  {
    name: "Anil Mehta",
    city: "Bengaluru",
    quote:
      "I used to ignore APR when signing up for credit cards. Thanks to Minemi, I learned how much I was overpaying and switched to a better plan. It's been a game-changer!",
  },
  {
    name: "Priya Sharma",
    city: "Delhi",
    quote:
      "Understanding APR has made me a smarter investor and borrower. Minemi's step-by-step guides gave me the clarity I needed.",
  },
];

const GradientSection = styled("section")(({ theme }) => ({
  background: "linear-gradient(to right, #311664, #59266d)",
  color: "white",
  padding: theme.spacing(6, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(8, 0),
  },
}));

const AvatarCircle = styled("div")(({ theme }) => ({
  width: 48,
  height: 48,
  borderRadius: "50%",
  backgroundColor: "#3a868b",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontWeight: "bold",
  fontSize: "1.25rem",
  marginRight: theme.spacing(2),
}));

const NavButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "white",
  color: "#59266d",
  "&:hover": {
    backgroundColor: "#ebe8fc",
  },
}));

export default function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  useEffect(() => {
    const timer = setInterval(nextTestimonial, 5000);
    return () => clearInterval(timer);
  }, [nextTestimonial]); // Added nextTestimonial to dependencies

  return (
    <GradientSection>
      <div
        style={{
          maxWidth: 1200,
          margin: "0 auto",
          padding: "0 16px",
          backgroundColor: "transparent",
        }}
      >
        <h4 align="center" gutterBottom className="bg-transparent text-3xl">
          Real Stories. Real Impact.
        </h4>
        <p
          variant="body1"
          align="center"
          paragraph
          className="card_para text-lg text-center bg-transparent"
          style={{ maxWidth: "100%", margin: "10px auto 32px" }}
        >
          Here's how Minemi has empowered everyday Indians to make smarter
          financial decisions through a better understanding of APR.
        </p>
        <div
          style={{
            position: "relative",
            maxWidth: "100%",
            margin: "0 auto",
            borderRadius: "15px",
          }}
        >
          <Card
            raised
            style={{ background: "transparent", borderRadius: "10px" }}
          >
            <CardContent
              style={{
                padding: 32,
                background: "transparent",
                borderRadius: "10px",
              }}
            >
              <FormatQuote
                style={{
                  color: "#3a868b",
                  fontSize: 40,
                  marginBottom: 16,
                  backgroundColor: "transparent",
                }}
              />
              <p
                className="bg-transparent card_para text-start"
                style={{ fontSize: "16px" }}
              >
                {testimonials[currentIndex].quote}
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "18px",
                }}
              >
                <AvatarCircle>
                  {testimonials[currentIndex].name[0]}
                </AvatarCircle>
                <div>
                  <p variant="subtitle1" className="card_para">
                    {testimonials[currentIndex].name}
                  </p>
                  <p
                    variant="body2"
                    color="textSecondary"
                    className="card_pare"
                  >
                    {testimonials[currentIndex].city}
                  </p>
                  {testimonials[currentIndex].verifiedSince && (
                    <p
                      variant="body2"
                      style={{ color: "#3a868b" }}
                      className="card_pare"
                    >
                      Verified User since{" "}
                      {testimonials[currentIndex].verifiedSince}
                    </p>
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
          {/* <NavButton
            onClick={prevTestimonial}
            style={{ left: -24, backgroundColor: "transparent" }}
            size="large"
            aria-label="Previous testimonial"
          >
            <ChevronLeft />
          </NavButton> */}
          {/* <NavButton
            onClick={nextTestimonial}
            style={{ right: -24, backgroundColor: "transparent" }}
            size="large"
            aria-label="Next testimonial"
          >
            <ChevronRight />
          </NavButton> */}
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: 60,
            backgroundColor: "transparent",
          }}
        >
          <button
            variant="contained"
            size="large"
            style={{
              backgroundColor: "#3a868b",
              color: "white",
              borderRadius: 9999,
              padding: "12px 24px",
              fontSize: "1rem",
              textTransform: "none",
            }}
          >
            Check My Rate
          </button>
        </div>
      </div>
    </GradientSection>
  );
}

import React from "react";
import "./TableData.css";
import { useMediaQuery } from "@mui/material";

const TableData = ({ onButtonClick }) => {
  const data = [
    { lender: "State Bank of India", rate: "8.50%", fees: "₹1,250/-" },
    { lender: "HDFC Bank", rate: "8.75%", fees: "₹1,250/-" },
    { lender: "ICICI Bank", rate: "8.75%", fees: "₹1,250/-" },
    { lender: "Bank of Baroda", rate: "8.40%", fees: "₹1,250/-" },
    { lender: "Punjab National Bank", rate: "8.40%", fees: "₹1,250/-" },
    { lender: "Axis Bank", rate: "8.75%", fees: "₹1,250/-" },
  ];

  // useMediaQuery
  const smallScreen = useMediaQuery("(max-width:576px)");
  const mediumScreen = useMediaQuery("(max-width:768px)");
  const largeScreen = useMediaQuery("(max-width:992px)");

  return (
    <>
      <div id="table-data-container">
        <div>
          <div className="table-heading-text-box ">
            <h3 className="table-heading-text">
              Compare Top Up Loan Offers & Choose the Best One!
            </h3>
          </div>
        </div>
        <div className="table-container">
          <table className="responsive-table">
            <thead>
              <tr>
                <th>Name of Lender</th>
                <th>Interest Rate (p.a.)</th>
                <th>Processing Fees</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td>{row.lender}</td>
                  <td>{row.rate}</td>
                  {/* <td>{row.tenure}</td> */}
                  <td>
                    {row.fees}

                    {/* <a href={row.link} className="apply-btn">
                      Apply Now
                    </a> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          id="apply-now-btn"
          className="mt-lg-4"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            id="table-button-box"
            className="pt-3 pt-md-4 bg-transparent"
            style={{
              width: smallScreen
                ? "9rem"
                : mediumScreen
                ? "15rem"
                : largeScreen
                ? "15rem"
                : "15rem",
            }}
          >
            <button
              onClick={onButtonClick}
              style={{
                width: "100%",
                backgroundColor: "#59266D",
                color: "#ffff",
                fontSize: smallScreen
                  ? "15px"
                  : mediumScreen
                  ? "15px"
                  : largeScreen
                  ? "20px"
                  : "24px",
                fontWeight: 500,
                lineHeight: smallScreen ? "15px" : "32px",
                borderRadius: smallScreen ? "4px" : "10px",
                padding: smallScreen
                  ? "0.6rem 1.8rem"
                  : mediumScreen
                  ? "0.6rem 5rem"
                  : largeScreen
                  ? "0.6rem 2rem"
                  : "1rem 0rem",
                fontFamily: "'Archivo',sans-serif",
                letterSpacing: "-0.17px",
                textTransform: "none",
                textAlign: "center",
              }}
              variant="contained"
            >
              Apply Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableData;

import { useEffect, useState } from "react";
import { Check } from "lucide-react";
import { useNavigate } from "react-router-dom";
export default function HeroSection({ scrollToFilter }) {
  const [loanAmount, setLoanAmount] = useState("500000");
  const navigate = useNavigate();

  const styles = {
    section: {
      background: "linear-gradient(to bottom, #ebe8fc, #f8f8f8)",
      padding: "16px",
    },
    container: {
      maxWidth: "1200px",
      margin: "0 auto",
      backgroundColor: "transparent",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gap: "24px",
      backgroundColor: "transparent",
    },
    heading: {
      fontSize: "24px",
      fontWeight: "bold",
      color: "#311664",
      marginBottom: "16px",
      textAlign: "center",

      backgroundColor: "transparent",
    },
    subheading: {
      backgroundColor: "rgba(255,255,255,0.5)",
      border: "1px solid #311664",
      padding: "16px",
      marginBottom: "24px",
      borderRadius: "8px",
    },
    featureBox: {
      backgroundColor: "white",
      padding: "16px",
      borderRadius: "8px",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      marginBottom: "24px",
    },
    featureItem: {
      display: "flex",
      alignItems: "center",
      marginBottom: "8px",
      backgroundColor: "transparent",
    },
    checkIcon: {
      color: "green",
      marginRight: "8px",
      backgroundColor: "transparent",
    },
    button: {
      backgroundColor: "#3a868b",
      color: "white",
      border: "none",
      borderRadius: "9999px",
      padding: "12px 24px",
      fontSize: "18px",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    loanComparisonBox: {
      backgroundColor: "white",
      border: "4px solid #311664",
      borderRadius: "8px",
      padding: "24px",
      position: "relative",
      overflow: "hidden",
    },
    loanComparisonHeader: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "8px",
      backgroundColor: "#3a868b",
    },
    input: {
      width: "100%",
      padding: "8px",
      marginBottom: "16px",
      border: "1px solid #311664",
      borderRadius: "4px",
      backgroundColor: "transparent",
    },
  };

  const formatCurrency = (value) => {
    const number = Number.parseInt(value.replace(/[^0-9]/g, ""));
    return isNaN(number)
      ? ""
      : number.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          maximumFractionDigits: 0,
        });
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setLoanAmount(value);
  };

  return (
    <>
      <section className="bg-gradient-to-b from-[#ebe8fc] to-white text-[#26292b] py-8 md:py-16 px-4 md:px-8 pt-5">
        <div
          className="max-w-7xl mx-auto"
          style={{ backgroundColor: "transparent" }}
        >
          <div
            className="grid md:grid-cols-2 gap-6 md:gap-12 items-start"
            style={{ backgroundColor: "transparent" }}
          >
            {/* Left Column */}
            <div
              className="text-center md:text-left space-y-4 md:space-y-6"
              style={{ backgroundColor: "transparent" }}
            >
              <h1
                className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-[#311664] leading-tight"
                style={{ backgroundColor: "transparent" }}
              >
                Drive Into 2025 with the Best Car Loan Deals – Only on Minemi!
              </h1>
              <h2
                className="text-base sm:text-lg md:text-xl font-semibold text-[#311664]"
                style={{ backgroundColor: "transparent" }}
              >
                Compare, Choose, and Secure Your Dream Car Loan with Unbeatable
                Rates from India's Top Banks.
              </h2>
              <h3
                className="text-xl sm:text-2xl md:text-3xl font-semibold text-[#3a868b]"
                style={{ backgroundColor: "transparent" }}
              >
                Ready to hit the road in your dream car?
              </h3>
              <div className="bg-white bg-opacity-90 border border-[#311664] rounded-lg p-4 md:p-6 shadow-md max-w-[500px] mx-auto md:mx-0">
                <p
                  className="text-sm md:text-base text-[#311664] leading-relaxed"
                  style={{ backgroundColor: "transparent" }}
                >
                  Get personalized car loan recommendations in seconds and
                  compare the best options from leading Indian banks based on
                  your preferences. Minemi helps you secure the perfect deal,
                  whether you're a first-time buyer or upgrading to a new ride.
                </p>
              </div>
              <div
                style={{ marginTop: "40px", backgroundColor: "transparent" }}
              >
                <button
                  onClick={scrollToFilter}
                  style={styles.button}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = "#59266d")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = "#3a868b")
                  }
                >
                  Compare Loans Nows
                </button>
              </div>
            </div>

            {/* Right Column */}
            <div
              className="space-y-6"
              style={{ backgroundColor: "transparent" }}
            >
              <div className="bg-white rounded-lg shadow-md p-4 md:p-6 border border-[#311664] max-w-[500px] mx-auto">
                <div
                  className="grid grid-cols-1 gap-4"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div
                    className="flex items-center justify-start"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Check
                      className="text-green-500 w-5 h-5 mr-2 flex-shrink-0"
                      style={{ backgroundColor: "transparent" }}
                    />
                    <span
                      className="text-sm md:text-base font-medium text-[#311664]"
                      style={{ backgroundColor: "transparent" }}
                    >
                      Verified Offers
                    </span>
                  </div>
                  <div
                    className="flex items-center justify-start"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Check
                      className="text-green-500 w-5 h-5 mr-2 flex-shrink-0"
                      style={{ backgroundColor: "transparent" }}
                    />
                    <span
                      className="text-sm md:text-base font-medium text-[#311664]"
                      style={{ backgroundColor: "transparent" }}
                    >
                      Secure & Transparent
                    </span>
                  </div>
                  <div
                    className="flex items-center justify-start"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Check
                      className="text-green-500 w-5 h-5 mr-2 flex-shrink-0"
                      style={{ backgroundColor: "transparent" }}
                    />
                    <span
                      className="text-sm md:text-base font-medium text-[#311664]"
                      style={{ backgroundColor: "transparent" }}
                    >
                      4.8/5 Customer Satisfaction
                    </span>
                  </div>
                </div>
              </div>
              <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg border-2 border-[#311664] relative overflow-hidden">
                <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-[#311664] via-[#59266d] to-[#3a868b]"></div>
                <div
                  className="flex justify-center mb-4"
                  style={{ backgroundColor: "transparent" }}
                >
                  <img
                    src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/IMG_1304-VulJed4sxlVHeoxGkWSlqfZI6iqxpa.png"
                    alt="Rupee Card"
                    width={100}
                    height={50}
                    className="transform -rotate-12"
                    style={{ backgroundColor: "transparent" }}
                  />
                </div>
                <h2
                  className="text-xl md:text-2xl font-bold text-[#311664] mb-3 text-center"
                  style={{ backgroundColor: "transparent" }}
                >
                  Compare Loan Rates in India – Get the Best Offer in 2 Minutes!
                </h2>
                <div
                  className="space-y-4 mb-6"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div
                    className="flex items-center"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Check
                      className="text-green-500 w-5 h-5 mr-2 flex-shrink-0"
                      style={{ backgroundColor: "transparent" }}
                    />
                    <span
                      className="text-sm md:text-base font-medium text-[#311664]"
                      style={{ backgroundColor: "transparent" }}
                    >
                      Check car loan rates from top Indian banks & NBFCs
                    </span>
                  </div>
                  <div
                    className="flex items-center"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Check
                      className="text-green-500 w-5 h-5 mr-2 flex-shrink-0"
                      style={{ backgroundColor: "transparent" }}
                    />
                    <span
                      className="text-sm md:text-base font-medium text-[#311664]"
                      style={{ backgroundColor: "transparent" }}
                    >
                      Explore car loans up to ₹50 Lakhs
                    </span>
                  </div>
                  <div
                    className="flex items-center"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Check
                      className="text-green-500 w-5 h-5 mr-2 flex-shrink-0"
                      style={{ backgroundColor: "transparent" }}
                    />
                    <span
                      className="text-sm md:text-base font-medium text-[#311664]"
                      style={{ backgroundColor: "transparent" }}
                    >
                      No impact on your CIBIL score
                    </span>
                  </div>
                </div>
                <div
                  className="space-y-4"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div style={{ backgroundColor: "transparent" }}>
                    <label
                      htmlFor="loanAmount"
                      className="block text-sm md:text-base font-medium text-[#311664] mb-1"
                      style={{ backgroundColor: "transparent" }}
                    >
                      Enter Car Loan Amount
                    </label>
                    <input
                      id="loanAmount"
                      type="text"
                      value={formatCurrency(loanAmount)}
                      onChange={handleInputChange}
                      className="w-full rounded-md  focus:border-[#3a868b] focus:ring-[#3a868b] p-2"
                      placeholder="₹5,00,000"
                      style={{
                        border: "1px solid #311664 ",
                        backgroundColor: "transparent",
                      }}
                    />
                  </div>
                  <button
                    className="w-full bg-[#3a868b] hover:bg-[#59266d] text-white text-base sm:text-lg py-3 sm:py-4 rounded-lg transition-colors duration-300"
                    onClick={() => navigate("/loan-recommendation")}
                  >
                    Check Car Loan Rates
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useEffect, useContext } from "react";
import "./TypesOfHomeLoan.css";
import { Link } from "react-router-dom";
import MetadataContext from "../../MetadataContext";

const TypesOfHomeLoan = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        "Types of Home Loans: Comprehensive Guidance for Better Decisions",
      pageDescription:
        "Home loans are crucial for buying a home. At Minemi, we provide key information and insights to help you make informed decisions.",
      pageKeywords:
        "NRI home loans, home loans for Non-Resident Indians, NRI property loans, eligibility for NRI home loans, NRI loan documentation, best NRI loan offers, property investment for NRIs, NRI mortgage loans, NRI home loan guide, home loan options for NRIs",

      canonicalUrl: "https://minemi.ai/loanpage/homeloan/types-of-home-loan",
    });
  }, [setMetadata]);
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className="custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/homeloan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Home Loan
              </Link>{" "}
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Types of Home Loans
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "46px",
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Types of Home Loans: Comprehensive Guidance for Better Decisions{" "}
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "20px",
            }}
          >
            Home loans are essential financial tools that help individuals
            secure their dream homes. At Minemi, we are dedicated to providing
            you with comprehensive information to help you make informed and
            responsible decisions. Explore the different types of home loans,
            understand when they are most beneficial, and navigate through
            dedicated pages for detailed insights and guidance.
          </p>
        </div>

        <div style={{ marginLeft: "4%", marginRight: "4%" }}>
          <div>
            <div
              style={{
                marginTop: 35,
                color: "#59266D",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              1) Standard Home Loan:
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Purpose:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Used to purchase an existing property, whether it’s a flat, house,
              or bungalow.
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Key Points to Consider:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Offers competitive interest rates, flexible repayment terms, and
              typically requires a down payment of 10-20% of the property’s
              value.
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              When to Take:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Consider this loan when you are ready to purchase a residential
              property and need financial support to do so.
            </div>
            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              How Much to Take:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Typically, the loan amount can cover up to 90% of the property’s
              value, depending on the bank and the borrower’s eligibility.
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 30,
                color: "#59266D",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              2) Home Construction Loan
            </div>
            {/* <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Celebrate your special day without compromising financial stability.
          </div> */}
            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Purpose:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              For individuals looking to build a house from scratch on a plot of
              land.
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Key Points to Consider:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              The loan amount is disbursed in stages, based on the progress of
              construction. You only pay interest on the disbursed amount during
              the construction phase.
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              When to Take:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Opt for this loan if you already own land and want to construct a
              house on it.
            </div>
            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              How Much to Take:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              The loan amount can be up to 85-90% of the total construction
              cost, depending on the lender’s policies.
            </div>
          </div>
          <div>
            <div
              style={{
                marginTop: 30,
                color: "#59266D",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              3) Home Improvement Loan
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Purpose:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              To renovate, repair, or upgrade your existing home.
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Key Points to Consider:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              This loan has a smaller loan amount compared to standard home
              loans and is often disbursed in one lump sum.
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              When to Take:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Consider this loan if you are planning to renovate or upgrade your
              home, such as adding new features, repairing structures, or
              repainting.
            </div>
            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              How Much to Take:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Loan amounts typically range between ₹1 lakh to ₹25 lakh,
              depending on the extent of improvement.
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 30,
                color: "#59266D",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              4) Balance Transfer Home Loan
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Purpose:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              To transfer your existing home loan from one bank to another to
              benefit from a lower interest rate or better terms.
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Key Points to Consider:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Helps in reducing your EMI by transferring the loan to a bank
              offering lower interest rates. Some banks also offer top-up loans
              along with a balance transfer.
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              When to Take:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Ideal when you want to reduce your monthly payments or if you are
              dissatisfied with your current lender’s terms.
            </div>
            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              How Much to Take:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              The loan amount is the remaining balance of your existing loan,
              plus any additional amount you wish to borrow for renovation or
              other purposes.
            </div>

            {/* Add a Button */}
            <div style={{ marginTop: 20 }}>
              <button
                onClick={() => {
                  window.location.href =
                    "/loanpage/homeloan/home-loan-balance-transfer";
                }}
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#59266D",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
              >
                Read More
              </button>
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 30,
                color: "#59266D",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              5) Plot Loan
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Purpose:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              For purchasing land or a plot of land for construction or
              investment purposes.
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Key Points to Consider:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              The loan is typically offered for purchasing residential plots.
              You can either buy an empty plot or land that already has some
              basic infrastructure.
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              When to Take:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Consider a plot loan if you want to buy land for future
              construction or investment.
            </div>
            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              How Much to Take:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              The loan amount is generally up to 80-85% of the plot's market
              value, depending on the lender’s policy and the borrower’s
              eligibility.
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 30,
                color: "#59266D",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              6) NRI Home Loan
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Purpose:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Specifically designed for non-resident Indians (NRIs) looking to
              buy property in India.{" "}
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              Key Points to Consider:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              NRIs can avail of loans for purchasing, constructing, or
              renovating properties in India. The loan can be repaid through NRE
              or NRO accounts.
            </div>

            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              When to Take:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Opt for this loan if you are an NRI wanting to invest in property
              in India.
            </div>
            <div
              style={{
                marginTop: 10,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 20,
              }}
            >
              How Much to Take:
            </div>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Typically, lenders offer up to 80-90% of the property value,
              depending on eligibility.
            </div>
          </div>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            These home loan types cater to various needs, from purchasing
            property to improving or constructing a home. Ensure that you select
            the one that best matches your requirements and financial situation.
          </div>
          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br className="display-none" /> and tailored just for you—apply
                now to see what you qualify for!
              </p>
              <div
                style={{
                  backgroundColor: "transparent",
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                }}
              >
                <a
                  href="https://minemi.ai/home-loan/check-eligibility"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 50,
                    paddingRight: 50,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                >
                  Check Eligiblity Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TypesOfHomeLoan;

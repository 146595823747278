import React, { useRef, useState, useEffect } from "react";
import "./LowerYourEmiPage.css";
import {useMediaQuery } from "@mui/material";
import vectorOne from "../../../../Assets/Images/FrameOne.webp";
import vectorTwo from "../../../../Assets/Images/FrameTwo.webp";
import vectorThree from "../../../../Assets/Images/FrameThree.webp";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import ListOfDocuments from "./Steps/StepTwo/ListOfDocuments.jsx";
import TableData from "./Steps/StepThree/TableData.jsx";
import Testimonials from "./Steps/StepFour/Testimonials.jsx";
import FAQ from "./FAQ/FAQ.jsx";
import { useNavigate } from "react-router-dom";
import ChooseMinemi from "./Steps/StepFive/ChooseMinemi.jsx";
import { useContext } from "react";
import { UserContext } from "../../../ContextAPI/FormContextAPI.jsx";
import TopUpMinemi from "./Steps/StepOne/TopUpMinemi.jsx";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import MetadataContext from "../../../MetadataContext.js";
import { ColorRing } from "react-loader-spinner";

// import { UserContext } from "../../ContextAPI/";
// import { useNavigate } from "react-router-dom";

const LowerYourEmiPage = ({ setFormData }) => {
  const { userData, setUserData } = useContext(UserContext) || {
    userData: {},
    setUserData: () => {},
  };
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [isChecked, setIsChecked] = useState(false);

  // state for terms & privecy
  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");
  const [htmlContentTwo, setHtmlContentTwo] = useState("");
  const [loader, setLoader] = useState(false);
  const gtag_report_conversion = () => {
    if (typeof window.gtag !== "undefined") {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-16847657594/W1BOCO3G9ZcaEPq0y-E-',
        'value': 1.0,
        'currency': 'INR',
        'event_callback': () => console.log("Conversion tracked"),
      });
      return true; // Returning true if gtag exists
    } else {
      console.error("gtag is not available");
      return false; // Returning false in case of an error
    }
  };

  const validate = () => {
    let newErrors = {};

    if (!userData.fname) newErrors.fname = "First Name is required";
    if (!userData.lname) newErrors.lname = "Last Name is required";
    if (!userData.email) {
      newErrors.email = "Email is required";
      setLoader(false);
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      newErrors.email = "Invalid email format";
      setLoader(false);
    }

    if (!userData.mobile) {
      newErrors.mobile = "Mobile number is required";
      setLoader(false);
    } else if (!/^\d{10}$/.test(userData.mobile)) {
      newErrors.mobile = "Mobile number must be 10 digits";
      setLoader(false);
    }

    // if (!userData.address) newErrors.address = "Address is required";
    if (!isChecked) newErrors.checkbox = "You must accept Terms & Conditions";

    setErrors(newErrors);
    setLoader(false);
    return Object.keys(newErrors).length === 0;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "mobile") {
      if (!/^\d*$/.test(value)) return;
      if (value.length > 10) return;
    }
    if (name === "fname" && !/^[A-Za-z\s]*$/.test(value)) {
      return; // Stop updating if input is invalid
    }
    if (name === "lname" && !/^[A-Za-z\s]*$/.test(value)) {
      return; // Stop updating if input is invalid
    }

    setUserData({ ...userData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setErrors((prevErrors) => ({ ...prevErrors, checkbox: "" }));
  };

  const handleSubmit = async (e) => {
    gtag_report_conversion()
    setLoader(true);
    e.preventDefault();
    if (!validate()) return;
    if (!validate()) {
      return;
    }
    try {
      setLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_SECRET_KEY}/login`,
        userData
      );
      // console.log("send-data:", response);

      if (response.status === 200) {
        navigate("/top-up-check-eligibility");
        setLoader(false);
      }
    } catch (error) {
      console.error("Error making POST request", error);
      setLoader(false);
    }
  };

  localStorage.removeItem("fromData");

  // useRef to reference the form
  const formRef = useRef(null);

  const scrollToForm = () => {
    // Scroll to the form section
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // terms & privecy

  // terms of use
  const CompanyDetail = () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/webData`,
    };

    axios
      .request(options)
      .then(function (response) {
        setHtmlContent(response.data.data.term_of_use);
        setHtmlContentTwo(response.data.data.privacy_policy);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    CompanyDetail();
  }, []);

  // useMediaQuery

  const smallScreen = useMediaQuery("(max-width:576px)");

  const mediumScreen = useMediaQuery("(max-width:768px)");
  const largeScreen = useMediaQuery("(max-width:992px)");

  // const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    userData.purpose = userData.purpose || "/lower-emi-top-up";

  }, []);
  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Lower Your EMI with Top-Up Loans | Minemi.ai",
      pageDescription:
        "Reduce your EMI burden with Minemi.ai's top-up loans. Get extra funds at lower interest rates and optimize your existing loan for better financial management.",
      pageKeywords:
        "top-up loans, lower EMI, loan optimization, extra loan funds, best top-up loan options",
      canonicalUrl: "https://minemi.ai/lower-emi-top-up",
    });
  }, [setMetadata]);

  return (
    <>
      {/* <Helmet>
        <title>
          Lower your EMI, reduce interest, and get extra funds—quick & easy!
        </title>
        <meta
          name="description"
          content="Discover the lowest interest rates on home loans. Compare offers from top lenders to find the best home loan that fits your financial goals. Apply today and save more!"
        />
        <meta
          name="keywords"
          content="Home Loan Offers, Lowest Home Loan Interest Rates, Affordable Home Loans, Home Loan Comparison, Best Home Loan Deals, Easy Home Loan Application, Home Financing Options"
        />
        <link rel="icon" type="image/webp" href="./mLogo.webp" />
      </Helmet> */}

      <div id="lower-emi-landing-page-container">
        <div id="bt-hero-section">
          {/* <div className="img-container">
            <img id="minemi-logo" src={Logo} alt="MinemiLogo" />
          </div> */}
          <section
            className="bt-page-content d-flex justify-content-between  w-100 "
            style={{ gap: "6rem" }}
          >
            <div
              className="main-bt-container"
              style={{ width: "58%", backgroundColor: "transparent" }}
            >
              <div className="bt-landing-page-content">
                <div id="top-heading-box">
                  <h1 className="bt-heading">
                    Lower Your EMI with{" "}
                    <span
                      style={{
                        color: "#E7E4F9",
                        backgroundColor: "transparent",
                      }}
                    >
                      Top-Up Loans
                    </span>{" "}
                    Optimize Your EMI with the Right Top-Up{" "}
                  </h1>
                  <div className="white-box-text  w-100 py-1 py-md-4">
                    <div className=" starting-from bg-white ">
                      <h3 className="poppins bg-transparent ">
                        Balance your EMI, adjust loan amount, and maximize
                        savings—quick & easy!
                      </h3>
                    </div>
                  </div>
                </div>

                {/* border */}
                <div className="small-border-line d-md-none"></div>

                <div
                  id="vector-container"
                  className=" pt-2 pb-2 pt-md-4  gap-2 flex-md-column gap-md-3 w-100  "
                >
                  <div
                    className=" vector-content-one d-flex flex-row  gap-2 gap-md-5 align-items-center "
                    // style={{ gap: "4.2rem" }}
                  >
                    <div className="vector-image-box">
                      <img
                        className="vector-one"
                        src={vectorOne}
                        alt="vector-image"
                      />
                    </div>
                    <div className="credit-score-impact">
                      <p className=" bg-transparent">Tips on Improving Score</p>
                    </div>
                  </div>
                  {/* border */}
                  <div
                    className=" d-md-none"
                    style={{
                      borderRight: " 2px solid #E0DBE6 ",
                      textAlign: "center",
                      // height: "2rem",
                      margin: "0.3rem 0",
                    }}
                  ></div>
                  <div
                    className=" vector-content-two d-flex flex-row pt-md-3 gap-2 gap-md-5 align-items-center  "
                    style={{ gap: "3.2rem" }}
                  >
                    <div className="vector-image-box-two">
                      <img
                        className="vector-two bg-transparent"
                        src={vectorTwo}
                        alt="vector-image"
                      />
                    </div>
                    <div className="quick-text">
                      <p className=" bg-transparent ">Get Report Instantly</p>
                    </div>
                  </div>
                  {/* border */}
                  <div
                    className=" d-md-none "
                    style={{
                      borderRight: "2px solid #E0DBE6",
                      textAlign: "center",
                      // height: "2.5rem",
                      margin: "0.3rem 0",
                    }}
                  ></div>
                  <div className="vector-content-three d-flex flex-row pt-md-3 gap-2 gap-md-5 align-items-center ">
                    <div className="vector-image-box-three">
                      {" "}
                      <img
                        className="vector-three bg-transparent"
                        src={vectorThree}
                        alt="vector-image"
                      />
                    </div>
                    <div className="easy-text">
                      <p className="m-0 bg-transparent">
                        Detailed Credit Report Insights
                      </p>
                    </div>
                  </div>
                </div>

                {/* border */}
                <div className="small-two-border-line d-md-none"></div>
              </div>
            </div>

            <div
              ref={formRef}
              className="card-box-content"
              style={{ width: "42%" }}
            >
              <div className="get-up-card">
                <div className="card-content d-flex flex-column justify-content-center align-items-center gap-md-4 bg-transparent">
                  <div className="get-up-content bg-transparent">
                    <div className="get-up-text bg-transparent">
                      {" "}
                      <h2 className=" bg-transparent">
                        Get Your Best Offer In 1 Minute
                      </h2>
                    </div>
                    <p className="enjoy-para pt-3 pb-2 ps-1 d-md-none bg-transparent">
                      Enjoy instant approval & processing
                    </p>
                  </div>

                  <div
                    id="input-filds-container"
                    className="w-100 bg-transparent"
                  >
                    {/* form */}
                    <form
                      action=""
                      onSubmit={handleSubmit}
                      className="bg-transparent"
                    >
                      <div
                        id="input-fields"
                        className=" bg-transparent  d-flex flex-column justify-content-center align-items-center gap-4 w-100"
                      >
                        <div className=" bg-transparent w-100 d-flex flex-column gap-4">
                          <div className=" bg-transparent">
                            {/* <input
                              className="bt-input-box"
                              type="text"
                              id="name"
                              name="fname"
                              placeholder="Enter First your Name"
                              // value={userData.name}
                              // onChange={handleChange}
                              value={userData.fname || ""}
                              onChange={handleChange}
                              
                            /> */}
                            <Form.Control
                              type="text"
                              placeholder="First name"
                              className="bt-input-box"
                              name="fname"
                              value={userData.fname || ""}
                              onChange={handleChange}
                            />

                            {errors.fname && (
                              <p
                                style={{
                                  color: "red",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {errors.fname}
                              </p>
                            )}
                          </div>
                          <div className=" bg-transparent">
                            {/* <input
                              className="bt-input-box"
                              type="text"
                              id="name"
                              name="lname"
                              placeholder="Enter Last your Name"
                             
                              value={userData.lname || ""}
                              onChange={handleChange}
                             
                            /> */}
                            <Form.Control
                              type="text"
                              placeholder="Last name"
                              className="bt-input-box "
                              name="lname"
                              value={userData.lname || ""}
                              onChange={handleChange}
                            />

                            {errors.lname && (
                              <p
                                style={{
                                  color: "red",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {errors.lname}
                              </p>
                            )}
                          </div>
                          {/* <div>
                            <input
                              className="bt-input-box"
                              type="text"
                              id="mobile"
                              name="mobile"
                              placeholder="Enter your Mobile No."
                              // value={userData.mobile}
                              // onChange={handleChange}
                              value={userData.mobile || ""}
                              onChange={handleChange}
                              required
                            />
                            {errors.mobile && (
                              <p style={{ color: "red" }}>{errors.mobile}</p>
                            )}
                          </div> */}
                          <InputGroup className="w-100 bg-transparent">
                            <InputGroup.Text className="mbl-text">
                              +91
                            </InputGroup.Text>
                            <Form.Control
                              // required
                              type="text"
                              maxLength={10}
                              placeholder="Contact number"
                              className="mbl-input"
                              name="mobile"
                              value={userData.mobile}
                              onChange={handleChange}
                            />
                            {errors.mobile && (
                              <p
                                style={{
                                  color: "red",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {errors.mobile}
                              </p>
                            )}
                          </InputGroup>

                          <div className="bg-transparent">
                            {/* <input
                              className="bt-input-box"
                              type="text"
                              id="email"
                              name="email"
                              placeholder="Enter your Email ID"
                              value={userData.email || ""}
                              onChange={handleChange}
                              
                            /> */}
                            <Form.Control
                              type="text"
                              placeholder="Email"
                              className="bt-input-box"
                              name="email"
                              value={userData.email || ""}
                              onChange={handleChange}
                            />
                            {/* {validated && !data.email && (
                      <p className="field-error">Email is required field</p>
                    )}
                    {emailError && <p className="field-error">Invalid Email</p>} */}

                            {errors.email && (
                              <p
                                style={{
                                  color: "red",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {errors.email}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div
                        id="check-box"
                        className="d-flex flex-row justify-content-center align-items-start pt-4 w-100  bg-white"
                      >
                        <div className="bg-white">
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            className="form-check-input"
                          />
                          {/* <FormControlLabel
                            sx={{
                              width: "22px",
                              height: "22px",
                              paddingRight: "30px",
                              "& .MuiSvgIcon-root": { fontSize: 28 },
                              backgroundColor: "#ffffff",
                            }}
                            control={
                              <Checkbox
                                style={{ backgroundColor: "#ffffff" }}
                                defaultChecked
                                // color="secondary"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                              />
                            }
                          /> */}
                        </div>
                        <div>
                          <p
                            id="terms-privecy-para"
                            className="bg-white"
                            // style={{ backgroundColor: "none" }}
                          >
                            I agree to MinEMI's{" "}
                            {/* <Link
                              sx={{
                                fontFamily: "'Poppins',sans-serif",
                                color: "#80387C",
                                textDecoration: "underline",
                                fontWeight: 700,
                              }}
                            >
                              <strong>
                                {" "}
                                Terms & Conditions & Privacy Policy
                              </strong>
                            </Link>{" "} */}
                            <a
                              style={{
                                fontFamily: "'Archivo',sans-serif",
                                color: "#80387C",
                                textDecoration: "underline",
                                fontWeight: 700,
                                backgroundColor: "transparent",
                              }}
                              href="#"
                              // onClick={(e) => {
                              //   e.preventDefault();
                              //   handleClickOne("term_of_use");
                              // }}
                              onClick={() => setShow(true)}
                            >
                              <strong> Terms of Use</strong>
                            </a>{" "}
                            and{" "}
                            <a
                              style={{
                                fontFamily: "'Archivo',sans-serif",
                                color: "#80387C",
                                textDecoration: "underline",
                                fontWeight: 700,
                              }}
                              href="#"
                              // onClick={(e) => {
                              //   e.preventDefault();
                              //   handleClickTwo("privacy_policy");
                              // }}
                              onClick={() => setShowTwo(true)}
                            >
                              <strong> Privacy Policy</strong>
                            </a>{" "}
                            and authorise to be communicate via SMS, Call,
                            Whatsapp and Email.
                          </p>
                          {errors.checkbox && (
                            <p
                              style={{
                                color: "red",
                                backgroundColor: "#ffff",
                                margin: 0,
                              }}
                            >
                              {errors.checkbox}
                            </p>
                          )}
                        </div>
                      </div>

                      <div id="button-box" className=" bg-transparent w-100">
                                            {!loader ? (
                                              <button
                                                type="submit"
                                                // onClick={() => {
                                                //   navigate("/find-home-loan-eligibility");
                                                // }}
                                                style={{
                                                  width: "100%",
                                                  backgroundColor: "#59266D",
                    
                                                  fontSize: smallScreen
                                                    ? "14px"
                                                    : mediumScreen
                                                    ? "16px"
                                                    : largeScreen
                                                    ? "16px"
                                                    : "20px",
                                                  fontWeight: 500,
                                                  lineHeight: "26px",
                                                  borderRadius: smallScreen ? "6px" : "10px",
                                                  padding: smallScreen
                                                    ? "0.3rem 2.5rem"
                                                    : "0.6rem 3rem",
                                                  // padding: "0.8rem 0",
                                                  fontFamily: "'Archivo',sans-serif",
                                                  letterSpacing: "-0.17px",
                                                  textTransform: "none",
                                                  color: "#ffff",
                                                  border: "none",
                                                }}
                                                variant="contained"
                                              >
                                                Check Eligibility Now
                                              </button>
                                            ) : (
                                              <button
                                                className="color-ring"
                                                style={{
                                                  width: "100%",
                                                  backgroundColor: "#59266D",
                    
                                                  fontSize: smallScreen
                                                    ? "14px"
                                                    : mediumScreen
                                                    ? "16px"
                                                    : largeScreen
                                                    ? "16px"
                                                    : "20px",
                                                  fontWeight: 500,
                                                  lineHeight: "26px",
                                                  borderRadius: smallScreen ? "6px" : "10px",
                                                  padding: smallScreen
                                                    ? "0.3rem 2.5rem"
                                                    : "0.6rem 3rem",
                                                  // padding: "0.8rem 0",
                                                  fontFamily: "'Archivo',sans-serif",
                                                  letterSpacing: "-0.17px",
                                                  textTransform: "none",
                                                  color: "#ffff",
                                                  border: "none",
                                                }}
                                              >
                                                <ColorRing
                                                  visible={loader}
                                                  height="30"
                                                  width="30"
                                                  ariaLabel="color-ring-loading"
                                                  wrapperStyle={{ background: "transparent" }}
                                                  wrapperClass="color-ring-wrapper"
                                                  colors={[
                                                    "#ffffff",
                                                    "#ffffff",
                                                    "#ffffff",
                                                    "#ffffff",
                                                    "#ffffff",
                                                    "#ffffff",
                                                  ]}
                                                />
                                              </button>
                                            )}
                                          </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* <section>
          <Calculator onButtonClick={scrollToForm} />
        </section> */}
        <section>
          <TopUpMinemi />
        </section>
        <section>
          <ListOfDocuments />
        </section>
        <section>
          <TableData onButtonClick={scrollToForm} />
        </section>
        <section>
          <ChooseMinemi />
        </section>
        <section>
          <Testimonials />
        </section>
        <section>
          <FAQ />
        </section>
        {/* terms-condition Modal */}
        <Modal
          className="bg-transparent"
          size="lg"
          show={show}
          onHide={() => {
            setShow(false);
            // resetOtp();
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <Modal.Body>
            <div
              style={{
                marginLeft: "5%",
                marginRight: "5%",
                marginTop: 10,
                fontSize: "21px",
                lineHeight: "3.75rem",
                color: "rgb(89, 38, 109)",
                fontWeight: "bold",
              }}
            >
              Terms and Conditions{" "}
            </div>
            <div
              className="api-text"
              style={{ marginLeft: "5%", marginRight: "5%" }}
              dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
          </Modal.Body>
        </Modal>

        {/* PrivecyPolicy Modal */}
        <Modal
          className="bg-transparent"
          size="lg"
          show={showTwo}
          onHide={() => {
            setShowTwo(false);
            // resetOtp();
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <Modal.Body>
            <div
              style={{
                marginLeft: "5%",
                marginRight: "5%",
                marginTop: 10,
                fontSize: "21px",
                lineHeight: "3.75rem",
                color: "rgb(89, 38, 109)",
                fontWeight: "bold",
              }}
            >
              Privacy Policy
            </div>
            <div
              className="api-text"
              style={{ marginLeft: "5%", marginRight: "5%" }}
              dangerouslySetInnerHTML={{ __html: htmlContentTwo }}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default LowerYourEmiPage;

import React, { useContext, useEffect } from "react";

import PersonalizedLoan from "./PersonalizedLoan";
import MetadataContext from "../../MetadataContext";

function RecommendationGmnesq1() {
  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Loan Recommendation Dashboard - MinEmi",
      pageDescription:
        "Explore the Loan Recommendation Dashboard by MinEmi to find the best loan options tailored to your needs. Compare EMI, interest rates, and repayment plans effortlessly.",
      pageKeywords:
        "loan recommendation, EMI calculator, best loan options, loan comparison, MinEmi, interest rates, loan eligibility, personal loan, home loan, auto loan, loan dashboard",

      canonicalUrl: "https://minemi.ai/loan-recommendation-page",
    });
  }, [setMetadata]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PersonalizedLoan />
    </>
  );
}

export default RecommendationGmnesq1;

export const faqData = [
  {
    id: 1,
    question: "What is a Loan Against Property (LAP)?",
    answer:
      "A Loan Against Property (LAP) is a secured loan where a borrower pledges their residential, commercial, or industrial property as collateral to avail funds from a bank or financial institution. Since the loan is backed by property, lenders offer lower interest rates compared to unsecured loans. The loan amount depends on the property's market value, and the borrower can use it for various financial needs, such as business expansion, medical emergencies, or higher education expenses.",
    // answerList: [
    //   {
    //     heading:
    //       " Eligibility criteria vary by lender, but typically include: ",
    //   },
    //   {
    //     info: "Minimum age of 21 years at the time of loan application.",
    //   },
    //   {
    //     info: " Stable income or employment history.",
    //   },
    //   {
    //     info: "A good credit score (typically 650 or above).",
    //   },
    //   {
    //     info: "Indian citizenship or valid residency proof for NRIs.",
    //   },
    // ],
  },
  {
    id: 2,
    question: "What types of properties are eligible for LAP?",
    answer:
      "Lenders typically accept residential, commercial, and industrial properties as collateral for a Loan Against Property. A residential property can be self-occupied or rented out, while a commercial property may include offices, shops, or other business establishments. Industrial properties such as warehouses and factories may also be considered. However, the property must be legally owned, free from disputes, and registered under valid government records to be eligible for the loan.",
  },
  {
    id: 3,
    question: "What is the maximum tenure for a Loan Against Property?",
    answer:
      "The repayment tenure for a Loan Against Property usually ranges from 15 to 20 years, depending on the lender's policies and the borrower’s repayment capacity. Some financial institutions may offer a shorter or longer tenure based on the loan amount, borrower's income, and financial profile. Choosing a longer tenure reduces the EMI burden but increases the overall interest paid over time.",
  },
  {
    id: 4,
    question: "How is my LAP eligibility calculated?",
    answer:
      "Lenders assess eligibility for a Loan Against Property based on several factors. One of the key aspects is the Fixed Obligation to Income Ratio (FOIR), which considers the borrower’s total fixed obligations, such as existing EMIs and expenses, in comparison to their income. Another important factor is the Loan-to-Value (LTV) ratio, where lenders typically provide 50% to 75% of the property's market value as a loan. Additionally, financial institutions evaluate income stability, credit score, and financial history to determine the borrower’s repayment capability.",
  },
  {
    id: 5,
    question:
      "Can I get a Loan Against Property if I have an existing home loan?",
    answer:
      "Yes, borrowers can avail of a Loan Against Property even if they have an existing home loan. However, the lender will evaluate the borrower’s financial standing, repayment capacity, and the remaining market value of the property before approving the loan. If the borrower’s income allows them to manage multiple EMIs without financial strain, and the property holds sufficient value after deducting the home loan liability, they may still qualify for LAP. A good credit score and consistent financial records further improve the chances of loan approval.",
  },
  // {
  //   id: 6,
  //   question: "How long does it take to get a home loan?",
  //   answer:
  //     "Home loans typically take a few weeks to process, depending on property verification and documentation. Minemi streamlines the process and provides faster access to offers.",
  // },
  // {
  //   id: 7,
  //   question: "Can I prepay or foreclose my home loan?",
  //   answer:
  //     "Some credit cards allow balance transfers from personal loans or other credit cards. However, this depends on the card issuer’s policies.",
  // },
  // {
  //   id: 8,
  //   question: "What is the processing fee for a home loan?",
  //   answer:
  //     "Focus on timely repayments of the new balance to take full advantage of the lower interest rate. Avoid accumulating new debt on the old account to prevent further financial strain.",
  // },
];

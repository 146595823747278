import React from "react";
import HeroSection from "./HeroSection";
import BenefitsSection from "./BenefitsSection";
import BalanceTransferSection from "./BalanceTransferSection";
import Testimonials from "./Testimonials";
import FeaturesSection from "./FeaturesSection";
import FaqPage from "./FaqPage";

export default function LowerEmiStaticPage() {
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <HeroSection />
        <BenefitsSection />
        <BalanceTransferSection />
        <Testimonials />
        <FeaturesSection />
        <FaqPage />
      </div>
    </>
  );
}

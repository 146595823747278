import React,{useState} from "react";
import "./CheckCardStatus.css";
import {FaIndianRupeeSign,FaStar,FaRegStarHalfStroke   } from "react-icons/fa6";
import { IoIosStarOutline } from "react-icons/io";
import Button from "react-bootstrap/Button";
import { ColorRing } from "react-loader-spinner";


const CheckCardStatus = () => {
  const [loader, setLoader] = useState(false);

const cardData = [
  { image: "../../../../images/card/image.png",bestFor : "Best For : Chosen based on user's income level and interest in shopping benefits", name: "Wells Fargo Active Cash Card",fee: "0", rate: "2 %",offers: "2000",creditScore: "690-850",star:3.5},
  { image: "../../../../images/card/image1.png",bestFor : "Best For : Chosen based on user's income level and interest in shopping benefits", name: "Flipkart Axis Credit Card",fee: "0", rate: "2 %",offers: "2000",creditScore: "690-850",star:4.5},
  { image: "../../../../images/card/image2.png",bestFor : "Best For : Chosen based on user's income level and interest in shopping benefits", name: "Wells Fargo Active Cash Card",fee: "0", rate: "2 %",offers: "2000",creditScore: "690-850",star:1.5},
  { image: "../../../../images/card/image.png",bestFor : "Best For : Chosen based on user's income level and interest in shopping benefits", name: "Flipkart Axis Credit Card",fee: "0", rate: "2 %",offers: "2000",creditScore: "690-850",star:2.5},
  { image: "../../../../images/card/image1.png",bestFor : "Best For : Chosen based on user's income level and interest in shopping benefits", name: "Wells Fargo Active Cash CardCard",fee: "0", rate: "2 %",offers: "2000",creditScore: "690-850",star:5},
]; 

  const StarRating = ({ rating }) => {
    const maxStars = 5;
    const stars = [];

    for (let i = 1; i <= maxStars; i++) {
      if (i <= rating) {
        stars.push(<FaStar key={i} fill="#25866a" stroke="#25866a" className="card-rating-star"  />);
      } else if (i - 0.5 === rating) {
        stars.push(<FaRegStarHalfStroke  key={i} fill="#25866a" stroke="#25866a" className="card-rating-star"  />);
      } else {
        stars.push(<IoIosStarOutline key={i} fill="#25866a" stroke="#25866a" className="card-rating-star" />);
      }
    }

    return <div className="flex space-x-1">{stars}</div>;
  };

  return (
    <>
      <div id="Loan-Check-Card-Availability">
        <div className="CheckCard-bottom-contentCon">
          <p id="CheckCard-bottom-title">Recommended Credit Cards Just for You</p>

          {cardData.map((item,index)=>(
          <div className="card-bottom-con" key={index}>
            <div className="travel-con">
              <p>{item.bestFor}</p>
            </div>

            <div className="row p-0 m-0">
              <div className="col-12 col-md-4 p-0 m-0">
                <img src={item.image} alt="card-image" className="card-img" />
              </div>
              

              <div className="col-12 col-md-8 px-0 m-0">
                <div className="row p-0 m-0">
                  <div className="col-12 col-md-8 m-0 p-0">
                    <div>
                      <div className="d-block d-md-none my-3 my-md-0">
                        <p className="card-rating-left">MinEmi Ratings</p>
                        <div className="card-star-left">
                          <StarRating rating={item.star} />  
                        </div>
                      </div>

                      <p className="card-name">{item.name}</p>

                      <div className="card-text-con">
                        <p className="text-title m-0 p-0">Annual Fee <span> :</span> </p>
                        &nbsp;&nbsp;&nbsp;
                        <p className="text-content m-0 p-0 d-flex align-items-center">
                          <FaIndianRupeeSign className="rupee-icon" />
                          {item.fee}
                        </p>
                      </div>
 
                      <div className="card-text-con">
                        <p className="text-title m-0 p-0">Reward Rate <span> :</span> </p>
                        &nbsp;&nbsp;&nbsp;
                        <p className="text-content m-0 p-0">{item.rate} ( Cashback )</p>
                      </div>

                      <div className="card-text-con">
                        <p className="text-title m-0 p-0">Intro Offers <span> :</span> </p>
                        &nbsp;&nbsp;&nbsp;
                        <p className="text-content m-0 p-0 d-flex align-items-center">
                          <FaIndianRupeeSign className="rupee-icon" />
                          {item.offers} ( Cashback )
                        </p>
                      </div>

                      <div className="card-text-con">
                        <p className="text-title m-0 p-0">Recommend Credit Score <span> :</span> </p>
                        &nbsp;&nbsp;&nbsp;
                        <p className="text-content m-0 p-0">{item.creditScore}</p>
                      </div>

                    </div>
                  </div>

                  <div className="col-12 col-md-4 d-flex flex-column justify-content-between m-0 p-0">
                    <div className="d-none d-md-block">
                      <p className="card-rating-text">MinEmi Ratings</p>
                      <div className="card-star-con">
                        <StarRating rating={item.star} />  
                      </div>
                    </div>

                    <div className="d-flex justify-content-end mt-4 mt-md-0">
                      {!loader ? (
                        <Button className="check-card-apply-btn" type="submit">
                          Apply Now
                        </Button>
                      ) : (
                        <Button type="button" className="check-card-apply-btn">
                        <ColorRing
                          visible={loader}
                          height="30"
                          width="30"
                          ariaLabel="color-ring-loading"
                          wrapperStyle={{}}
                          wrapperClass="color-ring-wrapper"
                          colors={[
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                          ]}
                        />
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CheckCardStatus;

import React from "react";
import {
  Star,
  Info,
  CheckCircle,
  Clock,
  Shield,
  TrendingUp,
  Search,
} from "lucide-react";
import { Link } from "react-router-dom";
import LasTopPicksSection from "./LasTopPicksSection";
import LoanFilter from "./LoanFilter";
import icici from "../../../Assets/Images/ICICIBank-circular.webp";
import hdfc from "../../../Assets/Images/hdfc.png";
import bajaj from "../../../Assets/Images/BF-photoaidcom-cropped.webp";
import Mirai from "../../../Assets/Images/MiraiAssetsCircular.webp";
import aditya from "../../../Assets/Images/ABF.webp";
import BF from "../../../Assets/Images/BF-photoaidcom-cropped.webp";
import volt from "../../../Assets/Images/VoltCircular.webp";
import tata from "../../../Assets/Images/tata-capital.png";
import { useContext, useEffect } from "react";
import MetadataContext from "../../MetadataContext";

const LoanAgainstSharesLandingPage = () => {
  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        "Best Loan Against Shares & Mutual Funds 2025 | Compare & Apply - Minemi",
      pageDescription:
        "Compare loan against shares & mutual funds from top banks & NBFCs. Find the lowest interest rates & best offers in 2025. Apply online hassle-free.",
      pageKeywords:
        "loan against shares, loan against mutual funds, best loan against shares 2025, best loan against mutual funds 2025, compare loans against shares, apply loan against mutual funds, low interest loan against shares, instant loan against securities, best loan options 2025, secured loan against investments",
      canonicalUrl:
        "https://minemi.ai/loanpage/digital-loan/loan-against-shares-and-mutual-funds/best-loans-2025-compare",
    });
  }, [setMetadata]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      className="flex min-h-screen flex-col "
      style={{ backgroundColor: "#f8f8f8" }}
    >
      {/* Hero Section */}
      <section className="bg-gradient-to-b from-[#ebe8fc] to-[#f8f8f8] py-10 sm:py-16">
        <div className="container mx-auto px-4">
          <div className="mx-auto max-w-3xl text-center">
            <h1 className="mb-4 text-3xl sm:text-4xl md:text-5xl font-bold leading-tight text-[#311664]">
              Best Loan Against Shares & Mutual Funds in India{" "}
              {new Date().getFullYear()}
            </h1>
            <p className="mb-6 sm:mb-8 text-base sm:text-lg text-[#26292b]">
              Compare top lenders offering Loan Against Shares and Loan Against
              Mutual Funds. Get quick liquidity without selling your investments
              with India's best securities-backed loans.
            </p>
            <div className="flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4 justify-center">
              <button
                className="rounded-md bg-[#59266d] px-4 sm:px-6 py-3 sm:py-4 text-base sm:text-lg font-medium text-white shadow-lg transition-transform hover:scale-105 hover:bg-[#59266d]/90"
                onClick={() =>
                  document
                    .getElementById("loan-against-shares_scroll")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Compare Loan Against Shares
              </button>
              <button
                className="rounded-md bg-[#3a868b] px-4 sm:px-6 py-3 sm:py-4 text-base sm:text-lg font-medium text-white shadow-lg transition-transform hover:scale-105 hover:bg-[#3a868b]/90"
                onClick={() =>
                  document
                    .getElementById("loan-against-mutual-funds_shares")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Compare Loan Against Mutual Funds
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Quick Benefits Section */}
      <section className="py-4" style={{ backgroundColor: "#f8f8f8" }}>
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-6 max-w-5xl mx-auto">
            <div className="flex items-center p-3 sm:p-4 rounded-lg border border-[#ebe8fc]">
              <div className="rounded-full bg-[#ebe8fc] p-2 sm:p-3 mr-3 sm:mr-4">
                <TrendingUp className="h-5 w-5 sm:h-6 sm:w-6 text-[#59266d]" />
              </div>
              <div>
                <h3 className="font-medium text-[#311664] text-sm sm:text-base m-0">
                  Lower Interest Rates
                </h3>
                <p className="text-xs sm:text-sm text-[#26292b] m-0">
                  3-5% lower than personal loans
                </p>
              </div>
            </div>
            <div className="flex items-center p-3 sm:p-4 rounded-lg border border-[#ebe8fc]">
              <div className="rounded-full bg-[#ebe8fc] p-2 sm:p-3 mr-3 sm:mr-4">
                <Clock className="h-5 w-5 sm:h-6 sm:w-6 text-[#59266d]" />
              </div>
              <div>
                <h3 className="font-medium text-[#311664] text-sm sm:text-base m-0">
                  Quick Processing
                </h3>
                <p className="text-xs sm:text-sm text-[#26292b] m-0">
                  Get funds in as little as 24 hours
                </p>
              </div>
            </div>
            <div className="flex items-center p-3 sm:p-4 rounded-lg border border-[#ebe8fc] sm:col-span-2 md:col-span-1 sm:mx-auto">
              <div className="rounded-full bg-[#ebe8fc] p-2 sm:p-3 mr-3 sm:mr-4">
                <Shield className="h-5 w-5 sm:h-6 sm:w-6 text-[#59266d]" />
              </div>
              <div>
                <h3 className="font-medium text-[#311664] text-sm sm:text-base m-0">
                  Keep Your Investments
                </h3>
                <p className="text-xs sm:text-sm text-[#26292b] m-0">
                  No need to sell your securities
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Loan Filter Section */}
      <section className="py-8 sm:py-12 bg-[#ebe8fc]/20">
        <div className="container mx-auto px-4">
          <LoanFilter />
        </div>
      </section>

      {/* Loan Against Shares Section */}
      <section
        className="py-8 sm:py-12 "
        id="loan-against-shares"
        style={{ backgroundColor: "#f8f8f8" }}
      >
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <span className="text-[#3a868b] font-medium text-xs sm:text-sm uppercase tracking-wider">
              Leverage Your Equity Portfolio
            </span>
            <h2 className="text-2xl sm:text-3xl font-bold text-[#311664] mb-3 sm:mb-4 mt-1">
              Loan Against Shares
            </h2>
            <p className="text-sm sm:text-base text-[#26292b] mb-4 sm:mb-6">
              A Loan Against Shares allows you to use your equity investments as
              collateral to secure a loan. This is ideal for investors who need
              quick funds but don't want to sell their shares and miss out on
              potential market gains.
            </p>
            <ul className="mb-4 sm:mb-6 space-y-2 sm:space-y-3">
              <li className="flex items-start">
                <CheckCircle className="h-4 w-4 sm:h-5 sm:w-5 text-[#3a868b] mr-2 flex-shrink-0 mt-0.5" />
                <span className="text-sm sm:text-base">
                  <strong>LTV Ratio:</strong> Get up to 50-70% of your share
                  portfolio value
                </span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="h-4 w-4 sm:h-5 sm:w-5 text-[#3a868b] mr-2 flex-shrink-0 mt-0.5" />
                <span className="text-sm sm:text-base">
                  <strong>Interest Rates:</strong> Starting from 10.5% (banks)
                  to 14.5% (NBFCs)
                </span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="h-4 w-4 sm:h-5 sm:w-5 text-[#3a868b] mr-2 flex-shrink-0 mt-0.5" />
                <span className="text-sm sm:text-base">
                  <strong>Loan Limits:</strong> Banks limited to ₹20 lakhs,
                  NBFCs offer higher amounts
                </span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="h-4 w-4 sm:h-5 sm:w-5 text-[#3a868b] mr-2 flex-shrink-0 mt-0.5" />
                <span className="text-sm sm:text-base">
                  <strong>Processing Time:</strong> As quick as 24-48 hours with
                  minimal documentation
                </span>
              </li>
            </ul>
            <Link to="#shares-section">
              <button
                className="rounded-md bg-[#59266d] px-4 sm:px-6 py-2 sm:py-3 text-sm sm:text-base font-medium text-white hover:bg-[#59266d]/90"
                onClick={() =>
                  document
                    .getElementById("loan-against-shares_scroll")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Compare Top Loan Against Shares Lenders
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Loan Against Mutual Funds Section */}
      <section
        className="py-8 sm:py-12 bg-[#ebe8fc]/10"
        id="loan-against-mutual-funds"
      >
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto">
            <span className="text-[#3a868b] font-medium text-xs sm:text-sm uppercase tracking-wider">
              Unlock Value From Your Fund Investments
            </span>
            <h2 className="text-2xl sm:text-3xl font-bold text-[#311664] mb-3 sm:mb-4 mt-1">
              Loan Against Mutual Funds
            </h2>
            <p className="text-sm sm:text-base text-[#26292b] mb-4 sm:mb-6">
              A Loan Against Mutual Funds enables you to use your mutual fund
              investments as collateral. This option is perfect for investors
              who want to maintain their long-term investment strategy while
              accessing immediate funds.
            </p>
            <ul className="mb-4 sm:mb-6 space-y-2 sm:space-y-3">
              <li className="flex items-start">
                <CheckCircle className="h-4 w-4 sm:h-5 sm:w-5 text-[#3a868b] mr-2 flex-shrink-0 mt-0.5" />
                <span className="text-sm sm:text-base">
                  <strong>LTV Ratio:</strong> Up to 80% of your mutual fund
                  portfolio value
                </span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="h-4 w-4 sm:h-5 sm:w-5 text-[#3a868b] mr-2 flex-shrink-0 mt-0.5" />
                <span className="text-sm sm:text-base">
                  <strong>Interest Rates:</strong> Starting from 10.25% (banks)
                  to 13.75% (NBFCs)
                </span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="h-4 w-4 sm:h-5 sm:w-5 text-[#3a868b] mr-2 flex-shrink-0 mt-0.5" />
                <span className="text-sm sm:text-base">
                  <strong>Eligible Funds:</strong> Equity, debt, and hybrid
                  mutual funds with good NAV
                </span>
              </li>
              <li className="flex items-start">
                <CheckCircle className="h-4 w-4 sm:h-5 sm:w-5 text-[#3a868b] mr-2 flex-shrink-0 mt-0.5" />
                <span className="text-sm sm:text-base">
                  <strong>Flexible Repayment:</strong> Multiple repayment
                  options to suit your cash flow
                </span>
              </li>
            </ul>
            <Link to="#mf-section">
              <button
                className="rounded-md bg-[#3a868b] px-4 sm:px-6 py-2 sm:py-3 text-sm sm:text-base font-medium text-white hover:bg-[#3a868b]/90"
                onClick={() =>
                  document
                    .getElementById("loan-against-mutual-funds_shares")
                    ?.scrollIntoView({ behavior: "smooth" })
                }
              >
                Compare Top Loan Against Mutual Funds Lenders
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Banks vs NBFCs Section */}
      <section
        className="py-10 sm:py-16 "
        style={{ backgroundColor: "#f8f8f8" }}
      >
        <div className=" mx-auto px-4">
          <h2 className="mb-4 sm:mb-8 text-center text-2xl sm:text-3xl font-bold text-[#311664]">
            Banks vs NBFCs: Key Differences for Securities-Backed Loans
          </h2>
          <p className="mb-8 sm:mb-12 text-center text-sm sm:text-base text-[#26292b] max-w-3xl mx-auto">
            Understanding the differences between banks and NBFCs is crucial
            when choosing a Loan Against Shares or Loan Against Mutual Funds
            provider.
          </p>

          <div className="overflow-x-auto rounded-lg border shadow-md">
            <table className="w-full border-collapse bg-white text-left text-sm">
              <thead className="bg-[#ebe8fc]">
                <tr>
                  <th className="px-3 sm:px-6 py-3 sm:py-4 font-medium text-[#311664] text-xs sm:text-sm">
                    Features
                  </th>
                  <th className="px-3 sm:px-6 py-3 sm:py-4 font-medium text-[#311664] text-xs sm:text-sm">
                    Banks
                  </th>
                  <th className="px-3 sm:px-6 py-3 sm:py-4 font-medium text-[#311664] text-xs sm:text-sm">
                    NBFCs
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y">
                <tr className="hover:bg-[#ebe8fc]/30">
                  <td className="px-3 sm:px-6 py-3 sm:py-4 font-medium text-xs sm:text-sm">
                    Loan Limit
                  </td>
                  <td className="px-3 sm:px-6 py-3 sm:py-4 text-xs sm:text-sm">
                    Limited to ₹20,00,000 as per RBI guidelines
                  </td>
                  <td className="px-3 sm:px-6 py-3 sm:py-4 text-xs sm:text-sm">
                    Can disburse loans above ₹20,00,000
                  </td>
                </tr>
                <tr className="hover:bg-[#ebe8fc]/30">
                  <td className="px-3 sm:px-6 py-3 sm:py-4 font-medium text-xs sm:text-sm">
                    Interest Rates
                  </td>
                  <td className="px-3 sm:px-6 py-3 sm:py-4 text-xs sm:text-sm">
                    Generally lower (10.5% - 12.5%)
                  </td>
                  <td className="px-3 sm:px-6 py-3 sm:py-4 text-xs sm:text-sm">
                    Slightly higher (11.5% - 14%)
                  </td>
                </tr>
                <tr className="hover:bg-[#ebe8fc]/30">
                  <td className="px-3 sm:px-6 py-3 sm:py-4 font-medium text-xs sm:text-sm">
                    Processing Time
                  </td>
                  <td className="px-3 sm:px-6 py-3 sm:py-4 text-xs sm:text-sm">
                    3-7 business days
                  </td>
                  <td className="px-3 sm:px-6 py-3 sm:py-4 text-xs sm:text-sm">
                    1-3 business days
                  </td>
                </tr>
                <tr className="hover:bg-[#ebe8fc]/30">
                  <td className="px-3 sm:px-6 py-3 sm:py-4 font-medium text-xs sm:text-sm">
                    Documentation
                  </td>
                  <td className="px-3 sm:px-6 py-3 sm:py-4 text-xs sm:text-sm">
                    More extensive documentation required
                  </td>
                  <td className="px-3 sm:px-6 py-3 sm:py-4 text-xs sm:text-sm">
                    Simplified documentation process
                  </td>
                </tr>
                <tr className="hover:bg-[#ebe8fc]/30">
                  <td className="px-3 sm:px-6 py-3 sm:py-4 font-medium text-xs sm:text-sm">
                    Eligibility Criteria
                  </td>
                  <td className="px-3 sm:px-6 py-3 sm:py-4 text-xs sm:text-sm">
                    Stricter eligibility requirements
                  </td>
                  <td className="px-3 sm:px-6 py-3 sm:py-4 text-xs sm:text-sm">
                    More flexible eligibility criteria
                  </td>
                </tr>
                <tr className="hover:bg-[#ebe8fc]/30">
                  <td className="px-3 sm:px-6 py-3 sm:py-4 font-medium text-xs sm:text-sm">
                    Repayment Flexibility
                  </td>
                  <td className="px-3 sm:px-6 py-3 sm:py-4 text-xs sm:text-sm">
                    Less flexible repayment options
                  </td>
                  <td className="px-3 sm:px-6 py-3 sm:py-4 text-xs sm:text-sm">
                    More flexible repayment structures
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      {/* Loan Against Shares Comparison Table */}
      <section className="py-16" id="shares-section">
        <div id="loan-against-shares_scroll" className=" mx-auto px-4">
          <h2 className="mb-8 text-center text-3xl font-bold text-[#311664]">
            Best Loan Against Shares Providers of{" "}
            {new Date().toLocaleString("default", { month: "long" })}{" "}
            {new Date().getFullYear()}
          </h2>
          <p className="mb-8 text-center text-[#26292b]">
            Compare the top lenders offering Loan Against Shares to find the
            best interest rates, LTV ratios, and processing times.
          </p>

          <div className="overflow-x-auto rounded-lg border shadow-md">
            <table className="w-full border-collapse bg-white text-left text-sm">
              <thead className="bg-[#ebe8fc]">
                <tr>
                  <th className="px-6 py-4 font-medium text-[#311664]">
                    Lender
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]">
                    <div className="flex items-center">
                      Minemi Rating
                      <Info className="ml-1 h-4 w-4 text-[#59266d]" />
                    </div>
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]">
                    <div className="flex items-center">
                      Estimated APR
                      <Info className="ml-1 h-4 w-4 text-[#59266d]" />
                    </div>
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]">
                    <div className="flex items-center">
                      Loan Amount
                      <Info className="ml-1 h-4 w-4 text-[#59266d]" />
                    </div>
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]">
                    <div className="flex items-center">
                      Min. Credit Score
                      <Info className="ml-1 h-4 w-4 text-[#59266d]" />
                    </div>
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]"></th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {[
                  {
                    name: "HDFC Bank",
                    type: "Bank",
                    img: hdfc,
                    rating: 5.0,
                    bestFor: "Best overall home loan provider",
                    apr: "8.00% - 12.50%",
                    amount: "₹1,00,000 - ₹5 Cr",
                    score: "750",
                    link: "https://apply.hdfcbank.com/vivid/las#LAS_Authentication",
                  },
                  {
                    name: "ICICI Bank",
                    type: "Bank",
                    img: icici,
                    rating: 4.5,
                    bestFor: "Best for existing bank customers",
                    apr: "8.25% - 13.00%",
                    amount: "₹1,00,000 - ₹5 Cr",
                    score: "740",
                    link: "https://www.icicibank.com/personal-banking/loans/loan-against-securities",
                  },
                  {
                    name: "Aditya Birla Finance",
                    type: "NBFC",
                    img: aditya,
                    rating: 4.5,
                    bestFor: "Best for self-employed individuals",
                    apr: "8.50% - 14.00%",
                    amount: "₹50,000 - ₹3 Cr",
                    score: "720",
                    link: "https://finance.adityabirlacapital.com/loan-against-securities/apply-now",
                  },
                  {
                    name: "Bajaj Finserv",
                    type: "NBFC",
                    img: BF,
                    rating: 4.5,
                    bestFor: "Best for quick disbursals",
                    apr: "9.00% - 15.00%",
                    amount: "₹50,000 - ₹2 Cr",
                    score: "710",
                    link: "https://www.bajajfinserv.in/loan-against-shares",
                  },
                  {
                    name: "Mirae Asset",
                    type: "Fintech",
                    img: Mirai,
                    rating: 4.0,
                    bestFor: "Best for quick processing",
                    apr: "9.00% - 15.00%",
                    amount: "₹50,000 - ₹2 Cr",
                    score: "710",
                    link: "https://transact.miraeassetfin.com/register?_gl=1*mr3huj*_gcl_au*NTg1OTM1NDYxLjE3NDIwNDUyMjg.*_ga*MTA4NzQ2NTU2OC4xNzQyMDQ1MjI5*_ga_21E4CJGJYQ*MTc0MjA0NTIyOC4xLjAuMTc0MjA0NTIyOC42MC4wLjA.",
                  },
                  {
                    name: "Tata Capital",
                    img: tata,
                    type: "NBFC",
                    rating: 4.0,
                    bestFor: "Best for flexible repayment options",
                    apr: "9.75% - 17.00%",
                    amount: "₹50,000 - ₹1.5 Cr",
                    score: "700",
                    link: "https://www.las.tatacapital.com/online/loans/las/apply-now-las-loan#!",
                  },
                  {
                    name: "Volt",
                    img: volt,
                    type: "Fintech",
                    rating: 4.0,
                    bestFor: "Best for digital experience",
                    apr: "10.00% - 18.00%",
                    amount: "₹50,000 - ₹1 Cr",
                    score: "690",
                    link: "https://voltmoney.in/",
                  },
                ].map((lender, index) => (
                  <tr key={index} className="hover:bg-[#ebe8fc]/30">
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0 rounded-full overflow-hidden">
                          <img
                            src={lender.img}
                            alt={lender.name}
                            width={40}
                            height={40}
                            className="object-cover"
                          />
                        </div>
                        <div className="ml-3">
                          <div className="font-medium text-[#311664]">
                            {lender.name}
                          </div>
                          <div className="text-xs text-gray-500">
                            {lender.bestFor}
                          </div>
                          <div className="text-xs px-2 py-0.5 bg-[#ebe8fc] rounded-full inline-block mt-1">
                            {lender.type}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <span className="mr-1 font-medium">
                          {lender.rating}
                        </span>
                        <div className="flex">
                          {Array(5)
                            .fill(0)
                            .map((_, i) => (
                              <Star
                                key={i}
                                className={`h-4 w-4 ${
                                  i < Math.floor(lender.rating)
                                    ? "fill-[#59266d] text-[#59266d]"
                                    : "text-gray-300"
                                }`}
                              />
                            ))}
                        </div>
                        <span className="ml-1 text-xs text-gray-500">/5</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 font-medium">{lender.apr}</td>
                    <td className="px-6 py-4">{lender.amount}</td>
                    <td className="px-6 py-4">{lender.score}</td>
                    <td className="px-6 py-4">
                      <button
                        className="rounded bg-[#59266d] px-4 py-2 text-sm font-medium text-white hover:bg-[#59266d]/90"
                        style={{ width: "7rem" }}
                        onClick={() => {
                          window.location.href = lender.link;
                        }}
                      >
                        Check My Rate
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-4 text-xs text-gray-500">
            <p>
              Many, or all, of the products featured on this page are from our
              partners who compensate us when you take certain actions on our
              website or click to apply on their website. However, this does not
              influence our evaluations. Our opinions are our own.
            </p>
          </div>
        </div>
      </section>

      {/* Loan Against Mutual Funds Comparison Table */}
      <section className="pt-4 pb-16 bg-[#ebe8fc]/10" id="mf-section">
        <div id="loan-against-mutual-funds_shares" className=" mx-auto px-4">
          <h2 className="mb-8 text-center text-3xl font-bold text-[#311664]">
            Best Loan Against Mutual Funds Providers of{" "}
            {new Date().toLocaleString("default", { month: "long" })}{" "}
            {new Date().getFullYear()}
          </h2>
          <p className="mb-8 text-center text-[#26292b]">
            Compare the top lenders offering Loan Against Mutual Funds to find
            the best interest rates, LTV ratios, and processing times.
          </p>

          <div className="overflow-x-auto rounded-lg border shadow-md">
            <table className="w-full border-collapse bg-white text-left text-sm">
              <thead className="bg-[#ebe8fc]">
                <tr>
                  <th className="px-6 py-4 font-medium text-[#311664]">
                    Lender
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]">
                    <div className="flex items-center">
                      Minemi Rating
                      <Info className="ml-1 h-4 w-4 text-[#59266d]" />
                    </div>
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]">
                    <div className="flex items-center">
                      Interest Rate
                      <Info className="ml-1 h-4 w-4 text-[#59266d]" />
                    </div>
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]">
                    <div className="flex items-center">
                      Max LTV
                      <Info className="ml-1 h-4 w-4 text-[#59266d]" />
                    </div>
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]">
                    <div className="flex items-center">
                      Processing Fee
                      <Info className="ml-1 h-4 w-4 text-[#59266d]" />
                    </div>
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]"></th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {[
                  {
                    name: "HDFC Bank",
                    img: hdfc,
                    type: "Bank",
                    rating: 5.0,
                    bestFor: "Best overall for mutual fund loans",
                    rate: "10.25-11.75%",
                    ltv: "Up to 65%",
                    fee: "0.50-1.00%",
                    link: "https://apply.hdfcbank.com/vivid/las#LAS_Authentication",
                  },
                  {
                    name: "ICICI Bank",
                    img: icici,
                    type: "Bank",
                    rating: 4.5,
                    bestFor: "Best for existing customers",
                    rate: "10.50-12.00%",
                    ltv: "Up to 60%",
                    fee: "0.50-1.00%",
                    link: "https://www.icicibank.com/personal-banking/loans/loan-against-securities",
                  },
                  {
                    name: "Aditya Birla Finance",
                    img: aditya,
                    type: "NBFC",
                    rating: 4.5,
                    bestFor: "Best for high-value loans",
                    rate: "11.25-13.25%",
                    ltv: "Up to 80%",
                    fee: "1.00-1.50%",
                    link: "https://finance.adityabirlacapital.com/loan-against-securities/apply-now",
                  },
                  {
                    name: "Bajaj Finserv",
                    img: bajaj,
                    type: "NBFC",
                    rating: 4.5,
                    bestFor: "Best for quick processing",
                    rate: "11.50-13.50%",
                    ltv: "Up to 75%",
                    fee: "1.00-2.00%",
                    link: "https://www.bajajfinserv.in/loan-against-shares",
                  },
                  {
                    name: "Mirae Asset",
                    img: Mirai,
                    type: "Fintech",
                    rating: 4.0,
                    bestFor: "Best for digital experience",
                    rate: "11.75-13.75%",
                    ltv: "Up to 70%",
                    fee: "1.00-1.50%",
                    link: "https://transact.miraeassetfin.com/register?_gl=1*mr3huj*_gcl_au*NTg1OTM1NDYxLjE3NDIwNDUyMjg.*_ga*MTA4NzQ2NTU2OC4xNzQyMDQ1MjI5*_ga_21E4CJGJYQ*MTc0MjA0NTIyOC4xLjAuMTc0MjA0NTIyOC42MC4wLjA.",
                  },
                  {
                    name: "Tata Capital",
                    img: tata,
                    type: "NBFC",
                    rating: 4.0,
                    bestFor: "Best for flexible repayment",
                    rate: "11.50-13.50%",
                    ltv: "Up to 70%",
                    fee: "1.00-1.50%",
                    link: "https://www.las.tatacapital.com/online/loans/las/apply-now-las-loan#!",
                  },
                ].map((lender, index) => (
                  <tr key={index} className="hover:bg-[#ebe8fc]/30">
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0 rounded-full overflow-hidden">
                          <img
                            src={lender.img}
                            alt={lender.name}
                            width={40}
                            height={40}
                            className="object-cover"
                          />
                        </div>
                        <div className="ml-3">
                          <div className="font-medium text-[#311664]">
                            {lender.name}
                          </div>
                          <div className="text-xs text-gray-500">
                            {lender.bestFor}
                          </div>
                          <div className="text-xs px-2 py-0.5 bg-[#ebe8fc] rounded-full inline-block mt-1">
                            {lender.type}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <span className="mr-1 font-medium">
                          {lender.rating}
                        </span>
                        <div className="flex">
                          {Array(5)
                            .fill(0)
                            .map((_, i) => (
                              <Star
                                key={i}
                                className={`h-4 w-4 ${
                                  i < Math.floor(lender.rating)
                                    ? "fill-[#59266d] text-[#59266d]"
                                    : "text-gray-300"
                                }`}
                              />
                            ))}
                        </div>
                        <span className="ml-1 text-xs text-gray-500">/5</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 font-medium">{lender.rate}</td>
                    <td className="px-6 py-4">{lender.ltv}</td>
                    <td className="px-6 py-4">{lender.fee}</td>
                    <td className="px-6 py-4">
                      <button
                        className="rounded bg-[#3a868b] px-4 py-2 text-sm font-medium text-white hover:bg-[#3a868b]/90"
                        style={{ width: "7rem" }}
                        onClick={() => {
                          window.location.href = lender.link;
                        }}
                      >
                        Check My Rate
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-4 text-xs text-gray-500">
            <p>
              Many, or all, of the products featured on this page are from our
              partners who compensate us when you take certain actions on our
              website or click to apply on their website. However, this does not
              influence our evaluations. Our opinions are our own.
            </p>
          </div>
        </div>
      </section>

      {/* Top Picks Section */}
      <LasTopPicksSection />

      {/* How It Works Section */}
      <section className="pt-4 pb-16 " style={{ backgroundColor: "#f8f8f8" }}>
        <div className="container mx-auto px-4">
          <h2 className="mb-12 text-center text-3xl font-bold text-[#311664]">
            How Loan Against Securities Works
          </h2>

          <div className="grid md:grid-cols-4 gap-8 max-w-5xl mx-auto">
            <div className="flex flex-col items-center text-center">
              <div className="mb-4 rounded-full bg-[#ebe8fc] p-4 w-16 h-16 flex items-center justify-center relative">
                <span className="absolute -top-2 -right-2 bg-[#59266d] text-white w-6 h-6 rounded-full flex items-center justify-center font-bold">
                  1
                </span>
                <TrendingUp className="h-8 w-8 text-[#59266d]" />
              </div>
              <h3 className="mb-2 text-lg font-bold text-[#311664]">
                Pledge Your Securities
              </h3>
              <p className="text-sm text-[#26292b]">
                Pledge your shares or mutual funds as collateral for the loan
                without selling them.
              </p>
            </div>

            <div className="flex flex-col items-center text-center">
              <div className="mb-4 rounded-full bg-[#ebe8fc] p-4 w-16 h-16 flex items-center justify-center relative">
                <span className="absolute -top-2 -right-2 bg-[#59266d] text-white w-6 h-6 rounded-full flex items-center justify-center font-bold">
                  2
                </span>
                <Search className="h-8 w-8 text-[#59266d]" />
              </div>
              <h3 className="mb-2 text-lg font-bold text-[#311664]">
                Lender Evaluation
              </h3>
              <p className="text-sm text-[#26292b]">
                The lender evaluates your securities and determines the loan
                amount based on LTV ratio.
              </p>
            </div>

            <div className="flex flex-col items-center text-center">
              <div className="mb-4 rounded-full bg-[#ebe8fc] p-4 w-16 h-16 flex items-center justify-center relative">
                <span className="absolute -top-2 -right-2 bg-[#59266d] text-white w-6 h-6 rounded-full flex items-center justify-center font-bold">
                  3
                </span>
                <Clock className="h-8 w-8 text-[#59266d]" />
              </div>
              <h3 className="mb-2 text-lg font-bold text-[#311664]">
                Quick Disbursement
              </h3>
              <p className="text-sm text-[#26292b]">
                After approval, the loan amount is disbursed to your account
                within 24-72 hours.
              </p>
            </div>

            <div className="flex flex-col items-center text-center">
              <div className="mb-4 rounded-full bg-[#ebe8fc] p-4 w-16 h-16 flex items-center justify-center relative">
                <span className="absolute -top-2 -right-2 bg-[#59266d] text-white w-6 h-6 rounded-full flex items-center justify-center font-bold">
                  4
                </span>
                <Shield className="h-8 w-8 text-[#59266d]" />
              </div>
              <h3 className="mb-2 text-lg font-bold text-[#311664]">
                Repay & Release
              </h3>
              <p className="text-sm text-[#26292b]">
                Repay the loan as per the agreed terms, and your securities are
                released back to you.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="pt-4 pb-16 bg-[#ebe8fc]/10">
        <div className="container mx-auto px-4">
          <h2 className="mb-12 text-center text-3xl font-bold text-[#311664]">
            Frequently Asked Questions About Loan Against Securities
          </h2>

          <div className="mx-auto max-w-3xl">
            <div className="space-y-6">
              {[
                {
                  question: "What is a Loan Against Shares?",
                  answer:
                    "A Loan Against Shares is a secured loan where you pledge your equity shares as collateral to obtain a loan. The loan amount is typically 50-70% of the market value of the pledged shares, with interest rates starting from 10.5%.",
                },
                {
                  question: "What is a Loan Against Mutual Funds?",
                  answer:
                    "A Loan Against Mutual Funds is a secured loan where you pledge your mutual fund units as collateral. You can typically get up to 80% of your mutual fund portfolio value as a loan, with interest rates starting from 10.25%.",
                },
                {
                  question:
                    "What is the difference between a bank and NBFC for securities-backed loans?",
                  answer:
                    "Banks are limited to providing loans up to ₹20,00,000 as per RBI guidelines, while NBFCs can offer higher loan amounts. NBFCs typically have faster processing times but slightly higher interest rates compared to banks.",
                },
                {
                  question:
                    "Which securities are eligible for Loan Against Securities?",
                  answer:
                    "Eligible securities typically include shares listed on NSE/BSE, equity mutual funds, debt mutual funds, government securities, and bonds. Each lender maintains an approved list of securities that they accept as collateral.",
                },
                {
                  question:
                    "What happens if I don't repay my Loan Against Securities?",
                  answer:
                    "If you fail to repay your loan, the lender has the right to liquidate your pledged securities to recover the outstanding amount. This could result in potential losses if the market value has decreased.",
                },
                {
                  question: "Can I continue trading with pledged securities?",
                  answer:
                    "Generally, you cannot trade the securities that are pledged as collateral. However, some lenders offer facilities where you can trade within certain limits while maintaining the required collateral value.",
                },
                {
                  question:
                    "What is the margin call in Loan Against Securities?",
                  answer:
                    "A margin call occurs when the value of your pledged securities falls below a certain threshold. The lender will ask you to provide additional securities or repay part of the loan to maintain the required loan-to-value ratio.",
                },
              ].map((faq, index) => (
                <div
                  key={index}
                  className="rounded-lg border bg-white p-6 shadow-sm"
                >
                  <h3 className="mb-2 text-lg font-medium text-[#311664]">
                    {faq.question}
                  </h3>
                  <p className="text-[#26292b]">{faq.answer}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section className="bg-[#ebe8fc] py-10 sm:py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="mb-4 sm:mb-6 text-3xl sm:text-4xl font-bold text-[#311664]">
            Ready to Get a Loan Against Your Securities?
          </h2>
          <p className="mx-auto mb-6 sm:mb-8 max-w-2xl text-base sm:text-lg text-[#26292b]">
            Compare Loan Against Shares and Loan Against Mutual Funds options
            from India's top lenders and find the perfect solution for your
            financial needs.
          </p>
          <div className="flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4 justify-center">
            <button
              className="rounded-md bg-[#59266d] px-4 sm:px-6 py-3 sm:py-4 text-base sm:text-lg font-medium text-white shadow-lg transition-transform hover:scale-105 hover:bg-[#59266d]/90"
              onClick={() =>
                document
                  .getElementById("loan-against-shares_scroll")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
            >
              Compare Loan Against Shares
            </button>
            <button
              className="rounded-md bg-[#3a868b] px-4 sm:px-6 py-3 sm:py-4 text-base sm:text-lg font-medium text-white shadow-lg transition-transform hover:scale-105 hover:bg-[#3a868b]/90"
              onClick={() =>
                document
                  .getElementById("loan-against-mutual-funds_shares")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
            >
              Compare Loan Against Mutual Funds
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};
export default LoanAgainstSharesLandingPage;

import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import tongueOut from "../../../Assets/Images/tongue-out.webp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import MetadataContext from "../../MetadataContext";

const GoldLoanCompanies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        "Best Gold Loan Providers in India | Compare Gold Loan Rates, Amounts & Features",
      pageDescription:
        "Explore the top gold loan companies in India. Compare gold loan interest rates, tenures, and features from SBI, HDFC, Muthoot, Manappuram, and more to secure the best deal for your needs.",
      pageKeywords:
        "Gold loan providers in India, Best gold loan company, Gold loan interest rates 2025, Gold loan comparison, Top 10 gold loan companies in India, Gold loan finance companies, NBFC gold loan list, Gold loan rates today, Gold loan EMI calculator, Gold loan vs personal loan, Gold loan tenure, Gold loan LTV ratio, Gold loan safety measures, Overdraft gold loan, Which bank offers the lowest gold loan interest rate in India, How to apply for gold loan online, Best NBFC for gold loan in 2025, Gold loan interest comparison SBI vs HDFC vs ICICI, Gold loan providers with the highest LTV ratio",
      canonicalUrl:
        "https://minemi.ai/loanpage/gold-loan/gold-loan-providers-in-india",
    });
  }, [setMetadata]);
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/gold-loan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Gold Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Top Gold Loan Providers in India
              </Link>{" "}
            </p>
          </div>

          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontSize: 40,
              color: "#59266D",
              backgroundColor: "#E7E4F9",
              lineHeight: 1,
            }}
          >
            {" "}
            Top Gold Loan Providers in India – Compare Interest Rates, Loan
            Amounts, and Features
          </h1>

          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Compare the top gold loan providers in India, featuring competitive
            interest rates, high loan amounts, and exclusive benefits to meet
            your financial needs. Explore options and choose the best plan
            today!
          </p>
        </div>

        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
          <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 30 }}>
            Introduction – Gold Loan Providers in India and How They Can Help
            You
          </h3>

          <div>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px ",
              }}
            >
              Gold loans are one of the fastest and most accessible ways to
              secure funds for personal and business needs in India. From banks
              to NBFCs (Non-Banking Financial Companies), institutions like{" "}
              <strong>
                State Bank of India (SBI), HDFC Bank, ICICI Bank, Muthoot
                Finance, Manappuram Finance, Bajaj Finserv
              </strong>
              , and <strong>Rupeek</strong> dominate this space by oering
              exible, secured credit using gold as collateral.
            </p>
          </div>
          <div>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Gold loans provide immediate liquidity with minimal documentation.
              Since the asset remains with the lender, interest rates are lower
              compared to unsecured personal loans. Whether you need funds for
              emergencies, weddings, education, or business expansion, gold
              loans oer a quick and secure nancial solution.
            </p>
          </div>

          <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
            Why Comparing Gold Loan Providers is Crucial
          </h3>
          <div>
            <h3
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Gold loan oerings vary signicantly across lenders in terms of:
            </h3>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rates :{" "}
                  </span>
                  Directly impacts overall loan costs.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan-to-Value (LTV) Ratio:{" "}
                  </span>
                  Higher LTV means higher payouts against your gold.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Repayment Models:{" "}
                  </span>
                  Choose between bullet payments, EMIs, and overdraft
                  facilities.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Tenure:{" "}
                  </span>
                  Longer tenures reduce monthly payments but increase overall
                  interest.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing & Foreclosure Charges:{" "}
                  </span>
                  Hidden fees can raise the eective cost.
                </div>
              </div>
            </div>

            <p
              style={{
                color: "#1F1F1F",
                fontSize: 16,
                fontWeight: 600,
                marginTop: 16,
              }}
            >
              Careful comparison ensures you secure a gold loan that is
              aordable, transparent, and aligns with your repayment capacity.
            </p>
          </div>

          {/* table data */}
          <div id="types-of-bt">
            <div id="eligibility-criteria">
              <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
                Gold Loan Comparison Table – Top Banks & NBFCs in India
              </h3>
            </div>

            <div
              id="bt-types-table"
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                      }}
                    >
                      Bank/NBFC
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Interest Rate (p.a.)
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Loan Amount
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Tenure
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Processing Fees
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Most Active Regions
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Key Feature
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      SBI
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      7.30% – 8.80%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹20,000 – ₹50 lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Up to 36 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      0.25%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Pan India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Flexible tenure, low rates
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      HDFC Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.50% – 16.50%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹25,000 – ₹1 crore
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      3 to 24 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Pan India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Overdraft facility
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      ICICI Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      9.00% – 19.76%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹50,000 – ₹1 crore
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      6 to 12 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Pan India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Instant disbursal
                    </td>
                  </tr>

                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      PNB
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      9.25%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹25,000 – ₹25 lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Up to 18 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      0.75%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      North & Central India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      MSME-friendly loans
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Canara Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      7.25%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹5,000 – ₹35 lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Up to 12 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      0.5%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      South India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Agricultural gold loans
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Axis Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      14.50%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹25,000 – ₹40 lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      6 to 36 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      0.50%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Pan India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Digital gold loan
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Union Bank of India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.70% – 9.40%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹15,000 – ₹25 lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Up to 36 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      0.5%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      East & North India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      High LTV
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Muthoot Finance
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      12.00% – 18.00%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹1,000 – ₹1.5 crore
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      6 to 24 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      South India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Bullet repayment
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Manappuram Finance
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      9.90% – 14.00%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹10,000 – ₹40 lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      6 to 12 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      South & West India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Overdraft facility
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Bajaj Finserv
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.85%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹25,000 – ₹2 crore
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      3 to 12 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1.25%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Urban India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      EMI-based gold loans
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      IIFL Finance
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      9.24%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹3,000 – ₹50 lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      6 to 36 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Pan India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Fully digital process
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Rupeek
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.25% – 14.00%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹10,000 – ₹1 crore
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      6 to 24 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Metros & Tier-1 Cities
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Home pick-up service
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      IndusInd Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      9.50% – 13.00%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹50,000 – ₹50 lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      6 to 24 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      0.75%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Urban India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Low foreclosure charges
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Federal Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      10.00%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹10,000 – ₹20 lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Up to 36 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      South India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Agricultural gold loan
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Kotak Mahindra Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      9.80% – 14.50%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹25,000 – ₹30 lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      6 to 12 months
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      0.50%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Urban India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Fast disbursal
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div>
            <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
              {" "}
              Key Gold Loan Providers – Features, Security, and Customer Service
            </h3>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  fontSize: 22,
                  marginTop: 20,
                }}
              >
                1. State Bank of India (SBI) Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  7.30% – 8.80%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹20,000 – ₹50 lakh
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  Up to 36 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  0.25%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  22-carat gold storage in secure vaults.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  Highly rated for its smooth digital loan process.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Instant disbursal and overdraft against gold
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  Part-payment and foreclosure allowed without penalties.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Extensive reach across urban and semi-urban areas.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Opt for HDFC if you need instant liquidity with low
                    documentation.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  fontSize: 22,
                  marginTop: 20,
                }}
              >
                2. HDFC Bank Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  8.50% – 16.50%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹25,000 – ₹1 crore
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  3 to 24 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  1%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Gold stored in fully insured secure vaults.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  Highly rated for its smooth digital loan process.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Instant disbursal and overdraft against gold.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  Part-payment and foreclosure allowed without penalties.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Extensive reach across urban and semi-urban areas.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Opt for HDFC if you need instant liquidity with low
                    documentation.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  fontSize: 22,
                  marginTop: 20,
                }}
              >
                3. ICICI Bank Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  9.00% – 19.76%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹50,000 – ₹1 crore
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  6 to 12 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  1%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Tamper-proof packaging and secured vaults.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  Seamless online gold loan applications with quick
                  disbursement.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Gold loan top-up facility for existing customers.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  Flexible repayment with part pre-payment.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Large branch network and robust digital services.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    ICICI Bank’s fast approval and high loan amounts make it
                    suitable for business owners.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  fontSize: 22,
                  marginTop: 20,
                }}
              >
                4. IDFC First Bank Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  9.50% – 12.50%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹10,000 – ₹50 lakh
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  6 to 24 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  0.75% – 1%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Advanced safety protocols with 24/7 surveillance of vaults.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  Transparent processes and high approval rate.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Fully digital loan process with doorstep gold evaluation.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  No charges for prepayment after 6 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Growing reach in urban and semi-urban areas.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    IDFC First Bank gold loans are ideal for salaried
                    individuals due to competitive interest rates.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  fontSize: 22,
                  marginTop: 20,
                }}
              >
                5. Bajaj Finserv Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  8.85%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹25,000 – ₹2 crore
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  3 to 12 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  1.25%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Secure vaults and comprehensive insurance cover.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  Quick disbursals, trusted NBFC in gold nancing.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  EMI-based gold loan repayment options.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  Early foreclosure with minimal charges.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Wide presence in urban areas with digital onboarding.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Bajaj’s EMI option provides exibility, making it perfect for
                    managing monthly cash ow.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  fontSize: 22,
                  marginTop: 20,
                }}
              >
                6. Punjab National Bank (PNB) Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  9.25%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹25,000 – ₹25 lakh
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  Up to 18 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  0.75%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Safe custody with tamper-proof sealing.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  Transparent documentation and quick loan disbursals.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Specialized loans for MSMEs and farmers.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  No foreclosure charges for agricultural loans.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Strong rural and semi-urban reach.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    PNB oers specialized schemes for MSME customers, making it
                    attractive for small businesses.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  fontSize: 22,
                  marginTop: 20,
                }}
              >
                7. Bank of India (BOI) Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  9.30% – 11.50%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹15,000 – ₹25 lakh
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  Up to 36 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  0.5%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Advanced gold storage facilities with biometric access.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  Trusted by small business owners and farmers.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Rural-focused gold loan schemes.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  Flexible repayment and foreclosure options.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Strong rural presence across India.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    BOI is great for agricultural gold loans with competitive
                    interest rates.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  fontSize: 22,
                  marginTop: 20,
                }}
              >
                8. Canara Bank Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  7.25%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹5,000 – ₹35 lakh
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  Up to 12 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  0.5%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Gold is stored in insured vaults under high security.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  Excellent customer service with low-interest loans.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Agricultural and MSME-specic schemes.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  Free foreclosure after 6 months.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Strong in North and Eastern India.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Union Bank’s high LTV and low fees make it ideal for
                    agricultural customers.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  fontSize: 22,
                  marginTop: 20,
                }}
              >
                9. Federal Bank Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  10.00%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>{" "}
                  ₹10,000 – ₹20 lakh
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  Up to 36 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  1%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Vault security with insured deposits.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  High ratings for quick loan approvals.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Flexible LTV options.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  Part payment available anytime.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Strong in Kerala and South India.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Federal Bank is the go-to for exible repayment terms and
                    higher LTV ratios.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p
              className="pt-3 pb-3"
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: 16,
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
              Here’s the continuation and expansion of the list featuring
              additional key gold loan providers in India, structured in the
              same format to ensure detailed comparison and clarity.
            </p>
          </div>
          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  fontSize: 22,
                  marginTop: 20,
                }}
              >
                10. Axis Bank Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  14.50%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹25,000 – ₹40 lakh
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  6 to 36 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  0.50%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Secure vault storage with full insurance.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  High ratings for fast approvals and urban focus.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Digital gold loan products and paperless disbursal.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  Partial and full repayment without foreclosure charges after 6
                  months.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Wide presence in urban and semi-urban area
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Axis Bank’s digital loan process is best suited for
                    tech-savvy customers seeking quick disbursement
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  fontSize: 22,
                  marginTop: 20,
                }}
              >
                11. Union Bank of India Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  8.70% – 9.40%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹15,000 – ₹25 lakh
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  Up to 36 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  0.50%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Secure vaults with tamper-proof storage.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  Known for rural outreach and customer-centric processes.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Agricultural gold loans with higher LTV for farmers.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  Free foreclosure after 3 months.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Extensive with branches across India.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    SBI is best for low-interest rates and long tenures, making
                    it ideal for large loan amounts.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  marginTop: 20,
                  fontSize: 22,
                }}
              >
                12. IIFL Finance Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  9.24%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹3,000 – ₹50 lakh
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  6 to 36 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  1%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Advanced tamper-proof gold packaging and vault storage.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  Rated highly for ease of loan application and disbursal.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Fully digital gold loan process with doorstep pickup
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  Zero foreclosure penalty after 12 months.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Pan India, strong in metros and Tier-1 cities.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    IIFL Finance is perfect for borrowers looking for a seamless
                    online gold loan experience.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  marginTop: 20,
                  fontSize: 22,
                }}
              >
                13. Rupeek Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  8.25% – 14.00%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹10,000 – ₹1 crore
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  6 to 24 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  1%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Gold is picked up from your home and stored in bank-grade
                  vaults.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  High satisfaction for its innovative home pick-up and digital
                  process.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Loan disbursal at home through mobile app and doorstep
                  service.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  Early foreclosure allowed without penalties.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Active in major metros and Tier-1 cities.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Rupeek is the top choice for borrowers who prefer at-home
                    gold loan services.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  marginTop: 20,
                  fontSize: 22,
                }}
              >
                14. IndusInd Bank Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  9.50% – 13.00%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹50,000 – ₹50 lakh
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  6 to 24 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  0.75%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Secure vaults with biometric access.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  Praised for quick loan processing and transparency.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Flexible repayment models and overdraft facilities.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  No foreclosure charges post 6 months.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Primarily urban-focused with growing semi-urban presence.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    IndusInd offers quick processing, making it ideal for
                    business owners need of instant liquidity.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  marginTop: 20,
                  fontSize: 22,
                }}
              >
                15. Kotak Mahindra Bank Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  9.80% – 14.50%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹25,000 – ₹30 lakh
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  6 to 12 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  0.50%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Tamper-proof vault storage with 24/7 surveillance
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  High rating for customer-centric gold loan process.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Pre-approved gold loans for existing Kotak customers.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  Early closure after 3 months with minimal charges.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Extensive in metro cities and expanding rural reach.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    kotak Mahindra Bank’s pre-approved gold loan facility offers
                    fast access to funds.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  marginTop: 20,
                  fontSize: 22,
                }}
              >
                16. South Indian Bank Gold Loa
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  8.90% – 11.50%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹10,000 – ₹20 lakh
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  Up to 36 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  0.50%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Gold stored securely in tamper-proof vaults
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  Positive reviews for fast disbursals and low documentation.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Special agricultural schemes for rural customers.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  Free foreclosure allowed after 6 months.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Primarily active in South India.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    South Indian Bank’s gold loans are best for agricultural and
                    small business needs.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  marginTop: 20,
                  fontSize: 22,
                }}
              >
                17. Yes Bank Gold Loan
              </h3>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Interest Rate:{" "}
                  </span>
                  10.00% – 15.00%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹25,000 – ₹50 lakh
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Tenure:{" "}
                  </span>
                  6 to 36 months
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fee:{" "}
                  </span>
                  1%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Security:{" "}
                  </span>
                  Gold is stored in insured and monitored vaults.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Customer Satisfaction:{" "}
                  </span>
                  Known for excellent service and transparent processes.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Innovation:{" "}
                  </span>
                  Part-payment flexibility with EMI options.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Closure Options:{" "}
                  </span>
                  Foreclosure allowed after 3 months with minimal charges.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Network:{" "}
                  </span>
                  Urban-focused with digital loan offering
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Yes Bank’s flexibility makes it a great option for
                    self-employed borrowers.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 25,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Final Thoughts
            </div>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                marginTop: "10px",
                lineHeight: "24px",
                fontWeight: 400,
              }}
            >
              The gold loan market in India is highly competitive, with numerous
              banks and NBFCs offering attractive schemes. Whether you prefer
              the reliability of SBI and HDFC, or t innovative approaches of
              Rupeek and Bajaj Finserv, there is a solution tailored to your
              needs. By comparing interest rates, tenures, and special features,
              borrowers can make informed decisions to optimize their borrowing
              experience.
            </p>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                marginTop: "10px",
                lineHeight: "24px",
                fontWeight: 400,
              }}
            >
              <strong>Call to Action:</strong>Start your gold loan journey with
              Minemi by comparing these top lenders today to find the best deals
              and schemes that align with your financial goals.
            </p>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Frequently Asked Questions (FAQs){" "}
          </div>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Which banks and NBFCs offer the best gold loan in India?
              </AccordionSummary>
              <AccordionDetails>
                Top providers include{" "}
                <strong>
                  State Bank of India (SBI), HDFC Bank, ICICI Bank, Punjab
                  National Bank (PNB), Bank of India (BOI), Muthoot Finance,
                  Manappuram Finance, Bajaj Finserv, IDFC First Bank, and
                  Rupeek.
                </strong>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                What is the difference between gold loans offered by banks and
                NBFCs?
              </AccordionSummary>
              <AccordionDetails>
                Banks generally offer lower interest rates (7.25% - 10%) but
                require more documentation. NBFCs like Muthoot and Manappuram
                provide faster disbursement and higher LTV (up to 75%) but may
                charge slightly higher interest rates (12% - 18%).
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                How do I apply for a gold loan from banks like SBI or ICICI?
              </AccordionSummary>
              <AccordionDetails>
                You can apply by visiting the nearest branch, applying online
                via their official websites or using their mobile apps. Some
                NBFCs like Rupeek and Muthoot offer doorstep goid loan services.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Which gold loan provider has the lowest interest rates?
              </AccordionSummary>
              <AccordionDetails>
                As of now, Canara Bank (7.25%), SBI (7.30%), and Union Bank of
                India (8.70%) offer so of the lowest interest rates on gold
                loans.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What is the typical processing time for a gold loan?
              </AccordionSummary>
              <AccordionDetails>
                Most banks and NBFCs process gold loans within 1 to 3 hours
                after gold valuation and documentation. NBFCs like Muthoot and
                Manappuram are known for instant disbursement.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Who is eligible to apply for a gold loan?
              </AccordionSummary>
              <AccordionDetails>
                Any Indian citizen aged 18 years and above who owns gold
                jewelry, coins, or ornaments can apply.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What documents are required for a gold loan from banks and
                NBFCs?
              </AccordionSummary>
              <AccordionDetails>
                <ul style={{ listStyle: "disc" }}>
                  <li>
                    <strong className="px-1">Identity Proof:</strong>Aadhaar,
                    PAN Card, Passport
                  </li>
                  <li className="pt-2">
                    <strong className="px-1">Address Proof: </strong>Utility
                    Bill, Voter ID, Driving License
                  </li>
                  <li className="pt-2">
                    <strong className="px-1"> Photographs </strong>and sometimes
                    income proof for higher loan amounts.
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Can I apply for a gold loan online?
              </AccordionSummary>
              <AccordionDetails>
                Yes, major lenders like HDFC, ICICI, Rupeek, Bajaj Finserv, and
                SBI allow online applications with minimal paperwork. NBFCs like
                Rupeek provide doorstep gold collection services.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Do I need to have a bank account with the lender to apply for a
                gold loan?
              </AccordionSummary>
              <AccordionDetails>
                No, most banks and NBFCs allow you to apply without holding an
                account. However, account holders may receive discounted
                interest rates or waived processing fees.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                How quickly is the gold loan amount disbursed?
              </AccordionSummary>
              <AccordionDetails>
                For most banks and NBFCs, the loan is disbursed within a few
                hours after gold valuation and documentation.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Which gold loan providers offer the highest loan-to-value (LTV)
                ratio?
              </AccordionSummary>
              <AccordionDetails>
                NBFCs like Muthoot Finance, Manappuram Finance, and Rupeek offer
                up to 75% LTV of the gold’s market value. Banks generally offer
                between 65% to 75% LTV.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                How is the interest rate calculated for gold loans?
              </AccordionSummary>
              <AccordionDetails>
                Interest rates are determined by:
                <ul style={{ listStyle: "disc" }}>
                  <li className="pt-2">
                    <strong className="px-1">Gold Value (LTV Ratio)</strong>
                  </li>
                  <li className="pt-2">
                    <strong className="px-1">Loan Amount and Tenure</strong>
                  </li>
                  <li className="pt-2">
                    <strong className="px-1">
                      {" "}
                      Borrower’s creditworthiness{" "}
                    </strong>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Do banks offer lower interest rates than NBFCs on gold loans?
              </AccordionSummary>
              <AccordionDetails>
                Yes. Banks like SBI, Canara Bank, and Union Bank of India
                typically offer .
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br className="display-none" /> and tailored just for you—apply
                now to see what you qualify for!
              </p>
              <div
                style={{
                  backgroundColor: "transparent",
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                }}
              >
                <a
                  href="https://minemi.ai/personal-loan/check-eligibility"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 50,
                    paddingRight: 50,
                    fontFamily: "Archivo",
                    fontWeight: 600,
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                >
                  Check Eligiblity Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoldLoanCompanies;

import { Star, Info, CheckCircle, Clock, Shield } from "lucide-react";
import "./PersonalLoanCompareLandingPage.css";
import TopPicksSection from "./TopPicksSection";
import { useNavigate } from "react-router-dom";
import ABF from "../../../Assets/Images/ABF.webp";
import BF from "../../../Assets/Images/BF-photoaidcom-cropped.webp";
import PF from "../../../Assets/Images/Piramil.png";
import TC from "../../../Assets/Images/tata-capital.png";
import HDFC from "../../../Assets/Images/hdfc.png";
import icici from "../../../Assets/Images/ICICIBank-circular.webp";
import yes from "../../../Assets/Images/yes_bank_circular.webp";
import lt from "../../../Assets/Images/LT_circular.webp";
import fibe from "../../../Assets/Images/Fibe-circular.webp";
import kredit from "../../../Assets/Images/kredit_bee_circular.webp";
import bob from "../../../Assets/Images/Bank-of-Baroda-icon.png";
import IDFC from "../../../Assets/Images/idfc.png";
import MetadataContext from "../../MetadataContext";
import { useContext, useEffect } from "react";

export default function PersonalLoanCompareLandingPage() {
  const navigate = useNavigate();

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Best Personal Loans 2025 | Compare & Apply Online - Minemi",
      pageDescription:
        "Compare personal loans from India's top banks & NBFCs. Find the lowest EMIs & best rates for 2025. Check eligibility & apply online with Minemi.",
      pageKeywords:
        "best personal loans 2025, compare personal loans, apply personal loans online, Minemi loans, low-interest personal loans, best loan rates 2025, online personal loan comparison, quick personal loans, unsecured personal loans, personal loan eligibility, best loan providers 2025, instant loan approval, fast personal loans, no credit check loans, top personal loan offers 2025",

      canonicalUrl:
        "https://minemi.ai/loanpage/personalloan/best-personal-loans-2025-compare",
    });
  }, [setMetadata]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="flex min-h-screen flex-col "
      style={{ backgroundColor: "#f8f8f8" }}
    >
      {/* Hero Section */}
      <section className="bg-gradient-to-b from-[#ebe8fc] to-[#f8f8f8]  py-5">
        <div className="container mx-auto px-4">
          <div className="mx-auto max-w-3xl text-center">
            <h1 className=" plc_heading mb-4  font-bold leading-tight ">
              Find the Best Personal Loan: Compare, Save, and Apply Smartly!
            </h1>
            <p className="mb-8 text-lg text-[#26292b]">
              Compare personal loans from India's top lenders to find the lowest
              EMIs and best rates tailored to your needs. Make smarter financial
              decisions with Minemi.
            </p>
            <button
              className="plc_cta rounded-md bg-[#3a868b] px-3 px-sm-5 py-3 text-lg font-medium text-white shadow-lg transition-transform hover:scale-105 hover:bg-[#3a868b]/90"
              onClick={() =>
                document
                  .getElementById("personal_loan_check_offers")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
            >
              Check Your Loan Offers Now
            </button>
          </div>
        </div>
      </section>

      {/* Loan Comparison Table */}
      <section className="py-16">
        <div id="personal_loan_compare_now" className=" mx-auto px-2 px-sm-4">
          <h2 className="mb-8 text-center text-3xl font-bold text-[#311664]">
            Best Personal Loan Lenders of{" "}
            {new Date().toLocaleString("default", { month: "long" })}{" "}
            {new Date().getFullYear()}
          </h2>
          <p className="mb-8 text-center text-[#26292b]">
            The best personal loan lenders offer affordable monthly payments at
            a rate that fits your budget. Compare personal loans from multiple
            lenders before making a choice.
          </p>

          <div className="overflow-x-auto rounded-lg border shadow-md">
            <table className="w-full border-collapse bg-white text-left text-sm">
              <thead className="bg-[#ebe8fc]">
                <tr>
                  <th
                    className="px-6 py-4 font-medium text-[#311664]"
                    style={{ verticalAlign: "top" }}
                  >
                    Lender
                  </th>
                  <th
                    className="px-6 py-4 font-medium text-[#311664]"
                    style={{ verticalAlign: "top" }}
                  >
                    <div className="flex items-center">
                      Minemi Rating
                      <Info className="ml-1 h-4 w-4 text-[#59266d]" />
                    </div>
                  </th>
                  <th
                    className="px-6 py-4 font-medium text-[#311664]"
                    style={{ verticalAlign: "top" }}
                  >
                    <div className="flex items-center">
                      Est. APR
                      <Info className="ml-1 h-4 w-4 text-[#59266d]" />
                    </div>
                  </th>
                  <th
                    className="px-6 py-4 font-medium text-[#311664]"
                    style={{ verticalAlign: "top" }}
                  >
                    Loan Amount
                  </th>
                  <th
                    className="px-6 py-4 font-medium text-[#311664]"
                    style={{ verticalAlign: "top" }}
                  >
                    <div className="flex items-center">
                      Min. Credit Score
                      <Info className="ml-1 h-4 w-4 text-[#59266d]" />
                    </div>
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]"></th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {[
                  {
                    name: "HDFC Bank",
                    img: HDFC,
                    rating: 5.0,
                    bestFor: "Best for existing bank customers",
                    apr: "10.50-21.00%",
                    amount: "₹50,000 - ₹40,00,000",
                    score: "750",
                    link: "/personalloan/applyonline",
                  },
                  {
                    name: "Bajaj Finserv",
                    img: BF,
                    rating: 4.5,
                    bestFor: "Best for quick disbursals",
                    apr: "11.99-16.00%",
                    amount: "₹1,00,000 - ₹20,00,000",
                    score: "750",
                    link: "/personalloan/applyonline",
                  },
                  {
                    name: "Aditya Birla Finance",
                    img: ABF,
                    rating: 5.0,
                    bestFor: "Best for borrowers with good to excellent credit",
                    apr: "10.99% - 24.00%",
                    amount: "₹50,000 - ₹25,00,000",
                    score: "700",
                  },

                  {
                    name: "Piramal Finance",
                    img: PF,
                    rating: 4.5,
                    bestFor: "Best for self-employed individuals",
                    apr: "12.50% - 20.00%",
                    amount: "₹50,000 - ₹30,00,000",
                    score: "720",
                    link: "/personalloan/applyonline",
                  },
                  {
                    name: "ICICI Bank",
                    img: icici,
                    rating: 5.0,
                    bestFor: "Best for existing bank customers",
                    apr: "10.25% - 19.75%",
                    amount: "₹50,000 - ₹35,00,000",
                    score: "750",
                    link: "/personalloan/applyonline",
                  },
                  {
                    name: "Yes Bank",
                    img: yes,
                    rating: 4.2,
                    bestFor: "Best for existing bank customers",
                    apr: "11.50% - 24.00%",
                    amount: "₹1,00,000 - ₹20,00,000",
                    score: "700",
                    link: "/personalloan/applyonline",
                  },
                  {
                    name: "L&T Finance",
                    img: lt,
                    rating: 4.3,
                    bestFor: "Best for existing bank customers",
                    apr: "12.00% - 18.50%",
                    amount: "₹50,000 - ₹15,00,000",
                    score: "720",
                    link: "/personalloan/applyonline",
                  },
                  {
                    name: "Fibe",
                    img: fibe,
                    rating: 4.4,
                    bestFor: "Best for existing bank customers",
                    apr: "14.00% - 27.00%",
                    amount: "₹5,000 - ₹5,00,000",
                    score: "650",
                    link: "/personalloan/applyonline",
                  },
                  {
                    name: "Kredit Bee",
                    img: kredit,
                    rating: 4.3,
                    bestFor: "Best for existing bank customers",
                    apr: "15.00% - 30.00%",
                    amount: "₹10,000 - ₹3,00,000",
                    score: "650",
                    link: "/personalloan/applyonline",
                  },
                  {
                    name: "Bank of Baroda",
                    img: bob,
                    rating: 5.0,
                    bestFor: "Best for existing bank customers",
                    apr: "10.50% - 18.75%",
                    amount: "₹1,00,000 - ₹40,00,000",
                    score: "750",
                    link: "/personalloan/applyonline",
                  },
                  {
                    name: "Tata Capital",
                    img: TC,
                    rating: 4.6,
                    bestFor: "Best for flexible repayment options",
                    apr: "11.99% - 22.00%",
                    amount: "₹75,000 - ₹25,00,000",
                    score: "720",
                    link: "/personalloan/applyonline",
                  },

                  // {
                  //   name: "IDFC First Bank",
                  //   img: IDFC,
                  //   rating: 4.5,
                  //   bestFor: "Best for low interest rates",
                  //   apr: "10.49-18.00%",
                  //   amount: "₹30,000-₹30,00,000",
                  //   score: "725",
                  // },
                ].map((lender, index) => (
                  <tr key={index} className="hover:bg-[#ebe8fc]/30">
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0 rounded-full overflow-hidden">
                          <img
                            src={lender.img}
                            alt={lender.name}
                            className="object-cover w-10 h-10"
                          />
                        </div>
                        <div className="ml-3">
                          <div className="font-medium text-[#311664]">
                            {lender.name}
                          </div>
                          <div className="text-xs text-gray-500">
                            {lender.bestFor}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <span className="mr-1 font-medium">
                          {lender.rating}
                        </span>
                        <div className="flex">
                          {Array(5)
                            .fill(0)
                            .map((_, i) => (
                              <Star
                                key={i}
                                className={`h-4 w-4 ${
                                  i < Math.floor(lender.rating)
                                    ? "fill-[#59266d] text-[#59266d]"
                                    : "text-gray-300"
                                }`}
                              />
                            ))}
                        </div>
                        <span className="ml-1 text-xs text-gray-500">/5</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 font-medium">{lender.apr}</td>
                    <td className="px-6 py-4">{lender.amount}</td>
                    <td className="px-6 py-4">{lender.score}</td>
                    <td className="px-2 py-4">
                      <button
                        className="rounded bg-[#59266d] px-4 py-2 text-sm font-medium text-white hover:bg-[#59266d]/90"
                        style={{ width: "7rem" }}
                        onClick={() => {
                          // window.location.href = lender.link;
                          navigate(lender.link);
                        }}
                      >
                        Check My Rate
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-4 text-xs text-gray-500">
            <p>
              Many, or all, of the products featured on this page are from our
              partners who compensate us when you take certain actions on our
              website or click to apply on their website. However, this does not
              influence our evaluations. Our opinions are our own.
            </p>
          </div>
        </div>
      </section>
      <div id="personal_loan_check_offers">
        <TopPicksSection />
      </div>

      {/* Key Features Section */}
      <section className="bg-[#ebe8fc]/30 py-16">
        <div className="container mx-auto px-4">
          <h2 className="mb-12 text-center text-3xl font-bold text-[#311664]">
            Why Choose Minemi
          </h2>
          <div className="grid gap-8 md:grid-cols-3">
            <div className="flex flex-col items-center rounded-lg bg-white p-6 text-center shadow-md">
              <div className="mb-4 rounded-full bg-[#ebe8fc] p-4">
                <CheckCircle className="h-8 w-8 text-[#59266d]" />
              </div>
              <h3 className="mb-2 text-xl font-bold text-[#311664]">
                Lower Your EMI
              </h3>
              <p className="text-[#26292b]">
                Compare loans from multiple lenders to find the lowest interest
                rates and save more on your monthly EMIs.
              </p>
            </div>
            <div className="flex flex-col items-center rounded-lg bg-white p-6 text-center shadow-md">
              <div className="mb-4 rounded-full bg-[#ebe8fc] p-4">
                <Clock className="h-8 w-8 text-[#59266d]" />
              </div>
              <h3 className="mb-2 text-xl font-bold text-[#311664]">
                Instant Eligibility Check
              </h3>
              <p className="text-[#26292b]">
                See your personalized loan options in minutes without affecting
                your credit score.
              </p>
            </div>
            <div className="flex flex-col items-center rounded-lg bg-white p-6 text-center shadow-md">
              <div className="mb-4 rounded-full bg-[#ebe8fc] p-4">
                <Shield className="h-8 w-8 text-[#59266d]" />
              </div>
              <h3 className="mb-2 text-xl font-bold text-[#311664]">
                No Hidden Fees
              </h3>
              <p className="text-[#26292b]">
                We provide transparent and unbiased loan comparisons with no
                hidden charges or surprises.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="mb-12 text-center text-3xl font-bold text-[#311664]">
            What Our Users Say
          </h2>
          <div className="grid gap-8 md:grid-cols-3">
            {[
              {
                name: "Rahul Sharma",
                location: "Mumbai",
                rating: 5,
                comment:
                  "Minemi helped me find a personal loan with an interest rate 2% lower than what my bank offered. The comparison tool was easy to use and saved me thousands of rupees!",
              },
              {
                name: "Priya Patel",
                location: "Bangalore",
                rating: 5,
                comment:
                  "I was hesitant about applying for a personal loan until I found Minemi. Their eligibility checker showed me exactly what I qualified for without affecting my credit score.",
              },
              {
                name: "Amit Verma",
                location: "Delhi",
                rating: 4,
                comment:
                  "The loan comparison feature is fantastic! I could easily see all the options side by side and choose the best one for my needs. Highly recommend Minemi to anyone looking for a loan.",
              },
            ].map((testimonial, index) => (
              <div
                key={index}
                className="rounded-lg border bg-white p-6 shadow-md"
              >
                <div className="mb-4 flex items-center">
                  <div className="h-12 w-12 rounded-full bg-[#ebe8fc]"></div>
                  <div className="ml-4">
                    <h4 className="font-medium text-[#311664]">
                      {testimonial.name}
                    </h4>
                    <p className="text-sm text-gray-500">
                      {testimonial.location}
                    </p>
                  </div>
                </div>
                <div className="mb-4 flex">
                  {Array(5)
                    .fill(0)
                    .map((_, i) => (
                      <Star
                        key={i}
                        className={`h-5 w-5 ${
                          i < testimonial.rating
                            ? "fill-[#59266d] text-[#59266d]"
                            : "text-gray-300"
                        }`}
                      />
                    ))}
                </div>
                <p className="text-[#26292b]">{testimonial.comment}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section className="bg-[#ebe8fc] py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="mb-6 text-4xl font-bold text-[#311664]">
            Find Your Best Loan in 2 Minutes!
          </h2>
          <p className="mx-auto mb-8 max-w-2xl text-lg text-[#26292b]">
            Compare personal loans from India's top lenders and find the perfect
            option for your financial needs.
          </p>
          <button
            className="rounded-md bg-[#311664]  px-5 py-3 text-lg font-medium text-white shadow-lg transition-transform hover:scale-105 hover:bg-[#311664]/90"
            onClick={() =>
              document
                .getElementById("personal_loan_compare_now")
                ?.scrollIntoView({ behavior: "smooth" })
            }
          >
            Start Comparing Now
          </button>
        </div>
      </section>
    </div>
  );
}

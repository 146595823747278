
import { useState, useRef, useEffect } from "react"
import { Star, Home, Building, ChevronLeft, ChevronRight } from "lucide-react"

const testimonials = [
  {
    name: "Ananya Iyer",
    location: "Chennai",
    quote:
      "Minemi made the home loan process so simple! I found the lowest interest rate in minutes and got my dream home without the usual bank hassle.",
    loanType: "Home Loan",
    bank: "HDFC Bank",
    image: "/placeholder.svg?height=100&width=100&text=AI",
    rating: 5,
  },
  {
    name: "Vikram Mehta",
    location: "Pune",
    quote:
      "I was confused about which loan to take, but Minemi's comparison tool helped me choose the best deal with the lowest EMI. Super easy and fast!",
    loanType: "Home Loan",
    bank: "SBI",
    image: "/placeholder.svg?height=100&width=100&text=VM",
    rating: 5,
  },
  {
    name: "Roshni Agarwal",
    location: "Bangalore",
    quote:
      "I saved ₹3.2 lakhs in interest thanks to Minemi! The detailed breakdown made it so easy to decide. Highly recommend!",
    loanType: "Home Loan",
    bank: "ICICI Bank",
    image: "/placeholder.svg?height=100&width=100&text=RA",
    rating: 5,
  },
  {
    name: "Arjun Verma",
    location: "Delhi",
    quote:
      "I never thought getting a home loan could be this hassle-free. Minemi showed me multiple options, and I got the best rate without running around banks.",
    loanType: "Home Loan",
    bank: "Axis Bank",
    image: "/placeholder.svg?height=100&width=100&text=AV",
    rating: 5,
  },
]

export default function TestimonialSection({scrollToFilter}) {

  const [currentIndex, setCurrentIndex] = useState(0)
  const testimonialRef = useRef(null)

  const scrollToTestimonial = (index) => {
    if (testimonialRef.current) {
      const scrollWidth = testimonialRef.current.scrollWidth
      const itemWidth = scrollWidth / testimonials.length
      testimonialRef.current.scrollTo({
        left: itemWidth * index,
        behavior: "smooth",
        scrollWidth:'none'
      })
    }
    setCurrentIndex(index)
  }

  const handlePrev = () => {
    const newIndex = (currentIndex - 1 + testimonials.length) % testimonials.length
    scrollToTestimonial(newIndex)
  }

  const handleNext = () => {
    const newIndex = (currentIndex + 1) % testimonials.length
    scrollToTestimonial(newIndex)
  }

  useEffect(() => {
    const handleResize = () => {
      if (testimonialRef.current) {
        const scrollWidth = testimonialRef.current.scrollWidth
        const itemWidth = scrollWidth / testimonials.length
        testimonialRef.current.scrollTo({
          left: itemWidth * currentIndex,
          behavior: "auto",
        })
      }
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [currentIndex])

  return (
    <>
    <section className="bg-gradient-to-b from-white to-[#ebe8fc] py-6 sm:py-10 md:py-16 px-3 sm:px-6 md:px-8">
      <div className="max-w-7xl mx-auto" style={{backgroundColor:'transparent'}}>
        <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-center text-[#311664] mb-2 sm:mb-4" style={{backgroundColor:'transparent'}}>
          Real Stories, Real Savings – How Minemi Helped Indians Own Their Dream Home
        </h2>
        <p className="text-sm text-center text-gray-600 mb-4" style={{backgroundColor:'transparent'}}>Trusted by thousands of homeowners across India</p>

        <div className="relative" style={{backgroundColor:'transparent'}}>
          <div ref={testimonialRef} className="flex overflow-x-hidden snap-x snap-mandatory" style={{backgroundColor:'transparent'}}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="flex-shrink-0 w-full snap-center px-4" style={{backgroundColor:'transparent'}}>
                <div className="bg-white rounded-lg shadow-md p-3 sm:p-4 md:p-6 transition-transform duration-300 hover:scale-105 h-full" >
                  <div className="flex items-start mb-2" style={{backgroundColor:'transparent'}}> 
                    <img
                      src='https://kzmj9hppznbs43tnwh2p.lite.vusercontent.net/placeholder.svg?height=100&width=100&text=AI'
                      alt={testimonial.name}
                      width={40}
                      height={40}
                      className="rounded-full mr-2 sm:w-12 sm:h-12 md:w-16 md:h-16"
                      style={{backgroundColor:'transparent'}}
                    />
                    <div style={{backgroundColor:'transparent'}}>
                      <h3 className="text-sm md:text-base font-semibold text-[#311664]" style={{backgroundColor:'transparent'}}>{testimonial.name}</h3>
                      <p className="text-xs sm:text-sm text-gray-600" style={{backgroundColor:'transparent'}}>{testimonial.location}</p>
                      <div className="flex items-center mt-1" style={{backgroundColor:'transparent'}}>
                        {[...Array(testimonial.rating)].map((_, i) => (
                          <Star key={i} className="w-4 h-4 text-yellow-400 fill-current" style={{backgroundColor:'transparent'}} />
                        ))}
                      </div>
                    </div>
                  </div>
                  <p className="text-xs sm:text-sm text-gray-700 mb-2 italic" style={{backgroundColor:'transparent'}}>"{testimonial.quote}"</p>
                  <div className="flex items-center justify-between text-xs sm:text-sm text-gray-600" style={{backgroundColor:'transparent'}}>
                    <div className="flex items-center" style={{backgroundColor:'transparent'}}>
                      <Home className="w-4 h-4 mr-1 text-[#3a868b]" style={{backgroundColor:'transparent'}} />
                      <span style={{backgroundColor:'transparent'}}>{testimonial.loanType}</span>
                    </div>
                    <div className="flex items-center" style={{backgroundColor:'transparent'}}>
                      <Building className="w-4 h-4 mr-1 text-[#3a868b]" style={{backgroundColor:'transparent'}} />
                      <span style={{backgroundColor:'transparent'}}>{testimonial.bank}</span>
                    </div>
                  </div>
                  <div className="mt-2 flex justify-between items-center" style={{backgroundColor:'transparent'}}>
                    <span className="text-xs bg-[#3a868b] text-white px-2 py-1 rounded-full">Verified Minemi User</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            onClick={handlePrev}
            className="absolute left-0 top-1/2 transform -translate-y-1/2  rounded-full p-1 sm:p-2 shadow-lg z-10 -ml-2 sm:-ml-4 focus:outline-none"
            aria-label="Previous testimonial"
            style={{backgroundColor:'transparent'}}
          >
            <ChevronLeft className="w-4 h-4 text-[#311664]"  style={{backgroundColor:'transparent'}}/>
          </div>
          <div
            onClick={handleNext}
            className="absolute right-0 top-1/2 transform  rounded-full p-1 sm:p-2 shadow-lg z-10 -mr-2 sm:-mr-4 focus:outline-none"
            aria-label="Next testimonial"
            style={{backgroundColor:'transparent'}}
          >
            <ChevronRight className="w-4 h-4 text-[#311664]" style={{backgroundColor:'transparent'}} />
          </div>
        </div>

        <div className="mt-4 flex justify-center" style={{backgroundColor:'transparent'}}> 
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => scrollToTestimonial(index)}
              className={`h-2 w-2 rounded-full mx-1 focus:outline-none ${
                index === currentIndex ? "bg-[#311664]" : "bg-gray-300"
              }`}
              aria-label={`Go to testimonial ${index + 1}`}
            />
          ))}
        </div>

        <div className="mt-12 text-center" style={{backgroundColor:'transparent'}}>
          <button className="bg-[#3a868b] hover:bg-[#59266d] text-white text-base sm:text-lg md:text-xl py-2 sm:py-3 md:py-4 px-6 sm:px-8 md:px-10 rounded-lg transition-colors duration-300"
         onClick={scrollToFilter} >
            Find My Best Home Loan
          </button>
        </div>
      </div>
    </section>
   
    </>
  )
}


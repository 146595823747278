import { CheckCircle } from "lucide-react";
import { useEffect, useState, useMemo } from "react";

export function LoanStatusMethods({ name }) {
  const [data, setData] = useState([]);

  const idbiData = useMemo(
    () => ({
      h2: "How to Check Your IDBI Bank Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your IDBI Bank home loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: " View your loan application progress",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "IDBI GO Mobile App & Net Banking",
          para2:
            "Log in to the IDBI GO Mobile app or IDBI Bank Net Banking to check your home loan application status.",
          step1: "Log in to IDBI GO Mobile app or Net Banking",
          step2: " Navigate to the 'Home Loans' or 'Applications' section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Home Loan Helpline",
          para2:
            "Contact IDBI Bank's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call IDBI Bank at 1800-209-4324 (toll-free) or +91 22 6748 9899",
          step2: "Provide your application number",
          step3: "Get your loan status information",
        },
      ],
    }),
    []
  );
  const lichousingData = useMemo(
    () => ({
      h2: "How to Check Your LIC Housing Finance Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your LIC Housing Finance home loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: " View your loan application progress",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "LIC HFL HOME App & Customer Portal",
          para2:
            "Log in to the LIC HFL HOME app or LIC Housing Finance Customer Portal to check your home loan application status.",
          step1: "Log in to LIC HFL HOME app or Customer Portal",
          step2: " Navigate to the 'Home Loans' or 'Applications' section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Home Loan Helpline",
          para2:
            "Contact LIC Housing Finance's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call LIC Housing Finance at 1800-258-5678 (toll-free) or +91 22 6616 7000",
          step2: "Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
      ],
    }),
    []
  );
  const icicihousingData = useMemo(
    () => ({
      h2: "How to Check Your ICICI Home Finance Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your ICICI Home Finance home loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: " View your loan application progress",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "ICICI iMobile App & Customer Portal",
          para2:
            "Log in to the ICICI iMobile app or ICICI Home Finance Customer Portal to check your home loan application status.",
          step1: "Log in to ICICI iMobile app or Customer Portal",
          step2: " Navigate to the 'Home Loans' or 'Applications' section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Home Loan Helpline",
          para2:
            "Contact ICICI Home Finance's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call ICICI Home Finance at 1800-267-4455 (toll-free) or +91 22 3366 7777",
          step2: "Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
      ],
    }),
    []
  );
  const pnbhousingData = useMemo(
    () => ({
      h2: "How to Check Your PNB Housing Finance Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your PNB Housing Finance home loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: " View your loan application progress",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "PNB HFL Customer Portal & Mobile App",
          para2:
            "Log in to the PNB Housing Finance Customer Portal or Mobile App to check your home loan application status.",
          step1: "Log in to PNB HFL Customer Portal or Mobile App",
          step2: " Navigate to the 'Home Loans' or 'Applications' section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Home Loan Helpline",
          para2:
            "Contact PNB Housing Finance's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call PNB Housing Finance at 1800-120-8800 (toll-free) or +91 11 2373 8888",
          step2: "Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
      ],
    }),
    []
  );
  const sbihousingData = useMemo(
    () => ({
      h2: "How to Check Your SBI Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your SBI Home Loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: " View your loan application progress",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "SBI YONO App & Online Banking",
          para2:
            "Log in to the SBI YONO app or SBI Online Banking portal to check your home loan application status.",
          step1: "Log in to SBI YONO app or Online Banking",
          step2: " Navigate to the 'Home Loans' or 'Applications' section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Home Loan Helpline",
          para2:
            "Contact SBI's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call SBI Home Loans at 1800-11-2211 (toll-free) or 1800-425-3800",
          step2: "Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
      ],
    }),
    []
  );
  const adityahousingData = useMemo(
    () => ({
      h2: "How to Check Your Aditya Birla Finance Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your Aditya Birla Finance Home Loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: " View your loan application progress",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "ABFL MyLoan App & Customer Portal",
          para2:
            "Log in to the ABFL MyLoan app or Aditya Birla Finance Customer Portal to check your home loan application status.",
          step1: "Log in to ABFL MyLoan app or Customer Portal",
          step2: " Navigate to the 'Home Loans' or 'Applications' section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Home Loan Helpline",
          para2:
            "Contact Aditya Birla Finance's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call Aditya Birla Finance at 1800-270-7000 (toll-free) or +91 22 6715 6666",
          step2: "Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
      ],
    }),
    []
  );
  const lthousingData = useMemo(
    () => ({
      h2: "How to Check Your L&T Finance Home Loan Status",
      para: "Multiple convenient ways to track your home loan application progress",
      step1: [
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
              <path d="M7 11V7a5 5 0 0 1 10 0v4" />
            </svg>
          ),
          h3: "MinEMI Loan Tracker",
          para2:
            "Use our secure online tracker above to instantly check your L&T Finance Home Loan status.",
          step1: "Enter your application details",
          step2: "Provide verification information",
          step3: " View your loan application progress",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <rect width="20" height="14" x="2" y="5" rx="2" />
              <line x1="2" x2="22" y1="10" y2="10" />
            </svg>
          ),
          h3: "L&T Finance Customer Portal",
          para2:
            "Log in to the L&T Finance Customer Portal to check your home loan application status.",
          step1: "Log in to L&T Finance Customer Portal",
          step2: " Navigate to the 'Home Loans' or 'Applications' section",
          step3: " Select 'application Status' to view your details",
        },
        {
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
          ),

          h3: " Home Loan Helpline",
          para2:
            "Contact L&T Finance's dedicated home loan helpline to inquire about your application status.",
          step1:
            "Call L&T Finance at 1800-5719-90 (toll-free) or +91 22 6621 7500",
          step2: "Provide your application number and verification details",
          step3: "Get your loan status information from the representative",
        },
      ],
    }),
    []
  );

  useEffect(() => {
    if (name === "checkidbihousingbankstatus") {
      setData(idbiData);
    } else if (name === "checklichousingbankstatus") {
      setData(lichousingData);
    } else if (name === "checkicicihousingbankstatus") {
      setData(icicihousingData);
    } else if (name === "checkpnbhousingbankstatus") {
      setData(pnbhousingData);
    } else if (name === "checksbihousingbankstatus") {
      setData(sbihousingData);
    } else if (name === "checkadityahousingbankstatus") {
      setData(adityahousingData);
    } else if (name === "checklthousingbankstatus") {
      setData(lthousingData);
    } else {
      setData([]);
    }
  }, [
    name,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    sbihousingData,
    adityahousingData,
    lthousingData,
  ]);
  return (
    <section className="py-2  border-b border-gray-200 bg-[#ebe8fb]">
      <div className="container sd:px-2  md:px-6 bg-transparent ">
        <div className="sd:w-full md:w-4/5 mx-auto bg-transparent">
          <div className="text-center mb-6 bg-transparent">
            <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple bg-transparent">
              {data.h2}
            </h2>
            <p className="text-dark-grey mt-2 text-sm bg-transparent text-center">
              {data.para}
            </p>
          </div>

          <div className="grid gap-3 bg-transparent">
            {data?.step1?.map((d, index) => (
              <div
                key={index}
                className="bg-white p-3 rounded-lg shadow-sm border border-gray-200"
              >
                <div className="flex items-start gap-3 bg-transparent">
                  <div className="h-10 w-10 rounded-full  flex items-center justify-center flex-shrink-0 bg-[#ebe8fb]">
                    {d.icon}
                  </div>
                  <div className="bg-transparent">
                    <h3 className="text-base font-bold text-primary-purple mb-2">
                      {d.h3}
                    </h3>
                    <p className="text-dark-grey mb-2 text-sm bg-transparent">
                      {d.para2}
                    </p>
                    <div className="space-y-2 bg-transparent p-0">
                      <div className="flex items-start  bg-transparent">
                        <CheckCircle
                          className="h-5 w-5 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        />

                        <p className="text-sm text-dark-grey bg-transparent p-0 m-0">
                          {d.step1}
                        </p>
                      </div>
                      <div className="flex items-start gap-0.5 bg-transparent">
                        <CheckCircle
                          className="h-5 w-5 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        />

                        <p className="text-sm text-dark-grey bg-transparent p-0 m-0">
                          {d.step2}
                        </p>
                      </div>
                      <div className="flex items-start gap-0.5 bg-transparent">
                        <CheckCircle
                          className="h-5 w-5 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        />
                        <p className="text-sm text-dark-grey bg-transparent p-0 m-0">
                          {d.step3}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export const faqData = [
  {
    id: 1,
    question: "What is a Top-Up Loan?",
    answer:
      "A top-up loan is an additional loan that you can avail on your existing home loan or personal loan. It allows you to borrow extra funds at a lower interest rate compared to personal loans.",
  },
  {
    id: 2,
    question: " Will a top-up loan always reduce my EMI?",
    answer:
      "Not necessarily. A top-up loan can lower your overall EMI if the interest rate is lower and the tenure is extended. However, if you borrow a significant additional amount, your EMI may increase.",
  },
  {
    id: 3,
    question: "Can I get a top-up loan from the same lender?",
    answer:
      "Yes, most banks and financial institutions offer top-up loans to their existing borrowers. However, eligibility depends on factors like your repayment history, loan balance, and creditworthiness.",
    // answerList: [
    //   {
    //     heading: " Loan Amount: ",
    //     info: "Higher amounts lead to higher EMIs.",
    //   },
    //   {
    //     heading: " Interest Rate:",
    //     info: "Lower rates reduce your EMI and total repayment.",
    //   },
    //   {
    //     heading: "Tenure:  ",
    //     info: " Longer tenures lower EMIs but increase total interest.",
    //   },
    // ],
  },
  {
    id: 4,
    question: "How does a top-up loan affect my credit score?",
    answer:
      "A top-up loan can positively impact your credit score if you repay it on time. However, taking on additional debt increases your total loan obligation, which might affect your credit utilization ratio.",
  },
  {
    id: 5,
    question: "What happens if I miss a payment on my top-up loan?",
    answer:
      "Missing payments can lead to late fees, increased interest costs, and a negative impact on your credit score. Repeated defaults may also result in legal action by the lender.",
  },
  {
    id: 6,
    question: "Are there any fees for a top-up loan?",
    answer:
      "Yes, lenders may charge processing fees, administrative fees, or other charges. These costs vary between banks, so it's best to compare before applying.",
  },
  {
    id: 7,
    question: "Can I take a top-up loan on multiple existing loans?",
    answer:
      "Yes, if you have multiple loans, you may be eligible for top-up loans on each, depending on your repayment history and the lender’s policies.",
  },
  {
    id: 8,
    question: "Will a top-up loan affect my credit score?",
    answer:
      "Yes, it can. If you manage your loan well and make timely payments, it can boost your score. However, missing payments or increasing your debt burden may negatively impact your credit rating.",
  },
];

import React from "react";
import Serve from "./Serve/Serve.jsx";
import Types from "./Types/Types.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BalanceTansferEligibility from "./BalanceTansferEligibility/BalanceTansferEligibility.jsx";

const BalanceTransferEligibilityForm = () => {
  return (
    <>
      {/* <Navbar /> */}

      <BalanceTansferEligibility />
      <Serve />
      <Types />
      {/* <Footer /> */}
      <ToastContainer />
    </>
  );
};

export default BalanceTransferEligibilityForm;

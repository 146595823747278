import React, { useState, useEffect, useContext } from "react";
import illustration from "../../../Assets/Images/Illustration22.webp";
import { LuDot } from "react-icons/lu";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import "./GoldLoanEligibilityCriteria.css";
import { Link } from "react-router-dom";
import toungeOut from "../../../Assets/Images/tongue-out.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MetadataContext from "../../MetadataContext";
import RelatedLinks from "../../../Components/RelatedLinks.js/Relatedlinks";

// import Breadcrumbs from "./Breadcrumbs";

const GoldLoanEligibilityCriteria = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Gold Loan Check Eligibility",
      pageDescription:
        "Gold loans offer quick access to funds by using your gold's value. Understanding your eligibility can ease the loan approval process and maximize your gold's benefits.",
      pageKeywords:
        "gold loan eligibility, check gold loan eligibility, gold loan approval, gold loan criteria, quick gold loans, gold loan benefits, gold value loan, gold-backed loans, loan against gold, gold loan process",
      canonicalUrl:
        "https://minemi.ai/loanpage/gold-loan/gold-loan-eligibility-criteria",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          className="margin-less"
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                to={"/loanpage/gold-loan"}
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Gold Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Gold Loan Check Eligibility
              </Link>
            </p>
          </div>
          <h1
            className="loanstatus-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Gold Loan Check Eligibility: Unlock the Value of Your Gold
          </h1>
          <p
            className="loanstatus-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: 1,
            }}
          >
            Gold loans offer a quick and hassle-free way to access funds by
            leveraging the value of your gold. Understanding your eligibility
            ensures a smoother loan approval process and helps you maximize the
            benefits of your gold assets.
          </p>
        </div>
        <div
          className="tracking-status"
          style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}
        >
          {/* eligibility content */}
          <h2
            style={{
              marginTop: 30,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Introduction
          </h2>

          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Understanding your eligibility is the first step in getting a car
            loan. By checking your eligibility, you can determine the loan
            amount you qualify for, the terms, and the interest rates that will
            be offered. Car loan eligibility criteria differ from lender to
            lender and can be based on various factors, including your income,
            age, employment, and credit score.
          </div>

          <div>
            <h3 style={{ fontSize: "24px", fontWeight: 600, marginTop: 25 }}>
              How to Check Gold Loan Eligibility
            </h3>
            <div
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              Checking your gold loan eligibility involves assessing key
              factors, such as the purity and weight of the gold, the
              applicant’s financial profile, and lender-specific requirements.
              Here’s what you need to know:
            </div>
            <div
              className="mt-2"
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              <ol style={{ listStyle: "number" }}>
                <li className="mt-1">
                  <strong>Loan Amount Eligibility: </strong>Determined by the
                  weight and purity of your gold.
                </li>
                <li className="mt-1">
                  <strong>Income Assessment: </strong>Most lenders do not
                  require proof of income but assess repayment capacity.
                </li>
                <li className="mt-1">
                  <strong>Credit Score: </strong>A good credit score enhances
                  loan approval chances.
                </li>
                <li className="mt-1">
                  <strong>Eligibility by Age: </strong>Typically, applicants
                  must be aged between 18 and 70 years.
                </li>
              </ol>
            </div>
          </div>

          <div id="table-data" className="mt-5">
            <h3 style={{ fontSize: "24px", fontWeight: 600, marginTop: 25 }}>
              Eligibility Criteria for Gold Loans
            </h3>

            <div
              className="mt-2"
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              <ul style={{ listStyle: "disc" }}>
                <li className="mt-1">
                  <strong>Loan Amount Eligibility: </strong>Determined by the
                  weight and purity of your gold.
                </li>
                <li className="mt-1">
                  <strong>Income Assessment: </strong>Most lenders do not
                  require proof of income but assess repayment capacity.
                </li>
                <li className="mt-1">
                  <strong>Credit Score: </strong>A good credit score enhances
                  loan approval chances.
                </li>
                <li className="mt-1">
                  <strong>Eligibility by Age: </strong>Typically, applicants
                  must be aged between 18 and 70 years.
                </li>
              </ul>
            </div>

            <div
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                      }}
                    >
                      Parameter
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Details
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        borderTopRightRadius: 18,
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Impact on Loan Eligibility
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Age
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Minimum: 18 years; Maximum: 70 years
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Older applicants may need a co-applicant.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Gold Purity
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Minimum 18 karats
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Higher purity (22K and above) fetches a better loan
                      amount.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Gold Type
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Ornaments, coins (up to 50 grams per RBI rules); bars not
                      accepted
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Limited types of gold are eligible for loans.
                    </td>
                  </tr>

                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Loan-to-Value Ratio (LTV)
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Up to 75% of the gold’s market value, as per RBI
                      guidelines
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Lower LTV may be offered based on risk assessment.
                    </td>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderBottomLeftRadius: 24,
                      borderBottomRightRadius: 24,
                    }}
                  >
                    <td
                      className="table-padding"
                      style={{
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                        border: "1px solid #ddd",
                        borderBottomLeftRadius: 18,
                      }}
                    >
                      Credit Score
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Not mandatory but 700+ is preferred
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                        border: "1px solid #ddd",
                        borderBottomRightRadius: 18,
                      }}
                    >
                      Higher credit scores ensure better terms.
                    </td>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderBottomLeftRadius: 24,
                      borderBottomRightRadius: 24,
                    }}
                  >
                    <td
                      className="table-padding"
                      style={{
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                        border: "1px solid #ddd",
                        borderBottomLeftRadius: 18,
                      }}
                    >
                      Income Proof
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Not mandatory for most lenders
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                        border: "1px solid #ddd",
                        borderBottomRightRadius: 18,
                      }}
                    >
                      Proof of stable income improves loan amount eligibility.
                    </td>
                  </tr>
                  {/* <tr style={{ backgroundColor: '#f2f2f2',borderBottomLeftRadius:24 }}>
          <td className="table-padding" style={{ padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:700 ,borderBottomLeftRadius:24 }}>Tenure



</td>
          <td className="table-padding"style={{ border: '1px solid #ddd', padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400 }}>	
          1 to 5 years

</td>
          <td className="table-padding" style={{  padding: '16px',backgroundColor:'white',fontFamily:'Archivo',fontSize:14,fontWeight:400,borderBottomRightRadius:24  }}>	
	
          Flexible tenure options available.</td>
        </tr> */}
                </tbody>
              </table>
            </div>
          </div>

          <div
            className="make-it-center increase-margin "
            style={{
              marginTop: "2%",
              marginBottom: "2%",
              padding: 24,
              background: "linear-gradient(91.7deg, #EADCFF 0.4%, #FFFFFF 99%)",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 20,
              borderRadius: 24,
            }}
          >
            <div
              style={{
                marginLeft: "4%",
                backgroundColor: "transparent",
                display: "flex",
                flexDirection: "column",
                gap: 24,
                borderRadius: 24,
                alignSelf: "center",
              }}
            >
              <p
                className="eligiblity-criteria"
                style={{
                  fontFamily: "Archivo",
                  color: "#59266D",
                  fontSize: 40,
                  backgroundColor: "transparent",
                }}
              >
                <i style={{ backgroundColor: "transparent", lineHeight: 1.2 }}>
                  Check Your Loan Eligibility Without{" "}
                  <br className="brake-remove" /> Impacting Your Credit Score
                </i>
              </p>
              <a
                href="https://minemi.ai/personal-loan/check-eligibility/"
                className="eligiblity-buttons"
                style={{
                  backgroundColor: "#59266D",
                  color: "#FFFFFF",
                  width: "39%",
                  borderRadius: 8,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 18,
                  paddingTop: 8,
                  paddingBottom: 8,
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                Check Eligibility
              </a>
            </div>
            <div
              style={{
                width: 200,
                height: 195,
                backgroundColor: "transparent",
                marginRight: "4%",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
              }}
            >
              <img
                src={illustration}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                loading="lazy"
                alt="eigibility"
              />
            </div>
          </div>

          <h3 style={{ fontSize: "24px", fontWeight: 600, marginTop: 25 }}>
            Eligibility Criteria by Lender
          </h3>
          <h4 style={{ fontSize: "18px", fontWeight: 600, marginTop: 20 }}>
            HDFC Bank
          </h4>

          <div
            className="mt-2"
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            <ul style={{ listStyle: "disc" }}>
              <li className="mt-1">
                <strong>Age Requirement: </strong>21-65 years
              </li>
              <li className="mt-1">
                <strong>Gold Purity: </strong>22K or higher ornaments accepted.
              </li>
              <li className="mt-1">
                <strong>Documents Required: </strong>ID proof, address proof,
                and basic gold appraisal.
              </li>
            </ul>
          </div>
          <h4 style={{ fontSize: "18px", fontWeight: 600, marginTop: 20 }}>
            AXIS Bank
          </h4>
          <div
            className="mt-2"
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            <ul style={{ listStyle: "disc" }}>
              <li className="mt-1">
                <strong>Age Requirement: </strong>18-70 years
              </li>
              <li className="mt-1">
                <strong>Gold Accepted: </strong>Jewelry only (coins and bars not
                accepted).
              </li>
              <li className="mt-1">
                <strong>Income Proof: </strong>Generally not required.
              </li>
            </ul>
          </div>
          <h4 style={{ fontSize: "18px", fontWeight: 600, marginTop: 20 }}>
            SBI Bank
          </h4>

          <div
            className="mt-2"
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            <ul style={{ listStyle: "disc" }}>
              <li className="mt-1">
                <strong>Age Requirement: </strong>18-65 years
              </li>
              <li className="mt-1">
                <strong>Maximum Loan Amount: </strong>Up to ₹50 lakh.
              </li>
              <li className="mt-1">
                <strong>Repayment Tenure: </strong>Flexible options of up to 36
                months.
              </li>
            </ul>
          </div>
          <h4 style={{ fontSize: "18px", fontWeight: 600, marginTop: 20 }}>
            ICICI Bank
          </h4>

          <div
            className="mt-2"
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            <ul style={{ listStyle: "disc" }}>
              <li className="mt-1">
                <strong>Age Requirement: </strong>21-65 years
              </li>
              <li className="mt-1">
                <strong>LTV Ratio: </strong>Up to 75% of the gold’s value.
              </li>
              <li className="mt-1">
                <strong>Additional Perks: </strong>Pre-approved offers for
                existing customers.
              </li>
            </ul>
          </div>

          <h3 style={{ fontSize: "24px", fontWeight: 600, marginTop: 25 }}>
            Understanding Eligibility for Gold Loans{" "}
          </h3>
          <h4 style={{ fontSize: "18px", fontWeight: 600, marginTop: 20 }}>
            1. Gold Purity and Value:
          </h4>
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 16,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Lenders assess the purity of your gold using a karat meter and
            calculate the loan amount based on the prevailing market rate.
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 16,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Example:
          </p>

          <div
            className="mt-2"
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            <ul style={{ listStyle: "disc" }}>
              <li className="mt-1">
                <strong>Gold weight: </strong>100 grams
              </li>
              <li className="mt-1">
                <strong>Purity: </strong>22 karats
              </li>
              <li className="mt-1">
                <strong>Market rate (per gram): </strong>₹5,500
              </li>
              <li className="mt-1">
                <strong>Loan amount: </strong>₹5,500 × 100 × 75% = ₹4,12,500
              </li>
            </ul>
          </div>
          <h4 style={{ fontSize: "18px", fontWeight: 600, marginTop: 20 }}>
            2. Income Stability:
          </h4>
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 16,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            While most gold loans don’t require income proof, demonstrating
            stable income may allow you to negotiate better interest rates or
            repayment terms.
          </p>
          <h4 style={{ fontSize: "18px", fontWeight: 600, marginTop: 20 }}>
            3. Repayment Capacity:
          </h4>
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 16,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Some lenders may check your repayment history, particularly if
            you’re applying for a high loan amount.
          </p>
          <h4 style={{ fontSize: "18px", fontWeight: 600, marginTop: 20 }}>
            4. Credit History:
          </h4>
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 16,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Though not mandatory, a higher credit score improves your bargaining
            power for better interest rates and lower processing fees.
          </p>

          {/* minemi tip section */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={toungeOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Unlock your gold’s full potential with MinEmi’s Gold Loan
                    Eligibility Checker. Assess your eligibility without
                    impacting your credit score and get tailored loan options
                    instantly.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <h3 style={{ fontSize: "24px", fontWeight: 600, marginTop: 25 }}>
            How to Improve Your Gold Loan Eligibility
          </h3>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Use High-Purity Gold: &nbsp;
                </span>
                Ensure your gold ornaments or coins are at least 22K to maximize
                the loan amount.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Provide Collateral in Good Condition: &nbsp;
                </span>
                Avoid damaged or broken jewelry to ensure smooth appraisal.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Maintain a Good Credit Score:&nbsp;
                </span>
                While not mandatory, a score above 700 can help secure better
                interest rates.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Reduce Existing Debt: &nbsp;
                </span>
                Pay off any high-interest obligations to improve your
                eligibility.
              </div>
            </div>
          </div>

          <h3 style={{ fontSize: "24px", fontWeight: 600, marginTop: 25 }}>
            Frequently Asked Questions (FAQs){" "}
          </h3>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              backgroundColor: "#F6F6F6",
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Can I apply for a gold loan without a credit score?
              </AccordionSummary>
              <AccordionDetails>
                Yes, most lenders prioritize gold value over credit history.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                What is the minimum weight of gold required for a loan?
              </AccordionSummary>
              <AccordionDetails>
                Typically, lenders require a minimum of 10 grams of gold.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Are there any restrictions on the type of gold?
              </AccordionSummary>
              <AccordionDetails>
                Yes, only gold jewelry and specific coins (up to 50 grams) are
                eligible.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Can I get a gold loan if I am self-employed?
              </AccordionSummary>
              <AccordionDetails>
                Yes, gold loans are available to salaried, self-employed, and
                unemployed individuals alike.
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <RelatedLinks
          title="Explore More Loan Eligiblity Options"
          description="Discover other financial products and services that might interest you"
          params="/loanpage/gold-loan/gold-loan-eligibility-criteria"
        />
      </div>
    </>
  );
};

export default GoldLoanEligibilityCriteria;


import { motion } from "framer-motion"
import { Check, X } from "lucide-react"

export default function ComparisonSection() {
  const features = [
    "No Impact on Credit Score",
    "Accurate AI-Powered Predictions",
    "Real-Time Rate Checking",
    "Personalized Loan Insights",
    "Data Privacy & Security",
  ]

  return (
    <section className="py-10 md:py-16 bg-[#ebe8fc]/20">
      <div className="container mx-auto px-6">
        <div className="text-center mb-8">
          <h2 className="text-xl md:text-2xl font-bold text-[#311664] mb-2">Why Minemi is the Best Platform?</h2>
          <div className="w-16 h-0.5 bg-[#3a868b] mx-auto"></div>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 5 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          viewport={{ once: true, margin: "-50px" }}
          className="max-w-lg mx-auto rounded-lg overflow-hidden shadow-lg"
        >
          <table className="w-full border-collapse">
            <thead className="bg-[#311664]">
              <tr>
                <th className="text-white font-medium text-sm py-3 px-4 text-left">Feature</th>
                <th className="text-white font-medium text-sm py-3 px-4 text-center">Minemi</th>
                <th className="text-white font-medium text-sm py-3 px-4 text-center">Others</th>
              </tr>
            </thead>
            <tbody>
              {features.map((feature, index) => (
                <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-[#ebe8fc]/30"}>
                  <td className="font-medium text-sm py-3 px-4 border-b border-[#ebe8fc]">{feature}</td>
                  <td className="text-center py-3 px-4 border-b border-[#ebe8fc]">
                    <div className="flex justify-center">
                      <Check className="h-5 w-5 text-green-600" />
                    </div>
                  </td>
                  <td className="text-center py-3 px-4 border-b border-[#ebe8fc]">
                    <div className="flex justify-center">
                      <X className="h-5 w-5 text-red-500" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </motion.div>
      </div>
    </section>
  )
}


import { useEffect, useState, useMemo } from "react";

export function StatusExplanation({ name }) {
  const [data, setData] = useState({
    title: "",
    description: "",
    statuses: [],
    footer: { text: "", button: { text: "", link: "" } },
  });

  const kotakData = useMemo(
    () => ({
      title: "Understanding Your Kotak Bank Loan Application Status",
      description:
        "Learn what each status means and the recommended actions to take",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your Kotak Bank personal loan has been approved. The bank has completed all verifications and approved your loan request.",
          nextSteps: [
            "Review the loan agreement sent to your email",
            "Complete the e-signing process through the secure portal",
            "Set up auto-debit for EMI payments if not already done",
            "Expect disbursement within 24-48 hours after document completion",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Kotak Bank has pre-approved your loan based on your credit profile and relationship with the bank. You're eligible for expedited processing with minimal documentation.",
          nextSteps: [
            "Log in to Kotak Net Banking to view your pre-approved offer",
            "Confirm the pre-approved loan amount and terms",
            "Complete the digital verification process (usually just OTP)",
            "Accept the offer to initiate quick disbursement",
          ],
        },
        {
          name: "Application Received",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Kotak Bank has received your personal loan application and has initiated the preliminary review process. This is the first stage of evaluation.",
          nextSteps: [
            "No immediate action required from your side",
            "Keep your phone accessible for verification calls",
            "Check your email for any initial document requests",
            "Expect movement to the next stage within 24-48 hours",
          ],
        },
        {
          name: "Document Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Kotak Bank is currently verifying the documents you've submitted. This includes income proof, address verification, and identity documents.",
          nextSteps: [
            "Check if all documents are properly uploaded in the portal",
            "Ensure documents are clearly legible and not expired",
            "Be prepared for verification calls from Kotak Bank",
            "Use MinEMI's Document Verification Checklist for guidance",
          ],
        },
        {
          name: "Disbursement Initiated",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan has been approved and Kotak Bank has initiated the disbursement process. The funds will be transferred to your designated account shortly.",
          nextSteps: [
            "Verify your bank account details in the Kotak Bank portal",
            "Watch for the disbursement SMS alert from Kotak Bank",
            "Check your account for the credited amount",
            "Review the welcome kit with repayment schedule details",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has not been approved by Kotak Bank at this time. This decision is based on various eligibility factors.",
          nextSteps: [
            "Request detailed feedback through Kotak Bank customer care",
            "Use MinEMI's Rejection Analysis Tool to identify issues",
            "Work on improving the specific factors affecting approval",
            "Consider applying after 3-6 months with improved credentials",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's AI-powered tools guide you through your Kotak Bank loan journey.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      title: "Understanding Your Bank of Baroda Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600 bg-transparent"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your loan application has been approved by Bank of Baroda. The loan amount will be disbursed to your account shortly.",
          nextSteps: [
            " Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            " Complete any pending documentation if required",
            "Wait for the amount to be credited to your account",
          ],
        },
        {
          name: "Approved with Lesser Amount",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600 bg-transparent"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your loan has been approved, but for a lower amount than requested based on your eligibility assessment.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Accept or decline the offered amount",
            "If accepted, complete the remaining formalities",
            "Use MinEMI's Eligibility Checker to understand why",
          ],
        },
        {
          name: "In Progress",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600 bg-transparent"
            >
              <circle cx="12" cy="12" r="10" />
              <path d="M12 8v4" />
              <path d="M12 16h.01" />
            </svg>
          ),
          description:
            "Your loan application is currently being processed by Bank of Baroda. The verification and assessment are ongoing.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 5-7 working days",
          ],
        },
        {
          name: "On Hold",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600 bg-transparent"
            >
              <path d="M8.5 14.5A2.5 2.5 0 0 0 11 12c0-1.38-.5-2-1-3-1.072-2.143-.224-4.054 2-6 .5 2.5 2 4.9 4 6.5 2 1.6 3 3.5 3 5.5a7 7 0 1 1-14 0c0-1.153.433-2.294 1-3a2.5 2.5 0 0 0 2.5 2.5z" />
            </svg>
          ),
          description:
            "Your loan application has been temporarily put on hold. This could be due to incomplete documentation or verification issues.",
          nextSteps: [
            " Contact Bank of Baroda customer support for details",
            "Submit any pending or additional documents requested",
            " Address any verification issues highlighted",
            "Use MinEMI's Loan Approval Predictor for guidance",
          ],
        },
        {
          name: "Recredit",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600 bg-transparent"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan application is being reassessed or reprocessed, possibly due to changes in your application or bank policies.",
          nextSteps: [
            " Wait for the bank to complete the reassessment",
            "Check if any additional information is required",
            " Contact customer support for estimated timeline",
            "Track your application status regularly",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600 bg-transparent"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has been rejected by Bank of Baroda. This could be due to various eligibility factors.",
          nextSteps: [
            "Request for the specific reason for rejection",
            "Use MinEMI's Eligibility Checker to identify issues",
            "Work on improving your credit score if that's a factor",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );

  const idfcData = useMemo(
    () => ({
      title: "Understanding Your Aditya Birla Finance Loan Application Status",
      description:
        "Learn what each status means and the recommended actions to take",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your Aditya Birla Finance personal loan has been approved. The company has completed all verifications and approved your loan request.",
          nextSteps: [
            "Review the loan agreement sent to your email",
            "Complete the e-signing process through the secure portal",
            "Set up auto-debit for EMI payments if not already done",
            "Expect disbursement within 24-48 hours after document completion",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Aditya Birla Finance has pre-approved your loan based on your credit profile. You're eligible for expedited processing with minimal documentation.",
          nextSteps: [
            "Log in to Kotak Net Banking to view your pre-approved offer",
            "Confirm the pre-approved loan amount and terms",
            "Complete the digital verification process (usually just OTP)",
            "Accept the offer to initiate quick disbursement",
          ],
        },
        {
          name: "Application Received",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Aditya Birla Finance has received your personal loan application and has initiated the preliminary review process. This is the first stage of evaluation.",
          nextSteps: [
            "No immediate action required from your side",
            "Keep your phone accessible for verification calls",
            "Check your email for any initial document requests",
            "Expect movement to the next stage within 24-48 hours",
          ],
        },
        {
          name: "Document Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Aditya Birla Finance is currently verifying the documents you've submitted. This includes income proof, address verification, and identity documents.",
          nextSteps: [
            "Check if all documents are properly uploaded in the portal",
            "Ensure documents are clearly legible and not expired",
            "Be prepared for verification calls from Kotak Bank",
            "Use MinEMI's Document Verification Checklist for guidance",
          ],
        },
        {
          name: "Disbursement Initiated",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan has been approved and Aditya Birla Finance has initiated the disbursement process. The funds will be transferred to your designated account shortly.",
          nextSteps: [
            "Verify your bank account details in the Kotak Bank portal",
            "Watch for the disbursement SMS alert from Kotak Bank",
            "Check your account for the credited amount",
            "Review the welcome kit with repayment schedule details",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has not been approved by Aditya Birla Finance at this time. This decision is based on various eligibility factors.",
          nextSteps: [
            "Request detailed feedback through Kotak Bank customer care",
            "Use MinEMI's Rejection Analysis Tool to identify issues",
            "Work on improving the specific factors affecting approval",
            "Consider applying after 3-6 months with improved credentials",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's AI-powered tools guide you through your Aditya Birla Finance loan journey.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      title: "Understanding Your HDFC Bank Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your loan application has been approved by HDFC Bank. The loan amount will be disbursed to your account shortly.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Wait for the amount to be credited to your account",
          ],
        },
        {
          name: "Approved with Lesser Amount",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been approved, but for a lower amount than requested based on your eligibility assessment.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "C Accept or decline the offered amount",
            " If accepted, complete the remaining formalities",
            "Use MinEMI's Eligibility Checker to understand why",
          ],
        },
        {
          name: "In Progress",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your loan application is currently being processed by HDFC Bank. The verification and assessment are ongoing.",
          nextSteps: [
            "Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "On Hold",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your loan application has been temporarily put on hold. This could be due to incomplete documentation or verification issues.",
          nextSteps: [
            "Contact HDFC Bank customer support for details",
            " Submit any pending or additional documents requested",
            " Address any verification issues highlighted",
            " Use MinEMI's Loan Approval Predictor for guidance",
          ],
        },
        {
          name: "Under Review",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan application is being reassessed or reprocessed, possibly due to changes in your application or bank policies.",
          nextSteps: [
            "Wait for the bank to complete the reassessment",
            "Check if any additional information is required",
            " Contact customer support for estimated timeline",
            "Track your application status regularly",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has been rejected by HDFC Bank. This could be due to various eligibility factors.",
          nextSteps: [
            "Request for the specific reason for rejection",
            "Use MinEMI's Eligibility Checker to identify issues",
            " Work on improving your credit score if that's a factor",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      title: "Understanding Your SBI Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your loan application has been approved by State Bank of India. The loan amount will be disbursed to your account shortly.",
          nextSteps: [
            "Check your registered email for the approval letter",
            "Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Wait for the amount to be credited to your account",
          ],
        },
        {
          name: "Sanctioned with Lower Amount",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been approved, but for a lower amount than requested based on your eligibility assessment.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Accept or decline the offered amount",
            "If accepted, complete the remaining formalities",
            "Use MinEMI's Eligibility Checker to understand why",
          ],
        },
        {
          name: "In Process",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your loan application is currently being processed by State Bank of India. The verification and assessment are ongoing.",
          nextSteps: [
            "Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 7-10 working days",
          ],
        },
        {
          name: "Pending",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your loan application has been temporarily put on hold. This could be due to incomplete documentation or verification issues.",
          nextSteps: [
            "Contact SBI customer support for details",
            "Submit any pending or additional documents requested",
            "Address any verification issues highlighted",
            "Use MinEMI's Loan Approval Predictor for guidance",
          ],
        },
        {
          name: "Under Review",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan application is being reassessed or reprocessed, possibly due to changes in your application or bank policies.",
          nextSteps: [
            "Wait for the bank to complete the reassessment",
            "Check if any additional information is required",
            "Contact customer support for estimated timeline",
            "Track your application status regularly",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has been rejected by State Bank of India. This could be due to various eligibility factors.",
          nextSteps: [
            "Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Work on improving your credit score if that's a factor",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      title: "Understanding Your Axis Bank Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your loan application has been approved by Axis Bank. The loan amount will be disbursed to your account shortly.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Wait for the amount to be credited to your account",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been approved, but for a lower amount than requested based on your eligibility assessment.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Accept or decline the offered amount",
            " If accepted, complete the remaining formalities",
            " Use MinEMI's Eligibility Checker to understand why",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your loan application is currently being processed by Axis Bank. The verification and assessment are ongoing.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "On Hold",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your loan application has been temporarily put on hold. This could be due to incomplete documentation or verification issues.",
          nextSteps: [
            " Contact Axis Bank customer support for details",
            "Submit any pending or additional documents requested",
            " Address any verification issues highlighted",
            "Use MinEMI's Loan Approval Predictor for guidance",
          ],
        },
        {
          name: "Under Review",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan application is being reassessed or reprocessed, possibly due to changes in your application or bank policies.",
          nextSteps: [
            "Wait for the bank to complete the reassessment",
            " Check if any additional information is required",
            " Contact customer support for estimated timeline",
            " Track your application status regularly",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has been rejected by Axis Bank. This could be due to various eligibility factors.",
          nextSteps: [
            " Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Work on improving your credit score if that's a factor",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      title: "Understanding Your ICICI Bank Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your loan application has been approved by ICICI Bank. The loan amount will be disbursed to your account shortly.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Wait for the amount to be credited to your account",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been approved, but for a lower amount than requested based on your eligibility assessment.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Accept or decline the offered amount",
            " If accepted, complete the remaining formalities",
            " Use MinEMI's Eligibility Checker to understand why",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your loan application is currently being processed by ICICI Bank. The verification and assessment are ongoing.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "On Hold",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your loan application has been temporarily put on hold. This could be due to incomplete documentation or verification issues.",
          nextSteps: [
            "  Contact ICICI Bank customer support for details",
            "Submit any pending or additional documents requested",
            " Address any verification issues highlighted",
            "Use MinEMI's Loan Approval Predictor for guidance",
          ],
        },
        {
          name: "Under Review",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan application is being reassessed or reprocessed, possibly due to changes in your application or bank policies.",
          nextSteps: [
            "Wait for the bank to complete the reassessment",
            " Check if any additional information is required",
            " Contact customer support for estimated timeline",
            " Track your application status regularly",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has been rejected by ICICI Bank. This could be due to various eligibility factors.",
          nextSteps: [
            " Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Work on improving your credit score if that's a factor",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      title: "Understanding Your Punjab National Bank Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your loan application has been approved by Punjab National Bank. The loan amount will be disbursed to your account shortly.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Wait for the amount to be credited to your account",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been approved, but for a lower amount than requested based on your eligibility assessment.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Accept or decline the offered amount",
            " If accepted, complete the remaining formalities",
            " Use MinEMI's Eligibility Checker to understand why",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your loan application is currently being processed by Punjab National Bank. The verification and assessment are ongoing.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "On Hold",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your loan application has been temporarily put on hold. This could be due to incomplete documentation or verification issues.",
          nextSteps: [
            "  Contact Punjab National Bank customer support for details",
            "Submit any pending or additional documents requested",
            " Address any verification issues highlighted",
            "Use MinEMI's Loan Approval Predictor for guidance",
          ],
        },
        {
          name: "Under Review",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan application is being reassessed or reprocessed, possibly due to changes in your application or bank policies.",
          nextSteps: [
            "Wait for the bank to complete the reassessment",
            " Check if any additional information is required",
            " Contact customer support for estimated timeline",
            " Track your application status regularly",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has been rejected by Punjab National Bank. This could be due to various eligibility factors.",
          nextSteps: [
            " Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Work on improving your credit score if that's a factor",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      title: "Understanding Your IndusInd Bank Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your loan application has been approved by IndusInd Bank. The loan amount will be disbursed to your account shortly.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Wait for the amount to be credited to your account",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been approved, but for a lower amount than requested based on your eligibility assessment.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Accept or decline the offered amount",
            " If accepted, complete the remaining formalities",
            " Use MinEMI's Eligibility Checker to understand why",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your loan application is currently being processed by IndusInd Bank. The verification and assessment are ongoing.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "On Hold",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your loan application has been temporarily put on hold. This could be due to incomplete documentation or verification issues.",
          nextSteps: [
            "  Contact IndusInd Bank customer support for details",
            "Submit any pending or additional documents requested",
            " Address any verification issues highlighted",
            "Use MinEMI's Loan Approval Predictor for guidance",
          ],
        },
        {
          name: "Under Review",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan application is being reassessed or reprocessed, possibly due to changes in your application or bank policies.",
          nextSteps: [
            "Wait for the bank to complete the reassessment",
            " Check if any additional information is required",
            " Contact customer support for estimated timeline",
            " Track your application status regularly",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has been rejected by IndusInd Bank. This could be due to various eligibility factors.",
          nextSteps: [
            " Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Work on improving your credit score if that's a factor",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      title: "Understanding Your Tata Capital Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your loan application has been approved by Tata Capital. The loan amount will be disbursed to your account shortly.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Wait for the amount to be credited to your account",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been approved, but for a lower amount than requested based on your eligibility assessment.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Accept or decline the offered amount",
            " If accepted, complete the remaining formalities",
            " Use MinEMI's Eligibility Checker to understand why",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your loan application is currently being processed by Tata Capital. The verification and assessment are ongoing.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "On Hold",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your loan application has been temporarily put on hold. This could be due to incomplete documentation or verification issues.",
          nextSteps: [
            "  Contact Tata Capital customer support for details",
            "Submit any pending or additional documents requested",
            " Address any verification issues highlighted",
            "Use MinEMI's Loan Approval Predictor for guidance",
          ],
        },
        {
          name: "Under Review",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan application is being reassessed or reprocessed, possibly due to changes in your application or bank policies.",
          nextSteps: [
            "Wait for Tata Capital to complete the reassessment",
            " Check if any additional information is required",
            " Contact customer support for estimated timeline",
            " Track your application status regularly",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has been rejected by Tata Capital. This could be due to various eligibility factors..",
          nextSteps: [
            " Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Work on improving your credit score if that's a factor",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "#loan-status-checker",
        },
      },
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      title: "UUnderstanding Your Yes Bank Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your loan application has been approved by Yes Bank. The loan amount will be disbursed to your account shortly.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Wait for the amount to be credited to your account",
          ],
        },
        {
          name: "Conditionally Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been approved, but for a lower amount than requested based on your eligibility assessment.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Accept or decline the offered amount",
            " If accepted, complete the remaining formalities",
            " Use MinEMI's Eligibility Checker to understand why",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your loan application is currently being processed by Yes Bank. The verification and assessment are ongoing.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "Pending",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your loan application has been temporarily put on hold. This could be due to incomplete documentation or verification issues.",
          nextSteps: [
            "  Contact Yes Bank customer support for details",
            "Submit any pending or additional documents requested",
            " Address any verification issues highlighted",
            "Use MinEMI's Loan Approval Predictor for guidance",
          ],
        },
        {
          name: "Under Review",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan application is being reassessed or reprocessed, possibly due to changes in your application or bank policies.",
          nextSteps: [
            "Wait for the bank to complete the reassessment",
            " Check if any additional information is required",
            " Contact customer support for estimated timeline",
            " Track your application status regularly",
          ],
        },
        {
          name: "Declined",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has been rejected by Yes Bank. This could be due to various eligibility factors.",
          nextSteps: [
            " Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Work on improving your credit score if that's a factor",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      title: "Understanding Your Union Bank Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Sanctioned",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your loan application has been approved by Union Bank. The loan amount will be disbursed to your account shortly.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Wait for the amount to be credited to your account",
          ],
        },
        {
          name: "Partially Sanctioned",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been approved, but for a lower amount than requested based on your eligibility assessment.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Accept or decline the offered amount",
            " If accepted, complete the remaining formalities",
            " Use MinEMI's Eligibility Checker to understand why",
          ],
        },
        {
          name: "Under Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your loan application is currently being processed by Union Bank. The verification and assessment are ongoing.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "Pending Documentation",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your loan application has been temporarily put on hold. This could be due to incomplete documentation or verification issues.",
          nextSteps: [
            "  Contact Union Bank customer support for details",
            "Submit any pending or additional documents requested",
            " Address any verification issues highlighted",
            "Use MinEMI's Loan Approval Predictor for guidance",
          ],
        },
        {
          name: "Under Review",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan application is being reassessed or reprocessed, possibly due to changes in your application or bank policies.",
          nextSteps: [
            "Wait for the bank to complete the reassessment",
            " Check if any additional information is required",
            " Contact customer support for estimated timeline",
            " Track your application status regularly",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has been rejected by Union Bank. This could be due to various eligibility factors.",
          nextSteps: [
            " Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Work on improving your credit score if that's a factor",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      title: "Understanding Your Bajaj Finance Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your loan application has been approved by Bajaj Finance and is ready for disbursement.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Wait for the amount to be credited to your account",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been approved, but for a lower amount than requested based on your eligibility assessment.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Accept or decline the offered amount",
            " If accepted, complete the remaining formalities",
            " Use MinEMI's Eligibility Checker to understand why",
          ],
        },
        {
          name: "UIn Process",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your loan application is currently being evaluated by Bajaj Finance. Verification and assessment are in progress.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "Documents Required",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your loan application has been temporarily put on hold. This could be due to incomplete documentation or verification issues.",
          nextSteps: [
            "  Check the Bajaj Finserv app for document requirementsWait for Bajaj Finance to complete their review",
            "Submit any pending or additional documents requested",
            " Address any verification issues highlighted",
            "Use MinEMI's Loan Approval Predictor for guidance",
          ],
        },
        {
          name: "Under Review",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan application is being reassessed or reprocessed, possibly due to changes in your application or bank policies.",
          nextSteps: [
            "Wait for Bajaj Finance to complete their review",
            " Check if any additional information is required",
            " Contact customer support for estimated timeline",
            " Track your application status regularly",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has been rejected by Union Bank. This could be due to various eligibility factors.",
          nextSteps: [
            " Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Work on improving your credit score if that's a factor",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      title: "Understanding Your Federal Bank Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your loan application has been approved by Federal Bank and is ready for disbursement.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Wait for the amount to be credited to your account",
          ],
        },
        {
          name: "Sanctioned",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been approved, but for a lower amount than requested based on your eligibility assessment.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Accept or decline the offered amount",
            " If accepted, complete the remaining formalities",
            " Use MinEMI's Eligibility Checker to understand why",
          ],
        },
        {
          name: "Under Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your loan application is currently being evaluated by Federal Bank. Verification and assessment are in progress.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "Documents Pending",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your loan application has been temporarily put on hold. This could be due to incomplete documentation or verification issues.",
          nextSteps: [
            "  Check the FedMobile app for document requirements",
            "Submit any pending or additional documents requested",
            " Address any verification issues highlighted",
            "Use MinEMI's Loan Approval Predictor for guidance",
          ],
        },
        {
          name: "Credit Verification",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan application is being reassessed or reprocessed, possibly due to changes in your application or bank policies.",
          nextSteps: [
            "Wait for Federal Bank to complete their verification",
            " Check if any additional information is required",
            " Contact customer support for estimated timeline",
            " Track your application status regularly",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has been rejected by Federal Bank based on their eligibility criteria.",
          nextSteps: [
            " Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Work on improving your credit score if that's a factor",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      title: "Understanding Your Canara Bank Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Sanctioned",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your loan application has been sanctioned by Canara Bank and is ready for disbursement.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Wait for the amount to be credited to your account",
          ],
        },
        {
          name: "Partially Sanctioned",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been approved, but for a lower amount than requested based on your eligibility assessment.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Accept or decline the offered amount",
            " If accepted, complete the remaining formalities",
            " Use MinEMI's Eligibility Checker to understand why",
          ],
        },
        {
          name: "Under Process",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your loan application is currently being evaluated by Canara Bank. Verification and assessment are in progress.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "Additional Documents Required",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your loan application has been temporarily put on hold. This could be due to incomplete documentation or verification issues.",
          nextSteps: [
            "  Check the CANDI app for document requirements",
            "Submit any pending or additional documents requested",
            " Address any verification issues highlighted",
            "Use MinEMI's Loan Approval Predictor for guidance",
          ],
        },
        {
          name: "Verification Stage",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan application is being reassessed or reprocessed, possibly due to changes in your application or bank policies.",
          nextSteps: [
            "Wait for Canara Bank to complete their verification",
            " Check if any additional information is required",
            " Contact customer support for estimated timeline",
            " Track your application status regularly",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has been rejected by Canara Bank based on their eligibility criteria.",
          nextSteps: [
            " Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Work on improving your credit score if that's a factor",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      title: "Understanding Your Bank of India Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your loan application has been approved by Bank of India and is ready for disbursement.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Wait for the amount to be credited to your account",
          ],
        },
        {
          name: "Sanctioned",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been approved, but for a lower amount than requested based on your eligibility assessment.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Accept or decline the offered amount",
            " If accepted, complete the remaining formalities",
            " Use MinEMI's Eligibility Checker to understand why",
          ],
        },
        {
          name: "In Process",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your loan application is currently being evaluated by Bank of India. Verification and assessment are in progress.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "Documents Pending",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your loan application has been temporarily put on hold. This could be due to incomplete documentation or verification issues.",
          nextSteps: [
            "Check the Starconnect+ app for document requirements",
            "Submit any pending or additional documents requested",
            " Address any verification issues highlighted",
            "Use MinEMI's Loan Approval Predictor for guidance",
          ],
        },
        {
          name: "Verification Stage",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your application is undergoing a detailed verification process, including credit assessment and field verification.",
          nextSteps: [
            " Wait for Bank of India to complete their verification",
            " Check if any additional information is required",
            " Contact customer support for estimated timeline",
            " Track your application status regularly",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has been rejected by Bank of India based on their eligibility criteria.",
          nextSteps: [
            " Request specific reasons for the rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Work on improving your credit score if that's a factor",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      title: "Understanding Your RBL Bank Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your loan application has been approved by RBL Bank and is ready for disbursement.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Verify the loan amount and terms",
            "Complete any pending documentation if required",
            "Prepare for disbursement to your bank account",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been pre-approved but may be for a different amount than requested based on your eligibility assessment.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Accept or decline the offered amount",
            " If accepted, complete the remaining formalities",
            " Use MinEMI's Eligibility Checker to understand why",
          ],
        },
        {
          name: "Under Review",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your loan application is currently being evaluated by RBL Bank. Verification and assessment are in progress.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Track your application status every 2-3 days",
            "Be patient as the process typically takes 3-5 working days",
          ],
        },
        {
          name: "Additional Information Required",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application needs additional documentation or verification before it can proceed further.",
          nextSteps: [
            "Check the RBL MoBank app for document requirements",
            "Submit any pending or additional documents requested",
            " Address any verification issues highlighted",
            "Use MinEMI's Loan Approval Predictor for guidance",
          ],
        },
        {
          name: "Verification Stage",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your application is undergoing a detailed verification process, including credit assessment and field verification.",
          nextSteps: [
            " Wait for RBL Bank to complete their verification",
            " Check if any additional information is required",
            " Contact customer support for estimated timeline",
            " Track your application status regularly",
          ],
        },
        {
          name: "Declined",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has been declined by RBL Bank based on their eligibility criteria.",
          nextSteps: [
            " Request specific reasons for the rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Work on improving your credit score if that's a factor",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );

  useEffect(() => {
    if (name === "checkkotakbankstatus") {
      setData(kotakData);
    } else if (name === "checkbobbankstatus") {
      setData(bobData);
    } else if (name === "checkidfcbankstatus") {
      setData(idfcData);
    } else if (name === "checkhdfcbankstatus") {
      setData(hdfcData);
    } else if (name === "checksbibankstatus") {
      setData(sbiData);
    } else if (name === "checkaxisbankstatus") {
      setData(axisData);
    } else if (name === "checkicicibankstatus") {
      setData(iciciData);
    } else if (name === "checkpunjabbankstatus") {
      setData(punjabData);
    } else if (name === "checkinduslndbankstatus") {
      setData(induslndData);
    } else if (name === "checktatabankstatus") {
      setData(tataData);
    } else if (name === "checkyesbankstatus") {
      setData(yesData);
    } else if (name === "checkunionbankstatus") {
      setData(unionData);
    } else if (name === "checkbajajbankstatus") {
      setData(bajajData);
    } else if (name === "checkfederalbankstatus") {
      setData(federalData);
    } else if (name === "checkcanarabankstatus") {
      setData(canaraData);
    } else if (name === "checkboibankstatus") {
      setData(boiData);
    } else if (name === "checkrblbankstatus") {
      setData(rblData);
    } else {
      // Initialize with empty structure instead of empty array
      setData({
        title: "",
        description: "",
        statuses: [],
        footer: { text: "", button: { text: "", link: "" } },
      });
    }
  }, [
    name,
    axisData,
    bobData,
    hdfcData,
    iciciData,
    idfcData,
    kotakData,
    sbiData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
  ]);

  return (
    <section className="py-4 md:py-16  border-b border-gray-200">
      <div className="container px-4 md:px-6">
        <div className="text-center mb-10 bg-transparent">
          <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl text-primary-purple bg-transparent ">
            {data.title}
          </h2>
          <p className="text-dark-grey mt-2 max-w-2xl mx-auto bg-transparent text-center">
            {data.description}
          </p>
        </div>

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 max-w-6xl mx-auto bg-transparent">
          {data.statuses.map((d, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-sm border border-gray-200"
            >
              <div className="h-12 w-12 rounded-full bg-green-100 flex items-center justify-center mb-4">
                {d.icon}
              </div>
              <h3 className="text-xl font-bold text-primary-purple mb-2">
                {d.name}
              </h3>
              <p className="text-dark-grey mb-4 bg-transparent">
                {d.description}
              </p>
              <div
                className="bg-light-bg px-4 pt-2 rounded-md border border-gray-100"
                style={{ backgroundColor: "#ebe8fb" }}
              >
                <h4 className="font-bold text-primary-purple text-sm mb-2 bg-transparent">
                  Next Steps:
                </h4>
                <ul className="text-sm text-dark-grey space-y-2 bg-transparent p-0">
                  {d.nextSteps.map((step, index) => (
                    <li key={index} className="bg-transparent">
                      • {step}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-10 text-center bg-transparent">
          <p className="text-dark-grey mb-3 bg-transparent text-center">
            {data.footer.text}
          </p>
          <a
            href="/trackloanstatus"
            className="inline-flex h-12 items-center justify-center rounded-md bg-teal px-8 text-sm font-medium text-white transition-colors hover:bg-primary-purple focus:outline-none focus:ring-2 focus:ring-primary-purple  bg-[#3b868b] hover:bg-[#2b1b61]"
          >
            {data.footer.button.text}
          </a>
        </div>
      </div>
    </section>
  );
}

import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import "./EligiblityCriteria.css";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import MetadataContext from "../MetadataContext";

const AxisEligiblity = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const infoListStyle = {
    color: "#1F1F1F",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "20px",
    paddingLeft: "13px",
    listStyleType: "circle",
    marginBottom: "0px",
  };

  const criteriaData = [
    {
      factor: "Income",
      requirement: "₹25,000/month (minimum for salaried individuals)",
      impact:
        "Higher income increases loan eligibility and the amount you can borrow.",
    },
    {
      factor: "Credit Score",
      requirement: "700+",
      impact:
        "A higher score qualifies you for better loan terms and lower interest rates.",
    },
    {
      factor: "Employment Status",
      requirement: "Salaried or self-employed (minimum 2 years)",
      impact:
        "Stable employment improves chances; self-employed individuals may need more documentation.",
    },
    {
      factor: "Fixed Obligations to Income Ratio (FOIR)",
      requirement: "40%-50%",
      impact:
        "A lower FOIR indicates fewer existing EMIs and improves loan eligibility.",
    },
    {
      factor: "Age",
      requirement: "21 to 60 years",
      impact:
        "Younger applicants have a longer tenure but must show steady income.",
    },
  ];

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        "Check Your Personal Loan Eligibility Instantly with AXIS Bank",
      pageDescription:
        "Check your personal loan eligibility instantly with AXIS Bank. Enjoy quick approvals, flexible EMIs, and hassle-free online applications. Apply now for fast disbursal!",
      pageKeywords:
        "AXIS Bank Personal Loan, Personal Loan Eligibility, Instant Loan Eligibility Check, AXIS Loan Eligibility Calculator, Personal Loan Online Application, Quick Personal Loan Approval, AXIS Bank Loan Calculator, Check Personal Loan Eligibility, Instant Personal Loan Approval, AXIS Bank Loan Offers, Personal Loan EMI Calculator, Online Loan Eligibility Check, AXIS Instant Loan Services, Best Personal Loan Rates, Loan Eligibility Criteria",
      canonicalUrl:
        "https://minemi.ai/loanpage/personalloan/loan-eligibilty-criteria/axis-loan-eligiblity",
    });
  }, [setMetadata]);
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              <Link
                to={"/loanpage/personalloan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Personal Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/personalloan/loan-eligibilty-criteria"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Personal Loan Check Eligibility
              </Link>{" "}
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Check Eligibility with Axis Bank
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "55px",
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            AXIS Bank Personal Loan Eligibility
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Check your eligibility for an AXIS Bank personal loan. Learn about
            the credit score, income, age, and other criteria to qualify for the
            best loan offers from AXIS.
          </p>
        </div>
        <div
          className=""
          style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}
        >
          <h2
            style={{
              marginTop: 30,
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 28,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Personal Loan Eligibility Criteria
          </h2>
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
              color: "#1f1f1f",
              marginTop: 15,
            }}
          >
            Understand the key factors that determine your eligibility for an
            AXIS Bank personal loan. From age and income to employment type and
            credit history, explore the essential requirements to secure the
            loan that fits your needs
          </p>
          <div
            style={{
              border: "1px solid #D9D9D9",
              borderRadius: 24,
              marginTop: 24,
              backgroundColor: "white",
              marginBottom: "2%",
              overflow: "hidden",
              maxWidth: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
              }}
            >
              <div
                style={{
                  backgroundColor: "#E7E4F9",
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 16,
                  lineHeight: "20px",
                  color: "#59266D",
                  padding: "16px",
                  borderBottom: "1px solid #D9D9D9",
                  borderTopLeftRadius: 24,
                  borderTopRightRadius: 24,
                  textAlign: "center",
                }}
              >
                AXIS Loan Eligibility Criteria
              </div>
            </div>

            <div
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                width: "100%",
                overflow: "auto",
              }}
            >
              <table
                className="table-class"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",

                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  textAlign: "left",
                }}
              >
                <thead className="thead-class">
                  <tr>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Eligibility Factor
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Minimum Requirement
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Impact on Loan Eligibility
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {criteriaData.map((row, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      }}
                    >
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        {row.factor}
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {row.requirement}
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {row.impact}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Eligibility Criteria for Axis Bank Personal Loan{" "}
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            To qualify for a personal loan from Axis Bank, applicants must meet
            the following requirements:{" "}
          </div>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Age:&nbsp;
                </span>
                Applicants should be between 21 years and 60 years at the time
                of loan maturity.{" "}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Income:&nbsp;
                </span>
                <ul style={infoListStyle}>
                  <li>
                    For existing Axis Bank customers, the minimum net monthly
                    income required is ₹15,000.
                  </li>
                  <li>
                    For non-Axis Bank customers, the minimum net monthly income
                    is ₹25,000.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Employment Type:&nbsp;
                </span>
                The loan is available for salaried individuals employed with
                public, private, or government entities.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Credit Score:{" "}
                </span>
                A CIBIL score of 700 or above is preferred for loan eligibility.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Loan Amount:{" "}
                </span>
                The maximum loan amount is determined based on the applicant's
                income and repayment capacity
              </div>
            </div>
          </div>
          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Documents Required for Axis Bank Personal Loan{" "}
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Applicants need to submit the following documents to process a
            personal loan application with Axis Bank:
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Identity Proof (any one):{" "}
                </span>
                <ul style={infoListStyle}>
                  <li>Aadhaar Card</li>
                  <li>PAN Card</li>
                  <li>Passport</li>
                  <li>Driving License</li>
                  <li className="mb-0">Voter ID</li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Address Proof (any one):
                </span>
                <ul style={infoListStyle}>
                  <li>Utility Bill (Electricity/Water/Telephone)</li>
                  <li>Aadhaar Card</li>
                  <li>Passport</li>
                  <li className="mb-0">Rental Agreement</li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                   Income Proof:
                </span>
                <ul style={infoListStyle}>
                  <li>
                    Last 3 months' salary slips (for salaried individuals).
                  </li>
                  <li>Latest Form 16 or Income Tax Returns.</li>
                  <li className="mb-0">
                    Bank statements of the last 6 months showing salary credits.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Photographs:{" "}
                </span>
                <ul style={infoListStyle}>
                  <li className="mb-0">Recent passport-sized photographs</li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Other Supporting Documents (if applicable):
                </span>
                <ul style={infoListStyle}>
                  <li className="mb-0">
                    Loan sanction letters for existing loans (if any).
                    Additional documents may be requested depending on the
                    applicant’s profile and loan amount.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            How to Improve Your Eligibility for an Axis Bank Personal Loan{" "}
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            If you're aiming to enhance your chances of securing a personal loan
            from Axis Bank, consider the following tips:
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Maintain a High Credit Score:
                </span>
                <ul style={infoListStyle}>
                  <li className="mb-0">
                    Ensure your CIBIL score is above 700. Timely repayment of
                    existing loans and credit card bills can help improve your
                    credit score over time.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Boost Your Income Profile:&nbsp;
                </span>
                <ul style={infoListStyle}>
                  <li className="mb-0">
                    If possible, demonstrate additional income sources, such as
                    rental income or bonuses. A higher disposable income
                    increases your repayment capacity
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Clear Existing Debts:
                </span>
                <ul style={infoListStyle}>
                  <li className="mb-0">
                    Reduce your debt-to-income ratio by paying off smaller loans
                    or credit card balances. This indicates better financial
                    health to lenders.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Choose an Appropriate Loan Amount:
                </span>
                <ul style={infoListStyle}>
                  <li className="mb-0">
                    Apply for a loan amount that matches your income and
                    repayment capability. Avoid overestimating your borrowing
                    requirements.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Provide Accurate Documentation:
                </span>
                <ul style={infoListStyle}>
                  <li className="mb-0">
                    Submit all required documents, such as salary slips, bank
                    statements, and identity proof, without discrepancies.
                    Missing or inconsistent documentation can delay approval.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Consider Co-Applicants:
                </span>
                <ul style={infoListStyle}>
                  <li className="mb-0">
                    Adding a co-applicant with a stable income or high credit
                    score can enhance your eligibility and increase the loan
                    amount.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Opt for a Longer Tenure:
                </span>
                <ul style={infoListStyle}>
                  <li className="mb-0">
                    Selecting a longer loan tenure reduces your EMI burden,
                    making it easier to qualify for the loan.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <LuDot />
                </span>
              </div>
              <div
                style={{
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                }}
              >
                <span
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  {" "}
                  Build a Strong Banking Relationship:
                </span>
                <ul style={infoListStyle}>
                  <li className="mb-0">
                    Maintaining accounts or other financial products with Axis
                    Bank can improve your chances of approval due to the bank’s
                    familiarity with your financial behavior
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <p
            style={{ fontSize: 16, lineHeight: "20px", color: "#1f1f1f" }}
            className="mt-4"
          >
            For more information please check{" "}
            <a
              href={
                "https://www.axisbank.com/retail/calculators/personal-loan-eligibility-calculator"
              }
              target="_blank"
            >
              AXIS Bank Eligibility
            </a>
          </p>

          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            FAQs on Axis Bank Personal Loan Eligibility
          </h3>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              backgroundColor: "#F6F6F6",
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What is the minimum salary required to be eligible for an Axis
                Bank personal loan?
              </AccordionSummary>
              <AccordionDetails>
                For existing Axis Bank customers, the minimum net monthly income
                required is ₹15,000. For non-Axis Bank customers, the
                requirement is ₹25,000
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{ fontWeight: 600, borderRadius: 12 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                What is the preferred credit score for Axis Bank personal loans?
              </AccordionSummary>
              <AccordionDetails>
                A credit score of 700 or above is preferred to enhance your
                chances of approval
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{ fontWeight: 600, borderRadius: 12 }}
              >
                What is the eligible age range for applying for a personal loan
                with Axis Bank?
              </AccordionSummary>
              <AccordionDetails>
                Applicants must be between 21 years and 60 years of age at the
                time of loan maturity
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{ fontWeight: 600, borderRadius: 12 }}
              >
                Can self-employed individuals apply for an Axis Bank personal
                loan?
              </AccordionSummary>
              <AccordionDetails>
                Axis Bank primarily offers personal loans to salaried
                individuals. However, selfemployed individuals may explore other
                Axis Bank loan products tailored to their needs
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{ fontWeight: 600, borderRadius: 12 }}
              >
                What documents are required to prove income for eligibility?
              </AccordionSummary>
              <AccordionDetails>
                You need to provide your last three months’ salary slips, six
                months' bank statements, and the latest Form 16 or Income Tax
                Returns as income proof
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br /> and tailored just for you—apply now to see what you
                qualify for!
              </p>
              <a
                href="https://minemi.ai/personal-loan/check-eligibility"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                  backgroundColor: "transparent",
                  marginTop: "2%",
                  cursor: "pointer",
                }}
              >
                <div
                  className="buttom-loan-eligiblity-one"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 40,
                    paddingRight: 40,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                   Check Your Eligibility Now
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AxisEligiblity;

import React, { useEffect, useContext } from "react";

import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import MetadataContext from "../../MetadataContext";
import tongueOut from "../../../Assets/Images/tongue-out.png";

const CCArticleThree = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Why Is My Credit Card Application Getting Rejected?",
      pageDescription:
        "Find out why your credit card application might be getting rejected. Learn the common reasons for credit card rejections in India and how to increase your chances of approval.",
      pageKeywords:
        "credit card rejection, reasons for credit card rejection, how to get a credit card, improve credit card approval chances, credit card application tips",
      canonicalUrl:
        "https://minemi.ai/creditcard/why-is-my-credit-card-application-getting-rejected",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8", height: "auto" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/creditcard"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Credit Card
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Why Is My Credit Card Application Getting Rejected?
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Why Is My Credit Card Application Getting Rejected?
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Applying for a credit card in India has become more accessible, with
            banks and financial institutions offering a wide range of options to
            suit different needs. However, it can be disappointing to find out
            that your application has been rejected. Understanding the reasons
            behind the rejection can help you improve your chances of approval
            in the future. In this article, we’ll explore the most common
            factors that lead to credit card application rejections in India and
            how you can address them effectively.
          </p>
        </div>

        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <h3
              style={{
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
                fontSize: 24,
              }}
            >
              Expert Insights on Credit Card Eligibility
            </h3>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              According to TransUnion CIBIL, a leading credit bureau in India,
              “A CIBIL score of 750 and above is considered good and
              significantly increases your chances of credit approval. However,
              it is equally important to meet other eligibility criteria set by
              the credit issuer, such as income levels and documentation
              requirements.”
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Let’s dive into the reasons behind credit card rejections and ways
              to address them, with examples of popular credit cards in India.
            </p>
          </div>

          <div>
            <h2
              style={{
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
                fontSize: 20,
              }}
            >
              1. Low Credit Score
            </h2>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              One of the most common reasons for credit card rejection is a low
              credit score. In India, the CIBIL score is widely used to assess
              an individual’s creditworthiness. A score above 750 is generally
              considered good, while anything below that might raise red flags
              for lenders.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              For instance, premium cards like HDFC Bank Regalia Credit Card or
              SBI Card ELITE often require a strong credit score for approval. A
              poor credit score could result in rejection for these high-reward
              cards.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong>How to Improve:</strong>
              <ul style={{ listStyle: "disc" }}>
                <li>Pay all your EMIs and credit card bills on time.</li>
                <li>Keep your credit utilization ratio below 30%.</li>
                <li>
                  Regularly check your credit report for errors and dispute
                  inaccuracies.
                </li>
              </ul>
            </p>
          </div>

          <div>
            <h3
              style={{
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
                fontSize: 20,
              }}
            >
              2. Insufficient Income
            </h3>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Credit card issuers set minimum income criteria to ensure
              applicants can repay the borrowed amount. If your income does not
              meet the bank’s threshold, your application may be rejected.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              For example:
              <ul style={{ listStyle: "disc" }}>
                <li>
                  ICICI Bank Coral Credit Card is tailored for individuals with
                  moderate incomes.
                </li>
                <li>
                  On the other hand, cards like American Express Platinum Card
                  have higher income requirements and are designed for affluent
                  individuals.
                </li>
              </ul>
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong>How to Improve:</strong>
              <ul style={{ listStyle: "disc" }}>
                <li>
                  Check the income criteria before applying for a credit card.
                </li>
                <li>
                  Consider applying for entry-level cards like Axis Bank ACE
                  Credit Card, which have lower income requirements.
                </li>
                <li>
                  Provide proof of additional sources of income if applicable.
                </li>
              </ul>
            </p>
          </div>

          <div>
            <h3
              style={{
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
                fontSize: 20,
              }}
            >
              3. Unstable Employment History
            </h3>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Lenders prefer applicants with a stable job history as it
              indicates financial stability. Frequent job changes or long
              periods of unemployment might result in your application being
              rejected.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong>How to Improve:</strong>
              <ul style={{ listStyle: "disc" }}>
                <li>Maintain a steady employment record before applying.</li>
                <li>
                  Include all relevant employment details and supporting
                  documents when filling out the application.
                </li>
              </ul>
            </p>
          </div>
          <div
            className="tips mt-5 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    Minemi Tips
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    <em style={{ background: "transparent" }}>
                      If your income doesn't meet the card issuer’s
                      requirements, consider applying for a lower-tier card or
                      providing proof of additional income sources (e.g.,
                      freelance work, rental income).
                    </em>
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "1%" }}>
            <h3
              style={{
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
                fontSize: 20,
              }}
            >
              4. Existing Debt Burden
            </h3>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              If you already have multiple loans or credit cards with high
              outstanding balances, lenders may view you as a high-risk
              borrower. A high debt-to-income ratio (DTI) can signal that you
              might struggle to manage additional credit.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong>How to Improve:</strong>
              <ul style={{ listStyle: "disc" }}>
                <li>
                  Pay off existing debts before applying for a new credit card.
                </li>
                <li>
                  Avoid taking on new loans if you plan to apply for a credit
                  card soon.
                </li>
              </ul>
            </p>
          </div>

          <div style={{ marginTop: "1%" }}>
            <h4
              style={{
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
                fontSize: 20,
              }}
            >
              5. Incomplete or Incorrect Application
            </h4>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              A simple mistake or missing information in your application can
              lead to rejection. Lenders require accurate and complete details
              to process your application efficiently.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong>How to Improve:</strong>
              <ul style={{ listStyle: "disc" }}>
                <li>
                  Double-check your application for errors before submitting it.
                </li>
                <li>
                  Ensure all required documents are attached and up-to-date.
                </li>
                <li>Provide accurate contact details for easy verification.</li>
              </ul>
            </p>
          </div>

          <div style={{ marginTop: "1%" }}>
            <h4
              style={{
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
                fontSize: 20,
              }}
            >
              6. Too Many Recent Applications
            </h4>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Applying for multiple credit cards or loans within a short period
              can negatively impact your credit score and make lenders wary of
              your financial behavior. Each application results in a hard
              enquiry, which can lower your credit score temporarily.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong>How to Improve:</strong>
              <ul style={{ listStyle: "disc" }}>
                <li>
                  Avoid applying for multiple credit cards simultaneously.
                </li>
                <li>
                  Wait for at least 3-6 months before reapplying if your
                  application is rejected.
                </li>
              </ul>
            </p>
          </div>
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    Minemi Tips
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    <em style={{ background: "transparent" }}>
                      Regularly review your credit report for any errors and
                      dispute them with the credit bureau.{" "}
                    </em>
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "1%" }}>
            <h4
              style={{
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
                fontSize: 20,
              }}
            >
              7. No Credit History
            </h4>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              If you’re applying for your first credit card, a lack of credit
              history might work against you. Lenders have no way of assessing
              your repayment behavior, making them hesitant to approve your
              application.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Example: Many banks offer secured cards like the SBI Unnati Credit
              Card or ICICI Bank Instant Platinum Card, which are ideal for
              first-time applicants with no credit history.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong>How to Improve:</strong>
              <ul style={{ listStyle: "disc" }}>
                <li>
                  Start by applying for a secured credit card backed by a fixed
                  deposit.
                </li>
                <li>
                  Use the card responsibly to build your credit history over
                  time.
                </li>
              </ul>
            </p>
          </div>

          <div style={{ marginTop: "1%" }}>
            <h4
              style={{
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
                fontSize: 20,
              }}
            >
              8. Mismatch in Eligibility Criteria
            </h4>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Each credit card has specific eligibility requirements based on
              factors such as age, income, location, and occupation. If you do
              not meet any of these criteria, your application is likely to be
              rejected.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong>How to Improve:</strong>
              <ul style={{ listStyle: "disc" }}>
                <li>
                  Review the card’s eligibility requirements carefully before
                  applying.
                </li>
                <li>
                  Choose a card that matches your profile and financial
                  situation.
                </li>
              </ul>
            </p>
          </div>
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    Minemi Tips
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    <em style={{ background: "transparent" }}>
                      If you’re not sure about your eligibility, you can use our
                      *check your eligibility tool* to know whether you qualify
                      for a specific card.{" "}
                    </em>
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "1%" }}>
            <h5
              style={{
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
                fontSize: 20,
              }}
            >
              9. Issues with Address Verification
            </h5>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              If the address you provide cannot be verified, or if it does not
              match the documents submitted, your application may be denied.
              Lenders rely on accurate address details for identity
              verification.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong>How to Improve:</strong>
              <ul style={{ listStyle: "disc" }}>
                <li>
                  Provide clear and valid address proof such as an Aadhaar card,
                  utility bill, or rental agreement.
                </li>
                <li>
                  Ensure your address matches across all submitted documents.
                </li>
              </ul>
            </p>
          </div>

          <div style={{ marginTop: "1%" }}>
            <h5
              style={{
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
                fontSize: 20,
              }}
            >
              10. Previous Default or Bankruptcy
            </h5>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              If you’ve defaulted on loans or declared bankruptcy in the past,
              it will reflect in your credit history and affect your chances of
              getting approved for a credit card.
            </p>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              <strong>How to Improve:</strong>
              <ul style={{ listStyle: "disc" }}>
                <li>
                  Work towards clearing old defaults or settling them with the
                  lender.
                </li>
                <li>
                  Gradually rebuild your credit score by using a secured credit
                  card.
                </li>
              </ul>
            </p>
          </div>

          <div style={{ marginTop: "2%" }} className="pb-4">
            <h3
              style={{
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
                fontSize: 24,
              }}
            >
              Conclusion
            </h3>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              }}
            >
              Understanding the reasons behind credit card application
              rejections can save you from unnecessary frustration. By improving
              your financial habits and addressing specific issues, you can
              increase your chances of approval and access the credit card that
              best suits your lifestyle.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CCArticleThree;

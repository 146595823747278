import { useState, useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"

import Illustration from '../../Assets/Images/Illustration.webp'

import HomeLogo  from '../../Assets/Images/HomeLogo.webp'
import userLogo from"../../Assets/Images/userLogo.webp"
import check from "../../Assets/Images/check.webp"
import uncheck  from "../../Assets/Images/uncheck.webp"


function LowerEmiDetailPage() {
  const location = useLocation()
  const navigate = useNavigate()
  const { state } = location
  const [all, setAll] = useState(true)
  const [homeloan, setHomeLoan] = useState(false)
  const [personalloan, setPersonalLoan] = useState(false)
  const [loanAmount, setLoanAmount] = useState("")
  const [tenure, setTenure] = useState("")
  const [oldirr, setOldIrr] = useState("")
  const [newirr, setNewIrr] = useState("")
  const [accountType, setAccountType] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const id = localStorage.getItem("id")
  const [data1, setData] = useState(
    id == "3" ? state.Loan.loanList.filter((item) => item.max_topup_allowed !== 0) : state.Loan.loanList,
  )
  const [val, setVal] = useState(null)
  const [open, setOpen] = useState(false)

  const allSection = () => {
    setAll(true)
    setHomeLoan(false)
    setPersonalLoan(false)
  }
  const homeLoan = () => {
    setHomeLoan(true)
    setAll(false)
    setPersonalLoan(false)
  }
  const personalLoan = () => {
    setHomeLoan(false)
    setAll(false)
    setPersonalLoan(true)
  }
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-IN").format(number)
  }

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token == null) {
      navigate("/")
    }
  }, [navigate])
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
}, [])

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-2 pb-20 md:py-8" style={{backgroundColor:'transparent'}}>
        {/* Mobile Illustration - Only visible on mobile */}
        <div className="md:hidden" style={{backgroundColor:'transparent'}}>
          <div className="text-center py-6" style={{backgroundColor:'transparent'}}>
            <img
              src={Illustration || "/placeholder.svg"}
              alt="Savings Illustration"
              className="mx-auto w-48 h-48 mb-4"
              style={{backgroundColor:'transparent'}}
            />
            {id==3?null:
            <>
            <div className="text-base mb-1" style={{backgroundColor:'transparent',textAlign:'center'}}>You can save</div>
            <div className="text-3xl font-semibold text-purple-900 mb-2" style={{backgroundColor:'transparent',textAlign:'center'}}>
              Rs {formatNumberWithCommas(state.Loan.emiSaved)}
            </div>
            </>
            }
          </div>
          <div className="text-sm text-gray-600 text-center mb-6" style={{backgroundColor:'transparent'}}>Please select the loans to lower your EMIs</div>
        </div>

        {/* Tabs */}
        <div className="flex space-x-6 md:space-x-8 mb-6 overflow-x-auto" style={{backgroundColor:'transparent'}}>
          <button
            onClick={allSection}
            className={`pb-2 px-2 text-sm md:text-base whitespace-nowrap ${
              all ? "border-b-2 border-black text-black font-medium" : "text-gray-500"
            }`}
            style={{backgroundColor:'transparent'}}
          >
            All
          </button>
          <button
            onClick={homeLoan}
            className={`pb-2 px-2 text-sm md:text-base whitespace-nowrap ${
              homeloan ? "border-b-2 border-black text-black font-medium" : "text-gray-500"
            }`}
            style={{backgroundColor:'transparent'}}
          >
            Home loan
          </button>
          <button
            onClick={personalLoan}
            className={`pb-2 px-2 text-sm md:text-base whitespace-nowrap ${
              personalloan ? "border-b-2 border-black text-black font-medium" : "text-gray-500"
            }`}
            style={{backgroundColor:'transparent'}}
          >
            Personal loan
          </button>
        </div>

        <div className="flex flex-col lg:flex-row gap-8 " style={{backgroundColor:'transparent',flexWrap:"nowrap"}}>
          {/* Loan Cards */}
          <div className="lg:w-2/3" style={{backgroundColor:'transparent'}}>
          {
            all &&
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4" style={{backgroundColor:'transparent'}}>
              {data1
                .filter((d) => d.status == 1)
                .map((d, index) => (
                  <div key={index} className="bg-white rounded-lg 
                  " style={{borderLeft:'4px solid green'}}
                  >
                    <div className="flex items-center" style={{backgroundColor:'transparent',padding:'2%',paddingTop:'3%',gap:2}}>
                      {(id == "1" || id == "2") && (
                        <div
                          className="w-6 h-6 cursor-pointer"
                          style={{backgroundColor:'transparent'}}
                          onClick={() => {
                            setLoanAmount(d.loanAmount)
                            setNewIrr(d.new_Irr)
                            setOldIrr(d.oldIrr)
                            setTenure(d.new_tenure)
                            setAccountNumber(d.Account_Number)
                            setAccountType(d.account_type)
                            setVal(val == index ? null : index)
                          }}
                        >
                          <img src={val === index ? check : uncheck} alt="" className="w-full h-full"style={{backgroundColor:'transparent'}} />
                        </div>
                      )}
                      <div className="w-10 h-10 bg-purple-50 rounded-full flex items-center justify-center">
                        <img src={d.account_type == "05" ? userLogo : HomeLogo} alt="Loan Type" className="w-5 h-5" style={{backgroundColor:'transparent'}}/>
                      </div>
                      <div className="flex-grow"style={{backgroundColor:'transparent'}}>
                        <div  className="make-small" style={{backgroundColor:'transparent',fontWeight:700,fontFamily:'Archivo',fontSize:15.5,color:'#1F1F1F',lineHeight:'1.2'}}>{d.loan_name}</div>
                        <div className="text-sm text-gray-500 make-small" style={{backgroundColor:'transparent'}}>SBI</div>
                      </div>
                      {d.save_on_emi > 0 && !isNaN(d.save_on_emi) && id != 3 && (
                        <div className="text-sm text-green-600 bg-green-50 px-3 py-1 rounded-full whitespace-nowrap" >
                          Saving ₹{formatNumberWithCommas(d.save_on_emi)}
                        </div>
                      )}
                    </div>
                    <div className="mt-2 text-center p-2" style={{backgroundColor:'transparent',borderTop:'1px solid black',padding:0}}>
                      <Link
                        to={id == "1" || id == "2" ? "/details" : "/topupdetail"}
                        state={{
                          oldIrr: d.oldIrr,
                          newIrr: d.new_Irr,
                          loanAmount: d.loanAmount,
                          tenure: d.new_tenure,
                          oldemi: d.old_emi,
                          newemi: d.new_emi,
                          saveonemi: d.save_on_emi,
                          accountType: d.account_type,
                          subscriber: d.subscriber_name,
                          savepercentage: d.save_percentage,
                          Account_Number: d.Account_Number,
                          maxtopupallowed: d.max_topup_allowed,
                          loan_tenure: d.loan_tenure,
                          CurrentBalance: d.Current_Balance,
                        }}
                        className="text-purple-800 text-sm hover:underline"
                        style={{fontFamily:'Archivo',fontWeight:600}}
                      >
                                                     {id == "1" || id == "2" ? "Check Savings":"Monthly Savings"}

                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          }
          {
            homeloan &&
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4" style={{backgroundColor:'transparent'}}>
              {data1
                .filter((d) => d.status == 1)
                .map((d, index) => (
                  <>
                    {d.account_type == "02" && (
                         <div key={index} className="bg-white rounded-lg 
                         " style={{borderLeft:'4px solid green'}}
                         >
                           <div className="flex items-center" style={{backgroundColor:'transparent',padding:'2%',paddingTop:'3%',gap:2}}>
                             {(id == "1" || id == "2") && (
                               <div
                                 className="w-6 h-6 cursor-pointer"
                                 style={{backgroundColor:'transparent'}}
                                 onClick={() => {
                                   setLoanAmount(d.loanAmount)
                                   setNewIrr(d.new_Irr)
                                   setOldIrr(d.oldIrr)
                                   setTenure(d.new_tenure)
                                   setAccountNumber(d.Account_Number)
                                   setAccountType(d.account_type)
                                   setVal(val == index ? null : index)
                                 }}
                               >
                                 <img src={val === index ? check : uncheck} alt="" className="w-full h-full"style={{backgroundColor:'transparent'}} />
                               </div>
                             )}
                             <div className="w-10 h-10 bg-purple-50 rounded-full flex items-center justify-center">
                               <img src={d.account_type == "05" ? userLogo : HomeLogo} alt="Loan Type" className="w-5 h-5" style={{backgroundColor:'transparent'}}/>
                             </div>
                             <div className="flex-grow"style={{backgroundColor:'transparent'}}>
                               <div style={{backgroundColor:'transparent',fontWeight:700,fontFamily:'Archivo',fontSize:15.5,color:'#1F1F1F',lineHeight:'1.2'}}>{d.loan_name}</div>
                               <div className="text-sm text-gray-500" style={{backgroundColor:'transparent'}}>SBI</div>
                             </div>
                             {d.save_on_emi > 0 && !isNaN(d.save_on_emi) && id != 3 && (
                               <div className="text-sm text-green-600 bg-green-50 px-3 py-1 rounded-full whitespace-nowrap" >
                                 Saving ₹{formatNumberWithCommas(d.save_on_emi)}
                               </div>
                             )}
                           </div>
                           <div className="mt-2 text-center p-2" style={{backgroundColor:'transparent',borderTop:'1px solid black',padding:0}}>
                             <Link
                               to={id == "1" || id == "2" ? "/details" : "/topupdetail"}
                               state={{
                                 oldIrr: d.oldIrr,
                                 newIrr: d.new_Irr,
                                 loanAmount: d.loanAmount,
                                 tenure: d.new_tenure,
                                 oldemi: d.old_emi,
                                 newemi: d.new_emi,
                                 saveonemi: d.save_on_emi,
                                 accountType: d.account_type,
                                 subscriber: d.subscriber_name,
                                 savepercentage: d.save_percentage,
                                 Account_Number: d.Account_Number,
                                 maxtopupallowed: d.max_topup_allowed,
                                 loan_tenure: d.loan_tenure,
                                 CurrentBalance: d.Current_Balance,
                               }}
                               className="text-purple-800 text-sm hover:underline"
                               style={{fontFamily:'Archivo',fontWeight:600}}
                             >
                                                            {id == "1" || id == "2" ? "Check Savings":"Monthly Savings"}

                             </Link>
                           </div>
                         </div>
                    )}
                  </>
               
                ))}
            </div>
            
          }
            {
            personalloan &&
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4" style={{backgroundColor:'transparent'}}>
              {data1
                .filter((d) => d.status == 1)
                .map((d, index) => (
                  <>
                    {d.account_type == "05" && (
                         <div key={index} className="bg-white rounded-lg 
                         " style={{borderLeft:'4px solid green'}}
                         >
                           <div className="flex items-center" style={{backgroundColor:'transparent',padding:'2%',paddingTop:'3%',gap:2}}>
                             {(id == "1" || id == "2") && (
                               <div
                                 className="w-6 h-6 cursor-pointer"
                                 style={{backgroundColor:'transparent'}}
                                 onClick={() => {
                                   setLoanAmount(d.loanAmount)
                                   setNewIrr(d.new_Irr)
                                   setOldIrr(d.oldIrr)
                                   setTenure(d.new_tenure)
                                   setAccountNumber(d.Account_Number)
                                   setAccountType(d.account_type)
                                   setVal(val == index ? null : index)
                                 }}
                               >
                                 <img src={val === index ? check : uncheck} alt="" className="w-full h-full"style={{backgroundColor:'transparent'}} />
                               </div>
                             )}
                             <div className="w-10 h-10 bg-purple-50 rounded-full flex items-center justify-center">
                               <img src={d.account_type == "05" ? userLogo : HomeLogo} alt="Loan Type" className="w-5 h-5" style={{backgroundColor:'transparent'}}/>
                             </div>
                             <div className="flex-grow"style={{backgroundColor:'transparent'}}>
                               <div style={{backgroundColor:'transparent',fontWeight:700,fontFamily:'Archivo',fontSize:15.5,color:'#1F1F1F',lineHeight:'1.2'}}>{d.loan_name}</div>
                               <div className="text-sm text-gray-500" style={{backgroundColor:'transparent'}}>SBI</div>
                             </div>
                             {d.save_on_emi > 0 && !isNaN(d.save_on_emi) && id != 3 && (
                               <div className="text-sm text-green-600 bg-green-50 px-3 py-1 rounded-full whitespace-nowrap" >
                                 Saving ₹{formatNumberWithCommas(d.save_on_emi)}
                               </div>
                             )}
                           </div>
                           <div className="mt-2 text-center p-2" style={{backgroundColor:'transparent',borderTop:'1px solid black',padding:0}}>
                             <Link
                               to={id == "1" || id == "2" ? "/details" : "/topupdetail"}
                               state={{
                                 oldIrr: d.oldIrr,
                                 newIrr: d.new_Irr,
                                 loanAmount: d.loanAmount,
                                 tenure: d.new_tenure,
                                 oldemi: d.old_emi,
                                 newemi: d.new_emi,
                                 saveonemi: d.save_on_emi,
                                 accountType: d.account_type,
                                 subscriber: d.subscriber_name,
                                 savepercentage: d.save_percentage,
                                 Account_Number: d.Account_Number,
                                 maxtopupallowed: d.max_topup_allowed,
                                 loan_tenure: d.loan_tenure,
                                 CurrentBalance: d.Current_Balance,
                               }}
                               className="text-purple-800 text-sm hover:underline"
                               style={{fontFamily:'Archivo',fontWeight:600}}
                             >
                              {id == "1" || id == "2" ? "Check Savings":"Monthly Savings"}
                              
                             </Link>
                           </div>
                         </div>
                    )}
                  </>
               
                ))}
            </div>
            
          }
          </div>

          {/* Desktop Illustration - Hidden on mobile */}
          <div className="hidden lg:block lg:w-1/3" style={{backgroundColor:'transparent'}}>
            <div className="text-center" style={{backgroundColor:'transparent'}}>
              <img
                src={Illustration || "/placeholder.svg"}
                alt="Savings Illustration"
                className="mx-auto w-64 h-64 mb-6"
                style={{backgroundColor:'transparent'}}
              />
                 {id==3?null:
                 <>
              <div className="text-base mb-2"style={{backgroundColor:'transparent',textAlign:'center'}}>You can save</div>
              <div className="text-3xl font-semibold text-purple-900 mb-2" style={{backgroundColor:'transparent',textAlign:'center'}}>
                Rs {formatNumberWithCommas(state.Loan.emiSaved)}
              </div>
              </>
}
              <div className="text-sm text-gray-500" style={{backgroundColor:'transparent',textAlign:'center'}}>
                {id == 2
                  ? "by merging your loans into the option(s) mentioned"
                  : id == 1
                    ? "by lowering your EMIs"
                    : null}
              </div>
            </div>
          </div>
        </div>

        {/* Fixed Proceed Button */}
        {(id == "1" || id == "2") && (
          <div className=" p-4 md:static md:bg-transparent md:mt-8"  style={{backgroundColor:'transparent'}}>
            <div className="text-center text-sm text-gray-500 mb-3" style={{backgroundColor:'transparent'}}>1 selected</div>
            <Link
              to={accountType === "05" ? "/personalloanform" : "/homeloanform"}
              state={{
                loanAmount: loanAmount,
                oldIrr: oldirr,
                tenure: tenure,
                newIrr: newirr,
                accountType: accountType,
                Account_Number: accountNumber,
              }}
              style={{backgroundColor:'transparent'}}
            >
              <button
                className={`w-full max-w-md mx-auto py-3 rounded-lg text-white font-semibold ${
                  val === null ? "bg-[#59266D] cursor-not-allowed" : "bg-[#59266D] hover:bg-purple-900"
                } transition-colors block`}
                disabled={val === null}
                style={{opacity: val === null?0.5:1}}
              >
                Proceed
              </button>
            </Link>
          </div>
        )}
      </div>

      {/* Info Modal */}
      {open && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50" style={{backgroundColor:'transparent'}}>
          <div className="bg-white rounded-lg p-6 max-w-md w-full" >
            <div className="flex justify-between items-center mb-4" style={{backgroundColor:'transparent'}}>
              <h2 className="text-xl font-semibold" style={{backgroundColor:'transparent',textAlign:'center'}}>Loan Merging Information</h2>
              <button onClick={() => setOpen(false)} className="text-gray-500 hover:text-gray-700 text-2xl">
                &times;
              </button>
            </div>
            <div style={{backgroundColor:'transparent',textAlign:'center'}}>Merging all your running Personal loan into one loan</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default LowerEmiDetailPage


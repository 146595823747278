import React, { useState } from "react";
import { FiCheckSquare, FiClock, FiZap } from "react-icons/fi";
import { LuPiggyBank } from "react-icons/lu";
import { IoChevronDownOutline, IoSearchOutline } from "react-icons/io5";

const faqCategories = [
  {
    name: "Eligibility",
    icon: (
      <FiCheckSquare
        className="w-6 h-6"
        style={{ backgroundColor: "#ebe8fb" }}
      />
    ),
    questions: [
      {
        q: "Who is eligible for a loan top-up?",
        a: "Generally, individuals with a good credit score and a consistent repayment history on their existing loan are eligible for a top-up. The specific criteria may vary by lender.",
      },
      {
        q: "What documents do I need to apply for a top-up loan?",
        a: "Typically, you'll need proof of identity, address proof, income statements, and details of your existing loan. The exact requirements may vary based on the lender and your specific situation.",
      },
    ],
  },
  {
    name: "Process",
    icon: (
      <FiClock className="w-6 h-6" style={{ backgroundColor: "#ebe8fb" }} />
    ),
    questions: [
      {
        q: "How long does the loan transfer process take?",
        a: "The loan transfer process usually takes between 2-4 weeks, depending on the complexity of your case and the efficiency of the involved banks.",
      },
      {
        q: "Can I transfer my loan to any bank of my choice?",
        a: "You can transfer your loan to any bank that offers balance transfer facilities and approves your application. MINEMI helps you compare and choose the best options available to you.",
      },
    ],
  },
  {
    name: "Savings",
    icon: (
      <LuPiggyBank className="w-6 h-6" style={{ backgroundColor: "#ebe8fb" }} />
    ),
    questions: [
      {
        q: "How much can I save by transferring my loan?",
        a: "Savings can vary greatly depending on your current interest rate, loan amount, and the new offer. Many of our customers save between 10-20% on their EMIs.",
      },
      {
        q: "Will my EMI tenure change if I get a top-up loan?",
        a: "Your EMI tenure may change depending on the terms of your new loan. You can often choose to extend your tenure to lower your EMIs further or keep it the same with a lower interest rate.",
      },
    ],
  },
  {
    name: "Top-Up Loans",
    icon: <FiZap className="w-6 h-6" style={{ backgroundColor: "#ebe8fb" }} />,
    questions: [
      {
        q: "What can I use the top-up loan for?",
        a: "Top-up loans are typically unrestricted. You can use them for home renovations, education, business expansion, or any other personal or professional need.",
      },
      {
        q: "What is the maximum amount I can get as a top-up?",
        a: "The maximum top-up amount depends on factors like your income, credit score, and existing loan balance. It can range from 20% to 100% of your original loan amount.",
      },
    ],
  },
];

function NewQueSection() {
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedQuestions, setExpandedQuestions] = useState([]);

  const toggleQuestion = (questionId) => {
    setExpandedQuestions((prev) =>
      prev.includes(questionId)
        ? prev.filter((id) => id !== questionId)
        : [...prev, questionId]
    );
  };

  const filteredCategories = faqCategories.map((category) => ({
    ...category,
    questions: category.questions.filter(
      (q) =>
        q.q.toLowerCase().includes(searchTerm.toLowerCase()) ||
        q.a.toLowerCase().includes(searchTerm.toLowerCase())
    ),
  }));

  return (
    <section className="py-6 md:py-16 " style={{ backgroundColor: "#f8f8f8" }}>
      <h2 className="text-xl md:text-3xl font-bold bg-transparent text-center mb-3 md:mb-4 text-[#59266d]">
        Got Questions? We've Got Answers!
      </h2>
      <p className="text-xs md:text-lg text-center bg-transparent mb-6 md:mb-8 max-w-2xl mx-auto">
        Find answers to the most common questions about reducing your EMIs and
        accessing top-up loans.
      </p>
      <div
        className="container "
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div className="relative mb-6 md:mb-8" style={{ width: "100%" }}>
          <IoSearchOutline className="absolute  left-2 md:left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4 md:w-5 md:h-5" />
          <input
            type="text"
            placeholder="Search FAQs..."
            className="w-full  md:p-3 pl-8 bg-white md:pl-10 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-[#59266d] text-sm md:text-base"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <div className="container mx-auto bg-transparent px-4">
        <div
          className="space-y-3 bg-transparent md:space-y-6"
          style={{ width: "100%" }}
        >
          {filteredCategories.map((category) => (
            <div
              key={category.name}
              className="bg-[#EBE8FC] rounded-lg p-3 md:p-6 "
            >
              <h3 className="text-lg md:text-xl font-semibold bg-transparent mb-3 md:mb-4 flex items-center">
                {category.icon}
                <span className="ml-2 bg-transparent">{category.name}</span>
              </h3>
              {category.questions.map((q) => (
                <div key={q.q} className="mb-3 md:mb-4 bg-transparent">
                  <button
                    className="w-full text-left p-3 md:p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow flex justify-between items-center"
                    onClick={() => toggleQuestion(q.q)}
                  >
                    <span className="font-medium text-xs bg-transparent md:text-base">
                      {q.q}
                    </span>
                    <IoChevronDownOutline
                      className={`w-4 h-4 md:w-5 md:h-5 bg-transparent transform transition-transform ${
                        expandedQuestions.includes(q.q) ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                  {expandedQuestions.includes(q.q) && (
                    <div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                      className="bg-white mt-2 p-3 md:p-4 rounded-lg"
                    >
                      <p className="text-xs md:text-base bg-white">{q.a}</p>
                      <button className="mt-2 text-xs md:text-base text-[#59266d] bg-transparent hover:underline">
                        Learn More About {category.name}
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="text-center bg-transparent mt-8 md:mt-12">
        <p className="mb-3 md:mb-4  bg-transparent text-center text-sm md:text-base">
          Didn't find your answer?
        </p>
        <a
          href="/login?goal=loan-top-up"
          className=""
          onClick={() => {
            localStorage.setItem("id", 3);
            window.scrollTo(0, 0);
          }}
        >
          <button className="bg-[#59266d] text-white px-4 py-2 md:px-8 md:py-3 rounded-full text-sm md:text-lg font-semibold hover:bg-purple-800 transition-colors change-width">
            Contact Our Experts
          </button>
        </a>
      </div>
    </section>
  );
}

export default NewQueSection;


import { Card, CardContent } from "@mui/material"
import { motion } from "framer-motion"
import { DollarSign, BarChart2, Target, Scale } from "lucide-react"

export default function WhyCheckRate() {
  const features = [
    {
      icon: <DollarSign className="h-5 w-5 text-white" />,
      title: "Pay Less EMI",
      description: "Find the lowest interest rates to reduce your monthly payments.",
    },
    {
      icon: <BarChart2 className="h-5 w-5 text-white" />,
      title: "Negotiate Better",
      description: "Use your pre-qualified rate to bargain for better loan terms.",
    },
    {
      icon: <Target className="h-5 w-5 text-white" />,
      title: "Know Your Credit Standing",
      description: "See how your credit score affects your loan eligibility.",
    },
    {
      icon: <Scale className="h-5 w-5 text-white" />,
      title: "Compare Smartly",
      description: "Check multiple offers to pick the best deal and avoid overpaying.",
    },
  ]

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  }

  const itemVariants = {
    hidden: { y: 5, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  }

  return (
    <section className="py-10 md:py-16 bg-[#ebe8fc]/20">
      <div className="container mx-auto px-6">
        <div className="text-center mb-8">
          <h2 className="text-xl md:text-2xl font-bold text-[#311664] mb-2">Why Check Your Rate Before Applying?</h2>
          <div className="w-16 h-0.5 bg-[#3a868b] mx-auto"></div>
        </div>

        <motion.div
          className="grid grid-cols-2 gap-6 max-w-4xl mx-auto"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-50px" }}
        >
          {features.map((feature, index) => (
            <motion.div key={index} variants={itemVariants}>
              <Card className="h-full border-none shadow-md hover:shadow-lg transition-all duration-300 bg-white overflow-hidden">
                <CardContent className="p-0">
                  <div className="bg-gradient-to-r from-[#311664] to-[#59266d] p-3 flex items-center gap-3">
                    <div className="p-1.5 rounded-full bg-white/10">{feature.icon}</div>
                    <h3 className="text-sm md:text-base font-medium text-white">{feature.title}</h3>
                  </div>
                  <div className="p-4">
                    <p className="text-sm text-[#26292b]">{feature.description}</p>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  )
}


import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography } from "@mui/material";
import TongueOut from "../../../Assets/Images/tongue-out.webp";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import MetadataContext from "../../MetadataContext";

const GoldLoanBalanceTransfer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        "Gold Loan Balance Transfer | Compare Best Banks for Low Interest Rates",
      pageDescription:
        "Discover the best gold loan balance transfer options for 2025. Compare interest rates, benefits, and processes offered by leading Indian banks for transferring your gold loan.",
      pageKeywords:
        "gold loan balance transfer, best gold loan transfer banks, gold loan balance transfer interest rates, gold loan transfer process, low-interest gold loan balance transfer, SBI gold loan transfer, HDFC gold loan transfer, ICICI gold loan transfer, gold loan refinancing, gold loan transfer eligibility, gold loan balance transfer benefits, transfer existing gold loan, gold loan EMI reduction, gold loan interest savings",
      canonicalUrl:
        "https://minemi.ai/loanpage/gold-loan/types-of-gold-loan/gold-loan-balance-transfer",
    });
  }, [setMetadata]);
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/gold-loan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Gold Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Gold Loan Balance Transfer
              </Link>{" "}
            </p>
          </div>

          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontSize: 40,
              color: "#59266D",
              backgroundColor: "#E7E4F9",
              lineHeight: 1,
            }}
          >
            {" "}
            Gold Loan Balance Transfer : Switch to Lower Interest Rates and Save
            More
          </h1>

          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Easily transfer your gold loan balance to another bank and enjoy
            lower interest rates. Compare top banks' offers, save on EMIs, and
            manage your loan better!
          </p>
        </div>

        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
          <h2 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 35 }}>
            Introduction
          </h2>

          <div>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px ",
              }}
            >
              A <strong>Gold Loan Balance Transfer</strong> allows borrowers to
              shift their existing gold loan from one lender to another to
              benefit from more favorable terms, such as lower interest rates,
              enhanced repayment options, or superior customer service.
            </p>
          </div>
          <div></div>

          <div>
            <h2
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontSize: 24,
                marginTop: 25,
              }}
            >
              Key Features of Gold Loan Balance Transfer:
            </h2>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Lower Interest Rates:{" "}
                  </span>
                  Transferring your gold loan can lead to reduced interest
                  rates, decreasing your overall repayment amount.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Flexible Repayment Options:{" "}
                  </span>
                  New lenders may offer more accommodating repayment plans,
                  including interest-only EMIs or bullet repayments.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Enhanced Loan-to-Value (LTV) Ratio:{" "}
                  </span>
                  Some lenders provide a higher LTV ratio, enabling you to
                  borrow more against your gold's value.
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontSize: 24,
                marginTop: 25,
              }}
            >
              Benefits of Opting for a Gold Loan Balance Transfer:
            </h2>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Cost Savings:{" "}
                  </span>
                  Securing a lower interest rate reduces your monthly EMIs and
                  the total interest paid over the loan tenure.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Improved Service Quality:{" "}
                  </span>
                  Switching to a lender with better customer service can enhance
                  your borrowing experience.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Additional Perks:{" "}
                  </span>
                  Some lenders offer benefits like zero processing fees, no
                  prepayment charges, and insurance coverage for pledged gold.
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontSize: 24,
                marginTop: 25,
              }}
            >
              Considerations Before Transferring a Gold Loan:
            </h2>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Processing Fees:{" "}
                  </span>
                  Be aware of any processing fees charged by the new lender,
                  which could offset the savings from a lower interest rate.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Prepayment Penalties:{" "}
                  </span>
                  Check if your current lender imposes penalties for early loan
                  closure.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Eligibility Criteria:{" "}
                  </span>
                  Ensure you meet the new lender's requirements, such as age
                  limits and gold purity standards.
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <h2
              style={{
                fontSize: 22,
                fontFamily: "'Archivo',sans-serif",
                color: "1f1f1f",
                marginTop: 20,
              }}
            >
              Expert Quote:
            </h2>
            <p
              style={{
                fontSize: 16,
                fontFamily: "'Archivo',sans-serif",
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
              Financial advisor Aparna Ramachandra notes, "If you choose to go
              to an NBFC, please remember that while your turnaround time is
              faster, the ability to get the loan is easier, you will end up
              paying maybe a higher rate of interest compared to a bank."
            </p>
          </div>

          {/* minemi tip section */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={TongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Before proceeding with a gold loan balance transfer, use an
                    online EMI calculator to compare the current and proposed
                    EMIs. This will help you assess potential savings and make
                    an informed decision.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          {/* table data */}
          <div id="types-of-bt">
            <div id="eligibility-criteria">
              <div
                className="  "
                style={{
                  marginTop: 25,
                }}
              >
                <h2 style={{ color: "#1F1F1F", fontSize: 24 }}>
                  Comparison of Gold Loan Balance Transfer Features Across Major
                  Lenders:
                </h2>
              </div>
            </div>

            <div
              id="bt-types-table"
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                      }}
                    >
                      Lender
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Interest Rate (p.a.)
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Processing Fee
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Repayment Options
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Additional Benefits
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      IIFL Finance
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Competitive rates
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Minimal
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Flexible, including bullet repayment
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Ouick Disbursement,Secure collateral
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Muthoot Finance
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Competitive rates
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Minimal
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Flexible,including bullet repayment
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Secure storage of gold,quick disbursal
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      SahiBandhu
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Competitive rates
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Minimal
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Flexible tenures
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Secure collateral, quick Disbursement
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 25,
              }}
            >
              <h2
                style={{
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",

                  fontSize: 24,
                }}
              >
                Application Process for a Gold Loan Balance Transfer:
              </h2>
              <div
                style={{
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 500,
                        fontSize: 16,
                      }}
                    >
                      Research and Compare:{" "}
                    </span>
                    Identify lenders offering better terms than your current
                    loan.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 500,
                        fontSize: 16,
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 500,
                        fontSize: 16,
                      }}
                    >
                      Check Eligibility:{" "}
                    </span>
                    Ensure you meet the new lender's criteria.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              style={{
                marginTop: 25,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Conclusion
            </div>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                marginTop: "10px",
                lineHeight: "24px",
                fontWeight: 400,
              }}
            >
              A Gold Loan Top-Up is an efficient way to access additional funds
              using your existing gold loan.By understanding the features,
              benefits, and considerations, you can make an informed decision
              that aligns with your financial needs.
            </p>
          </div>

          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br className="display-none" /> and tailored just for you—apply
                now to see what you qualify for!
              </p>
              <div
                style={{
                  backgroundColor: "transparent",
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                }}
              >
                <a
                  href="https://minemi.ai/personal-loan/check-eligibility"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 50,
                    paddingRight: 50,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                >
                  Check Eligiblity Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoldLoanBalanceTransfer;

import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import "./Question.css";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import logo from ".././assets/Images/mLogo.webp";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#F6F6F6",
  boxShadow: 24,
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 2,
  paddingRight: 2,
  border: 0,
};

const Question4 = () => {
  const location = useLocation();
  const { occupation, monthly, category } = location.state || {};
  const [progress, setProgress] = useState(90);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [token, setToken] = useState("");


  // Load saved value on component mount
  useEffect(() => {
    const savedValue = localStorage.getItem("spendAmount");
    if (savedValue) {
      setValue(savedValue);

    }
  }, []);

  // Save value to localStorage whenever it changes
  useEffect(() => {
    if (value) {
      localStorage.setItem("spendAmount", value);

    }
  }, [value]);

  const PostHandler = () => {
    setLoading(true);
    if (value == "") {
      alert("Please fill field.");
      setLoading(false);
      return;
    } else {
      if (token) {
        const creditScore = localStorage.getItem("creditScore");
        const SpendAmount = localStorage.getItem("spendAmount");
        const Occupation = localStorage.getItem("occupation");
        const MonthlyIncome = localStorage.getItem("monthlyIncome");
        const CreditCardSelection = localStorage.getItem("creditCardSelection");
        const form = new FormData();
        form.append("credit_score", creditScore);
        form.append("Occupation", Occupation);
        form.append("monthly_income", MonthlyIncome);
        form.append("category", CreditCardSelection);
        form.append("roughly_spend_monthly", SpendAmount);
        const options = {
          method: 'POST',
          url: 'https://cardai.minemi.ai/match-credit-cards',
          headers: {
            'content-type': 'multipart/form-data'
          },
          data: form
        };

        axios.request(options).then(function (response) {
          localStorage.setItem("matchdata", JSON.stringify(response.data.matches[0].matches));
          localStorage.setItem("attemept", true);
          // Clear the saved value after successful submission
      
          navigate("/credit-card-recommendations");
          setLoading(false);
        }).catch(function (error) {
          console.error(error);
          setLoading(false);
        });
      } else {
        navigate("/credit-login", { state: { occupation: occupation, category: category, spendmonth: value, month: monthly } });
      }
    }

  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {

      PostHandler();
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);
  }, []);

  const formatNumberWithCommas = (number) => {
    if (number === 0 || number === null || number === undefined) {
      return "Enter spend";
    }
    return new Intl.NumberFormat("en-IN").format(number);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 75,
          left: 0,
          width: "100%",
          height: 5,
          backgroundColor: "#E7E4F9",
          zIndex: 2,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 75,
          left: 0,
          height: 5,
          width: `${progress}%`,
          backgroundColor: progress <= 90 ? "#59266D" : "#E7E4F9",
          transition: "width 0.3s ease",
          zIndex: 2,
        }}
      />
      <div className="min-h-[100vh] w-full pt-4 md:pt-8 px-4 md:px-6">
        <div
          className="container make-center"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 722,
            paddingLeft: "4%",
            paddingRight: "4%",
          }}
        >

          <div style={{ display: "flex", flexDirection: "row", gap: 8 }} onClick={() => navigate("/monthly-income")}>

            <FaArrowLeftLong
              style={{
                alignSelf: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                fontSize: 20,
                color: "#8A8A8A",
              }}
            />
            <p
              style={{
                alignSelf: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                fontSize: 16,
                marginBottom: 0,
                color: "#8A8A8A",
              }}
            >
              Back
            </p>
          </div>
          <div onClick={() => setOpen(true)}>
            <IoClose
              style={{
                alignSelf: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                fontSize: 24,
                color: "#8A8A8A",

                cursor: 'pointer'

              }}
            />
          </div>
        </div>
        <div
          className="container make-width-less mt-4"
          style={{
            textAlign: "center",
            fontFamily: "Archivo",
            color: "#000000",
            fontSize: 24,
            fontWeight: 600,

            width: 470

          }}
        >
          Approximately how much do you plan to spend per month with this card?
        </div>
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 20,
            justifyContent: "center",
          }}
        >

          <div style={{ width: 350, border: '1px solid #846CA6', borderRadius: 8, backgroundColor: '#FFFFFF', height: 40, display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
            <div style={{ alignSelf: 'center', paddingLeft: '4%', backgroundColor: 'white' }}>₹</div>
            <input
              className="input-fiedl-inner"
              value={value != 0 ? formatNumberWithCommas(value) : ""}
              type="text"
              placeholder="Enter spend "
              style={{
                width: 338,
                border: "0px solid white",
                marginLeft: 5,
                marginRight: 8,

                outline: 'none',
                backgroundColor: 'white'
              }}
              onChange={(e) => {
                const rawValue = e.target.value.replace(/,/g, "");
                if (!isNaN(rawValue)) setValue(rawValue);
              }}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div
          className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <button
          disabled={loading}
            onClick={() => PostHandler()}
            style={{
              fontFamily: 'Archivo',
            }}
            className=" button-class next-button"
          >
            {loading ?
              <ColorRing
                visible={loading}
                height="30"
                width="30"
                ariaLabel="color-ring-loading"
                wrapperStyle={{

                  backgroundColor: 'transparent',

                }}
                wrapperClass="color-ring-wrapper"
                className="color-ring-wrapper"
                colors={[
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                ]}
              />
              : "NEXT"}
          </button>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ width: 40, height: 40 }}>
              <img src={logo} style={{ width: '100%', height: '100%' }} alt="" />
            </div>
            <IoClose onClick={() => setOpen(false)}

              style={{
                alignSelf: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                fontSize: 24,

                cursor: 'pointer'
              }}
            />
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: '#59266D', fontSize: 24, fontFamily: 'Archivo', fontWeight: 700, marginTop: 16 }}>
            Wait! Your Card is Just a Step Away!

          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ color: "#8A8A8A", fontSize: 18, fontWeight: 400, fontFamily: 'Archivo' }}>
            Complete the form to receive the best credit card tailored to your needs.
          </Typography>
          <div style={{ backgroundColor: '#59266D', padding: 15, color: '#FFFFFF', borderRadius: 10, textAlign: 'center', marginTop: '4%', fontFamily: 'Archivo', fontWeight: 700, fontSize: 16, cursor: "pointer" }} onClick={() => setOpen(false)}>Keep Going</div>
          <div style={{ backgroundColor: '#F6F6F6', padding: 15, color: '#59266D', borderRadius: 10, textAlign: 'center', marginTop: '4%', fontFamily: 'Archivo', fontWeight: 700, fontSize: 16, border: '1px solid #59266D', cursor: "pointer" }} onClick={() => navigate("/creditcard-recommendation")}>No Thanks</div>


        </Box>
      </Modal>
    </>
  );
};

export default Question4;
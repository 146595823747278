import React from "react";
// import Image from "../../../../Assets/Images/Rectangle.png";
import Image from "../../../../../../Assets/Images/Rectangle-img.png";

import "./ListOfDocuments.css";

const ListOfDocuments = () => {
  return (
    <>
      <div id="list-of-documents" className="bg-transparent">
        <div id="how-it-works-container">
          <div id="documents-content" className="bg-transparent">
            <div className="documents-content-container bg-transparent">
              <div className="content-box w-100 bg-transparent ">
                {" "}
                <div className="documents-text-box bg-transparent">
                  <div className="bg-transparent">
                    <div className="bg-transparent">
                      <h4 className="bg-transparent" id="documents-heading">
                        How it Works
                      </h4>
                    </div>
                    <div className="text-box bg-transparent">
                      <div className="d-flex flex-row gap-3 gap-md-5 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent m-0">
                          Enter your basic details and give consent to fetch
                          your credit score securely.
                        </p>
                      </div>
                      <div className="d-flex flex-row gap-3 gap-md-5 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent m-0">
                          Access your score instantly and understand what it
                          means for your finances.
                        </p>
                      </div>
                      <div className="d-flex flex-row gap-3 gap-md-5 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent m-0">
                          Access your score instantly and understand what it
                          means for your finances.
                        </p>
                      </div>
                      <div className="d-flex flex-row gap-3 gap-md-5 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent m-0">
                          See loans and interest rates designed for your credit
                          profiles.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="documents-image-box  d-md-block bg-transparent">
                  <div className="bg-transparent">
                    <img
                      className="documents-image bg-transparent"
                      src={Image}
                      alt="docuents-image"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListOfDocuments;

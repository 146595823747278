import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaRupeeSign } from "react-icons/fa";
import { FaPercent } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ColorRing } from "react-loader-spinner";
import ReactSpeedometer from "react-d3-speedometer";
import { useLocation, useNavigate } from "react-router-dom";
import "./Eligibility.css";
import axios from "axios";

const Eligibility = () => {
  const navigate = useNavigate();

  const fields = [
    {
      label: "Loan amount",
      icon: <FaRupeeSign color="#59266D" className="icons" />,
      placeholder: 100000,
      type: "text",
      name: "amount",
      text: "( In Rupees )",
    },
    {
      label: "Loan Tenure",
      icon: <FaRegCalendarAlt color="#59266D" className="icons" />,
      placeholder: 12,
      type: "number",
      name: "tenure",
      text: "( In Months )",
    },
    {
      label: "Interest Rate",
      icon: <FaPercent color="#59266D" className="icons" />,
      placeholder: 12,
      type: "text",
      name: "interest",
      text: "( In Percent )",
    },
    {
      label: "EMI",
      icon: <FaRupeeSign color="#59266D" className="icons" />,
      placeholder: 1000,
      type: "text",
      name: "emi",
      text: "( Per Months )",
    },
  ];

  const [reportDate, setReportDate] = useState("");
  const [maxTenure, setMaxTenure] = useState(0);
  const [maxLoan, setMaxLoan] = useState(0);
  const [score, setScore] = useState(0);
  const [amount, setAmount] = useState("");
  const [tenure, setTenure] = useState("");
  const [username, setUsername] = useState("");
  const [interest, setInterest] = useState("");
  const [emi, setEmi] = useState("");
  const [formId, seFormId] = useState(null);
  const [maxValue, setMaxValue] = useState(900);
  const [cibilCondition, setCibilCondition] = useState(null);
  const [cibilMsg, setCibilMsg] = useState(null);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [formShow, setFormShow] = useState(false);

  // Manually format the date as MM/DD/YYYY
  useEffect(() => {
    window.scrollTo(0, 0);

    const currentDate = new Date();

    const formattedDate =
      `${currentDate.getDate().toString().padStart(2, "0")}/` +
      `${(currentDate.getMonth() + 1).toString().padStart(2, "0")}/` +
      `${currentDate.getFullYear()}`;
    setReportDate(formattedDate);

    const eligibleData = localStorage.getItem("eligibleData");
    const isFid = sessionStorage.getItem("fId");
    const uName = localStorage.getItem("username");
    uName !== null && setUsername(uName);

    if (eligibleData === null || isFid === null) {
      navigate("/", { state: { msg: "Unauthorized Access!" } });
    } else {
      seFormId(Number(isFid));
      const datas = JSON.parse(eligibleData);
      const loanAmt = datas.data.eligibleLoan.amount;
      const findType = datas.data.eligibleLoan.savingopprtunity.formula_type;
      const findTenure = findType === "hl" ? 360 : 84;
      const irate = datas.data.eligibleLoan.savingopprtunity.irr;
      const scoreMax =
        datas.data.eligibleLoan.savingopprtunity.credit_score_max;

      if (
        datas.data.status === 2 ||
        datas.status === 0 ||
        datas.data.eligibleLoan.amount < 0
      ) {
        setFormShow(true);
      }

      setScore(Number(datas.data.cbil.score));
      setMaxValue(scoreMax);
      setAmount(loanAmt);
      setInterest(irate);
      setTenure(findTenure);
      setMaxLoan(loanAmt);
      setMaxTenure(findTenure);
      setCibilCondition(datas.data.cibilMsg.condition);
      setCibilMsg(datas.data.cibilMsg.message);

      const P = parseFloat(loanAmt);
      const n = parseInt(findTenure);
      const annualRate = parseFloat(irate);

      // Calculate monthly interest rate
      const r = annualRate / 12 / 100;

      // Check for zero or negative interest rate
      if (r <= 0 || n <= 0 || P <= 0) {
        setAmount(0);
        return;
      }

      // EMI calculation using formula
      const mEmi = (P * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
      setEmi(Number(mEmi.toFixed(0)));
    }
  }, []);

  const handleChange = (event) => {
    const { value, name } = event.target;
    if (name === "emi") {
      const plainNumber = parseFloat(value.replace(/,/g, ""));
      plainNumber >= 0 && setEmi(plainNumber);
      value === "" && setEmi("");
    }

    if (name === "amount") {
      const plainNumber = parseFloat(value.replace(/,/g, ""));
      plainNumber >= 0 && plainNumber <= maxLoan && setAmount(plainNumber);
      value === "" && setAmount("");
    }

    if (name === "tenure" && value >= 0 && value <= maxTenure) {
      setTenure(value);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (amount || tenure || interest || emi) {
      const formData = new FormData();
      formData.append("amount", amount);
      formData.append("tenure", tenure);
      formData.append("iRate", interest);
      formData.append("emi", emi);
      formData.append("formId", formId);
      setLoader(true);


      const response = await axios.post(
       `${process.env.REACT_APP_URL}/applyLoan`,
        formData
      );


      if (response.data.status) {
        setShow(true);
      }
      setLoader(false);
    }
  };

  return (
    <>
      <div id="personalLoan-eligibility" className="bg-transparent">
        <div className="titleCon ">
          <p className="title m-0 p-0">Personal Loan Eligibility Report</p>
        </div>

        <div className="contentCon pb-5">
          <div className="row d-flex justify-content-between bg-transparent">
            <div
              style={{ backgroundColor: "transparent" }}
              className={
                formShow
                  ? "col-12 col-md-12 mb-5 mb-md-0"
                  : "col-12 col-md-5 mb-5 mb-md-0"
              }
            >
              <p className="cus-name p-0 mx-0 text-capitalize bg-transparent">
                Hey {username}
              </p>
              <p className="credit-title p-0 m-0 bg-transparent">
                Here's your credit Score.....
              </p>
              <div className="credit-score-con bg-transparent">
                <div
                  id={
                    formShow
                      ? "speedometer-con-manage"
                      : "personal-speedometer-con"
                  }
                >
                  <div
                    id="personal_loan_speedometer"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "transparent",
                    }}
                  >
                    <ReactSpeedometer
                      fluidWidth={true}
                      value={score}
                      maxValue={maxValue}
                      minValue={0}
                      textColor="#59266D"
                      needleColor="#431d76"
                      segmentColors={[
                        "rgb(132, 108, 166)",
                        "rgb(116, 88, 156)",
                        "rgb(89, 38, 109)",
                        "rgb(52, 22, 100)",
                        "rgba(2, 90, 0, 1)",
                      ]}
                      customSegmentStops={[0, 500, 700, 800, maxValue]}
                      needleHeightRatio={0.7}
                      currentValueText={`${score}`}
                      valueTextFontSize="22px"
                    />
                  </div>
                </div>

                <div
                  style={{ backgroundColor: "transparent" }}
                  className={
                    formShow
                      ? "score-con d-flex justify-content-between"
                      : "d-flex justify-content-between"
                  }
                >
                  <p
                    style={{ backgroundColor: "transparent" }}
                    className={
                      formShow
                        ? "poor-score2 ms-2 mb-0"
                        : "poor-score ms-2 mb-0"
                    }
                  >
                    Poor
                  </p>
                  <p
                    style={{ backgroundColor: "transparent" }}
                    className={
                      formShow
                        ? "good-score2 me-2 mb-0"
                        : "good-score me-2 mb-0"
                    }
                  >
                    Good
                  </p>
                </div>

                <div className="d-flex justify-content-center cmp-logo-con bg-transparent">
                  <img
                    src="images/experian.png"
                    className="cmp-logo bg-transparent"
                  />
                </div>

                <div className="cibil-con bg-transparent">
                  <p
                    style={{ backgroundColor: "transparent" }}
                    className={
                      formShow ? "cibil-cond mb-2" : "cibil-condition mb-2"
                    }
                  >
                    {cibilCondition}
                  </p>
                  <p
                    style={{ backgroundColor: "transparent" }}
                    className={formShow ? "cibil-msg2 mb-0" : "cibil-msg mb-0"}
                  >
                    {cibilMsg}
                  </p>
                </div>

                <div className="report-con bg-transparent">
                  <p className="reportText bg-transparent">
                    Report issue with the result{" "}
                    <a href="https://consumer.experian.in/ECV-OLN/view/angular/#/">
                      Here
                    </a>
                  </p>
                  <p className="reportUpdate bg-transparent">
                    last update on {reportDate}
                  </p>
                </div>
              </div>
            </div>

            {!formShow && (
              <div className="col-12 col-md-6 p-0 bg-transparent">
                <p className="based-score m-0 p-0 bg-transparent">
                  Based on your credit score
                </p>
                <div className="form-con ">
                  <p className="form-title m-0 p-0">YOU ARE ELIGIBLE FOR....</p>
                  <form onSubmit={handleSubmit} className="bg-transparent">
                    <div className="pe-0 pe-xl-4 bg-transparent">
                      {fields.map((item, index) => (
                        <div key={index} className="bg-transparent">
                          <div className="d-flex justify-content-between align-items-center mt-4 bg-transparent">
                            <p className="label p-0 m-0 bg-transparent">
                              {item.label}
                            </p>
                            <div className="input-con bg-transparent">
                              <InputGroup className="d-flex bg-transparent">
                                <InputGroup.Text className="input-icon px-3 ">
                                  {item.icon}
                                </InputGroup.Text>
                                <Form.Control
                                  // required
                                  placeholder={item.placeholder}
                                  className="input-field"
                                  type={item.type}
                                  name={item.name}
                                  value={
                                    (index === 0 &&
                                      new Intl.NumberFormat("en-IN").format(
                                        amount
                                      )) ||
                                    (index === 1 && tenure) ||
                                    (index === 2 && interest) ||
                                    (index === 3 &&
                                      new Intl.NumberFormat("en-IN").format(
                                        emi
                                      ))
                                  }
                                  onChange={(event) => handleChange(event)}
                                />
                              </InputGroup>
                         
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="d-flex justify-content-center btn-con bg-transparent">
                      {!loader ? (
                        <Button className="apply-btn" type="submit">
                          Apply Now
                        </Button>
                      ) : (
                        <Button type="button" className="apply-btn">
                          <ColorRing
                            visible={loader}
                            height="30"
                            width="30"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{ backgroundColor: "transparent" }}
                            wrapperClass="color-ring-wrapper"
                            colors={[
                              "#ffffff",
                              "#ffffff",
                              "#ffffff",
                              "#ffffff",
                              "#ffffff",
                              "#ffffff",
                            ]}
                          />
                        </Button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>

        <Modal
          className="bg-transparent"
          show={show}
          onHide={() => setShow(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            Your responses have been recorded, we'll get back to you.
          </Modal.Body>
          <Modal.Footer>
            <Button id="modalCloseBtn" onClick={() => setShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Eligibility;

import React, { useState, useEffect, useContext } from "react";
import money from "../../Assets/Images/money.webp";
import creditScore from "../../Assets/Images/credit score.webp";
import Vector from "../../Assets/Images/Vector (5).webp";
import Group from "../../Assets/Images/Group (3).webp";
import Bill from "../../Assets/Images/Bill Get.webp";
import Ratio from "../../Assets/Images/Ratio.webp";
import "./Personalloan.css";
import Grid from "@mui/material/Grid";
import Item from "@mui/material/Paper"; // Assuming Item is a Paper component
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";

import MetadataContext from "../MetadataContext";

import { useMediaQuery } from "@mui/material";

const PersonalLoan = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const isMediumScreen = useMediaQuery("(max-width: 756px) ");
  const isVerySmallScreen = useMediaQuery("(max-width:400px)");

  const [newsData, setNewsData] = useState([]);
  const [manageData, setManageData] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const blogs = [
    {
      id: 1,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/seven_steps_pl_2-1737196935581.webp",
      title: "How to get a personal loan in 7 steps",
      writer: "Vikalp Shukla",
      description:
        "Securing a personal loan begins with assessing your financial profile and finding the loan offer that best suits your needs.",
      link: "https://minemi.ai/calculators/seven-steps-personal-loan",
    },

    {
      id: 2,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/WeddingLoan-1736254541368.webp",
      title: "Discover the Ultimate Guide to Wedding Loans",
      writer: "Aditi Malhotra",
      description:
        "Wedding Loan Guide | Compare Personal Loans for Wedding Expenses in India",
      link: "https://minemi.ai/calculators/discover-the-ultimate-guide-to-wedding-loans",
    },
    {
      id: 3,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/PLBL-1736254699409.webp",
      title:
        "Personal Loan Balance Transfer with MinEMI: Save More on Your Loan",
      writer: "Aditi Malhotra",
      description:
        "See how much you could save by transferring debt from a high-interest loan or credit card to a more favorable loan.",
      link: "https://minemi.ai/calculators/financial-insights-and-tools/personal-loan-balance-transfer-blog",
    },
    {
      id: 4,
      image:
        "https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Personal-Loan-Prepayment-and-Foreclosure-1737197730395.webp",
      title: "Personal Loan Prepayment and Foreclosure",
      writer: "Vikalp Shukla",
      description:
        "Personal loans provide quick access to funds for various needs, from medical emergencies to home renovations. However, managing these loans eectively can save you signicant money in the long run. Prepayment and foreclosure are two key strategies to reduce the interest burden.",
      naviLink:
        "/loanpage/personalloan/personal-loan-prepayment-and-foreclosure",
    },
  ];

  const fetchLatestNews = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/news`,
    };

    axios
      .request(options)
      .then(function (response) {
        setNewsData(response.data.data.latestnews);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchLatestNews();
  }, []);

  const fetchManageLoans = async () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/manageloan`,
    };

    axios
      .request(options)
      .then(function (response) {
        setManageData(response.data.data.manageloan);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchManageLoans();
  }, []);

  const homeLoanItems = manageData.filter((d) => d.category === "Home Loan");
  const visibleItems = showAll
    ? homeLoanItems.slice(1)
    : homeLoanItems.slice(1, 4);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Get Quick Loans Instantly Online | Minemi.ai",
      pageDescription:
        "Need funds fast? Minemi.ai offers quick loans with simple applications and instant approvals. Secure the money you need when you need it most",
      pageKeywords:
        "quick loans, secured loan, personal loans, financial needs, flexible loans, quick loan approval, competitive loan rates, tailored loan solutions, personal financing, loan options, online loans, customized loans",
      canonicalUrl: "https://minemi.ai/loanpage/personalloan",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 48,
            paddingBottom: 80,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                className=" custom-link bg-transparent archivo "
                style={{
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Personal Loan
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "46px",
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Personal Loans: Tailored for Your Financial Needs
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 500,
              fontSize: 24,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "30px",
            }}
          >
            Compare personal loans, check eligibility, and explore various loan
            types to make informed decisions with Minemi.
          </p>
        </div>

        <div
          className="make-wrap"
          style={{
            marginTop: 56,
            marginLeft: "4%",
            marginRight: "4%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 16,
          }}
        >
          <div
            onClick={() =>
              navigate("/loanpage/personalloan/loan-eligibilty-criteria")
            }
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 10,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              // width: 290,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                backgroundColor: "transparent",
                fontSize: 16,
              }}
            >
              Personal Loan Check Eligibility
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              Borrowing power by credit score and income.
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src={money}
                  alt="Personal Loan Check Eligibility"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  backgroundColor: "transparent",
                  marginTop: 8,
                }}
              >
                Check loan eligibility instantly with no credit score impact.
              </p>
            </p>
          </div>
          <div
            onClick={() => navigate("/loanpage/personalloan/personal-topup")}
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 12,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              width: 290,
              cursor: "pointer",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                backgroundColor: "transparent",
                fontSize: 16,
              }}
            >
              Personal Loan Top-Up
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              Get additional funds over your existing loan.
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src={Ratio}
                  alt="Personal Loan Top-Up"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 14,
              }}
            >
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  backgroundColor: "transparent",
                  marginTop: 8,
                }}
              >
                Personal loan top-ups boost borrowing capacity.
              </p>
            </p>
          </div>
          <div
            onClick={() => navigate("/loanpage/personalloan/badcredit")}
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 12,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              cursor: "pointer",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                backgroundColor: "transparent",
                fontSize: 16,
              }}
            >
              Compare Interest Rates
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 14,
              }}
            >
              Compare personal loan rates from top banks.
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src={Group}
                  alt="Compare Interest Rates"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 14,
              }}
            >
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  backgroundColor: "transparent",
                  marginTop: 8,
                }}
              >
                Find low rates and choose a budget-friendly loan.
              </p>
            </p>
          </div>
          <div
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 12,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              cursor: "pointer",
              // width: 290,
            }}
          >
            <a href="https://minemi.ai/calculators/emi-calculators/personal-loan-emi-calculator">
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  color: "#1F1F1F",
                  backgroundColor: "transparent",
                  fontSize: 16,
                }}
              >
                Personal Loan EMI Calculator
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  backgroundColor: "transparent",
                  marginTop: 8,
                  fontSize: 13,
                }}
              >
                Calculate your monthly EMIs with ease.
              </p>
              <div
                style={{
                  backgroundColor: "transparent",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 32,
                  marginBottom: 19,
                }}
              >
                <div
                  style={{
                    width: 56,
                    height: 56,
                    backgroundColor: "transparent",
                  }}
                >
                  <img
                    src={creditScore}
                    alt="Personal Loan EMI Calculator"
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "transparent",
                    }}
                  />
                </div>
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#846CA6",
                  backgroundColor: "transparent",
                  marginTop: 8,
                  fontSize: 14,
                }}
              >
                {" "}
                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Archivo",
                    fontWeight: 400,
                    color: "#8A8A8A",
                    backgroundColor: "transparent",
                    marginTop: 8,
                  }}
                >
                  Estimate monthly repayments using our EMI calculator.
                </p>
              </p>
            </a>
          </div>
          <div
            onClick={() => navigate("/best-personal-loan-offer")}
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 12,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              cursor: "pointer",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                backgroundColor: "transparent",
                fontSize: 16,
              }}
            >
              Compare Personal Loans
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              {" "}
              Find and compare the best loan options.
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src={Vector}
                  alt="Types of Personal Loans"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  backgroundColor: "transparent",
                  marginTop: 8,
                }}
              >
                Compare interest rates, terms, and benefits to choose the right
                loan.
              </p>
            </p>
          </div>
          <div
            onClick={() => navigate("/loanpage/personalloan/loan-tracking")}
            className="width-adjust"
            style={{
              backgroundColor: "#EDEBEE",
              padding: 12,
              borderRadius: 12,
              border: "1px solid #E7E4F9",
              cursor: "pointer",
              // width: 290,
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 500,
                color: "#1F1F1F",
                backgroundColor: "transparent",
                fontSize: 16,
              }}
            >
              Loan Status Tracking
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#8A8A8A",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              Track the status of your loan application.
            </p>
            <div
              style={{
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
                marginTop: 32,
                marginBottom: 19,
                marginBottom: 19,
              }}
            >
              <div
                style={{
                  width: 56,
                  height: 56,
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src={Bill}
                  alt="Loan Status Tracking"
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                  }}
                />
              </div>
            </div>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                color: "#846CA6",
                backgroundColor: "transparent",
                marginTop: 8,
                fontSize: 13,
              }}
            >
              {" "}
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "Archivo",
                  fontWeight: 400,
                  color: "#8A8A8A",
                  backgroundColor: "transparent",
                  marginTop: 8,
                }}
              >
                Real-time loan application tracking made easy.
              </p>
            </p>
          </div>
        </div>
        <div
          style={{
            maginTop: 64,
            marginLeft: "4%",
            marginRight: "4%",
            marginTop: 32,
          }}
        >
          <h2
            style={{
              color: "#59266D",
              fontWeight: 600,
              fontFamily: "Archivo",
              fontSize: 28,
              lineHeight: 1.2,
            }}
          >
            Personal Loan Application Guide
          </h2>
        </div>
        <div
          style={{ marginLeft: "4%", marginRight: "4%", marginTop: 32 }}
          className="loan-list-container px-0"
        >
          {blogs?.map((blog) => (
            <div
              className="loan-container"
              onClick={() => navigate(blog.naviLink)}
              key={blog.id}
            >
              <a href={blog.link}>
                <img
                  src={blog.image}
                  alt={blog.heading}
                  loading="lazy"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  className="loan-image d-none d-md-block"
                />
                <h3
                  className="loan-heading"
                  style={{
                    fontFamily: "Archivo",
                    fontWeight: 600,
                    fontSize: 18,
                    display: "-webkit-box",
                    WebkitLineClamp: 2, // Limit to 2 lines
                    WebkitBoxOrient: "vertical", // Required for webkit-line-clamp
                    overflow: "hidden", // Hide overflowing text
                    textOverflow: "ellipsis", // Add ellipsis
                  }}
                >
                  {blog.title}
                </h3>
                <p
                  style={{
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    color: "#8a8a8a",
                    fontSize: 14,
                  }}
                  className="mt-2 mb-1"
                >
                  by {blog.writer}
                </p>
                <div
                  className="loan-subheading"
                  dangerouslySetInnerHTML={{
                    __html: blog.description,
                  }}
                  style={{
                    fontFamily: "Archivo",
                    fontWeight: 400,
                    fontSize: 18,
                    color: "#B1B1B1",
                    display: "-webkit-box",
                    WebkitLineClamp: 2, // Limit to 2 lines
                    WebkitBoxOrient: "vertical", // Required for webkit-line-clamp
                    overflow: "hidden", // Hide overflowing text
                    textOverflow: "ellipsis", // Add ellipsis
                  }}
                />
              </a>
            </div>
          ))}
        </div>
        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 32 }}>
          <h3
            style={{
              color: "#59266D",
              fontWeight: 600,
              fontFamily: "Archivo",
              fontSize: 28,
              lineHeight: 1,
            }}
          >
            Manage your loan
          </h3>
          <div style={{ marginTop: 40, marginBottom: 32 }}>
            <Grid container spacing={5}>
              {manageData
                .filter((d) => d.category === "Personal Loan")
                .slice(0, 1) // Adjust slice range based on your needs
                .map((d) => {
                  return (
                    <Grid item xs={12} md={6}>
                      <Item
                        style={{
                          border: 0,
                          boxShadow: "0px 0px",
                          backgroundColor: "#f8f8f8",
                        }}
                      >
                        <Link
                          to={`/loanpage/personalloan/manageloan_detail/${d.mslug}`}
                          state={{
                            mslug: d?.mslug,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 8,
                            }}
                          >
                            <div style={{ height: "auto" }}>
                              <img
                                src={d.image}
                                alt={d?.title}
                                loading="lazy"
                                style={{
                                  width: "100%",
                                  height: isVerySmallScreen ? "100%" : "400px",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 8,
                              }}
                            >
                              <p
                                className="manage_latest_link m-0"
                                style={{ fontSize: 18, fontWeight: 400 }}
                              >
                                {d?.title}
                              </p>
                              <p
                                style={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "grey",
                                }}
                              >
                                by {d?.author}
                              </p>

                              <p
                                style={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  lineHeight: 1.3,
                                  color: "grey",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    d?.description && d.description.length > 100
                                      ? `${d.description.slice(0, 100)}...`
                                      : d?.description || "",
                                }}
                              ></p>
                            </div>
                          </div>
                        </Link>
                      </Item>
                    </Grid>
                  );
                })}

              <Grid item xs={12} md={6}>
                <Item
                  style={{
                    border: 0,
                    boxShadow: "0px 0px",
                    display: "flex",
                    flexDirection: "column",
                    gap: 32,
                    backgroundColor: "#f8f8f8",
                  }}
                >
                  {visibleItems.map((d) => (
                    <div key={d.id}>
                      <Link
                        to={`/loanpage/personalloan/manageloan_detail/${d.mslug}`}
                        state={{
                          mslug: d.mslug,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 12,
                          }}
                        >
                          <div
                            style={{
                              minWidth: isVerySmallScreen
                                ? 80
                                : isMediumScreen
                                ? 120
                                : 150,
                              maxWidth: 190,
                            }}
                          >
                            <img
                              src={d.image}
                              alt={d.title}
                              loading="lazy"
                              style={{
                                width: "100%",
                                height: isVerySmallScreen
                                  ? "50px"
                                  : isMediumScreen
                                  ? "80px"
                                  : "110px",
                                objectFit: "cover",
                                borderRadius: "5px",
                                objectPosition: "center",
                              }}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 3,
                            }}
                          >
                            <p
                              className="manage_latest_link m-0 pt-0 pt-sm-3"
                              style={{ fontSize: 18, fontWeight: 400 }}
                            >
                              {d.title}
                            </p>
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "grey",
                              }}
                            >
                              by {d.author}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                  {homeLoanItems.length > 4 && (
                    <button
                      onClick={() => {
                        navigate("/loanpage/personalloan/manageloan_detail");
                      }}
                      style={{
                        alignSelf: "center",
                        padding: "8px 16px",
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#007BFF",
                        backgroundColor: "transparent",
                        border: "1px solid #007BFF",
                        borderRadius: 5,
                        cursor: "pointer",
                      }}
                    >
                      See More
                    </button>
                  )}
                </Item>
              </Grid>
            </Grid>
          </div>
        </div>
        <div
          style={{
            marginLeft: "4%",
            marginRight: "4%",
            marginTop: 40,
            paddingBottom: 40,
          }}
        >
          <h3
            style={{
              color: "#59266D",
              fontWeight: 600,
              fontFamily: "Archivo",
              fontSize: 28,
              lineHeight: 1,
            }}
          >
            The Latest
          </h3>
          <Grid container spacing={4} style={{ marginTop: "2%" }}>
            {newsData
              .filter((item) => item.category === "Personal Loan")
              .map((item) => (
                <Grid item xs={12} sm={6} key={item.id}>
                  {item.category === "Personal Loan" && (
                    <Item
                      style={{
                        boxShadow: "0px 0px",
                        marginBottom: 10,
                        backgroundColor: "#f8f8f8",
                      }}
                    >
                      <Link
                        to={`/loanpage/personalloan/news_detail/${item.nslug}`}
                        state={{
                          nslug: item.nslug,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 12,
                            alignItems: "start",
                          }}
                        >
                          <div
                            style={{
                              // width: "100%",
                              minWidth: isVerySmallScreen
                                ? 80
                                : isMediumScreen
                                ? 120
                                : 150,
                              maxWidth: 190,
                            }}
                          >
                            <img
                              src={item.image}
                              alt={item.title}
                              loading="lazy"
                              style={{
                                width: "100%",
                                // maxWidth: "250px",
                                height: isVerySmallScreen
                                  ? "50px"
                                  : isMediumScreen
                                  ? "80px"
                                  : "110px",
                                objectFit: "cover",

                                borderRadius: "5px",
                                objectPosition: "center",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 5,
                              alignSelf: "center",
                            }}
                          >
                            <p
                              style={{ fontSize: 18, fontWeight: 400 }}
                              className="manage_latest_link m-0"
                            >
                              {item.title}
                            </p>
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "grey",
                              }}
                              className="m-0"
                            >
                              by {item.author}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </Item>
                  )}
                </Grid>
              ))}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default PersonalLoan;

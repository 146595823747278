import React from "react";
import "./HowItWorks.css";
import Operator from "../../Assets/Images/Operator.webp";
import Timeismoney from "../../Assets/Images/Timeismoney.webp";
import Virtual from "../../Assets/Images/Virtual.webp";

const HowItWorks = () => {
  return (
    <div className="margin-remove-sm"
      style={{
        backgroundColor: "rgba(89, 38, 109, 1)",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 40,
      }}
    >
      <h3 className="HowItWorksHeading">Why choose MinEMI?</h3>
      <div className="HowItWorksContainer">
        <div className="HowItWorksSection">
          <div className="Operator">
            <img src={Operator} alt="Negotiatior" loading="lazy" />
          </div>
          <div className="HowItWorksSectionHeading">
            Your loan negotiator & advisor
          </div>
          <div className="HowItWorksSectionSubHeading">
            We know banking in and out and have ways to get you the best
            deals.😉
          </div>
          <div></div>
        </div>

        <div className="HowItWorksSection">
          <div className="Operator">
            <img src={Timeismoney} alt="Beneficial" loading="lazy" />
          </div>
          <div className="HowItWorksSectionHeading">Time is Money</div>
          <div className="HowItWorksSectionSubHeading">
            We understand that money is beneficial when given on time. We make
            that possible.
          </div>
        </div>

        <div className="HowItWorksSection">
          <div className="Operator">
            <img src={Virtual} alt="Completely Digital" loading="lazy" />
          </div>
          <div className="HowItWorksSectionHeading">Completely Digital</div>
          <div className="HowItWorksSectionSubHeading">
            We are safe, and secure and prefer APIs over cold calling.
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;

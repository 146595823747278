import React, { useContext, useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import logo from ".././assets/Images/mLogo.webp";
import { Typography } from "@mui/material";
import MetadataContext from "../../MetadataContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "#F6F6F6",
  boxShadow: 24,
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 2,
  paddingRight: 2,
  border: 0,
};

const ExisitingLowerEmi = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { category } = location.state || {};

  const [progress, setProgress] = useState(50);
  const [active, setActive] = useState(
    () => parseInt(localStorage.getItem("active")) || 0
  );
  const [value, setValue] = useState(localStorage.getItem("value") || "");
  const [emiAmount, setEmiAmount] = useState(
    localStorage.getItem("emiAmount") || ""
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    localStorage.setItem("active", active);
    localStorage.setItem("value", value);
    localStorage.setItem("emiAmount", emiAmount);
  }, [active, value, emiAmount]);

  useEffect(() => {
    setActive(0);
    setValue("Yes");
    setEmiAmount("");
    localStorage.setItem("active", 0);
    localStorage.setItem("value", "Yes");
    localStorage.setItem("emiAmount", "");
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Select Existing EMI - Minemi ",
      pageDescription:
        "Select your existing EMI details with Minemi  and explore refinancing options.",
      pageKeywords:
        "select existing EMI, EMI refinancing, loan refinancing options, EMI management, loan repayment options, EMI calculator, loan restructuring, EMI tracking, loan consolidation options",

      canonicalUrl: "https://minemi.ai/select-existing-emi",
    });
  }, [setMetadata]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div className="progress-bar-background" />
        <div className="progress-bar" style={{ width: `${progress}%` }} />
        <div className="min-h-[100vh] w-full pt-4 md:pt-8 px-4 md:px-6">
          <div
            className="container make-center"
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 4%",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 8,
                cursor: "pointer",
              }}
              onClick={() => navigate("/select-assets")}
            >
              <FaArrowLeftLong style={{ fontSize: 20, color: "#8A8A8A" }} />
              <p style={{ marginLeft: 8, fontSize: 16, color: "#8A8A8A" }}>
                Back
              </p>
            </div>
            <IoClose
              style={{ fontSize: 24, color: "#8A8A8A", cursor: "pointer" }}
              onClick={() => setOpen(true)}
            />
          </div>

          <div className="mx-auto mt-8 md:mt-12">
            <h1 className="text-center text-2xl md:text-3xl font-semibold text-black mb-8">
              Do you have existing EMIs or loan payments?
            </h1>

            <div className="flex flex-col sm:flex-row justify-center gap-4 md:gap-5 px-4">
              <button
                onClick={() => {
                  setActive(0);
                  setValue("Yes");
                  setEmiAmount("");
                }}
                className={` px-6 w-full sm:w-48 rounded-lg shadow-md transition-all ${
                  active === 0
                    ? "bg-[#E7E4F9] border-2 border-[#59266D]"
                    : "bg-white hover:bg-gray-50"
                }`}
                style={{ padding: "13px " }}
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setActive(1);
                  setValue("No");
                  setEmiAmount("");
                }}
                className={`px-6 w-full sm:w-48 rounded-lg shadow-md transition-all ${
                  active === 1
                    ? "bg-[#E7E4F9] border-2 border-[#59266D]"
                    : "bg-white hover:bg-gray-50"
                }`}
                style={{ padding: "13px " }}
              >
                No
              </button>
            </div>

            <div className="mt-8 flex justify-center px-4">
              <button
                style={{ fontFamily: "Archivo" }}
                onClick={() => {
                  if (value === "Yes") {
                    navigate("/pay-monthly");
                  } else {
                    navigate("/select-assets-or-investments-to-use", {
                      state: { occupation: value, category, emiAmount },
                    });
                  }
                }}
                className="next-button"
              >
                NEXT
              </button>
            </div>
          </div>
        </div>

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: 40, height: 40 }}>
                <img
                  src={logo}
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </div>
              <IoClose
                onClick={() => setOpen(false)}
                style={{
                  alignSelf: "center",
                  fontWeight: 400,
                  fontSize: 24,
                  cursor: "pointer",
                }}
              />
            </div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#59266D",
                fontSize: 24,
                fontWeight: 700,
                marginTop: 16,
              }}
            >
              Wait ! your loan recommendation is just a step away !
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              style={{
                color: "#8A8A8A",
                fontSize: 18,
                fontWeight: 400,
                fontFamily: "Archivo",
              }}
            >
              Complete the form to receive the best credit card tailored to your
              needs.
            </Typography>
            <div
              style={{
                backgroundColor: "#59266D",
                padding: 15,
                color: "#FFFFFF",
                borderRadius: 10,
                textAlign: "center",
                marginTop: "4%",
                fontFamily: "Archivo",
                fontWeight: 700,
                fontSize: 16,
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            >
              Keep Going
            </div>
            <div
              style={{
                backgroundColor: "#F6F6F6",
                padding: 15,
                color: "#59266D",
                borderRadius: 10,
                textAlign: "center",
                marginTop: "4%",
                fontFamily: "Archivo",
                fontWeight: 700,
                fontSize: 16,
                border: "1px solid #59266D",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              No Thanks
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ExisitingLowerEmi;

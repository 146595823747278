import { CheckCircle } from "lucide-react";
import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

export function MinemiTools({ name }) {
  const [data, setData] = useState({});

  const idbiData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Toolss",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage experts can help you navigate the IDBI Bank home loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const lichousingData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage experts can help you navigate the LIC Housing Finance home loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const icicihousingData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage experts can help you navigate the ICICI Home Finance home loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const pnbhousingData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage experts can help you navigate the PNB Housing Finance home loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const sbihousingData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage experts can help you navigate the SBI Home Loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const adityahousingData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage experts can help you navigate the Aditya Birla Finance Home Loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );
  const lthousingData = useMemo(
    () => ({
      title: "MinEMI's Intelligent Home Loan Tools",
      description:
        "Maximize your home loan approval chances with our AI-powered tools",
      tools: [
        {
          name: "Home Loan Approval Predictor",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z" />
            </svg>
          ),
          description:
            "Our AI-powered tool analyzes your financial profile and property details to predict your home loan approval chances.",
          features: [
            "Predicts approval probability with 90% accuracy",
            "Identifies factors affecting your application",
            "Provides steps to improve approval chances",
          ],
          link: {
            text: "Use Loan Approval Predictor",
            url: "#loan-predictor",
          },
        },
        {
          name: "Home Loan Eligibility Calculator",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-primary-purple"
            >
              <path d="M2 9a3 3 0 0 1 0 6v2a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-2a3 3 0 0 1 0-6V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z" />
              <path d="M13 5v2" />
              <path d="M13 17v2" />
              <path d="M13 11v2" />
            </svg>
          ),
          description:
            "Calculate your exact home loan eligibility amount based on your income, existing EMIs, and property value.",
          features: [
            "Calculates maximum eligible loan amount",
            "Estimates EMI based on your eligibility",
            "Shows how to improve your eligibility",
          ],
          link: {
            text: "Check Your Eligibility Now",
            url: "/trackloanstatus",
          },
        },
      ],
      consultation: {
        title: "Need Personalized Guidance?",
        description:
          "Our mortgage experts can help you navigate the L&T Finance Home Loan process and maximize your approval chances.",
        link: {
          text: "Schedule a Free Consultation",
          url: "/personalloan/applyonline",
        },
      },
    }),
    []
  );

  useEffect(() => {
    if (name === "checkidbihousingbankstatus") {
      setData(idbiData);
    } else if (name === "checklichousingbankstatus") {
      setData(lichousingData);
    } else if (name === "checkicicihousingbankstatus") {
      setData(icicihousingData);
    } else if (name === "checkpnbhousingbankstatus") {
      setData(pnbhousingData);
    } else if (name === "checksbihousingbankstatus") {
      setData(sbihousingData);
    } else if (name === "checkadityahousingbankstatus") {
      setData(adityahousingData);
    } else if (name === "checklthousingbankstatus") {
      setData(lthousingData);
    } else {
      setData({});
    }
  }, [
    name,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    sbihousingData,
    adityahousingData,
    lthousingData,
  ]);

  return (
    <section
      className="py-2 border-b border-gray-200"
      style={{ backgroundColor: "#ebe8fb" }}
    >
      <div className="container px-2 md:px-6 bg-transparent">
        <div className="sd:w-full md:w-4/5 mx-auto bg-transparent">
          <div className="text-center mb-6 bg-transparent">
            <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple">
              {data?.title}
            </h2>
            <p className="text-dark-grey mt-0 text-sm bg-transparent text-center">
              {data?.description}
            </p>
          </div>

          <div className="grid gap-3 bg-transparent">
            {data.tools?.map((d, index) => (
              <div
                key={index}
                className="bg-white p-3 rounded-lg shadow-sm border border-gray-200"
              >
                <div className="bg-transparent">
                  <div
                    className="h-10 w-10 rounded-full bg-light-bg flex items-center justify-center mb-3 mx-auto"
                    style={{ backgroundColor: "#ebe8fb" }}
                  >
                    {d.icon}
                  </div>
                  <h3 className="text-base font-bold text-primary-purple mb-2 text-center">
                    {d.name}
                  </h3>
                  <p className="text-dark-grey mb-3 text-sm bg-transparent">
                    {d.description}
                  </p>
                  <div className="space-y-2 mb-4 bg-transparent">
                    {d.features.map((feature, i) => (
                      <div
                        key={i}
                        className="flex items-start gap-0.5 bg-transparent"
                      >
                        {/* <CheckCircle
                          className="h-4 w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        /> */}
                        <CheckCircle
                          className="h-6 w-6 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        />

                        <p className="text-sm text-dark-grey bg-transparent m-0">
                          {feature}
                        </p>
                      </div>
                    ))}
                  </div>
                  <Link
                    to={d.link.url}
                    className="inline-flex h-12 items-center justify-center rounded-md bg-teal px-6 text-sm font-medium text-white transition-colors w-full bg-[#3b868b] hover:bg-[#2b1b61]"
                  >
                    {d.link.text}
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-3 bg-white p-3 rounded-lg border border-gray-200 shadow-sm">
            <h3 className="text-base font-bold text-primary-purple mb-2 text-center">
              {data.consultation?.title}
            </h3>
            <p className="text-dark-grey mb-4 text-sm bg-transparent">
              {data.consultation?.description}
            </p>
            <Link
              to={data.consultation?.link?.url}
              className="inline-flex h-12 items-center justify-center rounded-md bg-primary-purple px-6 text-sm font-medium text-white hover:bg-accent-purple transition-colors w-full bg-[#2b1b61] hover:bg-[#3b868b]"
            >
              {data.consultation?.link?.text}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ColorRing } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../ContextAPI/FormContextAPI";
import { useNavigate } from "react-router-dom";
import login from "../../Assets/Images/login.webp";
import axios from "axios";
import experian from "../../Assets/Images/experian.webp";
import { Link } from "react-router-dom";

export default function LoanApplicationForm() {
  const [otpLoader, setOtpLoader] = useState(false);
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const { city } = useContext(UserContext);
  const [stgOne, setStgOne] = useState("");
  const [stgTwo, setStgTwo] = useState("");
  const [mbl, setMbl] = useState("");
  const [token, setToken] = useState("");
  const [otpField, setOtpField] = useState(new Array(6).fill(""));
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    consent: false,
    consent2: false,
  });
  const timerRef = useRef(null);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [timeLeft, setTimeLeft] = useState(() => {
    const endTime = parseInt(localStorage.getItem("otpEndTime"), 10);
    const now = new Date().getTime();
    const timeLeft = Math.floor((endTime - now) / 1000);
    return timeLeft > 0 ? timeLeft : 30;
  });
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const startTimer = (duration = 30) => {
    // Clear any existing timer
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    
    setTimeLeft(duration);
    setIsResendDisabled(true);
    
    timerRef.current = setInterval(() => {
      setTimeLeft(prev => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          setIsResendDisabled(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Initialize timer when OTP modal is shown or when resending OTP
  useEffect(() => {
    if (show) {
      startTimer();
    }
    
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [show]); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleConsent1 = (e) => {
    setFormData((prev) => ({ ...prev, consent: e.target.checked }));
  };
  const handleConsent2 = (e) => {
    setFormData((prev) => ({ ...prev, consent2: e.target.checked }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { firstName, lastName, email, phone, consent, consent2 } = formData;

    // Validation regex patterns
    const nameRegex = /^[a-zA-Z]{2,}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/;

    // Field validations
    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !phone.trim() ||
      !consent ||
      !consent2
    ) {
      toast.error("Please fill all required fields.");
      return;
    }

    if (!firstName.trim()) {
      toast.error("First name is required");
      return;
    }

    if (!lastName.trim()) {
      toast.error("Last name is required");
      return;
    }
    if (!consent || !consent2) {
      toast.error("Please check all the concent .");
      return;
    }

    if (email && !emailRegex.test(email.trim())) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (!phoneRegex.test(phone.trim())) {
      toast.error("Phone number must be exactly 10 digits.");
      return;
    }

    setLoader(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SECRET_KEY}/login`,
        {
          fname: firstName.trim(),
          lname: lastName.trim(),
          email: email ? email.trim() : "",
          mobile: phone.trim(),
          city: city,
          purpose:"running-loan-form"

        }
      );

      if (response.data.status) {
        const { expData, token } = response.data.data;
        setStgOne(expData.stgOneHitId);
        setStgTwo(expData.stgTwoHitId);
        setTimeLeft(30);
        setIsResendDisabled(true)
        setToken(token.token);
        setMbl(phone.trim());
        setShow(true);
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("Error making POST request", error);
      toast.error("Something went wrong. Please try again later.");
    } finally {
      setLoader(false);
    }
  };

  const otpSubmit = async () => {
    setOtpLoader(true);
    const concatenateOtp = otpField.join("");
    if (concatenateOtp.length === 6) {
      let formData = new FormData();
      formData.append("stgOneHitId", stgOne);
      formData.append("stgTwoHitId", stgTwo);
      formData.append("otp", concatenateOtp);
      formData.append("mobileNo", mbl);

      formData.append("type", "CUSTOM");
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/verify_otp`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the Authorization header
            },
          }
        );

        if (response.data.status == 1) {
          localStorage.setItem("token", token);
          localStorage.setItem("creditScore", response.data.data.credit_score);
          localStorage.setItem("name", response.data.data.name);
          localStorage.setItem("id", 1);
          setOtpLoader(false);
          navigate("/loan-overview", {
            state: {
              cibil: response.data.data.credit_score,
              name: response.data.data.name,
              amount: response.data.data.outstanding_amount,
              runningLoan: response.data.data.no_of_active_loan,
              unsecured:response.data.data.unsecure_loan_amt,
              secured:response.data.data.secure_loan_amt,
            },
          });
        } else {
          toast.error(response.data.msg);
          setOtpLoader(false);
        }
      } catch (error) {
        toast.error(error.response.data.msg);
        console.error("Error making POST request", error);
        setOtpLoader(false);
      }
    }
  };
  const otpHandleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, "").slice(0, 1); // Ensure only one digit
    if (!value) return;
  
    const newOtp = [...otpField];
    newOtp[index] = value;
    setOtpField(newOtp);
  
    // Move focus to the next field if the current field has a value
    if (index < 5 && value) {
      inputRefs.current[index + 1].focus();
    }
  }
  
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      // Create a copy of the current OTP array
      const newOtp = [...otpField];
  
      // Clear the current field
      newOtp[index] = "";
  
      // If the current field is empty and not the first field, move focus to the previous field
      if (index > 0 && !newOtp[index]) {
        inputRefs.current[index - 1].focus();
      }
  
      // Update the state with the new OTP array
      setOtpField(newOtp);
    }
  }
  const handleResendOtp = async () => {
    if (isResendDisabled) return;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SECRET_KEY}/login`,
        {
          fname: formData.firstName,
          email: formData.email,
          mobile: formData.phone,
          lname: formData.lastName,
          city: city,
        }
      );
  
      if (response.data.status) {
        const { expData, token } = response.data.data;
        setStgOne(expData.stgOneHitId);
        setStgTwo(expData.stgTwoHitId);
        setToken(token.token);
        setTimeLeft(30);
        setIsResendDisabled(true)
        setOtpField(new Array(6).fill(""));
        toast.success("OTP resent successfully");
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("Error resending OTP", error);
      toast.error("Failed to resend OTP");
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit} className="p-6 md:p-8 space-y-6">
        <div className="p-4 border-b border-gray-200">
          <div className="font-medium text-lg text-gray-800">
            Personal Details
          </div>
        </div>

        <div className="space-y-6">
          <div className="grid md:grid-cols-2 gap-6">
            <div
              className="space-y-2"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="firstName" className="text-gray-700 font-medium">
                First Name
              </label>
              <input
                style={{
                  border: "1px solid gray ",
                  borderRadius: 8,
                  padding: 8,
                }}
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="Enter your first name"
                className="border-gray-300 focus:ring-[#3A1E6D] focus:border-[#3A1E6D]"
                required
              />
            </div>

            <div
              className="space-y-2"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="lastName" className="text-gray-700 font-medium">
                Last Name
              </label>
              <input
                style={{
                  border: "1px solid gray ",
                  borderRadius: 8,
                  padding: 8,
                }}
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Enter your last name"
                className="border-gray-300 focus:ring-[#3A1E6D] focus:border-[#3A1E6D]"
                required
              />
            </div>
          </div>

          <div
            className="space-y-2"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="email" className="text-gray-700 font-medium">
              Email Address
            </label>
            <input
              style={{ border: "1px solid gray ", borderRadius: 8, padding: 8 }}
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email address"
              className="border-gray-300 focus:ring-[#3A1E6D] focus:border-[#3A1E6D]"
              required
            />
          </div>

          <div
            className="space-y-2"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label htmlFor="phone" className="text-gray-700 font-medium">
              Phone Number
            </label>
            <input
              style={{ border: "1px solid gray ", borderRadius: 8, padding: 8 }}
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter your phone number"
              className="border-gray-300 focus:ring-[#3A1E6D] focus:border-[#3A1E6D]"
              required
            />
          </div>

          <div className="flex items-start space-x-3 pt-2">
            <div className="flex items-center h-5">
              <input
                id="consent"
                type="checkbox"
                checked={formData.consent}
                onChange={handleConsent1}
                className="w-4 h-4 text-[#3a868b] border-[#3a868b] rounded focus:ring-[#3a868b]"
              />
            </div>
            <label htmlFor="consentTerms" className="text-sm font-normal">
              I agree to MinEMI's{" "}
              <a
                         href="https://minemi.ai/terms-condition"
                         target="_blank"
                style={{ color: "rgba(89, 38, 109, 1)", fontWeight: 600 }}
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
         
                 href="https://minemi.ai/privacypolicy"
                 target="_blank"
                style={{ color: "rgba(89, 38, 109, 1)", fontWeight: 600 }}
              >
                Privacy Policy
              </a>{" "}
              and authorise to be communicated via SMS, Call, WhatsApp, and
              {showMore1 ? (
                <>
                  {" "}
                  Email. I hereby also allow MinEMI to access my credit report
                  on my behalf.
                  <span
                    className="text-[#3a868b] cursor-pointer font-medium"
                    onClick={() => setShowMore1(false)}
                  >
                    {" "}
                    Read Less
                  </span>
                </>
              ) : (
                <span
                  className="text-[#3a868b] cursor-pointer font-medium"
                  onClick={() => setShowMore1(true)}
                >
                  {" "}
                  ...Read More
                </span>
              )}
            </label>
          </div>

          <div className="flex items-start space-x-3 pt-2">
            <div className="flex items-center h-5">
              <input
                id="consentMarketing"
                type="checkbox"
                checked={formData.consent2}
                onChange={handleConsent2}
                className="w-4 h-4 text-[#3a868b] border-[#3a868b] rounded focus:ring-[#3a868b]"
              />
            </div>
            <label htmlFor="consentMarketing" className="text-sm font-normal">
              I hereby consent to Nine Triangles Pvt.Ltd. being appointed as my
              authorised representative to receive my credit information from
              Experian for the purpose of Credit Assessment{" "}
              {showMore2 ? (
                <>
                  to advise me on the best loan offers on expiry of 6 months
                  from the date the consent is collected, whichever is earlier.
                  I also agree to{" "}
                  <a
                     href="https://minemi.ai/experianconsent"
                     target="_blank"
                    style={{ color: "rgba(89, 38, 109, 1)", fontWeight: 600 }}
                  >
                    Experian Terms and Conditions.
                  </a>
                  <span
                    className="text-[#3a868b] cursor-pointer font-medium"
                    onClick={() => setShowMore2(false)}
                  >
                    {" "}
                    Read Less
                  </span>
                </>
              ) : (
                <span
                  className="text-[#3a868b] cursor-pointer font-medium"
                  onClick={() => setShowMore2(true)}
                >
                  {" "}
                  ...Read More
                </span>
              )}
            </label>
          </div>

          <div
            className="d-flex flex-column align-items-center mt-3"
            style={{
              backgroundColor: "transparent",
            }}
          >
            <div className="experianLogo">
              <img src={experian} alt="image" />
            </div>
          </div>

          <button
            type="submit"
            style={{ borderRadius: 8 }}
            disabled={loader}
            className="w-full bg-[#3BBAA0] hover:bg-[#2A9D87] text-white font-medium py-1 text-lg"
          >
            {loader ? (
              <ColorRing
                visible={loader}
                height="30"
                width="30"
                ariaLabel="color-ring-loading"
                wrapperClass="color-ring-wrapper"
                style={{ color: "white" }}
                colors={[
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                  "#ffffff",
                ]}
              />
            ) : (
              "Check My Loans"
            )}
          </button>

          <p className="text-xs text-center text-gray-600">
            No credit card required. Free forever.
          </p>
        </div>
      </form>

      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        backdrop={true}
        keyboard={false}
        style={{ backgroundColor: "transparent" }}
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <div className="row" id="otpCon">
            <div className="col-lg-6 px-0">
              <img src={login} alt="" style={{ objectFit: "cover" }} />
            </div>

            <div className="col-lg-6" id="otp-section">
              <div>
                <p className="m-0 p-0 otp-text ">Enter OTP</p>
                <p className="m-0 p-0 otp-desc mt-2">
                  Please enter the one-time password (OTP) sent on the given
                  number - {mbl}
                </p>
              </div>

              <div className="d-flex justify-content-between otp-input-con">
                {otpField.map((digit, index) => (
                  <Form.Control
                    type="number"
                    className="otp-inputs"
                    key={index}
                    maxLength="1"
                    value={digit}
                    onChange={(e) => otpHandleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </div>
              <div style={{
                fontWeight: 700,
                fontSize: 14,
                color: "rgba(89, 38, 109, 1)",
                textAlign: "center",
                marginTop:'5%'
              }}
            >
              {formatTime(timeLeft)} 
            </div>

              <div className="otpBtn">
                {!otpLoader ? (
                  <Button
                    className="button-class"
                    id="verify-btn"
                    onClick={otpSubmit}
                  >
                    Verify
                  </Button>
                ) : (
                  <Button
                    className="button-class"
                    id="verify-btn"
                    style={{ backgroundColor: "#633376" }}
                  >
                    <ColorRing
                      visible={otpLoader}
                      height="30"
                      width="30"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{ backgroundColor: "#633376" }}
                      wrapperClass="color-ring-wrapper"
                      style={{ color: "white", backgroundColor: "#633376" }}
                      colors={[
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                      ]}
                    />
                  </Button>
                )}
                <Button
                  className="button-class"
                  id="resend-otp"
                  disabled={isResendDisabled}
                  onClick={() => handleResendOtp()}
                >
                  Resend OTP
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer position="top-right" autoClose={3000} />
    </>
  );
}

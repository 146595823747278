import { useContext, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import login from "../../Assets/Images/login.webp";
import { Button, Form } from "react-bootstrap";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../ContextAPI/FormContextAPI";
import { Link } from "react-router-dom";
import experian from '../../Assets/Images/experian.webp'

export default function LoanCheckerForm() {
  const navigate = useNavigate();
  const [sliderValue, setSliderValue] = useState(25000);
  const inputRefs = useRef([]);
  const [otpField, setOtpField] = useState(new Array(6).fill(""));
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const { city } = useContext(UserContext);
  const [stgOne, setStgOne] = useState("");
  const [stgTwo, setStgTwo] = useState("");
  const [mbl, setMbl] = useState("");
  const [ltype, setltype] = useState("");
  const [token, setToken] = useState("");
  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const [company, setCompany] = useState([]);
  const [listshow, setListshow] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const timerRef = useRef(null);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [timeLeft, setTimeLeft] = useState(() => {
    const endTime = parseInt(localStorage.getItem("otpEndTime"), 10);
    const now = new Date().getTime();
    const timeLeft = Math.floor((endTime - now) / 1000);
    return timeLeft > 0 ? timeLeft : 30;
  });
  
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    loanAmount: 10000,  // Default value
    loanType: "",
    monthlyIncome: "",
    monthlyEmi: "",
    employmentType: "",
    existingLoans: "",
    repaymentHistory: "",
    consentTerms: false,
    consentMarketing: false,
    cibil: "",
    company: ""
  });
  
  const [errors, setErrors] = useState({});

  // Indian currency formatter
  const formatIndianCurrency = (num) => {
    if (!num) return "";
    const numStr = num.toString();
    const lastThree = numStr.substring(numStr.length - 3);
    const otherNumbers = numStr.substring(0, numStr.length - 3);
    if (otherNumbers !== "") {
      return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree;
    }
    return lastThree;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Special handling for numeric fields
    if (name === "monthlyIncome" || name === "monthlyEmi") {
      // Remove all non-digit characters and format
      const numericValue = value.replace(/[^0-9]/g, "");
      setFormData({
        ...formData,
        [name]: numericValue,
      });
    }
     else {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }

    // Clear error when field is edited
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const handleSliderChange = (e) => {
    const value = Number.parseInt(e.target.value);
    setSliderValue(value);
    setFormData({
      ...formData,
      loanAmount: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate required fields
    if (formData.firstName.trim() === "") {
      newErrors.firstName = "First name is required";
    }
    if ( formData.lastName.trim() === "") {
      newErrors.lastName = "Last name is required";
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    // Validate contact number
    if (!formData.contactNumber.trim()) {
      newErrors.contactNumber = "Contact number is required";
    } else if (formData.contactNumber.length < 10) {
      newErrors.contactNumber = "Contact number must be at least 10 digits";
    }

    // Validate loan type
    if (!formData.loanType) newErrors.loanType = "Please select a loan type";

    // Validate monthly income
    if (!formData.monthlyIncome.trim())
      newErrors.monthlyIncome = "Monthly income is required";

    // Validate employment type
    if (!formData.employmentType)
      newErrors.employmentType = "Please select your employment type"; 

   // In your validateForm function:
    if (formData.employmentType === "salaried" && !formData.company) {
      newErrors.company = "Please select your Company name";
    }
    // Validate consent
    if (!formData.consentTerms)
      newErrors.consentTerms = "You must agree to the terms and conditions"; 
     if (!formData.consentMarketing)
      newErrors.consentMarketing = "You must agree to the terms and conditions";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault(); 

    if (validateForm()) {
      setLoader(true);

      try {
        const response = await axios.post(`${process.env.REACT_APP_URL}/login`, {
          fname: formData.firstName,
          email: formData.email,
          mobile: formData.contactNumber,
          lname: formData.lastName,
          city: city,
           purpose:'  Check Your Loan Approval Chances',
           "profession": formData.employmentType,
           "company": formData.company,
           "monthly_income": formData.monthlyIncome?.replace(/[, ]/g, ""),
           "monthly_emi": formData.monthlyEmi==0 ?0:formData.monthlyEmi.replace(/[, ]/g, ""),
           "loan_type": formData.loanType,
           "loanamount": formData.loanAmount,

 

        });


        if (response.data.status) {

          const { expData, token } = response.data.data;
          setStgOne(expData.stgOneHitId);
          setStgTwo(expData.stgTwoHitId);
          setTimeLeft(30)
          localStorage.setItem("formId",response.data.data.formId)
          setMbl(formData.contactNumber);
          setShow(true);
          setLoader(false);
        } else {
          toast.error(response.data.msg);
          setLoader(false);
        }
      } catch (error) {
        console.error("Error making POST request", error);
        setLoader(false);
        toast.error("Something went wrong. Please try again later.");
      }
    }
  };

  const otpSubmit = async () => {
    setOtpLoader(true);
    const formid=localStorage.getItem("formId")
    const concatenateOtp = otpField.join("");
    if (concatenateOtp.length === 6) {
      let otpFormData = new FormData();
      otpFormData.append("stgOneHitId", stgOne);
      otpFormData.append("stgTwoHitId", stgTwo);
      otpFormData.append("otp", concatenateOtp);
      otpFormData.append("mobile", mbl);
      otpFormData.append("type", "CUSTOM");
      otpFormData.append("formId", formid);
      
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/verifyOtp`,
          otpFormData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        if (response.data.status === 1) {
          localStorage.setItem("creditScore", response.data.data.credit_score);
          localStorage.setItem("name",formData.firstName);
          localStorage.setItem("id", 1);
          navigate('/loan-approval', {
            state: {
              loanamount: formData.loanAmount,
              loantype: formData.loanType,
              monthlyincome: formData.monthlyIncome,
              monthlyemi: formData.monthlyEmi,
              employmenttype: formData.employmentType,
              cibil: response.data.data.credit_score,
              unsecured:response.data.data.unsecure_loan_amt,
              secured:response.data.data.secure_loan_amt,
              name:  formData.firstName,
              amount: response.data.data.outstanding_amount,
              runningLoan: response.data.data.no_of_active_loan,
              employmentType: formData.employmentType,
              company: formData.company,
              eligibleLoan:response.data.data.eligibleLoan.amount

              
            }
          });
        } else {
          toast.error(response.data.msg);
          setOtpLoader(false);
        }
      } catch (error) {
        console.error("Error verifying OTP", error);
        toast.error("Failed to verify OTP");
        setOtpLoader(false);
      }
    } else {
      toast.error("Please enter a valid 6-digit OTP");
      setOtpLoader(false);
    }
  };

  const otpHandleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, "").slice(0, 1); // Ensure only one digit
    if (!value) return;
  
    const newOtp = [...otpField];
    newOtp[index] = value;
    setOtpField(newOtp);
  
    // Move focus to the next field if the current field has a value
    if (index < 5 && value) {
      inputRefs.current[index + 1].focus();
    }
  }
  
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      // Create a copy of the current OTP array
      const newOtp = [...otpField];
  
      // Clear the current field
      newOtp[index] = "";
  
      // If the current field is empty and not the first field, move focus to the previous field
      if (index > 0 && !newOtp[index]) {
        inputRefs.current[index - 1].focus();
      }
  
      // Update the state with the new OTP array
      setOtpField(newOtp);
    }
  }
  const handleResendOtp = async () => {
    if (isResendDisabled) return;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/login`,
        {
          fname: formData.firstName,
          email: formData.email,
          mobile: formData.contactNumber,
          lname: formData.lastName,
          city: city,
          purpose:'  Check Your Loan Approval Chances',
          "profession": formData.employmentType,
          "company": formData.company,
          "monthly_income": formData.monthlyIncome?.replace(/[, ]/g, ""),
          "monthly_emi": formData.monthlyEmi==0 ?0:formData.monthlyEmi.replace(/[, ]/g, ""),
          "loan_type": formData.loanType,
          "loan_type": formData.loanType,
          "loanamount": formData.loanAmount,
          

        }
      );
  
      if (response.data.status) {
        const { expData, token } = response.data.data;
        setStgOne(expData.stgOneHitId);
        setStgTwo(expData.stgTwoHitId);
        localStorage.setItem("formId",response.data.data.formId)
        setOtpField(new Array(6).fill(""));
        toast.success("OTP resent successfully");
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("Error resending OTP", error);
      toast.error("Failed to resend OTP");
    }
  };

  const Company = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SECRET_KEY}/companyList`
    );

    if (response.status === 200) {
      setCompany(response.data.data.company);
    } else {
      setCompany([]);
    }
  };

  useEffect(() => {
    Company();
  }, []);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const startTimer = (duration = 30) => {
    // Clear any existing timer
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    
    setTimeLeft(duration);
    setIsResendDisabled(true);
    
    timerRef.current = setInterval(() => {
      setTimeLeft(prev => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          setIsResendDisabled(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Initialize timer when OTP modal is shown or when resending OTP
  useEffect(() => {
    if (show) {
      startTimer();
    }
    
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [show]); // This effect depends on the 'show' state
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      loanAmount: ltype == "hl" ? 50000 : 10000
    }));
  }, [ltype]);

  return (
    <>
      <section className="w-full bg-[#ebe8fc]/50 py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto bg-white shadow-xl rounded-xl overflow-hidden">
            <div className="bg-[#311664] text-white text-center py-4 md:py-8 px-4">
              <h2 className="text-2xl md:text-3xl font-bold">
                Check Your Loan Approval Chances
              </h2>
              <p className="text-[#ebe8fc] text-sm md:text-lg mt-1 md:mt-2">
                It's free, instant, and won't affect your credit score
              </p>
            </div>
            <div className="p-4 md:p-8">
              <form onSubmit={handleSubmit} className="space-y-4 md:space-y-8">
                {/* Personal Information */}
                <div className="space-y-4">
                  <h3 className="text-lg md:text-xl font-semibold text-[#311664]">
                    Personal Information
                  </h3>

                  <div className="grid md:grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <label
                        htmlFor="firstName"
                        className="block text-base font-medium text-[#311664]"
                      >
                        First Name
                      </label>
                      <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        placeholder="Enter your first name"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        className="w-full h-10 px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-[#311664] focus:ring-1 focus:ring-[#311664]"
                      />
                      {errors.firstName && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.firstName}
                        </p>
                      )}
                    </div>

                    <div className="space-y-2">
                      <label
                        htmlFor="lastName"
                        className="block text-base font-medium text-[#311664]"
                      >
                        Last Name
                      </label>
                      <input
                        id="lastName"
                        name="lastName"
                        type="text"
                        placeholder="Enter your last name"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        className="w-full h-10 px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-[#311664] focus:ring-1 focus:ring-[#311664]"
                      />
                      {errors.lastName && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.lastName}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="grid md:grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <label
                        htmlFor="email"
                        className="block text-base font-medium text-[#311664]"
                      >
                        Email Address
                      </label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="your.email@example.com"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="w-full h-10 px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-[#311664] focus:ring-1 focus:ring-[#311664]"
                      />
                      {errors.email && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.email}
                        </p>
                      )}
                    </div>

                    <div className="space-y-2">
                      <label
                        htmlFor="contactNumber"
                        className="block text-base font-medium text-[#311664]"
                      >
                        Contact Number
                      </label>
                      <input
                        id="contactNumber"
                        name="contactNumber"
                        type="tel"
                        placeholder="Enter your mobile number"
                        value={formData.contactNumber}
                        onChange={handleInputChange}
                        className="w-full h-10 px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-[#311664] focus:ring-1 focus:ring-[#311664]"
                      />
                      {errors.contactNumber && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.contactNumber}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {/* Loan Details */}
                <div className="space-y-4">
                  <h3 className="text-lg md:text-xl font-semibold text-[#311664]">
                    Loan Details
                  </h3>
                  <div className="grid md:grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <label
                        htmlFor="loanType"
                        className="block text-base font-medium text-[#311664]"
                      >
                        Type of Loan 
                      </label>
                      <select
                        id="loanType"
                        name="loanType"
                        value={formData.loanType}
                        onChange={(event) => {
                          handleInputChange(event); // Ensure event is passed correctly
                          setltype(event.target.value); // If updating based on input value
                        }}
                        
                        className="w-full h-10 px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-[#311664] focus:ring-1 focus:ring-[#311664]"
                      >
                        <option value="" disabled>
                          Select loan type
                        </option>
                        <option value="hl">Home Loan</option>
                        <option value="pl">Personal Loan</option>
                        <option value="cl">Car Loan</option>
                      </select>
                      {errors.loanType && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.loanType}
                        </p>
                      )}
                    </div>

                    <div className="space-y-2">
                      <label
                        htmlFor="monthlyIncome"
                        className="block text-base font-medium text-[#311664]"
                      >
                        Monthly Income (₹)
                      </label>
                      <input
                        id="monthlyIncome"
                        name="monthlyIncome"
                        type="text"
                        placeholder="e.g., 50,000"
                        value={formatIndianCurrency(formData.monthlyIncome)}
                        onChange={handleInputChange}
                        className="w-full h-10 px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-[#311664] focus:ring-1 focus:ring-[#311664]"
                      />
                      {errors.monthlyIncome && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.monthlyIncome}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="space-y-2 md:space-y-4">
  <label
    htmlFor="loanAmount"
    className="block text-base font-medium text-[#311664]"
  >
    Loan Amount Required
  </label>
  <div className="space-y-1 md:space-y-2">
    <div className="flex justify-between items-center">
      <span className="text-xs md:text-sm text-gray-500">
        ₹{formData.loanType === "pl" ? 10000 : formData.loanType === "hl" ? 50000 : 10000}
      </span>
      <input 
        type="text"
        style={{border:'1px solid grey',borderRadius:8}}
        className="text-base md:text-xl font-bold text-[#311664] border-gray-300 focus:outline-none focus:border-[#311664] w-82 text-center"
        value={formData.loanAmount == 0 ? '' : `₹${formatIndianCurrency(formData.loanAmount)}`}
    
        onChange={(e) => {
          // Allow only numbers during typing
          const rawValue = e.target.value.replace(/[^0-9]/g, "");
          e.target.value = rawValue;
          
          // Update state in real-time if valid number
          if (rawValue !== "") {
            const numValue = parseInt(rawValue);
            if (!isNaN(numValue)) {
              setFormData({
                ...formData,
                loanAmount: numValue
              });
            }
          } else {
            // If empty, set to 0 temporarily (will be handled on blur)
            setFormData({
              ...formData,
              loanAmount: 0
            });
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.target.blur();
          }
        }}
      />
      <span className="text-xs md:text-sm text-gray-500">
        ₹{formData.loanType === "pl" ? 5000000 : formData.loanType === "hl" ? 50000000 : 10000000}
      </span>
    </div>
    <input
      id="loanAmount"
      name="loanAmount"
      type="range"
      min={formData.loanType === "pl" ? 10000 : formData.loanType === "hl" ? 50000 : 10000}
      max={formData.loanType === "pl" ? 5000000 : formData.loanType === "hl" ? 50000000 : 10000000}
      step="10000"
      value={sliderValue}
      onChange={handleSliderChange}
      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-[#311664]"
    />
  </div>
</div>

               

               

                  <div className="grid md:grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <label
                        htmlFor="monthlyEmi"
                        className="block text-base font-medium text-[#311664]"
                      >
                        Current Monthly EMI (₹)
                      </label>
                      <input
                        id="monthlyEmi"
                        name="monthlyEmi"
                        type="text"
                        placeholder="e.g., 15,000"
                        value={formatIndianCurrency(formData.monthlyEmi)}
                        onChange={handleInputChange}
                        className="w-full h-10 px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-[#311664] focus:ring-1 focus:ring-[#311664]"
                      />
                      <p className="text-xs text-gray-500">
                        Total of all existing loan EMIs (if any)
                      </p>
                    </div>

                    <div className="space-y-2">
                      <label
                        htmlFor="employmentType"
                        className="block text-base font-medium text-[#311664]"
                      >
                        Employment Type
                      </label>
                      <select
                        id="employmentType"
                        name="employmentType"
                        value={formData.employmentType}
                        onChange={handleInputChange}
                        className="w-full h-10 px-3 py-2 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-[#311664] focus:ring-1 focus:ring-[#311664]"
                      >
                        <option value="" disabled>
                          Select employment type
                        </option>
                        <option value="salaried">Salaried</option>
                        <option value="self-employed">Self-Employed</option>
                        <option value="other">Other</option>
                      </select>
                      {errors.employmentType && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.employmentType}
                        </p>
                      )}
                    </div>
                    {formData.employmentType === "salaried" && (
                    
                        <div className="space-y-1">
                        <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                          Name of Company
                        </label>
                        
                        {/* Main company input field */}
                        <div className="relative">
                          <input
                            id="company"
                            required
                            value={formData.company}
                            onChange={handleInputChange}
                            onClick={() => setListshow(!listshow)} // Toggle dropdown on click
                            className="h-10 text-sm w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#311664] focus:border-[#311664] bg-white"
                            placeholder="Select company"
                          />
                          
                          {/* Searchable dropdown (conditionally shown) */}
                          {listshow && (
                            <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                              {/* Search input inside dropdown */}
                              <div className="p-2 border-b">
                                <input
                                  type="text"
                                  placeholder="Search companies..."
                                  value={searchTerm}
                                  onChange={(e) => setSearchTerm(e.target.value)}
                                  className="h-8 text-sm w-full px-2 py-1 border border-gray-300 rounded"
                                />
                              </div>
                              
                              {/* Filtered company list */}
                              <div className="divide-y">
                                {company
                                  .filter(company => 
                                    company.company_name.toLowerCase().includes(searchTerm.toLowerCase())
                                  )
                                  .map((d, index) => (
                                    <div
                                      key={index}
                                      className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                                      onClick={() => {
                                        setFormData({
                                          ...formData,
                                          company: d.company_name
                                        });
                                        setListshow(false); // Close after selection
                                        setSearchTerm(''); // Reset search
                                      }}
                                    >
                                      {d.company_name}
                                    </div>
                                  ))
                                }
                              </div>

                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                    {errors.company && (
              <p className="text-red-500 text-sm mt-1">
                {errors.company}
              </p>
                    )}

                {/* Consent Checkboxes */}
                <div className="space-y-4">
                  <h3 className="text-lg md:text-xl font-semibold text-[#311664]">
                    Consent 
                  </h3>
                  
                  <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                    <input
                      id="consentTerms"
                      name="consentTerms"
                      type="checkbox"
                      checked={formData.consentTerms}
                      onChange={handleInputChange}
                      className="h-4 w-4 mt-1 text-[#311664] focus:ring-[#311664] rounded"
                    />
                    <div className="space-y-1 leading-none">
                      <label
                        htmlFor="consentTerms"
                        className="text-sm font-normal"
                      >
                        I agree to MinEMI's{" "}
                        <a

target="_blank"
href="https://minemi.ai/terms-condition"
                          style={{
                            color: "rgba(89, 38, 109, 1)",
                            fontWeight: 600,
                          }}
                        >
                          Terms of Use{" "}
                        </a>
                        and
                        <a

target="_blank"
href="https://minemi.ai/privacypolicy"
                          style={{
                            color: "rgba(89, 38, 109, 1)",
                            fontWeight: 600,
                          }}
                        >
                          {" "}
                          Privacy Policy
                        </a>{" "}
                        and authorise to be communicate via SMS {" "}
                        {showMore1 ? (
                          <>
                            Call, Whatsapp and Email. I hereby also allow MInEMI to access my credit report on my behalf{" "}
                            <span 
                              style={{color:'#311664'}} 
                              onClick={() => setShowMore1(false)}
                            >
                              {" "}Read less
                            </span>
                          </>
                        ) : (
                          <span 
                            style={{color:'#311664'}} 
                            onClick={() => setShowMore1(true)}
                          >
                            {" "}Read more...
                          </span>
                        )}
                      </label>
                      
                      {errors.consentTerms && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.consentTerms}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                    <input
                      id="consentMarketing"
                      name="consentMarketing"
                      type="checkbox"
                      checked={formData.consentMarketing}
                      onChange={handleInputChange}
                      className="h-4 w-4 mt-1 text-[#311664] focus:ring-[#311664] rounded"
                    />
                    <div className="space-y-1 leading-none">
                      <label
                        htmlFor="consentMarketing"
                        className="text-sm font-normal"
                      >
                        {" "}
                        I hearby consent to Nine Triangles Pvt.Ltd. being
                        appointed as my authorised representative to receive my {" "}
                        {showMore2 ? (
                          <>
                            credit information from Experian for the purpose of Credit Assessment to advice me on the best loan offers on expiry of 6 months from the date the consent is collected, whichever is earlier. I also agree to{" "}
                          <a
                             target="_blank"
                             href="https://minemi.ai/experianconsent"
                              style={{
                                color: "rgba(89, 38, 109, 1)",
                                fontWeight: 600,
                              }}
                            >
                              Experian Terms and Conditions.{" "}
                            </a>
                            <span 
                              style={{color:'#311664'}} 
                              onClick={() => setShowMore2(!showMore2)}
                            >
                              Read Less...
                            </span>
                          </>
                        ) : (
                          <span 
                            style={{color:'#311664'}} 
                            onClick={() => setShowMore2(!showMore2)}
                          >
                            {" "}Read more...
                          </span>
                        )}
                      </label>
                      {errors.consentMarketing && (
                        <p className="text-red-500 text-sm mt-1">
                          {errors.consentMarketing}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div 
                  className="d-flex flex-column align-items-center mt-3"
                  style={{
                    backgroundColor: 'transparent' 
                  }}
                >
                  <div className="experianLogo">
                    <img src={experian} alt="Experian logo" />
                  </div>
                </div>

                <div className="flex justify-center mt-8">
                  <button
                    type="submit"
                    className="bg-[#3a868b] hover:bg-[#3a868b]/90 text-white px-8 py-2 md:py-6 text-base md:text-lg rounded-lg shadow-lg"
                  >
                    {loader ? (
                      <ColorRing
                        visible={loader}
                        height="30"
                        width="30"
                        ariaLabel="color-ring-loading"
                        wrapperClass="color-ring-wrapper"
                        style={{ color: "white" }}
                        colors={[
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                        ]}
                      />
                    ) : (
                      "Check My Loan Chances"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      
      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        backdrop={true}
        keyboard={false}
        style={{ backgroundColor: "transparent" }}
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <div className="row" id="otpCon">
            <div className="col-lg-6 px-0">
              <img src={login} alt="Login image" style={{ objectFit: "cover" }} />
            </div>

            <div className="col-lg-6" id="otp-section">
              <div>
                <p className="m-0 p-0 otp-text ">Enter OTP</p>
                <p className="m-0 p-0 otp-desc mt-2">
                  Please enter the one-time password (OTP) sent on the given
                  number - {mbl}
                </p>
              </div>

              <div className="d-flex justify-content-between otp-input-con">
                {otpField.map((digit, index) => (
                  <Form.Control
                    type="number"
                    className="otp-inputs"
                    key={index}
                    maxLength="1"
                    value={digit}
                    onChange={(e) => otpHandleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </div>
              <div
              style={{
                fontWeight: 700,
                fontSize: 14,
                color: "rgba(89, 38, 109, 1)",
                textAlign: "center",
                marginTop:'5%'
              }}
            >
              {formatTime(timeLeft)} 
            </div>

              <div className="otpBtn">
                {!otpLoader ? (
                  <Button
                    className="button-class"
                    id="verify-btn"
                    onClick={otpSubmit}
                  >
                    Verify
                  </Button>
                ) : (
                  <Button
                    className="button-class"
                    id="verify-btn"
                    style={{ backgroundColor: "#633376" }}
                    disabled
                  >
                    <ColorRing
                      visible={otpLoader}
                      height="30"
                      width="30"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{ backgroundColor: "#633376" }}
                      wrapperClass="color-ring-wrapper"
                      style={{ color: "white", backgroundColor: "#633376" }}
                      colors={[
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                      ]}
                    />
                  </Button>
                )}
                <Button
                  className="button-class"
                  id="resend-otp"
                  disabled={isResendDisabled}
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      
      <ToastContainer
        position="top-right"
        style={{zIndex:90000000}}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
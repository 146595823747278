import React from "react";
// import Navbar from "../Navbar";
// import FindLoan from "./FindLoan";
import Serve from "./Serve/Serve.jsx";
import Types from "./Types/Types.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import LoanConsolidationEligibility from "./PersonalLoanEligibility/LoanConsolidationEligibility.jsx";
import PersonalLoanEligibility from "./PersonalLoanEligibility/PersonalLoanEligibility.jsx";

const PersonalLoanEligibilityForm = () => {
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <PersonalLoanEligibility />
        <Serve />
        <Types />
        <ToastContainer />
      </div>
    </>
  );
};

export default PersonalLoanEligibilityForm;


import { useContext, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { CheckCircle2, AlertCircle, ChevronDown, X, ChevronRight } from "lucide-react";
import experian from "../../Assets/Images/experian.webp";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../ContextAPI/FormContextAPI";
import axios from "axios";
import login from "../../Assets/Images/login.webp";
import { Button, Form, Modal } from "react-bootstrap";
import { ColorRing } from "react-loader-spinner";

function LoanCheckerForm() {
  const navigate = useNavigate();
  const [stgOne, setStgOne] = useState("");
  const [firstName, setFirstname] = useState("");
  const [lastName,setLastName] = useState("");
  const [email,setEmail] = useState("");
  const [mobile,setMobile] = useState("");
  const [stgTwo, setStgTwo] = useState("");
  const [mbl, setMbl] = useState("");
  const [token, setToken] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySuggestions, setCompanySuggestions] = useState([]);
  const [showCompanySuggestions, setShowCompanySuggestions] = useState(false);
  const inputRefs = useRef([]);
  const [otpField, setOtpField] = useState(new Array(6).fill(""));
  const [show, setShow] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const { city } = useContext(UserContext);
  const [annual, setAnnual] = useState("");
  const [monthlyemi, setMonthlyEmi] = useState(0);
  const [specifiedcard, setCreditCard] = useState("");
  const [list,setList]=useState([]);

  const [specificCardOpen, setSpecificCardOpen] = useState(false);
  const [showFullConsent, setShowFullConsent] = useState(false);
  const [showFullMarketingConsent, setShowFullMarketingConsent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toastMessage, setToast] = useState({ visible: false, message: "", type: "error" });

  // Consent texts
  const consentShortText = (
    <> I agree to MinEMI's <Link to="/terms-condition" style={{ color: '#561A6A', fontWeight: 'bold' }}>Terms of Use </Link> and <Link to="/privacypolicy" style={{ color: '#561A6A', fontWeight: 'bold' }}>Privacy Policy </Link> and authorise ...</>
  );
  const consentFullText = (
    <> I agree to MinEMI's <Link to="/terms-condition" style={{ color: '#561A6A', fontWeight: 'bold' }}>Terms of Use </Link> and <Link to="/privacypolicy" style={{ color: '#561A6A', fontWeight: 'bold' }}>Privacy Policy </Link> and authorise to be communicate via SMS Call, Whatsapp and Email. I hereby also allow MInEMI to access my credit report on my behal</>
  );

  const marketingConsentShortText = " I hereby consent to Nine Triangles Pvt.Ltd. being appointed as my representative to ...";
  const marketingConsentFullText = (
    <>  I hereby consent to Nine Triangles Pvt.Ltd. being appointed as my representative to receive my credit information from Experian for the purpose of Credit Assessment to advise me on the best loan offers on expiry of 6 months from the date the consent is collected, whichever is earlier. I also agree to <Link to="/experianconsent" style={{ color: '#561A6A', fontWeight: 'bold' }}>  Experian Terms and Conditions </Link></>
  );

  // Form validation schema with enhanced validation
  const formSchema = z.object({
    firstName: z
      .string()
      .min(1, { message: "First name is required" })
      .regex(/^[A-Za-z\s]+$/, { message: "First name should only contain letters" }),
    lastName: z
      .string()
      .min(1, { message: "Last name is required" })
      .regex(/^[A-Za-z\s]+$/, { message: "Last name should only contain letters" }),
    phoneNumber: z
      .string()
      .min(1, { message: "Phone number is required" })
      .refine(
        (val) => {
          // Remove all non-digits and check if it's 10 digits
          const digitsOnly = val.replace(/\D/g, "");
          return digitsOnly.length === 10;
        },
        { message: "Phone number must be 10 digits" },
      ),
    email: z.string().min(1, { message: "Email is required" }).email({ message: "Invalid email address" }),
    specificCard: z.string().min(1, { message: "Please select a specific card" }),
    annualIncome: z
      .string()
      .min(1, { message: "Please enter your annual income" })
      .refine(
        (val) => {
          // Remove commas and check if it's a valid number
          const numericValue = val.replace(/,/g, "");
          return !isNaN(numericValue) && Number(numericValue) > 0;
        },
        { message: "Please enter a valid income amount" },
      ), 
    employmentType: z.string().min(1, { message: "Please select your employment type" }),
    companyName: z.string().optional().superRefine((val, ctx) => {
      if (employmentType === "salaried" && !val) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Company name is required for salaried individuals",
        });
      }
      if (employmentType === "salaried" && val && val.length < 3) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Company name must be at least 3 characters",
        });
      }
    }),
    consent: z.boolean().refine((val) => val === true, {
      message: "You must agree to the terms and conditions",
    }),
    marketingConsent: z.boolean().refine((val) => val === true, {
      message: "You must agree to the terms and conditions",
    }),
  });

  // Form handling
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      specificCard: "",
      annualIncome: "",
      monthlyemi: "",
      employmentType: "",
      companyName: "",
      consent: false,
      marketingConsent: false,
    },
    mode: "onChange", // Validate on change
  });

  // Fetch company suggestions
  const fetchCompanySuggestions = async (query) => {
    if (query.length < 2) {
      setCompanySuggestions([]);
      return;
    }
    try {
      // Replace with your actual API endpoint for company search
      const response = await axios.get(`${process.env.REACT_APP_SECRET_KEY}/company-search`, {
        params: { query }
      });
      setCompanySuggestions(response.data.suggestions || []);
    } catch (error) {
      console.error("Error fetching company suggestions:", error);
      setCompanySuggestions([]);
    }
  };

  // Handle company name change
  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    setCompanyName(value);
    setValue("companyName", value);
    fetchCompanySuggestions(value);
    setShowCompanySuggestions(true);
  };

  // Select a company from suggestions
  const selectCompany = (company) => {
    setCompanyName(company);
    setValue("companyName", company);
    setCompanySuggestions([]);
    setShowCompanySuggestions(false);
  };

  // Show toast message
  const showToast = (message, type = "error") => {
    setToast({ visible: true, message, type });

    // Auto-hide toast after 5 seconds
    setTimeout(() => {
      setToast({ ...toastMessage, visible: false });
    }, 5000);
  };

  // Handle form submission with validation
  const onSubmit = async (values) => {
    // Validate all fields before submission
    const isValid = Object.keys(errors).length === 0;

    if (!isValid) {
      // Show toast with the first error message
      const firstError = Object.values(errors)[0];
      showToast(firstError.message);
      return;
    }

    // Check if consent is given
    if (!values.consent) {
      showToast("You must agree to the terms and conditions");
      return;
    }
    if (!values.marketingConsent) {
      showToast("You must agree to the terms and conditions");
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_SECRET_KEY}/login`, {
        fname: values.firstName,
        email: values.email,
        mobile: values.phoneNumber.replace(/[-\s]/g, ""),
        lname: values.lastName,
        city: city,
        purpose: 'Card Selection & Eligibility Check',
        employmentType: values.employmentType,
        companyName: values.companyName
      });

      // Check if the response is successful
      if (response.data.status==1) {
        // Destructure the required data from the response
        const { expData, token } = response.data.data;
        setFirstname(values.firstName)
        setLastName(values.lastName)
        setEmail(values.email)
        setMobile(values.phoneNumber)

        setStgOne(expData.stgOneHitId);
        setStgTwo(expData.stgTwoHitId);
        setToken(token.token);
        setMbl(values.phoneNumber);
        setCreditCard(values.specificCard);
        setAnnual(values.annualIncome);
        setMonthlyEmi(values.monthlyemi);
        setShow(true); // Show success state
        setIsLoading(false); // Hide loader
      } else {
        toast.error(response.data.msg);
        setIsLoading(false);
      }
    } catch (error) {
      // Handle error in case of API failure
      console.error("Error making POST request", error);
      setIsLoading(false); // Hide loader even in case of error
      toast.error("Something went wrong. Please try again later.");
      setIsLoading(false);
    }
  };

  // Validate field on blur or change
  const validateField = async (fieldName) => {
    const result = await trigger(fieldName);
    if (!result && errors[fieldName]) {
      showToast(errors[fieldName].message);
    }
  };

  // Helper function to get selected item name
  const getSelectedName = (array, id) => {
    const item = array.find((item) => item.card_name === id);
    return item ? item.card_name : "";
  };

  // Format phone number
  const formatPhoneNumber = (value) => {
    if (!value) return value;

    // Remove all non-digits
    const phoneNumber = value.replace(/[^\d]/g, "");

    // Take the first 10 digits only
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const otpSubmit = async () => {
    setOtpLoader(true);
    const concatenateOtp = otpField.join("");
    if (concatenateOtp.length === 6) {
      let formData = new FormData();
      formData.append("stgOneHitId", stgOne);
      formData.append("stgTwoHitId", stgTwo);
      formData.append("otp", concatenateOtp);
      formData.append("mobileNo", mbl.replace(/[-\s]/g, ""));
      formData.append("type", "CUSTOM");
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SECRET_KEY}/verify_otp`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the Authorization header
            },
          }
        );

        if (response.data.status == 1) {
          localStorage.setItem("token", token);
          localStorage.setItem("creditScore", response.data.data.credit_score);
          localStorage.setItem("name", response.data.data.name);
          localStorage.setItem("id", 1);
          setShow(false);
          navigate("/check-card-status", {
            state: {
              name: response.data.data.name,
              latepayment: response.data.data.no_of_late_payment,
              amount: response.data.data.outstanding_amount,
              runningLoan: response.data.data.no_of_active_loan,
              cibil: response.data.data.credit_score,
              specificCard: specifiedcard,
              annualIncome: annual,
              monthlyemi: monthlyemi==""?0 : monthlyemi,
              unsecured:response.data.data.unsecure_loan_amt,
              secured:response.data.data.secure_loan_amt,
            }
          });
        } else {
          setOtpLoader(false);
          toast.error(response.data.msg);
        }
      } catch (error) {
        toast.error(error.response.data.msg);
        console.error("Error making POST request", error);
        setOtpLoader(false);
      }
    }
  }; 
  
  const ListHandler = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/creditCardList`,
                  
      );

      if (response.data.status == 1) {
    setList(response.data.data.list)
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
      console.error("Error making POST request", error);
    }
  };

  const otpHandleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, "").slice(0, 1); // Ensure only one digit
    if (!value) return;
  
    const newOtp = [...otpField];
    newOtp[index] = value;
    setOtpField(newOtp);
  
    // Move focus to the next field if the current field has a value
    if (index < 5 && value) {
      inputRefs.current[index + 1].focus();
    }
  }
  
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      // Create a copy of the current OTP array
      const newOtp = [...otpField];
  
      // Clear the current field
      newOtp[index] = "";
  
      // If the current field is empty and not the first field, move focus to the previous field
      if (index > 0 && !newOtp[index]) {
        inputRefs.current[index - 1].focus();
      }
  
      // Update the state with the new OTP array
      setOtpField(newOtp);
    }
  }
  
  const handleResendOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SECRET_KEY}/login`,
        {
          fname: firstName,
          email: email,
          mobile: mobile.replace(/[-/]/g, ""),
          lname: lastName,
          city: city,
          purpose:'Card Selection & Eligibility Check'
        }
      );
  
      if (response.data.status) {
        const { expData, token } = response.data.data;
        setStgOne(expData.stgOneHitId);
        setStgTwo(expData.stgTwoHitId);
        setToken(token.token);
        setOtpField(new Array(6).fill(""));
        toast.success("OTP resent successfully");
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("Error resending OTP", error);
      toast.error("Failed to resend OTP");
    }
  };
  
  useEffect(() => {
    ListHandler()
  }, [])

  return (
    <>
      <section id="eligibility-section" className="py-10 md:py-20 px-4 md:px-8 lg:px-16 bg-[#ebe8fc]/30">
        {/* Toast notification */}
        {toastMessage.visible && (
          <div
            className={`fixed top-5 right-5 p-3 rounded-lg flex items-center justify-between shadow-lg z-50 max-w-sm animate-in fade-in slide-in-from-top-5 ${
              toastMessage.type === "error"
                ? "bg-red-100 border-l-4 border-red-500 text-red-700"
                : "bg-green-100 border-l-4 border-green-500 text-green-700"
            }`}
          >
            <span className="mr-3 text-sm font-medium">{toastMessage.message}</span>
            <button
              className="bg-transparent border-none cursor-pointer p-1 flex items-center justify-center"
              onClick={() => setToast({ ...toastMessage, visible: false })}
            >
              <X size={16} />
            </button>
          </div>
        )}

        <div className="container max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="text-center mb-8 md:mb-16"
          >
            <h2 className="text-2xl md:text-4xl font-bold text-[#311664] mb-2 md:mb-2">
              Card Selection & Eligibility Check
            </h2>
            <p className="text-base md:text-lg text-gray-600 max-w-2xl mx-auto">
              Tell us a bit about yourself to find the perfect card for your needs.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 lg:grid-cols-1 gap-10 items-start">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
              className="bg-white rounded-xl shadow-lg md:w-[50%]"
              style={{ margin: 'auto' }}
            >
              <div className=" p-4 md:p-6 rounded-xl " style={{backgroundColor:'#311664',width:'100%'}}>

              <h3 className="text-2xl font-semibold text-white">Check Your Credit Card Approval Chances</h3>
              <p className="text-l font-semibold text-white">It's free ,Instant and won't affect your credit score</p>
              </div>

              <form onSubmit={handleSubmit(onSubmit)} className=" p-4 md:p-6 ">
                {/* Personal Information Section */}
                <h4 className="text-lg font-semibold text-[#311664] mb-2 mt-6">Personal Information</h4>

                {/* Name Fields (First Name and Last Name) */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                  {/* First Name */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                      First Name<span className="text-red-500 ml-0.5">*</span>
                    </label>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          placeholder="John"
                          className={`w-full p-2.5 text-sm border rounded-md outline-none ${
                            errors.firstName ? "border-red-500" : "border-gray-300"
                          }`}
                          {...field}
                          onBlur={() => validateField("firstName")}
                        />
                      )}
                    />
                    {errors.firstName && <p className="mt-1 text-xs text-red-500">{errors.firstName.message}</p>}
                  </div>

                  {/* Last Name */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                      Last Name<span className="text-red-500 ml-0.5">*</span>
                    </label>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          placeholder="Doe"
                          className={`w-full p-2.5 text-sm border rounded-md outline-none ${
                            errors.lastName ? "border-red-500" : "border-gray-300"
                          }`}
                          {...field}
                          onBlur={() => validateField("lastName")}
                        />
                      )}
                    />
                    {errors.lastName && <p className="mt-1 text-xs text-red-500">{errors.lastName.message}</p>}
                  </div>
                </div>

                {/* Contact Information */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                  {/* Phone Number */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                      Phone Number<span className="text-red-500 ml-0.5">*</span>
                    </label>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="tel"
                          placeholder="123-456-7890"
                          className={`w-full p-2.5 text-sm border rounded-md outline-none ${
                            errors.phoneNumber ? "border-red-500" : "border-gray-300"
                          }`}
                          value={field.value}
                          onChange={(e) => {
                            const formattedValue = formatPhoneNumber(e.target.value);
                            field.onChange(formattedValue);
                          }}
                          onBlur={() => validateField("phoneNumber")}
                        />
                      )}
                    />
                    {errors.phoneNumber && <p className="mt-1 text-xs text-red-500">{errors.phoneNumber.message}</p>}
                  </div>

                  {/* Email */}
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                      Email<span className="text-red-500 ml-0.5">*</span>
                    </label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="email"
                          placeholder="example@email.com"
                          className={`w-full p-2.5 text-sm border rounded-md outline-none ${
                            errors.email ? "border-red-500" : "border-gray-300"
                          }`}
                          {...field}
                          onBlur={() => validateField("email")}
                        />
                      )}
                    />
                    {errors.email && <p className="mt-1 text-xs text-red-500">{errors.email.message}</p>}
                  </div>
                </div>

                {/* Card Selection Section */}
                <h4 className="text-lg font-semibold text-[#311664] mb-2 mt-6">Card Selection</h4>

                {/* Card Type Dropdown */}

                <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mb-2">
               

                  {/* Specific Card Dropdown */}
                  <div className="mb-2">
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                    Desired Credit Card
                    <span className="text-red-500 ml-0.5">*</span>
                    <p style={{fontSize:'0.5rem',marginTop:'-1%',color:'grey'}}>We'll also suggest better options based on your profile.</p>
                    </label>
                    <Controller
                      name="specificCard"
                      control={control}
                      render={({ field }) => (
                        <div className="relative">
                          <button
                            type="button"
                            className={`w-full p-2.5 text-sm border rounded-md flex justify-between items-center bg-white ${
                              errors.specificCard ? "border-red-500" : "border-gray-300"
                            }`}
                            onClick={() => setSpecificCardOpen(!specificCardOpen)}
                          >
                            <span>
                              {field.value ? getSelectedName(list, field.value) : "Select a specific card"}
                            </span>
                            <ChevronDown size={16} />
                          </button>
                          {specificCardOpen && (
                            <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-md mt-1 z-10 max-h-48 overflow-y-auto shadow-lg">
                              {list.map((card) => (
                                <div
                                  key={card.id}
                                  className="p-2.5 hover:bg-[#ebe8fc]/30 cursor-pointer"
                                  onClick={() => {
                                    field.onChange(card.card_name);
                                    setSpecificCardOpen(false);
                                    validateField("specificCard");
                                  }}
                                >
                                  {card.card_name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    />
                    {errors.specificCard && <p className="mt-1 text-xs text-red-500">{errors.specificCard.message}</p>}
                  </div>
                </div>
              

                {/* Financial Information Section */}
                <h4 className="text-lg font-semibold text-[#311664] mb-2 mt-6">Financial Information</h4>

                {/* Annual Income Input */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-2">
                  <div className="mb-2">
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                      Monthly Income (₹)<span className="text-red-500 ml-0.5">*</span>
                    </label>
                    <Controller
                      name="annualIncome"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          placeholder="e.g. 500,000"
                          className={`w-full p-2.5 text-sm border rounded-md outline-none ${
                            errors.annualIncome ? "border-red-500" : "border-gray-300"
                          }`}
                          value={field.value}
                          onChange={(e) => {
                            // Format as currency
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            const formattedValue = value ? Number.parseInt(value).toLocaleString() : "";
                            field.onChange(formattedValue);
                          }}
                          onBlur={() => validateField("annualIncome")}
                        />
                      )}
                    />
                    {errors.annualIncome && <p className="mt-1 text-xs text-red-500">{errors.annualIncome.message}</p>}
                  </div>

                  <div className="mb-2">
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                      Monthly Emi (₹)<span className="text-red-500 ml-0.5">*</span>
                    </label>
                    <Controller
                      name="monthlyemi"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          placeholder="e.g. 500,000"
                          className={`w-full p-2.5 text-sm border rounded-md outline-none ${
                            errors.monthlyemi ? "border-red-500" : "border-gray-300"
                          }`}
                          value={field.value}
                          onChange={(e) => {
                            // Format as currency
                            const value = e.target.value.replace(/[^0-9]/g, "");
                            const formattedValue = value ? Number.parseInt(value).toLocaleString() : "";
                            field.onChange(formattedValue);
                          }}
                          onBlur={() => validateField("monthlyemi")}
                        />
                      )}
                    />
                  </div>
                </div> 

                {/* Profession Information Section */}
                <h4 className="text-lg font-semibold text-[#311664] mb-2 mt-6">Profession Information</h4>
                
                {/* Employment Type Dropdown */}
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700">
                    Employment Type<span className="text-red-500 ml-0.5">*</span>
                  </label>
                  <Controller
                    name="employmentType"
                    control={control}
                    render={({ field }) => (
                      <div className="relative">
                        <select
                          className={`w-full p-2.5 text-sm border rounded-md outline-none appearance-none ${
                            errors.employmentType ? "border-red-500" : "border-gray-300"
                          }`}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setEmploymentType(e.target.value);
                            validateField("employmentType");
                          }}
                          onBlur={() => validateField("employmentType")}
                        >
                          <option value="">Select employment type</option>
                          <option value="salaried">Salaried</option>
                          <option value="self-employed">Self-Employed</option>
                          <option value="business-owner">Business Owner</option>
                          <option value="student">Student</option>
                          <option value="retired">Retired</option>
                        </select>
                        <ChevronDown size={16} className="absolute right-3 top-3 text-gray-400 pointer-events-none" />
                      </div>
                    )}
                  />
                  {errors.employmentType && <p className="mt-1 text-xs text-red-500">{errors.employmentType.message}</p>}
                </div>

                {/* Company Name Input (Conditional) */}
                {employmentType === "salaried" && (
                  <div className="mb-4 relative">
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                      Company Name<span className="text-red-500 ml-0.5">*</span>
                    </label>
                    <Controller
                      name="companyName"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            type="text"
                            placeholder="Enter your company name"
                            className={`w-full p-2.5 text-sm border rounded-md outline-none ${
                              errors.companyName ? "border-red-500" : "border-gray-300"
                            }`}
                            value={companyName}
                            onChange={handleCompanyNameChange}
                            onBlur={() => {
                              setTimeout(() => setShowCompanySuggestions(false), 200);
                              validateField("companyName");
                            }}
                            onFocus={() => companyName.length > 0 && setShowCompanySuggestions(true)}
                          />
                          {showCompanySuggestions && companySuggestions.length > 0 && (
                            <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-md mt-1 z-10 max-h-48 overflow-y-auto shadow-lg">
                              {companySuggestions.map((company, index) => (
                                <div
                                  key={index}
                                  className="p-2.5 hover:bg-[#ebe8fc]/30 cursor-pointer"
                                  onClick={() => selectCompany(company)}
                                >
                                  {company}
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                    />
                    {errors.companyName && <p className="mt-1 text-xs text-red-500">{errors.companyName.message}</p>}
                  </div>
                )}

                {/* Consent Section */}
                <div className="mt-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
                  {/* Main Consent Checkbox (Required) */}
                  <div className="flex items-start mb-2">
                    <div className="flex items-center h-5">
                      <Controller
                        name="consent"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="checkbox"
                            className={`h-4 w-4 cursor-pointer ${errors.consent ? "border-red-500" : ""}`}
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                              validateField("consent");
                            }}
                            id="consent-checkbox"
                          />
                        )}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="consent-checkbox" className="text-gray-700 cursor-pointer">
                        {showFullConsent ? consentFullText : consentShortText}
                        <button
                          type="button"
                          className="text-[#3a868b] text-xs font-medium inline-flex items-center ml-1 focus:outline-none"
                          onClick={() => setShowFullConsent(!showFullConsent)}
                        >
                          {showFullConsent ? "Read Less" : "Read More"}
                          <ChevronRight size={12} className="ml-0.5" />
                        </button>
                      </label>
                      {errors.consent && <p className="mt-1 text-xs text-red-500">{errors.consent.message}</p>}
                    </div>
                  </div>

                  {/* Marketing Consent Checkbox (Optional) */}
                  <div className="flex items-start pt-3 border-t border-gray-200">
                    <div className="flex items-center h-5">
                      <Controller
                        name="marketingConsent"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="checkbox"
                            className={`h-4 w-4 cursor-pointer ${errors.marketingConsent ? "border-red-500" : ""}`}
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                              validateField("marketingConsent");
                            }}
                            id="consent-checkbox"
                          />
                        )}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="marketing-consent-checkbox" className="text-gray-700 cursor-pointer">
                        {showFullMarketingConsent ? marketingConsentFullText : marketingConsentShortText}
                        <button
                          type="button"
                          className="text-[#3a868b] text-xs font-medium inline-flex items-center ml-1 focus:outline-none"
                          onClick={() => setShowFullMarketingConsent(!showFullMarketingConsent)}
                        >
                          {showFullMarketingConsent ? "Read Less" : "Read More"}
                          <ChevronRight size={12} className="ml-0.5" />
                        </button>
                      </label>
                      {errors.marketingConsent && <p className="mt-1 text-xs text-red-500">{errors.marketingConsent.message}</p>}
                    </div>
                  </div>
                </div>

                {/* Submit Button */}
                <div className="experianLogo" style={{ margin: 'auto', marginTop: "2%" }}>
                  <img src={experian} alt="" />
                </div>
                <button
                  type="submit"
                  className={`w-full py-3 mt-6 bg-[#3a868b] text-white font-semibold rounded-md text-base ${
                    isLoading ? "opacity-70 cursor-not-allowed" : "hover:bg-[#347379]"
                  }`}
                  disabled={isLoading}
                >
                  {isLoading ? "Checking..." : "Check My Chances"}
                </button>
              </form>
            </motion.div>
          </div>
        </div>
      </section>
      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        backdrop={true}
        keyboard={false}
        style={{ backgroundColor: "transparent", zIndex: 90000 }}
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <div className="row" id="otpCon">
            <div className="col-lg-6 px-0">
              <img src={login} alt="" style={{ objectFit: "cover" }} />
            </div>

            <div className="col-lg-6" id="otp-section">
              <div>
                <p className="m-0 p-0 otp-text ">Enter OTP</p>
                <p className="m-0 p-0 otp-desc mt-2">
                  Please enter the one-time password (OTP) sent on the given
                  number - {mbl}
                </p>
              </div>

              <div className="d-flex justify-content-between otp-input-con">
  {otpField.map((digit, index) => (
    <Form.Control
      type="number"
      className="otp-inputs"
      key={index}
      maxLength="1"
      value={digit}
      onChange={(e) => otpHandleChange(e.target, index)}
      onKeyDown={(e) => handleKeyDown(e, index)}
      ref={(el) => (inputRefs.current[index] = el)}
    />
  ))}
              </div>

              <div className="otpBtn">
                {!otpLoader ? (
                  <Button
                    className="button-class"
                    id="verify-btn"
                    onClick={otpSubmit}
                  >
                    Verify
                  </Button>
                ) : (
                  <Button
                    className="button-class"
                    id="verify-btn"
                    style={{ backgroundColor: "#633376" }}
                  >
                    <ColorRing
                      visible={otpLoader}
                      height="30"
                      width="30"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{ backgroundColor: "#633376" }}
                      wrapperClass="color-ring-wrapper"
                      style={{ color: "white", backgroundColor: "#633376" }}
                      colors={[
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                      ]}
                    />
                  </Button>
                )}
                <Button
                  className="button-class"
                  id="resend-otp"
                  onClick={() => handleResendOtp()}
                >
                  Resend OTP
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-right"
        style={{zIndex:9000000}}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default LoanCheckerForm;
import React from "react";
import "./Section.css";
// import { Button } from "@/components/ui/button";
// import { Card, CardContent, CardHeader, p } from "@/components/ui/card";
import { CheckCircle } from "lucide-react";

import { Card, CardContent, CardHeader, Typography } from "@mui/material";

export default function EducationalContent() {
  return (
    <section className="space-y-4 py-6">
      <h2 className="text-xl sm:text-2xl font-bold text-[#26292b]">
        Understanding APR
      </h2>
      <div className="grid sm:grid-cols-2 gap-4 items-start">
        <Card
          sx={{
            backgroundColor: "#EBE8FC",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <CardContent sx={{ backgroundColor: "transparent" }}>
            <p
              className="card_pare text-lg sm:text-xl text-[#59266d] text-start"
              style={{ fontWeight: 500 }}
            >
              What is APR?
            </p>
          </CardContent>
          <CardContent style={{ backgroundColor: "transparent" }}>
            <p className="card_pare text-sm sm:text-base text-start">
              Annual Percentage Rate (APR) is the yearly cost of borrowing,
              including interest and fees, expressed as a percentage.
            </p>
          </CardContent>
        </Card>
        <Card
          sx={{
            backgroundColor: "#EBE8FC",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <CardContent sx={{ backgroundColor: "transparent" }}>
            <p
              className="card_pare text-lg sm:text-xl text-[#59266d] "
              style={{ fontWeight: 500 }}
            >
              Why APR Matters
            </p>
          </CardContent>
          <CardContent sx={{ backgroundColor: "transparent" }}>
            <p className="card_pare text-sm sm:text-base mb-4">
              Your interest rate is not the full cost of your loan – APR
              includes hidden fees, processing charges, and more. Checking it
              now ensures you don't overpay later!
            </p>
            <div
              className="grid gap-3"
              style={{ backgroundColor: "transparent" }}
            >
              <Card
                style={{ backgroundColor: "transparent", borderRadius: "8px" }}
                className="bg-white"
              >
                <CardContent
                  className="d-flex items-start align-items-center"
                  style={{
                    background: "#fff",
                    padding: "12px",
                  }}
                >
                  <CheckCircle
                    className="text-green-500 mr-2 h-5 w-5 mt-0.5 flex-shrink-0"
                    style={{ backgroundColor: "transparent" }}
                  />
                  <p className=" card_pare text-sm ">
                    Find out the true cost of your loan before applying.
                  </p>
                </CardContent>
              </Card>
              <Card
                style={{ backgroundColor: "transparent", borderRadius: "8px" }}
                className="bg-white"
              >
                <CardContent
                  className="d-flex items-start align-items-center"
                  style={{
                    background: "#fff",
                    padding: "12px",
                  }}
                >
                  <CheckCircle
                    className="text-green-500 mr-2 h-5 w-5 mt-0.5 flex-shrink-0"
                    style={{ backgroundColor: "transparent" }}
                  />
                  <p className="card_pare text-sm">
                    Avoid hidden processing fees and overcharges.
                  </p>
                </CardContent>
              </Card>
              <Card
                style={{ backgroundColor: "transparent", borderRadius: "8px" }}
                className="bg-white"
              >
                <CardContent
                  className="d-flex items-start align-items-center"
                  style={{
                    background: "#fff",
                    padding: "12px",
                  }}
                >
                  <CheckCircle
                    className="text-green-500 mr-2 h-5 w-5 mt-0.5 flex-shrink-0"
                    style={{ backgroundColor: "transparent" }}
                  />
                  <p className=" card_pare text-sm">
                    Compare loan offers with real APR transparency.
                  </p>
                </CardContent>
              </Card>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="text-center mt-6">
        <button
          className="bg-[#59266d] hover:bg-[#3a868b] text-white rounded-full px-4 py-1.5 text-sm sm:text-base "
          style={{}}
        >
          Check My APR & Get the Best Loan Rate
        </button>
      </div>
    </section>
  );
}

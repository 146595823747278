
import { motion } from "framer-motion"
import { Shield, Clock, Zap  } from "lucide-react"

export default function HeroSection() {
  return (
       <section className="bg-[#3A1E6D] text-white py-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight mb-6">
            Check Your Loan Rate Instantly –<span className="text-2xl md:text-4xl font-bold leading-tight m">Without Impacting Your Credit Score!</span> 

            </h1>
            <p className="text-lg md:text-xl opacity-90 mb-6">
            Get instant insights on <span className="font-bold"> approval chances </span>and estimated loan offers. Simplify your loan journey with accurate, fast results!

            </p>
            <p className="text-lg opacity-80 mb-8">
            Know your estimated interest rate and loan terms before applying.

            </p>
            <button
              className="bg-[#3BBAA0] hover:bg-[#2A9D87] text-white px-6 py-2 rounded-md flex items-center mx-auto"
              onClick={() => document.getElementById("rate-check-form")?.scrollIntoView({ behavior: "smooth" })}
            >
             Check My Rate Now
            </button>
          </div>
        </div>
      </section>
    
  )
}


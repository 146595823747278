import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from ".././assets/Images/mLogo.webp";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: '#F6F6F6',
  boxShadow: 24,
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 2,
  paddingRight: 2,
  border: 0,
};

const Question1 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { category } = location.state || {};

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Load stored value and determine active state
  const [value, setValue] = useState(() => {
    return localStorage.getItem("occupation") || "Salaried";
  });

  const [active, setActive] = useState(() => {
    return localStorage.getItem("occupation") === "Self-Employed" ? 1 : 0;
  });

  useEffect(() => {
    // Save value to localStorage whenever it changes
    localStorage.setItem("occupation", value);
  }, [value]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <div className="min-h-[100vh] w-full pt-4 md:pt-8 px-4 md:px-6">
        {/* Header Navigation */}
        <div className="container make-center" style={{ display: "flex", justifyContent: "space-between", paddingLeft: "4%", paddingRight: "4%" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: 8, cursor: 'pointer' }} onClick={() => navigate("/select-credit-score")}>
            <FaArrowLeftLong style={{ fontSize: 20, color: "#8A8A8A" }} />
            <p style={{ fontSize: 16, color: "#8A8A8A" }}>Back</p>
          </div>
          <IoClose style={{ fontSize: 24, color: "#8A8A8A", cursor: 'pointer' }} onClick={handleOpen} />
        </div>

        {/* Main Content */}
        <div className="max-w-2xl mx-auto mt-8 md:mt-12">
          <h1 className="text-center text-2xl md:text-3xl font-semibold text-black mb-8">
            What is your Occupation?
          </h1>

          {/* Occupation Buttons */}
          <div className="flex flex-col sm:flex-row justify-center gap-4 md:gap-5 px-4">
            <button
              onClick={() => {
                setActive(0);
                setValue("Salaried");
              }}
              className={`button-class py-3 px-6 w-full sm:w-48 rounded-lg shadow-md transition-all 
                ${active === 0 ? "bg-[#E7E4F9] border-2 border-[#59266D]" : "bg-white hover:bg-gray-50"}`}
            >
              Salaried
            </button>

            <button
              onClick={() => {
                setActive(1);
                setValue("Self-Employed");
              }}
              className={`button-class py-3 px-6 w-full sm:w-48 rounded-lg shadow-md transition-all 
                ${active === 1 ? "bg-[#E7E4F9] border-2 border-[#59266D]" : "bg-white hover:bg-gray-50"}`}
            >
              Self-Employed
            </button>
          </div>

          {/* Next Button */}
          <div className="mt-8 md:mt-12 flex justify-center px-4">
            <button
              style={{ fontFamily: "Archivo" }}
              onClick={() =>
                navigate("/monthly-income", { state: { occupation: value, category } })
              }
              className=" button-class next-button"
            >
              NEXT
            </button>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <img src={logo} style={{ width: 40, height: 40 }} alt="Logo" />
            <IoClose onClick={handleClose} style={{ fontSize: 24, cursor: 'pointer' }} />
          </div>
          <Typography variant="h6" style={{ color: '#59266D', fontSize: 24, fontWeight: 700, marginTop: 16 }}>
            Wait! Your Card is Just a Step Away!
          </Typography>
          <Typography sx={{ mt: 2 }} style={{ color: "#8A8A8A", fontSize: 18, fontWeight: 400 }}>
            Complete the form to receive the best credit card tailored to your needs.
          </Typography>
          <div
            style={{ backgroundColor: '#59266D', padding: 15, color: '#FFFFFF', borderRadius: 10, textAlign: 'center', marginTop: '4%', fontWeight: 700, fontSize: 16, cursor: "pointer" }}
            onClick={handleClose}
          >
            Keep Going
          </div>
          <div
            style={{ backgroundColor: '#F6F6F6', padding: 15, color: '#59266D', borderRadius: 10, textAlign: 'center', marginTop: '4%', fontWeight: 700, fontSize: 16, border: '1px solid #59266D', cursor: "pointer" }}
            onClick={() => navigate("/creditcard-recommendation")}
          >
            No Thanks
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Question1;

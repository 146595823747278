import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import MetadataContext from "../MetadataContext";

const IndusindEligibility = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const infoListStyle = {
    color: "#1F1F1F",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "20px",
    paddingLeft: "13px",
    listStyleType: "circle",
    marginBottom: "0px",
  };

  const criteriaData = [
    {
      factor: "Income",
      requirement:
        "Minimum ₹25,000/month (varies based on the loan amount and applicant's location).",
      impact:
        "Higher income enhances eligibility by reducing the risk of default.",
    },
    {
      factor: "Credit Score",
      requirement: "A credit score of 750 or above is preferred.",
      impact:
        "A higher score improves chances of approval and may qualify for better interest rates.",
    },
    {
      factor: "Employment Status",
      requirement:
        "Salaried individuals with at least 2 years of work experience; self-employed with a stable business income.",
      impact:
        "Stable employment or business history signals financial reliability to lenders.",
    },
    {
      factor: "Age",
      requirement:
        "Between 21 and 60 years for salaried; up to 65 years for self-employed.",
      impact:
        "Applicants closer to retirement age may face stricter criteria or shorter loan tenures.",
    },
    {
      factor: "Fixed Obligations to Income Ratio (FOIR)",
      requirement: "Below 40%-50%, including EMI obligations.",
      impact:
        "A lower FOIR indicates better repayment capacity, increasing the likelihood of approval.",
    },
  ];

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Check IndusInd Bank Personal Loan Eligibility Instantly",
      pageDescription:
        "Check your IndusInd Bank personal loan eligibility instantly. Get fast approvals, flexible EMIs, and hassle-free online application. Apply for a personal loan today!",
      pageKeywords:
        "IndusInd Bank Personal Loan, Personal Loan Eligibility, Instant Loan Eligibility Check, IndusInd Loan Eligibility Calculator, Personal Loan Online Application, Quick Personal Loan Approval, IndusInd Bank Loan Calculator, Check Personal Loan Eligibility, Instant Personal Loan Approval, IndusInd Bank Loan Offers, Personal Loan EMI Calculator, Online Loan Eligibility Check, IndusInd Instant Loan Services, Best Personal Loan Rates, Loan Eligibility Criteria",
      canonicalUrl:
        "https://minemi.ai/loanpage/personalloan/loan-eligibilty-criteria/indusind-loan-eligiblity",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              <Link
                to={"/loanpage/personalloan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Personal Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/personalloan/loan-eligibilty-criteria"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Personal Loan Check Eligibility
              </Link>{" "}
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Check Eligibility with IndusInd Bank
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "55px",
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            IndusInd Bank Personal Loan Eligibility
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Check your Eligibility for an IndusInd Bank Personal Loan. Learn
            more about the credit score, income, age and other categories to
            qualify for the best loan offers from IndusInd Bank.
          </p>
        </div>
        <div
          className=""
          style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}
        >
          <h2
            style={{
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 28,
              color: "#1F1F1F",
              marginTop: 30,
              lineHeight: 1.2,
            }}
          >
            Personal Loan Eligibility Criteria
          </h2>
          <p
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
              color: "#1F1F1F",
              marginTop: 15,
            }}
          >
            Explore the essential eligibility requirements for an IndusInd
            personal loan. From age and income thresholds to employment type and
            credit score benchmarks, learn what it takes to qualify for the best
            loan offers tailored to your financial needs.
          </p>
          <div
            style={{
              border: "1px solid #D9D9D9",
              borderRadius: 24,
              marginTop: 24,
              backgroundColor: "white",
              overflow: "hidden",
              maxWidth: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                borderTopLeftRadius: 24,
                borderTopRightRadius: 24,
              }}
            >
              <div
                style={{
                  backgroundColor: "#E7E4F9",
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 16,
                  lineHeight: "20px",
                  color: "#59266D",
                  padding: "16px",
                  borderBottom: "1px solid #D9D9D9",
                  borderTopLeftRadius: 24,
                  borderTopRightRadius: 24,
                  textAlign: "center",
                }}
              >
                IndusInd Loan Eligibility Criteria
              </div>
            </div>

            <div
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                width: "100%",
                overflow: "auto",
              }}
            >
              <table
                className="table-class"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 12,
                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  textAlign: "left",
                }}
              >
                <thead className="thead-class">
                  <tr>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Eligibility Factor
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Minimum Requirement
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "16px",
                        textAlign: "left",
                        verticalAlign: "top",
                        color: "#59266D",
                      }}
                    >
                      Impact on Loan Eligibility
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {criteriaData.map((row, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#f2f2f2" : "white",
                      }}
                    >
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        {row.factor}
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {row.requirement}
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        {row.impact}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Eligibility Criteria for IndusInd Bank Personal Loan{" "}
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Age:&nbsp;
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "black",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • Salaried:{" "}
                  </span>{" "}
                  21 to 60 years
                </div>{" "}
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "black",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • Self-Employed:
                  </span>{" "}
                  21 to 65 years
                </div>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "black",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    •
                  </span>{" "}
                  (Age at the time of loan maturity should not exceed the
                  specified limits.)
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Income:&nbsp;
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "black",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • Salaried:{" "}
                  </span>{" "}
                  Minimum monthly income of ₹25,000 (varies by location and loan
                  amount).
                </div>{" "}
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "black",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • Self-Employed:
                  </span>{" "}
                  Adequate and steady business income as validated by income tax
                  returns and bank statements.
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Employment Status:&nbsp;
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "black",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • Salaried:{" "}
                  </span>{" "}
                  Minimum of 2 years of total work experience with at least 1
                  year in the current organization.
                </div>{" "}
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "black",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • Self-Employed:
                  </span>{" "}
                  At least 3 years of stable business operation with verified
                  income documentation.
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Credit Score:{" "}
                </span>
                A score of 750 or above is preferred to demonstrate good
                creditworthiness. Lower scores may reduce chances of approval.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Loan Tenure and Amount:{" "}
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "black",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • Loan Amount:{" "}
                  </span>{" "}
                  ₹50,000 to ₹15,00,000 (subject to income and repayment
                  capacity)
                </div>{" "}
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "black",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • Tenure:
                  </span>{" "}
                  Flexible, ranging from 12 to 60 months.
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Fixed Obligations to Income Ratio (FOIR):{" "}
                </span>
                FOIR should be below 50%, including existing EMI obligations.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Banking Relationship:{" "}
                </span>
                Existing IndusInd Bank customers may benefit from preferential
                terms or faster approval. These eligibility factors are
                indicative and may vary based on IndusInd Bank’s internal
                policies and applicant profile
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Documents Required for IndusInd Bank Personal Loan{" "}
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Identity Proof (any one):{" "}
                </span>
                <ul style={infoListStyle}>
                  <li>PAN Card</li>
                  <li>Aadhaar Card</li>
                  <li>Passport</li>
                  <li>Driving License</li>
                  <li className="mb-0">Voter ID</li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Address Proof (any one):
                </span>
                <ul style={infoListStyle}>
                  <li>Aadhaar Card</li>
                  <li>Passport</li>
                  <li>Utility Bills (Electricity, Water, or Gas)</li>
                  <li className="mb-0">Rental Agreement (if applicable)</li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Income Proof:
                </span>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "black",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • For Salaried Individuals:
                  </span>{" "}
                  <ul
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                      paddingLeft: "28px",
                      listStyleType: "circle",
                      marginBottom: "0px",
                    }}
                  >
                    <li>Latest 3 months’ salary slips</li>
                    <li>Form 16 or Income Tax Returns (ITR)</li>
                    <li className="mb-0">
                      Latest 6 months’ bank statements showing salary credit
                    </li>
                  </ul>
                </div>
                <div>
                  <span
                    style={{
                      paddingLeft: "1%",
                      color: "black",
                      color: "#1F1F1F",
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    • For Self-Employed Individuals:
                  </span>{" "}
                  <ul
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                      paddingLeft: "28px",
                      listStyleType: "circle",
                      marginBottom: "0px",
                    }}
                  >
                    <li>Income Tax Returns for the last 2-3 years</li>
                    <li>
                      Audited financial statements (Profit & Loss and Balance
                      Sheet)
                    </li>
                    <li className="mb-0">
                      Business continuity proof (Trade license, GST
                      registration, etc.)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Photograph:{" "}
                </span>
                Recent passport-sized photograph
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Loan Application Form:
                </span>
                Duly filled and signed loan application form as per IndusInd
                Bank’s requirements.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Employment Proof (For Salaried):
                </span>
                Appointment letter or ID card issued by the employer. This
                documentation list may vary based on the applicant's profile and
                IndusInd Bank’s policies
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            How to Improve Your Eligibility for IndusInd Bank Personal Loan{" "}
          </div>

          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  Maintain a High Credit Score:
                </span>
                <ul style={infoListStyle}>
                  <li>
                    Aim for a credit score of 750 or higher by paying all EMIs
                    and credit card dues on time.
                  </li>
                  <li className="mb-0">
                    Avoid excessive credit inquiries that could lower your
                    score.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Showcase Stable Income:&nbsp;
                </span>
                <ul style={infoListStyle}>
                  <li>
                    Ensure your income meets or exceeds the bank's minimum
                    requirement
                  </li>
                  <li className="mb-0">
                    Provide complete and accurate income proof, such as salary
                    slips or audited financial statements.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Lower Your FOIR (Fixed Obligations to Income Ratio):
                </span>
                <ul style={infoListStyle}>
                  <li>
                    Reduce your existing debt obligations to improve repayment
                    capacity.
                  </li>
                  <li className="mb-0">
                    Pay off smaller loans or consolidate debt to keep your FOIR
                    under 50%.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Choose a Suitable Loan Amount:
                </span>
                <ul style={infoListStyle}>
                  <li>
                    Apply for a loan amount that aligns with your repayment
                    capacity and income level.
                  </li>
                  <li className="mb-0">
                    Avoid overleveraging to maintain credibility with the bank.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Strengthen Your Employment Profile:
                </span>
                <ul style={infoListStyle}>
                  <li>
                    For salaried individuals, ensure at least one year of work
                    experience in the current job.
                  </li>
                  <li className="mb-0">
                    Self-employed individuals should demonstrate business
                    stability through consistent income over the past 2-3 years.
                  </li>
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  {" "}
                  Build a Relationship with IndusInd Bank:
                </span>
                <ul style={infoListStyle}>
                  <li>
                    Maintain an active account or other banking relationships
                    with IndusInd Bank.
                  </li>
                  <li className="mb-0">
                    Existing customers often enjoy better terms and quicker
                    approvals.
                  </li>
                </ul>
              </div>
            </div>
            <p style={{ color: "#1F1F1F", fontWeight: 500, fontSize: 16 }}>
              These steps can enhance your profile and maximize the chances of
              approval for an IndusInd Bank personal loan
            </p>
          </div>

          <p
            style={{ fontSize: 16, lineHeight: "20px", color: "#1f1f1f" }}
            className="mt-4"
          >
            For more information please check{" "}
            <a
              href={
                "https://www.indusind.com/in/en/personal/loans/personal-loan.html"
              }
              target="_blank"
            >
              IndusInd Bank Eligibility
            </a>
          </p>

          <div
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            FAQs for IndusInd Bank Personal Loan Eligibility
          </div>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What is the minimum income requirement to apply for an IndusInd
                Bank personal loan?
              </AccordionSummary>
              <AccordionDetails>
                The minimum income requirement is ₹25,000 per month for salaried
                individuals. For self-employed applicants, a stable business
                income validated by financial documents is required.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{ fontWeight: 600, borderRadius: 12 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                What credit score is needed for an IndusInd Bank personal loan?
              </AccordionSummary>
              <AccordionDetails>
                A credit score of 750 or above is preferred for better approval
                chances and competitive interest rates. Lower scores may result
                in stricter terms or rejection.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{ fontWeight: 600, borderRadius: 12 }}
              >
                Can I apply for an IndusInd Bank personal loan if I am
                selfemployed?
              </AccordionSummary>
              <AccordionDetails>
                Yes, self-employed individuals can apply. They need to
                demonstrate a stable income with at least 3 years of business
                continuity supported by income tax returns and financial
                statements.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                backgroundColor: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{ fontWeight: 600, borderRadius: 12 }}
              >
                What is the age criterion to be eligible for an IndusInd Bank
                personal loan?
              </AccordionSummary>
              <AccordionDetails>
                Applicants must be between 21 and 60 years for salaried
                individuals and up to 65 years for self-employed individuals at
                the time of loan maturity.
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br /> and tailored just for you—apply now to see what you
                qualify for!
              </p>
              <a
                href="https://minemi.ai/personal-loan/check-eligibility"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                  backgroundColor: "transparent",
                  marginTop: "2%",
                  cursor: "pointer",
                }}
              >
                <div
                  className="buttom-loan-eligiblity-one"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 40,
                    paddingRight: 40,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                   Check Your Eligibility Now
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndusindEligibility;

import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import { Link } from "react-router-dom";
import MetadataContext from "../../MetadataContext";

const GoldLoanInterestRate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        "Gold Loan Interest Rates 2025 | Compare Top Banks for Jewel Loan",
      pageDescription:
        "Compare gold loan interest rates for 2025 from top Indian banks. Find the best gold loan schemes, EMI options, and jewel loan rates per gram.",
      pageKeywords:
        "gold loan interest rates, gold loan rate today, jewel loan interest rate, gold loan per gram, lowest gold loan interest rate, gold loan emi and interest payment, jewel loan, gold loan interest rate comparison, gold loan interest rates of top 10 bank,  gold loan schemes 2025, best bank for gold loan, SBI gold loan interest rate, ICICI gold loan interest rate, HDFC gold loan interest rate, jewel loan rate per gram, gold loan emi calculator, gold loan without cibil score",
      canonicalUrl:
        "https://minemi.ai/loanpage/gold-loan/gold-loan-interest-rates",
    });
  }, [setMetadata]);
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/gold-loan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Gold Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Gold Loan Interest Rates in India
              </Link>{" "}
            </p>
          </div>

          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontSize: 40,
              color: "#59266D",
              backgroundColor: "#E7E4F9",
              lineHeight: 1,
            }}
          >
            Gold Loan Interest Rates in India – Compare Top 10 Banks (2025)
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Compare the latest gold loan interest rates offered by the top 10
            banks in India for 2025. Find the best rates, terms, and benefits to
            meet your financial needs.
          </p>
        </div>

        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
          <h2 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 30 }}>
            Gold Loan Interest Rates: A Comprehensive Guide for 2025
          </h2>

          <div>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px ",
              }}
            >
              Gold loans have become one of the fastest-growing and most
              preferred credit products in India. With minimal documentation,
              quick disbursals, and lower interest rates than unsecured loans,
              gold loans are an attractive option for those needing immediate
              funds.
            </p>
          </div>
          <div>
            <p
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px ",
              }}
            >
              As gold prices continue to rise, leveraging gold for loans can
              unlock liquidity without selling the asset. This article covers{" "}
              <strong>gold loan interest rates</strong>,{" "}
              <strong>comparison of top banks</strong>, and essential factors to
              consider in 2025.
            </p>
          </div>

          <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
            Why Opt for a Gold Loan?
          </h3>
          <div>
            <p
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px ",
              }}
            >
              A gold loan allows you to pledge your gold jewelry or coins to
              secure funds, with exible repayment options and minimal
              requirements.
            </p>
            <h3
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontSize: 18,
                marginTop: 20,
              }}
            >
              Key Benets of Gold Loans:
            </h3>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    Quick Disbursal:{" "}
                  </span>
                  Loan disbursed within hours.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    Low Interest Rates:{" "}
                  </span>
                  Gold loans start at 7.5% p.a., signicantly lower than personal
                  loans.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    Flexible Repayment:{" "}
                  </span>
                  Choose between EMI, bullet repayment, or interest-only
                  schemes.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    No Credit Check:{" "}
                  </span>
                  Eligibility is based on gold value, not your credit score.
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
              Factors Aecting Gold Loan Interest Rates
            </h3>
            <h4 style={{ color: "#1F1F1F", fontSize: 20, marginTop: 20 }}>
              1. Gold Price (per gram):
            </h4>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Higher gold prices increase the loan amount.
            </p>
          </div>

          <div>
            <h4 style={{ color: "#1F1F1F", fontSize: 20, marginTop: 20 }}>
              2. Loan-to-Value (LTV) Ratio:{" "}
            </h4>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              RBI allows banks to lend up to{" "}
              <strong>75% of the gold’s value</strong>.
            </p>
          </div>
          <div>
            <h4 style={{ color: "#1F1F1F", fontSize: 20, marginTop: 20 }}>
              3. Tenure:
            </h4>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Shorter tenures generally attract lower interest rates.
            </p>
          </div>
          <div>
            <h4 style={{ color: "#1F1F1F", fontSize: 20, marginTop: 20 }}>
              4.Bank Policy:
            </h4>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Banks provide special rates during festive seasons or promotions.
            </p>
          </div>
          <div>
            <h4 style={{ color: "#1F1F1F", fontSize: 20, marginTop: 20 }}>
              5.Repayment Mode:{" "}
            </h4>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              EMI-based gold loans often have slightly higher rates than bullet
              repayment options.
            </p>
          </div>

          <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
            RBI’s Latest Guidelines (2024) on Gold Loans
          </h3>
          <div>
            <h3
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "20px ",
              }}
            >
              In September 2024, the{" "}
              <strong>Reserve Bank of India (RBI)</strong> issued guidelines
              emphasizing responsible lending and borrower protection in the
              gold loan sector.
            </h3>
            <h5 style={{ color: "#1F1F1F", fontSize: 18, marginTop: 20 }}>
              Key Highlights:
            </h5>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    Valuation Transparency:{" "}
                  </span>
                  Banks must conduct gold valuation in the borrower’s presence.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    LTV Monitoring:{" "}
                  </span>
                  Strict adherence to the 75% LTV ratio.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    Auction Transparency:{" "}
                  </span>
                  Clear auction guidelines for pledged gold in case of default.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    End-Use Monitoring:{" "}
                  </span>
                  Funds should be used for declared purposes to minimize risk.
                </div>
              </div>
            </div>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                color: "#1F1F1F",
                fontWeight: 400,
                paddingTop: "20px",
              }}
            >
              These regulations aim to enhance transparency and protect
              borrowers from unfair practices.
            </p>
          </div>

          <div>
            <h3
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: 22,
                color: "#1f1f1f",
                marginTop: 20,
              }}
            >
              Gold Loan Interest Rate Comparison – Top 10 Banks (2025)
            </h3>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: 16,
                lineHeight: "20px ",
                color: "#1f1f1f",
                fontWeight: 400,
              }}
            >
              Here’s a comparison of the gold loan interest rates, loan tenures,
              and features oered by the leading banks and NBFCs in India.
            </p>
          </div>

          {/* table data */}
          <div id="types-of-bt">
            <div id="eligibility-criteria">
              <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
                Gold Loan Interest Rate Comparison – Top 10 Banks and NBFCs
                (2025)
              </h3>
              <div
                className=" pb-2 "
                style={{
                  color: "#1F1F1F",
                }}
              >
                <p
                  style={{
                    fontFamily: "'Archivo',sans-serif",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 400,
                  }}
                >
                  To help you make an informed decision, here's a detailed table
                  comparing the gold loan interest rates, loan amounts,
                  repayment options, processing fees, and LTV ratios oered by
                  leading banks and NBFCs in India.
                </p>
              </div>
            </div>

            <div
              id="bt-types-table"
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
                lineHeight: "20px ",
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                      }}
                    >
                      Bank/NBFC
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Interest Rate (p.a.)
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Loan Amount
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Repayment Options
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Processing Fees
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      LTV Ratio
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      State Bank of India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      7.5% – 8.5%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹20,000 – ₹50 Lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      EMI / Bullet Payment
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      0.50% or ₹500 (Min.)
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      75%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      HDFC Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.0% – 9.5%8.75% onwards
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹25,000 – ₹1 Crore
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      EMI / InterestOnly
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1% or ₹1,000 (Min.)
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      75%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      ICICI Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      7.2% – 8.8%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹10,000 – ₹1 Crore
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Flexible Payment / EMI
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹750 – ₹1,500
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      75%
                    </td>
                  </tr>

                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Axis Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.25% – 9.5%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹25,000 – ₹50 Lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      EMI / Bullet Payment
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹500 or 1%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      75%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Punjab National Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.1% – 9.1%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹10,000 – ₹25 Lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      EMI / InterestOnly
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      0.75%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      75%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Bank of Baroda
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      7.75% – 8.85%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹15,000 – ₹50 Lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Bullet / EMI Up to 20 years
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      0.50% of Loan Amount
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      75%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Canara Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      7.65% – 8.75%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹25,000 – ₹25 Lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Interest-Only
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹750 Flat
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      75%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Union Bank of India
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      7.9% – 9.0%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹20,000 – ₹40 Lakh
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Bullet / EMI
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹500 or 1%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      75%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Muthoot Finance
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      8.25% – 12%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹10,000 – ₹1.5 Crore
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Bullet / Flexible
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      75%
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Manappuram Finance
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      9.0% – 12.5%
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      ₹10,000 – ₹1 Crore
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Bullet Payment
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      1% or ₹500
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      75%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p
              className="pt-3"
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
              }}
            >
              This comparison allows borrowers to easily evaluate which bank or
              NBFC provides the most suitable gold loan options in terms of
              interest rates, loan amounts, and repayment exibility. Be sure to
              consider all factors, including processing fees and LTV ratios,
              when choosing the best t for your nancial needs.
            </p>
          </div>

          <div>
            <div
              style={{
                marginTop: 20,
              }}
            >
              <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
                {" "}
                Gold Loan Per Gram – Calculation Example
              </h3>
            </div>
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  fontSize: 22,
                  marginTop: 20,
                }}
              >
                Formula:
              </h3>
              <p>Loan Amount = Gold Weight × Gold Price per gram × LTV%</p>
            </div>

            <h3
              style={{
                color: "#1F1F1F",
                fontSize: 22,
                marginTop: 20,
              }}
            >
              Example:
            </h3>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    Gold Weight:{" "}
                  </span>
                  60 grams
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    Gold Price (22k):{" "}
                  </span>
                  ₹5,500/gram
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    LTV:{" "}
                  </span>
                  75%
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    Loan Amount:{" "}
                  </span>
                  ₹5,500 × 60 × 75% = ₹2,47,500
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
                Gold Loan Repayment Options
              </h3>
              <div
                style={{
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                        lineHeight: "20px ",
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                        lineHeight: "20px ",
                      }}
                    >
                      Regular EMI:{" "}
                    </span>
                    Monthly payments cover both principal and interest.
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                        lineHeight: "20px ",
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                        lineHeight: "20px ",
                      }}
                    >
                      Interest-Only Payments:{" "}
                    </span>
                    Pay monthly interest and repay the principal at tenure end
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                        lineHeight: "20px ",
                      }}
                    >
                      <LuDot />
                    </span>
                  </div>
                  <div
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 400,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <span
                      style={{
                        color: "#1F1F1F",
                        fontWeight: 600,
                        fontSize: 16,
                        lineHeight: "20px ",
                      }}
                    >
                      Bullet Payment:{" "}
                    </span>
                    Entire principal and interest are repaid at the end of the
                    loan tenure.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="right-lender">
            <div>
              <h3
                style={{
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: 22,
                  marginTop: 20,
                }}
              >
                Minemi’s Suggestions and Tips for Gold Loans
              </h3>
              <p
                style={{
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: "16px",
                  fontWeight: 400,
                  marginTop: "10px",
                }}
              >
                To secure the best gold loan rates and terms:
              </p>
            </div>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    Opt for Money Saver Accounts:{" "}
                  </span>
                  Banks like SBI and ICICI oer gold loans linked to overdraft
                  accounts.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    Choose Government Schemes:{" "}
                  </span>
                  <strong> PM Vikas Yojana</strong> offers subsidized interest
                  rates.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    Negotiate Processing Fees:{" "}
                  </span>
                  Request fee waivers based on credit proles or relationships
                  with the bank.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    Track Gold Prices:{" "}
                  </span>
                  Apply when gold prices peak to maximize loan amounts.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px ",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px ",
                    }}
                  >
                    Maintain a Good Credit Score:{" "}
                  </span>
                  While not mandatory, higher scores can fetch better terms.
                </div>
              </div>
            </div>
          </div>

          <div>
            <h3 style={{ color: "#1F1F1F", fontSize: 24, marginTop: 25 }}>
              Conclusion
            </h3>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                marginTop: "10px",
                lineHeight: "24px",
                fontWeight: 400,
              }}
            >
              Gold loans oer an ecient way to unlock the value of your gold
              assets without liquidating them. In 2025, with increased
              regulatory oversight and competitive oerings from banks, borrowers
              have multiple options to choose from.
            </p>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                marginTop: "10px",
                lineHeight: "24px",
                fontWeight: 400,
              }}
            >
              Before applying, compare interest rates, processing fees, and
              repayment schemes to nd the most suitable lender. By following
              this guide and using Minemi’s comparison tools, you can secure the{" "}
              <strong>lowest gold loan interest rate with ease</strong>.
            </p>
          </div>

          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br className="display-none" /> and tailored just for you—apply
                now to see what you qualify for!
              </p>
              <div
                style={{
                  backgroundColor: "transparent",
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                }}
              >
                <a
                  href="https://minemi.ai/personal-loan/check-eligibility"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 50,
                    paddingRight: 50,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 16,
                    lineHeight: "20px ",
                    cursor: "pointer",
                  }}
                >
                  Check Eligiblity Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoldLoanInterestRate;

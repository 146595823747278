import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import "./Question.css";
import { useLocation, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import logo from ".././assets/Images/mLogo.webp";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  bgcolor: '#F6F6F6',
  boxShadow: 24,
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 2,
  paddingRight: 2,
  border: 0,
};

const Question2 = () => {
  const location = useLocation();
  const { occupation, category } = location.state || {}; // Get occupation and category from state
  const [progress, setProgress] = useState(40);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [value, setValue] = useState(() => {
    // Retrieve the value from localStorage if it exists
    const savedValue = localStorage.getItem("monthlyIncome");
    return savedValue || ""; // Default to an empty string if no value is found
  });
  const navigate = useNavigate();

  useEffect(() => {
    // Save the value to localStorage whenever it changes
    localStorage.setItem("monthlyIncome", value);
  }, [value]);

  const handleKeyDown = (e) => {
    // Check if the Enter key is pressed
    if (e.key === 'Enter') {
      if (value === "") {
        alert("Please fill field.");
        return;
      } else {
        navigate("/spend-per-month", {
          state: {
            occupation: occupation,
            monthly: value,
            category: category,
          },
        });
      }
    }
  };

  const formatNumberWithCommas = (number) => {
    if (number === 0 || number === null || number === undefined || number === "") {
      return ""; // Return an empty string for the placeholder
    }
    return new Intl.NumberFormat("en-IN").format(number);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 75,
          left: 0,
          width: "100%",
          height: 5,
          backgroundColor: "#E7E4F9",
          zIndex: 2,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 75,
          left: 0,
          height: 5,
          width: `${progress}%`,
          backgroundColor: progress <= 40 ? "#59266D" : "#E7E4F9",
          transition: "width 0.3s ease",
          zIndex: 2,
        }}
      />
      <div className="min-h-[100vh] w-full pt-4 md:pt-8 px-4 md:px-6">
        {/* Header Navigation */}
        <div
          className="container make-center"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 722,
            paddingLeft: "4%",
            paddingRight: "4%",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row", gap: 8, cursor: 'pointer' }}
            onClick={() => navigate("/select-occupation")}
          >
            <FaArrowLeftLong
              style={{
                alignSelf: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                fontSize: 20,
                color: "#8A8A8A",
              }}
            />
            <p
              style={{
                alignSelf: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                fontSize: 16,
                marginBottom: 0,
                color: "#8A8A8A",
              }}
            >
              Back
            </p>
          </div>
          <div onClick={() => setOpen(true)}>
            <IoClose
              style={{
                alignSelf: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                fontSize: 24,
                color: "#8A8A8A",
                cursor: 'pointer',
              }}
            />
          </div>
        </div>

        {/* Main Content */}
        <div
          style={{
            textAlign: "center",
            marginTop: "2%",
            fontFamily: "Archivo",
            color: "#000000",
            fontSize: 28,
            fontWeight: 600,
            justifyContent: 'center',
          }}
        >
          What is your monthly income?
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            justifyContent: "center",
            marginTop: '2%',
          }}
        >
          <div style={{ width: 350, border: '1px solid #846CA6', borderRadius: 8, backgroundColor: '#FFFFFF', height: 40, display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
            <div style={{ alignSelf: 'center', paddingLeft: '4%', backgroundColor: 'white' }}>₹</div>
            <input
              className="input-fiedl-inner"
              value={formatNumberWithCommas(value)}
              type="text" // Change type to text
              placeholder="Enter monthly income"
              style={{
                width: 338,
                border: "0px solid white",
                marginLeft: 5,
                marginRight: 8,
                outline: 'none',
                backgroundColor: 'white',
              }}
              onChange={(e) => {
                const rawValue = e.target.value.replace(/,/g, ""); // Remove commas
                if (!isNaN(rawValue)) setValue(rawValue); // Ensure it's numeric before updating
              }}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>

        {/* Next Button */}
        <div
          className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            onClick={() => {
              if (value === "") {
                alert("Please fill field.");
                return;
              }
              navigate("/spend-per-month", { state: { occupation: occupation, monthly: value, category: category } });
            }}
            className=" button-class next-button"
            style={{
              fontFamily: 'Archivo',
              marginTop: '3%',
            }}
          >
            NEXT
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ width: 40, height: 40 }}>
              <img src={logo} style={{ width: '100%', height: '100%' }} alt="" />
            </div>
            <IoClose
              onClick={() => setOpen(false)}
              style={{
                alignSelf: "center",
                fontFamily: "Archivo",
                fontWeight: 400,
                fontSize: 24,
                cursor: 'pointer',
              }}
            />
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: '#59266D', fontSize: 24, fontFamily: 'Archivo', fontWeight: 700, marginTop: 16 }}>
            Wait! Your Card is Just a Step Away!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ color: "#8A8A8A", fontSize: 18, fontWeight: 400, fontFamily: 'Archivo' }}>
            Complete the form to receive the best credit card tailored to your needs.
          </Typography>
          <div
            style={{ backgroundColor: '#59266D', padding: 15, color: '#FFFFFF', borderRadius: 10, textAlign: 'center', marginTop: '4%', fontFamily: 'Archivo', fontWeight: 700, fontSize: 16, cursor: "pointer" }}
            onClick={() => setOpen(false)}
          >
            Keep Going
          </div>
          <div
            style={{ backgroundColor: '#F6F6F6', padding: 15, color: '#59266D', borderRadius: 10, textAlign: 'center', marginTop: '4%', fontFamily: 'Archivo', fontWeight: 700, fontSize: 16, border: '1px solid #59266D', cursor: "pointer" }}
            onClick={() => navigate("/creditcard-recommendation")}
          >
            No Thanks
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Question2;
import { useState, useEffect } from "react";
import TestimonialCard from "./TestimonialCard";
const testimonials = [
  {
    name: "Rahul S.",
    location: "Mumbai, Maharashtra",
    quote:
      "Minemi helped me reduce my monthly EMI by 20%! The process was so simple and transparent. Highly recommend it to anyone looking to save.",
  },
  {
    name: "Priya K.",
    location: "Bangalore, Karnataka",
    quote:
      "I was skeptical at first, but Minemi proved me wrong. Their expert team guided me through every step, and now I'm saving ₹8,000 every month!",
  },
  {
    name: "Amit P.",
    location: "Delhi, NCR",
    quote:
      "Thanks to Minemi, I consolidated my loans and significantly lowered my interest rate. The best part? It didn't cost me a rupee to use their service!",
  },
];

export default function Testimonials() {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((current) => (current + 1) % testimonials.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="py-16 bg-[#EBE8FC] w-100">
      <h2
        className="text-3xl md:text-4xl font-bold text-center text-[#59266d] bg-[#EBE8FC] mb-4"
        style={{ fontSize: "36px", lineHeight: "40px" }}
      >
        Real Stories from Our Happy Customers
      </h2>
      <p
        className="text-xl text-center text-gray-600 bg-[#EBE8FC] mb-12"
        style={{
          fontSize: "20px",
          lineHeight: "28px",
          fontWeight: "400",
          color: "#4B5563",
        }}
      >
        See how Minemi has transformed the financial journeys of people across
        India.
      </p>
      <div className="container-fluid mx-auto px-4 bg-[#ebe8fb]">
        <div className="hidden md:grid grid-cols-1 md:grid-cols-3 gap-8 bg-[#ebe8fb]">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} {...testimonial} />
          ))}
        </div>
        <div className="md:hidden bg-[#ebe8fb]">
          <TestimonialCard {...testimonials[activeIndex]} />
          <div className="flex justify-center mt-4 bg-[#ebe8fb]">
            {testimonials.map((_, index) => (
              <button
                key={index}
                className={`h-3 w-3 mx-1 rounded-full ${
                  index === activeIndex ? "bg-[#59266d]" : "bg-gray-300"
                }`}
                onClick={() => setActiveIndex(index)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="text-center mt-12 bg-[#ebe8fb]">
        <a
          style={{ textDecoration: "none", color: "#ffffff" }}
          href="/login?goal=lower-your-emi"
          onClick={() => {
            localStorage.setItem("id", 1);
            window.scrollTo(0, 0);
          }}
        >
          <button
            className="bg-[#59266d] text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-purple-800 transition-colors  change-width"
            style={{ fontSize: "18px", lineHeight: "28px", fontWeight: "600" }}
          >
            Start Your Savings Journey
          </button>
        </a>
      </div>
    </section>
  );
}

import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import TongueOut from "../../../Assets/Images/tongue-out.webp";
import MetadataContext from "../../MetadataContext";

const LoanStatusTracking = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Track Your Credit Card Application Status Easily | Minemi.ai",
      pageDescription:
        "Minemi.ai simplifies credit card application tracking. Check your credit card status quickly and effortlessly with our user-friendly tracking service",

      pageKeywords:
        "credit card application tracking,how to track credit card status",
      canonicalUrl: "https://minemi.ai/creditcard/credit-card-status-tracking",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/creditcard"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Credit Cards
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Credit Card Status Tracking
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontSize: 40,
              color: "#59266D",
              backgroundColor: "#E7E4F9",
              lineHeight: 1,
            }}
          >
            Track Your Credit Card Applications in Real-Time!
          </h1>
          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Stay updated on your loan status, payment schedules, and more with
            ease.
          </p>
        </div>

        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 56 }}>
          {/* table data */}
          <div id="types-of-bt">
            <div id="eligibility-criteria">
              <div
                className=" pb-2 "
                style={{
                  marginTop: 40,
                }}
              >
                <h3 style={{ color: "#1F1F1F", fontSize: 24, lineHeight: 1.2 }}>
                  Why Credit Card Status Tracking Matters
                </h3>
                <h5
                  style={{
                    color: "#1F1F1F",
                    fontSize: 16,
                    lineHeight: "20px",
                    marginTop: 14,
                    fontWeight: 400,
                  }}
                >
                  Why Staying Updated is Important:
                </h5>
              </div>
              <div
                className=" pb-2 "
                style={{
                  color: "#1F1F1F",
                }}
              ></div>
            </div>

            <div
              id="bt-types-table"
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                      }}
                    >
                      Reason
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Benefit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Avoid Late Payments
                    </td>

                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Ensure timely repayments to avoid penalties and maintain a
                      healthy credit score.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Monitor Approval Progress
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Keep track of your loan application status to plan your
                      finances effectively.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Stay Informed on Disbursements
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Know when funds are disbursed to ensure smooth
                      transactions.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Manage Multiple Credit Cards
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Easily track EMIs, due dates, and outstanding balances for
                      all your loans in one place.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Plan Prepayments
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Identify opportunities to make early repayments and save
                      on interest.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* minemi tip */}

          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={TongueOut}
                    alt="minemi tip image"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                    loading="lazy"
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Set calendar reminders for EMI payments to avoid penalties
                    and boost your credit score.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              “Staying on top of my loan status has been one of the most
              important factors in keeping my finances under control.”
              <em>– Sanjay Gupta, Financial Advisor</em>
            </p>
          </div>
          {/* table data */}
          <div id="types-of-bt">
            <div id="eligibility-criteria">
              <div
                className="pb-2 "
                style={{
                  marginTop: 30,
                }}
              >
                <h3 style={{ color: "#1F1F1F", fontSize: 24, lineHeight: 1.2 }}>
                  Key Features of Credit Card Status Tracking Tools
                </h3>
                <h5
                  style={{
                    color: "#1F1F1F",
                    fontSize: 16,
                    lineHeight: "20px",
                    marginTop: 14,
                    fontWeight: 400,
                  }}
                >
                  What Makes Credit Card Tracking Simple with Minemi:
                </h5>
              </div>
              <div
                className=" pb-2 "
                style={{
                  color: "#1F1F1F",
                }}
              ></div>
            </div>

            <div
              id="bt-types-table"
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                      }}
                    >
                      Feature
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Real-Time Updates
                    </td>

                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Get instant updates on your loan application, approval, or
                      disbursement status.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Integrated Dashboard
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Access all your loans in one place with a user-friendly
                      interface.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      EMI Reminders
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Set automated reminders for EMI payments to avoid missed
                      deadlines.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Interest Tracker
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Monitor how much interest you’ve paid and the amount still
                      due.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Prepayment Calculator
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Evaluate potential savings from prepaying your loan using
                      in-built calculators.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Secure Document Storage
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Upload and store loan-related documents for easy reference
                      anytime.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* minemi tip */}

          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={TongueOut}
                    alt="minemi tip image"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                    loading="lazy"
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Always review your dashboard weekly to stay updated on loan
                    balances and avoid surprises.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              “Being able to track my loan’s progress at any time has given me
              peace of mind. I always know where I stand financially.”
              <em>– Asha Mehra, Small Business Owner</em>
            </p>
          </div>

          <div style={{ marginTop: 30 }}>
            <h3
              style={{
                fontFamily: "Archivo",
                color: "#1F1F1F",
                fontSize: 24,

                lineHeight: 1.2,
              }}
            >
              Steps to Track Your Credit Card Easily
            </h3>
            <h5
              style={{
                color: "#1F1F1F",
                fontSize: 16,
                lineHeight: "20px",
                marginTop: 14,
                fontWeight: 400,
              }}
            >
              How to Track Your Credit Card Status Online:
            </h5>
            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Log In to Your Bank Portal:{" "}
                  </span>
                  Visit the official website or app of your loan provider (e.g.,
                  ICICI, SBI, HDFC, Axis Bank).
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Enter Credit Card Details:{" "}
                  </span>
                  Use your Credit Card Reference Number or Application ID to
                  fetch the status.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Monitor Updates:{" "}
                  </span>
                  Check updates on application approval, disbursement, and EMIs
                  through your account dashboard.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}
                >
                  <span
                    style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                  >
                    Download Statements:{" "}
                  </span>
                  Download monthly or yearly loan statements directly for tax or
                  personal purposes.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}

          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={TongueOut}
                    alt="minemi tip image"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                    loading="lazy"
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Store your Credit Card Reference Number in a secure place
                    for quick access whenever needed.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              “Tracking my loan status has helped me stay organized and on top
              of my repayments. I no longer worry about missed payments.”
              <em>– Rohit Sharma, Marketing Professional</em>
            </p>
          </div>

          {/* table data */}
          <div id="types-of-bt">
            <div id="eligibility-criteria">
              <div
                className=" pb-2 "
                style={{
                  marginTop: 30,
                }}
              >
                <h3 style={{ color: "#1F1F1F", fontSize: 24, lineHeight: 1.2 }}>
                  Credit Card Status for Major Indian Banks
                </h3>
                <h5
                  style={{
                    color: "#1F1F1F",
                    fontSize: 16,
                    lineHeight: "20px",
                    marginTop: 14,
                    fontWeight: 400,
                  }}
                >
                  Track Credit Cards from Leading Banks in India:
                </h5>
              </div>
              <div
                className=" pb-2 "
                style={{
                  color: "#1F1F1F",
                }}
              ></div>
            </div>

            <div
              id="bt-types-table"
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                      }}
                    >
                      Bank Name
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Tracking Method
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Website/App Links
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      HDFC Bank
                    </td>

                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Log in via HDFC NetBanking or use the Credit Card Assist
                      App for real-time tracking.
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href="https://netbanking.hdfcbank.com/netbanking/"
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        Visit HDFC
                      </a>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      SBI
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Track your loan via the YONO App or SBI’s loan tracking
                      portal using your Credit Card Reference Number.
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href="https://www.sbicard.com/creditcards/app/user/login"
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        Visit SBI
                      </a>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      ICICI Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Use iMobile App or ICICI NetBanking to monitor your loan
                      application and repayments.
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href="https://infinity.icicibank.com/corp/AuthenticationController?FORMSGROUP_ID__=AuthenticationFG&__START_TRAN_FLAG__=Y&FG_BUTTONS__=LOAD&ACTION.LOAD=Y&AuthenticationFG.LOGIN_FLAG=1&BANK_ID=ICI&ITM=nli_personalb_personal_login_btn&_gl=1*30xkeg*_ga*MTgzMDcxOTY5Ni4xNjIwMDM5NDU0*_ga_SKB78GHTFV*MTYyODIzNDM4NC43Ny4xLjE2MjgyMzQ1MDQuMjc.&_ga=2.112515079.2052546120.1695658268-L1630608130.16869936084."
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        Visit ICICI
                      </a>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Axis Bank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Use the Axis Mobile App or check the official website to
                      track your loan.
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href="https://www.axisbank.com/retail/cards/credit-card/useful-links/card-services"
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        Visit Axis
                      </a>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Citibank
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Log in via the Citibank portal to access loan updates, EMI
                      schedules, and statements.
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href="https://www.online.citibank.co.in/products-services/online-services/internet-banking.htm"
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        Visit Citibank
                      </a>
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Bank of Baroda
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Use the Baroda Connect online portal or app to track loan
                      details.
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      <a
                        href="https://online.bobcard.co.in/Login.aspx?ReturnUrl=%2f"
                        style={{
                          color: "#561A6A",
                        }}
                      >
                        Visit BoB
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* minemi tip */}

          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={TongueOut}
                    alt="minemi tip image"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                    loading="lazy"
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Track your loan application regularly to spot and address
                    processing delays early.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3">
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              "The ability to track my loan status on the go has been
              invaluable. I can quickly address any delays and stay on track.”
              <em>– Priya Desai, Entrepreneur</em>
            </p>
          </div>

          {/* table data */}
          <div id="types-of-bt">
            <div id="eligibility-criteria">
              <div
                className=" pb-2 "
                style={{
                  marginTop: 30,
                }}
              >
                <h3 style={{ color: "#1F1F1F", fontSize: 24, lineHeight: 1.2 }}>
                  Credit Card Calculator Tools
                </h3>
                <h5
                  style={{
                    color: "#1F1F1F",
                    fontSize: 16,
                    lineHeight: "20px",
                    marginTop: 14,
                    fontWeight: 400,
                  }}
                >
                  Plan Your Finances with Credit Card Calculators:
                </h5>
              </div>
              <div
                className=" pb-2 "
                style={{
                  color: "#1F1F1F",
                }}
              ></div>
            </div>

            <div
              id="bt-types-table"
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                      }}
                    >
                      Calculator Type
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Use Case
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      EMI Calculator
                    </td>

                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Calculate your monthly EMI based on loan amount, tenure,
                      and interest rate.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Prepayment Calculator
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      See how prepaying your loan can reduce interest payments
                      and shorten your tenure.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Interest Breakdown
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Get a detailed view of how much of your EMI goes toward
                      interest and principal repayment.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Debt-to-Income Ratio
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Assess if your monthly loan commitments are within a safe
                      financial range.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* minemi tip */}

          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={TongueOut}
                    alt="minemi tip image"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                    loading="lazy"
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Use prepayment calculators to plan early closures and save
                    on long-term interest costs.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              “Having access to easy-to-use calculators has allowed me to make
              smarter financial decisions, saving me money in the long run.”
              <em>– Vikram Yadav, Financial Analyst</em>
            </p>
          </div>

          {/* table data */}
          <div id="types-of-bt">
            <div id="eligibility-criteria">
              <div
                className=" pb-2 "
                style={{
                  marginTop: 30,
                }}
              >
                <h3 style={{ color: "#1F1F1F", fontSize: 24, lineHeight: 1.2 }}>
                  Common Credit Card Tracking Problems and Solutions
                </h3>
                <h5
                  style={{
                    color: "#1F1F1F",
                    fontSize: 16,
                    lineHeight: "20px",
                    marginTop: 14,
                    fontWeight: 400,
                  }}
                >
                  Troubleshooting Credit Card Tracking Issues:
                </h5>
              </div>
              <div
                className=" pb-2 "
                style={{
                  color: "#1F1F1F",
                }}
              ></div>
            </div>

            <div
              id="bt-types-table"
              className="increase-margin"
              style={{
                marginTop: "2%",
                border: "1px solid #846CA6",
                borderRadius: 18,
                fontSize: 16,
                width: "100%",
              }}
            >
              <table
                id="customers"
                style={{
                  fontFamily: "Archivo",
                  borderCollapse: "collapse",
                  borderRadius: 18,
                  width: "100%",
                }}
              >
                <thead style={{ borderRadius: 18 }}>
                  <tr style={{ borderRadius: 18 }}>
                    <th
                      className="table-padding"
                      style={{
                        backgroundColor: "#E7E4F9",
                        backgroundColor: "#E7E4F9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        borderTopLeftRadius: 18,
                        color: "#59266D",
                      }}
                    >
                      Problem
                    </th>
                    <th
                      className="table-padding"
                      style={{
                        borderLeft: "1px solid #D9D9D9",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                        paddingLeft: "6px",
                        textAlign: "left",
                        backgroundColor: "#E7E4F9",
                        color: "#59266D",
                      }}
                    >
                      Solution
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Cannot Access Credit Card Account Online
                    </td>

                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Ensure you have the correct login credentials. Reset your
                      password if needed.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Missing Credit Card Reference Number
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Contact your bank’s customer care or check your loan
                      approval documents for the reference number.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Delayed Status Updates
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Processing times may vary. Reach out to the bank for
                      updates if the delay exceeds 3-5 business days.
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 700,
                      }}
                    >
                      Discrepancy in Credit Card Amounts
                    </td>
                    <td
                      className="table-padding"
                      style={{
                        border: "1px solid #ddd",
                        padding: "16px",
                        backgroundColor: "white",
                        fontFamily: "Archivo",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Review your statements carefully and raise disputes for
                      any inconsistencies immediately.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* minemi tip */}

          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div style={{ backgroundColor: "#e7e4f9" }} className="px-4 py-4">
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={TongueOut}
                    alt="minemi tip image"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                    loading="lazy"
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    If you notice a discrepancy in loan amounts, report it
                    immediately to avoid compounding errors.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              “Addressing issues early on has saved me from future headaches. I
              always review my loan details carefully.”
              <em>– Neelam Sharma, Tech Developer</em>
            </p>
          </div>

          <div
            style={{
              marginTop: 30,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
            }}
          >
            Frequently Asked Questions (FAQs){" "}
          </div>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              backgroundColor: "#F6F6F6",
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                How do I track my home loan status?
              </AccordionSummary>
              <AccordionDetails>
                Log in to your bank’s online portal or mobile app, use your
                Credit Card Reference Number, and navigate to the loan tracking
                section.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                What should I do if my application is rejected?
              </AccordionSummary>
              <AccordionDetails>
                Contact the bank to understand the reason and improve your
                eligibility by addressing issues like low credit score or
                missing documents.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Can I track multiple loans together?
              </AccordionSummary>
              <AccordionDetails>
                Yes, many apps, including Minemi, allow you to consolidate and
                track multiple loans in one dashboard.
              </AccordionDetails>
              <AccordionActions></AccordionActions>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                background: "#FFFFFF",
                overflow: "hidden",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                How can I know if my EMI is overdue?
              </AccordionSummary>
              <AccordionDetails>
                Check the EMI schedule in your loan account or enable Minemi’s
                EMI reminders to avoid overdue payments.
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 50,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Find Credit Cards Tailored Just for You
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Let us match you with the best credit cards based on your income
                and credit score.
              </p>
              <div
                style={{
                  backgroundColor: "transparent",
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                }}
              >
                <Link
                  to="/creditcard/creditcard-recommendation"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 50,
                    paddingRight: 50,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                >
                  See Recommended Cards Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanStatusTracking;

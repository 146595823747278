import React, { useEffect, useMemo, useState } from "react";
import { ChevronDown } from "lucide-react";

const Faq = ({ name }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [faqs, setFaqs] = useState([]);

  const kotakData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about Kotak Bank Home Loans",
        question:
          "How long does it take to get an Kotak Bank Home Loan approved?",
        answer:
          "Kotak Bank typically processes home loan applications within 5-8 working days. For salaried customers with excellent credit profiles, the process can be expedited to 3-5 working days. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through Kotak Bank NetBanking are processed more efficiently. Using MinEMI's tools can help streamline the process and potentially reduce the approval time by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question:
          "What are the common reasons for Kotak Bank Home Loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. KotakBank places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an Kotak Bank Home Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your debt-to-income ratio below 50%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Women applicants and salaried professionals should highlight their status to avail special interest rate concessions. Having an existing relationship with Kotak Bank also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const bobData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about Bank of Baroda Home Loans",
        question:
          "How long does it take to get a Bank of Baroda Home Loan approved?",
        answer:
          "Bank of Baroda typically processes home loan applications within 7-10 working days. For government employees and customers with excellent credit profiles, the process can be expedited to 3-5 working days. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through BOB World Internet Banking are processed more efficiently. Using MinEMI's tools can help streamline the process and potentially reduce the approval time by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question:
          "What are the common reasons for Bank of Baroda Home Loan rejection?",
        answer:
          "Common reasons include low credit score (below 680), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. Bank of Baroda places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting a Bank of Baroda Home Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 730, keep your debt-to-income ratio below 60%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Government employees and existing customers should highlight their status to avail special interest rate concessions. Having an existing relationship with Bank of Baroda also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const idfcData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about IDFC FIRST Bank Home Loans",
        question:
          " How long does it take to get an IDFC FIRST Bank Home Loan approved?",
        answer:
          "IDFC FIRST Bank typically processes home loan applications within 5-7 working days. For salaried professionals with excellent credit profiles, the process can be expedited to 2-3 working days. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through the IDFC FIRST Mobile App are processed more efficiently. Using MinEMI's tools can help streamline the process and potentially reduce the approval time by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question:
          "What are the common reasons for IDFC FIRST Bank Home Loan rejection?",
        answer:
          "Common reasons include low credit score (below 675), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. IDFC FIRST Bank places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an IDFC FIRST Bank Home Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 725, keep your debt-to-income ratio below 55%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Women borrowers and salaried professionals should highlight their status to avail special interest rate concessions. Having an existing relationship with IDFC FIRST Bank also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const hdfcData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about HDFC Bank Home Loans",
        question:
          "How long does it take to get an HDFC Bank Home Loan approved?",
        answer:
          "HDFC Bank typically processes home loan applications within 5-8 working days. For salaried customers with excellent credit profiles, the process can be expedited to 3-5 working days. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through HDFC Bank NetBanking are processed more efficiently. Using MinEMI's tools can help streamline the process and potentially reduce the approval time by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question:
          "What are the common reasons for HDFC Bank Home Loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. HDFC Bank places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an HDFC Bank Home Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your debt-to-income ratio below 50%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Women applicants and salaried professionals should highlight their status to avail special interest rate concessions. Having an existing relationship with HDFC Bank also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
      // {
      //   question:
      //     "Can I check my Bank of Baroda personal loan status without visiting the branch?",
      //   answer:
      //     "Yes, you can check your loan status through multiple channels without visiting a branch: Bank of Baroda's NetBanking portal, Baroda M-Connect Plus mobile app, customer care helpline (1800-258-4455), or using MinEMI's Loan Status Tracker on this page.",
      // },
    ],
    []
  );
  const sbiData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about SBI Home Loans",
        question: "How long does it take to get an SBI Home Loan approved?",
        answer:
          "SBI typically processes home loan applications within 7-10 working days. For salary account holders with excellent credit profiles, the process can be expedited to 3-5 working days. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through the YONO SBI App are processed more efficiently. Using MinEMI's tools can help streamline the process and potentially reduce the approval time by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question: "What are the common reasons for SBI Home Loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. SBI places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an SBI Home Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your debt-to-income ratio below 50%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Women borrowers should highlight their status to avail the 5 basis points interest rate concession. Having an existing relationship with SBI also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
      //
    ],
    []
  );
  const axisData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about Axis Bank Home Loans",
        question:
          "How long does it take to get an Axis Bank Home Loan approved?",
        answer:
          "Axis Bank typically processes home loan applications within 5-7 working days. For eligible customers, the Express Home Loan service offers approval decisions within 48 hours. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through the Axis Mobile App are processed more efficiently. Using MinEMI's tools can help streamline the process by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question:
          "What are the common reasons for Axis Bank Home Loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. Axis Bank places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an Axis Bank Home Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your debt-to-income ratio below 50%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Women borrowers should highlight their status to avail the 5 basis points interest rate concession. Having an existing relationship with Axis Bank also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const iciciData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about ICICI Bank Home Loans",
        question:
          "How long does it take to get an ICICI Bank Home Loan approved?",
        answer:
          "ICICI Bank typically processes home loan applications within 4-7 working days. For eligible customers, the Express Home Loan service offers approval with disbursement within 48 hours. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through the iMobile Pay App are processed more efficiently. Using MinEMI's tools can help streamline the process by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question:
          "What are the common reasons for ICICI Bank Home Loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. ICICI Bank places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an ICICI Bank Home Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your debt-to-income ratio below 45%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Women borrowers should highlight their status to avail the 5 basis points interest rate concession. Having an existing relationship with ICICI Bank also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const punjabData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about Punjab National Bank Home Loans",
        question:
          "How long does it take to get a Punjab National Bank Home Loan approved?",
        answer:
          "Punjab National Bank typically processes home loan applications within 5-7 working days. For eligible customers, the PNB Express Home Loan service offers approval within 3 working days. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through the PNB One Mobile App are processed more efficiently. Using MinEMI's tools can help streamline the process by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question:
          "What are the common reasons for Punjab National Bank Home Loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. PNB places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting a Punjab National Bank Home Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 740, keep your debt-to-income ratio below 50%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Women borrowers should highlight their status to avail the 5 basis points interest rate concession. Having an existing relationship with PNB also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const induslndData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about IndusInd Bank Home Loans",
        question:
          "How long does it take to get an IndusInd Bank Home Loan approved?",
        answer:
          "IndusInd Bank typically processes home loan applications within 5-7 working days. For eligible customers, the Express Home Loan service offers approval within 4 working days. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through the IndusInd Mobile App are processed more efficiently. Using MinEMI's tools can help streamline the process by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question:
          "What are the common reasons for IndusInd Bank Home Loan rejection?",
        answer:
          "Common reasons include low credit score (below 700), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. IndusInd Bank places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting an IndusInd Bank Home Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 730, keep your debt-to-income ratio below 50%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Women borrowers should highlight their status to avail the 10 basis points interest rate concession. Having an existing relationship with IndusInd Bank also improves your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const tataData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about Tata Housing Finance Home Loans",
        question:
          "How long does it take to get a Tata Housing Finance Home Loan approved?",
        answer:
          "Tata Housing Finance typically processes home loan applications within 4-7 working days. For eligible customers, the Express Disbursal service offers approval and disbursement within 48 hours. The timeline includes document verification, property legal checks, technical evaluation, and final approval. Digital applications through the Tata Capital app are processed more efficiently. Using MinEMI's tools can help streamline the process by ensuring all documentation is complete and accurate from the start.",
      },
      {
        question:
          "What are the common reasons for Tata Housing Finance Home Loan rejection?",
        answer:
          "Common reasons include low credit score (below 650), high existing debt obligations, incomplete or incorrect property documentation, legal issues with the property title, negative property valuation report, unstable employment history, and insufficient income. Tata Housing Finance places significant emphasis on the property's legal status and valuation. Properties with unclear titles, unauthorized constructions, or in disputed areas are often rejected. MinEMI's Home Loan Approval Predictor can help you identify and address these issues before applying.",
      },
      {
        question:
          "How can I improve my chances of getting a Tata Housing Finance Home Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 720, keep your debt-to-income ratio below 55%, ensure all property documents are complete and legally verified, provide comprehensive income proof, and apply for a loan amount that aligns with your income and property value. Women borrowers should highlight their status to avail the 10 basis points interest rate concession. Being a Tata Group employee or IT/ITES professional can also improve your chances. MinEMI's Eligibility Checker can help you determine the optimal loan amount to apply for.",
      },
    ],
    []
  );
  const yesData = useMemo(
    () => [
      {
        h2: "Expert answers to common questions about YES Bank home loans",
        question:
          "What is the typical processing time for a YES Bank home loan?",
        answer:
          "YES Bank typically processes home loans in 7-10 business days for salaried individuals with complete documentation. For self-employed applicants, it may take 10-14 business days. The processing time depends on property documentation, legal verification, and technical valuation. YES Bank's digital loan process can expedite approvals for existing customers. Property verification and valuation usually take 3-5 days. MinEMI's tools can help ensure your application is complete and optimized for faster approval.",
      },
      {
        question:
          "What are YES Bank's specific eligibility criteria for home loans?",
        answer:
          "YES Bank requires: (1) Age between 21-65 years, (2) Minimum monthly income of ₹30,000 for salaried and ₹40,000 for self-employed, (3) Credit score of 700+ (though 750+ is preferred for better rates), (4) Minimum 2 years of employment with at least 1 year in current job for salaried applicants, (5) 3+ years in current business for self-employed, and (6) Property with clear title and all approvals. YES Bank also considers your banking relationship, with preferential terms for existing customers.",
      },
      {
        question:
          "How can I improve my chances of getting a YES Bank home loan approved?",
        answer:
          "To maximize your YES Bank home loan approval chances: (1) Maintain a credit score above 750, (2) Keep your fixed obligation to income ratio (FOIR) below 50%, (3) Choose a property from YES Bank's approved project list, (4) Ensure all property documents are complete and legally verified, (5) Apply for a loan amount that aligns with your income (typically 4-5 times annual income), (6) Consider opening a savings account with YES Bank before applying, and (7) Use MinEMI's YES Bank Approval Predictor to identify and address potential issues before applying.",
      },
    ],
    []
  );
  const unionData = useMemo(
    () => [
      {
        h2: "Expert answers to common questions about Union Bank home loans",
        question:
          "What is the typical processing time for a Union Bank home loan?",
        answer:
          "Union Bank typically processes home loans in 10-15 business days for salaried individuals with complete documentation. For self-employed applicants, it may take 15-20 business days. The processing time depends on property documentation, legal verification, and technical valuation. PMAY scheme applications may take additional 7-10 days for subsidy processing. Property verification and valuation usually take 3-5 days. MinEMI's tools can help ensure your application is complete and optimized for faster approval.",
      },
      {
        question:
          "What are Union Bank's specific eligibility criteria for home loans?",
        answer:
          "Union Bank requires: (1) Age between 21-70 years (loan tenure should end before 70 years of age), (2) Minimum monthly income of ₹25,000 for salaried and ₹30,000 for self-employed, (3) Credit score of 700+ (though 750+ is preferred for better rates), (4) Minimum 2 years of employment with at least 1 year in current job for salaried applicants, (5) 3+ years in current business for self-employed, and (6) Property with clear title and all approvals. Union Bank also considers your banking relationship, with preferential terms for existing customers.",
      },
      {
        question:
          "How can I improve my chances of getting a Union Bank home loan approved?",
        answer:
          "To maximize your Union Bank home loan approval chances: (1) Maintain a credit score above 750, (2) Keep your fixed obligation to income ratio (FOIR) below 50%, (3) Choose a property with clear title and all approvals, (4) Ensure all property documents are complete and legally verified, (5) Apply for a loan amount that aligns with your income (typically 4-5 times annual income), (6) Check your PMAY eligibility and apply for the subsidy if eligible, and (7) Use MinEMI's Union Bank Approval Predictor to identify and address potential issues before applying.",
      },
    ],
    []
  );
  const bajajData = useMemo(
    () => [
      {
        h2: "Expert answers to common questions about Bajaj Finance home loans",
        question:
          "What is the typical processing time for a Bajaj Finance home loan?",
        answer:
          "Bajaj Finance typically processes home loans in 7-10 business days for salaried individuals with complete documentation. For self-employed applicants, it may take 10-14 business days. The processing time depends on property documentation, legal verification, and technical valuation. Bajaj Finance's digital loan process can expedite approvals for existing customers. Property verification and valuation usually take 2-4 days. MinEMI's tools can help ensure your application is complete and optimized for faster approval.",
      },
      {
        question:
          "What are Bajaj Finance's specific eligibility criteria for home loans?",
        answer:
          "Bajaj Finance requires: (1) Age between 23-70 years (loan tenure should end before 70 years of age), (2) Minimum monthly income of ₹30,000 for salaried and ₹40,000 for self-employed, (3) Credit score of 700+ (though 750+ is preferred for better rates), (4) Minimum 2 years of employment with at least 1 year in current job for salaried applicants, (5) 3+ years in current business for self-employed, and (6) Property with clear title and all approvals. Bajaj Finance also considers your relationship with them, with preferential terms for existing customers.",
      },
      {
        question:
          "How can I improve my chances of getting a Bajaj Finance home loan approved?",
        answer:
          "To maximize your Bajaj Finance home loan approval chances: (1) Maintain a credit score above 750, (2) Keep your fixed obligation to income ratio (FOIR) below 50%, (3) Choose a property with clear title and all approvals, (4) Ensure all property documents are complete and legally verified, (5) Apply for a loan amount that aligns with your income (typically 4-5 times annual income), (6) Maintain good repayment history on existing Bajaj Finserv products if any, and (7) Use MinEMI's Bajaj Finance Approval Predictor to identify and address potential issues before applying.",
      },
    ],
    []
  );
  const federalData = useMemo(
    () => [
      {
        h2: "Expert answers to common questions about Federal Bank home loans",
        question:
          "What is the typical processing time for a Federal Bank home loan?",
        answer:
          "Federal Bank typically processes home loans in 7-14 business days for salaried individuals with complete documentation. For self-employed applicants, it may take 10-15 business days. The processing time depends on property documentation, legal verification, and technical valuation. NRI applications may take an additional 3-5 days for verification. Property verification and valuation usually take 2-4 days. MinEMI's tools can help ensure your application is complete and optimized for faster approval.",
      },
      {
        question:
          "What are Federal Bank's specific eligibility criteria for home loans?",
        answer:
          "Federal Bank requires: (1) Age between 21-70 years (loan tenure should end before 70 years of age), (2) Minimum monthly income of ₹25,000 for salaried and ₹30,000 for self-employed, (3) Credit score of 700+ (though 750+ is preferred for better rates), (4) Minimum 2 years of employment with at least 1 year in current job for salaried applicants, (5) 3+ years in current business for self-employed, and (6) Property with clear title and all approvals. For NRI applicants, additional income proof and power of attorney documentation is required.",
      },
      {
        question:
          "How can I improve my chances of getting a Federal Bank home loan approved?",
        answer:
          "To maximize your Federal Bank home loan approval chances: (1) Maintain a credit score above 750, (2) Keep your fixed obligation to income ratio (FOIR) below 50%, (3) Choose a property with clear title and all approvals, (4) Ensure all property documents are complete and legally verified, (5) Apply for a loan amount that aligns with your income (typically 4-5 times annual income), (6) If you're an NRI, highlight this status for special benefits, and (7) Use MinEMI's Federal Bank Approval Predictor to identify and address potential issues before applying.",
      },
    ],
    []
  );
  const canaraData = useMemo(
    () => [
      {
        h2: "Expert answers to common questions about Canara Bank home loans",
        question:
          "What is the typical processing time for a Canara Bank home loan?",
        answer:
          "Canara Bank typically processes home loans in 10-15 business days for salaried individuals with complete documentation. For self-employed applicants, it may take 15-20 business days. The processing time depends on property documentation, legal verification, and technical valuation. Government employees may experience faster processing times of 7-10 days. Property verification and valuation usually take 3-5 days. MinEMI's tools can help ensure your application is complete and optimized for faster approval.",
      },
      {
        question:
          "What are Canara Bank's specific eligibility criteria for home loans?",
        answer:
          "Canara Bank requires: (1) Age between 21-70 years (loan tenure should end before 70 years of age), (2) Minimum monthly income of ₹25,000 for salaried and ₹30,000 for self-employed, (3) Credit score of 700+ (though 750+ is preferred for better rates), (4) Minimum 2 years of employment with at least 1 year in current job for salaried applicants, (5) 3+ years in current business for self-employed, and (6) Property with clear title and all approvals. For Budget Home Loan scheme, the income criteria are relaxed to ₹20,000 for salaried applicants.",
      },
      {
        question:
          "How can I improve my chances of getting a Canara Bank home loan approved?",
        answer:
          "To maximize your Canara Bank home loan approval chances: (1) Maintain a credit score above 750, (2) Keep your fixed obligation to income ratio (FOIR) below 50%, (3) Choose a property with clear title and all approvals, (4) Ensure all property documents are complete and legally verified, (5) Apply for a loan amount that aligns with your income (typically 4-5 times annual income), (6) If you're a government employee, highlight this status for special benefits, and (7) Use MinEMI's Canara Bank Approval Predictor to identify and address potential issues before applying.",
      },
    ],
    []
  );
  const boiData = useMemo(
    () => [
      {
        h2: "Expert answers to common questions about Bank of India home loans",
        question:
          "What is the typical processing time for a Bank of India home loan?",
        answer:
          "Bank of India typically processes home loans in 10-15 business days for salaried individuals with complete documentation. For self-employed applicants, it may take 15-20 business days. The processing time depends on property documentation, legal verification, and technical valuation. Women borrowers and existing account holders may experience faster processing times of 7-10 days. Property verification and valuation usually take 3-5 days. MinEMI's tools can help ensure your application is complete and optimized for faster approval.",
      },
      {
        question:
          "What are Bank of India's specific eligibility criteria for home loans?",
        answer:
          "Bank of India requires: (1) Age between 21-70 years (loan tenure should end before 70 years of age), (2) Minimum monthly income of ₹25,000 for salaried and ₹30,000 for self-employed, (3) Credit score of 700+ (though 750+ is preferred for better rates), (4) Minimum 2 years of employment with at least 1 year in current job for salaried applicants, (5) 3+ years in current business for self-employed, and (6) Property with clear title and all approvals. For Star Home Loan scheme, additional criteria may apply based on your relationship with the bank.",
      },
      {
        question:
          "How can I improve my chances of getting a Bank of India home loan approved?",
        answer:
          "To maximize your Bank of India home loan approval chances: (1) Maintain a credit score above 750, (2) Keep your fixed obligation to income ratio (FOIR) below 50%, (3) Choose a property with clear title and all approvals, (4) Ensure all property documents are complete and legally verified, (5) Apply for a loan amount that aligns with your income (typically 4-5 times annual income), (6) If you're a woman, consider being the primary applicant for special benefits, and (7) Use MinEMI's Bank of India Approval Predictor to identify and address potential issues before applying.",
      },
    ],
    []
  );
  const rblData = useMemo(
    () => [
      {
        h2: "Expert answers to common questions about RBL Bank home loans",
        question:
          "What is the typical processing time for an RBL Bank home loan?",
        answer:
          "RBL Bank typically processes home loans in 7-10 business days for salaried individuals with complete documentation. For self-employed applicants, it may take 10-15 business days. The processing time depends on property documentation, legal verification, and technical valuation. Premium banking customers may experience faster processing times of 5-7 days. Property verification and valuation usually take 2-3 days. The Express Home Loan program can provide in-principle approval within 24 hours for eligible customers. MinEMI's tools can help ensure your application is complete and optimized for faster approval.",
      },
      {
        question:
          "What are RBL Bank's specific eligibility criteria for home loans?",
        answer:
          "RBL Bank requires: (1) Age between 21-65 years (loan tenure should end before 65 years of age), (2) Minimum monthly income of ₹30,000 for salaried and ₹40,000 for self-employed, (3) Credit score of 700+ (though 750+ is preferred for better rates), (4) Minimum 2 years of employment with at least 1 year in current job for salaried applicants, (5) 3+ years in current business for self-employed, and (6) Property with clear title and all approvals. For the Apna Ghar scheme, additional criteria may apply based on first-time homebuyer status.",
      },
      {
        question:
          "How can I improve my chances of getting an RBL Bank home loan approved?",
        answer:
          "To maximize your RBL Bank home loan approval chances: (1) Maintain a credit score above 750, (2) Keep your fixed obligation to income ratio (FOIR) below 50%, (3) Choose a property with clear title and all approvals, (4) Ensure all property documents are complete and legally verified, (5) Apply for a loan amount that aligns with your income (typically 4-5 times annual income), (6) Consider opening an RBL Bank account and establishing a banking relationship before applying, and (7) Use MinEMI's RBL Bank Approval Predictor to identify and address potential issues before applying.",
      },
    ],
    []
  );
  const ltData = useMemo(
    () => [
      {
        h2: "Expert answers to common questions about L&T Bank home loans",
        question:
          "What is the typical processing time for an L&T Bank home loan?",
        answer:
          "L&T Bank typically processes home loans in 7-10 business days for salaried individuals with complete documentation. For self-employed applicants, it may take 10-15 business days. The processing time depends on property documentation, legal verification, and technical valuation. Premium banking customers may experience faster processing times of 5-7 days. Property verification and valuation usually take 2-3 days. The Express Home Loan program can provide in-principle approval within 24 hours for eligible customers. MinEMI's tools can help ensure your application is complete and optimized for faster approval.",
      },
      {
        question:
          "What are L&T Bank's specific eligibility criteria for home loans?",
        answer:
          "L&T Bank requires: (1) Age between 21-65 years (loan tenure should end before 65 years of age), (2) Minimum monthly income of ₹30,000 for salaried and ₹40,000 for self-employed, (3) Credit score of 700+ (though 750+ is preferred for better rates), (4) Minimum 2 years of employment with at least 1 year in current job for salaried applicants, (5) 3+ years in current business for self-employed, and (6) Property with clear title and all approvals. For the Apna Ghar scheme, additional criteria may apply based on first-time homebuyer status.",
      },
      {
        question:
          "How can I improve my chances of getting an L&T Bank home loan approved?",
        answer:
          "To maximize your L&T Bank home loan approval chances: (1) Maintain a credit score above 750, (2) Keep your fixed obligation to income ratio (FOIR) below 50%, (3) Choose a property with clear title and all approvals, (4) Ensure all property documents are complete and legally verified, (5) Apply for a loan amount that aligns with your income (typically 4-5 times annual income), (6) Consider opening an L&T Bank account and establishing a banking relationship before applying, and (7) Use MinEMI's L&T Bank Approval Predictor to identify and address potential issues before applying.",
      },
    ],
    []
  );
  const idbiData = useMemo(
    () => [
      {
        h2: "Expert answers to common questions about IDBI Bank home loans",
        question:
          "What is the typical processing time for an IDBI Bank home loan?",
        answer:
          "IDBI Bank typically processes home loans in 7-10 business days for salaried individuals with complete documentation. For self-employed applicants, it may take 10-15 business days. The processing time depends on property documentation, legal verification, and technical valuation. Premium banking customers may experience faster processing times of 5-7 days. Property verification and valuation usually take 2-3 days. The Express Home Loan program can provide in-principle approval within 24 hours for eligible customers. MinEMI's tools can help ensure your application is complete and optimized for faster approval.",
      },
      {
        question:
          "What are IDBI Bank's specific eligibility criteria for home loans?",
        answer:
          "IDBI Bank requires: (1) Age between 21-65 years (loan tenure should end before 65 years of age), (2) Minimum monthly income of ₹30,000 for salaried and ₹40,000 for self-employed, (3) Credit score of 700+ (though 750+ is preferred for better rates), (4) Minimum 2 years of employment with at least 1 year in current job for salaried applicants, (5) 3+ years in current business for self-employed, and (6) Property with clear title and all approvals. For the Apna Ghar scheme, additional criteria may apply based on first-time homebuyer status.",
      },
      {
        question:
          "How can I improve my chances of getting an IDBI Bank home loan approved?",
        answer:
          "To maximize your IDBI Bank home loan approval chances: (1) Maintain a credit score above 750, (2) Keep your fixed obligation to income ratio (FOIR) below 50%, (3) Choose a property with clear title and all approvals, (4) Ensure all property documents are complete and legally verified, (5) Apply for a loan amount that aligns with your income (typically 4-5 times annual income), (6) Consider opening an IDBI Bank account and establishing a banking relationship before applying, and (7) Use MinEMI's IDBI Bank Approval Predictor to identify and address potential issues before applying.",
      },
    ],
    []
  );
  const lichousingData = useMemo(
    () => [
      {
        h2: "Expert answers to common questions about LIC Housing Finance  Bank home loans",
        question:
          "How long does it take to get an LIC Housing Finance Home Loan approved?",
        answer:
          "LIC Housing Finance typically takes 15-25 days to process and approve a home loan application. The timeline depends on property verification, legal checks, and documentation completeness. For salaried customers with LIC policies, the process can be expedited to 10-15 days. Using MinEMI's tools can help streamline the process and potentially reduce the approval time.",
      },
      {
        question:
          "What documents are required for LIC Housing Finance Home Loan application?",
        answer:
          "LIC Housing Finance requires KYC documents (Aadhaar/PAN, address proof), income documents (salary slips, ITR, Form 16 for the past 2 years), bank statements for 6 months, property documents (sale deed, title deed, approved plan), and property valuation reports. LIC policyholders should also provide policy details for special rates. MinEMI's Document Checklist tool can help you prepare all required documents.",
      },
      {
        question:
          "How can I improve my chances of getting an LIC Housing Finance Home Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your fixed obligations to income ratio below 50%, choose a property with clear title documents, provide complete and accurate documentation, and apply for a loan amount that aligns with your income. Being an LIC policyholder can also improve your chances and help you secure better interest rates.",
      },
    ],
    []
  );
  const icicihousingData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about ICICI Home Finance Loans",
        question:
          "How long does it take to get an ICICI Home Finance Loan approved?",
        answer:
          "ICICI Home Finance typically takes 10-15 days to process and approve a home loan application. The timeline depends on property verification, legal checks, and documentation completeness. For existing ICICI Bank customers, the process can be expedited through their Express Home Loan program, which can provide approvals in as little as 8 days. Using MinEMI's tools can help streamline the process and potentially reduce the approval time.",
      },
      {
        question:
          "What documents are required for ICICI Home Finance Loan application?",
        answer:
          "ICICI Home Finance requires KYC documents (Aadhaar/PAN, address proof), income documents (salary slips, ITR, Form 16 for the past 2 years), bank statements for 6 months, property documents (sale deed, title deed, approved plan), and property valuation reports. For self-employed applicants, business proof and financial statements for 3 years are also required. MinEMI's Document Checklist tool can help you prepare all required documents.",
      },
      {
        question:
          "How can I improve my chances of getting an ICICI Home Finance Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your fixed obligations to income ratio below 45%, choose a property with clear title documents, provide complete and accurate documentation, and apply for a loan amount that aligns with your income. Being an existing ICICI Bank customer can also improve your chances and help you secure better interest rates.",
      },
    ],
    []
  );
  const pnbhousingData = useMemo(
    () => [
      {
        h2: "Get answers to common questions about PNB Home Finance Loans",
        question:
          "How long does it take to get a PNB Housing Finance Loan approved?",
        answer:
          "PNB Housing Finance typically takes 12-18 days to process and approve a home loan application. The timeline depends on property verification, legal checks, and documentation completeness. For government employees and existing PNB Bank customers, the process can be expedited to 8-10 days. Using MinEMI's tools can help streamline the process and potentially reduce the approval time.",
      },
      {
        question:
          "What documents are required for PNB Housing Finance Loan application?",
        answer:
          "PNB Housing Finance requires KYC documents (Aadhaar/PAN, address proof), income documents (salary slips, ITR, Form 16 for the past 2 years), bank statements for 6 months, property documents (sale deed, title deed, approved plan), and property valuation reports. For self-employed applicants, business proof and financial statements for 3 years are also required. MinEMI's Document Checklist tool can help you prepare all required documents.",
      },
      {
        question:
          "How can I improve my chances of getting a PNB Housing Finance Loan approved?",
        answer:
          "To improve your approval chances: maintain a credit score above 750, keep your fixed obligations to income ratio below 50%, choose a property with clear title documents, provide complete and accurate documentation, and apply for a loan amount that aligns with your income. Being a government employee or an existing PNB Bank customer can also improve your chances and help you secure better interest rates.",
      },
    ],
    []
  );
  const hdfclnthousingData = useMemo(
    () => [
      {
        h2: "Common questions about HDFC Ltd. home loans",
        question:
          "How long does it take to get an HDFC Ltd. home loan approved?",
        answer:
          "HDFC Ltd. typically processes home loan applications within 7-14 working days. The timeline depends on property documentation, legal verification, and technical assessment. For properties with clear titles and all documentation in order, approvals can be faster. The complete process from application to first disbursement usually takes 2-3 weeks. Using MinEMI's tools before applying can help streamline the process by ensuring you meet all eligibility criteria and have all required documentation ready.",
      },
      {
        question:
          "What are the common reasons for HDFC Ltd. home loan rejection?",
        answer:
          "Common rejection reasons include: low credit score (below 700), high debt-to-income ratio (above 50%), unstable employment history, incomplete or inconsistent documentation, property legal issues (unclear title, unauthorized construction), property valuation below expectations, and applying for an amount beyond your repayment capacity. HDFC Ltd. places significant emphasis on property documentation and legal verification. MinEMI's Approval Analyzer can help identify potential issues before you apply.",
      },
      {
        question:
          "How can I improve my chances of getting an HDFC Ltd. home loan approved?",
        answer:
          "To maximize approval chances: maintain a credit score above 750, keep credit card utilization below 30%, ensure all existing loans are paid on time, have stable employment with at least 2 years at your current job, provide complete and accurate documentation including Form 16 and IT returns for 3 years, choose a property with clear title and all approvals, and apply for a loan amount that's reasonable for your income. Being an existing HDFC customer can also improve your chances. MinEMI's Eligibility Calculator can help determine your optimal loan amount.",
      },
    ],
    []
  );
  const sbihomehousingData = useMemo(
    () => [
      {
        h2: "Common questions about SBI home loans",
        question: "How long does it take to get an SBI home loan approved?",
        answer:
          "SBI typically processes home loan applications within 8-15 working days. The timeline depends on property documentation, technical verification, and legal assessment. For properties with clear titles and all documentation in order, approvals can be faster. The complete process from application to first disbursement usually takes 2-4 weeks. Using MinEMI's tools before applying can help streamline the process by ensuring you meet all eligibility criteria and have all required documentation ready.",
      },
      {
        question: "What are the common reasons for SBI home loan rejection?",
        answer:
          "Common rejection reasons include: low credit score (below 700), high debt-to-income ratio (above 55%), unstable employment history, incomplete or inconsistent documentation, property legal issues (unclear title, unauthorized construction), property valuation below expectations, and applying for an amount beyond your repayment capacity. SBI places significant emphasis on property documentation and technical verification. MinEMI's Approval Analyzer can help identify potential issues before you apply.",
      },
      {
        question:
          "How can I improve my chances of getting an SBI home loan approved?",
        answer:
          "To maximize approval chances: maintain a credit score above 750, keep credit card utilization below 30%, ensure all existing loans are paid on time, have stable employment with at least 2 years at your current job, provide complete and accurate documentation including Form 16 and IT returns for 3 years, choose a property with clear title and all approvals, and apply for a loan amount that's reasonable for your income. Being an existing SBI customer can also improve your chances. MinEMI's Eligibility Calculator can help determine your optimal loan amount.",
      },
    ],
    []
  );
  const adityahomehousingData = useMemo(
    () => [
      {
        h2: "Common questions about Aditya Birla Finance home loans",
        question:
          "How long does it take to get an Aditya Birla Finance home loan approved?",
        answer:
          "Aditya Birla Finance typically processes home loan applications within 5-10 working days. The timeline depends on property documentation, legal verification, and credit assessment. For salaried professionals with excellent credit scores, ABFL's 'Express Approval' feature can provide in-principle approval in as little as 10 minutes. The complete process from application to first disbursement usually takes 1-3 weeks. Using MinEMI's tools before applying can help streamline the process by ensuring you meet all eligibility criteria and have all required documentation ready.",
      },
      {
        question:
          "What are the common reasons for Aditya Birla Finance home loan rejection?",
        answer:
          "Common rejection reasons include: low credit score (below 680), high FOIR (Fixed Obligation to Income Ratio) above 50%, unstable employment or business history, incomplete or inconsistent documentation, property legal issues (unclear title, unauthorized construction), property valuation below expectations, and applying for an amount beyond your repayment capacity. ABFL is particularly stringent about property legal verification. MinEMI's Approval Analyzer can help identify potential issues before you apply.",
      },
      {
        question:
          "How can I improve my chances of getting an Aditya Birla Finance home loan approved?",
        answer:
          "To maximize approval chances: maintain a credit score above 750, keep credit card utilization below 30%, ensure all existing loans are paid on time, have stable employment with at least 2 years at your current job (or 3 years of business vintage for self-employed), provide complete and accurate documentation, choose a property with clear title and all approvals, and apply for a loan amount that's reasonable for your income. Having existing relationships with Aditya Birla Group companies can also improve your chances. MinEMI's Eligibility Calculator can help determine your optimal loan amount.",
      },
    ],
    []
  );

  useEffect(() => {
    if (name === "checkkotakhomeloanbankstatus") {
      setFaqs(kotakData);
    } else if (name === "checkbobhomeloanbankstatus") {
      setFaqs(bobData);
    } else if (name === "checkidfchomeloanbankstatus") {
      setFaqs(idfcData);
    } else if (name === "checkhdfchomeloanbankstatus") {
      setFaqs(hdfcData);
    } else if (name === "checksbihomeloanbankstatus") {
      setFaqs(sbiData);
    } else if (name === "checkaxishomeloanbankstatus") {
      setFaqs(axisData);
    } else if (name === "checkicicihomeloanbankstatus") {
      setFaqs(iciciData);
    } else if (name === "checkpunjabhomeloanbankstatus") {
      setFaqs(punjabData);
    } else if (name === "checkinduslndhomeloanbankstatus") {
      setFaqs(induslndData);
    } else if (name === "checktatahomeloanbankstatus") {
      setFaqs(tataData);
    } else if (name === "checkyeshomeloanbankstatus") {
      setFaqs(yesData);
    } else if (name === "checkunionhomeloanbankstatus") {
      setFaqs(unionData);
    } else if (name === "checkbajajhomeloanbankstatus") {
      setFaqs(bajajData);
    } else if (name === "checkfederalhomeloanbankstatus") {
      setFaqs(federalData);
    } else if (name === "checkcanarahomeloanbankstatus") {
      setFaqs(canaraData);
    } else if (name === "checkboihomeloanbankstatus") {
      setFaqs(boiData);
    } else if (name === "checkrblhomeloanbankstatus") {
      setFaqs(rblData);
    } else if (name === "checklthomeloanbankstatus") {
      setFaqs(ltData);
    } else if (name === "checkidbihomeloanbankstatus") {
      setFaqs(idbiData);
    } else if (name === "checklichousinghomeloanbankstatus") {
      setFaqs(lichousingData);
    } else if (name === "checkicicihousinghomeloanbankstatus") {
      setFaqs(icicihousingData);
    } else if (name === "checkpnbhousinghomeloanbankstatus") {
      setFaqs(pnbhousingData);
    } else if (name === "checkhdfcltdhomeloanbankstatus") {
      setFaqs(hdfclnthousingData);
    } else if (name === "checksbihousinghomeloanbankstatus") {
      setFaqs(sbihomehousingData);
    } else if (name === "checkadityahousinghomeloanbankstatus") {
      setFaqs(adityahomehousingData);
    } else {
      setFaqs([]);
    }
  }, [
    name,
    kotakData,
    bobData,
    idfcData,
    hdfcData,
    sbiData,
    axisData,
    iciciData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
    ltData,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    hdfclnthousingData,
    sbihomehousingData,
    adityahomehousingData,
  ]);

  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="py-2 bg-white border-t border-light-bg">
      <div className="container px-4 bg-transparent">
        <div className="w-4/5l mx-auto bg-transparent">
          <div className="text-center mb-4 bg-transparent">
            <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple">
              Frequently Asked Questions
            </h2>
            {faqs.length > 0 && (
              <p className="text-dark-grey mt-0 text-sm bg-transparent text-center">
                {faqs[0].h2} {/* Displaying dynamic h2 */}
              </p>
            )}
            {/* <p className="text-dark-grey mt-1 text-sm bg-transparent text-center">
              Get answers to common questions about personal loans
            </p> */}
          </div>
          <div className="space-y-2 bg-transparent">
            {faqs.length > 0 &&
              faqs.slice(0, 3).map((faq, index) => (
                <div
                  key={index}
                  className="border border-light-bg rounded-lg overflow-hidden"
                >
                  <button
                    className="flex items-center justify-between w-full p-3 text-left bg-white hover:bg-light-bg transition-colors"
                    onClick={() => toggleFaq(index)}
                    aria-expanded={openIndex === index}
                  >
                    <h3 className="font-medium text-primary-purple text-xs ">
                      {faq.question}
                    </h3>
                    <ChevronDown
                      className={`h-4 w-4 text-primary-purple transition-transform bg-transparent ${
                        openIndex === index ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                  {openIndex === index && (
                    <div className="p-3 border-t border-light-bg bg-[#ebe8fb]">
                      <p className="text-dark-grey text-xs bg-[#ebe8fb]">
                        {faq.answer}
                      </p>
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className="mt-3 text-center bg-transparent">
            <a
              href="/faq"
              className="inline-flex h-9 items-center justify-center rounded-md bg-teal px-4 text-xs font-medium text-white transition-colors bg-[#3c868b] hover:bg-[purple]"
            >
              View All FAQs
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;

import { useState } from "react";
import {
  Home,
  ChevronDown,
  ChevronUp,
  Percent,
  Clock,
  Banknote,
  AlertCircle,
  FileText,
} from "lucide-react";

const faqs = [
  {
    category: "Home Loan Basics",
    icon: (
      <Home
        className="w-6 h-6 text-[#3a868b]"
        style={{ backgroundColor: "transparent" }}
      />
    ),
    questions: [
      {
        question: "What is a home loan, and how does it work?",
        answer:
          "A home loan is a financial product that allows you to borrow money to purchase a property. You repay the loan amount plus interest over a set period, typically 15-30 years. The property serves as collateral for the loan.",
      },
      {
        question:
          "What are the different types of home loans available in India?",
        answer:
          "In India, common types of home loans include fixed-rate loans, floating-rate loans, balance transfer loans, home improvement loans, and land purchase loans. Each type has its own features and benefits suited to different needs.",
      },
      {
        question: "How do I check my eligibility for a home loan?",
        answer:
          "Eligibility depends on factors like your age, income, credit score, and employment status. Most banks have online eligibility calculators. Minemi offers a comprehensive eligibility check across multiple lenders to find your best options.",
      },
      {
        question: "What is the minimum salary required for a home loan?",
        answer:
          "The minimum salary varies by lender and loan amount. Generally, banks prefer a minimum monthly income of ₹25,000-₹30,000. However, some lenders may consider lower incomes for smaller loan amounts or in tier-2/3 cities.",
      },
    ],
  },
  {
    category: "Interest Rates & EMI",
    icon: (
      <Percent
        className="w-6 h-6 text-[#3a868b]"
        style={{ backgroundColor: "transparent" }}
      />
    ),
    questions: [
      {
        question: "How are home loan interest rates calculated?",
        answer:
          "Home loan interest rates are typically based on the bank's base rate or MCLR (Marginal Cost of Funds based Lending Rate), plus a spread. Factors affecting your rate include credit score, loan amount, tenure, and property type.",
      },
      {
        question:
          "What is the difference between fixed and floating interest rates?",
        answer:
          "Fixed rates remain constant throughout the loan tenure, offering stability in EMIs. Floating rates are linked to market conditions and can change periodically, potentially offering lower rates but with less predictability.",
      },
      {
        question: "How can I reduce my home loan EMI?",
        answer:
          "To reduce your EMI, you can: 1) Opt for a longer loan tenure, 2) Make a larger down payment, 3) Improve your credit score for better rates, 4) Compare and switch to a lender offering lower rates, or 5) Make partial prepayments when possible.",
      },
      {
        question: "Can I prepay or foreclose my home loan?",
        answer:
          "Yes, most banks allow prepayment or foreclosure of home loans. For floating rate loans, there's usually no penalty. Fixed-rate loans may have some charges. Check your loan agreement or with your lender for specific terms.",
      },
    ],
  },
  {
    category: "Application & Approval Process",
    icon: (
      <FileText
        className="w-6 h-6 text-[#3a868b]"
        style={{ backgroundColor: "transparent" }}
      />
    ),
    questions: [
      {
        question: "What documents are required for a home loan?",
        answer:
          "Typically, you'll need: 1) Identity proof (Aadhaar, PAN), 2) Address proof, 3) Income proof (salary slips, ITR), 4) Bank statements, 5) Property documents, and 6) Passport-size photographs. Specific requirements may vary by lender.",
      },
      {
        question: "How long does it take to get a home loan approved?",
        answer:
          "The approval process usually takes 1-2 weeks, depending on the lender and your documentation. Some banks offer faster processing with pre-approval in 24-72 hours. Actual disbursement may take longer based on property documentation.",
      },
      {
        question: "Can I apply for a home loan online through Minemi?",
        answer:
          "While Minemi doesn't directly process loan applications, we provide a platform to compare and choose the best home loan offers. Once you select a loan, we guide you to the lender's online application process for a seamless experience.",
      },
      {
        question: "What should I do if my home loan application is rejected?",
        answer:
          "If rejected: 1) Understand the reason for rejection, 2) Work on improving those aspects (e.g., credit score, documentation), 3) Consider a co-applicant or guarantor, 4) Look for lenders with more lenient criteria, or 5) Opt for a lower loan amount.",
      },
    ],
  },
  {
    category: "Hidden Charges & Fees",
    icon: (
      <AlertCircle
        className="w-6 h-6 text-[#3a868b]"
        style={{ backgroundColor: "transparent" }}
      />
    ),
    questions: [
      {
        question:
          "What are the additional charges in a home loan (processing fees, prepayment penalties, etc.)?",
        answer:
          "Common charges include: 1) Processing fee (0.5-1% of loan amount), 2) Legal and technical charges, 3) Insurance premiums, 4) Stamp duty and registration charges, 5) GST on services, and 6) Prepayment or foreclosure charges (usually for fixed-rate loans).",
      },
      {
        question:
          "Are there any government schemes for lower home loan interest rates?",
        answer:
          "Yes, schemes like Pradhan Mantri Awas Yojana (PMAY) offer interest subsidies for eligible borrowers. Additionally, some state governments have their own housing schemes. Check your eligibility for these schemes to avail lower interest rates.",
      },
    ],
  },
  {
    category: "Loan Repayment & Tax Benefits",
    icon: (
      <Banknote
        className="w-6 h-6 text-[#3a868b]"
        style={{ backgroundColor: "transparent" }}
      />
    ),
    questions: [
      {
        question: "What are the tax benefits on home loans in India?",
        answer:
          "You can claim deductions on: 1) Interest paid (up to ₹2 lakhs under Section 24), 2) Principal repayment (up to ₹1.5 lakhs under Section 80C), 3) Stamp duty and registration charges (one-time benefit). Additional benefits for first-time homebuyers and affordable housing.",
      },
      {
        question:
          "Can I transfer my home loan to another bank for a lower interest rate?",
        answer:
          "Yes, this is called a balance transfer or home loan refinancing. If you find a significantly lower rate, you can transfer your loan. Consider the transfer fees and remaining tenure before deciding. Minemi can help you compare and find the best refinancing options.",
      },
      {
        question: "What happens if I miss an EMI payment?",
        answer:
          "Missing an EMI can lead to: 1) Late payment fees, 2) Negative impact on credit score, 3) Higher interest charges, 4) Potential legal action if continued. If you foresee difficulty, contact your lender proactively to discuss options like restructuring the loan.",
      },
    ],
  },
  {
    category: "Special Cases & Unique Scenarios",
    icon: (
      <Clock
        className="w-6 h-6 text-[#3a868b]"
        style={{ backgroundColor: "transparent" }}
      />
    ),
    questions: [
      {
        question: "Can NRIs apply for a home loan in India?",
        answer:
          "Yes, NRIs can apply for home loans in India. They need to provide additional documents like passport, visa, work permit, and overseas bank statements. The loan amount and interest rates may vary. Some banks have specialized NRI home loan products.",
      },
      {
        question:
          "Can I take a joint home loan with my spouse or family member?",
        answer:
          "Yes, joint home loans are common and often beneficial. They can increase your loan eligibility, provide tax benefits to both applicants, and may offer better interest rates. Both applicants are responsible for repayment.",
      },
      {
        question:
          "What happens to my home loan if I sell the property before completing repayment?",
        answer:
          "If you sell before repayment: 1) Inform your lender, 2) Use the sale proceeds to clear the outstanding loan, 3) Pay any applicable prepayment charges, 4) Receive the remaining amount after loan closure. Ensure all necessary documentation is completed for a smooth process.",
      },
    ],
  },
];

export default function Faq() {
  const [openCategory, setOpenCategory] = useState(null);
  const [openQuestions, setOpenQuestions] = useState({});

  const toggleCategory = (categoryIndex) => {
    setOpenCategory(openCategory === categoryIndex ? null : categoryIndex);
  };

  const toggleQuestion = (categoryIndex, questionIndex) => {
    setOpenQuestions((prev) => ({
      ...prev,
      [`${categoryIndex}-${questionIndex}`]:
        !prev[`${categoryIndex}-${questionIndex}`],
    }));
  };

  return (
    <section className="py-6 sm:py-10 md:py-16 px-3 sm:px-6 md:px-8">
      <div
        className="max-w-4xl mx-auto"
        style={{ backgroundColor: "transparent" }}
      >
        <h2
          className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-center text-[#311664] mb-2 sm:mb-4"
          style={{ backgroundColor: "transparent" }}
        >
          Got Questions? We've Got Answers!
        </h2>
        <p
          className="text-sm text-center text-gray-600 mb-4"
          style={{ backgroundColor: "transparent" }}
        >
          Everything you need to know about home loans in India
        </p>

        <div
          className="space-y-2 sm:space-y-4"
          style={{ backgroundColor: "transparent" }}
        >
          {faqs.map((category, categoryIndex) => (
            <div
              key={categoryIndex}
              className="bg-[#ebe8fc] rounded-lg border border-[#311664] overflow-hidden"
            >
              <button
                className={`w-full px-3 sm:px-4 md:px-6 py-2 sm:py-3 md:py-4 flex items-center justify-between text-left transition-colors duration-300 ${
                  openCategory === categoryIndex
                    ? "bg-[#311664] text-white"
                    : "hover:bg-[#311664] hover:text-white"
                }`}
                onClick={() => toggleCategory(categoryIndex)}
              >
                <div
                  className="flex items-center"
                  style={{ backgroundColor: "transparent" }}
                >
                  {category.icon}
                  <h4
                    className="ml-2 sm:ml-3 text-sm sm:text-base md:text-lg font-semibold"
                    style={{
                      backgroundColor: "transparent",
                      alignSelf: "center",
                    }}
                  >
                    {category.category}
                  </h4>
                </div>
                {openCategory === categoryIndex ? (
                  <ChevronUp
                    className="w-5 h-5"
                    style={{ backgroundColor: "transparent" }}
                  />
                ) : (
                  <ChevronDown
                    className="w-5 h-5"
                    style={{ backgroundColor: "transparent" }}
                  />
                )}
              </button>
              {openCategory === categoryIndex && (
                <div className="px-3 sm:px-4 md:px-6 py-2 sm:py-3 bg-white">
                  {category.questions.map((faq, faqIndex) => (
                    <div
                      key={faqIndex}
                      className="border-b border-gray-200 last:border-b-0"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <button
                        style={{
                          backgroundColor: "transparent",
                          alignItems: "center",
                        }}
                        className="w-full py-2 flex items-center justify-between text-left "
                        onClick={() => toggleQuestion(categoryIndex, faqIndex)}
                      >
                        <h4
                          className="text-left text-xs sm:text-sm md:text-base font-medium text-[#311664]"
                          style={{ backgroundColor: "transparent" }}
                        >
                          {faq.question}
                        </h4>
                        {openQuestions[`${categoryIndex}-${faqIndex}`] ? (
                          <ChevronUp
                            className="w-4 h-4"
                            style={{ backgroundColor: "transparent" }}
                          />
                        ) : (
                          <ChevronDown
                            className="w-4 h-4"
                            style={{ backgroundColor: "transparent" }}
                          />
                        )}
                      </button>
                      {openQuestions[`${categoryIndex}-${faqIndex}`] && (
                        <p
                          className="text-xs sm:text-sm md:text-base text-gray-600 pb-2"
                          style={{ backgroundColor: "transparent" }}
                        >
                          {faq.answer}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        <div
          className="mt-6 text-center"
          style={{ backgroundColor: "transparent" }}
        >
          <p
            className="text-sm text-[#311664] font-semibold mb-2"
            style={{ backgroundColor: "transparent", textAlign: "center" }}
          >
            Still have questions? Our home loan experts are here to help!
          </p>
          <button className="bg-[#3a868b] hover:bg-[#59266d] text-white text-sm sm:text-base md:text-lg py-2 sm:py-3 px-4 sm:px-6 md:px-8 rounded-lg transition-colors duration-300">
            Contact Support
          </button>
        </div>

        <p
          className="text-xs text-center text-gray-500 mt-4"
          style={{ backgroundColor: "transparent" }}
        >
          Our home loan experts update this FAQ regularly to provide the most
          accurate information.
        </p>
      </div>
    </section>
  );
}

import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { CheckCircle, ChevronLeft, ChevronRight, Star } from "lucide-react";

export default function TestimonialSection() {
  const testimonials = [
    {
      id: 1,
      name: "Aarav Joshi",
      location: "Pune",
      quote:
        "Minemi made checking my credit card eligibility so simple. In less than a minute, I knew exactly which cards I qualified for—no guesswork, no hidden terms!",
      rating: 5,
      avatar: "/placeholder.svg?height=80&width=80",
    },
    {
      id: 2,
      name: "Meera Deshmukh",
      location: "Jaipur",
      quote:
        "I was worried about my credit score getting affected, but Minemi assured me it wouldn't. Their eligibility check was accurate, and I got my ideal card approved within days!",
      rating: 5,
      avatar: "/placeholder.svg?height=80&width=80",
    },
    {
      id: 3,
      name: "Rajat Kapoor",
      location: "Kolkata",
      quote:
        "I've tried different platforms, but Minemi's recommendations were by far the most accurate. It saved me from multiple rejections and helped me find a card that suited my lifestyle.",
      rating: 4,
      avatar: "/placeholder.svg?height=80&width=80",
    },
    {
      id: 4,
      name: "Sanya Iyer",
      location: "Ahmedabad",
      quote:
        "Minemi felt like a personal advisor, not just a tool. The security and transparency reassured me, and the recommendations were tailored perfectly for my needs!",
      rating: 5,
      avatar: "/placeholder.svg?height=80&width=80",
    },
    {
      id: 5,
      name: "Kabir Malhotra",
      location: "Chandigarh",
      quote:
        "I never realized how easy choosing the right card could be until I used Minemi. The entire process was smooth, and I felt completely in control of my choices.",
      rating: 5,
      avatar: "/placeholder.svg?height=80&width=80",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleCount, setVisibleCount] = useState(1);
  const [autoplay, setAutoplay] = useState(true);
  const autoplayRef = useRef(null);

  // Determine how many testimonials to show based on screen size
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1280) {
        setVisibleCount(3);
      } else if (window.innerWidth >= 768) {
        setVisibleCount(2);
      } else {
        setVisibleCount(1);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Autoplay functionality
  useEffect(() => {
    if (autoplay) {
      autoplayRef.current = setInterval(() => {
        setActiveIndex(
          (prev) => (prev + 1) % (testimonials.length - visibleCount + 1)
        );
      }, 5000);
    }

    return () => {
      if (autoplayRef.current) {
        clearInterval(autoplayRef.current);
      }
    };
  }, [autoplay, testimonials.length, visibleCount]);

  const handlePrev = () => {
    setAutoplay(false);
    setActiveIndex((prev) =>
      prev === 0 ? testimonials.length - visibleCount : prev - 1
    );
  };

  const handleNext = () => {
    setAutoplay(false);
    setActiveIndex((prev) =>
      prev === testimonials.length - visibleCount ? 0 : prev + 1
    );
  };

  const getVisibleTestimonials = () => {
    return testimonials.slice(activeIndex, activeIndex + visibleCount);
  };

  return (
    <section className="py-10 md:py-20 px-4 md:px-8 lg:px-16 bg-gradient-to-br from-white to-[#ebe8fc]/30">
      <div className="container max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-8 md:mb-16"
        >
          <h2 className="text-2xl md:text-4xl font-bold text-[#311664] mb-2 md:mb-4">
            Trusted by Thousands. Recommended by Real Users.
          </h2>
          <div
            className="flex items-center justify-center gap-1 mb-4"
            style={{ flexDirection: "row" }}
          >
            {[1, 2, 3, 4, 5].map((star) => (
              <Star
                key={star}
                className="h-5 w-5 fill-yellow-400 text-yellow-400"
              />
            ))}
            <span className="ml-2 text-lg font-semibold text-gray-700">
              4.9/5
            </span>
            <span className="ml-1 text-sm text-gray-500">
              (based on 2,347 reviews)
            </span>
          </div>
          <p className="text-base md:text-lg text-gray-600 max-w-2xl mx-auto">
            See what our users have to say about their experience with Minemi's
            credit card eligibility checker.
          </p>
        </motion.div>

        <div className="relative">
          {/* Carousel navigation */}
          <div className="absolute top-1/2 -left-4 md:-left-8 -translate-y-1/2 z-10">
            <button
              variant="outline"
              size="icon"
              className="rounded-full bg-white shadow-md hover:bg-[#ebe8fc] border-none"
              onClick={handlePrev}
            >
              <ChevronLeft className="h-5 w-5 text-[#311664]" />
              <span className="sr-only">Previous testimonial</span>
            </button>
          </div>

          <div className="absolute top-1/2 -right-4 md:-right-8 -translate-y-1/2 z-10">
            <button
              variant="outline"
              size="icon"
              className="rounded-full bg-white shadow-md hover:bg-[#ebe8fc] border-none"
              onClick={handleNext}
            >
              <ChevronRight className="h-5 w-5 text-[#311664]" />
              <span className="sr-only">Next testimonial</span>
            </button>
          </div>

          {/* Testimonial cards */}
          <div className="overflow-hidden px-4">
            <div className="flex gap-6">
              <AnimatePresence initial={false} mode="wait">
                {getVisibleTestimonials().map((testimonial) => (
                  <motion.div
                    key={testimonial.id}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -50 }}
                    transition={{ duration: 0.5 }}
                    className="flex-1 min-w-0 "
                  >
                    <div className="bg-white rounded-xl shadow-lg p-6 md:p-8 h-full flex flex-col">
                      <div className="flex items-center mb-4">
                        <div className="relative">
                          <img
                            src="https://kzmic7ri6hboo0trjfow.lite.vusercontent.net/placeholder.svg?height=200&width=300"
                            alt={testimonial.name}
                            className="w-16 h-16 rounded-full object-cover border-2 border-[#3a868b]"
                          />
                          <div className="absolute -bottom-1 -right-1 bg-[#311664] rounded-full p-1">
                            <CheckCircle className="h-4 w-4 text-white" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <div className="flex items-center">
                            <h3 className="text-lg font-semibold text-[#311664]">
                              {testimonial.name}
                            </h3>
                            <span className="ml-2 text-xs px-2 py-0.5 bg-[#ebe8fc] text-[#311664] rounded-full">
                              Verified
                            </span>
                          </div>
                          <p className="text-gray-500">
                            {testimonial.location}
                          </p>
                          <div className="flex mt-1">
                            {Array.from({ length: 5 }).map((_, i) => (
                              <Star
                                key={i}
                                className={`h-4 w-4 ${
                                  i < testimonial.rating
                                    ? "fill-yellow-400 text-yellow-400"
                                    : "text-gray-300"
                                }`}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="flex-1">
                        <p className="text-gray-700 italic">
                          "{testimonial.quote}"
                        </p>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>
          </div>

          {/* Carousel indicators */}
          <div className="flex justify-center mt-8 space-x-2">
            {Array.from({ length: testimonials.length - visibleCount + 1 }).map(
              (_, index) => (
                <button
                  key={index}
                  className={`h-2 rounded-full transition-all ${
                    activeIndex === index
                      ? "w-8 bg-[#311664]"
                      : "w-2 bg-gray-300"
                  }`}
                  onClick={() => {
                    setAutoplay(false);
                    setActiveIndex(index);
                  }}
                  aria-label={`Go to testimonial ${index + 1}`}
                />
              )
            )}
          </div>
        </div>

        <div className="text-center mt-10">
          <button
            variant="outline"
            className="border-[#311664] text-[#311664] hover:bg-[#ebe8fc]"
          >
            Read More Reviews
          </button>
        </div>
      </div>
    </section>
  );
}

import { ShieldCheck, Lock } from "lucide-react"

export default function SecuritySection() {
  return (
    <section className="w-full py-5 md:py-16 bg-[#311664] text-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-3 md:mb-12">
          <h2 className="text-xl md:text-4xl font-bold mb-1 md:mb-4">
            Your Data is Safe & Our Predictions Are Accurate
          </h2>
          <p className="text-xs md:text-lg text-[#ebe8fc] max-w-2xl mx-auto">
            At Minemi, we take your data security and the accuracy of our predictions seriously.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-3 md:gap-8 max-w-4xl mx-auto">
          <div className="bg-white/10 backdrop-blur-sm p-3 md:p-8 rounded-xl border border-white/20 py-4">
            <div className="flex items-start">
              <div className="mr-2 md:mr-4">
                <ShieldCheck className="h-4 w-4 md:h-10 md:w-10 text-[#3a868b]" />
              </div>
              <div>
                <h3 className="text-sm md:text-xl font-bold mb-0.5 md:mb-3">AI-Driven Accuracy</h3>
                <p className="text-[10px] md:text-base text-[#ebe8fc]">
                  Our system is aligned with actual approval criteria used by major credit card issuers. We regularly
                  update our algorithms to reflect current practices, ensuring you get the most accurate assessment.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-white/10 backdrop-blur-sm p-3 md:p-8 rounded-xl border border-white/20 py-4">
            <div className="flex items-start">
              <div className="mr-2 md:mr-4">
                <Lock className="h-4 w-4 md:h-10 md:w-10 text-[#3a868b]" />
              </div>
              <div>
                <h3 className="text-sm md:text-xl font-bold mb-0.5 md:mb-3">Bank-Level Encryption</h3>
                <p className="text-[10px] md:text-base text-[#ebe8fc]">
                  Your personal information is protected with bank-level encryption. We never share your data with third
                  parties without your explicit consent, and we adhere to strict data privacy regulations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


import { CheckCircle } from "lucide-react";
import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

export function KeyApprovalFactors({ name }) {
  const [data, setData] = useState({
    title: "",
    description: "",
    statuses: [],
    footer: { text: "", para: "", link: "" },
  });
  const kotakData = useMemo(
    () => ({
      title: "Key Factors That Affect Your Kotak Bank Loan Approval",
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "/trackloanstatus",
      },
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const idfcData = useMemo(
    () => ({
      title: "Key Factors That Affect Your IDFC FIRST Bank Loan Approval",
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. IDFC FIRST Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to IDFC FIRST Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to IDFC FIRST Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "IDFC FIRST Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "IDFC FIRST Bank values employment stability as it indicates consistent income and repayment ability",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to IDFC FIRST Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your IDFC FIRST Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      display: "none",
      title: null,
      description:
        "     Beyond credit score, these factors significantly impact your loan approval chances",
      statuses: [
        {
          title: "Fixed Obligation to Income Ratio (FOIR)",
          description:
            "FOIR measures the percentage of your monthly income that goes toward debt repayments. Kotak Bank typically prefers a FOIR below 50-55%.",
          goodPractice: "Keep your FOIR below 50% for higher approval chances",
          avoid: "Taking multiple loans that push your FOIR above 60%",
        },
        {
          title: "Credit History Age",
          description:
            "The length of your credit history matters to Kotak Bank. A longer credit history demonstrates your experience in managing credit.",
          goodPractice: "Maintain a credit history of at least 3+ years",
          avoid:
            "Closing your oldest credit accounts, which shortens your history",
        },
        {
          title: "Number of Unsecured Loans",
          description:
            "Having too many unsecured loans (personal loans, credit cards) can signal financial stress to Kotak Bank.",
          goodPractice: "Limit unsecured loans to 2-3 at any given time",
          avoid:
            "Taking multiple personal loans from different lenders simultaneously",
        },
        {
          title: "Credit Card Utilization",
          description:
            "Kotak Bank closely monitors how much of your available credit card limit you're using. High utilization can indicate financial strain.",
          goodPractice:
            "Keep credit card utilization below 30% of your total limit",
          avoid:
            "Maxing out your credit cards or consistently using more than 70% of your limit",
        },
        {
          title: "Employment Stability",
          description:
            "Kotak Bank values employment stability as it indicates consistent income and repayment ability.",
          goodPractice:
            "Stay with the same employer for at least 1-2 years before applying",
          avoid:
            "Frequent job changes within short periods (less than 6 months)",
        },
        {
          title: "Recent Credit Inquiries",
          description:
            "Multiple loan applications in a short period can signal financial distress to Kotak Bank.",
          goodPractice: "Space out loan applications by at least 3-6 months",
          avoid:
            "Applying for multiple loans across different banks within weeks",
        },
      ],
      footer: {
        text: " Get Your Personalized Analysis",
        para: " Want to know how these factors specifically affect your Kotak Bank loan application?",
        link: "#loan-status-checker",
      },
    }),
    []
  );

  useEffect(() => {
    if (name === "checkkotakbankstatus") {
      setData(kotakData);
    } else if (name === "checkbobbankstatus") {
      setData(bobData);
    } else if (name === "checkidfcbankstatus") {
      setData(idfcData);
    } else if (name === "checkhdfcbankstatus") {
      setData(hdfcData);
    } else if (name === "checksbibankstatus") {
      setData(sbiData);
    } else if (name === "checkaxisbankstatus") {
      setData(axisData);
    } else if (name === "checkicicibankstatus") {
      setData(iciciData);
    } else if (name === "checkpunjabbankstatus") {
      setData(punjabData);
    } else if (name === "checkinduslndbankstatus") {
      setData(induslndData);
    } else if (name === "checktatabankstatus") {
      setData(tataData);
    } else if (name === "checkyesbankstatus") {
      setData(yesData);
    } else if (name === "checkunionbankstatus") {
      setData(unionData);
    } else if (name === "checkbajajbankstatus") {
      setData(bajajData);
    } else if (name === "checkfederalbankstatus") {
      setData(federalData);
    } else if (name === "checkcanarabankstatus") {
      setData(canaraData);
    } else if (name === "checkboibankstatus") {
      setData(boiData);
    } else if (name === "checkrblbankstatus") {
      setData(rblData);
    } else {
      setData({
        title: "",
        description: "",
        statuses: [],
        footer: { text: "", para: "", link: "" },
      });
    }
  }, [
    name,
    axisData,
    bobData,
    hdfcData,
    iciciData,
    idfcData,
    kotakData,
    sbiData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
  ]);
  return (
    <>
      {data.title == null ? null : (
        <section className="py-2 bg-white border-b border-gray-200">
          <div className="container sd:px-4 md:px-6 bg-transparent">
            <div className="md:w-4/5 sd:w-full mx-auto bg-transparent">
              <div className="text-center mb-8 bg-transparent">
                <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl text-primary-purple">
                  {data.title}
                </h2>
                <p className="text-dark-grey bg-transparent text-center">
                  {data.description}
                </p>
              </div>

              <div className="grid gap-6 md:grid-cols-2 bg-transparent">
                {data.statuses.map((d, index) => (
                  <div
                    key={index}
                    className=" p-6 rounded-lg border border-gray-200 shadow-sm"
                    style={{ backgroundColor: "#ebe8fb" }}
                  >
                    <h3 className="text-lg font-bold text-primary-purple mb-4">
                      {d.title}
                    </h3>
                    <p className="text-dark-grey mb-4 text-sm bg-transparent">
                      {d.description}
                    </p>
                    <div className="space-y-3 bg-transparent">
                      <div className="flex items-start gap-0.5  bg-transparent">
                        {/* <CheckCircle className="h-5 w-5 text-green-600 mt-0.5 bg-transparent" /> */}
                        <CheckCircle
                          className="h-6 w-6 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        />
                        <div className="bg-transparent">
                          <p className="text-sm text-dark-grey bg-transparent">
                            {d.goodPractice}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-start gap-0.5 bg-transparent">
                        {/* <CheckCircle className="h-5 w-5 text-green-600 mt-0.5 bg-transparent" /> */}
                        <CheckCircle
                          className="h-6 w-6 md:h-4 md:w-4 text-teal mt-0.5 mx-1 bg-transparent"
                          style={{ color: "green" }}
                        />
                        <div className="bg-transparent">
                          <p className="text-sm text-dark-grey bg-transparent">
                            {d.avoid}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {data.footer.text && (
                <div className="mt-8 text-center">
                  <p className="text-dark-grey mb-4">{data.footer.para}</p>
                  <Link
                    to={data.footer.link}
                    className="inline-flex h-12 items-center justify-center rounded-md bg-teal px-8 text-sm font-medium text-white hover:bg-primary-purple transition-colors bg-[#3b868b] hover:bg-[#2b1b61]"
                  >
                    {data.footer.text}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

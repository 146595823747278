
import { useState } from "react"
import user from "../../../Assets/Images/creditScore-image/Authorized.webp"



const testimonials = [
  {
    id: 1,
    name: "Priyesh Shah",
    role: "CEO, TechCorp",
    content: "This product has revolutionized our workflow. Highly recommended!",
    image: "/placeholder.svg?height=100&width=100",
  },
  {
    id: 2,
    name: "Aditi Malhotra",
    role: "Designer, CreativeCo",
    content: "I've never been more productive. This tool is a game-changer.",
    image: "/placeholder.svg?height=100&width=100",
  },
  {
    id: 3,
    name: "Vikalp",
    role: "Manager, InnovateCo",
    content: "Our team's efficiency has increased tenfold since we started using this.",
    image: "/placeholder.svg?height=100&width=100",
  },
  {
    id: 4,
    name: "Ayur Singh",
    role: "Developer, CodeCraft",
    content: "The features and user experience are unparalleled. A must-have tool.",
    image: "/placeholder.svg?height=100&width=100",
  },
  {
    id: 5,
    name: "Shubham Siddharth",
    role: "Entrepreneur",
    content: "This solution has been integral to scaling my business efficiently.",
    image: "/placeholder.svg?height=100&width=100",
  },
   {
    id: 6,
    name: "Uma Yadav",
    role: "Developer, CodeCraft",
    content: "The features and user experience are unparalleled. A must-have tool.",
    image: "/placeholder.svg?height=100&width=100",
  },
  {
    id: 7,
    name: "",
    role: "Annaya",
    content: "This solution has been integral to scaling my business efficiently.",
    image: "/placeholder.svg?height=100&width=100",
  },
]

export default function TestimonialSection() {
  const [activeIndex, setActiveIndex] = useState(0)



  return (
    <div className="w-full max-w-6xl mx-auto px-4 py-8 "   style={{backgroundColor:'transparent'}}>
      <h2 className="text-3xl font-bold text-center mb-8" style={{backgroundColor:'transparent',color:'#311664'}}>What Our Clients Say</h2>
      <div className="relative overflow-hidden" style={{backgroundColor:'transparent'}}>
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${activeIndex * 100}%)` ,backgroundColor:'transparent'}}
        >
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="w-full flex-shrink-0 px-4 md:w-1/3" style={{backgroundColor:'transparent'}}>
              <div className="bg-white rounded-lg p-6 h-full flex flex-col">
                <div className="flex items-center mb-4" style={{backgroundColor:'transparent'}}>
                  <img
                    src={user}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full mr-4"
                    style={{backgroundColor:'transparent'}}
                  />
                  <div style={{backgroundColor:'transparent'}}>
                    <h3 className="font-semibold" style={{backgroundColor:'transparent'}}>{testimonial.name}</h3>
                    <p className="text-sm text-gray-600" style={{backgroundColor:'transparent',marginTop:'-13%'}}>{testimonial.role}</p>
                  </div>
                </div>
                <p className="text-gray-700 flex-grow" style={{backgroundColor:'transparent'}}>{testimonial.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-4" style={{backgroundColor:'transparent'}}>
        {[0, 1, 2].map((index) => (
          <button
            key={index}
            className={`h-2 w-2 rounded-full mx-1 ${index === activeIndex ? "bg-[#311664]" : "bg-gray-300"}`}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </div>
    </div>
  )
}


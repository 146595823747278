
import React, { useEffect } from "react";
import "./HeroSection.css";
import { IoMdArrowForward } from "react-icons/io";
import lowerEmiIllustration from "../../Assets/Images/LowerEmiIllustration.webp";
import ConsolidateIllustration from "../../Assets/Images/ConsolidateIllustration.webp";
import TopUPIllustration from "../../Assets/Images/TopUPIllustration.1.webp";
import { FaArrowRight } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Goal() {
  const navigate = useNavigate();

  const handleClick = () => {
    localStorage.setItem("id", 1);
    navigate("/providedetails");
  };
  const handleClick2 = () => {
    localStorage.setItem("id", 2);
    navigate("/providedetails");
  };
  const handleClick3 = () => {
    localStorage.setItem("id", 3);
    navigate("/providedetails");
  };
  useEffect(() => {
    const from = localStorage.getItem("from");
    {
      from == null && navigate("/login");
    }
  }, []);
  const goalOptions = [
    {
      id: 1,
      title: "Lower Your EMIs",
      description: "Use our algorithm to reduce your loan interest rate based on your credit score.",
      image: lowerEmiIllustration,
    },
    {
      id: 2,
      title: "Club Multiple EMIs",
      description: "Review and consolidate high-interest loans and overdraft accounts affecting your credit profile.",
      image: ConsolidateIllustration,
    },
    {
      id: 3,
      title: "Get New Loan with lower EMI",
      description: "Get extra funds easily to chase new goals and opportunity.",
      image: TopUPIllustration,
    },
  ]
   useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
    }, [])
    
  return (
    <>
      <div className="HeroSection">
        <div className="HeroHeading">Minimize your EMIs with MinEMI</div>
        <div
          className="SubHeading"
          style={{
            fontSize: "16px",
            fontWeight: "800",
            paddingTop: "24px",
            fontFamily: "Archivo",
          }}
        >
          Are you Paying more EMIs than you should?
          <p style={{ color: "rgb(0, 153, 100)" }}>With MinEMI you can:</p>
        </div>

      
        <div
  className="HeroContainer grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
  style={{ backgroundColor: "transparent" }}
>
  {goalOptions.map((option) => (
  
      <div className=" Section"     key={option.id}>
                <a
                  className="lowerEmi"
                  onClick={() => handleClick(option.id)}
                >
                  <div className="InnerSection">
                    <div className="InnerSectionText">
                      <div
                        style={{
                          fontWeight: 500,
                          fontSize: "18px",
                          color: "rgba(89, 38, 109, 1)",
                          backgroundColor: "rgba(85, 50, 133, 0)",
                        }}
                      >
                       {option.title}
                      </div>
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "rgba(138, 138, 138, 1)",
                          backgroundColor: "rgba(85, 50, 133, 0)",
                        }}
                      >
                       {option.description}
                      </div>
                    </div>
                    <div
                     
                      style={{ backgroundColor: "transparent" }}
                    >
                      <IoMdArrowForward
                        size={30}
                        color={"#59266D"}
                        style={{ backgroundColor: "rgba(85, 50, 133, 0)" }}
                      />
                    </div>
                  </div>
                  <div className="Illustration">
                    <img
                      src={option.image}
                      alt="Lower Emi"
                      loading="lazy"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </a>
              </div>
  ))}
</div>

        <div className="HeroContainerMobile mt-3">
          <Link
            onClick={handleClick}
            to="/providedetails"
            className="lowerEmiMobile"
          >
            <div
              style={{
                width: "117px",
                height: "94px",
                marginTop: "22px",
                marginBottom: "28px",
                marginLeft: "2px",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={lowerEmiIllustration}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                alt="Lower My EMI"
              />
            </div>
            <div
              style={{
                marginTop: "32px",
                marginRight: "8px",
                marginBottom: "24px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                width: "203px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "transparent",
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "21px",
                    color: "rgba(89, 38, 109, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                  Lower Your EMIs
                </div>
                <FaArrowRight
                  size={16}
                  color={"rgba(89, 38, 109, 1)"}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 138, 138, 1)",
                  backgroundColor: "transparent",
                }}
              >
                Use our algorithm to reduce your loan interest rate based on
                your credit score.
              </div>
            </div>
          </Link>
          <Link
            onClick={handleClick2}
            to="/providedetails"
            className="lowerEmiMobile"
          >
            <div
              style={{
                width: "117px",
                height: "94px",
                marginTop: "22px",
                marginBottom: "28px",
                marginLeft: "2px",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={ConsolidateIllustration}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                alt="Loan Consolidate"
              />
            </div>
            <div
              style={{
                marginTop: "32px",
                marginRight: "8px",
                marginBottom: "24px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                width: "203px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "transparent",
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "21px",
                    color: "rgba(89, 38, 109, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                  Club Multiple EMIs
                </div>
                <FaArrowRight
                  size={16}
                  color={"rgba(89, 38, 109, 1)"}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 138, 138, 1)",
                  backgroundColor: "transparent",
                }}
              >
                Review and consolidate high-interest loans and overdraft
                accounts affecting your credit profile.
              </div>
            </div>
          </Link>
          <Link
            onClick={handleClick3}
            to="/providedetails"
            className="lowerEmiMobile"
          >
            <div
              style={{
                width: "117px",
                height: "94px",
                marginTop: "22px",
                marginBottom: "28px",
                marginLeft: "2px",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={TopUPIllustration}
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                alt="Top Up"
              />
            </div>
            <div
              style={{
                marginTop: "32px",
                marginRight: "8px",
                marginBottom: "24px",
                gap: "5px",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "transparent",
                width: "203px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "transparent",
                }}
              >
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "21px",
                    color: "rgba(89, 38, 109, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                  Get New Loan with lower EMI
                </div>
                <FaArrowRight
                  // onClick={handleClick3}
                  size={16}
                  color={"rgba(89, 38, 109, 1)"}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "rgba(138, 138, 138, 1)",
                  backgroundColor: "transparent",
                }}
              >
                Get extra funds easily to chase new goals and opportunity.
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Goal;

import React, { useContext, useEffect, useState } from "react";
import { Star, Info, CheckCircle, Clock, Shield } from "lucide-react";
import { useNavigate } from "react-router-dom";
import "./HomeLoanCompareLandingPage.css";
import TopPicksSection from "./TopPicksSection";
import aditya from "../../../Assets/Images/AdityaHome.webp";
import piramal from "../../../Assets/Images/Piramil.png";
import hdfc from "../../../Assets/Images/hdfc.png";
import sbi from "../../../Assets/Images/SBI-Home-Loan.webp";
import icici from "../../../Assets/Images/icici.webp";
import lic from "../../../Assets/Images/LIC-Housing.webp";
import union from "../../../Assets/Images/union_circular.webp";
import MetadataContext from "../../MetadataContext";

export default function HomeLoanCompareLandingPage() {
  const [loanAmount, setLoanAmount] = useState(3000000);
  const [interestRate, setInterestRate] = useState(8.5);
  const [loanTenure, setLoanTenure] = useState(20);

  const navigate = useNavigate();

  // Calculate EMI and related values
  const monthlyInterestRate = interestRate / 12 / 100;
  const totalMonths = loanTenure * 12;

  // EMI calculation formula: [P x R x (1+R)^N]/[(1+R)^N-1]
  const emi =
    (loanAmount *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, totalMonths)) /
    (Math.pow(1 + monthlyInterestRate, totalMonths) - 1);

  const totalAmount = emi * totalMonths;
  const totalInterest = totalAmount - loanAmount;

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Best Home Loans 2025 | Compare Rates & Apply Online - Minemi",
      pageDescription:
        "Compare home loans from India's top banks & NBFCs. Find the lowest interest rates & best offers in 2025. Check eligibility & apply online with Minemi.",
      pageKeywords:
        "best home loans 2025, compare home loan rates, apply for home loan online, mortgage rates 2025, best mortgage lenders, home loan comparison, low interest home loans, fixed rate mortgages, variable rate home loans, best home loan lenders, mortgage refinance rates, first-time home buyer loans, home loan eligibility, online mortgage application, Minemi home loans",

      canonicalUrl:
        "https://minemi.ai/loanpage/homeloan/best-home-loans-2025-compare",
    });
  }, [setMetadata]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="flex min-h-screen flex-col "
      style={{ backgroundColor: "#f8f8f8" }}
    >
      {/* Hero Section */}
      <section className="bg-gradient-to-b from-[#ebe8fc] to-[#f8f8f8] py-16">
        <div className="container mx-auto px-3">
          <div className="mx-auto max-w-3xl text-center">
            <h1 className="mb-4 text-4xl font-bold leading-tight text-[#311664] md:text-5xl">
              Find the Best Home Loan: Compare Rates, Save on EMIs, Own Your
              Dream Home!
            </h1>
            <p className="mb-8 text-lg text-[#26292b]">
              Compare home loans from India's top lenders to find the lowest
              interest rates and best terms tailored to your needs. Make your
              home buying journey smoother with Minemi.
            </p>
            <button
              className="rounded-md bg-[#3a868b] px-2 px-sm-4 py-3  text-lg font-medium text-white shadow-lg transition-transform hover:scale-105 hover:bg-[#3a868b]/90"
              onClick={() =>
                document
                  .getElementById("home_loan_eligibility_banks")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
            >
              Check Your Home Loan Eligibility
            </button>
          </div>
        </div>
      </section>

      {/* Loan Comparison Table */}
      <section id="home_loan_compare" className="py-16">
        <div className=" mx-auto px-4">
          <h2 className="mb-8 text-center text-3xl font-bold text-[#311664]">
            Best Home Loan Lenders of{" "}
            {new Date().toLocaleString("default", { month: "long" })}{" "}
            {new Date().getFullYear()}
          </h2>
          <p className="mb-8 text-center text-[#26292b]">
            The best home loan lenders offer competitive interest rates,
            flexible repayment options, and minimal processing fees. Compare
            home loans from multiple lenders before making this important
            financial decision.
          </p>

          <div className="overflow-x-auto rounded-lg border shadow-md">
            <table className="w-full border-collapse bg-white text-left text-sm">
              <thead className="bg-[#ebe8fc]">
                <tr>
                  <th className="px-6 py-4 font-medium text-[#311664]">
                    Lender
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]">
                    <div className="flex items-center">
                      Minemi Rating
                      <Info className="ml-1 h-4 w-4 text-[#59266d]" />
                    </div>
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]">
                    <div className="flex items-center">
                      Estimated APR
                      <Info className="ml-1 h-4 w-4 text-[#59266d]" />
                    </div>
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]">
                    <div className="flex items-center">
                      Loan Amount
                      <Info className="ml-1 h-4 w-4 text-[#59266d]" />
                    </div>
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]">
                    <div className="flex items-center">
                      Min. Credit Score
                      <Info className="ml-1 h-4 w-4 text-[#59266d]" />
                    </div>
                  </th>
                  <th className="px-6 py-4 font-medium text-[#311664]"></th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {[
                  {
                    name: "HDFC Bank",
                    img: hdfc,
                    rating: 5.0,
                    bestFor: "Best overall home loan provider",
                    apr: "8.50% - 10.25%",
                    amount: "₹5,00,000 - ₹10 Cr",
                    score: "750",
                    link: "/homeloan/applyonline",
                  },
                  {
                    name: "SBI Home Loans",
                    img: sbi,
                    rating: 4.5,
                    bestFor: "Best for government employees",
                    apr: "8.35% - 10.75%",
                    amount: "₹5,00,000 - ₹10 Cr",
                    score: "720",
                    link: "/homeloan/applyonline",
                  },
                  {
                    name: "Aditya Birla Housing Finance",
                    img: aditya,
                    rating: 4.5,
                    bestFor: "Best for self-employed individuals",
                    apr: "8.75% - 12.50%",
                    amount: "₹2,00,000 - ₹5 Cr",
                    score: "700",
                    link: "/homeloan/applyonline",
                  },
                  {
                    name: "Piramal Capital Housing Finance",
                    img: piramal,
                    rating: 4.5,
                    bestFor: "Best for affordable housing",
                    apr: "8.90% - 11.75%",
                    amount: "₹2,00,000 - ₹3 Cr",
                    score: "710",
                    link: "/homeloan/applyonline",
                  },
                  {
                    name: "ICICI Bank",
                    img: icici,
                    rating: 5.0,
                    bestFor: "Best for existing bank customers",
                    apr: "8.40% - 10.50%",
                    amount: "₹5,00,000 - ₹10 Cr",
                    score: "750",
                    link: "/homeloan/applyonline",
                  },
                  {
                    name: "LIC Housing Finance",
                    img: lic,
                    rating: 4.3,
                    bestFor: "Best for long tenure loans",
                    apr: "8.60% - 11.00%",
                    amount: "₹2,00,000 - ₹5 Cr",
                    score: "720",
                    link: "/homeloan/applyonline",
                  },
                  {
                    name: "Union Bank of India",
                    img: union,
                    rating: 4.4,
                    bestFor: "Best for self-employed individuals",
                    apr: "8.55% - 10.90%",
                    amount: "₹2,00,000 - ₹5 Cr",
                    score: "710",
                    link: "/homeloan/applyonline",
                  },
                  // {
                  //   name: "Bank of Baroda",
                  //   img: aditya,
                  //   rating: 5.0,
                  //   bestFor: "Best for self-employed individuals",
                  //   apr: "8.30% - 10.40%",
                  //   amount: "₹5,00,000 - ₹10 Cr",
                  //   score: "750",
                  // },
                  // {
                  //   name: "Bajaj Housing Finance",
                  //   img: bajaj,
                  //   rating: 5.0,
                  //   bestFor: "Best for salaried professionals",
                  //   apr: "8.50% - 10.25%",
                  //   amount: "₹5,00,000 - ₹10 Cr",
                  //   score: "750",
                  // },
                  // {
                  //   name: "Piramal Capital Housing Finance",
                  //   img: piramal,
                  //   rating: 4.5,
                  //   bestFor: "Best for affordable housing",
                  //   rate: "8.65-10.50%",
                  //   ltv: "Up to 90%",
                  //   fee: "0.50-1.00%",
                  // },
                  // {
                  //   name: "LIC Housing Finance",
                  //   img: lic,
                  //   rating: 4.0,
                  //   bestFor: "Best for long tenure loans",
                  //   rate: "8.50-9.80%",
                  //   ltv: "Up to 90%",
                  //   fee: "0.50%",
                  // },
                ].map((lender, index) => (
                  <tr key={index} className="hover:bg-[#ebe8fc]/30">
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0 rounded-full overflow-hidden">
                          <img
                            src={lender.img}
                            alt={lender.name}
                            width={40}
                            height={40}
                            className="object-cover"
                          />
                        </div>
                        <div className="ml-3">
                          <div className="font-medium text-[#311664]">
                            {lender.name}
                          </div>
                          <div className="text-xs text-gray-500">
                            {lender.bestFor}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <span className="mr-1 font-medium">
                          {lender.rating}
                        </span>
                        <div className="flex">
                          {Array(5)
                            .fill(0)
                            .map((_, i) => (
                              <Star
                                key={i}
                                className={`h-4 w-4 ${
                                  i < Math.floor(lender.rating)
                                    ? "fill-[#59266d] text-[#59266d]"
                                    : "text-gray-300"
                                }`}
                              />
                            ))}
                        </div>
                        <span className="ml-1 text-xs text-gray-500">/5</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 font-medium">{lender.apr}</td>
                    <td className="px-6 py-4">{lender.amount}</td>
                    <td className="px-6 py-4">{lender.score}</td>
                    <td className="px-6 py-4">
                      <button
                        className="rounded bg-[#59266d] px-4 py-2 text-sm font-medium text-white hover:bg-[#59266d]/90"
                        style={{ width: "7rem" }}
                        onClick={() => {
                          navigate(lender.link);
                        }}
                      >
                        Check My Rate
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-4 text-xs text-gray-500">
            <p>
              Many, or all, of the products featured on this page are from our
              partners who compensate us when you take certain actions on our
              website or click to apply on their website. However, this does not
              influence our evaluations. Our opinions are our own.
            </p>
          </div>
        </div>
      </section>

      {/* Top Picks Section */}
      <div id="home_loan_eligibility_banks">
        <TopPicksSection />
      </div>

      {/* Key Features Section */}
      <section className="bg-[#ebe8fc]/30 py-16">
        <div className="container mx-auto px-4">
          <h2 className="mb-12 text-center text-3xl font-bold text-[#311664]">
            Why Choose Minemi for Home Loans
          </h2>
          <div className="grid gap-8 md:grid-cols-3">
            <div className="flex flex-col items-center rounded-lg bg-white p-6 text-center shadow-md">
              <div className="mb-4 rounded-full bg-[#ebe8fc] p-4">
                <CheckCircle className="h-8 w-8 text-[#59266d]" />
              </div>
              <h3 className="mb-2 text-xl font-bold text-[#311664]">
                Lower Your EMI
              </h3>
              <p className="text-[#26292b]">
                Compare home loans from multiple lenders to find the lowest
                interest rates and save lakhs on your long-term EMIs.
              </p>
            </div>
            <div className="flex flex-col items-center rounded-lg bg-white p-6 text-center shadow-md">
              <div className="mb-4 rounded-full bg-[#ebe8fc] p-4">
                <Clock className="h-8 w-8 text-[#59266d]" />
              </div>
              <h3 className="mb-2 text-xl font-bold text-[#311664]">
                Quick Eligibility Check
              </h3>
              <p className="text-[#26292b]">
                See your personalized home loan options in minutes without
                affecting your credit score.
              </p>
            </div>
            <div className="flex flex-col items-center rounded-lg bg-white p-6 text-center shadow-md">
              <div className="mb-4 rounded-full bg-[#ebe8fc] p-4">
                <Shield className="h-8 w-8 text-[#59266d]" />
              </div>
              <h3 className="mb-2 text-xl font-bold text-[#311664]">
                Expert Guidance
              </h3>
              <p className="text-[#26292b]">
                Get personalized advice from our home loan experts to navigate
                the complex home buying process.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Home Loan Calculator Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="mx-auto max-w-4xl rounded-xl bg-white p-8 shadow-lg">
            <h2 className="mb-6 text-center text-3xl font-bold text-[#311664]">
              Home Loan EMI Calculator
            </h2>
            <p className="mb-8 text-center text-[#26292b]">
              Estimate your monthly EMI payments based on loan amount, interest
              rate, and tenure.
            </p>

            <div className="grid gap-8 md:grid-cols-2">
              <div className="space-y-6">
                <div>
                  <label className="mb-2 block text-sm font-medium text-[#26292b]">
                    Loan Amount (₹):{" "}
                    {new Intl.NumberFormat("en-IN").format(loanAmount)}
                  </label>
                  <input
                    type="range"
                    min="500000"
                    max="10000000"
                    step="100000"
                    style={{
                      outline: "none",
                      border: "none",
                    }}
                    value={loanAmount}
                    onChange={(e) => setLoanAmount(Number(e.target.value))}
                    className="w-full accent-[#59266d]"
                  />
                  <div className="mt-1 flex justify-between text-xs text-gray-500">
                    <span>₹5 Lakhs</span>
                    <span>₹1 Crore</span>
                  </div>
                </div>

                <div>
                  <label className="mb-2 block text-sm font-medium text-[#26292b]">
                    Interest Rate (%): {interestRate.toFixed(1)}%
                  </label>
                  <input
                    type="range"
                    min="7"
                    max="12"
                    step="0.1"
                    style={{
                      outline: "none",
                      border: "none",
                    }}
                    value={interestRate}
                    onChange={(e) => setInterestRate(Number(e.target.value))}
                    className="w-full accent-[#59266d]"
                  />
                  <div className="mt-1 flex justify-between text-xs text-gray-500">
                    <span>7%</span>
                    <span>12%</span>
                  </div>
                </div>

                <div>
                  <label className="mb-2 block text-sm font-medium text-[#26292b]">
                    Loan Tenure (Years): {loanTenure} years
                  </label>
                  <input
                    type="range"
                    min="5"
                    max="30"
                    step="1"
                    style={{
                      outline: "none",
                      border: "none",
                    }}
                    value={loanTenure}
                    onChange={(e) => setLoanTenure(Number(e.target.value))}
                    className="w-full accent-[#59266d]"
                  />
                  <div className="mt-1 flex justify-between text-xs text-gray-500">
                    <span>5 Years</span>
                    <span>30 Years</span>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center rounded-lg bg-[#ebe8fc] p-6">
                <div className="mb-4 text-center">
                  <p className="text-sm text-[#26292b]">
                    Your Estimated Monthly EMI
                  </p>
                  <p className="text-4xl font-bold text-[#311664]">
                    ₹{new Intl.NumberFormat("en-IN").format(Math.round(emi))}
                  </p>
                </div>

                <div className="grid w-full grid-cols-2 gap-4 text-center">
                  <div>
                    <p className="text-sm text-[#26292b]">Principal Amount</p>
                    <p className="font-medium text-[#311664]">
                      ₹{new Intl.NumberFormat("en-IN").format(loanAmount)}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-[#26292b]">Total Interest</p>
                    <p className="font-medium text-[#311664]">
                      ₹
                      {new Intl.NumberFormat("en-IN").format(
                        Math.round(totalInterest)
                      )}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-[#26292b]">Total Amount</p>
                    <p className="font-medium text-[#311664]">
                      ₹
                      {new Intl.NumberFormat("en-IN").format(
                        Math.round(totalAmount)
                      )}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-[#26292b]">Interest Rate</p>
                    <p className="font-medium text-[#311664]">
                      {interestRate.toFixed(1)}%
                    </p>
                  </div>
                </div>

                <button className="mt-6 w-full rounded-md bg-[#59266d] px-4 py-2 font-medium text-white hover:bg-[#59266d]/90">
                  Get Personalized Rates
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="mb-12 text-center text-3xl font-bold text-[#311664]">
            What Our Users Say
          </h2>
          <div className="grid gap-8 md:grid-cols-3">
            {[
              {
                name: "Vikram Mehta",
                location: "Mumbai",
                rating: 5,
                comment:
                  "Minemi helped me find a home loan with an interest rate 0.5% lower than what my bank offered. This saved me over ₹8 lakhs over the loan tenure!",
              },
              {
                name: "Sneha Reddy",
                location: "Hyderabad",
                rating: 5,
                comment:
                  "The home loan comparison tool made it easy to find the best option for my first home purchase. The expert guidance was invaluable throughout the process.",
              },
              {
                name: "Rajiv Malhotra",
                location: "Bangalore",
                rating: 4,
                comment:
                  "I was able to refinance my existing home loan to a much better rate using Minemi. The process was smooth and the customer service was excellent.",
              },
            ].map((testimonial, index) => (
              <div
                key={index}
                className="rounded-lg border bg-white p-6 shadow-md"
              >
                <div className="mb-4 flex items-center">
                  <div className="h-12 w-12 rounded-full bg-[#ebe8fc]"></div>
                  <div className="ml-4">
                    <h4 className="font-medium text-[#311664]">
                      {testimonial.name}
                    </h4>
                    <p className="text-sm text-gray-500">
                      {testimonial.location}
                    </p>
                  </div>
                </div>
                <div className="mb-4 flex">
                  {Array(5)
                    .fill(0)
                    .map((_, i) => (
                      <Star
                        key={i}
                        className={`h-5 w-5 ${
                          i < testimonial.rating
                            ? "fill-[#59266d] text-[#59266d]"
                            : "text-gray-300"
                        }`}
                      />
                    ))}
                </div>
                <p className="text-[#26292b]">{testimonial.comment}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Home Buying Guide Section */}
      <section className="bg-[#ebe8fc]/30 py-16">
        <div className="container mx-auto px-4">
          <h2 className="mb-8 text-center text-3xl font-bold text-[#311664]">
            Home Buying Guide
          </h2>
          <p className="mb-12 mx-auto max-w-3xl text-center text-[#26292b]">
            Navigating the home buying process can be complex. Our comprehensive
            guides help you make informed decisions at every step.
          </p>

          <div className="grid gap-8 md:grid-cols-3">
            <div className="rounded-lg bg-white overflow-hidden shadow-md">
              <div className="h-48 bg-gray-200">
                <img
                  src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/FirstTimeHomeBuyerGuide-1742019394137.webp"
                  alt="First-Time Home Buyer Guide"
                  width={384}
                  height={192}
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="p-6">
                <h3 className="mb-2 text-xl font-bold text-[#311664]">
                  How Much Loan Can I Afford?
                </h3>
                <p className="mb-4 text-[#26292b]">
                  Estimate how much you can borrow based on your income, loan
                  term, interest rate, and monthly expenses.
                </p>
                <a
                  href="https://minemi.ai/calculators/how-much-loan-can-i-afford"
                  className="text-[#3a868b] font-medium hover:underline"
                >
                  Read More →
                </a>
              </div>
            </div>

            <div className="rounded-lg bg-white overflow-hidden shadow-md">
              <div className="h-48 bg-gray-200">
                <img
                  src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/HomeLoanType-1742019625094.webp"
                  alt="Understanding Home Loan Types"
                  width={384}
                  height={192}
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="p-6">
                <h3 className="mb-2 text-xl font-bold text-[#311664]">
                  Home Loan Balance Transfer with MinEMI
                </h3>
                <p className="mb-4 text-[#26292b]">
                  See how much you could save by transferring debt from a
                  high-interest loan or credit card to a more favorable loan.
                </p>
                <a
                  href="https://minemi.ai/calculators/financial-insights-and-tools/home-loan-balance-transfer-blog"
                  className="text-[#3a868b] font-medium hover:underline"
                >
                  Read More →
                </a>
              </div>
            </div>

            <div className="rounded-lg bg-white overflow-hidden shadow-md">
              <div className="h-48 bg-gray-200">
                <img
                  src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/HomeLoanTaxBenifits-1742019852447.webp"
                  alt="Home Loan Tax Benefits"
                  width={384}
                  height={192}
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="p-6">
                <h3 className="mb-2 text-xl font-bold text-[#311664]">
                  Documents Required for Home Loan
                </h3>
                <p className="mb-4 text-[#26292b]">
                  Discover the complete checklist of documents required for a
                  home loan to ensure a hassle-free approval process
                </p>
                <a
                  href="https://minemi.ai/loanpage/homeloan/home-loan-documents-required"
                  className="text-[#3a868b] font-medium hover:underline"
                >
                  Read More →
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section className="bg-[#ebe8fc] py-16">
        <div className="container mx-auto px-4 text-center">
          <h2 className="mb-6 text-4xl font-bold text-[#311664]">
            Ready to Find Your Dream Home Loan?
          </h2>
          <p className="mx-auto mb-8 max-w-2xl text-lg text-[#26292b]">
            Compare home loans from India's top lenders and find the perfect
            option to finance your dream home.
          </p>
          <button
            className="rounded-md bg-[#311664] px-8 py-4 text-lg font-medium text-white shadow-lg transition-transform hover:scale-105 hover:bg-[#311664]/90"
            onClick={() =>
              document
                .getElementById("home_loan_compare")
                ?.scrollIntoView({ behavior: "smooth" })
            }
          >
            Start Comparing Now
          </button>
        </div>
      </section>
    </div>
  );
}

import { Shield, TrendingUp, CreditCard, Server } from "lucide-react";

export default function WhyMinemiSection() {
  const features = [
    {
      icon: <TrendingUp className="h-8 w-8 text-[#3a868b]" />,
      title: "Accurate Loan Predictions",
      description:
        "We analyze real-time lender criteria to provide the most accurate loan approval predictions.",
    },
    {
      icon: <CreditCard className="h-8 w-8 text-[#3a868b]" />,
      title: "No Impact on Credit Score",
      description:
        "Check your approval chances without any hard inquiries that could affect your credit score.",
    },
    {
      icon: <Shield className="h-8 w-8 text-[#3a868b]" />,
      title: "Smart Loan Recommendations",
      description:
        "Get personalized loan options tailored to your financial profile and needs.",
    },
    {
      icon: <Server className="h-8 w-8 text-[#3a868b]" />,
      title: "Bank-Level Data Security",
      description:
        "Your personal information is fully protected with enterprise-grade encryption and security protocols.",
    },
  ];

  return (
    <section
      className="w-full py-8 md:py-16 "
      style={{ backgroundColor: "#f8f8f8" }}
    >
      <div className="container mx-auto px-4">
        <div className="text-center mb-8 md:mb-16">
          <h2 className="text-2xl md:text-4xl font-bold text-[#311664] mb-2 md:mb-4">
            Why Check Your Loan Chances with Minemi?
          </h2>
          <p className="text-base md:text-lg text-gray-600 max-w-2xl mx-auto">
            Minemi provides the most reliable and accurate loan approval
            predictions without affecting your credit score.
          </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white p-3 md:p-6 rounded-xl shadow-md hover:shadow-xl transition-shadow border border-gray-100 flex flex-col items-center text-center"
            >
              <div className="rounded-full bg-[#ebe8fc] p-2 md:p-4 mb-2 md:mb-4">
                {feature.icon}
              </div>
              <h3 className="text-sm md:text-xl font-bold text-[#311664] mb-1 md:mb-3">
                {feature.title}
              </h3>
              <p className="text-xs md:text-base text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

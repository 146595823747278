import React, { useState } from "react";
import "./CheckCardAvailability.css";
import {
  FaIndianRupeeSign,
  FaStar,
  FaRegStarHalfStroke,
} from "react-icons/fa6";
import { IoIosStarOutline } from "react-icons/io";
import Button from "react-bootstrap/Button";
import { ColorRing } from "react-loader-spinner";
import axios from "axios";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { CheckCircle } from "lucide-react";

const CheckCardAvailability = ({ data }) => {
  const [loader2, setLoader2] = useState(false);
  const [show, setShow] = useState(false);
  const [indexs, setIndex] = useState("");
  const carddata = data.eligiblity.data;
  console.log(data)

  const StarRating = ({ rating }) => {
    const maxStars = 5;
    const stars = [];

    for (let i = 1; i <= maxStars; i++) {
      if (i <= rating) {
        stars.push(
          <FaStar
            key={i}
            fill="#25866a"
            stroke="#25866a"
            className="card-rating-star"
          />
        );
      } else if (i - 0.5 === rating) {
        stars.push(
          <FaRegStarHalfStroke
            key={i}
            fill="#25866a"
            stroke="#25866a"
            className="card-rating-star"
          />
        );
      } else {
        stars.push(
          <IoIosStarOutline
            key={i}
            fill="#25866a"
            stroke="#25866a"
            className="card-rating-star"
          />
        );
      }
    }

    return <div className="flex space-x-1">{stars}</div>;
  };
   const handleSubmit = async (name,irr,index) => {
    setLoader2(true)
    setIndex(index)
    const formid=localStorage.getItem("formid")
      if (name||irr) {
        const formData = new FormData();
        formData.append("card_name", name);
        formData.append("iRate", irr);
        formData.append("emi",data.monthlyemi?.replace(',',"")||0);

        formData.append("monthlyincome", data.annualincome.replace(/[, ]/g, ""));
        formData.append("formId", formid);
        formData.append("amount", "0");
        formData.append("tenure", "0");
  
  
        const response = await axios.post(
         `${process.env.REACT_APP_URL}/applyLoan`,
          formData
        );
  
  
        if (response.data.status) {
          setLoader2(false)
          setShow(true)
          setIndex("")
        }
      }
    }

  return (
    <>
      {carddata ? (
        <>
          <div id="Loan-Check-Card-Availability">
            <div className="CheckCard-Top-contentCon">
              <p id="CheckCard-title">
                Here are the details of your recommended credit card
              </p>


              <div id="card-top-con">
                <div className="row p-0 m-0">
                  <div className="col-12 col-md-4 p-0 m-0">
                    <img
                      src={carddata.excatCards.card_img}
                      alt="card-image"
                      id="card-img"
                    />
                  </div>

                  <div className="col-12 col-md-8 pt-3 px-0 m-0">
                    <div className="d-block d-md-none mb-3 mb-md-0">
                      <p className="card-rating-left">MinEmi Ratings</p>
                      <div className="card-star-left">
                        <StarRating rating={carddata.excatCards.rating / 2} />
                      </div>
                    </div>

                    <div id="travel-con">
                      <img
                        src="../../../../images/card/plane.png"
                        alt="airplane"
                      />
                      <p>{carddata.excatCards.bestFor}</p>
                    </div>


                    <div className="mt-2">
                      <p id="card-name">{carddata.excatCards.card_name}</p>
                      {/* <p id="card-offer">{carddata.excatCards.interest_rate}</p> */}

                      <div className="card-text-con">
                        <p className="text-title">
                          Annual Fee <span> :</span>{" "}
                        </p>
                        &nbsp;&nbsp;&nbsp;
                        <p className="text-content d-flex align-items-center">
                          <FaIndianRupeeSign className="rupee-icon" />
                          {carddata.excatCards.annual_fees}
                        </p>
                      </div>

                      <div className="card-text-con">
                        <p className="text-title">
                          Interest Rate <span> :</span>{" "}
                        </p>
                        &nbsp;&nbsp;&nbsp;
                        <p className="text-content">
                          {" "}
                          {carddata.excatCards.interest_rate}
                        </p>

                      </div>

                      <div className="col-12 col-md-4 d-flex flex-column justify-content-between m-0 p-0">
                        <div className="d-none d-md-block">
                          <p className="card-rating-text">MinEmi Ratings</p>
                          <div className="card-star-con">
                            <StarRating
                              rating={carddata.excatCards.rating / 2}
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-end mt-4 mt-md-0">
                          {!loader2 ? (
                            <Button
                              className="check-card-apply-btn"
                              type="submit"
                              onClick={() =>
                                handleSubmit(
                                  carddata.excatCards.card_name,
                                  carddata.excatCards.interest_rate,
                                  ""
                                )
                              }
                            >
                              Apply Now
                            </Button>
                          ) : (
                            <Button
                              type="button"
                              className="check-card-apply-btn"
                            >
                              <ColorRing
                                visible={loader2}
                                height="30"
                                width="30"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={[
                                  "#ffffff",
                                  "#ffffff",
                                  "#ffffff",
                                  "#ffffff",
                                  "#ffffff",
                                  "#ffffff",
                                ]}
                              />
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                               </div>
                </div>
              </div>
            </div>

            <div className="CheckCard-bottom-contentCon">
              <p id="CheckCard-bottom-title">
                Unlock More Credit Card Options Matching Your Credit{" "}
              </p>

              {carddata.otherCards.map((item, index) => (
                <div className="card-bottom-con" key={index}>
                  <div className="travel-con">
                    <p>{item.bestFor}</p>
                  </div>


                  <div className="row p-0 m-0">
                    <div className="col-12 col-md-4 p-0 m-0">
                      <img
                        src={item.card_img}
                        alt="card-image"
                        className="card-img"
                      />
                    </div>

                    <div className="col-12 col-md-8 px-0 m-0">
                      <div className="row p-0 m-0">
                        <div className="col-12 col-md-8 m-0 p-0">
                          <div>
                            <div className="d-block d-md-none my-3 my-md-0">
                              <p className="card-rating-left">MinEmi Ratings</p>
                              <div className="card-star-left">
                                <StarRating rating={item.rating / 2} />
                              </div>
                            </div>

                            <p className="card-name">{item.card_name}</p>

                            <div className="card-text-con">
                              <p className="text-title m-0 p-0">
                                Annual Fee <span> :</span>{" "}
                              </p>
                              &nbsp;&nbsp;&nbsp;
                              <p className="text-content m-0 p-0 d-flex align-items-center">
                                <FaIndianRupeeSign className="rupee-icon" />
                                {item.annual_fees}
                              </p>
                            </div>

                            <div className="card-text-con">
                              <p className="text-title m-0 p-0">
                                Reward Rate <span> :</span>{" "}
                              </p>
                              &nbsp;&nbsp;&nbsp;
                              <p className="text-content m-0 p-0">
                                {item.reward_rate} ( Cashback )
                              </p>
                            </div>

                            <div className="card-text-con">
                              <p className="text-title m-0 p-0">
                                Intro Offers <span> :</span>{" "}
                              </p>
                              &nbsp;&nbsp;&nbsp;
                              <p className="text-content m-0 p-0 d-flex align-items-center">
                                <FaIndianRupeeSign className="rupee-icon" />
                                {item.intro_offer}
                              </p>
                            </div>

                            <div className="card-text-con">
                              <p className="text-title m-0 p-0">
                                Recommend Credit Score <span> :</span>{" "}
                              </p>
                              &nbsp;&nbsp;&nbsp;
                              <p className="text-content m-0 p-0">
                                {item.min_cibil}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-md-4 d-flex flex-column justify-content-between m-0 p-0">
                          <div className="d-none d-md-block">
                            <p className="card-rating-text">MinEmi Ratings</p>
                            <div className="card-star-con">
                              <StarRating rating={item.rating / 2} />
                            </div>
                          </div>

                          <div className="d-flex justify-content-end mt-4 mt-md-0">
                            {indexs !== index ? (
                              <Button
                                className="check-card-apply-btn"
                                type="submit"
                                onClick={() =>
                                  handleSubmit(
                                    item.card_name,
                                    item.interest_rate,
                                    index
                                  )
                                }
                              >
                                Apply Now
                              </Button>
                            ) : (
                              <Button
                                type="button"
                                className="check-card-apply-btn"
                              >
                                <ColorRing
                                  visible={indexs === index}
                                  height="30"
                                  width="30"
                                  ariaLabel="color-ring-loading"
                                  wrapperStyle={{}}
                                  wrapperClass="color-ring-wrapper"
                                  colors={[
                                    "#ffffff",
                                    "#ffffff",
                                    "#ffffff",
                                    "#ffffff",
                                    "#ffffff",
                                    "#ffffff",
                                  ]}
                                />
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className=" p-8" style={{ backgroundColor: "#f8f8f8" }}>
          <div
            className="mx-auto max-w-3xl"
            style={{ backgroundColor: "#ffffff" }}
          >
            {/* Example of using the NoDataFound component */}
            <div
              className={`flex min-h-[100px] w-full flex-col items-center justify-center py-4`}
              style={{ border: "1px solid grey", borderRadius: 8 }}
            >
              <div className="flex flex-col items-center justify-center rounded-full bg-[#341664]/5 p-6">
                {/* Simple SVG icon instead of importing from lucide-react */}
                <svg
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#341664"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                  <path d="M14 2v6h6" />
                  <path d="M12 18v-6" />
                  <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                </svg>
              </div>

              <h3 className="mt-4 text-center text-xl font-medium text-[#341664]">
                No Data Found
              </h3>

              <p className="mt-2 max-w-md text-center text-sm text-[#341664]/70">
                No data is found for the user
              </p>
            </div>
          </div>
        </div>
      )}
      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-md">
          <div>
            <DialogTitle
              className=" font-bold text-center"
              style={{
                fontWeight: "bold",
                fontSize: 21,
                fontFamily: "Archivo",
                color: "#59266D",
              }}
            >
              Application Submitted Sucessfully!
            </DialogTitle>
          </div>

          <AnimatePresence mode="wait">
            <div
              key="success"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="flex flex-col items-center justify-center py-3 space-y-3"
            >
              <div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ type: "spring", damping: 10, stiffness: 100 }}
              >
                <CheckCircle className="w-16 h-16 text-green-500" />
              </div>
              <p className="text-center text-muted-foreground">
                Your responses have been recorded. Our team will review your
                feedback and get back to you soon!
              </p>
            </div>
          </AnimatePresence>

          <div className="sm:justify-center" style={{ display: "flex" }}>
            <Button
              id="loans-modalCloseBtn"
              onClick={() => setShow(false)}
              variant="outline"
              style={{ margin: "auto" }}
              className="mt-2 transition-all duration-300 hover:bg-primary hover:text-primary-foreground text-center"
            >
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CheckCardAvailability
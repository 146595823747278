import React, { useEffect, useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import experian from '../../../../Assets/Images/experian.webp'
import "./index.css";

const Index = () => {
  const [reportDate, setReportDate] = useState("");
  const [score, setScore] = useState("");
  const [username, setUsername] = useState("");
  const [maxValue, setMaxValue] = useState(900);

  const [cibilMsg, setCibilMsg] = useState(
    "Great news! Your credit score is in good shape. Access a wide range of financial opportunities with competitive rates."
  );
  const [formShow, setFormShow] = useState(false);
  const [outStanding, setOutstanding] = useState("");
  const [late, setLate] = useState("");
  const [running, setRunning] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    const currentDate = new Date();
    const formattedDate =
      `${currentDate.getDate().toString().padStart(2, "0")}/` +
      `${(currentDate.getMonth() + 1).toString().padStart(2, "0")}/` +
      `${currentDate.getFullYear()}`;
    setReportDate(formattedDate);
  }, []);

  useEffect(() => {
    setScore(localStorage.getItem("creditScore"));
    setOutstanding(localStorage.getItem("outstandingamount"));
    setLate(localStorage.getItem("latepayment"));
    setRunning(localStorage.getItem("runningloan"));
  }, []);
    const getRating = (score) => {
    if (score >= 720) {
      return "EXCELLENT";
    } else if (score >= 640) {
      return "GOOD";
    } else {
      return "BAD";
    }
  };
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-IN").format(number);
  };
  return (
    <>
      <div id="eligibility" >
        {/* <div className="titleCon">
          <p className="title m-0 p-0">Personal Loan Eligibility Report</p>
        </div> */}

        <div className="contentCon " style={{backgroundColor:'#FAF6FF'}} >
          <div className="row d-flex justify-content-between m-0 p-0" style={{backgroundColor:'transparent'}} >
            <div
              className={
                formShow
                  ? "col-12 col-md-12 mb-5 mb-md-0 p-0"
                  : "col-12 col-md-7 col-lg-8 mb-5 mb-md-0 p-0"
              }
              style={{backgroundColor:'transparent'}}
            >
              <p className="cus-name p-0 m-0 text-capitalize" style={{backgroundColor:'transparent',color:'black'}}>
                Hii👋 {username}
              </p>
              <p className="credit-title p-0 mt-1 " style={{backgroundColor:'transparent',lineHeight:1.5,color:'black'}}>
                Thankyou for using <b style={{backgroundColor:'transparent',color:'black'}}>MinEMI CreditPro!</b>
              </p>
              <p className="credit-desc p-0 mt-1" style={{backgroundColor:'transparent'}}>
                Here are the details of your requested loan of <b style={{backgroundColor:'transparent'}}>₹{formatNumberWithCommas(localStorage.getItem("loanAmount"))}</b>
              </p>
              <div className="credit-score-con" style={{backgroundColor:'transparent',paddingLeft:0}}>
                <p className="awesome-text" style={{backgroundColor:'transparent'}}>Awesome! here is your </p>
                <p className="loan-sum-text" style={{backgroundColor:'transparent'}}>Loan Summary</p>
                <div className="loan-sum-con" >
                  <div className="row p-0 m-0 d-flex justify-content-between gap-2"  style={{backgroundColor:'transparent'}}>
                    <div className=" col-lg-6 mb-0 mb-lg-3 p-0 d-flex justify-content-between" style={{backgroundColor:'transparent'}}>
                      <p className="loan-text" style={{backgroundColor:'transparent'}}>Total no. of running loans :</p>
                      <p className="loan-value" style={{backgroundColor:'transparent',marginTop:0}}>{running}</p>
                    </div>
                    <div className=" col-lg-5 mb-0 mb-lg-3 p-0 d-flex justify-content-between" style={{backgroundColor:'transparent'}}>
                      <p className="loan-text" style={{backgroundColor:'transparent'}}>Overdue amount :</p>
                      <p className="loan-value" style={{backgroundColor:'transparent',marginTop:0}}>₹80,000</p>
                    </div>
                    <div className="col-lg-6 p-0 d-flex justify-content-between" style={{backgroundColor:'transparent'}}>
                      <p className="loan-text" style={{backgroundColor:'transparent'}}>Total outstanding amount :</p>
                      <p className="loan-value" style={{backgroundColor:'transparent',marginTop:0}}>₹{formatNumberWithCommas(outStanding)}</p>
                    </div>
                    <div className=" col-lg-5 p-0 d-flex justify-content-between" style={{backgroundColor:'transparent'}}>
                      <p className="loan-text" style={{backgroundColor:'transparent'}}>No. of late payments :</p>
                      <p className="loan-value" style={{backgroundColor:'transparent',marginTop:0}}>{late=="null"?"-":late}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4 col-lg-3 p-0" style={{backgroundColor:'transparent'}}>
              <div id={formShow ? "speedometer-con-manage" : "speedometer-con"} style={{backgroundColor:'transparent'}}>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor:'transparent' 
                  }}
                >
                  <ReactSpeedometer
                    fluidWidth={true}
                    value={score}
                    maxValue={maxValue}
                    minValue={0}
                    textColor="#59266D"
                    needleColor="#431d76"
                    segmentColors={[
                      "rgb(132, 108, 166)",
                      "rgb(116, 88, 156)",
                      "rgb(89, 38, 109)",
                      "rgb(52, 22, 100)",
                      "rgba(2, 90, 0, 1)",
                    ]}
                    customSegmentStops={[0, 500, 700, 800, maxValue]}
                    needleHeightRatio={0.7}
                    currentValueText={`${score}`}
                    valueTextFontSize="20px"
                    style={{backgroundColor:'transparent'}}
                  />
                </div>
              </div>

              <div
                className={
                  formShow
                    ? "score-con d-flex justify-content-between"
                    : "d-flex justify-content-between"
                }
                style={{backgroundColor:'transparent'}}
              >
                <p
                style={{backgroundColor:'transparent',fontSize:16}}
                  className={formShow ? "poor-score2 mb-0" : "poor-score mb-0"}
                >
                  Poor
                </p>
                <p
                style={{backgroundColor:'transparent',fontSize:16}}
                  className={formShow ? "good-score2 mb-0" : "good-score mb-0"}
                >
                  Good
                </p>
              </div>

              <div style={{textAlign:'center',backgroundColor:'transparent',fontSize:18,marginTop:'-2%'}}>Your Credit Score is</div>
                <div style={{textAlign:'center',backgroundColor:'transparent',fontSize:22,color:'#59266D',lineHeight:1}}><b style={{backgroundColor:'transparent'}}>{score}</b></div>



      

              <div className="cibil-con" style={{backgroundColor:'transparent',marginTop:'2%'}}>
                <p
                style={{backgroundColor:'transparent',fontSize:16}}
                  className={
                    formShow ? "cibil-cond mb-2" : "cibil-condition mb-2"
                  }
                >
                  You are in a <b style={{color:'#EEA11E',  backgroundColor:'transparent' }}>{getRating(score)}</b> 
                  {getRating(score) === "EXCELLENT" ? "🙂👍" : (getRating(score) === "GOOD" ? "🙂" : "😐")}  Shape
                </p>
                <p 
                style={{backgroundColor:'transparent',fontWeight:400}}className={formShow ? "cibil-msg2 mb-0" : "cibil-msg mb-0"}>
                  {cibilMsg}
                </p>
              </div>
              <div className="d-flex justify-content-center cmp-logo-con" style={{backgroundColor:'transparent',marginTop:"3%"}}>
  <div className="experianLogo">
              <img src={experian} alt="image" />
            </div>              </div>

              <div className="report-con" style={{backgroundColor:'transparent'}}>
                <p className="reportText" style={{backgroundColor:'transparent'}}>
                  Report issue with the result{" "}
                  <a href="https://consumer.experian.in/ECV-OLN/view/angular/#/" style={{backgroundColor:'transparent'}}>
                    Here
                  </a>
                </p>
                <p className="reportUpdate" style={{backgroundColor:'transparent'}}>last update on {reportDate}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;

import { useNavigate } from "react-router-dom"

const styles = {
    section: {
      backgroundColor: "#311664",
      color: "#ffffff",
      padding: "32px 16px",
    },
    container: {
      maxWidth: "1200px",
      margin: "0 auto",
      textAlign: "center",
      backgroundColor:'transparent'

    },
    heading: {
      fontSize: "24px",
      fontWeight: "bold",
      marginBottom: "12px",
      backgroundColor:'transparent'

    },
    subheading: {
      fontSize: "16px",
      marginBottom: "24px",
      backgroundColor:'transparent',
      textAlign:'center'

    },
    buttonContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "12px",
      backgroundColor:'transparent'

    },
    button: {
      padding: "12px 24px",
      fontSize: "16px",
      fontWeight: "bold",
      borderRadius: "9999px",
      cursor: "pointer",
      transition: "background-color 0.3s",
      width: "100%",
      maxWidth: "300px",
      backgroundColor:'transparent'

    },
    primaryButton: {
      backgroundColor: "#3a868b",
      color: "#ffffff",
      border: "none",
    },
    secondaryButton: {
      backgroundColor: "transparent",
      color: "#ffffff",
      border: "2px solid #ffffff",
    },
  }
  
  export default function FinalCTA() {
    const navigate=useNavigate()
    return (
      <section style={styles.section}>
        <div style={styles.container}>
          <h2 style={styles.heading}>Ready to Find Your Perfect Loan?</h2>
          <p style={styles.subheading}>Let Minemi guide you to the best financial solution for your needs.</p>
          <div style={styles.buttonContainer}>
            <a href="https://minemi.ai/personal-loan/check-eligibility/"  style={{ ...styles.button, ...styles.primaryButton }}>
            <button
             
              onMouseOver={(e) => (e.target.style.backgroundColor = "#59266d")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#3a868b")}
            >
             Check Eligiblity
            </button>
            </a>
            <button
            onClick={()=>navigate("/credit-score")}
              style={{ ...styles.button, ...styles.secondaryButton }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#ffffff"
                e.target.style.color = "#311664"
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "transparent"
                e.target.style.color = "#ffffff"
              }}
            >
             Check Cibil Score
            </button>
          </div>
        </div>
      </section>
    )
  }
  
  
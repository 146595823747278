import React from "react";
import "./Serve.css";
import Accordion from "react-bootstrap/Accordion";
import { FaPlus } from "react-icons/fa";

const Serve = () => {
  const accordionData = [
    {
      title: "Car Loan Eligibility",
      content: (
        <p className="m-0 bg-transparent">
          A Quick Guide Car loan eligibility depends on your income, credit
          score, and the type of car you wish to purchase. Most lenders look for
          a credit score of 650+ and a steady income. At Minemi, you can check
          your car loan eligibility instantly, without impacting your credit
          score. This way, you know where you stand before applying, ensuring a
          smoother loan process and avoiding rejections.
        </p>
      ),
    },
    {
      title: "Benefits of a Car Loan",
      content: (
        <div className="bg-transparent">
          Car loans are designed to help you purchase your dream car with ease.
          <p className="my-2 bg-transparent">Key features include:</p>
          <p className="my-1 bg-transparent">• Attractive interest rates</p>
          <p className="m-0 bg-transparent">
            • Flexible repayment tenures (up to 7 years)
          </p>
          <p className="my-1 bg-transparent">
            •No need for collateral apart from the car itself
          </p>
          <p className="m-0 bg-transparent">
            • Quick approval and disbursal process
          </p>
        </div>
      ),
    },
    {
      title: "Why Check Car Loan Eligibility? ",
      content: (
        <p className="m-0 bg-transparent">
          Checking your car loan eligibility before applying helps you avoid
          multiple hard inquiries on your credit report, which can lower your
          score. Minemi allows you to check your eligibility without any impact
          on your credit score. This lets you find the right lender and loan
          terms, ensuring a smooth and quick approval process.
        </p>
      ),
    },
    {
      title: "When to Consider Car Loan?",
      content: (
        <p className="m-0 bg-transparent">
          A car loan is perfect when you're planning to buy a new or used
          vehicle but prefer not to make an outright payment. Car loans offer
          flexible repayment options, low interest rates, and a simple
          application process. Before you apply, ensure that the EMI fits within
          your monthly budget. Use Minemi's eligibility checker to determine the
          best loan options without affecting your credit score.
        </p>
      ),
    },
    {
      title: "Documents Required for Loan Consolidation",
      content: (
        <div className="bg-transparent">
          Documents You Need for a Car Loan
          <p className="my-1 bg-transparent">
            • Identity Proof: PAN Card, Aadhaar Card, Passport
          </p>
          <p className="m-0 bg-transparent">
            • Address Proof: Utility bills, Aadhaar, or rental agreements
          </p>
          <p className="my-1 bg-transparent">
            • Income Proof: Latest salary slips or bank statements (for
            salaried), IT returns or audited financials (for self-employed)
          </p>
          <p className="my-1 bg-transparent">
            •Employment Proof: Offer letter or employment certificate.
          </p>
          <p className="m-0 bg-transparent">
            •Vehicle Details: Proforma invoice of the car you wish to buy Having
            these documents ready will help you speed up the car loan
            application process.
          </p>
        </div>
      ),
    },
    {
      title: " Frequently Asked Questions (FAQs) ",
      content: (
        <div className="m-0 bg-transparent">
          <p className="m-0 bg-transparent">
            1. What is the minimum credit score required for a car loan? Most
            lenders require a credit score of 650 or higher. Check your car loan
            eligibility with Minemi to see your options without affecting your
            score.
          </p>
          <p className="my-2 bg-transparent">
            2. How much car loan can I get? The loan amount is determined by
            your income, credit score, and the price of the car. Use Minemi's
            eligibility checker to get an estimate.
          </p>
          <p className="m-0 bg-transparent">
            3. Will checking car loan eligibility impact my credit score? No,
            checking your eligibility through Minemi uses a soft inquiry that
            doesn't impact your credit score.
          </p>
          <p className="my-2 bg-transparent">
            4. Can I get a car loan for a used vehicle? Yes, many lenders offer
            car loans for used vehicles as well. Minemi can help you find the
            best lenders for your needs.
          </p>
          <p className="m-0 bg-transparent">
            5. How quickly can I get a car loan? Car loans are typically
            approved quickly, often within a day or two. Minemi helps speed up
            the process by providing personalized loan offers.
          </p>
        </div>
      ),
    },
  ];
  return (
    <>
      <div id="serve">
        <div className="contentCon">
          <div id="header-con">
            <p className="bg-transparent">We swear to serve you with ...</p>
          </div>
          <div id="content" className="bg-transparent">
            <div className="d-flex flex-wrap w-100 bg-transparent">
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/loan_eligibility.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/no_empact.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Impact on credit Score</p>
              </div>

              <div className="plus-icon-con second-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/interest_rate.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Best Interest Rate Offer</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/no_calls.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Calls From Us</p>
              </div>

              <div className="plus-icon-con fourth-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Data Security Guarantee</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>
            </div>

            <div id="line-breaker"></div>

            <div className="accordion-con bg-transparent">
              <Accordion className="bg-transparent">
                {accordionData.map((item, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body
                      style={{
                        visibility: "visible",
                        backgroundColor: "transparent",
                      }}
                    >
                      {item.content}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Serve;

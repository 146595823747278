import { useEffect, useState, useMemo } from "react";

export function Testimonials({ name }) {
  const [data, setData] = useState({});

  const idbiData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their home loan approval chances",
      testimonials: [
        {
          name: "Rajesh Verma",
          amount: "₹52,00,000",
          review:
            "I applied for an IDBI Bank home loan but was initially concerned about the processing time. MinEMI's experts suggested I apply through the Fast Track service since I'm a government employee. The entire process was completed in just 4 days, and I got a special interest rate concession of 0.15%!",
        },
        {
          name: "Anita Desai",
          amount: "₹38,00,000",
          review:
            "MinEMI's Document Checklist tool was a lifesaver! I was missing several important property documents that would have delayed my IDBI Bank home loan. Their experts guided me through the process of obtaining the right paperwork, and I was able to get my loan approved without any hiccups. The IDBI GO Mobile app made tracking my application status so convenient!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const lichousingData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their home loan approval chances",
      testimonials: [
        {
          name: "Suresh Mehta",
          amount: "₹45,00,000",
          review:
            "I was looking for a home loan after retirement and was worried about approval. MinEMI suggested LIC Housing Finance's Griha Varishtha scheme which allows repayment up to 80 years of age. The entire process was smooth, and I got a competitive interest rate of 8.50%. The LIC HFL HOME app made tracking my application status incredibly easy!",
        },
        {
          name: "Priya Sharma",
          amount: "₹62,00,000",
          review:
            "MinEMI's Document Checklist tool was a lifesaver! I was missing several important property documents that would have delayed my LIC Housing Finance home loan. Their experts guided me through the process of obtaining the right paperwork, and I was able to get my loan approved without any hiccups. As a woman applicant, I also received a special interest rate concession of 0.05%, which will save me thousands over the loan tenure!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const icicihousingData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their home loan approval chances",
      testimonials: [
        {
          name: "Ramesh Kumar",
          amount: "₹35,00,000",
          review:
            "I was looking for a home loan for a property in a rural area and was worried about approval. MinEMI suggested ICICI Home Finance's Rural Housing Finance scheme which offers special interest rates for such properties. The entire process was smooth, and I got a competitive interest rate of 8.45%. The ICICI iMobile app made tracking my application status incredibly easy!",
        },
        {
          name: "Meera Patel",
          amount: "₹58,00,000",
          review:
            "MinEMI's Document Checklist tool was a lifesaver! I was missing several important property documents that would have delayed my ICICI Home Finance home loan. Their experts guided me through the process of obtaining the right paperwork, and I was able to get my loan approved without any hiccups. As a woman applicant and existing ICICI Bank customer, I also received a special interest rate concession of 0.10%, which will save me thousands over the loan tenure!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const pnbhousingData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their home loan approval chances",
      testimonials: [
        {
          name: "Suresh Patel",
          amount: "₹32,00,000",
          review:
            "I was looking for a home loan for a property in an affordable housing project and was worried about approval. MinEMI suggested PNB Housing Finance's Unnati Home Loan scheme which offers special interest rates for such properties. The entire process was smooth, and I got a competitive interest rate of 8.60%. The PNB HFL Customer Portal made tracking my application status incredibly easy!",
        },
        {
          name: "Priya Singh",
          amount: "₹45,00,000",
          review:
            "MinEMI's Document Checklist tool was a lifesaver! I was missing several important property documents that would have delayed my PNB Housing Finance home loan. Their experts guided me through the process of obtaining the right paperwork, and I was able to get my loan approved without any hiccups. As a woman applicant and government employee, I also received a special interest rate concession of 0.15%, which will save me thousands over the loan tenure!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const sbihousingData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their home loan approval chances",
      testimonials: [
        {
          name: "Sunita Sharma",
          amount: "₹42,00,000",
          review:
            "I was looking for a home loan as a woman borrower and was worried about approval. MinEMI suggested SBI's Her Ghar scheme which offers special interest rates for women. The entire process was smooth, and I got a competitive interest rate of 8.40%. The SBI YONO app made tracking my application status incredibly easy!",
        },
        {
          name: "Rahul Verma",
          amount: "₹55,00,000",
          review:
            "MinEMI's Document Checklist tool was a lifesaver! I was missing several important property documents that would have delayed my SBI Home Loan. Their experts guided me through the process of obtaining the right paperwork, and I was able to get my loan approved without any hiccups. As a government employee, I also received a special interest rate concession, which will save me thousands over the loan tenure. I also opted for the Maxgain option which allows me to save interest by parking my surplus funds!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const adityahousingData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their home loan approval chances",
      testimonials: [
        {
          name: "Meera Kapoor",
          amount: "₹38,00,000",
          review:
            "I was looking for a home loan as a woman borrower and was worried about approval. MinEMI suggested Aditya Birla Finance's Griha Lakshmi scheme which offers special interest rates for women. The entire process was smooth, and I got a competitive interest rate of 8.65%. The ABFL MyLoan app made tracking my application status incredibly easy!",
        },
        {
          name: "Arjun Malhotra",
          amount: "₹52,00,000",
          review:
            "MinEMI's Document Checklist tool was a lifesaver! I was missing several important property documents that would have delayed my Aditya Birla Finance Home Loan. Their experts guided me through the process of obtaining the right paperwork, and I was able to get my loan approved without any hiccups. As a salaried professional, I also received a special interest rate concession. I also opted for the Flexi Home Loan option which allows me to withdraw funds as needed for my home renovation project!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );
  const lthousingData = useMemo(
    () => ({
      title: "Success Stories from MinEMI Users",
      description:
        "Real experiences from people who improved their home loan approval chances",
      testimonials: [
        {
          name: "Priya Sharma",
          amount: "₹45,00,000",
          review:
            "I was looking for a home loan as a woman borrower and was worried about approval. MinEMI suggested L&T Finance's Grihashakti scheme which offers special interest rates for women. The entire process was smooth, and I got a competitive interest rate of 8.70%. The L&T Finance Customer Portal made tracking my application status incredibly easy!",
        },
        {
          name: "Karan Patel",
          amount: "₹60,00,000",
          review:
            "MinEMI's Document Checklist tool was a lifesaver! I was missing several important property documents that would have delayed my L&T Finance Home Loan. Their experts guided me through the process of obtaining the right paperwork, and I was able to get my loan approved without any hiccups. As a young professional, I also qualified for the Super Home Loan option which considered my future income potential and increased my loan eligibility by almost 15%!",
        },
      ],
      link: {
        text: "View More Success Stories  ",
        url: "/testimonials",
      },
    }),
    []
  );

  useEffect(() => {
    if (name === "checkidbihousingbankstatus") {
      setData(idbiData);
    } else if (name === "checklichousingbankstatus") {
      setData(lichousingData);
    } else if (name === "checkicicihousingbankstatus") {
      setData(icicihousingData);
    } else if (name === "checkpnbhousingbankstatus") {
      setData(pnbhousingData);
    } else if (name === "checksbihousingbankstatus") {
      setData(sbihousingData);
    } else if (name === "checkadityahousingbankstatus") {
      setData(adityahousingData);
    } else if (name === "checklthousingbankstatus") {
      setData(lthousingData);
    } else {
      setData({});
    }
  }, [
    name,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    sbihousingData,
    adityahousingData,
    lthousingData,
  ]);

  return (
    <section className="py-4 bg-white border-b border-gray-200">
      <div className="container px-3 md:px-6 bg-transparent">
        <div className="w-4/5l mx-auto bg-transparent">
          <div className="text-center mb-6 bg-transparent">
            <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple">
              {data.title}
            </h2>
            <p className="text-dark-grey mt-2 text-sm bg-transparent text-center">
              {data.description}
            </p>
          </div>

          <div className="grid gap-3 bg-transparent">
            {data.testimonials?.map((d, index) => (
              <div
                key={index}
                className=" p-3 rounded-lg border border-gray-200 shadow-sm bg-[#ebe8fb]"
              >
                <div className="mb-3 bg-transparent">
                  <div className="flex bg-transparent">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <svg
                        key={star}
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="#FFD700"
                        stroke="#FFD700"
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="mr-1 bg-transparent"
                      >
                        <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                      </svg>
                    ))}
                  </div>
                </div>
                <p className="text-dark-grey mb-3 text-sm bg-transparent">
                  {d.review}
                </p>
                <div className="flex justify-between items-center bg-transparent">
                  <p className="font-bold text-primary-purple text-sm bg-transparent">
                    {d.name}
                  </p>
                  <p className="text-sm text-teal font-medium bg-transparent">
                    {d.amount}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* <div className="mt-4 text-center bg-transparent">
            <a
              href="/testimonials"
              className="text-primary-purple text-sm font-medium hover:underline"
            >
              {data?.link?.text} →
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MetadataProvider } from "./Pages/MetadataContext";
import { UserProvider } from "./Pages/ContextAPI/FormContextAPI";

const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error(
    "❌ Root element not found! Ensure <div id='root'></div> exists in index.html."
  );
}

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <MetadataProvider>
      <UserProvider>
        <App />
      </UserProvider>
    </MetadataProvider>
  </React.StrictMode>
);

// Optional: Measure performance in your app
reportWebVitals();

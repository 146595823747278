import React, { useContext, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { UserContext } from "../ContextAPI/FormContextAPI";
import { Button, Form, Modal } from "react-bootstrap";
import { ColorRing } from "react-loader-spinner";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import login from "../../Assets/Images/login.webp";
import experian from '../../Assets/Images/experian.webp';
import { Link } from "react-router-dom";

// Helper functions for Indian number formatting
const formatIndianNumber = (num) => {
  if (num === undefined || num === null || num === '') return '';
  const numStr = num.toString().replace(/,/g, '');
  const number = parseFloat(numStr);
  if (isNaN(number)) return '';
  return new Intl.NumberFormat('en-IN').format(number);
};

const parseIndianNumber = (str) => {
  if (!str) return 0;
  const numStr = typeof str === 'string' ? str : str.toString();
  const cleanedStr = numStr.replace(/,/g, '');
  return parseInt(cleanedStr, 10) || 0;
};

export default function CheckRateform() {
  const { city } = useContext(UserContext);
  const [stgOne, setStgOne] = useState("");
  const [stgTwo, setStgTwo] = useState("");
  const [mbl, setMbl] = useState("");
  const [token, setToken] = useState("");
  const [otpField, setOtpField] = useState(new Array(6).fill(""));
  const [show, setShow] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const [loanAmount, setLoanAmount] = useState("");
  const [LoanType, setLoanType] = useState("");
  const [companys, setCompany] = useState([]);
  const [companyname, setCompanyname] = useState("");
  const [emi, setEmi] = useState("");
  const [name, setName] = useState("");
  const [income, setIncome] = useState("");
  const [employmentType, setemploymentType] = useState("");
  const [listshow, setListshow] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const timerRef = useRef(null);
const [isResendDisabled, setIsResendDisabled] = useState(true);
const [timeLeft, setTimeLeft] = useState(() => {
  const endTime = parseInt(localStorage.getItem("otpEndTime"), 10);
  const now = new Date().getTime();
  const timeLeft = Math.floor((endTime - now) / 1000);
  return timeLeft > 0 ? timeLeft : 30;
});
  const [formData, setFormData] = useState({
    name: "",
    lname: "",
    email: "",
    mobile: "",
    employmentType: "",
    loanAmount: "", // Default value
    monthlyemi: "",
    monthlyincome: "",
    consent: false,
    consent2: false,
    loantype: "",
    company: ""
  });


  const handleConsent1 = (e) => {
    setFormData((prev) => ({ ...prev, consent: e.target.checked }));
  };
  
  const handleConsent2 = (e) => {
    setFormData((prev) => ({ ...prev, consent2: e.target.checked }));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedFormData = {
      ...formData,
      name: formData.name.trim(),
      lname: formData.lname.trim(),
    };
  
    const { email, mobile, employmentType, loantype, consent, consent2, monthlyincome, company } = formData;
    const {  name, lname } = trimmedFormData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const nameRegex = /^[a-zA-Z]{2,}$/;
    
    if (!nameRegex.test(name)) return toast('Invalid First Name');
    if (!nameRegex.test(lname)) return toast('Invalid Last Name');
    if (!emailRegex.test(email)) return toast('Invalid Email');
    if (mobile.length !== 10 || isNaN(mobile)) return toast('Invalid Mobile Number');
    if (!employmentType) return toast('Please select Employment Type');
    if (employmentType === "salaried" && company === "") return toast('Please select company name');
    if (!monthlyincome) return toast('Please Enter Monthly Income');
    if (!loantype) return toast('Please select loan Type');
    if (!consent || !consent2) return toast('Please check, all the concent ');

    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/login`,
        {
          fname: formData.name,
          email: formData.email,
          mobile: formData.mobile,
          lname: formData.lname,
          city: city,
          profession: formData.employmentType,
          company: formData.company,
          monthly_income: formData.monthlyincome?.replace(/[, ]/g, ""),
          monthly_emi: formData.monthlyemi=="undefined" ?0:formData.monthlyemi.replace(/[, ]/g, ""),
          loan_type: formData.loantype,
          loanamount:formData.loanAmount,
          purpose:"/check-rate-landing-page",
          

        }
      );

      if (response.data.status) {
        const { expData, token } = response.data.data;
        setStgOne(expData.stgOneHitId);
        setStgTwo(expData.stgTwoHitId);
        localStorage.setItem("formId",response.data.data.formId)
        setTimeLeft(30);
        setIsResendDisabled(true)
        // setToken(token.token);
        setMbl(formData.mobile);
        setShow(true);
        setIsSubmitting(false);
        setLoanAmount(formData.loanAmount);
        setLoanType(formData.loantype);
        setEmi(parseIndianNumber(formData.monthlyemi.replace(/[, ]/g, "")));
        setIncome(parseIndianNumber(formData.monthlyincome.replace(/[, ]/g, "")));
        setCompanyname(formData.company);
        setemploymentType(formData.employmentType);
        setName(formData.name);
        setTimeout(() => {
          toast(response.data.msg);
        }, 1500);
      } else {
        setTimeout(() => {
          toast(response.data.msg);
        }, 1500);
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("Error making POST request", error);
      setIsSubmitting(false);
    }
  };

  const handleSliderChange = (name, e) => {
    const value = parseInt(e.target.value, 10);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (name, e) => {
    console.log( name, e)
    setFormData({
      ...formData,
      [name]: e.target.value,
    });
  };
  
  const handleNumberInputChange = (name, e) => {
    const rawValue = e.target.value;
    // Only allow numbers and commas
    const cleanedValue = rawValue.replace(/[^0-9,]/g, '');
    const formattedValue = cleanedValue === '' ? '' : formatIndianNumber(cleanedValue);
    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  const otpSubmit = async () => {
    setOtpLoader(true);
    const concatenateOtp = otpField.join("");
    const formid=localStorage.getItem("formId")
    if (concatenateOtp.length === 6) {
      let formData = new FormData();
      formData.append("stgOneHitId", stgOne);
      formData.append("stgTwoHitId", stgTwo);
      formData.append("otp", concatenateOtp);
      formData.append("mobile", mbl);
      formData.append("type", "CUSTOM");
      formData.append("formId", formid);
     
      
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/verifyOtp`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.status == 1) {
          localStorage.setItem("token", token);
          localStorage.setItem("creditScore", response.data.data.credit_score);
          localStorage.setItem("name", name);
          localStorage.setItem("id", 1);
          setOtpLoader(false);
          navigate("/check-rate", {
            state: {
              cibil: response.data.data.credit_score,
              name: name,
              amount: response.data.data.outstanding_amount,
              runningLoan: response.data.data.no_of_active_loan,
              loanamount: loanAmount,
              monthlyemi: emi,
              monthlyincome: income,
              employmenttype: employmentType,
              loantype: LoanType,
              company: companyname,
              unsecured:response.data.data.unsecure_loan_amt,
              secured:response.data.data.secure_loan_amt,
              eligibleLoan:response.data.data.eligibleLoan.amount
            },
          });
        } else {
          toast.error(response.data.msg);
          setOtpLoader(false);
        }
      } catch (error) {
        toast.error(error.response.data.msg);
        console.error("Error making POST request", error);
        setOtpLoader(false);
      }
    }
  };

  const otpHandleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, "").slice(0, 1); // Ensure only one digit
    if (!value) return;
  
    const newOtp = [...otpField];
    newOtp[index] = value;
    setOtpField(newOtp);
  
    // Move focus to the next field if the current field has a value
    if (index < 5 && value) {
      inputRefs.current[index + 1].focus();
    }
  }
  
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      // Create a copy of the current OTP array
      const newOtp = [...otpField];
  
      // Clear the current field
      newOtp[index] = "";
  
      // If the current field is empty and not the first field, move focus to the previous field
      if (index > 0 && !newOtp[index]) {
        inputRefs.current[index - 1].focus();
      }
  
      // Update the state with the new OTP array
      setOtpField(newOtp);
    }
  }
  const handleResendOtp = async () => {
    if (isResendDisabled) return;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/login`,
        {
          fname: formData.name,
          email: formData.email,
          mobile: formData.mobile,
          lname: formData.lname,
          city: city,
          profession: employmentType,
          company: companyname,
          monthly_income: income,
          monthly_emi: emi,
          loan_type: LoanType,
          purpose:"/check-rate-landing-page"

        }
      );
  
      if (response.data.status) {
        const { expData, token } = response.data.data;
        startTimer(); // Reset the timer
        localStorage.setItem("formId",response.data.data.formId)
        setStgOne(expData.stgOneHitId);
        setStgTwo(expData.stgTwoHitId);
        setTimeLeft(30);
        setIsResendDisabled(true)
        setOtpField(new Array(6).fill(""));
        toast.success("OTP resent successfully");
      } else {
        toast.error(response.data.msg);
        setOtpField(new Array(6).fill(""));

      }
    } catch (error) {
      console.error("Error resending OTP", error);
      toast.error("Failed to resend OTP");
      setOtpField(new Array(6).fill(""));

    }
  };

  const Company = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SECRET_KEY}/companyList`
    );

    if (response.status === 200) {
      setCompany(response.data.data.company);
    } else {
      setCompany([]);
    }
  };

  useEffect(() => {
    Company();
  }, []);
  useEffect(() => {
    if (formData.loantype === "hl") {
      setFormData(prev => ({ ...prev, loanAmount: 50000 }));
    } else if (formData.loantype === "pl") {
      setFormData(prev => ({ ...prev, loanAmount: 10000 }));
    } else if (formData.loantype === "cl") {
      setFormData(prev => ({ ...prev, loanAmount: 10000 }));
    }
  }, [formData.loantype]);
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const startTimer = (duration = 30) => {
    // Clear any existing timer
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    
    setTimeLeft(duration);
    setIsResendDisabled(true);
    
    timerRef.current = setInterval(() => {
      setTimeLeft(prev => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          setIsResendDisabled(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Initialize timer when OTP modal is shown or when resending OTP
  useEffect(() => {
    if (show) {
      startTimer();
    }
    
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [show]); // This effect depends on the 'show' state

  return (
    <>
      <section id="rate-check-form" className="py-10 md:py-16 bg-white">
        <div className="container mx-auto px-6">
          <div className="text-center mb-6">
            <h2 className="text-xl md:text-2xl font-bold text-[#311664] mb-2">
              Check Your Loan Rate Instantly
            </h2>
            <div className="w-16 h-0.5 bg-[#3a868b] mx-auto mb-3"></div>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            viewport={{ once: true, margin: "-50px" }}
            className="max-w-md mx-auto"
          >
            <div className="border-none shadow-lg overflow-hidden rounded-lg bg-white">
              <div className="bg-gradient-to-r from-[#311664] to-[#59266d] text-white py-4 px-6">
                <h3 className="text-lg font-semibold text-center">Your Loan Rate Details</h3>
                <p className="text-[#ebe8fc] text-sm text-center">
                  No impact on your credit score
                </p>
              </div>
              <div className="p-6">
                <form onSubmit={handleSubmit} className="space-y-5">
                  <div className="d-flex sm:flex-col flex-row" style={{ gap: 8 }}>
                    <div className="space-y-1">
                      <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
                        First Name
                      </label>
                      <input
                        id="fullName"
                        type="text"
                        placeholder="Enter First name"
                        required
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        className="h-10 text-sm w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#311664] focus:border-[#311664]"
                      />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="lastname" className="block text-sm font-medium text-gray-700">
                        Last Name
                      </label>
                      <input
                        id="lastname"
                        type="text"
                        placeholder="Enter Last name"
                        required
                        value={formData.lname}
                        onChange={(e) => setFormData({ ...formData, lname: e.target.value })}
                        className="h-10 text-sm w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#311664] focus:border-[#311664]"
                      />
                    </div>
                  </div>
                  <div className="d-flex sm:flex-col flex-row" style={{ gap: 8 }}>
                    <div className="space-y-1">
                      <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <input
                        id="email"
                        type="email"
                        placeholder="Enter Email address"
                        required
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        className="h-10 text-sm w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#311664] focus:border-[#311664]"
                      />
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
                        Mobile No
                      </label>
                      <input
                        id="mobile"
                        type="tel"
                        placeholder="Enter Moblie number"
                        required
                        value={formData.mobile}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, '');
                          if (value.length <= 10) {
                            setFormData({ ...formData, mobile: value });
                          }
                        }}
                        className="h-10 text-sm w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#311664] focus:border-[#311664]"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="employmentType" className="block text-sm font-medium text-gray-700">
                      Employment Type
                    </label>
                    <select
                      id="employmentType"
                      required
                      value={formData.employmentType}
                      onChange={(e) => handleSelectChange("employmentType", e)}
                      className="h-10 text-sm w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#311664] focus:border-[#311664] bg-white"
                    >
                      <option value="" disabled selected>Select type</option>
                      <option value="salaried">Salaried</option>
                      <option value="self-employed">Self-Employed</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  {formData.employmentType === "salaried" && (
  <div className="space-y-1">
    <label htmlFor="company" className="block text-sm font-medium text-gray-700">
      Name of Company
    </label>
    
    {/* Main company input field */}
    <div className="relative">
      <input
        id="company"
        required
        value={formData.company}
        onChange={(e) => handleSelectChange("company", e)}
        onClick={() => setListshow(!listshow)} // Toggle dropdown on click
        className="h-10 text-sm w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#311664] focus:border-[#311664] bg-white"
        placeholder="Select company"
      />
      
      {/* Searchable dropdown (conditionally shown) */}
      {listshow && (
        <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {/* Search input inside dropdown */}
          <div className="p-2 border-b">
            <input
              type="text"
              placeholder="Search companies..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="h-8 text-sm w-full px-2 py-1 border border-gray-300 rounded"
            />
          </div>
          
          {/* Filtered company list */}
          <div className="divide-y">
            {companys
              .filter(company => 
                company.company_name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((d, index) => (
                <div
                  key={index}
                  className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => {
                    handleSelectChange("company", { 
                      target: { value: d.company_name } 
                    });
                    setListshow(false); // Close after selection
                    setSearchTerm(''); // Reset search
                  }}
                >
                  {d.company_name}
                </div>
              ))
            }
          </div>
        </div>
      )}
    </div>
  </div>
)}
                  <div className="space-y-1">
                    <label htmlFor="loantype" className="block text-sm font-medium text-gray-700">
                      Loan Type
                    </label>
                    <select
                      id="loantype"
                      required
                      value={formData.loantype}
                      onChange={(e) => handleSelectChange("loantype", e)}
                      className="h-10 text-sm w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#311664] focus:border-[#311664] bg-white"
                    >
                      <option value="" disabled selected>Select type</option>
                      <option value="hl">Housing Loan</option>
                      <option value="pl">Personal Loan</option>
                      <option value="cl">Car Loan</option>
                    </select>
                  </div>

                  <div className="space-y-2">
                  
                    <div className="d-flex sm:flex-col flex-row" style={{ gap: 8 }}>
                      <div className="space-y-1">
                        <label htmlFor="Montlyincome" className="block text-sm font-medium text-gray-700">
                          Monthly Income
                        </label>
                        <input
                          id="monthlyincome"
                          type="text"
                          placeholder="Enter Monthly income"
                          required
                          value={formData.monthlyincome}
                          onChange={(e) => handleNumberInputChange("monthlyincome", e)}
                          className="h-10 text-sm w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#311664] focus:border-[#311664]"
                        />
                      </div>
                      <div className="space-y-1">
                        <label htmlFor="monthlyemi" className="block text-sm font-medium text-gray-700">
                          Monthly EMI
                        </label>
                        <input
                          id="monthlyemi"
                          type="text"
                          placeholder="Enter Monthly EMI"
                          value={formData.monthlyemi}
                          onChange={(e) => handleNumberInputChange("monthlyemi", e)}
                          className="h-10 text-sm w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-[#311664] focus:border-[#311664]"
                        />
                      </div>
                    </div>
                    <div className="space-y-2">
  <div className="flex justify-between items-center">
    <label htmlFor="loanAmount" className="block text-sm font-medium text-gray-700">
      Loan Amount
    </label>
    <div className="px-1 py-1 border border-gray-300 rounded" style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
      <span  className="text-sm font-medium ">₹</span>
      <input
        type="text"
        style={{outline:"none"}}
        className="text-sm font-medium outline-none"
        value={formData.loanAmount ? formatIndianNumber(formData.loanAmount) : ""}
        onChange={(e) => {
          const rawValue = e.target.value.replace(/[^0-9]/g, '');
          
          // Allow empty value for clearing the field
          if (rawValue === '') {
            setFormData(prev => ({
              ...prev,
              loanAmount: ""
            }));
            return;
          }
          
          const numValue = parseInt(rawValue, 10) || 0;
          const min = formData.loantype === "pl" ? 10000 : formData.loantype === "hl" ? 50000 : 10000;
          const max = formData.loantype === "pl" ? 5000000 : formData.loantype === "hl" ? 50000000 : 10000000;
          
          // Only apply min/max clamping when user has finished typing (not during input)
          const clampedValue = numValue;
          
          setFormData(prev => ({
            ...prev,
            loanAmount: clampedValue
          }));
        }}
        placeholder=""
      />
    </div>
  </div>
  <input
    id="loanAmount"
    type="range"
    min={formData.loantype === "pl" ? 10000 : formData.loantype === "hl" ? 50000 : 10000}
    max={formData.loantype === "pl" ? 5000000 : formData.loantype === "hl" ? 50000000 : 10000000}
    step={10000}
    value={formData.loanAmount || (formData.loantype === "pl" ? 10000 : formData.loantype === "hl" ? 50000 : 10000)}
    onChange={(e) => handleSliderChange("loanAmount", e)}
    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-[#3a868b]"
  />
  <div className="flex justify-between text-xs text-gray-500">
    <span>₹{formatIndianNumber(formData.loantype === "pl" ? 10000 : formData.loantype === "hl" ? 50000 : 10000)}</span>
    <span>₹{formatIndianNumber(formData.loantype === "pl" ? 5000000 : formData.loantype === "hl" ? 50000000 : 10000000)}</span>
  </div>
</div>
                  </div>
                  
                  <div className="flex items-start space-x-3 pt-1">
                    <div className="flex items-center h-5">
                      <input
                        id="consent"
                        type="checkbox"
                        checked={formData.consent}
                        onChange={handleConsent1}
                        className="w-4 h-4 text-[#3a868b] border-[#3a868b] rounded focus:ring-[#3a868b]"
                      />
                    </div>
                    <label htmlFor="consentTerms" className="text-sm font-normal">
                      I agree to MinEMI's{" "}
                      <a
                         href="https://minemi.ai/terms-condition"
                         target="_blank"

                        style={{ color: "rgba(89, 38, 109, 1)", fontWeight: 600 }}
                      >
                        Terms of Use
                      </a>{" "}
                      and{" "}
                      <a
                         href="https://minemi.ai/privacypolicy"
                         target="_blank"
                   
                        style={{ color: "rgba(89, 38, 109, 1)", fontWeight: 600 }}
                      >
                        Privacy Policy
                      </a>
                      {" "}
                      and authorise to be 
                      {showMore1 ? (
                        <>
                         {" "} communicated via SMS, Call, WhatsApp, and Email. I hereby also allow MinEMI to access my credit report on my
                          behalf.
                          <span
                            className="text-[#3a868b] cursor-pointer font-medium"
                            onClick={() => setShowMore1(false)}
                          >
                            {" "}
                            Read Less
                          </span>
                        </>
                      ) : (
                        <span
                          className="text-[#3a868b] cursor-pointer font-medium"
                          onClick={() => setShowMore1(true)}
                        >
                          {" "}
                          ...Read More
                        </span>
                      )}
                    </label>
                  </div>
              
                  <div className="flex items-start space-x-3 pt-1">
                    <div className="flex items-center h-5">
                      <input
                        id="consentMarketing"
                        type="checkbox"
                        checked={formData.consent2}
                        onChange={handleConsent2}
                        className="w-4 h-4 text-[#3a868b] border-[#3a868b] rounded focus:ring-[#3a868b]"
                      />
                    </div>
                    <label htmlFor="consentMarketing" className="text-sm font-normal">
                      I hereby consent to Nine Triangles Pvt.Ltd. being appointed as my
                      authorised 
                    
                      {showMore2 ? (
                        <>{" "}
                          representative to receive my credit information from
                          Experian for the purpose of Credit Assessment
                          to advise me on the best loan offers on expiry of 6 months from
                          the date the consent is collected, whichever is earlier. I also 
                          agree to{" "}
                          <a
                         href="https://minemi.ai/experianconsent"
                         target="_blank"
                            style={{ color: "rgba(89, 38, 109, 1)", fontWeight: 600 }}
                          >
                            Experian Terms and Conditions.
                          </a>
                          <span
                            className="text-[#3a868b] cursor-pointer font-medium"
                            onClick={() => setShowMore2(false)}
                          >
                            {" "}
                            Read Less
                          </span>
                        </>
                      ) : (
                        <span
                          className="text-[#3a868b] cursor-pointer font-medium"
                          onClick={() => setShowMore2(true)}
                        >
                          {" "}
                          ...Read More
                        </span>
                      )}
                    </label>
                  </div>
          
                  <div className="d-flex flex-column align-items-center mt-3">
                    <div className="experianLogo">
                      <img src={experian} alt="experian logo" />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="w-full bg-[#3a868b] hover:bg-[#2d6a6e] text-white py-1 text-base font-medium mt-2 rounded-md transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Checking your rate..." : "Check My Rate Now"}
                  </button>
                </form>
              </div>
            </div>
          </motion.div>
        </div>
      </section>
      
      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        backdrop={true}
        keyboard={false}
      >
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <div className="row" id="otpCon">
            <div className="col-lg-6 px-0">
              <img src={login} alt="login" style={{ objectFit: "cover" }} />
            </div>

            <div className="col-lg-6" id="otp-section">
              <div>
                <p className="m-0 p-0 otp-text">Enter OTP</p>
                <p className="m-0 p-0 otp-desc mt-2">
                  Please enter the one-time password (OTP) sent on the given
                  number - {mbl}
                </p>
              </div>

              <div className="d-flex justify-content-between otp-input-con">
                {otpField.map((digit, index) => (
                  <Form.Control
                    type="number"
                    className="otp-inputs"
                    key={index}
                    maxLength="1"
                    value={digit}
                    onChange={(e) => otpHandleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </div>
              <div
              style={{
                fontWeight: 700,
                fontSize: 14,
                color: "rgba(89, 38, 109, 1)",
                textAlign: "center",
                marginTop:'5%'
              }}
            >
              {formatTime(timeLeft)} 
            </div>

              <div className="otpBtn">
                {!otpLoader ? (
                  <Button
                    className="button-class"
                    id="verify-btn"
                    onClick={otpSubmit}
                  >
                    Verify
                  </Button>
                ) : (
                  <Button
                    className="button-class"
                    id="verify-btn"
                    style={{ backgroundColor: "#633376" }}
                  >
                    <ColorRing
                      visible={otpLoader}
                      height="30"
                      width="30"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{ backgroundColor: "#633376" }}
                      wrapperClass="color-ring-wrapper"
                      colors={[
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                        "#ffffff",
                      ]}
                    />
                  </Button>
                )}
                <Button
                  className="button-class"
                  id="resend-otp"
                  disabled={isResendDisabled}
                  onClick={() => handleResendOtp()}
                >
                  Resend OTP
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
import React, { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./Components/Header/NavBar";
import Login from "./Pages/LoginSignUp/Login";
import Otp from "./Pages/LoginSignUp/Otp";
import ProvideDetails from "./Pages/LoginSignUp/ProvideDetails";
import Home from "./Pages/Home/Home";
import FooterUpdated from "./Components/Footer/FooterUpdated";
import HomeAfterLogin from "./Pages/Home/HomeAfterLogin";
import AboutUs from "./Pages/Home/AboutUs";
import ContactUs from "./Pages/Home/ContactUs";
import Confirm from "./Pages/Home/Confirm";
import UpdateProfile from "./Pages/Home/UpdateProfile";
import TopUpDetail from "./Pages/Home/TopUpDetail";
import LowerEmiDetail from "./Pages/Home/LowerEmiDetail";
import Form from "./Pages/Home/Form";
import Status from "./Pages/Home/Status";
import Loan from "./Pages/Home/Loan";
import LowerEmiDetailPage from "./Pages/Home/LowerEmiDetailPage";
import LoanEdit from "./Pages/Home/LoanEdit";
import Goal from "./Pages/Home/Goal";
import Test from "./Pages/Home/Test";
import Form2 from "./Pages/Home/Form2";
import Statuss from "./Pages/Home/Statuss";
import PrivacyPolicy from "./Pages/Home/PrivacyPolicy";
import TermsandCondition from "./Pages/Home/TermsandCondition";
import ExperianConsent from "./Pages/Home/ExperianConsent";
import GrievanceAddressal from "./Pages/Home/GrievanceAddressal";
import BalanceTransfer from "./Pages/Home/BalanceTransfer";
import Consolidate from "./Pages/Home/Consolidate";
import Topup from "./Pages/Home/Topup";
import Blog from "./Pages/Home/Blog";
import BlogDetail from "./Pages/Home/BlogDetail";
import PersonalLoan from "./Pages/StaticPage/PersonalLoan";
import BadCredit from "./Pages/StaticPage/BadCredit";
import Homeloan from "./Pages/StaticPage/Homeloan";
import CreditCard from "./Pages/StaticPage/CreditCard";
import Carloan from "./Pages/StaticPage/Carloan";
import Faq from "./Pages/StaticPage/Faq";
import EligiblityCriteria from "./Pages/StaticPage/EligiblityCriteria";
import HdfcEligiblity from "./Pages/StaticPage/HdfcEligiblity";
import LoanTracking from "./Pages/StaticPage/LoanTracking";
import LoanPage from "./Pages/StaticPage/LoanPage";
import AxisEligiblity from "./Pages/StaticPage/AxisEligiblity";
import SbiEligiblity from "./Pages/StaticPage/SbiEligiblity";
import IciciEligiblity from "./Pages/StaticPage/IciciEligiblity";
import PersonalTopup from "./Pages/StaticPage/PersonalTopup";
import Typesofpersonalloan from "./Pages/StaticPage/Typesofpersonalloan";
import IndusindEligibility from "./Pages/StaticPage/IndusiandEligibility";
import HomeLoanEligiblityCriteria from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanEligiblityCriteria";
import HomeLoanHdfcEligibility from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanHdfcEligibility";
import TypesOfHomeLoan from "./Pages/StaticPage/HomeLoanEligibilityCriteria/TypesOfHomeLoan";
import HomeLoanTopUp from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanTopUp";
import NRIHomeLoan from "./Pages/StaticPage/HomeLoanEligibilityCriteria/NRIHomeLoan";
import HomeLoanBalanceTransfer from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanBalanceTransfer";
import HomeLoanAxisEligibility from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanAxisEligibility";
import HomeLoanICICIEligibility from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanICICIEligibility";
import HomeLoanSbiEligibility from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanSbiEligibility";
import PersonalLoanNewsDetails from "./Pages/StaticPage/PersonalLoanNewsDetails";
import HLCompareInterestRates from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HLCompareInterestRates";
import DigitalLoan from "./Pages/StaticPage/DigitalLoan";
import PlManageLoanDetails from "./Pages/StaticPage/PlManageLoansDetails";
import CarLoanEligibilityCriteria from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanEligibilityCriteria";
import CarLoanCompareOffers from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanCompareOffers";
import CarLoanTopUp from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanTopUp";
import UsedCarLoan from "./Pages/StaticPage/CarLoanEligibilityCriteria/UsedCarLoan";
import CarLoanStatusTracking from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanStatusTracking";
import DigitalLoanOverdraft from "./Pages/StaticPage/DigitalLoan/DigitalLoanOverdraft";
import GoldLoan from "./Pages/StaticPage/GoldLoan";
import GoldLoanEligibilityCriteria from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanEligibilityCriteria";
import CarLoanHDFCeligibility from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanHDFCeligibility";
import CarLoanAXISeligibility from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanAXISeligibility";
import CarLoanSBIeligibility from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanSBIeligibility";
import CarLoanICICIeligibility from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanICICIeligibility";
import ArticleOne from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanArticles/ArticleOne";
import ArticleTwo from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanArticles/ArticleTwo";
import ArticleThree from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanArticles/ArticleThree";
import ArticleFour from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanArticles/ArticleFour";
import ArticleFive from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanArticles/ArticleFive";
import ArticleSix from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanArticles/ArticleSix";
import ArticleSeven from "./Pages/StaticPage/CarLoanEligibilityCriteria/CarLoanArticles/ArticleSeven";
import DLArticleOne from "./Pages/StaticPage/DigitalLoan/DigitalLoanArticles/DLArticleOne";
import DLArticleTwo from "./Pages/StaticPage/DigitalLoan/DigitalLoanArticles/DLArticleTwo";
import DLArticleThree from "./Pages/StaticPage/DigitalLoan/DigitalLoanArticles/DLArticleThree";
import DLArticleFour from "./Pages/StaticPage/DigitalLoan/DigitalLoanArticles/DLArticleFour";
import DLArticleFive from "./Pages/StaticPage/DigitalLoan/DigitalLoanArticles/DLArticleFive";
import DLArticleSix from "./Pages/StaticPage/DigitalLoan/DigitalLoanArticles/DLArticleSix";
import GoldLoanArticleOne from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanArticles/GoldLoanArticleOne";
import GoldLoanArticleTwo from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanArticles/GoldLoanArticleTwo";
import GoldLoanArticleThree from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanArticles/GoldLoanArticleThree";
import GoldLoanArticleFour from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanArticles/GoldLoanArticleFour";
import GoldLoanArticleFive from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanArticles/GoldLoanArticleFive";
import GoldLoanArticleSix from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanArticles/GoldLoanArticleSix";
import HomeArticleOne from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanArticles/HomeArticleOne";
import LoanArticleOne from "./Pages/StaticPage/LoanPageArticles/LoanArticleOne";
import LoanArticleTwo from "./Pages/StaticPage/LoanPageArticles/LoanArticleTwo";
import LoanArticleThree from "./Pages/StaticPage/LoanPageArticles/LoanArticleThree";
import HomeArticleTwo from "./Pages/StaticPage/HomeLoanEligibilityCriteria/HomeLoanArticles/HomeArticleTwo";
import LoanArticleFour from "./Pages/StaticPage/LoanPageArticles/LoanArticleFour";
import GoldLoanInterestRate from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanInterestRate";
import GoldLoanProcedure from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanProcedure";
import GoldLoanCompanies from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanCompanies";
import GoldLoanOverdraft from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanOverdraft";
import GoldLoanTopUp from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanTopUp";
import GoldLoanBulletRepayment from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanBulletRepayment";
import GoldLoanBalanceTransfer from "./Pages/StaticPage/GoldLoanEligibilityCriteria/GoldLoanBalanceTransfer";
import TypesOfGoldLoan from "./Pages/StaticPage/GoldLoanEligibilityCriteria/TypesOfGoldLoan";
import PersonalLoanOnCreditCards from "./Pages/StaticPage/DigitalLoan/PersonalLoanOnCreditCards";
import DigitalLoanAgainstShares from "./Pages/StaticPage/DigitalLoan/DigitalLoanAgainstShares";
import DigitalLoanAgainstProperty from "./Pages/StaticPage/DigitalLoan/DigitalLoanAgainstProperty";
import DigitalLoanTopUp from "./Pages/StaticPage/DigitalLoan/DigitalLoanTopUp";
import PLArticleOne from "./Pages/StaticPage/PersonalLoanArticles/PLArticleOne";
import CreditCardPage from "./Pages/StaticPage/CreditCardPages/CreditCardPage";
import BalanceTransferCreditCards from "./Pages/StaticPage/CreditCardPages/BalanceTransferCreditCards";
import TravelAndAirlineCredit from "./Pages/StaticPage/CreditCardPages/TravelAndAirlineCredit";
import CreditCardRewards from "./Pages/StaticPage/CreditCardPages/CreditCardRewards";
import LoanStatusTracking from "./Pages/StaticPage/CreditCardPages/LoanStatusTracking";
import HDFCInterestRate from "./Pages/StaticPage/PLInterestRate/HDFCInterestRate";
import SBIInterestRate from "./Pages/StaticPage/PLInterestRate/SBIInterestRate";
import ICICIInterestRate from "./Pages/StaticPage/PLInterestRate/ICICIInterestRate";
import AXISInterestRate from "./Pages/StaticPage/PLInterestRate/AXISInterestRate";
import BAJAJInterestRate from "./Pages/StaticPage/PLInterestRate/BAJAJInterestRate";
import CCArticleOne from "./Pages/StaticPage/CreditCardArticles/CCArticleOne";
import GoldLoanNewsDetails from "./Pages/StaticPage/GoldLoanNewsDetails";
import GoldLoanManageLoanDetails from "./Pages/StaticPage/GoldLoanManageLoanDetails";
import CarLoanNewsDetails from "./Pages/StaticPage/CarLoanNewsDetails";
import CarLoanManageLoanDetails from "./Pages/StaticPage/CarLoanManageLoanDetails";
import DigitalLoanNewsDetails from "./Pages/StaticPage/DigitalLoanNewsDetails";
import DigitalLoanManageLoanDetails from "./Pages/StaticPage/DigitalLoanManageLoanDetails";
import LoanNewsDetails from "./Pages/StaticPage/LoanNewsDetails";
import LoanManageLoanDetails from "./Pages/StaticPage/LoanManageLoanDetails";
import CreditCardNewsDetails from "./Pages/StaticPage/CreditCardNewsDetails";
import CreditCardManageLoan from "./Pages/StaticPage/CreditCardManageLoan";
import HomeLoanManageLoan from "./Pages/StaticPage/HomeLoanManageLoan";
import HomeLoanNewsDetails from "./Pages/StaticPage/HomeLoanNewsDetails";
import CCArticleTwo from "./Pages/StaticPage/CreditCardArticles/CCArticleTwo";
import CCArticleThree from "./Pages/StaticPage/CreditCardArticles/CCArticleThree";
import CCArticleFour from "./Pages/StaticPage/CreditCardArticles/CCArticleFour";
import CreditScore from "./Pages/CreditScore/CreditScore";
import LowerEmiStaticPage from "./Pages/LowerEmi/LowerEmiStaticPage";
import ConsolidateEmiSection from "./Pages/ConsolidateEmi/ConsolidateEmiSection";
import TopupFlowIndex from "./Pages/TopupFlow/TopupFlowIndex";
import CreditRecommendationPage from "./Pages/CreditRecommendation/CreditRecommendationPage";
import CreditCardscore from "./Pages/CreditRecommendation/Question/CreditCardscore";
import Question1 from "./Pages/CreditRecommendation/Question/Question1";
import Question2 from "./Pages/CreditRecommendation/Question/Question2";
import Question4 from "./Pages/CreditRecommendation/Question/Question4";
import LoginScreen from "./Pages/CreditRecommendation/LoginScreen";
import RecommendatedPage from "./Pages/CreditRecommendation/RecommendatedPage";
import HomeLoanManageLoanBlogs from "./Pages/StaticPage/HomeLoanManageLoanBlogs";
import PersonalLoanManageLoanBlog from "./Pages/StaticPage/PersonalLoanManageLoanBlog";
import CarLoanManageLoanBlog from "./Pages/StaticPage/CarLoanManageLoanBlog";
import DigitalLoanMangeLoanBlogs from "./Pages/StaticPage/DigitalLoanMangeLoanBlogs";
import GoldLoanManageLoanBlog from "./Pages/StaticPage/GoldLoanManageLoanBlog";
import CreditCardManageCreditBlog from "./Pages/StaticPage/CreditCardManageCreditBlog";
import NotFoundPage from "./ErrorPages/NotFoundPage";
import LoanManageLoanBlogs from "./Pages/StaticPage/LoanManageLoanBlogs";
import APRSection from "./Pages/APRSection/APRSection";
import BadCredits from "./Pages/BadCredits/BadCredits";
import CreditCardEligiblity from "./Pages/StaticPage/BeforeApplying/CreditCardEligiblity";
import MainOffer from "./Pages/StaticPage/Personloanoffer/MainOffer";
import BTLandingPage from "./Pages/CompaingnLandingPages/LowerEmiWithBalanceTransfer/BalanceTransferPage/BTLandingPage";
import BalanceTransferEligibilityForm from "./Pages/CompaingnLandingPages/LowerEmiWithBalanceTransfer/BalanceTransferForm/BalanceTransferEligibilityForm";
import LoanConsolidationPage from "./Pages/CompaingnLandingPages/LoanConsolidation/LoanConsolidationPage/LoanConsolidationPage";
import LoanConsolidationEligibilityForm from "./Pages/CompaingnLandingPages/LoanConsolidation/LoanConsolidationForm/LoanConsolidationEligibilityForm";
import LowerYourEmiPage from "./Pages/CompaingnLandingPages/LowerYourEmi/LowerYourEmiPage/LowerYourEmiPage";
import LowerYourEmiForm from "./Pages/CompaingnLandingPages/LowerYourEmi/LowerYourEmiForm/LowerYourEmiForm";
import CreditProForm from "./Pages/CreditPro/Mainform/MainForm";
import CreditProDashboard from "./Pages/CreditPro/Dashboard/index";
import HomeLoanPage from "./Pages/CompaingnLandingPages/HomeLoanLowestRates/HomeLoanPage/HomeLoanPage";
import HomeLoanEligibilityForm from "./Pages/CompaingnLandingPages/HomeLoanLowestRates/HomeLoanForm/HomeLoanEligibilityForm";
import DashboardPage from "./Pages/CompaingnLandingPages/HomeLoanLowestRates/DashboardPage/DashboardPage";
import PersonalLoanPage from "./Pages/CompaingnLandingPages/PersonalLoanLowestRates/PersonalLoanPage/PersonalLoanPage";
import PersonalLoanEligibilityForm from "./Pages/CompaingnLandingPages/PersonalLoanLowestRates/PersonalLoanForm/PersonalLoanEligibilityForm";
import PLDashboardPage from "./Pages/CompaingnLandingPages/PersonalLoanLowestRates/DashboardPage/PLDashboardPage";
import CarLoanPage from "./Pages/CompaingnLandingPages/BestCarLoan/CarLoanPage/CarLoanPage";
import CarLoanEligibilityForm from "./Pages/CompaingnLandingPages/BestCarLoan/CarLoanForm/CarLoanEligibilityForm";
import CarDashboardPage from "./Pages/CompaingnLandingPages/BestCarLoan/DashboardPage/CarDashboardPage";
import CheckCibilScorePage from "./Pages/CompaingnLandingPages/CheckCibilScore/CheckCibilScorePage/CheckCibilScorePage";
import CibilScoreForm from "./Pages/CompaingnLandingPages/CheckCibilScore/CibilScoreForm/CibilScoreForm";
// import LoanRecommendationPage from "./Pages/Loanrecommendation/LoanRecommendationPage";
import Payoff from "./Pages/Loanrecommendation/Question/Payoff";
import AssetsSelect from "./Pages/Loanrecommendation/Question/AssetsSelect";
import SelectPrefer from "./Pages/Loanrecommendation/Question/SelectPrefer";
import ExistingLowerEmi from "./Pages/Loanrecommendation/Question/ExisitingLowerEmi";
import Paymonthly from "./Pages/Loanrecommendation/Question/Paymonthly";
import SelectInvestment from "./Pages/Loanrecommendation/Question/SelectInvestment";
import Coapplicant from "./Pages/Loanrecommendation/Question/Coapplicant";
import Repaymentpriority from "./Pages/Loanrecommendation/Question/Repaymentpriority";
import Newrecommendation from "./Pages/Loanrecommendation/Newrecommendation";
import LoginLRScreen from "./Pages/Loanrecommendation/LoginLRScreen";
import RecommendationGmnesq1 from "./Pages/Loanrecommendation/RecommendationGmnesq/RecommendationGmnesq1";
import LoanAgainstPropertyPage from "./Pages/CompaingnLandingPages/LoanAgainstProperty/LoanAgainstPropertyPage/LoanAgainstPropertyPage";
import LoanAgainstPropertyForm from "./Pages/CompaingnLandingPages/LoanAgainstProperty/LoanAgainstPropertyForm/LoanAgainstPropertyForm";
import MainHomeLoan from "./Pages/Homeloan/MainHomeLoan";
import MainCarLoan from "./Pages/Carloan/MainCarLoan";
import LAPDashboardPage from "./Pages/CompaingnLandingPages/LoanAgainstProperty/DashboardPage/LAPDashboardPage";
import LoanRecommendationLandingPage from "./Pages/Loanrecommendation/LoanRecommendationLandingPage";
import LoanApproval from "./Pages/Loans/LoanApproval/Index";
import CheckRate from "./Pages/Loans/CheckRate/Index";
import CheckCardAvailability from "./Pages/Loans/CheckCardAvailability/Index";
import CheckCardStatus from "./Pages/Loans/CheckCardStatus/Index";
import LoanOverview from "./Pages/Loans/LoanOverview/Index";
import Typesofcarloan from "./Pages/StaticPage/Typesofcarloan";
import Kotakbank from "./Pages/KotakbankPage/Kotakbank";
import LandingPage from "./Pages/LoanApproval/LandingPage";
import CheckEligiblity from "./Pages/CheckEligiblity/CheckEligiblity";
import CreditLandingPage from "./Pages/CreditCardApproval/CreditLandingpage";
import Aipage from "./Pages/Aipage/Aipage";

import CheckRatePage from "./Pages/CheckRate/CheckRate";
import PersonalLoanCompareLandingPage from "./Pages/LoanComaprePages/PersonalLoanComparePages/PersonalLoanCompareLandingPage";
import HomeLoanCompareLandingPage from "./Pages/LoanComaprePages/HomeLoanComparePages/HomeLoanCompareLandingPage";
import LoanAgainstSharesLandingPage from "./Pages/LoanComaprePages/LoanAgainstSharesLandingPage/LoanAgainstSharesLandingPage";
import Runningloan from "./Pages/Runningloans/Runningloan";
import Homeloanstatus from "./Pages/Homeloanstatus/Homeloan";
import Homeloantracking from "./Pages/StaticPage/Homeloantracking";

import Newhomeloanpages from "./Pages/Newhomeloanpages/Kotakbank";

// import DashboardPage from "./Pages/CompaingnLandingPages/HomeLoanLowestRates/DashboardPage/DashboardPage.jsx";

const App = () => {
  useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <div>
      <Router scrollRestoration={true}>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/adcampaign" element={<Login />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/goal" element={<Goal />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/confirmloan" element={<LoanEdit />} />
          <Route path="/savingopportunity" element={<LowerEmiDetailPage />} />
          <Route path="/details" element={<LowerEmiDetail />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/dashboard" element={<HomeAfterLogin />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/providedetails" element={<ProvideDetails />} />
          <Route path="/updateprofile" element={<UpdateProfile />} />
          <Route path="/loanoverview" element={<Loan />} />
          <Route path="/test" element={<Test />} />
          <Route path="/topupdetail" element={<TopUpDetail />} />
          <Route path="/personalloanform" element={<Form />} />
          <Route path="/homeloanform" element={<Form2 />} />
          <Route path="/applicationstatus" element={<Statuss />} />
          <Route path="/statusdetail" element={<Status />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsandCondition />} />
          <Route path="/experianconsent" element={<ExperianConsent />} />
          <Route path="/GrievanceAddressal" element={<GrievanceAddressal />} />
          <Route path="/balancetransfer" element={<BalanceTransfer />} />
          <Route path="/loanconsolidation" element={<Consolidate />} />
          <Route path="/topup" element={<Topup />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogDetail/:bslug" element={<BlogDetail />} />
          <Route path="/loanpage/personalloan" element={<PersonalLoan />} />

          {/* Personal Loan News and Manage Loan */}
          <Route
            path="/loanpage/personalloan/manageloan_detail/:mslug"
            element={<PersonalLoanNewsDetails />} //
          />
          <Route
            path="/loanpage/personalloan/news_detail/:nslug"
            element={<PlManageLoanDetails />}
          />
          <Route
            path="/loanpage/personalloan/manageloan_detail/"
            element={<PersonalLoanManageLoanBlog />}
          />
          {/* Gold Loan News and Manage Loan */}
          <Route
            path="/loanpage/gold-loan/news_detail/:nslug"
            element={<GoldLoanNewsDetails />}
          />
          <Route
            path="/loanpage/gold-loan/manageloan_detail/:mslug"
            element={<GoldLoanManageLoanDetails />}
          />
          <Route
            path="/loanpage/gold-loan/manageloan_detail"
            element={<GoldLoanManageLoanBlog />}
          />
          {/* Car Loan News and Manage Loan */}
          <Route
            path="/loanpage/carloan/news_detail/:nslug"
            element={<CarLoanNewsDetails />}
          />
          <Route
            path="/loanpage/carloan/manageloan_detail/:mslug"
            element={<CarLoanManageLoanDetails />}
          />
          <Route
            path="/loanpage/carloan/manageloan_detail"
            element={<CarLoanManageLoanBlog />}
          />
          {/* Home Loan News and Manage Loan  */}
          <Route
            path="/loanpage/homeloan/news_detail/:nslug"
            element={<HomeLoanNewsDetails />}
          />
          <Route
            path="/loanpage/homeloan/manageloan_detail/:mslug"
            element={<HomeLoanManageLoan />}
          />
          <Route
            path="/loanpage/homeloan/manageloan_detail"
            element={<HomeLoanManageLoanBlogs />}
          />
          {/* Digital Loan News and Manage Loan */}
          <Route
            path="/loanpage/digital-loan/news_detail/:nslug"
            element={<DigitalLoanNewsDetails />}
          />
          <Route
            path="/loanpage/digital-loan/manageloan_detail/:mslug"
            element={<DigitalLoanManageLoanDetails />}
          />
          <Route
            path="/loanpage/digital-loan/manageloan_detail"
            element={<DigitalLoanMangeLoanBlogs />}
          />
          {/* Loan Page */}
          <Route
            path="/loanpage/news_detail/:nslug"
            element={<LoanNewsDetails />}
          />
          <Route
            path="/loanpage/manageloan_detail/:mslug"
            element={<LoanManageLoanDetails />}
          />
          <Route
            path="/loanpage/manageloan_detail"
            element={<LoanManageLoanBlogs />}
          />
          {/* Credit Card News and Manage Credit */}
          <Route
            path="/creditcard/news_detail/:nslug"
            element={<CreditCardNewsDetails />}
          />
          <Route
            path="/creditcard/manageloan_detail/:mslug"
            element={<CreditCardManageLoan />}
          />
          <Route
            path="/creditcard/manageloan_detail"
            element={<CreditCardManageCreditBlog />}
          />
          <Route path="/loanpage/homeloan" element={<Homeloan />} />
          <Route
            path="/loanpage/personalloan/badcredit"
            element={<BadCredit />}
          />
          <Route path="/creditcard" element={<CreditCard />} />
          <Route path="/loanpage/carloan" element={<Carloan />} />
          <Route path="/loanpage" element={<LoanPage />} />
          <Route path="/faq" element={<Faq />} />
          {/* Loan Articles */}
          <Route
            path="/loanpage/loan-get-pre-approved"
            element={<LoanArticleOne />}
          />
          <Route
            path="/loanpage/loan-interest-rate-comparison"
            element={<LoanArticleTwo />}
          />
          <Route
            path="/loanpage/7-key-factors-lenders-consider-before-approving-your-loan-application-in-india"
            element={<LoanArticleThree />}
          />
          <Route
            path="/loanpage/essential-tips-for-securing-online-loans"
            element={<LoanArticleFour />}
          />
          {/* Personal Loan*/}
          <Route
            path="/loanpage/personalloan/loan-eligibilty-criteria"
            element={<EligiblityCriteria />}
          />
          <Route
            path="/loanpage/personalloan/loan-eligibilty-criteria/hdfc-loan-eligiblity"
            element={<HdfcEligiblity />}
          />
          <Route
            path="/loanpage/personalloan/loan-eligibilty-criteria/axis-loan-eligiblity"
            element={<AxisEligiblity />}
          />
          <Route
            path="/loanpage/personalloan/loan-eligibilty-criteria/sbi-loan-eligiblity"
            element={<SbiEligiblity />}
          />
          <Route
            path="/loanpage/personalloan/loan-eligibilty-criteria/icici-loan-eligiblity"
            element={<IciciEligiblity />}
          />
          <Route
            path="/loanpage/personalloan/loan-eligibilty-criteria/indusind-loan-eligiblity"
            element={<IndusindEligibility />}
          />
          <Route
            path="/loanpage/personalloan/loan-tracking"
            element={<LoanTracking />}
          />
          <Route
            path="/loanpage/personalloan/personal-topup"
            element={<PersonalTopup />}
          />
          <Route
            path="/loanpage/personalloan/types-of-personalloan"
            element={<Typesofpersonalloan />}
          />
          {/* PL Compare Interest Rate Table */}
          <Route
            path="/loanpage/personalloan/badcredit/hdfc-bank-personal-loan-eligibility"
            element={<HDFCInterestRate />}
          />
          <Route
            path="/loanpage/personalloan/badcredit/sbi-personal-loan-eligibility"
            element={<SBIInterestRate />}
          />
          <Route
            path="/loanpage/personalloan/badcredit/icici-bank-personal-loan-eligibility"
            element={<ICICIInterestRate />}
          />
          <Route
            path="/loanpage/personalloan/badcredit/axis-bank-personal-loan-eligibility"
            element={<AXISInterestRate />}
          />
          <Route
            path="/loanpage/personalloan/badcredit/bajaj-finserv-bank-personal-loan-eligibility"
            element={<BAJAJInterestRate />}
          />
          {/* personal loan article */}
          <Route
            path="/loanpage/personalloan/personal-loan-prepayment-and-foreclosure"
            element={<PLArticleOne />}
          />
          {/* Home Loan  */}
          <Route
            path="/loanpage/homeloan/home-loan-eligibility-criteria"
            element={<HomeLoanEligiblityCriteria />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-compare-interest-rates"
            element={<HLCompareInterestRates />}
          />
          <Route
            path="/loanpage/homeloan/types-of-home-loan"
            element={<TypesOfHomeLoan />}
          />

          <Route
            path="/loanpage/homeloan/home-loan-topup"
            element={<HomeLoanTopUp />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-tracking"
            element={<Homeloantracking />}
          />
          <Route
            path="/loanpage/homeloan/nri-home-loan"
            element={<NRIHomeLoan />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-balance-transfer"
            element={<HomeLoanBalanceTransfer />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-eligibility-criteria/hdfc-home-loan-eligibility"
            element={<HomeLoanHdfcEligibility />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-eligibility-criteria/axis-home-loan-eligibility"
            element={<HomeLoanAxisEligibility />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-eligibility-criteria/icici-home-loan-eligibility"
            element={<HomeLoanICICIEligibility />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-eligibility-criteria/sbi-home-loan-eligibility"
            element={<HomeLoanSbiEligibility />}
          />
          {/* Home Loan Articles */}
          <Route
            path="/loanpage/homeloan/home-loan-documents-required"
            element={<HomeArticleOne />}
          />
          <Route
            path="/loanpage/homeloan/home-loan-comparison-guide-in-india"
            element={<HomeArticleTwo />}
          />
          {/* Car Loan Eligibility */}
          <Route
            path="/loanpage/carloan/car-loan-eligibility-criteria"
            element={<CarLoanEligibilityCriteria />}
          />
          <Route
            path="/loanpage/carloan/car-loan-compare-offers"
            element={<CarLoanCompareOffers />}
          />
          <Route
            path="/loanpage/carloan/car-loan-top-up"
            element={<CarLoanTopUp />}
          />
          <Route
            path="/loanpage/carloan/used-car-loan"
            element={<UsedCarLoan />}
          />
          <Route
            path="/loanpage/carloan/car-loan-status-tracking"
            element={<CarLoanStatusTracking />}
          />
          <Route
            path="/loanpage/carloan/car-loan-eligibility-criteria/hdfc-car-loan-eligibility"
            element={<CarLoanHDFCeligibility />}
          />
          <Route
            path="/loanpage/carloan/car-loan-eligibility-criteria/axis-car-loan-eligibility"
            element={<CarLoanAXISeligibility />}
          />
          <Route
            path="/loanpage/carloan/car-loan-eligibility-criteria/sbi-car-loan-eligibility"
            element={<CarLoanSBIeligibility />}
          />
          <Route
            path="/loanpage/carloan/car-loan-eligibility-criteria/icici-car-loan-eligibility"
            element={<CarLoanICICIeligibility />}
          />
          {/* Car Loan Articles */}
          <Route
            path="/loanpage/carloan/10-things-to-know-before-applying-for-a-car-loan"
            element={<ArticleOne />}
          />
          <Route
            path="/loanpage/carloan/should-you-choose-a-fixed-or-floating-interest-rate-for-your-car-loan"
            element={<ArticleTwo />}
          />
          <Route
            path="/loanpage/carloan/down-payment-strategies"
            element={<ArticleThree />}
          />
          <Route
            path="/loanpage/carloan/top-5-factors-that-affect-your-car-loan-eligibility"
            element={<ArticleFour />}
          />
          <Route
            path="/loanpage/carloan/comparing-car-loan-offers"
            element={<ArticleFive />}
          />
          <Route
            path="/loanpage/carloan/understanding-the-impact-of-credit-scores-on-car-loan-approval"
            element={<ArticleSix />}
          />
          <Route
            path="/loanpage/carloan/pre-owned-vs-new-car-loans"
            element={<ArticleSeven />}
          />
          {/* Digital Loan */}
          <Route path="/loanpage/digital-loan" element={<DigitalLoan />} />
          <Route
            path="/loanpage/digital-loan/digital-loan-overdraft-facility"
            element={<DigitalLoanOverdraft />}
          />
          <Route
            path="/loanpage/digital-loan/personal-loan-on-credit-cards"
            element={<PersonalLoanOnCreditCards />}
          />
          <Route
            path="/loanpage/digital-loan/loan-against-shares-and-mutual-funds"
            element={<DigitalLoanAgainstShares />}
          />
          <Route
            path="/loanpage/digital-loan/loan-against-property"
            element={<DigitalLoanAgainstProperty />}
          />
          <Route
            path="/loanpage/digital-loan/top-up-loans"
            element={<DigitalLoanTopUp />}
          />
          {/* Digital Loan Article */}
          <Route
            path="/loanpage/digital-loan/how-to-manage-your-loan-against-property-digital-loan"
            element={<DLArticleOne />}
          />
          <Route
            path="/loanpage/digital-loan/loan-against-shares-and-mutual-funds"
            element={<DLArticleTwo />}
          />
          <Route
            path="/loanpage/digital-loan/using-your-credit-card-personal-loan-for-financial-flexibility"
            element={<DLArticleThree />}
          />
          <Route
            path="/loanpage/digital-loan/how-to-secure-a-top-up-loan-digitally"
            element={<DLArticleFour />}
          />
          <Route
            path="/loanpage/digital-loan/repaying-your-digital-loans"
            element={<DLArticleFive />}
          />
          <Route
            path="/loanpage/digital-loan/monitoring-your-digital-loan-repayments"
            element={<DLArticleSix />}
          />
          {/* Gold Loan */}
          <Route path="/loanpage/gold-loan" element={<GoldLoan />} />
          <Route
            path="/loanpage/gold-loan/gold-loan-eligibility-criteria"
            element={<GoldLoanEligibilityCriteria />}
          />
          <Route
            path="/loanpage/gold-loan/gold-loan-interest-rates"
            element={<GoldLoanInterestRate />}
          />
          <Route
            path="/loanpage/gold-loan/gold-loan-procedure"
            element={<GoldLoanProcedure />}
          />
          <Route
            path="/loanpage/gold-loan/gold-loan-providers-in-india"
            element={<GoldLoanCompanies />}
          />
          <Route
            path="/loanpage/gold-loan/types-of-gold-loan"
            element={<TypesOfGoldLoan />}
          />
          <Route
            path="/loanpage/gold-loan/types-of-gold-loan/gold-loan-overdraft"
            element={<GoldLoanOverdraft />}
          />
          <Route
            path="/loanpage/gold-loan/types-of-gold-loan/gold-loan-top-up"
            element={<GoldLoanTopUp />}
          />
          <Route
            path="/loanpage/gold-loan/types-of-gold-loan/gold-loan-bullet-repayment"
            element={<GoldLoanBulletRepayment />}
          />
          <Route
            path="/loanpage/gold-loan/types-of-gold-loan/gold-loan-balance-transfer"
            element={<GoldLoanBalanceTransfer />}
          />
          {/* Gold Loan Articles */}
          <Route
            path="/loanpage/gold-loan/gold-loan-balance-transfer"
            element={<GoldLoanArticleOne />}
          />
          <Route
            path="/loanpage/gold-loan/how-to-top-up-your-gold-loan-for-additional-financial-needs"
            element={<GoldLoanArticleTwo />}
          />
          <Route
            path="/loanpage/gold-loan/prepayment-and-foreclosure-of-gold-loans"
            element={<GoldLoanArticleThree />}
          />
          <Route
            path="/loanpage/gold-loan/gold-loan-renewal-process"
            element={<GoldLoanArticleFour />}
          />
          <Route
            path="/loanpage/gold-loan/managing-gold-loan-emis"
            element={<GoldLoanArticleFive />}
          />
          <Route
            path="/loanpage/gold-loan/gold-loan-closure-process"
            element={<GoldLoanArticleSix />}
          />
          {/* Credit Card */}
          <Route
            path="/creditcard/credit-card-eligibility"
            element={<CreditCardPage />}
          />
          <Route
            path="/creditcard/balance-transfer-credit-cards"
            element={<BalanceTransferCreditCards />}
          />
          <Route
            path="/creditcard/travel-and-airline-credit-cards"
            element={<TravelAndAirlineCredit />}
          />
          <Route
            path="/creditcard/credit-card-rewards-and-cashback"
            element={<CreditCardRewards />}
          />
          <Route
            path="/creditcard/credit-card-status-tracking"
            element={<LoanStatusTracking />}
          />
          {/* Credit Card Article */}
          <Route
            path="/creditcard/how-to-maximize-credit-card-benefits"
            element={<CCArticleOne />}
          />
          <Route
            path="/creditcard/credit-card-annual-fees"
            element={<CCArticleTwo />}
          />
          <Route
            path="/creditcard/why-is-my-credit-card-application-getting-rejected"
            element={<CCArticleThree />}
          />
          <Route
            path="/creditcard/a-complete-guide-to-balance-transfer-credit-cards"
            element={<CCArticleFour />}
          />
          <Route path="/credit-score" element={<CreditScore />} />
          <Route path="/lower-emi-page" element={<LowerEmiStaticPage />} />
          <Route
            path="/consolidate-emi-pages"
            element={<ConsolidateEmiSection />}
          />
          <Route path="/topup-emi-pages" element={<TopupFlowIndex />} />
          <Route
            path="/creditcard/creditcard-recommendation"
            element={<CreditRecommendationPage />}
          />
          <Route path="/select-credit-score" element={<CreditCardscore />} />
          <Route path="/select-occupation" element={<Question1 />} />
          <Route path="/monthly-income" element={<Question2 />} />
          <Route path="/spend-per-month" element={<Question4 />} />
          <Route path="/credit-login" element={<LoginScreen />} />
          <Route
            path="/credit-card-recommendations"
            element={<RecommendatedPage />}
          />
          <Route
            path="/consolidate-emi-pages"
            element={<ConsolidateEmiSection />}
          />
          <Route path="/topup-emi-pages" element={<TopupFlowIndex />} />
          {/* APR */}
          <Route path="/apr" element={<APRSection />} />
          {/* BadCredits */}
          <Route path="/bad-credits" element={<BadCredits />} />
          <Route
            path="/credit-card-eligiblity-before-applying"
            element={<CreditCardEligiblity />}
          />
          <Route path="/best-personal-loan-offer" element={<MainOffer />} />
          <Route path="/best-home-loan-offer" element={<MainHomeLoan />} />
          <Route path="/best-car-loan-offer" element={<MainCarLoan />} />
          {/* Balance Tansfer Landing Page */}
          <Route
            path="/lower-emi-balance-transfer"
            element={<BTLandingPage />}
          />
          <Route
            path="/balance-transfer-check-eligibility"
            element={<BalanceTransferEligibilityForm />}
          />
          {/* Loan consolidation */}
          <Route
            path="/lower-emi-loan-consolidation"
            element={<LoanConsolidationPage />}
          />
          <Route
            path="/loan-consolidation-check-eligibility"
            element={<LoanConsolidationEligibilityForm />}
          />
          {/* lower your emi top up */}
          <Route path="/lower-emi-top-up" element={<LowerYourEmiPage />} />
          <Route
            path="/top-up-check-eligibility"
            element={<LowerYourEmiForm />}
          />
          <Route path="/trackloanstatus" element={<CreditProForm />} />
          <Route
            path="/credit-pro-dashboard"
            element={<CreditProDashboard />}
          />
          {/* Not Found Page */}
          <Route path="*" element={<NotFoundPage />} />
          {/* home loan compaingn page */}
          <Route path="/homeloan/applyonline" element={<HomeLoanPage />} />
          <Route
            path="/home-loan-check-eligibility"
            element={<HomeLoanEligibilityForm />}
          />
          <Route path="/home-loan-dashboard" element={<DashboardPage />} />
          {/* personal loan compaingn pages */}
          {/* <Route
            path="/personalloan/applyonline"
            element={<PersonalLoanPage />}
          /> */}
          <Route
            path="/personal-loan-check-eligibility"
            element={<PersonalLoanEligibilityForm />}
          />
          <Route
            path="/personal-loan-dashboard"
            element={<PLDashboardPage />}
          />
          {/* car loan compaingn pages */}
          <Route path="/carloan/applyonline" element={<CarLoanPage />} />
          <Route
            path="/car-loan-check-eligibility"
            element={<CarLoanEligibilityForm />}
          />
          <Route path="/car-loan-dashboard" element={<CarDashboardPage />} />
          {/* check cibil score page */}
          <Route path="/check-cibil-score" element={<CheckCibilScorePage />} />
          <Route path="/check-cibil-eligibility" element={<CibilScoreForm />} />
          <Route path="/select-payoff-loan" element={<Payoff />} />
          <Route path="/select-assets" element={<AssetsSelect />} />
          <Route path="/select-prefer" element={<SelectPrefer />} />
          <Route path="/select-existing-emi" element={<ExistingLowerEmi />} />
          <Route path="/pay-monthly" element={<Paymonthly />} />
          <Route
            path="/select-assets-or-investments-to-use"
            element={<SelectInvestment />}
          />
          <Route path="/apply-with-co-applicant" element={<Coapplicant />} />
          <Route
            path="/select-repayment-priority"
            element={<Repaymentpriority />}
          />
          <Route path="/new-recommendation" element={<Newrecommendation />} />
          <Route
            path="/loan-recommendation-login"
            element={<LoginLRScreen />}
          />
          <Route
            path="/loan-recommendation-page"
            element={<RecommendationGmnesq1 />}
          />
          {/* Loan Against Property */}
          <Route
            path="/loan-against-property"
            element={<LoanAgainstPropertyPage />}
          />
          <Route
            path="/lap-check-eligibility"
            element={<LoanAgainstPropertyForm />}
          />
          <Route path="/lap-dashboard-page" element={<LAPDashboardPage />} />
          <Route
            path="/loans/personalloan/status/:bankdetails"
            element={<Kotakbank />}
          />
          <Route
            path="/loans/personalloan/status/:bankdetails"
            element={<Homeloanstatus />}
          />

          <Route
            path="/loan-recommendation"
            element={<LoanRecommendationLandingPage />}
          />
          <Route path="/loan-approval" element={<LoanApproval />} />
          <Route path="/check-rate" element={<CheckRate />} />
          <Route
            path="/check-card-availability"
            element={<CheckCardAvailability />}
          />
          <Route path="/check-card-status" element={<CheckCardStatus />} />
          <Route path="/loan-overview" element={<LoanOverview />} />
          <Route
            path="/loanpage/carloan/types-of-car-loan"
            element={<Typesofcarloan />}
          />

          <Route path="/Kotak-bank" element={<Kotakbank />} />
          <Route path="/landing-page" element={<LandingPage />} />
          <Route
            path="/check-eligiblity-landing-page"
            element={<CheckEligiblity />}
          />
          <Route
            path="/creditcard-approval-landing-page"
            element={<CreditLandingPage />}
          />
          <Route path="/check-rate-landing-page" element={<CheckRatePage />} />
          <Route path="/ai-landing-page" element={<Aipage />} />

          <Route
            path="/loanpage/personalloan/best-personal-loans-2025-compare"
            element={<PersonalLoanCompareLandingPage />}
          />
          <Route
            path="/loanpage/homeloan/best-home-loans-2025-compare"
            element={<HomeLoanCompareLandingPage />}
          />
          <Route
            path="/loanpage/digital-loan/loan-against-shares-and-mutual-funds/best-loans-2025-compare"
            element={<LoanAgainstSharesLandingPage />}
          />
          <Route
            path="/loans/homeloan/status/:bankdetails"
            element={<Newhomeloanpages />}
          />
          <Route path="/running-loan-page" element={<Runningloan />} />
        </Routes>

        {/* <Footer /> */}
        <FooterUpdated />
      </Router>
    </div>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
import { IoStar } from "react-icons/io5";
import './CreditCard.css'; // Assume this is where your styles are defined
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation, useNavigate } from 'react-router-dom';
import { RiVerifiedBadgeFill } from "react-icons/ri";
import card from '../../Assets/Images/visa.webp'
import star from '../../Assets/Images/star.webp'
import trophy from '../../Assets/Images/trophy.webp'
import gift from '../../Assets/Images/christmas.webp'
import { Check } from 'lucide-react';


const RecommendatedPage = () => {
  const[data,setData]=useState([])
  const[rating,setRating]=useState([])
  const navigate =useNavigate()
 useEffect(() => {
  const storedData=localStorage.getItem("matchdata")
  if (storedData) {
   setData(JSON.parse(storedData)); // Parse the stored data and set it in state
 }
 }, [])

 const getRandomArray = () => {
  const length = 1;  // Since you want only 1 rating
  const min = 3.5;
  const max = 5.0;
  
  // Generate the random number and format it to 2 decimal places
  const randomArray = Array.from({ length }, () => (Math.random() * (max - min) + min).toFixed(2));
  
  // Update the state with the generated random rating
  return randomArray;
};
// useEffect(() => {
//   getRandomArray()
// }, [])


//  useEffect(() => {
//     const token = localStorage.getItem("token")
//     const attempt = localStorage.getItem("attemept")
//     if(token==null || token == ""){
//       navigate("/")
//     }
//     if(token!=null && attempt==null){
//       navigate("/select-occupation")
//     }
//     }, [])
 
  return (
    <>
      <div style={{paddingLeft:'4%',paddingRight:'4%',marginTop:'4%'}}>
        <div className="credit-card-header">
          <div className="text-section">
            <p className="header-title" style={{lineHeight:1.2}}>
            Your Best-Matched Credit Cards Are Ready!
            </p>
            <p className="description">
            We've analyzed your profile and spending habits to find the best credit cards for you. Compare your options and apply with confidence..<br className='credit-brake'/> 
            </p>
          </div>
          <div className="edit-preferences hover:bg-[#561A6A] hover:text-white py-2 px-4  "   onClick={()=>navigate("/creditcard/creditcard-recommendation")} style={{alignSelf:'end'}}>
          Refine My Preferences
          </div>
        </div>
        <p className="recommendation">Showing 5 recommendations</p>
        <div className='desktop-card ' style={{display:'flex',flexDirection:'column',gap:24}}> 
       
 {data.map((d,index)=>(
 <div key={index} className="credit-card"  style={{paddingLeft:0,paddingTop:0}}>

 <div  style={{backgroundColor:'transparent'}}>
 <div style={{backgroundColor:'#E3FAF2',height:40,width:'100%' ,borderTopLeftRadius:8,display:'flex',flexDirection:'row',gap:4,paddingLeft:'2%'}}>
 <RiVerifiedBadgeFill  style={{backgroundColor:'transparent',color:'#006642',fontSize:'1.3rem',alignSelf:'center'}}/>
  <div style={{color:'#006642',fontSize:'1.3rem',fontWeight:'bold',backgroundColor:'transparent',alignSelf:'center'}}>Best for :{d.reason}</div>
 </div>
 

  <div className="card-info" style={{paddingLeft:"2%",paddingTop:'2%'}}>
  <div>
   <div className="image-wrapper">
     <img src={d.image} className="card-image" alt="Card" style={{objectFit:'contain'}} />
   </div>
  </div>
   <div>
     <p className="card-title" style={{fontSize:'1.3rem',fontWeight:700}}>{d.cardName}</p>
     <div className="card-details" style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
    <div className="detail-item" style={{ display: 'flex', alignItems: 'center'}}>
      <div style={{display:'flex',flexDirection:'row',gap:4,alignItems:'center'}}>
      <div style={{width:24,height:24}}>
          <img src={card} alt="" style={{width:'100%',height:'100%',objectFit:'contain'}}/>
        </div>
      <p style={{ marginRight: '8px', fontWeight: 'bold',width:120,alignItems:'center'  }}>
        Annual Fee</p>
      </div>
      <p style={{ margin: '0 8px' }}>:</p>
      <p>{d.AnnualFees}</p>
    </div>

    <div className="detail-item" style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{display:'flex',flexDirection:'row',gap:4,alignItems:'center'}}>
    <div style={{width:24,height:24}}>
          <img src={star} alt="" style={{width:'100%',height:'100%',objectFit:'contain'}}/>
        </div>
      <p style={{ marginRight: '8px', fontWeight: 'bold',width:120  }}>
   Match Score</p>
    </div>
      <p style={{ margin: '0 8px' }}>:</p>
      <p>{d.matchScore}</p>
    </div>
    <div className="detail-item" style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{display:'flex',flexDirection:'row',gap:4}}>
    <div style={{width:24,height:24}}>
          <img src={gift} alt="" style={{width:'100%',height:'100%',objectFit:'contain'}}/>
        </div>
      <p style={{ marginRight: '8px', fontWeight: 'bold',width:120,lineHeight:1.3 ,alignSelf:'flex-start' }}>
      Intro Offer</p>
    </div>
      <p style={{ margin: '0 8px' }}>:</p>
      <p>{d.WelcomeOffer.slice(0, 70)}</p>
    </div>

    <div className="detail-item" style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{display:'flex',flexDirection:'row',gap:4,alignItems:'center'}}>
    <div style={{width:24,height:24}}>
          <img src={trophy} alt="" style={{width:'100%',height:'100%',objectFit:'contain'}}/>
        </div>
      <p style={{ marginRight: '8px', fontWeight: 'bold',width:120  }}>
      Why This Card?</p>
        </div>
      <p style={{ margin: '0 8px' }}>:</p>
      <p>{d.reason.slice(0, 70)}</p>
    </div>
  
  </div>
   </div>
  </div>
 </div>
 <div className="ratings" style={{paddingTop:'2%'}} >
   <p style={{marginBottom:0,fontFamily:'Archivo',color:'#8A8A8A',fontWeight:600,fontSize:16}}>Match Score</p>
   <div className="rating-stars">
     <IoStar color="#307F6B" size={20} style={{
          alignSelf: "center",
          alignContent: "center",
          alignItems: "center"
     }} />
     <p style={{
         alignSelf: "center",
         alignContent: "center",
         alignItems: "center",
         marginBottom:0,
         fontFamily:'Archivo',color:'#1F1F1F',fontWeight:400,fontSize:18
     }}>
       <span  style={{fontFamily:'Archivo',fontSize:18}}> Ratings: </span>
        {/* {rating.map((r, index) => (
          <span key={index} style={{fontFamily:'Archivo',fontSize:18}}>{r[index]}</span>
        ))} */}
       { getRandomArray()}
    /5</p>
   </div>
 </div>
</div>
 ))}
       
        </div>
     
                    <div  className='mobile-card'style={{display:'flex',flexDirection:'column',gap:24}}> 
                    {data.map((d, index) => (
  <div className="credit-card" key={index} style={{ marginBottom: '20px',paddingLeft:0,paddingRight:0,paddingTop:0 ,display:'flex',flexDirection:'column'}}>
     <div style={{backgroundColor:'#E3FAF2',height:48,width:'100%' ,borderTopLeftRadius:8,display:'flex',flexDirection:'row',gap:4,paddingLeft:'2%'}}>
 <RiVerifiedBadgeFill  style={{backgroundColor:'transparent',color:'#006642',fontSize:'1.1rem',lineHeight:1.5,alignSelf:'center'}}/>
  <div style={{color:'#006642',fontSize:'1.1rem',fontWeight:'bold',backgroundColor:'transparent',alignSelf:'center',lineHeight:1.3}}>Best for :Long intro period + quarterly categories</div>
 </div>
    <div style={{paddingLeft:'2%',paddingRight:'2%',paddingTop:'3%'}}>    
      <div style={{ display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center'}}>
        <div className="image-wrapper" style={{ width: 165, height: 103 }}>
          <img src={d.image} className="card-image" alt="Card" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </div>
        <div>
          <p style={{ fontFamily: 'Archivo', fontWeight: 700, fontSize: 20, marginBottom: 1, lineHeight: 1.3 }}>
            {d.cardName}
          </p>
          <div>
            <p style={{ marginBottom: 0, fontFamily: 'Archivo', color: '#8A8A8A', fontWeight: 600, fontSize: 12 }}>
              MinEmi Ratings
            </p>
            <div className="rating-stars" style={{ display: 'flex', alignItems: 'center' }}>
              <IoStar color="#307F6B" size={12} />
              <p style={{ marginBottom: 0, fontFamily: 'Archivo', color: '#1F1F1F', fontWeight: 400, fontSize: 14 }}>
                <span style={{ fontFamily: 'Archivo', color: '#8A8A8A', fontWeight: 400, fontSize: 14 }}>
                  {d.rank}
                </span>/5
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="card-details mt-4" 
     style={{ display: 'flex', flexDirection: 'column', paddingLeft: '2%' }}>
  
  {/* Individual Detail Row */}
  {[
    { label: "Annual Fee", value: d.AnnualFees,img:card },
    { label: "Match Score", value: d.matchScore,img:gift },
    { label: "Intro Offers", value: d.WelcomeOffer,img:star  },
    { label: "Why This Card? ", value: d.reason,img:trophy  },
  ].map((item, index) => (
    <div key={index} 
         className="detail-row" 
         style={{ display: 'flex', gap: '8px', marginBottom: '4px' }}>
          <div style={{display:'flex',flexDirection:'row',gap:2}}>
          <div style={{width:20,height:20}}>
          <img src={item.img} alt="" style={{width:'100%',height:'100%',objectFit:'contain'}}/>
        </div>
      <p style={{ flex: 1, fontFamily: 'Archivo', fontWeight: 400, fontSize: 12, color: '#8A8A8A', marginBottom: 0 ,paddingTop:0,width:80}}>
        {item.label}
      </p>
          </div>
      
      
      <p style={{ fontFamily: 'Archivo', fontWeight: 400, fontSize: 12, color: '#8A8A8A', marginBottom: 0 }}>:</p>
      
      <p style={{ flex: 2, fontFamily: 'Archivo', fontWeight: 400, fontSize: 12, color: '#1F1F1F', marginBottom: 0 ,alignSelf:'start',marginTop:0}}>
        {item.value}
      </p>
    </div>
  ))}
  
</div>

  </div>
))}

</div>
<div className="w-full max-w-md bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-blue-900 dark:to-indigo-900 rounded-lg shadow-md overflow-hidden mt-4">
      <div className="p-6" style={{backgroundColor:'transparent'}}>
        <h2 className="text-2xl font-semibold text-center text-blue-700 dark:text-blue-300 mb-6" style={{backgroundColor:'transparent'}}>
          Our Commitment to You
        </h2>
        <div className="space-y-4" style={{backgroundColor:'transparent'}}>
          {[
            "We match cards based on real-time eligibility",
            "No impact on your credit score",
            "Best options based on your profile & spending",
          ].map((point, index) => (
            <div key={index} className="flex items-center space-x-3" style={{backgroundColor:'transparent'}}>
              <div className="flex-shrink-0" style={{backgroundColor:'transparent'}}>
                <Check className="h-5 w-5 text-green-500" style={{backgroundColor:'transparent'}} />
              </div>
              <p className="text-sm md:text-base text-gray-700 dark:text-gray-200" style={{backgroundColor:'transparent'}}>{point}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  
<div style={{marginTop:'3%',display:'flex',flexDirection:'column',gap:8}}>
<p  className="faq-card" style={{color:'#1F1F1F',fontFamily:'Archivo',fontSize:32,fontWeight:600,marginBottom:9,lineHeight:1.2}}>Frequently Asked Questions  (FAQs)</p>

      <Accordion style={{border:'0.1px solid #C8C8C8',boxShadow:'0px 0px 0px',borderRadius:12}} defaultExpanded>
        <AccordionSummary style={{boxShadow:'0px 0px 0px',borderBottom:0,fontFamily:'Archivo',fontWeight:600,fontSize:18,color:"#1F1F1F",backgroundColor:'white'}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
        How does the credit card recommendation tool work?
        </AccordionSummary>
        <AccordionDetails style={{fontFamily:'Archivo',fontWeight:400,fontSize:16,color:"#8A8A8A"}}>
        Our tool analyzes your preferences and financial profile to suggest the best credit cards for your needs without affecting your credit score.
        </AccordionDetails>
      </Accordion>
      <Accordion style={{border:'0.1px solid #C8C8C8',boxShadow:'0px 0px 0px',borderRadius:12}}>
        <AccordionSummary style={{boxShadow:'0px 0px 0px',borderBottom:0,fontFamily:'Archivo',fontWeight:600,fontSize:18,color:"#1F1F1F"}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
     Will using the recommendation tool impact my credit score?
        </AccordionSummary>
        <AccordionDetails style={{fontFamily:'Archivo',fontWeight:400,fontSize:16,color:"#8A8A8A"}}>
        No, our tool performs a soft check, meaning it won’t impact your credit score at any stage of the process.
        </AccordionDetails>
      </Accordion>
      <Accordion style={{border:'0.1px solid #C8C8C8',boxShadow:'0px 0px 0px',borderRadius:12}}>
        <AccordionSummary style={{boxShadow:'0px 0px 0px',borderBottom:0,fontFamily:'Archivo',fontWeight:600,fontSize:18,color:"#1F1F1F"}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
     What factors are considered when recommending credit cards?
        </AccordionSummary>
        <AccordionDetails style={{fontFamily:'Archivo',fontWeight:400,fontSize:16,color:"#8A8A8A"}}>
        We consider factors such as spending habits, income, credit score, and card features to ensure the best match for you.       
        </AccordionDetails>
      </Accordion>
      <Accordion style={{border:'0.1px solid #C8C8C8',boxShadow:'0px 0px 0px',borderRadius:12}}>
        <AccordionSummary style={{boxShadow:'0px 0px 0px',borderBottom:0,fontFamily:'Archivo',fontWeight:600,fontSize:18,color:"#1F1F1F"}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
     How long does it take to get credit card recommendations?
        </AccordionSummary>
        <AccordionDetails style={{fontFamily:'Archivo',fontWeight:400,fontSize:16,color:"#8A8A8A"}}>
        You can receive personalized credit card suggestions within minutes after answering a few simple questions.
        </AccordionDetails>
      </Accordion>
      
      <Accordion style={{border:'0.1px solid #C8C8C8',boxShadow:'0px 0px 0px',borderRadius:12}}>
        <AccordionSummary style={{boxShadow:'0px 0px 0px',borderBottom:0,fontFamily:'Archivo',fontWeight:600,fontSize:18,color:"#1F1F1F"}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
      What types of credit cards can I compare?
        </AccordionSummary>
        <AccordionDetails style={{fontFamily:'Archivo',fontWeight:400,fontSize:16,color:"#8A8A8A"}}>
        Our tool covers a wide range of credit cards, including cashback, travel, rewards, and business credit cards.
        </AccordionDetails>
      </Accordion>
      <Accordion style={{border:'0.1px solid #C8C8C8',boxShadow:'0px 0px 0px',borderRadius:12}}>
        <AccordionSummary style={{boxShadow:'0px 0px 0px',borderBottom:0,fontFamily:'Archivo',fontWeight:600,fontSize:18,color:"#1F1F1F"}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
       Do I need to provide personal information to use the tool?
        </AccordionSummary>
        <AccordionDetails style={{fontFamily:'Archivo',fontWeight:400,fontSize:16,color:"#8A8A8A"}}>
        Yes, we require basic personal information, such as your PAN and mobile number, to securely fetch your credit score. This helps us provide accurate and personalized credit card recommendations. Your data is handled with the highest level of security and is never shared without consent.        </AccordionDetails>
      </Accordion>
      <Accordion style={{border:'0.1px solid #C8C8C8',boxShadow:'0px 0px 0px',borderRadius:12}}>
        <AccordionSummary style={{boxShadow:'0px 0px 0px',borderBottom:0,fontFamily:'Archivo',fontWeight:600,fontSize:18,color:"#1F1F1F"}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
       Can I apply for a credit card directly through Minemi?
        </AccordionSummary>
        <AccordionDetails style={{fontFamily:'Archivo',fontWeight:400,fontSize:16,color:"#8A8A8A"}}>
        Yes, once you receive recommendations, you can apply directly through the Minemi platform or the card issuer’s site.      </AccordionDetails>
      </Accordion>
      <Accordion style={{border:'0.1px solid #C8C8C8',boxShadow:'0px 0px 0px',borderRadius:12}}>
        <AccordionSummary style={{boxShadow:'0px 0px 0px',borderBottom:0,fontFamily:'Archivo',fontWeight:600,fontSize:18,color:"#1F1F1F"}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
      What is the best credit card for low annual fees?
        </AccordionSummary>
        <AccordionDetails style={{fontFamily:'Archivo',fontWeight:400,fontSize:16,color:"#8A8A8A"}}>
        Our tool can filter and highlight cards with low or no annual fees based on your preferences.     </AccordionDetails>
      </Accordion> 
        <Accordion style={{border:'0.1px solid #C8C8C8',boxShadow:'0px 0px 0px',borderRadius:12}}>
        <AccordionSummary style={{boxShadow:'0px 0px 0px',borderBottom:0,fontFamily:'Archivo',fontWeight:600,fontSize:18,color:"#1F1F1F"}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
     How accurate are the credit card recommendations?
        </AccordionSummary>
        <AccordionDetails style={{fontFamily:'Archivo',fontWeight:400,fontSize:16,color:"#8A8A8A"}}>
        Our tool uses real-time data and advanced algorithms to provide accurate and tailored credit card suggestions.
        </AccordionDetails>
      </Accordion>
        <Accordion style={{border:'0.1px solid #C8C8C8',boxShadow:'0px 0px 0px',borderRadius:12}}>
        <AccordionSummary style={{boxShadow:'0px 0px 0px',borderBottom:0,fontFamily:'Archivo',fontWeight:600,fontSize:18,color:"#1F1F1F"}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
 Can I compare multiple credit cards side by side?
        </AccordionSummary>
        <AccordionDetails style={{fontFamily:'Archivo',fontWeight:400,fontSize:16,color:"#8A8A8A"}}>
        Yes, after receiving recommendations, you can compare the features, fees, and benefits of multiple cards at once.
        </AccordionDetails>
      </Accordion>
    </div>
      </div>
    </>
  );
};

export default RecommendatedPage;

export const faqData = [
  {
    id: 1,
    question: "What is a balance transfer?",
    answer:
      "A balance transfer allows you to transfer the outstanding balance from one credit card or loan to another credit card with a lower interest rate or better terms.",
  },
  {
    id: 2,
    question: "Will a balance transfer always save me money?",
    answer:
      "Not necessarily. While balance transfers can reduce interest costs, fees associated with the transfer and the duration of the lower interest rate period can affect overall savings. It’s crucial to compare the total costs before proceeding.",
  },
  {
    id: 3,
    question: "Can I transfer balances between cards from the same bank?",
    answer:
      "Generally, banks do not allow balance transfers between their own credit cards. The purpose of a balance transfer is to move debt from one lender to another.",
    // answerList: [
    //   {
    //     heading: " Loan Amount: ",
    //     info: "Higher amounts lead to higher EMIs.",
    //   },
    //   {
    //     heading: " Interest Rate:",
    //     info: "Lower rates reduce your EMI and total repayment.",
    //   },
    //   {
    //     heading: "Tenure:  ",
    //     info: " Longer tenures lower EMIs but increase total interest.",
    //   },
    // ],
  },
  {
    id: 4,
    question: "How does a balance transfer affect my credit score?",
    answer:
      "Applying for a new credit card or loan for a balance transfer can result in a hard inquiry,which may temporarily lower your credit score. However, timely repayments on the new account can improve your score over time.",
  },
  {
    id: 5,
    question: "What happens if I miss a payment on my balance transfer?",
    answer:
      "Missing a payment can lead to penalties, an increase in the interest rate, and a negative impact on your credit score. It’s crucial to make payments on time to maintain favorable terms.",
  },
  {
    id: 6,
    question: "Are there any fees for a balance transfer?",
    answer:
      "Yes, the amount you can transfer is typically limited by the credit limit of the new card or loan and the bank’s policies. Some banks may allow transfers up to a certain percentage of the credit limit.",
  },
  {
    id: 7,
    question: "Can I transfer balances from multiple cards or loans?",
    answer:
      "Some credit cards allow balance transfers from personal loans or other credit cards. However, this depends on the card issuer’s policies.",
  },
  {
    id: 8,
    question: "Will a balance transfer affect my credit score?",
    answer:
      "Focus on timely repayments of the new balance to take full advantage of the lower interest rate. Avoid accumulating new debt on the old account to prevent further financial strain.",
  },
];

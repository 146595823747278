import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ColorRing } from "react-loader-spinner";
import "./index.css";
import axios from "axios";
import {IoIosPlay } from "react-icons/io";

const Index = () => {
    const [show, setShow] = useState(false);
    const [amount, setAmount] = useState("4567890");
    const [tenure, setTenure] = useState("48");
    const [interest, setInterest] = useState("25");
    const [emi, setEmi] = useState("65000");
    const [formId, seFormId] = useState(null);
    const [loader, setLoader] = useState(false);
    const [position, setPosition] = useState(50);
    const [bgColor, setBgColor] = useState(getGradientColor(50));
    
    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (amount || tenure || interest || emi) {
        const formData = new FormData();
        formData.append("amount", amount);
        formData.append("tenure", tenure);
        formData.append("iRate", interest);
        formData.append("emi", emi);
        formData.append("formId", formId);
        setLoader(true);

        const response = await axios.post(
           `${process.env.REACT_APP_URL}/applyLoan`,
            formData
        );

        if (response.data.status) {
            setShow(true);
        }
        setLoader(false);
        }
    };

  
    function handleRangeChange(e) {
      const newPosition = e.target.value;
      setPosition(newPosition);
      setBgColor(getGradientColor(newPosition));
    }
  
    function getGradientColor(value) {
      if (value < 50) {
        return `rgb(${255},${(value / 50) * 222},0)`;
      } else {
        return `rgb(${(100 - value) * 5},${222},0)`;
      }
    }

  return (
    <>
      <div id="Loan-Approval-Chance">
        <div className="contentCon">
            <p id="bottom-con-title">Your Credit Card Approval Chances</p>
            <div id="loans-details-con">
                <div className="row m-0 p-0 d-flex justify-content-between">
                    <div className="col-12 col-md-6 py-0 ps-0 pe-0 pe-md-4">
                        <div className="form-con">
                            <p className="form-title m-0 p-0">Here are the details of your Card approval chances</p>
                            <p id="loan-approval-percentage">60%</p>
                            <p id="loan-approval-text">Based on your profile and credit history, your chances of approval are lower. You may still be eligible for credit cards from a few lenders.</p>
                            <div className="d-flex justify-content-center btn-con">
                                {!loader ? (
                                    <Button className="score-btn" type="submit">
                                        Improve My Score
                                    </Button>
                                ) : (
                                    <Button type="button" className="score-btn">
                                    <ColorRing
                                        visible={loader}
                                        height="30"
                                        width="30"
                                        ariaLabel="color-ring-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="color-ring-wrapper"
                                        colors={[
                                        "#ffffff",
                                        "#ffffff",
                                        "#ffffff",
                                        "#ffffff",
                                        "#ffffff",
                                        "#ffffff",
                                        ]}
                                    />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-6 p-0">
                        <div>
                            <div id="range" className="p-0">
                                <div
                                className="percentage-container"
                                style={{
                                    left: `calc(${position}% - 1px)`,
                                }}
                                >
                                    <div id="balanced-con">
                                        <span className="percentage-text">{position}%</span>
                                        <div className="triangle-con"
                                        style={{
                                        right: 
                                        ((position > 0 && position < 31) && `2px`) || 
                                        ((position > 30 && position < 80) && `1px`) || 
                                        ((position > 79 && position < 101) && `0px`),
                                        }}
                                        >
                                            <IoIosPlay
                                            // size={55}
                                            className="mainIcon"
                                            color="white"
                                            style={{
                                            transform: "rotate(90deg)"
                                            }}
                                            />

                                            <IoIosPlay
                                            // size={35}
                                            className="secIcon"
                                            color={bgColor}
                                            style={{  
                                            position: "absolute",
                                            transform: "rotate(90deg)"
                                            }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                
                                <Form.Range
                                min="1"
                                max="100"
                                step="1"
                                className="custom-range"
                                value={position}
                                onChange={handleRangeChange}
                                />
                            </div>
                            <div className="d-flex justify-content-between">
                                <p className="range-text">0%</p>
                                <p className="range-text">100%</p>
                            </div>
                        </div>

                        <div id="chance-main-con">
                            <div className="chance-con">
                                <div className="chance-details">
                                    <div className="chance-color-con bg-danger"></div>
                                    <p className="chance-text text-danger m-0 py-0 px-1">Red -</p>
                                    <p className="chance-text m-0 p-0">0% - 49%</p>
                                </div>
                                <p className="chance-desc mx-0 mt-2 mb-0 p-0">Low chances. Consider improving your credit score</p>
                            </div>

                            <div className="chance-con">
                                <div className="chance-details">
                                    <div className="chance-color-con bg-warning"></div>
                                    <p className="chance-text text-warning m-0 py-0 px-1">Yellow -</p>
                                    <p className="chance-text m-0 p-0">50% - 79%</p>
                                </div>
                                <p className="chance-desc mx-0 mt-2 mb-0 p-0">Moderate chances. Some lenders may approve.</p>
                            </div>
                        
                            <div className="chance-con">
                                <div className="chance-details">
                                    <div className="chance-color-con bg-success"></div>
                                    <p className="chance-text text-success m-0 py-0 px-1">Green -</p>
                                    <p className="chance-text m-0 p-0">80% - 100%</p>
                                </div>
                                <p className="chance-desc mx-0 mt-2 mb-0 p-0">High chances! Likely to get approved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal
          show={show}
          onHide={() => setShow(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            Your responses have been recorded, we'll get back to you.
          </Modal.Body>
          <Modal.Footer>
            <Button id="loans-modalCloseBtn" onClick={() => setShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Index;

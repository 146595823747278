import React, { useEffect, useState } from "react";
import "./HeroSection.css";
import axios from "axios";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  const [htmlContent, setHtmlContent] = useState("");

  const CompanyDetail = () => {
    const options = {
      method: "GET",
      url: `${process.env.REACT_APP_SECRET_KEY}/webData`,
    };

    axios
      .request(options)
      .then(function (response) {
        setHtmlContent(response.data.data.privacy_policy);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    CompanyDetail();
  }, []);
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Minemi</title>
        <meta
          name="description"
          content="Understand how we ensures the privacy and security of your data when using our app for loan management, eligibility checks, and EMI calculations."
        />
      </Helmet>
      <div style={{ backgroundColor: "#f8f8f8", paddingBottom: 30 }}>
        <div
          style={{
            paddingLeft: "5%",
            paddingRight: "5%",
            paddingTop: 70,
            fontSize: "21px",
            lineHeight: "3.75rem",
            color: "rgb(89, 38, 109)",
            fontWeight: "bold",
          }}
        >
          Privacy Policy
        </div>
        <div
          className="api-text"
          style={{ marginLeft: "5%", marginRight: "5%" }}
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
      </div>
    </>
  );
}

export default PrivacyPolicy;

import React, { useState, useRef, useEffect, useContext } from "react";
import creditPRo from "../assets/Images/creditPRo.webp";
import img from "../assets/Images/login.webp";
import img1 from "../assets/Images/Frame 463.webp";
import img2 from "../assets/Images/Frame 464.webp";
import img3 from "../assets/Images/Frame 463 (1).webp";
import "./Home.css";
import TestimonialsSection from "./TestimonialsSection";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import experian from "../assets/Images/image 1.webp";
import { Helmet } from "react-helmet";
import { UserContext } from "../../ContextAPI/FormContextAPI";
const MainForm = () => {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const [salaried, setSalaried] = useState(false);
  const [cmpList, setCmpList] = useState([]);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const inputRefs = useRef([]);
  const [otpField, setOtpField] = useState(new Array(6).fill(""));
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const [stgOne, setStgOne] = useState("");
  const [stgTwo, setStgTwo] = useState("");
  const [mbl, setMbl] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const { city } = useContext(UserContext);

  const [data, setData] = useState({
    fname: "",
    lname: "",
    email: "",
    mobile: "",
    profession: "",
    company: "",
    monthly_income: "",
    monthly_emi: "",
    loan_amount: "",
    loan_type: "",
  });
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if both consent checkboxes are checked
    if (!check1 || !check2) {
      alert("Please accept both terms and conditions to proceed.");
      return;
    }

    if (!data.email || !data.mobile || data.mobile.length !== 10) {
      alert("Please provide a valid email and 10-digit mobile number.");
      return;
    }

    try {
      const formattedMonthlyIncome = data.monthly_income.replace(/,/g, "");
      const formattedMonthlyEmi = data.monthly_emi.replace(/,/g, "");
      const formattedLoanAmount = data.loan_amount.replace(/,/g, "");
      const payload = {
        ...data,
        monthly_income: formattedMonthlyIncome,
        monthly_emi: formattedMonthlyEmi,
        loan_amount: formattedLoanAmount,
        platform: "web", // Add platform information
        access_for: "credit_pro",
        purpose: "credit_pro",
        city: city,
        // Add access_for information
      };
      setLoader(true); // Show loader during the API call
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/login`, // Endpoint URL
        payload, // Request payload
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status == 1) {
        setStgOne(response.data.data.expData.stgOneHitId);
        setStgTwo(response.data.data.expData.stgTwoHitId);
        localStorage.setItem("formId", response.data.data.formId);
        setMbl(data.mobile);
        setLoader(false); // Hide loader
        setShow(true); // Show success modal
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      console.error("Error making POST request", error);
      setLoader(false); // Hide loader on error
      toast.error("Something went wrong. Please try again later.");
    }
  };
  const otpSubmit = async () => {
    const concatenateOtp = otpField.join("");

    if (concatenateOtp.length === 6) {
      let formData = new FormData();
      formData.append("stgOneHitId", stgOne);
      formData.append("stgTwoHitId", stgTwo);
      formData.append("otp", concatenateOtp);
      formData.append("mobile", mbl);
      formData.append("formId", localStorage.getItem("formId"));
      formData.append("type", "CUSTOM");
      try {
        setOtpLoader(true);
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/verifyOtp`,
          formData
        );

        if (response.data.status == 1) {
          localStorage.setItem("mobile", mbl);
          localStorage.setItem("creditScore", response.data.data.cbil.score);
          localStorage.setItem(
            "type",
            response.data.data.eligibleLoan.savingopprtunity.formula_type
          );
          localStorage.setItem(
            "amount",
            response.data.data.eligibleLoan.amount
          );
          localStorage.setItem(
            "tenure",
            response.data.data.eligibleLoan.savingopprtunity.formula_type ==
              "hl"
              ? 360
              : 84
          );
          localStorage.setItem(
            "loanAmount",
            data.loan_amount.replace(/,/g, "")
          );

          localStorage.setItem(
            "runningloan",
            response.data.data.loanDetail.noOfRunningLoan
          );
          localStorage.setItem(
            "outstandingamount",
            response.data.data.loanDetail.totalAmt
          );
          localStorage.setItem("latepayment", 0);
          localStorage.setItem("name", data.fname);

          LoanEstimator();
        } else {
          toast.error(response.data.msg);
        }
      } catch (error) {
        toast.error(error.response.data.msg);
        console.error("Error making POST request", error);
        setOtpLoader(false);
        setOtpLoader(false);
        navigate("/credit-pro-dashboard");
      }
    }
  };

  const LoanEstimator = async () => {
    let formData = new FormData();
    formData.append("cibilScore", localStorage.getItem("creditScore"));
    formData.append("loanAmount", localStorage.getItem("amount"));
    formData.append("loanType", localStorage.getItem("type"));
    formData.append("tenure", localStorage.getItem("tenure"));
    try {
      setOtpLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/loanEstimator`,
        formData
      );
      setOtpLoader(false);
      if (response.data.status == 1) {
        localStorage.setItem(
          "estimatorValue",
          JSON.stringify(response.data.data.estimatorValue)
        );
        localStorage.setItem("loanAmount", data.loan_amount.replace(/,/g, ""));
        setOtpLoader(false);
        navigate("/credit-pro-dashboard");
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {
      toast.error(error.response.data.msg);
      console.error("Error making POST request", error);
      setOtpLoader(false);
    }
  };
  const handleChange = async (event, cName) => {
    console.log(event, cName);
    let name = null;
    let value = null;

    // Fixed this condition to properly handle company selection
    if (event && event.target) {
      name = event.target.name;
      value = event.target.value;
    } else if (event && event.label) {
      // Changed from event.value to event.label
      name = cName;
      value = event.label; // Use label instead of value to get company name
    }

    if (name === "profession") {
      if (value === "Salaried") {
        setSalaried(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SECRET_KEY}/companyList`
          );
          if (response.status === 200) {
            const formattedOptions = response.data.data.company.map(
              (option) => ({
                value: option.id,
                label: option.company_name,
              })
            );
            setCmpList(formattedOptions);
          }
        } catch (error) {
          console.error("Error fetching company list", error);
        } finally {
        }
      } else {
        setSalaried(false);
        setCmpList([]);
      }
    }

    setData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  };
  const otpHandleChange = (element, index) => {
    const value = element.value.replace(/[^0-9]/g, "").slice(0, 1); // Ensure only one digit
    if (!value) return;
  
    const newOtp = [...otpField];
    newOtp[index] = value;
    setOtpField(newOtp);
  
    // Move focus to the next field if the current field has a value
    if (index < 5 && value) {
      inputRefs.current[index + 1].focus();
    }
  }
  
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      // Create a copy of the current OTP array
      const newOtp = [...otpField];
  
      // Clear the current field
      newOtp[index] = "";
  
      // If the current field is empty and not the first field, move focus to the previous field
      if (index > 0 && !newOtp[index]) {
        inputRefs.current[index - 1].focus();
      }
  
      // Update the state with the new OTP array
      setOtpField(newOtp);
    }
  }

  const formatIndianNumber = (number) => {
    if (!number) return "";

    // Convert to string and remove any existing commas
    let str = number.toString().replace(/,/g, "");

    // Handle negative numbers
    const isNegative = str.startsWith("-");
    if (isNegative) str = str.slice(1);

    // Split number into integer and decimal parts
    const [integerPart, decimalPart] = str.split(".");

    // Format integer part
    const lastThree = integerPart.slice(-3);
    const remaining = integerPart.slice(0, -3);

    let formattedNumber =
      remaining
        .split("")
        .reverse()
        .join("")
        .match(/.{1,2}/g)
        ?.map((group) => group.split("").reverse().join(""))
        .reverse()
        .join(",") || "";

    formattedNumber = formattedNumber
      ? `${formattedNumber},${lastThree}`
      : lastThree;

    // Add decimal part if exists
    if (decimalPart !== undefined) {
      formattedNumber += `.${decimalPart}`;
    }

    // Add negative sign if needed
    return isNegative ? `-${formattedNumber}` : formattedNumber;
  };
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded2, setIsExpanded2] = useState(false);

  const text =
    "I hereby consent to Nine Triangles Pvt. Ltd. being appointed as my authorised representative to receive my credit information from Experian for the purpose of Credit Assessment to advise me on the best loan offers on expiry of 6 months from the date the consent is collected, whichever is earlier. I also agree to Experian Terms and Conditions.";
  const text2 =
    "I agree to MinEMI's Terms of Use and Privacy Policy and authorise to be communicate via SMS ,Call, Whatsapp and Email. I hereby also allow MInEMI to access my credit report on my behalf";

  const truncatedText = text.slice(0, 100) + "..."; // Show first 100 characters
  const truncatedText2 = text2.slice(0, 100) + "..."; // Show first 100 characters

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Check Your Loan Status Online – MinEMI Loan Approval Insights
        </title>
        <meta
          name="description"
          content="Track your loan application status online with MinEMI. Get real-time insights into loan approval chances, check pending documents, and improve your eligibility before applying for a new loan."
        />
      </Helmet>

      <div>
        <div
          style={{
            backgroundColor: "#59266D",
            display: "flex",
            justifyContent: "center",
            flexWrap: "nowrap",
          }}
        >
          <div
            className="main-Heading"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 32,
              color: "#FFFFFF",
              padding: 5,
              paddingLeft: 0,
              paddingRight: 0,
              backgroundColor: "transparent",
            }}
          >
            Welcome
          </div>
        </div>
        <div
          className="w-full mt-4 "
          style={{ marginBottom: "10%", backgroundColor: "transparent" }}
        >
          <div
            className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
            style={{
              backgroundColor: "transparent",
            }}
          >
            <div
              className="flex flex-col lg:flex-row gap-8"
              style={{
                backgroundColor: "transparent",
              }}
            >
              {/* Left Column (Visible on large screens) */}
              <div
                className="w-full lg:w-7/12"
                style={{
                  backgroundColor: "transparent",
                }}
              >
                <div
                  className="w-full h-30 mb-6 image-widthChange "
                  style={{
                    backgroundColor: "transparent",
                  }}
                >
                  <img
                    src={creditPRo}
                    alt="Credit Pro"
                    className="w-full h-full"
                    style={{
                      backgroundColor: "transparent",
                    }}
                  />
                </div>
                <h1
                  className="text-[#59266D] text-4xl font-bold leading-tight mb-6"
                  style={{
                    fontFamily: "Archivo",
                    backgroundColor: "transparent",
                  }}
                >
                  Reject-proof your loan
                  <br />
                  application Now!
                </h1>
                <p
                  className="text-[#59266D] text-2xl mb-6"
                  style={{
                    fontFamily: "Archivo",
                    backgroundColor: "transparent",
                  }}
                >
                  Let <span className="font-bold">MinEMI Credit Pro</span> help
                  you secure the
                  <br />
                  loan you deserve!
                </p>
                <p
                  className="text-[#59266D] text-xl mb-12"
                  style={{
                    fontFamily: "Archivo",
                    backgroundColor: "transparent",
                  }}
                >
                  Just fill in the details and let{" "}
                  <span
                    className="text-[#EEA11E] font-bold"
                    style={{
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEMI Credit Pro
                  </span>
                  <br />
                  guide you towards a faster, smoother, and
                  <br />
                  rejection-proof approval process.
                </p>
              </div>

              {/* Right Column (Form Section) */}
              <div className="w-full lg:w-5/12 bg-[#E7E4F9] rounded-3xl border-2 border-[#846CA6]">
                <div className="bg-[#59266D] rounded-t-3xl p-2 text-center">
                  <h2
                    className="text-white text-xl sm:text-2xl font-semibold"
                    style={{
                      fontFamily: "Archivo",
                      backgroundColor: "transparent",
                    }}
                  >
                    Get Started With
                    <br />
                    <span
                      className="text-[#EEA11E] font-bold"
                      style={{
                        fontFamily: "Archivo",
                        backgroundColor: "transparent",
                      }}
                    >
                      MinEMI Credit Pro
                    </span>
                  </h2>
                </div>
                <div
                  className="p-3 "
                  style={{
                    backgroundColor: "transparent",
                  }}
                >
                  <Form
                    className="space-y-6"
                    noValidate
                    onSubmit={handleSubmit}
                    style={{
                      backgroundColor: "transparent",
                    }}
                  >
                    <div
                      className="grid grid-cols-2 gap-4"
                      style={{
                        backgroundColor: "transparent",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "transparent",
                        }}
                      >
                        <Form.Control
                          className="w-full h-9 rounded-lg border-[#846CA6] "
                          style={{
                            fontFamily: "Archivo",
                            backgroundColor: "white",
                            fontWeight: 400,
                            color: "grey",
                            fontSize: "1rem",
                          }}
                          onChange={handleChange}
                          placeholder="Enter First Name"
                          name="fname"
                          value={data.fname}
                          required
                        />
                      </div>
                      <div
                        style={{
                          backgroundColor: "transparent",
                        }}
                      >
                        <Form.Control
                          style={{
                            fontFamily: "Archivo",
                            backgroundColor: "white",
                            fontWeight: 400,
                            color: "grey",
                            fontSize: "1rem",
                          }}
                          className="w-full h-9 rounded-lg border-[#846CA6] text-sm"
                          onChange={handleChange}
                          placeholder="Enter Last Name"
                          name="lname"
                          value={data.lname}
                          required
                        />
                      </div>
                    </div>
                    <div
                      className="grid grid-cols-2 gap-4"
                      style={{
                        backgroundColor: "transparent",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "transparent",
                        }}
                      >
                        <Form.Control
                          style={{
                            fontFamily: "Archivo",
                            backgroundColor: "white",
                            fontWeight: 400,
                            color: "grey",
                            fontSize: "1rem",
                          }}
                          className="w-full h-9 rounded-lg border-[#846CA6] text-sm"
                          placeholder="Enter Email"
                          onChange={handleChange}
                          name="email"
                          value={data.email}
                          required
                        />
                      </div>
                      <div
                        style={{
                          backgroundColor: "transparent",
                        }}
                      >
                        <Form.Control
                          style={{
                            fontFamily: "Archivo",
                            backgroundColor: "white",
                            fontWeight: 400,
                            color: "grey",
                            fontSize: "1rem",
                          }}
                          className="w-full h-9 rounded-lg border-[#846CA6] "
                          type="tel"
                          placeholder="Enter Mobile Number"
                          onChange={handleChange}
                          name="mobile"
                          maxLength={10}
                          value={data.mobile}
                          required
                        />
                      </div>
                    </div>
                    <div style={{ backgroundColor: "transparent" }}>
                      <h3
                        className="text-[#59266D] text-xl font-semibold mb-4"
                        style={{
                          fontFamily: "Archivo",
                          backgroundColor: "transparent",
                        }}
                      >
                        Additional Information
                      </h3>

                      <Form.Select
                        className="mb-4"
                        style={{
                          fontFamily: "Archivo",
                          backgroundColor: "white",
                          fontWeight: 400,
                          color: "grey",
                          fontSize: "1rem",
                        }}
                        value={data.profession}
                        name="profession" // ✅ Add name attribute
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select your profession</option>
                        <option value="Salaried">Salaried</option>
                        <option value="Self-Employed">Self-Employed</option>
                        <option value="Other">Other</option>
                      </Form.Select>

                      {salaried && (
                        <div
                          className="mb-4"
                          style={{ backgroundColor: "transparent" }}
                        >
                          <Form.Select
                            className="mb-4 h-9"
                            style={{
                              fontFamily: "Archivo",
                              backgroundColor: "white",
                              fontWeight: 400,
                              color: "grey",
                              fontSize: "1rem",
                            }}
                            value={selectedOption?.value}
                            onChange={(e) => {
                              const selected = cmpList.find(
                                (cmp) => cmp.value === parseInt(e.target.value)
                              );
                              setSelectedOption(selected);
                              handleChange(selected, "company"); // This will now properly update data.company with the company name
                            }}
                          >
                            <option value="">Select your company</option>
                            {cmpList.map((cmp) => (
                              <option key={cmp.value} value={cmp.value}>
                                {cmp.label}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                      )}

                      <div
                        className="space-y-4"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <Form.Control
                          style={{
                            fontFamily: "Archivo",
                            backgroundColor: "white",
                            fontWeight: 400,
                            color: "grey",
                            fontSize: "1rem",
                          }}
                          className="w-full h-9 rounded-lg border-[#846CA6] "
                          placeholder="Your monthly income"
                          onChange={handleChange}
                          name="monthly_income"
                          value={formatIndianNumber(data.monthly_income)}
                          required
                        />
                        <Form.Control
                          style={{
                            fontFamily: "Archivo",
                            backgroundColor: "white",
                            fontWeight: 400,
                            color: "grey",
                            fontSize: "1rem",
                          }}
                          className="w-full h-9 rounded-lg border-[#846CA6] "
                          placeholder="Your monthly EMI"
                          onChange={handleChange}
                          name="monthly_emi"
                          value={formatIndianNumber(data.monthly_emi)}
                          required
                        />
                      </div>

                      <div
                        className="space-y-4"
                        style={{
                          backgroundColor: "transparent",
                          marginTop: "4%",
                        }}
                      >
                        <Form.Select
                          className="mb-4"
                          style={{
                            fontFamily: "Archivo",
                            backgroundColor: "white",
                            fontWeight: 400,
                            color: "grey",
                            fontSize: "1rem",
                          }}
                          value={data.loan_type}
                          name="loan_type" // ✅ Add name attribute
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">Select Loan Type</option>
                          <option value="hl">Housing Loan</option>
                          <option value="cl">Car Loan</option>
                          <option value="pl">Personal Loan</option>
                        </Form.Select>
                        <Form.Control
                          style={{
                            fontFamily: "Archivo",
                            backgroundColor: "white",
                            fontWeight: 400,
                            color: "grey",
                            fontSize: "1rem",
                          }}
                          className="w-full h-9 rounded-lg border-[#846CA6] "
                          placeholder="Loan Amount Required"
                          onChange={handleChange}
                          name="loan_amount"
                          value={formatIndianNumber(data.loan_amount)}
                          required
                        />
                      </div>
                    </div>
                    <div
                      className="space-y-4"
                      style={{
                        backgroundColor: "transparent",
                      }}
                    >
                      <div
                        className="flex items-start "
                        style={{
                          backgroundColor: "transparent",
                          gap: 4,
                        }}
                      >
                        <input
                          type="checkbox"
                          className="mt-1 w-5 h-5 rounded border-[#59266D]"
                          onChange={(e) => setCheck1(e.target.checked)}
                        />
                        <label
                          className="text-sm"
                          style={{
                            fontFamily: "Archivo",
                            backgroundColor: "transparent",
                          }}
                        >
                          {isExpanded2 ? text2 : truncatedText2}{" "}
                          <button
                            onClick={() => setIsExpanded2(!isExpanded2)}
                            className="text-blue-500 underline ml-1"
                          >
                            {isExpanded2 ? "Read Less" : "Read More"}
                          </button>
                        </label>
                      </div>
                      <div
                        className="flex items-start "
                        style={{
                          backgroundColor: "transparent",
                          gap: 4,
                        }}
                      >
                        <input
                          type="checkbox"
                          className="mt-1 w-5 h-5 rounded border-[#59266D]"
                          onChange={(e) => setCheck2(e.target.checked)}
                        />
                        <label
                          className="text-sm"
                          style={{
                            fontFamily: "Archivo",
                            backgroundColor: "transparent",
                          }}
                        >
                          {isExpanded ? text : truncatedText}{" "}
                          <button
                            onClick={() => setIsExpanded(!isExpanded)}
                            className="text-blue-500 underline ml-1"
                            style={{
                              backgroundColor: "transparent",
                            }}
                          >
                            {isExpanded ? "Read Less" : "Read More"}
                          </button>
                        </label>
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column align-items-center mt-3"
                      style={{
                        backgroundColor: "transparent",
                      }}
                    >
                      <p
                        // className="empowered-text"
                        style={{
                          fontWeight: 400,
                          fontFamily: "Archivo",
                          fontSize: 14,
                          color: "#1F1F1F",
                          marginBottom: 0,
                          backgroundColor: "transparent",
                          borderWidth: "1px solid red",
                        }}
                      >
                        Empowered By
                      </p>
                      <div
                        style={{
                          width: 134,
                          height: 40,
                          backgroundColor: "transparent",
                        }}
                      >
                        <img
                          src={experian}
                          alt="experian.webp"
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "transparent",
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="text-center"
                      style={{
                        position: "relative",
                        top: 35,
                        backgroundColor: "transparent",
                      }}
                    >
                      <Button
                        type="submit"
                        style={{
                          backgroundColor: "#EEA11E",
                          border: "none",
                          color: "#59266D",
                          fontWeight: 700,
                          fontFamily: "Archivo",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          margin: "auto",
                          backgroundColor: "#59266D",
                        }}
                        className={`px-8 py-2 text-lg font-bold transition-all w-[90%] ${
                          hover
                            ? "bg-[] text-white shadow-lg"
                            : "bg-[#59266D] text-white shadow-xl"
                        }`}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                      >
                        {loader ? (
                          <ColorRing
                            visible={true}
                            height="24"
                            width="24"
                            style={{ backgroundColor: "transparent" }}
                          />
                        ) : (
                          "Check Now"
                        )}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
          style={{
            backgroundColor: "transparent",
          }}
        >
          <div
            className="flex flex-col lg:flex-row gap-8"
            style={{
              backgroundColor: "transparent",
            }}
          >
            {/* Left Column (Visible on large screens) */}
            <div
              className="w-full lg:w-7/12"
              style={{
                backgroundColor: "transparent",
              }}
            >
              <div
                className="w-full h-30 mb-6 image-widthChange "
                style={{
                  backgroundColor: "transparent",
                }}
              >
                <img
                  src={creditPRo}
                  alt="Credit Pro"
                  className="w-full h-full"
                  style={{
                    backgroundColor: "transparent",
                  }}
                />
              </div>
              <h1
                className="text-[#59266D] text-4xl font-bold leading-tight mb-6"
                style={{
                  fontFamily: "Archivo",
                  backgroundColor: "transparent",
                }}
              >
                Reject-proof your loan
                <br />
                application Now!
              </h1>
              <p
                className="text-[#59266D] text-2xl mb-6"
                style={{
                  fontFamily: "Archivo",
                  backgroundColor: "transparent",
                }}
              >
                Let <span className="font-bold">MinEMI Credit Pro</span> help
                you secure the
                <br />
                loan you deserve!
              </p>
              <p
                className="text-[#59266D] text-xl mb-12"
                style={{
                  fontFamily: "Archivo",
                  backgroundColor: "transparent",
                }}
              >
                Just fill in the details and let{" "}
                <span
                  className="text-[#EEA11E] font-bold"
                  style={{
                    backgroundColor: "transparent",
                  }}
                >
                  MinEMI Credit Pro
                </span>
                <br />
                guide you towards a faster, smoother, and
                <br />
                rejection-proof approval process.
              </p>
            </div>

            {/* Right Column (Form Section) */}
            <div className="w-full lg:w-5/12 bg-[#E7E4F9] rounded-3xl border-2 border-[#846CA6]">
              <div className="bg-[#59266D] rounded-t-3xl p-2 text-center">
                <h2
                  className="text-white text-xl sm:text-2xl font-semibold"
                  style={{
                    fontFamily: "Archivo",
                    backgroundColor: "transparent",
                  }}
                >
                  Get Started With
                  <br />
                  <span
                    className="text-[#EEA11E] font-bold"
                    style={{
                      fontFamily: "Archivo",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEMI Credit Pro
                  </span>
                </h2>
              </div>
              <div
                className="p-3 "
                style={{
                  backgroundColor: "transparent",
                }}
              >
                <Form
                  className="space-y-6"
                  noValidate
                  onSubmit={handleSubmit}
                  style={{
                    backgroundColor: "transparent",
                  }}
                >
                  <div
                    className="grid grid-cols-2 gap-4"
                    style={{
                      backgroundColor: "transparent",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "transparent",
                      }}
                    >
                      <Form.Control
                        className="w-full h-9 rounded-lg border-[#846CA6] "
                        style={{
                          fontFamily: "Archivo",
                          backgroundColor: "white",
                          fontWeight: 400,
                          color: "grey",
                          fontSize: "1rem",
                        }}
                        onChange={handleChange}
                        placeholder="Enter First Name"
                        name="fname"
                        value={data.fname}
                        required
                      />
                    </div>
                    <div
                      style={{
                        backgroundColor: "transparent",
                      }}
                    >
                      <Form.Control
                        style={{
                          fontFamily: "Archivo",
                          backgroundColor: "white",
                          fontWeight: 400,
                          color: "grey",
                          fontSize: "1rem",
                        }}
                        className="w-full h-9 rounded-lg border-[#846CA6] text-sm"
                        onChange={handleChange}
                        placeholder="Enter Last Name"
                        name="lname"
                        value={data.lname}
                        required
                      />
                    </div>
                  </div>
                  <div
                    className="grid grid-cols-2 gap-4"
                    style={{
                      backgroundColor: "transparent",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "transparent",
                      }}
                    >
                      <Form.Control
                        style={{
                          fontFamily: "Archivo",
                          backgroundColor: "white",
                          fontWeight: 400,
                          color: "grey",
                          fontSize: "1rem",
                        }}
                        className="w-full h-9 rounded-lg border-[#846CA6] text-sm"
                        placeholder="Enter Email"
                        onChange={handleChange}
                        name="email"
                        value={data.email}
                        required
                      />
                    </div>
                    <div
                      style={{
                        backgroundColor: "transparent",
                      }}
                    >
                      <Form.Control
                        style={{
                          fontFamily: "Archivo",
                          backgroundColor: "white",
                          fontWeight: 400,
                          color: "grey",
                          fontSize: "1rem",
                        }}
                        className="w-full h-9 rounded-lg border-[#846CA6] "
                        type="tel"
                        placeholder="Enter Mobile Number"
                        onChange={handleChange}
                        name="mobile"
                        maxLength={10}
                        value={data.mobile}
                        required
                      />
                    </div>
                  </div>
                  <div style={{ backgroundColor: "transparent" }}>
                    <h3
                      className="text-[#59266D] text-xl font-semibold mb-4"
                      style={{
                        fontFamily: "Archivo",
                        backgroundColor: "transparent",
                      }}
                    >
                      Additional Information
                    </h3>

                    <Form.Select
                      className="mb-4"
                      style={{
                        fontFamily: "Archivo",
                        backgroundColor: "white",
                        fontWeight: 400,
                        color: "grey",
                        fontSize: "1rem",
                      }}
                      value={data.profession}
                      name="profession" // ✅ Add name attribute
                      onChange={(e) => handleChange(e)}
                    >
                      <option value="">Select your profession</option>
                      <option value="Salaried">Salaried</option>
                      <option value="Self-Employed">Self-Employed</option>
                      <option value="Other">Other</option>
                    </Form.Select>

                    {salaried && (
                      <div
                        className="mb-4"
                        style={{ backgroundColor: "transparent" }}
                      >
                        <Form.Select
                          className="mb-4 h-9"
                          style={{
                            fontFamily: "Archivo",
                            backgroundColor: "white",
                            fontWeight: 400,
                            color: "grey",
                            fontSize: "1rem",
                          }}
                          value={selectedOption?.value}
                          onChange={(e) => {
                            const selected = cmpList.find(
                              (cmp) => cmp.value === parseInt(e.target.value)
                            );
                            setSelectedOption(selected);
                            handleChange(selected, "company"); // This will now properly update data.company with the company name
                          }}
                        >
                          <option value="">Select your company</option>
                          {cmpList.map((cmp) => (
                            <option key={cmp.value} value={cmp.value}>
                              {cmp.label}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    )}

                    <div
                      className="space-y-4"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <Form.Control
                        style={{
                          fontFamily: "Archivo",
                          backgroundColor: "white",
                          fontWeight: 400,
                          color: "grey",
                          fontSize: "1rem",
                        }}
                        className="w-full h-9 rounded-lg border-[#846CA6] "
                        placeholder="Your monthly income"
                        onChange={handleChange}
                        name="monthly_income"
                        value={formatIndianNumber(data.monthly_income)}
                        required
                      />
                      <Form.Control
                        style={{
                          fontFamily: "Archivo",
                          backgroundColor: "white",
                          fontWeight: 400,
                          color: "grey",
                          fontSize: "1rem",
                        }}
                        className="w-full h-9 rounded-lg border-[#846CA6] "
                        placeholder="Your monthly EMI"
                        onChange={handleChange}
                        name="monthly_emi"
                        value={formatIndianNumber(data.monthly_emi)}
                        required
                      />
                    </div>

                    <div
                      className="space-y-4"
                      style={{
                        backgroundColor: "transparent",
                        marginTop: "4%",
                      }}
                    >
                      <Form.Select
                        className="mb-4"
                        style={{
                          fontFamily: "Archivo",
                          backgroundColor: "white",
                          fontWeight: 400,
                          color: "grey",
                          fontSize: "1rem",
                        }}
                        value={data.loan_type}
                        name="loan_type" // ✅ Add name attribute
                        onChange={(e) => handleChange(e)}
                      >
                        <option value="">Select Loan Type</option>
                        <option value="hl">Housing Loan</option>
                        <option value="cl">Car Loan</option>
                        <option value="pl">Personal Loan</option>
                      </Form.Select>
                      <Form.Control
                        style={{
                          fontFamily: "Archivo",
                          backgroundColor: "white",
                          fontWeight: 400,
                          color: "grey",
                          fontSize: "1rem",
                        }}
                        className="w-full h-9 rounded-lg border-[#846CA6] "
                        placeholder="Loan Amount Required"
                        onChange={handleChange}
                        name="loan_amount"
                        value={formatIndianNumber(data.loan_amount)}
                        required
                      />
                    </div>
                  </div>
                  <div
                    className="space-y-4"
                    style={{
                      backgroundColor: "transparent",
                    }}
                  >
                    <div
                      className="flex items-start "
                      style={{
                        backgroundColor: "transparent",
                        gap: 4,
                      }}
                    >
                      <input
                        type="checkbox"
                        className="mt-1 w-5 h-5 rounded border-[#59266D]"
                        onChange={(e) => setCheck1(e.target.checked)}
                      />
                      <label
                        className="text-sm"
                        style={{
                          fontFamily: "Archivo",
                          backgroundColor: "transparent",
                        }}
                      >
                        {isExpanded2 ? text2 : truncatedText2}{" "}
                        <button
                          onClick={() => setIsExpanded2(!isExpanded2)}
                          className="text-blue-500 underline ml-1"
                        >
                          {isExpanded2 ? "Read Less" : "Read More"}
                        </button>
                      </label>
                    </div>
                    <div
                      className="flex items-start "
                      style={{
                        backgroundColor: "transparent",
                        gap: 4,
                      }}
                    >
                      <input
                        type="checkbox"
                        className="mt-1 w-5 h-5 rounded border-[#59266D]"
                        onChange={(e) => setCheck2(e.target.checked)}
                      />
                      <label
                        className="text-sm"
                        style={{
                          fontFamily: "Archivo",
                          backgroundColor: "transparent",
                        }}
                      >
                        {isExpanded ? text : truncatedText}{" "}
                        <button
                          onClick={() => setIsExpanded(!isExpanded)}
                          className="text-blue-500 underline ml-1"
                          style={{
                            backgroundColor: "transparent",
                          }}
                        >
                          {isExpanded ? "Read Less" : "Read More"}
                        </button>
                      </label>
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column align-items-center mt-3"
                    style={{
                      backgroundColor: "transparent",
                    }}
                  >
                    <p
                      // className="empowered-text"
                      style={{
                        fontWeight: 400,
                        fontFamily: "Archivo",
                        fontSize: 14,
                        color: "#1F1F1F",
                        marginBottom: 0,
                        backgroundColor: "transparent",
                        borderWidth: "1px solid red",
                      }}
                    >
                      Empowered By
                    </p>
                    <div
                      style={{
                        width: 134,
                        height: 40,
                        backgroundColor: "transparent",
                      }}
                    >
                      <img
                        src={experian}
                        alt="experian.webp"
                        style={{
                          width: "100%",
                          height: "100%",
                          backgroundColor: "transparent",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{
                      position: "relative",
                      top: 35,
                      backgroundColor: "transparent",
                    }}
                  >
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: "#EEA11E",
                        border: "none",
                        color: "#59266D",
                        fontWeight: 700,
                        fontFamily: "Archivo",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        margin: "auto",
                        backgroundColor: "#59266D",
                      }}
                      className={`px-8 py-2 text-lg font-bold transition-all w-[90%] ${
                        hover
                          ? "bg-[] text-white shadow-lg"
                          : "bg-[#59266D] text-white shadow-xl"
                      }`}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                    >
                      {loader ? (
                        <ColorRing
                          visible={true}
                          height="24"
                          width="24"
                          style={{ backgroundColor: "transparent" }}
                        />
                      ) : (
                        "Check Now"
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div
        className="boxes"
        style={{
          marginTop: 170,
          backgroundColor: "#E7E4F9",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="howitwork"
          style={{
            backgroundColor: "#59266D",
            paddingLeft: 201,
            paddingRight: 201,
            paddingTop: 10,
            paddingBottom: 17,
            fontFamily: "Archivo",
            fontWeight: 700,
            fontSize: 32,
            color: "#FFFFFF",
            borderRadius: 16,
            position: "relative",
            bottom: 42,
            alignSelf: "center",
          }}
        >
          How It Works:
        </div>
        <div
          className=" center-3"
          style={{
            paddingBottom: 76,
            display: "flex",
            gap: 44,
            flexWrap: "wrap",
            margin: "auto",
            backgroundColor: "transparent",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              backgroundColor: "transparent",
            }}
          >
            <div
              className="work1"
              style={{
                width: 166,
                height: 152,
                alignSelf: "center",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={img1}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  alignSelf: "center",
                  backgroundColor: "transparent",
                }}
              />
            </div>
            <div
              className="mobile-text"
              style={{
                fontFamily: "Archivo",
                fontWeight: 700,
                fontSize: 18,
                color: "#59266D",
                alignSelf: "center",
                backgroundColor: "transparent",
              }}
            >
              Share your name, the loan <br className="brtag" /> amount you’re
              looking for <br className="brtag" /> and exiting loans you have.
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              backgroundColor: "transparent",
            }}
          >
            <div
              className="work1"
              style={{
                width: 166,
                height: 152,
                alignSelf: "center",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={img3}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  backgroundColor: "transparent",
                }}
              />
            </div>
            <div
              className="mobile-text"
              style={{
                fontFamily: "Archivo",
                fontWeight: 700,
                fontSize: 18,
                color: "#59266D",
                alignSelf: "center",
                backgroundColor: "transparent",
              }}
            >
              System analyzes your <br className="brtag" />
              financial profile and
              <br className="brtag" /> recommends the right loan
              <br className="brtag" /> type.
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              backgroundColor: "transparent",
            }}
          >
            <div
              className="work1"
              style={{
                width: 166,
                height: 152,
                alignSelf: "center",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={img2}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  backgroundColor: "transparent",
                }}
              />
            </div>
            <div
              className="mobile-text"
              style={{
                fontFamily: "Archivo",
                fontWeight: 700,
                fontSize: 18,
                color: "#59266D",
                alignSelf: "center",
                backgroundColor: "transparent",
              }}
            >
              With personalized advice,
              <br className="brtag" /> reduce the risk of loan{" "}
              <br className="brtag" /> rejection and move closer to
              <br className="brtag" /> securing your funds.{" "}
            </div>
          </div>
        </div>
      </div>

      <TestimonialsSection />
      <div style={{ marginTop: 70 }}>
        <Modal
          size="lg"
          style={{ backgroundColor: "transparent" }}
          show={show}
          onHide={() => setShow(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <Modal.Body>
            <div className="row" id="otpCon">
              <div className="col-lg-6 px-0">
                <img src={img} style={{ objectFit: "cover" }} />
              </div>
              <div className="col-lg-6" id="otp-section">
                <div>
                  <p className="m-0 p-0 otp-text">Enter OTP</p>
                  <p className="m-0 p-0 otp-desc" style={{ marginTop: "3%" }}>
                    Please enter the one-time password (OTP) sent on the given
                    number - {data.mobile}
                  </p>
                </div>
                <div className="d-flex justify-content-between otp-input-con">
                  {otpField.map((digit, index) => (
                    <Form.Control
                      type="text"
                      className="otp-inputs"
                      key={index}
                      maxLength="1"
                      value={digit}
                      onChange={(e) => otpHandleChange(e.target, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      ref={(el) => (inputRefs.current[index] = el)}
                    />
                  ))}
                </div>
                <div className="otpBtn">
                  {!otpLoader ? (
                    <Button id="verify-btn" onClick={otpSubmit}>
                      Verify
                    </Button>
                  ) : (
                    <Button
                      id="verify-btn"
                      className="d-flex justify-content-center"
                    >
                      <ColorRing
                        visible={otpLoader}
                        height="30"
                        width="30"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{ backgroundColor: "transparent" }}
                        wrapperClass="color-ring-wrapper"
                        style={{ textAlign: "center" }}
                        colors={[
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                          "#ffffff",
                        ]}
                      />
                    </Button>
                  )}
                  <Button id="resend-otp" onClick={() => handleSubmit()}>
                    {" "}
                    Resend OTP
                  </Button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
};
export default MainForm;

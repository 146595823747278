
import { motion } from "framer-motion"
import { CheckCircle, Lock, Database } from "lucide-react"

export default function HowItWorksSection() {
  const steps = [
    {
      icon: <Lock className="h-8 w-8 md:h-12 md:w-12 text-white" />,
      title: "You enter your details securely",
      description: "Provide basic information about your finances and preferences. All data is encrypted and secure.",
    },
    {
      icon: <Database className="h-8 w-8 md:h-12 md:w-12 text-white" />,
      title: "Our AI analyzes eligibility based on latest bank criteria",
      description:
        "Our advanced algorithms cross-reference your profile with current bank requirements to determine eligibility.",
    },
    {
      icon: <CheckCircle className="h-8 w-8 md:h-12 md:w-12 text-white" />,
      title: "You get instant, accurate results",
      description: "Receive your eligibility status immediately with personalized recommendations and next steps.",
    },
  ]

  return (
    <section className="py-8 md:py-20 px-4 md:px-8 lg:px-16 bg-gradient-to-br from-[#311664] to-[#59266d] text-white">
      <div className="container max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-8 md:mb-16"
        >
          <h2 className="text-xl md:text-4xl font-bold mb-2 md:mb-4">How Minemi Works?</h2>
          <p className="text-sm md:text-lg opacity-90 max-w-2xl mx-auto">
            A simple process to check eligibility without affecting your credit score.
          </p>
        </motion.div>

        <div className="relative">
          {/* Connection line */}
          <div className="hidden md:block absolute left-1/2 top-0 bottom-0 w-1 bg-white/20 -translate-x-1/2 z-0"></div>

          <div className="space-y-4 md:space-y-16 relative z-10">
            {steps.map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center relative"
              >
                {/* Step number */}
                <div className="hidden md:flex absolute left-1/2 -translate-x-1/2 -translate-y-1/2 top-1/2 h-12 w-12 rounded-full bg-white text-[#311664] items-center justify-center font-bold text-xl">
                  {index + 1}
                </div>

                {/* Content - alternate sides */}
                <div className={`${index % 2 === 0 ? "md:text-right md:order-first" : "md:order-last"}`}>
                  <div className="mb-2 md:hidden flex items-center">
                    <div className="bg-white/10 rounded-lg p-3 mr-4">{step.icon}</div>
                    <span className="h-6 w-6 rounded-full bg-white text-[#311664] flex items-center justify-center font-bold text-sm">
                      {index + 1}
                    </span>
                  </div>

                  <div className="hidden md:block bg-white/10 p-4 rounded-lg inline-block mb-4">{step.icon}</div>

                  <h3 className="text-base md:text-xl font-semibold mb-1 md:mb-3">{step.title}</h3>
                  <p className="text-xs md:text-base opacity-80">{step.description}</p>
                </div>

                {/* Image/illustration (can be different based on index) */}
                <div
                  className={`${index % 2 === 0 ? "md:order-last" : "md:order-first"} flex items-center justify-center`}
                >
                  <div className="relative w-full max-w-[120px] md:max-w-[300px] h-[100px] md:h-[200px] overflow-hidden rounded-lg bg-white/5 p-2 md:p-4">
                    {index === 0 && (
                      <motion.div
                        className="w-full h-full flex flex-col space-y-3"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.6 }}
                      >
                        <div className="h-6 w-3/4 bg-white/20 rounded-md"></div>
                        <div className="h-6 w-full bg-white/20 rounded-md"></div>
                        <div className="h-6 w-2/3 bg-white/20 rounded-md"></div>
                        <div className="mt-auto h-10 w-1/2 mx-auto bg-white/30 rounded-md"></div>
                      </motion.div>
                    )}

                    {index === 1 && (
                      <motion.div
                        className="w-full h-full flex items-center justify-center"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.6 }}
                      >
                        <div className="relative h-24 w-24">
                          <div className="absolute inset-0 border-4 border-t-white/80 border-r-white/30 border-b-white/30 border-l-white/30 rounded-full animate-spin"></div>
                          <div className="absolute inset-4 bg-white/10 rounded-full flex items-center justify-center">
                            <div className="h-8 w-8 bg-white/30 rounded-md"></div>
                          </div>
                        </div>
                      </motion.div>
                    )}

                    {index === 2 && (
                      <motion.div
                        className="w-full h-full flex items-center justify-center"
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.6 }}
                      >
                        <div className="bg-white/10 rounded-xl p-6 w-full">
                          <div className="mb-4 flex items-center">
                            <CheckCircle className="h-8 w-8 text-green-300 mr-3" />
                            <div className="h-4 w-3/4 bg-white/30 rounded-sm"></div>
                          </div>
                          <div className="space-y-2">
                            <div className="h-3 w-full bg-white/20 rounded-sm"></div>
                            <div className="h-3 w-2/3 bg-white/20 rounded-sm"></div>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}


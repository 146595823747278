import React, { useContext, useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import "./Question3.css";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import logo from ".././assets/Images/mLogo.webp";
import { Helmet } from "react-helmet";
import MetadataContext from "../../MetadataContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "#F6F6F6",
  boxShadow: 24,
  paddingTop: 3,
  paddingBottom: 3,
  paddingLeft: 2,
  paddingRight: 2,
  border: 0,
};

const SelectInvestment = () => {
  const location = useLocation();
  const { category } = location.state || {}; // Get occupation from state
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [progress, setProgress] = useState(80);
  const [selectedValues, setSelectedValues] = useState([]);

  const categories = [
    { name: "House", maxValue: "House" },
    { name: "Car", maxValue: "Car" },
    { name: "Shares", maxValue: "Shares" },
    { name: "Mutual Funds", maxValue: "Mutual Funds" },
    { name: "Gold", maxValue: "Gold" },
    { name: "Other Property", maxValue: "Other Property" },
    { name: "No Assets", maxValue: "No Assets" },
  ];

  // Load saved value from localStorage
  useEffect(() => {
    const savedValue = localStorage.getItem("assets");
    if (savedValue) {
      const savedArray = savedValue.split(",");
      setSelectedValues(savedArray);
    } else {
      setSelectedValues(categories[0].maxValue);
      localStorage.setItem("assets", categories[0].maxValue);
    }
  }, []);

  const handleCategorySelect = (category) => {
    let updatedValues;

    if (category.maxValue === "No Assets") {
      updatedValues = ["No Assets"];
    } else {
      if (selectedValues.includes("No Assets")) {
        updatedValues = [category.maxValue];
      } else {
        if (selectedValues.includes(category.maxValue)) {
          updatedValues = selectedValues.filter(
            (value) => value !== category.maxValue
          );
        } else {
          updatedValues = [...selectedValues, category.maxValue];
        }
      }
    }

    setSelectedValues(updatedValues);
    localStorage.setItem("assets", updatedValues.join(","));
  };

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        "Do you have any assets or investments to use as collateral? - MinEmi",
      pageDescription:
        "Find out if you have assets or investments that can be used as collateral for a loan. This helps us provide the best loan recommendations tailored to your financial profile.",
      pageKeywords:
        "loan recommendation, loan collateral, assets as collateral, investment-backed loans, loan recommendation, secured loan, loan eligibility, financial assets, borrowing against investments, collateral assessment, loan options",

      canonicalUrl: "https://minemi.ai/select-assets-or-investments-to-use",
    });
  }, [setMetadata]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ height: "auto", backgroundColor: "#f8f8f8" }}>
      <div className="progress-bar-background" />
      <div className="progress-bar" style={{ width: `${progress}%` }} />

      <div className="container pt-4">
        <div
          className="container "
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 4%",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row", gap: 8 }}
            onClick={() => navigate("/select-existing-emi")}
          >
            <FaArrowLeftLong style={{ fontSize: 20, color: "#8A8A8A" }} />
            <p style={{ marginBottom: 0, fontSize: 16, color: "#8A8A8A" }}>
              Back
            </p>
          </div>
          <IoClose
            style={{ fontSize: 24, color: "#8A8A8A", cursor: "pointer" }}
            onClick={() => setOpen(true)}
          />
        </div>

        {/* Title */}
        <div className="question-title mt-4">
          Do you have any assets or investments to use as collateral?
        </div>

        {/* Buttons */}
        <div
          className=" mt-4 "
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 16,
            justifyContent: "center",
            margin: "auto",
            flexWrap: "wrap",
          }}
        >
          {categories.map((category, index) => (
            <div
              key={index}
              className={`category-button sm:w-full ${
                selectedValues.includes(category.maxValue) ? "active" : ""
              }`}
              onClick={() => handleCategorySelect(category)}
              style={{
                marginBottom: "2%",
                fontFamily: "Archivo",
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              {category.name}
            </div>
          ))}
        </div>

        {/* Next Button */}
        <div
          className="next-button"
          style={{ fontFamily: "Archivo" }}
          onClick={() => {
            const savedValue = localStorage.getItem("assets");
            if (savedValue === "") {
              alert("Please choose atleast one options");
            } else {
              navigate("/apply-with-co-applicant", { state: { category } });
            }
          }}
        >
          NEXT
        </div>
      </div>

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: 40, height: 40 }}>
              <img
                src={logo}
                style={{ width: "100%", height: "100%" }}
                alt=""
              />
            </div>
            <IoClose
              onClick={() => setOpen(false)}
              style={{
                alignSelf: "center",
                fontWeight: 400,
                fontSize: 24,
                cursor: "pointer",
              }}
            />
          </div>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              color: "#59266D",
              fontSize: 24,
              fontWeight: 700,
              marginTop: 16,
            }}
          >
            Wait ! your loan recommendation is just a step away !
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{
              color: "#8A8A8A",
              fontSize: 18,
              fontWeight: 400,
              fontFamily: "Archivo",
            }}
          >
            Complete the form to receive the best credit card tailored to your
            needs.
          </Typography>
          <div
            style={{
              backgroundColor: "#59266D",
              padding: 15,
              color: "#FFFFFF",
              borderRadius: 10,
              textAlign: "center",
              marginTop: "4%",
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 16,
              cursor: "pointer",
            }}
            onClick={() => setOpen(false)}
          >
            Keep Going
          </div>
          <div
            style={{
              backgroundColor: "#F6F6F6",
              padding: 15,
              color: "#59266D",
              borderRadius: 10,
              textAlign: "center",
              marginTop: "4%",
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 16,
              border: "1px solid #59266D",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            No Thanks
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SelectInvestment;

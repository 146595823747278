import React, { useState, useEffect } from "react";
import "./Testimonials.css";
import { Avatar } from "@mui/material";
import Carousel from "react-bootstrap/Carousel";

// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

const Testimonials = () => {
  //   const responsive = {
  //     superLargeDesktop: {
  //       // the naming can be any, depends on you.
  //       breakpoint: { max: 4000, min: 3000 },
  //       items: 1,
  //       slidesToSlide: 1,
  //     },
  //     desktop: {
  //       breakpoint: { max: 3000, min: 1024 },
  //       items: 1,
  //       slidesToSlide: 1,
  //     },
  //     tablet: {
  //       breakpoint: { max: 1024, min: 464 },
  //       items: 1,
  //       slidesToSlide: 1,
  //     },
  //     mobile: {
  //       breakpoint: { max: 464, min: 0 },
  //       items: 1,
  //       slidesToSlide: 1,
  //     },
  //   };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the screen width is less than 768px (mobile view)
    const handleResize = () => setIsMobile(window.innerWidth < 768);

    // Initial check on component mount
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div id="testimonials-box" className="bg-transparent">
        <div id="testimonials-page-container">
          <div id="testimonials-page-content" className="bg-transparent">
            <div className="bg-transparent">
              <div className="bg-transparent heading-box">
                <h4 className="testimonials-heading">
                  What Our Customers Say About MinEMI
                </h4>
              </div>
            </div>
            <div className="box-container w-100 pt-lg-5 bg-transparent">
              {isMobile ? (
                // Mobile View: Show carousel
                <Carousel
                  className="custom-carousel"
                  style={{
                    // border: "1px solid red",
                    backgroundColor: "inherit",
                  }}
                >
                  {/* Card Box One */}
                  <Carousel.Item
                    style={{
                      // border: "1px solid red",
                      backgroundColor: "transparent",
                    }}
                  >
                    <div id="card_box_one" className="card-box-container">
                      <div className="bg-transparent">
                        <div className="d-flex flex-row gap-3 align-items-center gap-md-4 bg-transparent">
                          <div className="bg-transparent">
                            <Avatar sx={{ width: "50px", height: "50px" }} />
                          </div>
                          <div className="bg-transparent">
                            <p className="name-text mb-0 bg-transparent">
                              Priya
                            </p>
                            <p className="date-text mb-0 bg-transparent">
                              Pune
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="bg-transparent">
                        <p className="experience-text bg-transparent">
                          Minemi helped me transfer my home loan to a lower
                          interest rate, and now I’m saving ₹7,000 every month!
                          The process was smooth and quick.
                        </p>
                      </div>
                    </div>
                  </Carousel.Item>

                  {/* Card Box Two */}
                  <Carousel.Item className="">
                    <div id="card_box_two" className="card-box-container ">
                      <div className="bg-transparent">
                        <div className=" bg-transparent d-flex flex-row gap-3 align-items-center gap-md-4">
                          <div className="bg-transparent">
                            <Avatar sx={{ width: "50px", height: "50px" }} />
                          </div>
                          <div className="bg-transparent">
                            <p className="name-text mb-0 bg-transparent">
                              Priyesh Shah
                            </p>
                            <p className="date-text mb-0 bg-transparent">
                              Bhopal
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="bg-transparent">
                        <p className="experience-text bg-transparent">
                          Minemi helped me transfer my home loan to a lower
                          interest rate, and now I’m saving ₹7,000 every month!
                          The process was smooth and quick.
                        </p>
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              ) : (
                // Desktop View: Show both cards side by side
                <div
                  className="d-flex justify-content-center bg-transparent"
                  style={{ gap: "50px" }}
                >
                  {/* Card Box One */}
                  <div id="card_box_one" className="card-box-container">
                    <div className="bg-transparent">
                      <div className="d-flex flex-row  align-items-center gap-md-4 bg-transparent">
                        <div className="bg-transparent">
                          <Avatar
                            sx={{
                              width: "70px",
                              height: "70px",
                            }}
                          />
                        </div>
                        <div className="bg-transparent">
                          <p className="name-text mb-0">Priya</p>
                          <p className="date-text mb-0">Pune</p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-transparent">
                      <p className="experience-text pt-md-4">
                        Minemi helped me transfer my home loan to a lower
                        interest rate, and now I’m saving ₹7,000 every month!
                        The process was smooth and quick.
                      </p>
                    </div>
                  </div>

                  {/* Card Box Two */}
                  <div id="card_box_two" className="card-box-container">
                    <div className="bg-transparent">
                      <div className="d-flex flex-row gap-3 align-items-center gap-md-4 bg-transparent">
                        <div className="bg-transparent">
                          <Avatar sx={{ width: "70px", height: "70px" }} />
                        </div>
                        <div className="bg-transparent">
                          <p className="name-text mb-0">Priyesh Shah</p>
                          <p className="date-text mb-0">Bhopal</p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-transparent">
                      <p className="experience-text pt-md-4">
                        Minemi helped me transfer my home loan to a lower
                        interest rate, and now I’m saving ₹7,000 every month!
                        The process was smooth and quick.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;

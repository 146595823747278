import React, { useEffect, useContext } from "react";
import { LuDot } from "react-icons/lu";

import tongueOut from "../../../Assets/Images/tongue-out.webp";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import MetadataContext from "../../MetadataContext";

const PLArticleOne = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle:
        "Personal Loan Prepayment and Foreclosure | Compare Bank Charges & Tips",
      pageDescription:
        "Discover how personal loan prepayment and foreclosure can save you money.Compare preclosure charges for HDFC, SBI, ICICI, and others. Learn about interest,tenure, and RBI guidelines.",
      pageKeywords:
        "Personal loan prepayment, Personal loan foreclosure, Loan prepayment charges, Loan closure charges, Foreclosure charges for personal loans, HDFC personal loan foreclosure charges, Loan EMI payment, Loan management tips, Prepayment tenure and interest, Early repayment of personal loans, Personal loan pre-closure benefits",
      canonicalUrl:
        "https://minemi.ai/loanpage/personalloan/personal-loan-prepayment-and-foreclosure",
    });
  }, [setMetadata]);
  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/personalLoan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Personal Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                Personal Loan Prepayment and Foreclosure
              </Link>{" "}
            </p>
          </div>
          <h1
            className="homeloan-heading"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "55px",
              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Personal Loan Prepayment and Foreclosure: A Guide to Early Loan
            Closure and Savings
          </h1>

          <p
            className="homeloan-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 18,
              color: "#74589B",
              backgroundColor: "#E7E4F9",
              lineHeight: "24px",
            }}
          >
            Discover everything about personal loan prepayment and foreclosure,
            including their benefits, costs, and impact on your finances. Make
            informed decisions with this comprehensive guide.
          </p>
        </div>

        <div style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}>
          <h2
            style={{
              marginTop: 35,
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 24,
              color: "#1F1F1F",
              lineHeight: 1.2,
            }}
          >
            Understanding Personal Loan Prepayment and Foreclosure: A
            Comprehensive Guide
          </h2>

          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            <strong>Personal loans </strong>provide quick access to funds for
            various needs, from medical emergencies to home renovations.
            However, managing these loans effectively can sa you significant
            money in the long run. <strong>Prepayment and foreclosure</strong>{" "}
            are two k strategies to reduce the interest burden.
          </p>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            In this guide, we will explore how{" "}
            <strong>personal loan prepayment and foreclosure</strong> work, the
            associated charges across top banks in India, the impact on your
            loan tenure, and smart tips to minimize your financial liabilities.
          </p>

          <div>
            <h4
              style={{
                color: "#1F1F1F",
                fontFamily: "'Archivo',sans-serif",
                fontSize: 24,
                lineHeight: 1.2,
                marginTop: 25,
              }}
            >
              1. Personal Loan Prepayment and Foreclosure:
            </h4>

            <div
              style={{
                marginTop: 15,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Prepayment:{" "}
                  </span>
                  This refers to repaying part of your personal loan before the
                  scheduled EMI dates. By reducing the principal balance early,
                  you effectively cut down on the future interest.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Foreclosure (Pre-closure):{" "}
                  </span>
                  Foreclosure means repaying the entire outstanding loan balance
                  before the end of the original tenure. It closes the loan
                  prematurely, helping you save on the interest that would
                  otherwise accrue over the remaining loan period.
                </div>
              </div>
            </div>
            <div className="pt-3">
              <p
                style={{
                  fontFamily: "Archivo",
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                  lineHeight: "24px",
                }}
              >
                <strong style={{ paddingRight: 5, fontSize: 18 }}>
                  Example:{" "}
                </strong>
                A ₹5 lakh loan for 5 years at 10.5% interest accrues total
                interest of approximately ₹1.45 lakh. If you foreclose the loan
                after 2 years by paying ₹3.2 lakh, you could save around ₹50,000
                in interest.
              </p>
            </div>
          </div>
          <div>
            <div>
              <h4
                style={{
                  fontSize: 24,
                  lineHeight: 1.2,
                  color: "#1F1F1F",
                  marginTop: 25,
                  fontFamily: "'Archivo',sans-serif",
                }}
              >
                2. How Tenure, Interest Rate, and Prepayment Affect Interest
                Payments
              </h4>
              <p
                style={{
                  fontSize: 16,
                  lineHeight: "20px",
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  marginTop: 15,
                }}
              >
                The loan tenure directly impacts the total interest outflow.
              </p>
            </div>

            <div
              style={{
                marginTop: 15,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Shorter Tenure:{" "}
                  </span>
                  Higher EMIs but significantly lower interest payments over the
                  loan's life.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Longer Tenure:{" "}
                  </span>
                  Lower EMIs but higher overall interest paid.
                </div>
              </div>
            </div>
            <div className="pt-3">
              <p
                style={{
                  fontFamily: "Archivo",
                  color: "#1F1F1F",
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "20px",
                  lineHeight: "24px",
                }}
              >
                <strong style={{ paddingRight: 5, fontSize: 18 }}>
                  Key Insight:{" "}
                </strong>
                Prepaying during the initial years of your loan tenure can lead
                to greater savings since interest is front-loaded (higher in the
                earlier months of repayment).
              </p>
            </div>
          </div>
          <div>
            <div>
              <h4
                style={{
                  fontSize: 24,
                  lineHeight: 1.2,
                  marginTop: 20,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                }}
              >
                3. Prepayment and Foreclosure Charges Across Top Indian Banks
              </h4>
              <p
                style={{
                  marginTop: 15,
                  fontSize: 16,
                  lineHeight: "20px",
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  fontWeight: 400,
                }}
              >
                Banks charge fees for prepayment and foreclosure to make up for
                lost interest income. Below is a table summarizing the charges
                of leading Indian banks:
              </p>
            </div>
            {/* table data */}
            <div className="mt-4">
              <h3
                style={{
                  marginTop: 20,
                  fontSize: 24,
                  lineHeight: 1.2,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                }}
              >
                Prepayment and Foreclosure Charges Across Top Indian Banks
              </h3>
              <p
                style={{
                  marginTop: 15,
                  fontSize: 16,

                  lineHeight: "20px",
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  fontWeight: 400,
                }}
              >
                Banks charge fees for prepayment and foreclosure to make up for
                lost interest income. Below is a table summarizing the charges
                of leading Indian banks:
              </p>
              <div
                id="bt-types-table"
                className="increase-margin"
                style={{
                  marginTop: "2%",
                  border: "1px solid #846CA6",
                  borderRadius: 18,
                  fontSize: 16,
                  lineHeight: "20px",
                  width: "100%",
                }}
              >
                <table
                  id="customers"
                  style={{
                    fontFamily: "Archivo",
                    borderCollapse: "collapse",
                    borderRadius: 18,
                    width: "100%",
                  }}
                >
                  <thead style={{ borderRadius: 18 }}>
                    <tr style={{ borderRadius: 18 }}>
                      <th
                        className="table-padding"
                        style={{
                          backgroundColor: "#E7E4F9",
                          paddingTop: "16px",
                          paddingBottom: "16px",
                          paddingLeft: "6px",
                          textAlign: "left",
                          borderTopLeftRadius: 18,
                          color: "#59266D",
                        }}
                      >
                        Bank
                      </th>
                      <th
                        className="table-padding"
                        style={{
                          borderLeft: "1px solid #D9D9D9",
                          paddingTop: "16px",
                          paddingBottom: "16px",
                          paddingLeft: "6px",
                          textAlign: "left",
                          backgroundColor: "#E7E4F9",
                          color: "#59266D",
                        }}
                      >
                        Foreclosure/Prepayment Charges
                      </th>
                      <th
                        className="table-padding"
                        style={{
                          borderLeft: "1px solid #D9D9D9",
                          paddingTop: "16px",
                          paddingBottom: "16px",
                          paddingLeft: "6px",
                          textAlign: "left",
                          backgroundColor: "#E7E4F9",
                          color: "#59266D",
                        }}
                      >
                        Processing Fees
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        Processing Fees
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        4% (13-24 months), 3% (25-36 months), 2% (after 36
                        months). No prepayment before 12 EMIs.
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Up to ₹4,999
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        State Bank of India
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        3% on the prepaid amount. No lock in period.
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Nil (Limited period offer till Jan 2024)
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        ICICI Bank
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        3% of the outstanding amount. Nil after 12 EMIs
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        2.50% of loan amount + GST
                      </td>
                    </tr>

                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        Axis Bank
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        2% of the principal outstanding.
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        1.5% – 2%
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        Kotak Mahindra Bank
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        5% of the outstanding principal.
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Up to 2.5% of the loan amount
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        IDFC First Bank
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        5% for up to 24 months.
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Up to 3.5%
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        IndusInd Bank
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        4% of the outstanding principal.
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Up to 2.5%
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        Yes Bank
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        2% of the outstanding amount plus GST.
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Up to 2.5%
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        Bank of Baroda
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        4% of the outstanding principal.
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        2%
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#f2f2f2" }}>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 700,
                        }}
                      >
                        Punjab National Bank
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        3% of the outstanding amount.
                      </td>
                      <td
                        className="table-padding"
                        style={{
                          border: "1px solid #ddd",
                          padding: "16px",
                          backgroundColor: "white",
                          fontFamily: "Archivo",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        1%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p
                className="pt-4"
                style={{
                  fontSize: 16,
                  lineHeight: "20px",
                  fontFamily: "'Archivo',sans-serif",
                  lineHeight: "24px",
                  fontWeight: 400,
                }}
              >
                <strong>Disclaimer:</strong>The charges and fees mentioned above
                are subject to change and may vary based on individual loan
                agreements. It is advisable to consult directly with the
                respective banks for the most accurate and updated information
                regarding personal loan prepayment and foreclosure terms.
              </p>
            </div>

            {/* minemi tip section */}
            <div
              className="tips mt-4 mb-3"
              style={{
                backgroundColor: "#e7e4f9",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
              }}
            >
              <div
                id="border_line"
                style={{
                  width: "100%",
                  height: "5px",
                  backgroundColor: "#59266d",
                  border: "1px solid #8a8a8a",
                  borderTop: "0px",
                  clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
                }}
              ></div>
              <div
                style={{ backgroundColor: "#e7e4f9" }}
                className="px-1 px-sm-4 py-4"
              >
                <div
                  className="px-3 py-2"
                  style={{ backgroundColor: "#e7e4f9" }}
                >
                  <div
                    style={{ backgroundColor: "#e7e4f9" }}
                    className="d-flex flex-row gap-2 align-items-center "
                  >
                    <img
                      src={tongueOut}
                      alt="minemi tip image"
                      style={{
                        width: 30,
                        height: 30,
                        backgroundColor: "#e7e4f9",
                      }}
                      loading="lazy"
                    />
                    <Typography
                      sx={{
                        fontSize: 16,
                        lineHeight: "20px",
                        lineHeight: "24px",
                        letterSpacing: "0.08px",
                        color: "#59266D",
                        // color: "yellow",
                        fontFamily: "'Archivo', sans-serif",
                        backgroundColor: "transparent",
                      }}
                    >
                      MinEmi Tip
                    </Typography>
                  </div>
                  <div className="mt-2">
                    <Typography
                      sx={{
                        fontSize: 16,
                        lineHeight: "20px",
                        lineHeight: "24px",
                        letterSpacing: "0.08px",
                        color: "#1F1F1F",
                        // color: "#F6F6F6",
                        backgroundColor: "#e7e4f9",

                        fontFamily: "'Archivo', sans-serif",
                      }}
                    >
                      Before prepaying or foreclosing a loan, calculate whether
                      the interest savings surpass the foreclosure charges. If
                      the difference is small, it may be better to continue with
                      regular EMIs.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h4
                style={{
                  marginTop: 20,
                  fontSize: 24,
                  lineHeight: 1.2,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                }}
              >
                4. Lock-in Period and Why It Matter
              </h4>
              <p
                style={{
                  marginTop: 15,
                  fontSize: 16,
                  lineHeight: "20px",
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  fontWeight: 400,
                }}
              >
                A lock-in period restricts borrowers from making prepayments
                within a certain period after disbursal.
              </p>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h2
                style={{
                  fontSize: 20,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                }}
              >
                Key Considerations:
              </h2>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Some banks impose a <strong>lock-in period of 12 EMIs</strong>{" "}
                  before allowing prepayment.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  After the lock-in period, prepayment charges gradually reduce
                  over time.
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: 15,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h2
                style={{
                  fontSize: 20,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                }}
              >
                Example:
              </h2>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <strong> HDFC Bank </strong>does not permit prepayment before
                  12 months.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <strong> ICICI Bank</strong> allows foreclosure without any
                  charges after 12 EMIs.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip section */}
          <div
            className="tips mt-5 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "20px",
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "20px",
                      lineHeight: "24px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Always check the lock-in period before signing the loan
                    agreement. This ensures flexibility in case you decide to
                    prepay earlier.
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <h4
                style={{
                  marginTop: 20,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: 24,
                  lineHeight: 1.2,
                }}
              >
                5. RBI Guidelines on Prepayment and Foreclosure
              </h4>
              <p
                style={{
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: 16,
                  lineHeight: "20px",
                  fontWeight: 400,
                  marginTop: 15,
                }}
              >
                The <strong>Reserve Bank of India (RBI)</strong> has introduced
                several guidelines to protect borrowers:
              </p>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Floating Rate Loans:{" "}
                  </span>
                  RBI mandates that lenders cannot charge foreclosure or
                  prepayment fees on floating rate personal loans taken by
                  individual borrowers.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Transparency:{" "}
                  </span>
                  Banks must clearly disclose prepayment charges during the loan
                  sanction process.
                </div>
              </div>
            </div>

            {/* minemi tip */}
            <div
              className="tips mt-4 mb-3"
              style={{
                backgroundColor: "#e7e4f9",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
              }}
            >
              <div
                id="border_line"
                style={{
                  width: "100%",
                  height: "5px",
                  backgroundColor: "#59266d",
                  border: "1px solid #8a8a8a",
                  borderTop: "0px",
                  clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
                }}
              ></div>
              <div
                style={{ backgroundColor: "#e7e4f9" }}
                className="px-1 px-sm-4 py-4"
              >
                <div
                  className="px-3 py-2"
                  style={{ backgroundColor: "#e7e4f9" }}
                >
                  <div
                    style={{ backgroundColor: "#e7e4f9" }}
                    className="d-flex flex-row gap-2 align-items-center "
                  >
                    <img
                      src={tongueOut}
                      alt="minemi tip image"
                      loading="lazy"
                      style={{
                        width: 30,
                        height: 30,
                        backgroundColor: "#e7e4f9",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: 16,
                        lineHeight: "20px",
                        lineHeight: "24px",
                        letterSpacing: "0.08px",
                        color: "#59266D",
                        // color: "yellow",
                        fontFamily: "'Archivo', sans-serif",
                        backgroundColor: "transparent",
                      }}
                    >
                      Expert Quote
                    </Typography>
                  </div>
                  <div className="mt-2 ">
                    <Typography
                      sx={{
                        fontSize: 16,
                        lineHeight: "20px",
                        lineHeight: "24px",
                        letterSpacing: "0.08px",
                        color: "#1F1F1F",
                        // color: "#F6F6F6",
                        backgroundColor: "#e7e4f9",

                        fontFamily: "'Archivo', sans-serif",
                      }}
                    >
                      For floating-rate loans, ensure that no bank applies
                      foreclosure charges, as this violat RBI policies.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h4
                style={{
                  marginTop: 20,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: 24,
                  lineHeight: 1.2,
                }}
              >
                6. How Prepayment and Foreclosure Impact Loan Managemen
              </h4>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Reduced Interest:{" "}
                  </span>
                  Prepayment lowers the outstanding principal, leading to
                  reduced interest in the following EMIs.
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Debt-Free Sooner:{" "}
                  </span>
                  Foreclosing a loan ensures quicker debt freedom.
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h4
                style={{
                  marginTop: 20,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: 24,
                  lineHeight: 1.2,
                }}
              >
                7. Additional Charges to Be Aware Of:
              </h4>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Processing Fees for Early Closure:{" "}
                  </span>
                  Some banks impose a separate processing fee to handle loan
                  pre-closure.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Administrative Costs:{" "}
                  </span>
                  Minor administrative fees may apply during loan foreclosure.
                </div>
              </div>
            </div>
          </div>

          {/* minemi tip */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              style={{ backgroundColor: "#e7e4f9" }}
              className="px-1 px-sm-4 py-4"
            >
              <div className="px-3 py-2" style={{ backgroundColor: "#e7e4f9" }}>
                <div
                  style={{ backgroundColor: "#e7e4f9" }}
                  className="d-flex flex-row gap-2 align-items-center "
                >
                  <img
                    src={tongueOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "20px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "transparent",
                    }}
                  >
                    Expert Quote
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "20px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    Ask for a breakup of the total charges to avoid hidden fees
                    that could impact your savings.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h4
                style={{
                  marginTop: 20,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: 24,
                  lineHeight: 1.2,
                }}
              >
                8. Online Prepayment and Foreclosure Process
              </h4>
              <p
                style={{
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: 16,
                  lineHeight: "20px",
                  fontWeight: 400,
                  marginTop: 15,
                }}
              >
                Many banks offer online options to{" "}
                <strong> initiate prepayment or foreclosure</strong> through n
                banking or mobile apps:
              </p>
            </div>

            <div
              style={{
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Net Banking:{" "}
                  </span>
                  Access the loan section and choose the prepayment option.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Loan Statements:{" "}
                  </span>
                  Download and monitor your loan balance and prepayment
                  eligibility.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Mobile Apps:{" "}
                  </span>
                  Some lenders allow prepayment directly through their apps
                  without visiting a branch.
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <h4
                style={{
                  marginTop: 20,
                  color: "#1F1F1F",
                  fontFamily: "'Archivo',sans-serif",
                  fontSize: 24,
                  lineHeight: 1.2,
                }}
              >
                9. Key Minemi Tips for Prepayment and Foreclosure
              </h4>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Prepay During Initial Years:{" "}
                  </span>
                  This is when interest is at its highest, maximizing your
                  savings.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Monitor Interest Rates:{" "}
                  </span>
                  Prepay when interest rates are high to reduce the loan burden
                  faster.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Negotiate Fees:{" "}
                  </span>
                  Request your lender to waive or reduce foreclosure charges,
                  especially if you are a long-term customer.
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    <LuDot />
                  </span>
                </div>
                <div
                  style={{
                    color: "#1F1F1F",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      color: "#1F1F1F",
                      fontWeight: 600,
                      fontSize: 16,
                      lineHeight: "20px",
                    }}
                  >
                    Use Bonuses or Windfalls:{" "}
                  </span>
                  Channel unexpected income (like bonuses) toward loan
                  prepayment to reduce long-term liabilities.
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 20,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 22,
            }}
          >
            Conclusion
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                fontWeight: 400,
                marginTop: "10px",
                lineHeight: "24px",
              }}
            >
              <strong> Personal loan prepayment and foreclosure</strong> are
              effective tools to reduce debt and sa on interest. While banks
              charge fees for early repayment, understanding the nuances can
              lead to significant financial benefits. By carefully evaluating
              lock-in pe prepayment penalties, and RBI guidelines, borrowers can
              optimize their loan management strategies.
            </p>
          </div>

          <div>
            <p
              style={{
                fontFamily: "'Archivo',sans-serif",
                fontSize: "16px",
                marginTop: "10px",
                lineHeight: "24px",
              }}
            >
              Explore Minemi’s loan management tools to calculate potential
              savings through prepayment and foreclosure. Take control of your
              loans today and pave the way for a debt-free future.
            </p>
          </div>

          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br className="display-none" /> and tailored just for you—apply
                now to see what you qualify for!
              </p>
              <div
                style={{
                  backgroundColor: "transparent",
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                }}
              >
                <a
                  href="https://minemi.ai/personal-loan/check-eligibility"
                  style={{
                    backgroundColor: "#59266D",
                    color: "white",
                    borderRadius: 8,
                    padding: 10,
                    paddingLeft: 50,
                    paddingRight: 50,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 16,
                    lineHeight: "20px",
                  }}
                >
                  Check Eligiblity Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PLArticleOne;

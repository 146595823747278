import React from "react";
import { useEffect, useState } from "react";
import { TrendingUp, BarChart, Search } from "lucide-react";
import LoanFilterResults from "./LoanFilterResults";

export default function LoanFilter() {
  const [securityType, setSecurityType] = useState("shares");
  const [loanAmount, setLoanAmount] = useState("");
  const [showResults, setShowResults] = useState(false);

  const handleSecurityTypeChange = (type) => {
    setSecurityType(type);
  };

  const handleLoanAmountChange = (e) => {
    setLoanAmount(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowResults(true);
  };

  useEffect(() => {
    if (showResults) {
      const element = document.getElementById("loan_filter_results");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [showResults]);

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-2xl font-bold text-[#311664] mb-6 text-center">
        Find the Best Loan Against Securities for You
      </h2>

      <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
            <div>
              <label className="block text-sm font-medium text-[#26292b] mb-2">
                Type of Security
              </label>
              <div className="grid grid-cols-2 gap-2 sm:gap-4">
                <div className="relative ">
                  <input
                    type="radio"
                    name="securityType"
                    id="shares"
                    value="shares"
                    className="peer sr-only"
                    checked={securityType === "shares"}
                    onChange={() => handleSecurityTypeChange("shares")}
                  />
                  <label
                    htmlFor="shares"
                    className="flex items-center justify-center p-2 px-sm-5 border-2 rounded-md cursor-pointer border-gray-200 hover:border-[#59266d] peer-checked:border-[#59266d] peer-checked:bg-[#ebe8fc] text-sm sm:text-base "
                  >
                    <TrendingUp className="h-4 w-4 sm:h-5 sm:w-5 mr-1 sm:mr-2 text-[#59266d]" />
                    <span>Shares</span>
                  </label>
                </div>
                <div className="relative">
                  <input
                    type="radio"
                    name="securityType"
                    id="mutualFunds"
                    value="mutualFunds"
                    className="peer sr-only"
                    checked={securityType === "mutualFunds"}
                    onChange={() => handleSecurityTypeChange("mutualFunds")}
                  />
                  <label
                    htmlFor="mutualFunds"
                    className="flex items-center justify-center p-2 px-sm-4  border-2 rounded-md cursor-pointer border-gray-200 hover:border-[#3a868b] peer-checked:border-[#3a868b] peer-checked:bg-[#ebe8fc] text-sm sm:text-base"
                  >
                    <BarChart className="h-4 w-4 sm:h-5 sm:w-5 mr-1 sm:mr-2 text-[#3a868b]" />
                    <span>Mutual Funds</span>
                  </label>
                </div>
              </div>
            </div>

            <div>
              <label
                htmlFor="loanAmount"
                className="block text-sm font-medium text-[#26292b] mb-2"
              >
                Loan Amount (₹)
              </label>
              <select
                id="loanAmount"
                className="w-full p-2 sm:p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#59266d] text-sm sm:text-base"
                value={loanAmount}
                onChange={handleLoanAmountChange}
              >
                <option value="">Select loan amount</option>
                <option value="500000">Up to ₹5 Lakhs</option>
                <option value="1000000">₹5 Lakhs - ₹10 Lakhs</option>
                <option value="2000000">₹10 Lakhs - ₹20 Lakhs</option>
                <option value="5000000">₹20 Lakhs - ₹50 Lakhs</option>
                <option value="10000000">₹50 Lakhs - ₹1 Crore</option>
                <option value="20000000">Above ₹1 Crore</option>
              </select>
            </div>
          </div>

          <div className="mt-4 sm:mt-6 flex justify-center">
            <button
              type="submit"
              className="rounded-md bg-[#311664] px-4 sm:px-8 py-2 sm:py-3 font-medium text-white hover:bg-[#311664]/90 flex items-center text-sm sm:text-base w-full sm:w-auto justify-center"
            >
              <Search className="h-4 w-4 sm:h-5 sm:w-5 mr-1 sm:mr-2" />
              Find Best Options
            </button>
          </div>

          <div className="mt-4 sm:mt-6 text-center text-xs sm:text-sm text-gray-500">
            <p>
              We'll show you the best lenders based on your requirements and
              portfolio value.
            </p>
          </div>
        </form>
      </div>

      {/* Results Section */}
      {showResults && (
        <div id="loan_filter_results" className="mt-6 sm:mt-8">
          <LoanFilterResults
            securityType={securityType}
            loanAmount={loanAmount}
          />
        </div>
      )}
    </div>
  );
}

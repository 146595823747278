import React, { useState, useEffect } from "react";
import "./Insights.css";
import { useLocation, useNavigate } from "react-router-dom";

const Insights = () => {
  const location = useLocation();
  const pRoute = location.state;
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  useEffect(() => {
    const isFid = sessionStorage.getItem("fId");
    if (isFid !== null) {
      const jsonData = localStorage.getItem("eligibleData");
      const eligibleData = JSON.parse(jsonData);
      if (eligibleData.data.alerts.length < 1) {
        navigate("/", { replace: true });
      }
      setData(eligibleData.data.alerts);
    }
  }, []);
  let count = 0;

  return (
    <>
      <div id="insights">
        <div className="headerCon">
          <div className="header m-0 p-0 bg-transparent">
            <span className="bg-transparent">MinEMI Expert</span>
            <img
              src="images/magnify.png"
              alt="insights-logo"
              className="magnify-logo bg-transparent"
            />
            <span className="bg-transparent">signts</span>
          </div>
        </div>
        <div id="insights-content">
          <div className="w-100 bg-transparent">
            {data.map((item, index) => {
              if (item.status === 1) {
                count++;
                return (
                  <div className="insights-block bg-transparent" key={index}>
                    <div className="position-relative bg-transparent">
                      <img
                        className="bg-transparent"
                        src="images/em.png"
                        alt="block1"
                      />
                      <p className="p-0 m-0 img-no bg-transparent">{count}</p>
                    </div>

                    <div className="d-flex justify-content-center align-items-center w-100 bg-transparent">
                      <p className="mb-0 bg-transparent">{item.text}</p>
                    </div>
                  </div>
                );
              }
              return null;
            })}
            <p className="text bg-transparent">
              To unlock benefits of MinEMI Insights
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Insights;


import { useState } from "react"
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react"
import { Typography } from "@mui/material"


const faqData = [
  {
    question: "How does Minemi's loan comparison work?",
    answer:
      "Minemi compares personal loans from top Indian banks & NBFCs in real-time, helping you find the best interest rates, EMI options, and loan terms suited to your financial profile. Our AI-powered platform analyzes your inputs and matches you with the most suitable loan offers, saving you time and effort in your search for the perfect personal loan.",
  },
  {
    question: "Will checking loan offers on Minemi affect my credit score?",
    answer:
      "No, comparing loan offers on Minemi does not impact your credit score. We fetch pre-approved offers without a hard credit inquiry. This means you can explore multiple loan options freely and make an informed decision without worrying about any negative impact on your creditworthiness.",
  },
  {
    question: "What factors should I consider before choosing a personal loan?",
    answer:
      "When selecting a personal loan, consider these key factors: 1) Interest rate - lower rates mean less overall cost, 2) Processing fees - one-time charges that can affect the total loan cost, 3) Loan tenure - affects your EMI amount and total interest paid, 4) Prepayment options - flexibility to repay early without penalties, and 5) EMI affordability - ensure the monthly payment fits your budget. Minemi helps you compare these aspects across different lenders to find the best fit for your financial situation.",
  },
  {
    question: "Which banks and NBFCs does Minemi compare?",
    answer:
      "Minemi compares personal loans from leading Indian financial institutions including HDFC Bank, ICICI Bank, State Bank of India (SBI), Axis Bank, Kotak Mahindra Bank, Bajaj Finserv, and many more. We continuously expand our network to ensure you have access to a wide range of loan options from reputable lenders across India.",
  },
  {
    question: "Can I apply for a loan directly through Minemi?",
    answer:
      "While you can't apply for loans directly through Minemi, we simplify the process by helping you compare and choose the best loan offer. Once you've selected a loan, we redirect you to the lender's official platform for a secure and hassle-free application process. This ensures that your personal and financial information is handled directly by the bank or NBFC you've chosen.",
  },
]


const FAQItem = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="border-b border-gray-200 py-4" style={{backgroundColor:'transparent'}}>
      <button className="flex justify-between items-center w-full text-left" onClick={() => setIsOpen(!isOpen)} style={{backgroundColor:'transparent'}}>
        <span className="text-lg font-medium text-gray-900" style={{backgroundColor:'transparent'}}>{item.question}</span>
        {isOpen ? (
          <ChevronUpIcon className="w-5 h-5 text-gray-500" style={{backgroundColor:'transparent'}}/>
        ) : (
          <ChevronDownIcon className="w-5 h-5 text-gray-500"  style={{backgroundColor:'transparent'}}/>
        )}
      </button>
      {isOpen && (
        <div className="mt-2 pr-12" style={{backgroundColor:'transparent'}}>
          <p className="text-base text-gray-500" style={{backgroundColor:'transparent'}}>{item.answer}</p>
        </div>
      )}
    </div>
  )
}

const FAQ = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8" style={{backgroundColor:'transparent',marginTop:'4%'}}>
 <h2 className="text-3xl"

            style={{ color: '#311664', fontWeight: "bold", mb: 4,backgroundColor:'transparent',textAlign:'center' }}
          >
            Got Questions? We've Got Answers!
          </h2>    
            <div className="space-y-2" style={{backgroundColor:'transparent'}}>
        {faqData.map((item, index) => (
          <FAQItem key={index} item={item} style={{backgroundColor:'transparent'}} />
        ))}
      </div>
    </div>
  )
}

export default FAQ


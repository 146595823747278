
import { ArrowRight } from "lucide-react"
import { useNavigate } from "react-router-dom"
import img from '../../Assets/Images/20945436.jpg'


export default function HeroSection() {
    const navigate=useNavigate()
  const scrollToForm = () => {

    const formElement = document.getElementById("form-section")
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <section className="w-full bg-gradient-to-r from-[#311664] to-[#59266d] text-white">
      <div className="container mx-auto px-4 py-20 md:py-24 lg:py-24 flex flex-col lg:flex-row items-center justify-between">
        <div className="lg:w-1/2 space-y-6 md:space-y-6 mb-6 lg:mb-0">
          <h1 className="text-4xl md:text-4xl lg:text-5xl font-bold leading-tight ">
          Check Your Credit Card Approval Chances Instantly
          </h1>
          <p className=" mb-8 text-xl md:text-xl text-[#ebe8fc] leading-tight">
          Find out if you qualify for a credit card before applying – without affecting your credit score!
          </p>
          <button
            onClick={scrollToForm}
            size="lg"
            style={{gap:2}}
            className="bg-[#3a868b] hover:bg-[#3a868b]/90 text-white px-6 py-2 md:px-8 md:py-6 text-base md:text-lg rounded-lg shadow-lg flex items-center  transform hover:scale-105 transition-all vi-tight"
          >
            Check My Chances Now <ArrowRight className="ml-2 h-5 w-5" />
          </button>
        </div>
        <div className="hidden lg:flex lg:w-1/2 justify-center">
          <div className="relative w-full max-w-xs md:max-w-md">
            <div className="absolute -top-2 -left-2 md:-top-4 md:-left-4 w-full h-full bg-[#3a868b]/20 rounded-2xl"></div>
            <div className="relative bg-white p-4 md:p-6 rounded-2xl shadow-xl">
              <img
                src={img}
                width={400}
                height={400}
                alt="Illustration of a person with an approved loan application"
                className="w-full h-auto rounded-lg"
                priority
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


import React from "react";
import Serve from "./Serve/Serve.jsx";
import Types from "./Types/Types.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomeLoanEligibility from "./HomeLoanEligibility/HomeLoanEligibility.jsx";

const HomeLoanEligibilityForm = () => {
  return (
    <>
      {/* <Navbar /> */}
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <HomeLoanEligibility />
        <Serve />
        <Types />
        {/* <Footer /> */}
        <ToastContainer />
      </div>
    </>
  );
};

export default HomeLoanEligibilityForm;

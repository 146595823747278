import CheckRateform from "./CheckRateform";
import ComparisonSection from "./ComparisonSection";
import FaqSection from "./FaqSection";
import HeroSection from "./HeroSection";
import HowMinemiWorks from "./HowMinemiWorks";
import TestimonialsSection from "./TestimonialsSection";
import WhyCheckRate from "./WhyCheckRate";

export default function CheckRate() {
  return (
    <div className="min-h-screen" style={{ backgroundColor: "#f8f8f8" }}>
      <HeroSection />
      <CheckRateform />
      <WhyCheckRate />
      <HowMinemiWorks />
      <ComparisonSection />
      <TestimonialsSection />
      <FaqSection />
    </div>
  );
}

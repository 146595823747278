import React from "react";
import "./index.css";
import dualfiles from '../../assets/Images/dual-files.webp'
import reducepayment from '../../assets/Images/reduce-payment.webp'
import cashpayment from '../../assets/Images/cash-payment.webp'
import nohandshake from '../../assets/Images/no-handshake.webp'
import incomegrowth from '../../assets/Images/income-growth.webp'
import secureshield from '../../assets/Images/secure-shield.webp'

const Index = () => {
  return (
    <>
      <div id="approval">
        <div className="titleCon">
          <p className="title m-0 p-0">Our recommendation regarding your loan approval</p>
        </div>

        <div className="contentCon">
            <div className="row m-0 p-0 " style={{backgroundColor:'transparent'}}>
                <div className="col-12 col-md-6 mb-3 ps-0 pe-0 pe-md-2 " style={{backgroundColor:'transparent'}}>
                    <div className="approval-con">
                        <div className="approval-header d-flex justify-content-between" style={{backgroundColor:'transparent'}}>
                            <p className="approval-header-title" style={{backgroundColor:'transparent'}}>1. Loan Consolidation</p>
                            <img src={dualfiles} alt="dual-files" className="approval-header-img" style={{backgroundColor:'transparent'}}/>
                        </div>
                        <div className="ps-3" style={{backgroundColor:'transparent'}}>
                            <div className="approval-header-text" style={{backgroundColor:'transparent'}}><div className="approval-dots" style={{backgroundColor:'transparent'}}></div> <span style={{backgroundColor:'transparent'}}>Combine high-interest unsecured loans into one.</span></div>
                            <div className="approval-header-text" style={{backgroundColor:'transparent'}}><div className="approval-dots" style={{backgroundColor:'transparent'}}></div> <span style={{backgroundColor:'transparent'}}>Opt for lower-interest, longer-tenure loans.</span></div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-3 ps-0 pe-0 pe-md-2" style={{backgroundColor:'transparent'}}>
                    <div className="approval-con">
                        <div className="approval-header d-flex justify-content-between" style={{backgroundColor:'transparent'}}>
                            <p className="approval-header-title" style={{backgroundColor:'transparent'}}>2. Reduce Credit Card Utilization</p>
                            <img src={reducepayment} alt="reduce-payment" className="approval-header-img" style={{backgroundColor:'transparent'}}/>
                        </div>
                        <div className="ps-3" style={{backgroundColor:'transparent'}}>
                            <div className="approval-header-text" style={{backgroundColor:'transparent'}}><div className="approval-dots" style={{backgroundColor:'transparent'}}></div> <span style={{backgroundColor:'transparent'}}>Pay down credit card balances to below 30%.</span></div>
                            <div className="approval-header-text" style={{backgroundColor:'transparent'}}><div className="approval-dots" style={{backgroundColor:'transparent'}}></div> <span style={{backgroundColor:'transparent'}}>Focus on high-balance cards first.</span></div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6 mb-3 ps-0 pe-0 pe-md-2 " style={{backgroundColor:'transparent'}}>
                    <div className="approval-con">
                        <div className="approval-header d-flex justify-content-between" style={{backgroundColor:'transparent'}}>
                            <p className="approval-header-title" style={{backgroundColor:'transparent'}}>3. Avoid New Credit Applications</p>
                            <img src={nohandshake} alt="no-handshake" className="approval-header-img" style={{backgroundColor:'transparent'}}/>
                        </div>
                        <div className="ps-3" style={{backgroundColor:'transparent'}}>
                            <div className="approval-header-text" style={{backgroundColor:'transparent'}}><div className="approval-dots" style={{backgroundColor:'transparent'}}></div> <span style={{backgroundColor:'transparent'}}>Refrain from applying for new loans or cards.</span></div>
                            <div className="approval-header-text" style={{backgroundColor:'transparent'}}><div className="approval-dots"style={{backgroundColor:'transparent'}}></div> <span style={{backgroundColor:'transparent'}}>Each inquiry impacts approval chances.</span></div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-3 ps-0 pe-0 pe-md-2" style={{backgroundColor:'transparent'}}>
                    <div className="approval-con" >
                        <div className="approval-header d-flex justify-content-between" style={{backgroundColor:'transparent'}}>
                            <p className="approval-header-title" style={{backgroundColor:'transparent'}}>4. Increase Income or Lower FOIR</p>
                            <img src={incomegrowth} alt="income-growth" className="approval-header-img" style={{backgroundColor:'transparent'}}/>
                        </div>
                        <div className="ps-3" style={{backgroundColor:'transparent'}}>
                            <div className="approval-header-text" style={{backgroundColor:'transparent'}}><div className="approval-dots" style={{backgroundColor:'transparent'}}></div> <span style={{backgroundColor:'transparent'}}>Explore ways to boost monthly income.</span></div>
                            <div className="approval-header-text" style={{backgroundColor:'transparent'}}><div className="approval-dots" style={{backgroundColor:'transparent'}}></div> <span style={{backgroundColor:'transparent'}}>Cut unnecessary expenses to improve FOIR.</span></div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-3 ps-0 pe-0 pe-md-2 " style={{backgroundColor:'transparent'}}>
                    <div className="approval-con">
                        <div className="approval-header d-flex justify-content-between" style={{backgroundColor:'transparent'}}>
                            <p className="approval-header-title" style={{backgroundColor:'transparent'}}>5. Consider Secured Loans</p>
                            <img src={secureshield} alt="secure-shield" className="approval-header-img" style={{backgroundColor:'transparent'}}/>
                        </div>
                        <div className="ps-3" style={{backgroundColor:'transparent'}}>
                            <div className="approval-header-text" style={{backgroundColor:'transparent'}}><div className="approval-dots" style={{backgroundColor:'transparent'}}></div> <span style={{backgroundColor:'transparent'}}>Shift some unsecured debt to secured loans.</span></div>
                            <div className="approval-header-text" style={{backgroundColor:'transparent'}}><div className="approval-dots" style={{backgroundColor:'transparent'}}></div> <span style={{backgroundColor:'transparent'}}>Secured loans often have lower interest rates</span></div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 mb-3 ps-0 pe-0 pe-md-2 " style={{backgroundColor:'transparent'}}>
                    <div className="approval-con">
                        <div className="approval-header d-flex justify-content-between" style={{backgroundColor:'transparent'}}>
                            <p className="approval-header-title" style={{backgroundColor:'transparent'}}>6. Maintain Timely Payments</p>
                            <img src={cashpayment} alt="cash-payment" className="approval-header-img" style={{backgroundColor:'transparent'}}/>
                        </div>
                        <div className="ps-3" style={{backgroundColor:'transparent'}}>
                            <div className="approval-header-text" style={{backgroundColor:'transparent'}}><div className="approval-dots" style={{backgroundColor:'transparent'}}></div> <span style={{backgroundColor:'transparent'}}>Keep all payments on time to reinforce good credit behavior.</span></div>
                            <div className="approval-header-text" style={{backgroundColor:'transparent'}}> <div className="approval-dots" style={{backgroundColor:'transparent'}}></div> <span style={{backgroundColor:'transparent'}}>Avoid late payments to support creditworthiness.</span></div>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>
      </div>
    </>
  );
};

export default Index;

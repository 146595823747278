
import { motion } from "framer-motion"
import { UserCircle, BarChart, Clock, ThumbsUp } from "lucide-react"

export default function HowMinemiWorks() {
  const steps = [
    {
      icon: <UserCircle className="h-5 w-5 text-white" />,
      title: "You Enter Your Details",
      description: "Tell us about your income, credit score, and loan requirements.",
    },
    {
      icon: <BarChart className="h-5 w-5 text-white" />,
      title: "We Analyze Your Profile",
      description: "Our AI-powered system evaluates key factors.",
    },
    {
      icon: <Clock className="h-5 w-5 text-white" />,
      title: "You Get Your Rate Instantly",
      description: "See the best loan rate tailored for you.",
    },
    {
      icon: <ThumbsUp className="h-5 w-5 text-white" />,
      title: "Apply with Confidence",
      description: "Apply for loans with high approval chances.",
    },
  ]

  return (
    <section className="py-10 md:py-16 bg-gradient-to-br from-[#311664] to-[#59266d] text-white">
      <div className="container mx-auto px-6">
        <div className="text-center mb-8">
          <h2 className="text-xl md:text-2xl font-bold mb-2">How Does Minemi Calculate Your Loan Rate?</h2>
          <div className="w-16 h-0.5 bg-[#3a868b] mx-auto"></div>
        </div>

        <div className="grid grid-cols-1  max-w-xl mx-auto" style={{gap:8}}>
          {steps.map((step, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 5 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2, delay: index * 0.1 }}
              viewport={{ once: true, margin: "-50px" }}
              className="flex items-start  p-4 bg-white/10 rounded-lg"
              style={{gap:8}}
            >
              <div className="flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-[#3a868b] text-white font-bold text-sm">
                {index + 1}
              </div>
              <div>
                <div className="flex items-center mb-1" style={{gap:4}}>
                  {step.icon}
                  <div className="text-base font-semibold">{step.title}</div>
                </div>
                <p className="text-sm text-[#ebe8fc]">{step.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}


import React from "react";
import HeroSectionPage from "./HeroSectionPage";
import { EmiTopupSection } from "./EmiTopupSection";
import { AskedQuestions } from "./AskedQuestions";
import LoanConsolidate from "./LoanConsolidate";
import WhyChooseMinemi from "./WhyChooseMinemi";
export default function ConsolidateEmiSection() {
  return (
    <>
      <HeroSectionPage />
      <EmiTopupSection />
      <LoanConsolidate />
      <AskedQuestions />
      <WhyChooseMinemi />
    </>
  );
}

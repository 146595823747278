import { useEffect, useState, useMemo } from "react";
// import { Helmet } from "react-helmet";
export function LoanStatusChecker({ name }) {
  const [data, setData] = useState({}); // Initialize with an empty object

  const kotakData = useMemo(
    () => [
      {
        h2: "  Check Your Kotak Bank Personal Loan Status",
        para: "     Get instant updates on your loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10" />
            <path d="m9 12 2 2 4-4" />
          </svg>
        ),
        h3: " Real-Time Loan Status Tracker",
        para2:
          "   Our loan experts are ready to help you check your Kotak Mahindra Bank personal loan status and provide guidance on next steps for faster approval.",
        text: "  Contact our support team",
      },
    ],
    []
  );
  const bobData = useMemo(
    () => [
      {
        h2: "  Check Your Bank of Baroda Personal Loan Status",
        para: "     Get instant updates on your loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: " Personal Assistance for Loan Status",
        para2:
          "   Our loan experts are available to help you check your Bank of Baroda personal loan status and guide you through the next steps.",
        text: "  Contact our support team",
      },
    ],
    []
  );
  const idfcData = useMemo(
    () => [
      {
        h2: " Track Your Aditya Birla Finance Personal Loan Status",
        para: "  Get instant updates on your loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Real-Time Loan Status Tracker",
        para2:
          "   Our loan experts are ready to help you check your Aditya Birla Finance personal loan status and provide guidance on next steps for faster approval.",
        text: "  Contact our support team",
      },
    ],
    []
  );
  const hdfcData = useMemo(
    () => [
      {
        h2: " Check Your HDFC Bank Personal Loan Status",
        para: " Get instant updates on your loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Loan Status",
        para2:
          "  Our loan experts are available to help you check your HDFC Bank personal loan status and guide you through the next steps.",
        text: " Contact our support team",
      },
    ],
    []
  );
  const sbiData = useMemo(
    () => [
      {
        h2: " Check Your State Bank of India Personal Loan Status",
        para: " Get instant updates on your loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Loan Status",
        para2:
          "  Our loan experts are available to help you check your SBI personal loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const axisData = useMemo(
    () => [
      {
        h2: "Check Your Axis Bank Personal Loan Status",
        para: "Get instant updates on your loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Loan Status",
        para2:
          " Our loan experts are available to help you check your Axis Bank personal loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const iciciData = useMemo(
    () => [
      {
        h2: "Check Your ICICI Bank Personal Loan Status",
        para: "Get instant updates on your loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Loan Status",
        para2:
          " Our loan experts are available to help you check your ICICI Bank personal loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const punjabData = useMemo(
    () => [
      {
        h2: "Check Your Punjab National Bank Personal Loan Status",
        para: "Get instant updates on your loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Loan Status",
        para2:
          "Our loan experts are available to help you check your Punjab National Bank personal loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const induslndData = useMemo(
    () => [
      {
        h2: "Check Your IndusInd Bank Personal Loan Status",
        para: "Get instant updates on your loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Loan Status",
        para2:
          "Our loan experts are available to help you check your IndusInd Bank personal loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const tataData = useMemo(
    () => [
      {
        h2: "Check Your Tata Capital Personal Loan Status",
        para: "Get instant updates on your loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Loan Status",
        para2:
          "Our loan experts are available to help you check your Tata Capital personal loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const yesData = useMemo(
    () => [
      {
        h2: "Check Your Yes Bank Personal Loan Status",
        para: "Get instant updates on your loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Loan Status",
        para2:
          "Our loan experts are available to help you check your Yes Bank personal loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const unionData = useMemo(
    () => [
      {
        h2: "Check Your Union Bank Personal Loan Status",
        para: "Get instant updates on your loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Loan Status",
        para2:
          "Our loan experts are available to help you check your Union Bank personal loan status and guide you through the next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const bajajData = useMemo(
    () => [
      {
        h2: "Track Your Bajaj Finance Personal Loan Status",
        para: "Get instant updates on your loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Loan Status",
        para2:
          "Our loan specialists are ready to help you check your Bajaj Finance personal loan status and provide guidance on next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const federalData = useMemo(
    () => [
      {
        h2: "Track Your Federal Bank Personal Loan Status",
        para: "Get instant updates on your loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Loan Status",
        para2:
          "Our loan specialists are ready to help you check your Federal Bank personal loan status and provide guidance on next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const canaraData = useMemo(
    () => [
      {
        h2: "Track Your Canara Bank Personal Loan Status",
        para: "Get instant updates on your loan application status",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Personal Assistance for Loan Status",
        para2:
          "Our loan specialists are ready to help you check your Canara Bank personal loan status and provide guidance on next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const boiData = useMemo(
    () => [
      {
        h2: "Track Your Bank of India Personal Loan Status",
        para: "Get real-time updates on your loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Expert Loan Status Assistance",
        para2:
          "Our loan specialists are ready to help you check your Bank of India personal loan status and provide guidance on next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );
  const rblData = useMemo(
    () => [
      {
        h2: "Track Your RBL Bank Personal Loan Status",
        para: "Get real-time updates on your loan application progress",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mx-auto mb-4 text-primary-purple bg-transparent"
          >
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        ),
        h3: "Expert Loan Status Assistance",
        para2:
          "Our loan specialists are ready to help you check your RBL Bank personal loan status and provide guidance on next steps.",
        text: "Contact our support team",
      },
    ],
    []
  );

  useEffect(() => {
    if (name === "checkkotakbankstatus") {
      setData(kotakData[0]);
    } else if (name === "checkbobbankstatus") {
      setData(bobData[0]);
    } else if (name === "checkidfcbankstatus") {
      setData(idfcData[0]);
    } else if (name === "checkhdfcbankstatus") {
      setData(hdfcData[0]);
    } else if (name === "checksbibankstatus") {
      setData(sbiData[0]);
    } else if (name === "checkaxisbankstatus") {
      setData(axisData[0]);
    } else if (name === "checkicicibankstatus") {
      setData(iciciData[0]);
    } else if (name === "checkpunjabbankstatus") {
      setData(punjabData[0]);
    } else if (name === "checkinduslndbankstatus") {
      setData(induslndData[0]);
    } else if (name === "checktatabankstatus") {
      setData(tataData[0]);
    } else if (name === "checkyesbankstatus") {
      setData(yesData[0]);
    } else if (name === "checkunionbankstatus") {
      setData(unionData[0]);
    } else if (name === "checkbajajbankstatus") {
      setData(bajajData[0]);
    } else if (name === "checkfederalbankstatus") {
      setData(federalData[0]);
    } else if (name === "checkcanarabankstatus") {
      setData(canaraData[0]);
    } else if (name === "checkboibankstatus") {
      setData(boiData[0]);
    } else if (name === "checkrblbankstatus") {
      setData(rblData[0]);
    } else {
      setData({}); // Reset data to an empty object if no match
    }
  }, [
    name,
    kotakData,
    bobData,
    idfcData,
    hdfcData,
    sbiData,
    axisData,
    iciciData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
  ]);

  // Render only when data is available
  if (!data.h2) {
    return null; // Return null if data is not available
  }

  return (
    <section
      id="loan-status-checker"
      className="py-3  border-t border-b border-gray-200"
    >
      <div className="container md:px-6 bg-transparent ">
        <div className="mx-auto sd:w-full md:w-4/5 bg-transparent">
          <div className="text-center mb-6 bg-transparent">
            <h2 className="text-xl font-bold tracking-tighter sm:text-2xl text-primary-purple bg-transparent">
              {data.h2}
            </h2>
            <p className="text-dark-grey mt-0 text-sm bg-transparent text-center">
              {data.para}
            </p>
          </div>
          <div
            className=" px-3 py-3 rounded-lg border border-gray-200 shadow-sm"
            style={{ backgroundColor: "#ebe8fb" }}
          >
            <div className="text-center bg-transparent">
              {data.icon}

              <h3 className="text-lg font-bold text-primary-purple">
                {data.h3}
              </h3>
              <p className="text-dark-grey text-sm bg-transparent text-center">
                {data.para2}
              </p>
              <div className="flex flex-col gap-3 bg-transparent">
                <a
                  href="/trackloanstatus"
                  className="inline-flex mt-2 h-12 items-center justify-center rounded-md border border-primary-purple  px-6 text-sm font-medium text-white text-primary-purple bg-[#3b868b] hover:bg-[#311569] transition-colors w-full"
                >
                  Check Your Application Status
                </a>
                <a
                  href="#loan-predictor"
                  className="inline-flex h-12 items-center justify-center rounded-md border border-primary-purple  text-purple-900 px-6 text-sm font-medium text-primary-purple  transition-colors w-full  bg-[white] hover:bg-[#ebe8fb]"
                >
                  Predict Your Approval Chances
                </a>
              </div>
            </div>
          </div>
          <div className="mt-2 flex justify-center text-xs text-dark-grey bg-transparent">
            <p className="bg-transparent">
              Need immediate assistance?{" "}
              <a href="/contactus" className="text-teal hover:underline">
                {data.text}
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

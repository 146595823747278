
import { motion } from "framer-motion"
import { Shield, Target, PieChart, TrendingUp } from "lucide-react"

export default function WhyChooseSection() {
  const features = [
    {
      icon: <Target className="h-8 w-8 md:h-12 md:w-12 text-[#311664]" />,
      title: "Accurate Predictions",
      description: "Our advanced AI-powered analysis ensures 97% accuracy in eligibility results.",
    },
    {
      icon: <Shield className="h-8 w-8 md:h-12 md:w-12 text-[#311664]" />,
      title: "Data Security",
      description: "Your information is 100% encrypted and never shared with third parties.",
    },
    {
      icon: <PieChart className="h-8 w-8 md:h-12 md:w-12 text-[#311664]" />,
      title: "Personalized Insights",
      description: "Get tailored card recommendations based on your financial profile.",
    },
    {
      icon: <TrendingUp className="h-8 w-8 md:h-12 md:w-12 text-[#311664]" />,
      title: "No Credit Score Impact",
      description: "Checking eligibility won't affect your credit score.",
    },
  ]

  return (
    <section className="py-8 md:py-20 px-4 md:px-8 lg:px-16">
      <div className="container max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-8 md:mb-16"
        >
          <h2 className="text-xl md:text-4xl font-bold text-[#311664] mb-2 md:mb-4">Why Minemi is Your Best Choice?</h2>
          <p className="text-sm md:text-lg text-gray-600 max-w-2xl mx-auto">
            We're committed to providing the most accurate and secure credit card eligibility checker.
          </p>
        </motion.div>

        <div className="grid grid-cols-2 gap-3 md:grid-cols-2 md:gap-10">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="bg-white p-2 md:p-8 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <div className="bg-[#ebe8fc] p-2 md:p-2 rounded-lg inline-block mb-2 md:mb-6">{feature.icon}</div>
              <h3 className="text-base md:text-xl font-semibold text-[#311664] mb-1 md:mb-4">{feature.title}</h3>
              <p className="text-xs md:text-base text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}


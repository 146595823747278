import React from "react";
import "./ErrorPage.css";
import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <>
      <div id="not-found-container" className="">
        <div style={{ backgroundColor: "transparent" }}>
          <title>404 - Page Not Found</title>
          <meta
            name="description"
            content="The page you're looking for doesn't exist."
          />
        </div>
        <div
          className="bg-gray-100 flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8"
          style={{ backgroundColor: "transparent" }}
        >
          <div
            className="max-w-md w-full space-y-8 text-center"
            style={{ backgroundColor: "transparent", margin: "auto" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="300"
              height="300"
              viewBox="0 0 300 300"
              fill="none"
              style={{ backgroundColor: "transparent", margin: "auto" }}
            >
              <circle cx="150" cy="150" r="150" fill="#F3F4F6" />
              <path d="M78 130H222V170H78V130Z" fill="#D1D5DB" />
              <path d="M98 110H202V190H98V110Z" fill="#9CA3AF" />
              <path d="M118 90H182V210H118V90Z" fill="#6B7280" />
              <text
                x="150"
                y="160"
                font-family="Arial, sans-serif"
                font-size="48"
                fill="#1F2937"
                text-anchor="middle"
                dominant-baseline="middle"
              >
                404
              </text>
            </svg>
            <h1
              className="mt-6 text-3xl font-extrabold text-gray-900 sm:text-4xl"
              style={{ backgroundColor: "transparent" }}
            >
              Oops! Page not found
            </h1>
            <p
              className="mt-2 text-sm text-gray-600"
              style={{ backgroundColor: "transparent" }}
            >
              The page you're looking for doesn't exist or has been moved.
            </p>
            <div className="mt-5" style={{ backgroundColor: "transparent" }}>
              <Link
                href="/"
                className="text-base font-medium text-indigo-600 hover:text-indigo-500"
                style={{ backgroundColor: "transparent" }}
              >
                Go back home
                <span
                  aria-hidden="true"
                  style={{ backgroundColor: "transparent" }}
                >
                  {" "}
                  &rarr;
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFoundPage;

import React, { useEffect, useRef, useState } from "react";
import img from "../../Assets/Images/image 48.webp";
import icici from "../../Assets/Images/icici.webp";

import sbi from "../../Assets/Images/sbi.webp";
import bajaj from "../../Assets/Images/bajaj-finance6835.webp";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const BankTable = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [index, setIndex] = useState(0);
  const [isMoreCriteria, setIsMoreCriteria] = useState(false);

  const BankTableRef = useRef(null);
  const navigate = useNavigate();

  const isLargeScreen = useMediaQuery("(max-width:1080px)");
  const isMediumScreen = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (window.location.hash === "#BankTable" && BankTableRef.current) {
      BankTableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleLoadMore = () => {
    setIsMoreCriteria((prev) => !prev);
  };

  const mainContent = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: isMediumScreen ? "column" : "row",
    gap: isMediumScreen ? "16px" : isLargeScreen ? "30px" : "120px",
  };
  const tableContent = {
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: isMediumScreen ? "column" : "row",
    gap: isMediumScreen ? "16px" : isLargeScreen ? "20px" : "55px",
  };

  return (
    <div
      style={{
        marginTop: 16,
        backgroundColor: "#FFFFFF",
        padding: 16,
        borderRadius: 12,
        display: "flex",
        flexDirection: "column",
        gap: isMediumScreen ? 25 : 8,
      }}
    >
      <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
        <div className="" style={mainContent}>
          <div
            className="bank-image"
            style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
          >
            <img
              src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/image%2048-1736403678444.webp"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                backgroundColor: "#FFFFFF",
              }}
              alt="HDFC Bank"
            />
          </div>

          <div className="" style={tableContent}>
            <div
              className="headingss"
              style={{
                display: "flex",
                flexDirection: "column",

                backgroundColor: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <p
                className="main-h"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#8A8A8A",
                  alignSelf: "center",
                }}
              >
                Interest Rate (p.a.)
              </p>
              <p className="main-sub" style={{ backgroundColor: "#FFFFFF" }}>
                10.75% - 14.00%
              </p>
            </div>
            <div
              className="headingss"
              style={{
                display: "flex",
                flexDirection: "column",

                backgroundColor: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <p
                className="main-h"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#8A8A8A",
                  alignSelf: "center",
                }}
              >
                Loan Tenure
              </p>
              <p className="main-sub" style={{ backgroundColor: "#FFFFFF" }}>
                {" "}
                1 to 5 years
              </p>
            </div>
            <div
              className="headingss"
              style={{
                display: "flex",
                flexDirection: "column",

                backgroundColor: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <p
                className="main-h"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#8A8A8A",
                  alignSelf: "center",
                }}
              >
                Processing Fees
              </p>
              <p className="main-sub" style={{ backgroundColor: "#FFFFFF" }}>
                {" "}
                Up to 2.5%
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={() =>
            navigate(
              "/loanpage/personalloan/badcredit/hdfc-bank-personal-loan-eligibility"
            )
          }
          className="view-detail"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 256,
            fontFamily: "Archivo",
            color: "#FFFFFF",
            fontWeight: 500,
            fontSize: 18,
            cursor: "pointer",
          }}
        >
          View Details
        </div>
      </div>
      <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
        <div className="" style={mainContent}>
          <div
            className="bank-image"
            style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
          >
            <img
              src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/sbi-1736403898225.webp"
              alt="SBI"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                backgroundColor: "#FFFFFF",
              }}
            />
          </div>

          <div className="" style={tableContent}>
            <div
              className="headingss"
              style={{
                display: "flex",
                flexDirection: "column",

                backgroundColor: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <p
                className="main-h"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#8A8A8A",
                  alignSelf: "center",
                }}
              >
                Interest Rate (p.a.)
              </p>
              <p className="main-sub" style={{ backgroundColor: "#FFFFFF" }}>
                9.60% - 13.85%
              </p>
            </div>
            <div
              className="headingss"
              style={{
                display: "flex",
                flexDirection: "column",

                backgroundColor: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <p
                className="main-h"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#8A8A8A",
                  alignSelf: "center",
                }}
              >
                Loan Tenure
              </p>
              <p className="main-sub" style={{ backgroundColor: "#FFFFFF" }}>
                {" "}
                1 to 7 years
              </p>
            </div>
            <div
              className="headingss"
              style={{
                display: "flex",
                flexDirection: "column",

                backgroundColor: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <p
                className="main-h"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#8A8A8A",
                  alignSelf: "center",
                }}
              >
                Processing Fees
              </p>
              <p className="main-sub" style={{ backgroundColor: "#FFFFFF" }}>
                Up to 1.5%
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={() =>
            navigate(
              "/loanpage/personalloan/badcredit/sbi-personal-loan-eligibility"
            )
          }
          className="view-detail"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 256,
            fontFamily: "Archivo",
            color: "#FFFFFF",
            fontWeight: 500,
            fontSize: 18,
            cursor: "pointer",
          }}
        >
          View Details
        </div>
      </div>
      <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
        <div className="" style={mainContent}>
          <div
            className="bank-image"
            style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
          >
            <img
              src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/icici-1736403726941.webp"
              alt="ICICI Bank"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                backgroundColor: "#FFFFFF",
              }}
            />
          </div>

          <div className="" style={tableContent}>
            <div
              className="headingss"
              style={{
                display: "flex",
                flexDirection: "column",

                backgroundColor: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <p
                className="main-h"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#8A8A8A",
                  alignSelf: "center",
                }}
              >
                Interest Rate (p.a.)
              </p>
              <p className="main-sub" style={{ backgroundColor: "#FFFFFF" }}>
                10.50% - 16.00%
              </p>
            </div>
            <div
              className="headingss"
              style={{
                display: "flex",
                flexDirection: "column",

                backgroundColor: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <p
                className="main-h"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#8A8A8A",
                  alignSelf: "center",
                }}
              >
                Loan Tenure
              </p>
              <p className="main-sub" style={{ backgroundColor: "#FFFFFF" }}>
                1 to 6 years
              </p>
            </div>
            <div
              className="headingss"
              style={{
                display: "flex",
                flexDirection: "column",

                backgroundColor: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <p
                className="main-h"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#8A8A8A",
                  alignSelf: "center",
                }}
              >
                Processing Fees
              </p>
              <p className="main-sub" style={{ backgroundColor: "#FFFFFF" }}>
                Up to 2.25%
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={() =>
            navigate(
              "/loanpage/personalloan/badcredit/icici-bank-personal-loan-eligibility"
            )
          }
          className="view-detail"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 256,
            fontFamily: "Archivo",
            color: "#FFFFFF",
            fontWeight: 500,
            fontSize: 18,
            cursor: "pointer",
          }}
        >
          View Details
        </div>
      </div>
      <div className="main-box" style={{ backgroundColor: "#FFFFFF" }}>
        <div className="" style={mainContent}>
          <div
            className="bank-image"
            style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
          >
            <img
              src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/axis-1736403836726.webp"
              alt="AXIS Bank"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                backgroundColor: "#FFFFFF",
              }}
            />
          </div>

          <div className="" style={tableContent}>
            <div
              className="headingss"
              style={{
                display: "flex",
                flexDirection: "column",

                backgroundColor: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <p
                className="main-h"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#8A8A8A",
                  alignSelf: "center",
                }}
              >
                Interest Rate (p.a.)
              </p>
              <p className="main-sub" style={{ backgroundColor: "#FFFFFF" }}>
                {" "}
                10.49% - 17.00%
              </p>
            </div>
            <div
              className="headingss"
              style={{
                display: "flex",
                flexDirection: "column",

                backgroundColor: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <p
                className="main-h"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#8A8A8A",
                  alignSelf: "center",
                }}
              >
                Loan Tenure
              </p>
              <p className="main-sub" style={{ backgroundColor: "#FFFFFF" }}>
                {" "}
                1 to 5 years
              </p>
            </div>
            <div
              className="headingss"
              style={{
                display: "flex",
                flexDirection: "column",

                backgroundColor: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <p
                className="main-h"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#8A8A8A",
                  alignSelf: "center",
                }}
              >
                Processing Fees
              </p>
              <p className="main-sub" style={{ backgroundColor: "#FFFFFF" }}>
                Up to 1.75%
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={() =>
            navigate(
              "/loanpage/personalloan/badcredit/axis-bank-personal-loan-eligibility"
            )
          }
          className="view-detail"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 256,
            fontFamily: "Archivo",
            color: "#FFFFFF",
            fontWeight: 500,
            fontSize: 18,
            cursor: "pointer",
          }}
        >
          View Details
        </div>
      </div>
      <div
        className={`main-box ${isMoreCriteria === true ? "d-flex" : "d-none"} `}
        style={{
          backgroundColor: "#FFFFFF",

          paddingBottom: 15,
        }}
      >
        <div className="" style={mainContent}>
          <div
            className="bank-image"
            style={{ width: 141, height: 62, backgroundColor: "#FFFFFF" }}
          >
            <img
              src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/bajaj-finance6835-1736403990642.webp"
              alt="BAJAJ Finserve"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                backgroundColor: "#FFFFFF",
              }}
            />
          </div>

          <div className="" style={tableContent}>
            <div
              className="headingss"
              style={{
                display: "flex",
                flexDirection: "column",

                backgroundColor: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <p
                className="main-h"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#8A8A8A",
                  alignSelf: "center",
                }}
              >
                Interest Rate (p.a.)
              </p>
              <p className="main-sub" style={{ backgroundColor: "#FFFFFF" }}>
                {" "}
                12.00% - 17.00%
              </p>
            </div>
            <div
              className="headingss"
              style={{
                display: "flex",
                flexDirection: "column",

                backgroundColor: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <p
                className="main-h"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#8A8A8A",
                  alignSelf: "center",
                }}
              >
                Loan Tenure
              </p>
              <p className="main-sub" style={{ backgroundColor: "#FFFFFF" }}>
                {" "}
                1 to 5 years
              </p>
            </div>
            <div
              className="headingss"
              style={{
                display: "flex",
                flexDirection: "column",

                backgroundColor: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              <p
                className="main-h"
                style={{
                  backgroundColor: "#FFFFFF",
                  color: "#8A8A8A",
                  alignSelf: "center",
                }}
              >
                Processing Fees
              </p>
              <p className="main-sub" style={{ backgroundColor: "#FFFFFF" }}>
                Up to 3.0%
              </p>
            </div>
          </div>
        </div>
        <div
          onClick={() =>
            navigate(
              "/loanpage/personalloan/badcredit/bajaj-finserv-bank-personal-loan-eligibility"
            )
          }
          className="view-detail"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 256,
            fontFamily: "Archivo",
            color: "#FFFFFF",
            fontWeight: 500,
            fontSize: 18,
            cursor: "pointer",
          }}
        >
          View Details
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          backgroundColor: "white",
          padding: 5,
          paddingTop: 16,
          fontFamily: "Archivo",
          fontWeight: 600,
          borderTop: "1px solid #D9D9D9",
          color: "#1F1F1F",
          fontSize: 18,
        }}
        onClick={() => {
          handleLoadMore();
        }}
      >
        {isMoreCriteria === true ? "Hide" : "Load More..."}
      </div>
    </div>
  );
};

export default BankTable;

export default function ComparisonTable() {
    const comparisonData = [
      {
        aspect: "Answers",
        regular: "Generic, one-size-fits-all responses",
        minemi: "Specific answers tailored to your actual credit profile",
      },
      {
        aspect: "Scenarios",
        regular: "Based on hypothetical situations",
        minemi: "Data-driven analysis of your real financial situation",
      },
      {
        aspect: "Accuracy",
        regular: "General information that may not apply to you",
        minemi: "Precise guidance based on your credit history and score",
      },
      {
        aspect: "Personalization",
        regular: "Limited to the information you manually provide",
        minemi: "Automatically analyzes your complete credit profile",
      },
      {
        aspect: "Recommendations",
        regular: "Generic tips anyone can find online",
        minemi: "Actionable advice specific to your financial goals",
      },
    ]
  
    return (
      <section className="py-16 md:py-24 px-4">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-[#311664] text-center mb-6">Why Minemi AI is Different</h2>
          <div className="text-lg text-[#26292b] text-center mb-12 max-w-3xl mx-auto">
            Unlike regular chatbots, Minemi AI provides personalized financial guidance based on your actual credit data.
          </div>
  
          <div className="overflow-x-auto">
            <table className="w-full border-collapse bg-white rounded-lg overflow-hidden shadow-lg">
              <thead>
                <tr>
                  <th className="py-3 px-6 bg-[#311664] text-white text-left">Aspect</th>
                  <th className="py-3 px-6 bg-[#311664] text-white text-left">Regular Chatbots</th>
                  <th className="py-3 px-6 bg-[#59266d] text-white text-left">Minemi AI</th>
                </tr>
              </thead>
              <tbody>
                {comparisonData.map((row, index) => (
                  <tr key={index} className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                    <td className="py-3 px-6 border-b border-gray-200 font-medium text-[#311664]">{row.aspect}</td>
                    <td className="py-3 px-6 border-b border-gray-200 text-[#26292b]">{row.regular}</td>
                    <td className="py-3 px-6 border-b border-gray-200 text-[#26292b] bg-[#3a868b]/10">{row.minemi}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    )
  }
  
  
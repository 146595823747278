import { Link } from "react-router-dom"

export default function FourthFold({ onScrollToSecondFold }) {
  const questions = [
    "How can I improve my credit score?",
    "Which loans am I eligible for?",
    "Can I reduce my EMI with my current credit score?",
    "What's the FOIR requirement for a home loan?",
    "Am I over-leveraged? Should I consolidate my loans?",
  ]

  return (
    <section className="py-16 md:py-24 px-4">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-[#311664] text-center mb-12">What You Can Ask Minemi AI</h2>

        <div className="bg-white rounded-lg border border-[#3a868b]/30 p-8 mb-10 shadow-md">
          <h3 className="text-xl font-semibold text-[#311664] mb-6 text-center">Common Questions Users Ask:</h3>

          <div className="space-y-4">
            {questions.map((question, index) => (
              <div
                key={index}
                className="flex items-start p-3 bg-[#311664]/5 rounded-lg hover:bg-[#311664]/10 transition-all duration-300"
              >
                <div className="mr-3 mt-1 text-[#3a868b]">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="text-[#26292b]">{question}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="text-center">
          <button
            className="bg-[#59266d] hover:bg-[#3a868b] text-white text-lg py-3 px-8 rounded-lg transition-all duration-300 shadow-lg"
            asChild
            onClick={onScrollToSecondFold}
          >
Start Chat Now
          </button>
        </div>
      </div>
    </section>
  )
}


import { useEffect, useState, useMemo } from "react";

export function StatusExplanation({ name }) {
  const [data, setData] = useState({
    title: "",
    description: "",
    statuses: [],
    footer: { text: "", button: { text: "", link: "" } },
  });

  const kotakData = useMemo(
    () => ({
      title: "Understanding Your Kotak Home Finance Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by Kotak Home Finance. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            " Check your registered email for the approval letter",
            "  Complete property documentation and legal verification",
            " Sign the loan agreement and mortgage deed",
            "Wait for the disbursement to the seller/builder",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            " Review the approved loan amount and terms",
            " Discuss with the bank if you need clarification",
            " Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Progress",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by Kotak Home Finance. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            "Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Be available for property inspection if scheduled",
            "Be patient as home loans typically take 6-10 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            " Address any queries from the legal team promptly",
            "Follow up with the seller if additional documents are needed",
            " Track the status through Kotak iMobile app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            "Ensure property access for the evaluator",
            "Have construction plans and approvals ready if needed",
            "Address any structural concerns highlighted",
            "Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by Kotak Home Finance. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "Request for the specific reason for rejection",
            "Use MinEMI's Eligibility Checker to identify issues",
            " Address the concerns (credit score, income, property title)",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const bobData = useMemo(
    () => ({
      title: "Understanding Your Bank of Baroda  Home Finance Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by Bank of Baroda  Home Finance. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            " Check your registered email for the approval letter",
            "  Complete property documentation and legal verification",
            " Sign the loan agreement and mortgage deed",
            "Wait for the disbursement to the seller/builder",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            " Review the approved loan amount and terms",
            " Discuss with the bank if you need clarification",
            " Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Progress",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by Bank of Baroda  Home Finance. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            "Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Be available for property inspection if scheduled",
            "Be patient as home loans typically take 6-10 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            " Address any queries from the legal team promptly",
            "Follow up with the seller if additional documents are needed",
            " Track the status through Bank of Baroda  iMobile app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            "Ensure property access for the evaluator",
            "Have construction plans and approvals ready if needed",
            "Address any structural concerns highlighted",
            "Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by Bank of Baroda  Home Finance. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "Request for the specific reason for rejection",
            "Use MinEMI's Eligibility Checker to identify issues",
            " Address the concerns (credit score, income, property title)",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );

  const idfcData = useMemo(
    () => ({
      title: "Understanding Your ICICI Home Finance Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by IDFC FIRST Bank Home Finance. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            "Review the loan agreement sent to your email",
            "Complete the e-signing process through the secure portal",
            "Set up auto-debit for EMI payments if not already done",
            "Expect disbursement within 24-48 hours after document completion",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "IDFC FIRST Bank has pre-approved your loan based on your credit profile. You're eligible for expedited processing with minimal documentation.",
          nextSteps: [
            "Log in to idfc first bank Net Banking to view your pre-approved offer",
            "Confirm the pre-approved loan amount and terms",
            "Complete the digital verification process (usually just OTP)",
            "Accept the offer to initiate quick disbursement",
          ],
        },
        {
          name: "Application Received",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "IDFC FIRST Bank has received your personal loan application and has initiated the preliminary review process. This is the first stage of evaluation.",
          nextSteps: [
            "No immediate action required from your side",
            "Keep your phone accessible for verification calls",
            "Check your email for any initial document requests",
            "Expect movement to the next stage within 24-48 hours",
          ],
        },
        {
          name: "Document Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "IDFC FIRST Bank is currently verifying the documents you've submitted. This includes income proof, address verification, and identity documents.",
          nextSteps: [
            "Check if all documents are properly uploaded in the portal",
            "Ensure documents are clearly legible and not expired",
            "Be prepared for verification calls from idfc first bank",
            "Use MinEMI's Document Verification Checklist for guidance",
          ],
        },
        {
          name: "Disbursement Initiated",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan has been approved and IDFC FIRST Bank has initiated the disbursement process. The funds will be transferred to your designated account shortly.",
          nextSteps: [
            "Verify your bank account details in the idfc first bank portal",
            "Watch for the disbursement SMS alert from idfc first bank",
            "Check your account for the credited amount",
            "Review the welcome kit with repayment schedule details",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your loan application has not been approved by IDFC FIRST Bank at this time. This decision is based on various eligibility factors.",
          nextSteps: [
            "Request detailed feedback through idfc first bank customer care",
            "Use MinEMI's Rejection Analysis Tool to identify issues",
            "Work on improving the specific factors affecting approval",
            "Consider applying after 3-6 months with improved credentials",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's AI-powered tools guide you through your idfc first bank loan journey.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const hdfcData = useMemo(
    () => ({
      title: "Understanding Your HDFC Home Finance Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by HDFC Home Finance. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            " Check your registered email for the approval letter",
            "  Complete property documentation and legal verification",
            " Sign the loan agreement and mortgage deed",
            "Wait for the disbursement to the seller/builder",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            " Review the approved loan amount and terms",
            " Discuss with the bank if you need clarification",
            " Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Progress",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by HDFC Home Finance. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            "Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Be available for property inspection if scheduled",
            "Be patient as home loans typically take 6-10 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            " Address any queries from the legal team promptly",
            "Follow up with the seller if additional documents are needed",
            " Track the status through HDFC iMobile app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            "Ensure property access for the evaluator",
            "Have construction plans and approvals ready if needed",
            "Address any structural concerns highlighted",
            "Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by HDFC Home Finance. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "Request for the specific reason for rejection",
            "Use MinEMI's Eligibility Checker to identify issues",
            " Address the concerns (credit score, income, property title)",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const sbiData = useMemo(
    () => ({
      title: "Understanding Your SBI Home Finance Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by SBI Home Finance. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            "Check your registered email for the approval letter",
            "Complete property documentation and legal verification",
            "Sign the loan agreement and mortgage deed",
            "Wait for the disbursement to the seller/builder",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            "Review the approved loan amount and terms",
            " Discuss with the bank if you need clarification",
            "Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by SBI Home Finance. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            "Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Be available for property inspection if scheduled",
            "Be patient as home loans typically take 6-10 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            "Address any queries from the legal team promptly",
            "Follow up with the seller if additional documents are needed",
            "Track the status through SBI iMobile app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            "Ensure property access for the evaluator",
            " Have construction plans and approvals ready if needed",
            " Address any structural concerns highlighted",
            " Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by SBI Home Finance. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "Request for the specific reason for rejection",
            "Use MinEMI's Eligibility Checker to identify issues",
            "Address the concerns (credit score, income, property title)",
            " Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const axisData = useMemo(
    () => ({
      title: "Understanding Your Axis Bank Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by Axis Bank. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Complete property documentation and legal verification",
            " Sign the loan agreement and mortgage deed",
            "Wait for the disbursement to the seller/builder",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Discuss with the bank if you need clarification",
            " Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by Axis Bank. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            "Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Be available for property inspection if scheduled",
            "Be patient as home loans typically take 5-7 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            "Address any queries from the legal team promptly",
            " Follow up with the seller if additional documents are needed",
            "Track the status through Axis Mobile app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            "Ensure property access for the evaluator",
            "  Have construction plans and approvals ready if needed",
            "Address any structural concerns highlighted",
            "  Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by Axis Bank. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Address the concerns (credit score, income, property title)",
            " Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const iciciData = useMemo(
    () => ({
      title: "Understanding Your ICICI Bank Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by ICICI Bank. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Complete property documentation and legal verification",
            " Sign the loan agreement and mortgage deed",
            "Wait for the disbursement to the seller/builder",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Discuss with the bank if you need clarification",
            " Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by ICICI Bank. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            "Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Be available for property inspection if scheduled",
            "Be patient as home loans typically take 5-7 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            "Address any queries from the legal team promptly",
            " Follow up with the seller if additional documents are needed",
            "Track the status through ICICI Mobile app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            "Ensure property access for the evaluator",
            "  Have construction plans and approvals ready if needed",
            "Address any structural concerns highlighted",
            "  Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by ICICI Bank. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Address the concerns (credit score, income, property title)",
            " Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const punjabData = useMemo(
    () => ({
      title: "Understanding Your Punjab National Bank Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by Punjab National  Bank. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Complete property documentation and legal verification",
            " Sign the loan agreement and mortgage deed",
            "Wait for the disbursement to the seller/builder",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Discuss with the bank if you need clarification",
            " Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by Punjab National  Bank. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            "Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Be available for property inspection if scheduled",
            "Be patient as home loans typically take 5-7 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            "Address any queries from the legal team promptly",
            " Follow up with the seller if additional documents are needed",
            "Track the status through Punjab National  Mobile app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            "Ensure property access for the evaluator",
            "  Have construction plans and approvals ready if needed",
            "Address any structural concerns highlighted",
            "  Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by Punjab National  Bank. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Address the concerns (credit score, income, property title)",
            " Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const induslndData = useMemo(
    () => ({
      title: "Understanding Your IndusInd Bank Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by IndusInd Bank. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Complete property documentation and legal verification",
            " Sign the loan agreement and mortgage deed",
            "Wait for the disbursement to the seller/builder",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Discuss with the bank if you need clarification",
            " Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by IndusInd Bank. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            "Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Be available for property inspection if scheduled",
            "Be patient as home loans typically take 5-7 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            "Address any queries from the legal team promptly",
            " Follow up with the seller if additional documents are needed",
            "Track the status through IndusInd Mobile app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            "Ensure property access for the evaluator",
            "  Have construction plans and approvals ready if needed",
            "Address any structural concerns highlighted",
            "  Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by IndusInd Bank. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Address the concerns (credit score, income, property title)",
            " Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const tataData = useMemo(
    () => ({
      title: "Understanding Your Tata Capital Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Your home loan application has been approved by Tata Capital  Bank. The loan amount will be disbursed after property documentation is completed.",
          nextSteps: [
            "Check your registered email for the approval letter",
            " Complete property documentation and legal verification",
            " Sign the loan agreement and mortgage deed",
            "Wait for the disbursement to the seller/builder",
          ],
        },
        {
          name: "Partially Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your home loan has been approved, but for a lower amount than requested based on your eligibility assessment or property valuation.",
          nextSteps: [
            "Review the approved loan amount and terms",
            "Discuss with the bank if you need clarification",
            " Arrange for additional funds if needed",
            "Accept the offer and proceed with documentation",
          ],
        },
        {
          name: "Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being processed by Tata Capital Bank. The verification, property assessment, and legal checks are ongoing.",
          nextSteps: [
            "Keep your phone accessible for verification calls",
            "Check your email regularly for any document requests",
            "Be available for property inspection if scheduled",
            "Be patient as home loans typically take 5-7 working days",
          ],
        },
        {
          name: "Legal Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application is currently in the legal verification stage. The bank is checking property documents and title clearance.",
          nextSteps: [
            "Ensure all property documents are submitted",
            "Address any queries from the legal team promptly",
            " Follow up with the seller if additional documents are needed",
            "Track the status through Tata Capital Mobile app or customer support",
          ],
        },
        {
          name: "Technical Evaluation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your property is currently undergoing technical evaluation. The bank's evaluator is assessing the property value and construction quality.",
          nextSteps: [
            "Ensure property access for the evaluator",
            "  Have construction plans and approvals ready if needed",
            "Address any structural concerns highlighted",
            "  Wait for the evaluation report to be processed",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by Tata Capital Bank. This could be due to eligibility factors or property-related issues.",
          nextSteps: [
            "Request for the specific reason for rejection",
            " Use MinEMI's Eligibility Checker to identify issues",
            "Address the concerns (credit score, income, property title)",
            " Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "#loan-status-checker",
        },
      },
    }),
    []
  );
  const yesData = useMemo(
    () => ({
      title: "Understanding Your YES Bank Home Loan Application Status",
      description:
        "Learn what each status means and the recommended actions to take",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your YES Bank home loan has been approved. The bank has completed all verifications and approved your loan request.",
          nextSteps: [
            " Review the loan agreement sent to your email",
            " Complete the e-signing process through the secure portal",
            "Set up auto-debit for EMI payments if not already done",
            " Expect disbursement within 3-5 business days after document completion",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "YES Bank has pre-approved your home loan based on your credit profile and relationship with the bank. You're eligible for expedited processing with minimal documentation.",
          nextSteps: [
            " Log in to YES Bank Net Banking to view your pre-approved offer",
            "Confirm the pre-approved loan amount and terms",
            " Submit property documents for legal verification",
            "Complete the digital verification process for faster approval",
          ],
        },
        {
          name: "Application Received",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "YES Bank has received your home loan application and has initiated the preliminary review process. This is the first stage of evaluation.",
          nextSteps: [
            "  No immediate action required from your side",
            " Keep your phone accessible for verification calls",
            "Check your email for any initial document requests",
            "Expect movement to the next stage within 2-3 business days",
          ],
        },
        {
          name: "Document Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "YES Bank is currently verifying the documents you've submitted. This includes income proof, property documents, and identity verification.",
          nextSteps: [
            "  Check if all documents are properly uploaded in the portal",
            " Ensure property documents are complete and legally verified",
            "  Be prepared for verification calls from YES Bank",
            " Use MinEMI's Document Verification Checklist for guidance",
          ],
        },
        {
          name: "Property Valuation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan has been conditionally approved and YES Bank has initiated the property valuation process. A technical officer will visit the property for assessment.",
          nextSteps: [
            "Coordinate with the bank for property inspection appointment",
            "  Ensure all property documents are readily available",
            " Prepare the property for technical inspection",
            " Address any concerns raised during the valuation process",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has not been approved by YES Bank at this time. This decision is based on various eligibility factors.",
          nextSteps: [
            " Request detailed feedback through YES Bank customer care",
            " Use MinEMI's Rejection Analysis Tool to identify issues",
            "Work on improving the specific factors affecting approval",
            "Consider applying after 3-6 months with improved credentials",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const unionData = useMemo(
    () => ({
      title: "Understanding Your Union Bank Home Loan Application Status",
      description:
        "Learn what each status means and the recommended actions to take",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your Union Bank home loan has been approved. The bank has completed all verifications and approved your loan request.",
          nextSteps: [
            " Review the loan agreement sent to your email",
            " Complete the e-signing process through the secure portal",
            " Set up auto-debit for EMI payments if not already done",
            " Expect disbursement within 5-7 business days after document completion",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Union Bank has pre-approved your home loan based on your credit profile and relationship with the bank. You're eligible for expedited processing with minimal documentation.",
          nextSteps: [
            " Log in to Union Bank Net Banking to view your pre-approved offer",
            " Confirm the pre-approved loan amount and terms",
            " Submit property documents for legal verification",
            "  Complete the digital verification process for faster approval",
          ],
        },
        {
          name: "Application Received",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Union Bank has received your home loan application and has initiated the preliminary review process. This is the first stage of evaluation.",
          nextSteps: [
            "  No immediate action required from your side",
            "Keep your phone accessible for verification calls",
            "Check your email for any initial document requests",
            " Expect movement to the next stage within 3-5 business days",
          ],
        },
        {
          name: "Document Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Union Bank is currently verifying the documents you've submitted. This includes income proof, property documents, and identity verification.",
          nextSteps: [
            "Check if all documents are properly uploaded in the portal",
            " Ensure property documents are complete and legally verified",
            " Be prepared for verification calls from Union Bank",
            "Use MinEMI's Document Verification Checklist for guidance",
          ],
        },
        {
          name: "Property Valuation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan has been conditionally approved and Union Bank has initiated the property valuation process. A technical officer will visit the property for assessment.",
          nextSteps: [
            " Coordinate with the bank for property inspection appointment",
            "Ensure all property documents are readily available",
            " Prepare the property for technical inspection",
            " Address any concerns raised during the valuation process",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has not been approved by Union Bank at this time. This decision is based on various eligibility factors.",
          nextSteps: [
            "Request detailed feedback through Union Bank customer care",
            " Use MinEMI's Rejection Analysis Tool to identify issues",
            " Work on improving the specific factors affecting approval",
            "Consider applying after 3-6 months with improved credentials",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const bajajData = useMemo(
    () => ({
      title: "Understanding Your Bajaj Finance Home Loan Application Status",
      description:
        "Learn what each status means and the recommended actions to take",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your Bajaj Finance home loan has been approved. The company has completed all verifications and approved your loan request.",
          nextSteps: [
            "Review the loan agreement sent to your email",
            " Complete the e-signing process through the secure portal",
            "Set up auto-debit for EMI payments if not already done",
            "Expect disbursement within 3-5 business days after document completion",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Bajaj Finance has pre-approved your home loan based on your credit profile and relationship with the company. You're eligible for expedited processing with minimal documentation.",
          nextSteps: [
            "Log in to the Bajaj Finserv app to view your pre-approved offer",
            "Confirm the pre-approved loan amount and terms",
            " Submit property documents for legal verification",
            " Complete the digital verification process for faster approval",
          ],
        },
        {
          name: "Application Received",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Bajaj Finance has received your home loan application and has initiated the preliminary review process. This is the first stage of evaluation.",
          nextSteps: [
            " No immediate action required from your side",
            " Keep your phone accessible for verification calls",
            "Check your email for any initial document requests",
            "Expect movement to the next stage within 2-3 business days",
          ],
        },
        {
          name: "Document Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Bajaj Finance is currently verifying the documents you've submitted. This includes income proof, property documents, and identity verification.",
          nextSteps: [
            "  Check if all documents are properly uploaded in the portal",
            " Ensure property documents are complete and legally verified",
            " Be prepared for verification calls from Bajaj Finance",
            "Use MinEMI's Document Verification Checklist for guidance",
          ],
        },
        {
          name: "Property Valuation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan has been conditionally approved and Bajaj Finance has initiated the property valuation process. A technical officer will visit the property for assessment.",
          nextSteps: [
            "Coordinate with the company for property inspection appointment",
            "  Ensure all property documents are readily available",
            "  Prepare the property for technical inspection",
            " Address any concerns raised during the valuation process",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has not been approved by Bajaj Finance at this time. This decision is based on various eligibility factors.",
          nextSteps: [
            " Request detailed feedback through Bajaj Finance customer care",
            "  Use MinEMI's Rejection Analysis Tool to identify issues",
            "Work on improving the specific factors affecting approval",
            "Consider applying after 3-6 months with improved credentials",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const federalData = useMemo(
    () => ({
      title: "Understanding Your Federal Bank Home Loan Application Status",
      description:
        "Learn what each status means and the recommended actions to take",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your Federal Bank home loan has been approved. The bank has completed all verifications and approved your loan request.",
          nextSteps: [
            " Review the loan agreement sent to your email",
            "Complete the e-signing process through the secure portal",
            "Set up auto-debit for EMI payments if not already done",
            "Expect disbursement within 3-5 business days after document completion",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Federal Bank has pre-approved your home loan based on your credit profile and relationship with the bank. You're eligible for expedited processing with minimal documentation.",
          nextSteps: [
            " Log in to the FedMobile app to view your pre-approved offer",
            "Confirm the pre-approved loan amount and terms",
            " Submit property documents for legal verification",
            " Complete the digital verification process for faster approval",
          ],
        },
        {
          name: "Application Received",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Federal Bank has received your home loan application and has initiated the preliminary review process. This is the first stage of evaluation.",
          nextSteps: [
            "  No immediate action required from your side",
            " Keep your phone accessible for verification calls",
            "Check your email for any initial document requests",
            " Expect movement to the next stage within 2-3 business days",
          ],
        },
        {
          name: "Document Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Federal Bank is currently verifying the documents you've submitted. This includes income proof, property documents, and identity verification.",
          nextSteps: [
            "   Check if all documents are properly uploaded in the portal",
            "Ensure property documents are complete and legally verified",
            "  Be prepared for verification calls from Federal Bank",
            "Use MinEMI's Document Verification Checklist for guidance",
          ],
        },
        {
          name: "Property Valuation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan has been conditionally approved and Federal Bank has initiated the property valuation process. A technical officer will visit the property for assessment.",
          nextSteps: [
            " Coordinate with the bank for property inspection appointment",
            " Ensure all property documents are readily available",
            " Prepare the property for technical inspection",
            "  Address any concerns raised during the valuation process",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has not been approved by Federal Bank at this time. This decision is based on various eligibility factors.",
          nextSteps: [
            " Request detailed feedback through Federal Bank customer care",
            " Use MinEMI's Rejection Analysis Tool to identify issues",
            "Work on improving the specific factors affecting approval",
            "Consider applying after 3-6 months with improved credentials",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const canaraData = useMemo(
    () => ({
      title: "Understanding Your Canara Bank Home Loan Application Status",
      description:
        "Learn what each status means and the recommended actions to take",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your Canara Bank home loan has been approved. The bank has completed all verifications and approved your loan request.",
          nextSteps: [
            "Review the loan agreement sent to your email",
            "Complete the e-signing process through the secure portal",
            " Set up auto-debit for EMI payments if not already done",
            "Expect disbursement within 3-5 business days after document completion",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Canara Bank has pre-approved your home loan based on your credit profile and relationship with the bank. You're eligible for expedited processing with minimal documentation.",
          nextSteps: [
            "Log in to Canara Bank's net banking to view your pre-approved offer",
            "Confirm the pre-approved loan amount and terms",
            "Submit property documents for legal verification",
            "Complete the digital verification process for faster approval",
          ],
        },
        {
          name: "Application Received",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Canara Bank has received your home loan application and has initiated the preliminary review process. This is the first stage of evaluation.",
          nextSteps: [
            "  No immediate action required from your side",
            " Keep your phone accessible for verification calls",
            "Check your email for any initial document requests",
            "Expect movement to the next stage within 2-3 business days",
          ],
        },
        {
          name: "Document Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Canara Bank is currently verifying the documents you've submitted. This includes income proof, property documents, and identity verification.",
          nextSteps: [
            "  Check if all documents are properly uploaded in the portal",
            "Ensure property documents are complete and legally verified",
            "  Be prepared for verification calls from Canara Bank",
            "Use MinEMI's Document Verification Checklist for guidance",
          ],
        },
        {
          name: "Property Valuation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan has been conditionally approved and Canara Bank has initiated the property valuation process. A technical officer will visit the property for assessment.",
          nextSteps: [
            "Coordinate with the bank for property inspection appointment",
            "  Ensure all property documents are readily available",
            " Prepare the property for technical inspection",
            " Address any concerns raised during the valuation process",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has not been approved by Canara Bank at this time. This decision is based on various eligibility factors.",
          nextSteps: [
            " Request detailed feedback through Canara Bank customer care",
            " Use MinEMI's Rejection Analysis Tool to identify issues",
            "Work on improving the specific factors affecting approval",
            " Consider applying after 3-6 months with improved credentials",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const boiData = useMemo(
    () => ({
      title: "Understanding Your Bank of India Home Loan Application Status",
      description:
        "Learn what each status means and the recommended actions to take",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your Bank of India home loan has been approved. The bank has completed all verifications and approved your loan request.",
          nextSteps: [
            "Review the loan agreement sent to your email",
            " Complete the e-signing process through the secure portal",
            "Set up auto-debit for EMI payments if not already done",
            "Expect disbursement within 3-5 business days after document completion",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Bank of India has pre-approved your home loan based on your credit profile and relationship with the bank. You're eligible for expedited processing with minimal documentation.",
          nextSteps: [
            "Log in to Bank of India's StarConnect net banking to view your pre-approved offer",
            "Confirm the pre-approved loan amount and terms",
            "Submit property documents for legal verification",
            "Complete the digital verification process for faster approval",
          ],
        },
        {
          name: "Application Received",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Bank of India has received your home loan application and has initiated the preliminary review process. This is the first stage of evaluation.",
          nextSteps: [
            " No immediate action required from your side",
            "Keep your phone accessible for verification calls",
            "Check your email for any initial document requests",
            "Expect movement to the next stage within 2-3 business days",
          ],
        },
        {
          name: "Document Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Bank of India is currently verifying the documents you've submitted. This includes income proof, property documents, and identity verification.",
          nextSteps: [
            "Check if all documents are properly uploaded in the portal",
            "Ensure property documents are complete and legally verified",
            "Be prepared for verification calls from Bank of India",
            "Use MinEMI's Document Verification Checklist for guidance",
          ],
        },
        {
          name: "Property Valuation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan has been conditionally approved and Bank of India has initiated the property valuation process. A technical officer will visit the property for assessment.",
          nextSteps: [
            "Coordinate with the bank for property inspection appointment",
            "Ensure all property documents are readily available",
            " Prepare the property for technical inspection",
            " Address any concerns raised during the valuation process",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has not been approved by Bank of India at this time. This decision is based on various eligibility factors.",
          nextSteps: [
            " Request detailed feedback through Bank of India customer care",
            " Use MinEMI's Rejection Analysis Tool to identify issues",
            " Work on improving the specific factors affecting approval",
            " Consider applying after 3-6 months with improved credentials",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const rblData = useMemo(
    () => ({
      title: "Understanding Your RBL Bank Home Loan Application Status",
      description:
        "Learn what each status means and the recommended actions to take",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your RBL Bank home loan has been approved. The bank has completed all verifications and approved your loan request",
          nextSteps: [
            "Review the loan agreement sent to your email",
            " Complete the e-signing process through the secure portal",
            "Set up auto-debit for EMI payments if not already done",
            "Expect disbursement within 3-5 business days after document completion",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "RBL Bank has pre-approved your home loan based on your credit profile and relationship with the bank. You're eligible for expedited processing with minimal documentation.",
          nextSteps: [
            " Log in to RBL Bank's net banking to view your pre-approved offer",
            "Confirm the pre-approved loan amount and terms",
            "Submit property documents for legal verification",
            " Complete the digital verification process for faster approval",
          ],
        },
        {
          name: "Application Received",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "RBL Bank has received your home loan application and has initiated the preliminary review process. This is the first stage of evaluation.",
          nextSteps: [
            " No immediate action required from your side",
            "Keep your phone accessible for verification calls",
            "Check your email for any initial document requests",
            " Expect movement to the next stage within 2-3 business days",
          ],
        },
        {
          name: "Document Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "RBL Bank is currently verifying the documents you've submitted. This includes income proof, property documents, and identity verification.",
          nextSteps: [
            "Check if all documents are properly uploaded in the portal",
            " Ensure property documents are complete and legally verified",
            " Be prepared for verification calls from RBL Bank",
            "Use MinEMI's Document Verification Checklist for guidance",
          ],
        },
        {
          name: "Property Valuation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan has been conditionally approved and RBL Bank has initiated the property valuation process. A technical officer will visit the property for assessment.",
          nextSteps: [
            " Coordinate with the bank for property inspection appointment",
            " Ensure all property documents are readily available",
            " Prepare the property for technical inspection",
            " Address any concerns raised during the valuation process",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has not been approved by RBL Bank at this time. This decision is based on various eligibility factors.",
          nextSteps: [
            " Request detailed feedback through RBL Bank customer care",
            " Use MinEMI's Rejection Analysis Tool to identify issues",
            " Work on improving the specific factors affecting approval",
            "Consider applying after 3-6 months with improved credentials",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const ltData = useMemo(
    () => ({
      title: "Understanding Your L&T Bank Home Loan Application Status",
      description:
        "Learn what each status means and the recommended actions to take",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your L&T Bank home loan has been approved. The bank has completed all verifications and approved your loan request",
          nextSteps: [
            "Review the loan agreement sent to your email",
            " Complete the e-signing process through the secure portal",
            "Set up auto-debit for EMI payments if not already done",
            "Expect disbursement within 3-5 business days after document completion",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "L&T Bank has pre-approved your home loan based on your credit profile and relationship with the bank. You're eligible for expedited processing with minimal documentation.",
          nextSteps: [
            " Log in to L&T Bank's net banking to view your pre-approved offer",
            "Confirm the pre-approved loan amount and terms",
            "Submit property documents for legal verification",
            " Complete the digital verification process for faster approval",
          ],
        },
        {
          name: "Application Received",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "L&T Bank has received your home loan application and has initiated the preliminary review process. This is the first stage of evaluation.",
          nextSteps: [
            " No immediate action required from your side",
            "Keep your phone accessible for verification calls",
            "Check your email for any initial document requests",
            " Expect movement to the next stage within 2-3 business days",
          ],
        },
        {
          name: "Document Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "L&T Bank is currently verifying the documents you've submitted. This includes income proof, property documents, and identity verification.",
          nextSteps: [
            "Check if all documents are properly uploaded in the portal",
            " Ensure property documents are complete and legally verified",
            " Be prepared for verification calls from L&T Bank",
            "Use MinEMI's Document Verification Checklist for guidance",
          ],
        },
        {
          name: "Property Valuation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan has been conditionally approved and L&T Bank has initiated the property valuation process. A technical officer will visit the property for assessment.",
          nextSteps: [
            " Coordinate with the bank for property inspection appointment",
            " Ensure all property documents are readily available",
            " Prepare the property for technical inspection",
            " Address any concerns raised during the valuation process",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has not been approved by L&T Bank at this time. This decision is based on various eligibility factors.",
          nextSteps: [
            " Request detailed feedback through L&T Bank customer care",
            " Use MinEMI's Rejection Analysis Tool to identify issues",
            " Work on improving the specific factors affecting approval",
            "Consider applying after 3-6 months with improved credentials",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const idbiData = useMemo(
    () => ({
      title: "Understanding Your IDBI Bank Home Loan Application Status",
      description:
        "Learn what each status means and the recommended actions to take",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your IDBI Bank home loan has been approved. The bank has completed all verifications and approved your loan request",
          nextSteps: [
            "Review the loan agreement sent to your email",
            " Complete the e-signing process through the secure portal",
            "Set up auto-debit for EMI payments if not already done",
            "Expect disbursement within 3-5 business days after document completion",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "IDBI Bank has pre-approved your home loan based on your credit profile and relationship with the bank. You're eligible for expedited processing with minimal documentation.",
          nextSteps: [
            " Log in to IDBI Bank's net banking to view your pre-approved offer",
            "Confirm the pre-approved loan amount and terms",
            "Submit property documents for legal verification",
            " Complete the digital verification process for faster approval",
          ],
        },
        {
          name: "Application Received",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "IDBI Bank has received your home loan application and has initiated the preliminary review process. This is the first stage of evaluation.",
          nextSteps: [
            " No immediate action required from your side",
            "Keep your phone accessible for verification calls",
            "Check your email for any initial document requests",
            " Expect movement to the next stage within 2-3 business days",
          ],
        },
        {
          name: "Document Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "IDBI Bank is currently verifying the documents you've submitted. This includes income proof, property documents, and identity verification.",
          nextSteps: [
            "Check if all documents are properly uploaded in the portal",
            " Ensure property documents are complete and legally verified",
            " Be prepared for verification calls from IDBI Bank",
            "Use MinEMI's Document Verification Checklist for guidance",
          ],
        },
        {
          name: "Property Valuation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan has been conditionally approved and IDBI Bank has initiated the property valuation process. A technical officer will visit the property for assessment.",
          nextSteps: [
            " Coordinate with the bank for property inspection appointment",
            " Ensure all property documents are readily available",
            " Prepare the property for technical inspection",
            " Address any concerns raised during the valuation process",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has not been approved by IDBI Bank at this time. This decision is based on various eligibility factors.",
          nextSteps: [
            " Request detailed feedback through IDBI Bank customer care",
            " Use MinEMI's Rejection Analysis Tool to identify issues",
            " Work on improving the specific factors affecting approval",
            "Consider applying after 3-6 months with improved credentials",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const lichousingData = useMemo(
    () => ({
      title: "Understanding Your LIC Housing Finance Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your LIC Housing Finance Bank home loan has been approved. The bank has completed all verifications and approved your loan request",
          nextSteps: [
            "Review the loan agreement sent to your email",
            " Complete the e-signing process through the secure portal",
            "Set up auto-debit for EMI payments if not already done",
            "Expect disbursement within 3-5 business days after document completion",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "LIC Housing Finance Bank has pre-approved your home loan based on your credit profile and relationship with the bank. You're eligible for expedited processing with minimal documentation.",
          nextSteps: [
            " Log in to LIC Housing Finance Bank's net banking to view your pre-approved offer",
            "Confirm the pre-approved loan amount and terms",
            "Submit property documents for legal verification",
            " Complete the digital verification process for faster approval",
          ],
        },
        {
          name: "Application Received",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "LIC Housing Finance Bank has received your home loan application and has initiated the preliminary review process. This is the first stage of evaluation.",
          nextSteps: [
            " No immediate action required from your side",
            "Keep your phone accessible for verification calls",
            "Check your email for any initial document requests",
            " Expect movement to the next stage within 2-3 business days",
          ],
        },
        {
          name: "Document Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "LIC Housing Finance Bank is currently verifying the documents you've submitted. This includes income proof, property documents, and identity verification.",
          nextSteps: [
            "Check if all documents are properly uploaded in the portal",
            " Ensure property documents are complete and legally verified",
            " Be prepared for verification calls from LIC Housing Finance Bank",
            "Use MinEMI's Document Verification Checklist for guidance",
          ],
        },
        {
          name: "Property Valuation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan has been conditionally approved and LIC Housing Finance Bank has initiated the property valuation process. A technical officer will visit the property for assessment.",
          nextSteps: [
            " Coordinate with the bank for property inspection appointment",
            " Ensure all property documents are readily available",
            " Prepare the property for technical inspection",
            " Address any concerns raised during the valuation process",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has not been approved by LIC Housing Finance Bank at this time. This decision is based on various eligibility factors.",
          nextSteps: [
            " Request detailed feedback through LIC Housing Finance Bank customer care",
            " Use MinEMI's Rejection Analysis Tool to identify issues",
            " Work on improving the specific factors affecting approval",
            "Consider applying after 3-6 months with improved credentials",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const icicihousingData = useMemo(
    () => ({
      title: "Understanding Your ICICI Home Finance Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your ICICI Home Finance Bank home loan has been approved. The bank has completed all verifications and approved your loan request",
          nextSteps: [
            "Review the loan agreement sent to your email",
            " Complete the e-signing process through the secure portal",
            "Set up auto-debit for EMI payments if not already done",
            "Expect disbursement within 3-5 business days after document completion",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "ICICI Home Finance Bank has pre-approved your home loan based on your credit profile and relationship with the bank. You're eligible for expedited processing with minimal documentation.",
          nextSteps: [
            " Log in to ICICI Home Finance Bank's net banking to view your pre-approved offer",
            "Confirm the pre-approved loan amount and terms",
            "Submit property documents for legal verification",
            " Complete the digital verification process for faster approval",
          ],
        },
        {
          name: "Application Received",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "ICICI Home Finance Bank has received your home loan application and has initiated the preliminary review process. This is the first stage of evaluation.",
          nextSteps: [
            " No immediate action required from your side",
            "Keep your phone accessible for verification calls",
            "Check your email for any initial document requests",
            " Expect movement to the next stage within 2-3 business days",
          ],
        },
        {
          name: "Document Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "ICICI Home Finance Bank is currently verifying the documents you've submitted. This includes income proof, property documents, and identity verification.",
          nextSteps: [
            "Check if all documents are properly uploaded in the portal",
            " Ensure property documents are complete and legally verified",
            " Be prepared for verification calls from ICICI Home Finance Bank",
            "Use MinEMI's Document Verification Checklist for guidance",
          ],
        },
        {
          name: "Property Valuation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan has been conditionally approved and ICICI Home Finance Bank has initiated the property valuation process. A technical officer will visit the property for assessment.",
          nextSteps: [
            " Coordinate with the bank for property inspection appointment",
            " Ensure all property documents are readily available",
            " Prepare the property for technical inspection",
            " Address any concerns raised during the valuation process",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has not been approved by ICICI Home Finance Bank at this time. This decision is based on various eligibility factors.",
          nextSteps: [
            " Request detailed feedback through ICICI Home Finance Bank customer care",
            " Use MinEMI's Rejection Analysis Tool to identify issues",
            " Work on improving the specific factors affecting approval",
            "Consider applying after 3-6 months with improved credentials",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const pnbhousingData = useMemo(
    () => ({
      title: "Understanding Your PNB Home Finance Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your PNB Home Finance Bank home loan has been approved. The bank has completed all verifications and approved your loan request",
          nextSteps: [
            "Review the loan agreement sent to your email",
            " Complete the e-signing process through the secure portal",
            "Set up auto-debit for EMI payments if not already done",
            "Expect disbursement within 3-5 business days after document completion",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "PNB Home Finance Bank has pre-approved your home loan based on your credit profile and relationship with the bank. You're eligible for expedited processing with minimal documentation.",
          nextSteps: [
            " Log in to PNB Home Finance Bank's net banking to view your pre-approved offer",
            "Confirm the pre-approved loan amount and terms",
            "Submit property documents for legal verification",
            " Complete the digital verification process for faster approval",
          ],
        },
        {
          name: "Application Received",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "PNB Home Finance Bank has received your home loan application and has initiated the preliminary review process. This is the first stage of evaluation.",
          nextSteps: [
            " No immediate action required from your side",
            "Keep your phone accessible for verification calls",
            "Check your email for any initial document requests",
            " Expect movement to the next stage within 2-3 business days",
          ],
        },
        {
          name: "Document Verification",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "PNB Home Finance Bank is currently verifying the documents you've submitted. This includes income proof, property documents, and identity verification.",
          nextSteps: [
            "Check if all documents are properly uploaded in the portal",
            " Ensure property documents are complete and legally verified",
            " Be prepared for verification calls from PNB Home Finance Bank",
            "Use MinEMI's Document Verification Checklist for guidance",
          ],
        },
        {
          name: "Property Valuation",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your loan has been conditionally approved and PNB Home Finance Bank has initiated the property valuation process. A technical officer will visit the property for assessment.",
          nextSteps: [
            " Coordinate with the bank for property inspection appointment",
            " Ensure all property documents are readily available",
            " Prepare the property for technical inspection",
            " Address any concerns raised during the valuation process",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has not been approved by PNB Home Finance Bank at this time. This decision is based on various eligibility factors.",
          nextSteps: [
            " Request detailed feedback through PNB Home Finance Bank customer care",
            " Use MinEMI's Rejection Analysis Tool to identify issues",
            " Work on improving the specific factors affecting approval",
            "Consider applying after 3-6 months with improved credentials",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const hdfclnthousingData = useMemo(
    () => ({
      title: "Understanding Your HDFC Ltd. Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your home loan application has been approved by HDFC Ltd. and is ready for disbursement.",
          nextSteps: [
            "Check your registered email for the approval letter",
            "  Review and accept the loan offer and terms",
            "Complete property documentation and legal verification",
            " Prepare for disbursement as per the payment schedule",
          ],
        },
        {
          name: "In-Principle Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been pre-approved based on your financial profile, but final approval depends on property evaluation and documentation.",
          nextSteps: [
            "  Review the in-principle approval terms carefully",
            "Submit property documents for legal verification",
            "Schedule property valuation with HDFC Ltd.",
            "Complete any pending income documentation",
          ],
        },
        {
          name: "Under Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being evaluated by HDFC Ltd. Credit assessment and verification are in progress.",
          nextSteps: [
            "Keep your phone accessible for verification calls",
            "Monitor your email for any communication",
            "Check your application status every 3-4 days",
            "Be patient as processing typically takes 7-14 days",
          ],
        },
        {
          name: "Documents Pending",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application needs additional documentation or property papers before it can proceed further.",
          nextSteps: [
            "Check the HomeOnline portal for document requirements",
            " Submit all requested documents promptly",
            "Ensure property papers are complete and clear",
            "Upload documents digitally when possible for faster processing",
          ],
        },
        {
          name: "Legal Verification",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your application is undergoing legal verification of property documents and title clearance.",
          nextSteps: [
            " Wait for HDFC Ltd. to complete their legal verification",
            " Be prepared for a possible property visit for valuation",
            " Contact your relationship manager for status updates",
            "  Use MinEMI's tools to track progress and next steps",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by HDFC Ltd. based on their eligibility criteria or property assessment.",
          nextSteps: [
            " Request specific reasons for the rejection",
            "Use MinEMI's Eligibility Analyzer to identify issues",
            "Work on improving the factors affecting your application",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const sbihomehousingData = useMemo(
    () => ({
      title: "Understanding Your SBI Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Sanctioned",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your home loan application has been sanctioned by SBI and is ready for disbursement.",
          nextSteps: [
            "Check your registered email for the sanction letter",
            "Review and accept the loan offer and terms",
            "Complete property documentation and mortgage formalities",
            "Prepare for disbursement as per the payment schedule",
          ],
        },
        {
          name: "Pre-Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been pre-approved based on your financial profile, but final sanction depends on property evaluation and documentation.",
          nextSteps: [
            "Review the pre-approval terms carefully",
            "Submit property documents for legal verification",
            " Schedule property valuation with SBI",
            "Complete any pending income documentation",
          ],
        },
        {
          name: "Under Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being evaluated by SBI. Credit assessment and verification are in progress.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Monitor your email for any communication",
            "Check your application status every 3-4 days",
            "Be patient as processing typically takes 8-15 days",
          ],
        },
        {
          name: "Documents Pending",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application needs additional documentation or property papers before it can proceed further.",
          nextSteps: [
            "Check the YONO SBI app for document requirements",
            " Submit all requested documents promptly",
            "Ensure property papers are complete and clear",
            "Upload documents digitally when possible for faster processing",
          ],
        },
        {
          name: "Technical Verification",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your application is undergoing technical verification of property documents and valuation assessment.",
          nextSteps: [
            "Wait for SBI to complete their technical verification",
            " Be prepared for a possible property visit for valuation",
            " Contact your home loan officer for status updates",
            "  Use MinEMI's tools to track progress and next steps",
          ],
        },
        {
          name: "Rejected",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has been rejected by SBI based on their eligibility criteria or property assessment.",
          nextSteps: [
            " Request specific reasons for the rejection",
            "Use MinEMI's Eligibility Analyzer to identify issues",
            "Work on improving the factors affecting your application",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );
  const adityahomehousingData = useMemo(
    () => ({
      title: "Understanding Your Aditya Birla Finance Home Loan Status",
      description:
        "Learn what each status means and the recommended next steps",
      statuses: [
        {
          name: "Approved",
          color: "green",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-green-600"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          ),
          description:
            "Congratulations! Your home loan application has been approved by Aditya Birla Finance and is ready for disbursement.",
          nextSteps: [
            "Check your registered email for the approval letter",
            "Review and accept the loan offer and terms",
            "Complete property documentation and mortgage formalities",
            "Prepare for disbursement as per the payment schedule",
          ],
        },
        {
          name: "In-Principle Approved",
          color: "yellow",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-yellow-600"
            >
              <path d="M12 9v4" />
              <path d="M12 17h.01" />
              <path d="M3.6 9h16.8a1 1 0 0 1 .9 1.45l-8.4 14.48a1 1 0 0 1-1.8 0L2.7 10.45A1 1 0 0 1 3.6 9z" />
            </svg>
          ),
          description:
            "Your loan has been pre-approved based on your financial profile, but final approval depends on property evaluation and documentation.",
          nextSteps: [
            "Review the pre-approval terms carefully",
            "Submit property documents for legal verification",
            " Schedule property valuation with ABFL",
            "Complete any pending income documentation",
          ],
        },
        {
          name: "Under Processing",
          color: "blue",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-blue-600"
            >
              <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
              <path d="m9 12 2 2 4-4" />
            </svg>
          ),
          description:
            "Your home loan application is currently being evaluated by Aditya Birla Finance. Credit assessment and verification are in progress.",
          nextSteps: [
            " Keep your phone accessible for verification calls",
            "Monitor your email for any communication",
            "Check your application status every 3-4 days",
            "Be patient as processing typically takes 8-15 days",
          ],
        },
        {
          name: "Additional Documents Required",
          color: "orange",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-orange-600"
            >
              <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
              <polyline points="14 2 14 8 20 8" />
              <line x1="16" x2="8" y1="13" y2="13" />
              <line x1="16" x2="8" y1="17" y2="17" />
              <line x1="10" x2="8" y1="9" y2="9" />
            </svg>
          ),
          description:
            "Your application needs additional documentation or property papers before it can proceed further.",
          nextSteps: [
            "Check the ABFL portal for document requirements",
            " Submit all requested documents promptly",
            "Ensure property papers are complete and clear",
            "Upload documents digitally when possible for faster processing",
          ],
        },
        {
          name: "Legal Verification",
          color: "purple",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-purple-600"
            >
              <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
              <path d="M3 3v5h5" />
              <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16" />
              <path d="M16 16h5v5" />
            </svg>
          ),
          description:
            "Your application is undergoing legal verification of property documents and valuation assessment.",
          nextSteps: [
            "Wait for ABFL to complete their legal verification",
            " Be prepared for a possible property visit for valuation",
            " Contact your home loan officer for status updates",
            "  Use MinEMI's tools to track progress and next steps",
          ],
        },
        {
          name: "Not Approved",
          color: "red",
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-red-600"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          ),
          description:
            "Unfortunately, your home loan application has not been approved by Aditya Birla Finance based on their eligibility criteria or property assessment.",
          nextSteps: [
            " Request specific reasons for the rejection",
            "Use MinEMI's Eligibility Analyzer to identify issues",
            "Work on improving the factors affecting your application",
            "Consider reapplying after 3-6 months with improvements",
          ],
        },
      ],
      footer: {
        text: "Not sure what to do next? Let MinEMI's intelligent tools guide you through the process.",
        button: {
          text: "Check Your Status Now",
          link: "/trackloanstatus",
        },
      },
    }),
    []
  );

  useEffect(() => {
    if (name === "checkkotakhomeloanbankstatus") {
      setData(kotakData);
    } else if (name === "checkbobhomeloanbankstatus") {
      setData(bobData);
    } else if (name === "checkidfchomeloanbankstatus") {
      setData(idfcData);
    } else if (name === "checkhdfchomeloanbankstatus") {
      setData(hdfcData);
    } else if (name === "checksbihomeloanbankstatus") {
      setData(sbiData);
    } else if (name === "checkaxishomeloanbankstatus") {
      setData(axisData);
    } else if (name === "checkicicihomeloanbankstatus") {
      setData(iciciData);
    } else if (name === "checkpunjabhomeloanbankstatus") {
      setData(punjabData);
    } else if (name === "checkinduslndhomeloanbankstatus") {
      setData(induslndData);
    } else if (name === "checktatahomeloanbankstatus") {
      setData(tataData);
    } else if (name === "checkyeshomeloanbankstatus") {
      setData(yesData);
    } else if (name === "checkunionhomeloanbankstatus") {
      setData(unionData);
    } else if (name === "checkbajajhomeloanbankstatus") {
      setData(bajajData);
    } else if (name === "checkfederalhomeloanbankstatus") {
      setData(federalData);
    } else if (name === "checkcanarahomeloanbankstatus") {
      setData(canaraData);
    } else if (name === "checkboihomeloanbankstatus") {
      setData(boiData);
    } else if (name === "checkrblhomeloanbankstatus") {
      setData(rblData);
    } else if (name === "checklthomeloanbankstatus") {
      setData(ltData);
    } else if (name === "checkidbihomeloanbankstatus") {
      setData(idbiData);
    } else if (name === "checklichousinghomeloanbankstatus") {
      setData(lichousingData);
    } else if (name === "checkicicihousinghomeloanbankstatus") {
      setData(icicihousingData);
    } else if (name === "checkpnbhousinghomeloanbankstatus") {
      setData(pnbhousingData);
    } else if (name === "checkhdfcltdhomeloanbankstatus") {
      setData(hdfclnthousingData);
    } else if (name === "checksbihousinghomeloanbankstatus") {
      setData(sbihomehousingData);
    } else if (name === "checkadityahousinghomeloanbankstatus") {
      setData(adityahomehousingData);
    } else {
      // Initialize with empty structure instead of empty array
      setData({
        title: "",
        description: "",
        statuses: [],
        footer: { text: "", button: { text: "", link: "" } },
      });
    }
  }, [
    name,
    axisData,
    bobData,
    hdfcData,
    iciciData,
    idfcData,
    kotakData,
    sbiData,
    punjabData,
    induslndData,
    tataData,
    yesData,
    unionData,
    bajajData,
    federalData,
    canaraData,
    boiData,
    rblData,
    ltData,
    idbiData,
    lichousingData,
    icicihousingData,
    pnbhousingData,
    hdfclnthousingData,
    sbihomehousingData,
    adityahomehousingData,
  ]);

  return (
    <section className="py-4 md:py-16 bg-white border-b border-gray-200">
      <div className="container px-4 md:px-6">
        <div className="text-center mb-10 bg-transparent">
          <h2 className="text-2xl font-bold tracking-tighter sm:text-3xl text-primary-purple bg-transparent ">
            {data.title}
          </h2>
          <p className="text-dark-grey mt-2 max-w-2xl mx-auto bg-transparent text-center">
            {data.description}
          </p>
        </div>

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 max-w-6xl mx-auto bg-transparent">
          {data.statuses.map((d, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-sm border border-gray-200"
            >
              <div className="h-12 w-12 rounded-full bg-green-100 flex items-center justify-center mb-4">
                {d.icon}
              </div>
              <h3 className="text-xl font-bold text-primary-purple mb-2">
                {d.name}
              </h3>
              <p className="text-dark-grey mb-4 bg-transparent">
                {d.description}
              </p>
              <div
                className="bg-light-bg px-4 pt-2 rounded-md border border-gray-100"
                style={{ backgroundColor: "#ebe8fb" }}
              >
                <h4 className="font-bold text-primary-purple text-sm mb-2 bg-transparent">
                  Next Steps:
                </h4>
                <ul className="text-sm text-dark-grey space-y-2 bg-transparent p-0">
                  {d.nextSteps.map((step, index) => (
                    <li key={index} className="bg-transparent">
                      • {step}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-10 text-center bg-transparent">
          <p className="text-dark-grey mb-3 bg-transparent text-center">
            {data.footer.text}
          </p>
          <a
            href="/trackloanstatus"
            className="inline-flex h-12 items-center justify-center rounded-md bg-teal px-8 text-sm font-medium text-white transition-colors hover:bg-primary-purple focus:outline-none focus:ring-2 focus:ring-primary-purple  bg-[#3b868b] hover:bg-[#2b1b61]"
          >
            {data.footer.button.text}
          </a>
        </div>
      </div>
    </section>
  );
}

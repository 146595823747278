import { Link } from "react-router-dom";

export default function FirstFold({ onScrollToSecondFold }) {
  return (
    <section className=" flex flex-col justify-center items-center px-4  py-16">
      <div className="max-w-4xl mx-auto text-center">
        <h1 className="text-4xl md:text-6xl font-bold text-[#311664] mb-6 leading-tight">
          AI-Powered Credit & Loan Guidance – Just for You.
        </h1>
        <p className="text-xl md:text-2xl text-[#26292b] mb-8 max-w-3xl mx-auto">
          Get instant, personalized answers on how to improve your credit score, lower EMIs, and qualify for better
          loans. Minemi AI understands your credit profile and gives unique, relevant advice—not generic tips.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3  mb-10 max-w-2xl mx-auto" style={{gap:8}}>
          <div className="flex items-center text-[#26292b]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-2 text-[#3a868b]"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <span>Real Answers for Your Credit Profile</span>
          </div>
          <div className="flex items-center text-[#26292b]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-2 text-[#3a868b]"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <span>Instant Loan & EMI Guidance</span>
          </div>
          <div className="flex items-center text-[#26292b]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-2 text-[#3a868b]"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <span>100% Secure & Private</span>
          </div>
        </div>

        <button
          className="bg-[#59266d] hover:bg-[#3a868b] text-white text-lg py-2 px-8 rounded-lg transition-all duration-300 shadow-lg"
          asChild
          onClick={onScrollToSecondFold}
        >
Start Chat Now
        </button>
      </div>
    </section>
  )
}


import React from "react";
import "./Serve.css";
import Accordion from "react-bootstrap/Accordion";
import { FaPlus } from "react-icons/fa";

const Serve = () => {
  const accordionData = [
    {
      title: "Check Your CIBIL Score Instantly!",
      content: (
        <p className="m-0 bg-transparent">
          Your CIBIL score plays a crucial role in determining your loan
          eligibility. A higher score, typically above 700, increases your
          chances of securing better loan offers with lower EMIs. At Minemi, we
          offer a quick and hassle-free way to check your CIBIL score instantly
          without affecting your credit rating. Knowing your score upfront helps
          you explore better financial options and make informed decisions for a
          secure future. Take control of your financial health today!
        </p>
      ),
    },
    {
      title: "Features and Benefits of Cibil Score",
      content: (
        <div className="bg-transparent">
          Your CIBIL score plays a crucial role in determining your loan
          eligibility and interest rates. A higher score increases your chances
          of getting loans at better terms:
          <p className="my-2 bg-transparent">Key features include:</p>
          <p className="my-1 bg-transparent">
            • Better Loan Offers – Higher scores help you secure loans with
            lower interest rates.
          </p>
          <p className="m-0 bg-transparent">
            • Faster Approvals – Lenders process applications quickly for
            individuals with good credit scores.
          </p>
          <p className="my-1 bg-transparent">
            • Instant & Free Check – Get your credit score in minutes.
          </p>
          <p className="m-0 bg-transparent">
            • Safe & Secure – Your data remains confidential.
          </p>
        </div>
      ),
    },
    {
      title: "Why Check Your CIBIL Score? ",
      content: (
        <p className="m-0 bg-transparent">
          Checking your CIBIL score before applying for a loan helps you
          understand your creditworthiness and improves your chances of
          approval. When you apply for multiple loans without knowing your
          score, lenders conduct hard inquiries, which can negatively impact
          your credit score. With Minemi, you can check your CIBIL score
          instantly without affecting it. This allows you to assess your loan
          eligibility in advance, compare better offers from multiple lenders,
          and secure loans at lower interest rates. By knowing your score
          beforehand, you reduce the risk of rejection and can take necessary
          steps to improve your financial standing.
        </p>
      ),
    },
    {
      title: "Should You Check Your CIBIL Score?",
      content: (
        <p className="m-0 bg-transparent">
          If you're planning to apply for a loan or credit card, knowing your
          CIBIL score is essential. It determines your eligibility, approval
          speed, and interest rates. A higher score unlocks better loan offers,
          while a lower score gives you a chance to improve before applying.
          With Minemi, you can check your CIBIL score instantly without
          affecting it. This helps you compare options, secure the best deals,
          and make informed financial decisions. Stay ahead by checking your
          score and ensuring a smooth borrowing experience.
        </p>
      ),
    },
    {
      title: "Documents Required to Check CIBIL Score",
      content: (
        <div className="bg-transparent">
          When checking your CIBIL score, you may need to provide the following
          details:
          <p className="my-1 bg-transparent">
            • Identity Proof: PAN Card, Aadhaar Card, Passport
          </p>
          <p className="m-0 bg-transparent">
            • Contact Details: Mobile number and email ID for verification
          </p>
          <p className="my-1 bg-transparent">
            • Personal Information: Name, Date of Birth, and Address as per
            official records
          </p>
          <p className="my-1 bg-transparent">
            • Financial Details: Loan or credit card details for authentication
            (if required)
          </p>
        </div>
      ),
    },
    {
      title: " Frequently Asked Questions (FAQs) ",
      content: (
        <div className="m-0 bg-transparent">
          <p className="m-0 bg-transparent">
            1.Why should I check my CIBIL score? Checking your CIBIL score helps
            you understand your creditworthiness before applying for a loan or
            credit card. A good score increases your chances of approval and
            better interest rates.
          </p>
          <p className="my-2 bg-transparent">
            2.No, checking your own CIBIL score is considered a soft inquiry and
            does not impact your credit score. However, multiple hard inquiries
            from lenders can lower your score.
          </p>
          <p className="m-0 bg-transparent">
            3.How often should I check my CIBIL score? It’s advisable to check
            your score regularly to stay informed about your credit health.
            Monitoring it helps you detect errors, track improvements, and plan
            for future loans.
          </p>
          <p className="my-2 bg-transparent">
            4.What factors affect my CIBIL score? Your score is influenced by
            factors such as repayment history, credit utilization, loan
            inquiries, and the length of your credit history. Timely payments
            and responsible credit usage can help improve it.
          </p>
          <p className="m-0 bg-transparent">
            5.What happens if I have a low CIBIL score? A low score can lead to
            loan rejections or higher interest rates. To improve it, maintain
            timely repayments, reduce outstanding debt, and avoid multiple loan
            applications in a short period.
          </p>
        </div>
      ),
    },
  ];
  return (
    <>
      <div id="serve">
        <div className="contentCon">
          <div id="header-con">
            <p className="bg-transparent">We swear to serve you with ...</p>
          </div>
          <div id="content" className="bg-transparent">
            <div className="d-flex flex-wrap w-100 bg-transparent">
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/loan_eligibility.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/no_empact.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Impact on credit Score</p>
              </div>

              <div className="plus-icon-con second-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/interest_rate.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Best Interest Rate Offer</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/no_calls.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">No Calls From Us</p>
              </div>

              <div className="plus-icon-con fourth-icon bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent ">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Data Security Guarantee</p>
              </div>

              <div className="plus-icon-con bg-transparent">
                <FaPlus className="plus-icon bg-transparent" />
              </div>
              <div className="loan-info-con bg-transparent">
                <img
                  className="bg-transparent"
                  src="images/data_securities.webp"
                  alt="your-loan"
                />
                <p className="bg-transparent">Your Loan Eligibility</p>
              </div>
            </div>

            <div id="line-breaker"></div>

            <div className="accordion-con bg-transparent">
              <Accordion className="bg-transparent">
                {accordionData.map((item, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{item.title}</Accordion.Header>
                    <Accordion.Body
                      style={{
                        visibility: "visible",
                        backgroundColor: "transparent",
                      }}
                    >
                      {item.content}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Serve;

import React from "react";
import Image from "../../../../../../Assets/Images/Rectangle-img.png";

import "./ListOfDocuments.css";

const ListOfDocuments = () => {
  return (
    <>
      <div id="list-of-documents" className="bg-transparent">
        <div id="home-loan-documents-container">
          <div id="documents-content" className="bg-transparent">
            <div className="documents-content-container bg-transparent">
              <div className="content-box w-100 d-flex flex-column flex-md-row bg-transparent ">
                {" "}
                <div className="documents-text-box bg-transparent">
                  <div className="bg-transparent">
                    <div className="bg-transparent">
                      <h4 id="documents-heading" className="bg-transparent">
                        What You’ll Get After Filling the Form
                      </h4>
                    </div>
                    <div className="text-box bg-transparent">
                      <div className="d-flex flex-row gap-3 gap-md-4 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent">
                          Your Maximum Home Loan Eligibility.
                        </p>
                      </div>
                      <div className="d-flex flex-row gap-3 gap-md-4 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent">
                          The Lowest Home Loan Interest Rate.
                        </p>
                      </div>
                      <div className="d-flex flex-row gap-3 gap-md-4 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent">
                          Property & Credit Evaluation Insights.
                        </p>
                      </div>
                      <div className="d-flex flex-row gap-3 gap-md-4 bg-transparent">
                        <p className="symbol-para bg-transparent">#</p>
                        <p className="text-para bg-transparent">
                          Personalized Approval Improvement Tips.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="documents-image-box  d-md-block bg-transparent">
                  <div className="bg-transparent">
                    <img
                      className="documents-image bg-transparent"
                      src={Image}
                      alt="docuents-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListOfDocuments;

import React from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import { Percent, Calculator, DollarSign, BarChart } from "lucide-react";
import APRCalculator from "./APRCalculator";

const steps = [
  {
    title: "Understand the Basics",
    description:
      "APR represents the annual cost of borrowing, including interest and fees.",
    icon: Percent,
  },
  {
    title: "Gather the Numbers",
    description: "Collect the loan amount, interest rate, fees, and loan term.",
    icon: DollarSign,
  },
  {
    title: "Perform the Calculation",
    description:
      "Add total interest and fees, divide by the loan amount, then annualize the percentage.",
    icon: Calculator,
  },
  {
    title: "Why It Matters",
    description:
      "APR helps you understand total costs and compare loan or credit card options effectively.",
    icon: BarChart,
  },
];

export default function HowAPRWorks() {
  return (
    <section className="py-6 space-y-6">
      <Typography
        variant="h5"
        align="center"
        className="font-bold text-[#26292b]"
      >
        How Does APR Work? A Simple Breakdown
      </Typography>
      <div className="grid sm:grid-cols-2 gap-4">
        {steps.map((step, index) => (
          <Card
            key={index}
            style={{ backgroundColor: "#ebe8fc" }}
            className="bg-[#ebe8fc] hover:shadow-md transition-shadow duration-300"
          >
            <div className="p-3" style={{ backgroundColor: "transparent" }}>
              <Box
                display="flex"
                alignItems="center"
                className="space-x-3"
                style={{ backgroundColor: "transparent" }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ backgroundColor: "#59266d" }}
                  className="p-2 bg-[#59266d] rounded-full"
                >
                  <step.icon
                    className="h-5 w-5 text-white"
                    style={{ backgroundColor: "transparent" }}
                  />
                </Box>
                <Typography
                  variant="h6"
                  className="card_pare text-[#59266d]"
                  style={{ backgroundColor: "transparent" }}
                >
                  {step.title}
                </Typography>
              </Box>
            </div>
            <CardContent
              className="p-4"
              style={{ backgroundColor: "transparent" }}
            >
              <p
                variant="body2"
                className="card_pare text-sm"
                style={{ backgroundColor: "transparent" }}
              >
                {step.description}
              </p>
            </CardContent>
          </Card>
        ))}
      </div>

      {/* CTA section */}
      <div className="bg-[#ebe8fc] p-6 rounded-xl text-center">
        <Typography
          variant="h6"
          className=" text-[#59266d] mb-3"
          style={{ backgroundColor: "transparent", fontWeight: 600 }}
        >
          Ready to Take Control of Your Finances?
        </Typography>
        <p
          variant="body2"
          className="  text-sm sm:text-base text-[#26292b] mb-4"
          style={{ backgroundColor: "transparent" }}
        >
          Understanding APR is the first step towards making informed financial
          decisions. Let Minemi guide you through the process.
        </p>
        <button
          className="bg-[#59266d] hover:bg-[#3a868b] text-white rounded-full px-4 py-[8px] text-sm sm:text-base "
          style={{}}
        >
          Check My APR & Get the Best Loan Rate
        </button>
      </div>

      <div className="mt-6 space-y-6">
        <h6
          align="center"
          className="font-bold text-2xl text-[#26292b] mb-4"
          style={{ fontWeight: 600 }}
        >
          Try It Yourself: APR Calculator
        </h6>
        <APRCalculator />
        <Box textAlign="center">
          <button
            color="primary"
            className=" bg-[#59266d] hover:bg-[#3a868b] text-white rounded-full px-6 py-1.5 text-sm sm:text-base transition-all duration-300 ease-in-out transform hover:scale-105"
          >
            Check My Rate
          </button>
        </Box>
      </div>
    </section>
  );
}

import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Vector from "../../Assets/Images/Vector.webp";
import check from "../../Assets/Images/check.webp";
import uncheck from "../../Assets/Images/uncheck.webp";

function Form() {
  const location = useLocation();
  const navigate = useNavigate();
  const { loanAmount, oldIrr, tenure, newIrr, accountType, Account_Number } = location.state;

  const [formData, setFormData] = useState({
    outstanding: loanAmount,
    oldIrrrs: oldIrr,
    tenures: tenure,
    newIrrs: newIrr,
    salary: "",
    Address: "",
    workaddress: "",
  });

  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  const [option, setOption] = useState(false);
  const [errors, setErrors] = useState({});
  const [click, setClick] = useState(false);

  const inputRef = useRef(null);
  const inputRef1 = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleImageUpload = (event, setImageFunc) => {
    const file = event.target.files[0];
    if (file) {
      const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (validFileTypes.includes(file.type)) {
        setImageFunc(file.name);
      } else {
        alert("Please select a valid image file (jpg, jpeg, png)");
      }
    }
  };

  const formatPrice = (price) => {
    if (!price) return "";
    const cleanNumber = String(price).replace(/,/g, "");
    if (!/^\d+$/.test(cleanNumber)) return "";
    const lastThree = cleanNumber.substring(cleanNumber.length - 3);
    const otherNumbers = cleanNumber.substring(0, cleanNumber.length - 3);
    if (otherNumbers !== '') {
      const formatted = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
      return formatted + "," + lastThree;
    }
    return lastThree;
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.outstanding || isNaN(formData.outstanding)) newErrors.outstanding = "Outstanding Loan Amount is required";
    if (!formData.oldIrrrs || isNaN(formData.oldIrrrs)) newErrors.oldIrrrs = "Current Interest Rate is required";
    if (!formData.tenures || isNaN(formData.tenures)) newErrors.tenures = "Remaining Tenure is required";
    if (!formData.newIrrs || isNaN(formData.newIrrs)) newErrors.newIrrs = "New Interest Rate is required";
    if (!formData.salary || isNaN(formData.salary)) newErrors.salary = "Please enter valid salary";
    if (!formData.Address || !/^[a-zA-Z0-9]*$/.test(formData.Address)) newErrors.Address = "Please Enter Valid Permanent Address";
    if (!formData.workaddress || !/^[a-zA-Z0-9\s]*$/.test(formData.workaddress)) newErrors.workaddress = "Please Enter Valid Work Address";
    if (!image) newErrors.image = "Please Upload Your Salary Slip";
    if (!image1) newErrors.image1 = "Please Upload Your Bank Statement";
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    setClick(true);
    if (validateForm()) {
      const token = localStorage.getItem("token");
      const formDataToSend = new FormData();
      
      // Append all form data
      Object.keys(formData).forEach(key => formDataToSend.append(key, formData[key]));
      formDataToSend.append("goal", localStorage.getItem("id"));
      formDataToSend.append("account_Type", accountType);
      formDataToSend.append("accountNumber", Account_Number);
      formDataToSend.append("uploadSalarySlip", image);
      formDataToSend.append("BankStatement", image1);

      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_SECRET_KEY}/user/applyLoan`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formDataToSend,
      };

      axios.request(options)
        .then(function (response) {
          navigate("/confirm");
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token == null) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="max-w-7xl mx-auto px-2 pb-20 md:py-8 bg-transparent">
      <div className="flex flex-col mt-15 mb-15">
        <h1 className="text-3xl font-medium text-[#59266D] mb-2.5">Fill Your Details</h1>
        <p className="text-sm text-[#8A8A8A]">
          Please provide the following details to confirm your<br className="md:hidden" /> application
        </p>
      </div>

      <div className="flex flex-wrap gap-6 mb-10 mt-4">
        {[
          { label: "Outstanding Loan Amount", name: "outstanding", value: formatPrice(formData.outstanding) },
          { label: "Current Interest Rate(Prefilled)", name: "oldIrrrs", value: formData.oldIrrrs },
          { label: "Remaining Tenure(Prefilled)", name: "tenures", value: formData.tenures },
          { label: "New Interest Rate offered", name: "newIrrs", value: formData.newIrrs },
        ].map((field) => (
          <div key={field.name} className="w-full md:w-[calc(50%-12px)]">
            <label className="block text-base font-medium text-[#1F1F1F] mb-2.5">{field.label}</label>
            <input
              className="w-full h-12 px-5 rounded-lg border border-[#8A8A8A] bg-white outline-none"
              name={field.name}
              value={field.value}
              onChange={handleChange}
              type="text"
            />
            {errors[field.name] && <p className="text-red-500 mt-1">{errors[field.name]}</p>}
          </div>
        ))}
      </div>

      <div className="flex flex-wrap gap-9 mb-15">
        {[
          { label: "Upload Salary Slips(s)", ref: inputRef, image: image, setImage: setImage },
          { label: "Upload Bank Statements(s)", ref: inputRef1, image: image1, setImage: setImage1 },
        ].map((upload, index) => (
          <div key={index} className="w-full md:w-[calc(50%-18px)]" >
            <p className="text-base font-medium text-[#1F1F1F] mb-2.5" style={{backgroundColor:'transparent'}}>{upload.label}</p>
            <div 
              className="border border-dashed border-[#8A8A8A] w-full h-26 rounded-lg bg-white flex items-center justify-center flex-col cursor-pointer"
              onClick={() => upload.ref.current.click()}
              style={{height:97}}
         
            >
              {!upload.image ? (
                <>
                  <input
                    type="file"
                    ref={upload.ref}
                    className="hidden"
                    onChange={(e) => handleImageUpload(e, upload.setImage)}
                    accept=".jpg, .jpeg, .png, .pdf"
                   
                  />
                  <img src={Vector || "/placeholder.svg"} alt="" className="w-5 h-4 mb-2.5"  style={{backgroundColor:'transparent'}}/>
                  <p className="text-sm text-[#8A8A8A]" style={{backgroundColor:'transparent'}}>{upload.label}</p>
                </>
              ) : (
                <p className="text-sm">Name: {upload.image}</p>
              )}
            </div>
            {errors[`image${index + 1}`] && <p className="text-red-500 mt-1">{errors[`image${index + 1}`]}</p>}
            <p className="text-xs text-[#8A8A8A] mt-2.5">
              {index === 0 ? "Last 3 months salary slip" : "Last 3 months bank statement"}<br />
              File Format: JPG, PNG, PDF
            </p>
          </div>
        ))}
      </div>

    

      <div className="flex flex-wrap gap-6 mb-10 mt-6">
        {[
          { label: "Monthly Income/Salary", name: "salary", value: formData.salary },
          { label: "Permanent Address", name: "Address" },
          { label: "Work Address", name: "workaddress" },
        ].map((field) => (
          <div key={field.name} className="w-full md:w-[calc(33.33%-16px)]">
            <label className="block text-base font-medium text-[#1F1F1F] mb-2.5">{field.label}</label>
            <input
              className="w-full h-12 px-5 rounded-lg border border-[#8A8A8A] bg-white outline-none"
              name={field.name}
              value={field.value || formData[field.name]}
              onChange={handleChange}
              placeholder={field.label}
            />
            {errors[field.name] && <p className="text-red-500 mt-1">{errors[field.name]}</p>}
          </div>
        ))}
      </div>

      <div className="flex justify-center mb-15">
        <button
          onClick={handleSubmit}
          className="w-full max-w-md py-3 rounded-lg text-white font-semibold bg-[#59266D] hover:bg-[#4a2c75] transition-colors"
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default Form;

import { CheckCircle2, FileText, BarChart2, Zap, Award } from "lucide-react"

export default function HowItWorksSection() {
  // Using the primary brand color for all steps
  const primaryColor = "#311664"

  const steps = [
    {
      number: 1,
      icon: <FileText className="h-5 w-5 md:h-6 md:w-6 text-white" />,
      title: "Answer Simple Questions",
      description: "Fill out our brief form with your financial details and loan requirements.",
    },
    {
      number: 2,
      icon: <BarChart2 className="h-5 w-5 md:h-6 md:w-6 text-white" />,
      title: "System Analysis",
      description:
        "Our system analyzes your profile against real lending criteria from multiple financial institutions.",
    },
    {
      number: 3,
      icon: <Zap className="h-5 w-5 md:h-6 md:w-6 text-white" />,
      title: "Instant Results",
      description: "Get an immediate assessment of your loan approval chances with personalized insights.",
    },
    {
      number: 4,
      icon: <Award className="h-5 w-5 md:h-6 md:w-6 text-white" />,
      title: "Improvement Recommendations",
      description: "Receive actionable tips to improve your chances if needed, or proceed with confidence.",
    },
  ]

  return (
    <section className="w-full py-8 md:py-16 bg-[#ebe8fc]/30">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8 md:mb-16">
          <h2 className="text-2xl md:text-4xl font-bold text-[#311664] mb-2 md:mb-4">How Minemi Works</h2>
          <p className="text-base md:text-lg text-gray-600 max-w-2xl mx-auto">
            Our simple yet powerful system provides accurate loan approval predictions in just a few steps.
          </p>
        </div>

        <div className="flex flex-col max-w-4xl mx-auto">
          {steps.map((step, index) => (
            <div key={index} className="relative mb-4 md:mb-6 last:mb-0">
              <div className="flex">
                {/* Left side with number and connecting line */}
                <div className="flex flex-col items-center mr-4 md:mr-6">
                  <div className="flex items-center justify-center rounded-full w-8 h-8 md:w-10 md:h-10 flex-shrink-0 z-10 bg-[#311664]">
                    {step.icon}
                  </div>
                  {index < steps.length - 1 && <div className="w-0.5 bg-[#311664]/30 h-full mt-2 mb-2 flex-grow"></div>}
                </div>

                {/* Right side with content */}
                <div className="bg-white rounded-xl shadow-sm p-4 md:p-6 flex-grow border-l-4 border-[#311664]">
                  <div className="flex items-center mb-2">
                    <span className="text-lg md:text-xl font-bold mr-2 text-[#311664]">Step {step.number}</span>
                    <div className="text-base md:text-lg font-bold text-[#311664]" style={{alignSelf:'center'}}>{step.title}</div>
                  </div>
                  <p className="text-xs md:text-sm text-gray-600">{step.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-6 md:mt-12 flex justify-center">
          <div className="bg-white p-3 md:p-6 rounded-xl shadow-md max-w-2xl border-l-4 border-[#311664]">
            <div className="flex items-start">
              <CheckCircle2 className="h-4 w-4 md:h-6 md:w-6 text-[#311664] mr-2 md:mr-3 mt-1 flex-shrink-0" />
              <p className="text-xs md:text-base text-gray-700">
                <span className="font-bold text-[#311664]">Expert Tip:</span> Using Minemi before applying for a loan
                can save you from unnecessary hard inquiries on your credit report. Each rejected loan application can
                lower your credit score by 5-10 points and stay on your report for up to 2 years.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}


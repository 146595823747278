export const faqData = [
  {
    id: 1,
    question: "How does my credit score affect my loan approval?",
    answer:
      "Your credit score is one of the most important factors lenders consider when deciding whether to approve your loan application. A high credit score indicates responsible financial behavior, making you a lower-risk borrower. This increases your chances of approval and allows you to secure loans at lower interest rates with better repayment terms. On the other hand, a low credit score can make it harder to get approved, and even if you do qualify, you might face higher interest rates and stricter loan conditions.",
  },
  {
    id: 2,
    question: "Can I improve my score before applying for a loan?",
    answer:
      "Yes, you can definitely work on improving your credit score before applying for a loan. Paying your bills on time is crucial, as late payments can negatively impact your score. Reducing your outstanding debt, especially credit card balances, can also make a big difference. Checking your credit report for any errors and disputing inaccuracies can help prevent unnecessary drops in your score. Additionally, avoiding multiple new credit applications in a short period and maintaining older credit accounts can contribute to a better credit history. Since improving your score takes time, it’s best to start these steps well in advance of applying for a loan.",
  },
  {
    id: 3,
    question: "Does checking my credit score lower my score?",
    answer:
      "No, checking your own credit score does not lower your score. This is known as a soft inquiry, which has no impact on your credit rating. However, when a lender checks your credit as part of a loan application, it is considered a hard inquiry, which can slightly lower your score. That said, occasional credit checks for personal monitoring won’t harm your credit and can actually help you stay informed and take necessary steps to improve your score before applying for a loan.",
    // answerList: [
    //   {
    //     heading: " Loan Amount: ",
    //     info: "Higher amounts lead to higher EMIs.",
    //   },
    //   {
    //     heading: " Interest Rate:",
    //     info: "Lower rates reduce your EMI and total repayment.",
    //   },
    //   {
    //     heading: "Tenure:  ",
    //     info: " Longer tenures lower EMIs but increase total interest.",
    //   },
    // ],
  },
  {
    id: 4,
    question: "How Can I Improve My Credit Score?",
    answer:
      "Improving your credit score requires responsible financial habits. Pay bills on time, maintain a low credit utilization ratio, avoid multiple loan applications in a short period, and maintain a healthy mix of credit types.",
  },
  {
    id: 5,
    question: "What Factors Affect My Credit Score?",
    answer:
      "Key factors include payment history, credit utilization ratio, types of credit, length of credit history, and recent inquiries. Missing payments, overusing credit, and applying for multiple loans can negatively impact your score.",
  },
  {
    id: 6,
    question: "What Happens If My Credit Score is Low?",
    answer:
      "A low credit score can lead to loan rejections, higher interest rates, and lower credit limits. Lenders may require additional security, a guarantor, or impose stricter loan terms.",
  },
  {
    id: 7,
    question: "How Often Should I Check My Credit Score?",
    answer:
      "It's recommended to check your credit score every three to six months. Regular monitoring helps identify errors, detect fraud, and take corrective actions to improve your financial health.",
  },
  {
    id: 8,
    question: "Can I Get a Loan with a Low Credit Score?",
    answer:
      "Yes, but it may be challenging. Lenders may require collateral, a guarantor, or offer loans with higher interest rates. Opting for secured loans like a loan against a fixed deposit or property may increase approval chances.",
  },
];

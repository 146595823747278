import React from "react";
import "./SimpleSteps.css";
// import vectorOne from "../../../../Assets/Images/pl-details-vector.png";
import vectorOne from "../../../../../../Assets/Images/pl-details-vector.webp";
import vectorTwo from "../../../../../../Assets/Images/lc-icon-two.webp";
import vectoThree from "../../../../../../Assets/Images/lc-icon-three.webp";
import vectorFour from "../../../../../../Assets/Images/icon-four.webp";

const SimpleSteps = () => {
  return (
    <>
      <div id="loan-consolidation-steps">
        <div
          id="four-simple-steps-container"
          className="loan-consolidation-steps"
        >
          <div id="steps-content-page">
            <div className="heading-text-container">
              <h4 className="heading-text">Get started in 4 simple steps</h4>
            </div>
            <div className="steps-page-content d-flex flex-row flex-md-column">
              <div id="vectors-img-container">
                <div className="image-content-section  d-flex flex-column flex-md-row gap-4 justify-content-md-between  pt-md-3 ">
                  {/* image one */}

                  <div className=" vector-img-text-container w-100  d-flex flex-row flex-md-column  gap-3 gap-lg-3 ">
                    <div className="vector-image-one ">
                      <img
                        id="img-one"
                        className="bg-transparent"
                        src={vectorOne}
                        alt="vector-image"
                      />
                    </div>
                    <div id="vector-text-one">
                      <p className="text">
                        Provide information about your existing loans and
                        personal details.
                      </p>
                    </div>
                  </div>

                  {/* image two */}

                  <div className=" vector-img-text-container  w-100  d-flex flex-row flex-md-column  gap-3 gap-lg-3 ">
                    <div className="vector-image-two image-two-box">
                      <img
                        id="img-two"
                        className="bg-transparent"
                        src={vectorTwo}
                        alt="vector-image"
                      />
                    </div>
                    <div id="vector-text-two">
                      <p className="text">
                        See personalized consolidation offers tailored to your
                        profile.
                      </p>
                    </div>
                  </div>

                  {/* image three */}

                  <div className=" vector-img-text-container w-100  d-flex flex-row flex-md-column  gap-3 gap-lg-3 ">
                    <div className="vector-image-three">
                      <img
                        id="img-three"
                        className="bg-transparent"
                        src={vectoThree}
                        alt="vector-image"
                      />
                    </div>
                    <div id="vector-text-three">
                      <p className="text">
                        Select the best consolidation plan to lower your EMI
                      </p>
                    </div>
                  </div>

                  {/* image four */}

                  <div className=" vector-img-text-container w-100  d-flex flex-row flex-md-column  gap-3 gap-lg-3 ">
                    <div className="vector-image-four">
                      <img
                        id="img-four"
                        className="bg-transparent"
                        src={vectorFour}
                        alt="vector-image"
                      />
                    </div>
                    <div id="vector-text-four">
                      <p className="text">
                        We’ll help you settle existing loans with your new
                        consolidated loan.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className=" vector-text-container d-flex flex-column flex-md-row  ">
              <div id="vector-text">
                <p>Lending Partner calls you to confirm details</p>
              </div>
              <div id="vector-text">
                <p>Site visit & in-person discussion</p>
              </div>
              <div id="vector-text">
                <p>Loan Approval & Get funds disbursed</p>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SimpleSteps;

import React, { useEffect, useState, useContext } from "react";
import toungeOut from "../../../Assets/Images/tongue-out.webp";
import { LuDot } from "react-icons/lu";
import "../EligiblityCriteria.css";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MetadataContext from "../../MetadataContext";

const ICICIInterestRate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { setMetadata } = useContext(MetadataContext);

  useEffect(() => {
    setMetadata({
      pageTitle: "Compare ICICI Bank Personal Loan Eligibility",
      pageDescription:
        "Compare ICICI Bank personal loan eligibility easily and instantly. Use our eligibility calculator to find the best loan options with flexible EMIs. Apply online now!",
      pageKeywords:
        "ICICI Bank Personal Loan Eligibility, Compare Personal Loan Eligibility, ICICI Loan Eligibility Calculator, Personal Loan Online Application, Quick Loan Approval, ICICI Bank Loan Offers, Loan Eligibility Criteria, Personal Loan Interest Rates, Instant Loan Eligibility Check, Personal Loan EMI Calculator, Best Personal Loan Options, Online Eligibility Check, ICICI Instant Loans, ICICI Personal Loan Criteria",
      canonicalUrl:
        "https://minemi.ai/loanpage/personalloan/badcredit/icici-bank-personal-loan-eligibility",
    });
  }, [setMetadata]);

  return (
    <>
      <div style={{ backgroundColor: "#f8f8f8" }}>
        <div
          className="margin-less"
          style={{
            backgroundColor: "#E7E4F9",
            paddingLeft: "4%",
            paddingRight: "4%",
            display: "flex",
            flexDirection: "column",
            gap: 16,
            paddingTop: 40,
            paddingBottom: 40,
            overflow: "hidden",
            boxShadow: "2px 1px 2px 0px #00000040",
          }}
        >
          {/* breadcrumbs */}
          <div id="breadcrumbs" style={{ background: "transparent" }}>
            <p className="pages_links bg-transparent links ">
              <Link
                to={"/"}
                // underline="hover"
                className=" custom-link bg-transparent archivo "
                style={{
                  // color: "#59266D",
                  cursor: "pointer",
                  fontFamily: "'Archivo', sans-serif",
                }}
              >
                Home
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1 "
                style={{
                  color: "#59266D",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Loans
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              <Link
                to={"/loanpage/personalloan"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Personal Loan
              </Link>
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                to={"/loanpage/personalloan/badcredit"}
                underline="hover"
                className=" custom-link d-none d-md-inline bg-transparent archivo"
                style={{ cursor: "pointer" }}
              >
                Personal Loan Interest Rates
              </Link>{" "}
              &nbsp;
              <span
                className="d-none d-md-inline bg-transparent ps-1 px-1"
                style={{
                  color: "#59266d",
                  fontFamily: "'Archivo', sans-serif",
                  fontWeight: 800,
                }}
              >
                &gt;
              </span>
              &nbsp;
              <Link
                underline="hover"
                className="d-none d-md-inline fw-bold bg-transparent archivo"
                style={{ color: "#59266d", cursor: "pointer" }}
              >
                ICICI Bank Personal Loan Eligibility
              </Link>{" "}
            </p>
          </div>
          <h1
            className="loanstatus-heading mt-3"
            style={{
              fontFamily: "Archivo",
              fontWeight: 700,
              fontSize: 40,
              lineHeight: "55px",

              color: "#59266D",
              backgroundColor: "#E7E4F9",
            }}
          >
            Compare ICICI Bank Personal Loan Eligibility
          </h1>
          <p
            className="loanstatus-paragraph"
            style={{
              fontFamily: "Archivo",
              fontWeight: 500,
              fontSize: 18,
              lineHeight: "24px",

              color: "#74589B",
              backgroundColor: "#E7E4F9",
            }}
          >
            ICICI Bank offers personal loans tailored to various financial needs
            with quick approvals, competitive interest rates, and easy repayment
            options. Below is a detailed breakdown of ICICI Bank's personal loan
            eligibility criteria and related information to help you make an
            informed decision.
          </p>
        </div>
        <div
          className="tracking-status"
          style={{ marginLeft: "4%", marginRight: "4%", marginTop: 35 }}
        >
          <h2
            style={{
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 24,
              color: "#1F1F1F",
              marginTop: 35,
              lineHeight: 1.2,
            }}
          >
            Eligibility Criteria for ICICI Personal Loans
          </h2>
          <p
            style={{
              marginTop: 15,
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
              color: "#1F1F1F",
              lineHeight: "20px",
            }}
          >
            ICICI Bank evaluates loan applications based on several factors,
            such as income, employment status, and credit score. Here’s a
            detailed overview:
          </p>

          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                      width: "30%",
                    }}
                  >
                    Parameter
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Eligibility Requirement
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Age
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Salaried: 23–58 years <br />- Self-Employed: 28–65 years
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Income
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Salaried: Minimum monthly income of ₹25,000 <be /> -
                    Self-Employed: Annual turnover as per ITR
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Employment Type
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Salaried individuals or self-employed professionals/business
                    owners
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Work Experience
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Salaried: Minimum 2 years with at least 1 year in the
                    current organization
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottomLeftRadius: 24,
                    borderBottomRightRadius: 24,
                  }}
                >
                  <td
                    className="table-padding"
                    style={{
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      borderBottomLeftRadius: 18,
                    }}
                  >
                    Credit Score
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    700 or above preferred
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className="make-it-center increase-margin "
            style={{
              marginTop: "4%",
              marginBottom: "2%",
              padding: 24,
              background: "linear-gradient(91.7deg, #EADCFF 0.4%, #FFFFFF 99%)",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 20,
              borderRadius: 24,
            }}
          >
            <div
              style={{
                marginLeft: "4%",
                backgroundColor: "transparent",
                display: "flex",
                flexDirection: "column",
                gap: 24,
                borderRadius: 24,
                alignSelf: "center",
              }}
            >
              <p
                className="eligiblity-criteria"
                style={{
                  fontFamily: "Archivo",
                  color: "#59266D",
                  fontSize: 40,
                  backgroundColor: "transparent",
                }}
              >
                <i style={{ backgroundColor: "transparent", lineHeight: 1.2 }}>
                  Check Your Loan Eligibility Without{" "}
                  <br className="brake-remove" /> Impacting Your Credit Score
                </i>
              </p>
              <a
                href="https://minemi.ai/personal-loan/check-eligibility/"
                className="eligiblity-buttons"
                style={{
                  backgroundColor: "#59266D",
                  color: "#FFFFFF",
                  width: "39%",
                  borderRadius: 8,
                  fontFamily: "Archivo",
                  fontWeight: 500,
                  fontSize: 18,
                  paddingTop: 8,
                  paddingBottom: 8,
                  textAlign: "center",
                }}
              >
                Check Eligibility
              </a>
            </div>
            <div
              style={{
                width: 200,
                height: 195,
                backgroundColor: "transparent",
                marginRight: "4%",
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
              }}
            >
              <img
                src="https://minemiwebsite.s3.ap-south-1.amazonaws.com/assets/Illustration-1736403318651.webp"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "transparent",
                }}
                loading="lazy"
                alt="Check Eligibility"
              />
            </div>
          </div>

          <h3
            style={{
              fontFamily: "Archivo",
              fontWeight: 600,
              fontSize: 24,
              color: "#1F1F1F",
              lineHeight: 1.2,
              marginTop: 25,
            }}
          >
            Features That Enhance Your Eligibility
          </h3>

          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                      width: "30%",
                    }}
                  >
                    Feature
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Impact on Eligibility
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    High Credit Score (750+)
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Increases approval chances and ensures better interest
                    rates.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Low Fixed Obligations to Income Ratio (FOIR)
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Applicants with fewer financial obligations have higher
                    chances of approval.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Stable Income Source
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Ensures the bank of your repayment capacity, improving your
                    eligibility.
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Pre-Existing Relationship with ICICI Bank
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Existing customers may receive faster approvals and lower
                    rates.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            How to Check Your ICICI Personal Loan Eligibility
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Steps to Check Eligibility Online
          </div>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                Visit the{" "}
                <a href={"https://www.icicibank.com/"}>
                  ICICI Official Website.
                </a>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                Go to the “Personal Loans” section
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                Use the eligibility calculator by entering details like income,
                existing liabilities, and loan requirements.
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <span
                  style={{ color: "#1F1F1F", fontWeight: 600, fontSize: 16 }}
                >
                  <LuDot />
                </span>
              </div>
              <div style={{ color: "#1F1F1F", fontWeight: 400, fontSize: 16 }}>
                Get instant results based on your inputs.
              </div>
            </div>
          </div>
          <p
            style={{
              fontFamily: "Archivo",
              marginTop: 16,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            <span
              style={{
                fontFamily: "Archivo",
                marginTop: 16,
                color: "#1F1F1F",
                fontWeight: 600,
                fontSize: 16,
                lineHeight: "20px",
              }}
            >
              Offline Method:
            </span>{" "}
            Visit the nearest ICICI Bank branch with your documents. The bank
            representatives will help you evaluate your eligibility.
          </p>
          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Eligibility Criteria by Applicant Type
          </h3>

          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                      width: "30%",
                    }}
                  >
                    Applicant Type
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Key Criteria
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Salaried Individuals
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Regular income proof, employment in a reputed organization,
                    and credit score above 700.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Self-Employed
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Consistent business turnover as per ITR filings and stable
                    operations for 3 years.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Documents Required for ICICI Personal Loan Eligibility
          </h3>
          <div
            style={{
              fontFamily: "Archivo",
              marginTop: 15,
              color: "#1F1F1F",
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "20px",
            }}
          >
            Ensure you have the following documents ready to avoid processing
            delays:
          </div>
          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                      width: "30%",
                    }}
                  >
                    Category
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Examples
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Identity Proof
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Aadhaar, PAN Card, Passport, or Voter ID.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Address Proof
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Utility Bills, Rent Agreement, Aadhaar Card, or Passport.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Income Proof
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    - Salaried: Salary slips for the last 3 months, Form 16
                    <br /> - Self-Employed: ITR for 2 years.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Employment Proof
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Offer Letter, Appointment Letter, or Employer Certificate.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Bank Statement
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Last 6 months’ bank statements.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Photographs
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Recent passport-sized photographs.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            How to Improve Your ICICI Personal Loan Eligibility
          </h3>

          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                      width: "30%",
                    }}
                  >
                    Improvement Strategy
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Maintain a High Credit Score
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Timely bill payments and reducing credit utilization can
                    significantly improve your eligibility.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Reduce Existing Debt
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Paying off smaller debts or EMIs reduces your financial
                    liabilities, enhancing eligibility.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Include a Co-Applicant
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Applying with a co-applicant with a strong financial profile
                    may increase your chances.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Choose a Lower Loan Amount
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Opting for a loan amount that aligns with your repayment
                    capacity improves your approval chances.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* minemi tip section */}
          <div
            className="tips mt-4 mb-3"
            style={{
              backgroundColor: "#e7e4f9",
              border: "1px solid #8a8a8a",
              borderTop: "0px",
            }}
          >
            <div
              id="border_line"
              style={{
                width: "100%",
                height: "5px",
                backgroundColor: "#59266d",
                border: "1px solid #8a8a8a",
                borderTop: "0px",
                clipPath: "polygon(0 0, 100% 0, 99.8% 100%, 0.2% 100%)",
              }}
            ></div>
            <div
              className="px-1 px-sm-4 py-4"
              style={{ backgroundColor: "#e7e4f9" }}
            >
              <div
                className="px-3 py-2"
                style={{ backgroundColor: "#e7e4f9", padding: "30px " }}
              >
                <div
                  className="d-flex flex-row gap-2 align-items-center"
                  style={{
                    backgroundColor: "transparent",
                    marginBottom: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    gap: "20px",
                  }}
                >
                  <img
                    src={toungeOut}
                    alt="minemi tip image"
                    loading="lazy"
                    style={{
                      width: 30,
                      height: 30,
                      backgroundColor: "#e7e4f9",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "20px",
                      letterSpacing: "0.08px",
                      color: "#59266D",
                      // color: "yellow",
                      fontFamily: "'Archivo', sans-serif",
                      backgroundColor: "#e7e4f9",
                    }}
                  >
                    MinEmi Tip
                  </Typography>
                </div>
                <div className="mt-2 ">
                  <Typography
                    sx={{
                      fontSize: 16,
                      lineHeight: "20px",
                      letterSpacing: "0.08px",
                      color: "#1F1F1F",
                      // color: "#F6F6F6",
                      backgroundColor: "#e7e4f9",

                      fontFamily: "'Archivo', sans-serif",
                    }}
                  >
                    👉 "Simplify your finances by consolidating smaller loans
                    into one manageable payment. Use MinEmi’s EMI Monitoring
                    Tool to track payments and optimize your credit profile for
                    ICICI personal loans."
                  </Typography>
                </div>
              </div>
            </div>
          </div>

          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Benefits of ICICI Personal Loans
          </h3>

          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                      width: "30%",
                    }}
                  >
                    Benefit
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Attractive Interest Rates
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Starting at 10.75% p.a.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Flexible Tenure Options
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Repayment tenure ranges from 12 to 72 months, providing
                    flexibility in EMI planning.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Quick Disbursal
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Funds are disbursed within 24–48 hours for eligible
                    applicants.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Minimal Paperwork
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Streamlined application process with basic document
                    requirements.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    High Loan Amount
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Borrow up to ₹40 lakhs based on your eligibility.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Snapshot of Key ICICI Loan Parameters
          </h3>

          <div
            className="increase-margin"
            style={{
              marginTop: "2%",
              border: "1px solid #846CA6",
              borderRadius: 18,
            }}
          >
            <table
              id="customers"
              style={{
                fontFamily: "Archivo",
                borderCollapse: "collapse",
                borderRadius: 18,
                width: "100%",
              }}
            >
              <thead style={{ borderRadius: 18 }}>
                <tr style={{ borderRadius: 18 }}>
                  <th
                    className="table-padding"
                    style={{
                      backgroundColor: "#E7E4F9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      borderTopLeftRadius: 18,
                      color: "#59266D",
                      width: "30%",
                    }}
                  >
                    Parameter
                  </th>
                  <th
                    className="table-padding"
                    style={{
                      borderLeft: "1px solid #D9D9D9",
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      paddingLeft: "16px",
                      textAlign: "left",
                      backgroundColor: "#E7E4F9",
                      color: "#59266D",
                    }}
                  >
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Age Limit
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Salaried: 23–58 years
                    <br />
                    Self-Employed: 28–65 years.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Minimum Income
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    ₹25,000 per month for salaried applicants.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Maximum Loan Amount
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    ₹40 lakhs.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Repayment Tenure
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    12 to 72 months.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Interest Rate
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Starting at 10.75% p.a.
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#f2f2f2" }}>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 700,
                    }}
                  >
                    Processing Fee
                  </td>
                  <td
                    className="table-padding"
                    style={{
                      border: "1px solid #ddd",
                      padding: "16px",
                      backgroundColor: "white",
                      fontFamily: "Archivo",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Up to 2.5% of the loan amount + applicable taxes.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p
            style={{ fontSize: 16, lineHeight: "20px", color: "#1f1f1f" }}
            className="mt-4"
          >
            For more information please check{" "}
            <a
              href={
                "https://www.icicibank.com/personal-banking/loans/personal-loan/eligibility"
              }
              target="_blank"
            >
              ICICI Bank Eligibility
            </a>
          </p>

          <h3
            style={{
              marginTop: 25,
              color: "#1F1F1F",
              fontWeight: 600,
              fontSize: 24,
              lineHeight: 1.2,
            }}
          >
            Frequently Asked Questions (FAQs){" "}
          </h3>
          <div
            className="accordion "
            style={{
              marginTop: 9,
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <Accordion
              defaultExpanded={true}
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                overflow: "hidden",
                backgroundColor: "#FFFFFF",
              }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                What is the minimum income required to apply for an ICICI
                personal loan?
              </AccordionSummary>
              <AccordionDetails>
                Salaried applicants must have a minimum monthly income of
                ₹25,000.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                overflow: "hidden",
                backgroundColor: "#FFFFFF",
              }}
            >
              <AccordionSummary
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Can self-employed individuals apply for an ICICI personal loan?
              </AccordionSummary>
              <AccordionDetails>
                Yes, self-employed individuals with consistent business turnover
                and stable ITR filings can apply.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                overflow: "hidden",
                backgroundColor: "#FFFFFF",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                How much loan amount can I get from ICICI Bank?
              </AccordionSummary>
              <AccordionDetails>
                You can avail of a personal loan ranging from ₹50,000 to ₹40
                lakhs, subject to eligibility.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                overflow: "hidden",
                backgroundColor: "#FFFFFF",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Does ICICI offer pre-approved personal loans?
              </AccordionSummary>
              <AccordionDetails>
                Yes, ICICI Bank offers pre-approved personal loans to select
                existing customers based on their credit profile.
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                overflow: "hidden",
                backgroundColor: "#FFFFFF",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Is there a processing fee for ICICI personal loans?
              </AccordionSummary>
              <AccordionDetails>
                Yes, a processing fee of up to 2.5% of the loan amount is
                applicable.
              </AccordionDetails>
              {/* <AccordionActions></AccordionActions> */}
            </Accordion>
            <Accordion
              style={{
                border: "1px solid #C8C8C8",
                borderRadius: 12,
                overflow: "hidden",
                backgroundColor: "#FFFFFF",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                style={{
                  borderRadius: 12,
                  fontWeight: 600,
                }}
              >
                Can I check my personal loan eligibility without visiting the
                bank?
              </AccordionSummary>
              <AccordionDetails>
                Yes, you can use the online eligibility calculator on the ICICI
                Bank website to check your eligibility instantly.
              </AccordionDetails>
            </Accordion>
          </div>

          <div
            className="increase-margines"
            style={{
              backgroundColor: "#E7E4F9",
              borderRadius: 24,
              padding: 40,
              marginTop: 40,
              paddingBottom: 40,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: "transparent",
              }}
            >
              <p
                className="minutes"
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 32,
                  fontWeight: 600,
                }}
              >
                Check Your Eligibility in Minutes!
              </p>
              <p
                style={{
                  color: "#59266D",
                  backgroundColor: "transparent",
                  fontFamily: "Archivo",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: 1.2,
                }}
              >
                Get personalized loan options based on your income, current EMI,
                and credit score. Quick, easy,
                <br className="display-none" /> and tailored just for you—apply
                now to see what you qualify for!
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 24,
                  backgroundColor: "transparent",
                  marginTop: "3%",
                }}
              >
                <a
                  href="https://minemi.ai/personal-loan/check-eligibility/"
                  className="eligiblity-buttons"
                  style={{
                    backgroundColor: "#59266D",
                    color: "#FFFFFF",
                    width: "18%",
                    borderRadius: 8,
                    fontFamily: "Archivo",
                    fontWeight: 500,
                    fontSize: 18,
                    paddingTop: 8,
                    paddingBottom: 8,
                    textAlign: "center",
                  }}
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ICICIInterestRate;
